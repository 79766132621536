import { FC, useEffect, useState } from "react";
import CourseContentPage from "./course-content-page";
import PrivateRoute from "../../../navigations/PrivateRoute";
import NavbarProgressCourseComponent from "../../../components/navigation-components/navbar-progress-course-component";
import CommunityPage from "./community-page";
import WebinarCoursePage from "./webinar-course-page";
import CustomSwitch from "../../../helpers/custom-switch";
import NavbarResponsiveCourseComponent from "../../../components/navigation-components/ navbar-responsive-progress-course";

import { connect } from "react-redux";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, Dispatch, bindActionCreators } from "redux";

const PassCourseContainerContainer: FC<any> = (props: any) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (isMobile ? <> <NavbarResponsiveCourseComponent idCourse={props.location.pathname.split("/")[props.location.pathname.split("/").length-1]}/></> : <>
        <div className="page-wrapper-height">
            <NavbarProgressCourseComponent />
            <CustomSwitch>
                <PrivateRoute exact={true} path={`${props?.match.path}/course/:idCourse`} component={CourseContentPage} />
                <PrivateRoute exact={true} path={`${props?.match.path}/community/:idCourse`} component={CommunityPage} />
                <PrivateRoute exact={true} path={`${props?.match.path}/webinar/:idCourse`} component={WebinarCoursePage} />
            </CustomSwitch>
        </div>
    </>
    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({

}, dispatch);
const mapStateToProps = (state: AppState) => ({
    course: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    follow: state.studentTraining.listFollowUps,
});

export default connect(mapStateToProps, mapDispatchToProps)(PassCourseContainerContainer);
