
import { FC } from 'react'
import { useHistory } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { returnStartPathUrl } from '../../helpers/domainCheck';






const CheckoutSuccededComponent: FC<any> = () => {

    const { t } = useTranslation();

    const history = useHistory()






    return (<>
<div className="payment-succeded-container">
            <div className='payment-succeded'>
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="60" cy="60" r="60" fill="#DADBDF" />
                <g filter="url(#filter0_dd_16360_325848)">
                    <rect x="19.1992" y="36" width="81.6" height="48.7845" rx="3.6" fill="#F5F5F4" />
                    <rect x="19.1992" y="42.1699" width="81.6" height="11.4065" fill="#292524" />
                    <rect x="24.2891" y="57.4375" width="34.9213" height="3.86065" fill="#D7D3D0" />
                    <rect x="61.3145" y="57.4375" width="14.2142" height="3.86065" fill="#D7D3D0" />
                </g>
                <circle cx="97.2008" cy="81.1559" r="13.1676" fill="white" />
                <g clip-path="url(#clip0_16360_325848)">
                    <path d="M97.2008 67.9883C89.9323 67.9883 84.0332 73.8874 84.0332 81.1559C84.0332 88.4245 89.9323 94.3236 97.2008 94.3236C104.469 94.3236 110.368 88.4245 110.368 81.1559C110.368 73.8874 104.469 67.9883 97.2008 67.9883ZM94.5673 87.7397L87.9835 81.1559L89.8401 79.2993L94.5673 84.0133L104.562 74.0191L106.418 75.8889L94.5673 87.7397Z" fill="#9DDBA9" />
                </g>
                <defs>
                    <filter id="filter0_dd_16360_325848" x="9.59922" y="33.6" width="100.8" height="67.9852" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="7.2" />
                        <feGaussianBlur stdDeviation="4.8" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.152941 0 0 0 0 0.152941 0 0 0 0 0.152941 0 0 0 0.04 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16360_325848" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="2.4" />
                        <feGaussianBlur stdDeviation="1.8" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.152941 0 0 0 0 0.152941 0 0 0 0 0.152941 0 0 0 0.03 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_16360_325848" result="effect2_dropShadow_16360_325848" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_16360_325848" result="shape" />
                    </filter>
                    <clipPath id="clip0_16360_325848">
                        <rect width="31.6023" height="31.6023" fill="white" transform="translate(81.3984 65.3535)" />
                    </clipPath>
                </defs>
            </svg>
            <div className="wrapper-empty-list  ">
                        <span className="H3-Headline me-2 pe-1">{t('paymentPassed')}</span>
                        <span className="body-md black-800" >{t('paymentSuccesfull')}</span>
            </div>
                           <button className="btn-Primary large  d-flex me-2" onClick={() => { history.push(`${returnStartPathUrl()}/activity`) }}>
                                    <div className="m-auto Button-md">
                                    {t('returnToActivity')}
                                    </div>
                                </button>



        </div>
        </div>




    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({


}, dispatch);

export default CheckoutSuccededComponent;


