import { ComponentProps, FC} from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import LoginComponent from "../../components/auth-components/login-register-components/login-component";


const LoginPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    return (<> <LoginComponent /> </>
        
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({

    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearAllData }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);