import { ComponentProps, FC, useMemo } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SchoolPreviewComponent from "../../components/school-preview/school-preview-component";
const SchoolPreviewPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
   
    const user = useMemo(() => {return props?.connectedUser}, [props?.connectedUser] );
 
    
    return (
     
            <>      {!user?.role.includes("role_student") &&
                <SchoolPreviewComponent/>}
            </>
   
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearAllData }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolPreviewPage);