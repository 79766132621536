import { FC } from "react";
import RouteWithBreadCrumb from '../../navigations/RouteWithBreadCrumb';
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import RegistrationPreviewStudentPage from "./registration-preview-page";
import FooterComponent from "../../components/footer-component";
import coursesListCardPage from "./courses-list-card-page";
import AdmissionFormMyCoursesPage from "./admissionForm-my-courses-page";
import PrivateRoute from "../../navigations/PrivateRoute";
import FormationCreationContainer from "./formation-creation/formation-creation-container";
import ProgressContainer from "../../pages/my-courses/progress/progressContainer";
import TrainingPaymentPage from "../finance/training-payment-page";
import RegistrationPreviewFromListStudentPage from '../students/preview-registration-page';
import PreviewCourseAfterInscriptionPage from './course-preview-page';
import PreviewPathAfterInscriptionPage from './preview-path-page';
import PreviewSessionAfterInscriptionPage from './preview-session-page';
import CustomSwitch from "../../helpers/custom-switch";
const MyCoursesContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height" id="my-courses-container">
      <CustomSwitch>
        <RouteWithNavbar history={props.history} roles={['role_prof', 'role_student', "role_adminstrator"]} exact={true} path={`${props.match.path}/my-courses`} component={coursesListCardPage} />
        <PrivateRoute path={`${props.match.path}/create-formation`} component={FormationCreationContainer} roles={['role_prof', "role_adminstrator"]} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/preview/course/:id`} access={true} component={PreviewCourseAfterInscriptionPage} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/preview/path/:id`} access={true} component={PreviewPathAfterInscriptionPage} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/preview/session/:id`} access={true} component={PreviewSessionAfterInscriptionPage} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/payement/course/:id`} component={TrainingPaymentPage} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/payement/path/:id`} component={TrainingPaymentPage} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/payement/session/:id`} component={TrainingPaymentPage} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/inscription/:type/:id`} component={AdmissionFormMyCoursesPage} />
        <RouteWithBreadCrumb roles={['role_student']} exact={true} path={`${props.match.path}/registration/:idFollow/:idStudent/:idRF`} component={RegistrationPreviewStudentPage} />
        <RouteWithBreadCrumb roles={['role_prof', 'role_director']} exact={true} path={`${props.match.path}/registrationPreview/:idFollow/:idStudent/:idRF`} component={RegistrationPreviewFromListStudentPage} />
        <PrivateRoute history={props.history} path={`${props.match.path}`} component={ProgressContainer} />
      </CustomSwitch>

    </div>
    <FooterComponent />
  </>
  )
}
export default MyCoursesContainer;