import React, { useEffect, useState } from 'react'
import constructionLogo from '../../styles/imgs/constructionLogo.png'
import koorsLogo from "../../styles/images/KoorsLogo.png"
import axiosInstance from '../../helpers/interceptors'

function PlatfomUpdatePage() {

  const [title, setTitle] = useState("Koors est en cours de maintenance")
  const [comment, setComment] = useState(" ")

  useEffect(() => {
    axiosInstance.post(`${process.env.REACT_APP_BASE_URL20}settings/currentSettings`,{product : "APP"})
    .then(response => {
      setTitle(response?.data?.title)
      setComment(response?.data?.comment)
    })
      
  }, [])
  
  
  return (
    <div style={{ maxWidth: "1200px", margin: 'auto' }}>
        <div className='d-flex flex-column  align-items-center' style={{paddingTop:"64px"}}>
            <img  height={65} width={43} src={koorsLogo} alt="koors logo" style={{marginBottom:"122px"}}/>
            <img  height={216} width={417} src={constructionLogo} alt="construction logo"  />
            <span className='H2-Headline' style={{marginTop:'44px'}}>{title}</span>
            <span className='d-flex body-xl' >{comment}</span>
       </div>
    </div>
  )
}

export default PlatfomUpdatePage