import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../../global-state/actions/profile-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { setRegistrationForm, setApplicationFile, getFollow, getStudentInfoById } from '../../../global-state/actions/course-actions';
import TableElement from '../../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const StudentsInscribedInfoTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const moment = require('moment');
    const history = useHistory();

    const iconCourse = <svg className="me-2" width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
        </g>
        <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
        <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
            </clipPath>
        </defs>
    </svg>;
    const iconPath = <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
            fill="#FA83EE" />
    </svg>
    const iconSession = <svg className="me-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
        </g>
        <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
        <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
        <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
            </clipPath>
        </defs>
    </svg>

    const [sizeInscribed, setSizeInscribed] = useState(20);
    const [inscribedDataLength, setInscribedDataLength] = useState<any>(null);
    const [inscribedData, setInscribedData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false)
    const [emptysearch, setemptysearch] = useState<any>(false);

    const inscribedHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-17", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('progress'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const dateFormat = require('dateformat');
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);

    useEffect(() => {
        setLoadingTable(props?.loadStudentsInscribed);
    }, [props?.loadStudentsInscribed])
    
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    useEffect(() => {
        if (!props?.data?.searchInscribed && props?.studentsList?.list?.length === 0 && props?.followCount?.INSCRIBED > 0){
            setemptysearch(true)
        }else{
            setemptysearch(false)
        }
    }, [props?.data])

    const handleScroll = async () => {

        if (!(atBottom) && ((window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight) && (inscribedData.body.length < props?.followCount?.INSCRIBED)) {
           
            setAtBottom(true)
            await props?.getNextPageInscribed(props?.pageNumberInscribed+1)
        }
        if (!(((window.innerHeight + window.scrollY) - 5) < document.documentElement.scrollHeight) || props?.loader === false) {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        setSizeInscribed(props?.data?.sizeInscribed);

    }, [props?.data])

    useEffect(() => {
        const list = {
            headers: inscribedHeaders,
            body: props?.studentsList?.list?.map((student: any) => {
                const date = new Date()
                date.setDate(date.getDate() - student?.lastConnexion)
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: <div className="d-flex align-items-center">
                                    {(student?.type === 'COURSE') ? iconCourse : (student?.type === 'PATH') ? iconPath : iconSession}
                                    <span className="ms-2">{student?.courseTitle}</span></div>, classNames: "", actionName: '' },
                            { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: moment(date).fromNow(), classNames: "text-center", actionName: 'text-center' },
                            { content: (student?.progress === null) ? '0%' : student?.progress + '%', classNames: "text-end", actionName: '' },
                        ]
                    }
                }
            })
        }
        setInscribedData(list);
        setInscribedDataLength(props?.studentsList?.length);
        setLoadingTable(false)
    }, [props?.studentsList?.list])

    const goToregistration = async (Student: any) => {
        props?.clearHistoryArray()
        await props?.getStudentInfoById(Student.id, props?.schoolInfo?.id);
        await props?.setApplicationFile(Student.applicationFile);
        await props?.setRegistrationForm(Student.registrationForm);
        await props?.getFollow(Student.idFollow);
        await props?.getStudent(Student);
        props?.redirect(t('Etudiant_1'),`${returnStartPathUrl()}/students/all`)
        props?.redirect(Student?.firstName + " " + Student?.lastName, `${returnStartPathUrl()}/students/profile/student/${Student.id}`)
        props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/students/registration/${Student.idFollow}/${Student.id}/${Student.registrationForm.id}`)
         history.push({ pathname: `${returnStartPathUrl()}/students/registration/${Student.idFollow}/${Student.id}/${Student.registrationForm.id}`, state: { data: Student } })
    }

    const doAction = async (value: any) => {

        if (value.action === 'goToPath') {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray()
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
             history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
        if (value.action === 'goToRegistration') {
            goToregistration(value.row.student);
        }
    }

    return (<>
        {!loadingTable ?
        <div>
            {(inscribedData?.body?.length > 0) && <>
                <TableElement data={inscribedData} doAction={(value: any) => { doAction(value) }} />
                {(atBottom 
                    && (props?.studentsList?.list?.length < props?.followCount?.INSCRIBED ))
                    && <div className="d-flex w-100 align-items-center justify-content-center">
                        <div className="m-3">
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div>
                    </div>}
            </>
            }
            {(inscribedData?.body?.length === 0 && !props?.data?.searchInscribed)&& !emptysearch &&
                <div className="w-100 text-center mt-5 pt-5">
                    <EmptyListElement lines={[t('noStudentFound')]} />
                </div>}
                {(inscribedData?.body?.length === 0 && props?.data?.searchInscribed) &&
                <div className="w-100 text-center mt-5 pt-5">
                      <EmptyListElement lines={[t("noStudentFound_2"),t("noProfessorsList_2")]} />
                  </div>}

            
        </div> :
            <div>
                <TableStudentsListLoadingComponent />
            </div>}
    </>);
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    loadStudentsInscribed: state.profReducer.loadStudentsInscribed,
    addDataList: ownProps?.addDataList,
    data: ownProps?.data
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getFollow,
            getStudentInfoById,
            getStudent,
            setApplicationFile,
            setRegistrationForm,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsInscribedInfoTableComponent);