import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
const moment= require('moment');

interface Props{
    post:any,
}
const QuestionCardHeader : FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>& Props>=(props:any)=>{
    const { t } = useTranslation();
    const [index, setIndex] = useState();
    const dateFormat = require('dateformat');
    useEffect(()=>{
       
        let lang = localStorage.getItem("language") || "fr" 
        if(lang!=="en"){
            require('moment/locale/'+ lang);
            moment.defineLocale(lang, {parentLocale: lang});
        }else{ moment.defineLocale('en', {parentLocale: 'en'});}
       
        if(props?.post.subject && props?.post.subject.category==="CHAPTER" && !props?.connectedUser?.role.includes("role_student")){
             setIndex(props?.courseForm?.orders.find((obj:any)=>obj.chapter.id===Number(props?.post.subject.idSubject))?.index); 
        }else{
            setIndex(props?.courseInfo?.orders.find((obj:any)=>obj.chapter.id===Number(props?.post.subject.idSubject))?.index); 
        }
        

    }, [])
    const getTrainingTypeFn=(category:string)=>{//getTrainingTypeFn(props?.post.subject?.category?.category)
        switch(category){
            case 'COURSE':
                return t('Cours');
            case 'CHAPTER':
                return t('Cours');
            case 'PATH':
                return t('pathTitle');
            default:
                return t('sessionTitle');
        }
    }
    const getCoursTitleFn=()=>{
        if(props?.post.subject?.category?.category==="COURSE" ||props?.post.subject?.category?.category==="PATH" || props?.post.subject?.category?.category==="SESSION" )
            return props?.post.subject.category?.courseTitle;
        else 
            return props?.post.subject.category?.titleCourse
    }
    const calculateTimeFn=()=>{
        if(moment(Date()).diff(moment(props?.post?.createdAt), 'days')<1)return moment(props?.post?.createdAt).fromNow()
        else return dateFormat(props?.post.createdAt,"dd/mm/yyyy")
    }
    const returnTypeFN=()=>{
        if(props?.post.subject?.category==="CHAPTER")return  "#"+t('chapitre')+" "+index
        else return  "#"+t('Cours')
    }
    return(
        <> {props?.connectedUser?.role.includes("role_prof")?
            <div className="d-flex flex-wrap discuttion-titles  mt-2">
                <span className="body-sm bold me-3"><span className="badge-text secondary me-1">{getTrainingTypeFn(props?.post.subject?.category?.category)}</span>{getCoursTitleFn()}</span>
                <span className="body-sm bold me-3">{(props?.post.subject?.category?.category==="CHAPTER")?"#"+t('chapitre')+" "+props?.post.subject?.category?.index: "#"+ getTrainingTypeFn(props?.post.subject?.category?.category)}</span>
                <span className="body-sm bold neutral-3 me-3">{dateFormat(props?.post.createdAt,"dd/mm/yyyy")}
                    {(moment(Date()).diff(moment(props?.post?.createdAt), 'days')<3)&& <span className="badge-text secondary">{" / "+moment(props?.post?.createdAt).fromNow()}</span> }
                    {(3<=moment(Date()).diff(moment(props?.post?.createdAt), 'days') && moment(Date()).diff(moment(props?.post?.createdAt), 'days')<6)&&  <span className="badge-text warning">{" / "+moment(props?.post?.createdAt).fromNow()}</span>}
                    {(6<=moment(Date()).diff(moment(props?.post?.createdAt), 'days'))&&  <span className="badge-text danger">{" / "+moment(props?.post?.createdAt).fromNow()}</span> }
                </span>
            </div>:
            <div className="d-flex flex-wrap discuttion-titles  mt-2">
                <span className="body-sm bold me-3">{returnTypeFN() }</span>
                <span className="body-sm bold neutral-3 me-3">{ calculateTimeFn()}</span>
            </div>}
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    courseForm: state.courseReducer.courseForm,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {},
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCardHeader)