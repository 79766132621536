import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const ButtonFollowLoading: FC<ComponentProps<any>> = () => {

  return (
    <div className='d-flex align-items-center justify-content-center my-4'>
        <Skeleton variant="rect" animation="wave" width={'10%'} height={48} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
    </div>
  );
}

export default ButtonFollowLoading;