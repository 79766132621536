import { FC, useEffect, useState } from "react";
import PrivateRoute from "../../../navigations/PrivateRoute";
import SessionCommunityPage from "./session-community-page";
import NavbarProgressSessionComponent from '../../../components/navigation-components/navbar-progress-session-component';
import WebinarTrainingPage from "./webinar-training-page";
import SessionProgressPage from "./session-progress-page";
import ExamResultPage from "./exam-result-page";
import CourseContentPage from "./course-content-page";
import CustomSwitch from "../../../helpers/custom-switch";
import NavbarResponsiveCourseComponent from "../../../components/navigation-components/ navbar-responsive-progress-course";

const ProgressSessionContainer: FC<any> = (props: any) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        isMobile ? <> <NavbarResponsiveCourseComponent idPath={props.location.pathname.split("/")[props.location.pathname.split("/").length-1]} /></> :
            <>
                <NavbarProgressSessionComponent />
                <CustomSwitch>
                    <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/progressSession/:idSession`} component={SessionProgressPage} />
                    <PrivateRoute exact={true} path={`${props?.match.path}/resultExam/:idExam`} component={ExamResultPage} />
                    <PrivateRoute exact={true} path={`${props?.match.path}/passCourse/:idCourse`} component={CourseContentPage} />
                    <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/community/:id`} component={SessionCommunityPage} />
                    <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/webinar/:id`} component={WebinarTrainingPage} />

                </CustomSwitch>
            </>
    )
}
export default ProgressSessionContainer;