import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../../global-state/store/root-reducers';
import { clearHistoryArray, redirect} from '../../../global-state/actions/breadcrumb-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import ChoiceCardFormationComponent from '../../../components/formations-card/choice-card-formation-component';
import { createCourse, clearFormCourse } from '../../../global-state/actions/course-actions';
import { getCountCourses } from '../../../global-state/actions/catalog-actions';
import { clearMyTrainings, clearTraining, createTraining } from '../../../global-state/actions/training-actions';
import { openModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../../../components/modals/modal-ids';
import { getProfessor } from '../../../global-state/actions/professor-actions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import { getSchoolInformationApi } from '../../../services/finance.service';

const courseIcon = <svg className="mb-3" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM25.3333 25.3333H6.66667V6.66667H25.3333V25.3333Z" fill="#5693EE" />
    <line x1="10.2617" y1="19.0527" x2="21.7354" y2="19.0527" stroke="#5693EE" strokeWidth="2" strokeLinecap="round" />
    <line x1="21.7383" y1="12.9473" x2="10.2646" y2="12.9473" stroke="#5693EE" strokeWidth="2" strokeLinecap="round" />
</svg>;
const pathIcon = <svg className="mb-3" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.52747 8.35462H3.2002V26.9728C3.2002 28.2528 4.24747 29.3001 5.52747 29.3001H24.1456V26.9728H5.52747V8.35462ZM26.4729 3.70007H10.182C8.90201 3.70007 7.85474 4.74735 7.85474 6.02735V22.3183C7.85474 23.5983 8.90201 24.6455 10.182 24.6455H26.4729C27.7529 24.6455 28.8002 23.5983 28.8002 22.3183V6.02735C28.8002 4.74735 27.7529 3.70007 26.4729 3.70007ZM26.4729 22.3183H10.182V6.02735H26.4729V22.3183Z" fill="#FA83EE" />
</svg>
const sessionIcon = <svg className="mb-3" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.40042 8.53376H4.26709V25.6004C4.26709 26.7738 5.22709 27.7338 6.40042 27.7338H23.4671V25.6004H6.40042V8.53376ZM25.6004 4.26709H10.6671C9.49376 4.26709 8.53376 5.22709 8.53376 6.40042V21.3338C8.53376 22.5071 9.49376 23.4671 10.6671 23.4671H25.6004C26.7738 23.4671 27.7338 22.5071 27.7338 21.3338V6.40042C27.7338 5.22709 26.7738 4.26709 25.6004 4.26709ZM25.6004 21.3338H10.6671V6.40042H25.6004V21.3338Z" fill="#8A57CC" />
    <line x1="9" y1="11.7998" x2="27.2" y2="11.7998" stroke="#8A57CC" strokeWidth="2" />
    <line x1="13.3999" y1="5.6001" x2="13.3999" y2="1.6001" stroke="#8A57CC" strokeWidth="2" />
    <line x1="23" y1="5.6001" x2="23" y2="1.6001" stroke="#8A57CC" strokeWidth="2" />
</svg>
const ChoiceFormationPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [verification, setVerification] = useState<any>();
    useEffect(() => {
     
        props?.getProfessor(props?.connectedUser?.idSchool, '')
        props?.clearHistoryArray()
        props?.redirect(t('createNewFormation'), `${returnStartPathUrl()}/courses/create-formation/choice`)        
        return (()=>{
            props?.clearHistoryArray()
        })
    }, [])

    useEffect(() => {
        props?.getCountCourses(props?.schoolInfo?.id)
    }, [props?.schoolInfo])

    const createCourseFn = async () => {
        await props?.clearFormCourse();
        const config = {
            id: null,
            objectives: '',
            resume: '',
            prerequisite: '',
            skills: [],
            idRegistrationForm: null,
            status: 'DRAFT',
            level: "ANYLEVEL",
            creationDate: Date.now().toString(),
            idProf: props?.connectedUser?.idPerson,
            creator: props?.connectedUser?.idPerson,
            entityId: props?.connectedUser?.idSchool,
            entityType: 'SCHOOL',
            linkImg: '',
            video: '',
            isVisible:true,
            isDiplomat:false,
            theme:[]
        }
        const object = {
            title: '',
            type: 'COURSE',
            ...config
        }

        await props?.createCourse(object, props?.connectedUser);
        await props?.clearMyTrainings()
        props?.redirect(t('createCourse'), `${returnStartPathUrl()}/courses/create-formation/course/description`)

         history.push(`${returnStartPathUrl()}/courses/create-formation/course/description`);
    }
    const addTraining = async (type: string) => {
        const config = {
            id: null,
            objectives: '',
            resume: '',
            prerequisite: '',
            skills: [],
            idRegistrationForm: null,
            status: 'DRAFT',
            level: "ANYLEVEL",
            creationDate: Date.now().toString(),
            idProf: props?.connectedUser?.idPerson,
            creator: props?.connectedUser?.idPerson,
            entityId: props?.connectedUser?.idSchool,
            entityType: 'SCHOOL',
            linkImg: '',
            video: '',
            isVisible:true,
            isDiplomat:false,
            theme:[]
        }
        await props?.clearTraining(type)
        switch (type) {
            case "PATH": await props?.createTraining("paths", { ...props?.path, ...config, subjectsByPath: [], title: '', type, isDiplomat:false });  history.push(`${returnStartPathUrl()}/courses/create-formation/path/description`); break;
            case "COURSE": await props?.createTraining("course", { ...props?.course, ...config, type });  history.push(`${returnStartPathUrl()}/courses/create-formation/course/description`); break;
            case "SESSION": await props?.createTraining("sessions", { ...props?.session, ...config, subjectsBySession: [], title: '', type, isDiplomat:false });  history.push(`${returnStartPathUrl()}/courses/create-formation/session/description`); break;
        }

        
    }



    const courseCreation = async (type: string) => {

        if (props?.connectedUser?.role === "role_adminstrator") {
            await props.openModal({ idModal: ModalIDS.PROFESSOR_CHOICE, size: "md", data: { type: type } })
        } else {
            createCourseFn()
        }
    }
    const createPath = async (type: string) => {
        if (props?.connectedUser?.role === "role_adminstrator") {
            await props.openModal({ idModal: ModalIDS.PROFESSOR_CHOICE, size: "md", data: { type: type } })
        } else {
            addTraining("PATH")
        }
    }

    const getSchoolInformation = async (id:Number) =>{
        const result = await getSchoolInformationApi(id);
        setVerification(result);
    }

    useEffect(() => {
        if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
            getSchoolInformation(props?.schoolInfo?.id)
        }
    
    
      }, [props?.schoolInfo])
   

    const createSession = async (type: string) => {
        if (props?.connectedUser.role === "role_adminstrator") {
            await props.openModal({ idModal: ModalIDS.PROFESSOR_CHOICE, size: "md", data: { type: type } })
        } else {
            addTraining("SESSION")
        }
    }

   
      
    return (
        <>
            <div className="row mt-5" style={{ maxWidth: '1200px', margin: 'auto' }}>
                <div className="d-flex w-100 flex-column align-items-center justify-content-center mt-5">
                    <h2 className="H2-Headline neutral-3 mt-5"> {t('createNewFormation')} </h2>
                    <h3 className="H3-Headline mt-3"> {t('selectFormatFormation')} </h3>
                    <div className="grid-cards p-0 mt-5">
                        <ChoiceCardFormationComponent title={t('Cours')} icon={courseIcon} description={t('descriptionChoiseCours')} >
                            <button   onClick={() => { courseCreation("COURSE") }} type="button" className="btn-Primary large">
                                {t('createCourse')}
                            </button>
                        </ChoiceCardFormationComponent>
                        <ChoiceCardFormationComponent title={t('pathTitle')} icon={pathIcon} description={t('descriptionChoisePath')} >
                            <button type="button" onClick={() => {!!verification && verification?.limitations?.find((path:any)=>path.key==="path")&& verification?.limitations?.find((path:any)=>path.key==="path")?.value==="true"? createPath("PATH") :history.push(`${returnStartPathUrl()}/not-found/path`)}} className="btn-Primary large">
                                {t('createPath')}
                            </button>
                        </ChoiceCardFormationComponent>
                        <ChoiceCardFormationComponent title={t('sessionTitle')} icon={sessionIcon} description={t('descriptionChoiseSession')} >
                            <button type="button" onClick={() => { !!verification && !!verification?.limitations?.find((path:any)=>path.key==="session") && verification?.limitations?.find((path:any)=>path.key==="session")?.value==="true"? createSession("SESSION") :history.push(`${returnStartPathUrl()}/not-found/session`)  }} className="btn-Primary large">
                                {t('createSession')}
                            </button>
                        </ChoiceCardFormationComponent>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    historyPathProfesseurs: state.historyReducer.listHistoryProfesseur,
    lastPath: state.historyReducer.lastPathBeforeBreadCrumb,
    course: state.trainingReducer.course,
    path: state.trainingReducer.path,
    session: state.trainingReducer.session,
    subscription: state.schoolInfoReducer.subscription,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    courseNumber: state.catalogReducer.courseNumber

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createCourse,
            clearFormCourse,
            clearTraining,
            createTraining,
            clearMyTrainings,
            getCountCourses,
            openModal,
            getProfessor,
            clearHistoryArray,
            redirect
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormationPage);
