import { FC } from "react";
import DescriptionCourseFormPage from "./description-course-page";
import NavbarCourseComponent from "../../../../components/navigation-components/navbar-course-component";
import PrivateRoute from "../../../../navigations/PrivateRoute";
import RouteWithBreadCrumb from "../../../../navigations/RouteWithBreadCrumb";
import AdmissionCoursePage from "./admission-course-page";
import ContentCoursePage from "./content-course-page";
import ExamChapterFormPage from "./exam-chapter-form-page";
import StudentsCoursePage from "./students-course-page";
import CustomSwitch from "../../../../helpers/custom-switch";
import CommentCourseAssistant from "./comment-course-assistant";
import { useHistory } from "react-router";
interface Params {
    id: any
}

const CourseCreationContainer: FC<any> = (props: any) => {
  const history = useHistory();  
  return (<>

    {!history.location.pathname.includes('/chapter-test') && <NavbarCourseComponent />}
    {history.location.pathname.includes('/chapter-test') && <div style={{height:"64px"}} />}

      <CustomSwitch>
         <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={DescriptionCourseFormPage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/content`} component={ContentCoursePage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/inscription`} component={AdmissionCoursePage} />
         <PrivateRoute exact={true} path={`${props?.match.path}/students`} component={StudentsCoursePage} />
         <PrivateRoute exact={true} path={`${props?.match.path}/comments`} component={CommentCourseAssistant} />
         <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/chapter-test`} component={ExamChapterFormPage} />
      </CustomSwitch>
  </>
  )
}
export default CourseCreationContainer;