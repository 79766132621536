import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import "../../../translations/i18n";
import HeaderTrainingFormComponent from '../../../components/formations-card/header-formation-form';

import ResponseClosedQuestion from '../../../components/sondage/response-question-component';
import ResponseOpenQuestion from '../../../components/sondage/response-open-question-component';
import axiosInstance from '../../../helpers/interceptors';
import { useParams } from 'react-router';

const ResponseSurveyPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { id } = useParams<any>();
    const [survey, setSurvey] = useState<any>()
    const [exam, setExam] = useState<any>()


    const getExam = async (idSurvey: any) => {
        const baseUrl = process.env.REACT_APP_BASE_URL6;
        let responseSurvey: any = await axiosInstance.get(baseUrl + "exams/getResponsesByQnaId/" + idSurvey).then(response => { return response.data; }).catch(err => { return [] });
        setSurvey(responseSurvey)

    }

    const geSurvey = async (idSurvey: any) => {
        const baseUrl = process.env.REACT_APP_BASE_URL6;
        let previewSurvey: any = await axiosInstance.get(baseUrl + "exams/findby/" + idSurvey).then(response => { return response.data; }).catch(err => { return [] });
        setExam(previewSurvey)
    }
    useEffect(() => {
        getExam(id)
        geSurvey(id)
    }, [id])

    const calculateTaux = () => {
        if (survey?.totalPersons === 0 || survey?.totalResponses === 0) {
            return 0;
        } else {
            if (Number.isInteger((survey?.totalResponses / survey?.totalPersons) * 100)) {
                return (survey?.totalResponses / survey?.totalPersons) * 100;
            } else {
                return ((survey?.totalResponses / survey?.totalPersons) * 100).toFixed(2);
            }
        }
    }


    return (<>
        <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
            <HeaderTrainingFormComponent type={"SURVEY"} title={exam?.title} status={""} trainingTitle={props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName} creationDate={exam?.creationDate?.substring(0, 10).split("-").reverse().join("/")} />
        </div>

        <div className="m-auto py-5 px-4 d-flex justify-content-between  flex-wrap " style={{ maxWidth: "1224px" }}>
            <div className="d-flex flex-column me-4 ">
                <span className="H3-Headline">Informations générales</span>
                <span className="body-md" style={{ maxWidth: 347 }}>Vous trouverez dans ce récapitulatif toutes les informations et le bilan concernant ce  sondage.</span>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row " style={{ width: "718px" }}>
                    <div className="activity-card small col-xl-4 me-4 py-3 d-flex justify-content-between second-card-list-activities " style={{ width: "230px" }}>
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                            <div className="d-flex flex-column me-4 ">
                                <span className={survey?.totalPersons === 0 ? "H2-Headline neutral-3" : "H2-Headline"}>
                                    {survey?.totalPersons}
                                </span>
                                <span className="body-sm bold neutral-3">Nombre d’affichage</span>
                            </div>
                        </div>
                    </div>
                    <div className="activity-card small col-xl-4 me-4 py-3 d-flex justify-content-between second-card-list-activities" style={{ width: "230px" }}>
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                            <div className="d-flex flex-column me-4 ">
                                <span className={survey?.totalResponses === 0 ? "H2-Headline neutral-3" : "H2-Headline"}>
                                    {survey?.totalResponses}
                                </span>
                                <span className="body-sm bold neutral-3">Total des réponses</span>
                            </div>
                        </div>
                    </div>

                    <div className="activity-card small col-xl-4 me-4 py-3 d-flex justify-content-between second-card-list-activities " style={{ width: "230px" }}>
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                            <div className="d-flex flex-column me-4 ">
                                <span className={calculateTaux() === 0 ? "H2-Headline neutral-3" : "H2-Headline"}>
                                    {calculateTaux()} %
                                </span>
                                <span className="body-sm bold neutral-3">Taux d’achèvement</span>
                            </div>
                        </div>
                    </div>





                </div>
            </div>


        </div>

        {survey?.totalResponses === 0 ? <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
            <div className="m-auto py-5 px-4 d-flex justify-content-between  flex-wrap " style={{ maxWidth: "1224px" }}>
                <div className="d-flex flex-column me-4 " style={{ width: "367px" }}>
                    <span className="H3-Headline" style={{ lineHeight: '43px' }}>Vue d’ensemble</span>
                    <span className="body-md" >Vous trouverez dans ce résumé toutes les informations et le bilan concernant ce sondage.</span>
                </div>
                <div className="d-flex flex-column ">
                    <div className="d-flex flex-row ">

                        <div className="d-flex justify-content-between " style={{ width: "724px", border: "1px solid #EBECEE", padding: "37px 150px 20px 59px" }}>
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                                <div className="d-flex flex-column me-4 ">
                                    <span className="body-md" style={{ marginBottom: "21px" }}>Votre sondage n’a récolté aucune réponse pour le moment</span>
                                    <button type="button" className="btn-Primary medium" style={{ inlineSize: "fit-content" }}>
                                        Partager
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

            : <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
                <div className="m-auto py-5 px-4 d-flex justify-content-between  flex-wrap " style={{ maxWidth: "1224px" }}>
                    <div className="d-flex flex-column me-4 " style={{ width: "367px" }}>
                        <span className="H3-Headline" style={{ lineHeight: '43px' }}>Résumé par question</span>
                        <span className="body-md" >
                            Vous trouverez dans ce résumé toutes les informations et le bilan concernant ce sondage.

                        </span>
                    </div>
                    <div className="d-flex flex-column ">
                        {survey?.staticAnswerDtoList?.map((question: any, index: any) => (
                            <div key={index}>
                                {question.type === "OQ"
                                    ? <ResponseOpenQuestion response={question} totalResponse={survey?.totalResponses} index={index + 1} />
                                    : <ResponseClosedQuestion response={question} totalResponse={survey?.totalResponses} index={index + 1} />
                                }

                            </div>
                        ))}



                    </div>

                </div>
            </div>}

    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUserInfo: state.authReducer.connectedUserInfo,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponseSurveyPage);
