import { useState, useEffect, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';

import TableLoading from './table-loading'

import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import TableSondage from '../../elements/table/table-sondage';
import { getListServeys, getSizeQnas, getSurveyById } from '../../global-state/actions/sondage-actions';
import BtnAddSondage from '../../pages/sondage/BtnAddSondage';
import { getExamToPass } from '../../global-state/actions/exam-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
interface ObjectCoursesPayed {
    t: any

}


const SondagesListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ObjectCoursesPayed> = (props: any) => {
    const dateFormat = require('dateformat');
    const history = useHistory()
    const t = props?.t
    const status = [
        { label: t("Brouillon"), value: "DRAFT", color: "primary" },
        { label: t("Clôturé"), value: "ARCHIVE", color: "danger" },
        { label: t("Ouvert"), value: "ACTIVE", color: "success" },
    ]
    const [filter, setFilter] = useState({
        pageSize: 12,
        pageNumber: 0,
        qnaType: "SERVEY",
        entityType: "SCHOOL",
        entityId: props?.school?.id

    })
    const [size, setSize] = useState(props?.size)
    const [atBottom, setAtBottom] = useState<any>(false)
    const [loading, setLoading] = useState(true);
    const [loadingScroll, setLoadingScroll] = useState(true);
    const headers = [
        { title: t('TITREdusondage'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Auteur'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('CRÉELE'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('CloturéLE'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('RÉPONSEs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Statut'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    ]
    useEffect(() => {
        setLoading(props?.loading);
    }, [props?.loading]);

    useEffect(() => {
        setSize(props?.size);
        props?.getSizeQnas(filter, props?.school.id);
        filterQnas(filter);
    }, []);

    useEffect(() => {
        setSize(props?.size);
    }, [props?.size]);

    useEffect(() => {
        setLoadingScroll(props?.loadingscroll);
    }, [props?.loadingscroll]);


    useEffect(() => {

        props?.getListServeys(filter, "first");


    }, [])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    const getData = async () => {

        if (props?.listServeys?.length < props?.size && !loadingScroll) {
            let filter = {
                pageSize: 12,
                pageNumber: props?.pageNumber,
                qnaType: "SERVEY",
                entityType: "SCHOOL",
                entityId: props?.school?.id
            }
            await props?.getListServeys(filter, 'no');
        }
    }
    const handleScroll = async () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight) {
            getData()
            document.removeEventListener('scroll', handleScroll);
        }
        if ((window.innerHeight + window.scrollY) <= document.documentElement.scrollHeight) {
            setAtBottom(false);
        }
    }


    const filterQnas = (e: any) => {
        props?.getListServeys(e, "first");
    };


    const data: any = useMemo(() => {
        return {
            headers,
            body: props?.listServeys?.map((qna: any) => {
                const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === qna?.status?.toLowerCase());

                return {
                    row: {
                        data: qna,
                        classNames: "table-white",
                        columns: [
                            { content: qna?.title, classNames: "", actionName: 'open' },
                            { content: props?.connctedUser?.firstName + " " + props?.connctedUser?.lastName, actionName: 'open' },
                            { content: dateFormat(qna?.creationDate, "dd/mm/yyyy"), classNames: "", actionName: 'open' },
                            {
                                content: <div className='d-flex flex-column'>
                                    <span> {qna?.status == "ARCHIVE" && qna?.updateDate != null ? dateFormat(qna?.updateDate, "dd/mm/yyyy") : "-"} </span>

                                </div>, classNames: "", actionName: 'open'
                            },
                            { content: qna.nbResponses, classNames: "text-center", actionName: 'open' },
                            { content: actualStatus?.label, classNames: `text-start badge-text ${actualStatus?.color}`, actionName: 'open' },

                        ]
                    }
                }

            })

        }




    }, [props?.listServeys])




    const doAction = async (obj: any) => {
        if (obj.row.data.status === "ACTIVE") {
            props?.clearHistoryArray()
            props?.redirect("Sondage", `${returnStartPathUrl()}/survey/preview/${obj?.row?.data?.id}`)
            props?.redirect("Réponses", `${returnStartPathUrl()}/survey/preview/${obj?.row?.data?.id}`)
            await history.push(`${returnStartPathUrl()}/survey/details/${obj?.row?.data?.id}/preview`)
        } else {
            if (obj?.action === 'open') {
                const index = obj?.index;
                await props?.getSurveyById(props?.listServeys[index]?.id);
                await props?.clearHistoryArray().then(async () => {
                    await history.push(`${returnStartPathUrl()}/create-sondage`);
                });
            }
        }

    };


    return (
        <>

            <div className=" justify-content-center mt-0 w-100 mx-auto finance-height-table" style={{ maxWidth: '1200px' }}>

                {!loading && (props?.listServeys?.length > 0) &&
                    <>
                        <TableSondage data={data} doAction={doAction} />
                        {loadingScroll && props.listServeys.length > props.size && (
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>
                        )}



                    </>}
                {!loading && (props?.listServeys?.length === 0)
                    && <div className='d-flex flex-column mt-5 pt-5'>
                        <div className="msg-list-empty">
                            <EmptyListElement lines={["Aucun sondage n’a encore été créé"]} />
                        </div>
                        <div className='d-flex justify-content-center '>

                            <button type="button" className="btn-Primary large" onClick={() => history.push(`${returnStartPathUrl()}/create-sondage`)}>
                                Créer un sondage
                            </button>
                        </div>
                    </div>
                }
                {loading && <TableLoading />}
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    school: state.schoolInfoReducer.schoolInfo,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    user: state.authReducer.connectedUser,
    connctedUser: state.authReducer.connectedUserInfo


});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getListServeys,
            getSizeQnas,
            getExamToPass,
            getSurveyById,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(SondagesListComponent);
