import { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import defaultUser from '../../styles/imgs/Default_user_icon.svg';
import { useTranslation } from 'react-i18next';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useHistory } from 'react-router';
import koolog from '../../styles/images/koolog.png';
interface Props {
    t: any

}
const SondageUserValidatePage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    return (<>

        <div style={{ backgroundColor: "#FFFFFF" }}>

            <div className="d-flex flex-column align-items-center mx-4" style={{ backgroundColor: "#FBFBFD", paddingTop: "41px" }}>


                <div className="w-100  d-flex align-items-center justify-content-center flex-column px-5" style={{ minHeight: 300 }}>

                    <img style={{ width: 120, height: 120, objectFit: 'fill', borderRadius: '50%' }} src={(props?.school?.logo && props?.school?.logo !== null && props?.school?.logo !== '') ? props?.school?.logo : koolog} alt="user icon" />

                </div>
                <span className="body-xl bold" >{props?.school?.name} </span>

                <h3 className="H2-Headline mt-4" >Merci d’avoir participé à ce</h3>
                <h3 className="H2-Headline " >sondage!</h3>
            </div>



        </div>



    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    school: state.schoolInfoReducer.schoolInfo
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {


        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    
)(SondageUserValidatePage);
