import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import EmptyListElement from "../empty-list/normal-empty-list-element";

interface Props {
  data: any
  doAction?: any
  height?: number | string
}

function TableTeamWithActions(props:any) {
    const [data, setData] = useState<any>();
    const { t } = useTranslation();
    useEffect(() => {
      setData(props?.data)
    }, [props?.data]);
    const doAction = (e: any, actionName: string, row: any, index: number) => {
      if (actionName !== '') {
        e.stopPropagation();
  
        const object = {
          row: row,
          action: actionName,
          index: index
        }
        props?.doAction(object);
      }
    }

    return (
      <>
        <div className="mt-3" style={{minHeight: 'calc(100% - 134px)'}}>
          <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200, overflow: 'initial' }}>
            {data?.body?.length > 0 && <table className="w-100 custom-table">
              <thead className="mb-4">
                <tr>
                  {data?.headers?.map((header: any) =>
                  (<th key={header.title} scope={header.scope} className={header.classNames}>
                    <button className={header.buttonClasses}>
                      {t(header.title)}
                    </button>
                  </th>)
                  )}
                </tr>
              </thead>
              <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
                {data?.body?.map((data: any, index: number) =>
                (
                  <tr key={index + 'row'} style={{ cursor: (data.row.actionName === "") ? 'default' : 'pointer' , position:"relative"}} onClick={(e) => doAction(e, data.row.actionName, data.row, index)} className={data.row.classNames}>
                    {data.row.columns.map((column: any, index: number) =>
                    (
                      <td key={index + 'column'} onClick={(e) => doAction(e, column.actionName, data.row, index)}  className={column.classNames} colSpan={column.colspan}>{column.content}</td>
                    )
                    )}
                  </tr>
                )
                )}
              </tbody>
            </table>}
          </div>
            {
              props?.actualTab === 'assistant' && data?.body?.length === 0 && <div className="mt-7"><EmptyListElement lines={[t('noAssistant_1'), t('noAssistant_2')]} /></div>
            }
            {
              props?.actualTab === 'prof' && data?.body?.length === 0 && <div className="mt-7"><EmptyListElement lines={[t('noProfessorsList_1'), t('noProfessorsList_2')]} /></div>
            }
            
            {
              props?.actualTab === 'all' && props?.teamList?.length === 0 && <div className="mt-7"><EmptyListElement lines={[t('noTeam1'), t('noTeam2')]} /></div>
            }
            
        </div>
      </>
    );
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
  teamList: state.profReducer.teamList,
});

export default connect(mapStateToProps)(TableTeamWithActions)