
import { FC, useMemo } from "react";
import {useLocation } from "react-router";
import NavbarPathComponent from "../../../../components/navigation-components/navbar-path-component";
import PrivateRoute from "../../../../navigations/PrivateRoute";
import PathContentPage from "./path-content-page";
import PathDescriptionPage from "./path-description-page";
import AdmissionPathPage from './admission-path-page';
import CustomSwitch from "../../../../helpers/custom-switch";
import studentsPathPage from "./students-path-page";

const PathCreationContainer: FC<any> = (props: any) => {
  const location=useLocation()
    const activeTab:string=useMemo(()=>{
      return  (location.pathname.split("/").pop() as string);
  },[location?.pathname])
  return (<>

<NavbarPathComponent activeTab={activeTab}/>
      <CustomSwitch>
         <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={PathDescriptionPage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/content`} component={PathContentPage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/inscription`} component={AdmissionPathPage} />
         <PrivateRoute exact={true} path={`${props?.match.path}/students`} component={studentsPathPage} />

      </CustomSwitch>
  </>
  )
}
export default PathCreationContainer;