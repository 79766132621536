import Axios from '../../helpers/interceptors';
import { ComponentProps, FC, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCourse } from '../../global-state/actions/course-actions';
import { getAssistant, getTeam, searchTeam , clearTeamList} from '../../global-state/actions/professor-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import SelectElement from '../../elements/select/select-element';


const FilterTeamListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [filter, setFilter] = useState<any>({ status: "", search: "" });
    const [defaultValueStatus, setDefaultValueStatus] = useState("none");
    const { t } = useTranslation();
    const listStatus = [
        { label: t('Tous'), value: t('Tous'), id: 1 },
        { label: t('Enattente'), value: 'Enattente', id: 2 },
        { label: t('Admis'), value: 'Admis', id: 3 },
        { label: t('Suspendu'), value: 'Suspendu', id: 4 }
    ]
    const genericExcel = () => {
        const name = t('listeTeam') + " " + props?.schoolInfo?.code
        const lang = localStorage.getItem("language")
        const baseUrl1 = process.env.REACT_APP_BASE_URL2;
        Axios({
            url: baseUrl1 + "person/export/prof/" + props?.director.idSchool + "/" + name + "/" + lang+"/"+props?.actualTab,
            method: 'POST',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.csv');
            document.body.appendChild(link);
            link.click();
        })
    }
    const searchTeam = async (search: any) => {
        const copyFilter = { ...filter };
        copyFilter.search = search;
        setFilter(copyFilter);
        if (search.length > 1) {
            await props?.searchTeam(props?.director.idSchool, search.length > 0 ? search.toLowerCase() : "", "ALL", 0)
        } else if (search === "") {
            await props?.clearTeamList()
              props?.getTeam(props?.director.idSchool, "ADMINSTRATOR", 0, 'refresh')
              props?.getTeam(props?.director.idSchool, "ALL", 0, 'refresh')
              props?.getTeam(props?.director.idSchool, "PROF", 0, 'refresh')
        }
    }
    const changeStatus = (value: string) => {
        const copyFilter = { ...filter };
        copyFilter.status = value;
        setFilter(copyFilter);
        props?.doFilter(value);
    }

    
    return (
        <div className="d-flex align-items-center justify-content-between flex-wrap mt-5">
            <button onClick={() => genericExcel()} style={{ minHeight: '45px', marginRight: '10px' }} className="btn-Secondary large icon-left"  >
                <span className="material-icons-outlined" style={{ marginRight: '8px' }} >download</span>
                <div className="m-auto ps-2">{t('exportListe')}</div>
            </button>
            <div className="d-flex align-items-center">
                <div style={{ width: '220px', marginRight: '10px' }}>
                    <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueStatus} options={listStatus} onChange={(e: any) => { changeStatus(e) }} placeholder={t('statusFilter')} />
                </div>
                <div className="input-form-control" style={{ maxWidth: '220px' }}>
                    <input dir="ltr"type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={filter.search} onChange={(e: any) => searchTeam(e.target.value)} />
                    <span className="material-icons-outlined"> search </span>
                </div>
            </div>
        </div>


    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    doFilter: ownProps?.doFilter,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ getAssistant, searchTeam, getCourse, getTeam , clearTeamList}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterTeamListComponent);
