import { ComponentProps, FC, useCallback, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { changeMail, getUserProfileDetails } from '../../global-state/actions/auth-actions';
import axios from "../../helpers/interceptors";
import { debounce } from 'lodash';
import { verifyEmailExist } from '../../global-state/actions/user-auth-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';

const UpdateUserMailcomponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { t } = useTranslation();
    const [mail] = useState(props?.userProfileDetails?.email);
    const [open, setOpen] = useState(false);
    const [accountPassword, setAccountPassword] = useState<string>("");
    const [newEmail, setNewEmail] = useState<string>("");
    const [errorMsg,setErrorMsg] = useState<string>("");
    const [passwordDisplay, setPasswordDisplay] = useState<boolean>(false)
    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)

    const changeMail = async() => {
        const checkPassword = {
            email: mail?.toLowerCase(),
            password: accountPassword
        }
        const updateMail = {
            "id": props?.userProfileDetails?.id,
            "lastName": props?.userProfileDetails?.lastName,
            "firstName": props?.userProfileDetails?.firstName,
            "email": mail?.toLowerCase(),
            "domain": props?.userProfileDetails?.domain,
            "newEmail": newEmail?.toLowerCase()
        }
        if(!!props?.connectedUser?.role && props?.connectedUser?.role=="role_director" && process.env.REACT_APP_BASE_HUBSPOT === "true"){
            const object = {
                "properties": {
                  "email": props?.userProfileDetails?.email?.toLowerCase(),
                  "firstname": props?.userProfileDetails?.firstName,
                  "lastname": props?.userProfileDetails?.lastName,
                }
              }
            await axios.post(process.env.REACT_APP_BASE_URL21 + "hubspot/getUser", object ).then(async(resp:any)=>{
   
                const objectMail = {
                    "email": mail?.toLowerCase(),
                    "properties": {
                      "email": newEmail,
                      "firstname": props?.userProfileDetails?.firstName,
                      "lastname": props?.userProfileDetails?.lastName,
                    }
                  }
                  if(!!resp?.data?.id){
                    await axios.post(process.env.REACT_APP_BASE_URL21 + "hubspot/updateMail/"+resp?.data?.id, objectMail )

                  }
                          })
   
      
          
        }

       await props?.changeMail(checkPassword, updateMail)

       window.location.href = `/schoolissam/auth/login`;
            
    }
    const checkMailExist = async (email:any) => {
        if(email!==""){
        let checkEmail = await props.verifyEmailExist(email)
        if(checkEmail.data !== "Email not found"){
            setErrorMsg("Cette adresse e-mail est déjà utilisée")
        }
        else {
            setErrorMsg("")
        }
    }
    }

    const cancelDelete = () => {
        setOpen(false);
        setNewEmail("");
    }
    
    const debounceVerifMail = useCallback(debounce(checkMailExist, 1000), []);
    return (
        <div className='mb-4 w-100'>
            <div className="d-flex flex-column">
                <div className=" d-flex align-items-center justify-content-between">
                    <span className="H3-Headline">{t("emailAdd")}</span>
                    <div className='first-action-profile'>                    
                        <button className="btn-text outlined first-letter-capitalize" onClick={(e) => setOpen(!open)}>{t("emailUpdateButtonShH")}</button>
                    </div>
                    <div className='second-action-profile'>
                        <button type="button" className="btn-QuickActions medium" onClick={(e) => setOpen(!open)}><span className="material-icons-outlined">edit</span></button>
                    </div>
                </div>
            </div>
            <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="form-title neutral-3">{t('emailAdd')}</span>
                    <span className="form-title mb-0">{mail}</span>
                </div>
            </div>

           {open === true &&
            <>
                <div className="row mt-4">
                    <span className="form-title ">{t('newEmail')}</span>
                </div>
                <div className="d-flex email-edit-small" style={{gap: 24}}>
                    <div className="d-flex w-100" style={{ display: "flex", flexDirection: "column" }}>
                        <input dir="ltr"type="text" value={newEmail} className={`form-control input text-default ${errorMsg!=''?"error":""}`} placeholder={t("emailLogin")} 
                        onChange={(e) => {setNewEmail(e.target.value.trim()); 
                            debounceVerifMail(e.target.value.trim());
                           }} />
                        <div className='mt-1'>
                            <span className='error-input'>{errorMsg}</span>
                        </div>
                    </div>
                    <div className="d-flex w-100" style={{ display: "flex", flexDirection: "column" }} >
                        <div className="input-form-control" >
                            <input dir="ltr"type={passwordDisplay ? "text" : "password"}  autoComplete="new-password" className="form-control input text-default" placeholder={t('password')} value={accountPassword} onChange={(e) => setAccountPassword(e.target.value)} />
                            {!passwordDisplay && <span className="material-icons-outlined" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setPasswordDisplay(!passwordDisplay)}>visibility</span>}
                            {passwordDisplay && <span className="material-icons-outlined" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setPasswordDisplay(!passwordDisplay)}>visibility_off</span>}
                        </div>
                        <div style={{ marginTop: "32px", marginLeft: "auto" }}>
                            <button type="button" className="btn-Secondary large" style={{ marginRight: "12px" }} onClick={cancelDelete} >
                                {t('annuler')}
                            </button>
                            <button disabled={(newEmail === '' || (!regx.test(newEmail)) || accountPassword === '' || errorMsg != '') } type="button" className="btn-Primary large" onClick={changeMail}>
                                {t('modifier')}
                            </button>
                        </div>
                    </div>
                </div>
            </>
            }

<div className="mt-4">
                <hr  style={{ border: "1px solid #F2F2F5" }} />
            </div>
        </div>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    changeMail,
    verifyEmailExist
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserMailcomponent);

