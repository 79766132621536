import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';

const MoreInfoSectionComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [type, setType] = useState<string>("course");
    useEffect(() => {
      if(props?.type) {

        setType(props?.type);
      }
    }, [props?.type])
    return <>
    <section style={{background: 'linear-gradient(to right, #FFFFFF 50%, #FBFBFD 50%)'}}>

    <div style={{ minHeight: 300, maxWidth: 1200, margin: 'auto' }}>
        <div className="d-flex align-items-start  w-100 px-2">
              <div className="w-100 pe-5" style={{minHeight: 300}}>
                <p className="H3-Headline black-800 mt-5"> {t('objPEda')} </p>
                <div className="my-3 py-3 body-md neutral-2" dangerouslySetInnerHTML={{ __html: ((props?.objectives)?props?.objectives:"Votre texte...") }}></div>
              </div>
              <div className="w-100 ps-5 " style={{ minHeight: 300 }}>
                <p className="ps-5 H3-Headline black-800 mt-5">{t((type === 'COURSE')?'preresuiPeda':(type==='SESSION')?'preresuiPedaSession': 'preresuiPedaPath')}</p>
                <div className="ps-5 my-3 py-3 body-md neutral-2" dangerouslySetInnerHTML={{ __html: ((props?.prerequisite )?props?.prerequisite :"Votre texte...")}}>
                </div>
              </div>
              </div>
          </div>
    </section>
    </>
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    objectives: ownProps?.objectives,
    prerequisite: ownProps?.prerequisite,
    type: ownProps?.type
});

export default connect(mapStateToProps, null)(MoreInfoSectionComponent);
