

import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import InputUploadProfileSchool from '../../../elements/inputs/input-upload-profile-school';


const SchoolPhotoModal: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    let [centralLogo, setCentralLogo] = useState<any>(props?.connectedSchool?.logo);

    useEffect(() => {
        if(props?.connectedSchool?.logo!=undefined && props?.connectedSchool?.logo?.length!=0){
            setCentralLogo(props?.connectedSchool?.logo)
        }
    }, [props?.connectedSchool])


    return (
        <div>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center ">
                                <span className="H4-Subtitle">{t('saveSchoolLogo')}</span>
                            </div>
                            <div className="col-md-6 upload-image-profile text-center" style={{ paddingTop: "42px" }}>
                                <InputUploadProfileSchool width={240} photo={centralLogo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none" }}>
                <div className="row ">

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedSchool: state.authReducer.connectedSchoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(SchoolPhotoModal);

