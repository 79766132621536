
import { ComponentProps, FC } from "react"
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearAllData, getMyCourses } from "../../../global-state/actions/course-actions";
import { AppState } from "../../../global-state/store/root-reducers";
import "../../../translations/i18n";

const PreviewProgress: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {



    return (
        <>
            <div className="container-fluid " style={{ marginTop: '64px' }}>
                <div className="row">
                    {/* <ProgressBodyContent  /> */}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    myCourses: state.courseReducer.myCourses,
    history: ownProps?.history
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData, getMyCourses }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewProgress);