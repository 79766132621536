import { FC } from "react";
import CustomSwitch from "../../helpers/custom-switch";
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import QuestionPage from './question-page';


const QuestionContainer: FC<any> = (props: any) => {
    return (<>
        <CustomSwitch>
            <RouteWithNavbar exact={true} history={props?.history} path={`${props?.match.path}/all`} component={QuestionPage} />
        </CustomSwitch>
    </>
    )
}
export default QuestionContainer;