import { FC, useEffect, useState } from "react";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import passExamPage from "./pass-exam-page";
import PrivateRoute from "../../navigations/PrivateRoute";


const PassExamContainer: FC<any> = (props: any) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (<>
        <div className="page-wrapper-height">
            <CustomSwitch>
                {isMobile ? <PrivateRoute exact={true} path={`${props?.match.path}/pass/:id`} component={passExamPage} /> : <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/pass/:id`} component={passExamPage} />}
            </CustomSwitch>
        </div>
        <FooterComponent />
    </>
    )
}
export default PassExamContainer;