
import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData, setActualTabManageProgress } from "../../global-state/actions/course-actions";
import { clearLastTrainingId } from "../../global-state/actions/breadcrumb-actions";
import "../../translations/i18n";
import PathProgresscontentComponent from '../../components/path-components/path-progress-content-component';
import SessionProgressContentComponent from '../../components/path-components/session-progress-content-component';
import { useHistory } from "react-router-dom";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import PathCourseSidebarComponent from "../path-components/path-course-components/path-course-sidebar-component";
import CommunityPage from "../../pages/my-courses/progress/community-page";
import ReactPlayer from 'react-player';
import { getCurrentTraining, getCurrentCourse, clearCurrentCourse } from '../../global-state/actions/student-training-actions';


const NavbarResponsiveCourseComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    const [actualTab, setTab] = useState<string>(props?.tabProgressManager);
    const [course, setCourse] = useState<any>({});
    const { t } = useTranslation();

    const studentProgress = useMemo(() => {
        if (props?.follow[0]?.progressTraining?.progress != 0 && props?.follow[0]?.progressTraining?.progress != undefined) {
            return props?.follow[0]?.progressTraining?.progress;
        } else {
            return 0;
        }
    }, [props?.follow[0]?.progressTraining?.progress])    

    useEffect(() => {
        if(props.idCourse){
            props?.getCurrentCourse(Number(props.idCourse));
        }
        if(props.idPath){
            props?.getCurrentTraining(Number(props.idPath))
        }
        if(props?.tabProgressManager === undefined){
            props?.setActualTabManageProgress("previewProgress");
            setTab("previewProgress")
        }
    }, []);

    useEffect(() => {
        setCourse(props?.currentCourse ? props?.currentCourse : props?.currentPath);
    }, [props?.currentCourse, props?.currentPath])

    const setActualTab = (tab: string, where: string, id: Number) => {
        if (id === undefined) { return }
        props?.setActualTabManageProgress(tab);
        setTab(tab);
        if (course?.followUp?.id !== undefined) {
            if(course.type==="COURSE"){
                history.push(`${returnStartPathUrl()}/passCourse/${where}` + id);
            }
            if(course.type==="PATH"){
                history.push(`${returnStartPathUrl()}/passPath/${where}` + id);
            }
            if(course.type==="SESSION"){
                history.push(`${returnStartPathUrl()}/passSession/${where}` + id);
            }
            
        }
    }

    const goBackToList = async () => {
        if (props?.currentPath===undefined || (props?.currentPath?.id === course?.id)){
            history.push(`${returnStartPathUrl()}/courses/my-courses`);
            props?.setActualTabManageProgress("previewProgress");
        }else{
            setCourse(props?.currentPath)
            props?.clearLastTrainingId()
            props?.setActualTabManageProgress("previewProgress");
        }
    }

    return (
        <>
            <div className="container" style={{ background: "white" }}>
                <div className="cours-nav" >


                    <div className="navbar navbar-expand-lg  d-flex justify-content-between p-0 ">
                        <div className="row ">
                            <div className="col-2">
                                <a onClick={() => { goBackToList() }} className="d-flex justify-content-left align-items-center nav-back">
                                    <span className="material-icons-round">
                                        chevron_left
                                    </span>
                                </a>
                            </div>
                            <div className="col-10">
                                <div className="course-title">
                                    {course && course.title}
                                </div>
                                <div className="sub-title">
                                    {course && (course.type === "COURSE" ? (" " + t('courseBy')) : course.type === "PATH" ? (" " + t('pathBy')) : (" " + t('sessionBy'))) + " " + course.personName}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row " style={{ height: "45px" }}>
                        <div className="col-6 border-btn-chapter d-flex justify-content-center align-items-center" >
                            <a onClick={() => { setActualTab('previewProgress', 'course/', course?.followUp && course?.followUp?.id) }} >
                                <span className={(actualTab === 'previewProgress') ? "nav-btn-chapter" : "nav-btn-chapter-active"}>
                                    {course && (course.type === "COURSE" ? (" " + t('Course')) : course.type === "PATH" ? (" " + t('Parcours')) : (" " + t('Session')))}

                                </span>
                            </a>
                        </div>
                        <div className="col-6 border-btn-chapter d-flex justify-content-center align-items-center" >
                            <a onClick={() => { setActualTab('community', "community/", course?.followUp && course?.followUp?.id) }} >
                                <span className={(actualTab === 'community') ? "nav-btn-chapter" : "nav-btn-chapter-active"}>
                                    {t('community')}
                                </span>
                            </a>
                        </div>

                    </div>
                    {(actualTab !== 'community') && <div className="row">
                        {(course?.video !== "" && course?.video?.length !== 0 && course?.video?.length !== null && course?.video?.length !== undefined) ?
                            <div className='img-more-info custom-video-youtube' style={{ width: "100%", height: "254px", }}>
                                <ReactPlayer
                                    controls={true}
                                    config={{
                                        vimeo: {
                                            playerOptions: {
                                                responsive: true,
                                            },
                                        },
                                        youtube: {
                                            embedOptions: {
                                                height: '500px',
                                            }
                                        },
                                        dailymotion: {}
                                    }}
                                    width='100%'
                                    height={"254px"}
                                    stopOnUnmount={false}
                                    url={course?.video} />
                            </div> :
                            <img src={course && course.linkImg} alt="Koors" style={{ objectFit: "cover", padding: "0", height: "20vh" }}></img>
                        }

                    </div>}
                    {(actualTab !== 'community') && (studentProgress <= 100) && 
                        <div style={{ position: "relative", top: "-1.5vh" }}>
                            <div className="d-flex flex-row align-items-center justify-content-between">
                                <div className="progress-bar-bg w-100" style={{ height: "5px" }}>
                                    <div className="progress-bar-bg success" role="progressbar" style={{ width: props?.currentCourse ? props?.currentCourse?.followUp?.progressTraining?.progress + "%" : studentProgress + "%", height: "5px" }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <span className="H3-Headline ms-2"><span className="body-sm bold black-800"></span></span>
                            </div>
                            <span className="body-sm bold " style={{ float: "right", position: "relative", top: "-3.5vh", fontSize: "10px", color: "white" }}>{props?.currentCourse ? props?.currentCourse?.followUp?.progressTraining?.lastProgression : props?.follow[0]?.progressTraining?.lastProgression}<span className="neutral-3">/{props?.currentCourse ? props?.currentCourse?.orders?.length : props?.currentPath?.orders?.length}  {t('completedParts')}</span></span>
                            <span className="body-sm bold " style={{ float: "left", position: "relative", top: "-3.5vh", fontSize: "14px", color: "white" }}>{props?.currentCourse ? props?.currentCourse?.followUp?.progressTraining?.progress : props?.follow[0]?.progressTraining?.progress}%</span>
                        </div> 
                    }
                </div>
                <div className="">
                    {actualTab === 'previewProgress' ? 
                            (course?.type === "COURSE" ? <PathCourseSidebarComponent isMobile={true} courseSections={props.sections}/> : course?.type === "PATH" ? 
                            <>
                                <div className="d-flex justify-content-end">
                                    <div className="" style={{position: "relative", top: "50px",fontSize: "20px", fontWeight: "bold", }}>
                                        {"Contenu du Parcours"}
                                    </div>
                                </div>
                                <div>
                                    <PathProgresscontentComponent isMobile={true} />
                                </div>
                                
                            </> : 
                            course?.type === "SESSION" ?
                            <>
                                <div className="d-flex justify-content-end">
                                    <div className="" style={{ position: "relative", top: "50px", fontSize: "20px", fontWeight: "bold" }}>
                                        {"Contenu de la session"}
                                    </div>
                                </div>
                                <SessionProgressContentComponent isMobile={true} />
                            </>:
                            <PathCourseSidebarComponent isMobile={true} courseSections={props.sections} />) 
                    : <><CommunityPage isMobile={true} /></>
                    }

                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    tabProgressManager: state.courseReducer.tabProgressManager,
    currentCourse: state.studentTraining.currentCourse,
    currentPath: state.studentTraining.currentTraining,
    history: ownProps?.history,
    currentProgress: state.studentTraining.currentProgress,
    type: ownProps?.type,
    follow: state.studentTraining.listFollowUps,
    subscription: state.schoolInfoReducer.subscription,
    sections: state.studentTraining.sections,
    lastTrainingId: state.historyReducer.lastTrainingId,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData, setActualTabManageProgress, clearLastTrainingId, getCurrentTraining, getCurrentCourse, clearCurrentCourse }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavbarResponsiveCourseComponent);

