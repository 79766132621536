import diplomaIcon from '../../styles/imgs/diploma.svg';

function TagDiploma({ type }:any) {

  const checkType = type === 'PATH' ? 'Parcours' : type === 'SESSION' ? 'Session' : 'Cours' 
  
  return (
    <div className="diploma-tag" style={{ margin: "14px 0 12px 0" }}>
      <span className="icon" style={{ marginRight: "5px" }}>
        <img src={diplomaIcon} alt="" style={{ marginBottom: "4px" }} />
      </span>
      <span className="body-sm bold">{checkType} diplômant</span>
    </div>
  );
}

export default TagDiploma;
