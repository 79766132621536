import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { saveReturnPath, redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { createFollow, getRegistrationForm, getApplicationFile, clearApplicationFile, } from '../../global-state/actions/course-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
const HowToWorkSectionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [courseForm, setCourseForm] = useState<any>(null);
  const show = useMemo(() => { return props?.show }, [props?.show]);
  const history = useHistory();
  const { t } = useTranslation();
  const [courseFormType,setCourseFormType]=useState<any>()

  useEffect(() => {
    if (props?.follow && props?.follow.id) {
      history.push(`${returnStartPathUrl()}/courses/details/${props?.follow.id}`);
    }
  }, [props?.follow]);
  useEffect(() => {
    if (props?.courseForm) {
      setCourseForm(props?.courseForm);
      let titleCourse=(props?.courseForm?.type === 'PATH') ? 'registerPath' : 'registerSession'
      setCourseFormType(t((props?.courseForm?.type === 'COURSE') ? 'registerCourse' : titleCourse))
    }


  }, [props?.courseForm]);
  const followTraining = async (type:string) => {
    if (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
      if ((courseForm?.idRegistrationForm === 0 || courseForm?.idRegistrationForm === null || courseForm?.idRegistrationForm === undefined) && (props?.price?.price === 0 || props?.price === null)){
   
          let follow = {
              studentId: props?.connectedUser?.idPerson,
              trainingId: courseForm.id,
              trainingType: courseForm.type,
              entityId: courseForm.entityId,
              entityType: courseForm.entityType,
              statusList: [{
                  status: "INSCRIBED"
              }]
          }
          await props?.createFollow(follow);
      } else if((courseForm?.idRegistrationForm === 0 || courseForm?.idRegistrationForm === null || courseForm?.idRegistrationForm === undefined) && (props?.price?.price !== 0 || props?.price !== null)) {     
          
          props?.clearHistoryArray()
          props?.redirect(props?.courseForm?.title, "")
          props?.redirect(t("paiement"), "")
          history.push(`${returnStartPathUrl()}/courses/payement/${type}/${courseForm?.id}`)
          
      }
      else if( (courseForm?.idRegistrationForm !== 0 && courseForm?.idRegistrationForm !== null && courseForm?.idRegistrationForm !== undefined) ||
      ((courseForm?.idRegistrationForm !== 0 && courseForm?.idRegistrationForm !== null && courseForm?.idRegistrationForm !== undefined)&& (props?.price?.price !== 0 || props?.price !== null))){

          await props?.getApplicationFile(props?.connectedUser?.idPerson, props?.registration?.id);
          await props?.getRegistrationForm(props?.registration?.id);
          props?.redirect(t('Subscription'), `${returnStartPathUrl()}/catalog/inscription/${courseForm?.type?.toLowerCase()}/${courseForm?.idRegistrationForm}`)
          history.push(`${returnStartPathUrl()}/catalog/inscription/${courseForm?.type?.toLowerCase()}/${courseForm?.idRegistrationForm}`);
      }
      
  }

  else {
      props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/course/${courseForm?.id}`, tabPage: '' });
      history.push(`${returnStartPathUrl()}/auth/login`);
  }

}

const getButtonwordingFN = (typeInput: string) => {
  switch (typeInput) {
      case 'course':
          return t('followCourse');
      case 'path':
          return t('followPath');
      default:
          return t('followSession')
  }

}

  return <>
    <section style={{ backgroundColor: '#FFFFFF' }}>

      <div className="py-5 max-width" style={{ margin: 'auto', minHeight: 250 }}>
        <div className="d-flex align-items-center justify-content-center text-center mb-5" >
          <h2 className="H3-Headline black-800"> {t('howItWorks')}</h2>
        </div>
        <div className={"d-sm-flex align-items-center justify-content-around"}>
          <div className="text-center px-4 max-width-how-it-works" >
            <h3 className="H4-Subtitle black-800">{(props?.courseForm?.type === 'COURSE')?t('registerCourse'): (props?.courseForm?.type === 'PATH')?t('registerPath'): t('registerSession')}</h3>
            <p className="body-md neutral-2 mt-3">
              {t('previewText_1')}
            </p>
          </div>
          <div className="justify-content-center text-center" style={{ margin: 'auto' }}>
            <span className="material-icons-outlined rotate-how-it-works">arrow_right_alt</span>
          </div>
          <div className=" text-center px-4 max-width-how-it-works">
            <h3 className="H4-Subtitle black-800">{t('progressView')}</h3>
            <p className="body-md neutral-2 mt-3">
              {t('previewText_2')}
            </p>
          </div>
          <div className="justify-content-center text-center" style={{ margin: 'auto' }}>
            <span className="material-icons-outlined rotate-how-it-works">arrow_right_alt</span>
          </div>
          <div className="text-center px-4 max-width-how-it-works">
            <h3 className="H4-Subtitle black-800">{t('testConn_2')}</h3>
            <p className="body-md neutral-2 mt-3">
              {t('previewText_3')}
            </p>
          </div>
        </div>


      </div>

    </section>
  </>
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  redirect, clearHistoryArray, 
  saveReturnPath, createFollow, getRegistrationForm, getApplicationFile, clearApplicationFile,
}, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
  objectives: ownProps?.objectives,
  prerequisite: ownProps?.prerequisite,
  connectedUser: state.authReducer.connectedUser,
  follow: state.courseReducer.follow,
  registration: state.courseReducer.registration,
  courseForm: ownProps?.courseForm,
  price: ownProps?.price,
  disabled: ownProps?.disabled,
  show: ownProps?.show,
  type: ownProps?.type
});

export default connect(mapStateToProps, mapDispatchToProps)(HowToWorkSectionComponent);