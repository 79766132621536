import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { openModal, closeModal} from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import axiosInstance from '../../helpers/interceptors';
import { getSchoolInfo } from '../../global-state/actions/app-container-actions';
import { getCodeFromUrl } from '../../helpers/domainCheck';
import { filterStudentInGroup } from '../../services/groupe.student.service';
interface Group {
 
  studentName:string;
  inscritLe :string;
  lastConnexion:string ;

}

const FilterGroupPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const[groupId,setGroupId]= useState(props?.group?.id);
    const [searchValue, setSearchValue]=useState<string>("")
    
    const idsGroup= useMemo(()=>{
        
        if(props?.tabGroupManager==="all"){
            return[props?.group?.id, props?.group?.childsList?.map((subGroup:any)=>{ return subGroup?.id})].flat(Infinity)
        }else {
            return [props?.groupList.find((group:any)=>group?.label===props?.tabGroupManager)?.id]
        }
    },[props?.group,props?.tabGroupManager])
    const openModalFn=()=>{
     
      props?.openModal({idModal: ModalIDS.ADD_STUDENTS_TO_GROUP, size: "lg", scroll: 'body',data:{groupForm:props?.group}})
       
    }
    useEffect(() => { 
        async function fetchMyAPI() {
            let code = getCodeFromUrl()
            
            await props?.getSchoolInfo(code)
            
          }
      
          fetchMyAPI()
     
    }, []);
    useEffect(() => { 
        setSearchValue("")
       if(props?.tabGroupManager=="all")
       {setGroupId(props?.group?.id)}
       else{
  setGroupId( props?.groupList.find((groupPr:any)=>groupPr?.label===props?.tabGroupManager)?.id)
   
       }
    }, [props?.group,props?.tabGroupManager,props?.groupList]);


    const genericExcel = () => {
   
        const name = t('ListStudents')+" "+ props?.schoolInfo?.code
        const lang = localStorage.getItem("language")
        const baseUrl1 = process.env.REACT_APP_BASE_URL2;
        axiosInstance({
          url: baseUrl1 + "group/export/" + props?.schoolInfo.id+"/"+name+"/"+lang+"/"+groupId,
          method: 'POST',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const linked = document.createElement('a');
          linked.href = url;
          linked.setAttribute('download', name+'.csv');
          document.body.appendChild(linked);
          linked.click();
        })
      }
        const filterListText = (value:string)=>{
            if (value.length>3 ||value.length===0){
            
            props?.isSearch(true)
             let filter={
                 ids:idsGroup,
                 search:value
             }
            filterStudentInGroup(filter,props?.schoolInfo.id).then((response:any)=>{
              if(response){
                props.filterStudents(response);
              }
            }) .catch((error: any) => {
                console.error('Error filtering students:', error);
            });
            }
        }
    return (
        <>
            <div className=" mt-5  mx-auto " style={{ maxWidth: '1200px' }}>
                <div className=" d-flex align-items-center justify-content-between flex-wrap " >
                    <div className="d-flex align-items-center">
                        {(props?.tabGroupManager!== "all" || (props?.tabGroupManager=== "all" && props?.group?.childsList?.length===0 ))&& <button type="button" className="btn-Primary large icon-left me-2" onClick={()=>{openModalFn()}}>
                            <span className="material-icons-outlined">add</span>
                            <div className="m-auto ps-2">{t('addStudentToGroup')}</div>
                        </button>}
                        <button type="button" className="btn-Secondary large icon-left"  onClick={() => genericExcel()}>
                            <span className="material-icons-outlined" >download</span>
                            <div className="m-auto ps-2">{t('exportListe')}</div>
                        </button>
                    </div>
                    <div className="d-flex align-items-center">
                    <div className="input-form-control" style={{ width: '220px' }}>
                        <input dir="ltr"type="text" className="form-control input text-default"  value={searchValue} placeholder={t('searchProfessors')} onChange={(e: any) => { filterListText(e.target.value);setSearchValue(e.target.value) }}/>
                        <span className="material-icons-outlined">search</span>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps:any) => ({
    schoolInfo :state.schoolInfoReducer.schoolInfo,
    isSearch: ownProps?.isSearch
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        openModal,
        closeModal,
        getSchoolInfo,

    }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FilterGroupPageComponent)
