import moment from "moment";
import crypto from "crypto";
export function couponStatus(startDate: any, endDate: any) {
	const curentDate = moment();
	if (curentDate.isAfter(startDate) && curentDate.isBefore(endDate)) {
		return "En cours";
	}
    if (curentDate.isAfter(startDate) && endDate === null) {
		return "En cours";
	}
	if (curentDate.isAfter(startDate) && curentDate.isAfter(endDate)) {
		return "Expiré";
	}

	return "Planifié";
}

export function couponType(promoType: any) {
	if (promoType === "CASHBACK") {
		return "reduce_amount";
	}else if (promoType === "DISCOUNT"){
        return "reduce_discount";
    }
}

export const generatedCode = () => {
	const randomString = crypto.randomBytes(4).toString("hex");
	return randomString;
  };
