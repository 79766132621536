import StepUserGuide from "../components/modals/modal-user-guide/step-user-guide";
import hand_pointing_up from '../styles/imgs/user-guide/hand_pointing_up.svg';
import hand_pointing_right from '../styles/imgs/user-guide/hand_pointing_right.svg';
import man_teacher from '../styles/imgs/user-guide/man_teacher.svg';
import student from '../styles/imgs/user-guide/student.svg';
import books from '../styles/imgs/user-guide/books.svg';
import bookmark_tabs from '../styles/imgs/user-guide/bookmark_tabs.svg';
import memo from '../styles/imgs/user-guide/memo.svg';
import party_popper from '../styles/imgs/user-guide/party_popper.svg';

export const mySteps: any = [
  {
    target: "#step-team-navbar_director",
    locale: { skip: <span>Passer</span> },
    content: (
      
      <StepUserGuide
        icon={hand_pointing_up}
        title="Coup d’oeil à votre équipe"
        body={() => ( 
          <div>
            Commencez par <strong>gérer</strong> les membres de votre équipe.
          </div>
        )}
        steps={() => (
          <span>
            {2} sur {16}
          </span>
        )}
      />
    ),
    disableBeacon: true,
    floaterProps: { disableFlip: true,},
    placement: "bottom-end",
  },
  {
    target: "#step-team-page_director",
    content: (
      <StepUserGuide
        icon={man_teacher}
        title="Gérez ici votre équipe"
        body={() => (
          <div>
            Vous pouvez <strong>inviter, filtrer</strong> et{" "}
            <strong>exporter</strong> la liste de vos professeurs et de vos
            administrateurs.
          </div>
        )}
        steps={() => (
          <span>
            {3} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
  },
  {
    target: "#step-Etudiants-navbar_director",
    content: (
      <StepUserGuide
        icon={hand_pointing_up}
        title="Vos étudiants c’est par ici"
        body={() => <div>Passons maintenant à la gestion de vos étudiants</div>}
        steps={() => (
          <span>
            {4} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom-end",
  },
  {
    target: "#step-student-page_director",
    content: (
      <StepUserGuide
        icon={student}
        title="Découvrez ici vos étudiants"
        body={() => (
          <div>
            <strong>Ajoutez, suivez</strong> et <strong>groupez</strong> vos
            étudiants en temps réel selon leurs statuts dans l’école
          </div>
        )}
        steps={() => (
          <span>
            {5} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
  },
  {
    target: "#step-TitleFormations-navbar_director",
    content: (
      <StepUserGuide
        icon={hand_pointing_up}
        title="À vous de valider les formations"
        body={() => (
          <div>Consultez ici les formations proposées par vos professeurs.</div>
        )}
        steps={() => (
          <span>
            {6} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom-end",
  },
  {
    target: "#step-training-page_director",
    content: (
      <StepUserGuide
        icon={books}
        title="Gérez vos formations ici"
        body={() => (
          <div>
            <strong>Validez</strong> et <strong>mettez</strong> en ligne les
            formations dans votre école. <br />
            Découvrez comment <strong>créer</strong> des formations en passant à
            l’étape suivante.
          </div>
        )}
        steps={() => (
          <span>
            {7} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
  },
  {
    target: "#step-change-role_director",
    content: (
      <StepUserGuide
        icon={hand_pointing_right}
        title="Changez de rôle en cliquant ici"
        body={() => (
          <div>
            Vous pouvez ici changer votre rôle en tant que{" "}
            <strong>professeur</strong> pour découvrir le reste des
            fonctionnalités.
          </div>
        )}
        steps={() => (
          <span>
            {8} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#step-go-to-professor-from-director",
    content: (
      <StepUserGuide
        icon={hand_pointing_right}
        title="Allez dans professeur"
        body={() => (
          <div>Découvrons ensemble votre interface en tant que professeur.</div>
        )}
        steps={() => (
          <span>
            {9} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "left-start",
  },
  {
    target: "#step-TitleFormations-navbar_prof",
    content: (
      <StepUserGuide
        icon={hand_pointing_up}
        title="Créez ici vos formations"
        body={() => (
          <div><strong>Composez</strong> plusieurs types de formation en toute simplicité</div>
        )}
        steps={() => (
          <span>
            {10} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom-end",
  },
  {
    target: "#step-training-page_prof",
    content: (
      <StepUserGuide
        icon={books}
        title="Voici la liste des formations"
        body={() => (
          <div><strong>Ajoutez</strong> des <strong>cours</strong>, des <strong>parcours</strong> et des <strong>sessions</strong> en intégrant des présentations, des vidéos et des fichiers.</div>
        )}
        steps={() => (
          <span>
            {11} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "left",
  },
  {
    target: "#step-Examens-navbar_prof",
    content: (
      <StepUserGuide
        icon={hand_pointing_up}
        title="Notez ici vos examens"
        body={() => (
          <div>Vous pouvez corriger les examens de vos étudiants et consultez les résultats de leurs tests de connaissances.</div>
        )}
        steps={() => (
          <span>
            {12} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom-end",
  },
  {
    target: "#step-examen-page_prof",
    content: (
      <StepUserGuide
        icon={bookmark_tabs}
        title="Corrigez vos examens"
        body={() => (
          <div>Notez en quelques clics les examens.</div>
        )}
        steps={() => (
          <span>
            {13} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: "#step-Questions-navbar_prof",
    content: (
      <StepUserGuide
        icon={hand_pointing_up}
        title="Consultez les questions ici"
        body={() => (
          <div><strong>Répondez</strong> aux questions posées par vos étudiants.</div>
        )}
        steps={() => (
          <span>
            {14} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom-end",
  },
  {
    target: "#step-question-page_prof",
    content: (
      <StepUserGuide
        icon={memo}
        title="Interagissez avec vos étudiants"
        body={() => (
          <div>Soyez à jour avec vos étudiants en traitant <strong>leurs questions</strong> et <strong>leurs commentaires.</strong></div>
        )}
        steps={() => (
          <span>
            {15} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: "#step-last-question-page_prof",
    content: (
      <StepUserGuide
        icon={party_popper}
        title="Vous êtes maintenant prêt !"
        body={() => (
          <div><strong>Félicitations!</strong> Vous avez maintenant exploré toutes les principales fonctionnalités de Koors.</div>
        )}
        steps={() => (
          <span>
            {16} sur {16}
          </span>
        )}
      />
    ),
    locale: { skip: <span>Passer</span> },
    disableBeacon: true,
    placement: "bottom",
  },
  
];
