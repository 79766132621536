import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { getCourse, getCourseTests, getRegistrationForm } from '../../global-state/actions/course-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { useHistory } from 'react-router';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import FilterTeamListComponent from '../filters/FilterListTeamComponent';
import TableTeamWithActions from '../../elements/table/table-team-with-actions';
import TeamTableAction from '../../elements/table-actions/team-table-action';
import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import "../../styles/koors.scss"
import { returnStartPathUrl } from '../../helpers/domainCheck';


const TableAllTeamListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const dateFormat = require('dateformat');
    const { t } = useTranslation();
    const history = useHistory();
    const [teamList, setTeamList] = useState<any>([])
    const [filteredTeam, setfilteredTeam] = useState<any>();
    const [activateSnakbar, setActivateSnakbar] = useState<boolean>(false)
    const [teamSize, setTeamSize] = useState<number>(0);
    const headers = [
        { title: t('team'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('role'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const status = [
        { label: t('Enattente'), value: "Enattente", color: "warning" },
        { label: t('Admis'), value: "Admis", color: "success" },
        { label: t('Suspendu'), value: "Suspendu", color: "danger" },
    ]
    function doFilter (value: string) {
        let copyTeamList = { ...teamList };        
        let actualStatus: any = status.find((e: any) => e?.value === value)?.value;
        copyTeamList.body = copyTeamList?.body?.filter((assistant: any) => {

            return (value !== "" ? assistant.row.columns[4]?.content?.props?.status?.includes(t(actualStatus)) : true)
        });

        setfilteredTeam(copyTeamList);
    }
    const doAction = async (value: any) => {
        let type = ""

        if (props?.assistants?.findIndex((obj: any) => value.row?.team?.id === obj?.id) > -1) {
            type = "ADMINSTRATOR"
        } else {
            type = "prof"
        }

        if (type === "ADMINSTRATOR") {
            props?.clearHistoryArray();
            props?.redirect(t("schoolAssistant"), `${returnStartPathUrl()}/professors/all`);
            props?.redirect(value?.row?.team?.name, `${returnStartPathUrl()}/professors/infoteam/${value?.row?.team?.id}`);
             history.push(`${returnStartPathUrl()}/professors/infoteam/${value.row.team.id}`);
        } else {
            props?.clearHistoryArray();
            props?.redirect(t("Teachers"), `${returnStartPathUrl()}/professors/all`);
            props?.redirect(value?.row?.team?.name, `${returnStartPathUrl()}/professors/infoteam/${value?.row?.team?.id}`);
             history.push(`${returnStartPathUrl()}/professors/infoteam/${value?.row?.team?.id}`);
        }
    }


    const activateSnakBar = ()=>{
        setActivateSnakbar(true)
    }


    useEffect(() => {
        setTeamSize(props?.teamSize)
    }, [props?.teamSize])

    useEffect(() => {

        setTeamList(props?.teamList)
    }, [props?.teamList])

    useEffect(() => {
        setTeamSize(props?.teamSize)
        setTeamList(props?.teamList)
    }, [])
    useEffect(() => {
        
        const teamList = {
            headers: headers,
            body: props?.teamList?.map((team: any) => {
                const actualStatus: any = status.find(obj => obj.value.toLowerCase() === team.status.toLowerCase());
                return {
                    row: {
                        team: team,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: team?.name, classNames: "", actionName: '' },
                            { content: team?.role==="PROF"?t('Professeur_1'):t('schoolAssistant'), classNames: "text-center", actionName: '' },
                            { content: team?.inscription === null ? "-" : dateFormat(team?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: team?.lastVisit === "-" ? "-" : team?.lastVisit + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
                            { content:< TeamTableAction status={actualStatus?.label}  color={actualStatus?.color}  id={team?.id} role={team?.role} activateSnakBar={activateSnakBar}/> , classNames: `text-end with-action-edit`, actionName: '' },
                        ]
                    }
                }
            })
        }
        
        setTeamList(teamList);
        setfilteredTeam(teamList);
    }, [props?.teamList]);



    return (
        <>
        <div className="container-fluid m-0 p-0 mx-auto" style={{ maxWidth: '1200px' }}>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    {props?.actualTab === 'all' && props?.teamSize > 0 && <FilterTeamListComponent doFilter={doFilter} actualTab="All"/>}
                </div>
                <div className="col-md-12 p-0 m-0 mt-5">
                    {props?.teamSize > 0 ? <>
                        <TableTeamWithActions 
                            height={10} 
                            data={filteredTeam} 
                            doAction={(value: any) => { doAction(value) }} 
                            actualTab={props?.actualTab} 
                        />
                    </> : <>
                        <div className="w-100">
                            <div className='wrapper-empty-list mt-5 pt-5'>
                                <EmptyListElement lines={[t('noTeamDefault_1'), t('noTeamDefault_2')]} />
                                {props?.inviteMember()}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
        <Snackbar open={activateSnakbar} autoHideDuration={6000}  onClose={()=>{setActivateSnakbar(!activateSnakbar)}}>
        <Alert style={{backgroundColor:"#272727"}}>
                <div className='d-flex flex-row align-item-center'>
                <span className='body-md' style={{color:"#FFFFFF" , paddingRight:"98px"}} > {t('invitationSentSuccesfully')}</span>
                <Button color="inherit" size="small" onClick={()=>{setActivateSnakbar(!activateSnakbar)}}> 
                    <CloseIcon  style={{color:"#FFFFFF"}}/>
                </Button>
                </div>
        </Alert>
    </Snackbar>
    </>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    teamList: state.profReducer.teamList,
    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    teamSize: state.profReducer.teamSize,
    assistants: state.profReducer.assistants
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { getCourse, getCourseTests, getRegistrationForm, redirect, clearHistoryArray }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableAllTeamListComponent);
