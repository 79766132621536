
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import { goToRedirection} from '../../global-state/actions/breadcrumb-actions';
import { checkExamProgressStatus, setSudentMark } from '../../global-state/actions/exam-actions';
import { checkProgressCourse, calculateProgressTraining } from '../../global-state/actions/student-training-actions';
import { AppState } from '../../global-state/store/root-reducers';
import SelectElement from '../../elements/select/select-element';
import TextareaUnderlineElement from '../../elements/text-area/text-area-underline-element';
import { useHistory } from 'react-router-dom';
import { returnStartPathUrl } from '../../helpers/domainCheck';


const OpenQuestionNotationpage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory()
    const questionToCorrect = useMemo(() => { return props?.location.state.obj }, [props?.location.state.obj]);
    const [comment, setComment] = useState<any>(props?.location?.state?.obj?.teacherComment !=null ? props?.location?.state?.obj?.teacherComment : "");
    const [idAnswer,setIdAnswer] = useState<number>(props?.history?.location?.state?.obj?.id);
    const [mark, setMark] = useState<any>("");
    const examForm = useMemo(() => {return props?.examForm }, [props?.examForm])
    const [filterOption, setFilterOption] = useState<any>();
    const scale = useMemo(() => {return props?.location?.state?.obj?.questionByQna?.scaleNote }, [props?.location?.state?.obj?.questionByQna?.scaleNote])
    const [clicked, setClicked] = useState<boolean>(false);

    useEffect(() => {
        if (props?.history?.location?.state?.obj?.questionMark != null) {
            setMark(props?.history?.location?.state?.obj?.questionMark)
        }
    }, [])

    useEffect(() => {
        if(props?.history?.location?.state?.obj?.id!==undefined){
            setIdAnswer(props?.history?.location?.state?.obj?.id)
        }
    }, [props?.history?.location?.state?.obj?.id])
    


    const doFilter = (value: string) => {
        if (value != null || value !== undefined) {
            setMark(Number(value));

        }
    }

    useEffect(() => {
        setClicked(false)

        const liste = [];
        for (let i = 0; i < scale + 1; i++) {
            liste.push({ value: i.toString(), label: i + '/' + scale });
        }
        setFilterOption(liste);
    }, [])
    
    useEffect(() => {
        if (clicked) {
            redirectToBack()
            setClicked(false)
            
        }
    }, [clicked])

    const redirectToBack = async()=>{
        const newArrayHistory = props?.historyArray.slice(0,props?.historyArray.length-1)   
        props?.goToRedirection(newArrayHistory)
        history.push(`${returnStartPathUrl()}/examsDetails/submission/${props?.actualTab}/${examForm?.progressQna?.id}`);
    }

    const setMarkForStudent = async () => {

        await props?.setSudentMark(idAnswer, mark, comment, examForm, examForm?.progressQna?.status , examForm?.progressQna?.idQna);
        if(examForm?.progressQna?.status ==="TOREVIEW") {
        await props?.checkExamProgressStatus(examForm?.progressQna?.id, examForm?.idExam);
        if (examForm?.entityType === "COURSE" && examForm?.trainingName === null) {
            if (props?.examForm?.follow && props?.examForm?.follow?.id) {
                await props?.checkProgressCourse(props?.examForm?.follow, props?.examForm?.entityId, props?.examForm?.studentId, true , props?.currentCourse);
            }
        } else {
            if (examForm?.entityType === "COURSE") {
                if (props?.examForm?.follow && props?.examForm?.follow?.id) {
                    await props?.checkProgressCourse(props?.examForm?.follow, props?.examForm?.entityId, props?.examForm?.studentId, true , props?.currentCourse);
                }
            }
            await props?.calculateProgressTraining({ type: (examForm?.idFollowParent === null) ? examForm?.entityType : examForm?.trainingType, followUp: { id: (examForm?.idFollowParent === null) ? examForm?.follow?.id : examForm?.idFollowParent } });

        }
    }
         setClicked(true);
    }



    


    return (
        <div style={{ backgroundColor: "#F2F2F5" }}>
            <div className="d-flex flex-column justify-content-between" style={{ position: "fixed", top: 64, width: "100%", zIndex: 1000 }}>
                <div className="px-2" style={{ backgroundColor: "#FFFFFF" }} >
                    <div style={{ maxWidth: "1200px", margin: 'auto' }}>
                        <div>
                            <h2 className="H2-Headline Black-800" style={{ paddingTop: "60px" }}> {t("question")} {props?.location.state.i + 1}</h2>
                        </div>
                        <div className="pb-4">
                            <h3 className="H3-Headline neutral-3">{questionToCorrect?.questionByQna?.question?.questionStatement}</h3>
                        </div>
                    </div>
                </div>
            </div >
            <div className="d-flex flex-column " style={{ paddingTop: 276, minHeight: 'calc(100vh - 80px)' }}>
                <div className="mx-auto" style={{ backgroundColor: "#FFFFFF", maxWidth: 792, width: "100%" }}>
                    <div className="d-flex align-items-center justify-content-between mt-5 mx-5 mb-4">
                        <h3 className="H3-Headline black-800 mt-3 w-100 first-letter-capitalize"> {t("NOTER_LA_QUESTION")}</h3>
                        <div style={{ width: 220 }}>
                            <SelectElement disabled={false} defaultValue={mark != null ? mark.toString() : "..."} options={filterOption} placeholder={".../" + scale} onChange={(e: any) => { doFilter(e) }} />
                        </div>
                    </div>
                    <span className="mx-5 H4-Subtitle black-800" >{t("Ajouter_un_commentaire")}</span>
                    <div className="py-3 mx-5" >
                        <TextareaUnderlineElement id="resume" value={comment} className="textarea-input" onChange={(e: string) => setComment(e)} placeholder={"Pas de commentaire..."} style={{ maxHeight: "28px" }} />
                    </div>
                    <div className="d-flex align-items-center justify-content-end mx-5" style={{ paddingBottom: "40px", paddingTop: "29px" }}>
                        <button type="button" className="btn-Secondary large" onClick={() => setMarkForStudent()}>
                            {t("VALIDER_MA_NOTATION")}
                        </button>
                    </div>
                </div>
                <div className="w-100 mx-auto" style={{ backgroundColor: "#F2F2F5", paddingTop: 40, paddingBottom: "56px", maxWidth: 792 }}>
                    <div dangerouslySetInnerHTML={{ __html: questionToCorrect?.studentOpenQuestionSubmission }}></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    examForm: state.examReducer.examForm,
    connectedUserDetails: state.authReducer.connectedUser,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    historyArray: state.historyReducer.history,
    history: ownProps?.history,
    actualTab: state.examReducer.actualTab,
    indexExamForm: state.examReducer.indexExamForm
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            goToRedirection,
            setSudentMark,
            checkExamProgressStatus,
            checkProgressCourse,
            calculateProgressTraining,
        },
        dispatch
    );


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OpenQuestionNotationpage);