import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { setActualTabManageQuestion, getFilteredQuestion, getQuestionByType } from '../../global-state/actions/chat-actions';
import { getMyCoursesProfessor, getMyCourses} from '../../global-state/actions/course-actions';
import ManageQuestionComponent from '../../components/questions/manage-question-component';
import ListCardQuestionProfessor from '../../components/questions/list-card-question-professor';
import ManageQuestionProfessorLoadingComponent from '../../components/questions/manage-question-professor-loading-component';
import QuestionCardProfessorLoading from '../../components/questions/question-card/question-card-professor-loading';
let outsideTab = "";
let outsidepageAll = 0;
let outsidepageSettled = 0;
let outsidepageUnsettled = 0;
let outsideTrie="";
let outsideFiltre="";
let outsidesearchFilter="";
let outsideAtBottom=false;
const QuestionPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const [actualTab, setActualTab] = useState<string>("")
    const [trie, setTrie] = useState("recent");
    const [filtre, setFiltre] = useState("all");
    const [doSearch, setDoSearch] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");
    const [pageUnsettled, setPageUnsettled] = useState(0);
    const [pageSettled, setPageSettled] = useState(0);
    const [pageAll, setPageAll] = useState(0);
    const [atBottom, setAtBottom] = useState(false);
    const [loading, setLoading] = useState(true);
    outsideTab = actualTab;
    outsidepageAll = pageAll;
    outsidepageSettled = pageSettled;
    outsidepageUnsettled = pageUnsettled;
    outsideTrie=trie;
    outsideFiltre=filtre;
    outsidesearchFilter=searchFilter;
    outsideAtBottom = atBottom;

    useEffect(() => {
        setActualTab(props?.tabQuestionManager);
    }, [props?.tabQuestionManager])

    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);

    useEffect(()=>{
        if(searchFilter===""){
            setDoSearch(false)
        }
    },[searchFilter])

    const onInit = async () => {
        let filter = {
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL",
            idProf: props?.connectedUser?.idPerson,
        }
        props?.getMyCourses(filter)
        window.addEventListener('scroll', handleScroll, { passive: true });
        props?.getQuestionByType(props?.connectedUser?.idPerson, props?.connectedUser?.idSchool,"SCHOOL", true)
        setLoading(false)
    }

    useEffect(() => {
        onInit()
        return () => {
            window.removeEventListener('scroll', handleScroll);
            props?.setActualTabManageQuestion("unsettled");
            setTrie("recent");
            setFiltre("all");
            setDoSearch(false);
            setSearchFilter("");
            setPageUnsettled(0);
            setPageSettled(0);
            setPageAll(0);

        }
    }, [])

    const Search = (doSearchInput: any) => {
       
        changeList(doSearchInput.trie, doSearchInput.filtre, doSearchInput.searchFilter, 0, true);
        switch (outsideTab) {
            case 'unsettled':
                setPageUnsettled(0);
                break;
            case 'settled':
                setPageSettled(0);
                break;
            case 'all':
                setPageAll(0);
                break;
        }
        setTrie(doSearchInput.trie);
        setFiltre(doSearchInput.filtre);
        setSearchFilter(doSearchInput.searchFilter)
        setDoSearch(true);
    }
    const handleScroll = (event: any) => {
        if (!(outsideAtBottom) && isBottom()) {
            setAtBottom(true);
            changeList(outsideTrie, outsideFiltre, outsidesearchFilter, (outsideTab === 'unsettled') ? outsidepageUnsettled + 1 : (outsideTab === 'settled') ? outsidepageSettled + 1 : outsidepageAll + 1, false);
            if (outsideTab === 'unsettled') {
                setPageUnsettled(outsidepageUnsettled + 1)
            } else if (outsideTab === 'settled') {
                setPageSettled(outsidepageSettled + 1)
            } else {
                setPageAll(outsidepageAll + 1)
            }
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }
    const isBottom = () => {
        return ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
    }
    const changeList = (trieInput: string, filter: string, searchFilterInput: string, page: number, isSearch: boolean) => {
        if (filter === "all") {
            props?.getFilteredQuestion(props?.connectedUser?.idPerson, props?.connectedUser?.idSchool, { idCours: null, type: null ,trie: trieInput, actualTab: outsideTab, searchFilter: searchFilterInput, page: page }, isSearch);
        } else {
            let filtreObject = JSON.parse(filter);
            props?.getFilteredQuestion(props?.connectedUser?.idPerson, props?.connectedUser?.idSchool, { idCours: filtreObject.id,type:filtreObject.type, trie: trieInput, actualTab: outsideTab, searchFilter: searchFilterInput, page: page }, isSearch);
        }
    }
    return (
        <>
        <span id="step-question-page_prof"></span>
        <span id="step-last-question-page_prof"></span>
            {!loading && <div className="page-wrapper-height">
                <ManageQuestionComponent Searching={Search} />
                <div style={{maxWidth:'1200px', margin : 'auto'}}> 
                    {(actualTab === "unsettled") && <div className="row d-flex justify-content-center">
                        <div className="col-md-12 p-0" id="unsettled">
                            <ListCardQuestionProfessor doSearch={doSearch} what={actualTab} questionList={props?.listQuestions?.listUnsettledQuestions} />
                        </div>
                    </div>}
                    {(actualTab === "settled") && <div className="row d-flex justify-content-center">
                        <div className="col-md-12 p-0" id="settled">
                            <ListCardQuestionProfessor doSearch={doSearch} what={actualTab} questionList={props?.listQuestions?.listSettledQuestions} />
                        </div>
                    </div>}
                    {(actualTab === "all") && <div className="row d-flex justify-content-center">
                        <div className="col-md-12 p-0" id="all">
                            <ListCardQuestionProfessor doSearch={doSearch} what={actualTab} questionList={props?.listQuestions?.allQuestions} />
                        </div>
                    </div>}
                </div>
            </div>}
            {loading && <div className="page-wrapper-height">
                <ManageQuestionProfessorLoadingComponent />
                <div style={{maxWidth:'1200px', margin : 'auto'}}>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 p-0" id="unsettled">
                            <QuestionCardProfessorLoading />
                        </div>
                        <div className="col-md-12 p-0" id="unsettled">
                            <QuestionCardProfessorLoading />
                        </div>
                        <div className="col-md-12 p-0" id="unsettled">
                            <QuestionCardProfessorLoading />
                        </div>
                        <div className="col-md-12 p-0" id="unsettled">
                            <QuestionCardProfessorLoading />
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    tabQuestionManager: state.chatReducer.tabQuestionManager,
    connectedUser: state.authReducer.connectedUser,
    listQuestions: state.chatReducer.listQuestions,
    myCourses: state.courseReducer.myCourses,
    loading: state.chatReducer.loadingQuestions,
    schoolInfo:state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { setActualTabManageQuestion, getFilteredQuestion, getMyCoursesProfessor, getQuestionByType, getMyCourses },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);