import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useTranslation } from "react-i18next";
import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";

import { affectStudentToGroup, disaffectStudentFromGroup, getGroupByStudent } from '../../services/groupe.student.service';
import { deactivateModal } from '../../global-state/actions/modal-actions';

interface Props {
  index: number,
  student: any
}
interface ObjectsData {
  person: any;
  idGroup: string | null; 
  idSchool: string | null;
  subGroup: boolean; 
  parentGroup: boolean;
  idGroupParent: string | null; 
}

const ActionTableStudentGroupListe: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [indexAction, setIndexAction] = useState<number>(0);
  const [allowdGroup, setAllowdGroup] = useState<any>()
  const [groupForStudent, setGroupForStudent] = useState<any>([]);

  useEffect(() => {
    let isMounted = true;
  
    getGroupByStudent(props?.student?.id).then((response:any)=>{
      if (isMounted) {
        setGroupForStudent(response);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [props?.student?.id]);
  
  useEffect(() => {
    let isMounted = true;
    let list = props?.groupList.filter((obj: any) => {
      return !(groupForStudent.map((group: any) => {
        return group?.id
      }).includes(obj?.id))
    });
  
    if (isMounted) {
      setAllowdGroup(list);
    }
    return () => {
      isMounted = false;
    };
  }, [props?.groupList, groupForStudent]); 

  const openActionTable = (i: number, action: string) => {
    if ((action === 'open' && open) || action === '') {
      setOpen(!open);
    }
  }

  const handleAddStudentToGroup = async (objects: ObjectsData) => {
    try {
      const response = await affectStudentToGroup(objects);
      const groupListForStudentCopy: any[] = [...groupForStudent];
      const allGroup: any[] = [...props?.groupList];
      const groupIndex = allGroup.findIndex((group) => group?.id === response.id);
      groupListForStudentCopy.push(allGroup[groupIndex]);
      setGroupForStudent(groupListForStudentCopy);
      props?.deactivateModal();
    } catch (error) {
      console.error("Error while adding student to group:", error);
    }
  };

  const handleDisaffectStudentFromGroup = async (objects: ObjectsData) => {
    try {
      const response = await disaffectStudentFromGroup(objects);
      const groupForStudentCopy: any[] = [...groupForStudent];
      const allGroups: any[] = [...props.groupList];
      const indexGroupForStudent = allGroups.findIndex((group) => group?.id === response.id);
      if (indexGroupForStudent !== -1) {
        groupForStudentCopy.splice(indexGroupForStudent, 1);
      }
      setGroupForStudent(groupForStudentCopy);
      props?.deactivateModal();
    } catch (error) {
      console.error("Error while disaffecting student from group:", error);
    }
  };
  


  const disaffectstudentFromGrpoup = async (e: any, student: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props?.tabGroupManager === props?.groupForm?.label || (props?.tabGroupManager === "all" && props?.groupForm?.childsList.length === 0)) {
      let objects:ObjectsData = {
        person: { ...student, type: 'NP' },
        idGroup: props?.groupForm.id,
        idSchool: props?.connectedUser?.idSchool,
        subGroup: false,
        parentGroup:true,
        idGroupParent:props?.groupForm.id,
      }
      handleDisaffectStudentFromGroup(objects)
    } else {
      let actualGroup = props?.groupForm?.childsList.find((obj: any) => { return obj.label === props?.tabGroupManager });
      let objects :ObjectsData= {
        person: { ...student, type: 'NP' },
        idGroup: actualGroup.id,
        idSchool: props?.connectedUser?.idSchool,
        subGroup: true,
        parentGroup:false,
        idGroupParent:props?.groupForm.id,

      }
      handleDisaffectStudentFromGroup(objects)
    }

  }
  const addToGroup = async (group: any) => {
    let idsSubGroup= props?.groupForm.childsList.map((obj:any)=>{return obj?.id})
    let objects:ObjectsData = {
      person: { ...props?.student, type: 'NP' },
      idGroup: group.id,
      idSchool: props?.connectedUser?.idSchool,
      subGroup: (group.id===props?.groupForm.id || !idsSubGroup.includes(group.id))?false: true,
      parentGroup: group.id===props?.groupForm.id? true: false,
      idGroupParent:props?.groupForm.id,
    }
    handleAddStudentToGroup(objects);
    
  }
   const changeGroup= async (group:any, event:any)=>{
    
     let actualGroup= props?.groupList.find((obj:any)=>{return obj.label===(props?.tabGroupManager==="all"?props?.groupForm.label:props?.tabGroupManager)})
     
     let idsSubGroup= props?.groupForm.childsList.map((obj:any)=>{return obj?.id})
     let affectedPerson={
      person: { ...props?.student, type: 'NP' },
      idGroup: group.id,
      idSchool: props?.connectedUser?.idSchool,
      subGroup: (group.id===props?.groupForm.id || !idsSubGroup.includes(group.id))?false: true,
      parentGroup: group.id===props?.groupForm.id? true: false,
      idGroupParent:props?.groupForm.id,
     }
      let disaffectStudent={
        person: { ...props?.student, type: 'NP' },
        idGroup: actualGroup.id,
        idSchool: props?.connectedUser?.idSchool,
        subGroup: (actualGroup.id===props?.groupForm.id || !idsSubGroup.includes(actualGroup.id))?false: true,
        parentGroup: actualGroup.id===props?.groupForm.id? true: false,
        idGroupParent:props?.groupForm.id,
      }
      try{
       await handleDisaffectStudentFromGroup(disaffectStudent);
       await handleAddStudentToGroup(affectedPerson);

      } catch (err){
        console.error("Error changingn student to group:", err);
      }
  
     
   }
   function onClickAwayhandler () {
    openActionTable(props?.index, 'open');
    setIndexAction(0)
   }
  return (
    <ClickAwayListener onClickAway={onClickAwayhandler}>
      <div className=" d-flex justify-content-end">
        <button onClick={() => { openActionTable(props?.index, ''); setIndexAction(0) }} className="me-3 btn-QuickActions transparent">
          <span className="material-icons-outlined">edit</span>
        </button>
        <div className={` py-3 navbar-submenu-list ${(open === false) ? "close" : ""}`} >
          {indexAction === 0 && <div className="d-flex flex-column">
            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { setIndexAction(1) }}>
              <span className="material-icons-outlined">
                redo
          </span>
              <span className="body-md bold ms-2">{t('changeGroup')}</span>
            </a>
            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { setIndexAction(2) }}>
              <span className="material-icons-outlined">
                add
          </span>
              <span className="body-md bold ms-2">{t('addToGroup')}</span>
            </a>
            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={(e) => { disaffectstudentFromGrpoup(e, props?.student) }}>
              <span className="material-icons-outlined">
                delete
          </span>
              <span className="body-md bold ms-2">{t('deleteFromGroup')}</span>
            </a>
          </div>}
          {indexAction === 2 && <div className="d-flex flex-column">
            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { setIndexAction(0) }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7.99 13L20 13V11L7.99 11L7.99 8L4 12L7.99 16V13Z" fill="#272727" />
              </svg>
              <span className="body-md bold H1-Headline ms-2">{t('addToGroup')}</span>
            </a>
            {allowdGroup.map((g: any) => {
              return <a key={g.id} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { addToGroup(g) }}>
                <span className="body-md bold ms-2">{g.label}</span>
              </a>
            })}
          </div>
          }
          {indexAction === 1 && <div className="d-flex flex-column">
            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { setIndexAction(0) }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7.99 13L20 13V11L7.99 11L7.99 8L4 12L7.99 16V13Z" fill="#272727" />
              </svg>
              <span className="body-md bold H1-Headline ms-2">{props?.tabGroupManager==="all"?props?.groupForm.label:props?.tabGroupManager}</span>
            </a>
            {allowdGroup.map((g: any) => {
              return <a key={g.id} className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={(e) => { changeGroup(g, e) }}>
                <span className="body-md bold ms-2">{g.label}</span>
              </a>
            })}
          </div>}
        </div>
      </div>
    </ClickAwayListener>

  )
}
const mapStateToProps = (state: AppState) => ({
  listeStudents: state.profReducer.listeStudents,
  connectedUser: state.authReducer.connectedUser,
  modalPage:state.modalReducer.modalPage


});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
    deactivateModal,
    }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionTableStudentGroupListe);