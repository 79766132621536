import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const PrettoSlider = withStyles({
  root: {
    color: 'black',
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#5693EE',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    border: '2px solid #5693EE',
    marginTop: -3,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      color: '#272727',
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 20,
  },
  rail: {
    height: 4,
    borderRadius: 20,
  },
})(Slider);
export default function SliderZoomInOut(properties: any) {
  const [value, setValue] = useState(properties.scale);
  const handleChange = (event: any, newValue: any) => {

    properties.newScale(newValue);
  };
  useEffect(() => {
    setValue(properties.scale);
  }, [properties.scale]);
  return <>
    <div className="d-flex align-items-center" style={{ width: 250 }}>
      <span className="material-icons me-2 setting-size-cover" style={{ cursor: 'pointer' }} onClick={(e) => { handleChange(e, value - 0.02) }}> remove </span>
      <PrettoSlider valueLabelDisplay="off" min={1} value={value} max={5} step={0.02} onChange={handleChange} aria-labelledby="continuous-slider" className="align-items-center d-flex" />
      <span className="material-icons ms-2 setting-size-cover" style={{ cursor: 'pointer' }} onClick={(e) => { handleChange(e, value + 0.02) }}> add </span>
    </div>
  </>
}


