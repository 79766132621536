import React, { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import PathCourseContentComponent from './path-course-content-component';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { checkProgressCourse, getCurrentCourse, getSideBarSectionContent, getSideBarSectionContentWithoutApi, getTrainingSectionContent, updateIndexProgress } from '../../../global-state/actions/student-training-actions';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getDuration } from '../../../base/functions/Functions';
import "../../../index.scss"; 

interface ownProps {
    isOpen: boolean;
    onClose: () => void;
}

const CustomSwipeableDrawer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [startX, setStartX] = useState<number | null>(null);
    const { t } = useTranslation();
    const [courseProgress, setCourseProgress] = useState<any>(null);
    const [leftLoader, setLeftLoader] = useState<boolean>(false)
    const [rightLoader, setRightLoader] = useState<boolean>(false)

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setStartX(e.touches[0].clientX);
    };

    const goNextPrevious = (index: number, side: string) => {
        if (side === "right") {
            setRightLoader(true)
        }
        else {
            setLeftLoader(true)
        }
        props?.getSideBarSectionContent(sections[index], index, sections[index]?.type, props?.course?.followUp?.id);
        props?.getTrainingSectionContent(sections[index], index, sections[index]?.type, props?.course?.followUp?.id).then(() => {
            setLeftLoader(false)
            setRightLoader(false)
        })
    }
    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (startX !== null) {
            const currentX = e.touches[0].clientX;
            const deltaX = currentX - startX;

            // Adjust this threshold as needed
            if (deltaX > 50) {
                props.onClose();
                setStartX(null);
            }
        }
    };

    const sortByOrder = (a: any, b: any) => {
        if (a?.index < b?.index) {
            return -1;
        }
        if (a?.index > b?.index) {
            return 1;
        }
        return 0;
    }
    const sections: any[] = useMemo(() => {
        if (!!props?.course?.orders && !!props?.course?.exams) {
            return [
                ...[...props?.course?.orders, ...props?.course?.exams].sort(sortByOrder).map((section: any) => {
                    let progress = null;
                    if (courseProgress && courseProgress?.progressChapters) {
                        if (!!section?.chapter) {
                            const object = courseProgress?.progressChapters?.find((obj: any) => obj?.chapterId === section?.chapter?.id);
                            if (object) {
                                progress = object;
                            }
                        } else {
                            const object = courseProgress?.progressQna?.find((obj: any) => obj?.idQna === section?.id);
                            if (object) {
                                progress = object;
                            }
                        }
                    }
                    let content = !!section?.chapter ? { ...section?.chapter, type: 'CHAPTER', progress: progress } : { ...section, type: section?.qnaType, progress: progress }
                    return {
                        ...content, index: section?.index,
                    }
                }),
                { title: 'diploma', index: (props?.course?.orders?.length + props?.course?.exams?.length + 1), type: 'diploma' }
            ]
        } else {
            return [
                { title: 'diploma', index: 1, type: 'diploma' }
            ]
        }


    }, [props?.course, props?.currentTrainingProgress])
    
    return (
        <div
            className={`custom-swipeable-drawer-left ${props.isOpen ? 'open' : ''}`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            style={{ overflowY: 'auto' }}
        >
            <div className="drawer-content">
                <div >
                    <div className="d-flex align-items-center" style={{ borderBottom: "1px solid #F2F2F5" }}>
                        <div className="px-4 col-2">
                            {/* Your drawer content goes here */}
                            <a onClick={props.onClose} className="d-flex justify-content-left align-items-center nav-back">
                                <span className="material-icons-round">
                                    chevron_left
                                </span>
                            </a>
                        </div>
                        <div className="col-8 d-flex justify-content-center" >
                            {props?.sectionContentDetails?.contentType === "CHAPTER" && (
                                <div>
                                    <span className="H3-Headline">{props?.sectionContentDetails?.sectionContent?.title}.</span><span className="H3-Headline" style={{ color: "#CACBCE" }} >{getDuration(props?.sectionContentDetails?.sectionContent?.duration)}</span>
                                </div>
                            )}
                            {props?.sectionContentDetails?.contentType === "FINALEXAM" && (
                                <div className='cours-nav'>
                                    <div className="course-title">
                                        {props?.course && props?.course.title}
                                    </div>
                                    <div className="sub-title">
                                        {props?.course && (props?.course.type === "COURSE" ? (" " + t('courseBy')) : props?.course.type === "PATH" ? (" " + t('pathBy')) : (" " + t('sessionBy'))) + " " + props?.course.personName}
                                    </div>
                                </div>
                            )}
                            {props?.sectionContentDetails?.contentType === "KNOWLEDGETEST" && (
                                <div className='cours-nav'>
                                    <div className="course-title">
                                        {props?.course && props?.course.title}
                                    </div>
                                    <div className="sub-title">
                                        {props?.course && (props?.course.type === "COURSE" ? (" " + t('courseBy')) : props?.course.type === "PATH" ? (" " + t('pathBy')) : (" " + t('sessionBy'))) + " " + props?.course.personName}
                                    </div>
                                </div>
                            )}
                            {props?.sectionContentDetails?.contentType === "diploma" &&
                                <div className='cours-nav'>
                                    <div className="course-title">
                                        {props?.course && props?.course.title}
                                    </div>
                                    <div className="sub-title">
                                        {props?.course && (props?.course.type === "COURSE" ? (" " + t('courseBy')) : props?.course.type === "PATH" ? (" " + t('pathBy')) : (" " + t('sessionBy'))) + " " + props?.course.personName}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div style={{ backgroundColor: "#FFFFFF" }} id="next-previous-buttons" >
                        <div className="d-flex flex-row justify-content-between px-4" >

                            <div className='fixed-bottom d-flex justify-content-between' style={{ height: "60px", backgroundColor: "#FFFFFF" }}>
                                {!leftLoader ? <button className="d-flex align-items-center nav-back" style={{ border: "none" }} disabled={props?.sectionContentDetails?.index === 0} onClick={() => goNextPrevious(props?.sectionContentDetails?.index - 1, "left")}>
                                    <span className="material-icons-round">
                                        chevron_left
                                    </span>
                                    <span>
                                        Précedent
                                    </span>
                                </button>
                                    :
                                    <button className="nav-back" style={{ border: "none" }}>
                                        <svg className="spinner" viewBox="0 0 50 50">
                                            <circle className="path" cx="25" cy="25" r="14" fill="none" strokeWidth="2"></circle>
                                        </svg>
                                    </button>
                                }


                                {
                                    !rightLoader ? <button className="d-flex align-items-center nav-back" style={{ border: "none" }} disabled={((props?.sectionContentDetails?.index + 1 === sections.length - 1) && (props?.isCompleted === 'UNCOMPLETED') || (props?.sectionContentDetails?.index === sections.length - 1))} onClick={() => goNextPrevious(props?.sectionContentDetails?.index + 1, "right")}>
                                        <span>Suivant</span>

                                        <span className="material-icons-round">
                                            chevron_right
                                        </span>

                                    </button>
                                        :
                                        <button className="nav-back" style={{ border: "none" }}>
                                            <svg className="spinner" viewBox="0 0 50 50">
                                                <circle className="path" cx="25" cy="25" r="14" fill="none" strokeWidth="2"></circle>
                                            </svg>
                                        </button>}
                            </div>

                        </div>
                    </div>
                    < PathCourseContentComponent isMobile={true} />
                </div>
            </div>
        </div>
    );
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getSideBarSectionContent,
            getSideBarSectionContentWithoutApi,
            getCurrentCourse,
            getTrainingSectionContent,
            checkProgressCourse,
            updateIndexProgress

        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    sectionContentDetails: state.studentTraining.sectionContentDetails,
    course: state.studentTraining.currentCourse,
    isCompleted: state.studentTraining.isCompleted,
    currentTrainingProgress: state.studentTraining.currentTrainingProgress,
    checkIndexProgress: state.studentTraining.first

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSwipeableDrawer);

