import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { closeModal, openModal } from '../../global-state/actions/modal-actions';
import { clearMessage, loginUser } from '../../global-state/actions/auth-actions';
import "../../translations/i18n";
import { useHistory } from 'react-router-dom';
import { clearHistory, saveReturnPath } from '../../global-state/actions/breadcrumb-actions';



const SignInPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [msg, setMsg] = useState('');
    const [showP, setShowP] = useState(false);
    const history = useHistory();
    const [trainingUrl, setTrainingUrl] = useState<string>("")

    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)
    const [emailError, setEmailError] = useState("")


    useEffect(() => {
        if (!regx.test(email) && email?.length !== 0) {
            setEmailError("Adresse e-mail invalide")
        }
    }, [email])

    useEffect(() => {

        if (props?.returnPathPlateform?.path !== undefined) {
            setTrainingUrl(props?.returnPathPlateform?.path)
        }
    }, [props?.returnPathPlateform])

    useEffect(() => {
        props?.clearMessage();
        setMsg("")
    }, [])

    const signIn = async () => {
        await props?.loginUser(email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), password)
    }

    const returnToTraining = async () => {
        await props?.saveReturnPath({ path: `${props?.envUrl}/auth/sign-in` });
        await history.push(`${trainingUrl}`);
    }

    useEffect(() => {
        if (props?.connectedUser !== undefined
            && props?.error === undefined 
        ) {
            returnToTraining()
        }
    }, [props?.connectedUser, props?.error])

    useEffect(() => {
        if (props?.error !== undefined && props?.error?.length !== 0) {
            setMsg("Mot de passe incorrecte")
        }
    }, [props?.error]);

    const checkMsg = () => {
        if (msg && msg !== "") {
            props?.clearMessage();
        }
    }

    const signUp = () => {
        history.push(`${props?.envUrl}/auth/sign-up`);
    }

    return (
        < div >
            <div className="modal-body mb-3 w-100" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="d-flex flex-column align-items-center w-100 mb-4">
                        <div className="H3-Headline black-800 mb-2  ">Connexion </div>
                        <span className="body-md  black-800" >Finalisez votre achat et profitez du</span>
                        <span className="body-md  black-800" >cours en vous connectant</span>
                        <div className=" " style={{ padding: "40px 0px" }}>
                            <div className="mb-3 w-100">
                                <label className="form-title">{t('emailLogin')} </label>
                                <input dir="ltr" type="text" className={emailError?.length != 0 ? "form-control input text-default error" : "form-control input text-default"} placeholder={t('emailLogin')} value={email} onChange={(event) => { checkMsg(); setEmail(event.target.value.trim()) }} />
                                {emailError?.length !== 0 && <div className="mt-1">
                                    <label className="error-input mt-1">{emailError}</label>
                                </div>}
                            </div>
                            <div className="w-100">
                                <label className="form-title">{t('password')} </label>
                                <div className="input-form-control">
                                    <input dir="ltr" type={showP ? "text" : "password"} className="form-control input text-default" placeholder={t('password')} value={password} onChange={(event) => { checkMsg(); setPassword(event.target.value) }} />
                                    {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                    {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                </div>
                            </div>
                            <div className="mt-1">
                                <label className="error-input mt-1">{msg}</label>
                            </div>
                            <div className="mt-3  d-flex flex-column align-items-center">
                                <button type="button" className="btn-Primary large" onClick={(event) => { event.preventDefault(); signIn() }}>
                                    {t('cnxButton')}
                                </button>

                            </div>
                            <div className="mt-5 d-flex  flex-column align-items-center">
                                <div className=" text-center">
                                    <span className="body-md bold black-800" >Vous n’avez pas de compte ? </span>
                                    <a onClick={() => { signUp() }}>S’inscrire</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ div>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    actualOffer: state.courseReducer.actualOffer,
    lengthCourses: state.courseReducer.LengthCoursesPublished,
    error: state.messageReducer.error,
    returnPathPlateform: state.historyReducer.returnPathPlateform,
    envUrl: state.historyReducer.envUrl
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            loginUser,
            closeModal,
            openModal,
            saveReturnPath,
            clearHistory,
            clearMessage
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);