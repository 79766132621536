import { ComponentProps, FC } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { ModalIDS } from '../modals/modal-ids';
import { closeModal, openModal } from '../../global-state/actions/modal-actions';
const DeleteAccountComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const { t } = useTranslation();
    const openModalFn = () => {
        props?.openModal({ idModal: ModalIDS.DELETE_USER_ACCOUNT, size: "md" })
    }

    return (
        <div className="d-flex delete-account w-100 " style={{ paddingBottom:"56px"}}>
            <button className="btn-text outlined" onClick={() => { openModalFn() }} >{t("deleteCompte")}</button>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    openModal,
    closeModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountComponent);

