import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxSchoolState } from '../reducers/school-reducer';
import axios from '../../helpers/interceptors';
export enum EReduxActionTypes {
  CHANGE_TAB = 'CHANGE_TAB',
  GET_LEGAL_PERSON='GET_LEGAL_PERSON'
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}


export interface IReduxChangeTabSchoolAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_TAB;
  data: any
}
export interface IReduxGetLeaglPersonAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_LEGAL_PERSON;
  data: any
}


export function changeTabSchool(actualTab: string): ThunkAction<Promise<IReduxChangeTabSchoolAction>, IReduxSchoolState, undefined, IReduxChangeTabSchoolAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolState, undefined, IReduxChangeTabSchoolAction>) => {

    return dispatch({
      type: EReduxActionTypes.CHANGE_TAB,
      data: {tab: actualTab}
    });
  }
}


export function getLegalPerson(origin: number): ThunkAction<Promise<IReduxGetLeaglPersonAction>, IReduxSchoolState, undefined, IReduxGetLeaglPersonAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolState, undefined, IReduxGetLeaglPersonAction>) => {
    const school =   await axios.get(process.env.REACT_APP_BASE_URL2+"person/legalpersonbyid/"+origin).then(response => { return response.data }).catch(() => { return { data: "" } })
    return dispatch({
      type: EReduxActionTypes.GET_LEGAL_PERSON,
      data: school
    });
  }
}

