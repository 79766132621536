import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import TrainingContentCous from "./course-section-component";
import { formatNumber, getDuration } from '../../base/functions/Functions';

interface Props {
  index: any
  title: any,
  list: any,
  duration: any,
}
const TrainingContentPath: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [coursesListInsidePath, setCoursesListInsidePath] = useState(0);
  
  const openPathDetails = () => {
    setOpen(!open);
  }
  const { t } = useTranslation();


  const convertTime = (minutes: number) => {
    if (minutes < 60) {
      return minutes + ' MN';
    } else {

      return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
    }
  }
  return (
    <>
    <div className='d-none d-lg-block'>
      <div className={`d-flex align-items-center bg-with-border white pointer py-28 ps-40 pe-4 bg-with-border white opened mb-3`}>
        <div className="me-40">
          <span className="H3-Headline">{formatNumber(props?.index)}</span>
        </div>

      <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">

        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span className="H3-Headline mb-6 formation-card horizontal title">{props?.title}</span>
            <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
              <div className="d-flex me-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                        12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                    fill="#FA83EE" />
                </svg>
              </div>
              <div className="body-sm bold neutral-2">
                {t('pathTitle')}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center detail-liste-responsive-actions detail-liste-responsive-session justify-content-end" >
          <div className=" ms-2 d-flex flex-row detail-liste-responsive-other progress-training flex-wrap">
            <div className="d-flex align-items-center me-4">
              <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
              <span className="body-md bold">{convertTime(props?.duration)}</span>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="btn-QuickActions small me-2" onClick={() => openPathDetails()}>
        {!open ?
          <span className="material-icons-outlined">
            expand_more</span> :
          <span className="material-icons-outlined">
            expand_less</span>
        }
      </button>
      <div className="subliste-all-ligne" style={(open) ?
        { height: 83 + (137 * 1), transition: 'height .5s ease', overflowY: 'hidden' , display:'none'} :
        { height: '0px', transition: 'height .5s ease', overflowY: 'hidden' }}></div>
      </div>
      <div style={(open) ?
        { height: (37 + (136 * props?.list?.length) + (coursesListInsidePath * 112)), transition: 'all .5s ease', overflowY: 'hidden'} :
        { height: 0, transition: 'all .5s ease', overflowY: 'hidden', marginTop: 0 }}>

        {props?.list?.map((obj: any) => {

          return <>

            {(!!obj.course) &&
              <>
              <div className="d-flex flex-column mb-3">
                <div className="d-flex flex-row align-items-center justify-content-end" style={{ marginLeft: 55 }}>
                  <div className="subliste-cours-ligne path-description"></div>
                  <TrainingContentCous coursesListInsidePath = {coursesListInsidePath} setCoursesListInsidePath={setCoursesListInsidePath} index={obj?.index + 1} title={obj?.course?.title} list={obj?.course?.orders} exams={obj?.course?.exams} duration={getDuration(obj?.course?.duration)} />
                </div>
              </div>
              </>
            }

          </>
        })}
      </div>
    </div>
    <div className='d-block d-lg-none'>
      <div className={`d-flex align-items-center bg-with-border white pointer px-2 px-sm-3 py-28 bg-with-border white opened mb-3`}>
        <div className="px-3 pr-4 px-md-4">
          <span className="H3-Headline-path-course-title">{formatNumber(props?.index)}</span>
        </div>
        <div className="flex flex-row justify-content-between detail-liste-responsive w-100 px-2">
            <div className="d-flex align-items-center justify-content-between">
              <span className="H3-Headline-path-course-title" style={{WebkitLineClamp: "1", overflow : "hidden", textOverflow: "ellipsis", display: "-webkit-box",WebkitBoxOrient: "vertical"}}>{props?.title}</span>
              <div className="d-flex flex-row detail-liste-responsive-other progress-training flex-wrap">
                <div className="d-flex align-items-center">
                  <span className="material-icons-outlined neutral-3 px-1">timer</span>
                  <span className="body-md bold text-truncate">{convertTime(props?.duration)}</span>
                </div>
              </div>
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
              <div className="d-flex me-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                        12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                    fill="#FA83EE" />
                </svg>
              </div>
              <div className="body-sm bold neutral-2">
                {t('pathTitle')}
              </div>
            </div> 
            <button type="button" className="btn-QuickActions small" onClick={() => openPathDetails()}>
                {!open ?
                  <span className="material-icons-outlined">
                    expand_more</span> :
                  <span className="material-icons-outlined">
                    expand_less</span>
                }
            </button>
          </div>
        </div>
      <div className="subliste-all-ligne" style={(open) ?
        { height: 83 + (137 * 1), transition: 'height .5s ease', overflowY: 'hidden' , display:'none'} :
        { height: '0px', transition: 'height .5s ease', overflowY: 'hidden' }}></div>
      </div>
      <div style={(open) ?
        { height: (37 + (136 * props?.list?.length) + (coursesListInsidePath * 112)), transition: 'all .5s ease', overflowY: 'hidden'} :
        { height: 0, transition: 'all .5s ease', overflowY: 'hidden', marginTop: 0 }}>

        {props?.list?.map((obj: any) => {

          return <>

            {(!!obj.course) &&
              <>
              <div className="d-flex flex-column mb-3">
                <div className="d-flex flex-row align-items-center justify-content-end" style={{ marginLeft: 55 }}>
                  <div className="subliste-cours-ligne path-description"></div>
                  <TrainingContentCous coursesListInsidePath = {coursesListInsidePath} setCoursesListInsidePath={setCoursesListInsidePath} index={obj?.index + 1} title={obj?.course?.title} list={obj?.course?.orders} exams={obj?.course?.exams} duration={getDuration(obj?.course?.duration)} />
                </div>
              </div>
              </>
            }

          </>
        })}
      </div>
    </div>
    </>
  );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({

}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({


});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingContentPath);