import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {createSeens} from '../../global-state/actions/chat-actions';
import { returnDynamicValueFromUrl } from "../../helpers/domainCheck";
const HeaderRegistrationPreviewStudentPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [showChat, setShowChat] = useState(false)
    const location = useLocation();
    const [follow, setFollow] = useState<any>()
    const training = useMemo<any>(() => {
        return  props?.mycourses?.find((obj: any) => { return obj.followUp.id === Number(returnDynamicValueFromUrl(3)) })
    }, [props?.myCourses])
    useEffect(()=>{
        if(props?.openChat===true){openChatFN(true)}
        
    }, [props?.openChat])

    const openChatFN = async  (value: boolean) => {
        props?.isOpen(value);
        setShowChat(value);
        if (value === true && props?.listPosts && props?.listPosts.length !== 0) {
            
            let listObjects: any[];
            let responsesIds = props?.listPosts?.filter((obj: any) => { return ((obj?.idAuthor?.id !== props?.connectedUser?.idPerson) && ((obj?.seens?.map((seen: any) => { return seen?.idPerson }))?.includes(props?.connectedUser?.idPerson))) || (obj?.idAuthor?.id !== props?.connectedUser?.idPerson && obj?.seens?.length === 0) })?.map((obj: any) => { return obj?.id })
            listObjects = responsesIds?.map((obj: any) => {
               return { idPerson: props?.connectedUser?.idPerson, isSeen: true, postId: obj }
            });
           await  props?.createSeens(listObjects, props?.what);
        }
    }
    useEffect(() => {

        let idFollow = returnDynamicValueFromUrl(3)
        let object = props?.follow.find((followObj: any) => { return followObj.id === Number(idFollow) })
        
        setFollow(object)
    }, [props?.follow])
    
    const checkChatList = () => {
        if(props?.connectedUser?.role === 'role_student' && props?.listPosts.length === 0){ 
            return true
        } 
        return false
    }
    
    return (
        <>
            <div className="px-3 pt-3">
                <div className={(!showChat)?`col-md-12 pb-5 px-0 mx-auto pt-5`:`col-md-10 pb-5 px-0 mx-auto pt-5`} style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-between">
                        <div className=" d-flex align-items-center justify-content-between flex-wrap mt-5">
                            <div className="col-md-9">
                                <h1 style={{ textAlign: 'left' }} className="H2-Headline ">{t('demandeDinscription')}</h1>
                                <p className="H3-Headline neutral-3"><span>{training?.title}</span></p>
                            </div>
                            <div className="col-md-3 d-flex flex-wrap align-items-center">
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "CANDIDATE") &&
                                    <div className="badge-withicon progres mb-2">
                                        <span className="material-icons-outlined">free_breakfast</span>
                                        <span className="ms-2">{t('waiting')} </span>
                                    </div>}
                                    {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "UNPAID") &&
                                   <div className="badge-withicon validated">
                                   <span className="material-icons-round">done</span>
                                   <span className="ms-2">
                                       {t('fileValidated')}
                                   </span>
                               </div>}
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "REFUSED") &&
                                    <div className="badge-withicon unvalide">
                                        <span className="material-icons-outlined">close</span>
                                        <span className="ms-2">
                                            {t('fileRefused')}
                                        </span>
                                    </div>}
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "INSCRIBED") &&
                                    <div className="badge-withicon validated">
                                        <span className="material-icons-round">done</span>
                                        <span className="ms-2">
                                            {t('fileValidated')}
                                        </span>
                                    </div>}
                                <button 
                                    type="button" 
                                    className="btn-QuickActions ms-4" 
                                    id="openChat" 
                                    onClick={() => { openChatFN(!showChat) }}
                                    disabled={!!checkChatList()}
                                ><span className="material-icons-outlined">chat_bubble_outline</span>
                                </button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    follow: state.courseReducer.follow,
    courseInfo: state.courseReducer.courseInfo,
    myCourses: state.courseReducer.myCourses,
    listPosts: state.chatReducer.listPosts,
    connectedUser : state.authReducer.connectedUser,
    openChat: state.chatReducer.openChat,
    isOpen:ownProps?.isOpen

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    createSeens
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRegistrationPreviewStudentPageComponent);