
import { ComponentProps, FC } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { clearTeamList, deleteInvitation, getTeam, getTeamNumber } from '../../../global-state/actions/professor-actions';
import { closeModal } from '../../../global-state/actions/modal-actions';


const DirectorPaymentWariningModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
   
    const closeModal = () => {
        props?.closeModal()
    }



    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row justify-content-center">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.9998 0.666656C11.1198 0.666656 0.666504 11.12 0.666504 24C0.666504 36.88 11.1198 47.3333 23.9998 47.3333C36.8798 47.3333 47.3332 36.88 47.3332 24C47.3332 11.12 36.8798 0.666656 23.9998 0.666656ZM23.9998 42.6667C13.6865 42.6667 5.33317 34.3133 5.33317 24C5.33317 19.6833 6.80317 15.7167 9.2765 12.5667L35.4332 38.7233C32.2832 41.1967 28.3165 42.6667 23.9998 42.6667ZM38.7232 35.4333L12.5665 9.27666C15.7165 6.80332 19.6832 5.33332 23.9998 5.33332C34.3132 5.33332 42.6665 13.6867 42.6665 24C42.6665 28.3167 41.1965 32.2833 38.7232 35.4333Z" fill="#F5B95F" />
                        </svg>
                    </div>
                    <div className='d-flex align-text-center flex-column' style={{ paddingTop: "36px", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "27px", paddingLeft: "27px" }}>

                        <span className="H4-Subtitle black-800 bold" >Souhaitez-vous arrêter le prélèvement?</span>
                        <span className="body-sm pt-1" >Si vous arrêtez le prélèvement, cela voudrait dire que l’étudiant a abandonné le cours.</span>
                        <span className="body-sm "> Allez sur votre moteur de paiement pour arrêter le prélèvement.</span>

                    </div>
                    <div style={{ paddingTop: "27px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button type="button" className="btn-Primary large" onClick={()=>{closeModal()}}>
                            Retour
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({

    director: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearTeamList,
            getTeamNumber,
            getTeam,
        deleteInvitation,
        closeModal
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DirectorPaymentWariningModalComponent);