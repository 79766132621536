import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { publishCourse, updateCoursesLists } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { createTraining, publishTraining } from '../../../global-state/actions/training-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';
import { getExamToPass } from '../../../global-state/actions/exam-actions';
import { createExam, updateExam } from '../../../global-state/actions/course-actions';
import { getListServeys, getSizeQnas } from '../../../global-state/actions/sondage-actions';
import axiosInstance from '../../../helpers/interceptors';
const ArchiveSondageModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [qna, setQna] = useState<any>(undefined)
    let [filter, setfilter] = useState({

        qnaType: "SERVEY",
        entityType: "SCHOOL",
        entityId: props?.school?.id,
        pageSize: 12,
        pageNumber: 0,
      })
    const onInit = async (id: any) => {
        await props?.getExamToPass(id);
    }

    useEffect(() => {
        if (!!props?.examToPass) {
            setQna(props?.examToPass)
        }
    }, [props?.examToPass])
    useEffect(() => {

        onInit(props?.content?.data?.id)
    }, [props?.content])

    const archiveServey = async () => {
        if (!!qna) {
           await axiosInstance.post(process.env.REACT_APP_BASE_URL6 + "survey/endSurvey/"+qna?.id)
            .then((res:any)=>{
                 props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: "ok", lines: ["Sondage clôturé avec succès"] });
                     props?.getListServeys(filter,"first")
            })
        }


    }
    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body" style={{ paddingTop: '0', overflow:'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-9 p-0" style={{ margin: 'auto' }}>
                        <div className='row col-md-12'>
                            <div className="d-flex flex-column justify-content-start">
                                <span className="H3-Headline black-800">Clôturer le sondage</span>
                                <span className="H4-Subtitle neutral-3"> {props?.content?.data?.title} </span>
                                <span className="mt-3 body-sm black-800">Ce sondage sera définitivement clôturé.
                                </span>
                                <span className="body-sm black-800" > Vous n’aurez plus la possibilité de partager le sondage si vous changez d’avis et les  </span>
                                <span className="body-sm black-800" >destinataires ne pourront plus répondre au sondage</span>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer" style={{ border: "none" }}>
                    <div className="row mt-3 mb-5 w-100">
                        <div className='col-md-9 m-auto p-0 text-center justify-content-end' style={{ display: 'flex', alignItems: 'center' }}>
                            <button type="button" className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }} >{t('annuler')}</button>
                            <button type="button" className="btn-Primary large danger" onClick={() => { archiveServey(); }} >Clôturer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    examToPass: state.examReducer.examToPass,
    school: state.schoolInfoReducer.schoolInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            publishCourse,
            updateCoursesLists,
            createTraining,
            publishTraining,
            getExamToPass,
            updateExam,
            getListServeys

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveSondageModalComponent);