import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import userLogo from '../../styles/imgs/Default_user_icon.svg';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getStudentInfoById} from  '../../global-state/actions/course-actions';
import { returnDynamicValueFromUrl } from "../../helpers/domainCheck";

const HeaderRegistrationPreviewBlockStudentInfoComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const dateFormat = require('dateformat');
    const location = useLocation();
    const [ statusActual, setStatusActual]=useState<any>();
    useEffect(()=>{
        if(!!props?.follow){let idStudent = location?.pathname?.split('/')[4]

     
            props?.getStudentInfoById(props?.follow[0]?.studentId,props?.schoolInfo?.id);
      
       
        let indexFollow= props?.follow?.findIndex((obj:any)=>{return obj.id===Number(returnDynamicValueFromUrl(3))})
        let status =props?.follow[indexFollow]?.statusList?.find((el: any) => {return el.status==="CANDIDATE"}  )
        setStatusActual(status)
      }
    }, [props?.follow])
    return (
        <>
            <div className="px-3 ">
                <div className="col-md-7 py-4  my-3 mx-auto " style={{ maxWidth: '1200px' }}>
                    <div className="card text-center  " >
                        <div  style={{ backgroundColor: '#FBFBFD' }}>
                            <div className="d-flex flex-row align-items-center">
                                <div className="">
                                    <img style={{ borderRadius: "50%", width: "120px",height:"120px", objectFit: 'cover' }} src={(props?.studentInfo?.photo) ? props?.studentInfo?.photo : userLogo} />
                                </div>
                                <div className="ms-3 ps-4">
                                    <div className=" text-left mb-2">
                                        <h2 className="heading-h2-noirstd" style={{ textAlign: 'left',textTransform: 'capitalize' }}>{props?.studentInfo?.firstName} {props?.studentInfo?.lastName}</h2>
                                    </div>
                                    <div className="text-left" >
                                        <h4 className="body-sm bold" style={{ textAlign: 'left' }}><span className="neutral-3 me-2">{t('reponseInscrit')}</span> {dateFormat(statusActual?.creationDate, "dd/mm/yyyy")}</h4>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    follow: state.courseReducer.follow,
    actualStudent: state.detailsProfileReducer.actualStudent,
    studentInfo:state.courseReducer.studentInfo,
    schoolInfo:state.schoolInfoReducer.schoolInfo,
    applicationFile:state.courseReducer.applicationFile
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
     getStudentInfoById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRegistrationPreviewBlockStudentInfoComponent);