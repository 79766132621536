import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SchoolProfSectionComponentLoading: FC<ComponentProps<any>> = () => {

  return (
    <>
        <section className="d-none d-xl-block py-4" style={ { background: 'linear-gradient(to right, #212529 50%, transparent 50%)' }}>

            <div style={{ minHeight: 300, maxWidth: 1200, margin: 'auto' }}>
                <div className="d-xl-flex align-items-start w-100 px-2">
                    <div className="w-100 d-flex align-items-center justify-content-center flex-column px-5" style={{ minHeight: 300 }}>
                        <Skeleton variant="rect" animation="wave" width={'50%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />     
                        <Skeleton variant="circle" animation="wave" width={80} height={80} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />
                        <Skeleton variant="rect" animation="wave" width={'80%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-5" />
                        <Skeleton variant="rect" animation="wave" width={'85%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                        <Skeleton variant="rect" animation="wave" width={'82%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                        <Skeleton variant="rect" animation="wave" width={'85%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                        <Skeleton variant="rect" animation="wave" width={'75%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                    </div>
                    <div className="w-100  d-flex align-items-center justify-content-center flex-column px-5" style={{ minHeight: 300 }}>
                        <Skeleton variant="rect" animation="wave" width={'50%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />     
                        <Skeleton variant="circle" animation="wave" width={80} height={80} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />        
                        <Skeleton variant="rect" animation="wave" width={'80%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-5" />
                        <Skeleton variant="rect" animation="wave" width={'85%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                        <Skeleton variant="rect" animation="wave" width={'82%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                        <Skeleton variant="rect" animation="wave" width={'85%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                        <Skeleton variant="rect" animation="wave" width={'75%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SchoolProfSectionComponentLoading;