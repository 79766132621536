import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "../../../../../global-state/store/root-reducers";
const moment= require('moment');
interface Props{
    post:any,
    from?:string,
}
 const CommentCardHeader:  FC<ReturnType<typeof mapStateToProps> & Props>=(props:any)=>{
    const { t } = useTranslation();
    const [index, setIndex] = useState();
    const dateFormat = require('dateformat');

   
    useEffect(()=>{
        let lang = localStorage.getItem("language") || "fr" 
        
              if(lang!=="en"){
            require('moment/locale/'+ lang);
            moment.defineLocale(lang, {parentLocale: lang});
        }
        else{ 
            moment.defineLocale('en', {parentLocale: 'en'});
        }
       
        if(props?.post?.subject && props?.post?.subject?.category==="CHAPTER" && !props?.connectedUser?.role?.includes("role_student")){
             setIndex(props?.courseForm?.orders?.find((obj:any)=>obj?.chapter?.id===Number(props?.post?.subject?.idSubject))?.index); 
        }else{
            setIndex(props?.courseInfo?.orders?.find((obj:any)=>obj?.chapter?.id===Number(props?.post?.subject?.idSubject))?.index); 
        }
    }, [])
    const returnCategoryFn = (category: string)=>{
        switch(category){
            case 'CHAPTER':
                return "#"+t('chapitre')+" "+index;
            case 'COURSE':
                return "#"+t('Cours');
            default:
                return "#"+t('pathTitle') ;
        }
    }
    const calculateTime=(createdAt:any)=>{
        if(moment(Date()).diff(moment(createdAt), 'days')<1)
            return moment(createdAt).fromNow();
        else 
            return dateFormat(createdAt,"dd/mm/yyyy");
    }
     return (
         <>
            { props?.from==="communityProgress"?
            <div className="d-flex flex-wrap discuttion-titles  mt-2">
                <span className="body-sm bold neutral-3 me-3">{calculateTime(props?.post?.createdAt)}</span>
            </div>
            :<div className="d-flex flex-wrap discuttion-titles  mt-2">
                <span className="body-sm bold me-3">{ returnCategoryFn(props?.post?.subject?.category)}</span>
                <span className="body-sm bold neutral-3 me-3">{calculateTime(props?.post?.createdAt)}</span>
            </div>}
         </>
     )
 }
 const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.studentTraining.currentCourse,
    courseForm: state.courseReducer.courseForm,
});

export default connect(mapStateToProps)(CommentCardHeader)