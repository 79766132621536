import {  FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { getCountStudent } from "../../global-state/actions/training-actions";
import { AppState } from "../../global-state/store/root-reducers";
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from "../../components/modals/modal-ids";
import React from "react";
import { getSurvey } from "../../global-state/actions/sondage-actions";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

interface Props {
  data: any;
  doAction?: any;
  height?: number | string;
  size?: number;
}

const TableSondage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const [open, setOpen] = useState<any[]>([]);
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const submenuRef = useRef<HTMLDivElement>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);


  


  useEffect(() => {
    setLoading(props?.loading);
  }, [props?.loading]);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const doAction = (e: any, actionName: string, row: any, index: number) => {
    if (actionName !== '') {
      e.stopPropagation();
      const object = {
        row: row,
        action: actionName,
        index: index
      }
      props?.doAction(object);
    }
  }

  const handleOpen = (e:any, index: number) => {
    e.stopPropagation();
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {

    }
  };


  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClick = async (e:any, index: any) => {
    e.stopPropagation();
  }

  const shareSurvey = (e:any, id: any) => {
    e.stopPropagation();
    props?.openModal({ idModal: ModalIDS.SHARE_SONDAGE, size: "md" });
  }



  const copyLink = (e:any,obj: any) => {
    e.stopPropagation();
    if (typeof window.location.href != undefined) {
      let url: string = window.location.href
      let surveyLink: string = url.split('/')[2] + "/" + url.split('/')[3] + "/survey/user/"+obj?.row?.data?.id      
      navigator.clipboard.writeText(surveyLink)
      setOpenSnackbar(true)
    }
  }

  const handleClose = () => {
    setOpenSnackbar(false);
};



  return (
    <>
      <div className="my-3">
        <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
          <table className="w-100 custom-table">
            <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any) => (
                  <th key={header.title} scope={header.scope} className={header.classNames}>
                    <button className={header.buttonClasses}>
                      {t(header.title)}
                      {header.sorted && (
                        <div className="d-flex flex-column">
                          <span className="material-icons-outlined trie-arrow up">arrow_drop_up</span>
                          <span className="material-icons-outlined trie-arrow down">arrow_drop_down</span>
                        </div>
                      )}
                    </button>
                  </th>
                ))}
                <th key="Actions" scope="col" className="">
                  <button className="d-flex mx-auto button-trie px-0">
                    {t('Actions')}
                    <div className="d-flex flex-column">
                      <span className="material-icons-outlined trie-arrow up">arrow_drop_up</span>
                      <span className="material-icons-outlined trie-arrow down">arrow_drop_down</span>
                    </div>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
              {data?.body?.map((obj: any, index: number) => (
                <React.Fragment key={index}>
                  <tr style={{ cursor: 'pointer' }} onClick={(e) => doAction(e, obj.row.actionName, obj.row, index)} className={obj.row.classNames}>
                    {obj.row.columns.map((column: any, colIndex: number) => (
                      <td
                        key={colIndex + 'column'}
                        onClick={(e) => doAction(e, column.actionName, obj.row, index)}
                        className={column.classNames}
                        style={{ position: 'relative' }}
                        colSpan={column.colspan}
                      >
                        {column.content}
                      </td>
                    ))}
                    <td key={index + 'columnc'}>
                      <div className="d-flex mx-auto flex-row ms-2" style={{ maxWidth: 35 }}>

                        <button type="button" 
                          className="btn-QuickActions outlined col-md-6 align-items-center ">
                          <span className="material-icons-outlined"
                            style={{ transform: 'rotate(120deg)' }}
                            onClick={(e: any) => copyLink(e, obj)}
                          >link</span>
                        </button>

                        <button type="button" onClick={(e) => { handleOpen(e,index) }} 
                          className="btn-QuickActions outlined col-md-6 align-items-center ">
                          <span className="material-icons-outlined">more_horiz</span>
                        </button>

                        {openIndex === index && (
                          <div className="d-flex flex-column sub-liste" style={{ position: 'absolute', marginTop: '35px', marginLeft: '-5px', width: 190 }} ref={submenuRef}>
                            <a style={{ cursor: 'pointer' }} className="submenu-list-button d-flex align-items-center p-2 px-3">
                              <span className="body-md bold ms-2" onClick={(e:any) => { shareSurvey(e,index) }} >Partager</span>
                            </a>

                            <a style={{ cursor: 'pointer' }} className="submenu-list-button d-flex align-items-center p-2 px-3" onClick={(e:any) => { handleClick(e,openIndex) }}>
                              <span className="body-md bold ms-2">Clôturer</span>
                            </a>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

      </div>
      <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose}>Lien copié avec succès</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  countStudent: state.trainingReducer.countStudent,
  loading: state.trainingReducer.loadingcountStudent,
  LengthCourses: state.courseReducer.LengthCourses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCountStudent,
      openModal,
      closeModal,
      getSurvey
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TableSondage);