import { useEffect, useState } from "react";
import { ComponentProps, FC } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';



const CardFileFormComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {

  const [show, setShow] = useState(false);
  const onDelete = () => {
    props?.onDelete();
  }
  return <>

    <div className="mx-auto">
      <div className="bg-with-border white p-4">
        <div className="d-flex align-items-center justify-content-between  m-2">
          <div className="d-flex align-items-center me-2">
            <div className="me-3 d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
              <span className="material-icons-outlined black-800"> cloud_upload </span>
            </div>
            <div className="d-flex flex-column black-800">
              <span className="form-edit mb-1">{props?.filename}</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {
              (props?.uploadingChapterFileLoader && props?.uploadingChapterFileLoader.indexOf(props?.filename + props?.filesize) > -1) &&
              <>
                <svg className="spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
                <div className="p-4">
                  Importation en cours ....
                </div>
              </>


            }
            <button type="button" onClick={onDelete} className="btn-QuickActions me-3">
              <span className="material-icons-outlined">delete</span>
            </button>
            {props?.filename.includes('.pdf') && (props?.uploadingChapterFileLoader && props?.uploadingChapterFileLoader.indexOf(props?.filename + props?.filesize) === -1) &&
              <button type="button" onClick={() => setShow(!show)} className="btn-QuickActions ">
                <span className="material-icons-outlined">{(show) ? 'visibility_off' : 'remove_red_eye'}</span>
              </button>}

          </div>
        </div>
      </div>
      {show && <div style={{ height: 900 }}> <iframe src={`${props?.file}`} frameBorder="0" scrolling="auto" height="100%" width="100%" ></iframe> </div>}
    </div>
  </>
}


const mapStateToProps = (state: AppState) => ({
  uploadingChapterFileLoader: state.courseReducer.uploadingChapterFileLoader,
});
export default connect(mapStateToProps)(CardFileFormComponent)