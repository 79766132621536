import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/';
import "../../translations/i18n";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import DisplayClosedQuestionComponent from './display-closed-question-component';
import { getResultAnswers } from '../../global-state/actions/exam-actions';
import DisplayOpenQuestionComponent from './display-open-question-component';
import { getSideBarSectionContent } from '../../global-state/actions/student-training-actions';


const ExamResultsComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [studentAnswers, setStudentAnswers] = useState<any>(props?.studentAnswers);
    const progress = useMemo(() => { return props?.sectionContent?.sectionContent?.progression }, [props?.sectionContent?.sectionContent?.progression])
    const questionByQnaList = useMemo(() => { return props?.sectionContent?.sectionContent?.questionByQnaList }, [props?.sectionContent?.sectionContent?.questionByQnaList])
    const [courseProgress, setCourseProgress] = useState<any>(null);
    const { t } = useTranslation();
    let studentId = useMemo(() => props?.connectedUser?.idPerson, [props?.connectedUser?.idPerson])
    let examId = useMemo(() => props?.sectionContent?.sectionContent?.id, [props?.sectionContent?.sectionContent?.id])

    useEffect(() => {
        setStudentAnswers(props?.studentAnswers)
    }, [props?.studentAnswers])


    const sortByOrder = (a: any, b: any) => {
        if (a?.index < b?.index) {
            return -1;
        }
        if (a?.index > b?.index) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {
        if (props?.currentTrainingProgress && props?.currentTrainingProgress !== null) {
            setCourseProgress(props?.currentTrainingProgress);
        }
    }, [props?.currentTrainingProgress])
    const sections: any[] = useMemo(() => {
        if (!!props?.course?.orders && !!props?.course?.exams)
            return [
                ...[...props?.course?.orders, ...props?.course?.exams].sort(sortByOrder).map((section: any) => {
                    let progress = null;
                    if (courseProgress !== null) {
                        if (!!section?.chapter) {
                            const object = courseProgress?.progressChapters.find((obj: any) => obj?.chapterId === section?.chapter?.id);
                            if (object) {
                                progress = object;
                            }
                        } else {
                            const object = courseProgress?.progressQna.find((obj: any) => obj?.idQna === section?.id);
                            if (object) {
                                progress = object;
                            }
                        }
                    }
                    let content = !!section?.chapter ? { ...section?.chapter, type: 'CHAPTER', progress: progress } : { ...section, type: section?.qnaType, progress: progress }
                    return {
                        ...content, index: section?.index,
                    }
                }),
                { title: 'diploma', index: (props?.course?.orders?.length + props?.course?.exams?.length + 1), type: 'diploma' }
            ]
        return [
            { title: 'diploma', index: 1, type: 'diploma' }
        ]
    }, [props?.course])
    useEffect(() => {
        if (typeof props?.course?.followUp?.id != undefined && props?.course?.followUp?.id != 0 && props?.course?.followUp?.id != undefined) {
            props?.getResultAnswers(examId, studentId, props?.course?.followUp?.id);
        }

    }, [props?.sectionContent?.sectionContent, examId])
    useEffect(() => {
        setStudentAnswers(props?.studentAnswers)
    }, [props?.studentAnswers]);

    const goNextPrevious = () => {
        props?.getSideBarSectionContent(sections[props?.sectionContent?.index + 1], props?.sectionContent?.index + 1, sections[props?.sectionContent?.index + 1]?.type, props?.course?.followUp?.id);
    }



    return (
        <div>
            <div className={props.isMobile ? "grid-container" : "grid-container mx-4"} style={{ borderWidth: "40px 40px 40px 40px", boxSizing: "border-box", border: "1px solid #F2F2F5", borderRadius: "3px", padding: "40px 40px", backgroundColor: "#FBFBFD" }} >
                <div className={!props.isMobile ? 'd-flex align-items-center' : ''} style={{ paddingRight: "40px" }}>
                    {!props.isMobile && <div className={`test-state ${(progress?.finalMark / progress?.scaleNote >= 0.5) ? 'success' : 'refused'} d-flex align-items-center`}>
                        <span className="material-icons-outlined" style={{ fontSize: 64 }}>emoji_events</span>
                    </div>}


                    <div style={{ paddingLeft: "40px" }}>
                        <div className="grid-container text-center">
                            <div className="mb-4" > <span className="H3-Headline black-800" > {props?.sectionContent?.sectionContent?.title} </span> </div>
                            <div className="pb-2" > <span className="H4-Subtitle neutral-3" >{(progress?.finalMark / progress?.scaleNote >= 0.5) ? (<span > {t("Réuissi")} !</span>) : (<span  > {t("insuffisant")} </span>)}</span> </div>
                            <div className="" >
                                <span className="H4-Subtitle neutral-3"  >
                                    {(progress?.finalMark / progress?.scaleNote >= 0.5) ? (<span style={{ color: "#9DDBA9" }} >{progress?.finalMark}</span>) : (<span style={{ color: "#E06A6A" }} >{progress?.finalMark}</span>)}/{progress?.scaleNote} </span> </div>
                        </div>
                        {
                            props.isMobile ? 
                            <div>
                                {(props?.sectionContent?.contentType === 'FINALEXAM') && <button style={{width: "100%"}} onClick={() => goNextPrevious()} type="button" className="btn-Secondary large icon-right mt-4" >
                                    <div className="m-auto pe-2"> {t('getMyDiplome')} </div>
                                </button>}
                            </div>
                            
                            :
                            <button onClick={() => goNextPrevious()} type="button" className="btn-Secondary  large icon-right mt-4" >
                                <div className="m-auto pe-2"> {(props?.sectionContent?.contentType === 'FINALEXAM') ? t('getMyDiplome') : t('nextChapterText')} </div>
                                <span className="material-icons-outlined"> chevron_right </span>
                            </button>
                        }
                    </div>
                </div>

            </div>
            <div className="" style={props.isMobile ? {padding:"10px 0px 10px 0px"} : { padding: "24px 24px 24px 24px" }}>
                {studentAnswers && studentAnswers?.length > 0 && studentAnswers?.map((obj: any, i: number) => {
                    return <div key={obj.id} >
                        {obj?.type === "CQA" ? (
                            <div style={{ paddingBottom: "16px" }}>
                                <DisplayClosedQuestionComponent
                                isMobile={props.isMobile}
                                    key={i + 1}
                                    answer={obj}
                                    question={questionByQnaList?.find((qbe: any) => qbe?.id === obj?.questionByQna?.id)}
                                    index={i}
                                />
                            </div>
                        ) : (

                            <div style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                                <DisplayOpenQuestionComponent
                                    isMobile={props.isMobile}
                                    key={i + 1}
                                    answer={obj}
                                    question={questionByQnaList?.find((qbe: any) => qbe.id === obj?.questionByQna?.id)}
                                    index={i}
                                />
                            </div>

                        )}
                    </div>
                })}

            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ getResultAnswers, getSideBarSectionContent }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    question: ownProps?.question,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    exam: state.examReducer.examToPass,
    studentAnswers: state.examReducer.listOfResponses,
    sectionContent: state.studentTraining.sectionContentDetails,
    course: state.studentTraining.currentCourse,

});

export default connect(mapStateToProps, mapDispatchToProps)(ExamResultsComponent);


