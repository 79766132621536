import { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { saveChangeTo } from '../../global-state/actions/app-container-actions';
import { withTranslation } from 'react-i18next';
import { AppState } from '../../global-state/store/root-reducers';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { returnDynamicValueFromUrl, returnStartPathUrl } from '../../helpers/domainCheck';
import TarificationPeriod from './payment-period.component';
import { getOfferCourse, getOfferPricingCourse } from '../../global-state/actions/course-actions';
import SelectElement from "../../elements/select/select-element";
import { useHistory } from 'react-router';
import axiosInstance from '../../helpers/interceptors';
import { getCummulSalesByOfferFinanceApi, getAllOfferPayedFinanceApi } from '../../services/finance.service';
import { getListYearAndMonthsToDay } from '../../base/functions/Functions';
interface Filter {
    startDate: string;
    endDate: string;
}

const OfferDetailsComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [paymentPeriodic, setPaymentPeriodic] = useState<boolean>();
    const [typeTarification, setTypeTarification] = useState<string>("");
    const [paymentUnique, setPaymentUnique] = useState<boolean>(false);
    const [paymentEasy, setPaymentEasy] = useState<boolean>(false);
    const [paymentUniqueAccess, setPaymentUniqueAccess] = useState<string>("");
    const [defaultValue, setDefaultValue] = useState("none");
    const [offerTraining, setOfferTraining] = useState<any>(undefined);
    const [checkUniquePay, setCheckUniquePay] = useState<boolean>(false);
    const [checkFacPay, setCheckFacPay] = useState<boolean>(false);
    const dateFormat = require('dateformat');
    const [filterPayed, setFilterPayed] = useState<any>({
        yearOrMonthbyPayed: '' + new Date().getFullYear(),
        startDate: new Date().getFullYear() + '-01-01',
        endDate: new Date().getFullYear() + '-12-31',

    })
    const history = useHistory();
    const [priceAbnClass, setPriceAbnClass] = useState<any>('form-control input text-default')
    const [priceAbnEror, setPriceAbnError] = useState<any>('')
    const [priceAbn, setPriceAbn] = useState<any>();
    const [monthRulesClass, setMonthRulesClass] = useState<any>('form-control input text-default')
    const [priceUnique, setPriceUnique] = useState<any>(undefined);
    const [paymentSubscription, setPaymentSubscription] = useState<boolean>();
    const [pricings, setPricings] = useState<Array<any>>([]);

    const [access, setAccess] = useState<any>()
    const [trainingType, setTrainingType] = useState<any>("")
    const [title, setTitle] = useState<any>("")
    const [profName, setProfName] = useState<any>("")
    const [creationDate, setCreationDate] = useState<any>("")
    const [startDate, setStartDate] = useState<any>(new Date().getFullYear() + '-01-01')
    const [endDate, setEndDate] = useState<any>(new Date().getFullYear() + '-12-31')
    const [defaultValueAbonnement, setDefaultValueAbonnement] = useState<any>("none")
    const paymentUniquePriceRef = useRef<HTMLInputElement>(null);
    const [priceUniqueClass, setPriceUniqueClass] = useState<any>('form-control input text-default')
    const [priceUniqueEror, setPriceUniqueError] = useState<any>('')
    const [payRules, setPayRules] = useState<any>(undefined);
    const [accecebilty, setAccecebilty] = useState<any>("-")
    const [checkPrice, setCheckPrice] = useState<any>(true)
    const [inscribedStudentNumber, setInscribedStudentNumber] = useState<number>(0)
    const [offerId, setOfferId] = useState<number>(0)
    const [cumulSalesByOffer, setCumulSalesByOffer] = useState<any>()
    const [listOffersPayed, setListOffersPayed] = useState<any>()
    const [listYearAndMonth, setListYearAndMonth] = useState<any>()

    useEffect(() => {
        if (priceAbn == '0') {
            setPriceAbnClass("form-control input text-default error")
            setPriceAbnError('Saisissez un montant supérieur à zéro')
        } else {
            setPriceAbnClass("form-control input text-default")
            setPriceAbnError('')
        }
    }, [priceAbn])

    const getAllOfferPayedFinance = async (filter: any, idSchool: any, action: any) =>{
        const result = await getAllOfferPayedFinanceApi(filter, idSchool, action)
        setListOffersPayed(result)
    }

    useEffect(()=>{
        setListYearAndMonth(getListYearAndMonthsToDay())
        getAllOfferPayedFinance(filterPayed, props?.school?.id, "first")
    },[])

    useEffect(() => {
        if (priceUnique == '0') {
            setPriceUniqueClass("form-control input text-default error")
            setPriceUniqueError('Saisissez un montant supérieur à zéro')
        } else {
            setPriceUniqueClass("form-control input text-default")
            setPriceUniqueError('')
        }
    }, [priceUnique])

    useEffect(() => {
        if (payRules == '0') {
            setMonthRulesClass("form-control input text-default error")
        } else {
            setMonthRulesClass("form-control input text-default")
        }
    }, [payRules])

    const listAcessAbn = [
        { label: "Semaine", value: "WEEK", id: 1 },
        { label: "Mois", value: "MONTH", id: 2 },
        { label: "Trimestre", value: "QUARTER", id: 3 },
        { label: "Semestre", value: "SEMESTER", id: 4 },
    ]

    const [toChange, setToChange] = useState<boolean>(false)

    const ajouterArray = () => {
        setPricings(prevArray => [
            ...prevArray,
            {
                id: null,
                period: "MONTH",
                status: "ACTIVE",
                price: 0,
                nbPeriod: 0,
                currency: "EUR",
                startDate: new Date(Date.now()),
                endDate: null
            }
        ]);
        setToChange(true)
    };

    function getPaymentPeriodicValue(data: any) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === 'payment_periodic') {
                return data[i]?.value === 'true';
            }
        }
        return false;
    }

    function getPaymentSubscriptionValue(data: any) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key == 'payment_subscription') {
                return data[i]?.value == 'true';
            }
        }
        return false;
    }

    useEffect(() => {
        setPaymentSubscription(getPaymentSubscriptionValue(props?.limitations))
        setPaymentPeriodic(getPaymentPeriodicValue(props?.limitations))
    }, [props?.limitations])

    const handleKeyPress = (event: any) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    const supprimerArray = (index: number) => {
        setPricings(prevArray => {
            const newArray = [...prevArray].filter((p: any) => p.nbPeriod != null);
            newArray.splice(index, 1);
            const filteredArray = [...prevArray].filter((p: any) => p.nbPeriod == null);
            return [...filteredArray, ...newArray];
        });

        if (pricings.filter((p: any) => p.nbPeriod != null).length == 1) {
            setCheckFacPay(false)
            setPaymentEasy(false)
        }
    };

    const listAcess = [
        { label: "Illimité", value: "Illimité", id: 1 },
        { label: "Période limitée", value: "Période limitée", id: 2 },
    ]

    useEffect(() => {
        let offer = 0 ;
        
        if(typeof window.location.href !== 'undefined'){
            offer = parseInt(returnDynamicValueFromUrl(3), 10)
        }

        if (typeof offer === 'number' && offer!==undefined && offer !== 0 && offer !== null   && !isNaN(offer)) {
            setOfferId(offer)
        }
    }, [window.location.href])

    useEffect(() => {
        if ( offerId !== 0 ) {
            props?.getOfferPricingCourse(offerId)
        }
    }, [offerId])


    useEffect(() => {
        setPricings(props?.courseTarificationForm?.pricings)
        setOfferTraining(props?.courseTarificationForm)
        setAccess(props?.courseTarificationForm?.rules)
        if (props.courseTarificationForm?.rules?.find((item: any) => item.key === "nb_month_access") && props.courseTarificationForm?.rules.find((item: any) => item.key === "nb_month_access")?.value == "-1") {
            setDefaultValue("Illimité")
        }
        if (props.courseTarificationForm?.rules?.find((item: any) => item.key === "nb_month_access") && props.courseTarificationForm?.rules.find((item: any) => item.key === "nb_month_access")?.value !== "-1") {
            setDefaultValue("Période limitée")
        }
        if (props.courseTarificationForm?.pricings?.find((item: any) => item.nbPeriod == null && item.period != "ONESHOT")) {
            setDefaultValueAbonnement(props.courseTarificationForm.pricings?.find((item: any) => item.nbPeriod == null && item.period != "ONESHOT")?.period)

        }
        let types: string[] = [];
        const prices: any = props?.courseTarificationForm?.pricings
        if (Array.isArray(prices)) {

            for (let pricing of prices) {
                if (pricing.price === 0 && pricing.price != null && pricing.period === "ONESHOT") {
                    types.push("FREE");
                    setTypeTarification("Free")

                }
                if (pricing.price > 0 && pricing.period === "ONESHOT") {
                    types.push("UNIQUE");
                    setTypeTarification("Pay")
                    setCheckUniquePay(true)

                }
                if (pricing.nbPeriod !== null && pricing.period !== "ONESHOT") {
                    types.push("PAR FACILITÉ");
                    setTypeTarification("Pay");
                    setCheckFacPay(true)

                }
                if (pricing.period !== "ONESHOT" && pricing.nbPeriod === null) {
                    types.push("ABONNEMENT");
                    setTypeTarification("Abn")

                }
            }
        }
    }, [props?.courseTarificationForm])

    useEffect(() => {
        async function fetchData() {
            await getCummulSalesByOfferFinance(filterPayed, offerId);
            const offerInfo = listOffersPayed?.find((offer: any) => offer?.id === offerId);
            setTrainingType(offerInfo?.trainingType);

            setTitle(offerInfo?.title);
            setProfName(offerInfo?.personDto?.firstName + " " + offerInfo?.personDto?.lastName);
            setCreationDate(dateFormat(offerInfo?.creationDate, "dd/mm/yyyy"));
        }
        if (offerId !==0) {
            fetchData();
        }


        if(typeof parseInt(returnDynamicValueFromUrl(3), 10)==='number'){
            fetchData();
        }
        
    }, [listOffersPayed, props?.connectedUser, props?.paymentMethod, offerId]);

    const getCummulSalesByOfferFinance = async (filter: any, idOffer: any) =>{
        const result = await getCummulSalesByOfferFinanceApi(filter, idOffer)
        setCumulSalesByOffer(result)
    }

    const getDateDebutAndFin = async (e: any) => {
        let filter: any = {};
        let year = new Date().getFullYear();
        let month: any = "";
        if (e.length === 4) {
            year = parseInt(e);
            filter = {
                startDate: `${year}-01-01`,
                endDate: `${year}-12-31`,
            };
        }
        else if (e.length === 2) {
            month = e;
            filter = {
                startDate: `${year}-${month}-01`,
                endDate: `${year}-${month}-${new Date(year, month, 0).getDate()
                    }`,
            };
        }
        else if (e.length === 1) {
            month = "0" + e;
            filter = {
                startDate: `${year}-${month}-01`,
                endDate: `${year}-${month}-${new Date(year, month, 0).getDate()
                    }`,
            };
        }

        setFilterPayed({
            yearOrMonthbyPayed: e,
            startDate: filter.startDate,
            endDate: filter.endDate,
        });

        setStartDate(filter.startDate);
        setEndDate(filter.endDate);


        if (offerId) {
            await getCummulSalesByOfferFinance(filter, offerId);
        }

    };

    const openModalFn = async () => {
        await props?.openModal({
            idModal: ModalIDS.CONFIRM_NEW_TRAINING_PRICE, size: "md", data: {
                pricings: pricings,
                rules: access,
                trainingType: trainingType
            }
        })
    }

    const addUniquePayment = async (e: any) => {
        if (e.target.checked == true) {
            setPricings(prevArray => [
                ...prevArray,
                {
                    id: null,
                    period: "ONESHOT",
                    status: "ACTIVE",
                    price: 1,
                    nbPeriod: null,
                    currency: "EUR",
                    startDate: new Date(Date.now()),
                    endDate: null
                }
            ]);
        } else {
            setPaymentUnique(false)
            const object = [...pricings].filter((item: any) => item.period != "ONESHOT");
            setPricings(object)
        }

        setToChange(true)
        await props?.saveChangeTo(true)

    }
    
    const addFacPayment = async (e: any) => {
        if (e.target.checked == true) {
            setPricings(prevArray => [
                ...prevArray,
                {
                    id: null,
                    period: "MONTH",
                    status: "ACTIVE",
                    price: 0,
                    nbPeriod: 0,
                    currency: "EUR",
                    startDate: new Date(Date.now()),
                    endDate: null
                }
            ]);
        } else {
            const object = [...pricings].filter((item: any) => item.nbPeriod == null);
            setPricings(object)
        }
        setToChange(true)
        await props?.saveChangeTo(true)



    }

    const getStartDate = async (e: any) => {
        let filter: any
        filter = {
            startDate: e,
            endDate: endDate
        }
        if (offerId !==0) {
            await getCummulSalesByOfferFinance(filter, offerId)
        }
    }

    const getendDate = async (e: any) => {

        let filter: any
        filter = {
            startDate: startDate,
            endDate: e
        }
        if (offerId !==null) {
            await getCummulSalesByOfferFinance(filter, offerId)
        }

    }

    const handleTrainingType = (t: any) => {
        if (trainingType === 'COURSE') {
            return t('courseBy')
        }
        if (trainingType === 'PATH') {
            return 'Parcours donné par'
        }
        else { return 'Session donnée par' }
    }

    const check = async (event: any) => {
        setTypeTarification(event.target?.value)
    }

    useEffect(() => {
        if (paymentUniqueAccess === "Illimité") {
            const object = [...access]
            object.find((item: any) => item.key === "nb_month_access").value = "-1"
            setAccess(object)
            setToChange(true)
            props?.saveChangeTo(true)

        }

    }, [paymentUniqueAccess])

    const changeRules = async (e: any) => {
        const object = [...access]
        object.find((item: any) => item.key === "nb_month_access").value = e.target?.value
        setAccess(object)
        setToChange(true)
        await props?.saveChangeTo(true)

    }

    const changePricingUnique = async (e: any) => {

        if (!!pricings && pricings?.find((item: any) => item.period == "ONESHOT")) {
            const object: any = [...pricings]
            object.find((item: any) => item.period == "ONESHOT").price = Number(e.target?.value)
            setPricings(object)
        }
        setToChange(true)
        await props?.saveChangeTo(true)

    }

    const changePricingFree = async (e: any) => {
        if (e.target.checked === true || e.type == "click") {
            const object = {
                "id": null,
                "period": "ONESHOT",
                "status": "ACTIVE",
                "price": 0,
                "nbPeriod": null,
                "currency": "EUR",
                "startDate": new Date(Date.now()),
                "endDate": null
            }
            setPricings([object])
            const object1 = [...access]
            object1.find((item: any) => item.key === "nb_month_access").value = "-1"
            setAccess(object1)
        }
        setToChange(true)
        await props?.saveChangeTo(true)


    }

    const changeAbonnement = async (e: any, type: any) => {
        const object: any = [...pricings]
        if (!!pricings && pricings?.find((item: any) => item.period !== "ONESHOT" && item.nbPeriod == null) && type == "PRIX") {
            object.find((item: any) => item.period !== "ONESHOT" && item.nbPeriod == null).price = Number(e.target?.value)

        }
        if (!!pricings && pricings?.find((item: any) => item.period !== "ONESHOT" && item.nbPeriod == null) && type == "SELECT") {

            object.find((item: any) => item.period !== "ONESHOT" && item.nbPeriod == null).period = e
        }
        setPricings(object)

        setToChange(true)
        await props?.saveChangeTo(true)

    }

    const changePricingFac = async (index: any, price: any, time: any) => {
        if (price !== 0) {
            const object = [...pricings]
            object.filter((p: any) => p.nbPeriod != null)[index].price = Number(price);
            setPricings(object)

        }

        if (time !== 0) {
            const object = [...pricings]
            object.filter((p: any) => p.nbPeriod != null)[index].nbPeriod = Number(time);
            setPricings(object)

        }
        setToChange(true)
        await props?.saveChangeTo(true)


    }

    const { t }: any = props;

    useEffect(() => {
        if (pricings?.length == 0) {
            setCheckFacPay(false)
            setCheckUniquePay(false)
        }
        if (!!pricings?.find((item: any) => item?.period === "ONESHOT")) { setPriceUnique(pricings?.find((item: any) => item?.period === "ONESHOT")?.price) }
        if (!!pricings?.find((item: any) => item?.period !== "ONESHOT" && item?.nbPeriod == null)) {
            setPriceAbn(pricings?.find((item: any) => item?.period !== "ONESHOT" && item?.nbPeriod == null)?.price)
        }
    }, [pricings])

    const handleAbonnement = async (e: any) => {
        if (e.target.checked == true || e.type == "click") {

            setPricings([
                {
                    id: null,
                    period: "MONTH",
                    status: "ACTIVE",
                    price: 0,
                    nbPeriod: null,
                    currency: "EUR",
                    startDate: new Date(Date.now()),
                    endDate: null
                }
            ]);

        } else {
            const object = [...pricings].filter((item: any) => !(item.period !== "ONESHOT" && item.nbPeriod === null));
            setPricings(object)
        }
        const object1: any = [...access]
        object1.find((item: any) => item.key === "nb_month_access").value = "-1"
        setAccess(object1)
        await props?.saveChangeTo(true)
        setToChange(true)

    }

    useEffect(() => {
        setToChange(props?.changed)
    },[props?.changed])

    useEffect(() => {
        props?.courseTarificationForm?.rules?.find((item: any) => item.key === "nb_month_access")?.value == "-1" ? setAccecebilty("Illimité")
            : setAccecebilty(props?.courseTarificationForm?.rules?.find((item: any) => item.key === "nb_month_access")?.value + " mois")
    }, [props?.courseTarificationForm])

    const annuleUpdate = async () => {
        setPricings(props?.courseTarificationForm?.pricings)
        setAccess(props?.courseTarificationForm?.rules)
        await props?.saveChangeTo(false)
        setToChange(false)
        for (let pricing of props?.courseTarificationForm?.pricings) {
            if (pricing?.price === 0 && pricing?.price != null && pricing?.period === "ONESHOT") {

                setTypeTarification("Free")

            }
            if (pricing?.price > 0 && pricing?.period === "ONESHOT") {

                setTypeTarification("Pay")
                setCheckUniquePay(true)

            }
            if (pricing?.nbPeriod !== null && pricing?.period !== "ONESHOT") {

                setTypeTarification("Pay");
                setCheckFacPay(true)

            }
            if (pricing?.period !== "ONESHOT" && pricing?.nbPeriod === null) {

                setTypeTarification("Abn")

            }
        }
    }

    useEffect(() => {
        if (!!access?.find((item: any) => item.key === "nb_month_access")) {
            setPayRules(Math.abs(access?.find((item: any) => item.key === "nb_month_access")?.value))
        }
    }, [access])

    useEffect(() => {
        if (!Array.isArray(pricings)) {
            setCheckPrice(false);
            return;
        }

        for (const item of pricings) {
            if (item?.period !== "ONESHOT" && item?.price === 0) {
                if (item?.nbPeriod === 0 || item?.nbPeriod == null) {
                    setCheckPrice(false);
                    break;
                }
                setCheckPrice(false);
            } else if (item?.period !== "ONESHOT" && item?.price !== 0 && item?.nbPeriod === 0) {
                setCheckPrice(false);
                break;
            } else {
                setCheckPrice(true);
            }
        }
    }, [pricings]);


    useEffect(() => {

        if (props?.courseTarificationForm?.rules !== undefined) {
            let trainingRule = props?.courseTarificationForm?.rules?.filter((rule: any) => rule?.key === 'id_course' || rule?.key === 'id_session' || rule?.key === 'id_path')
            let filter = {
                "trainingId": trainingRule[0]?.value,
                "trainingType": trainingRule[0].key === 'id_course' ? "COURSE" : trainingRule[0].key === 'id_path' ? "PATH" : "SESSION"
            }
            switch (trainingRule[0]?.key) {
                case 'id_course':
                    axiosInstance.get(process.env.REACT_APP_BASE_URL3 + "follow/inscrit/coursebyprof/" + trainingRule[0]?.value)
                        .then((res: any) => {
                            setInscribedStudentNumber(res.data)

                        })
                    break;
                case 'id_path':
                    axiosInstance.get(process.env.REACT_APP_BASE_URL3 + "follow/inscrit/path/" + trainingRule[0]?.value)
                        .then((res: any) => {
                            setInscribedStudentNumber(res.data)
                        })
                    break;
                default:
                    axiosInstance.get(process.env.REACT_APP_BASE_URL3 + "follow/inscrit/session/" + trainingRule[0]?.value)
                        .then((res: any) => {
                            setInscribedStudentNumber(res.data)
                        })
            }



        }

    }, [props?.courseTarificationForm?.rules])


    


    return (

        <>
            <div className="justify-content-center" style={{ marginTop: "64px" }}>
                <div className="py-4" style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="mx-auto my-2 flex-wrap" style={{ maxWidth: '1200px' }}>
                        <div  >

                            <span className="H2-Headline neutral-3">{title}</span>
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                            <div className="d-flex flex-row me-3">
                                <span className="body-sm bold neutral-3 me-2">{handleTrainingType(t)} </span>
                                <span className="body-sm bold black-800">{profName}</span>
                            </div>
                            <div className="d-flex flex-row">
                                <span className="body-sm bold neutral-3 me-2">{trainingType === 'SESSION' ? t('PublishedAte') : t('PublishedAt')} </span>
                                <span className="body-sm bold black-800">{creationDate}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="m-auto py-5 px-4 d-flex justify-content-between  flex-wrap " style={{ maxWidth: "1224px" }}>
                    <div className="d-flex flex-column me-4 ">
                        <span className="H3-Headline" style={{ lineHeight: '43px' }}>Vue d’ensemble</span>
                        <span className="body-md" style={{ maxWidth: 347 }}>Vous trouverez dans ce récapitulatif toutes les informations et le bilan concernant ce cours.</span>
                    </div>

                    <div className="d-flex flex-column" >

                        <div className="d-flex flex-column">


                            <div className="d-flex flex-row align-items-center  flex-wrap">

                                <div className="bd-highlight select-input me-4 py-2" style={{ minWidth: 215, marginRight: '10px' }}>
                                    <select className="select-payment"
                                        id="listNamesProfessors" value={filterPayed.yearOrMonthbyPayed} onChange={(e: any) => { setFilterPayed({ yearOrMonthbyPayed: e.target?.value }); getDateDebutAndFin(e.target?.value) }} >
                                        <option className="option-choice" value={new Date().getFullYear()} >{t('Année') + " " + new Date().getFullYear()} </option>
                                        {listYearAndMonth?.map((obj: any, i: number) => {
                                            if (Number.isInteger(obj.label))
                                                return <option key={i + 'b'} className="option-choice" value={obj?.value}>{t('Année') + " " + t(obj.label)}</option>
                                            else
                                                return <option key={i + 'b'} className="option-choice" value={obj?.value}>{t(obj.label) + " " + new Date().getFullYear()}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="d-flex flex-row flex-wrap">
                                    <div className="d-flex flex-row align-items-center py-2">
                                        <span className="body-md bold black-800 me-2" >{t('Du')} </span>

                                        <input dir="ltr" style={{ minWidth: 215, marginRight: '10px' }} type="date" className="form-control input medium input-date text-default" value={startDate} onChange={(e: any) => { setStartDate(e.target?.value); getStartDate(e.target?.value) }} />

                                    </div>

                                    <div className="d-flex flex-row align-items-center py-2">
                                        <span className="ms-1 body-md bold black-800 me-2">{t('Au')} </span>
                                        <input dir="ltr" style={{ minWidth: 215, marginRight: '10px' }} type="date" className="form-control input medium input-date text-default" value={endDate} onChange={(e: any) => { setEndDate(e.target?.value); getendDate(e.target?.value) }} />

                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-column mt-4">
                                <div className="d-flex flex-row ">
                                    <div className="  me-4  d-flex justify-content-between w-100" style={{ maxWidth: "none" }}>
                                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                                            <div className="d-flex flex-column me-4 ">
                                                <span className="H2-Headline" style={{ lineHeight: '43px' }}>{cumulSalesByOffer?.pricingsByOfferDto === null ? 0.00 : cumulSalesByOffer?.pricingsByOfferDto?.sumPrice}{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                                                <span className="body-md">{t('RésultatCumulé')}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" me-4 py-3 d-flex justify-content-between w-100" style={{ maxWidth: "none" }}>
                                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                                            <div className="d-flex flex-column me-4 ">
                                                <span className="H2-Headline" style={{ lineHeight: '43px' }}>{inscribedStudentNumber}</span>
                                                <span className="body-md">{t('etudiantsInscrits')}</span>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <hr className="mt-2" style={{ backgroundColor: "#DADBDF", opacity: 1 }} />

                                {
                                    cumulSalesByOffer?.pricingsByOfferDto?.prices?.map((item: any) =>
                                        <> <div className="d-flex flex-row flex-wrap">
                                            <div className="me-4  d-flex w-20" >
                                                <div className="d-flex flex-row flex-wrap ">
                                                    <div className="d-flex flex-column me-4 ">
                                                        <span className="body-md">Type de plan</span>
                                                        <span className="body-xl bold" style={{ lineHeight: '43px' }}>{item?.nbPeriod !== null ? "Par Facilité" : (item?.period === "ONESHOT" ? "Unique" : "Abonnement")}</span>

                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                item?.nbPeriod == null && item?.period == "ONESHOT" &&
                                                <div className=" me-4 d-flex w-20 " >
                                                    <div className="d-flex flex-row flex-wrap">
                                                        <div className="d-flex flex-column me-4 ">
                                                            <span className="body-md">Tarif</span>
                                                            <span className="body-xl bold" style={{ lineHeight: '43px' }}>{Object.freeze(item?.price)}{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                item?.nbPeriod !== null && item.period !== "ONESHOT" &&
                                                <div className=" me-4 d-flex w-20 " >
                                                    <div className="d-flex flex-row flex-wrap">
                                                        <div className="d-flex flex-column me-4 ">
                                                            <span className="body-md">Tarif</span>
                                                            <span className="body-xl bold" style={{ lineHeight: '43px' }}>{item?.price}{props?.paymentMethod?.currency === "TND" ? "TND" : "€"} X    <span className="body-sm" style={{ lineHeight: '20px' }}>{item?.nbPeriod + " " + t(item?.period)} </span> </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                item?.nbPeriod == null && item.period !== "ONESHOT" &&
                                                <div className=" me-4 d-flex  w-20" >
                                                    <div className="d-flex flex-row flex-wrap">
                                                        <div className="d-flex flex-column me-4 ">
                                                            <span className="body-md">Tarif</span>
                                                            <span className="body-xl bold" style={{ lineHeight: '43px' }}>{item?.price}{props?.paymentMethod?.currency === "TND" ? "TND" : "€"} /    <span className="body-sm" style={{ lineHeight: '20px' }}>{t(item?.period)} </span> </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            <div className=" me-4  d-flex w-20" >
                                                <div className="d-flex flex-row flex-wrap">
                                                    <div className="d-flex flex-column me-4 ">
                                                        <span className="body-md">Accessibilité</span>
                                                        <span className="body-xl bold" style={{ lineHeight: '43px' }}>{accecebilty}</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>


                    </div>

                </div>

                <div style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="m-auto py-5 px-4 d-flex justify-content-between  flex-wrap " style={{ maxWidth: '1224px' }}>

                        <div className="d-flex flex-column me-4 ">
                            <span className="H3-Headline" style={{ lineHeight: '43px' }}>Tarification</span>
                            <span className="body-md" style={{ maxWidth: 347 }}>Voici votre tarification actuel. Vous pouvez apporter des modifications et mettre à jour la tarification de ce cours.</span>
                            <div className='d-flex flex-row mt-3'>
                                {toChange && checkPrice && <>

                                    <button type="button" className="btn-Primary medium me-4" style={{ width: 145, height: 48 }} onClick={() => { openModalFn() }}>
                                        METTRE À JOUR
                                    </button>
                                    <button type="button" className="btn-Secondary medium" style={{ width: 102, height: 48 }} onClick={annuleUpdate} >
                                        Annuler
                                    </button>
                                </>}


                            </div>
                        </div>

                        <div className=" mt-4" >
                            <div style={{ maxWidth: 800 }} className="px-2 mx-auto d-flex flex-column py-4 ">
                                <div style={{ width: 798, backgroundColor: '#FFFFFF', minHeight: 147, paddingBottom: "23px" }} className="d-flex flex-column">
                                    <div className="d-flex align-items-center" style={{ marginLeft: "28px", marginTop: "30px" }}>
                                        <div className="d-flex align-items-center me-4">
                                            <input dir="ltr" className="input-style-radio" onClick={(e) => { check({ target: { value: 'Free' } }) }} onChange={(e: any) => { changePricingFree(e) }} type="radio" name="Free" id="Free" checked={typeTarification === 'Free'} />
                                            <label htmlFor="Free"></label>
                                            <span onClick={(e: any) => { check({ target: { value: 'Free' } }); changePricingFree(e) }} style={{ cursor: 'pointer' }} className={`body-md black-800 ps-2  ${typeTarification === 'Free' ? 'underline-text' : ''}`}> Accès gratuit</span>
                                        </div>
                                        <div className="d-flex align-items-center me-4">
                                            <input dir="ltr" className="input-style-radio" onClick={(e) => { check(e) }} onChange={() => { setPricings([]) }} type="radio" name="Pay" id="Pay" value="Pay" checked={typeTarification === 'Pay'} />
                                            <label htmlFor="Pay"></label>
                                            <span onClick={() => { check({ target: { value: 'Pay' } }); setPricings([]) }} style={{ cursor: 'pointer' }} className={`body-md black-800 ps-2  ${typeTarification === 'Pay' ? 'underline-text' : ''}`}> Accès payant</span>
                                        </div>
                                        <div className="d-flex align-items-center"
                                            style={{
                                                opacity: !paymentSubscription ? '0.3' : '1',
                                                pointerEvents: (!paymentSubscription) ? 'none' : 'auto',
                                            }}>
                                            <input dir="ltr" className="input-style-radio" onClick={(e) => { check({ target: { value: 'Abn' } }) }} onChange={(e: any) => { handleAbonnement(e) }} type="radio" name="Abn" id="Abn" checked={typeTarification === 'Abn'} />
                                            <label htmlFor="Abn"></label>
                                            <span style={{ cursor: 'pointer' }} onClick={(e) => { check({ target: { value: 'Abn' } }); handleAbonnement(e) }} className={`body-md black-800 ps-2  ${typeTarification === 'Abn' ? 'underline-text' : ''}`}> Accès abonnement</span>
                                        </div>
                                        {!paymentSubscription &&
                                            <span style={{ cursor: "pointer", marginLeft: "6px" }}>
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_20661_107782)">
                                                        <rect y="0.5" width="17" height="17" rx="8.5" fill="#F5B95F" />
                                                        <path d="M11.3332 13.2502V14.6668H5.6665V13.2502H11.3332ZM7.7915 6.15975V11.8335H9.20817V6.15975H11.3332L8.49984 3.3335L5.6665 6.15975H7.7915Z" fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_20661_107782">
                                                            <rect y="0.5" width="17" height="17" rx="8.5" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </span>
                                        }
                                    </div>

                                    <hr style={{ backgroundColor: "#DADBDF", margin: "20px 26px 20px 26px", height: "2px" }} />
                                    {typeTarification === "Free" &&
                                        <div style={{ marginLeft: "30px" }}>
                                            <span className="body-sm black-800">Offrez un plan gratuit à vos apprenants</span>
                                        </div>}


                                    {typeTarification === "Pay" &&
                                        <>
                                            {(props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) && <>
                                                <div className='d-flex flex-row  d-flex align-items-center notif-warning-card mx-auto' style={{ height: "42px", width: "93%", marginBottom: "20px" }}>
                                                    <svg width="25" height="29" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.333008 25.9998H29.6663L14.9997 0.666504L0.333008 25.9998ZM16.333 21.9998H13.6663V19.3332H16.333V21.9998ZM16.333 16.6665H13.6663V11.3332H16.333V16.6665Z" fill="#F5B95F" />
                                                    </svg>
                                                    <div className='body-sm ' style={{ marginLeft: "17px", marginTop: "4px" }}>
                                                        Connectez-vous à votre moteur de paiement pour pouvoir accepter les paiements.<span className="notif-link" onClick={() => { history.push(`${returnStartPathUrl()}/param`) }}>   Gérer les paiements</span>
                                                    </div>
                                                </div>
                                            </>}

                                            <div style={{
                                                marginLeft: "25px", marginRight: "25px", pointerEvents: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? 'none' : 'auto',
                                                opacity: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? '0.3' : '1'
                                            }}>
                                                <div className='d-flex flex-row'>
                                                    <div className="d-flex align-items-top flex-column" style={{ maxWidth: '225px', minWidth: '225px', marginLeft: "30px" }}>
                                                        <label className="form-default black-800 mb-1">Accessible</label>
                                                        <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValue} options={listAcess} onChange={(e: any) => setPaymentUniqueAccess(e)} placeholder={"Sélectionner"} />
                                                    </div>
                                                    {(paymentUniqueAccess === "Période limitée" || access?.find((item: any) => item.key === "nb_month_access")?.value != "-1") &&

                                                        <div className="d-flex align-items-top flex-column" style={{ marginLeft: "30px" }}>
                                                            <label className="form-default black-800 mb-1">Pendant</label>
                                                            <div className="input-form-control">
                                                                <input type="tel" className={monthRulesClass} placeholder="" value={

                                                                    payRules}
                                                                    style={{
                                                                        WebkitAppearance: "none",
                                                                        MozAppearance: "textfield",
                                                                        appearance: "none",
                                                                        margin: 0,
                                                                        paddingRight: "16px"
                                                                    }}
                                                                    onKeyPress={handleKeyPress}
                                                                    onChange={(e: any) => changeRules(e)}
                                                                />
                                                                <span style={{ fontWeight: 500 }} >
                                                                    Mois
                                                                </span>
                                                            </div>


                                                        </div>}
                                                </div>

                                                <div style={{
                                                    marginTop: '24px', pointerEvents: 'auto'
                                                    , opacity: '1'
                                                }}>
                                                    <div className="d-flex align-items-top flex-row">
                                                        <input className="input-style-checkbox" type="checkbox" id="checkBtn" checked={checkUniquePay} onChange={(e: any) => { setCheckUniquePay(!checkUniquePay); addUniquePayment(e) }} />
                                                        <label style={{ marginTop: "3px" }} htmlFor="checkBtn" />

                                                        <div className="d-flex flex-column">
                                                            <span className="ps-2 body-md bold">Paiement unique</span>
                                                            <span className="ps-2 body-sm ">Proposez aux apprenants des frais uniques pour accéder au contenu. </span>
                                                        </div>
                                                        {paymentUnique ?
                                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => setPaymentUnique(!paymentUnique)}>expand_less</span>
                                                            :
                                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => setPaymentUnique(!paymentUnique)}>expand_more</span>
                                                        }
                                                    </div>
                                                    {paymentUnique &&
                                                        <div className="d-flex align-items-top flex-row" style={{ marginTop: "20px", marginLeft: "30px" }}>
                                                            <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>
                                                                <label className="form-default black-800 mb-1">Frais d’inscription</label>
                                                                <div className="input-form-control">
                                                                    <input type="number" className={priceUniqueClass} placeholder=""
                                                                        ref={paymentUniquePriceRef}
                                                                        style={{
                                                                            WebkitAppearance: "none",
                                                                            MozAppearance: "textfield",
                                                                            appearance: "none",
                                                                            margin: 0
                                                                        }}
                                                                        //onKeyPress={handleKeyPress}
                                                                        value={priceUnique}
                                                                        onChange={(e) => { changePricingUnique(e) }}

                                                                    />
                                                                    <span style={{ fontWeight: 500 }} >
                                                                        TTC
                                                                    </span>
                                                                </div>
                                                                <span className="error-input2">
                                                                    {priceUniqueEror}
                                                                </span>
                                                            </div>



                                                        </div>}
                                                </div>
                                                <div style={{
                                                    marginTop: '24px', pointerEvents: (paymentPeriodic === false) ? 'none' : 'auto',
                                                    opacity: (paymentPeriodic === false) ? '0.3' : '1'
                                                }}>
                                                    <div className="d-flex align-items-top flex-row">
                                                        <input className="input-style-checkbox" type="checkbox" id="checkBtn2" checked={checkFacPay} onChange={(e: any) => { setCheckFacPay(!checkFacPay); addFacPayment(e) }} />
                                                        <label style={{ marginTop: "3px" }} htmlFor="checkBtn2" />
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex flex-row">
                                                                <span className="ps-2 body-md bold">Paiement par facilité</span>
                                                                {!paymentPeriodic &&
                                                                    <span style={{ cursor: "pointer", marginLeft: "6px" }}>
                                                                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clip-path="url(#clip0_20661_107782)">
                                                                                <rect y="0.5" width="17" height="17" rx="8.5" fill="#F5B95F" />
                                                                                <path d="M11.3332 13.2502V14.6668H5.6665V13.2502H11.3332ZM7.7915 6.15975V11.8335H9.20817V6.15975H11.3332L8.49984 3.3335L5.6665 6.15975H7.7915Z" fill="white" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_20661_107782">
                                                                                    <rect y="0.5" width="17" height="17" rx="8.5" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </span>
                                                                }
                                                            </div>
                                                            <span className="ps-2 body-sm ">Donnez la possibilité à vos apprenants de payer par tranche selon l'échéance <br />de votre choix. </span>
                                                        </div>
                                                        {paymentEasy ?
                                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => setPaymentEasy(!paymentEasy)}>expand_less</span>
                                                            :
                                                            <span className="material-icons-outlined ms-auto pointer " onClick={() => setPaymentEasy(!paymentEasy)}>expand_more</span>
                                                        }
                                                    </div>
                                                    {(paymentEasy) &&
                                                        <>
                                                            {pricings?.filter((p: any) => p.nbPeriod != null)?.map((ligne, index) => (
                                                                <div key={index}>
                                                                    {index === pricings?.filter((p: any) => p.nbPeriod != null)?.length - 1 ?

                                                                        <TarificationPeriod key={index} ajouterLigne={ajouterArray} supprimerLigne={supprimerArray} showAddButton={pricings?.filter((a: any) => a?.nbPeriod != null)?.length < 3} index={index} item={ligne} changePricingFac={changePricingFac} />
                                                                        :
                                                                        <TarificationPeriod key={index} ajouterLigne={ajouterArray} supprimerLigne={supprimerArray} showAddButton={false} item={ligne} index={index} changePricingFac={changePricingFac} />}

                                                                </div>
                                                            ))}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>}


                                    {typeTarification === "Abn" &&
                                        <div style={{ marginLeft: "25px", marginRight: "25px" }}>

                                            {(props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) && <>
                                                <div className='d-flex flex-row  d-flex align-items-center notif-warning-card' style={{ height: "42px" }}>
                                                    <svg width="25" height="29" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.333008 25.9998H29.6663L14.9997 0.666504L0.333008 25.9998ZM16.333 21.9998H13.6663V19.3332H16.333V21.9998ZM16.333 16.6665H13.6663V11.3332H16.333V16.6665Z" fill="#F5B95F" />
                                                    </svg>
                                                    <div className='body-sm ' style={{ marginLeft: "17px", marginTop: "4px" }}>
                                                        Connectez-vous à votre moteur de paiement pour pouvoir accepter les paiements.<span className="notif-link" onClick={() => { history.push(`${returnStartPathUrl()}/param`) }}>   Gérer les paiements</span>
                                                    </div>
                                                </div>
                                            </>}
                                            <div style={{
                                                marginTop: '24px', pointerEvents: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? 'none' : 'auto'
                                                , opacity: (props?.paymentSchoolInfo === null || props?.paymentSchoolInfo?.isActivated === false) ? '0.3' : '1'
                                            }}>
                                                <div className="d-flex align-items-top flex-row">

                                                    <div className="d-flex flex-column">
                                                        <span className="ps-2 body-sm ">Proposez aux apprenants des frais mensuels récurrents pour accéder au <br /> contenu du cours. </span>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-top flex-row ps-2" style={{ marginTop: "20px" }}>
                                                    <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>
                                                        <label className="form-default black-800 mb-1">Frais d’abonnement</label>
                                                        <div className="input-form-control">
                                                            <input type="number" className={priceAbnClass} placeholder=""
                                                                style={{
                                                                    WebkitAppearance: "none",
                                                                    MozAppearance: "textfield",
                                                                    appearance: "none",
                                                                    margin: 0
                                                                }}
                                                                //onKeyPress={handleKeyPress}
                                                                value={priceAbn}
                                                                onChange={(e) => { changeAbonnement(e, "PRIX") }}
                                                            />
                                                            <span style={{ fontWeight: 500 }} >
                                                                TTC
                                                            </span>
                                                        </div>
                                                        <span className="error-input2">
                                                            {priceAbnEror}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-top flex-column" style={{ maxWidth: '225px', minWidth: '225px' }}>
                                                        <label className="form-default black-800 mb-1">Par</label>
                                                        <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueAbonnement} options={listAcessAbn} onChange={(e: any) => changeAbonnement(e, "SELECT")} placeholder={"Sélectionner"} />
                                                    </div>





                                                </div>
                                            </div>

                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    saveChangeTo,
    openModal,
    getOfferCourse,
    getOfferPricingCourse,
}, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    history: ownProps?.history,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    courseInfo: state.courseReducer.courseInfo,
    connectedUser: state.authReducer.connectedUser,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    payment: state.courseReducer.payement,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    courseForm: state.courseReducer.courseInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    changed: state.schoolInfoReducer.changeTo,
    limitations: state.schoolInfoReducer.subscription.limitations,
    actualOffer: state.courseReducer.actualOffer,
    school: state.schoolInfoReducer.schoolInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OfferDetailsComponent));
