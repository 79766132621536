import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { getDataFromURL } from '../../../../base/functions/Functions';
import FeedbackTrainingRateComponent from '../../../feedback/feedback-training-rate-component';


const SectionDiplomaComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [sectionContent, setSectionContent] = useState(props?.sectionContent)
    const title = useMemo(() => { return props?.course?.title }, [props?.course?.title])
    const isCompleted = useMemo(() => { return props?.isCompleted }, [props?.isCompleted])
    let diploma = useMemo(() => props?.course?.diploma, [props?.course?.diploma])
    useEffect(() => {
        setSectionContent(props?.sectionContent)
    }, [props?.sectionContent])

    useEffect(() => {
        setSectionContent(props?.sectionContent)
    }, [props?.course])


    const checkForCertificate = async (e: any) => {
        let fileName = props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName + ".pdf"
        getDataFromURL(props?.course?.followUp?.certificationLink, fileName);

    }


    return (
        
        <div className="">
            {diploma != null && 
            props.isMobile ? 
            <div>
                <div className='d-flex align-items-center justify-content-center' style={{height: "48px"}}>
                    <span className="H3-Headline">{t('diplomeButton')}</span>
                </div>
                <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: "#FBFBFD"}}>
                    <div className="text-center"  >
                        <div className="d-flex align-items-center justify-content-center my-5" >
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50" fill="#F0FAF2"/>
                                <g clip-path="url(#clip0_24543_73201)">
                                    <path d="M59.7222 40.2778H56.9444V37.5H43.0556V40.2778H40.2778C38.75 40.2778 37.5 41.5278 37.5 43.0556V44.4444C37.5 47.9861 40.1667 50.875 43.5972 51.3056C44.4722 53.3889 46.3472 54.9583 48.6111 55.4167V59.7222H43.0556V62.5H56.9444V59.7222H51.3889V55.4167C53.6528 54.9583 55.5278 53.3889 56.4028 51.3056C59.8333 50.875 62.5 47.9861 62.5 44.4444V43.0556C62.5 41.5278 61.25 40.2778 59.7222 40.2778ZM40.2778 44.4444V43.0556H43.0556V48.3611C41.4444 47.7778 40.2778 46.25 40.2778 44.4444ZM50 52.7778C47.7083 52.7778 45.8333 50.9028 45.8333 48.6111V40.2778H54.1667V48.6111C54.1667 50.9028 52.2917 52.7778 50 52.7778ZM59.7222 44.4444C59.7222 46.25 58.5556 47.7778 56.9444 48.3611V43.0556H59.7222V44.4444Z" fill="#9DDBA9"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_24543_73201">
                                        <rect width="64" height="64" fill="white" transform="translate(33.3334 33.3334)"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <p className="H4-Subtitle mt-4"> {title}</p>
                        <p className="H4-Subtitle neutral-3 mt-4" >{t('congratulation')}</p>
                        <div className="my-4">
                            {(props?.course?.followUp?.certificationLink!=="") ? <button type="button" className="btn-Secondary  large icon-left" onClick={(e) => { checkForCertificate(e) }}>
                                <span className="material-icons-outlined"> download </span>
                                <div className="m-auto ps-2"> {t('downloadFileCertif')} </div>
                            </button> :
                                <button type="button" className="btn-Secondary loaddata large" onClick={(e) => { checkForCertificate(e) }}>
                                    <div className="threedots-large-Animation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>}
                        </div>
                    </div>
                    
                </div>
                <div className="d-flex mb-5" style={{ backgroundColor: "#FBFBFD"}}>
                    {props?.course?.type === 'COURSE' &&
                    <div className="d-flex py-5 mb-4" >
                        <FeedbackTrainingRateComponent isMobile={props.isMobile} coursInfo={{ id: props?.course?.id, title: props?.course?.title, type: props?.course?.type }} />
                    </div>}
                </div>
            </div>
            :
            
            <div className="mb-4 p-4" style={{ backgroundColor: "#FBFBFD", border: '1px solid #F2F2F5', borderRadius: 3 }}>
                <div className='d-flex'>
                    <div className="d-flex align-items-center justify-content-end" style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "29px", paddingBottom: "27px" }} >
                        <div className=" diplom-state d-flex align-items-center justify-content-center" >
                            <svg width="64" height="64" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7023 4.92903V1.77235C15.7023 0.79179 14.8927 0 13.8712 0H1.84732C0.831296 0 0 0.79179 0 1.77235V15.5212C0 16.507 0.831296 17.304 1.84732 17.304H10.8666V19.2651C10.8666 19.5326 11.0242 19.7738 11.2632 19.9049C11.5023 20.036 11.8011 20.0307 12.0348 19.8944L14.6917 18.3475L17.3485 19.8944C17.4681 19.9626 17.6039 19.9993 17.7452 19.9993C17.8756 19.9993 18.0168 19.9678 18.1309 19.9049C18.37 19.7738 18.5276 19.5326 18.5276 19.2651V13.4971C19.4512 12.5743 20 11.3263 20 9.94719C19.9946 7.46695 18.1472 5.39047 15.7023 4.92903ZM10.8666 15.8358H1.84732C1.66802 15.8358 1.52133 15.6995 1.52133 15.5212V1.77235C1.52133 1.59931 1.66802 1.46822 1.84732 1.46822H13.8712C14.0505 1.46822 14.1809 1.59931 14.1809 1.77235V4.85037C11.5186 5.09158 9.37789 7.28342 9.37789 9.94195C9.37789 11.321 9.94295 12.5743 10.8666 13.4919V15.8358ZM17.0062 17.9647L15.0992 16.8583C14.8547 16.7167 14.5395 16.7167 14.295 16.8583L12.3879 17.9647V14.5564C13.0943 14.8762 13.8767 15.0597 14.6971 15.0597C15.5175 15.0597 16.2999 14.8815 17.0062 14.5564V17.9647ZM14.6971 13.5915C12.6161 13.5915 10.9209 11.9555 10.9209 9.94719C10.9209 7.93888 12.6161 6.30286 14.6971 6.30286C16.778 6.30286 18.4732 7.93363 18.4732 9.94719C18.4732 11.9555 16.778 13.5915 14.6971 13.5915Z" fill="#9ddba9" />
                                <path d="M9.83425 3.88037H2.98828V5.34859H9.83425V3.88037Z" fill="#9ddba9" />
                                <path d="M7.60659 7.91797H2.98828V9.38619H7.60659V7.91797Z" fill="#9ddba9" />
                                <path d="M8.63892 11.9556H2.98828V13.4238H8.63892V11.9556Z" fill="#9ddba9" />
                            </svg>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100" style={{ paddingLeft: "40px", paddingTop: "20px" }} >
                        <div className="d-flex  align-items-center justify-content-end w-100">
                            <span className="material-icons-outlined me-3" style={{ color: "#9ddba9" }}> turned_in_not </span>
                        </div>

                        <span className="H3-Headline mt-3"> {title}</span>
                        <span className="H4-Subtitle neutral-3 mt-4" > {t('congratulation')}<br />{t('diplomaMsg')}</span>
                        <div style={{ paddingBottom: "40px", paddingTop: "40px" }}>
                            {(props?.course?.followUp?.certificationLink!=="") ? <button type="button" className="btn-Secondary  large icon-left" onClick={(e) => { checkForCertificate(e) }}>
                                <span className="material-icons-outlined"> download </span>
                                <div className="m-auto ps-2"> {t('downloadFileCertif')} </div>
                            </button> :
                                <button type="button" className="btn-Secondary loaddata large" onClick={(e) => { checkForCertificate(e) }}>
                                    <div className="threedots-large-Animation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>}
                        </div>
                    </div>
                </div>
                <div >
                    {props?.course?.type === 'COURSE' &&
                    <div className="d-flex pt-5 pb-5" style={{ backgroundColor: "#FBFBFD", border: '1px solid #F2F2F5', borderRadius: 3 }}>
                        <FeedbackTrainingRateComponent isMobile={props.isMobile} coursInfo={{ id: props?.course?.id, title: props?.course?.title, type: props?.course?.type }} />
                    </div>}
                </div>
            </div>
            
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getDataFromURL
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    sectionContent: state.studentTraining.sectionContentDetails.sectionContent,
    course: state.studentTraining.currentCourse,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    courseInfo: state.courseReducer.courseInfo,
    isCompleted: state.studentTraining.isCompleted
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionDiplomaComponent);

