import axiosInstance from "../helpers/interceptors";

const saveActionHistory = async (action:any,idPerson:any,idLegalPerson:any) => {

    let baseURL = process.env.REACT_APP_BASE_URL14;
    const data = await axiosInstance
      .post(baseURL + "audit/create", { action: action, idPerson: idPerson, idLegalPerson: idLegalPerson })
      .then((response) => {
        return response.data;
      });
    return data;
  };
  
  export { saveActionHistory };
  