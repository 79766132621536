import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton'

const CardCourseVerticalLoading: FC<ComponentProps<any>> = () => {

    return (
        <div className="card">
        <Skeleton variant="rect" animation="wave" width={"100%"} height={220} className="skeleton image" />
        <div className="card-body p-4 bg-with-border">
          <div className="H3-Headline m-0">
            <Skeleton variant="text" animation="wave" className="skeleton text" />
          </div>
          <div className="card-text m-0 py-4">
            <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton text" />
          </div>
          <div className="d-flex justify-content-start">
            <Skeleton variant="text" animation="wave" width={'43%'} className="skeleton text" />
          </div>
        </div>
      </div>
    );
}

export default CardCourseVerticalLoading;
