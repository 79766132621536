import { Route, RouteProps } from "react-router-dom"
import NavbarResponsive from '../components/navigation-components/navbar-global-component';
import PrivateRoute from "./PrivateRoute";
interface Props extends RouteProps {
    component?: any;
    path?: any,
    roles?: string[]
    history: any
}
const RouteWithNavbar = (props: Props) => {  
    const { component: Component, roles, history, path, ...rest } = props;
      

    return (<> 
            {
                Array.isArray(roles) ?
                    <PrivateRoute {...rest} roles={props?.roles} path={path} >
                        <div style={{ width:"100%", backgroundColor: 'white' }}>
                            <NavbarResponsive />
                        </div>
                        <div >
                            <Component history={history} />
                        </div>
                    </PrivateRoute> :
                    <Route path={path}>
                        <div style={{ width:"100%", backgroundColor: 'white' }}>
                            <NavbarResponsive />
                        </div>
                        <div >
                            <Component history={history} />
                        </div>
                    </Route>
            }
    </>

    );
};

export default RouteWithNavbar;