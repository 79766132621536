import { ComponentProps, FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AppState } from '../../global-state/store/root-reducers';
import EditorToolbar, { modules, formats } from '../../elements/edit-toolbar-quill/edit-toolbar-quill';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { setCurrentQuestion, submitOpenQuestionQnaResponse, saveAnswers, createProgressQna, emptyProgress } from '../../global-state/actions/exam-actions';
import { useParams, useHistory } from 'react-router-dom';
import { calculateTraining, getCurrentCourse } from '../../global-state/actions/student-training-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from "../../components/modals/modal-ids";

interface Params {
    id: any
}
const PassOpenQuestionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const [submission, setSubmission] = useState<string>("");
    const [question, setQuestion] = useState(props?.question);
    const [isAnswered, setIsAnswered] = useState(false);
    const { t } = useTranslation();
    const { id } = useParams<Params>();
    const [openQuestionForm, setOpenQuestionForm] = useState<any>();
    const history = useHistory();
    const [idProgression, setIdProgression] = useState(props?.idProgression)

    useEffect(() => {
        if (props?.idProgression != null) {
            setIdProgression(props?.idProgression)
        }
    }, [])

    useEffect(() => {
        if (props?.idProgression != null) {
            setIdProgression(props?.idProgression)
        }
    }, [props?.idProgression])



    useEffect(() => {
        setQuestion(props?.question);
        if (props?.studentAnswers && props?.studentAnswers[props?.indexQuestionToPass] && props?.studentAnswers[props?.indexQuestionToPass]?.id !== null) {
            const obj = props?.studentAnswers[props?.indexQuestionToPass];
            obj['questionByExamId'] = props?.question?.id
            if (obj.examId === undefined) {
                obj['examId'] = Number(id);
            }
            obj['studentId'] = props?.connectedUser?.idPerson;
            setOpenQuestionForm(obj);
            setSubmission(obj?.studentOpenQuestionSubmission);
            setIsAnswered(true);
        } else {
            setOpenQuestionForm({
                id: null,
                studentId: props?.connectedUser?.idPerson,
                questionByExamId: props?.question?.id,
                examId: id,
                idProgressExam: idProgression != null ? idProgression : props?.idProgression,
                studentOpenQuestionSubmission: "",
            });
            setSubmission("");
            setIsAnswered(false)
        }
    }, [props?.question]);

    const saveOpenQuestionResponse = async (e: any) => {
        if (props.connectedUser == undefined || (!!props.connectedUser && props?.connectedUser.role == "role_student")) {
            const copy = { ...openQuestionForm };
            copy.studentOpenQuestionSubmission = submission;
            setOpenQuestionForm(copy);
            if (props && props.answers) {
                const indexQna = props?.answers.findIndex((answer: any) => answer?.examId == copy?.examId && copy?.questionByExamId == answer?.questionByExamId);
                if (indexQna !== -1) {
                    copy['id'] = props?.answers.find((answer: any) => answer?.examId == copy?.examId && copy?.questionByExamId == answer?.questionByExamId)?.id
                }
            }
            await props?.submitOpenQuestionQnaResponse(copy, props?.indexQuestionToPass, (props?.currentCourse?.followUp?.progressTraining === null) ? null : props?.currentCourse?.followUp?.progressTraining, props?.currentCourse).then(async (resp: any) => {
                await props?.saveAnswers(resp?.data?.data)

            });
        }

        await postVAlid();
    }
    const changeInput = (data: any) => {
        setSubmission(data);

    }

    const postVAlid = async () => {
        if (props?.indexQuestionToPass < props?.exam?.questionByQnaList.length - 1) {
            props?.setCurrentQuestion(props?.indexQuestionToPass + 1);
        } else {


            if (props.connectedUser == undefined || (!!props.connectedUser && props?.connectedUser.role == "role_student")) {
                let object = { ...props?.progressQna }
                object["status"] = "COMPLETED"
                await props?.createProgressQna(object)
                await props?.emptyProgress()
                await history.push(`${returnStartPathUrl()}/survey/passed`);
            }else{
                await props?.openModal({ idModal: ModalIDS.SUCEESS_PREVISIALIZATION, size: "md", lines: ["C’est la fin de la prévisualisation !"] });            }

        }
    };

    return (
        < div  >
            <div className="d-flex flex-column align-items-center justify-content-center" >
                <span className='body-md'>
                    Répondez à cette question en écrivant votre réponse dans la zone de texte
                </span>
            </div>
            <div style={{ textAlign: 'center' }}>

                <span > {question?.question.questionAnswerExplanation}</span>

            </div>
            <div style={{ paddingTop: "24px" }}>
                <div>
                    <EditorToolbar />
                </div>
                <div style={{ minHeight: 292 }} >
                    <ReactQuill theme="snow" modules={modules} formats={formats} value={submission + ""} onChange={(e: any) => (!isAnswered) ? changeInput(e) : {}} />
                </div>
            </div>
            <div className="row " style={{ margin: "32px 0px 0px 0px " }}  >
                <button disabled={isAnswered} style={{ height: 80 }} className="btn-Primary large px-4" onClick={(e) => { saveOpenQuestionResponse(e) }}> {props?.indexQuestionToPass < props?.exam?.questionByQnaList.length - 1 ? t("Valider_ma_réponse") : "Terminer"} </button>
            </div>

        </div>
    )
}




const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal, closeModal, createProgressQna, emptyProgress, saveAnswers, submitOpenQuestionQnaResponse, setCurrentQuestion, calculateTraining, getCurrentCourse
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    studentAnswers: state.examReducer.listOfResponses,
    currentCourse: state.studentTraining.currentCourse,
    currentTraining: state.studentTraining.currentTraining,
    listFollowUps: state.studentTraining.listFollowUps,
    currentProgress: state.studentTraining.currentTrainingProgress,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    sectionContentDetails: state.studentTraining.sectionContentDetails,
    exam: state.examReducer.examToPass,
    answers: state.examReducer.answers,
    progressQna: state.examReducer.progressQna
});

export default connect(mapStateToProps, mapDispatchToProps)(PassOpenQuestionComponent);
