import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect} from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import { openModal, closeModal} from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../../../components/modals/modal-ids';
import { useHistory } from "react-router";
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import * as _ from 'lodash';
import { returnStartPathUrl } from "../../../helpers/domainCheck";
interface Props {
  group: Group;
  openModal: (params: { idModal: string; size: string; data: any }) => void;
  redirect: (label: string, url: string) => void;
}

interface Group {
  id: string;
  label: string;
  personList: any[];
  childsList: any[];
}


const GroupCard: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props) => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState(false);
    const [open, setOpen] = useState(false);
    const [numberStudents, setNumberstudents]= useState<number>()
    const history = useHistory();
    
     useEffect(()=>{
      let personListId=_.uniq([props?.group?.personList?.map((obj:any)=>{return obj.id}), props?.group?.childsList?.map((subGroup:any)=>{ return subGroup?.personList?.map((p:any)=>{return p.id})})].flat(Infinity))
            setNumberstudents(personListId?.length)
     }, [props?.group])
    const openModifiers = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(!open);
    }
    const updateGroupTitle=(e:any)=>{
      e.preventDefault();
      e.stopPropagation();
      props?.openModal({idModal: ModalIDS.UPDATE_GROUP_TITLE, size: "md", data: {group: props?.group}})
      setOpen(!open);
    }
    const deleteGroupFn=(e:any)=>{
      e.preventDefault();
      e.stopPropagation();
      props?.openModal({idModal: ModalIDS.DELETE_GROUP, size: "md", data: {group: props?.group}})
      setOpen(!open);

    }
    const goToGroup=async(e:any)=>{
      e.preventDefault();
      e.stopPropagation();
      props?.clearHistoryArray()
      props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
      props?.redirect(props?.group?.label, `${returnStartPathUrl()}/students/group/${props?.group?.id}`)
      history.push(`${returnStartPathUrl()}/students/group/${props?.group?.id}`);
    }
    return ( 
      <>
      <div style={{width:384, cursor: 'pointer'}} className="bg-with-border white vignette-hover card-group ps-4 py-28"  onClick={(e)=>{goToGroup(e)}} onMouseEnter={() => setIsShown(true)} onMouseLeave={() =>{setIsShown(false); setOpen(false)}} >
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            <span className={`H4-Subtitle black-800 mb-3 card-group title ${(isShown !== true) ? "" : "hovred"}`} >
            {props?.group?.label}
            </span>
            <div className="d-flex flex-row">
              <span className="H4-Subtitle black-800 me-2">{numberStudents}</span>
              <span className="H4-Subtitle neutral-3 me-3" style={{textTransform:'lowercase'}} >{t('students')}</span>
              <span className="H4-Subtitle black-800 me-2">{props?.group?.childsList?.length}</span>
              <span className="H4-Subtitle neutral-3" style={{textTransform:'lowercase'}} >{t('subGroup')}</span>
            </div>
          </div>
        </div>
        {isShown && <>
          <div className="card-group modifiers d-flex flex-column">
            <button type="button" className="btn-QuickActions transparent" onClick={(e) => openModifiers(e)}>
              <span className="material-icons-outlined">edit</span>
            </button>
            <div className={` py-3 navbar-submenu-list group-list ${(open === false) ? "close" : ""}`} style={{ position: 'absolute', right: 0, top: 48 }} >
              <div className="d-flex flex-column">
                <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={(e)=>{updateGroupTitle(e)}}>

                  <span className="body-md bold ms-2">{t('updateGroupTitle')}</span>
                </a>
                <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3" onClick={(e)=>{deleteGroupFn(e)}}>

                  <span className="body-md bold ms-2">{t('deleteGroup')}</span>
                </a>
              </div>
            </div>
            <button type="button" className="btn-QuickActions transparent" onClick={(e)=>{goToGroup(e)}}>
              <span className="material-icons-outlined">add</span>
            </button>
          </div>
        </>
        }
      </div>
    </>
    )
}
const mapStateToProps = (state: AppState) => ({
  
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
          openModal,
          closeModal,
          redirect, 
          clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(GroupCard);