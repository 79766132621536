import { ComponentProps, FC, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { updateTraining ,getCoursesForDirector,getOnlineCoursesForDirector} from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import { useHistory } from 'react-router';
const VisibleModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const courseData = useMemo(() => { return props?.content?.data }, [props?.content])

    const update = async () => {
        let filter = {
            id: courseData?.row?.course.id,
            isVisible: false
        }
        if(filter?.id!=null && filter?.id!==undefined){
        await props?.updateTraining(filter).then(async () => {
            let filterCourse = {
                entityId: props?.user?.idSchool,
                entityType: 'SCHOOL',
                pageSize: 12,
                pageNumber: 0,
            }
            let filterOnline = {
                entityId: props?.user?.idSchool,
                entityType: 'SCHOOL',
                pageSize: 12,
                pageNumber: 0,
                status:"PUBLISHED"
            }
            if (props?.actualTabAllCourses == "all") {
            

                await props?.getCoursesForDirector(filterCourse, 'yes')
                
                
               
            }
            if (props?.actualTabAllCourses == "online") {
         
    
                await props?.getOnlineCoursesForDirector(filterOnline, 'yes')

            }

            await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText:"retour à la liste des formations", lines: ["Cours masqué du catalogue avec succès"] });
      

        })
    }

    }



    return (
        <>

            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-start mb-1">
                                <span className="H3-Headline">Souhaitez-vous masquer ce cours du catalogue? </span>
                            </div>
                            <div className="col-md-12 d-flex justify-content-start mb-1 mt-1">
                                <h3 className="H4-Subtitle neutral-3">{courseData?.row?.course?.title} </h3>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none" }}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-9 d-flex  justify-content-end' >
                        <button className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { update() }} >masquer</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    content: state.modalReducer.modalContent,
    actualTabAllCourses: state.courseReducer.actualTabAllCourses,
    user: state.authReducer.connectedUser,
    
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            updateTraining,
            openModal,
            closeModal,
            getCoursesForDirector,
            getOnlineCoursesForDirector
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(VisibleModalComponent);