import { AnyAction, combineReducers } from 'redux';
import courseReducer, {initialState as courseReducerInitialState} from '../reducers/course-reducer'; 
import messageReducer from '../reducers/message-reducer';
import catalogReducer, {initialState as catalogReducerInitialState} from '../reducers/catalog-reducer';
import authReducer , {initialState as authReducerInitialState}from '../reducers/auth-reducer';
import profReducer  from '../reducers/professor-reducer'
import historyReducer  from '../reducers/breadcrumb-reducer';
import uploadReducer from '../reducers/upload-video-course-reducer';
import uploadChapterReducer from '../reducers/upload-video-chapter-reducer';
import detailsProfileReducer from '../reducers/profile-reducer';
import schoolInfoReducer from '../reducers/app-container-reducers';
import financeReducer from '../reducers/finance-reducer';
import chatReducer from '../reducers/chat-reducer';
import webinarReducer from'../reducers/webinar-reducer';
import socketReducer from '../reducers/socket-reducer';
import progressReducer from '../reducers/progress-reducer'
import modalReducer, {initialState as modalReducerInitialState} from '../reducers/modal-reducer';
import examReducer from '../reducers/exam-reducer';
import schoolReducer from '../reducers/school-reducer';
import trainingReducer, {initialState as trainingReducerInitialState}  from '../reducers/training-reducer';
import studentTraining from '../reducers/student-training-reducer'
import { EReduxActionTypes } from '../actions/user-auth-actions';
import userGuideReducer from '../reducers/user-guide-reducer';
import formReducer from '../reducers/form-reducer';

export const appReducer = combineReducers({
  courseReducer,
  authReducer,
  catalogReducer,
  messageReducer ,
  profReducer ,
  historyReducer,
  uploadReducer,
  uploadChapterReducer,
  detailsProfileReducer,
  schoolInfoReducer,
  financeReducer,
  chatReducer,
  webinarReducer,
  examReducer,
  socketReducer,
  progressReducer,
  modalReducer,
  schoolReducer,
  trainingReducer,
  studentTraining,
  userGuideReducer,
  formReducer
});
 export type AppState = ReturnType<typeof appReducer>;
 const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction
) => {
/* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
  if (action.type === EReduxActionTypes.LOGOUT_USER) {
    const offer_token: any = localStorage.getItem("access_limitation");
    localStorage.clear();
    localStorage.setItem("access_limitation", offer_token);
    return {
      ...state,
      courseReducer:courseReducerInitialState,
      trainingReducer:trainingReducerInitialState,
      authReducer:{...authReducerInitialState,
        connectedSchoolInfo: state.authReducer.connectedSchoolInfo,
        schoolInfo:  state.authReducer.schoolInfo},
        modalReducer:modalReducerInitialState,
        catalogReducer:catalogReducerInitialState

    }
  }

  return appReducer(state, action);
};

export default rootReducer;