/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { updatePath } from '../../global-state/actions/breadcrumb-actions'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createCourse, getCourseSubtitleVimeoUploaded, uploadImageCourse } from '../../global-state/actions/course-actions';
import { clearFormVideo } from '../../global-state/actions/upload-video-course-actions';
import { debounce } from 'lodash';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import ChipInput from 'material-ui-chip-input'
import { useTranslation } from 'react-i18next';
import TextareaWithoutBorderElement from '../../elements/text-area/text-area-without-border-element';
import UploadImageCourseComponent from '../card-uploads/upload-image-course-component';
import UploadVideoCourseComponent from '../card-uploads/upload-video-course-component';
import { clearMyTrainings } from '../../global-state/actions/training-actions';
import HtmlTooltip from '../../elements/tooltip/Tooltip-Customized-element';
import ContainerUploadSubtitleComponent from '../card-uploads/container-upload-subtitle-component';
import ReactQuill from 'react-quill';

const DescriptionCourseFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [courseForm, setCourseForm] = useState<any>();
    const [msgLimitInput, setmsgLimitInput] = useState("");
    const [withUpload, setWithUpload] = useState<any>()
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState<boolean>(true);
    const video = useMemo(() => { return courseForm?.video }, [courseForm?.video])

    useEffect(() => {

        const clearData = async () => {
            await props?.clearFormVideo()
        }
        clearData();
        return () => {

            clearData();
        }
    }, [])

    useEffect(() => {
        if (refresh || props?.courseForm !== courseForm) {
            if(props?.courseForm?.isDiplomat==null){
            const object ={...props?.courseForm}
              object['isDiplomat']=false
              setCourseForm(object)
            }else{
                setCourseForm(props?.courseForm);
   
            }
           
            setRefresh(false);
        }
    }, [props?.courseForm]);

    async function onUrlAdd(e: any) {
        e.stopPropagation()
        props?.openModal({ idModal: ModalIDS.ADD_VIDEO_URL, size: 'md', data: { courseForm } })
    }

    function deleteVideo(e: any) {
        e.preventDefault();
        const copyCourseForm = { ...courseForm };
        copyCourseForm['video'] = '';
        copyCourseForm['type'] = 'COURSE';
        if (props?.connectedUser?.role === "role_prof") {
            copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
            copyCourseForm['creator'] = props?.connectedUser?.idPerson;
        }
        setWithUpload(false);
        setCourseForm(copyCourseForm);
        debouncedChangeHandler(copyCourseForm)
    }

    async function uploadVideo(link: string) {
        const copyCourseForm = { ...courseForm };
        copyCourseForm['video'] = link;
        copyCourseForm['type'] = 'COURSE';
        if (props?.connectedUser?.role === "role_prof") {
            copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
            copyCourseForm['creator'] = props?.connectedUser?.idPerson;
        }
        await setCourseForm(copyCourseForm);
        await debouncedChangeHandler(copyCourseForm)

    }

    const textAreaAdjust = (element: any, tag: string) => {
        
        if (element?.value?.length === 256) setmsgLimitInput("Le titre du cours ne doit pas dépasser les 255 caractères (Espaces inclus)")
        if (element?.value?.length < 255) setmsgLimitInput("")

        const copyCourseForm = { ...props?.courseForm };
        if (tag === 'text') {
            copyCourseForm[element.id] = element.value;
        } 
        else if(tag === 'themes'){
            let themesLength = 0
            element.value?.map((element:any)=>
                themesLength+=element.length
            )
            if (themesLength + element.value.length>255){
                element.value.pop()
            }
            else {
                copyCourseForm['theme'] = element.value;
            }
        }
        else {
            copyCourseForm['skills'] = element;
            let elementToAdd= element[element?.length-1]
            if(elementToAdd?.length>255){                
                element.pop()
            }
        }
        copyCourseForm['type'] = 'COURSE';
        if (props?.connectedUser?.role === "role_prof") {
            copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
            copyCourseForm['creator'] = props?.connectedUser?.idPerson;
        }
        setCourseForm(copyCourseForm);
        if(element.id==="level" || element.id==="title" || element.id==="themes"){
            debouncedChangeHandler(copyCourseForm)
        }
    }

    const textAreaCreateCourse = () =>{
        createCourseApi(courseForm)
    }

    const setFile = (image: string) => {
        const copyCourseForm = { ...courseForm };
        copyCourseForm['linkImg'] = image;
        copyCourseForm['type'] = 'COURSE';

        if (props?.connectedUser?.role === "role_prof") {
            copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
            copyCourseForm['creator'] = props?.connectedUser?.idPerson;
        }
        setCourseForm(copyCourseForm);
    }

    const createCourseApi = (course: any) => {

        props?.createCourse(course, props?.connectedUser)
        props?.clearMyTrainings()
    }

    const debouncedChangeHandler = useCallback(debounce(createCourseApi, 1000), []);

    useEffect(() => {
        if (props?.linkVideo !== undefined && props?.linkVideo?.length !== 0) {
            if (props?.linkVideo.includes('player.vimeo.com')) {
                props?.getCourseSubtitleVimeoUploaded(props?.linkVideo.split('/').pop())
                setWithUpload(true)
            } else {
                setWithUpload(false)
            }

        }

    }, [props?.linkVideo])

    const [isLoadingVideoToGetSubtitle, setIsLoadingVideoToGetSubtitle] = useState<{ from: string, isLoading: boolean }>({
        from: 'course', isLoading: false
    })

    const getSubtitleUploaded = () => {
        setIsLoadingVideoToGetSubtitle(prev => ({ ...prev, isLoading: true }));
        if (props?.linkVideo !== undefined && props?.linkVideo?.length !== 0) {
            props?.getCourseSubtitleVimeoUploaded(props?.linkVideo.split('/').pop())
        }
    }
    return (
        <>
            <style>
            {`
                .ql-container.ql-snow {
                    border:none;
                    background-color:white;
                    padding-top:8px;
                }
                .ql-editor {
                    min-height:30px !important;
                    padding:0;
                    font-size:16px;
                    font-family: "Public", sans-serif;
                }

                .ql-editor.ql-blank::before {
                    color: #cacbce;
                    font-style: normal; 
                    left: 0px;
                    right: 8px;
                    
                }
            `}
            </style>
            <section style={{ maxWidth: 1200, margin: 'auto' }}>
                <div className="d-flex align-items-center justify-content-between mx-2">
                    <div className="d-flex flex-column w-100 my-5 position-relative">
                        <h3 className="H3-Headline mb-3" >{t('titleCourseForm')} *</h3>
                        {/* cours */}
                        <input
                            dir="ltr"
                            type="text"
                            id="title"
                            value={courseForm?.title || ''}
                            onChange={(e) => textAreaAdjust(e.target, 'text')}
                            className="input-title"
                            placeholder={t('titleCoursePlaceholder')}
                            maxLength={255}
                        />
                        <span className='msg-error'>{msgLimitInput}</span>
                    </div>
                </div>
                <div>
                    
                    <h3 className="H4-Subtitle mb-3" >Thématique du cours </h3>
                    <span className='msg-error'>{msgLimitInput}</span>
                    <ChipInput onChange={(theme) => textAreaAdjust({id:"themes", value:theme}, 'themes')} defaultValue={courseForm?.theme} classes={{ root: 'transparent', underline: 'transparent' }} fullWidth={true} alwaysShowPlaceholder={true} placeholder={"Saisir la thématique du cours"}/>

                </div>
            </section>
            <section style={{ backgroundColor: "#FFFFFF" }}>
                <div style={{ maxWidth: 1200, margin: 'auto' }} >
                    <div className="d-flex flex-column my-2 py-5 mx-2">
                        <h3 className="H3-Headline mb-4" >{t('requiredLevelText')}</h3>
                        <div className="d-flex flex-row align-items-center justify-content-start flex-wrap w-100 mt-2">
                            <input maxLength={255} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'ANYLEVEL' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="anyLevel" value="option1" defaultChecked={(courseForm && courseForm?.level === 'ANYLEVEL')} />
                            <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="anyLevel">{t('anyLevelCourse')}</label>

                            <input maxLength={255} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'BEGINNER' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="beginner" value="option2" defaultChecked={(courseForm && courseForm?.level === 'BEGINNER')} />
                            <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="beginner">{t('firstLevel')}</label>

                            <input maxLength={255} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'INTERMEDIATE' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="intermediate" value="option1" defaultChecked={(courseForm && courseForm?.level === 'INTERMEDIATE')} />
                            <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="intermediate">{t('secondLevel')}</label>

                            <input maxLength={255} dir="ltr" onClick={(e) => textAreaAdjust({ id: 'level', value: 'ADVANCED' }, 'text')} className="input-style-radio-button" type="radio" name="exampleRadios3" id="expert" value="option2" defaultChecked={(courseForm && courseForm?.level === 'ADVANCED')} />
                            <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} htmlFor="expert" >{t('thirdLevel')}</label>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ maxWidth: 1200, margin: 'auto' }} className="py-5">
                <h3 className="H3-Headline mb-4 my-2  mx-2">{t('courseDescriptionForm')}</h3>
                <div className="d-flex justify-content-center bg-with-border mt-2  mx-2" style={{ borderRadius: "3px" }}>
                    <div style={{ width: 'calc(100% - 408px)', marginTop: 56, marginBottom: 56 }}>
                        <div className='' style={{ width: "100%" }}>
                            <div className="d-flex align-items-start mb-2">
                                <label className="body-md bold black-800">{t('resume')}*</label>
                                <HtmlTooltip content={<div className='d-flex flex-column'>
                                    <div>
                                        {t('coursResume')}
                                    </div>
                                </div>}>
                                    <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                </HtmlTooltip >
                            </div>
                            <div onBlur={()=>{textAreaCreateCourse()}}>
                                <ReactQuill  modules={{"toolbar": false}} placeholder={t('textAreaPlaceholder')} value={courseForm?.resume} onChange={(e: string) => textAreaAdjust({ id: 'resume', value: e }, 'text')} />
                            </div>
                        </div>
                        <div className=' mt-5' style={{ width: "100%" }}>
                            <div className="d-flex align-items-start mb-2">
                                <label className="body-md bold black-800">{t('objectives')}</label>
                                <HtmlTooltip content={<div className='d-flex flex-column'>
                                    <div>
                                        {t('coursObjectives')}
                                    </div>
                                </div>}>
                                    <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                </HtmlTooltip >
                            </div>
                            <div onBlur={()=>{textAreaCreateCourse()}}>
                                <TextareaWithoutBorderElement value={courseForm?.objectives} id={"objectives"} placeholder={t('textAreaPlaceholder')} onChange={(e: string) => textAreaAdjust({ id: 'objectives', value: e }, 'text')} />
                            </div>
                        </div>
                        <div className=' mt-5' style={{ width: "100%" }}>
                            <div className="d-flex align-items-start mb-2">
                                <label className="body-md bold black-800">{t('prerequisite')}</label>
                                <HtmlTooltip content={<div className='d-flex flex-column'>
                                    <div>
                                        {t('preRequisite')}
                                    </div>
                                </div>}>
                                    <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                </HtmlTooltip >
                            </div>
                            <div onBlur={()=>{textAreaCreateCourse()}}>
                                <TextareaWithoutBorderElement value={courseForm?.prerequisite} id={"prerequisite"} placeholder={t('textAreaPlaceholder')} onChange={(e: string) => textAreaAdjust({ id: 'prerequisite', value: e }, 'text')} />
                            </div>
                        </div>
                        <div className="skills-form-professor mt-5" style={{ width: "100%" }}>
                            <div className="d-flex align-items-start mb-2">
                                <label className="body-md bold black-800">{t('skills')}</label>
                                <HtmlTooltip content={<div className='d-flex flex-column'>
                                    <div>
                                        {t('skillsToLearn')}
                                    </div>
                                </div>}>
                                    <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                </HtmlTooltip >
                            </div>
                            <div onBlur={()=>{textAreaCreateCourse()}}>
                                <ChipInput classes={{ root: 'transparent', underline: 'transparent' }} fullWidth={true} alwaysShowPlaceholder={true} placeholder={t('skillsPlaceholder')} defaultValue={courseForm?.skills} onChange={(chips) => textAreaAdjust(chips, 'chips')} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ backgroundColor: "#FFFFFF" }} className="py-5 ">
                <div className="my-2" style={{ maxWidth: 1200, margin: 'auto' }}>
                    <h3 className="H3-Headline mb-4 mx-2">{t('titleFormCourse_2')} *</h3>
                    <div className="mt-2 mx-2">
                        <UploadImageCourseComponent getFile={(image: string) => { setFile(image) }} />
                    </div>
                </div>
            </section>
            <section style={{ maxWidth: 1200, margin: 'auto' }} className="py-5">
                {/* <h3 className="H3-Headline mb-4 mx-2">{t('titleFormCourse_1')}</h3> */}
                <div className="mt-2 ml-0">
                    <UploadVideoCourseComponent
                        description={t('uploadVideo_1')}
                        buttonText1={t('uploadVideo_2')}
                        buttonText2={t('addVideoToIntegrate')}
                        t={t}
                        deleteFile={deleteVideo}
                        uploadVideo={uploadVideo}
                        onUrlAdd={onUrlAdd}
                        file={video}
                        titleComponent='cours'
                        isUploading={() => setWithUpload(true)}
                        isLoadingVideoToGetSubtitle={isLoadingVideoToGetSubtitle}
                        setIsLoadingVideoToGetSubtitle={setIsLoadingVideoToGetSubtitle}
                        withUpload={withUpload}
                    />
                </div>
                {props?.linkVideo?.length > 0 && withUpload && <ContainerUploadSubtitleComponent
                    getSubtitleUploaded={getSubtitleUploaded}
                    idVideo={props?.linkVideo.split('/').pop()}
                    type='isCourse'
                />}
            </section>
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        createCourse,
        uploadImageCourse,
        updatePath,
        clearFormVideo,
        clearMyTrainings,
        openModal,
        getCourseSubtitleVimeoUploaded,
    }, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    courseTitle: ownProps?.courseTitle,
    courseForm: state.courseReducer.courseForm,
    history: ownProps?.history,
    t: ownProps?.t,
    linkVideo: state.courseReducer.courseForm?.video
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionCourseFormComponent);