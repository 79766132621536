import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from '../../helpers/interceptors';
import axiosGLobal from 'axios';
import { IReduxAuthState } from '../reducers/auth-reducer';
import qs from 'querystring';
import { getCodeFromUrl, getCodeFromUrlWithParams, getDomainFromUrl, returnStartPathUrl } from '../../helpers/domainCheck';

export enum EReduxActionTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  MESSAGE_SET = 'MESSAGE_SET',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
  REGISTER_FAIL = 'REGISTER_FAIL',
  REGISTER_USER = 'REGISTER_USER',
  GET_CONNECTED_USER = 'GET_CONNECTED_USER',
  UPDATE_INFO_SCHOOL = 'UPDATE_INFO_SCHOOL',
  LOGOUT_USER = 'LOGOUT_USER',
  REGISTER_PROF_DIRECTOR = 'REGISTER_PROF_DIRECTOR',
  REGISTER_STUDENT = 'REGISTER_STUDENT',
  LOADING_REGISTER_STUDENT = 'LOADING_REGISTER_STUDENT',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  INVITE_PERSON = 'INVITE_PERSON',
  IS_PERSON_INVITED = 'IS_PERSON_INVITED',
  CHECK_TOKEN = 'CHECK_TOKEN',
  CHECK_PASSWORD = 'CHECK_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  INVALIDE_TOKEN = 'INVALIDE_TOKEN',
  GET_PROF_BIO = 'GET_PROF_BIO',
  CHANGE_ROLE = 'CHANGE_ROLE',
  CHANGE_TOKEN = 'CHANGE_TOKEN',
  SET_CONNECTED_USER_INFO = 'SET_CONNECTED_USER_INFO',
  GET_TICKETS = 'GET_TICKETS',
  GET_ACTUAL_TAB = 'GET_ACTUAL_TAB',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  GET_USER_PROFILE_DETAILS = 'GET_USER_PROFILE_DETAILS',
  RESEND_EMAIL = 'RESEND_EMAIL',
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
  CHECK_PASSWORD_IS_VALID = 'CHECK_PASSWORD_IS_VALID',
  DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT',
  LOADING_USER_PROFILE = "LOADING_USER_PROFILE",
  CHECK_PASSWORD_USER = 'CHECK_PASSWORD_USER',
  GET_CARDS = 'GET_CARDS',
  LOGIN_WITH_COOKIES = 'LOGIN_WITH_COOKIES',
  GET_INSCRIBED_SCHOOLS_LIST = 'GET_INSCRIBED_SCHOOLS_LIST',
  SWITCH_BETWEEN_SCHOOL = 'SWITCH_BETWEEN_SCHOOL',
  IS_FIRST_LOGIN = 'IS_FIRST_LOGIN',
  RESUM_FIRST_LOGIN = 'RESUM_FIRST_LOGIN'
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}



export interface IReduxGetUserProfileDetailsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_USER_PROFILE_DETAILS | EReduxActionTypes.LOADING_USER_PROFILE;
  data: any;
}
export interface IReduxDeleteUserAccountAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_USER_ACCOUNT;
  data: any;
}
export interface IReduxGetCardsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CARDS;
  data: any;
}
export interface IReduxCheckPasswordUserAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_PASSWORD_USER;
  data: any;
}
export interface IReduxCheckPasswordIsValidAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_PASSWORD_IS_VALID;
  data: any;
}

export interface IReduxUpdateUserProfileAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_USER_PROFILE;
  data: any;
}

export interface IReduxChangeUserPasswordAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_USER_PASSWORD;
  data: any;
}

export interface IReduxLoginSuccessAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGIN_SUCCESS;
  data: any;
}
export interface IReduxRefreshTokenAction extends IReduxBaseAction {
  type: EReduxActionTypes.REFRESH_TOKEN;
  data: any;
}
export interface IReduxGetActualTabAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_ACTUAL_TAB;
  data: any;
}
export interface IReduxSetConnectedUserAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_CONNECTED_USER_INFO;
  data: any;
}
export interface IReduxChangeTokenAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_TOKEN;
  data: any;
}
export interface IReduxChangeRoleAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_ROLE;
  data: any;
}
export interface IReduxGetTecketsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TICKETS;
  data: any;
}
export interface IReduxGetProfBioAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_PROF_BIO;
  data: any;
}

export interface IReduxResetPasswordAction extends IReduxBaseAction {
  type: EReduxActionTypes.RESET_PASSWORD;
  data: any;
}
export interface IReduxUploadImageAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPLOAD_IMAGE;
  data: any;
}

export interface IReduxCheckPasswordAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_PASSWORD | EReduxActionTypes.INVALIDE_TOKEN;
  data: any;
}
export interface IReduxCheckTokenAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_TOKEN | EReduxActionTypes.INVALIDE_TOKEN;
  data: any;
}

export interface IReduxResendAction extends IReduxBaseAction {
  type: EReduxActionTypes.RESEND_EMAIL
  data: any;
}

export interface IReduxInvitePersonAction extends IReduxBaseAction {
  type: EReduxActionTypes.INVITE_PERSON;
  data: any;
}

export interface IReduxIsPersonInvitedAction extends IReduxBaseAction {
  type: EReduxActionTypes.IS_PERSON_INVITED;
  data: any;
}

export interface IReduxRegisterStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.REGISTER_STUDENT | EReduxActionTypes.LOADING_REGISTER_STUDENT;
  data: any;
}


export interface IReduxRegisterProfDirectorAction extends IReduxBaseAction {
  type: EReduxActionTypes.REGISTER_PROF_DIRECTOR;
  data: any;
}
export interface IReduxGetConnectedUserAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CONNECTED_USER;
  data: any;
}

export interface IReduxInvalideTokenAction extends IReduxBaseAction {
  type: EReduxActionTypes.INVALIDE_TOKEN;
  data: any;
}
export interface IReduxLoginFailsction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGIN_FAIL;
  data: any;
}

export interface IReduxRegisterUserAction extends IReduxBaseAction {
  type: EReduxActionTypes.REGISTER_USER;
  data: any;
}

export interface IReduxLogoutAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGOUT_USER;
}

export interface IReduxMessageSetAction extends IReduxBaseAction {
  type: EReduxActionTypes.MESSAGE_SET;
  data: any;
}

export interface IReduxMessageClearAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_MESSAGE;
  data: any;
}
export interface IReduxUpdateSchoolAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_INFO_SCHOOL;
  data: any;
}
export interface IReduxGetProfDataAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_INFO_SCHOOL;
  data: any;
}

export interface IReduxLoginWithCookieAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGIN_WITH_COOKIES | EReduxActionTypes.IS_FIRST_LOGIN;
  data: any;
}

export interface IReduxGetInscribedSchoolsListAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_INSCRIBED_SCHOOLS_LIST;
  data: any;
}

export interface IReduxSwitchBetweenSchoolAction extends IReduxBaseAction {
  type: EReduxActionTypes.SWITCH_BETWEEN_SCHOOL
  data: any
}

export interface IReduxResumFirstLoginAction extends IReduxBaseAction {
  type: EReduxActionTypes.RESUM_FIRST_LOGIN
  data: any
}


export function switchSchools(code: string, email: string, password: string, schoolId: string): ThunkAction<Promise<IReduxSwitchBetweenSchoolAction>, IReduxAuthState, undefined, IReduxSwitchBetweenSchoolAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxSwitchBetweenSchoolAction>) => {
    let schoolUrl;
    const newSchoolToken = await axios.request({
      url: "/oauth/token",
      method: "post",
      baseURL: process.env.REACT_APP_BASE_URL1,
      headers: {
        'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        "grant_type": "password",
        "username": email,
        "password": password,
        "schoolId": schoolId,
      })

    }).then((oauthResponse: any) => {
      return oauthResponse.data
    }).catch((error: any) => {
      console.error(error)
    })

    localStorage.setItem("access_token", JSON.stringify(newSchoolToken?.access_token));
    localStorage.setItem("refresh_token", JSON.stringify(newSchoolToken?.refresh_token));
    localStorage.setItem("expires_in", JSON.stringify(newSchoolToken?.expires_in))
    localStorage.setItem("lastConnexion", JSON.stringify(newSchoolToken?.lastConnexion))

    if (window.location.href.includes(process.env.REACT_APP_BASE_SUBDOMAIN as string) || window.location.href.includes("http://localhost:3000/")) {

      if (window.location.href.includes("http://localhost:3000/")) {
        document.cookie = code + "Token=" + JSON.stringify(newSchoolToken) + "; SameSite=None; Secure; path=/;";
        schoolUrl = "http://localhost:3000/" + code
      } else {
        document.cookie = code + "Token=" + JSON.stringify(newSchoolToken) + "; SameSite=None; Secure; path=/; domain=koors.io";
        schoolUrl = code + process.env.REACT_APP_BASE_SUBDOMAIN
      }

    } else if (window.location.href.includes(process.env.REACT_APP_SCHOOL_DOMAIN as string)) {
      schoolUrl = process.env.REACT_APP_SCHOOL_DOMAIN + code
    }


    return dispatch({
      type: EReduxActionTypes.SWITCH_BETWEEN_SCHOOL,
      data: schoolUrl
    });

  }
}


export function getProfData(idProf: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL1;

    const data = await axios.get(baseUrl + "users/" + idProf
    ).then((response) => {

      return response.data;
    }).catch(err => { return null });
    return dispatch({
      type: EReduxActionTypes.GET_PROF_BIO,
      data: data
    });
  };

}
export function getProfDataByDirector(idProf: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {
    const prof = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/naturalpersonbyid/${idProf}`).then(response => { return response.data }).catch(() => { return { data: "" } })
    return dispatch({
      type: EReduxActionTypes.GET_PROF_BIO,
      data: prof
    });
  };

}
export function changeRole(role: any, connectedUser: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {

    let user = {
      "id": connectedUser.id,
      "idPerson": connectedUser.idPerson,
      "idSchool": connectedUser.idSchool,
      "logo": connectedUser.logo,
      "role": role,
      "possibleRoles": connectedUser.possibleRoles
    }


    let baseURL = process.env.REACT_APP_BASE_URL1;
    let headersContent: any = {
      'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
      'Content-Type': 'application/x-www-form-urlencoded',
      'apiKey': `${process.env.REACT_APP_API_KEY_KONG}`
    }

    if (window.location.href.includes("http://localhost:3000/")) {
      headersContent = {
        'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
        'Content-Type': 'application/x-www-form-urlencoded',
        'apiKey': `${process.env.REACT_APP_API_KEY_KONG}`
      }
    } else {
      headersContent = {
        'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
        'Content-Type': 'application/x-www-form-urlencoded',
        'apiKey': `${process.env.REACT_APP_API_KEY_KONG}`
      }
    }

    await axiosGLobal.request({
      url: "/oauth/token",
      method: "post",
      baseURL: baseURL,

      headers: headersContent,

      data: qs.stringify({
        "grant_type": "refresh_token",
        "refresh_token": localStorage?.getItem('refresh_token')?.replace(/\"/g, ""),
        "actualRole": role,
        "schoolId": connectedUser.idSchool,
      })


    }
    ).then((res) => {


      let data = {
        "access_token": res.data.access_token,
        "refresh_token": res.data.refresh_token,
        "expires_in": res.data.expires_in,
        "lastConnexion": res.data.lastConnexion

      }

      dispatch({
        type: EReduxActionTypes.CHANGE_ROLE,
        data: user,
      });
      dispatch({
        type: EReduxActionTypes.CHANGE_TOKEN,
        data: data,
      });


      let ticketObject: any = {
        "entityId": connectedUser.idSchool,
        "entityType": "SCHOOL",
      }
      if (role === "role_student") ticketObject['studentId'] = connectedUser.idPerson
      else ticketObject['idProf'] = connectedUser.idPerson

      axios.post(process.env.REACT_APP_BASE_URL14 + 'tickets/token', ticketObject).then(res => {
        dispatch({
          type: EReduxActionTypes.GET_TICKETS,
          data: res.data,
        });
      })

    }).catch(err => console.error(err));


  };

}



export function refreshToken(schoolId: any, personId: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {
    let ticketObject: any = {
      "entityId": schoolId,
      "entityType": "SCHOOL",
    }
    ticketObject['studentId'] = personId;
    axios.post(process.env.REACT_APP_BASE_URL14 + 'tickets/token', ticketObject).then(res => {

      localStorage.setItem("access_tickets", JSON.stringify(res.data))
      dispatch({
        type: EReduxActionTypes.GET_TICKETS,
        data: res.data,
      });
    })
  }
};

const incrementUserNbrConnexions = async (idPerson: any , token:string) => {  
  try {
    axiosGLobal.get(process.env.REACT_APP_BASE_URL1 + 'person/incremetNbrConnexions/' + idPerson, {
      headers: {
        Authorization: "Bearer " + token,
        apiKey: `${process.env.REACT_APP_API_KEY_KONG}`
      }
    })
  } catch (e) {
    console.error(e)
  }
}


export function loginWithCookies(tokenToUse: any): ThunkAction<Promise<IReduxLoginWithCookieAction>, IReduxAuthState, undefined, IReduxLoginWithCookieAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxLoginWithCookieAction>) => {
    let hubspotURL = process.env.REACT_APP_BASE_URL21;
    let baseURL = process.env.REACT_APP_BASE_URL1;
    let token = JSON.parse(tokenToUse)

    let schoolInfo: any
    let directorInfo: any
    await axios.get(process.env.REACT_APP_BASE_URL2 + "person/legalpersonbyid/" + token?.schoolId).then((resp: any) => {
      schoolInfo = resp.data
    })
    await axios.get(process.env.REACT_APP_BASE_URL2 + "person/naturalpersonbyid/" + token?.id_Person)
      .then((resp: any) => {
        directorInfo = resp.data
      })

    

    let data = {
      "access_token": token?.access_token,
      "refresh_token": token?.refresh_token,
      "expires_in": token?.expires_in,
      "lastConnexion": token?.lastConnexion,
      user: {
        "id": token?.id,
        "idPerson": token?.id_Person,
        "idSchool": token?.schoolId,
        "logo": schoolInfo?.logo,
        "role": token?.authorities[0] != token?.scope ? token?.authorities[0] : token?.scope,
        "possibleRoles": token?.possible_role
      },
      userInfo: {
        "photo": directorInfo?.photo,
        "firstName": directorInfo?.firstName,
        "lastName": directorInfo?.lastName,
      },
      schoolInfo: {

        "code": schoolInfo?.code,
        "color": schoolInfo?.color,
        "coverPhoto": schoolInfo?.coverPhoto,
        "centralLogo": schoolInfo?.centralLogo,
        "creationDate": schoolInfo?.creationDate,
        "description": schoolInfo?.description,
        "logo": schoolInfo?.logo,
        "name": schoolInfo?.name,
        "shortDescription": schoolInfo?.shortDescription
      }
    }

    if (typeof window !== 'undefined' && (!window?.location?.href.includes("localhost"))) {
      document.cookie = schoolInfo?.code + "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.koors.io";
    } else {
      document.cookie = schoolInfo?.code + "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ";
    }

    await incrementUserNbrConnexions(token?.id_Person , token?.access_token)

    await axiosGLobal.post(baseURL + "users/students/auth", [token?.id_Person], {
      headers: {
        Authorization: "Bearer " + token?.access_token,
        apiKey: `${process.env.REACT_APP_API_KEY_KONG}`
      }
    }).then(result => {

      if (result.data.studentNbre === 0) {
        dispatch({
          type: EReduxActionTypes.IS_FIRST_LOGIN,
          data: true
        });
        if (process.env.REACT_APP_BASE_HUBSPOT === 'true') {
          axiosGLobal.post(hubspotURL + "hubspot/updateDeal", { email: directorInfo.email, properties: { dealstage: 85176807 } }, {
            headers: {
              Authorization: "Bearer " + token?.access_token,
              apiKey: `${process.env.REACT_APP_API_KEY_KONG}`
            }
          })
        }
      } else {
        dispatch({
          type: EReduxActionTypes.IS_FIRST_LOGIN,
          data: false
        })
      }
    })
    let baseURL2 = process.env.REACT_APP_BASE_URL14
    axiosGLobal.post(baseURL2 + "audit/create", { action: 'koors_count_login_director', idPerson: token?.id_Person, idLegalPerson: parseInt(token?.schoolId) }, {
      headers: {
        Authorization: "Bearer " + token?.access_token,
        apiKey: `${process.env.REACT_APP_API_KEY_KONG}`
      }
    })

    axiosGLobal.post(baseURL + "history/students", { action: 'LOGIN', idPerson: token?.id_Person, idLegalPerson: parseInt(token?.schoolId) }, {
      headers: {
        Authorization: "Bearer " + token?.access_token,
        apiKey: `${process.env.REACT_APP_API_KEY_KONG}`
      }
    })



    return dispatch({
      type: EReduxActionTypes.LOGIN_WITH_COOKIES,
      data: data
    });

  }
}



export function loginUser(
  login: any, password: any
): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {
    dispatch({
      type: EReduxActionTypes.LOADING_REGISTER_STUDENT,
      data: null
    });
    let baseURL = process.env.REACT_APP_BASE_URL1;
    let baseURL1 = process.env.REACT_APP_BASE_URL2
    let baseURL2 = process.env.REACT_APP_BASE_URL14
    let hubspotURL = process.env.REACT_APP_BASE_URL21;
    let data;
    let code = getCodeFromUrl()
    axios.get(baseURL1 + "person/relation/" + login + "/" + code).then((response) => {
      if (response.data.to) {
        axios.request({
          url: "/oauth/token",
          method: "post",
          baseURL: baseURL,
          headers: {
            'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify({
            "grant_type": "password",
            "username": login,
            "password": password,
            "schoolId": response.data.to.id,
          })
        }).then((res) => {
          data = {
            "access_token": res.data.access_token,
            "refresh_token": res.data.refresh_token,
            "expires_in": res.data.expires_in,
            "lastConnexion": res.data.lastConnexion,
            user: {
              "id": res.data.id,
              "idPerson": res.data.id_Person,
              "idSchool": response.data.to.id,
              "logo": response.data.to.logo,
              "role": res.data.scope,
              "possibleRoles": res.data.possible_role
            },
            userInfo: {
              "photo": response.data.from.photo,
              "firstName": response.data.from.firstName,
              "lastName": response.data.from.lastName,
            },
            schoolInfo: {

              "code": response.data.to.code,
              "color": response.data.to.color,
              "coverPhoto": response.data.to.coverPhoto,
              "centralLogo": response.data.to.centralLogo,
              "creationDate": response.data.to.creationDate,
              "description": response.data.to.description,
              "logo": response.data.to.logo,
              "name": response.data.to.name,
              "shortDescription": response.data.to.shortDescription
            }
          }

          incrementUserNbrConnexions(res.data.id_Person, res.data.access_token)

          dispatch({
            type: EReduxActionTypes.LOGIN_SUCCESS,
            data: data,
          });
          if (res.data.scope === "role_director") {
            axios.post(baseURL + "users/students/auth", [res.data.id_Person]).then(result => {
              if (result.data.studentNbre === 0) {
                //TODO
                if (process.env.REACT_APP_BASE_HUBSPOT === 'true') {
                  axios.post(hubspotURL + "hubspot/updateDeal", { email: response.data.from.email, properties: { dealstage: 85176807 } })
                }
              }
            })
            axios.post(baseURL2 + "audit/create", { action: 'koors_count_login_director', idPerson: res.data.id_Person, idLegalPerson: parseInt(res?.data?.schoolId) })


            axios.post(baseURL + "history/students", { action: 'LOGIN', idPerson: res.data.id_Person, idLegalPerson: parseInt(res?.data?.schoolId) })

          } else {
            axios.post(baseURL2 + "audit/create", { action: 'koors_count_login_members', idPerson: res.data.id_Person, idLegalPerson: parseInt(res?.data?.schoolId) })

            axios.post(baseURL + "history/students", { action: 'LOGIN', idPerson: res.data.id_Person, idLegalPerson: parseInt(res?.data?.schoolId) })

          }



          let ticketObject: any = {
            "entityId": response.data.to.id,
            "entityType": "SCHOOL",
          }
          if (res.data.scope === "role_student") ticketObject['studentId'] = res.data.id_Person
          else ticketObject['idProf'] = res.data.id_Person

          axios.post(process.env.REACT_APP_BASE_URL14 + 'tickets/token', ticketObject).then(res => {
            dispatch({
              type: EReduxActionTypes.GET_TICKETS,
              data: res.data,
            });
          })




        }).catch((err) => {

          if (err.response.data.error_description === "VI")
            data = { message: "E-mail ou mot de passe incorrecte" }

          else if (err.response.data.error_description === "CB") {
            data = { message: "Merci d'activer votre compte en cliquant sur le lien envoyé à votre adresse mail" }
          }
          else if (err.response.data.error_description === "Le compte utilisateur est d&eacute;sactiv&eacute;") {
            data = { message: "Votre compte a été désactivé" }

          }
          else if (err.response.data.error_description === "Bad credentials") {
            data = { message: "E-mail ou mot de passe incorrecte" }
          }
          else {
            data = { message: "Problème de connexion au serveur. Veuillez réessayer ultérieurement." }
          }
          dispatch({
            type: EReduxActionTypes.LOGIN_FAIL,
          });

          dispatch({
            type: EReduxActionTypes.MESSAGE_SET,
            data: data
          });


        })
      } else {
        dispatch({
          type: EReduxActionTypes.LOGIN_FAIL,
        });
        dispatch({
          type: EReduxActionTypes.MESSAGE_SET,
          data: { message: response.data }
        });
      }
    }).catch((err) => {
      if (err?.response?.data?.message === "Le compte utilisateur est d&eacute;sactiv&eacute") {
        data = { message: "Votre compte a été désactivé" }

      } else {
        data = { message: "E-mail ou mot de passe incorrecte" }

      }
      dispatch({
        type: EReduxActionTypes.LOGIN_FAIL,
      });

      dispatch({
        type: EReduxActionTypes.MESSAGE_SET,
        data: data
      });
    })

  };
}
export function logout(
): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {


    return dispatch({
      type: EReduxActionTypes.LOGOUT_USER,
    });


  };

}
export function clearMessage(): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {


    return dispatch({
      type: EReduxActionTypes.CLEAR_MESSAGE,
    });

  }
}
export function registerStudent(register: any): ThunkAction<Promise<IReduxRegisterStudentAction>, IReduxAuthState, undefined, IReduxRegisterStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxRegisterStudentAction>) => {
    dispatch({
      type: EReduxActionTypes.LOADING_REGISTER_STUDENT,
      data: true
    });
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    const registerStudents = await axios.post(baseUrl + "person/register", {
      "email": register.email.toLowerCase(),
      "lastName": register.lastName,
      "firstName": register.firstName,
      "password": register.password,
      "dateNaiss": register.birthDay,
      "photo": register.photo,
      "gender": register.gender,
      "schooling": register.levelStudy === "" ? null : register.levelStudy,
      "country": register.country === "" ? null : register.country,
      "city": register.city,
      "role": "role_student",
      "domain": getCodeFromUrlWithParams(register.link),
      "domainUrl": getDomainFromUrl()
    }).then(async (response) => {
      return await axios.get(baseUrl + "person/getlegalpersonbycode/" + (getCodeFromUrlWithParams(register.link))).then(async (responsel) => {
        return await axios.post(baseUrl + "person/attach", {

          "invitation": {
            "relationType": "STUDENT",
            "to": {
              "id": responsel.data.id,
              "code": responsel.data.code,
              "type": "LP"
            },
            "invited": {
              "id": response.data.id,
              "firstName": response.data.firstName,
              "type": "NP"
            }
          }
        }).then(resp => { return resp; }).catch(err => console.error(err));
      })
    })

    return dispatch({
      type: EReduxActionTypes.REGISTER_STUDENT,
      data: registerStudents
    });

  }
}
export function registerProfDirector(register: any): ThunkAction<Promise<IReduxRegisterProfDirectorAction>, IReduxAuthState, undefined, IReduxRegisterProfDirectorAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxRegisterProfDirectorAction>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL2;



    const registerProfDirectors = await axios.post(baseUrl + "person/register", {
      "id": register.id,
      "email": register.email,
      "lastName": register.lastName,
      "firstName": register.firstName,
      "password": register.password,
      "dateNaiss": register.birthDay,
      "photo": register.photo,
      "gender": register.gender,
      "schooling": register.levelStudy === "" ? null : register.levelStudy,
      "country": register.country === "" ? null : register.country,
      "city": register.city,
      "token": register.token.substring(register.token.indexOf("-") + 1),
      "role": register.relationType,
      "bio": register.bio,
      "domainUrl": getDomainFromUrl()


    }).then(response => {return response.data; }).catch(err => console.error(err));

    return dispatch({
      type: EReduxActionTypes.REGISTER_PROF_DIRECTOR,
      data: registerProfDirectors
    });

  }
}
export function uploadImage(form: any): ThunkAction<Promise<IReduxUploadImageAction>, IReduxAuthState, undefined, IReduxUploadImageAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxUploadImageAction>) => {
    const baseUrlGed = process.env.REACT_APP_BASE_URL4;
    const res = await axios.post(baseUrlGed + "upload/image",
      form
    ).then(response => { return response.data; }).catch(err => console.error(err));

    return dispatch({
      type: EReduxActionTypes.UPLOAD_IMAGE,
      data: res
    });

  }
}
export function checkPassword(token: any): ThunkAction<Promise<IReduxCheckPasswordAction>, IReduxAuthState, undefined, IReduxCheckPasswordAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxCheckPasswordAction>) => {
    const res = axios.get(process.env.REACT_APP_BASE_URL1 + "person/checkmail/password?token=" + token).then(response => { return response.data; }).catch(err => {
      dispatch({ type: EReduxActionTypes.INVALIDE_TOKEN, data: err })
    });

    return dispatch({
      type: EReduxActionTypes.CHECK_PASSWORD,
      data: res
    });

  }
}
export function resetPassword(row: any, confirmPass: any): ThunkAction<Promise<IReduxResetPasswordAction>, IReduxAuthState, undefined, IReduxResetPasswordAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxResetPasswordAction>) => {

    const res = await axios.post(process.env.REACT_APP_BASE_URL1 + "person/resetpassword", {
      "email": row,
      "password": confirmPass
    }).then(response => { return response.data; }).catch(err => console.error(err));



    return dispatch({
      type: EReduxActionTypes.RESET_PASSWORD,
      data: res
    });

  }
}
export function CheckToken(token: any): ThunkAction<Promise<IReduxCheckTokenAction>, IReduxAuthState, undefined, IReduxCheckTokenAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxCheckTokenAction>) => {
    const res = await axios.get(process.env.REACT_APP_BASE_URL1 + "person/checkmail/mail?token=" + token)
      .then(data => {
        if (data.data === "this token doesn't exist") {
          return "token doesn't exist"
        }
        return "token Verifié";
      }).catch(err => {
        dispatch({ type: EReduxActionTypes.INVALIDE_TOKEN, data: "token invalide" })
      });

    return dispatch({
      type: EReduxActionTypes.CHECK_TOKEN,
      data: res
    });

  }
}

export function resendEmail(email: any, host: any): ThunkAction<Promise<IReduxResendAction>, IReduxAuthState, undefined, IReduxResendAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxResendAction>) => {
    const res = await axios.post(process.env.REACT_APP_BASE_URL2 + "person/resend",
      {
        email: email,
        host: host
      })
      .then(data => { return data; }).catch(err => {
        return err
      });
    return dispatch({
      type: EReduxActionTypes.RESEND_EMAIL,
      data: res
    });

  }
}

export function invitePerson(token: any): ThunkAction<Promise<IReduxInvitePersonAction>, IReduxAuthState, undefined, IReduxInvitePersonAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxInvitePersonAction>) => {

    const res = await axios.get(process.env.REACT_APP_BASE_URL2 + "person/invitation/" + token.substring(token.indexOf("-") + 1))
      .then(response => { return response.data; }).catch(err => console.error(err));


    return dispatch({
      type: EReduxActionTypes.INVITE_PERSON,
      data: res
    });

  }
}
export function checkIfPersonIsInvited(token: any): ThunkAction<Promise<IReduxIsPersonInvitedAction>, IReduxAuthState, undefined, IReduxIsPersonInvitedAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxIsPersonInvitedAction>) => {

    const res = await axios.get(process.env.REACT_APP_BASE_URL2 + "person/invitation/" + token.substring(token.indexOf("-") + 1))
      .then(
        response => {
          if (response.data) {
            return true
          } else return false
        })
      .catch(err => console.error(err));


    return dispatch({
      type: EReduxActionTypes.IS_PERSON_INVITED,
      data: res
    });

  }
}
export function setPersonInvited(value: any): ThunkAction<Promise<IReduxIsPersonInvitedAction>, IReduxAuthState, undefined, IReduxIsPersonInvitedAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxIsPersonInvitedAction>) => {


    return dispatch({
      type: EReduxActionTypes.IS_PERSON_INVITED,
      data: value
    });

  }
}
export function getUserById(id: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {

    let auth = ('Bearer ' + localStorage.getItem("access_token")).replace(/\"/g, "")
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    let data;
    axios.get(baseUrl + "users/" + id,
      {
        headers: {
          'Authorization': auth
        }
      }).then((response) => {
        data = {

          userInfo: {

            "photo": response.data.photo,
            "firstName": response.data.firstName,
            "lastName": response.data.lastName,

          },
        }
        dispatch({
          type: EReduxActionTypes.GET_CONNECTED_USER,
          data: data,
        });
      })
  }


}
export function UpdateSchool(object: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL2;
    let data;
    axios.post(baseUrl + "person/updatelegalperson", object).then((response) => {
      data = {
        schoolInfo: {

          "code": response.data.code,
          "color": response.data.color,
          "coverPhoto": response.data.coverPhoto,
          "centralLogo": response.data.centralLogo,
          "creationDate": response.data.creationDate,
          "description": response.data.description,
          "logo": response.data.logo,
          "name": response.data.name,
          "shortDescription": response.data.shortDescription,
          "domain": response.data.domain
        }
      }
      dispatch({
        type: EReduxActionTypes.UPDATE_INFO_SCHOOL,
        data: data,
      });
    })
  }


}
export function setConnectedUser(user: any): ThunkAction<Promise<IReduxSetConnectedUserAction>, IReduxAuthState, undefined, IReduxSetConnectedUserAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxSetConnectedUserAction>) => {
    return dispatch({
      type: EReduxActionTypes.SET_CONNECTED_USER_INFO,
      data: user
    });

  }
}
export function getActualTab(actualTab: any): ThunkAction<Promise<IReduxGetActualTabAction>, IReduxAuthState, undefined, IReduxGetActualTabAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxGetActualTabAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_ACTUAL_TAB,
      data: actualTab,
    });

  }
}





export function getUserProfileDetails(id: number, refresh: any): ThunkAction<Promise<IReduxGetUserProfileDetailsAction>, IReduxAuthState, undefined, IReduxGetUserProfileDetailsAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxGetUserProfileDetailsAction>) => {
    if (refresh === undefined) {
      dispatch({ type: EReduxActionTypes.LOADING_USER_PROFILE, data: undefined });
    }

    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const userProfile: any = await axios.get(baseUrl + "users/" + id).then(response => { return response.data; }).catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.GET_USER_PROFILE_DETAILS,
      data: userProfile
    });

  }
}

export function updateProfile(updatedProfile: any): ThunkAction<Promise<IReduxUpdateUserProfileAction>, IReduxAuthState, undefined, IReduxUpdateUserProfileAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxUpdateUserProfileAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const userProfile: any = await axios.post(baseUrl + "users/updateprofile", updatedProfile).then(response => { return response.data; }).catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.UPDATE_USER_PROFILE,
      data: userProfile
    });

  }
}
export function getCards(card: any): ThunkAction<Promise<IReduxGetCardsAction>, IReduxAuthState, undefined, IReduxGetCardsAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxGetCardsAction>) => {

    const cards: any = await axios.get(process.env.REACT_APP_BASE_URL15 + 'strip/get-cards/' + card).then(async (response: any) => { return response.data.data }).catch(err => { return console.error(err) });
    return dispatch({
      type: EReduxActionTypes.GET_CARDS,
      data: cards
    });

  }
}

export function changeMail(checkPassword: any, updateMail: any): ThunkAction<Promise<IReduxChangeUserPasswordAction>, IReduxAuthState, undefined, IReduxChangeUserPasswordAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxChangeUserPasswordAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const userProfile: any = await axios.post(baseUrl + "users/checkpassword/", checkPassword).then(response => {

      updateMail.domain = getCodeFromUrl()
      axios.post(baseUrl + "users/updateprofile/", updateMail)
        .then(response => {
          window.localStorage.clear()
        })
        .catch(err => { return console.error(err) });


    }).catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.CHANGE_USER_PASSWORD,
      data: userProfile
    });
  }
}
export function checkPasswordUser(checkPassword: any): ThunkAction<Promise<IReduxCheckPasswordUserAction>, IReduxAuthState, undefined, IReduxCheckPasswordUserAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxCheckPasswordUserAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const userProfile: any = await axios.post(baseUrl + "users/checkpassword/", checkPassword).then(response => {
      return response.data

    }).catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.CHECK_PASSWORD_USER,
      data: userProfile
    });
  }
}


export function changePassword(checkPassword: any, newPassword: any): ThunkAction<Promise<IReduxChangeUserPasswordAction>, IReduxAuthState, undefined, IReduxChangeUserPasswordAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxChangeUserPasswordAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const userProfile: any = await axios.post(baseUrl + "users/checkpassword/", checkPassword).then(response => {

      newPassword.domain = getDomainFromUrl()
      axios.post(baseUrl + "users/updatepassword", newPassword)

        .catch(err => { return console.error(err) });


    }).catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.CHANGE_USER_PASSWORD,
      data: userProfile
    });
  }
}

export function deleteUserAccount(id: number, idschool: number, deleteReason: any, userComment: any): ThunkAction<Promise<IReduxDeleteUserAccountAction>, IReduxAuthState, undefined, IReduxDeleteUserAccountAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxDeleteUserAccountAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    const userProfile: any = await axios.get(baseUrl + "person/accessvalidation/" + id + "/" + idschool + "/Desactivee")
      .then(response => {
        const offer_token: any = localStorage.getItem("access_limitation");
        localStorage.clear();
        localStorage.setItem("access_limitation", offer_token);
        window.location.reload();
      })
      .catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.DELETE_USER_ACCOUNT,
      data: userProfile
    });

  }
}



export function getInscribedSchoolsList(connectedUserId: number): ThunkAction<Promise<IReduxGetInscribedSchoolsListAction>, IReduxAuthState, undefined, IReduxGetInscribedSchoolsListAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxGetInscribedSchoolsListAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    let userProfile: any = []
    await axios.get(baseUrl + "person/getListOfInscribedSchools/" + connectedUserId)
      .then(response => {
        userProfile = response.data
      })
      .catch(err => { return console.error(err) });

    return dispatch({
      type: EReduxActionTypes.GET_INSCRIBED_SCHOOLS_LIST,
      data: userProfile
    });

  }
}

export function resumFirstLogin(): ThunkAction<Promise<IReduxResumFirstLoginAction>, IReduxAuthState, undefined, IReduxResumFirstLoginAction> {
  return async (dispatch: ThunkDispatch<any, undefined, IReduxResumFirstLoginAction>) => {

    return dispatch({
      type: EReduxActionTypes.RESUM_FIRST_LOGIN,
      data: false
    });
  };

}

