import { ComponentProps, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import ProfessorSheetComponent from '../../components/professor-profil/professor-sheet-component';

const ProfessorSheetPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const user = useMemo(() => { return props?.connectedUser }, [props?.connectedUser]);



    return (<>
        {(user?.role.includes("role_director") || user?.role.includes("role_adminstrator")) &&
            <>
                <ProfessorSheetComponent />
            </>

        }

    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfessorSheetPage);
