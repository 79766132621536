
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import ExamResultTrainingComponent from '../../../components/pass-exam/exam-result-training-component';
import { setExamResult } from '../../../global-state/actions/student-training-actions';

interface Params {
    idExam: string
}
const ExamResultPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &  ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [progressTraining, setProgressTraining] = useState<any>();
    let dateFormat = require('dateformat');
    const { idExam } = useParams<Params>();
    useEffect(() => {

        setProgressTraining(props?.examResult);
        return () => {
            props?.setExamResult(undefined)
        }
    }, [props?.examResult])
    const getDuration = ( ) => {
        if (progressTraining?.duration < 60) {
            return progressTraining?.duration + ' mn';
        } else {
            return Math.floor(progressTraining?.duration / 60) + 'h' + (((progressTraining?.duration % 60) < 10 ? '0' : '') + (progressTraining?.duration % 60));
        }
    }
    return (<>
    <div style={{backgroundColor: 'white'}}>
        <div style={{maxWidth: 1200, margin: 'auto'}} className=" py-2 text-center">

            <p className="H4-Subtitle black-800 my-4  ">{t("finalExmBreadcrunbTitle")} . <span className="neutral-3">{getDuration()}</span></p>
        </div>
    </div>
        <div className="mt-4 pt-2" style={{maxWidth: 1200, margin: 'auto'}} >
            {(progressTraining?.progression?.status === "TOREVIEW") && (
                <>
                    <div className="grid-container mx-4" style={{ border: "1px solid #F2F2F5", borderRadius: "3px", padding: "40px 40px", backgroundColor: "#FBFBFD" }} >
                        <div className="d-flex align-items-center flex-wrap" >
                            <div className="test-state waiting d-flex align-items-center justify-content-center">
                                <div className="threedots-large-Animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div style={{ paddingLeft: "70px" }}>
                                <div>
                                    <span className="H3-Headline black-800">{progressTraining?.title}</span>
                                </div>
                                <div className="mt-4">
                                    <span className="body-md bold neutral-3"> {t("Examen_final_envoyé_le")} <span style={{ color: "#272727" }}>{dateFormat(progressTraining?.progression?.creationDate, "dd/mm/yyyy")}</span></span>
                                </div>
                                <div className="mt-2 pt-1">
                                    <span className="body-md bold neutral-3"> {t("En_attende_de_correction_par_le_professeur")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {progressTraining?.progression?.status === "COMPLETED" && (
                <>
                    <ExamResultTrainingComponent />
                </>
            )}
        </div>
        </>
    )
}



const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { setExamResult }, dispatch );

const mapStateToProps = (state: AppState) => ({
    examToPass: state.examReducer.examToPass,
    examResult: state.studentTraining.examResult,
    connectedUser: state.authReducer.connectedUser
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamResultPage);

