import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { clearMessage, loginUser, changeRole, getUserById } from '../../../global-state/actions/auth-actions';
import { closeModal, closeModalWithAction, openModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import { useHistory } from 'react-router-dom';




const SignInModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [msg, setMsg] = useState('');
    const [showP, setShowP] = useState(false);
    const history = useHistory();

    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)
    const [emailError, setEmailError] = useState("")


    useEffect(() => {
        if (!regx.test(email) && email.length !== 0) {
            setEmailError("Adresse e-mail invalide")
        } else {
            setEmailError("")
        }
    }, [email])


    const signIn = () => {
        const offer_token: any = localStorage.getItem("access_limitation");
        localStorage.clear();
        localStorage.setItem("access_limitation", offer_token);
        props?.loginUser(email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), password)
    }

    const continueToFollowTraining = async () => {
        await props?.closeModalWithAction("connected");
    }

    const changeProfile = async (roles: any) => {
        await props?.changeRole(roles, props?.connectedUser);
        await props?.getUserById(props?.connectedUser?.id);
        await continueToFollowTraining();
    }

    useEffect(() => {
        if (props?.connectedUser &&
            localStorage.getItem('access_token') !== null
            && localStorage.getItem('access_token') !== undefined
            && localStorage.getItem('access_token') !== '') {
            props?.closeModal()

            if (props?.connectedUser?.possibleRoles?.length > 1) {
                changeProfile("role_student")

            }
            else {
                continueToFollowTraining()
            }

        }
    }, [props?.connectedUser?.id])

    useEffect(() => {
        if (props?.error != undefined && props?.error?.length !== 0) {
            setMsg("Mot de passe incorrecte")
        }
    }, [props?.error]);

    const checkMsg = () => {
        if (msg && msg !== "") {
            props?.clearMessage();
        }
    }

    const signUp = () => {
        props?.openModal({ idModal: ModalIDS.SIGN_UP_MODAL, size: "sm", data: {} });
    }

    useEffect(() => {
        props?.clearMessage();
    }, [])





    return (
        < div style={{ maxHeight: "816px" }}>
            <div className="modal-header" style={{ border: "none" }}>
                <div className="ms-auto" style={{ paddingTop: "24px", paddingRight: "24px" }}>
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body w-100" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ paddingRight: "0px !important" }} >
                        <div className="H3-Headline black-800" style={{ paddingLeft: "25px" }}>Connexion </div>
                        <span className="body-md  black-800" style={{ paddingLeft: "25px" }}>Finalisez votre achat et profitez du</span>
                        <span className="body-md  black-800" style={{ paddingLeft: "25px" }}>cours en vous connectant</span>
                        <div style={{ paddingTop: "27px" }}>
                            <div style={{ paddingBottom: "16px" }}>
                                <span className='body-md bold'>{t('emailLogin')} </span>
                                <input dir="ltr" type="text" style={{ maxWidth: "384px" }} className={emailError?.length !== 0 ? "form-control input text-default error" : "form-control input text-default"} placeholder={t('emailLogin')} value={email} onChange={(event) => { checkMsg(); setEmail(event.target.value.trim()) }} />
                                {emailError?.length !== 0 && <span className="error-input mt-1">{emailError}</span>}
                            </div>
                            <div className="w-100">
                                <span className='body-md bold'>{t('password')} </span>
                                <div className="input-form-control">
                                    <input dir="ltr" type={showP ? "text" : "password"} style={{ width: "384px" }} className={msg?.length != 0 ? "form-control input text-default error" : "form-control input text-default"} placeholder={t('password')} value={password} onChange={(event) => { checkMsg(); setPassword(event.target.value) }} />
                                    {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                    {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                </div>
                                {msg?.length !== 0 && <span className="error-input mt-1">{msg}</span>}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{ paddingTop: "49px" }}>
                            <button type="button" className="btn-Primary large" onClick={(event) => { event.preventDefault(); signIn() }}>
                                Se connecter
                            </button>
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{ paddingBottom: "40px" }}>
                            <div className=" text-center">
                                <span className="body-md bold black-800" >Vous n’avez pas de compte ? </span>
                                <button className="mt-3 btn-text forgot-password" onClick={() => signUp()}>S’inscrire</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ div>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    actualOffer: state.courseReducer.actualOffer,
    lengthCourses: state.courseReducer.LengthCoursesPublished,
    error: state.messageReducer.error,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            loginUser,
            closeModal,
            openModal,
            clearMessage,
            closeModalWithAction,
            getUserById,
            changeRole
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(SignInModalComponent);