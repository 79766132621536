import { FC } from "react";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import FooterComponent from "../../components/footer-component";
import "../../translations/i18n";
import CustomSwitch from "../../helpers/custom-switch";
import WebinarsNotFoundPage from "./webinars-not-found-page";
const WebinarsNotFoundContainer: FC<any> = (props: any) => {
  return (<>
     <div className="page-wrapper-height"  style={{ backgroundColor: '#ffffff' }}>
     <CustomSwitch>
      <RouteWithNavbar history={props?.history} path={`${props?.match.path}`} exact={true} component={WebinarsNotFoundPage} />
      
    </CustomSwitch>
    </div>
    <FooterComponent />
  </>
  )
}
export default WebinarsNotFoundContainer;