import { Switch, Redirect } from "react-router-dom";

const CustomSwitch = (props: any) => {
  const { children } = props;
  return (
    <Switch>
      {props?.children}
      <Redirect exact={true} path="**" to="/404" />
    </Switch>
  );
}
export default CustomSwitch;