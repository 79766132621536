import React from 'react'


function connectToStripeWebhookComponent() {


    const redirectToStripeTuto =()=>{
        window.open('https://www.koors.io/stripe', '_blank');
    }


    return (
        <div className='pt-3 d-flex flex-row align-item-center justify-content-between' style={{ gap: "100px", padding: "24px 46px 24px 24px", borderRadius: "4px", border: "1px solid var(--neutre-5, #F2F2F5)" }}>
            <div>
                <div>
                    <span className="H4-Subtitle ">Suivi des paiements de vos formations</span>
                </div>
                <div >
                    <p className="body-md pt-1">Pour activer le suivi automatique des paiements de vos abonnements et suivre leurs statuts, branchez votre compte
                        sur notre système de notification. On vous explique cela sur cette page</p>
                </div>
            </div>
            <div>

                <button type="button" className="btn-Primary medium icon-right" style={{ marginTop: "28px" }} onClick={()=>redirectToStripeTuto()}>
                    <div className="m-auto pe-2" >Voir comment</div>
                    <span className="material-icons-outlined">
                        open_in_new
                    </span>
                </button>
            </div>

        </div>
    )
}

export default connectToStripeWebhookComponent