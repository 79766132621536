import { ComponentProps, FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AppState } from '../../global-state/store/root-reducers';
import EditorToolbar, { modules, formats } from '../../elements/edit-toolbar-quill/edit-toolbar-quill';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { setCurrentQuestion, submitOpenQuestionResponse } from '../../global-state/actions/exam-actions';
import { useParams, useHistory } from 'react-router-dom';
import { calculateTraining, getCurrentCourse } from '../../global-state/actions/student-training-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';


interface Params {
    id: any
}
const PassOpenQuestionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const [submission, setSubmission] = useState<string>("");
    const [question, setQuestion] = useState(props?.question);
    const [isAnswered, setIsAnswered] = useState(false);
    const { t } = useTranslation();
    const { id } = useParams<Params>();
    const [openQuestionForm, setOpenQuestionForm] = useState<any>();
    const history = useHistory();
    const [idProgression, setIdProgression] = useState(props?.idProgression)
    const [loader, setLoader] = useState<boolean>(false)

    useEffect(() => {
        if(props?.idProgression!=null){
        setIdProgression(props?.idProgression)
    }
    }, [])

    useEffect(() => {
        if(props?.idProgression!=null){
        setIdProgression(props?.idProgression)
    }
    }, [props?.idProgression])
    


    useEffect(() => {
        setQuestion(props?.question);
        if (props?.studentAnswers && props?.studentAnswers[props?.indexQuestionToPass] && props?.studentAnswers[props?.indexQuestionToPass]?.id !== null) {
            const obj = props?.studentAnswers[props?.indexQuestionToPass];
            obj['questionByExamId'] = props?.question?.id
            if (obj.examId === undefined) {
                obj['examId'] = Number(id);
            }
            obj['studentId'] = props?.connectedUser?.idPerson;
            setOpenQuestionForm(obj);
            setSubmission(obj?.studentOpenQuestionSubmission);
            setIsAnswered(true);
        } else {
            setOpenQuestionForm({
                id: null,
                studentId: props?.connectedUser?.idPerson,
                questionByExamId: props?.question?.id,
                examId: id,
                idProgressQna: idProgression != null ? idProgression :props?.idProgression,
                studentOpenQuestionSubmission: "",
            });
            setSubmission("");
            setIsAnswered(false)
        }
    }, [props?.question]);

    const saveOpenQuestionResponse = async (e: any) => {
        setLoader(true)
        const copy = { ...openQuestionForm };
        copy.studentOpenQuestionSubmission = submission;
        setOpenQuestionForm(copy);
        await props?.submitOpenQuestionResponse(copy, props?.indexQuestionToPass, (props?.currentCourse?.followUp?.progressTraining === null)?null :props?.currentCourse?.followUp?.progressTraining,props?.currentCourse);
        if( props?.currentCourse?.type !== "COURSE") {
            await props?.calculateTraining(props?.currentCourse, props?.currentTraining, props?.listFollowUps, props?.currentProgress)
        }
        await postVAlid()
        setLoader(false)
    }
    const changeInput = (data: any) => {
        setSubmission(data);

    }

    const postVAlid = async () => {
        if (props?.indexQuestionToPass < props?.exam?.questionByQnaList.length - 1) {
            props?.setCurrentQuestion(props?.indexQuestionToPass + 1);
        } else {
            await props?.getCurrentCourse(props?.currentCourse?.followUp?.id);

            if(props?.currentTraining?.type === 'SESSION') {
                if(props?.currentCourse?.type === "COURSE") {
                    history.push(`${returnStartPathUrl()}/passSession/passCourse/${props?.currentCourse?.followUp?.id}`); 
                } else {
                    history.push(`${returnStartPathUrl()}/passSession/progressSession/${props?.currentTraining?.followUp?.id}`); 
                }
            } else {
                if( props?.currentCourse?.type === 'PATH') {
                    history.push(`${returnStartPathUrl()}/passPath/progressPath/${props?.currentCourse?.followUp?.id}`);
                } else {
                    if(props?.currentTraining?.type === 'PATH') {
                        history.push(`${returnStartPathUrl()}/passPath/passCourse/${props?.currentCourse?.followUp?.id}`); 
                    } else {
                        history.push(`${returnStartPathUrl()}/passCourse/course/${props?.currentCourse?.followUp?.id}`); 
                    }
                }
            }
        }
    };

    return (
        < div  >
            <div style={{ textAlign: 'center' }}>

                <span > {question?.question.questionAnswerExplanation}</span>

            </div>
            <div style={{ paddingTop: "24px" }}>
                <div>
                    <EditorToolbar />
                </div>
                <div style={{ minHeight: 292 }} >
                    <ReactQuill theme="snow" modules={modules} formats={formats} value={submission+""} onChange={(e: any) => (!isAnswered) ? changeInput(e) : {}} />
                </div>
            </div>
            <div className="row " style={{ margin: "32px 0px 0px 0px " }}  >
                {!loader ? <button disabled={isAnswered} className="btn-Primary large px-4" onClick={(e) => { saveOpenQuestionResponse(e) }}> {t("Valider_ma_réponse")} </button> 
                    :
                    <button className="btn-Primary large w-100 py-4">
                        <div className="threedots-large-Animation ">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>     
                }
            </div>

        </div>
    )
}




const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            submitOpenQuestionResponse, setCurrentQuestion, calculateTraining, getCurrentCourse
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    studentAnswers: state.examReducer.listOfResponses,
    currentCourse: state.studentTraining.currentCourse,
    currentTraining: state.studentTraining.currentTraining,
    listFollowUps: state.studentTraining.listFollowUps,
    currentProgress: state.studentTraining.currentTrainingProgress,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    sectionContentDetails: state.studentTraining.sectionContentDetails,
    exam: state.examReducer.examToPass,
});

export default connect(mapStateToProps, mapDispatchToProps)(PassOpenQuestionComponent);
