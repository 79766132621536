import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { getStudent } from "../../global-state/actions/profile-actions";
import { getCoursesForDirector } from "../../global-state/actions/course-actions";
import "../../translations/i18n";
import HeaderStudentsListComponent from "../../components/headers-page/header-students-list-component";
import FilterStudentsListComponent from "../../components/filters/filter-students-list-component";
import TableStudentsListComponent from "../../components/tables/table-students-list-component";
import FilterStudentGroupsComponent from "../../components/filters/filter-students-groups-component";
import GroupListComponent from "../../components/group/group-list-component";
import {
  getAllFollowsByCandidateApi,
  getAllFollowsByCertifiedApi,
  getAllFollowsByGeneralApi,
  getAllFollowsByInscribedApi,
  getAllFollowsBySuspendedApi,
  getAllFollowsCountApi,
  getAllFollowsUnpaidApi,
  getStudentsListApi
} from "../../services/professor.service";
import { fetchData } from "../../base/functions/Functions";
import {
  getGroupBySchool,
  searchGroupByLabel
} from "../../services/groupe.student.service";
import { clearModal } from "../../global-state/actions/modal-actions";
import InviteStudentModalComponent from "../../components/modals/modal-forms/invite-student-modal-component";
import InvitationStudentListModalComponent from "../../components/modals/modal-forms/invitation-student-list-modal-component";
import Dialog from "@material-ui/core/Dialog";
import ValideInvitationModalComponent from "../../components/modals/modal-forms/valide-invitation-modal-component";
import AnnuleInvitationModalComponent from "../../components/modals/modal-forms/annule-invitation-modal-component";
import ErrorInvitationModalComponent from "../../components/modals/modal-forms/error-invitation-modal-component";

const StudentsListPage: FC<
  ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ComponentProps<any>
> = (props) => {
  const [searchGeneral, setSearchGeneral] = useState(false);
  const [searchCandidate, setSeachCandidate] = useState(false);
  const [searchCertified, setSearchCertified] = useState(false);
  const [searchInscribed, setSearchInscribed] = useState(false);
  const [searchSuspended, setSearchSuspended] = useState(false);
  const [searchUnpaid, setSearchUnpaid] = useState(false);

  const [sizeGeneral, setSizeGeneral] = useState(20);
  const [sizeCandidates, setSizeCandidates] = useState(20);
  const [sizeCertified, setSizeCertified] = useState(20);
  const [sizeInscribed, setSizeInscribed] = useState(20);
  const [sizeSuspended, setSizeSuspended] = useState(20);
  const [sizeUnpaid, setSizeUnpaid] = useState(20);
  const [loading, setLoading] = useState(true);
  const [isSearch, setIsSearch] = useState(false);

  const [studentsList, setStudentsList] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageNumberInscribed, setPageNumberInscribed] = useState<number>(0);
  const [studentsListStatus, setStudentsListStatus] = useState<string>("all");
  const [studentSearch, setStudentSearch] = useState<string>("");
  const [loadingAllStudentsList, setLoadingAllStudentsList] =
    useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  const [actualTab, setActualTab] = useState("All");
  const [courseList, setCourseList] = useState<any>([]);
  const [general, setGeneral] = useState<any>();
  const [suspended, setSuspended] = useState<any>();
  const [candidate, setCandidate] = useState<any>();
  const [certified, setCertified] = useState<any>();
  const [inscribed, setInscribed] = useState<any>();
  const [unpaid, setUnpaid] = useState<any>();
  const [followCount, setFollowCount] = useState<any>();
  const [groupList, setGroupList] = useState([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [actualTabModal, setActualTabModal] = useState("one");
  const [modalListInviteOpened, setModalListInviteOpened] =
    useState<boolean>(false);
  const [inviteStudentsList, setInviteStudentsList] = useState([]);
  const [modalSuccessOpened, setModalSuccessOpened] = useState<boolean>(false);
  const [modalErrorOpened, setModalErrorOpened] = useState<boolean>(false);
  const [modalCancelOpened, setModalCancelOpened] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const updateLoaderValue = (value: boolean) => {
    setLoader(value);
  };

  const getNextPage = async (nextPage: number) => {
    if (nextPage !== 0) {
      let filter = {
        schoolId: props?.connectedUser?.idSchool,
        search: studentSearch,
        page: nextPage,
        studentsListStatus: studentsListStatus
      };
      await getListStudentsList(filter);
      await setPageNumber(nextPage);
    }
  };

  const getNextPageInscribed = async (nextPage: number, searchValue:any) => {
    const search = {
      inscribedFilter: searchValue ? searchValue : "",
    };
    if (actualTab === "Inscribed") {
      if (nextPage !== 0) {
        let filter = {
          entityId: props?.connectedUser?.idSchool,
          entityType:'SCHOOL'
        };
        await getAllFollowsByInscribed(filter,search,{sizeInscribed: sizeInscribed},courseList,nextPage);
        await setPageNumberInscribed(nextPage);
      }
    }
  };

  const getNextPageGeneral = async (searchValue:any) => {
    let filter = {
        entityId: props?.connectedUser?.idSchool,
        entityType:'SCHOOL'
    };
    const search = {
        generalFilter:searchValue ? searchValue : ""
    };
    if (searchValue === "" || searchValue===undefined){
        await getAllFollowByGeneral(props?.connectedUser?.role, filter, search, {sizeGeneral:sizeGeneral +20}, courseList)
        setSizeGeneral(sizeGeneral + 20);
    }else{
        await getAllFollowByGeneral(props?.connectedUser?.role, filter, search, {sizeGeneral:20}, courseList)
        setSizeGeneral(20);
    }
    
  }

  const getNextPageCertified = async (searchValue:any) => {
    let filter = {
        entityId: props?.connectedUser?.idSchool,
        entityType:'SCHOOL'
    };
    const search = {
        certifiedFilter:searchValue ? searchValue : ""
    };
    if (searchValue === "" || searchValue===undefined){
        await getAllFollowsByCertified(filter,search, {sizeCertified:sizeCertified +20}, courseList)
        setSizeCertified(sizeCertified + 20);
    }else{
        await getAllFollowsByCertified(filter,search, {sizeCertified:20}, courseList)
        setSizeCertified(20);
    }
  }

  const getNextPageCandidate = async (searchValue:any) => {
    let filter = {
        entityId: props?.connectedUser?.idSchool,
        entityType:'SCHOOL'
    };
    const search = {
        candidatesFilter:searchValue ? searchValue : ""
    };
    if(searchValue === "" || searchValue===undefined){
        await getAllFollowsByCandidate(filter,search, {sizeCandidates:sizeCandidates +20}, courseList)
        setSizeCandidates(sizeCandidates + 20);
    }else{
        await getAllFollowsByCandidate(filter,search, {sizeCandidates:20}, courseList)
        setSizeCandidates(20);
    }
    
  }

  const getNextPageSuspended = async (searchValue:any) => {
    let filter = {
        entityId: props?.connectedUser?.idSchool,
        entityType:'SCHOOL'
    };
    const search = {
        suspendedFilter:searchValue ? searchValue : ""
    };
    if(searchValue === "" || searchValue===undefined){
        await getAllFollowsBySuspended(props?.connectedUser?.role,filter,search, {sizeSuspended:sizeSuspended +20}, courseList)
        setSizeSuspended(sizeSuspended + 20);
    }else{
        await getAllFollowsBySuspended(props?.connectedUser?.role,filter,search, {sizeSuspended:20}, courseList)
        setSizeSuspended(20);
    }
    
  }

  const getNextPageUnpaid = async (searchValue:any) => {
    let filter = {
        entityId: props?.connectedUser?.idSchool,
        entityType:'SCHOOL'
    };
    const search = {
        unpaidFilter:searchValue ? searchValue : "",
    };
    if(searchValue === "" || searchValue===undefined){
        await getAllFollowsUnpaid(filter,search, {sizeUnpaid:sizeUnpaid +20}, courseList)
        setSizeUnpaid(sizeUnpaid + 20);
    }else{
        await getAllFollowsUnpaid(filter,search, {sizeUnpaid:20}, courseList)
        setSizeUnpaid(20);
    }
  }

  useEffect(() => {
    if (props?.modalAction.length !== 0 && props?.modalAction === "reload") {
      initialiseList();
      props.clearModal();
    }
  }, [props?.modalAction]);

  const initialiseList = () => {
    let filter = {
      schoolId: props?.connectedUser?.idSchool,
      search: studentSearch,
      page: 0,
      studentsListStatus: studentsListStatus
    };
    getListStudentsList(filter);
    setPageNumber(0);
    props.clearModal();
  };

  const updateFilterData = async (search: string, status: string) => {
    await setStudentSearch(search);
    await setStudentsListStatus(status);
    let filter = {
      schoolId: props?.connectedUser?.idSchool,
      search: search,
      page: 0,
      studentsListStatus: status
    };
    if (props?.connectedUser?.role === 'role_director'){
        await getListStudentsList(filter);
    }
  };

  const getListStudentsList = async (filter: any) => {
    if(hasMoreData){
      if(filter.page !==0){
        await updateLoaderValue(true);
      }
    let data: never[] = await fetchData(
      "POST",
      `${process.env.REACT_APP_BASE_URL2}person/getAllStudentsList`,
      filter
    );
    if ((data.length === 0 || data.length < 20) && filter.page !== 0) {
      setHasMoreData(false);
    } 
    if (data.length !== 0 && filter.page !== 0) {
      await setStudentsList([...studentsList, ...data]);
    } else if (filter.page === 0) {
      await setStudentsList(data);
    }
    await updateLoaderValue(false);
  };
}

  const getInitialData = async () => {
    let filter = {
      schoolId: props?.connectedUser?.idSchool,
      search: "",
      page: 0,
      studentsListStatus: "all"
    };
    if (props?.connectedUser?.role === 'role_director'){
        await getListStudentsList(filter);
        await setLoadingAllStudentsList(false);
    }
    
  };

  useEffect(() => {
    fetchApiData();
    if (props?.connectedUser?.role === 'role_director'){
        getInitialData();
    }
    return () => {
      setPageNumber(0);
      setStudentSearch("");
    };
  }, []);

  const getAllFollowsCount = async (courseList: any) => {
    const result = await getAllFollowsCountApi(
      {
        entityId: props?.connectedUser?.idSchool,
        entityType: "SCHOOL",
        idProf: props?.connectedUser?.idPerson
      },
      courseList
    );
    setFollowCount(result);
  };

  const getAllFollowsByCandidate = async (
    filter: any,
    search: any,
    size: any,
    courseList: any
  ) => {
    const result = await getAllFollowsByCandidateApi(
      filter,
      search,
      size,
      courseList
    );
    setCandidate(result);
  };

  const getAllFollowsBySuspended = async (
    role: any,
    filter: any,
    search: any,
    size: any,
    courseList: any
  ) => {
    const result = await getAllFollowsBySuspendedApi(
      role,
      filter,
      search,
      size,
      courseList
    );
    setSuspended(result);
  };

  const getAllFollowsByCertified = async (
    filter: any,
    search: any,
    size: any,
    courseList: any
  ) => {
    const result = await getAllFollowsByCertifiedApi(
      filter,
      search,
      size,
      courseList
    );
    setCertified(result);
  };

  const getAllFollowsUnpaid = async (
    filter: any,
    search: any,
    size: any,
    courseList: any
  ) => {
    const result = await getAllFollowsUnpaidApi(
      filter,
      search,
      size,
      courseList
    );
    setUnpaid(result);
  };

  const getAllFollowsByInscribed = async (
    filter: any,
    search: any,
    size: any,
    courseList: any,
    page: any
  ) => {
    await updateLoaderValue(true);
    const result = await getAllFollowsByInscribedApi(
      filter,
      search,
      size,
      courseList,
      page
    );
   await setInscribed((prevState: any) => {
      if (prevState) {
        return {
          list: [...prevState.list, ...result.list],
        };
      } else {
        return result;
      }
    });
    await updateLoaderValue(false);

  };

  const getAllFollowByGeneral = async (
    role: any,
    filter: any,
    search: any,
    size: any,
    courseList: any
  ) => {
    const result = await getAllFollowsByGeneralApi(
      role,
      filter,
      search,
      size,
      courseList
    );
    setGeneral(result);
  };

  const fetchApiData = async () => {
    const search = {
      generalFilter: "",
      candidatesFilter: "",
      certifiedFilter: "",
      inscribedFilter: "",
      suspendedFilter: "",
      unpaidFilter: ""
    };
    const size = {
      sizeGeneral: sizeGeneral,
      sizeCandidates: sizeCandidates,
      sizeCertified: sizeCertified,
      sizeInscribed: sizeInscribed,
      sizeSuspended: sizeSuspended,
      sizeUnpaid: sizeUnpaid
    };
    const filter = {
      entityId: props?.connectedUser?.idSchool,
      entityType: "SCHOOL"
    };
    const role = props?.connectedUser?.role;
    if (props?.connectedUser?.role === "role_prof") {
      await getStudentsListApi(role, {
        entityId: props?.connectedUser?.idSchool,
        entityType: "SCHOOL",
        idProf: props?.connectedUser?.idPerson
      }).then((res) => {
        setCourseList(res);
        getAllFollowsCount(res);
        getAllFollowsByGeneralApi(role, filter, search, size, res).then(
          (resGeneral: any) => {
            setGeneral(resGeneral);
          }
        );
      });
    } else {
      await getStudentsListApi(role, filter).then((res) => {
        setCourseList(res);
        getAllFollowsCount(res);
      });
      getGroupBySchool(props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
    }
  };

  const fetchDataBySelectedTab = async (actualTab: string, copyState: any) => {
    const search = {
      generalFilter: "",
      candidatesFilter: "",
      certifiedFilter: "",
      inscribedFilter: "",
      suspendedFilter: "",
      unpaidFilter: ""
    };
    const size = {
      sizeGeneral: sizeGeneral,
      sizeCandidates: sizeCandidates,
      sizeCertified: sizeCertified,
      sizeInscribed: sizeInscribed,
      sizeSuspended: sizeSuspended,
      sizeUnpaid: sizeUnpaid
    };
    const filter =
      props?.connectedUser?.role === "role_prof"
        ? {
            entityId: props?.connectedUser?.idSchool,
            entityType: "SCHOOL",
            idProf: props?.connectedUser?.idPerson
          }
        : { entityId: props?.connectedUser?.idSchool, entityType: "SCHOOL" };
    const role = props?.connectedUser?.role;

    if (
      actualTab === "Inscribed" &&
      (inscribed === undefined || copyState !== "")
    ) {
      await getAllFollowsByInscribed(
        filter,
        copyState !== "" ? copyState.filters : search,
        copyState !== "" ? copyState.sizes : size,
        courseList,
        pageNumber
      );
    }
    if (
      actualTab === "Candidate" &&
      (inscribed === undefined || copyState !== "")
    ) {
      await getAllFollowsByCandidate(
        filter,
        copyState !== "" ? copyState.filters : search,
        copyState !== "" ? copyState.sizes : size,
        courseList
      );
    }
    if (actualTab === "Unpaid" && (unpaid === undefined || copyState !== "")) {
      await getAllFollowsUnpaid(
        filter,
        copyState !== "" ? copyState.filters : search,
        copyState !== "" ? copyState.sizes : size,
        courseList
      );
    }
    if (
      actualTab === "Certified" &&
      (certified === undefined || copyState !== "")
    ) {
      await getAllFollowsByCertified(
        filter,
        copyState !== "" ? copyState.filters : search,
        copyState !== "" ? copyState.sizes : size,
        courseList
      );
    }
    if (
      actualTab === "Suspended" &&
      (suspended === undefined || copyState !== "")
    ) {
      await getAllFollowsBySuspended(
        role,
        filter,
        copyState !== "" ? copyState.filters : search,
        copyState !== "" ? copyState.sizes : size,
        courseList
      );
    }
    if (
      actualTab === "All" &&
      (general === undefined || copyState !== "") &&
      props?.connectedUser?.role === "role_director"
    ) {
      await getAllFollowByGeneral(
        role,
        filter,
        copyState !== "" ? copyState.filters : search,
        copyState !== "" ? copyState.sizes : size,
        courseList
      );
    }
  };

  useEffect(() => {
    fetchDataBySelectedTab(actualTab, "");
  }, [actualTab]);

  useEffect(() => {
    if (props?.connectedUser?.role === "role_director") {
      getGroupBySchool(props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
    }

    if (props?.modalPage === false) {
      fetchApiData();
    }
    props?.clearModal();
  }, [props?.modalPage]);

  const SearchGroup = (value: string) => {
    if (value.length > 2) {
      searchGroupByLabel(value, props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
      setIsSearch(true);
    }
    if (value.length === 0) {
      getGroupBySchool(props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
      setIsSearch(false);
    }
  };

  const filterGroup = (value: boolean) => {
    setIsSearch(value);
  };

  const checkStudentsAllCategories = () => {
    //CONDITION FOR ALL STUDENTS
    if (followCount?.GENERAL > 0 && actualTab === "All")
      return (
        <FilterStudentsListComponent
          data={{
            studentSearch: studentSearch,
            studentsListStatus: studentsListStatus
          }}
          actualTab={actualTab}
          updateFilterData={updateFilterData}
          pageNumber={pageNumber}
          getNextPage={getNextPage}
          getNextPageGeneral={getNextPageGeneral}
          setSearchGeneral={setSearchGeneral}
        />
      );

    //CONDITION FOR INSCRIBED STUDENTS
    if (followCount?.INSCRIBED > 0 && actualTab === "Inscribed")
      return (
        <FilterStudentsListComponent
          actualTab={actualTab}
          pageNumber={pageNumber}
          getNextPage={getNextPage}
          getNextPageInscribed={getNextPageInscribed}
          setSearchInscribed={setSearchInscribed}
        />
      );

    //CONDITION FOR CANDIDATE STUDENTS
    if (followCount?.CANDIDATE > 0 && actualTab === "Candidate")
      return (
        <FilterStudentsListComponent
          actualTab={actualTab}
          setSearchCandidate={setSeachCandidate}
          getNextPageCandidate={getNextPageCandidate}
        />
      );

    //CONDITION FOR UNPAID STUDENTS
    if (followCount?.UNPAID > 0 && actualTab === "Unpaid")
      return (
        <FilterStudentsListComponent
          actualTab={actualTab}
          getNextPageUnpaid={getNextPageUnpaid}
          setSearchUnpaid={setSearchUnpaid}
        />
      );

    //CONDITION FOR CERTIFIED STUDENTS
    if (followCount?.CERTIFIED > 0 && actualTab === "Certified")
      return (
        <FilterStudentsListComponent
          actualTab={actualTab}
          getNextPageCertified={getNextPageCertified}
          setSearchCertified={setSearchCertified}
        />
      );

    //CONDITION FOR SUSPENDED STUDENTS
    if (followCount?.SUSPENDED > 0 && actualTab === "Suspended")
      return (
        <FilterStudentsListComponent
          actualTab={actualTab}
          getNextPageSuspended={getNextPageSuspended}
          setSearchSuspended={setSearchSuspended}
        />
      );
  };
  return (
    <div className='container-fluid'>
      <span
        id='step-student-page_director'
        style={{ position: "absolute" }}
      ></span>

      {modalOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                // Set 'open' to false, however you would do that with your particular code.
                setModalOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <InviteStudentModalComponent
              setInviteStudentsList={setInviteStudentsList}
              setModalListInviteOpened={setModalListInviteOpened}
              setModalOpened={setModalOpened}
              general={general}
              setGeneral={setGeneral}
              studentsList={studentsList}
              setStudentsList={setStudentsList}
              actualTabModal={actualTabModal}
              setActualTabModal={setActualTabModal}
            />
          </Dialog>
        </div>
      )}

      {modalListInviteOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalListInviteOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                // Set 'open' to false, however you would do that with your particular code.
                setModalListInviteOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <InvitationStudentListModalComponent
              inviteStudentsList={inviteStudentsList}
              setModalOpened={setModalOpened}
              setModalListInviteOpened={setModalListInviteOpened}
              setModalSuccessOpened={setModalSuccessOpened}
              setModalErrorOpened={setModalErrorOpened}
              setModalCancelOpened={setModalCancelOpened}
            />
          </Dialog>
        </div>
      )}

      {modalSuccessOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalSuccessOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalSuccessOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <ValideInvitationModalComponent
              inviteStudentsList={inviteStudentsList}
              setInviteStudentsList={setInviteStudentsList}
              setModalSuccessOpened={setModalSuccessOpened}
              general={general}
              setGeneral={setGeneral}
              studentsList={studentsList}
              setStudentsList={setStudentsList}
            />
          </Dialog>
        </div>
      )}

      {modalErrorOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalErrorOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalErrorOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <ErrorInvitationModalComponent
              setModalListInviteOpened={setModalListInviteOpened}
              setModalErrorOpened={setModalErrorOpened}
            />
          </Dialog>
        </div>
      )}

      {modalCancelOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalCancelOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalCancelOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <AnnuleInvitationModalComponent
              setModalCancelOpened={setModalCancelOpened}
              setModalListInviteOpened={setModalListInviteOpened}
              setModalOpened={setModalOpened}
            />
          </Dialog>
        </div>
      )}

      <div className='row justify-content-center'>
        <HeaderStudentsListComponent
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
          actualTab={actualTab}
          setActualTab={setActualTab}
          followCount={followCount}
          groupList={groupList}
          setActualTabModal={setActualTabModal}
        />
        {actualTab !== "Groups" ? (
          <>
            {checkStudentsAllCategories()}
            <TableStudentsListComponent
              data={{
                sizeGeneral: sizeGeneral,
                sizeCandidates: sizeCandidates,
                sizeCertified: sizeCertified,
                sizeInscribed: sizeInscribed,
                sizeSuspended: sizeSuspended,
                sizeUnpaid: sizeUnpaid,
                searchGeneral: studentsList,
                searchCandidate: searchCandidate,
                searchCertified: searchCertified,
                searchInscribed: searchInscribed,
                searchSuspended: searchSuspended,
                searchUnpaid: searchUnpaid
              }}
              followCount={followCount}
              updateFilterData={updateFilterData}
              studentsList={studentsList}
              getNextPage={getNextPage}
              pageNumber={pageNumber}
              loader={loader}
              actualTab={actualTab}
              setActualTab={setActualTab}
              general={general}
              unpaid={unpaid}
              inscribed={inscribed}
              certified={certified}
              suspended={suspended}
              candidate={candidate}
              getNextPageInscribed ={getNextPageInscribed}
              pageNumberInscribed = {pageNumberInscribed}
              getNextPageCertified = {getNextPageCertified}
              getNextPageCandidate = {getNextPageCandidate}
              getNextPageSuspended = {getNextPageSuspended}
              getNextPageUnpaid = {getNextPageUnpaid}
              getNextPageGeneral = {getNextPageGeneral}
              modalOpened={modalOpened}
              setModalOpened={setModalOpened}
              setActualTabModal={setActualTabModal}
            />
          </>
        ) : (
          <>
            <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
              <FilterStudentGroupsComponent
                groupList={groupList}
                Searching={SearchGroup}
                filter={filterGroup}
              />
              <GroupListComponent isSearch={isSearch} groupList={groupList} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  myStudents: state.courseReducer.myStudents,
  nameSchool: state.authReducer.connectedSchoolInfo?.code,
  connectedUser: state.authReducer.connectedUser,
  listeStudents: state.profReducer.listeStudents,
  countFormationStudents: state.profReducer.countFormationStudents,
  courseInfo: state.courseReducer.courseInfo,
  registration: state.courseReducer.registration,
  applicationFile: state.courseReducer.applicationFile,
  history: ownProps?.history,
  studentInfo: state.courseReducer.studentInfo,
  lastTabClicked: state.profReducer.lastTabClicked,
  loadingAllStudentsList: state.profReducer.loadingAllStudentsList,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  pageNumber: state.courseReducer.pageNumber,
  modalPage: state.modalReducer.modalPage,
  modalAction: state.modalReducer.modalAction
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getStudent,
      getGroupBySchool,
      searchGroupByLabel,
      clearModal,
      getCoursesForDirector
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentsListPage);
