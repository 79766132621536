import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import GroupCard from '../group/group-card/group-card-component';
import { ModalIDS } from '../modals/modal-ids';
import {openModal, closeModal} from '../../global-state/actions/modal-actions';
interface Props {
    isSearch: boolean,
}
const GroupListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [groups, setGroups] = useState<any>(props?.groupList)

    useEffect(() => {
        setGroups(props?.groupList)
    }, [props?.groupList])

    const openModalFn = async () => {
        await props?.openModal({ idModal: ModalIDS.ADD_NEW_GROUP, size: "md" })
    }
    
    return (
        <>
            {(groups?.length === 0 && !props?.isSearch) &&
                <div className="w-100 text-center mt-5 pt-5 ">
                    <EmptyListElement lines={[t('noGroupFound')]} />
                    <button type="button" style={{ margin: '0 auto' }} className="btn-Primary large icon-left" onClick={() => openModalFn()}>
                        <span className="material-icons-outlined" style={{ marginRight: '8px' }} >add</span>
                        <div className="m-auto ps-2">{t('newGroup')}</div>
                    </button>
                </div>
            }
            {(groups?.length === 0 && props?.isSearch) &&
                <div className="w-100 text-center mt-5 pt-5">
                    <EmptyListElement lines={[t('noGroupFound_2')]} />
                </div>
            }
            <div className="grid-cards w-100 mx-auto justify-content-center mt-5"  style={{ maxWidth: '1200px' }} >
            {groups?.map((group: any) => {
                return <div key={group.id}><GroupCard group={group}  /> </div>
            })}
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState , ownProps:any) => ({

    isSearch: ownProps?.isSearch



});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupListComponent);
