import React, { FC } from "react";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { useHistory } from "react-router";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { createOrUpdateSurvey } from "../../global-state/actions/sondage-actions";

const BtnAddSondageComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ 
  fromEmptyList, 
  title, 
  addSondage, 
  questionByQnaList, 
  schoolInfo, 
  fromAddSondageMultiple, 
  fromAddSondageCheckBox, 
  fromAddSondageOpenQuestion,
  createOrUpdateSurvey,
  sondageReducer
}: any) => {

  const history = useHistory();

  const getQuizType = () => {
    if(fromAddSondageCheckBox) return "QCU"
    if(fromAddSondageMultiple) return 'QCM'
  }

  const questionByExamObject = () => {
    if(fromAddSondageMultiple || fromAddSondageCheckBox) return {
      id: null,
      question: {
          type: 'CQ', 
          id: null,
          questionStatement: "",
          questionAnswerExplanation: "",
          quizType: getQuizType(),
          questionChoice: [
              {
                  id: null,
                  choice: "", 
                  choiceState: false 
              }
          ]
      },
      scaleNote: null
    }

    if(fromAddSondageOpenQuestion) return {
      id: null,
      scaleNote: null,
      question: {
          id: null,
          questionStatement: "dfdf", 
          questionAnswerExplanation: "",
          type: "OQ", 
          questionAssessmentLink: ""
      }
    }
  }

  const handleAdd = () => {
    return history.push(`${returnStartPathUrl()}/create-sondage`);

  }

  return (
    <button
      type="button"
      className={`btn-Secondary large icon-left mx-auto`}
      onClick={() => handleAdd()}
    >
      <span className="material-icons-outlined">add</span>
      <div className="m-auto ps-2">{title}</div>
    </button>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
    fromEmptyList: ownProps.fromEmptyList,
    title: ownProps.title,
    addSondage: ownProps.addSondage,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    sondageReducer: state.examReducer.examToPass

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    createOrUpdateSurvey
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BtnAddSondageComponent);
