import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { sendMyRequest } from '../../../global-state/actions/course-actions';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { ModalIDS } from '../modal-ids';
import { useLocation } from 'react-router-dom';
import { getDomainFromUrl, returnDynamicValueFromUrl } from '../../../helpers/domainCheck';

const RefuseRegistrationModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<string>('')
    const location = useLocation();
    const inputChange = (value: string) => {
        setComment(value);
    }
    const getMessage=(type:string)=>{
         switch(type){
            case 'COURSE':
                return t('messageRefuseRegistrationCours') ;
            case 'PATH':
                return t('messageRefuseRegistrationPath');
            default:
                return t('messageRefuseRegistrationSession');
        }
    }
    const refuseRegistration = async () => {
        let code = getDomainFromUrl()

        let status = {
            status: "REFUSED",
            follow: { id: returnDynamicValueFromUrl(3) }
        };

        let obj = {
            comment: comment,
            idStudent: props?.actualStudent?.id,
            idRegistration: props?.registration?.id,
            status: "REFUSED",
            code: code,
            idFollow: returnDynamicValueFromUrl(3),
            title: (props?.actualStudent?.courseTitle)?props?.actualStudent?.courseTitle:props?.actualStudent?.title,
            idSchool: props?.connectedUser?.idSchool,
            trainingType:props?.actualStudent?.type,
        }
        await props?.sendMyRequest(status, obj, props?.connectedUser?.idPerson, props?.actualStudent?.id);
        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('backtodemand'), lines: [getMessage(props?.actualStudent.type)] });

    }
    return (
        <>

            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-start mb-1">
                                <h3 className="H3-Headline">{t('refuserDemandeRegistration')}</h3>
                            </div>
                            <div className="col-md-12 mb-2">
                                <h4 className="H4-Subtitle neutral-3">{props?.actualStudent.courseTitle}</h4>
                            </div>
                            <div className="col-md-12  mb-2 input-text-form-control">
                                <span className="body-sm">{t('commentStudentSuspend')}</span>
                            </div>
                            <div className="col-md-12  mb-4 input-text-form-control">
                                <TextareaUnderlineElement id={"commentPublishProf"} value={comment} onChange={(value: string) => inputChange(value)} placeholder={t('modalPublishCourse_3')} />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none" }}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-9 d-flex  justify-content-end' >
                        <button className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large danger" disabled={comment === ''} style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { refuseRegistration() }} >{t('refuser')}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    actualStudent: state.detailsProfileReducer.actualStudent,
    connectedUser: state.authReducer.connectedUser,
    registration: state.courseReducer.registration
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            sendMyRequest,
            closeModal

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(RefuseRegistrationModalComponent);