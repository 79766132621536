import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";




export default function ContentCourseCardPreviewComponent(props: any) {
  const { t } = useTranslation();
  const dateFormat = require('dateformat');
  const [level, setLevel] = useState<string>('');
  const levels = [
    { value: 'BEGINNER', label: t('selectLevelCourse') + ' ' + t('selectLevelCourse_1') },
    { value: 'INTERMEDIATE', label: t('selectLevelCourse') + ' ' + t('selectLevelCourse_2') },
    { value: 'ADVANCED', label: t('selectLevelCourse') + ' ' + t('selectLevelCourse_3') },
    { value: 'ANYLEVEL', label: t('anyLevelCourse') },

  ]
  useEffect(() => {
    let level = levels.find((level: any) => level?.value === props?.content?.level);
    if (level) {
      setLevel(level?.label);
    } else {
      setLevel('');
    }
  }, [props?.content]);
  const convertTime = (minutes: number) => {
    if (minutes < 60) {
      return minutes + ' MN';
    } else {
      return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
    }
  }





  return (<div className={` d-flex align-items-center bg-with-border white vignette-hover dragAndDrop  ps-40 pe-4 py-28 mt-3`} >
    <div style={{ width: 70 }}>
      <span className="H3-Headline">{(props?.index < 10) ? "0" + props?.index : props?.index}</span>
    </div>

    <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span className="H3-Headline mb-6 formation-card horizontal title">{props?.content?.title}</span>
          <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
            <div className="d-flex me-2">
              {(props?.content?.type === 'COURSE') ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                <line x1="5.38184" y1="9.77632" x2="10.6187" y2="9.77632" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                <line x1="10.6182" y1="6.22368" x2="5.38132" y2="6.22368" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
              </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909 12.4364 3.56364 13.0909
                 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z" fill="#FA83EE" />
              </svg>}
            </div>
            <span className="body-sm bold neutral-2">{t((props?.content?.type === 'COURSE') ? 'Cours' : 'parcoursTitle')}</span>
          </div>
        </div>
      </div>

      <div className=" d-flex align-items-center detail-liste-responsive-actions me-2 justify-content-end" style={{ minWidth: 290 }}>
        {(props?.type === 'session') && <span className="body-md bold black-800 me-4">{dateFormat(props?.startDate, "dd/mm/yyyy")}</span>}
        <div className="d-flex align-items-start">
          <span className="material-icons-outlined me-1" style={{ color: '#CACBCE' }}> signal_cellular_alt</span>
          <span className="body-md bold black-800 me-4" style={{ textTransform: 'capitalize' }}> {level} </span>
        </div>
        <div className="d-flex align-items-center detail-liste-responsive-chapter">
          <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
          <span className="body-md bold">{convertTime(props?.content?.duration)}</span>
        </div>
      </div>
    </div>
  </div>)
}
