import { FC, useEffect, useState } from "react"
import { getDuration } from '../../base/functions/Functions';
import { connect } from "react-redux"
import { AppState } from '../../global-state/store/root-reducers';
import { useLocation, useHistory } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from "react-i18next";
import axios from "../../helpers/interceptors"
import CheckoutStripeComponent from "./checkout-stripe-component";
import { updateShow } from '../../global-state/actions/course-actions';
import { saveReturnPath } from '../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { refreshToken } from "../../global-state/actions/auth-actions";
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import { createPaymentRequestApi } from "../../services/finance.service";

const levels = [
  { value: "BEGINNER", label: "selectLevelCourse_1" },
  { value: "INTERMEDIATE", label: "selectLevelCourse_2" },
  { value: "ADVANCED", label: "selectLevelCourse_3" },
  { value: "ANYLEVEL", label: "anyLevelCourse" },
]


const TrainingPaymentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [fullDuration, setFullDuration] = useState<number>(0)
  const [price, setPrice] = useState<any>(0);
  const stripePromise = loadStripe(`${process.env.REACT_APP_BASE_STRIPE_KEY}`, { stripeAccount: props?.paymentMethod?.accountId });
  const [active, setActive] = useState(true);
  const [promoCode, setPromocode] = useState("");
  const [message, setMessage] = useState("");
  const [productCode, setProductCode] = useState("KOORS")
  const [valide, setValide] = useState(false);
  const [retire, setRetire] = useState(false);
  const [retireMsg, setRetireMsg] = useState("Code promo retiré")
  const [newPrice, setNewPrice] = useState(0);
  const [coupon, setCoupon] = useState<any>();
  const [priceCoupon, setPriceCoupon] = useState<any>()
  const [couponReference, setCouponReference] = useState<any>()
  const [offerId, setOfferId] = useState<any>()
  const [pricings, setPricings] = useState<any>()
  const [actualPricings, setActualPricings] = useState<any>()
  const [trainingoffer, setTrainingoffer] = useState<any>()
  const [trainingOfferType, setTrainingOfferType] = useState<string>()
  const [selectedOneShot, setSelectedOneShot] = useState(true);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const [training, setTraining] = useState<any>()
  const [multiplePayment, setMultiplePayment] = useState(false)
  const [paymentChoice, setPaymentChoice] = useState(false)

  const handleContract = async (responseSubscription: any) => {
    if (props?.connectedUser?.role?.includes("role_student")) {
      axios.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract", {
        contractIdentifier: {
          contractId: null,
          contractReference: null
        },
        contractDetails: {
          offerId: trainingoffer?.id,
          contractDuration: {
            startAt: new Date(Date.now()),
            endAt: null,
          },
          pricingId: actualPricings?.id,
          paymentMethod: "CARD",
          paymentEngine: "STRIPE"
        },
        contractRoles: [{
          personId: props?.connectedUser?.idPerson,
          roleType: "STUDENT"
        }],
        status: [{
          statusType: "ONGOING"
        }]
      }).then(async (response: any) => {

        if (!!responseSubscription && responseSubscription !== "") {


          await Promise.all([
            axios.post(process.env.REACT_APP_BASE_URL13 + 'paymentsetting/createSetting/', {
              subjectId: response?.data?.contractIdentifier?.contractId,
              subjectType: "CONTRACT",
              enginePaymentId: responseSubscription.data.subscriptionId
            }),
          ]);
        }
      }).then((createdContract: any) => {

        let follow = {
          courseId: props?.courseInfo?.id,
          studentId: props?.connectedUser?.idPerson,
          trainingId: props?.courseInfo?.id,
          trainingType: props?.courseInfo?.type,
          entityId: props?.courseInfo?.entityId,
          entityType: props?.courseInfo?.entityType,
          statusList: [{
            status: "INSCRIBED"
          }],
          contractReference: createdContract?.data?.contractIdentifier?.contractReference
        }

        axios.post(process.env.REACT_APP_BASE_URL14 + "training", follow)
          .then(async (response3: any) => {
            props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
            if (props?.courseInfo.type === "COURSE") {
              await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passCourse/course/${response3?.data[0]?.id}`, buttonText: t('StartTheCourse') });

            }
            else if (props?.courseInfo?.type === "PATH") {
              await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passPath/progressPath/${response3?.data[0]?.id}`, buttonText: t('StartThePath') });


            } else {
              await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passSession/progressSession/${response3?.data[0]?.id}`, buttonText: t('StartTheSession') });
            }
          })
          .catch(err => console.error(err));
      }).catch(err => { console.error(err); }
      )
      props?.updateShow(false)
    }
    else {
      props?.saveReturnPath({ path: `${returnStartPathUrl()}/courses/my-courses/`, tabPage: '' });
      history.push(`${returnStartPathUrl()}/auth/login`);
    }

  }
  
  const handleClickCoupon = async () => {
    if (promoCode == "") {
      setMessage("Veuillez entrer un code promo")
    } else {

      const couponObject ={
        "product":"KOORS",
        "promoCode":promoCode
      }

      await axios.post(`${process.env.REACT_APP_BASE_URL22}coupons/couponsSearch` , couponObject).then(async (responseCoupon) => {
        setCoupon(responseCoupon?.data)
        let exists =responseCoupon?.data?.offers?.findIndex((couponOffer:any)=>couponOffer ===offerId);
        
        if (responseCoupon?.data?.expireAt != null && new Date(responseCoupon?.data?.expireAt) < new Date(Date.now())) {
          setMessage("Le code promo est expiré")

        } else if (responseCoupon?.data?.id == null) {

          setMessage("Le code promo est invalide")

        } else {
          await axios.get(`${process.env.REACT_APP_BASE_URL22}coupons/` + responseCoupon?.data?.referenceCode + "/" + props?.connectedUser?.idPerson + "/get").then((respUser) => {
            if (respUser.data == "User Exist") {
              setPriceCoupon(undefined)
              setMessage("Le code promo a déjà été utilisé")
            } else if(exists!=-1){
              setMessage("")
              setValide(true)
              setCouponReference(responseCoupon?.data?.referenceCode)
              if (responseCoupon?.data?.promotion?.promoType == "DISCOUNT") {
                setNewPrice(price * (responseCoupon?.data?.promotion?.promoValue / 100))
                setPriceCoupon(price - (price * responseCoupon?.data?.promotion?.promoValue / 100))

              }
              if (responseCoupon?.data?.promotion?.promoType == "CASHBACK") {
                setNewPrice(responseCoupon?.data?.promotion?.promoValue)
                if (price - responseCoupon?.data?.promotion?.promoValue < 0) {
                  setPriceCoupon(0)
                } else {
                  setPriceCoupon(price - responseCoupon?.data?.promotion?.promoValue)
                }

              }



            }else{
              setMessage("Le code promo est invalide")
            }

          })
        }
      });
    }


  }

  useEffect(() => {
    if (!!trainingoffer) {
      if (trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT")) {
        setActualPricings(trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT"))
      } else {

        setActualPricings(trainingoffer?.pricings[0])
      }

      if(trainingoffer?.pricings?.find((item: any) => item?.nbPeriod != null)){        
        setMultiplePayment(true);
      }
    }
  }, [trainingoffer])
  useEffect(() => {
    if (currentPath.includes("COURSE")) {
      setTraining(props?.courseInfo)
    } else {
      setTraining(props?.training)
    }
  }, [currentPath])

  const manageData = async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL12;
    const baseUrl3 = process.env.REACT_APP_BASE_URL11
    let offerId = await axios.get(baseUrl3 + `offers/offerId/` + training?.id.toString()).then(res => {
      setOfferId(res.data)
      return res.data
    }).catch(err => console.error(err));
    let pricings = await axios.get(baseUrl + `static/` + offerId).then(res => {
      setPricings(res.data)
      return res.data
    }).catch(err => console.error(err));
    let prices = pricings?.price;
    setPrice(prices)
    if (!!training) {
      await axios.get(`${process.env.REACT_APP_BASE_URL6}exams/getByCourseId/${training?.id}`).then(response => {
        setFullDuration(training?.duration + response.data?.map((test: any) => test?.duration).reduce((a: number, b: number) => a + b, 0))
      })
    }
    if (pricings?.currency === "TND") {
      const paymentRequestBody = {
        vendor: Number(props?.paymee?.accountId),
        amount: pricings?.price,
        note: training?.title
      }
      await createPaymentRequestApi(paymentRequestBody)
    }
  }

  const getTrainingOfferDetails = async () => {
    const baseUrl3 = process.env.REACT_APP_BASE_URL11
    await axios.get(baseUrl3 + `offers/offerId/` + training?.id.toString()).then(res => {
      axios.get(baseUrl3 + `offers/getOffer/` + res.data).then(offerResponse => {
        setTrainingoffer(offerResponse.data)
      }).catch((error: any) => {
        console.error(error)
      })
    }).catch((error: any) => {
      console.error(error)
    })
  }

  const defineOfferPricingType = async (offer: any) => {
    switch (true) {
      case (offer?.pricings?.[0]?.price > 0 && offer?.pricings?.[0]?.period === "ONESHOT"):
        setTrainingOfferType("unique");
        break;
      case (!offer?.pricings?.[0]?.nbPeriod && offer?.pricings?.[0]?.period !== "ONESHOT"):
        setTrainingOfferType("Abonnement");
        break;
      default:
        setTrainingOfferType("facilite");
        break;
    }
  };

  useEffect(() => {
    if (trainingoffer != undefined) {
      defineOfferPricingType(trainingoffer)
    }

  }, [trainingoffer])

  useEffect(() => {
    if (!!training && training?.id !=undefined) {
      manageData();
      getTrainingOfferDetails()
    }

  }, [training, props?.payement])


  const retireCoupon = () => {
    setValide(false)
    setRetire(!retire)
    setPriceCoupon(undefined)
  }

  useEffect(() => {
    if (!!actualPricings) {
      setPrice(actualPricings?.price)
    }
  }, [actualPricings])


  const handleGoBackClick = () =>{
    if(paymentChoice){
      setPaymentChoice(false)
    }
    else{
      history.goBack()
    }
  }


  

  
  return (<>
    <style>
      {`
      
        @media only screen and (max-width: 1024px) {
          #breadcramp {
            display: none;
          }
          #footer{
            display: none;
          }
          #payment-page{
            margin: 0px 16px;
          }
          #my-courses-container{
            height: 100vh;
          }
          
        }
        
      `}
    </style>

    <div className="d-flex d-lg-none justify-content-center align-items-center border-bottom" style={{width:"100%", height:"60px", backgroundColor:"white" }}>
      <a style={{ cursor: 'pointer', width:"15%", color:"black"}} onClick={() => { handleGoBackClick() }} className="d-flex justify-content-center align-items-center">
          <span className="material-icons-round">
              chevron_left
          </span>
      </a>
      
      <div style={{width:"85%", marginLeft: "30%"}} className="H4-Subtitle">
        {t('paymentTitle')}
      </div>
    </div>

    <div className="d-none d-lg-block container-fluid mt-4" >
      <div className=" flex-wrap" style={{ marginTop: "64px" }}>
        <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>
          <div className="row mx-auto" style={{ maxWidth: '1200px' }}>
            <div className="col-md-12 mt-5" >
              <span className="H2-Headline black-800">{t('paymentTitle')}</span>
            </div>

          </div>
        </div>

      </div>
      <div className="row justify-content-center" >
        <div className="col-md-12" >
          <div className="row mx-auto" style={{ maxWidth: '1440px' }}>
            <div className=" d-flex align-items-center justify-content-around " style={{ padding: 30, margin: 0 }}>

              {/* {props?.schoolInfo?.currency === "EUR" ? */}
              <div style={{ width: '50%', height: 'auto'}}  >

                <div className="bg-with-border white p-5" id={"stripe-pay"}>

                  {(trainingoffer?.pricings?.find((item: any) => item?.nbPeriod != null)) &&
                    <div className='d-flex flex-column mb-3'>
                      <h3 className="H3-Headline mb-3">{t('Modedepayement')}</h3>
                      <span style={{ marginBottom: 15 }} >Veuillez choisir le mode de paiement qui vous convient</span>
                      <div className="d-flex flex-row align-items-center">
                        <div>
                          {trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT") &&
                            <div className="d-flex flex-row me-4">
                              <div className="d-flex align-items-top me-4" style={{ marginRight: 43 }}>
                                <input className="input-style-radio" type="radio"
                                  name="paymentType-oneShot"
                                  id="paymentTypeOneShot"
                                  value="ONESHOT"
                                  checked={selectedOneShot}
                                  onChange={() => {
                                    setSelectedOneShot(true); setSelectedInstallment(null);
                                    setActualPricings(trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT"))
                                  }} />
                                <label htmlFor="paymentTypeOneShot"></label>
                                <span className="body-md ps-1" style={{ verticalAlign: "middle" }}>Paiement unique:<span className="form-title ps-1 ms-1">{trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT")?.price}€ </span> </span>
                              </div>

                              <span className="body-md" style={{ verticalAlign: "middle" }} >ou</span>
                            </div>
                          }
                        </div>
                        <div>

                          {trainingoffer?.pricings?.filter((item: any) => item?.period !== "ONESHOT")?.map((trainingPrice: any) => {
                            return (
                              <div key={trainingPrice.id} className='d-flex flex-colunm'>
                                <div className="d-flex align-items-top" style={{ marginRight: 43 }}>
                                  <input
                                    className="input-style-radio"
                                    type="radio"
                                    name="paymentType-multiple"
                                    id={`paymentTypeInstallment-${trainingPrice.id}`}
                                    value={trainingPrice.id}
                                    checked={selectedInstallment === trainingPrice.id}
                                    onChange={() => {
                                      setSelectedInstallment(trainingPrice.id);
                                      setSelectedOneShot(false);
                                      
                                      setActualPricings(trainingPrice)
                                    }}
                                  />
                                  <label htmlFor={`paymentTypeInstallment-${trainingPrice.id}`}></label>
                                  <span className="body-md ps-1 ">Payez en {trainingPrice?.nbPeriod} fois:<span className="form-title ps-1 ms-1">{trainingPrice?.price}€ x {trainingPrice?.nbPeriod} </span> </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  }

                  <Elements stripe={stripePromise}>
                    <CheckoutStripeComponent actualPricings={actualPricings} priceCoupon={priceCoupon} valide={valide} pricings={pricings} offerId={offerId} couponReference={couponReference} trainingoffer={trainingoffer} courseInfo={training} />
                  </Elements>

                </div>

              </div>
              {/* // : <div>
                //   <CheckoutPaymeeComponent amount={price} />
                // </div>} */}

              <div className="pr-2 " style={{ width: "20%" }} >

                <div className="product-detail-payment white" id={"stripe-pay"}>
                  <div className="mb-4" >
                    <img className="product-detail-payment-img" style={{ maxWidth: 332 }} src={training?.linkImg} />
                  </div>
                  <div className="d-flex flex-column mb-4">
                    <div className="d-flex flex-column mb-3">
                      <div className="H3-Headline cours-card vertical title">
                        {training?.title}
                      </div>
                      <div className="d-flex flex-row align-items-center pt-1">
                        <div className="d-flex align-items-center me-2">

                          {training?.type === "COURSE" &&
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                              <line x1="5.38086" y1="9.77637" x2="10.6177" y2="9.77637" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                              <line x1="10.6191" y1="6.22363" x2="5.3823" y2="6.22363" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>}

                          {training?.type === "PATH" &&
                          <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                       12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                            fill="#FA83EE" />
                        </svg>}

                          {training?.type === "SESSION" &&
                          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0)">
                            <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
                          </g>
                          <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
                          <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
                          <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
                          <defs>
                            <clipPath id="clip0">
                              <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
                            </clipPath>
                          </defs>
                        </svg>}

                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <span className="body-sm bold">
                            <span className="badge-text secondary me-1">
                              { training?.type==="COURSE"?t('courseBy'):(training?.type==="PATH"?t('pathBy'):t('SessionGivenBy'))}
                            </span>
                            {training?.professor?.firstName} {training?.professor?.lastName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row ">
                      <div className="d-flex mb-3 me-3">
                        <span className="material-icons-outlined icon-grey pe-1">
                          signal_cellular_alt
                        </span>
                        <span className="body-md bold black-800">
                          {t(levels.find(level => level.value === training?.level)?.label + '')}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="material-icons-outlined icon-grey pe-1">
                          timer
                        </span>
                        <span className="body-md bold details black-800">
                          {getDuration(fullDuration)}
                        </span>
                      </div>
                    </div>
                  </div>


                  <hr className="hr-payment" />

                  {!!actualPricings && actualPricings?.period === "ONESHOT" && <>


                    {
                      active && <button className="btn-text body-sm bold outlined" onClick={() => { setActive(!active) }}>Avez-vous un code promo / coupon ?</button>
                    }
                    {
                      !valide && !active && <div className="d-flex flex-column mb-1">
                        <span className="form-title">Code promo / coupon</span>
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex flex-column mb-1">

                          </div>
                          <input type="text" className={message == "" ? "form-control input text-default" : "form-control input text-default error"} style={{ width: 216, height: 48 }} placeholder="Entrez le code" onChange={(e) => setPromocode(e.target.value)
                          } />


                          <button type="button" className="btn-Primary large" onClick={() => { handleClickCoupon() }}>
                            Appliquer
                          </button>
                        </div>
                        {message != "" && <label className="error-input mt-1">{message}</label>}
                        {message == "" && retire && <label className='form-error mt-1 green-800'>{retireMsg}</label>}
                      </div>
                    }
                    {
                      valide && <div className='d-flex flex-row p-2 mt-3' style={{ border: "2px solid #9DDBA9", borderRadius: "4px" }}>
                        <span className='material-icons-outlined green-800 pe-2 pt-1'>local_offer</span>
                        <div className='me-auto body-md bold'>{promoCode}</div>
                        <button className="btn-text small">
                          <span className='d-flex justify-content-end body-md bold' onClick={() => retireCoupon()}
                          >
                            Retirer
                          </span>
                        </button>
                      </div>
                    }
                    <hr className="hr-payment " />
                    <div className="d-flex justify-content-between mb-1">
                      <span className="body-md  black-800">
                        Prix total HT
                      </span>
                      <span className="body-md bold black-800">
                        {price != undefined && (((price / 1.2) % 1 !== 0) ? (price / 1.2).toFixed(2): (price / 1.2).toFixed(0))}<span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                      </span>
                    </div>
                    {
                      valide && <div className="d-flex justify-content-between mb-1">
                        <span className="body-md  green-800">
                          Remise coupon {!!coupon && coupon?.promotion?.promoType == "DISCOUNT" ? coupon?.promotion?.promoValue + "%" : ""}
                        </span>
                        <span className="body-md bold green-800">
                          -{!!newPrice && newPrice?.toFixed(2)}<span className="H3-Headline green-800 ">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                        </span>
                      </div>
                    }
                    <div className="d-flex justify-content-between mb-3">
                      <span className="body-md  black-800">
                        TVA 20%
                      </span>
                      <span className="body-md bold black-800">
                        {price != undefined && Number(((price / 1.2) * 0.2) % 1 !==0) ? ((price / 1.2) * 0.2).toFixed(2) : ((price / 1.2) * 0.2).toFixed(0)}<span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                      </span>
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                      <span className="H3-Headline black-800">
                        {t('PRIX')}
                      </span>
                      <span className="H3-Headline black-800">
                        {(priceCoupon!=undefined) ? 
                          (priceCoupon % 1 !==0 ) ? priceCoupon.toFixed(2): priceCoupon.toFixed(0) 
                          : 
                          (price % 1 !== 0) ? price.toFixed(2) : price.toFixed(0)}
                      <span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                      </span>
                    </div>

                  </>}

                  {!!actualPricings && actualPricings?.period != "ONESHOT" && actualPricings?.nbPeriod == null &&
                    <>

                      <div className="d-flex justify-content-between mb-4">
                        <span className="H3-Headline black-800">
                          Montant TTC
                        </span>
                        <span className="H3-Headline black-800">
                          {!!priceCoupon ? 
                            (priceCoupon % 1 !== 0) ? priceCoupon.toFixed(2) : priceCoupon.toFixed(0)
                            :
                            (price % 1 !== 0) ? price.toFixed(2) : price.toFixed(0)}
                          
                          <span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}/</span>
                          <span className="body-sm neutral-3">{t(actualPricings?.period)}</span>
                        </span>
                      </div>
                    </>}

                  {!!actualPricings && actualPricings?.period != "ONESHOT" && actualPricings?.nbPeriod != null &&
                    <>
                      <div className="d-flex justify-content-between mb-1">
                        <span className="body-md  black-800">
                          Prix total HT
                        </span>
                        <span className="body-md bold black-800">
                          {price != undefined && ((price / 1.2) % 1 !==0 ) ? (price / 1.2).toFixed(2) : (price / 1.2).toFixed(0)}<span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span className="body-md  black-800">
                          TVA 20%
                        </span>
                        <span className="body-md bold black-800">
                          {price != undefined && Number(((price / 1.2) * 0.2) % 1 !== 0) ? ((price / 1.2) * 0.2).toFixed(2) : ((price / 1.2) * 0.2).toFixed(0)}<span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <span className="H3-Headline black-800">
                          Montant TTC
                        </span>
                        <span className="H3-Headline black-800">
                          {!!priceCoupon ? 
                            (priceCoupon % 1 !== 0 ) ? priceCoupon.toFixed(2) : priceCoupon.toFixed(0)
                            : 
                            (price % 1 !== 0 ) ? price.toFixed(2) : price.toFixed(0)}
                            <span className="H3-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}/</span>
                            <span className="body-sm neutral-3">{t(actualPricings?.period)}</span> 
                        </span>
                      </div>
                    </>}
                  { priceCoupon === 0 && <button type="button" className="btn-Primary large mt-4 w-100" onClick={()=>{handleContract("")}}>
                    Commencer le { training?.type==="COURSE"?"cours":(training?.type==="PATH"?"parcours":"session")}
                  </button>}
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="d-block d-lg-none container-fluid mt-4">
      <div className="border rounded d-flex " style={{width:"100%",height:"100%", position: "relative", overflowX:"hidden"}}>
        <div className='p-2' style={{width:"55%"}}>
          <div className="payment-title-mobile " style={{WebkitLineClamp: "3", overflow : "hidden", textOverflow: "ellipsis", display: "-webkit-box",WebkitBoxOrient: "vertical"}} >
            {training?.title}
          </div>
          <div className="d-flex flex-row flex-wrap mt-3">
              <span className="payment-subtitle-mobile">
                { training?.type==="COURSE"?t('courseBy'):(training?.type==="PATH"?t('pathBy'):t('SessionGivenBy'))} {training?.professor?.firstName} {training?.professor?.lastName}
              </span>
          </div>
          {props.courseInfo?.isDiplomat && <div className="payment-subtitle-mobile mt-3" >Avec diplôme</div>}
          <hr className="hr-payment " />
          {!!actualPricings && actualPricings?.period === "ONESHOT" && 
          <>
            
            <div className="d-flex justify-content-between mb-2">
              <span className="payment-price-description-mobile">
                Prix total HT
              </span>
              <span className="payment-price-mobile">
                {price !== undefined && ((price / 1.2) % 1 !== 0) ? (price / 1.2).toFixed(2) : (price / 1.2).toFixed(0)}
                <span className="payment-price-mobile">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
              </span>
            </div>

            <div className="d-flex justify-content-between mb-2">
              <span className="payment-price-description-mobile">
                TVA 20%
              </span>
              <span className="payment-price-mobile">
                {price != undefined && Number(((price / 1.2) * 0.2) % 1 !== 0 ) ? ((price / 1.2) * 0.2).toFixed(2) : ((price / 1.2) * 0.2).toFixed(0)}<span className="payment-price-mobile">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
              </span>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className="payment-montant-mobile">
              Montant TTC
              </span>
              <span className="payment-montant-mobile">
                {!!priceCoupon ? 
                (priceCoupon % 1 !== 0 ) ? priceCoupon.toFixed(2) : priceCoupon.toFixed(0)
                : 
                (price % 1 !== 0) ? price.toFixed(2) : price.toFixed(0)}
                <span className="H4-Subtitle neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
              </span>
            </div>

          </>}

          {!!actualPricings && actualPricings?.period != "ONESHOT" && actualPricings?.nbPeriod == null &&
            <>
              
              <div className="d-flex justify-content-between align-items-center">
                <span className="payment-montant-mobile">
                  Montant TTC
                </span>
                <span className="payment-montant-mobile">
                  {!!priceCoupon ? 
                  (priceCoupon % 1 !== 0 ) ? priceCoupon.toFixed(2) : priceCoupon.toFixed(0)
                  : 
                  (price % 1 !== 0 ) ? price.toFixed(2) : price.toFixed(0)}
                  <span className="H4-Subtitle neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}/</span>
                  <span className="body-sm neutral-3">{t(actualPricings?.period)}</span>
                </span>
              </div>
            </>}

          {!!actualPricings && actualPricings?.period != "ONESHOT" && actualPricings?.nbPeriod != null &&
            <>
              <div className="d-flex justify-content-between mb-2">
                <span className="payment-price-description-mobile">
                  Prix total HT
                </span>
                <span className="payment-price-mobile">
                  {price != undefined && ((price / 1.2) % 1 !== 0 ) ? (price / 1.2).toFixed(2) : (price / 1.2).toFixed(0)}<span className="body-sm bold black-800">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span className="payment-price-description-mobile">
                  TVA 20%
                </span>
                <span className="payment-price-mobile">
                  {price != undefined && Number(((price / 1.2) * 0.2) % 1 !== 0 ) ? ((price / 1.2) * 0.2).toFixed(2) : ((price / 1.2) * 0.2).toFixed(0)}<span className="body-sm bold black-800">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span>
                </span>
              </div>
              <div className="d-flex justify-content-between mb-1" >
                <span className="payment-montant-mobile">
                  Montant TTC
                </span>
                <span className="payment-montant-mobile">
                  {!!priceCoupon ? 
                  (priceCoupon % 1 !== 0) ? priceCoupon.toFixed(2) : priceCoupon.toFixed(0) 
                  : 
                  (price % 1 !== 0) ? price.toFixed(2) : price.toFixed(0)}<span className="H4-Headline neutral-3">{props?.paymentMethod?.currency === "TND" ? "TND" : "€"}/</span>
                  <span className="body-sm neutral-3">{t(actualPricings?.period)}</span>
                </span>
              </div>
            </>
          }

        </div>
        <div className="px-2 d-flex" style={{ width:"45%", height:"100%", position: "absolute",right:"0"}}>
          <img alt="" className="rounded align-self-center" style={{width:"100%", height:"65%", verticalAlign: "middle", display:"inline-block"}} src={training?.linkImg} />
        </div>
      </div>
      <div className="justify-content-center">

        <div style={{ width: '100%', height: 'auto' }}>
            {(trainingoffer?.pricings?.find((item: any) => item?.nbPeriod != null)) && !paymentChoice &&
              <div className='d-flex flex-column mb-3'>
                <h3 className="H3-Headline mb-3 mt-4">{t('Modedepayement')}</h3>
                <span style={{ marginBottom: 15 }} >Veuillez choisir le mode de paiement qui vous convient</span>
                <div className="align-items-center ">
                  <div>
                    {trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT") &&
                      <div className="d-flex flex-row me-4">
                        <div className="d-flex align-items-top me-4">
                          <input className="input-style-radio" type="radio"
                            name="paymentType"
                            id="paymentTypeOneShot"
                            value="ONESHOT"
                            checked={selectedOneShot}
                            onChange={() => {
                              setSelectedOneShot(true); setSelectedInstallment(null);
                              setActualPricings(trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT"))
                            }} />
                          <label htmlFor="paymentTypeOneShot"></label>
                          <span className="body-md ps-1" style={{ verticalAlign: "middle" }}>Paiement unique:<span className="form-title ps-1 ms-1">{trainingoffer?.pricings?.find((item: any) => item?.period === "ONESHOT")?.price}€ </span> </span>
                        </div>

                      </div>
                    }
                  </div>
                  <div>
                    
                    {trainingoffer?.pricings?.filter((item: any) => item?.period !== "ONESHOT")?.map((trainingPrice: any) => {
                      return (
                        <div key={trainingPrice.id} className='d-flex flex-colunm mt-4'>
                          <div className="d-flex align-items-top" style={{ marginRight: 43 }}>
                            <input
                              className="input-style-radio"
                              type="radio"
                              name="paymentType"
                              id={`paymentTypeInstallment-${trainingPrice.id}`}
                              value={trainingPrice.id}
                              checked={selectedInstallment === trainingPrice.id}
                              onChange={() => {
                                setSelectedOneShot(false);
                                setSelectedInstallment(trainingPrice.id);
                                setActualPricings(trainingPrice)
                              }}
                            />
                            <label htmlFor={`paymentTypeInstallment-${trainingPrice.id}`}></label>
                            <span className="body-md ps-1">Payez en {trainingPrice?.nbPeriod} fois:<span className="form-title ps-1 ms-1">{trainingPrice?.price}€ x {trainingPrice?.nbPeriod} </span> </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-block d-lg-none" style={{width:"100%", height:"148px"}} />
                  <div className="d-block d-lg-none d-flex justify-content-center align-items-center fixed-bottom mt-4" style={{width:"100%", height:"112px", backgroundColor:"white"}} >
                    <button type="button" style={{width:"50%", height:"48px",}} className="btn-Primary large " onClick={()=>{setPaymentChoice(true)}}>
                      Confirmer
                    </button>
                  </div>
                </div>
              </div>
            }
            {(!multiplePayment || paymentChoice) && <div className="row mt-4">
              <Elements stripe={stripePromise}>
                <CheckoutStripeComponent actualPricings={actualPricings} priceCoupon={priceCoupon} valide={valide} pricings={pricings} offerId={offerId} couponReference={couponReference} trainingoffer={trainingoffer} courseInfo={training} />
              </Elements>
            </div>}
        </div>
        {/* // : <div>
          //   <CheckoutPaymeeComponent amount={price} />
          // </div>} */}

      </div>
    </div>
  </>

  )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({

  connectedUser: state.authReducer.connectedUser,
  loggedIn: state.authReducer.loggedIn,
  courseInfo: state.courseReducer.courseInfo,
  applicationFile: state.courseReducer.applicationFile,
  follow: state.courseReducer.follow,
  registration: state.courseReducer.registration,
  schoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
  payement: state.courseReducer.payement,
  handleSubmit: ownProps?.handleSubmit,
  paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
  schoolPaymentCredentials: state.schoolInfoReducer.schoolInfo,
  paymee: state.schoolInfoReducer.paymentSchoolInfo,
  training: state.courseReducer.training,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
        openModal, refreshToken, updateShow,saveReturnPath
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TrainingPaymentComponent);