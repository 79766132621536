import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { ModalIDS } from '../modal-ids';
const AntSwitch = withStyles(() => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 3,
        color: 'white',
        '&$checked': {
            transform: 'translateX(18px)',
            color: '#ffffff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5A5A5A',
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        boxShadow: '0px 4px 4px rgba(34, 34, 34, 0.25)',
    },
    track: {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: '#EBECEE',
    },
    checked: {},
}))(Switch);

const ErrorInvitationModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();

    const handleCheckErrors = () =>{
        props.setModalErrorOpened(false)
        props.setModalListInviteOpened(true)
    }

    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.setModalErrorOpened(false)}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0 , overflow:'hidden'}}>
                <div className="body-modal-publish">
                    <div className="mx-5 px-5 center" style={{ margin: 'auto' }}>

                        <div className="d-flex flex-column align-item-center">

                            <div className='d-flex flex-row align-items-center mb-4'>

                                <span className="material-icons-outlined align-items-center me-2" style={{ fontSize: 35, color: "#E06A6A" }}>error</span>
                                <span className="H3-Headline align-items-center "> Erreur</span>
                            </div>


                        </div>
                        <div >
                            <span className="body-sm">{t('cannotImportListOfStudents')}</span>



                        </div>

                        <div className="mt-4 mb-4">
                            <button className="btn-text outlined" onClick={() => handleCheckErrors()}> Vérifiez les erreurs</button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingTop: 0, border: "none" }}>
                <div className=" d-flex mt-3 mb-5 mx-5 px-5">
                    <div className='col-md-12 text-center justify-content-center buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' , gap:"0px 12px"}}>
                        <button type="button" className="btn-Secondary large me-1" onClick={() => handleCheckErrors()} style={{ textTransform: 'uppercase' }} >Retour</button>
                        <button type="button" className="btn-Primary large " onClick={() => { props?.setModalErrorOpened(false) }} >Annuler l’importation</button>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    student: state.detailsProfileReducer.actualStudent,
    connectionUser: state.authReducer.connectedUser,
    invitationList: state.studentTraining.invitationList

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { closeModal, openModal },
        dispatch
    );
    
export default connect(mapStateToProps, mapDispatchToProps)(ErrorInvitationModalComponent);