import { IReduxGetProgressAction } from "../actions/progress-actions"

import { EReduxActionTypes } from "../actions/progress-actions";

export interface IProgressCourseGeneralDetails {
    type: string,
    title: string,
    progress: number,
    totalDuration: number,
    navBarSelectedTab: string
}

export interface IContentToDisplay {
    index: number,
    content: any,
}

export interface IContent {
    follows: any,
    training: any
}

export interface IReduxProgressState {
    progressCourseDetails: IProgressCourseGeneralDetails,
    studentProgress: IContent
}

const  initialState: IReduxProgressState = {
    progressCourseDetails: {
        type: "",
        title: "",
        progress: 0,
        totalDuration: 0,
        navBarSelectedTab: ""
    },
    studentProgress: {
        follows: undefined,
        training: undefined
    }
}

type TProgressReducerActions = IReduxGetProgressAction;

export default function (state: IReduxProgressState = initialState, action: TProgressReducerActions) {
    switch (action.type) {
        case EReduxActionTypes.GET_PROGRESS:
            return { ...state, studentProgress: action.data };
        default:
            return state;
    }
}
