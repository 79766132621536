import { ComponentProps, FC, useEffect, useState, useRef } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { getCourseStatus } from '../../global-state/actions/app-container-actions';
import { getActiveCourses, updateCoursesLists } from '../../global-state/actions/course-actions';
import { updateStatus } from '../../global-state/actions/course-actions';
import TableElement from '../../elements/table/normal-without-action-table-element';
const CourseListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [students, setStudents] = useState<any>()
    const [courses, setCourses] = useState<any>()
    const headers = [
        { title: t('courseTable'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Action'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    useEffect(() => {
        let filter = {
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL"
        }
        props?.getActiveCourses(filter)
        
    }, [])
    useEffect(() => {
        setCourses(props?.activeCourses?.filter((course: any) => course?.status == "PUBLISHED" || course.status=="DEACTIVATE"))
    }, [props?.activeCourses])
    useEffect(() => {
        let filter = {
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL"
        }

        const students = {
            headers: headers,

            body: courses?.map((student: any) => {

                return {
                    row: {

                        classNames: "table-white",
                        columns: [
                            { content: student.title, classNames: "", actionName: '' },
                            {
                                content: <>
                                   {(student.status == "PUBLISHED")&&
                                      <button type="button" className="btn-Primary medium danger" onClick={() => { props?.updateStatus("DEACTIVATE", student.id).then((resp:any)=>props?.getActiveCourses(filter)) }}>
                                      {t('deactivateCours')}
                                  </button>}
                                  {(student.status == "DEACTIVATE")&&
                                      <button type="button" className="btn-Primary medium " onClick={() => { props?.updateStatus("PUBLISHED", student.id).then((resp:any)=>props?.getActiveCourses(filter)) }}>
                                      {t('activate')}
                                  </button>}
                                 
                                </>, classNames: `text-end badge-text`, actionName: ''
                            },
                        ]
                    }
                }
            })
        }

        setStudents(students);
    }, [courses]);


    useEffect(() => {


        const student = props?.rule.filter((rule: any) => rule.key == 'nb_cours')[0]
        if (!!student && !! courses) {
            (Number(student.value) >= courses?.filter((course: any) => course?.status == "PUBLISHED")?.length) ? props?.getCourseStatus(true) : props?.getCourseStatus(false)
        }
    }, [props?.rule, courses])
    return (<>
        <div>
            {(students?.body?.length > 0) && <>
                <TableElement data={students} />


            </>
            }

        </div>
    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    subscription: state.schoolInfoReducer.subscription,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    activeCourses: state.courseReducer.activeCourses
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    updateCoursesLists,
    getActiveCourses,
    updateStatus, getCourseStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CourseListComponent);