import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import _, { debounce } from 'lodash';
import {openModal} from '../../global-state/actions/modal-actions';
import BeforeUploadElement from '../../elements/file-upload-card/before-upload-element';
import { createApplicationFile, clearApplicationFile, createFollow,setApplicationFile ,sendMyRequest} from '../../global-state/actions/course-actions';
import TextareaWithoutBorderElement from "../../elements/text-area/text-area-without-border-element";
import { useParams } from "react-router-dom";
import { getOffer } from "../../global-state/actions/app-container-actions";
interface Params {
    type: string;
    id: string;
}
const AdmissionFormForStudentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [answers, setAnswers] = useState<any>((props?.applicationFile && props?.applicationFile?.answers) ? props?.applicationFile?.answers : _.times(props?.registration?.questions?.length, _.constant({ 'id': null, 'index': props?.registration?.questions?.length + 1, 'answer': '' })))
    const [documents, setDocuments] = useState<any>((props?.applicationFile && (props?.applicationFile?.documents && props?.applicationFile?.documents.length > 0)) ? props?.applicationFile?.documents : _.times(props?.registration?.documents?.length, _.constant({ 'id': null, 'index': props?.registration?.documents?.length + 1, 'link': '', 'name': '' })))
    const {type} = useParams<Params>();
    const idTraining= useMemo(()=>{return (type === 'course')?props?.courseInfo?.id:props?.training?.id;},[])
    const [applicationfile, setApplicationfile] = useState<any>()
    const [follow, setFollow] = useState<any>()

    useEffect(() => {
        props?.setApplicationFile(props?.applicationFile)
        setAnswers((props?.applicationFile && props?.applicationFile?.answers) ? props?.applicationFile?.answers : _.times(props?.registration?.questions?.length, _.constant({ 'id': null, 'index': props?.registration?.questions?.length + 1, 'answer': '' })))
        setDocuments((props?.applicationFile && (props?.applicationFile?.documents && props?.applicationFile?.documents.length > 0)) ? props?.applicationFile?.documents : _.times(props?.registration?.documents?.length, _.constant({ 'id': null, 'index': props?.registration?.documents?.length + 1, 'link': '', 'name': '' })))
    }, [props?.applicationFile?.answers])

    useEffect(() => {
        setApplicationfile(props?.applicationFile)
    }, [props?.applicationFile])

    useEffect(()=>{
       if(props?.follow!==undefined) {
        let actualFollow:any
        let followList=[...props?.follow];
        switch(type){
            case 'course':
                actualFollow= followList.find((obj:any)=>{ return obj.pathParentId===null && obj.courseId===Number(idTraining) && obj.pathChildId===null && obj.sessionId===null})
                setFollow(actualFollow)
                break;
            case 'path':
                actualFollow= followList.find((obj:any)=>{ return obj.pathParentId===Number(idTraining) && obj.courseId===null && obj.pathChildId===null && obj.sessionId===null})
                setFollow(actualFollow)
                break;
            case 'session':
                actualFollow= followList.find((obj:any)=>{ return obj.pathParentId===null && obj.courseId===null && obj.pathChildId===null && obj.sessionId===Number(idTraining)})
                setFollow(actualFollow)
                break;
        }}
        props?.getOffer(props?.school?.id)
    }, [])

    const addAnswer = async (index: number, value: string)=>{
        let allanswers = [...answers];
        let studentAnswer = {
            id: allanswers[index]?.id,
            index: allanswers[index]?.index,
            answer: value
        }
        allanswers[index] = studentAnswer;
        allanswers = allanswers.map((obj: any, x: number) => {
            obj.index = x + 1;
            return obj;
        })
        await setAnswers(allanswers);
        await debouncecallApiCreateApplicationFile({answers: allanswers, documents: documents});

    }

    const addFile= async (file: any, index: number)=>{
        let alldocuments = documents;
        let newFile = {
            id: documents[index].id,
            index: (documents[index].index === 100) ? index + 1 :documents[index].index,
            link: file[0],
            name: file[0].name,
        }
        alldocuments[index] = newFile;
        alldocuments = alldocuments.map((obj: any, x: number) => {
            obj.index = x + 1;
            return obj;
        })
        await setDocuments(alldocuments)
        await debouncecallApiCreateApplicationFile({answers: answers, documents: alldocuments});
    }

    const deleteFile = async (index: number)=>{
        let newFile = {
            id: props?.applicationFile?.documents[index]?.id,
            index: props?.applicationFile?.documents[index]?.index,
            link: "",
            name: "",
        }
        let alldocuments = documents
        alldocuments[index] = newFile;
        await setDocuments(alldocuments)
        await debouncecallApiCreateApplicationFile({answers: answers, documents: alldocuments});
    }
   
    const returnTypeFn= (typeInput:string)=>{
        switch(typeInput){
            case 'course':
                return 'COURSE';
            case 'path':
                return 'PATH';
            case 'session': 
                return 'SESSION';
        }
    }

    const callApiCreateApplicationFile = async (application: any) => {
        if ( !(applicationfile && applicationfile?.id !== null)) {

            let contractToCreate={
                contractIdentifier: {
                    contractId: null,
                    contractReference: null
                },
                contractDetails: {
                    offerId: props?.coursePrice?.offer_id,
                    contractDuration: {
                        startAt: new Date(Date.now()),
                        endAt: null
                    },
                    pricingId: props?.offer?.idPricings[0],
                    paymentMethod: "CARD",
                    paymentEngine: "STRIPE"
                },
                contractRoles: [
                    {
                        personId: props?.connectedUser?.idPerson,
                        roleType: "STUDENT"
                    }
                ],
                status: [
                    {
                        statusType: "ONGOING"
                    }
                ]
            }
            let newFollow = {
                    studentId: props?.connectedUser?.idPerson,
                    trainingId: (type === 'course')?props?.courseInfo?.id: props?.training?.id,
                    trainingType: returnTypeFn(type),
                    entityId: (type === 'course')?props?.courseInfo?.idSchool:props?.training?.idSchool,
                    entityType: "SCHOOL",
                    statusList: [{
                        status: "DRAFT"
                    }]
                
            }
            await props?.createFollow(newFollow, contractToCreate);
        }
      
        let object : any= {
            follow: follow,
            id:(applicationfile)?applicationfile?.id : null,
            answers: application.answers,
            documents: application.documents,
        };
        object['idStudent'] =props?.connectedUser?.idPerson;
        object['registrationForm'] = props?.registration;
        object['id'] = (applicationfile)?applicationfile?.id : null;
       
        await props?.createApplicationFile(object, props?.connectedUser?.idSchool)

    }
    
    const debouncecallApiCreateApplicationFile = useCallback( debounce(callApiCreateApplicationFile, 1000), [applicationfile]);
    
    return (
        <>
               <div className='row justify-content-center' style={{ margin: 0, padding: 0 }}>
                    <div className="col-md-7">
                        <div className="row">
                           {props?.registration?.questions.length!==0  && <div className=" mt-5 mb-5">
                                <h3 className=" mt-1 pb-2 H3-Headline">{t('titreRegistration_1')}</h3>
                                {props?.registration?.questions?.map((question: any, index: any) => (
                                    <div  key={index}>
                                        <div className="form-group mt-5 mb-2 pb-1">
                                            <label className="H4-Subtitle" style={{ paddingLeft: '0px' }}>{question.question}</label>
                                        </div>
                                        
                                            <TextareaWithoutBorderElement id={index}  index={index} value={answers[index]?.answer} onChange={(value: string) => addAnswer(index, value)} placeholder={t('placeholderTextArea')}/>
                                        
                                    </div>
                                ))}
                            </div>}
                           {props?.registration?.documents.length!==0 && props?.registration?.questions.length!==0 &&<div className="mb-5">
                                <hr  className="mt-2" style={{backgroundColor: "#DADBDF", opacity:1}}/>
                            </div>}
                           { props?.registration?.documents.length!==0&& <div className="col-md-12  mb-5">
                                <h1 className="mb-5 mt-1 pb-2 H3-Headline">{t('titreRegistration_2')}</h1>
                                {props?.registration?.documents?.map((document: any, index: any) => (
                                    <div className="  mb-3 pb-4 " key={index}>
                                        <div className="form-group  mb-3">
                                            <label className="H4-Subtitle">{document.document}</label>
                                        </div>
                                        <div className="form-group ">
                                            {(documents[index]?.name === '' && documents[index]?.link === '') ?
                                                <BeforeUploadElement t={t} addFile={(file: any) => { addFile(file, index) }} />
                                                : <><div className="bg-with-border white p-4">
                                                <div className="d-flex align-items-center justify-content-between  m-2">
                                                    <div className="d-flex align-items-center me-2">
                                                        <div className="me-3 d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                                                            <span className="material-icons-outlined black-800"> cloud_upload </span>
                                                        </div>
                                                        <div className="d-flex flex-column black-800">
                                                            <span className="form-edit mb-1">{documents[index]?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <button type="button" className="btn-QuickActions me-3">
                                                            <span className="material-icons-outlined" onClick={(e) => { deleteFile(index) }}>delete</span>
                                                        </button>
                                                        {documents[index]?.name?.includes('.pdf') && <button onClick={() => setShow(!show)} type="button" className="btn-QuickActions">
                                                            <span className="material-icons-outlined">{show?'visibility_off':'remove_red_eye'}</span>
                                                        </button>}
                                                    </div>
                                                </div>
                                            </div>
                                            {show && <div style={{ height: 900 }}> <iframe src={`${documents[index].link}`} frameBorder="0" scrolling="auto" height="100%" width="100%" ></iframe> </div> }
                                            </>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>}

                        </div>
                    </div>
                </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.courseReducer.courseInfo,
    training: state.courseReducer.training,
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    history: ownProps?.history,
    coursePrice: state.courseReducer.priceCourse,
    school: state.schoolInfoReducer.schoolInfo,
    offer: state.schoolInfoReducer.offer

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    sendMyRequest, openModal,clearApplicationFile,createApplicationFile,createFollow, setApplicationFile, getOffer
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionFormForStudentComponent);