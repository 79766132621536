import React, { FC } from "react";
import BredCrumbSondage from "./BredCrumbSondage";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { addServeys } from '../../global-state/actions/sondage-actions'
import HeaderPreviewComponent from "../../components/sondage/header-preview-component";
import TitleSondageComponent from "../../components/sondage/title-sondage-component";
import CardServeyComponent from "../../components/sondage/card-servey-component";
import BtnChoiceSondageComponent from "../../components/sondage/btn-choice-sondage-component";

const PreviewSondage: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ schoolInfo, addServeys, sondageReducer }: any) => {

  const addSondage = (servey: any) => {
    addServeys(servey, schoolInfo.id)
  };

  const handleShowCardQuestion = (id: number) => {

  };

  const handleChangeResponse = (idChoice: number, msg: any, idResp: any) => {

  }

  return (
    <div className="container-create-sondage">
      <BredCrumbSondage title={"Nouveau sondage"} />
      <main className="main-create-sondage">
        <HeaderPreviewComponent />
        <TitleSondageComponent />
        {sondageReducer?.questionByQnaList?.length > 0 &&
          sondageReducer?.questionByQnaList?.map((questionByExam: any, index: any) => (
            <CardServeyComponent
              questionByExam={questionByExam}
              handleShowCardQuestion={handleShowCardQuestion}
              handleChangeResponse={handleChangeResponse}
              index={index}
              />
          ))}

        <BtnChoiceSondageComponent addSondage={addSondage} questionByQnaList={sondageReducer?.questionByQnaList} />
      </main>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  sondageReducer: state.examReducer.examToPass
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    addServeys
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewSondage);
