import { FC, useEffect, useState } from "react";

interface LigneProps {
    ajouterLigne: any;
    supprimerLigne: any;
    showAddButton: any;
    item: any,
    changePricingFac: any,
    index: any
}
const TarificationPeriod: FC<LigneProps> = ({ ajouterLigne, supprimerLigne, showAddButton, item, changePricingFac, index }) => {
    const [inputPrice, setInputPrice] = useState(item?.price);
    const [inputPeriod, setInputPeriod] = useState(item?.nbPeriod)
    const [priceClass, setPriceClass] = useState<any>('form-control input text-default')
    const [priceEror, setPriceError] = useState<any>('')
    const [periodClass, setPeriodClass] = useState<any>('form-control input text-default')
    useEffect(() => {
        if (inputPrice == '0') {
            setPriceClass("form-control input text-default error")
            setPriceError('Mensualité erroné')
        } else {
            setPriceClass("form-control input text-default")
            setPriceError('')
        }
    }, [inputPrice])

    useEffect(() => {
        if (inputPeriod == '0') {
            setPeriodClass("form-control input text-default error")
        } else {
            setPeriodClass("form-control input text-default")
        }
    }, [inputPeriod])
    const handleKeyPress = (event: any) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };
    return (
        <div className="d-flex align-items-top flex-row" style={{ marginLeft: "30px", marginTop: "20px" }}>
            <div className="d-flex align-items-top flex-column" style={{ marginRight: "30px" }}>
                <label className="form-default black-800 mb-1">Mensualité</label>
                <div className="input-form-control">
                    <input type="number" className={priceClass} placeholder=""
                        style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                            appearance: "none",
                            margin: 0
                        }}
                        value={inputPrice}
                        //onKeyPress={handleKeyPress}
                        onChange={(e) => { changePricingFac(index, e.target.value, 0); setInputPrice(e.target.value) }}
                        min="1"
                    />
                    <span style={{fontWeight:500}}>
                        TTC
                    </span>
                </div>
                <span className="error-input2">
                    {priceEror}
                </span>
            </div>
            <div className="d-flex align-items-top flex-column" style={{ marginLeft: "45px" }}>
                <label className="form-default black-800 mb-1">Nombre d’échéance</label>
                <div className="input-form-control">
                    <input type="tel" className={periodClass} placeholder=""
                        style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                            appearance: "none",
                            margin: 0,
                            padding: "12px 12px",
                            height: "50px!Important",
                        }}
                        min="0"
                        step="1"
                        value={inputPeriod}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => { changePricingFac(index, 0, e.target.value); setInputPeriod(e.target.value) }}
                    />
                    <span style={{fontWeight:500}} >
                        Mois
                    </span>
                </div>
            </div>
            {showAddButton &&
                <><div className="d-flex align-items-top flex-column" style={{ marginLeft: "44px", marginTop: "40px" }}>
                    <button type="button" className="btn-QuickActions" onClick={ajouterLigne}>
                        <span className="material-icons-outlined">add</span>
                    </button>
                </div><div className="d-flex align-items-top flex-column" style={{ marginLeft: "44px", marginTop: "40px" }}>
                        <button type="button" className="btn-QuickActions" onClick={supprimerLigne}>
                            <span className="material-icons-outlined">delete</span>
                        </button>
                    </div></>}

        </div>
    );
}

export default TarificationPeriod;
