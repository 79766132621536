import { fetchData } from "../base/functions/Functions";

const BASE_URL = process.env.REACT_APP_BASE_URL22;

const getCoupons = async (entityId:any, entityType:any) => {
    try {
        const url = `${BASE_URL}coupons/${entityId}/${entityType}/client`;
        return await fetchData("GET", url, {});
    } catch (error) {
        console.error("Error fetching coupons:", error);
        throw error; 
    }
};

const createCoupon = async (couponBodyObject:any) => {
    try {
        const url = `${BASE_URL}coupons/create`;
        return await fetchData("POST", url, couponBodyObject);
    } catch (error) {
        console.error("Error creating coupon:", error);
        throw error; 
    }
};

const getCouponsByProductAndPromoCode = async (product:any, promoCode:any) => {
    try {
        const url = `${BASE_URL}coupons/couponsSearch`;
        const couponObject = { product, promoCode };
        return await fetchData("POST", url, couponObject);
    } catch (error) {
        console.error("Error fetching coupons by product and promo code:", error);
        throw error; 
    }
};

export { createCoupon, getCouponsByProductAndPromoCode, getCoupons };
