import { FC } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { handleChangeQuestion } from "../../global-state/actions/sondage-actions";
import QuestionInputComponent from "./question-input-component";

const ContentChoiceCardComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ questionByExam, handleChangeResponse, handleChangeQuestion, index }: any) => {

  const displayType = (type: any) => {
    if (type === 'QCU') return 'Case à cocher';
    if (type === 'QCM') return 'Choix multiple';
    if (type === 'OQ') return 'Question ouverte';
  }

  return (
    <div className="content-card-choice-sondage mb-4">
      {questionByExam?.question?.questionChoice?.map((choiceQuestion: any, indexResponse: any) => (
        <QuestionInputComponent
          handleChangeResponse={handleChangeResponse}
          choiceQuestion={choiceQuestion}
          questionByExam={questionByExam}
          index={index}
          indexResponse={indexResponse}
          lengthOfChoices={questionByExam?.question?.questionChoice.length}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  sondageReducer: state.examReducer.examToPass
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    handleChangeQuestion
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentChoiceCardComponent);
