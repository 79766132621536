import { returnStartPathUrl } from "../helpers/domainCheck";

interface RouteDTO {
    path: string,
    label: string;
    class: string;
}


export const routesProfessor: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/activity`, label: "Activite", class: "nav-link" },
    { path: `${returnStartPathUrl()}/courses/my-courses`, label: "TitleFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/students/all`, label: "Etudiants", class: "nav-link" },
    { path: `${returnStartPathUrl()}/questions/all`, label: "Questions", class: "nav-link" },
    { path: `${returnStartPathUrl()}/examsDetails/details`, label: "Examens", class: "nav-link" },
    { path: `${returnStartPathUrl()}/webinars`, label: "Webinar1", class: "nav-link" },
]
export const routesProfessorWithoutWebinar: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/activity`, label: "Activite", class: "nav-link" },
    { path: `${returnStartPathUrl()}/courses/my-courses`, label: "TitleFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/students/all`, label: "Etudiants", class: "nav-link" },
    { path: `${returnStartPathUrl()}/questions/all`, label: "Questions", class: "nav-link" },
    { path: `${returnStartPathUrl()}/examsDetails/details`, label: "Examens", class: "nav-link" },
]
export const routesDirector: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/activity`, label: "Activite", class: "nav-link" },
    { path: `${returnStartPathUrl()}/all-courses`, label: "TitleFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/professors/all`, label: "team", class: "nav-link" },
    { path: `${returnStartPathUrl()}/students/all`, label: "Etudiants", class: "nav-link" },
    { path: `${returnStartPathUrl()}/webinars`, label: "Webinar1", class: "nav-link" },
    { path: `${returnStartPathUrl()}/finance/all`, label: "Finance", class: "nav-link" },
    { path: `${returnStartPathUrl()}/coupon`, label: 'Coupons', class: 'nav-link' },

]
export const routesDirectorWithoutWebinar: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/activity`, label: "Activite", class: "nav-link" },
    { path: `${returnStartPathUrl()}/all-courses`, label: "TitleFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/professors/all`, label: "team", class: "nav-link" },
    { path: `${returnStartPathUrl()}/students/all`, label: "Etudiants", class: "nav-link" },
    { path: `${returnStartPathUrl()}/webinars`, label: "Webinar1", class: "nav-link" },
    { path: `${returnStartPathUrl()}/finance/all`, label: "Finance", class: "nav-link" },
    { path: `${returnStartPathUrl()}/coupon`, label: 'Coupons', class: 'nav-link' },
]
export const routesStudent: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/courses/my-courses`, label: "myFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/catalog`, label: "Catalogue", class: "nav-link" },
    { path: `${returnStartPathUrl()}/webinars`, label: "Webinar1", class: "nav-link" },

]
export const routesStudentWithoutWebinar: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/courses/my-courses`, label: "myFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/catalog`, label: "Catalogue", class: "nav-link" },
]
export const routesVisitor: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/school`, label: "lecole", class: "nav-link" },
    { path: `${returnStartPathUrl()}/catalog`, label: "Catalogue", class: "nav-link" },
]

export const routesSchoolAssistant: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/activity`, label: "Activite", class: "nav-link" },
    { path: `${returnStartPathUrl()}/all-courses/assistant`, label: "TitleFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/examsDetails/details`, label: "Examens", class: "nav-link" },
    { path: `${returnStartPathUrl()}/students/all`, label: "Etudiants", class: "nav-link" },
    { path: `${returnStartPathUrl()}/webinars`, label: "Webinar1", class: "nav-link" },
    { path: `${returnStartPathUrl()}/coupon`, label: 'Coupons', class: 'nav-link' },
]
export const routesSchoolAssistantWithoutWebinar: RouteDTO[] = [
    { path: `${returnStartPathUrl()}/activity`, label: "Activite", class: "nav-link" },
    { path: `${returnStartPathUrl()}/all-courses/assistant`, label: "TitleFormations", class: "nav-link" },
    { path: `${returnStartPathUrl()}/examsDetails/details`, label: "Examens", class: "nav-link" },
    { path: `${returnStartPathUrl()}/students/all`, label: "Etudiants", class: "nav-link" },
    { path: `${returnStartPathUrl()}/coupon`, label: 'Coupons', class: 'nav-link' },
]