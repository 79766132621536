import { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';


const FilterAllCoursesLoadingComponent: FC = () => {

    return (
        <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-between flex-wrap" >
                        <Skeleton className="skeleton square" animation="wave" variant="rect" width={225} height={48} />
                        <div className="d-flex align-items-center flex-wrap">
                                <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={225} height={48} />
                                <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={225} height={48} />
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default FilterAllCoursesLoadingComponent;
