import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import PaymentStripeComponent from './payment-stripe-component';
import { useTranslation } from 'react-i18next';
import { getContract } from '../../global-state/actions/app-container-actions';
import { getOffersApi } from '../../services/finance.service';


const AntSwitch = withStyles(() => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 3,
        color: 'white',
        '&$checked': {
            transform: 'translateX(18px)',
            color: '#ffffff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5A5A5A',
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        boxShadow: '0px 4px 4px rgba(34, 34, 34, 0.25)',
    },
    track: {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: '#EBECEE',
    },
    checked: {},
}))(Switch);



const PaymentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [offers, setOffers] = useState<any>();

    const getOffers = async () =>{
        const result = await getOffersApi()
        setOffers(result.filter((test: any) => test?.pricings[0]?.price))
    }

    useEffect(() => {
        getOffers()
        props?.getContract(props?.schoolInfo?.id, "SCHOOL")

    }, [])

    return (<>
        <div className="container-fluid mb-5">
            <div className="row justify-content-center">
                <div className=" text-center " style={{ backgroundColor: '#FFFFFF',paddingTop: 64 }}>
                    <div style={{ maxWidth: '1200px', marginLeft:"120px" }} className='d-flex flex-row justify-content-start  py-2 my-4'>
                        <span className="H2-Headline" style={{ display: 'inline-block' }}>{t('subscriptionPayments')}</span>

                    </div>
                </div>
                     <PaymentStripeComponent />
               
            </div>
        </div>
    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    subscription: state.schoolInfoReducer.subscription,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getContract
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);