import { Fragment, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import i18n from "../../translations/i18n";


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    border: '1px solid transparent',
    fontSize: 16,
    height: 22,
    padding: '10px 26px 10px 12px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0.2),
    width: '100%',
  },
}));

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char: string) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const countries = [
  { code: 'FR', label: 'Français', value: 'fr' },
  { code: 'US', label: 'English', value: 'en' },
  { code: 'ES', label: 'Español', value: 'es' },
  { code: 'IT', label: 'Italiano', value: 'it' },
  { code: 'DE', label: 'Deutsch', value: 'de' },
  { code: 'NL', label: 'Nederlands', value: 'nl' },
  { code: 'SE', label: 'Svenska', value: 'sv' },
  { code: 'TN', label: 'Arabe', value: 'ar' },
]

export default function SelectWithIcon() {

  const classes = useStyles();
  const [country, setCountry] = useState('fr');
  useEffect(() => {
    const language: string = localStorage.getItem('language') || '';
    if(language === '') {
      localStorage.setItem('language', 'fr');
      setCountry('fr');
    } else {
      setCountry(language);
    }
  }, []);
  const handleChange = (value: any) => {
    setCountry(value.target.value);
    localStorage.setItem('language', value.target.value);
    i18n.changeLanguage(value.target.value);
  };

  return (
    <div>
      <FormControl className={classes.margin}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={country}
          onChange={(e) => handleChange(e)}
          input={<BootstrapInput />}
        >
          {countries.map((option: any, i: number) => {
            return <MenuItem key={i} value={option.value}>
              <Fragment>
                <span className="me-3">{countryToFlag(option.code)}</span>
                {option.label}
              </Fragment>
            </MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  );
}
