import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { clearTeam, clearTeamList, getAssistant, getProfessor, getTeam, getTeamNumber, searchProf } from '../../global-state/actions/professor-actions';
import { openModal } from '../../global-state/actions/modal-actions';
import { getCourse } from '../../global-state/actions/course-actions';
import { getOffer, getSubscriptions } from '../../global-state/actions/app-container-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import TableProfessorsListComponent from '../../components/tables/table-professors-list-component';
import TableProfessorsListLoadingComponent from '../../components/tables/table-professors-list-loading-component';
import { ModalIDS } from '../../components/modals/modal-ids';
import TableAssistantListComponent from '../../components/tables/table-assistant-list-component';
import TableAllTeamListComponent from '../../components/tables/table-all-team-component';
import { getCountsApi, getLimitationsApi } from '../../services/finance.service';


let outsideAtBottom = false;
const ProfessorsListPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [role, setRole] = useState(props?.connectedUser?.role);
    const [actualTab, setActualTab] = useState<string>("all");
    const [loadingAssistant, setLoadingAssistant] = useState(true);
    const [listOfAssistant, setListOfAssistant] = useState(props?.assistants);
    const [atBottom, setAtBottom] = useState(false);
    const [teamList, setTeamList] = useState(props?.teamList)
    const [teamListLoading, setTeamListLoading] = useState(true)
    const [profiles, setProfiles] = useState<any>()

    outsideAtBottom = atBottom;

    const getCounts = async (id: number) =>{
        const result = await getCountsApi(id)
        setProfiles(result)
    }

    const getProfData = async (profPage: number) => {
        if (props.profs !==undefined && (profPage * 12) < props?.profs?.length) {
            setLoader(true);
            let pageNumber = profPage + 1;
            props?.getTeam(props?.director.idSchool, "PROF", pageNumber, '').then(()=>{
                setLoader(false);
            })
        }
    }

    const getAssistantData = async (assistantNumber: number) => {
        if ((assistantNumber * 12) < props?.assistants?.length) {
            setLoader(true);
            let pageNumber = assistantNumber + 1;
            props.getTeam(props.director.idSchool, "ADMINSTRATOR", pageNumber, '').then(()=>{
                setLoader(false);
            })
        }

    }

    const getTeamData = async (teamNumber: number) => {
        if ((teamNumber * 12) < props?.teamList?.length) {
            setLoader(true);
            let pageNumber = teamNumber + 1;
            props?.getTeam(props?.director.idSchool, "ALL", pageNumber, '').then(()=>{
                setLoader(false);
            })
        }
    }

    useEffect(() => {

        if (props.profs !==undefined && atBottom && (actualTab === "prof") && (props?.sizeProfs  > props?.profs?.length)) {
            getProfData(props?.profPage)
        } else if (atBottom && (actualTab === "ADMINSTRATOR") && (props?.sizeAssistants > props?.assistants?.length)) {
            getAssistantData(props?.assistantPage);
        } else if (atBottom && (actualTab === "all") && (props?.teamSize > props?.teamList?.length)) {
            getTeamData(props?.teamPage)
        }

    }, [atBottom])


    useEffect(() => {
        props?.getOffer(props?.subscription?.idOffer)
        getCounts(props?.schoolInfo?.id)
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const handleScroll = (event: any) => {
        if (!(atBottom) && isBottom()) {
            setAtBottom(true);
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }
    const isBottom = () => {
        return ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
    }

    const getInitialData = async () => {
        setActualTab("all")

        await props?.getTeamNumber(props?.director?.idSchool);

        setRole(props?.connectedUser?.role)

        await props.getTeam(props.director.idSchool, "PROF", 0, 'firstLoad')


        await props.getTeam(props.director.idSchool, "ADMINSTRATOR", 0, 'firstLoad')


        await props.getTeam(props.director.idSchool, "ALL", 0, 'firstLoad')

    }


    useEffect(() => {

        getInitialData();
    }, [])


    useEffect(() => {
        setRole(props?.connectedUser?.role)
    }, [props?.connectedUser]);

    useEffect(() => {
        props?.getSubscriptions(props?.schoolInfo?.id)
    }, [props?.schoolInfo])



    useEffect(() => {
        setLoading((props.profs !==undefined && props?.profs < 0))
    }, [props?.profs]);

    useEffect(() => {
        setLoadingAssistant(props?.loadingAssistant)
    }, [props?.loadingAssistant])

    useEffect(() => {
        setListOfAssistant(props?.assistants)
    }, [props?.assistants]) 

    useEffect(() => {
        setTeamListLoading(props?.teamListLoading)
    }, [props?.teamListLoading])

    useEffect(() => {
        setTeamList(props?.teamList)
    }, [props?.teamList])


    const inviteTeamMember = async () => {
        if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
            await getLimitationsApi(props?.schoolInfo?.id, props?.offer?.id).then(async (resp: any) => {


                await props?.openModal({ idModal: ModalIDS.INVITE_TEAM_MEMBER, profiles: resp.data, size: "md" });
            })
        }
    }

    const inviteMember = () => {
        return (
            <div className='d-flex  flex-wrap '>
                {role === "role_director" && <div style={{ marginLeft: "5px" }}>
                    <button type="button" className="btn-Primary large icon-left `" onClick={() => inviteTeamMember()}  >
                        <div className='d-flex align-items-center'>
                            <span className="material-icons" style={{ marginRight: '8px' }}>add</span>{t('membreInvitation')}
                            <div className="m-auto ps-2 ">{t('question').toUpperCase}</div>
                        </div>
                    </button>
                </div>}
            </div>
        )
    }

    const handleDisplayTab: {
        [key: string]: () => any;
    } = {
        all: () => teamListLoading ? <TableProfessorsListLoadingComponent /> : <TableAllTeamListComponent inviteMember={inviteMember} actualTab={actualTab} />,
        prof: () => loading ? <TableProfessorsListLoadingComponent /> : <TableProfessorsListComponent inviteMember={inviteMember} actualTab={actualTab} />,
        ADMINSTRATOR: () => loadingAssistant ? <TableProfessorsListLoadingComponent /> : <TableAssistantListComponent inviteMember={inviteMember} actualTab={actualTab} />
    }

    return (
        <div className="container-fluid">
            <span id='step-team-page_director' style={{ position: 'absolute' }}></span>
            <div className="row justify-content-center">
                <div className="col-md-12" >
                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="d-flex align-items-center justify-content-between flex-wrap pt-5 mx-auto" style={{ maxWidth: '1200px' }}>
                            {<>
                                <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                    {t('team')}
                                    <span className="ms-3" style={{ color: "#272727" }}>
                                        {props?.teamSize}
                                    </span>
                                </h1>
                                {props?.teamSize > 0 && inviteMember()}
                            </>
                            }
                        </div>
                        <div>
                            {
                                <div className="d-flex  px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}>
                                    <button className={`btn-Tabs ${(actualTab === 'all') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => setActualTab("all")} >
                                        {t('allTeam')} ({(props?.teamSize > 0 && props?.teamSize < 10) ? "0" + props?.teamSize : props?.teamSize})
                                    </button>
                                    <button className={`btn-Tabs ${(actualTab === 'prof') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => setActualTab("prof")} >
                                        {t('Professeurs')} ({(props?.sizeProfs > 0 && props?.sizeProfs < 10) ? "0" + props?.sizeProfs : props?.sizeProfs})
                                        {
                                            (profiles?.filter((profile: any) => profile.key = "nb_prof")[0]?.valueContract > 0 && profiles?.filter((profile: any) => profile.key = "nb_prof")[0]?.valueSchool >= profiles?.filter((profile: any) => profile.key = "nb_prof")[0]?.valueContract) &&
                                            <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                                    </button>
                                    <button className={`btn-Tabs ${(actualTab === 'ADMINSTRATOR') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => setActualTab("ADMINSTRATOR")}  >
                                        {t('schoolAssistant1')} ({(props?.sizeAssistants > 0 && props?.sizeAssistants < 10) ? "0" + props?.sizeAssistants : props?.sizeAssistants})
                                        {
                                            (profiles?.filter((profile: any) => profile.key = "nb_administrateur")[0]?.valueContract > 0 && profiles?.filter((profile: any) => profile.key = "nb_administrateur")[0]?.valueSchool >= profiles?.filter((profile: any) => profile.key = "nb_cours")[0]?.valueContract) &&
                                            <span className="material-icons" style={{ color: "#F5B95F" }}>upgrade</span>}
                                    </button>

                                </ div>
                            }
                        </div>
                    </div>

                    {handleDisplayTab[actualTab]()}
                    {loader===true && <div className="d-flex w-100 align-items-center justify-content-center">
                        <div className="m-3">
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div>
                    </div>}
                </div >
            </div >
        </div >
    );
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    profs: state.profReducer.profList,
    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    loadingAllProfessors: state.profReducer.loadingAllProfessors,
    connectedUser: state.authReducer.connectedUser,
    loadingAssistant: state.profReducer.loadingAssistant,
    assistants: state.profReducer.assistants,
    sizeProfs: state.profReducer.sizeProfs,
    sizeAssistants: state.profReducer.sizeAssistants,
    teamSize: state.profReducer.teamSize,
    listOfAssistant: state.profReducer.assistants,
    profPage: state.profReducer.profPage,
    assistantPage: state.profReducer.assistantPage,
    teamList: state.profReducer.teamList,
    teamPage: state.profReducer.teamPage,
    teamListLoading: state.profReducer.loadingTeam,
    offer: state.schoolInfoReducer.offer,
    subscription: state.schoolInfoReducer.subscription,
    
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getProfessor,
            searchProf,
            getCourse,
            openModal,
            getSubscriptions, getAssistant, getTeamNumber, clearTeam, getTeam, clearTeamList,
            getOffer
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfessorsListPage);
