import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { redirect, clearHistoryArray } from "../../global-state/actions/breadcrumb-actions";
import { getCourse, getCourseTests, getOnlineCoursesForDirector, getRegistrationForm } from "../../global-state/actions/course-actions";
import { getTrainingById } from "../../global-state/actions/training-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import DragableTableWithExpandElement from "../../elements/table/dragable-table-element";
import FilterAllCoursesComponent from "../filters/filter-all-courses-component";
import Axios from '../../helpers/interceptors';
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import DefaultTitle from '../../helpers/default-title'
import { returnStartPathUrl } from "../../helpers/domainCheck";
import TableWithExpandElement from "../../elements/table/table-with-expand-element";
const TableOnlineComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [atBottom, setAtBottom] = useState<boolean>(false);
    const status = [
        { label: t('Avalider'), value: "Pending", color: "warning" },
        { label: t('Enligne'), value: "PUBLISHED", color: "primary" },
        { label: t('Archive'), value: "Archive", color: "secondary" },
        { label: t('Refuse'), value: "Refused", color: "danger" },
        { label: t('Deactivate'), value: "Deactivate", color: "dark" }
    ]
    const [filterTr, setFilterTr] = useState<any>()
    const history = useHistory();
    const [courses, setCourses] = useState<any>();
    const [filteredCourses, setFilteredCourses] = useState<any>();
    const [loadingOnline, setLoadingOnline] = useState(true);
    const [pageNumberOnline, setPageNumberOnline] = useState(props?.pageNumberOnline);
    const [isFilteredByProf, setIsFilteredByProf] = useState<boolean>(false);
    const [isFilteredBySearch, setIsFilteredBySearch] = useState<boolean>(false);

    const trainingTitle: any = {
        "COURSE": t('Cours'),
        "PATH": t('parcoursTitle'),
        "SESSION": t('sessionTitle')
    }
    useEffect(() => {
        setPageNumberOnline(props?.pageNumberOnline)
    }, [props?.pageNumberOnline]);


    const headers = [
        { title: t('TitleFormations'), sorted: true, classNames: "w-32", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Professeurs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("creationDate"), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]



    const handleScroll = async () => {


        if (!(atBottom) && !(isFilteredByProf) && (window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight && props?.onlineCourses?.length < props?.LengthCoursesPublished && !loadingOnline) {
            setAtBottom(true);

            let filterOnline = {
                entityId: props?.user?.idSchool,
                entityType: 'SCHOOL',
                pageSize: 12,
                pageNumber: pageNumberOnline + 1,
                status: "PUBLISHED"
            }

            await props?.getOnlineCoursesForDirector(filterOnline, 'no')

            document.removeEventListener('scroll', handleScroll);
        }



        if ((window.innerHeight + window.scrollY) <= document.documentElement.scrollHeight) {
            setAtBottom(false);
        }

    }

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    useEffect(() => {
        setLoadingOnline(props?.loadingOnlineCoursesScroll)
    }, [props?.loadingOnlineCoursesScroll]);

    const doAction = async (value: any) => {
        
        if (value.action === 'goToPath') {
            if (value.row.course.type === 'COURSE') {
                await props?.getCourse(value.row.course.id);
                if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                    await props?.getRegistrationForm(value.row.course.idRegistration);
                }
                await props?.getCourseTests(value.row.course.id);
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`)
                history.push(`${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`);
            } else if (value.row.course.type === 'PATH') {
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`)
                await props?.getTrainingById('paths', value.row.course.id);
                if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                    await props?.getRegistrationForm(value?.row?.course?.idRegistration);
                }
                history.push(`${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`);
            } else {
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`)
                await props?.getTrainingById('sessions', value.row.course.id);
                history.push(`${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`);
            }
        }
    }

    const doFilterOnline = (filter: any) => {
        setFilterTr(filter)

    }


    const getProfessor = (data: any, course:any) => {

let email = data.find((prof:any) => prof.id== course.idProf)?.email;
return <><span>{course.personName}</span><br/><span className="indexes">{email}</span></>
    }
    useEffect(() => {

        if (filterTr?.searchProfessors && filterTr?.searchProfessors !== 0) {
            setIsFilteredByProf(true)
        } else {
            setIsFilteredByProf(false)
        }
        if (filterTr?.search && filterTr?.search !== '') {
            setIsFilteredBySearch(true)
        } else {
            setIsFilteredBySearch(false)
        }
    }, [filterTr])
    let getData = () => {
        let tablHeader = headers
        Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.user?.idSchool}/profs`).then(response => {

            const dataOnline = {
                headers:tablHeader,
                body: props?.onlineCourses?.map((course: any, index: any) => {
                    const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === course?.status?.toLowerCase());
                    return {
                        row: {
                            course: course,
                            actionName: "",
                            classNames: "table-white",
                            columns: [
                                { content: <DefaultTitle title={course?.title} type={course?.type} />, classNames: "w-30", actionName: 'goToPath' },
                                { content: 
                          
                                    getProfessor(response.data,course),
                                    
                                    
                                    
                                    
                                    classNames: "w-25", actionName: 'goToPath' },
                                { content: trainingTitle[course.type], classNames: "w-16", actionName: 'goToPath' },
                                { content: course.creationDate.substring(0, 10).split("-").reverse().join("/"), actionName: 'goToPath' },
                                { content: t(actualStatus?.label), classNames: ` text-end badge-text ${actualStatus?.color}`, actionName: 'goToPath' },
                            ],

                        }
                    }
                })
            }
            setCourses(dataOnline);
            setFilteredCourses(dataOnline);
        })
    }

    useEffect(() => {
        getData();        
    }, [props?.onlineCourses]);

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                {(props?.LengthCoursesPublished > 0) ?

                    <>
                        <div className="col-md-12">
                            <FilterAllCoursesComponent doFilter={(filter: any) => doFilterOnline(filter)} pageNumber={pageNumberOnline} />
                        </div>
                        {filteredCourses?.body?.length === 0 ?
                            <div className="w-100 mt-5 pt-5">
                                <div className="msg-formation-list-empty">
                                    {(isFilteredByProf === true) ?
                                        <EmptyListElement lines={[t("noTrainingSearch"), t("emptyListFormation2")]} />
                                        :
                                        <EmptyListElement lines={[t("emptyListFormation1"), t("emptyListFormation2")]} />
                                    }

                                </div>
                            </div> :
                            <div className="col-md-12 mt-5">
                                {isFilteredByProf || isFilteredBySearch ?
                                    <TableWithExpandElement data={filteredCourses} doAction={(value: any) => { doAction(value); }} size={props?.LengthCoursesPublished} />
                                    :
                                    <DragableTableWithExpandElement data={filteredCourses} doAction={(value: any) => { doAction(value); }} size={props?.LengthCoursesPublished} />

                                }


                                {(props?.onlineCourses?.length > 0 && loadingOnline) &&
                                    <div className="d-flex w-100 align-items-center justify-content-center">
                                        <div className="m-3">
                                            <svg className="spinner" viewBox="0 0 50 50">
                                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                            </svg>
                                        </div>
                                    </div>
                                }

                            </div>}
                    </> : <>
                        <div className="w-100 mt-5 pt-5">
                            <EmptyListElement lines={[t('FormationEnLigne')]} />
                        </div>
                    </>
                }
            </div >
        </div>
    );



}

const mapStateToProps = (state: AppState) => ({
    onlineCourses: state.courseReducer.onlineCourses,
    user: state.authReducer.connectedUser,
    actualTabAllCourses: state.courseReducer.actualTabAllCourses,
    loadingOnlineCourses: state.courseReducer.loadingOnlineCourses,
    loadingOnlineCoursesScroll: state.courseReducer.loadingOnlineCoursesScroll,
    LengthCoursesPublished: state.courseReducer.LengthCoursesPublished,
    pageNumberOnline: state.courseReducer.pageNumberOnline
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { redirect, clearHistoryArray, getOnlineCoursesForDirector, getCourse, getCourseTests, getRegistrationForm, getTrainingById }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableOnlineComponent);
