import { ComponentProps, FC, useEffect,useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { openModal } from '../../global-state/actions/modal-actions';
import {sendMyRequest} from '../../global-state/actions/course-actions';
import { useLocation } from "react-router-dom";
import { returnDynamicValueFromUrl } from "../../helpers/domainCheck";

const HeaderRegistrationPreviewDirectorPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [follow, setFollow]= useState<any>()
    const idFollow = returnDynamicValueFromUrl(3);
    useEffect(()=>{
        let object= props?.follow.find((followObj:any)=>{ return followObj.id===Number(idFollow)})
        setFollow(object)
    },[props?.follow])
   
    return (
        <>
            <div className="px-3 pt-3">
                <div className="px-0 mx-auto pt-5" style={{ maxWidth: '1200px' }}>
                    <div className="py-3 justify-content-between">
                        <div className="  py-4 d-flex align-items-center justify-content-between flex-wrap ">
                            <div className="col-md-7">
                                <h1 style={{ textAlign: 'left' }} className="H2-Headline ">{t('demandeDinscription')}</h1>
                                <span className="H3-Headline neutral-3"><span>{(props?.actualStudent?.courseTitle)?props?.actualStudent?.courseTitle:props?.actualStudent?.title}</span></span>
                            </div>
                            <div className="col-md-5 d-flex align-items-center justify-content-end">
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "CANDIDATE") &&
                                    <div className="badge-withicon progres">
                                    <span className="material-icons-outlined">free_breakfast</span>
                                    <span className="ms-2">
                                    {t('Enattente')}
                                    </span>
                                  </div>
                                   }
                                    {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "REFUSED") &&
                                     <div className="badge-withicon unvalide">
                                     <span className="material-icons-outlined">close</span>
                                     <span className="ms-2">
                                     {t('notValid')}
                                     </span>
                                   </div>}
                                    {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "INSCRIBED" || follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "CERTIFIED" )  &&
                                    <div className="badge-withicon validated">
                                    <span className="material-icons-round">done</span>
                                    <span className="ms-2">
                                    {t('validAction')}
                                    </span>
                                  </div>
                                  }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    follow: state.courseReducer.follow,
    actualStudent:state.detailsProfileReducer.actualStudent,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    openModal,
    sendMyRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRegistrationPreviewDirectorPageComponent);