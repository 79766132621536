
import { ComponentProps, FC, useEffect,useState } from "react"
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData, setActualTabManageProgress } from "../../global-state/actions/course-actions";
import "../../translations/i18n";
import { useHistory, useLocation } from "react-router-dom";
import { returnStartPathUrl } from "../../helpers/domainCheck";


const NavbarProgressSessionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [actualTab, setTab] = useState<string>(props?.tabProgressManager);
  const { t } = useTranslation();
  const [training, setTraining] = useState<any>();

  useEffect(() => {
    setTab(actualTab)
    return () => {
      props?.setActualTabManageProgress("previewProgress");
      setTab("previewProgress")

    }
  }, []);

  useEffect(() => {
    setTraining(props?.currentTraining);
}, [props?.currentTraining])

const setActualTab = (tab: string, where: string, id: Number) => {
  if(id === undefined){return}
  props?.setActualTabManageProgress(tab);
  setTab(tab);
  if(tab !== actualTab) {
    history.push(`${returnStartPathUrl()}/passSession/${where}`+id);
  }
}
  const goBackToList = () => {
    if(location.pathname.includes('/passSession/passCourse/') || location.pathname.includes('/passSession/resultExam/')) {
      history.push(`${returnStartPathUrl()}/passSession/progressSession/${training?.followUp?.id}`);
    } else {
      history.push(`${returnStartPathUrl()}/courses/my-courses`);
    }
    props?.setActualTabManageProgress("previewProgress");
  }

    return (
        <>
        <div className="cours-nav">
    <div className="navbar navbar-expand-lg d-flex justify-content-between p-0 ">
      <div className="col-md-1 d-flex ">
        <a onClick={() => { goBackToList() }} style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center nav-back">
          <span className="material-icons-round">
            chevron_left
          </span>
        </a>
      </div>
      <div className="col-md-11 d-flex justify-content-center ">
        <div className="d-flex  align-items-center">
          <a  style={{cursor: 'pointer'}}onClick={() => { setActualTab('previewProgress', 'progressSession/', training?.followUp?.id)} }className="d-flex justify-content-center align-items-center mx-1">
            <span className={(actualTab === 'previewProgress')?"nav-btn-black-active":"nav-btn-black"}>
            {t('sessionTitle')}
            </span>
          </a>
          <a style={{cursor: 'pointer'}} onClick={() => { setActualTab('community', 'community/', training?.followUp?.id)}} className="d-flex justify-content-center align-items-center mx-1">
            <span className={(actualTab === 'community')?"nav-btn-black-active":"nav-btn-black"}>
            {t('community')}
            </span>
          </a>
          {(props?.subscription?.limitations?.find((obj: any) => obj?.key === 'webinar') && props?.subscription?.limitations?.find((obj: any) => obj?.key === 'webinar')?.value === "true")&&
          <a style={{cursor: 'pointer'}} onClick={() => { setActualTab('webinar', 'webinar/', training?.followUp?.id) }} className="d-flex justify-content-center align-items-center mx-1">
            <span className={(actualTab === 'webinar')?"nav-btn-black-active":"nav-btn-black"}>
            {t('Webinar')}
            </span>
          </a>}
        </div>
        </div>
    </div>
  </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    tabProgressManager: state.courseReducer.tabProgressManager,
    currentTraining: state.studentTraining.currentTraining,
    history: ownProps?.history,
    subscription:state.schoolInfoReducer.subscription
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData, setActualTabManageProgress }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavbarProgressSessionComponent);
