import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxModalState } from './../reducers/modal-reducer';

export enum EReduxActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  ACTIVATE_MODAL = 'ACTIVATE_MODAL',
  DEACTIVATE_MODAL = 'DEACTIVATE_MODAL',
  CLEAR_MODAL = 'CLEAR_MODAL',
  CLOSE_MODAL_WITH_ACTION='CLOSE_MODAL_WITH_ACTION',
  SHOW_SNACK_BAR='SHOW_SNACK_BAR',
  CLEAR_SNACK_BAR='CLEAR_SNACK_BAR'
}

 interface IReduxBaseAction {
   type: EReduxActionTypes;
   data?: any;
 }

export interface IReduxOpenModalAction extends IReduxBaseAction {
  type: EReduxActionTypes.OPEN_MODAL;
  data: any;
}
export interface IReduxCloseModalAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLOSE_MODAL;
}

export interface IReduxActivateModalAction extends IReduxBaseAction {
  type: EReduxActionTypes.ACTIVATE_MODAL;
}

export interface IReduxDeactivateModalAction extends IReduxBaseAction {
  type: EReduxActionTypes.DEACTIVATE_MODAL;
}
export interface IReduxClearModalAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_MODAL;
}

export interface IReduxCloseModalWithActionAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLOSE_MODAL_WITH_ACTION;
  data:string
}

export interface IReduxShowSnackBarAction extends IReduxBaseAction {
  type: EReduxActionTypes.SHOW_SNACK_BAR;
  status: boolean,
  message: string
}

export interface IReduxClearSnackBarAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_SNACK_BAR;
}

export function clearSnackBar(): ThunkAction<Promise<IReduxClearSnackBarAction>, IReduxModalState, undefined, IReduxClearSnackBarAction> {
  return async (dispatch: ThunkDispatch<IReduxModalState, undefined, IReduxClearSnackBarAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_SNACK_BAR,
    });
  };
}

export function displaySnackBar(status: boolean, message: string): ThunkAction<Promise<IReduxShowSnackBarAction>, IReduxModalState, undefined, IReduxShowSnackBarAction> {
  return async (dispatch: ThunkDispatch<IReduxModalState, undefined, IReduxShowSnackBarAction>) => {
    
    
    if(typeof message === 'string'){
      return dispatch({
        type: EReduxActionTypes.SHOW_SNACK_BAR,
        status,
        message
      });
    }else{
      return dispatch({
        type: EReduxActionTypes.SHOW_SNACK_BAR,
        status,
        message:"Une erreur est survenue, veuillez actualiser la page dans quelques secondes."
      });
    }
    
  };
}

export function openModal(content: any): ThunkAction<Promise<IReduxOpenModalAction>, IReduxModalState, undefined, IReduxOpenModalAction> {
  return async (dispatch: ThunkDispatch<IReduxModalState, undefined, IReduxOpenModalAction>) => {
    return dispatch({
      type: EReduxActionTypes.OPEN_MODAL,
      data: content
    });
  };
}
export function closeModal(): ThunkAction<Promise<IReduxCloseModalAction>, IReduxModalState, undefined, IReduxCloseModalAction> {
  return async (dispatch: ThunkDispatch<IReduxModalState, undefined, IReduxCloseModalAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLOSE_MODAL,
    });
  };
}

export function activateModal(): IReduxActivateModalAction {
  return {
    type: EReduxActionTypes.ACTIVATE_MODAL,
  };
}

export function deactivateModal(): IReduxDeactivateModalAction {
  return {
    type: EReduxActionTypes.DEACTIVATE_MODAL,
  };
}

export function clearModal(): IReduxClearModalAction {
  return {
    type: EReduxActionTypes.CLEAR_MODAL,
  };
}

export function closeModalWithAction(content: string): ThunkAction<Promise<IReduxCloseModalWithActionAction>, IReduxModalState, undefined, IReduxCloseModalWithActionAction> {
  return async (dispatch: ThunkDispatch<IReduxModalState, undefined, IReduxCloseModalWithActionAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLOSE_MODAL_WITH_ACTION,
      data: content
    });
  };
}