interface IGetSubtitleUploaded {
  active:boolean,
  display_language: string,
  hls_link: string,
  hls_link_expires_time: number,
  id: number,
  language: string,
  link: string,
  link_expires_time: number,
  name: string,
  type: string,
  uri: string
}

export const languagesManagement = (
  type:string,
  getChapterSubtitleUploaded: IGetSubtitleUploaded[],
  getSessionSubtitleUploaded: IGetSubtitleUploaded[],
  getPathSubtitleUploaded: IGetSubtitleUploaded[],
  getCourseSubtitleUploaded: IGetSubtitleUploaded[],
  setLanguagesSelected: (myVariable: any) => void,
  ) => {
    switch (type) {
      case 'isChapter':
        if(!getChapterSubtitleUploaded || getChapterSubtitleUploaded.length === 0) return
        let allLanguagesChapter: any = [];
        getChapterSubtitleUploaded.map((el: any) => {
          return allLanguagesChapter.push(el.language)
        })
        setLanguagesSelected(allLanguagesChapter);
        break;
      case 'isSession':
        if(!getSessionSubtitleUploaded || getSessionSubtitleUploaded.length === 0) return
        let allLanguagesSession: any = [];
        getSessionSubtitleUploaded.map((el: any) => {
          return allLanguagesSession.push(el.language)
        })
        setLanguagesSelected(allLanguagesSession);
        break;
      case 'isPath':
        if(!getPathSubtitleUploaded || getPathSubtitleUploaded.length === 0) return
        let allLanguagesPath: any = [];
        getPathSubtitleUploaded.map((el: any) => {
          return allLanguagesPath.push(el.language)
        })
        setLanguagesSelected(allLanguagesPath);
        break;
      case 'isCourse':
        if(!getCourseSubtitleUploaded || getCourseSubtitleUploaded.length === 0) return
        let allLanguages: any = [];
        getCourseSubtitleUploaded.map((el: any) => {
          return allLanguages.push(el.language)
        })
        setLanguagesSelected(allLanguages);
        break;
      default:
        break;
    }

  }