import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
const FilterCommunityLoadingComponent : FC <ComponentProps<any>> = () => {
    return(
        <>
        <div className="row">
            <div className="col-md-4  mt-5 d-flex justify-content-start text-right px-0">
                <Skeleton className="skeleton  square me-3" animation="wave" variant="rect" width={230} height={48} />
            </div>
            <div className="col-md-8  mt-5 d-flex justify-content-end text-right px-0">
                <div className="select-input me-2" style={{ width: '225px' }}>
                    <Skeleton className="skeleton   square me-3" animation="wave" variant="rect" width={'100%'} height={48} /> 
                </div> 
                <div className="select-input me-2" style={{ width: '225px' }}>
                    <Skeleton className="skeleton   square me-3" animation="wave" variant="rect" width={'100%'} height={48} />
                </div> 
                <div className="select-input me-2" style={{ width: '225px' }}>
                    <Skeleton className="skeleton  square me-3" animation="wave" variant="rect" width={'100%'} height={48} />
                </div> 
            </div>
        </div>
       
                    
    </>
    )
}
export default FilterCommunityLoadingComponent; 