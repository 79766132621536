import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import "../../translations/i18n";
import CardActivityProf from "./card-prof/card-activity-prof";
import CardActivityProfLoading from "./card-prof/card-activity-prof-loading";
import CardActivityProfInitial from "./card-prof-initial/card-activity-prof-initial";
import { getCatalog } from "../../global-state/actions/catalog-duplicaction-actions";
import { getListCoursesForProfesseurApi, getFollowUpActivityApi, getProfActivityApi, getMarkMedianActivityApi } from "../../services/activity.service";
import { isArray } from "lodash";

const ProfessorActivitiesCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState(false);
    const [courseList, setCourseList] = useState<any>();

    const [connectedList, setConnectedList] = useState<any>({ "studentNbre": 0, "lastDays": 0 });
    const [followers, setFollowers] = useState<number>(0);
    const [appFile, setAppFile] = useState<number>(0);
    const [formation, setFormation] = useState<number>(0);
    const [certif, setCertif] = useState<number>(0);
    const [percentagemale, setPercentagemale] = useState<number>(0);
    const [percentagefemale, setPercentagefemale] = useState<number>(0);
    const [avgage, setAvgage] = useState<number>(0);
    const [ville, setVille] = useState<any>([]);
    const [refused, setRefused] = useState<number>(0);
    const [newCandidate, setNewCandidate] = useState<number>(0);
    const [subscriptionProf, setSubscriptionProf] = useState<number>(0);
    const [newInscribed, setNewInscribed] = useState<number>(0);
    const [year, setYear] = useState<any>([]);

    const [visit, setVisit] = useState<number>(0);
    const [newCourses, setNewCourses] = useState<number>(0);

    const [markMedian, setMarkMedian] = useState<number>(0);

    const getFollowUpActivity = async (list: any) => {
        const result = await getFollowUpActivityApi(list, props.user?.idSchool, props.user?.idPerson);
        setConnectedList(result.connectedList);
        setFollowers(result.followers);
        setAppFile(result.appFile);
        setFormation(result.formation);
        setCertif(result.certif);
        setPercentagemale(result.statistic.percentagemale);
        setPercentagefemale(result.statistic.percentagefemale);
        setAvgage(result.statistic.avgage);
        setVille(result.statistic.ville);
        setRefused(result.refused);
        setNewCandidate(result.newCandidate);
        setNewInscribed(result.newInscribed);
        setSubscriptionProf(result.subscriptionProf);
        setYear(result.year);
    }

    const getProfActivity = async (list: any) => {
        const result = await getProfActivityApi(list);
        setVisit(result.visitLast_30_Days);
        setNewCourses(result.newCoursesProf);
    }

    const getMarkMedianActivity = async (list: any) => {
        const result = await getMarkMedianActivityApi(list);
        setMarkMedian(result.markMedian);
    }

    const getListCourses = async (schoolId: any, profId: any) => {
        const result = await getListCoursesForProfesseurApi(schoolId, profId);
        setCourseList(result);
    }

    useEffect(() => {
        const fetchData = async () => {
            await getListCourses(props?.user?.idSchool, props?.user?.idPerson);
            setLoading(false);
        };
        fetchData();
    }, [props?.user?.idSchool, props?.user?.idPerson]);

    useEffect(() => {
        if (loading && courseList.length > 0) {
            const fetchActivities = async () => {
                await getMarkMedianActivity(courseList);
                await getProfActivity(courseList);
                await getFollowUpActivity(courseList);
                setLoading(false);
            };
            fetchActivities();
        }
    }, [loading, courseList]);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex flex-column justify-content-center">
                    {((isArray(courseList)) && courseList?.length === 0 && !loading) ?
                        <CardActivityProfInitial courseList={courseList} />
                        :
                        <>
                            {loading || !(courseList) ?
                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between">
                                    <CardActivityProfLoading />
                                </div>
                                :
                                <CardActivityProf
                                    courseList={courseList}
                                    connectedList={connectedList}
                                    followers={followers}
                                    appFile={appFile}
                                    formation={formation}
                                    certif={certif}
                                    percentagemale={percentagemale}
                                    percentagefemale={percentagefemale}
                                    avgage={avgage}
                                    ville={ville}
                                    refused={refused}
                                    newCandidate={newCandidate}
                                    subscriptionProf={subscriptionProf}
                                    newInscribed={newInscribed}
                                    year={year}
                                    visit={visit}
                                    newCourses={newCourses}
                                    markMedian={markMedian}
                                />
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    myCourses: state.courseReducer.myCourses,
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    user: state.authReducer.connectedUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { getCatalog },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProfessorActivitiesCardComponent);
