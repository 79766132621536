export enum ModalIDS {
    ATTENTION = 'ATTENTION',
    TRANSFER = 'TRANSFER',
    INVITE_PROF_TO_SCHOOL = 'INVITE_PROF_TO_SCHOOL',
    LOADING_VIDEO_UPLOAD_COURSE = "LOADING_VIDEO_UPLOAD_COURSE",
    ADD_COMMENT = 'ADD_COMMENT',
    VISE_TRAINING = "VISE_TRAINING",
    NOT_VISE_TRAINING = "NOT_VISE_TRAINING",
    TRANSFER_TRAINING = "TRANSFER_TRAINING",
    TRANSFER_DIRECTOR_TRAINING = "TRANSFER_DIRECTOR_TRAINING",
    ADD_QUESTION = 'ADD_QUESTION',
    ADD_COMMENT_PROGRESS = 'ADD_COMMENT_PROGRESS',
    ADD_QUESTION_PROGRESS = 'ADD_QUESTION_PROGRESS',
    CHOICE_TYPE_TEST = "CHOICE_TYPE_TEST",
    ADD_NEW_GROUP = 'ADD_NEW_GROUP',
    UPDATE_GROUP_TITLE = 'UPDATE_GROUP_TITLE',
    ADD_STUDENTS_TO_GROUP = 'ADD_STUDENTS_TO_GROUP',
    ADD_PATH_TRAINING = 'ADD_PATH_TRAINING',
    ADD_SESSION_TRAINING = 'ADD_SESSION_TRAINING',
    ADD_SUB_GROUP = 'ADD_SUB_GROUP',
    SUCCESS_MODAL_WITH_BUTTON = 'SUCCESS_MODAL_WITH_BUTTON',
    SUCCESS_ANNULATION_MODAL_WITH_BUTTON = 'SUCCESS_ANNULATION_MODAL_WITH_BUTTON',
    DELETE_GROUP = 'DELETE_GROUP',
    DELETE_COUPON = 'DELETE_COUPON',
    DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT',
    CHECK_FORM_COURSE_CONDITIONS = 'CHECK_FORM_COURSE_CONDITIONS',
    CHECK_FORM_TARIFICATION_CONDITIONS = 'CHECK_FORM_TARIFICATION_CONDITIONS',
    PUBLISH_COURSE_PROF = "PUBLISH_COURSE_PROF",
    DEACTIVATE_STUDENT = "DEACTIVATE_STUDENT",
    REINTEGRATE_STUDENT = "REINTEGRATE_STUDENT",
    ADD_STUDENT_TO_GROUPS = "ADD_STUDENT_TO_GROUPS",
    ADD_TRAINING = 'ADD_TRAINING',
    CHECK_FORM_TRAINING_CONDITIONS = 'CHECK_FORM_TRAINING_CONDITIONS',
    DEACTIVATE_TRAINING = "DEACTIVATE_TRAINING",
    REACTIVATE_TRAINING = "REACTIVATE_TRAINING",
    REFUSE_TRAINING = "REFUSE_TRAINING",
    ACCEPT_TRAINING = "ACCEPT_TRAINING",
    ADD_COMMENT_PATH = 'ADD_COMMENT_PATH',
    ADD_QUESTION_PATH = 'ADD_QUESTION_PATH',
    ADD_COMMENT_SESSION = 'ADD_COMMENT_SESSION',
    ADD_QUESTION_SESSION = 'ADD_QUESTION_SESSION',
    PUBLISH_TRAINING = 'PUBLISH_TRAINING',
    REFUSE_REGISTRATION = 'REFUSE_REGISTRATION',
    ACCEPT_REGISTRATION = 'ACCEPT_REGISTRATION',
    GIVE_FEEDBACK_MODAL = 'GIVE_FEEDBACK_MODAL',
    UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO',
    DEACTIVATE_PROF = "DEACTIVATE_PROF",
    DEACTIVATE_COUPON = "DEACTIVATE_COUPON",
    ACTIVATE_COUPON = "ACTIVATE_COUPON",
    REINTEGRATE_PROF = "REINTEGRATE_PROF",
    UPLOAD_SCHOOL_PHOTO = "UPLOAD_SCHOOL_PHOTO",
    CONFIRM_NEW_TRAINING_PRICE = "CONFIRM_NEW_TRAINING_PRICE",
    ID_ACCOUNT_PAYMEE = "ID_ACCOUNT_PAYMEE",
    INVITE_ASSISTANT_TO_SCHOOL = 'INVITE_ASSISTANT_TO_SCHOOL',
    DEACTIVATE_ASSISTANT = "DEACTIVATE_ASSISTANT",
    REINTEGRATE_ASSISTANT = "REINTEGRATE_ASSISTANT",
    INVITE_TEAM_MEMBER = "INVITE_TEAM_MEMBER",
    PROFESSOR_CHOICE = "PROFESSOR_CHOICE",
    DELETE_CARD = "DELETE_CARD",
    DEFAULT_CARD = "DEFAULT_CARD",
    LIMITATIONS_MODAL = "LIMITATIONS_MODAL",
    COMPARISATION_MODAL = "COMPARISATION_MODAL",
    SUCCESS_SUBSCRIPTION = "SUCCESS_SUBSCRIPTION",
    SUCEESS_PREVISIALIZATION = "SUCEESS_PREVISIALIZATION",
    PATH_EMPTY = 'PATH_EMPTY',
    CREATE_STUDENT = 'CREATE_STUDENT',
    STUDENT_LIST = 'STUDENT_LIST',
    ERROR_INVITATION = 'ERROR_INVITATION',
    VALIDE_INVITATION = 'VALIDE_INVITATION',
    ANNULE_INVITATION = 'ANNULE_INVITATION',
    DECLINE_TEAM_INVITATION = 'DECLINE_TEAM_INVITATION',
    ADD_VIDEO_URL = "ADD_VIDEO_URL",
    CHECK_FORM_COUPON_CONDITIONS = "CHECK_FORM_COUPON_CONDITIONS",
    FAILED_PAYMENT = "FAILED_PAYMENT",
    VALIDATE_NEW_PRICE_MODAL = "VALIDATE_NEW_PRICE_MODAL",
    DEACTIVATE_SUBSCRIPTION_MODAL = "DEACTIVATE_SUBSCRIPTION_MODAL",
    DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
    DEPOSIT_MODAL = "DEPOSIT_MODAL",
    UPDATE_SCHOOL_LOGO = "UPDATE_SCHOOL_LOGO",
    ARCHIVE_SONDAGE = "ARCHIVE_SONDAGE",
    SHARE_SONDAGE = 'SHARE_SONDAGE',
    PAYMENT_WARNING = 'PAYMENT_WARNING',
    SIGN_IN_MODAL = 'SIGN_IN_MODAL',
    SIGN_UP_MODAL = 'SIGN_UP_MODAL'
}