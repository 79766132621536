import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { openModal, closeModal, deactivateModal } from '../../global-state/actions/modal-actions';
import { createApplicationFile, clearApplicationFile, createFollow, setApplicationFile, sendMyRequest } from '../../global-state/actions/course-actions';
import Axios from '../../helpers/interceptors';
import { ModalIDS } from "../modals/modal-ids";
import { getStudentsOfProf } from '../../global-state/actions/professor-actions';
import { isValidEmail } from "../../base/functions/Functions";
import axiosInstance from "../../helpers/interceptors";
import { debounce } from "lodash";

interface Params {
    type: string;
    id: string;
}
const AddOneStudentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)
    const [errorEmail, setErrorEmail] = useState<string>('')
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const [email, setEmail] = useState("");
    const [name, setName] = useState<any>("")
    const [firstName, setFirstName] = useState<any>("")

    const checkEmail = async (email: String) => {
        try {
            const response = await axiosInstance.get(
                process.env.REACT_APP_BASE_URL2 + "person/getpersonbyemail/" + email?.toLowerCase()
            );
            return response.data;
        } catch (error) {
            throw error; // Handle the error 
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isValidEmail(email?.toLowerCase())) {
                try {
                    const res = await checkEmail(email?.toLowerCase());
                    if (res !== 'Email not found') {
                        setErrorEmail('Email already Exist !');
                    } else {
                        setErrorEmail('');
                    }
                } catch (error) {
                    console.error("Error fetching email:", error);
                    // Handle error here
                }
            }
        };

        fetchData();
    }, [email]);


    const Inviter = () => {

        if (!isValidEmail(email?.toLowerCase())) {
            setErrorEmail('L\'email est invalide');
            return;
        }

        let auth = ('Bearer ' + localStorage.getItem("access_token")).replace(/\"/g, "")
        Axios.get(baseUrl + "users/" + props?.connectedUser?.id, {
            headers: {
                'Authorization': auth
            }
        })
        .then((result) => {
            Axios.post(baseUrl1 + "person/invitationWithLimitations", {
                "invitation": {
                    "invitedBy": {
                        "id": result.data.id,
                        "firstName": result.data.firstName,
                        "lastName": result.data.lastName,
                        "gender": result.data.gender,
                        "type": "NP"
                    },

                    "invited": {
                        "firstName": firstName,
                        "lastName": name,
                        "email": email?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, ""),
                        "type": "NP"
                    },
                    "relationType": "STUDENT",

                    "to": {
                        "id": props?.schoolInfo?.id,
                        "code": props?.schoolInfo?.domain ?? document.location.hostname,
                        "name": props?.schoolInfo?.name,
                        "type": "LP",
                        "logo":props?.schoolInfo?.logo
                    }
                },
                "domain": props?.connectedUser?.role
            }).then((res) => {
                Axios.post(baseUrl1 + "person/attach", {
                    "invitation":
                    {
                        "id": res.data.id,
                        "invitedBy": res.data.invitedBy,
                        "invited": res.data.invited,
                        "relationType": "STUDENT",
                        "to": res.data.to,
                    }
                }).then(async (ress) => {
                    const newDate = new Date()
                        const date = newDate.getDate();
                        const month = newDate.getMonth() + 1;
                        const year = newDate.getFullYear();
                    if(props?.connectedUser?.role === 'role_director'){
                        const copyState= [{
                            fullName:`${firstName+" "+name}`,
                            inscriptionDate:`${(Math.abs(date) < 10 ? "0"+date : date) +"/"+ (Math.abs(month) < 10 ? "0"+month : month )+"/"+year}`,
                            lastConnectionDate:"-",
                            studentEmail:email?.toLowerCase(),
                            studentId: props?.studentsList[0]?.studentId+1,
                            studentStatus:"Enattente"
                        }, ...props?.studentsList]
                        props?.setStudentsList(copyState)
                    }else{
                      /*   const copyState= [{
                            firstName:firstName,
                            lastName:name,
                            inscriptionDate:`${(Math.abs(date) < 10 ? "0"+date : date) +"/"+ (Math.abs(month) < 10 ? "0"+month : month )+"/"+year}`,
                            lastConnexion:null,
                            studentStatus:"Enattente"
                        }, ...props?.general?.list]
                        props?.setGeneral({
                            length:props?.general?.length+1, 
                            list: copyState
                        }) */
                    }
                    props?.deactivateModal();
                    props?.setModalOpened(false);
                    await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToStudentsList'), lines: [t('invitationSentSuccesfully')] });
                })
            }).catch((error) => {
                console.error(error.response.data.message)
                //setMsg(error.response.data.message)
            })

        }).catch((error) => {
            console.error(error?.response?.data?.message)
            //setMsg(error?.response?.data?.message)
        })
    }



    const verifEmail = async (email: string) => {
        if (!regx.test(email)) {
            setErrorEmail('L\'email est invalide');
        } else {
            const result = await Axios.get(baseUrl1 + "person/getpersonbyemail/" + email).then((emailRes: any) => {
                return emailRes;
            })
                .catch((err: any) => {
                    return err
                });

            if (result.data === "Email not found") {
                setErrorEmail('')
                setEmail(email)

            } else {
                setErrorEmail('L\'email déja existe')
            }


        }
    }


    const debounceVerifMail = useCallback(debounce(verifEmail, 1000), []);
    return (
        <>
            <div className="mb-4">
                <label className="form-title">{t('Nom')}* </label>
                <input type="text" className="form-control input text-default" placeholder={t('Nom')} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className="mt-2 mb-4">
                <label className="form-title">{t('Prenom')}*</label>
                <input type="text" className="form-control input text-default" placeholder={t('Prenom')} onChange={(e) => { setFirstName(e.target.value) }} />
            </div>
            <div className="mt-2 mb-4">
                <label className="form-title">{t('emailLogin')}*</label>
                <input type="email" className="form-control input text-default" placeholder={t('emailLogin')} onChange={(e) => { debounceVerifMail(e.target.value); }} />
                {(errorEmail !== '') && <span style={{ color: '#E06A6A' }}>{errorEmail}</span>}

            </div>

            <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                <button className="btn-Secondary large me-3" onClick={() => {props?.setModalOpened(false)}} >
                    {t('annuler')}
                </button>
                <button className="btn-Primary large success" disabled={name === "" || firstName === "" || email === "" || errorEmail !== ''} onClick={() => { Inviter() }}  >
                    {t('invite')}
                </button>

            </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.courseReducer.courseInfo,
    training: state.courseReducer.training,
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    history: ownProps?.history,
    schoolInfo: state.schoolInfoReducer.schoolInfo

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    sendMyRequest, openModal, closeModal, clearApplicationFile, createApplicationFile, createFollow, setApplicationFile,deactivateModal ,getStudentsOfProf
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddOneStudentComponent);