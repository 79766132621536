import { ComponentProps, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { ModalIDS } from '../../modals/modal-ids';
import EmptyListElement from "../../../elements/empty-list/normal-empty-list-element";
import { AppState } from "../../../global-state/store/root-reducers";
import CommentCardPath from './cards/comment-card-path';
import { openModal } from '../../../global-state/actions/modal-actions';
import { connect } from "react-redux";
interface Props {
    postsList: any,
    doSearch: boolean,
    what: string,
    from?: string,
}
const ListCardCommentPathStudent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [posts, setPosts] = useState(props?.postsList)
    useEffect(() => {
        setPosts(props?.postsList)
    }, [props?.postsList])
    const openModalFn = () => {
        if (props?.from === "communityProgress") {
            if (props?.tabChatManager === "discussion") {
                props?.openModal({idModal: ModalIDS.ADD_COMMENT_PROGRESS, size: "md"});
            } else {
                props?.openModal({idModal: ModalIDS.ADD_QUESTION_PROGRESS, size: "md"});
            }
        } else {
            if (props?.tabChatManager === "discussion") {
                props?.openModal({idModal: ModalIDS. ADD_COMMENT_PATH, size: "md"});
            } else {
                props?.openModal({idModal: ModalIDS.ADD_QUESTION_PATH, size: "md"});
            }
        }


    }
    return (
        <>
            {(posts?.length === 0 && !props?.doSearch) && <div className="w-100 text-center mt-5 pt-5">
                <EmptyListElement lines={[props?.tabChatManager === "discussion"?t('noCommentFound'):t('noQuestionFound')]}>
                    <button type="button" className="btn-Secondary large icon-left" onClick={() => { openModalFn() }}>
                        <span className="material-icons-outlined">add</span>
                        <div className="m-auto ps-2">{props?.tabChatManager === "question" ? t('addQuestionButton') : t('addCommentButton')}</div>
                    </button>
                </EmptyListElement></div>}
            {(posts?.length === 0 && props?.doSearch) && <div className="w-100 text-center mt-5 pt-5"><EmptyListElement lines={[props?.tabChatManager === "discussion"?t('noCommentFound_2'):t('noQuestionFound_2')]} /></div>}
            { posts?.map((post: any) => {
                return <div className="w-100 mx-2" key={post.id}><CommentCardPath key={post.id} t={t} from={props?.from} post={post} what={props?.what} subjectType ={"path"}/></div>
            })}
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    tabChatManager: state.chatReducer.tabChatManager,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>bindActionCreators( {openModal,},dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListCardCommentPathStudent);