import { useState, useCallback, FC, useEffect, useMemo } from 'react';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { updateIndexChapters, updateIndexExams } from '../../global-state/actions/course-actions';
import CardDragDropComponent from './card-list-content-course/card-drag-drop-component';
import { getCourseChapters } from '../../global-state/actions/course-actions';
import { set } from 'lodash';
const style = {
    width: '100%',
};

interface PROPS {
    listeChapters: any[]
    getChapterForm: any
    getExamForm: any
    examFinal: any[]
    t: any
}
    
const ListCardTestChapterComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props: any) => {
    {
        const [cards, setCards] = useState(props.listeChapters || []);
        const options = useMemo(() => {
            if (Array.isArray(cards)) {
                return cards?.map((option: any, index: number) => { return { value: index + 1, label: option?.title, id: option?.id } })
            }
            return []
        }, [cards])
    

        const moveCard = useCallback((dragIndex, hoverIndex) => {
            const dragCard = cards[dragIndex];
            setCards(update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }));

        }, [cards]);


        useEffect(() => {
            

            let compteurChapter = 1;
            let compteurTest = 1;
            const newCards = cards?.map((card: any, i: number) => {
                card.index = i + 1;
                if (card.chapter) {
                    card.compteur = compteurChapter;
                    compteurChapter++;
                } else {
                    card.compteur = compteurTest;
                    compteurTest++;
                }
                return card;
            })
            if (props?.examFinal.length > 0) {
                const finalExam = props?.examFinal[0];
                finalExam.index = newCards.length + 1;
                newCards.push(finalExam);
            }
            updateIndexChaptersAsync(newCards,props?.courseForm.id);
            updateIndexExamsAsync(newCards,props?.courseForm.id)
        }, [cards]);
        const updateIndexChaptersAsync = async (newCards: any[], courseId: number) => {
            try {
              const filteredChapters = newCards.filter((obj: any) => obj.chapter !== undefined);
              await props?.updateIndexChapters(filteredChapters, courseId);
            } catch (error) {
              
              console.error("Error updating chapters:", error);
            }
          };
          const updateIndexExamsAsync = async (newCards: any[], courseId: number) => {
            try {
              const filteredExams = newCards.filter((obj: any) => obj.chapter === undefined);
              await props?.updateIndexExams(filteredExams, courseId);
            } catch (error) {
              console.error("Error updating exams:", error);
            }
          };
          
        const renderCard = (card: any, index: any) => {
            return (<CardDragDropComponent t={props?.t} getChapter={getChapter} getExam={getExam} key={index} index={index} id={card?.id} chapter={card} moveCard={moveCard} options={options} />);
        };
        const getChapter = (chapter: any) => {
            props?.getChapterForm(chapter);
        }
        const getExam = (exam: any) => {
            props?.getExamForm(exam);
        }
        return (<>
            <div className="justify-content-center" style={style}>
                {cards.map((card: any, i: number) => renderCard(card, i))}
            </div>
        </>);
    }
};
const mapStateToProps = (state: AppState, ownProps: PROPS) => ({
    listeChapters: ownProps?.listeChapters,
    courseForm: state.courseReducer.courseForm,
    getChapterForm: ownProps?.getChapterForm,
    examFinal: ownProps?.examFinal,
    t: ownProps?.t
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            updateIndexChapters,
            updateIndexExams,
            getCourseChapters
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ListCardTestChapterComponent);