import { ComponentProps, FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { returnDynamicValueFromUrl } from '../../../helpers/domainCheck';
import {  getOfferPricingCourse,createOrUpdateOfferCourse } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../../modals/modal-ids';
import { getCummulSalesByOfferFinance } from '../../../global-state/actions/finance-actions';

const ConfirmNewPriceModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const offerData = useMemo(() => { return props?.content?.data }, [props?.content]);
    const [oldRule, setOldRule] = useState<any>()
    const [newRule, setNewRule] = useState<any>()
    const [filterPayed, setFilterPayed] = useState<any>({
        yearOrMonthbyPayed: '' + new Date().getFullYear(),
        startDate: new Date().getFullYear() + '-01-01',
        endDate: new Date().getFullYear() + '-12-31',

    })
    useEffect(() => {
        if (!!props.courseTarificationForm && !!props.courseTarificationForm?.rules.find((rule: any) => rule.key === "nb_month_access")) {
            setOldRule(props.courseTarificationForm?.rules.find((rule: any) => rule.key === "nb_month_access")?.value)
        }
        if (!!offerData && !!offerData?.rules.find((rule: any) => rule.key === "nb_month_access")) {
            setNewRule(offerData?.rules.find((rule: any) => rule.key === "nb_month_access")?.value)
        }
    }, [offerData,props.courseTarificationForm])

    useEffect(() => {
        props?.getOfferPricingCourse(parseInt(returnDynamicValueFromUrl(3), 10))
    }, [])

    const { t } = useTranslation();

    const enregitrer = async () => {
        const object ={...props.courseTarificationForm}
        object.pricings = offerData?.pricings
        object.rules=offerData?.rules
        object.typePricing ="STATIC"
        object.stripeAccount=  props?.paymentMethod?.accountId;
        await props?.createOrUpdateOfferCourse(object).then(async(resp:any)=>{
            if(!!resp){
                await props?.openModal({ idModal: ModalIDS.VALIDATE_NEW_PRICE_MODAL, size: "md",data:{
             
                    trainingType:offerData.trainingType === "COURSE" ? "cours" : (offerData.trainingType === "PATH" ? "parcours" : "session")
                } 
                
                 
            })
            let param = parseInt(returnDynamicValueFromUrl(3), 10);
            await props?.getCummulSalesByOfferFinance(filterPayed, param);
            }
      
        });
    }
    const cancelUpdate = async () => {
        await props?.closeModal();
    }
    return (
        <div>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row " style={{ textAlign: "center" }} >
                    <div>
                        <span className="material-icons-outlined" style={{ color: "#9DDBA9", fontSize: "55px", marginBottom: "39px" }}>
                            check_circle</span>
                    </div>
                    <div>
                        <span className="H4-Subtitle black-800" >Vous venez de changer le prix du {offerData.trainingType === "COURSE" ? "cours" : (offerData.trainingType === "PATH" ? "parcours" : "session")}:</span>
                    </div>
                    <div>
                        <span className="H4-Subtitle neutral-3" >{props?.courseTarificationForm?.label}</span>
                    </div>

                    <div style={{ marginTop: "24px", maxWidth: '588px' }} className="m-auto py-5 px-4 d-flex justify-content-between  flex-wrap " >

                        <div>
                            <p className="H4-Subtitle black-800">Plan actuel</p>
                            {props.courseTarificationForm?.pricings?.map((ligne: any, index: any) => (
                                <div key={index} className="d-flex flex-column">


                                    <span className="body-md black-800">{
                                        ligne.price === 0 && ligne.price != null && ligne.period === "ONESHOT" ? "Gratuit" :
                                            (ligne.price > 0 && ligne.period === "ONESHOT" ? "Unique" :
                                                ligne.nbPeriod !== null && ligne.period !== "ONESHOT" ? "Par Facilité" : "Abonnement")
                                    }</span>

                                    {
                                        ligne?.nbPeriod == null && ligne.period == "ONESHOT" && ligne.price > 0 &&


                                        <span className="body-xl bold">{ligne?.price}{ligne?.currency === "TND" ? "TND" : "€"}</span>


                                    }
                                    {
                                        ligne?.nbPeriod !== null && ligne.period !== "ONESHOT" &&



                                        <span className="body-xl bold" >{ligne?.price}{ligne?.currency === "TND" ? "TND" : "€"} X    <span className="body-sm">{ligne?.nbPeriod + " Fois"} </span> </span>


                                    }
                                    {
                                        ligne?.nbPeriod == null && ligne.period !== "ONESHOT" &&


                                        <span className="body-xl bold" >{ligne?.price}{ligne?.currency === "TND" ? "TND" : "€"} /    <span className="body-sm" >{t(ligne?.period)} </span> </span>


                                    }


                                </div>
                            ))}

                            {!!oldRule && oldRule !== "-1" &&
                                <span className="body-sm bold" >Accessible:    <span className="body-sm" >{oldRule + " mois"}</span> </span>
                            }
                            {!!oldRule && oldRule === "-1" &&
                                <span className="body-sm bold" >Accessible:    <span className="body-sm" >Illimité</span> </span>
                            }
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <span className="material-icons-outlined" style={{ fontSize: "55px", marginBottom: "39px", maxWidth: 37.33 }}>

                                arrow_right_alt</span>

                        </div>
                        <div>
                            <p className="H4-Subtitle black-800">Nouveau plan affecté</p>
                            {offerData?.pricings?.map((ligne: any, index: any) => (
                                <div key={index} className="d-flex flex-column">


                                    <span className="body-md black-800">{
                                        ligne.price === 0 && ligne.price != null && ligne.period === "ONESHOT" ? "Gratuit" :
                                            (ligne.price > 0 && ligne.period === "ONESHOT" ? "Unique" :
                                                ligne.nbPeriod !== null && ligne.period !== "ONESHOT" ? "Par Facilité" : "Abonnement")
                                    }</span>

                                    {
                                        ligne?.nbPeriod == null && ligne.period == "ONESHOT" && ligne.price > 0 &&


                                        <span className="body-xl bold">{ligne?.price}{ligne?.currency === "TND" ? "TND" : "€"}</span>


                                    }
                                    {
                                        ligne?.nbPeriod !== null && ligne.period !== "ONESHOT" &&



                                        <span className="body-xl bold" >{ligne?.price}{ligne?.currency === "TND" ? "TND" : "€"} X    <span className="body-sm">{ligne?.nbPeriod + " Fois"} </span> </span>


                                    }
                                    {
                                        ligne?.nbPeriod == null && ligne.period !== "ONESHOT" &&


                                        <span className="body-xl bold" >{ligne?.price}{ligne?.currency === "TND" ? "TND" : "€"} /    <span className="body-sm" >{t(ligne?.period)} </span> </span>


                                    }


                                </div>
                            ))}
                            {!!newRule && newRule !== "-1" &&
                                <span className="body-sm bold" >Accessible:    <span className="body-sm" >{newRule + " mois"}</span> </span>
                            }
                            {!!newRule && newRule === "-1" &&
                                <span className="body-sm bold" >Accessible:    <span className="body-sm" >Illimité</span> </span>
                            }

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ paddingBottom: 80, borderTop: "none", marginTop:"20px" }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }} >
                    <button className="btn-Secondary large me-3" onClick={() => { cancelUpdate() }}>{t('annuler')}</button>
                    <button type="button" className="btn-Primary large large" onClick={() => { enregitrer() }} >{t('saveUpdate')}</button>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            getOfferPricingCourse,
            createOrUpdateOfferCourse,
            getCummulSalesByOfferFinance
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmNewPriceModalComponent);

