import { EReduxActionTypes ,IReduxChangeTabSchoolAction,IReduxGetLeaglPersonAction} from '../actions/school-actions';


export interface IReduxSchoolState {
  actualTabSchool:any,
  legalPerson:any
}

const  initialState: IReduxSchoolState = {
  actualTabSchool:"payment",
  legalPerson:undefined
};

type TSchoolReducerActions = IReduxChangeTabSchoolAction|IReduxGetLeaglPersonAction;

export default function (state: IReduxSchoolState = initialState, action: TSchoolReducerActions) {
  
  switch (action.type) {
    case EReduxActionTypes.CHANGE_TAB:
      return {...state, actualTabSchool: action.data.tab}
      case EReduxActionTypes.GET_LEGAL_PERSON:
      return {...state, legalPerson: action.data}  
    default:
      return state;
  }
}
