import { FC } from "react";
import { Switch } from "react-router"; 
import FooterComponent from "../../components/footer-component";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import CatalogPage from "./catalog-page";
import PreviewCoursePage from "./preview-course-page";
import PreviewPathPage from "./preview-path-page";
import PreviewSessionPage from "./preview-session-page";
import AdmissionFormPage from './admissionForm-page';
import CatalogRegistrationPreviewStudentPage from  './catalog-registration-preview-page';
import CustomSwitch from "../../helpers/custom-switch";
import TrainingPreviewPage from "./training-preview-page";
const CatalogContainer: FC<any> = (props: any) => { 
          return (<>
          <div className="page-wrapper-height">
            <CustomSwitch> 
                <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/preview/course/:id`} access={true} component={PreviewCoursePage} /> 
                <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/preview/path/:id`} access={true} component={PreviewPathPage} /> 
                <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/preview/session/:id`} access={true} component={PreviewSessionPage} /> 
                <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/inscription/:type/:id`} access={true} component={AdmissionFormPage} /> 
                <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/registration/:idFollow/:idStudent/:idRF`} access={true} component={CatalogRegistrationPreviewStudentPage} /> 
                <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/previewtraining/:type/:id`} access={true} component={TrainingPreviewPage} /> 
                <RouteWithNavbar history={props?.history} exact={true} path={`${props?.match.path}`} component={CatalogPage} /> 
            </CustomSwitch>

            </div>
            <FooterComponent />
          </>
          )
      }
export default CatalogContainer;