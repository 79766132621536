import Skeleton from '@material-ui/lab/Skeleton';

function CorrectionExamBodyLoadingComponent() {
    return (


        <div className="mt-3" style={{ backgroundColor: "#FBFBFD" }}>
            <div style={{ maxWidth: "1200px", margin: "auto" }}>
                <div className="row mt-3 mb-3" style={{ paddingTop: "32px" }}>
                    <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={400} height={20} />
                </div>
                <div className="row">
                    <div className=" row d-flex flex-column mb-2" style={{ paddingTop: "32px" }}>
                        <div className="d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40">
                            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                                <div className="d-flex flex-column w-100" >
                                    <div className="d-flex flex-row flex-wrap align-items-center">
                                        <Skeleton className="skeleton text mb-3 me-3" animation="wave" variant="text" width={'15%'} />
                                        <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'20%'} />
                                    </div>
                                    <Skeleton className="skeleton text" animation="wave" variant="text" width={'55%'} />
                                </div>
                            </div>
                        </div>

                        <div className="bg-accordion-border accordion-body pb-3">
                            <div className="d-flex flex-column w-100">
                                <div className="d-flex flex-row mb-4">
                                    <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={48} height={48} />
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={'50%'} height={48} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" row d-flex flex-column mb-2" style={{ paddingTop: "32px" }}>
                        <div className="d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40">
                            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                                <div className="d-flex flex-column w-100" >
                                    <div className="d-flex flex-row flex-wrap align-items-center">
                                        <Skeleton className="skeleton text mb-3 me-3" animation="wave" variant="text" width={'15%'} />
                                        <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'20%'} />
                                    </div>
                                    <Skeleton className="skeleton text" animation="wave" variant="text" width={'55%'} />
                                </div>
                            </div>
                        </div>

                        <div className="bg-accordion-border accordion-body pb-3">
                            <div className="d-flex flex-column w-100">
                                <div className="d-flex flex-row mb-4">
                                    <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={48} height={48} />
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={'50%'} height={48} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CorrectionExamBodyLoadingComponent
