import { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ImageCropping } from '../../../UI/ImageCrop/ImageCropping';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import logoSchool from '../../../styles/imgs/koorsK.png'
import { AppState } from "../../../global-state/store/root-reducers";
import { loginUser, registerProfDirector, registerStudent, uploadImage } from "../../../global-state/actions/auth-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import SelectElement from '../../../elements/select/select-element';
import { getDomainFromUrl, returnStartPathUrl } from '../../../helpers/domainCheck';
interface RegisterNP {
    firstName: string,
    lastName: string,
    password: string,
    email: string,
    id: number,
    relationType: string,
    type?: string,
    bio?: string
    token?: any
}
interface RouteParams {
    token: string,
    role: string
}

interface StudentRegisterObject {
    email: string,
    lastName: string,
    firstName: string,
    password: string,
    birthDay: string,
    photo: string,
    gender: StudentGender,
    levelStudy: string,
    country: string,
    city: string,
    link: string
}
type StudentGender = "FEMALE" | "MALE"


const Register: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RegisterNP> = (props) => {
    const { t } = useTranslation();
    let history = useHistory()
    const { token } = useParams<RouteParams>();
    const { role } = useParams<RouteParams>();
    let [button, setButton] = useState<any>();
    let [firstName, setFirstName] = useState<any>("")
    let [lastName, setLastName] = useState<any>("");
    let [gender, setGender] = useState<any>(null);
    let [birthDay, setBrithDay] = useState<any>(null);
    let [typeBirthdate, setTypeBirthdate] = useState<any>("text");
    let [city, setCity] = useState<any>(null);
    let [country, setCountry] = useState<any>("");
    let [levelStudy, setLevelStudy] = useState<any>("");
    let [validation, setValidation] = useState(false)
    const [msg, setMsg] = useState<any>("")
    const [check, setCheck] = useState<any>(false)
    let [photo, setPhoto] = useState<string>("")
    let link = window.location.href
    const [defaultValueSchooling] = useState("none");
    const listSchoolings = [
        { label: t('Primaire'), value: "Primaire" },
        { label: t('College'), value: "Collège" },
        { label: t('Lycee'), value: "Lycée" },
        { label: t('Bac'), value: "Bac" },
        { label: t('Technicien2'), value: "Technicien (Bac +2)" },
        { label: t('License'), value: "Licence (Bac+3)" },
        { label: t('Master'), value: "Master ou équivalent (BAC +4 ou + 5)" },
        { label: t('Ingenieur'), value: "Ingénieur" },
        { label: t('Doctorat'), value: "Doctorat" },
    ]
    let [loading, setLoading] = useState(false)


    useEffect(() => {
        if (props?.relationType === "STUDENT" && token == undefined)
            setButton(t('validInscription'))
        else {
            setButton(t('activateAccount'))
            setFirstName(props?.firstName === null ? "" : props?.firstName)
            setLastName(props?.lastName === null ? "" : props?.lastName)
        }
    }, [])

    function validateUser(student: StudentRegisterObject): boolean {
        return !!(
            student.firstName &&
            student.lastName &&
            student.gender &&
            student.email &&
            typeof student.firstName === 'string' &&
            typeof student.lastName === 'string' &&
            typeof student.email === 'string' &&
            isValidGender(student.gender)
        );
    }

    function isValidGender(toTest: any): toTest is StudentGender {
        return toTest === "FEMALE" || toTest === "MALE";
    }

    const register = async () => {

        if (!!token && !!role) {
            let registerProf = {
                id: props?.id,
                email: props?.email,
                lastName: lastName,
                firstName: firstName,
                password: props?.password,
                birthDay: birthDay,
                photo: photo,
                gender: gender,
                levelStudy: levelStudy,
                country: country,
                city: city,
                token: props?.token,
                relationType: props?.relationType,
                bio: props?.bio,
                domainUrl: getDomainFromUrl()

            }
            await props?.registerProfDirector(registerProf)
            await signIn()
            setLoading(false)
        } else {
            let registerElement: StudentRegisterObject = {
                email: props?.email,
                lastName: lastName,
                firstName: firstName,
                password: props?.password,
                birthDay: birthDay,
                photo: photo,
                gender: gender,
                levelStudy: levelStudy,
                country: country,
                city: city,
                link: link
            }

            if (validateUser(registerElement)) {
                props?.registerStudent(registerElement)
            }


        }

    }
    useEffect(() => {

        if (props.loadingRegisterStudent !== null && props.loadingRegisterStudent) {
            setLoading(props.loadingRegisterStudent);
        }
        if (props.loadingRegisterStudent !== null && !props.loadingRegisterStudent) {

            signIn()
        }
    }, [props.loadingRegisterStudent])

    const signIn = async () => {
        await props?.loginUser(props?.email, props?.password)
        await setLoading(props.loadingRegisterStudent);
    }

    const onGetBlobFil = async (blobFile: any) => {
        const form = new FormData();
        if (blobFile !== null) {
            form.append('file', blobFile);
            Promise.resolve(await props?.uploadImage(form)).then(() => {
                setPhoto(props?.imageUploaded?.link)
            })
        }
    }
    useEffect(() => {
        if (new Date(birthDay) >= new Date(Date.now())) {
            setCheck(false);
            setMsg("Date de naissance erronée")
        } else {
            setCheck(true);
            setMsg("")
        };

    }, [birthDay])

    useEffect(() => {
        if (props?.connectedUser && localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== '') {
            switch (props?.connectedUser?.role) {
                case "role_adminstrator": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                case "role_prof": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                case "role_student": history.push({ pathname: `${returnStartPathUrl()}/catalog`, state: undefined })
                    break;
            }
        }

    }, [props?.connectedUser])

    return (
        <>
            {(validation === false) ?
                <>
                    <div className="auth-close">
                        <button onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)} type="button" className="btn-QuickActions">
                            <span className="material-icons-outlined">close</span>
                        </button>
                    </div>
                    <div className="d-flex flex-column align-items-center w-100  auth-form mx-5">
                        <div className="auth-form-logo">
                            <img style={{ maxHeight: "164px" }} src={props?.logoschool?.logo === null ? logoSchool : props?.logoschool?.logo} alt="logo school" />
                        </div>
                        <div className="d-flex flex-column align-items-center w-100 mb-4">
                            <div className="d-flex flex-column w-100">
                                <div className="text-center">
                                    {(props?.type !== "Inscription") && <span className="H3-Headline"> {props?.type} </span>}
                                    {(props?.type === "Inscription") && <span className="H3-Headline"> {t('inscriptionTitle')} </span>}
                                </div>
                                <div style={{ marginBottom: "8%" }}></div>

                                <div className="d-flex flex-row w-100 justify-content-around">
                                    <div>
                                        <div className=" d-flex align-items-top">
                                            <input dir="ltr" className="input-style-radio" type="radio" id="female" value={gender} name="gender" onChange={() => setGender("FEMALE")} />
                                            <label htmlFor="female"></label>
                                            <span className="form-title ps-1 ms-2">{t('madame')}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className=" d-flex align-items-top">
                                            <input dir="ltr" className="input-style-radio" type="radio" id="male" value={gender} name="gender" onChange={() => setGender("MALE")} />
                                            <label htmlFor="male"></label>
                                            <span className="form-title ps-1 ms-2">{t('monsieur')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 w-100">
                                <label className="form-title">{t('PrenomObligatoire')}</label>
                                <input
                                    id='first-name'
                                    dir="ltr"
                                    type="text"
                                    className="form-control input text-default"
                                    placeholder={t('PrenomObligatoire')}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 w-100">
                                <label className="form-title">{t('NomObligatoire')} </label>
                                <input
                                    id='last-name'
                                    dir="ltr"
                                    type="text"
                                    className="form-control input text-default"
                                    placeholder={t('NomObligatoire')}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 w-100">
                                <label className="form-title">{t('birthdate')} </label>
                                <input dir="ltr" type="date" onFocus={() => setTypeBirthdate("date")} onKeyDown={(e) => {

                                }} max={new Date().toISOString().split("T")[0]} className="form-control input text-default" placeholder={t('birthdate_input')} value={birthDay} onChange={(e) => setBrithDay(e.target.value)} />

                                <div className="mt-1">
                                    <label className="error-input mt-1">{msg}</label>
                                </div>
                            </div>
                            <div className="mb-3 w-100">
                                <label className="form-title">{t('city')} </label>
                                <input dir="ltr" type="text" placeholder={t('city')} className="form-control input text-default" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="mb-3 w-100">
                                <label className="form-title">{t('NivScolaire')} </label>
                                <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueSchooling} options={listSchoolings} onChange={(e: any) => { setLevelStudy(e) }} placeholder={t('NivScolaire')} />
                            </div>
                            <div className="col-12 justify-content-center upload-image-profile mt-3">
                                <ImageCropping imageSrc={null} onGetBlobFile={onGetBlobFil} from={"register"} />
                            </div>
                            <div style={{ marginBottom: '5%' }}></div>
                            <div className="col-md-12 text-center">
                                {!loading ? <button type="button" className="btn-Primary large" onClick={register} disabled={!(firstName !== '' && lastName !== '' && gender !== null && check !== false)} >
                                    {button}
                                </button> :
                                    <button type="button" className="btn-Primary large loaddata " style={{ width: 216, height: 48 }} >
                                        <div className="threedots-large-Animation">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>}
                            </div>

                            <div className="mt-5 d-flex  flex-column align-items-center">
                                <div className=" text-center">
                                    <span className="body-md bold black-800" >{t('alreadyHaveAcc')}</span>

                                </div>

                                <div className="mt-3 ">
                                    <button type="button" className="btn-Secondary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                                        {t('cnxButton')}
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>


                </>
                :
                <>

                    {
                        props?.relationType === "STUDENT" && token === undefined && <>
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-8 justify-content-center">
                                    <div className="row justify-content-center">
                                        <div className="col-md-6 text-center">
                                            <img style={{ maxHeight: "164px" }} src={props?.logoschool?.logo === null ? logoSchool : props?.logoschool?.logo} alt="logo school" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-5">
                                        <div className="col-md-6 justify-content-center mt-3">
                                            <div className="row justify-content-center form">
                                                <div className="row success">
                                                    <div className="col-md-12 text-center">
                                                        <span className="material-icons" style={{ color: "#9DDBA9", fontSize: '50px' }}>check</span>
                                                        <div style={{ marginBottom: "8%" }}></div>
                                                        {(props?.relationType === "STUDENT") &&
                                                            <span className="H4-Subtitle">{t('registerDone_1')}</span>}
                                                        {(props?.relationType !== "STUDENT") &&
                                                            <span className="H4-Subtitle">{t('CreationAccountProfDirector')}</span>}
                                                    </div>
                                                    <div style={{ marginBottom: '6%' }}></div>

                                                    <div className="col-md-12 text-center">
                                                        {(props?.relationType === "STUDENT") &&
                                                            <>
                                                                <span className="H4-Subtitle">{t('registerDone_2')}</span>

                                                                <div style={{ marginBottom: '6%' }}></div>
                                                                <span className="H4-Subtitle" style={{ position: "relative", left: "10px" }}> {t('registerDone_3')}</span>

                                                                <div style={{ marginBottom: '20%' }}></div>
                                                                <span className="H4-Subtitle">{t('registerDone_4')}</span>

                                                            </>
                                                        }
                                                        {(props?.relationType !== "STUDENT") &&
                                                            <>
                                                                <span className="H4-Subtitle mt-5">{t('registerDone_4')}</span>

                                                            </>
                                                        }

                                                    </div>
                                                    <div style={{ marginBottom: '20%' }}></div>

                                                    <div className="col-md-12 text-center">
                                                        <button type="button" className="btn-Primary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                                                            {t('cnxButton')}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    }
                </>
            }
        </>
    )


}
const mapStateToProps = (state: AppState) => ({
    imageUploaded: state.authReducer.imageUploaded,
    connectedUser: state.authReducer.connectedUser,
    logoschool: state.schoolInfoReducer.schoolInfo,
    loadingRegisterStudent: state.authReducer.loadingRegisterStudent
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            registerProfDirector,
            registerStudent,
            uploadImage,
            loginUser
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Register);
