import { ComponentProps, FC } from 'react';
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { getProfessor, } from '../../../global-state/actions/professor-actions';
import FeedbackTrainingRateComponent from '../../feedback/feedback-training-rate-component';

const GiveFeedbackTrainingModal: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row justify-content-center">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="row">
                            <FeedbackTrainingRateComponent coursInfo={props?.content?.data} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    content: state.modalReducer.modalContent,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ openModal, closeModal, getProfessor }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GiveFeedbackTrainingModal);