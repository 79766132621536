import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/';
import "../../translations/i18n";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import DisplayClosedQuestionComponent from './display-closed-question-component';
import { getResultAnswers } from '../../global-state/actions/exam-actions';
import DisplayOpenQuestionComponent from './display-open-question-component';


const ExamResultTrainingComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [studentAnswers, setStudentAnswers] = useState<any>(props?.studentAnswers);
    const [exam, setExam] = useState<any>();

    const questionByQnaList = useMemo(() => { return props?.examResult?.questionByQnaList }, [props?.examResult?.questionByQnaList])
    const { t } = useTranslation();

    useEffect(() => {
        setExam(props?.examResult);
        if( typeof props?.training?.followUp?.id != undefined && props?.training?.followUp?.id !=0 &&  props?.training?.followUp?.id!=undefined){
            props?.getResultAnswers(props?.examResult?.id, props?.connectedUser?.idPerson, props?.training?.followUp?.id);
        }
    }, [props?.examResult])
    useEffect(() => {
        setStudentAnswers(props?.studentAnswers)
    }, [props?.studentAnswers]);
    
    return (
        <div>
            <div className="grid-container mx-4" style={{ borderWidth: "40px 40px 40px 40px", boxSizing: "border-box", border: "1px solid #F2F2F5", borderRadius: "3px", padding: "40px 40px", backgroundColor: "#FBFBFD" }} >
                <div className="d-flex align-items-center" style={{ paddingRight: "40px" }}>
                    <div className={`test-state ${(exam?.progression?.finalMark / exam?.progression?.scaleNote >= 0.5) ? 'success' : 'refused'} d-flex align-items-center`}>
                        <span className="material-icons-outlined" style={{ fontSize: 64 }}>emoji_events</span>
                    </div>


                    <div style={{ paddingLeft: "40px" }}>
                        <div className="grid-container">
                            <div className="mb-4" > <span className="H3-Headline black-800" > {exam?.title} </span> </div>
                            <div className="pb-2" > <span className="H4-Subtitle neutral-3" >{(exam?.progression?.finalMark / exam?.progression?.scaleNote >= 0.5) ? (<span > {t("Réuissi")} !</span>) : (<span  > {t("insuffisant")} </span>)}</span> </div>
                            <div className="" >
                                <span className="H4-Subtitle neutral-3"  >
                                    {(exam?.progression?.finalMark / exam?.progression?.scaleNote >= 0.5) ? (<span style={{ color: "#9DDBA9" }} >{exam?.progression?.finalMark}</span>) : (<span style={{ color: "#E06A6A" }} >{exam?.progression?.finalMark}</span>)}/{exam?.progression?.scaleNote} </span> </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="" style={{ padding: "24px 24px 24px 24px" }}>
                {studentAnswers?.map((obj: any, i: number) => {
                    return <div key={obj.id} >
                        {obj?.type === "CQA" ? (
                            <div style={{ paddingBottom: "16px" }}>
                                <DisplayClosedQuestionComponent
                                    key={i}
                                    answer={obj}
                                    question={questionByQnaList?.find((qbe: any) => qbe?.id === obj?.questionByExam?.id)}
                                    index={i}
                                    color={'white'}
                                />
                            </div>
                        ) : (

                            <div style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                                <DisplayOpenQuestionComponent
                                    color={'white'}
                                    key={i}
                                    answer={obj}
                                    question={questionByQnaList?.find((qbe: any) => qbe.id === obj?.questionByExam?.id)}
                                    index={i}
                                />
                            </div>

                        )}
                    </div>
                })}

            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ getResultAnswers }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    studentAnswers: state.examReducer.listOfResponses,
    examResult: state.studentTraining.examResult,
    training: state.studentTraining.currentTraining,

});

export default connect(mapStateToProps, mapDispatchToProps)(ExamResultTrainingComponent);


