import { useTranslation } from "react-i18next";
import { ComponentProps, FC, useState, useEffect} from "react"
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { loginWithCookies } from '../../../global-state/actions/auth-actions';
import { AppState } from '../../../global-state/store/root-reducers';
import {  useHistory } from 'react-router-dom';
import { getCodeFromUrl } from "../../../helpers/domainCheck";
import { logout } from "../../../global-state/actions/user-auth-actions";


const  LoadingComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [cookieValue, setcookieValue] = useState<any>()
  const code =window.location.href.split("//")[1].split("/")[0] === "localhost:3000" || window.location.href.split("//")[1].split("/")[0] === "app" + process.env.REACT_APP_BASE_SUBDOMAIN ? window.location.href.split("//")[1].split("/")[1] : window.location.href.split("//")[1].split(".")[0]
  const history = useHistory();
  

  useEffect(() => {
    
      if (typeof window !== "undefined") {
      setcookieValue(findCookie(code+"Token"))
      if ((window.location.href === "https://"+process.env.REACT_APP_SCHOOL_DOMAIN1)||(window.location.href === "https://"+process.env.REACT_APP_SCHOOL_DOMAIN) ){
        window.location.href = "https://"+process.env.REACT_APP_GENERIC_LOGIN
    }
      }
  }, [])


  useEffect(() => {
      setcookieValue(findCookie(code+"Token"))
  }, [code])


  const findCookie=(cname:string)=>{
      var name = cname + "=";
          var ca = document.cookie.split(';');
          for (var i = 0; i < ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) === ' ') c = c.substring(1);
              if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
          }
          return "";
  }


  

  const loginWithCookies = async()=>{
      const offer_token: any = localStorage.getItem("access_limitation");
      localStorage.clear();
      localStorage.setItem("access_limitation", offer_token);
       props?.loginWithCookies(cookieValue)
       setcookieValue("")
  }

  useEffect(() => {
      if(cookieValue !== undefined && cookieValue?.length!=0 && cookieValue !== null && cookieValue !== "" && cookieValue !== " "){
          loginWithCookies()
      }
  }, [cookieValue])


  useEffect(() => {
    let codeUrl = getCodeFromUrl()
    let codeRedux = props?.school?.code
    if (codeRedux && codeUrl) {
        if (codeRedux != codeUrl) {
             props?.logout();
        }
    }
}, [props?.school?.code])
  
  return (
    <div className="page-wrapper-height mx-auto" style={{display:"flex", alignItems:"center" , justifyContent:"center", flexDirection:"column", margin:"auto"}}>
    <div  style={{display:"flex", alignItems:"center" , justifyContent:"center", position:"relative" }}>
        <div className="bt-spinner " style={{width:"80px" , height:"80px" }}> 
        </div>
   
    </div>
        <span className="body-md neutral-2 mt-4 pt-3">{t('loadInformation')}</span>

    </div>
  )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
 school : state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ 
  loginWithCookies,
  logout
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoadingComponent);