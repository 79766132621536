import { ClickAwayListener } from '@material-ui/core'
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ModalIDS } from '../../components/modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { deleteInvitation, getTeam, getTeamNumber, resend } from '../../global-state/actions/professor-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";


const TeamTableAction: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [menuButton, setMenuButton] = useState(false)
    const [id, setId] = useState<number>(props?.id)
    const { t } = useTranslation();
    const [tabCss, setTabCss] = useState<boolean>(false)
    const [tabCss1, setTabCss1] = useState<boolean>(false)

    useEffect(() => {
        setId(props?.id)
    }, [props?.id])

    const open = (e:any) =>{
        e.stopPropagation();
        setMenuButton(!menuButton)
    }

    const resendInvitation = (e:any , id:number) =>{
        e.stopPropagation();
        props?.resend(id)
        setMenuButton(!menuButton)
        props?.activateSnakBar()
        setTabCss(!tabCss)
    }

    const deleteInvitation = async(e:any) =>{
        await e.stopPropagation();
        await setMenuButton(!menuButton)
        await  props?.openModal({ idModal: ModalIDS.DECLINE_TEAM_INVITATION, info:{id:id ,role:props?.role} , size: "md" })  
        setTabCss1(!tabCss1)      
        
    }

  


    return (
        <div>
            {props?.status === t('Enattente') && <div onMouseEnter={() => { setShowMenu(true) }} onMouseLeave={() => { setShowMenu(false) }} className='d-flex justify-content-end'>
                {
                    showMenu === false ? <span className={`text-end badge-text ${props?.color}`}>{props?.status}</span>
                        :
                        <div style={{ width: "48px", height: "48px" }}>
                            <ClickAwayListener onClickAway={() => { setShowMenu(false) }}>
                                <div className=" d-flex justify-content-end">
                                    <button className="me-3 btn-QuickActions transparent" onClick={(e: any) => { open(e) }}>
                                        <span className="material-icons"> more_horiz </span>
                                    </button>
                                    {menuButton === true && <div className={` py-3 navbar-submenu-list`} style={{ position: 'absolute', right: 0, top: 40 }}>
                                        <div className="d-flex flex-column">
                                            <div onMouseEnter={()=>setTabCss(!tabCss)} onMouseLeave={()=>setTabCss(!tabCss)}  >
                                            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3"  >
                                                <span className={`${tabCss===false ? "body-md " : "body-md neutral-2"} bold  ms-2`}    onClick={(e) => { resendInvitation(e, id) }}>{t('resendInvitation')}</span>
                                            </a>
                                            </div>
                                            <div  onMouseEnter={()=>setTabCss1(!tabCss1)} onMouseLeave={()=>setTabCss1(!tabCss1)}>
                                            <a className="navbar-submenu-list-button d-flex align-items-center p-2 px-3"  >
                                            <span className={`${tabCss1===false ? "body-md " : "body-md neutral-2"} bold  ms-2`} onClick={(e) => { deleteInvitation(e) }}>{t('deleteInvitation')}</span>
                                            </a>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </ClickAwayListener>
                        </div>
                }
            </div>}
            {props?.status != t('Enattente') && <div>
                <span className={`text-end badge-text ${props?.color}`}>{props?.status}</span>
            </div>}
        </div>
    )
}



const mapStateToProps = (state: AppState, ownProps: any) => ({
    director: state.authReducer.connectedUser,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            resend,
            deleteInvitation,
            getTeamNumber,
            getTeam,
            openModal
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamTableAction);
