import { ComponentProps, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ModalIDS } from '../../components/modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { getUserProfileDetails, updateProfile } from '../../global-state/actions/auth-actions';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import { getDomainFromUrl } from '../../helpers/domainCheck';

const InputUploadProfilePhoto: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [profile, setProfile] = useState(props?.userProfileDetails)
    const [photo, setPhoto] = useState<any>("");
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState(props?.userProfileDetails?.firstName);
    const [typeBirthdate, setTypeBirthdate] = useState<any>((props?.userProfileDetails?.dateNaiss !== null) ? props?.userProfileDetails?.dateNaiss : "");
    const [country, setCountry] = useState(props?.userProfileDetails?.country);
    const [lastName, setLastName] = useState(props?.userProfileDetails?.lastName);
    const [city, setCity] = useState(props?.userProfileDetails?.city);
    const [domain, setDomain] = useState(props?.userProfileDetails?.schooling);
    const [email, setEmail] = useState(props?.userProfileDetails?.email);





    const deleteFile = async () => {
        let copyProfile = {...props?.userProfileDetails};
        copyProfile.photo = null;
        setPhoto(null)
        copyProfile.lastName = lastName;
        copyProfile.firstName = firstName;
        copyProfile.email = email;
        copyProfile.country = country;
        copyProfile.city = city;
        copyProfile.dateNaiss = typeBirthdate;
        copyProfile.domain = domain;
        copyProfile.domainUrl= getDomainFromUrl()

        await props?.updateProfile(copyProfile);
        await props?.getUserProfileDetails(props?.connectedUser?.id, 'dont'); //person
    }
    useEffect(() => {
        setPhoto(props?.userProfileDetails?.photo)
    }, [])

    useEffect(() => {
        setPhoto(props?.userProfileDetails?.photo)
    }, [props?.userProfileDetails?.photo])

    useEffect(() => {
        
        setFirstName(props?.userProfileDetails?.firstName);
        setTypeBirthdate((props?.userProfileDetails?.dateNaiss) ? props?.userProfileDetails?.dateNaiss : "");
        setCountry(props?.userProfileDetails?.country);
        setLastName(props?.userProfileDetails?.lastName);
        setCity(props?.userProfileDetails?.city);
        setDomain(props?.userProfileDetails?.schooling);
        setEmail(props?.userProfileDetails?.email);
        setPhoto(props?.userProfileDetails?.photo);
            }, [])

    useEffect(() => {
        
        setFirstName(props?.userProfileDetails?.firstName);
        setTypeBirthdate((props?.userProfileDetails?.dateNaiss) ? props?.userProfileDetails?.dateNaiss : "");
        setCountry(props?.userProfileDetails?.country);
        setLastName(props?.userProfileDetails?.lastName);
        setCity(props?.userProfileDetails?.city);
        setDomain(props?.userProfileDetails?.schooling);
        setEmail(props?.userProfileDetails?.email);
        setPhoto(props?.userProfileDetails?.photo);
        
    }, [props?.userProfileDetails])


    const openModalFn = () => {
        props?.openModal({ idModal: ModalIDS.UPDATE_PROFILE_PHOTO, size: "md" })
    }


    
    return (
        <div className="d-flex align-items-center justify-content-center flex-column mx-auto">
            <div   >
                {photo === null ?
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div className="test-state waiting d-flex align-items-center justify-content-center" style={{ width: "160px", height: "160px" }} >
                            <span className="material-icons-outlined" style={{ fontSize: "80px" }} > person_outline </span>
                        </div>
                        <div style={{ paddingTop: "42px" }} >
                            <button type="button" className="btn-Secondary large" onClick={() => { openModalFn() }}> {t('addPhoto')} </button>
                        </div>
                    </ div>
                    :
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div style={{ paddingBottom: "40px" }}>
                            <img style={{ position: "relative", borderRadius: "50%", width: "160px", height: "160px", zIndex: 100, border: "3px solid #f8f8fa" }} src={photo} />
                        </ div>
                        <div>
                            <button type="button" className="btn-Secondary large"style={{width: "max-content"}} onClick={() => { openModalFn() }} >{t('changePhoto')}</button>
                        </div>
                        <div style={{ paddingTop: "16px" }}>
                            <button className="btn-text outlined" onClick={(e) => deleteFile()}>{t('deletePhoto')}</button>
                        </div>
                    </ div>
                }
            </div>



        </div >
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    updateProfile,
    getUserProfileDetails,
    openModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InputUploadProfilePhoto);