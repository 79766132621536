import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SelectMultipleElements from '../../elements/multipleSelect/select-multiple-elements';
import { createTraining, deleteSubject, removeTest } from '../../global-state/actions/training-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { formatNumber, getDuration } from '../../base/functions/Functions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import axiosInstance from '../../helpers/interceptors';
interface Props {
  options: any[],
  data: any,
  cardsLength?: number,
  index: number,
  moveCard?: Function,
  t: any,

}
const PathCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const history = useHistory()
  const ItemTypes = {
    CARD: 'card',
  }
  const ref = useRef<HTMLDivElement>(null)
  const [isShown, setIsShown] = useState(false)
  const index = useMemo(() => { return props?.index }, [props?.index])
  const prevOptions: any = usePrevious(props?.options)
  const [mandatories, setMandatories] = useState<any | undefined>(undefined)
  const [options, setOptions] = useState<any[] | undefined>(undefined)
  const { t } = useTranslation();
  useEffect(() => {
    if (Array.isArray(props?.data?.mandatories)) {
      let differences = prevOptions?.filter((x: any) => props?.options?.find((y: any) => y?.id == x?.id)?.value !== x?.value);
      if (Array.isArray(differences) && differences.length > 0) {
        setMandatories(props?.data?.mandatories.map((mandatory: number) => {
          let id = prevOptions.find((prevOption: any) => prevOption?.value === mandatory)?.id
          if (!!id) {
            return props?.options.find((option: any) => option?.id === id)?.value
          }
          return mandatory;
        }))
      }

      else {
        setMandatories(props?.data?.mandatories)
      }
      setOptions(Array.isArray(props?.options) ? props?.options.slice(0, index).concat(props?.options.slice(index + 1)) : [])
    }
    else {
      setMandatories(undefined)

    }
  }
    , [props?.options, props?.data?.mandatories])
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (clientOffset.y < 430) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      if (props?.moveCard) {
        props?.moveCard(dragIndex, hoverIndex);
      }
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  const formatedIndex = useMemo(() => { return formatNumber(index + 1) }, [index])
  const deletePathSubject = async () => {
    if (mandatories !== undefined) {

      
      
     await props?.deleteSubject(props?.path?.id, props?.data?.id)
     if(props?.path?.status==="PUBLISHED"){
      let updateFollows =
          {
              "action":"DELETE",
              "trainingType":"PATH",
              "trainingId":props?.path?.id,
              "idSubject":props?.data?.id,
              "typeSubject":props?.data?.type==="Cours" ? "COURSE":"PATH"
          }

      axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdate", updateFollows)
      
   }

    }
    else {
      props?.removeTest("PATH", props?.data?.id)
    }
  }
  useEffect(() => {
    if (!!mandatories) {

      let mandatoriesBySubject = mandatories?.map((e: any) => e - 1)
      let subjectByPathIndex = props?.path?.subjectsByPath.findIndex((e: any) => e?.id == props?.data?.id)
      if (JSON.stringify(mandatoriesBySubject) != JSON.stringify(props?.path?.subjectsByPath[subjectByPathIndex]?.mandatories)) {
        let path = props?.path
        path.subjectsByPath[subjectByPathIndex] = { ...props?.path?.subjectsByPath[subjectByPathIndex], mandatories: mandatoriesBySubject }
        props?.createTraining("paths", path)
      }
    }
  }, [mandatories])



  const goTo = async () => {
    if (mandatories === undefined) {
      props?.clearHistoryArray()
      props?.redirect(t('Parcours'), `${returnStartPathUrl()}/courses/create-formation/path/content`)
      props?.redirect(props?.path?.title, `${returnStartPathUrl()}/courses/create-formation/path/content`)
      props?.redirect(t('finalExam'),"")
      history.push({ pathname: `${returnStartPathUrl()}/courses/create-formation/path/test` })
    }
  }

  drag(drop(ref))


  return (
    <>{
    }
      <div ref={ref} style={{ opacity: opacity, minHeight: 120 }} className={` d-flex align-items-center bg-with-border white vignette-hover dragAndDrop  ps-40 pe-4 mb-3 ${(isShown !== true) ? "py-28" : "py-23"}`} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} onClick={() => { goTo() }}>
        {!isShown || mandatories === undefined ? <>
          <div className="me-40" style={{ width: 32 }}>
            <span className="H3-Headline" >{formatedIndex}</span>
          </div>
        </> :
          <><div className="me-38">
            <button type="button" disabled={index === 0} onClick={() => { props?.moveCard && props?.moveCard(index, index - 1) }} className="btn-QuickActions small mb-2" >
              <span className="material-icons-outlined">
                arrow_upward</span>
            </button>
            <button type="button" disabled={index === options?.length} onClick={() => { props?.moveCard && props?.moveCard(index, index + 1) }} className="btn-QuickActions small" >
              <span className="material-icons-outlined">
                arrow_downward</span>
            </button>
          </div></>
        }
        <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column">
              <span className="H3-Headline mb-6 formation-card horizontal title">{props?.data?.title}</span>
              <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                <>
                  <div className="d-flex me-2">
                    {props?.data?.icon}
                  </div>
                  <div className="body-sm bold neutral-2">
                    {props?.data?.type}
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className=" d-flex align-items-center detail-liste-responsive-actions me-2 justify-content-end" style={{ minWidth: 520 }}>

            {mandatories !== undefined && props?.cardsLength && props?.cardsLength > 1 &&
              <div className="me-4 detail-liste-responsive-select" style={{ minWidth: 147, maxWidth: 147 }}>
                <SelectMultipleElements defaultValue={mandatories} placeholder={"access"} taille={'md'} options={options} onChange={setMandatories} />
              </div>
            }
            <div className={props?.data?.classNames}>
              <div className="d-flex align-items-center justify-content-start training-card-lvl detail-liste-responsive-level me-4 pe-2">{/*updated*/}
                {props?.data?.other}
              </div>
              {!isShown ? <>
                <div className="d-flex align-items-center training-card-timing">{/*updated*/}
                  <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
                  <span className="body-md bold">{getDuration(props?.data?.duration)}</span>
                </div>
              </> :
                <><div className="d-flex flex-row training-card-timing justify-content-end">{/*updated*/}
                  <button onClick={(event) => { event.stopPropagation(); deletePathSubject() }} className="btn-QuickActions small" >
                    <span className="material-icons-outlined">
                      delete</span>
                  </button>
                </div></>}
            </div>
          </div>
        </div>
      </div>
    </>


  );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createTraining,
      removeTest,
      deleteSubject,
      redirect, clearHistoryArray
    },
    dispatch
  );
const mapStateToProps = (state: AppState) => ({
  path: state.trainingReducer.path
});
// Hook
function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
export default connect(mapStateToProps, mapDispatchToProps)(PathCardComponent);
