import { ComponentProps, FC, useEffect, useState, useRef, useMemo } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getUserProfileDetails, updateProfile } from '../../global-state/actions/auth-actions';
import { setConnectedUser } from '../../global-state/actions/auth-actions';
import { getUserById } from '../../global-state/actions/user-auth-actions';
import { getPaymentSetting } from '../../global-state/actions/finance-actions';
import { loginUser } from '../../global-state/actions/auth-actions';
import { openModal } from '../../global-state/actions/modal-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import CardComponent from './card-component';
import { getActualTab ,getCards} from '../../global-state/actions/auth-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getOffer, getContract } from '../../global-state/actions/app-container-actions';

const FacturationComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    let dateFormat = require('dateformat');
    const { t } = useTranslation();
    const [contract, setContract] = useState<any>()
    const openRef = useRef(null);
    const openRef2 = useRef(null);
    const [datesNbre, setDatesNbre] = useState<any>()
    let history = useHistory()


    useEffect(() => {
        if (!!props?.subscription && !!props?.subscription?.idOffer) {
            props?.getOffer(props?.subscription?.idOffer)
        }
    }, [props?.subscription])

    useEffect(() => {
        if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
            props?.getContract(props?.schoolInfo?.id, "SCHOOL")
        }
    }, [props?.schoolInfo])

    useEffect(() => {
        if (!!props?.contract && props?.contract?.length >0) {

            setContract(props?.contract[0])
        }

    }, [props?.contract])
 
    useEffect(() => {
        if (!!props?.contract) {

            setDatesNbre(props?.contract[0]?.contractDetails?.contractDuration?.endAt  != null ?(new Date(props?.contract[0]?.contractDetails?.contractDuration?.endAt).getTime() - Date.now()) / (1000 * 3600 * 24):0)
  
        }

    }, [props?.contract])

    const goAction = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("addNewPaymentMethod"), `${returnStartPathUrl()}/param`)
         history.push(`${returnStartPathUrl()}/customer/add`)


    }

    useEffect(() => {
        if (!!props?.schoolInfo?.id ) {
            props?.getPaymentSetting(props?.schoolInfo?.id, "CUSTOMER").then(async (resp: any) => {

               if(!! resp.data && !!resp.data.engineId)

               { props?.getCards(resp.data.engineId)}
            })
        }
    }, [props?.schoolInfo])


    return (<>

        <>


            <div className='w-100 d-flex flex-column p-4'>
                { !!props?.cards &&props?.cards?.length == 0 && <>
                    <div className='d-flex flex-column' >

                        <span className="H4-Subtitle black-800">{t('noPaymentMethodAdded')}</span>
                    </div>
                    <hr className="hr-nav my-3" /></>}
                {!!props?.cards && props?.cards?.length > 0 && props?.cards.map((card: any,index:any) => {
                    return <>
                        <CardComponent brand={card.brand} last4={card.last4} cusId={card.customer} cardId={card?.id} index={index}/>

                    </>
                })}

                <div className='d-flex flex-column mt-2' >
                    <div className='d-flex flex-row align-items-center justify-content-end'>

                        <button type="button" className="btn-Secondary large" onClick={goAction} >
                            {t('addPaymentMethod')}
                        </button>
                    </div>
                </div>



            </div>

        </>
       
    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    subscription: state.schoolInfoReducer.subscription,
    contract: state.schoolInfoReducer.contract,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedSchool: state.authReducer.connectedSchoolInfo,
    cards: state.authReducer.cards,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    updateProfile,
    getUserById,
    setConnectedUser, getOffer, getContract,
    loginUser,
    openModal,
    getPaymentSetting,
    getCards,
    getActualTab,
    redirect, clearHistoryArray
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacturationComponent);


