import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { useHistory } from "react-router-dom";
import BtnAddSondage from "../../pages/sondage/BtnAddSondage";
const HeaderPreviewSondageComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ props }: any) => {

  const [sondageVision, setSondageVesion] = useState(true)
  const history = useHistory()
  const redirect = async () => {
    props?.clearHistoryArray()
    props?.redirect("Title", `${returnStartPathUrl()}/survey/preview`)
    await history.push(`${returnStartPathUrl()}/survey/preview`)
 
  }
  useEffect(() => {
    if (!sondageVision ) {
      redirect();
    }
  }, [sondageVision])
  return (
    <>

      <div className="container-fluid" style={{ marginTop: 80 }}>
        <div className="row justify-content-center">
          <div className="row justify-content-center">
            <div className="col-md-12 " style={{ backgroundColor: '#FFFFFF' }}>
              <div className="row mx-auto" style={{ maxWidth: '1200px' }}>
                <div className="col-md-12 mt-5" >
                  <div className="d-flex align-items-center mb-3 " style={{ justifyContent: "space-between" }}>
                    <h1 className="H2-Headline d-flex align-items-center" style={{ textAlign: 'left' }}>Sondage</h1>
                    <div className='d-flex flex-end mb-4'>
                      <div className="btn-header-creacte-sondage">
                        <input className="input-style" type="checkbox" id="toggleBtn4" onClick={() => setSondageVesion(!sondageVision)} checked={sondageVision} />
                        <div className="toggle-container cours-editing">
                          <label className="label-style cours-editing" htmlFor="toggleBtn4">
                            <span className="on cours-editing">Éditer</span>
                            <span className="off cours-preview">Prévisualiser</span>
                            <input type="text" style={{ display: "none" }} />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
      </div>

    </>

  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  title: ownProps.title,
  examToPass: state.examReducer.examToPass
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ 
    redirect, clearHistoryArray
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPreviewSondageComponent);
