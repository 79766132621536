import axiosInstance from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxWebinarState } from '../reducers/webinar-reducer'
export enum EReduxActionTypes {

   CREATE_WEBINAR = 'CREATE_WEBINAR',
   GET_WEBINAR = 'GET_WEBINAR',
   GET_CALENDAR = 'GET_CALENDAR',
   GET_WEBINARBYID = 'GET_WEBINARBYID',
   CHANGE_STATUS = 'CHANGE_STATUS',
   UPDATE_WEBINAR = 'UPDATE_WEBINAR',
   UPDATE = 'UPDATE',
   LOADING_MY_WEBINARS = 'LOADING_MY_WEBINARS',
   CHECK_WEBINAR_TIME_AVAILABILITY = 'CHECK_WEBINAR_TIME_AVAILABILITY',
   CLEAR_CHECK = 'CLEAR_CHECK',
   CREATE_EXTERNAL_ACCOUNTS = 'CREATE_EXTERNAL_ACCOUNTS',
   CHECK_EXTERNAL_ACCOUNT ='CHECK_EXTERNAL_ACCOUNT',
   GET_EXTERNAL_ACCOUNT_INFO ='GET_EXTERNAL_ACCOUNT_INFO',
   DISCONNECT_FROM_ZOOM = 'DISCONNECT_FROM_ZOOM',
   ADD_EXTERNAL_ACCOUNT = 'ADD_EXTERNAL_ACCOUNT',
   REFRESH_ZOOM_TOKEN = 'REFRESH_ZOOM_TOKEN',

}
interface IReduxBaseAction {
   type: EReduxActionTypes;
}



export interface IReduxGetAddExternalAccountAction extends IReduxBaseAction {
   type: EReduxActionTypes.ADD_EXTERNAL_ACCOUNT;
   data: any;
}

export interface IReduxGetDisconnectFromZoomAction extends IReduxBaseAction {
   type: EReduxActionTypes.DISCONNECT_FROM_ZOOM;
   data: any;
}

export interface IReduxGetExternalAccountInfoAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_EXTERNAL_ACCOUNT_INFO;
   data: any;
}

export interface IReduxCheckExternalAccountAction extends IReduxBaseAction {
   type: EReduxActionTypes.CHECK_EXTERNAL_ACCOUNT;
   data: any;
}

export interface IReduxCreateWebinarAction extends IReduxBaseAction {
   type: EReduxActionTypes.CREATE_WEBINAR;
   data: any;
}
export interface IReduxUpdateWebinarAction extends IReduxBaseAction {
   type: EReduxActionTypes.UPDATE_WEBINAR;
   data: any;
}
export interface IReduxUpdateAction extends IReduxBaseAction {
   type: EReduxActionTypes.UPDATE;
   data: any;
}

export interface IReduxGetWebinarAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_WEBINAR | EReduxActionTypes.LOADING_MY_WEBINARS;
   data: any;
}
export interface IReduxGetCalendarAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_CALENDAR;
   data: any;
}
export interface IReduxGetWebinarByIdAction extends IReduxBaseAction {
   type: EReduxActionTypes.GET_WEBINARBYID;
   data: any;
}
export interface IReduxChangeStatusAction extends IReduxBaseAction {
   type: EReduxActionTypes.CHANGE_STATUS;
   data: any;
}

export interface IReduxClearCheckAction extends IReduxBaseAction {
   type: EReduxActionTypes.CLEAR_CHECK;
   data: any;
}
export interface IReduxCheckAvailabilityAction extends IReduxBaseAction {
   type: EReduxActionTypes.CHECK_WEBINAR_TIME_AVAILABILITY;
   data: any;
}

export interface IReduxRefreshZoomTokenAction extends IReduxBaseAction {
   type: EReduxActionTypes.REFRESH_ZOOM_TOKEN;
   data: any;
}


export function clearCheck(): ThunkAction<Promise<IReduxClearCheckAction>, IReduxWebinarState, undefined, IReduxClearCheckAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxClearCheckAction>) => {
      return dispatch({
         type: EReduxActionTypes.CLEAR_CHECK,
         data: false
      });

   }
}

export function checkAvailability(webinarToCheck: any): ThunkAction<Promise<IReduxCheckAvailabilityAction>, IReduxWebinarState, undefined, IReduxCheckAvailabilityAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxCheckAvailabilityAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let webinarToBeCreatedStatus = await axiosInstance.post(baseUrl + `webinars/checkAvailability`, webinarToCheck).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.CHECK_WEBINAR_TIME_AVAILABILITY,
         data: { isValid: webinarToBeCreatedStatus }
      });

   }
}

export function createWebinar(webinar: any, filters: any, listCourses: number[], courses: any): ThunkAction<Promise<IReduxCreateWebinarAction>, IReduxWebinarState, undefined, IReduxCreateWebinarAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxCreateWebinarAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let webinarCreated = await axiosInstance.post(baseUrl + `webinars/create`, webinar).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.CREATE_WEBINAR,
         data: { webinar: webinarCreated, filters: filters, listCourses: listCourses, courses: courses }
      });

   }
}
export function getWebinars(filters: any): ThunkAction<Promise<IReduxGetWebinarAction>, IReduxWebinarState, undefined, IReduxGetWebinarAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxGetWebinarAction>) => {
      dispatch({ type: EReduxActionTypes.LOADING_MY_WEBINARS, data: undefined })
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let listWebinars = await axiosInstance.post(baseUrl + `webinars/filter`, filters).then(res => { return res.data }).catch(err => {return []});
      return dispatch({
         type: EReduxActionTypes.GET_WEBINAR,
         data: listWebinars
      });

   }
}
export function getCalendar(value: any): ThunkAction<Promise<IReduxGetCalendarAction>, IReduxWebinarState, undefined, IReduxGetCalendarAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxGetCalendarAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let calendar = await axiosInstance.get(baseUrl + `webinars/generate-calendar/` + value).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.GET_CALENDAR,
         data: calendar
      });

   }
}
export function getWebinarById(value: any, where: string): ThunkAction<Promise<IReduxGetWebinarByIdAction>, IReduxWebinarState, undefined, IReduxGetWebinarByIdAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxGetWebinarByIdAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let webinar = await axiosInstance.get(baseUrl + `webinars/` + value).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.GET_WEBINARBYID,
         data: { webinar: webinar, where: where }
      });

   }
}

export function changeStatus(status: any, idWebinar: any): ThunkAction<Promise<IReduxChangeStatusAction>, IReduxWebinarState, undefined, IReduxChangeStatusAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxChangeStatusAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let stat = await axiosInstance.put(baseUrl + `webinars/webinar/` + idWebinar + `/` + status).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.CHANGE_STATUS,
         data: stat
      });

   }
}

export function updateWebinar(webinar: any, idWebinar: any): ThunkAction<Promise<IReduxUpdateWebinarAction>, IReduxWebinarState, undefined, IReduxUpdateWebinarAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxUpdateWebinarAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL9;
      let stat = await axiosInstance.put(baseUrl + `webinars/update/` + idWebinar, webinar).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.UPDATE_WEBINAR,
         data: stat
      });

   }
}
export function update(webinar: any): ThunkAction<Promise<IReduxUpdateAction>, IReduxWebinarState, undefined, IReduxUpdateAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxUpdateAction>) => {
      return dispatch({
         type: EReduxActionTypes.UPDATE,
         data: webinar
      });

   }
}


export function checkExternalAccount(webinar: any): ThunkAction<Promise<IReduxCheckExternalAccountAction>, IReduxWebinarState, undefined, IReduxCheckExternalAccountAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxCheckExternalAccountAction>) => {
      let state = await axiosInstance.get(process.env.REACT_APP_BASE_URL9 + `externalAccount/update/` , webinar).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.CHECK_EXTERNAL_ACCOUNT,
         data: state
      });

   }
}


export function getZoomAccountDetails(id: number): ThunkAction<Promise<IReduxGetExternalAccountInfoAction>, IReduxWebinarState, undefined, IReduxGetExternalAccountInfoAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxGetExternalAccountInfoAction>) => {
      let accountInfo= await axiosInstance.get(process.env.REACT_APP_BASE_URL9 + `externalAccount/getAccountInfoForDirector/`+id ).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.GET_EXTERNAL_ACCOUNT_INFO,
         data: accountInfo
      });

   }
}


export function disconnectFromZoom(id: number): ThunkAction<Promise<IReduxGetDisconnectFromZoomAction>, IReduxWebinarState, undefined, IReduxGetDisconnectFromZoomAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxGetDisconnectFromZoomAction>) => {
      let accountInfo= await axiosInstance.get(process.env.REACT_APP_BASE_URL9 + `externalAccount/disconnect/`+id ).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.DISCONNECT_FROM_ZOOM,
         data: accountInfo
      });

   }
}



export function addExternalAccount(account: any): ThunkAction<Promise<IReduxGetAddExternalAccountAction>, IReduxWebinarState, undefined, IReduxGetAddExternalAccountAction> {
   return async (dispatch: ThunkDispatch<IReduxWebinarState, undefined, IReduxGetAddExternalAccountAction>) => {
      let accountInfo= await axiosInstance.post(process.env.REACT_APP_BASE_URL9 + `externalAccount/createOrUpdateExternalAccount`, account).then(res => { return res.data }).catch(err => console.error(err));
      return dispatch({
         type: EReduxActionTypes.ADD_EXTERNAL_ACCOUNT,
         data: accountInfo
      });

   }
}