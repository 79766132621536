import { ComponentProps, FC } from "react"
import { connect } from "react-redux";
import { AppState } from "../../../global-state/store/root-reducers";
import { clearAllData } from "../../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import WebinarTrainingComponent from "../../../components/list-cards-webinars/path-list-card-component";
const WebinarTrainingPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = () => { 
    return (
             <>
                <WebinarTrainingComponent />
            </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearAllData }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(WebinarTrainingPage);