import { useTranslation } from "react-i18next"

const DefaultTtitle  = (props:any) => {
    const {t} = useTranslation()
    const setDefaultTitle = (title:any,type:any) => {
        if(title===''){
          switch(type){
            case 'COURSE':
              return (t('titleCourseForm'))
            case 'PATH':
              return (t('InitialPathName'))
            case 'SESSION':
              return (t('SessionTitleCreation'))
        } 
      } else {
          return title
        }
    }
        return (
            <>
                {setDefaultTitle(props?.title,props?.type)}
            </>
        )
    }

export default DefaultTtitle;