import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import "../../translations/i18n";
import CardActivityDirector from "./card-director/card-activity-director";
import CardActivityProfLoading from "./card-prof/card-activity-prof-loading";
import CardInitialActivity from "./initial-card/card-initial-activity";
import { getSubscriptions } from "../../global-state/actions/app-container-actions";
import { getTeamNumber } from "../../global-state/actions/professor-actions";
import { getListCoursesForDirecteurApi, getFollowUpActivityApi, getPersonActivityApi, getTrainingActivityApi, getMarkMedianActivityApi } from "../../services/activity.service";
import { isArray } from "lodash";

const DirectorActivitiesCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState(false);
    const [courseList, setCourseList] = useState<any>();
    const [connectedList, setConnectedList] = useState<any>({ "studentNbre": 0, "lastDays": 0 });
    const [followers, setFollowers] = useState<number>(0);
    const [appFile, setAppFile] = useState<number>(0);
    const [formation, setFormation] = useState<number>(0);
    const [certif, setCertif] = useState<number>(0);
    const [ville, setVille] = useState<any[]>([]);
    const [refused, setRefused] = useState<number>(0);
    const [newCandidate, setNewCandidate] = useState<number>(0);
    const [newInscribed, setNewInscribed] = useState<number>(0);
    const [year, setYear] = useState<any[]>([]);
    const [visit, setVisit] = useState<number>(0);
    const [countSchoolActifStudent, setCountSchoolActifStudent] = useState<number>(0);
    const [avg, setAvg] = useState<number>(0);
    const [malePour, setMalePour] = useState<number>(0);
    const [femalePour, setFemalePour] = useState<number>(0);
    const [newCourses, setNewCourses] = useState<number>(0);
    const [profs, setProfs] = useState<number>(0);
    const [newProfs, setNewProfs] = useState<number>(0);
    const [markMedian, setMarkMedian] = useState<number>(0);

    const getFollowUpActivity = async (list: any) => {
        const result = await getFollowUpActivityApi(list, props.user?.idSchool, props.user?.idPerson);
        setConnectedList(result.connectedList);
        setFollowers(result.followers);
        setAppFile(result.appFile);
        setFormation(result.formation);
        setCertif(result.certif);
        setVille(result.statistic.ville);
        setRefused(result.refused);
        setNewCandidate(result.newCandidate);
        setNewInscribed(result.newInscribed);
        setYear(result.year);
    };

    const getPersonActivity = async (list: any) => {
        const result = await getPersonActivityApi(props.user?.idSchool, list, props.user?.idPerson);
        setVisit(result.visit);
        setCountSchoolActifStudent(result.countSchoolActifStudent);
        setAvg(result.genderInfos.avg);
        setMalePour(result.genderInfos.malePour);
        setFemalePour(result.genderInfos.femalePour);
    };

    const getTrainingActivity = async (list: any) => {
        const result = await getTrainingActivityApi(list, props.user?.idSchool, props.user?.idPerson);
        setNewCourses(result.newCourses);
        setProfs(result.profs);
        setNewProfs(result.newProfs);
    };

    const getMarkMedianActivity = async (list: any) => {
        const result = await getMarkMedianActivityApi(list);
        setMarkMedian(result.markMedian);
    };


    const getListCourses = async (schoolId: any) => {
        const result = await getListCoursesForDirecteurApi(schoolId);
        setCourseList(result);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getListCourses(props?.user?.idSchool);
            setLoading(false);
        };
        fetchData();
    }, [props?.user?.idSchool]);

    useEffect(() => {
        if (loading && courseList.length > 0) {
            const fetchActivities = async () => {
                await getMarkMedianActivity(courseList);
                await getPersonActivity(courseList);
                await getTrainingActivity(courseList);
                await getFollowUpActivity(courseList);
                setLoading(false);
            };
            fetchActivities();
        }
    }, [loading, courseList]);

    return (
        <div className="container-fluid p-0">
            <div className="d-flex flex-column justify-content-center">
                {(isArray(courseList) && !loading && courseList.length === 0) ? (
                    <CardInitialActivity courseList={courseList} />
                ) : (
                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between">
                        {loading || !courseList ? (
                            <CardActivityProfLoading />
                        ) : (
                            <CardActivityDirector
                                courseList={courseList}
                                connectedList={connectedList}
                                followers={followers}
                                appFile={appFile}
                                formation={formation}
                                certif={certif}
                                ville={ville}
                                refused={refused}
                                newCandidate={newCandidate}
                                newInscribed={newInscribed}
                                year={year}
                                countSchoolActifStudent={countSchoolActifStudent}
                                avg={avg}
                                malePour={malePour}
                                femalePour={femalePour}
                                visit={visit}
                                newCourses={newCourses}
                                profs={profs}
                                newProfs={newProfs}
                                markMedian={markMedian}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myCourses: state.courseReducer.myCourses,
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    user: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getSubscriptions,
            getTeamNumber,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DirectorActivitiesCardComponent);