import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentPathSectionComponent from "../../components/preview-course-components/content-path-section-component";
import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import { sendIpAddressCourse, getOfferCourse, getContent, getRegistrationForm, checkFollowStudentCourse, updateShow, clearAllData, getOfferPricingCourse } from '../../global-state/actions/course-actions';
import { clearFeedbacks, getAllFeedbacks } from '../../global-state/actions/feedback-actions';
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";
import DescPreviewCourse from "../../components/preview-course-components/desc-preview-course";
import { getProfDataByDirector } from "../../global-state/actions/auth-actions";
import { getTrainingById } from "../../global-state/actions/training-actions";
import axiosInstance from "../../helpers/interceptors";

const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]
const PreviewPathPageFromProf: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const [courseInfo, setCourseInfo] = useState<any>();
    const location = useLocation();
    const [price, setPrice] = useState<any>()
    const [ips, setIps] = useState('')
    const [id, setId] = useState(props.pathInfoFromProf?.id)
    const [show, setShow] = useState()
    const [trainingLevel, setTrainingLevel] = useState<any>()
    const [contentWidth, setContentWidth] = useState<any>()

    useEffect(() => {

        if (props?.courseInfo === undefined || props?.courseInfo === null || (props?.courseInfo && props?.courseInfo?.id !== Number(id))) {
            let filter = {
                trainingType: "PATH",
                trainingId: Number(id)
            }
            props?.getContent(filter);
            props?.getOfferCourse(Number(id));
        } else {
            setCourseInfo(props?.courseInfo);
        }
        if (location.pathname.includes("preview")) {
            fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=b6078e4a8db0480488ea97bd57ee755f").then(response => response.json())
                .then(dataInfo => setIps(dataInfo.ip_address));

        }
        const data = async () => {
            await props?.clearFeedbacks();
            await props?.getAllFeedbacks(Number(id), 0, 'refresh');
        }
        data();
        return () => {
            props?.clearFeedbacks();
        };
    }, []);

    useEffect(() => {
        if (props?.connectedUser?.role?.includes("role_student")) {
            props?.checkFollowStudentCourse(id, props?.connectedUser?.idPerson, props?.courseInfo?.type)

        }

    }, []);
    useEffect(() => {
        setShow(props?.show)

    }, [props?.show]);

    useEffect(() => {
        if (props?.courseInfo) {
            props?.courseInfo?.orders?.sort((a: any, b: any) => {
                if (a.index > b.index) {
                    return 1;
                } else if (a.index < b.index) {
                    return -1
                } else {
                    return 0
                }
            })
            setCourseInfo(props?.courseInfo);
        }
    }, [props?.courseInfo])
   


    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    const avisClicked = () => {
        document.getElementById('clickLink')?.click();
    }

    useEffect(() => {
        if(props?.courseInfo!=undefined){
            const levelFouned = levels.find(obj => obj.value === props?.courseInfo?.level);
            setTrainingLevel((levelFouned) ? levelFouned?.label : '...');
        }

        if (
            props && 
            props.courseInfo && 
            props.courseInfo.prerequisite !== undefined && 
            props.courseInfo.prerequisite !== null && 
            props.courseInfo.skills !== undefined && 
            props.courseInfo.skills !== null && 
            props.courseInfo.skills.length > 0 && 
            props.courseInfo.objectives !== undefined && 
            props.courseInfo.objectives !== null 
          ) {
            setContentWidth("100%");
          } else {
            setContentWidth("70%");
          }   
    }, [props?.courseInfo])


    return (
        <div className={`${location.pathname.includes('/catalog/preview') ? 'mt-5' : ''}`}>
            <a href="#listFeedbacks" id="clickLink" style={{ display: 'none' }}></a>
            <DescPreviewCourse 
                avisClicked={avisClicked} 
                courseForm={props.pathInfoFromProf}
                trainingOffer={props.trainingOfferFromPath}
                owner={courseInfo?.professor.firstName + " " + courseInfo?.professor.lastName} 
                price={price} 
                type={courseInfo?.type}
                time={convertTime(courseInfo?.duration)} 
                resume={props?.courseInfo?.resume}
                objectives={props?.courseInfo?.objectives}
                prerequisite={props?.courseInfo?.prerequisite}
                trainingLevel={trainingLevel}
                title={props?.courseInfo?.title}
                media={props?.courseInfo?.linkImg}
                video={props?.courseInfo?.video}
            />
            
            { (props?.courseInfo?.skills != 0) &&    <SkillsSectionComponent 
            skills={courseInfo?.skills}
            objectives={props?.courseInfo?.objectives}
            prerequisite={props?.courseInfo?.prerequisite}
             />}
            <ContentPathSectionComponent exam={courseInfo?.exams[0]} orders={courseInfo?.subjectsByPath} time={convertTime(courseInfo?.duration)} title={courseInfo?.title} courseInfo={courseInfo} width={contentWidth} />
            <SchoolProfSectionComponent profData={courseInfo?.professor} photoProf={courseInfo?.professor.photo} />
            <HowToWorkSectionComponent courseForm={courseInfo} price={price} courseInfo={courseInfo} show={show} />
            <div id="listFeedbacks">
                <ListFeedbackSectionComponent />
            </div>
            <div className='suivre' style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px", backgroundColor: '#fff' }}>
                <button
                    className="btn-Primary large"
                    disabled
                > suivre ce {courseInfo?.type === "COURSE" ? "COURS" : courseInfo?.type}</button>
            </div>
        </div>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    priceCourse: state.courseReducer.priceCourse,
    courseInfo: state.courseReducer.training,
    show: state.courseReducer.show,
    courseTarificationForm: state.courseReducer.courseTarificationForm

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    clearAllData,
    sendIpAddressCourse,
    getOfferCourse,
    getContent,
    checkFollowStudentCourse,
    clearFeedbacks,
    getAllFeedbacks,
    updateShow,
    getRegistrationForm,
    getOfferPricingCourse,
    getProfDataByDirector,
    getTrainingById,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPathPageFromProf);