import { FC, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getCatalog } from "../../global-state/actions/catalog-actions";
import { getStudent } from '../../global-state/actions/profile-actions';
import { getApplicationFile, getRegistrationForm, getFollow, getStudentInfoById } from '../../global-state/actions/course-actions';
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import "../../translations/i18n";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import StudentTableWithExpandElement from '../../elements/table/table-student-with-expand-element';
import axiosInstance from "../../helpers/interceptors";
interface Props {

  listInscribed: any
  goToregistration: any,
  goToCourse: any

}
const CoursSuiviComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const { t } = useTranslation();
  const dateFormat = require('dateformat');
  const [data, setData] = useState<any>(null);
  const history = useHistory();
  const [trainingOffers, setTrainingOffers] = useState<any>()
  const listInscribed= useMemo(() =>  props?.listInscribed, [props?.listInscribed])
  const tabHeaders = [
    { title: t('courseTable'), sorted: true, classNames: "w-30", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('Professeur_1'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    { title: t('inscriptionTitle'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('dernVisite'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    { title: t('paiement'), sorted: true, classNames: "w-34", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('progressTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
  ]



  const getinscribedTrainingOffers =(list:any)=>{
    let ids: string[] = []
    const baseUrl3 = process.env.REACT_APP_BASE_URL11
    ids = props?.listInscribed?.filter((training: any) => training?.id).map((training: any) => training?.id.toString());
    if (ids?.length > 0) {
      axiosInstance.post(baseUrl3 + `offers/getOffersbyTrainingsIds/`, ids).then(res => {
        setTrainingOffers(res.data);

      })
    }
  }

  useEffect(() => {
    if(listInscribed!=undefined && listInscribed!=null && listInscribed?.length!=0){
      getinscribedTrainingOffers(listInscribed)
    }
}, [listInscribed, props?.listInscribed])



  const getType =(type:any)=>{
    switch(type){
      case 'COURSE':
        return "Cours";
      case 'PATH':
        return "Parcours";
      default:
        return "Session";
    }
  }

  const formatData =  ()=>{
    let list = {
      headers: tabHeaders,
      body: props?.listInscribed?.map((follow: any, index: number) => {
        const status = follow?.followUp?.statusList?.find((el: any) => new Date(el?.creationDate).getTime() === Math.max(...follow?.followUp?.statusList?.map((e: any) => new Date(e.creationDate))))
        const date = (Math.ceil((new Date().getTime() - new Date(follow?.followUp?.lastVisit).getTime()) / (1000 * 3600 * 24))) + " " + t('joursVisit')
        let trainingOffer: any = trainingOffers?.filter((offer: any) => offer?.idTraining === follow?.id)[0]
        let paymentOffer: string = "Gratuit"
        trainingOffer?.pricings?.map((pricing: any) => {

          if (pricing?.period === "ONESHOT" && pricing?.nbPeriod === null && pricing?.price != 0) {
            paymentOffer = "Unique"
          }
          if (pricing?.period === "MONTH" && pricing?.nbPeriod != null) {
            paymentOffer = "Par facilité"
          }
          if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing?.period) && pricing?.nbPeriod == null) {
            paymentOffer = "Abonnement"
          }
        });

        

        return {
          row: {
            student: follow,
            actionName: "goToPath",
            classNames: "table-white relative",
            columns: [
              {
                content: <div className="d-flex align-items-center">
                  <span className="ms-2">{follow?.title}</span></div>, classNames: "d-flex  px-0", actionName: ''
              },

              {
                content: <span style={{paddingLeft:"20px"}}>{getType(follow?.type)}</span>,
                classNames: `text-center `,
                actionName: ''
              },

              { content:  <span >{follow?.personName}</span>, classNames: "text-center align-tex-center justify-content-between", actionName: '' },
              { content: dateFormat(status?.creationDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
              {
                content: (follow?.idRegistrationForm !== undefined && follow?.idRegistrationForm !== 0 && follow.idRegistrationForm !== null) ? <button style={{ position: 'absolute', top: '48%' }} className={`btn-Document accepted`}>
                  <span className="material-icons-outlined">description</span>
                </button> : <button className="btn btn-Document" disabled><span className="material-icons-outlined" >highlight_off</span></button>,
                classNames: `text-center with-icon relative-position d-flex `,
                actionName: 'goToRegistration'
              },
              {
                content: follow?.followUp?.lastVisit === null ? <span style={{justifyContent:"center" , alignItems:"center"}}>-</span> : date,
                classNames: " mx-auto align-items-center justify-content-center",
                actionName: ''
              },
              {
                content: paymentOffer,
                classNames: `text-center `,
                actionName: ''
              },
              {
                content: <span>{follow?.followUp?.progressTraining?.progress}<span className="neutral-3">{" " + "%"}</span></span>,
                classNames: `text-center `,
                actionName: ''
              },
            ]
          }
        }
      })
    }
    
    return list;
  }


  useEffect(() => {
    let formatted = formatData()
    setData(formatted)
  }, [props?.listInscribed, listInscribed , trainingOffers])

  



  const doAction = (value: any) => {
    if (value?.action === 'goToRegistration' && value?.row?.student?.idRegistrationForm != null) {
      goToregistration(value.row.student);
    }

  }
  const goToregistration = async (Student: any) => {
    props?.clearHistoryArray()
    await props?.getStudentInfoById(Student?.followUp?.studentId, props?.schoolInfo?.id);
    await props?.getApplicationFile(Student?.followUp?.studentId, Student?.idRegistrationForm);
    await props?.getRegistrationForm(Student.idRegistrationForm);
    await props?.getFollow(Student?.followUp?.id);
    await props?.getStudent(Student);
    props?.redirect(t('Etudiant_1'), `${returnStartPathUrl()}/students/all`)
    props?.redirect(props?.studentInfo?.firstName + " " + props?.studentInfo?.lastName, `${returnStartPathUrl()}/students/profile/student/${Student?.followUp?.studentId}`)
    props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/students/registration/${Student?.followUp?.id}/${Student?.followUp?.studentId}/${Student.idRegistrationForm}`)
    history.push({ pathname: `${returnStartPathUrl()}/students/registration/${Student?.followUp?.id}/${Student?.followUp?.studentId}/${Student.idRegistrationForm}`, state: { data: Student } })
  }

  return (
    <StudentTableWithExpandElement 
    data={data} 
    doAction={(value: any) => { doAction(value) }} 
    trainingOffers={trainingOffers}
    />
  )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
  history: ownProps?.history,
  actualStudent: state.detailsProfileReducer.actualStudent,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  studentInfo: state.courseReducer.studentInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCatalog,
      getStudentInfoById, getApplicationFile, getRegistrationForm, getFollow, getStudent,
      redirect, clearHistoryArray
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(CoursSuiviComponent);