import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { setActualTab } from '../../global-state/actions/professor-actions';
import "../../translations/i18n";
import { patchGroup } from '../../global-state/actions/course-actions';
import { patchGroup as patchTrainingGroup } from '../../global-state/actions/training-actions';
import TableElement from '../../elements/table/normal-table-element';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import * as _ from 'lodash';
import { getGroupBySchool } from '../../services/groupe.student.service';

const TableAutorisationsTrainingComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();

    const [groups, setGroups] = useState<any[]>(props?.training?.groups)

    const [allData, setAllData] = useState<any>(null);
    const[groupList,setGroupList]= useState<any[]>([])
    const generalHeaders = [
        { title: t('groupNameTable'), sorted: true, classNames: "w-55", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Etudiants'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('autorisationTrainingTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    ]

    const updateAuthorizations = useCallback((id: any) => {
        let groupIds = []
        if (groups?.find((e: number) => e === id)) {
            groupIds = (props?.training.groups.filter((e: number) => e !== id))
        } else {
            groupIds = [...groups, id]
        }

        setGroups(groupIds);

    }, [groups])

    useEffect(()=>{
        getGroupBySchool(props?.schoolInfo?.id).then((response)=>{
            setGroupList(response)

        })
    }, [])
    useEffect(() => {
        props?.setActualTab(props?.lastTabClicked);
    }, [props?.lastTabClicked])
    useEffect(() => {
        if (Array.isArray(props?.training?.groups))
            setGroups(props?.training?.groups)

    }, [props?.training?.groups])

    useEffect(() => {

        if (props?.training?.groups.filter((x: number) => !groups.includes(x)).concat(groups.filter((x: number) => !props?.training?.groups.includes(x))).length) {
           
            if (props?.training?.type === "COURSE") {
                props?.patchGroup({ ...props?.training, groups });
            }
            else { props?.patchTrainingGroup({ ...props?.training, groups }); }
        }

    }, [groups])

    useEffect(() => {

        if (Array.isArray(groupList) && Array.isArray(groups)) {
            const list = {
                headers: generalHeaders,
                body: groupList?.map((group: any, i: number) => {
                    let personListId = _.uniq([group?.personList?.map((obj: any) => { return obj.id }), group?.childsList?.map((subGroup: any) => { return subGroup?.personList?.map((p: any) => { return p.id }) })].flat(Infinity))
                    return {
                        row: {
                            group: group,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: group?.label, classNames: "", actionName: '' },
                                { content: personListId.length + ' ' + t('Etudiants').toLowerCase(), classNames: "text-center", actionName: '' },
                                {
                                    content: <>
                                        <input dir="ltr"type="checkbox" className="input-style" checked={groups?.find((e: number) => e === group?.id) ? true : false} onChange={() => { updateAuthorizations(group?.id) }} id={i.toString()} />

                                        <div className="toggle-container authorisation">
                                            <label className="label-style authorisation" htmlFor={i.toString()}>
                                                <span className="on authorisation-on">{t('autorzedStatus')}</span>
                                                <span className="off authorisation-off">{t('unauthorized')}</span>
                                                <input dir="ltr"type="text" style={{ display: 'none' }} />
                                            </label>
                                        </div>
                                    </>
                                    , classNames: `text-end d-flex justify-content-end`, actionName: ''
                                }
                            ]
                        }
                    }
                })
            }
            setAllData(list);
        }
    }, [groups])

    const doAction = (value: any) => {
        if (value.action === 'affectAction') {

        } else if (value.action === 'disaffectAction') {

        }
    }

    return (<div className="col-md-12 mt-2">
        {allData?.body?.length!==0 && <>

            <TableElement data={allData} doAction={(value: any) => { doAction(value) }} />
        </>
        }
        {(allData?.body?.length === 0) &&
            <div className="w-100 text-center mt-5">
                <EmptyListElement lines={[t('noGroupFound')]} />
            </div>
        }
    </div>
    );
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    lastTabClicked: state.profReducer.lastTabClicked,
    training: ownProps?.training,
    schoolInfo: state.schoolInfoReducer.schoolInfo, 

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            setActualTab,
            patchGroup,
            patchTrainingGroup
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(TableAutorisationsTrainingComponent);

