import { Component, ComponentProps } from 'react';
import axios from '../../../helpers/interceptors';
import { withRouter } from 'react-router-dom';
import "../../../translations/i18n";
import { getDomainFromUrl, returnStartPathUrl } from '../../../helpers/domainCheck';

interface RecoverPass {
    email: any
    validation: boolean
    loading: boolean,
    error: boolean,
    errorMessage: boolean,
}

class RecoverPassword extends Component<ComponentProps<any>, RecoverPass>  {
    baseUrl = process.env.REACT_APP_BASE_URL2;
    state: RecoverPass = {
        email: "",
        validation: true,
        loading: false,
        error: true,
        errorMessage: false
    }
    constructor(props: any) {
        super(props);
        this.validationEmail = this.validationEmail.bind(this);
    }
    signIn() {
        this.props?.history.push(`${returnStartPathUrl()}/auth/login`)
    }
    validationEmail() {
        let domain:string=getDomainFromUrl()
        let object ={
            "email":this.state.email?.toLowerCase(),
            "domain":domain
        }
        axios.post(this.baseUrl + "person/changepassword",object).then((response) => {
            if (response.data === "email invalid") {
                this.setState({ errorMessage: true })
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
                this.setState({ error: false, validation: false })
            }
        }).catch(() => {
            this.setState({ loading: false })
            this.setState({ error: false, validation: false })
        })

    }
    render() {
        const { t }: any = this.props;
        const { validation, error, loading ,errorMessage } = this.state;
        return (
            <>
                <div className="auth-close">
                    <button type="button" className="btn-QuickActions" onClick={() => this.props?.history.push(`${returnStartPathUrl()}/auth/login`)}>
                        <span className="material-icons-outlined">close</span>
                    </button>

                </div>
                <div style={{ marginTop: "15%" }}></div>
                <div className="row justify-content-center">
                    <div className="col-md-8 justify-content-center">
                        <div className="row justify-content-center">
                            <div className="col-md-9 justify-content-center ">
                                <div className="form-login row justify-content-center form">

                                    {error ?
                                        <>
                                            <div className="col-md-12 text-center">
                                                <span className="H3-Headline">{t('recPass')} </span>
                                            </div>
                                            <div className="col-md-12 mt-5 text-center">
                                                <span className="body-md">{t('reinMail')}</span>

                                            </div>
                                            <div style={{ marginBottom: "10%", maxWidth: "384px", maxHeight: "48px" }}></div>
                                            <div className="col-md-8 justify-content-center">
                                                <label className="form-title">{t('emailLogin')} </label>
                                                <input dir="ltr" type="text" placeholder={t('emailLogin')} className={validation ? "form-control input text-default" : "form-control input text-default error"} value={this.state.email} onChange={(event) => { this.setState({ email: event.target.value.trim(), validation: true,errorMessage:false  }) }} />
                                                {errorMessage &&   <div className='mt-1'>
                                                    <span className='error-input'>Aucun compte n’a été crée avec cette adresse mail. Veuillez vérifier votre adresse mail.</span>
                                                </div>}

                                            </div>
                                            <div className="col-md-12 mt-5 text-center" >
                                            {!loading ? <button type="button" className="btn-Primary large" disabled={(this.state.email === "")} onClick={() => { this.validationEmail() }} style={{ maxWidth: "283px", maxHeight: "48px" }}>
                                                    <span >{t('reinBUttonPass')}</span>
                                                </button>
                                                    :
                                                    <button type="button" className="btn-Primary large loaddata " style={{ width: "283px", height: "48px" }} >
                                                        <div className="threedots-large-Animation">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    </button>}
                                            </div>
                                            <div style={{ marginBottom: "15%" }}></div>
                                            <div className="col-md-12 ">
                                                <div className="row justify-content-center">
                                                    <div className="col-md-12 text-center">
                                                        <p onClick={() => { this.props?.history.push(`${returnStartPathUrl()}/auth/login`) }} className="link-text" style={{ textDecoration: 'none', cursor: 'pointer' }}>{t('alreadyHaveAcc')}</p>
                                                    </div>
                                                    <div style={{ marginBottom: "2%" }}></div>

                                                    <div className="col-md-12 text-center">
                                                        <button type="button" className="btn-Secondary large" onClick={() => this.signIn()}>
                                                            {t('cnxButton')}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <>
                                            <div style={{ marginBottom: "10%", paddingTop: "200px" }}></div>
                                            <div className="col-md-12 text-center">
                                                <span className="material-icons-outlined" style={{ fontSize: "41px", color: "#9DDBA9" }}>check</span>
                                            </div>
                                            <div className="col-md-12 text-center" style={{ paddingTop: "27.67" }}>
                                                <span className='H3-Headline' style={{ fontSize: "24px", lineHeight: "32px" }}>Email de réinitialisation envoyé</span>
                                            </div>
                                            <div className="col-md-12 text-center" style={{ paddingTop: "27.67" }}>
                                                <span className='body-md ' style={{ textAlign: 'center', alignItems: "center", color: "#5A5A5A" }}>Vérifiez votre boite mail et ouvrez le lien que vous avez reçu pour réinitialiser votre mot de passe</span>
                                            </div>
                                            <div className="col-md-12 mt-5 text-center" >
                                                <button type="button" className="btn-Primary large" onClick={() => this.props?.history.push(`${returnStartPathUrl()}/auth/login`)} style={{ maxWidth: "283px", maxHeight: "48px" }}>
                                                    <span >{t('returnToConnexionPage')}</span>
                                                </button>
                                            </div>
                                        </>


                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default withRouter((RecoverPassword));