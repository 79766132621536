import { ComponentProps, FC} from "react"
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import FooterComponent from "../../components/footer-component";
import logoSchool from '../../styles/imgs/koorsLogo.png'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { returnStartPathUrl } from "../../helpers/domainCheck";

const CancelledInvitaiton: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
    const {t} = useTranslation()
    let history = useHistory()
return(
    <>
    <div className="d-flex flex-column page-wrapper-height mx-auto justify-content-center" style={{maxWidth:'1200px', padding:"120px 48px"}}>
                <div className="d-flex align-items-center justify-content-center">
                                <img style={{ height:"100px", width:"72px" }} src={props?.logoschool?.logo === null ? logoSchool : props?.logoschool?.logo} alt="logo school" />
                </div>
                        <div className="d-flex flex-column align-items-center ">
                            
                            <div className="d-flex H3-Headline mt-4 pt-3">
                                <span>{t('invitationLinkExpired')}</span>
                            </div>
                            <div className="d-flex flex-column body-md justify-content-center align-items-center text-align-center" style={{paddingTop:"24px"}}>
                                <span className="d-flex justify-content-center text-align-center">{t('verifiyAccess')}</span>
                                <span className="d-flex">{t('ecole_2')}.</span>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                                <button type="button" className="btn-Primary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                                                    {t('cnxButton')}
                                                </button>
                            </div>
                        </div>
    </div>
    
    <div className="fixed-bottom">
    <FooterComponent />
    </div>
    </>
)
}

const mapStateToProps = (state: AppState) => ({
    logoschool: state.schoolInfoReducer.schoolInfo,
  });
  
export default connect( mapStateToProps, null )(CancelledInvitaiton);