import React, { FC } from "react";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import BtnAddSondage from "../../pages/sondage/BtnAddSondage";
import BtnAddSondageComponent from "./btn-add-sondage-component";


const BtnChoiceSondageComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ addSondage, questionByQnaList, sondageReducer }: any) => {
  return (
    <div className={`container-btns-choices-sondage container-btns-choices-sondage--not-empty`}>
    
      <div className="container-btn-add-sondage">
        <BtnAddSondageComponent
          fromAddSondageMultiple
          title={"Choix multiples"}
          addSondage={addSondage}
          questionByQnaList={questionByQnaList}
        />
        <BtnAddSondageComponent
          fromAddSondageCheckBox
          title={"Case à cocher"}
          addSondage={addSondage}
          questionByQnaList={questionByQnaList}
        />
        <BtnAddSondageComponent
          fromAddSondageOpenQuestion
          title={"Question ouverte"}
          addSondage={addSondage}
          questionByQnaList={questionByQnaList}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  addSondage: ownProps.addSondage,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BtnChoiceSondageComponent);
