


import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updatePath } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardClosedQuestionComponent from "./cards-questions/card-closed-question-component";
import CardOpenQuestionComponent from "./cards-questions/card-open-question-component";

const FinalExamFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
  const [listQuestions, setListQuestions] = useState<any>([]);
  const [copyListQuestions, setCopyListQuestions] = useState<any>([]);
  const { t } = useTranslation();
    
  useEffect(() => {
    if(props?.testForm !== undefined ){
      setCopyListQuestions(JSON.parse(JSON.stringify(props?.testForm?.questionByQnaList)))
    }
    setListQuestions(props?.listQuestions);
  }, [props?.listQuestions]);

  
  const saveQuestion = (object: any, index: number) => {
    props?.saveQuestion(object, index);
  }

  const addQuestion = () => {
    props?.addQuestion();
  }

  const addOpenQuestion = () => {
    props?.addOpenQuestion();
  }
   
  return (<>
    <div style={{ margin: 0 }}>

      {listQuestions?.length === 0 ? (
        <div className="grid-container mt-20" style={{ flexDirection: "column", alignItems: "center" }} >
          <EmptyListElement lines={[t('Créer_votre_premiere_question')]} >
            <div className="d-flex align-items-center">
              <button className="btn-Primary large icon-left me-3" onClick={() => addQuestion()} >
                <span className="material-icons"> add </span>
                <div className="m-auto ps-2">{t("Question_fermées")}</div>
              </button>
              <button className="btn-Primary large icon-left" onClick={() => addOpenQuestion()} >
                <span className="material-icons" > add </span>
                <div className="m-auto ps-2">{t("QUESTIONS_OUVERTES")}</div>
              </button>
            </div>
          </EmptyListElement>
        </div>

      ) : (
        <div className="mt-5" >
          {listQuestions?.map(
            (question: any, index: number) => {

              if (question?.question.type === "CQ") {
                return (
                  <div >
                    <CardClosedQuestionComponent
                      key={index}
                      saveQuestion={saveQuestion}
                      deleteQuestion={props?.deleteQuestion}
                      formQuestion={question}
                      indexQuestion={index}
                      initForm={copyListQuestions[index]}
                    />
                  </div>
                );
              } else {
                return (
                  <div >
                    <CardOpenQuestionComponent
                      indexQuestion={index}
                      key={index}
                      openQuestion={question}
                      saveOpenQuestion={saveQuestion}
                      deleteQuestion={props?.deleteQuestion}
                      initForm={copyListQuestions[index]}
                    />
                  </div>
                );
              }
            }
          )}

          <div className="mb-5" >
            <div className="d-flex -align-items-center ">
              <button className="btn-Secondary large icon-left me-3" onClick={() => addQuestion()} >  <span className="material-icons" >  add </span> <div className="m-auto ps-2">{t("Question_fermées")}</div> </button>
              <button className="btn-Secondary large icon-left " onClick={() => addOpenQuestion()} >  <span className="material-icons" style={{ marginRight: "8px" }} >  add </span> <div className="m-auto ps-2">{t("QUESTIONS_OUVERTES")}</div> </button>
            </div>
          </div>

        </div>

      )}

    </div>

  </>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ updatePath, }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  listHistory: state.historyReducer.listHistoryBreadCrumb,
  addQuestion: ownProps?.addQuestion,
  addOpenQuestion: ownProps?.addOpenQuestion,
  deleteQuestion: ownProps?.deleteQuestion,
  saveQuestion: ownProps?.saveQuestion,
  listQuestions: ownProps?.listQuestions,
  testForm: state.courseReducer.testForm,
});
export default connect(mapStateToProps, mapDispatchToProps)(FinalExamFormComponent)
