import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { activateModal, closeModal, deactivateModal } from '../../../global-state/actions/modal-actions';

import TableScrollElement from '../../../elements/table/table-scroll-element';
import { affectStudentToGroup, disaffectStudentFromGroup } from '../../../services/groupe.student.service';
import { getStudentsPerSchoolId } from '../../../services/student.service';
import TableScrollElementStudentsGroup from '../../../elements/table/table-scroll-element-students-group';

const AddStudentToGroupModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState('');
    const [studentsList, setStudentsList] = useState<any[]>([]);
    const [data, setData] = useState<any>(null);
    const [list, setList] = useState<any>(null);
    const [groupForm, setGroupForm] = useState(props.groupForm);
    const [tabGroupManager, setTabGroupManager] = useState('all');
    const [loader, setLoader] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const moment = require('moment');

    const tabHeaders = [
        { title: t('Etudiants'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Action'), sorted: false, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-4 pe-2" },
    ];

    const dateFormat = require('dateformat');

    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr";
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            moment.defineLocale('en', { parentLocale: 'en' });
        }
    }, []);

    useEffect(() => {
        fetchStudents(page);
    }, []);

    const fetchStudents = async (pageNumber: number) => {
        setLoader(true);
        const response: any = await getStudentsPerSchoolId(props?.connectedUser?.idSchool, pageNumber, 10);
        setStudentsList(prevStudents => [...prevStudents, ...response]);
        setLoader(false);
    };

    const searchItems = (searchValue: any) => {
        setSearchInput(searchValue);
        let newdata = { ...list };
        newdata.body = newdata.body.filter((item: any) => {
            const name = item.row.student.firstName + " " + item.row.student.lastName;
            return name.toLowerCase().includes(searchValue.toLowerCase());
        });
        setData(newdata);
    };

    useEffect(() => {
        let list = {
            headers: tabHeaders,
            body: studentsList.map((student: any) => {
                return {
                    row: {
                        student: student,
                        classNames: "table-white",
                        columns: [
                            { content: student?.name, classNames: "w-30", actionName: '' },
                            {
                                content: <span> {dateFormat(student?.inscription, "dd/mm/yyyy")}
                                    {(moment(Date()).diff(moment(student?.inscription), 'days') < 3) && <span className="badge-text secondary">{" / " + moment(student?.inscription).fromNow()}</span>}
                                    {(3 <= moment(Date()).diff(moment(student?.inscription), 'days') && moment(Date()).diff(moment(student?.inscription), 'days') < 6) && <span className="badge-text warning">{" / " + moment(student?.inscription).fromNow()}</span>}
                                    {(6 <= moment(Date()).diff(moment(student?.inscription), 'days')) && <span className="badge-text danger">{" / " + moment(student?.inscription).fromNow()}</span>}
                                </span>,
                                classNames: "text-center w-30", actionName: ''
                            },
                            { content: student?.lastVisit === null ? "-" : moment(student?.lastVisit, "DD/MM/YYYY").fromNow(), classNames: "text-center w-20", actionName: "" },
                            {
                                content: (checkStudent(student.id) === false) ? <button style={{ position: "absolute", top: 12 }} type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> add </span>
                                    <div className="m-auto ps-2">{t('add')}</div></button> :
                                    <button style={{ position: "absolute", top: 12 }} type="button" className="btn-Secondary medium icon-left adding-done">
                                        <span className="material-icons-outlined"> check </span>
                                        <div className="m-auto ps-2">{t('add')}</div></button>,
                                classNames: " d-flex align-items-center justify-content-end with-icon position-relative", actionName: (checkStudent(student.id) === false) ? 'addstudentToGroup' : 'disaffectStudent'
                            },
                        ]
                    }
                }
            })
        };
        setData(list);
    }, [studentsList, groupForm?.personList]);

    useEffect(() => {
        let list = {
            headers: tabHeaders,
            body: studentsList.map((student: any) => {
                return {
                    row: {
                        student: student,
                        classNames: "table-white",
                        columns: [
                            { content: student?.name, classNames: "w-30", actionName: '' },
                            {
                                content: <span> {dateFormat(student?.inscription, "dd/mm/yyyy")}
                                    {(moment(Date()).diff(moment(student?.inscription), 'days') < 3) && <span className="badge-text secondary">{" / " + moment(student?.inscription).fromNow()}</span>}
                                    {(3 <= moment(Date()).diff(moment(student?.inscription), 'days') && moment(Date()).diff(moment(student?.inscription), 'days') < 6) && <span className="badge-text warning">{" / " + moment(student?.inscription).fromNow()}</span>}
                                    {(6 <= moment(Date()).diff(moment(student?.inscription), 'days')) && <span className="badge-text danger">{" / " + moment(student?.inscription).fromNow()}</span>}
                                </span>,
                                classNames: "text-center w-30", actionName: ''
                            },
                            { content: student?.lastVisit === null ? "-" : moment(student?.lastVisit, "DD/MM/YYYY").fromNow(), classNames: "text-center w-20", actionName: "" },
                            {
                                content: (checkStudent(student.id) === false) ? <button type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> add </span>
                                    <div className="m-auto ps-2">{t('add')}</div></button> :
                                    <button type="button" className="btn-Secondary medium icon-left adding-done">
                                        <span className="material-icons-outlined"> check </span>
                                        <div className="m-auto ps-2">{t('add')}</div></button>,
                                classNames: " d-flex align-items-center justify-content-end with-icon ", actionName: (checkStudent(student.id) === false) ? 'addstudentToGroup' : 'disaffectStudent'
                            },
                        ]
                    }
                }
            })
        };
        setData(list);
        setList(list);
    }, [groupForm.childsList]);

    const checkStudent = (idStudent: number) => {
        if (tabGroupManager === groupForm?.label || (tabGroupManager === "all" && groupForm?.childsList?.length === 0)) {
            let student = groupForm?.personList?.find((obj: any) => obj?.id === Number(idStudent));
            if (student) { return true; }
            else { return false; }
        } else {
            let actualGroup = groupForm?.childsList?.find((obj: any) => { return obj.label === tabGroupManager; });
            let student = actualGroup?.personList?.find((obj: any) => obj.id === Number(idStudent));
            if (student) { return true; }
            else { return false; }
        }
    };

    const addSudentToGroup = async (student: any) => {
        if (tabGroupManager === groupForm?.label || (tabGroupManager === "all" && groupForm?.childsList?.length === 0)) {
            let objects = {
                person: { ...student, type: 'NP' },
                idGroup: groupForm.id,
                idSchool: props?.connectedUser?.idSchool,
                subGroup: false,
                parentGroup: true,
                idGroupParent: groupForm.id,
            };
            await affectStudentToGroup(objects).then((response: any) => {
                objects.person['creationDate'] = objects.person.inscription;
                setGroupForm(response);
            });
        } else {
            let actualGroup = groupForm?.childsList?.find((obj: any) => { return obj.label === tabGroupManager; });
            let objects = {
                person: { ...student, type: 'NP' },
                idGroup: actualGroup?.id,
                idSchool: props?.connectedUser?.idSchool,
                subGroup: true,
                parentGroup: false,
                idGroupParent: groupForm.id,
            };
            await affectStudentToGroup(objects).then((response: any) => {
                objects.person['creationDate'] = objects.person.inscription;
                setGroupForm(response);
            });
        }
    };

    const disaffectstudentFromGrpoup = async (student: any) => {
        if (tabGroupManager === groupForm?.label || (tabGroupManager === "all" && groupForm?.childsList.length === 0)) {
            let objects = {
                person: { ...student, type: 'NP' },
                idGroup: groupForm.id,
                idSchool: props?.connectedUser?.idSchool,
                subGroup: false,
                parentGroup: true,
                idGroupParent: groupForm.id,
            };
            await disaffectStudentFromGroup(objects).then((response: any) => {
                setGroupForm(response);
            });
        } else {
            let actualGroup = groupForm?.childsList.find((obj: any) => { return obj.label === tabGroupManager; });
            let objects = {
                person: { ...student, type: 'NP' },
                idGroup: actualGroup.id,
                idSchool: props?.connectedUser?.idSchool,
                subGroup: true,
                parentGroup: false,
                idGroupParent: groupForm.id,
            };
            await disaffectStudentFromGroup(objects).then((response: any) => {
                setGroupForm(response);
            });
        }
    };

    const doAction = (value: any) => {
        if (value.action === 'addstudentToGroup') {
            addSudentToGroup(value.row.student);
            props?.activateModal();
        } else if (value.action === 'disaffectStudent') {
            disaffectstudentFromGrpoup(value.row.student);
            props?.activateModal();
        }
    };

    return (
        <div style={{ height: 696, background: "#F8F8FA" }}>
            <div className="modal-header mb-2" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="p-3 d-flex align-items-center justify-content-between  w-100 ">
                    <h3 className="H3-Headline">{t('addStudentToGroup')}</h3>
                    <div className="d-flex align-items-center justify-content-end py-2 ">
                        <div className="input-form-control me-2" style={{ maxWidth: '220px' }}>
                            <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} onChange={(e) => searchItems(e.target.value)} />
                            <span className="material-icons-outlined">search</span>
                        </div>
                        <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>
                </div>
            </div>

            <div className="modal-body mt-4" style={{ paddingTop: 0 }}>
                <TableScrollElementStudentsGroup
                    data={data}
                    doAction={doAction}
                    fetchMoreData={fetchStudents}
                    loader={loader}
                    page={page}
                    setPage={setPage}
                    setLoader={setLoader}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState) => ({
    listeStudents: state.profReducer.listeStudents,
    groupForm: state.modalReducer?.modalContent?.data?.groupForm,
    connectedUser: state.authReducer.connectedUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            closeModal,
            deactivateModal,
            activateModal,
        }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddStudentToGroupModalComponent);
