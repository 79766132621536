import { ComponentProps, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
const HeaderPathProgress: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [training, setTraining] = useState<any>(undefined);

    useEffect(() => {
        if (props?.currentTraining && training!==props?.currentTraining ) {
            setTraining(props?.currentTraining);
        }
    }, [props?.currentTraining])

    return (
        <div style={{ maxWidth: "1200px", margin: "auto" }}>
            <div className="mx-2">
                <h2 className="H2-Headline black-800" >{training?.title}</h2>
                <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                    <div className="d-flex me-2">
                        {training?.type === 'PATH' ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                                                    12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                                fill="#FA83EE" />
                        </svg> : <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.40042 8.53376H4.26709V25.6004C4.26709 26.7738 5.22709 27.7338 6.40042 27.7338H23.4671V25.6004H6.40042V8.53376ZM25.6004 4.26709H10.6671C9.49376 4.26709 8.53376 5.22709 8.53376 6.40042V21.3338C8.53376 22.5071 9.49376 23.4671 10.6671 23.4671H25.6004C26.7738 23.4671 27.7338 22.5071 27.7338 21.3338V6.40042C27.7338 5.22709 26.7738 4.26709 25.6004 4.26709ZM25.6004 21.3338H10.6671V6.40042H25.6004V21.3338Z" fill="#8A57CC" />
                            <line x1="9" y1="11.7998" x2="27.2" y2="11.7998" stroke="#8A57CC" strokeWidth="2" />
                            <line x1="13.3999" y1="5.6001" x2="13.3999" y2="1.6001" stroke="#8A57CC" strokeWidth="2" />
                            <line x1="23" y1="5.6001" x2="23" y2="1.6001" stroke="#8A57CC" strokeWidth="2" />
                        </svg>}
                    </div>
                    <div className="body-sm bold neutral-2">
                        {t((training?.type === 'PATH') ? 'PathGivenBy' : 'SessionGivenBy')} {training?.personName}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining
});

export default connect(mapStateToProps, null)(HeaderPathProgress);
