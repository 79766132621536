import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../global-state/store/root-reducers';
import { clearSnackBar } from "../global-state/actions/modal-actions"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cookieAlert: {
    "& .MuiAlert-icon": {
      color: "red"
    }
  }
});


function Alert(props: any) {
    return <MuiAlert elevation={6} variant="outlinedError" {...props} />;
}

const SnackBarErrors: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) =>{
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        props?.clearSnackBar()
    };
    useEffect(()=>{
        setOpen(props.showSnackBar)
    },[props.showSnackBar])
    return(
        <div>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert
                severity="error"
                    className={classes.cookieAlert}
                    onClose={handleClose}
                    style={{
                        backgroundColor:'#FFFAFA',
                        color:"black",
                        border:'1px solid #E06A6A'
                    }}
                >
                  {props?.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    message: ownProps?.message,
    showSnackBar: ownProps?.showSnackBar
});
  
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    clearSnackBar
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarErrors)

