import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCourse, getCourseTests, getRegistrationForm } from '../../global-state/actions/course-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import TableElement from '../../elements/table/normal-without-action-table-element';
import { useHistory } from 'react-router';
import { ModalIDS } from '../../components/modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { useParams } from 'react-router-dom';
import { getProfessor } from '../../global-state/actions/professor-actions';
import { getOffer, getProfStatus } from '../../global-state/actions/app-container-actions';
interface Params {
  id: string
}
const ProfsListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const dateFormat = require('dateformat');
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<Params>();
  const [profs, setProfs] = useState<any>();
  const [profsList, setProfsList] = useState<any>();
  const [filteredProfs, setFilteredProfs] = useState<any>();
  const headers = [
    { title: t('filterProfessors'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('courseTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    { title: t('Action'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
  ]
  const status = [
    { label: t('Enattente'), value: "Enattente", color: "warning" },
    { label: t('Admis'), value: "Admis", color: "success" },
    { label: t('Suspendu'), value: "Suspendu", color: "danger" },
  ]
  useEffect(() => {
    props?.getProfessor(props?.schoolInfo?.id)
  }, [props?.schoolInfo])
  useEffect(() => {


    setProfsList(props?.profs.liste)
  }, [props?.profs])
  useEffect(() => {
    if (!!props?.profs) {
      const professors = {
        headers: headers,
        body: props?.profs?.liste?.map((prof: any) => {
          const actualStatus: any = status.find(obj => obj.value.toLowerCase() === prof.status.toLowerCase());
          return {
            row: {
              prof: prof,
              actionName: "goToPath",
              classNames: "table-white",
              columns: [
                { content: prof?.name, classNames: "", actionName: '' },
                { content: prof?.lastVisit === "-" ? "-" : prof?.lastVisit + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
                { content: prof?.cours, classNames: "text-center", actionName: '' },
                { content: actualStatus.label, classNames: `text-end badge-text ${actualStatus.color}`, actionName: '' },
                {
                  content: <>{(prof.status != "Suspendu") &&
                    <button type="button" className="btn-Primary medium danger" onClick={() => { affectOtherProfButton(prof.id, prof.name) }}>
                      {t('deactivateCours')}
                    </button>
                  }
                    {(prof.status == "Suspendu") &&
                      <button type="button" className="btn-Primary medium " onClick={() => { reinstateProfButton(prof.id, prof.name) }}>
                        {t('activate')}
                      </button>
                    }

                  </>, classNames: `text-end badge-text ${actualStatus.color}`, actionName: ''
                },
              ]
            }
          }
        })


      }
      setProfs(professors);
      setFilteredProfs(professors)
    }


  }, [props?.profs]);
  const affectOtherProfButton = async (id: any, name: any) => {
    await props?.openModal({ idModal: ModalIDS.DEACTIVATE_PROF, size: "md", data: { prof: { name: name, id: id } } })

  }
  const reinstateProfButton = async (id: any, name: any) => {
    await props?.openModal({ idModal: ModalIDS.REINTEGRATE_PROF, size: "md", data: { prof: { name: name, id: id } } })
  }
  useEffect(() => {
    const prof = props?.rule.filter((rule: any) => rule.key == 'nb_prof')[0]
    if (!!prof) {
      const test = props?.profs?.liste?.filter((prof: any) => prof?.status !== "Suspendu");

      (Number(prof.value) >= test?.length) ? props?.getProfStatus(true) : props?.getProfStatus(false)
    }
  }, [props?.rule, props?.profs])

  return (
    <div className="container-fluid m-0 p-0 mx-auto" style={{ maxWidth: '1200px' }}>
      <div className="row justify-content-center">

        <div className="col-md-12 p-0 m-0 mt-5">
          {(props?.profs?.liste?.length > 0) && <>
            <TableElement height={10} data={filteredProfs}
            />
          </>
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  profs: state.profReducer.profs,
  director: state.authReducer.connectedUser,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  history: ownProps?.history,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
    getCourse, getCourseTests, getRegistrationForm, openModal, getProfStatus,
    getOffer, getProfessor
    }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfsListComponent);
