import { ComponentProps, FC, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import { createCoupon } from '../../../global-state/actions/coupon-actions';
import axiosInstance from "../../../helpers/interceptors";
import { getSchoolInfo } from '../../../global-state/actions/app-container-actions';
const DeactivateSubscriptionModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const courseData = useMemo(() => { return props?.content?.data }, [props?.content]);

    const deactivateCoupon = async () => {

        await props?.getSchoolInfo(props?.schoolInfo?.code).then(async (response: any) => {

            await axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "offer/cancelSubscription/" + courseData?.course?.id + "/" + props?.connectionUser?.idPerson + "/" + response?.data?.paymentInfo?.accountId).then(async () => {

                await props?.openModal({ idModal: ModalIDS.SUCCESS_ANNULATION_MODAL_WITH_BUTTON, size: "md", buttonText: 'RETOUR', lines: ["Coupon désactivé avec succès"], data: { contract: props?.content?.data?.contract } })
            })

        })



    }
    return (
        <>

            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000 }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' , overflow:'hidden'}}>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-start mb-1">
                                <h3 className="H3-Headline">Souhaitez-vous réellement annuler votre abonnement?</h3>
                            </div>

                            <div className="col-md-12 input-text-form-control">
                                <span className="body-xl">Si vous décidez d’annuler votre abonnement, vous aurez accès au  </span>
                                <span className="body-xl">contenus jusqu'à la fin de la période pour laquelle vous avez payé .</span>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ border: "none" }}>
                <div className="row mt-3 mb-5 me-3">
                    <div className='col-md-12 text-center justify-content-center' style={{ display: 'flex', alignItems: 'center' }}>
                        <button className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>Quitter</button>
                        <button type="button" className="btn-Primary large danger" onClick={() => { deactivateCoupon() }} >Annuler mon abonnement</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    profs: state.profReducer.profList,
    paymentMethod: state.schoolInfoReducer.paymentMethod,
    schoolInfo: state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            createCoupon,
            getSchoolInfo

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DeactivateSubscriptionModalComponent);
