import { ComponentProps, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getNextExam } from '../../global-state/actions/exam-actions';

const HeaderViewFinalExamSubmissionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const [examProps, setExamProps] = useState<any>(props?.indexExamForm);
    const [listeExams, setListExams] = useState<any>([]);
    const { t } = useTranslation();
    const [examTitle, setExamTitle] = useState(props?.examForm?.titleExam);
    const [scaleNote, setScaleNote] = useState(props?.examForm?.progressQna?.scaleNote)
    const [finalMark, setFinalMark] = useState(props?.examForm?.progressQna?.finalMark)


    useEffect(() => {
        setFinalMark(props?.examForm?.progressQna?.finalMark)
    }, [])

    useEffect(() => {
      setScaleNote(props?.examForm?.progressQna?.scaleNote)
    }, [])

    useEffect(() => {
        setFinalMark(props?.examForm?.progressQna?.finalMark)
    }, [props?.examForm?.progressQna?.finalMark])
    
    useEffect(() => {
      setScaleNote(props?.examForm?.progressQna?.scaleNote)
    }, [props?.examForm?.progressQna?.scaleNote])
    
    useEffect(() => {
        setExamTitle(props?.examForm?.titleExam);
    }, [props?.examForm])
    useEffect(() => {
        setExamProps(props?.indexExamForm);       
    }, [props?.indexExamForm])
    useEffect(() => {
        switch (props?.actualTab) {
            case "TOREVIEW":
                setListExams(props?.examsList.listExamToReview);
                break;
            case "COMPLETED":
                setListExams(props?.examsList.listExamCompleted);
                break;
            case "ALL":
                setListExams(props?.examsList.listAllExam);
                break;
        }
    }, [props?.actualTab])
    const nextExam = async (index: number) => {
        await props?.getNextExam(index);
    }

    const markColor = (a: any, b: any) => {
        return !(b / a < 0.5);
    }

    return (
        <div style={{ backgroundColor: "#FFFFFF", zIndex: 1000, position: 'fixed', top: '64px' }}>
            <div style={{ maxWidth: "1200px", margin: "auto" }}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-wrap mb-3 pt-4" >
                        <div className=" me-3">
                            <button disabled={(examProps?.index === 0)} type="button" className="btn-QuickActions me-3" onClick={() => { nextExam((examProps?.index - 1)) }} >
                                <span className="material-icons-outlined">chevron_left</span>
                            </button>
                        </div>
                        <div>
                            <button disabled={(examProps?.index === listeExams.length - 1)} type="button" className="btn-QuickActions" onClick={() => { nextExam((examProps?.index + 1)) }} >
                                <span className="material-icons-outlined">chevron_right</span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-wrap mb-3 pt-4" style={{ float: 'right' }}>

                        <h2 className="H2-Headline" >
                            {(props?.examForm?.progressQna?.status === "TOREVIEW") ? (
                                <>.../{props?.examForm?.progressQna?.scaleNote}</>
                            ) : (
                                <><span style={{ color: markColor(scaleNote,finalMark) ? "#9DDBA9" : "#E06A6A" }}>
                                    {finalMark}</span>/{scaleNote}</>
                            )}
                        </h2>
                    </div>
                </div>
                <div className="row" >
                    <h2 className="H2-Headline Black-800 mt-3">{t("EXAMEN_FINAL")}</h2>
                </div>
                <div className="row pb-4">
                    <h3 className="H3-Headline neutral-3">{examTitle}</h3>
                </div>
            </div >
        </div >
    )
}

const mapStateToProps = (state: AppState) => ({
    examsList: state.examReducer.examsList,
    actualTab: state.examReducer.actualTab,
    examForm: state.examReducer.examForm,
    indexExamForm: state.examReducer.indexExamForm,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ getNextExam, }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HeaderViewFinalExamSubmissionComponent);


