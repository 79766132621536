import io from "socket.io-client";
import { EReduxActionTypes, IReduxSocketInitAction, IReduxConnectAction } from "./../actions/socket-actions";

export interface IReduxSocketState {
    socketChat?: any
    socketInit: boolean
}

const  initialState: IReduxSocketState = {
    socketChat: undefined,
    socketInit: false
}
type SocketReducerActions = IReduxSocketInitAction  | IReduxConnectAction;
export default function (state: IReduxSocketState = initialState, action: SocketReducerActions) {
    switch (action.type) {
        case EReduxActionTypes.CONNECT_CHAT: 
        let socketCopy = {...state.socketChat};
        socketCopy = io(`${process.env.REACT_APP_BASE_URL_WS}`, {
            transports: ['websocket', 'polling'],
            forceNew: true, 
            secure: false,
            path: "/chat/socket.io",
            reconnectionAttempts: 10000000,
            timeout: 10000, 
            query: {
            apiKey: `${process.env.REACT_APP_API_KEY_KONG}`
          }
        });
          return {...state, socketChat: socketCopy};
        case EReduxActionTypes.INITIATE_SOCKET: 
          return {...state, socketInit: true};
        default:
            return state;
    }

}
