import axios from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxFeedbacksState } from '../reducers/feedback-reducer';

export enum EReduxActionTypes {
    CREATE_FEEDBACK = 'CREATE_FEEDBACK',
    GET_MY_FEEDBACK ='GET_MY_FEEDBACK ',
    GET_FEEDBACKS ='GET_FEEDBACKS',
    CLEAR_FEEDBACKS = 'CLEAR_FEEDBACKS'

}

interface IReduxBaseAction {
    type: EReduxActionTypes;
  }

export interface IReduxCreateFeedbackAction extends IReduxBaseAction {
    type: EReduxActionTypes.CREATE_FEEDBACK;
    data: any;
  }
  export interface IReduxClearFeedbackAction extends IReduxBaseAction {
    type: EReduxActionTypes.CLEAR_FEEDBACKS;
  }
export interface IReduxGetMyFeedbacksAction extends IReduxBaseAction{
    type: EReduxActionTypes.GET_MY_FEEDBACK;
    data: any;
}
export interface IReduxGetFeedbacksAction extends IReduxBaseAction{
    type: EReduxActionTypes.GET_FEEDBACKS;
    data: any;
}


export function clearFeedbacks(): ThunkAction<Promise<IReduxClearFeedbackAction>, IReduxFeedbacksState, undefined, IReduxClearFeedbackAction> {
  return async (dispatch: ThunkDispatch<IReduxFeedbacksState, undefined, IReduxClearFeedbackAction>) => {
      
      return dispatch({
          type: EReduxActionTypes.CLEAR_FEEDBACKS,
      });

  };
}
  export function createFeedback(object: any): ThunkAction<Promise<IReduxCreateFeedbackAction>, IReduxFeedbacksState, undefined, IReduxCreateFeedbackAction> {
    return async (dispatch: ThunkDispatch<IReduxFeedbacksState, undefined, IReduxCreateFeedbackAction>) => {
        const baseUrl = process.env.REACT_APP_BASE_URL8;
        const res: any = await axios.post(baseUrl+"feedback/createFeedback", object)
        .then(response =>{
            return response;
        })
        .catch(err =>{
            console.error(err)
        });
        return dispatch({
            type: EReduxActionTypes.CREATE_FEEDBACK,
            data: res.data
        });

    };
}
export function getMyFeedback(object: any): ThunkAction<Promise<IReduxGetMyFeedbacksAction>, IReduxFeedbacksState, undefined, IReduxGetMyFeedbacksAction> {
    return async (dispatch: ThunkDispatch<IReduxFeedbacksState, undefined, IReduxGetMyFeedbacksAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL8;
      let res :any=[]
      try{
        res= await axios.post(baseUrl + "feedback/myFeedback/", object)
        .then((response) => {
          if(response.data===""){
            return undefined
          }
          return response?.data
        })
      }
      catch (error:any) {
        console.error(error);
        return res;
      }


      return dispatch({
        type: EReduxActionTypes.GET_MY_FEEDBACK,
        data: await res
      })
    }
  }
  export function getAllFeedbacks(idSubject: number, page: number, action: string):ThunkAction<Promise<IReduxGetFeedbacksAction>, IReduxFeedbacksState, undefined, IReduxGetFeedbacksAction>{
    return async (dispatch: ThunkDispatch<IReduxFeedbacksState, undefined, IReduxGetFeedbacksAction>) =>{
      const baseUrl = process.env.REACT_APP_BASE_URL8;
      const baseUrlPerson= process.env.REACT_APP_BASE_URL2;
     let feedbacks = await axios.post(baseUrl+"feedback/all/"+idSubject, {page}).then(res =>{return res.data })
     .catch(err=>{return{feedbacks:[]}})
    
      let listPersonId: any =feedbacks.feedbacks.filter((filter: any) => filter.userStatus=== 'USER').map((obj: any) =>{return obj.idPerson})
      const listPerson = await axios.post(baseUrlPerson+"person/student", listPersonId) .then(response=>{ return response.data})
       
         
      feedbacks['feedbacks']=feedbacks.feedbacks.map((obj: any) => {
        let idPer= obj.idPerson;
        const person = listPerson.find((personItem: any) => personItem.id === Number (idPer));
        
        if(person) {
          obj.idPerson= person;
        }
        return obj;
      })
      
     
      return dispatch({
        type: EReduxActionTypes.GET_FEEDBACKS,
        data: {feedbacks, concatAction: action}
      })

    }
  }

  