import SubtitleUploaded from "../../components/card-uploads/subtitle-uploaded-component"

interface IGetSubtitleUploaded {
    active:boolean,
    display_language: string,
    hls_link: string,
    hls_link_expires_time: number,
    id: number,
    language: string,
    link: string,
    link_expires_time: number,
    name: string,
    type: string,
    uri: string
}
export const displaySubtitleUploaded: any = {
    isChapter: (
        getChapterSubtitleUploaded: IGetSubtitleUploaded[], 
        b: IGetSubtitleUploaded[] ,
        c: IGetSubtitleUploaded[] ,
        d: IGetSubtitleUploaded[], 
        idVideo: string, 
        getSubtitleUploaded: () => void
    ) => {

        if(getChapterSubtitleUploaded?.length > 0) {
            return getChapterSubtitleUploaded?.map((file:any) => (
                <SubtitleUploaded 
                    fileName={file.name} 
                    language={file.display_language} 
                    idVideo={idVideo} 
                    idSubtitle={file.id} 
                    getSubtitleUploaded={getSubtitleUploaded}
                />
            ))
        }
        return null
    },
    isSession: (
        a: IGetSubtitleUploaded[], 
        getSessionSubtitleUploaded: IGetSubtitleUploaded[], 
        c: IGetSubtitleUploaded[], 
        d: IGetSubtitleUploaded[], 
        idVideo: string, 
        getSubtitleUploaded: () => void
    ) => {
        if(getSessionSubtitleUploaded?.length > 0) {
            return getSessionSubtitleUploaded?.map((file: any) => (
                <SubtitleUploaded 
                    fileName={file.name} 
                    language={file.display_language} 
                    idVideo={idVideo} 
                    idSubtitle={file.id} 
                    getSubtitleUploaded={getSubtitleUploaded}
                />
            ))
        }
        return null
    },
    isPath: (
        a: IGetSubtitleUploaded[], 
        b: IGetSubtitleUploaded[], 
        getPathSubtitleUploaded: IGetSubtitleUploaded[], 
        d: IGetSubtitleUploaded[], 
        idVideo: string, 
        getSubtitleUploaded: ()=> void
    ) => {
        if(getPathSubtitleUploaded?.length > 0) {
            return getPathSubtitleUploaded.map((file: any) => (
                <SubtitleUploaded 
                    fileName={file.name} 
                    language={file.display_language} 
                    idVideo={idVideo} 
                    idSubtitle={file.id} 
                    getSubtitleUploaded={getSubtitleUploaded}
                />
            ))
        }
        return null
    },
    isCourse: (
        a: IGetSubtitleUploaded[], 
        b: IGetSubtitleUploaded[], 
        c: IGetSubtitleUploaded[], 
        getCourseSubtitleUploaded: IGetSubtitleUploaded[], 
        idVideo: string, 
        getSubtitleUploaded: () => void
    ) => {
        if(getCourseSubtitleUploaded?.length > 0) {
            return getCourseSubtitleUploaded.map((file: any) => (
                <SubtitleUploaded 
                    fileName={file.name} 
                    language={file.display_language} 
                    idVideo={idVideo} 
                    idSubtitle={file.id} 
                    getSubtitleUploaded={getSubtitleUploaded}
                />
            ))
        }
        return null
    }
}
