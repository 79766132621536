import { ComponentProps, FC, useMemo } from "react"
import { connect } from "react-redux";

import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";

import pathnotfound from "../../../../styles/images/pathnotfound.png"
import { useHistory } from "react-router";
import { AppState } from "../../../../global-state/store/root-reducers";
import { clearAllData } from "../../../../global-state/actions/course-actions";
import { redirect, clearHistoryArray } from '../../../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from "../../../../helpers/domainCheck";
const PathNotFoundPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const user = useMemo(() => { return props?.connectedUser }, [props?.connectedUser]);
    const history = useHistory()
    const {t} = useTranslation()
    const goTo = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("offersList"), "")
         history.push(`${returnStartPathUrl()}/list`);
       


    }
    return (<>
        <>
            <div className="container-fluid" >
                <div className="row justify-content-center">
                    <div className="col-md-12" style={{ backgroundColor: '#ffffff' }}>
                        <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                            <div className=" col-md-6 mt-5" >
                                <div className="d-flex flex-column py-4">
                                    <span className="body-xl bold mb-4 Purple-800" >Parcours</span>
                                    <span className="H2-Headline mb-4" >Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
                                    <span className="body-xl mb-3" >Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.</span>
                                    <span className="body-xl mb-5">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.</span>


                                    <button type="button" className="btn-Primary large icon-right mt-2" onClick={()=>{goTo()}}>
                                        <div className="m-auto pe-2">Passer à l'offre business</div>
                                        <span className="material-icons-outlined">
                                        keyboard_arrow_right
                                        </span>
                                    </button>
                                </div>
                                <div className="py-4 w-100">

                                </div>



                            </div>
                            <div className="col-md-6 mt-5 py-4" >
                            


                                <img className="ps-4"  src={pathnotfound} alt="koors logo" />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData ,redirect, clearHistoryArray}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PathNotFoundPage);