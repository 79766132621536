/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux'; 
import { clearFormVideo } from '../../../../global-state/actions/upload-video-course-actions';
import { useTranslation } from 'react-i18next'; 
import ContentPathFormComponent from '../../../../components/formation-path-form/content-path-form-component';
import HeaderTrainingFormComponent from '../../../../components/formations-card/header-formation-form';

const PathContentFormPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();


    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
                <HeaderTrainingFormComponent type={"PATH"} title={t('contenu de parcours')} status={props?.path?.status} trainingTitle={props?.path?.title} ></HeaderTrainingFormComponent>
            </div>
            <ContentPathFormComponent />
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearFormVideo,
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    history: ownProps?.history,
    t: ownProps?.t,
    path:state.trainingReducer.path
});

export default connect(mapStateToProps, mapDispatchToProps)(PathContentFormPage);