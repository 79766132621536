import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
const CardActivityProfLoading: FC<ComponentProps<any>> = () => {

  return (
    <div className="container" style={{ maxWidth: '1224px' }}>
<div className="d-flex flex-column">
  <div className="d-flex flex-row main-list-activities">
    <div className="w-100 mb-4">
      <div className="d-flex flex-column">
        <div className='d-flex flex-row mb-4 second-list-activities'>
          <Skeleton variant="rect" animation="wave" width={'70%'} height={55} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
          <Skeleton variant="rect" animation="wave" width={'30%'} height={55} className="skeleton w-100 col-xs-7 me-4 second-card-list-activities" />
        </div>
        <div className='col-md-12 col-xs-12 d-flex flex-row second-list-activities'>
          <Skeleton variant="rect" animation="wave" width={'30%'} height={55} className="skeleton  w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
          <Skeleton variant="rect" animation="wave" width={'70%'} height={55} className="skeleton  w-100 col-xs-7 me-4 second-card-list-activities" />
        </div>
      </div>
    </div>
    <Skeleton variant="rect" animation="wave" width={'25%'} height={134} className="skeleton skeleton-rect col-md-3 col-xs-3 d-flex mb-4" />
  </div>
  <div className="d-flex row">
    <div className='col-md-12 col-xs-12 d-flex flex-row main-list-activities'>
      <Skeleton variant="rect" animation="wave" width={'25%'} height={55} className="skeleton skeleton-main padding-perso me-4 d-flex justify-content-between  second-card-list-activities" />
      <Skeleton variant="rect" animation="wave" width={'25%'} height={55} className="skeleton skeleton-main padding-perso me-4 d-flex justify-content-between main-card-list-activities second-card-list-activities" />
      <Skeleton variant="rect" animation="wave" width={'25%'} height={55} className="skeleton skeleton-main padding-perso me-4 d-flex justify-content-between second-card-list-activities" />
      <Skeleton variant="rect" animation="wave" width={282} height={55} className="skeleton padding-perso d-flex justify-content-between second-card-list-activities" />
    </div>
  </div>
</div>

<Skeleton variant="rect" animation="wave" width={'100%'} height={16} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />
<Skeleton variant="rect" animation="wave" width={'100%'} height={490} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />

</div>
  );
}

export default CardActivityProfLoading;
