import { ComponentProps, FC } from "react"
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom"
import { AppState } from "../global-state/store/root-reducers";
import BreadCrumbGlobalComponent from '../components/navigation-components/bredcrumb-global-component';
const RouteWithBreadCrumb: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { component: Component,access, path, roles, user, ...rest } = props;
    const isAuthorized=():boolean=>{
        if(access) {
            return true;
        }
        if(!user?.loggedIn) {
            return false;
        } else {
            if(Array.isArray(roles))
            { 
                return  roles.includes(user.connectedUser.role)
            } 
            return true;
        }
    }
   
    return (
        
        isAuthorized() === true ? <Route path={path} {...rest} render={(routeProps) => {
            return <>
                <BreadCrumbGlobalComponent {...routeProps} /><Component {...routeProps} />
            </> 
            }} />:
                <Redirect
                   to={{
                       pathname: '/auth/login',
                       state: { from: props?.location },
                   }}
                 />


    )
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
    user: state.authReducer,
    history: ownProps?.hisotry
});
export default connect(mapStateToProps)(RouteWithBreadCrumb);

