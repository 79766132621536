import { IReduxChangingActualTabAction, IReduxCreateProgressQnaAction, IReduxEmptyProgressAction, IReduxSaveAnswersAction, IReduxClearExamProgressAction, IReduxStudentResponseOpenQnaAction, IReduxUserResponseCloseAction, IReduxStudentResponseCloseAction, IReduxStudentResponseOpenAction, IReduxCheckExamProgressStatusAction, IReduxClearAllAction, IReduxClearExamFormAction, IReduxExamStatFormAction, IReduxGetActualTabAction, IReduxGetExamFormAction, IReduxGetExamListAction, IReduxGetExisitingAnswersAction, IReduxGetNextExamFormAction, IReduxPassExamAction, IReduxSetCurrentQuestionAction, IReduxSetMarkAction, IReduxSetQuestionsToPassAction, IReduxUpdateExamListsAction, IReduxGetExamsWithFilterAction } from "../actions/exam-actions";
import { EReduxActionTypes } from "../actions/exam-actions";


export interface IExam {
  studentId: number,
  idCourse: number,
  idExam: number,
  titleExam: string,
  progressQna: any,
  studentAnswerList: any,
  courseName: string,
  studentName: string
}
export interface IExamStat {
  nbrExamsToReview: number,
  nbrCompletedExams: number,
  nbrAllExams: number,
}
export interface ExamLists {
  listExamToReview: IExam[],
  listExamCompleted: IExam[],
  listAllExam: IExam[],

}


export interface IndexExamForm {
  index: number,
  typeExam: string,
}

export interface SubmitAnswerMark {
  idAnswer: number,
  mark: number,
  comment: string
}

export interface StudentAnswer {
  documentLink: string,
  teacherComment: string
  id: any,
  idStudent: number,
  questionByExam: any,
  questionMark: number,
  studentOpenQuestionSubmission: string,
  type: any
}

export interface UpdateExamLists {
  idAnswer: number,
  scaleNote: number,
  status: any,
  notMarked: number
}




export interface IReduxGetExamListState {
  examsList: ExamLists,
  examForm?: IExam,
  examStats: IExamStat,
  settingMark: SubmitAnswerMark,
  studentAnswer: StudentAnswer,
  indexExamForm: IndexExamForm,
  updateExamLists: UpdateExamLists,
  actualTab: string,
  loadingTabs: boolean,
  loadingLists: boolean,
  newExam: any,
  loadingExamForm: boolean,
  examToPass: any,
  questionsToPass: any,
  listOfResponses: any,
  indexQuestionToPass: number
  progressTraining?: any,
  answers: any[],
  progressQna: any
}


const initialState: IReduxGetExamListState = {
  loadingLists: true,
  loadingTabs: true,
  loadingExamForm: true,
  examsList: {
    listExamToReview: [],
    listExamCompleted: [],
    listAllExam: [],
  },
  examForm: undefined,
  examStats: {
    nbrExamsToReview: 0,
    nbrCompletedExams: 0,
    nbrAllExams: 0,
  },

  settingMark: {
    idAnswer: 0,
    mark: 0,
    comment: ""
  },

  studentAnswer: {
    documentLink: "",
    teacherComment: '',
    id: 0,
    idStudent: 0,
    questionByExam: undefined,
    questionMark: 0,
    studentOpenQuestionSubmission: "",
    type: undefined
  },

  indexExamForm: {
    index: 0,
    typeExam: "",
  },

  updateExamLists: {
    idAnswer: 0,
    scaleNote: 0,
    status: undefined,
    notMarked: 0
  },
  actualTab: 'ALL',
  newExam: undefined,
  examToPass: undefined,
  questionsToPass: undefined,
  listOfResponses: {},
  indexQuestionToPass: 0,
  progressTraining: undefined,
  answers: [],
  progressQna: undefined
};


type TExamReducerActions = IReduxCreateProgressQnaAction | IReduxEmptyProgressAction | IReduxSaveAnswersAction | IReduxStudentResponseOpenQnaAction | IReduxUserResponseCloseAction | IReduxClearExamProgressAction | IReduxStudentResponseCloseAction | IReduxStudentResponseOpenAction | IReduxSetCurrentQuestionAction | IReduxGetExisitingAnswersAction | IReduxSetQuestionsToPassAction | IReduxPassExamAction | IReduxCheckExamProgressStatusAction | IReduxChangingActualTabAction | IReduxGetActualTabAction | IReduxGetExamListAction | IReduxClearAllAction | IReduxGetExamFormAction
  | IReduxClearExamFormAction | IReduxExamStatFormAction | IReduxSetMarkAction | IReduxGetNextExamFormAction | IReduxUpdateExamListsAction | IReduxGetExamsWithFilterAction;

export default function (state: IReduxGetExamListState = initialState, action: TExamReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.GET_EXAMS_WITH_FILTER:
      return { ...state, examsList: action.data, loadingLists: false };
    case EReduxActionTypes.SET_CURRENT_QUESTION:
      return { ...state, indexQuestionToPass: action.data };
    case EReduxActionTypes.CREATE_PROGRESS_QNA:
      return { ...state, progressQna: action.data };
    case EReduxActionTypes.EMPTY_PROGRESS:
      return { ...state, progressQna: undefined };

    case EReduxActionTypes.SAVE_ANSWERS:
      const list: any[] = state.answers ? [...state.answers] : [];
      const indexQna = list.findIndex((answer: any) => answer?.examId == action?.data?.examId && action?.data?.questionByExamId == answer?.questionByExamId);
      if (indexQna !== -1) {
        list[indexQna] = action.data;
        return { ...state, answers: list }
      } else {
        list.push(action.data);
        return { ...state, answers: list }
      }

    case EReduxActionTypes.SET_QUESTIONS_TO_PASS:
      return { ...state, questionsToPass: action.data };
    case EReduxActionTypes.PASS_EXAM:
      return { ...state, examToPass: action.data };
    case EReduxActionTypes.EXISTING_ANSWERS:
      return { ...state, listOfResponses: action.data };
    case EReduxActionTypes.LOADING_SUBMISSION:
      return { ...state, loadingExamForm: true };
    case EReduxActionTypes.CHECK_EXAM_PROGRESS_STATUS:
      const oldExams = { ...state.examsList };
      const updatedExam = { ...state.examForm };
      const newAllExamLists = [...oldExams.listAllExam];
      const indexexam = newAllExamLists.findIndex((obj: any) => obj.idExam === action.data);
      if (indexexam !== -1) {
        newAllExamLists[indexexam].progressQna.status = updatedExam.progressQna.status;
        oldExams.listAllExam = newAllExamLists;
      }
      return { ...state, examsList: oldExams };
    case EReduxActionTypes.LOADING_LISTS:
      return { ...state, loadingLists: true };
    case EReduxActionTypes.LOADING_TABS:
      return { ...state, loadingTabs: true };
    case EReduxActionTypes.CHANGING_ACTUAL_TAB:
      return { ...state, actualTab: action.data };
    case EReduxActionTypes.ACTUAL_TAB:
      return { ...state, actualTab: action.data };
    case EReduxActionTypes.GET_EXAMLIST:
      return { ...state, examsList: action.data, loadingLists: false };
    case EReduxActionTypes.GET_EXAM_STATS:
      return { ...state, examStats: action.data, loadingTabs: false };
    case EReduxActionTypes.GET_EXAM_FORM:
      return { ...state, examForm: action.data.exam, indexExamForm: { index: action.data.index, typeExam: action.data.typeList }, loadingExamForm: false };
    case EReduxActionTypes.CLEAR_EXAM_FORM:
      return { ...state, examForm: undefined, loadingExamForm: true };
    case EReduxActionTypes.SET_OPEN_QUESTION_MARK:

      if (action.data === null) {
        return { ...state }
      } else {
        const update = { ...state.examForm }
        const index = update.studentAnswerList.findIndex((obj: any) => obj.id === action.data.id);
        if (index !== -1) {
          update.studentAnswerList[index] = action.data;
          const verif = update.studentAnswerList.findIndex((obj: any) => obj.questionMark === null);
          if (verif === -1) {
            update.progressQna.status = "COMPLETED";
            update.progressQna.finalMark = update.studentAnswerList.map((obj: any) => { return obj.questionMark }).reduce((a: any, b: any) => a + b, 0);
          }
        }
        return { ...state, examForm: update };
      }
    case EReduxActionTypes.GET_NEXT_EXAM_FORM:
      const examFormIndex = { ...state.indexExamForm }
      const allLists = { ...state.examsList };
      let formExam = undefined;
      switch (examFormIndex.typeExam) {
        case "TOREVIEW":
          formExam = allLists.listExamToReview[action.data.index];
          break;
        case "COMPLETED":
          formExam = allLists.listExamCompleted[action.data.index];
          break;
        case "ALL":
          formExam = allLists.listAllExam[action.data.index];
          break;
      }
      examFormIndex.index = action.data.index;
      return { ...state, indexExamForm: examFormIndex, examForm: formExam };
    case EReduxActionTypes.UPDATE_EXAM_LISTS:
      const updatedExamForm: any = { ...state.examForm };
      const updatedExamLists = { ...state.examsList }
      updatedExamForm.progressQna.status = "COMPLETED";
      let finalMarkModified = 0
      if (!updatedExamForm.studentAnswerList.find((obj: any) => obj.questionMark === null)) {
        updatedExamForm.studentAnswerList.map((resp: any, i: number) => {
          finalMarkModified = finalMarkModified + resp.questionMark;
        })
        updatedExamForm.progressQna.finalMark = finalMarkModified;
        const index = updatedExamLists.listExamToReview.findIndex((obj: any) => obj.progressQna.progressId === action.data)
        if (index !== -1) {
          updatedExamLists.listExamToReview.splice(index, 1);
        }
        updatedExamLists.listExamCompleted.push(updatedExamForm);
        const index1 = updatedExamLists.listAllExam.findIndex((obj: any) => obj.progressQna.id === action.data)
        if (index1 !== -1) {
          updatedExamLists.listAllExam[index1] = updatedExamForm;
        }
        return { ...state, examsList: updatedExamLists, examForm: updatedExamForm, newExam: "COMPLETED", actualTab: "COMPLETED", indexExamForm: { index: updatedExamLists.listExamCompleted.length - 1, typeExam: "COMPLETED" } };
      } else {
        return { ...state };
      }
    case EReduxActionTypes.CLEAR_ALL:
      return initialState;
    case EReduxActionTypes.SUBMIT_RESPONSE_OPEN:
      let liste = { ...state.listOfResponses };
      liste[action.data.index] = action?.data?.data;
      return { ...state, listOfResponses: liste, progressTraining: action?.data?.trainingProgress };
    case EReduxActionTypes.CLEAR_EXAM_PROGRESS:
      return {
        ...state, progressTraining: undefined, examForm: undefined,
        studentAnswer: {
          documentLink: "",
          teacherComment: '',
          id: 0,
          idStudent: 0,
          questionByExam: undefined,
          questionMark: 0,
          studentOpenQuestionSubmission: "",
          type: undefined
        }
      };
    default:
      return state;
  }
}


