import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import TrainingContentCous from './course-section-component';
import TrainingContentPath from './path-section-component'
import FinalExamCardPreview from './chapter-exam/final-exam-card-preview';
import { useTranslation } from 'react-i18next';

const PathSessionContentSection: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [matchesMediaQueryLG, setMatchesMediaQueryLG] = useState(window.matchMedia("(min-width: 1200px)").matches)
  const [courseInfo, setCourseInfo] = useState<any>(undefined)
  const [order, setOrder] = useState(props?.orders)


  const convertTime = (minutes: number) => {
    if (minutes < 60) {
      return minutes + ' MN';
    } else {

      return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
    }
  }

    
    useEffect(() => {
        window
        .matchMedia("(min-width: 1200px)")
        .addEventListener('change', e => setMatchesMediaQueryLG( e.matches ));
        
        if(props?.courseInfo !==courseInfo){
            setCourseInfo(props?.courseInfo)
          }

          if(typeof props?.orders !=undefined){
            let ordered = props?.orders?.sort((a:any, b:any) => a?.index - b?.index)?.filter((obj:any, index:any, self:any) => {
              return index === self.findIndex((o:any) => o?.id === obj?.id);
            });
            setOrder(ordered)
          }

    }, []);

  return <>

    <section className="mb-5 pb-3" style={{ maxWidth:matchesMediaQueryLG ? 1200 : 600, margin: 'auto'}}>

      <div className="d-flex flex-column justify-content-center px-2" style={{width: matchesMediaQueryLG ? props?.width : "100%"}}>
        {(courseInfo?.trainingType === "PATH") &&
          <h3 className="H3-Headline black-800 text-center mt-5 mb-4 pb-3">{t('pathContentTitle')}</h3>

        }
        {(courseInfo?.trainingType === "SESSION") &&
          <h3 className="H3-Headline black-800 text-center mb-4 mt-5 pb-3">{t('sessionContentTitle')}</h3>

        }
        {order?.map((obj: any, index: number) => {

          return <>
            {
              (!!obj.path) &&
              <TrainingContentPath index={obj?.index + 1} title={obj?.path?.title} list={obj?.path?.subjectsByPath} duration={obj.path.duration} />
            }
            {
              (!!obj.course) &&
              <TrainingContentCous index={obj?.index + 1} title={obj?.course?.title} list={obj?.course?.orders} exams={obj?.course?.exams} duration={convertTime(obj?.course.duration)} />
            }
          </>
        })
        }
        {props?.exam && props?.exam.length!=0 && <>
          <FinalExamCardPreview preview={true} index={props?.orders?.length + 1} title={props?.exam?.title} duration={props?.exam?.duration} />
        </>}
      </div>
    </section>
  </>

}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  orders: ownProps?.orders,
  time: ownProps?.time,
  listTest: state.courseReducer.listTest,
  courseInfo: ownProps?.courseInfo

});

export default connect(mapStateToProps, null)(PathSessionContentSection);
