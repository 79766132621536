import { FC } from "react";
import RouteWithBreadCrumb from '../../navigations/RouteWithBreadCrumb';
import FooterComponent from "../../components/footer-component";
import SubscriptionPage from "./subscription-page";
import CustomSwitch from "../../helpers/custom-switch";
const SubscriptionContainer: FC<any> = (props: any) => {
    return (<>
        <div className="page-wrapper-height">
            <CustomSwitch>
                <RouteWithBreadCrumb exact={true} history={props?.history} path={`${props?.match.path}`} component={SubscriptionPage} roles={["role_director", "role_prof", "role_student"]} />
            </CustomSwitch>
        </div>
        <FooterComponent />
    </>
    )
}
export default SubscriptionContainer;