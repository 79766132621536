
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";

import { ComponentProps, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import axios from "../../helpers/interceptors";
import { openModal } from "../../global-state/actions/modal-actions";
import { ModalIDS } from "../modals/modal-ids";
import { useTranslation } from "react-i18next";
import { getContract } from '../../global-state/actions/app-container-actions';


const CARD_OPTIONS: any = {
  showIcon: true,
  iconStyle: "solid",
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      iconColor: '#E06A6A',
      color: '#272727',
    },
  },
};
const CARD_OPTIONS_EXP = {
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      color: '#272727',
    },
  },
};
const CARD_OPTIONS_CVC = {
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      color: '#272727',
    },
  },
};


const AddCustomerComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation()
  const [errorCVC, setErrorCVC] = useState(undefined);
  const [errorCardNumber, setErrorCardNumber] = useState(undefined);
  const [errorDateExp, setErrorDateExp] = useState(undefined);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    props?.getContract(props?.schoolInfo?.id, "SCHOOL")

  }, [])

  const handleSubmit = async (event: any) => {

    event.preventDefault();
    if (!stripe || !elements) {

      return;
    }
    const promise = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!
    });
    const token: any = await stripe.createToken(
      elements.getElement(CardNumberElement)!,
    );


    if (!!promise.paymentMethod && !!token.token.id) {
      await axios.get(`${process.env.REACT_APP_BASE_URL13}paymentsetting/getPaymentSetting/` + props?.connectedUserInfo?.id + `/CUSTOMER`).then(async (res: any) => {


        if (!!res.data.engineId) {
          await axios.post(process.env.REACT_APP_BASE_URL15 + 'strip/create-card/' + res.data.engineId + '/' + token.token.id).then(async (response: any) => {
            await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t("returnToParameters"), path: "/param", lines: [t("cardAddedSuccessfully")] });



          })

        } else {
          let object = { id: props?.connectedUserInfo?.id, email: props?.connectedUserInfo?.email }
          await axios.post(process.env.REACT_APP_BASE_URL15 + 'strip/create-customer/', object).then(async (response: any) => {

            await axios.post(process.env.REACT_APP_BASE_URL13 + 'paymentsetting/createSetting/', {
              "subjectId": props?.schoolInfo?.id,
              "subjectType": "CUSTOMER",
              "enginePaymentId": response.data.id
            }).then(async (res: any) => {
              await axios.post(process.env.REACT_APP_BASE_URL15 + 'strip/create-card/' + res.data.engineId + '/' + token.token.id)
            }).then(async (ress: any) => {
              await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t("returnToParameters"), path: "/param", lines: [t("cardAddedSuccessfully")] });
            })
          })
        }

      })

    }
  }

  return (<>


    <div className="bg-with-border white p-5 mt-5" style={{ width: "100%" }} onSubmit={handleSubmit} >
      <h3 className="H3-Headline mb-3">{t('PaymentDetails')}</h3>
      <div className="d-flex flex-column ml-3 mb-4">
        <label className="form-title">{t('LastNameandFirstName')}</label>
        <input dir="ltr" disabled type="text" className="form-control input text-default" placeholder={props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName} />
      </div>
      <div className="ml-3 mb-4">
        <label className="form-title mb-2">{t('CreditCardNumber')}</label>
        <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorCardNumber) ? "#F2F2F5" : "#fae9e9" }}>
          <CardNumberElement onChange={(e: any) => setErrorCardNumber(e.error)} options={CARD_OPTIONS} />
        </div>
      </div>
      <div className="ml-3 d-flex align-items-center justify-content-between flew-wrap ">
        <div className="w-100 me-2">
          <label className="form-title mb-2">{t('ExpirationDate')}</label>
          <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorDateExp) ? "#F2F2F5" : "#fae9e9" }}>
            <CardExpiryElement onChange={(e: any) => setErrorDateExp(e.error)} options={CARD_OPTIONS_EXP} />
          </div>
        </div>
        <div className="w-100 ml-3 ">
          <label className="form-title mb-2">{t('Cryptogramme')}</label>
          <div className=" px-3 py-3" style={{ position: 'relative', borderRadius: '3px', backgroundColor: (!errorCVC) ? "#F2F2F5" : "#fae9e9" }}>
            <div className="w-60">
              <CardCvcElement onChange={(e: any) => setErrorCVC(e.error)} options={CARD_OPTIONS_CVC} />
            </div>
            <div style={{ position: "absolute", right: 12, top: 14 }}>

            </div>
          </div>
        </div>

      </div>
      <div className="d-flex flex-row justify-content-end">
        <button type="button" className="btn-Primary large mt-4 " onClick={handleSubmit}>
          {t('save')}
        </button>
      </div>

      <button id='checkedConditionsTrue' style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#activateModalFinal"></button>
      <button id='checkedConditionsFalse' style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#continuCreatingModal"></button>

    </div>
  </>
  )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  subscription: state.schoolInfoReducer.subscription,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  connectedUserInfo: state.authReducer.connectedUserInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getContract,
  openModal

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerComponent);

