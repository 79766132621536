import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { affectOtherProf } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import { getProfessor, suspendTeamMember } from '../../../global-state/actions/professor-actions';
import { getCoursesProf } from '../../../global-state/actions/catalog-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';

const DeactivateProfModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState("")
    const [prof, setProf] = useState(props?.content?.data?.prof);
    let [otherProf, setOtherProf] = useState('');

    useEffect(() => {
        if (props?.profs?.length === 0) {
            props?.getProfessor(props?.connectionUser.idSchool, '')
        }
    }, [])



    useEffect(() => {
        setProf(props?.content?.data?.prof)
    }, [props?.content])
    const affectOtherProfButton = async () => {
        let code = getDomainFromUrl()
        let object = {
            comment: comment,
            code: code,
            idSchool: props?.connectionUser.idSchool,
            courses: [],
        }
        let filter = {
            entityId: props?.connectionUser.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: prof.id,

        }
        await props?.suspendTeamMember(prof.id, otherProf, props?.connectionUser.idPerson, object , "PROF").then(
             props?.affectOtherProf(prof.id, otherProf, props?.connectionUser.idPerson, object)
        )
        await props?.getProfessor(props?.connectionUser.idSchool, '')
        await props?.getCoursesProf(filter)
        setOtherProf('');
        setComment('');
        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", path: "", buttonText: t('returnToProfProfile'), lines: [t('successSuspendProf')] });
    }
    const changeColor = (idSelect: string) => {
        if ((document?.getElementById(idSelect) as any).value == "") {
            (document?.getElementById(idSelect) as any).className = "select-city-register option-placeholder";
        } else {
            (document?.getElementById(idSelect) as any).className = "select-city-register option-choice";
        }
    }
    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-9 p-0 " style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 ">
                                <span className="H3-Headline">{t('SuspendProf')} </span>
                                <br />
                                <span className="H3-Headline bold neutral-3"> {prof?.name} </span>

                            </div>
                            <div className="col-md-12 mt-5 ">

                                <span className="body-sm">{t('textProfSuspend')}</span>
                            </div>
                            <div className="col-md-12 mt-5">
                                <label className="form-title">{t('profchoice')}</label>
                                <div className="select-input">
                                    <select id="choiceProfessor" className="select-city-register" onChange={(e) => { setOtherProf(e.target.value); changeColor('choiceProfessor') }} value={otherProf}>
                                        <option className="option-placeholder" value="" selected disabled hidden>{t('affectOtherPRof')}</option>
                                        {props?.profs?.filter((obj: any ,) => obj.id !== Number(prof?.id))?.map((profObject: any) => {
                                            return <option key={profObject?.id} className="option-choice" value={profObject.id}>{profObject.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5 input-full">
                                <label className="form-title">{t('commentProfSuspend')}</label>


                                <div className="col-md-12 mt-3 ">
                                    <textarea style={{  maxHeight:'75px',fontSize: "16px" }} rows={1}  value={comment} onChange={(e) => { setComment(e.target.value) }} id="professorDetails" className="textaria-textField" placeholder={t('commentSuspendInputProf')} ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer p-0" style={{ paddingTop: 0, border: "none" }}>
                <div className="row mt-3 mb-5 w-100">
                    <div className='col-md-9 text-center justify-content-end p-0 m-auto buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large" onClick={() => { setComment(''); setOtherProf(''); props?.closeModal() }} style={{ textTransform: 'uppercase', marginRight: '2%' }} >{t('annuler')}</button>
                        <button type="button" className="btn-Primary large danger" onClick={() => { affectOtherProfButton(); }} disabled={!(comment !== '' && otherProf !== '')}>{t('SuspendProf')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    profs: state.profReducer.profList,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            affectOtherProf,
            getProfessor,
            getCoursesProf,
            suspendTeamMember

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DeactivateProfModalComponent);
