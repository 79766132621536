import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';

import TableScrollElement from '../../../elements/table/table-scroll-element';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import HtmlTooltip from '../../../elements/tooltip/Tooltip-Customized-element';
import { ModalIDS } from '../modal-ids';
import ValideInvitationModalComponent from './valide-invitation-modal-component';
import ErrorInvitationModalComponent from './error-invitation-modal-component';

const AntSwitch = withStyles(() => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 3,
        color: 'white',
        '&$checked': {
            transform: 'translateX(18px)',
            color: '#ffffff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5A5A5A',
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        boxShadow: '0px 4px 4px rgba(34, 34, 34, 0.25)',
    },
    track: {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: '#EBECEE',
    },
    checked: {},
}))(Switch);

const InvitationStudentListModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [items, setItems] = useState<any>();
    const [data, setData] = useState<any>(null);
    const [failed, setFailed] = useState<any>();
    const [checked, setChecked] = useState(false)
    const [success, setSuccess] = useState<any>();
    
    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)

    const tabHeaders = [
        { title: "", sorted: true, classNames: "w-23", scope: "col", buttonClasses: "d-flex button-trie px-0 text-center" },
        { title: "Prénom", sorted: true, classNames: "w-22", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: "Nom", sorted: true, classNames: "w-24", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: "Email", sorted: true, classNames: "w-32", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    ]

    useEffect(() => {
        setItems(props?.inviteStudentsList)
    }, [])

    useEffect(() => {
        setFailed(items?.filter((item: any) => Object.values(item)[0] === '' || Object.values(item)[1] ===''||Object.values(item)[2] === ''||Object.values(item)[0] === undefined || Object.values(item)[1] === undefined || Object.values(item)[2] === undefined || !regx.test(Object.values(item)[2] as any)))
        setSuccess(items?.filter((item: any) => Object.values(item)[0] !== '' && Object.values(item)[1] !== '' && Object.values(item)[2] !== '' && Object.values(item)[0] !== undefined && Object.values(item)[1] !== undefined && Object.values(item)[2] !== undefined && regx.test(Object.values(item)[2] as any)))
    }, [items])

    const checkImportation=()=>{
        if(failed?.length >0){
            props.setModalListInviteOpened(false)
            props.setModalErrorOpened(true)
        }else{
            props.setModalListInviteOpened(false)
            props.setModalSuccessOpened(true)
        }
    }
   
    useEffect(() => {
            let list = {
                headers: tabHeaders,
                body: items?.map((invitation: any, index: any) => {
                    return {
                        row: {
                            classNames: "table-white",
                            columns: [
                                { content: index + 1, classNames: "w-25", actionName: '' },
                                { content: (!! Object.values(invitation)[0] || Object.values(invitation)[0] !== '') ? Object.values(invitation)[0] :      <HtmlTooltip content={<div className='d-flex flex-column'>
                                <div>
                                  <span className="body-sm bold" >{t('firstName')} </span>{t('obligatoire')}</div>

                            </div>}>
                                <span className="material-icons-outlined align-items-center justify-content-center" style={{ fontSize: 24, color: "#E06A6A" }}>error</span>
                            </HtmlTooltip >, classNames: "w-25", actionName: '' },
                                { content: (!! Object.values(invitation)[1] || Object.values(invitation)[1] !== '') ? Object.values(invitation)[1] : 
                                <HtmlTooltip content={<div className='d-flex flex-column'>
                                <div>
                                  <span className="body-sm bold" >{t('name')}</span>{t('obligatoire')}</div>

                            </div>}>
                                <span className="material-icons-outlined  align-items-center justify-content-center" style={{ fontSize: 24, color: "#E06A6A" }}>error</span>
                            </HtmlTooltip >
                                , classNames: "w-25", actionName: '' },
                                {
                                    content: (!! Object.values(invitation)[2] || Object.values(invitation)[2]!='') && regx.test(Object.values(invitation)[2] as any ) ? Object.values(invitation)[2] : <div className="d-flex flex-row" ><span>{Object.values(invitation)[2] as any}</span>
                                        <HtmlTooltip content={<div className='d-flex flex-column align-items-center justify-content-center'>
                                            <div>
                                                {t('emailAddressNotValid')}<br />
                                                {t('emailFormat')}<span className="body-sm bold align-items-center justify-content-center align-text-center" >{t('emailExample')}</span> </div>

                                        </div>}>
                                            <span className="material-icons-outlined align-items-center justify-content-center" style={{ paddingLeft:"10px",fontSize: 24, color: "#E06A6A" }}>error</span>
                                        </HtmlTooltip >
                                    </div>, classNames: "w-25", actionName: ''
                                },

                            ]
                        }
                    }
                })
            }
            setData(list)
        
    }, [items])

    const handleChange = (checked: any, data: any) => {
        if (checked) {
            setItems(data?.items)
        } else {
            setItems(data?.items.filter((item: any) => Object.values(item)[0] === undefined || Object.values(item)[0] === ''||Object.values(item)[1] === ''||Object.values(item)[2] === ''|| Object.values(item)[1] === undefined || Object.values(item)[2] === undefined || !regx.test(Object.values(item)[2] as any)))
        }
        setChecked(!checked)
    };

    const handleGoBackCLick = () => {
        props.setModalListInviteOpened(false)
        props.setModalOpened(true)
    }

    const handleCloseClick = () => {
        props.setModalCancelOpened(true)
    }

    return (
        <>
            <div style={{ height: 696, background: "#F8F8FA" }}>
                <div className="modal-header mb-2 pt-4" style={{ paddingBottom: 0, borderBottom: "none" }}>
                    <div className="mx-3 px-3  d-flex align-items-center justify-content-between  w-100 ">
                        <h3 className="H3-Headline">{t('importationResult')}</h3>
                        <div className="d-flex align-items-center justify-content-end py-2 ">

                            <button className="btn-QuickActions" onClick={() =>  handleCloseClick()}><span className="material-icons">close</span></button>
                        </div>
                    </div>

                </div>
                <div className="modal-header mb-2" style={{ paddingBottom: 0, borderBottom: "none", overflow:'hidden' }}>
                    <div className='d-flex flex-row mx-3 px-2'>
                        {!!success && success?.length !== 0 &&
                            <div className='notif-suceess small d-flex flex-row align-items-center me-3 '>
                                <span className="material-icons  me-3" style={{ fontSize: 32, color: "#9DDBA9" }}>check_circle</span>
                                <span className='body-md black-800' style={{ width: 'max-content' }}> <span className='body-md bold black-800'> {success?.length} </span>{success?.length===1 ? t('succesfulImportation1'):t('succesfulImportation')}</span>
                            </div>}
                        {!!failed && failed?.length !== 0 &&
                            <div className='notif-fail small d-flex flex-row align-items-center '>
                                <span className="material-icons  me-3" style={{ fontSize: 32, color: "#E06A6A" }}>error</span>
                                <span className='body-md black-800' style={{ width: 'max-content' }}> <span className='body-md bold black-800'> {failed?.length} { failed?.length===1 ? t('lignes1'): t('lignes')} </span>{failed?.length===1 ? t('containErrors1'): t('containErrors')}
                                </span>

                            </div>
                        }

                    </div>

                </div>
                {!!failed && failed?.length !== 0 &&
                <div className="modal-header mb-2" style={{ paddingBottom: 0, borderBottom: "none" }}>
                    <div className='d-flex flex-row  mx-3 px-2'>
                        < Grid component="label" justify='center' container alignItems="center" spacing={1}>
                            <Grid item>
                                <AntSwitch checked={checked} onChange={() => { handleChange(checked, props?.inviteStudentsList?.data) }} name="checkedC" />
                            </Grid>
                            <Grid item><span className='H4-Subtitle ms-3 black-800'>Afficher uniquement les lignes avec des erreurs</span> </Grid>
                        </Grid>
                    </div>
                </div>
                    }
                <div className="modal-body mt-3" style={{ paddingTop: 0 }}>
                     <TableScrollElement height={367} data={data} doAction={(value: any) => {
                       
                    }} /> 
                </div>

                <div className="d-flex mx-4 px-4 mt-4 mb-2 justify-content-end ">
                    <button className="btn-Secondary large me-3" onClick={()=> handleGoBackCLick()}>
                       retour
                    </button>
                    <button className="btn-Primary large "  onClick={checkImportation} >
                        Envoyer les invitations
                    </button>

                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    student: state.detailsProfileReducer.actualStudent,
    connectionUser: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { closeModal,openModal },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(InvitationStudentListModalComponent);
