import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import {getDataFromURL} from '../../base/functions/Functions';
import _ from 'lodash';
import { sendMyRequest } from '../../global-state/actions/course-actions';
import { openModal } from '../../global-state/actions/modal-actions';
const AdmissionPreviewStudentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [answers, setAnswers] = useState<any>((props?.applicationFile && props?.applicationFile?.answers) ? props?.applicationFile?.answers : _.times(props?.registration?.questions?.length, _.constant({ 'id': null, 'index': props?.registration?.questions?.length + 1, 'answer': '' })))
    const [documents, setDocuments] = useState<any>((props?.applicationFile && (props?.applicationFile?.documents && props?.applicationFile?.documents.length > 0)) ? props?.applicationFile?.documents : _.times(props?.registration?.documents?.length, _.constant({ 'id': null, 'index': props?.registration?.documents?.length + 1, 'link': '', 'name': '' })))
    const [show, setShow] = useState(false);
    useEffect(() => {
        if(props?.applicationFile && props?.applicationFile?.answers) {
            setAnswers(props?.applicationFile?.answers);
        } else {
            _.times(props?.registration?.questions?.length, _.constant({ 'id': null, 'index': props?.registration?.questions?.length + 1, 'answer': '' }));
        }
    }, [props?.applicationFile])
    useEffect(() => {
        if(props?.applicationFile && (props?.applicationFile?.documents && props?.applicationFile?.documents.length > 0)) {
            setDocuments(props?.applicationFile?.documents);
        } else {
            _.times(props?.registration?.questions?.length, _.constant({ 'id': null, 'index': props?.registration?.questions?.length + 1, 'answer': '' }));
        }
    }, [props?.applicationFile])
    return (
        <>
            <div className='row justify-content-center' style={{ margin: 0, padding: 0 }}>
                <div className="col-md-7">
                    <div className="row">
                        {props?.registration?.questions.length !== 0 && <div className=" mt-5 mb-5">
                            <h3 className=" mt-1 pb-2 H3-Headline">{t('titreRegistration_1')}</h3>
                            {props?.registration?.questions?.map((question: any, index: any) => (
                                <div  key={index} >
                                    <div className="form-group mt-5 mb-2 pb-1">
                                        <label className="H4-Subtitle body-md bold">{question?.question}</label>
                                    </div>
                                    <p className="body-md neutral-2">{answers[index]?.answer}</p>
                                </div>
                            ))}
                        </div>}
                        {props?.registration?.documents?.length !== 0 && props?.registration?.questions?.length !== 0 &&
                            <div className=" mb-5">
                                <hr  className="mt-2" style={{backgroundColor: "#DADBDF", opacity:1}}/>
                            </div>}
                        {props?.registration?.documents?.length !== 0 && <> <div className="col-md-12  mb-5">
                            <h1 className="mb-5 mt-1 pb-2 H3-Headline">{t('titreRegistration_2')}</h1>
                            {props?.registration?.documents?.map((document: any, index: any) => (
                                <div className="  mb-3 pb-4 " key={index}>
                                    <div className="form-group  mb-3">
                                        <label className="H4-Subtitle">{document?.document}</label>
                                    </div>
                                    <div className="bg-with-border white p-4">
                                        <div className="d-flex align-items-center justify-content-between  m-2">
                                            <div className="d-flex align-items-center me-2">
                                                <div className="me-3 d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                                                    <span className="material-icons-outlined black-800"> cloud_upload </span>
                                                </div>
                                                <div className="d-flex flex-column black-800">
                                                    <span className="form-edit mb-1">{documents[index]?.name}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <button type="button" className="btn-QuickActions me-3" onClick={() =>  getDataFromURL(documents[index]?.link)}>
                                                    <span className="material-icons-outlined">download</span>
                                                </button>
                                                {documents[index]?.link?.includes('.pdf') && <button type="button" className="btn-QuickActions" onClick={() => setShow(!show)}>
                                                    <span className="material-icons-outlined">{show?'visibility_off':'remove_red_eye'}</span>
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                    {show && <div style={{ height: 900 }}> <iframe src={`${documents[index]?.link}`} frameBorder="0" scrolling="auto" height="100%" width="100%" ></iframe> </div> }
                                </div>
                            ))}
                        </div></>}

                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.courseReducer.courseInfo,
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    sendMyRequest, openModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionPreviewStudentComponent);