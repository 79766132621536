import { FC } from "react";
import { Switch } from "react-router";

import RouteWithBreadCrumb from '../../navigations/RouteWithBreadCrumb';
import FooterComponent from "../../components/footer-component";
import FacturationPage from "./facturation-page";
import CustomSwitch from "../../helpers/custom-switch";
const FacturationContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height" >
      <CustomSwitch>
        <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/add`} component={FacturationPage} />
     
      </CustomSwitch>

    </div>
    <FooterComponent />
  </>
  )
}
export default FacturationContainer;