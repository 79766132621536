import {
  EReduxActionTypes,
  IReduxActivateModalAction,
  IReduxClearModalAction,
  IReduxCloseModalAction,
  IReduxCloseModalWithActionAction,
  IReduxDeactivateModalAction,
  IReduxOpenModalAction,
  IReduxShowSnackBarAction,
  IReduxClearSnackBarAction
} from "./../actions/modal-actions";

export interface IReduxModalState {
  modalOpened: boolean;
  modalContent?: IModalContent;
  modalPage: boolean | null;
  modalAction:string, 
  showSnackBar:boolean,
  message:string
}
interface IModalContent {
  size: string;
  idModal: string;
  buttonText: string;
  lines: [string];
  path: string;
  data: any;
}
export const initialState: IReduxModalState = {
  modalOpened: false,
  modalContent: undefined,
  modalPage: null,
  modalAction:"",
  showSnackBar:false,
  message:""
};

type TModalReducerActions =
  | IReduxCloseModalAction
  | IReduxOpenModalAction
  | IReduxActivateModalAction 
  | IReduxDeactivateModalAction 
  | IReduxClearModalAction
  | IReduxCloseModalWithActionAction
  | IReduxShowSnackBarAction
  | IReduxClearSnackBarAction

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state: IReduxModalState = initialState,
  action: TModalReducerActions
) {
  switch (action.type) {
    case EReduxActionTypes.CLOSE_MODAL:
      return { ...state, modalContent: undefined, modalOpened: false };
    case EReduxActionTypes.OPEN_MODAL:
      return { ...state, modalContent: action.data, modalOpened: true };
    case EReduxActionTypes.ACTIVATE_MODAL: 
      return { ...state, modalPage: true };
    case EReduxActionTypes.DEACTIVATE_MODAL: 
      return { ...state, modalPage: false };
    case EReduxActionTypes.CLEAR_MODAL: 
      return { ...state, modalPage: null , modalAction :'' };
    case EReduxActionTypes.CLOSE_MODAL_WITH_ACTION: 
      return { ...state, modalAction: action.data , modalOpened: false };
    case EReduxActionTypes.SHOW_SNACK_BAR: 
      return { ...state, showSnackBar: action.status , message: action.message };
    case EReduxActionTypes.CLEAR_SNACK_BAR: 
      return { ...state, showSnackBar: false , message: "" }; 
    default:
      return state;
  }
}