import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { publishCourse, updateCoursesLists } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { createTraining, publishTraining } from '../../../global-state/actions/training-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';

const ReactivateTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseInfo, setCourseInfo] = useState<any>();
    const [comment, setComment] = useState<string>("");
    const [type, setType] = useState<any>("course");
    const [training, setTraining] = useState<any>("");
    const [trainingSession, setTrainingSession] = useState<any>("");
    
    useEffect(() => {
      if(props?.content?.data?.type !==undefined){
        setType(props?.content?.data?.type);
      }
      
  }, [])


    

    useEffect(() => {
      setType(props?.content?.data?.type);
  }, [props?.content])
  useEffect(() => {
    setTrainingSession(props?.trainingSession)
}, [props?.trainingSession])
  useEffect(() => {
    setTraining(props?.training);
  
}, [props?.training])
    useEffect(() => {
        setCourseInfo(props?.courseInfo)
    }, [props?.courseInfo]);
    const validateCours = async (value: string) => {
        let code = getDomainFromUrl()
        let obj={
          comment:(comment !== "") ? comment : t('nothingTOSay'),
          idCours:(type==="course")?courseInfo?.id:(type==="path")?training?.id:trainingSession?.id,
          status:"",
          title:(type==="course")?courseInfo?.title:(type==="path")?training?.title:trainingSession?.title,
          code:code,
          userRole:props?.connectionUser?.role,
          idSchool:props?.connectionUser?.idSchool,
          trainingType:(type==="course")?courseInfo?.type?.toUpperCase():(type==="path")?training?.type?.toUpperCase():trainingSession?.type?.toUpperCase(),
       }
       switch(type) {
        case'course':
        await props?.publishCourse(value,courseInfo?.idProf, props?.connectionUser?.idPerson,obj); 
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToCourse'), lines: [t('textreactiovationCourse')]});
        break;    
        case'path':
        await props?.createTraining("paths", { ...training, status: "PUBLISHED" });
        await props?.publishTraining(value, props?.connectionUser?.idPerson, training?.idProf, obj)
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToPathButton'), lines: [t('textreactiovationPath')]});
        break;
        case'session':
        await props?.createTraining("sessions", {...trainingSession,status:"PUBLISHED"});
        await props?.publishTraining(value, props?.connectionUser?.idPerson, trainingSession?.idProf, obj)
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToSessionButton'), lines: [t('textreactiovationSession')]});
        break;
    }
        
      }
    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
                  <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                    <div className="row body-modal-publish">
                      <div className="col-md-11" style={{ margin: 'auto' }}>
                          <div className="d-flex justify-content-start flex-column">
                            <span className="H3-Headline black-800"> {t((type === 'course')?'modalReactivateCoursTitle': (type === 'path')?'modalReactivatePathTitle':'modalReactivateSessionTitle')}</span>
                            <span className="H4-Subtitle neutral-3"> {(type === 'course')?courseInfo?.title: (type === 'path')? training?.title: trainingSession?.title} </span>
                            <span className="body-sm black-800 mt-3">{t((type === 'course')?'reactivateCoursText':(type === 'path')?'reactivatePathText':'reactivateSessionText')}</span>
                            <span className="body-sm black-800">{t((type === 'course')?'reactivateCoursText_2':(type === 'path')?'reactivatePathText_2':'reactivateSessionText_2')}</span>
                            
                          </div>
                          <div className="col-md-12 mt-4 input-full">
                            <p className="form-title mb-1">{t('modalPublishCourse_2_2')}</p>
                            <div className="input-text-form-control">
                            <TextareaUnderlineElement type="text" id={"commentReactvateCourse"} value={comment} onChange={(e: string) => { setComment(e) }} placeholder={t('commentSuspendInputProf')} />
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer" style={{ border: "none" }}>
                    <div className="row mt-3 mb-5">
                      <div className='col-md-12 text-center justify-content-center' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large success" onClick={() => {validateCours("REACTIVATE"); }} disabled={ comment === ''}>{t('reactivateCours')}</button>
                      </div>
                    </div>
                  </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            updateCoursesLists,
            publishCourse,
            createTraining,
            publishTraining

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ReactivateTrainingModalComponent);