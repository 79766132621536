import Skeleton from '@material-ui/lab/Skeleton';
import { ComponentProps, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import axios from "../../helpers/interceptors";
import { checkPayment } from '../../global-state/actions/finance-actions';
import { openModal } from "../../global-state/actions/modal-actions";
import { ModalIDS } from "../modals/modal-ids";
import { getContract } from '../../global-state/actions/app-container-actions';
import { checkPaymentApi } from '../../services/finance.service';

interface Props {
    offer: any
    price: any,
    token: any
}

interface Params {
    id: string;
}
const endDateHandlerFn = (offerPricings:any,propsPrice:any) => {
    if(!!offerPricings &&offerPricings.length >1){
        if(offerPricings?.find((a: any) => a?.price === propsPrice)?.period === "MONTH"){
            return new Date(Date.now() + 26306e5) 
        } else {
            return new Date(Date.now() + 3170e7)
        }
    } else {
        return new Date(Date.now() + 26306e5)
    }
}

const CheckoutPaymeeComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any> & Props> = (props) => {
    const [paymenturl] = useState<string>("https://sandbox.paymee.tn/gateway/");
    const [random, setRandom] = useState<number>(0);
    const [contract, setContract] = useState<any>()
    const [tokenPaymee, setTokenPaymee] = useState<string>("")
    const [offer,setOffer]=useState<any>()
    const {id} = useParams<Params>();
    useEffect(() => {
        props?.getContract(props?.schoolInfo?.id, "SCHOOL")

    }, [])
    useEffect(() => {
        if(!!props?.contract && props.contract?.length>0){
            setContract(props?.contract[0])
        }
     
    }, [props?.contract])

    useEffect(() => {
     if(!!props?.offer && props?.offer?.id == id){         
        setOffer(props?.offer)
     }
      
 

    }, [props?.offer])
    const createContract = async () => {
        const date = new Date();
        props?.getContract(props?.schoolInfo?.id, "SCHOOL").then(async (respContract: any) => {
            if(!!respContract && !!respContract.data &&  respContract.data.length >0 ){            
                   await axios.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract",
                   {
                    contractIdentifier: {
                        contractReference: respContract?.data[0]?.contractIdentifier?.contractReference
                    },

                    status: [{
                        statusType: "CANCELLED"
                    }]
    
                }
                   ).then(async (respContract: any) => {
                          
                    if (!!offer && !!offer.pricings) {
                        await axios.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract",
                       
                        {
                            contractIdentifier: {
                                contractId: null,
                                contractReference: null
                            },
                            contractDetails: {
                                offerId: offer.id,
                                contractDuration: {
                                    startAt: new Date(Date.now()),
                                    endAt: endDateHandlerFn(offer.pricings,props?.price),
                                },
                                pricingId: !!offer.pricings && offer.pricings.length >1? offer.pricings.find((a: any) => a.price == props?.price)?.id:offer.pricings[0]?.id,
                                paymentMethod: "CARD",
                                paymentEngine: "PAYMEE"
                            },
                            contractRoles: [{
                                personId: props?.schoolInfo?.id,
                                roleType: "SCHOOL"
                            }],
                            status: [{
                                statusType: "ONGOING"
                            }]
            
                        }
        
                    ).then(async (respContract: any) => {
                            await props?.openModal({ idModal: ModalIDS.SUCCESS_SUBSCRIPTION, size: "md", buttonText: "Retourner au platforme", path: "/auth/login", lines: ["Votre abonnement a été bien enregistré"] });
    
                        })
                    }
            
                })
            }
      

        }
        )


    }
    const check = async () => {
        const checkToken = {
            "token": props?.tokenOffer
        }

        await checkPaymentApi(checkToken).then(async(resp: any) => {
            if (resp.data === true) {
                if(!!offer && !! offer.pricings){
                   await axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
                        status: "PAYED",
                        amount: Number(props?.price),
                        pricingsId: !!offer.pricings && offer.pricings.length >1 ?offer?.pricings?.find((a: any) => a?.price == props?.price)?.id:offer?.pricings[0]?.id ,
                        stripeEmail: props?.userProfile.email,
                        personId: props?.userProfile.id,
                        entityId: props?.schoolInfo?.id,
                        currency: "TND",
                        offerId: offer.id
                    }).then(res4 => {
                     createContract(); })
                } 
                
            } else {

            await    axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
                    status: "REFUSED",
                    amount: Number(props?.price),
                    pricingsId: props?.priceId,
                    stripeEmail: props?.userProfile.email,
                    personId: props?.userProfile.id,
                    entityId: props?.schoolInfo?.id,
                    currency: "TND",
                    offerId: offer.id
                }).then(resp => { return resp.data }).catch(err => console.error(err));
            }
        }).catch((err: any) => {
            console.error(err);
        })
    }
    useEffect(() => {
                if (props?.tokenOffer && props?.tokenOffer !== '') { setTokenPaymee(props?.tokenOffer); }

    }, [props?.tokenOffer, props?.paymeePayment])
    useEffect(() => {

        window.addEventListener('message', function (event) {
            if (!!offer && (event.data.event_id === 'paymee.complete') && (!!props?.tokenOffer && props?.tokenOffer !== '')) {
                check();   
            }
        }, false);
    }, [offer])


    return (
        <div>
            {props?.tokenOffer?.length != 0 ?
                <div >
                    <iframe key={random}
                        width="500"
                        height="500"
                        src={`${paymenturl}${props?.tokenOffer}`}
                    >
                    </iframe>
                </div> :
                <div>
                    <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={500} height={500} />

                </div>
            }
        </div>

    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    subscription: state.schoolInfoReducer.subscription,
    contract: state.schoolInfoReducer.contract,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    userProfile: state.authReducer.connectedUserInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getContract,
    checkPayment,
    openModal

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymeeComponent);

