import Skeleton from '@material-ui/lab/Skeleton';
import  { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import "../../translations/i18n";

function ProfileCardComponent(props: any) {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>("");
    const [value, setValue] = useState<any>("-");


    useEffect(() => {
        setTitle(props?.title)
        setValue(props?.value)
    }, [props?.title, props?.value])
   

    if (value != undefined || value != null){
        return (
            <div className='d-flex flex-column' style={{ backgroundColor: "#FFFFFF", borderColor: "#F2F2F5", height: "96px", width: "288px", marginRight: "16px", borderRadius: "3px" }}>
                <div className='mb-2 mt-2' style={{ marginLeft: "24px" }} >
                    <span className="body-md bold neutral-3" style={{ marginTop: "16px" }}>{title}</span>
                </div>
    
                {title != "Role" ?
                    <div style={{ marginLeft: "24px" }}>
                        <span className="body-md bold black-800" >{value} {(title === "Dernière visite" && value!="-") && <span>{t('joursVisit')}</span>}</span>
                    </div> :
                    <div style={{ marginLeft: "24px" }}>
                        
                        <span className={`badge-user badge-${value === t('professeur') ? "primary" : "info"}`}>{value}</span>
                    </div>
                }
            </div>
        )


    } else {
        return (
            <div className='d-flex flex-column' style={{ backgroundColor: "#FFFFFF", borderColor: "#F2F2F5", height: "96px", width: "288px", marginRight: "16px", borderRadius: "3px" }}>
                <Skeleton variant="rect" animation="wave" width={288} height={96} className="skeleton w-100 " />
            </div>
        )
    }
    
            
    
    
}

export default ProfileCardComponent