import { FC } from 'react';
import FilterAllCoursesLoadingComponent from './../filters/filter-all-courses-loading-component';
import Skeleton from '@material-ui/lab/Skeleton';


const TableAllCoursesLoadingComponent: FC = () => {

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <FilterAllCoursesLoadingComponent />
        </div>
        <div className="col-md-12 mt-5">
          <div className="my-3" style={{ backgroundColor: "#FBFBFD" }}>
            <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
              <table className="w-100 custom-table">
                <thead className="mb-4">
                  <tr>
                    <th> <Skeleton variant="text" animation="wave" width={'50%'} className="skeleton data-table" /> </th>
                    <th> <Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table" /> </th>
                    <th className="text-center"> <Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /> </th>
                    <th className="text-center"> <Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /> </th>
                    <th className="text-center with-icon"> <Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /> </th>
                    <th className="text-end badge-text primary"> <Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table ms-auto" /> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-white">
                    <td><Skeleton variant="text" animation="wave" width={'90%'} className="skeleton data-table" /></td>
                    <td><Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table" /></td>
                    <td className="text-center"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                    <td className="text-center"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                    <td className="text-center with-icon"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                    <td className="text-end badge-text primary"><Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table ms-auto" /></td>
                  </tr>
                    <tr className="table-white">
                      <td><Skeleton variant="text" animation="wave" width={'90%'} className="skeleton data-table" /></td>
                      <td><Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center with-icon"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-end badge-text primary"><Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table ms-auto" /></td>
                    </tr> 
                    <tr className="table-white">
                      <td><Skeleton variant="text" animation="wave" width={'90%'} className="skeleton data-table" /></td>
                      <td><Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center with-icon"><Skeleton variant="text" animation="wave" width={'30%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-end badge-text primary"><Skeleton variant="text" animation="wave" width={'80%'} className="skeleton data-table ms-auto" /></td>
                    </tr> 
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div >
    </div>
  );
}

export default TableAllCoursesLoadingComponent;
