
import { EReduxActionTypes, IReduxUpdateAction, IReduxUpdateWebinarAction, IReduxCreateWebinarAction, IReduxGetCalendarAction, IReduxGetWebinarAction, IReduxChangeStatusAction, IReduxGetWebinarByIdAction, IReduxClearCheckAction, IReduxCheckAvailabilityAction, IReduxGetExternalAccountInfoAction, IReduxGetDisconnectFromZoomAction, IReduxGetAddExternalAccountAction } from "../actions/webinar-actions"

export interface IWebinar {

    title: string,
    duration: number,
    subjectType: string,
    subjectIds: any[],
    description: any,
    img: string,
    link: string,
    startDate: string,
    personSet: any[],
    recordingLink: string,
    idSchool: number,
    agenda: boolean
}

export interface Filters {
    subjectType: string,
    statusWebinar: string,
    subjectIds: any[],
    sort: string,
    search: string
}


export interface IReduxWebinarState {
    webinars: any[],
    calendar: any,
    webinarById: any,
    status: any,
    updatedWebinar: any,
    loadingMyWebinars: any,
    isValid: boolean
    zoomAccountInfo:any

}

const initialState: IReduxWebinarState = {
    webinars: [],
    calendar: null,
    webinarById: {},
    status: {},
    updatedWebinar: undefined,
    loadingMyWebinars: undefined,
    isValid: false,
    zoomAccountInfo:undefined

}
type WebinarReducerActions = IReduxUpdateAction | IReduxUpdateWebinarAction | IReduxCreateWebinarAction | IReduxGetWebinarAction | IReduxChangeStatusAction | IReduxGetCalendarAction | IReduxGetWebinarByIdAction
    | IReduxClearCheckAction | IReduxCheckAvailabilityAction | IReduxGetExternalAccountInfoAction| IReduxGetDisconnectFromZoomAction |IReduxGetAddExternalAccountAction
export default function (state: IReduxWebinarState = initialState, action: WebinarReducerActions) {
    switch (action.type) {
        case EReduxActionTypes.LOADING_MY_WEBINARS:
            return { ...state, loadingMyWebinars: true }
        case EReduxActionTypes.CREATE_WEBINAR:
            let webinar = [...state.webinars];
            let filter = action?.data?.filters
            webinar.push(action?.data?.webinar);
            let courses = action?.data?.courses
            let webinars = webinar.filter(obj => {
                let idSubject = courses?.filter((a: any) => obj?.courseTitle === a?.title)[0]?.id
                if ((filter?.statusWebinar === obj?.webinarStatusHistories.sort((a: any, b: any) => (a?.creationDate < b?.creationDate) ? 1 : -1)[0]?.status || filter?.statusWebinar === "") &&
                    (filter?.subjectIds?.length === action?.data?.listCourses?.length || filter?.subjectIds?.length == 0 || filter?.subjectIds?.map((num: string) => { return Number(num) }).includes(idSubject)) &&
                    (obj?.title.includes(filter?.search) || filter?.search === "")) {
                    return true;
                }
                else {
                    return false;
                }
            }).sort((a: any, b: any) => {
                if (a?.startDate > b?.startDate) {
                    return (filter?.sort === 'asc') ? 1 : -1;
                }
                else if (a?.startDate < b?.startDate) {
                    return (filter?.sort === 'asc') ? -1 : 1;
                } else
                    return 0;
            })
            return { ...state, webinars: webinars };
        case EReduxActionTypes.GET_WEBINAR:
            return { ...state, webinars: action.data, loadingMyWebinars: false };
        case EReduxActionTypes.GET_CALENDAR:
            return { ...state, calendar: action.data };
        case EReduxActionTypes.GET_WEBINARBYID:
            if (action.data.where === 'UPDATECALENDAR') {
                const listeWebinars = [...state?.webinars];
                const indexWebinar = listeWebinars.findIndex(obj => obj?.id === action?.data?.webinar?.id);
                listeWebinars[indexWebinar] = action?.data?.webinar;
                return { ...state, webinars: listeWebinars };
            } else {
                return { ...state, webinarById: action?.data?.webinar };
            }

        case EReduxActionTypes.CHANGE_STATUS:
            const liste = [...state.webinars];
            const index = liste.findIndex(obj => obj?.id === action?.data?.id);
            liste[index] = action.data;
            return { ...state, webinars: liste };

        case EReduxActionTypes.UPDATE_WEBINAR:
            const list = [...state.webinars];
            const indexs = list.findIndex(obj => obj?.id === action?.data?.id);
            list[indexs] = action.data;
            return { ...state, webinars: list };
        case EReduxActionTypes.UPDATE:
            return { ...state, updatedWebinar: action.data };
        case EReduxActionTypes.CLEAR_CHECK:
            return { ...state, isValid: action.data };
        case EReduxActionTypes.CHECK_WEBINAR_TIME_AVAILABILITY:
            return { ...state, isValid: action.data.isValid };
        case EReduxActionTypes.GET_EXTERNAL_ACCOUNT_INFO:
                return { ...state, zoomAccountInfo: action.data };
        case EReduxActionTypes.DISCONNECT_FROM_ZOOM:
                return { ...state, zoomAccountInfo: action.data };
        case EReduxActionTypes.ADD_EXTERNAL_ACCOUNT:
                return { ...state, zoomAccountInfo: action.data };
        default:
            return state;
    }

}
