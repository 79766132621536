import { ComponentProps, FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TableAllCouponsComponent from '../../components/tables/table-all-coupons.component';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import { getCoupons } from '../../services/coupon.service'; 
import { AppState } from '../../global-state/store/root-reducers';
import { useHistory } from "react-router";

import { returnStartPathUrl } from '../../helpers/domainCheck';
import TableAllCouponsLoadingComponent from '../../components/tables/table-all-coupons-loading-component';

const AllCouponsPage: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
  const [couponList, setCouponList] = useState<any>([]);
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [couponBody, setCouponBody] = useState<any>(
    ({
      product: "KOORS",
      promotion: {
        promoCode: null,
        promoType: "DISCOUNT",
        promoValue: "",
      },
      referenceCode: null,
      startAt: null,
      expireAt: null,
      status: "DRAFT",
      offers: [],
      entityId: props?.user?.idSchool,
      entityType: "SCHOOL",
    })
  );

  useEffect(() => {
    getCoupons(props?.user?.idSchool,"SCHOOL").then(response => {
      setCouponList(response)
      setLoading(false)
    }


    );
  }, []);
  
  const handleClick = () => {
    setCouponList([]);
    history.push({ pathname: `${returnStartPathUrl()}/coupon/create-coupon` });
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
              <div className="col-md-12 mt-5" >
                <div className="d-flex align-items-center mb-3 " style={{ justifyContent: "space-between" }}>
                  <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left' }}>
                    Coupons
                    <span className="ms-3" style={{ color: "#272727" }}>
                      {couponList?.length}
                    </span>
                  </h1>
                  {couponList?.length > 0 &&
                  <button className="btn-Primary large icon-left" onClick={handleClick}>
                    <span className="material-icons">add</span>
                    <div className="m-auto ps-2">AJOUTER un Coupon</div>
                  </button>}
                </div>
              </div>
            </div>
          </div>
          {couponList?.length > 0 ?
           <TableAllCouponsComponent coupons={couponList} />
          :loading ? 
            <TableAllCouponsLoadingComponent />
           :  <div className="w-100">
            <div className='wrapper-empty-list mt-5 pt-5'>
              <EmptyListElement lines={["Aucun coupon crée"]} >
                <button className="btn-Primary large icon-left" onClick={()=>{handleClick()}}>
                  <span className="material-icons">add</span>
                  <div className="m-auto ps-2">AJOUTER un Coupon</div>
                </button>
              </EmptyListElement>
            </div>
          </div>}
        </div >
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  user: state.authReducer.connectedUser,
});


export default connect(
  mapStateToProps,

)(AllCouponsPage);
