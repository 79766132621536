import {ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import SelectElement from "../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import InputSearch from '../../elements/inputs/input-search';
import { debounce } from "lodash";
import { ModalIDS } from '../../components/modals/modal-ids';
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { openModal } from '../../global-state/actions/modal-actions';

const FilterCommunityProgressComponent: FC<ReturnType<typeof mapStateToProps>& ReturnType<typeof mapDispatchToProps>  &ComponentProps<any>>=(props)=>{
    const { t } = useTranslation();
    const [tab,setTab] = useState<string>("");
    const [trieOption, setTrieOption]= useState<any[]>([]);
    const [trie, setTrie]= useState<string>("recent");
    const [searchFilter, setSearchFilter]= useState<string>("");
    useEffect(() => {
        setTrie("recent");
        setSearchFilter("") 
        setTab(props?.tabChatManager)
        setTrieOption([{value:"recent",label:t('recent') },{value:"oldest", label:t('oldest')},{value:"maxResponses", label:t('maxResponses')}])
    }, [props?.tabChatManager])
    const sort= (trieSearch:string)=>{
        setTrie(trieSearch);
        props?.isSearching({trie: trieSearch, searchFilter: searchFilter});
    }
    const Search= (search:string)=>{
        setSearchFilter(search);
        props?.isSearching({trie: trie, searchFilter: search});
    }
    const debounceSearch = useCallback( debounce(Search, 300), []);
    const openModalFn = () => {
        if(tab==="discussion"){
            props?.openModal({idModal: ModalIDS.ADD_COMMENT_PROGRESS, size: "md", data: {chapter: props?.chapter}});
        }else{
            props?.openModal({idModal: ModalIDS.ADD_QUESTION_PROGRESS, size: "md" , data: {chapter: props?.chapter}});
        }
      
    }
    return(
        <>
         <div className="row">
                <div className="col-md-4  mt-5 d-flex justify-content-start text-right px-0">
                    <button onClick={() => openModalFn()} type="button" className="btn-Secondary large icon-left">
                        <span className="material-icons-outlined">add</span>
                        <div className="m-auto ps-2">{tab==="question"?t('addQuestionButton'):t('addCommentButton')}</div>
                    </button>
                </div>
                <div className="col-md-8  mt-5 d-flex justify-content-end text-right px-0">
                    <div className="select-input me-2" style={{ width: '225px' }}>
                    
                    <SelectElement disabled={false} taille={'lg'} defaultValue={trie} options={trieOption}  placeholder ={""} onChange={sort} /> 
                    </div> 
                    <InputSearch  value={searchFilter}  onChange={debounceSearch} placeholder={t('searchProfessors')}/>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    tabChatManager: state.chatReducer.tabChatManager,
    isSearching: ownProps?.isSearching,
    chapter: ownProps?.chapter
  });
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
      {
        openModal,
      },
      dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FilterCommunityProgressComponent);
   