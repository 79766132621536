import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from './../../global-state/actions/modal-actions';
import { deleteTraining } from './../../global-state/actions/training-actions';

import { clearFormCourse, createCourse, getOfferCourse, getOfferPricingCourse, updateCoursesLists } from '../../global-state/actions/course-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getCountsApi } from '../../services/finance.service';

const statusList = [
    { value: "Published", color: "success" },
    { value: "Draft", color: "primary" },
    { value: "Pending", color: "warning" },
    { value: "Refused", color: "danger" },
    { value: "Deactivate", color: "secondary" },
    { value: "Archive", color: "#B1B1B1" },
    { value: "RequestDeactivation", color: "#F5B95F" },
    { value: "RequestActivation", color: "#F5B95F" },
    { value: "RequestDeletion", color: "#F5B95F" },

]
interface Params {
    type: string;
    id: string;
}
const NavbarCourseComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [courseForm, setCourseForm] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [valide, setValid] = useState<any>(true);
    const { type, id } = useParams<Params>();
    const location = useLocation();
    const [idOffer, setIdOffer] = useState<any>();
    const [errors, setErrors] = useState<any>();
    const [profiles, setProfiles] = useState<any>();

    const getProfiles = async (idEntity: number) =>{
        const result = await getCountsApi(idEntity);
        setProfiles(result)
    }

    useEffect(() => {
        let stat = null;
        if (props?.connectedUser?.role == 'role_director') {
            stat = statusList.find(obj => obj?.value.toUpperCase() == props?.courseInfo?.status)
            setCourseForm(props?.courseInfo);
        } else {
            stat = statusList.find(obj => obj?.value.toUpperCase() == props?.courseForm?.status)
            setCourseForm(props?.courseForm);
        }
        checkConditions(props?.courseForm);
        if (stat) {
            setStatus(stat);
        } else {
            setStatus("");
        }
        if ((courseForm?.status == 'REFUSED') || (courseForm?.status == 'DRAFT')) {
            setValid(false)
        }

    }, [props?.courseForm, props?.courseInfo, props?.courseForm?.orders, courseForm]);

    useEffect(() => {
        if (props?.returnPathPlateform.tabPage == 'publishCourse') {
            props.getOfferPricingCourse(idOffer)
                .then(() => {
                    props?.openModal({ idModal: ModalIDS.ACCEPT_TRAINING, size: 'md' });
                })
        }
    }, [props?.returnPathPlateform])

    useEffect(()=>{
        getProfiles(props?.schoolInfo?.id)
    },[])

    const checkConditions = (course: any) => {
        let check = true;
        const errorsCopy = { ...errors };

        if (course?.orders?.length == 0) {
            errorsCopy['chaptersError'] = t('errorChaptersLength');

            check = false;
        } else {
            errorsCopy['chaptersError'] = '';
        }
        if (course?.linkImg == '') {
            errorsCopy['linkImgError'] = t('linkImgCourseError')
            check = false;
        } else {
            errorsCopy['linkImgError'] = '';
        }
        if (course?.resume == '') {
            errorsCopy['descriptionError'] = t('descriptionCourseError')
            check = false;
        } else {
            errorsCopy['descriptionError'] = "";
        }
        if (course?.title == '') {
            errorsCopy['titleError'] = t('emptyTitleError')
            check = false;
        } else {
            errorsCopy['titleError'] = '';
        }
        setErrors(errorsCopy);
        return { check: check, errors: errorsCopy };
    }

    const verifConditions = () => {
        props?.createCourse(props?.courseForm, props?.connectedUser)
        const check = checkConditions(courseForm);
        if (check.check) {
            props?.openModal({ idModal: ModalIDS.PUBLISH_COURSE_PROF, size: "md" });
        } else {
            props?.openModal({ idModal: ModalIDS.CHECK_FORM_COURSE_CONDITIONS, size: "md", data: { errors: check.errors } });
        }
    }

    const goBackToList = () => {
        if (props?.connectedUser?.role === 'role_director') {
            history.push(`${returnStartPathUrl()}/all-courses`);

        } else if (props?.connectedUser?.role === "role_adminstrator") {
            history.push(`${returnStartPathUrl()}/all-courses/assistant`);
        } else {
            history.push(`${returnStartPathUrl()}/courses/my-courses`);
        }
    }

    const goToPath = (where: string) => {
        if (props?.connectedUser?.role === 'role_director') {
            history.push(`${returnStartPathUrl()}/all-courses/course/${id}/${where}`);
        } else {
            history.push(`${returnStartPathUrl()}/courses/create-formation/course/${where}`);
        }
    }

    const deleteTrainingContent = async () => {
        await props?.deleteTraining("COURSE", props?.courseForm?.id)
        await props?.clearFormCourse()
        history.push(`${returnStartPathUrl()}/courses/my-courses`)
    }

    const openModalFn = async (value: string) => {        
        const valueSchool = props?.trainingSize
        const valueContract = props.trainingLimitation?.find((profile: any) => profile.key == "nb_cours")?.value
       

        if (valueContract == -1 || valueSchool < Number(valueContract))
        {
            switch (value) {
                case 'DEACTIVATE':
                    props?.openModal({ idModal: ModalIDS.DEACTIVATE_TRAINING, size: 'md', data: { type: 'course' } });
                    break;
                case 'PUBLISHED':
                    props?.openModal({ idModal: ModalIDS.REACTIVATE_TRAINING, size: 'md', data: { type: 'course' } });
                    break;
                case 'REFUSED':
                    props?.openModal({ idModal: ModalIDS.REFUSE_TRAINING, size: 'md', data: { type: 'course' } });
                    break;
                case 'ACCEPTED':
                    props.getOfferPricingCourse(idOffer)
                    if (props?.courseTarificationForm?.pricings.length == 0 )
                    {    
                        props?.openModal({ idModal: ModalIDS.CHECK_FORM_TARIFICATION_CONDITIONS, size: 'md', data: { type: 'course' } });
                    }else {
                        props?.openModal({ idModal: ModalIDS.ACCEPT_TRAINING, size: 'md', data: { type: 'course' } });
                    }
                    break;
            }
        }else if ( valueSchool >= valueContract)
        {
            history.push(`${returnStartPathUrl()}/list`);
        }
     
    }

    const checkFreeOfferAndNumberOfCoursesBelowLimit = () => {
        const valueSchool = profiles?.find((profile: any) => profile.key == "nb_cours")?.valueSchool
        const valueContract = profiles?.find((profile: any) => profile.key == "nb_cours")?.valueContract
        if (valueContract == -1) return false

        return valueSchool >= valueContract
    }

    const handleTransfer = async () => {
        await props?.openModal({ idModal: ModalIDS.TRANSFER_TRAINING, size: "md", data: { type: 'course' } });
    }

    const handleTransferDirector = async () => {
        await props?.openModal({ idModal: ModalIDS.TRANSFER_DIRECTOR_TRAINING, size: "md", data: { type: 'course',id:courseForm?.id } });
    }

    useEffect(() => {
        if (props.actualOffer?.id !== undefined && props.actualOffer?.id !== null) {
            setIdOffer(props.actualOffer?.id)
        }
    }, [props.actualOffer]);

    return (
        <>
            <div className="cours-nav">
                <div className="navbar navbar-expand-lg box-shadow-navbar-bredcrumb d-flex justify-content-between p-0 flex-nowrap">
                    <div className="d-flex order-0">
                        <a onClick={() => { goBackToList() }} style={{ cursor: 'pointer' }} className="d-flex justify-content-center align-items-center nav-back">
                            <span className="material-icons-round">
                                chevron_left
                            </span>
                        </a>
                        <div className="d-flex px-4 align-items-center">
                            {(courseForm?.status == 'PUBLISHED' || courseForm?.status == 'DEACTIVATE') && <a style={{ cursor: 'pointer' }} onClick={() => goToPath('students')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/students')) ? '-active' : ''}`}>
                                    {t('Etudiants')}
                                </span>
                            </a>}
                            {((props?.connectedUser?.role == "role_adminstrator" || props?.connectedUser?.role == "role_prof") && (courseForm?.status == 'PUBLISHED')) &&
                                <a style={{ cursor: 'pointer' }} onClick={() => goToPath('comments')} className="d-flex justify-content-center align-items-center mx-1">
                                    <span className={`nav-btn-black${(location.pathname.includes('/comments')) ? '-active' : ''}`}>
                                        {t('comments')}
                                    </span>
                                </a>
                            }
                            <a style={{ cursor: 'pointer' }} onClick={() => goToPath('description')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/description')) ? '-active' : ''}`}>
                                    {t('descriptionTitle')}
                                </span>
                            </a>
                            <a style={{ cursor: 'pointer' }} onClick={() => goToPath('content')} className="d-flex justify-content-center align-items-center mx-1">
                                <span style={{ textTransform: 'capitalize' }} className={`nav-btn-black${(location.pathname.includes('/content')) ? '-active' : ''}`}>
                                    {t('content').charAt(0).toUpperCase() + t('content').slice(1).toLowerCase()}
                                </span>
                            </a>
                            {(courseForm?.status == 'PENDING' || courseForm?.status == 'REFUSED') && <a style={{ cursor: 'pointer' }} onClick={() => goToPath('students')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/students')) ? '-active' : ''}`}>
                                    {t('Etudiants')}
                                </span>
                            </a>}
                            <a style={{ cursor: 'pointer' }} onClick={() => goToPath('inscription')} className="d-flex justify-content-center align-items-center mx-1">
                                <span className={`nav-btn-black${(location.pathname.includes('/inscription')) ? '-active' : ''}`}>
                                    {t('inscriptionTitle')}
                                </span>
                            </a>
                            {props?.connectedUser?.role == "role_director" &&
                                <a style={{ cursor: 'pointer' }} onClick={() => goToPath('tarification')} className="d-flex justify-content-center align-items-center mx-1">
                                    <span className={`nav-btn-black${(location.pathname.includes('/tarification')) ? '-active' : ''}`}>
                                        Tarification
                                    </span>
                                </a>}

                        </div>
                    </div>
                    {props?.connectedUser?.role == "role_director" ?
                        <div className="d-flex justify-content-end order-1 w-100">
                            {
                                (courseForm?.status === 'PENDING') &&
                                <div className="d-flex justify-content-center align-items-center px-1">
                                    <span className={`badge-text ${status?.color}`}>
                                        {t("Avalider")}
                                    </span>
                                </div>
                            }
                              { (courseForm?.idProf != 0 && courseForm?.idProf != null) &&    <a style={{ cursor: 'pointer' }} onClick={() => handleTransferDirector()} className="d-flex justify-content-center align-items-center px-4 nav secondary-action">
                                <span>
                                    Transférer
                                </span>
                            </a>}
                          
                            {((courseForm?.status == 'PUBLISHED' || courseForm?.status == 'DEACTIVATE')) && courseForm?.idProf != 0 && courseForm?.idProf != null &&
                                <div style={{ cursor: 'pointer', border: 'none' }} className={`d-flex justify-content-center align-items-center nav-state-${(courseForm?.status == 'PUBLISHED') ? 'enligne' : 'horsligne'}-prof`}>
                                    <input dir="ltr" readOnly={true} checked={courseForm?.status == 'PUBLISHED'} className="input-style" onClick={() => openModalFn((courseForm?.status == 'PUBLISHED') ? 'DEACTIVATE' : 'PUBLISHED')} type="checkbox" id="toggleBtn" />
                                    <div className="toggle-container" onClick={() => openModalFn((courseForm?.status == 'PUBLISHED') ? 'DEACTIVATE' : 'PUBLISHED')}>
                                        <label className="label-style" htmlFor="toggleBtn">
                                            <span className="on">{t('online')}</span>
                                            <span className="off">{t('offline')} </span>
                                            <input dir="ltr" type="text" style={{ display: 'none' }} />
                                        </label>
                                    </div>
                                </div>
                            }
                            {((courseForm?.status == 'PUBLISHED' || courseForm?.status == 'DEACTIVATE')) && (courseForm?.idProf == 0 || courseForm?.idProf == null) &&
                              <>
                               <div className="d-flex justify-content-center align-items-center px-4">
                                    <span className={`badge-text warning`}>
                                       {t('Aaccepter')}
                                    </span>
                                </div>
                                
                                <button style={{ cursor: 'pointer', border: 'none' }} onClick={() => handleTransfer()} className={`d-flex justify-content-center align-items-center nav publish`}>
                                    <span>
                                        Accepter
                                    </span>
                                </button>
                                </>
                          
                            }
                            {(courseForm?.status == 'PENDING') &&
                                <>
                                   
                                    <button onClick={() => openModalFn('REFUSED')} style={{ cursor: 'pointer', border: 'none' }} className="d-flex justify-content-center align-items-center nav refuse">
                                        <span>{t('refuser')}</span>
                                    </button>
                                    <button
                                        onClick={() => openModalFn('ACCEPTED')}
                                        style={{ cursor: 'pointer', border: 'none' }}
                                        className={`d-flex justify-content-center align-items-center nav publish `}
                                    >
                                        <span>{t('valider')} </span>
                                    </button>
                                </>
                            }
                            {(courseForm?.status == 'REFUSED')
                                &&
                                <div style={{ border: 'none', textTransform: 'capitalize' }} className="d-flex justify-content-center align-items-center nav-state-refused">
                                    <span>{t('refuser_1').toLowerCase()}</span>
                                </div>
                            }
                        </div>
                        : <div className="d-flex justify-content-end order-1 w-100">
                            <div className="d-flex justify-content-center align-items-center px-4">
                                <span className={`badge-text ${status?.color}`}>
                                    {t(status?.value)}
                                </span>
                            </div>

                            {(courseForm?.status == 'DRAFT') && <a style={{ cursor: 'pointer' }} onClick={() => { deleteTrainingContent() }} className="d-flex justify-content-center align-items-center px-4 nav secondary-action">
                                <span>
                                    {t('delete')}
                                </span>
                            </a>}
                            <button style={{ cursor: 'pointer', border: 'none' }} disabled={valide} onClick={() => verifConditions()} className={`d-flex justify-content-center align-items-center nav publish ${(courseForm?.status == 'DRAFT' || courseForm?.status == 'REFUSED') ? '' : 'disabled'}`}>
                                <span>
                                    {t('publishMyReview')}
                                </span>
                            </button>
                        </div>
                    }
                </div>
            </div>

        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    loggedIn: state.authReducer.loggedIn,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    historyPathProfesseurs: state.historyReducer.listHistoryProfesseur,
    lastPath: state.historyReducer.lastPathBeforeBreadCrumb,
    courseForm: state.courseReducer.courseForm,
    courseInfo: state.courseReducer.courseInfo,
    returnPathPlateform: state.historyReducer.returnPathPlateform,
    trainingLimitation : state.schoolInfoReducer.subscription.limitations,
    trainingSize: state.courseReducer.LengthCoursesPublished,
    actualOffer: state.courseReducer.actualOffer,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            updateCoursesLists,
            getOfferPricingCourse,
            openModal,
            getOfferCourse,
            deleteTraining,
            clearFormCourse,
            createCourse,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(NavbarCourseComponent);