

import { useState } from 'react';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
  export default function ColorSelector(props: any) {
    const { t } = useTranslation();
    const [clicked, setClicked] = useState(false);
    const [color, setColor] = useColor("hex",!!props?.color?props?.color:"#272727");
    const update=async(e: any)=>{
      e.stopPropagation();
       setClicked(!clicked);

      await props?.update(color.hex)
    }

    return <>
    <ClickAwayListener onClickAway={() => setClicked(false)}>
    <div className="container-fluid row justify-content-center">
      <div className="col-md-12 d-flex align-items-center justify-content-center color-picker">
        {clicked && <div onClick={(e) => e.stopPropagation()} className="m-auto color-picker selector" style={{'backgroundColor': 'white'}}><ColorPicker width={330} height={244} hideHSV={true} color={color} onChange={(e:any) => { setColor(e);props?.update(e.hex)}} /></div>}
      </div>
      <div className="col-md-12 color-picker button">
        <button id="buttonColor" type="button" className="btn-Primary large d-flex align-items-center justify-content-center  color-picker button" style={{ backgroundColor: color.hex }} value={color.hex} onClick={(e) => { update(e) }}  >
          {clicked && <div className="clip-path w-50 m-auto"></div>}
          {t('AddYourColorToPersonalizeThePresentationOfTheCourses')}</button>
      </div>
    </div>
    </ClickAwayListener>
    </>
  }