import { FC, useEffect, useMemo, useState, ComponentProps } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import userLogo from '../../styles/imgs/Default_user_icon.svg';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useHistory, useParams } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { goToProfesseurs, clearHistoryProfesseurs } from '../../global-state/actions/breadcrumb-actions';
import { getCoursesProf } from '../../global-state/actions/catalog-actions';
import { affectOtherProf, reinstateProf } from '../../global-state/actions/course-actions';
import iconSuccess from '../../styles/imgs/icon-success.png';
import axios from '../../helpers/interceptors';
import TableElement from '../../elements/table/normal-table-element';
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import { ModalIDS } from '../../components/modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import TableProfessorsListLoadingComponent from '../../components/tables/table-professors-list-loading-component';
import { returnStartPathUrl } from '../../helpers/domainCheck';
interface DetailsProf {
    id: any
}
const ProfessorSheetComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { id } = useParams<DetailsProf>();
    const history = useHistory()
    let [name, setName] = useState();
    let [commentReinstate, setCommentReinstate] = useState('');
    let [photo, setPhoto] = useState('');
    let [lastVisit, setLastVisit] = useState()
    let [bio, setBio] = useState<any>();
    let [email, setEmail] = useState("");
    let [status, setStatus] = useState("");
    let dateFormat = require('dateformat');
    let [coursOfProf, setCoursOfProf] = useState<any>();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();



    const headers = [
        { title: t('TitleFormations'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Inscrits'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Diplômés'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Candidats'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Tarif'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Statut'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const statusList: any = [

        { label: t('toValidate'), value: "PENDING", color: "warning" },
        { label: t('Published'), value: "PUBLISHED", color: "primary" },
        { label: t('Archive'), value: "ARCHIVE", color: "secondary" },
        { label: t('Refused'), value: "REFUSED", color: "danger" },
        { label: t('Deactivate'), value: "DEACTIVATE", color: "dark" }
    ]


    let [inscription, setInscription] = useState();

    useEffect(() => {
        let prof = props?.profs?.find((x: any) => x?.id === parseInt(id));
        setName(prof?.name)
        setPhoto(prof?.photo)
        setLastVisit(prof?.lastVisit)
        setBio((prof?.pp === null) ? undefined : prof.pp)
        setEmail(prof?.email);
        setStatus(prof.status);
        setInscription(dateFormat(prof?.inscription === null ? "-" : prof?.inscription, "dd/mm/yyyy"))
        props?.clearHistoryProfesseurs()
        props?.goToProfesseurs({ title: prof?.name, path: '/professors/all', index: null });


    }, [])
    useEffect(() => {
        let prof = props?.profs?.find((x: any) => x?.id === parseInt(id));
        setStatus(prof.status);
        props?.clearHistoryProfesseurs()


    }, [props?.profs])
    useEffect(() => {
        const b: any = document.getElementById('professorDetails');
        b.style.height = "1px";
        b.style.height = (12 + b.scrollHeight) + "px";
    }, [bio]);
    useEffect(() => {
        let filter = {
            entityId: props?.user?.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: id,

        }
        props?.getCoursesProf(filter)

    }, []);


    useEffect(() => {
        setCoursOfProf(props?.profCourseList)
    }, [props?.profCourseList]);
    useEffect(() => {
        setLoading(props?.loadingCourse)
    }, [props?.loadingCourse]);

    const doAction = (obj: any) => {

        history.push(`${returnStartPathUrl()}/courses/details-course/${props?.profCourseList[obj?.index].id}`)


    }
    const data: any = useMemo(() => {
        return {
            headers,
            body: props?.profCourseList?.map((course: any) => {
                let statusCourse = statusList.find((e: any) => e?.value === course?.status)
                return {
                    row: {

                        classNames: "table-white",
                        columns: [
                            { content: course?.title, classNames: "", actionName: '' },
                            { content: (course?.type === "PATH") ? t('parcoursTitle') : (course?.type === "COURSE") ? t('Course') : t('sessionTitle'), classNames: "text-center", actionName: '' },
                            { content: course?.nbrRegistered, classNames: "text-center", actionName: '' },
                            { content: course?.nbrDiplomas, classNames: "text-center", actionName: '' },
                            { content: course?.nbrCandidate, classNames: "text-center" },
                            { content: course?.price?.price?.price === 0 ? "Gratuit" : course?.price?.price?.price, classNames: "text-center", actionName: '' },
                            { content: statusCourse?.label, classNames: `text-end badge-text ${statusCourse?.color}`, actionName: '' }
                        ]
                    }
                }

            })

        }






    }, [props?.profCourseList])
    const textAreaAdjust = (element: any, tag: string) => {
        element.style.height = "1px";
        element.style.height = (12 + element.scrollHeight) + "px";
        if (element.value !== "") {
            let chaine = element.value
            setBio(chaine);
        }

        else
            setBio(undefined);
    }
    const handleBlur = () => {
        const baseUrl = process.env.REACT_APP_BASE_URL2;

        axios.post(baseUrl + "person/updatebioprof", {
            "id": id,
            "bio": bio === undefined ? undefined : bio,
            "email": email
        })

    }
    const affectOtherProfButton = async () => {
        await props?.openModal({ idModal: ModalIDS.DEACTIVATE_PROF, size: "md", data: { prof: { name: name, id: id } } })

    }
    const reinstateProfButton = async () => {
        await props?.openModal({ idModal: ModalIDS.REINTEGRATE_PROF, size: "md", data: { prof: { name: name, id: id } } })
    }

    return (
        <>
            <div style={{ marginTop: 64 }}>



                <div style={{ backgroundColor: '#FFFFFF', paddingBottom: 24 }}>
                    <div className="px-4 mx-auto py-3" style={{ maxWidth: '1248px' }} >
                        <div className="d-flex flex-row justify-content-between my-4">
                            <div className="d-flex align-items-center">

                                <div>
                                    <img style={{ borderRadius: "50%", width: "120px", objectFit: 'fill' }} src={photo ? photo : userLogo} />
                                </div>
                                <div className="ms-4 ps-3">
                                    <div className="d-flex flex-column">


                                        <span className="H2-Headline">{name} </span>



                                        <div className="d-flex flex-row me-3 mt-2">
                                            <span className="body-sm bold neutral-3 me-2">{t('Email')}</span>
                                            <span className="body-sm bold black-800"> {email}</span>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap mt-2">
                                            <div className="d-flex flex-row me-3">
                                                <span className="body-sm bold neutral-3 me-2">{t('inscriptionTitle')}</span>
                                                <span className="body-sm bold black-800">{"  " + inscription === null ? "-" : inscription + "    "}</span>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <span className="body-sm bold neutral-3 me-2">{t('lastVisitProfile_1')}</span>
                                                <span className="body-sm bold black-800">{lastVisit} {t('joursVisit')}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className=" justify-content-end align-items-start d-flex" style={{ display: 'flex', alignItems: 'center' }}>

                                {(status === 'En attente') ?
                                    <button className="btn-Primary medium" >{t('relaunchProfInvitation')}</button>
                                    :



                                    <div className="justify-content-end align-items-center  d-flex">


                                        {(status === 'Admis') &&

                                            <input dir="ltr"className="input-style" type="checkbox" readOnly={true} style={{ display: 'none' }} id="toggleBtn" onClick={() => { affectOtherProfButton() }} checked={true} />}
                                        {(status === 'Suspendu') &&
                                            <input dir="ltr"className="input-style" type="checkbox" readOnly={true} style={{ display: 'none' }} id="toggleBtn" onClick={() => { reinstateProfButton() }} checked={false} />}

                                        <div className="toggle-container">
                                            <label className="label-style" htmlFor="toggleBtn">
                                                <span className="on">{t('Admis')}</span>
                                                <span className="off">{t('Suspendu')}</span>


                                                <input dir="ltr"type="text" style={{ display: 'none' }} />
                                            </label>
                                        </div>

                                    </div>





                                }
                            </div>
                        </div>




                    </div>

                    <div className="d-flex flex-column mx-auto my-5 mb-3" style={{ maxWidth: '1248px', borderRadius: 3, border: "1px solid #DFDFDF" }}>

                        <div style={{ maxWidth: '792px', alignItems: "center", justifyContent: "center" }} className="mx-auto" >
                            <div className=" mt-4" >
                                <div className="col-md-12 text-left" >
                                    <span className="H4-Subtitle">{t('descriptionProfLabel')}</span>

                                </div>
                                <div className="col-md-12 mt-3 mb-3">
                                    <textarea style={{ fontSize: "16px" }} value={bio} onChange={(e) => textAreaAdjust(e.target, 'text')} id="professorDetails" className="textaria-textField" onBlur={handleBlur} placeholder={t('textAreaPlaceholder')} ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row justify-content-center mt-3">
                    <div className=" me-0 ms-0 pe-0 ps-0  mt-3">
                        {loading ? <>
                            <TableProfessorsListLoadingComponent />
                        </> : <>{data?.body?.length > 0 && <>

                            <TableElement data={data} doAction={doAction} />


                        </>}
                            {coursOfProf?.length === 0
                                && <div className="col-md-12 text-center">

                                    <EmptyListElement lines={[t('noCoursesCreated')]} >

                                    </EmptyListElement>
                                </div>
                            }</>}
                    </div>
                </div>
            </div>

        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    profs: state.profReducer.profList,
    connectionUser: state.authReducer.connectedUser,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    profCourseList: state.catalogReducer.profCourseList,
    user: state.authReducer.connectedUser,
    loadingCourse: state.catalogReducer.loadingCourse
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            goToProfesseurs,
            clearHistoryProfesseurs,
            affectOtherProf,
            reinstateProf,
            getCoursesProf,
            openModal
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(ProfessorSheetComponent);