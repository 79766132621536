import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../../global-state/actions/profile-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import axios from '../../../helpers/interceptors';
import { getStudents, getStudentInfoById } from '../../../global-state/actions/course-actions';
import TableElement from '../../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const StudentsCertifiedInfoTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const iconCourse = <svg className="me-2" width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
        </g>
        <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
        <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
            </clipPath>
        </defs>
    </svg>;
    const iconPath = <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
            fill="#FA83EE" />
    </svg>
    const iconSession = <svg className="me-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
        </g>
        <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
        <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
        <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
            </clipPath>
        </defs>
    </svg>
    const [sizeCertified, setSizeCertified] = useState(20);
    const [certifiedDataLength, setCertifiedDataLength] = useState<any>(null);
    const [certifiedData, setCertifiedData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false)
    const [emptysearch, setemptysearch] = useState<any>(false);
    const certifiedHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-17", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('TitleFormations'), sorted: true, classNames: "w-30", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('certifiedTitle'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('obtentionPeriod'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('diplomeButton'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto me-4" },
    ]
    const dateFormat = require('dateformat');
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    useEffect(() => {
      
        if (!props?.data?.searchCertified && props?.studentsList?.list?.length === 0 && props?.followCount?.CERTIFIED > 0) 
        {
            setemptysearch(true)
        }else{
            setemptysearch(false)
        }
    }, [props?.data])

    const handleScroll = async () => {
        if (!(atBottom) && ((window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight) && certifiedDataLength > sizeCertified) {
            setAtBottom(true)
            props.getNextPageCertified()
        }
        if (!(((window.innerHeight + window.scrollY) -5) < document.documentElement.scrollHeight)) {
            setAtBottom(false);
        }
        if(certifiedDataLength < sizeCertified){
            setAtBottom(false);
        }
    }

    useEffect(() => {
        setSizeCertified(props?.data?.sizeCertified);
    }, [props?.data])

    useEffect(() => {
        const list = {
            headers: certifiedHeaders,
            body: props?.studentsList?.list.map((student: any) => {
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: <div className="d-flex align-items-center">
                                    {(student?.type === 'COURSE') ? iconCourse : (student?.type === 'PATH') ? iconPath : iconSession}
                                    <span className="ms-2">{student?.courseTitle}</span></div>, classNames: "", actionName: ''
                            },
                            { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: dateFormat(student?.certificationDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: t('In') + " " + Math.ceil((new Date(student?.certificationDate).getTime() - new Date(student?.inscriptionDate).getTime()) / (1000 * 3600 * 24)) + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
                            {
                                content: <button style={{ position: "absolute", bottom: 20, right: 16 }} type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> download </span>
                                    <div className="m-auto ps-2">{t('diplomeButton')}</div></button>, classNames: "text-end with-icon p-0 m-0 relative-position", actionName: 'downloadCertification'
                            },
                        ]
                    }
                }
            })
        }
        setCertifiedData(list);
        setCertifiedDataLength(props?.studentsList?.length);
        setLoadingTable(false)
    }, [props?.studentsList])

    const downloadFile = (e: any, link: string) => {
        let filename = link.split('/')[3];
        axios.post(`${process.env.REACT_APP_BASE_URL4}download/file`, { filename: filename }, { responseType: 'blob' }).then((res: any) => {
            const urlLink = window.URL.createObjectURL(new Blob([res.data]));
            const linkHtml = document.createElement('a');
            linkHtml.href = urlLink;
            linkHtml.setAttribute('download', decodeURIComponent(filename));
            document.body.appendChild(linkHtml);
            linkHtml.click();
        }).catch(err => console.error(err));
    }

    const doAction = async (value: any) => {
        if (value.action === 'goToPath') {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray()
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
            history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
        if (value.action === 'downloadCertification') {
            downloadFile(null, value.row.student.linkCertification);
        }
    }

    return (<>
        {!loadingTable ?
        <div>
            {(certifiedData?.body?.length > 0) && 
            <>
                <TableElement data={certifiedData} doAction={(value: any) => { doAction(value) }} />
                {(atBottom )
                    && <div className="d-flex w-100 align-items-center justify-content-center">
                        <div className="m-3">
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div>
                    </div>}
                </>
                }
                {(certifiedData?.body?.length === 0 && !props?.data?.searchCertified && !emptysearch) &&
                    <div className="w-100 text-center mt-5 pt-5">
                        <EmptyListElement lines={[t('noCertifedFound')]} />
                    </div>
                }
                    {(emptysearch) &&
                        <div>
                            <TableStudentsListLoadingComponent />
                        </div>
                    }
                            {(certifiedData?.body?.length === 0 && props?.data?.searchCertified) &&
                    <div className="w-100 text-center mt-5 pt-5">
                        <EmptyListElement lines={[t("noStudentFound_2"),t("noProfessorsList_2")]} />
                </div>}
            </div>:
            <div>
                <TableStudentsListLoadingComponent />
            </div>}
    </>);
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    addDataList: ownProps?.addDataList,
    loadStudentsCertified: state.profReducer.loadStudentsCertified,
    data: ownProps?.data
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudents,
            getStudentInfoById,
            getStudent,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsCertifiedInfoTableComponent);