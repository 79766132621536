/* eslint react/no-did-mount-set-state: 0 */
import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { updatePath } from '../../../../global-state/actions/breadcrumb-actions'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { uploadImageCourse } from '../../../../global-state/actions/course-actions';
import { clearFormVideo } from '../../../../global-state/actions/upload-video-course-actions';
import { useTranslation } from 'react-i18next';
import DescriptionSessionFormComponent from '../../../../components/formation-session-form/description-session-form-component';
import HeaderTrainingFormComponent from '../../../../components/formations-card/header-formation-form';
import PreviewSessionPageFromProf from '../../../catalog/preview-session-page-from-prof';
import axiosInstance from '../../../../helpers/interceptors';
import { useParams } from "react-router-dom";

interface Params {
    idSession: string
}
const DescriptionSessionFormPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const { t } = useTranslation();
    const [copyDate, setCopyDate] = useState();
    const [modeVision, setModeVision] = useState<boolean>(false);
    const [trainingOffer, setTrainingOffer] = useState(null)
    const session = useMemo(() => {return props?.session;}, [props?.session])
    const { idSession } = useParams<Params>();

    const getTrainingPricing = async () => {
        const baseUrl3 = process.env.REACT_APP_BASE_URL11
            axiosInstance.get(baseUrl3 + `offers/offerId/` + props?.session?.id.toString())
            .then(res => {
                if (res?.data && res?.data !== "") {
                    axiosInstance.get(baseUrl3 + `offers/getOffer/` + res?.data).then(offerResponse => {
                        setTrainingOffer(offerResponse?.data)
                    }).catch((error: any) => {
                        console.error(error)
                    })
                }
            }).catch((error: any) => {
                console.error(error)
            })
    }

    useEffect(() => {
        setCopyDate(props?.session?.creationDate.substring(0, 10).split("-").reverse().join("/"));
        if(props?.session?.id !==null && props?.session?.id !==undefined && props?.session.id ===idSession){
            getTrainingPricing()
        }
        
    }, [props?.session])




    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF' }} className="w-100">
                <HeaderTrainingFormComponent type={"SESSION"} title={t('descriptionTitle')} status={session?.status} trainingTitle={session?.title} creationDate={copyDate}>
                    <div>
                        <input dir="ltr" readOnly className="input-style" type="checkbox" id="toggleBtn4" onClick={() => setModeVision(!modeVision)} checked={modeVision} />
                        <div className="toggle-container cours-editing">
                            <label className="label-style cours-editing" htmlFor="toggleBtn4">
                                <span className="on cours-editing">{t('editeMode')} </span>
                                <span className="off cours-preview">{t('Previsualiser')}</span>
                                <input dir="ltr" type="text" style={{ display: 'none' }} />
                            </label>
                        </div>
                    </div>
                </HeaderTrainingFormComponent>
            </div>
            {!modeVision ? <DescriptionSessionFormComponent /> : <PreviewSessionPageFromProf sessionInfoFromProf={props?.session} trainingOfferFromSession={props?.courseTarificationForm?.pricings} />}
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            uploadImageCourse,
            updatePath,
            clearFormVideo,
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    session: state.trainingReducer.session,
    courseTarificationForm: state.courseReducer.courseTarificationForm

});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionSessionFormPage);