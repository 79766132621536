import React, { FC } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { handleChangeTitleDescSondage } from '../../global-state/actions/sondage-actions'

const InputSondageComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ title, placeholder, htmlForId, classNamePlaceholder, bg, handleChangeTitleDescSondage, from }: any) => {

  return (
    <div className={`container-input-sondage ${bg}`}>
      <div className="input-sondage">
        <div className="title">
          <div className="d-flex flex-column w-100">
            <label htmlFor={htmlForId}>{title}</label>
            {htmlForId == "inputTitleSondage" &&
              <span className="H2-Headline neutral-4">{placeholder}</span>
            }
            {htmlForId == "inputDescSondage" &&
              <span className="body-md">{placeholder}</span>
            }


          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  title: ownProps.title,
  placeholder: ownProps.placeholder,
  htmlForId: ownProps.htmlForId,
  classNamePlaceholder: ownProps.classNamePlaceholder,
  bg: ownProps.bg
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    handleChangeTitleDescSondage
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputSondageComponent);