import { ComponentProps, FC } from 'react';
import { Route } from 'react-router';
import LoginPage from './login-page';
import MailValidationPage from './mail-validation-page';
import NewPasswordPage from './new-password-page';
import RecoverPasswordPage from './recover-password-page';
import RegisterPage from './register-page'
import { useTranslation } from "react-i18next";
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import CustomSwitch from '../../helpers/custom-switch';
import PlatfomUpdatePage from '../platformUpdate/platfomUpdatePage';
import signInPage from './sign-in-page';
import signUpPage from './sign-up-page';

const Auth: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
    const {t} = useTranslation()
    return (
        <div className="d-flex " style={{ height: '100%' }}>
            <>
                <div className="auth-sidelogo d-flex align-items-center justify-content-center p-5">
                    <div className='d-flex flex-column m-2'>
                        <svg width="81" height="87" viewBox="0 0 81 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40 7L40.7988 13.7826C42.8015 30.7885 56.2115 44.1985 73.2174 46.2012L80 47L73.2174 47.7988C56.2115 49.8015 42.8015 63.2115 40.7988 80.2174L40 87L39.2012 80.2174C37.1985 63.2115 23.7885 49.8015 6.78264 47.7988L0 47L6.78264 46.2012C23.7885 44.1985 37.1985 30.7885 39.2012 13.7826L40 7Z" fill="white"/>
                        <path d="M66.2732 0.545471L66.4874 2.36445C67.0245 6.92512 70.6208 10.5214 75.1815 11.0585L77.0004 11.2727L75.1815 11.487C70.6208 12.0241 67.0245 15.6204 66.4874 20.181L66.2732 22L66.059 20.181C65.5219 15.6204 61.9255 12.0241 57.3649 11.487L55.5459 11.2727L57.3649 11.0585C61.9256 10.5214 65.5219 6.92512 66.059 2.36445L66.2732 0.545471Z" fill="#F5B95F"/>
                        <path d="M73.8182 22L73.9543 23.1562C74.2957 26.0549 76.5815 28.3407 79.4802 28.6821L80.6364 28.8182L79.4802 28.9544C76.5815 29.2957 74.2957 31.5815 73.9543 34.4803L73.8182 35.6364L73.682 34.4803C73.3407 31.5815 71.0549 29.2957 68.1561 28.9544L67 28.8182L68.1561 28.6821C71.0549 28.3407 73.3407 26.0549 73.682 23.1562L73.8182 22Z" fill="#F5B95F"/>
                        </svg>
                        <span className='H5-Subtitle mt-5'>{t('transformKnowledge')}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-center auth-details px-4">
                    <CustomSwitch>
                    <Route exact={true} path={`${props?.match.path}/login`} component={LoginPage} />
                    <Route exact={true} path={`${props?.match.path}/register/:token/:role`} component={RegisterPage} />
                    <Route exact={true} path={`${props?.match.path}/register`} component={RegisterPage} />
                    <Route exact={true} path={`${props?.match.path}/forgotpassword`} component={RecoverPasswordPage} />
                    <Route exact={true} path={`${props?.match.path}/resetpassword/token/:token`} component={NewPasswordPage} />
                    <Route exact={true} path={`${props?.match.path}/validateMail/token/:token`} component={MailValidationPage} />
                    <Route exact={true} path={`${props?.match.path}/update`} component={PlatfomUpdatePage} />
                    <Route exact={true} path={`${props?.match.path}/sign-in`} component={signInPage} />
                    <Route exact={true} path={`${props?.match.path}/sign-up`} component={signUpPage} />
                    </CustomSwitch>
                </div>
            </>
        </div>
    );
    
}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn
});

export default connect(mapStateToProps, null)(Auth);