import axios from './interceptors';


export const exportListCourses = async(filterCourse: any, name:string,lang:any ) => {
   
        axios({
            url: `${process.env.REACT_APP_BASE_URL14}training/export/${name}/${lang}`,
            method: 'POST',
            responseType: 'blob',
            data: filterCourse,
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.csv');
            document.body.appendChild(link);
            link.click();
        })
  
}
