import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { reinstateTeamMember } from '../../../global-state/actions/professor-actions';
import { ModalIDS } from '../modal-ids';
import { getProfessor } from '../../../global-state/actions/professor-actions';
import { getCoursesProf } from '../../../global-state/actions/catalog-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';

const ReintegrateProfModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    let [commentReinstate, setCommentReinstate] = useState('');
    const [prof, setProf] = useState(props?.content?.data?.prof);
    useEffect(() => {
        setProf(props?.content?.data?.prof)
    }, [props?.content])

    const reinstateProfFN = async () => {
        let code = getDomainFromUrl()
        let object = {
            comment: commentReinstate,
            code: code,
            idSchool: props?.connectionUser.idSchool,
            courses: [],
            messageReinstateProf: "",
        }
        let filter = {
            entityId: props?.connectionUser.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: prof.id,

        }
        await props?.reinstateTeamMember(prof?.id, props?.connectionUser.idPerson, object, "PROF")
        await props?.getProfessor(props?.connectionUser.idSchool, '')
        await props?.getCoursesProf(filter)
        setCommentReinstate('');
        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToProfProfile'), path: "", lines: [t('successReinstateProf')] });
    }
    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden'}}>
                <div className="row body-modal-publish">
                    <div className="col-md-9 p-0 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 ">
                                <span className="H3-Headline"> {t('ReintegrateProf')}</span>
                                <br />
                                <span className="H3-Headline bold neutral-3"> {prof?.name} </span>

                            </div>

                            <div className="col-md-12 mt-5 ">
                                <span className="body-sm">{t('ReintegrateProfText')}</span>
                            </div>
                            <div className="col-md-12 mt-5 input-full">
                                <label className="form-title">{t('modalPublishCourse_2_2')}</label>


                                <div className="col-md-12 mt-3 ">
                                    <textarea style={{  maxHeight:'75px',fontSize: "16px" }} rows={1} value={commentReinstate} onChange={(e) => { setCommentReinstate(e.target.value) }} placeholder={t('commentSuspendInputProf')} className="textaria-textField"  ></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer p-0" style={{ paddingTop: 0, border: "none" }}>
                <div className="row mt-3 mb-5 w-100">
                    <div className='col-md-9 m-auto p-0 text-center justify-content-end buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large" onClick={() => { setCommentReinstate(''); props?.closeModal() }} style={{ textTransform: 'uppercase', marginRight: '2%' }} >{t('annuler')}</button>
                        <button type="button" className="btn-Primary large success" onClick={() => { reinstateProfFN(); }} disabled={(commentReinstate === '')}>{t('AcceptProf')}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            reinstateTeamMember,
            getProfessor,
            getCoursesProf

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ReintegrateProfModalComponent);