import { AppState } from '../../../../global-state/store/root-reducers';
import { ComponentProps, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";


const DiplomaSidebarComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const overallProgress = useMemo(() => { return props?.overallProgress }, [props?.overallProgress]);

    return (
        <>
            <li key={props?.index} style={{ cursor: (overallProgress !== 100) ? 'pointer' : 'default' }}
                className={` component-chapters-progress-chapters-list-chapter${(props?.open === true) ? "" : " close"}
                component-chapters-progress-chapters-list-chapter${(overallProgress <100) ? " locked" : (props?.sectionContent?.index === props?.index && (overallProgress === 100)) ? "-current-done-diplom" : "-done-diplom"}`}
                onClick={() => { (overallProgress ===100) && props?.displayContent(props?.content, props?.index, "diploma") }}>
                <div className={` ${(props?.open === true) ? "component-chapters-progress-description" : "component-chapters-progress-description close"}`}>
                    <div className="d-flex flex-column">
                        <span className="component-chapters-progress-chapters-list-chapter-title">{t('diplomeButton')}</span>
                        <span className="component-chapters-progress-chapters-list-chapter-description mt-auto">{props?.title}</span>
                    </div>
                </div>
                <div className="component-chapters-progress-chapters-list-chapter-progress-container diplom">
                    <div className="component-chapters-progress-chapters-list-chapter-progress-icon">
                        {(overallProgress ===100) && <div className={`component-chapters-progress-chapters-list-chapter-progress-test-done`}></div>}
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-svg diplom" width="20" height="20" viewBox="0 0 20 20" >
                            <path d="M15.7023 4.92903V1.77235C15.7023 0.79179 14.8927 0 13.8712 0H1.84732C0.831296 0 0 0.79179 0 1.77235V15.5212C0 16.507 0.831296 17.304 1.84732 17.304H10.8666V19.2651C10.8666 19.5326 11.0242 19.7738 11.2632 19.9049C11.5023 20.036 11.8011 20.0307 12.0348 19.8944L14.6917 18.3475L17.3485 19.8944C17.4681 19.9626 17.6039 19.9993 17.7452 19.9993C17.8756 19.9993 18.0168 19.9678 18.1309 19.9049C18.37 19.7738 18.5276 19.5326 18.5276 19.2651V13.4971C19.4512 12.5743 20 11.3263 20 9.94719C19.9946 7.46695 18.1472 5.39047 15.7023 4.92903ZM10.8666 15.8358H1.84732C1.66802 15.8358 1.52133 15.6995 1.52133 15.5212V1.77235C1.52133 1.59931 1.66802 1.46822 1.84732 1.46822H13.8712C14.0505 1.46822 14.1809 1.59931 14.1809 1.77235V4.85037C11.5186 5.09158 9.37789 7.28342 9.37789 9.94195C9.37789 11.321 9.94295 12.5743 10.8666 13.4919V15.8358ZM17.0062 17.9647L15.0992 16.8583C14.8547 16.7167 14.5395 16.7167 14.295 16.8583L12.3879 17.9647V14.5564C13.0943 14.8762 13.8767 15.0597 14.6971 15.0597C15.5175 15.0597 16.2999 14.8815 17.0062 14.5564V17.9647ZM14.6971 13.5915C12.6161 13.5915 10.9209 11.9555 10.9209 9.94719C10.9209 7.93888 12.6161 6.30286 14.6971 6.30286C16.778 6.30286 18.4732 7.93363 18.4732 9.94719C18.4732 11.9555 16.778 13.5915 14.6971 13.5915Z" />
                            <path d="M9.83425 3.88037H2.98828V5.34859H9.83425V3.88037Z" />
                            <path d="M7.60659 7.91797H2.98828V9.38619H7.60659V7.91797Z" />
                            <path d="M8.63892 11.9556H2.98828V13.4238H8.63892V11.9556Z" />
                        </svg>
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-left diplom" width="23" height="42" viewBox="0 0 23 42" fill="none">
                            <path d="M21.0968 2.46383C10.8218 2.46383 2.49219 10.7936 2.49219 21.0688C2.49219 31.3441 10.8218 39.6738 21.0968 39.6738" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                        <svg className="component-chapters-progress-chapters-list-chapter-progress-arc-right diplom" width="23" height="42" viewBox="0 0 23 42" fill="none">
                            <path d="M2.10287 39.6739C12.3755 39.6739 20.7031 31.3461 20.7031 21.0733C20.7031 10.8004 12.3755 2.47266 2.10287 2.47265" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            </li>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    course: state.studentTraining.currentCourse,
    content: ownProps?.content,
    index: ownProps?.index,
    open: ownProps?.open,
    displayContent: ownProps?.displayContent,
    title: ownProps?.title,
    sectionContent: state.studentTraining.sectionContentDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(DiplomaSidebarComponent);

