import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IReduxFormState } from "./../reducers/form-reducer";

export enum EReduxActionTypes {
  ACTIVATE_COUPON = "ACTIVATE_COUPON",
  DESACTIVATE_COUPON = "DESACTIVATE_COUPON",
  CANCEL_COUPON = "CANCEL_COUPON"
}

export interface IReduxBaseAction {
  type: EReduxActionTypes;
  data?: any;
}
export interface IReduxDesactivateCouponAction extends IReduxBaseAction {
  type: EReduxActionTypes.DESACTIVATE_COUPON;
}
export interface IReduxActivateCouponAction extends IReduxBaseAction {
  type: EReduxActionTypes.ACTIVATE_COUPON;
}
export interface IReduxCancelCouponAction extends IReduxBaseAction {
  type: EReduxActionTypes.CANCEL_COUPON;
}
export function activateCoupon() {
  return async (
    dispatch: ThunkDispatch<IReduxFormState, undefined, IReduxBaseAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.ACTIVATE_COUPON
    });
  };
}
export function desactivateCoupon() {
  return async (
    dispatch: ThunkDispatch<IReduxFormState, undefined, IReduxBaseAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.DESACTIVATE_COUPON
    });
  };
}
export function cancelCoupon() {
  return async (
    dispatch: ThunkDispatch<IReduxFormState, undefined, IReduxBaseAction>
  ) => {
    return dispatch({
      type: EReduxActionTypes.CANCEL_COUPON
    });
  };
}
