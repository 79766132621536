import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import "../../../translations/i18n";
import koolog from '../../../styles/images/koolog.png';
import PreviewOpenQuestionComponent from '../../../components/sondage/preview-open-question-component';
import { useParams } from 'react-router';
import axiosInstance from '../../../helpers/interceptors';
import PreviewClosedQuestionComponent from '../../../components/sondage/preview-closed-question-component';

const SurveyPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const { id } = useParams<any>();
    const [survey, setSurvey] = useState<any>()

    const getExam = async (idSurvey: any) => {
        const baseUrl = process.env.REACT_APP_BASE_URL6;
        let previewSurvey: any = await axiosInstance.get(baseUrl + "exams/findby/" + idSurvey).then(response => { return response.data; }).catch(err => { return [] });
        setSurvey(previewSurvey)  

    }

    useEffect(() => {
        getExam(id)
    }, [id])

    return (<>
        <div style={{ backgroundColor: '#FFFFFF' }}>
            <div className=" mx-auto py-1" style={{ maxWidth: '1330px' }}>
                <div className="d-flex flex-row justify-content-between my-4">
                    <div className="d-flex align-items-center">

                        <span className="H3-Headline"  >Sondage</span>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ backgroundColor: '#FBFBFD' }}>
            <div className="px-4 mx-auto py-3" style={{ maxWidth: '1200px' }}>
                <div className="d-flex flex-row justify-content-between my-4">
                    <div className="d-flex align-items-center">
                        <div >
                            <img style={{ borderRadius: "50%", width: "120px", height: "120px", objectFit: 'cover' }} src={(props?.schoolInfo?.logo) ? props?.schoolInfo?.logo : koolog} />
                        </div>
                        <div className="ms-4 ps-3" >
                            <div className="d-flex flex-column">
                                <span className="H3-Headline"  >{survey?.title}</span>
                                <span className="body-md" style={{ maxWidth: 792 }}>
                                    {survey?.description}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ backgroundColor: '#FBFBFD', marginTop: "20px" }}>
            <div className="mx-auto py-3" style={{ maxWidth: '1200px' }}>
                {survey?.questionByQnaList?.map((question: any, index: any) => (
                    <div key={index}>
                        {question.question.type === "OQ" 
                        ? <PreviewOpenQuestionComponent question={question.question} index={index+1} />
                        : <PreviewClosedQuestionComponent question={question.question} index={index+1} />
                        }

                    </div>
                ))}
            </div>
        </div>




    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    schoolInfo: state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyPage);
