import { EReduxActionTypes, IReduxGetCatalogAction,IReduxGetCountCoursesAction, IReduxGetMyCoursesStudentCatalogAction,IReduxGetCoursesAction,IReduxChangeStyleCourseAction, IReduxGetCoursesProfAction } from '../actions/catalog-actions';

export interface ICourse {
  status: string;
  id: string | null;
  level: string;
  title: string;
  creationDate: string;
  idProf: number;
  idSchool: number;
  resume: string;
  objectives: string;
  prerequisite: string;
  skills: string[],
  order: IChapter[]

}
export interface IChapter {
  status: string;
  id: string;
  text: string;
  duration: number;
  creationDate: string;
  title: string;
  index: number
}
export interface IReduxCoursesState {
  courses: ICourse[],
  loadingCatalog: boolean,
  styleCardCourse: string,
  profCourseList: any,
  loadingCourse: boolean,
  list:any,
  myCourses:any,
  courseNumber:any
  loadingScrollCatalog:boolean
  pageNumber:any
}

export const initialState: IReduxCoursesState = {
  courses: [],
  loadingCatalog: true,
  styleCardCourse: 'vertical',
  profCourseList: undefined,
  loadingCourse: true,
  list:undefined,
  myCourses:undefined,
  courseNumber:undefined,
  loadingScrollCatalog:false,
  pageNumber:0

};

type TCatalogReducerActions =IReduxGetCountCoursesAction| IReduxGetMyCoursesStudentCatalogAction|IReduxGetCatalogAction | IReduxGetCoursesAction|IReduxChangeStyleCourseAction | IReduxGetCoursesProfAction;

export default function (state: IReduxCoursesState = initialState, action: TCatalogReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.LOADING_CATALOG:
      return { ...state, loadingCatalog: true }
      case EReduxActionTypes.GET_COURSES:
        return { ...state, list: action.data }
    case EReduxActionTypes.LOADING_COURSES:
      return { ...state, loadingCourse: true }
    case EReduxActionTypes.GET_CATALOG:

      if(action.scrollOrNot === 'first time') {
        return {...state, courses:action.data,pageNumber:action?.pageNumber,loadingCatalog:false, loadingScrollCatalog:false }
    } else if(action.scrollOrNot === 'scroll'){
        return {...state, courses:[...state.courses,...action.data],pageNumber:action?.pageNumber,loadingCatalog:false, loadingScrollCatalog:false }
    } else {
        return {...state}
    }
      case EReduxActionTypes.GET_COUNT_COURSES:
      return { ...state, courseNumber: action.data };
    case EReduxActionTypes.GET_COURSES_PROF:
      return { ...state, profCourseList: action.data, loadingCourse: false };
    case EReduxActionTypes.CHANGE_STYLE_CARD:
      return { ...state, styleCardCourse: action.data };

        case EReduxActionTypes.LOADING_CATALOG_SCROLL:   
            return {...state,loadingScrollCatalog:true } 
    default:
      return state;
  }
}
