import { FC, useEffect, useMemo, useState, ComponentProps } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import userLogo from '../../styles/imgs/Default_user_icon.svg';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useHistory, useParams } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { getCoursesProf } from '../../global-state/actions/catalog-actions';
import { affectOtherProf, getCourse, getCourseTests, reinstateProf } from '../../global-state/actions/course-actions';
import axios from '../../helpers/interceptors';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import ProfileCardComponent from './profile-card-component';
import axiosInstance from '../../helpers/interceptors';
import TableProfessorsListLoadingComponent from '../tables/table-professors-list-loading-component';
import TableElement from '../../elements/table/normal-table-element';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import Skeleton from '@material-ui/lab/Skeleton';
import { getTeam, resend } from '../../global-state/actions/professor-actions';
import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { getTrainingById } from '../../global-state/actions/training-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';

interface DetailsAssistant {
    id: any
}
const TeamProfileSheetComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { id } = useParams<DetailsAssistant>();
    let [name, setName] = useState("");
    let [photo, setPhoto] = useState("");
    let [lastVisit, setLastVisit] = useState("");
    let [bio, setBio] = useState<any>(undefined);
    let [email, setEmail] = useState("");
    let [status, setStatus] = useState("");
    let [inscription, setInscription] = useState("");
    let [nbrLogin, setNbrLogin] = useState(0);
    let [currency, setCurrency] = useState("");
    let dateFormat = require('dateformat');
    const [actualTab, setActualTab] = useState("info")
    const { t } = useTranslation();
    const [role, setRole] = useState("");
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    let [coursOfProf, setCoursOfProf] = useState<any>();
    const [teamMember, setTeamMember] = useState<any>();
    const [teacherIncome, setTeacherIncome] = useState();
    const [activateSnakbar, setActivateSnakbar] = useState<boolean>(false)
    const trainingTypeTitle: any = {
        "COURSE": t('Course'),
        "PATH": t('parcoursTitle'),
        "SESSION": t('sessionTitle')
    }

    useEffect(() => {
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL2}person/getType/${id}`)
            .then(async (response: any) => {
                setRole(response?.data)
            });
    }, [])

    // useEffect(() => {
    //     console.log(props.teamList);
        
    //     if (!!props?.teamList) {
    //         setTeamMember(props?.teamList?.find((item: any) => item?.id === parseInt(id)));
    //         console.log("aaaaaaaaaaaaaaaaaaaaaaa ", teamMember);
            
    //         getTeacherIncome()
    //     }
    // }, [props?.teamList])

    useEffect(() => {
        getTeamMember();        
    }, [teamMember])

    const getTeamMember = async () => {
        setName(teamMember?.name ?? "-");
        setPhoto(teamMember?.photo);
        setLastVisit(teamMember?.lastVisit ?? "-");
        setBio(teamMember?.biography ?? undefined);
        setEmail(teamMember?.email ?? "-");
       

        if (status === "" || status === undefined) {
            setStatus(teamMember?.status);
        }
        setInscription(dateFormat(teamMember?.inscription === null ? "-" : teamMember?.inscription, "dd/mm/yyyy"))
        const baseUrl1 = process.env.REACT_APP_BASE_URL1;
        axios({
            url: baseUrl1 + "person/lastconnexion/" + id,
            method: 'GET',

        }).then((response) => {
            setNbrLogin(response?.data ?? 0);
        })
        let filter = {
            entityId: props?.user?.idSchool,
            entityType: "SCHOOL",
            status: "PUBLISHED",
            idProf: id,

        }
        await props?.getCoursesProf(filter)
        getTeacherIncome()
    }

    // useEffect(() => {
    //     setTeamMember(props?.teamList?.find((x: any) => x?.id === parseInt(id)));
    //     setStatus(teamMember?.status);

    // }, [props?.assistants, props?.profList])

    useEffect(() => {
        setCurrency(props?.paymentSchoolInfo?.currency)
    }, [props?.paymentSchoolInfo])

    const textAreaAdjust = (element: any, tag: string) => {
        element.style.height = "1px";
        element.style.height = (12 + element.scrollHeight) + "px";
        if (element.value !== "") {
            let chaine = element.value
            setBio(chaine);
        }

        else
            setBio(undefined);
    }

    const handleBlur = () => {
        const baseUrl = process.env.REACT_APP_BASE_URL2;
        axios.post(baseUrl + "person/updatebioprof", {
            "id": id,
            "bio": bio === undefined ? undefined : bio,
            "email": email
        })

    }

    const desactivate = async () => {

        if (role === "PROF") {
            await props?.openModal({ idModal: ModalIDS.DEACTIVATE_PROF, size: "md", data: { prof: { name: name, id: id } } })

        } else if (role === "ADMINSTRATOR") {
            await props?.openModal({ idModal: ModalIDS.DEACTIVATE_ASSISTANT, size: "md", data: { assistant: { name: name, id: id } } })

        }

    }

    const reinstate = async () => {
        if (role === "PROF") {
            await props.openModal({ idModal: ModalIDS.REINTEGRATE_PROF, size: "md", data: { prof: { name: name, id: id } } })
        } else if (role === "ADMINSTRATOR") {
            await props.openModal({ idModal: ModalIDS.REINTEGRATE_ASSISTANT, size: "md", data: { assistant: { name: name, id: id } } })
        }
    }

    const getTeacherIncome = async () => {
        const filter = {
            idCourse: "",
            search: ""
        }
        const baseUrl13 = process.env.REACT_APP_BASE_URL13

        await axiosInstance.post(baseUrl13 + "payment/getTeacherIncome/" + props?.connectionUser.idSchool + "/" + id, filter)
            .then((response) => {
                setTeacherIncome(response.data)
            })
    }

    const headers = [
        { title: t('TitleFormations'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Inscrits'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Diplômés'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Candidats'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Tarif'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Statut'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]

    const statusList: any = [

        { label: t('toValidate'), value: "PENDING", color: "warning" },
        { label: t('Published'), value: "PUBLISHED", color: "primary" },
        { label: t('archive'), value: "ARCHIVE", color: "secondary" },
        { label: t('Refused'), value: "REFUSED", color: "danger" },
        { label: t('Deactivate'), value: "DEACTIVATE", color: "dark" }
    ]

    const data: any = useMemo(() => {
        return {
            headers,
            body: props?.profCourseList?.map((course: any) => {
                let statusCourse = statusList.find((e: any) => e?.value === course?.status)
                return {
                    row: {
                        course: course,
                        classNames: "table-white",
                        columns: [
                            { content: course?.title, classNames: "", actionName: 'goToPath' },
                            { content: trainingTypeTitle[course?.type], classNames: "text-center", actionName: 'goToPath' },
                            { content: course?.nbrRegistered, classNames: "text-center", actionName: 'goToPath' },
                            { content: course?.nbrDiplomas, classNames: "text-center", actionName: 'goToPath' },
                            { content: course?.nbrCandidate, classNames: "text-center" },
                            { content: course?.price?.price?.price === 0 ? "Gratuit" : course?.price?.price?.price, classNames: "text-center", actionName: 'goToPath' },
                            { content: statusCourse?.label, classNames: `text-end badge-text ${statusCourse?.color}`, actionName: 'goToPath' }
                        ]
                    }
                }
            })
        }
    }, [props?.profCourseList])

    const doAction = async (value: any) => {

        if (value.action === 'goToPath') {
            if (value.row.course.type === 'COURSE') {
                await props?.getCourse(value.row.course.id);
                if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                    await props?.getRegistrationForm(value.row.course.idRegistration);
                }
                await props?.getCourseTests(value.row.course.id);
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`)
                history.push(`${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`);
            } else if (value.row.course.type === 'PATH') {
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`)
                await props?.getTrainingById('paths', value.row.course.id);
                if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                    await props?.getRegistrationForm(value?.row?.course?.idRegistration);
                }
                history.push(`${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`);
            } else {
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`)
                await props?.getTrainingById('sessions', value.row.course.id);
                history.push(`${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`);
            }
        }
    }

    useEffect(() => {
        setLoading(props?.loadingCourse)
    }, [props?.loadingCourse]);

    useEffect(() => {
        setCoursOfProf(props?.profCourseList)
    }, [props?.profCourseList]);

    useEffect(() => {
        searchForTeamMember()
    }, [role, props?.assistants, props?.profList])

    const searchForTeamMember = () => {
        let indexTeam
        if (role === "PROF") {
            indexTeam = props?.profList?.findIndex((team: any) => team?.id === parseInt(id));
            if (status === "" || status === undefined) {
                setStatus(props?.profList[indexTeam]?.status)
                setTeamMember(props?.profList[indexTeam])
            }
        } else if (role === "ADMINSTRATOR") {
            indexTeam = props?.assistants?.findIndex((team: any) => team?.id === parseInt(id));
            if (status === "" || status === undefined) {
                setStatus(props?.assistants[indexTeam]?.status)
            }
        }
    }

    const deleteTeamMember = async () => {
        await props?.openModal({ idModal: ModalIDS.DECLINE_TEAM_INVITATION, info: { id: id, role: props?.role }, size: "md" })

    }

    const resendInvitation = () => {
        props?.resend(id)
        setActivateSnakbar(true)
    }



    return (
        <>
            <div style={{ marginTop: 64 }}>
                <div style={{ backgroundColor: '#FFFFFF', paddingBottom: 24 }}>
                    <div className="px-4 mx-auto py-3" style={{ maxWidth: '1248px' }} >
                        <div className="d-flex flex-row justify-content-between my-4">
                            <div className="d-flex align-items-center">
                                <div>
                                    <img style={{ borderRadius: "50%", width: "120px", objectFit: 'fill' }} src={photo ? photo : userLogo} />
                                </div>
                                <div className="ms-4 ps-3">
                                    <div className="d-flex flex-column">
                                        <div className=' flex-row align-items-center justify-content-between ' >
                                            <span className="H3-Headline">{name}</span>
                                            <span className={`badge-team ${status === "Admis" ? "admis" : status === "Enattente" ? "waiting" : "suspended"}`}>{status === "Admis" ? t('Admis') : status === "Enattente" ? t('Enattente') : t('Suspendu')}</span>
                                        </div>
                                        <div className="d-flex flex-row me-3 mt-2">
                                            <span className="body-sm bold neutral-3 me-2">{t('Email')}</span>
                                            <span className="body-sm bold black-800"> {email}</span>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap mt-2">
                                            <div className="d-flex flex-row me-3">
                                                <span className="body-sm bold neutral-3 me-2">{t('inscriptionTitle')}</span>
                                                <span className="body-sm bold black-800">{"  " + inscription === null ? "-" : inscription + "    "}</span>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <span className="body-sm bold neutral-3 me-2">{t('lastVisitProfile_1')}</span>
                                                <span className="body-sm bold black-800">{lastVisit} {t('joursVisit')}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=" justify-content-end align-items-start d-flex" style={{ display: 'flex', alignItems: 'center' }}>
                                {(status === 'Enattente') ?
                                    <div className='d-flex  flex-column'>
                                        <button type="button" className="btn-Secondary large icon-left" onClick={() => resendInvitation()}  >{t('Renvoyer l’invitation')}</button>
                                        <div className=" justify-content-end align-items-end d-flex pt-2">
                                            <button className="btn-text-team outlined danger" onClick={() => { deleteTeamMember() }}>{t('deleteInvitation')}</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="justify-content-end align-items-center  d-flex">
                                        {(status === 'Admis') &&
                                            <input dir="ltr" className="input-style" type="checkbox" readOnly={true} style={{ display: 'none' }} id="toggleBtn" onClick={() => { desactivate() }} checked={true} />}
                                        {(status === 'Suspendu') &&
                                            <input dir="ltr" className="input-style" type="checkbox" readOnly={true} style={{ display: 'none' }} id="toggleBtn" checked={false} onClick={() => { reinstate() }} />}

                                        <div className="toggle-container">
                                            <label className="label-style" htmlFor="toggleBtn">
                                                <span className="on">{t('Admis')}</span>
                                                <span className="off">{t('Suspendu')}</span>
                                                <input dir="ltr" type="text" style={{ display: 'none' }} />
                                            </label>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div>
                    </div>
                </div>
            </div>


            <div style={{ maxWidth: '1200px', alignItems: "center", justifyContent: "center" }} className="mx-auto" >

                <div className="d-flex  px-2 pb-4 mt-3 " style={{ maxWidth: '1200px' }}>
                    <div>
                        <button className={`btn-Tabs ${(actualTab === "info") ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => setActualTab("info")} >
                            {t('info')}
                        </button>
                    </div>
                    {role === "PROF" && <div className='d-flex'>
                        <button className={`btn-Tabs ${(actualTab === 'desc') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => setActualTab("desc")} >
                            {t('shortDescription')}
                        </button>
                        <button className={`btn-Tabs ${(actualTab === 'trainning') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => setActualTab("trainning")}  >
                            {t('courseTable')}
                        </button>
                    </div>}
                </ div>
            </div>


            <div>
                {((actualTab === "info" && teacherIncome != undefined)) &&
                    <div className=" px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }} >
                        <div className='d-flex '>
                            {status === 'Enattente' ? <ProfileCardComponent title={t('nbrConx')} value={"-"} /> : <ProfileCardComponent title={t('nbrConx')} value={nbrLogin} />}
                            <ProfileCardComponent title={t('inscDate')} value={inscription} />
                            {status === 'Enattente' ? <ProfileCardComponent title={t('lastVisitProfile_1')} value={"-"} /> : <ProfileCardComponent title={t('lastVisitProfile_1')} value={lastVisit} />}
                            {status === 'Enattente' ? <ProfileCardComponent title={t('role1')} value={"-"} /> : <ProfileCardComponent title={t('role1')} value={role === "PROF" ? t('professeur') : t('schoolAssistant')} />}
                        </div>
                        {role === "PROF" &&
                            <div style={{ marginTop: "16px" }}>
                                {(status === 'Enattente' || props?.paymentSchoolInfo?.isActivated === false || props?.paymentSchoolInfo === null) ? <ProfileCardComponent title={t('totalRevenu')} value={"-"} /> : <ProfileCardComponent title={t('totalRevenu')} value={teacherIncome + " " + currency} />}
                            </div>}
                    </div>
                }
                {((actualTab === "info" && teacherIncome == undefined)) &&
                    <div className=" px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }} >
                        <div className='d-flex '>
                            <Skeleton variant="rect" animation="wave" width={"288px"} height={"96px"} className="skeleton" style={{ marginRight: "16px" }} />
                            <Skeleton variant="rect" animation="wave" width={"288px"} height={"96px"} className="skeleton" style={{ marginRight: "16px" }} />
                            <Skeleton variant="rect" animation="wave" width={"288px"} height={"96px"} className="skeleton" style={{ marginRight: "16px" }} />
                            <Skeleton variant="rect" animation="wave" width={"288px"} height={"96px"} className="skeleton" />
                        </div>
                        {role === "PROF" &&
                            <div style={{ marginTop: "16px" }}>
                                <Skeleton variant="rect" animation="wave" width={"288px"} height={"96px"} className="skeleton" />
                            </div>}
                    </div>
                }

                {actualTab === "desc" &&
                    <div className="d-flex flex-column mx-auto my-5 mb-3 mt-4" style={{ maxWidth: '1200px', borderRadius: 3, border: "1px solid #DFDFDF", padding: "56px 206px" }}>
                        <div className="col-md-12 text-left" >
                            <span className="H4-Subtitle">{t('shortDescription1')} {!!role && role === "PROF" ? <span>{t('filterProfessors')}</span> : <span>{t('schoolAssistant')}</span>}.</span>

                        </div>
                        <div className="col-md-12 mt-3 mb-3">
                            <textarea style={{ fontSize: "16px" }} value={bio} onChange={(e) => textAreaAdjust(e.target, 'text')} id="professorDetails" className="textaria-textField" onBlur={handleBlur} placeholder={t('textAreaPlaceholder')} ></textarea>
                        </div>
                    </div>}
                {actualTab === "trainning" &&
                    <div className="container-fluid mx-auto row justify-content-center mt-3" style={{ maxWidth: '1200px' }}>
                        <div className=" me-0 ms-0 pe-0 ps-0  mt-3">
                            {loading ? <>
                                <TableProfessorsListLoadingComponent />
                            </> : <>{data?.body?.length > 0 && <>

                                <TableElement data={data} doAction={(value: any) => { doAction(value); }} />


                            </>}
                                {coursOfProf?.length === 0
                                    && <div className="col-md-12 text-center">

                                        <EmptyListElement lines={[t('noCoursesCreated')]} >

                                        </EmptyListElement>
                                    </div>
                                }</>}
                        </div>
                    </div>}
            </div>

            <Snackbar open={activateSnakbar} autoHideDuration={6000} onClose={() => { setActivateSnakbar(!activateSnakbar) }}>
                <Alert style={{ backgroundColor: "#272727" }}>
                    <div className='d-flex flex-row align-item-center'>
                        <span className='body-md' style={{ color: "#FFFFFF", paddingRight: "98px" }} > {t('invitationSentSuccesfully')}</span>
                        <Button color="inherit" size="small" onClick={() => { setActivateSnakbar(!activateSnakbar) }}>
                            <CloseIcon style={{ color: "#FFFFFF" }} />
                        </Button>
                    </div>
                </Alert>
            </Snackbar>
        </>

    );
}

const mapStateToProps = (state: AppState) => ({
    teamList: state.profReducer.teamList,
    connectionUser: state.authReducer.connectedUser,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    user: state.authReducer.connectedUser,
    loadingCourse: state.catalogReducer.loadingCourse,
    assistants: state.profReducer.assistants,
    profCourseList: state.catalogReducer.profCourseList,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    profList: state.profReducer.profList


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            affectOtherProf,
            reinstateProf,
            getTrainingById,
            getCourseTests,
            getCourse,
            getCoursesProf,
            openModal,
            getTeam,
            resend,
            redirect, clearHistoryArray
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(TeamProfileSheetComponent);