import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxGetExamListState } from '../reducers/exam-reducer';
import axios from '../../helpers/interceptors';


export enum EReduxActionTypes {

  SAVE_ANSWERS = 'SAVE_ANSWERS',
  GET_EXAMLIST = 'GET_EXAMLIST',
  GET_EXAM_FORM = 'GET_EXAM_FORM',
  CLEAR_EXAM_FORM = 'CLEAR_EXAM_FORM',
  GET_EXAM_STATS = 'GET_EXAM_STATS',
  SET_OPEN_QUESTION_MARK = 'SET_OPEN_QUESTION_MARK',
  GET_NEXT_EXAM_FORM = 'GET_NEXT_EXAM_FORM',
  UPDATE_EXAM_LISTS = 'UPDATE_EXAM_LISTS',
  CLEAR_ALL = 'CLEAR_ALL',
  ACTUAL_TAB = 'ACTUAL_TAB',
  CHANGING_ACTUAL_TAB = 'CHANGING_ACTUAL_TAB',
  LOADING_TABS = "LOADING_TABS",
  LOADING_LISTS = "LOADING_LISTS",
  CHECK_EXAM_PROGRESS_STATUS = "CHECK_EXAM_PROGRESS_STATUS",
  LOADING_SUBMISSION = "LOADING_SUBMISSION",
  PASS_EXAM = "PASS_EXAM",
  SUBMIT_RESPONSE_OPEN = "SUBMIT_RESPONSE_OPEN",
  SUBMIT_RESPONSE_OPEN_QNA = "SUBMIT_RESPONSE_OPEN_QNA",
  SUBMIT_RESPONSE_QNA_CLOSED = "SUBMIT_RESPONSE_QNA_CLOSED",
  SET_QUESTIONS_TO_PASS = "SET_QUESTIONS_TO_PASS",
  EXISTING_ANSWERS = "EXISTING_ANSWERS",
  SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION",
  SUBMIT_CLOSE_QUESTION = "SUBMIT_CLOSE_QUESTION",
  CLEAR_EXAM_PROGRESS = "CLEAR_EXAM_PROGRESS",
  GET_EXAMS_WITH_FILTER = "GET_EXAMS_WITH_FILTER",
  CREATE_PROGRESS_QNA = "CREATE_PROGRESS_QNA",
  EMPTY_PROGRESS = "EMPTY_PROGRESS"
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}


export interface IReduxStudentResponseCloseAction extends IReduxBaseAction {
  type: EReduxActionTypes.SUBMIT_RESPONSE_OPEN;
  data: any;
}
export interface IReduxSaveAnswersAction extends IReduxBaseAction {
  type: EReduxActionTypes.SAVE_ANSWERS;
  data: any;
}
export interface IReduxCreateProgressQnaAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_PROGRESS_QNA;
  data: any;
}
export interface IReduxEmptyProgressAction extends IReduxBaseAction {
  type: EReduxActionTypes.EMPTY_PROGRESS;

}
export interface IReduxStudentResponseOpenQnaAction extends IReduxBaseAction {
  type: EReduxActionTypes.SUBMIT_RESPONSE_OPEN_QNA;
  data: any;
}
export interface IReduxUserResponseCloseAction extends IReduxBaseAction {
  type: EReduxActionTypes.SUBMIT_RESPONSE_QNA_CLOSED;
  data: any;
}

export interface IReduxSetCurrentQuestionAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_CURRENT_QUESTION;
  data: any;
}
export interface IReduxGetExisitingAnswersAction extends IReduxBaseAction {
  type: EReduxActionTypes.EXISTING_ANSWERS;
  data: any;
}
export interface IReduxSetQuestionsToPassAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_QUESTIONS_TO_PASS;
  data: any;
}

export interface IReduxStudentResponseOpenAction extends IReduxBaseAction {
  type: EReduxActionTypes.SUBMIT_RESPONSE_OPEN;
  data: any;
}

export interface IReduxPassExamAction extends IReduxBaseAction {
  type: EReduxActionTypes.PASS_EXAM;
  data: any;
}

export interface IReduxCheckExamProgressStatusAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_EXAM_PROGRESS_STATUS | EReduxActionTypes.UPDATE_EXAM_LISTS;
  data: any;
}

export interface IReduxGetActualTabAction extends IReduxBaseAction {
  type: EReduxActionTypes.ACTUAL_TAB;
  data: any;
}

export interface IReduxChangingActualTabAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGING_ACTUAL_TAB;
  data: any;
}

export interface IReduxGetExamListAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_EXAMLIST | EReduxActionTypes.LOADING_LISTS;
  data: any;
}

export interface IReduxGetExamFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_EXAM_FORM | EReduxActionTypes.LOADING_SUBMISSION;
  data: any;
}
export interface IReduxClearExamFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_EXAM_FORM | EReduxActionTypes.LOADING_SUBMISSION;
  data: any;
}

export interface IReduxExamStatFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_EXAM_STATS | EReduxActionTypes.LOADING_TABS;
  data: any;
}

export interface IReduxSetMarkAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_OPEN_QUESTION_MARK;
  data: any;
}

export interface IReduxGetNextExamFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_NEXT_EXAM_FORM
  data: any;
}
export interface IReduxUpdateExamListsAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_EXAM_LISTS
  data: any;
}

export interface IReduxClearAllAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_ALL
}
export interface IReduxClearExamProgressAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_EXAM_PROGRESS
}

export interface IReduxGetExamsWithFilterAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_EXAMS_WITH_FILTER | EReduxActionTypes.LOADING_LISTS;
  data: any;
}

export function getExamsWithFilter(searchFilter: any): ThunkAction<Promise<IReduxGetExamsWithFilterAction>, IReduxGetExamListState, undefined, IReduxGetExamsWithFilterAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetExamsWithFilterAction>) => {

    dispatch({ type: EReduxActionTypes.LOADING_LISTS, data: undefined });

    let result = await axios.post(`${process.env.REACT_APP_BASE_URL6}answers/toCorrect`, searchFilter).then((response1) => { return response1.data }).catch(err => { return [] });
    let resToReview = result.filter((exam: any) => exam?.progressQna?.status === "TOREVIEW")
    let resCompleted = result.filter((exam: any) => exam?.progressQna?.status === "COMPLETED")
    let resAll = result

    return dispatch({
      type: EReduxActionTypes.GET_EXAMS_WITH_FILTER,
      data: { listExamToReview: resToReview, listExamCompleted: resCompleted, listAllExam: resAll }
    });
  };
}


export function getExamsList(idSchool: number, idProf: number, role: string) {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetExamListAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_LISTS, data: undefined });

    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let resToReview;
    let resCompleted;
    let resAll;

    let object1={
      "idSchool": idSchool,
      "idUser": idProf,
      "displayFor":role ,
      "status":"TOREVIEW" ,
      "entityType":"SCHOOL"
  
    }
    let object2={
      "idSchool": idSchool,
      "idUser": idProf,
      "displayFor":role ,
      "status":"COMPLETED" ,
      "entityType":"SCHOOL"
  
    }
    let object3={
      "idSchool": idSchool,
      "idUser": idProf,
      "displayFor":role ,
      "status":"ALL" ,
      "entityType":"SCHOOL"
  
    }
    if (role === "PROF") {

      resToReview = await axios.post(baseUrl + "answers/toCorrectProf",object1).then(response => {
        return (response.data === '') ? [] : response.data;
      }).catch(err => { return [] });
      resCompleted = await axios.post(baseUrl + "answers/toCorrectProf",object2).then(response => {
        return (response.data === '') ? [] : response.data;
      }).catch(err => { return [] });
      resAll = await axios.post(baseUrl + "answers/toCorrectProf",object3).then(response => {
        return (response.data === '') ? [] : response.data;
      }).catch(err => { return [] });

    } else if (role === "ADMINSTRATOR") {

      resToReview = await axios.post(baseUrl + "answers/toCorrectAssistant", object1 ).then(response => {
        return (response.data === '') ? [] : response.data;
      }).catch(err => { return [] });
      resCompleted = await axios.post(baseUrl + "answers/toCorrectAssistant", object2 ).then(response => {
        return (response.data === '') ? [] : response.data;
      }).catch(err => { return [] });
      resAll = await axios.post(baseUrl + "answers/toCorrectAssistant", object3 ).then(response => {
        return (response.data === '') ? [] : response.data;
      }).catch(err => { return [] });

    }
    return dispatch({
      type: EReduxActionTypes.GET_EXAMLIST,
      data: { listExamToReview: resToReview, listExamCompleted: resCompleted, listAllExam: resAll }
    });
  };
}

export function updateExamLists(progressId: number): ThunkAction<Promise<IReduxUpdateExamListsAction>, IReduxGetExamListState, undefined, IReduxUpdateExamListsAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxUpdateExamListsAction>) => {

    return dispatch({
      type: EReduxActionTypes.UPDATE_EXAM_LISTS,
      data: progressId
    });
  };
}

export function checkExamProgressStatus(progressId: number, idExam: number): ThunkAction<Promise<IReduxCheckExamProgressStatusAction>, IReduxGetExamListState, undefined, IReduxCheckExamProgressStatusAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxCheckExamProgressStatusAction>) => {
    dispatch({ type: EReduxActionTypes.UPDATE_EXAM_LISTS, data: progressId });
    return dispatch({
      type: EReduxActionTypes.CHECK_EXAM_PROGRESS_STATUS,
      data: idExam
    });
  };
}

export function getExamsStats(idSchool: number, idProf: number, role: any) {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxExamStatFormAction>) => {

    dispatch({ type: EReduxActionTypes.LOADING_TABS, data: undefined });
    let examStat;
    const baseUrl = process.env.REACT_APP_BASE_URL6;

    let object={
      "idSchool": idSchool,
      "idUser": idProf,
      "displayFor":role,
      "entityType":"SCHOOL"
  
    }

    if (role === "PROF") {
      examStat = await axios.post(baseUrl + "answers/nbrExam",object).then(response => { return response.data; }).catch(err => { return [] });

    } else if (role === "ADMINSTRATOR") {
      examStat = await axios.post(baseUrl + "answers/schoolNbrExam/",object).then(response => { return response.data; }).catch(err => { return [] });

    }
    return dispatch({
      type: EReduxActionTypes.GET_EXAM_STATS,
      data: { nbrExamsToReview: examStat?.nbrExamsToReview, nbrCompletedExams: examStat?.nbrCompletedExams, nbrAllExams: examStat?.nbrAllExams }
    });
  };
}


export function getExamForm(exam: any, typeList: string, index: number): ThunkAction<Promise<IReduxGetExamFormAction>, IReduxGetExamListState, undefined, IReduxGetExamFormAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetExamFormAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_SUBMISSION, data: undefined });
    return dispatch({
      type: EReduxActionTypes.GET_EXAM_FORM,
      data: { exam, typeList, index }
    });
  };
}
export function clearExams(): ThunkAction<Promise<IReduxClearAllAction>, IReduxGetExamListState, undefined, IReduxClearAllAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxClearAllAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_ALL,
    });
  };
}

export function getNextExam(index: number): ThunkAction<Promise<IReduxGetNextExamFormAction>, IReduxGetExamListState, undefined, IReduxGetNextExamFormAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetNextExamFormAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_NEXT_EXAM_FORM,
      data: { index: index },
    });
  };
}


export function clearExamForm(): ThunkAction<Promise<IReduxClearExamFormAction>, IReduxGetExamListState, undefined, IReduxClearExamFormAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxClearExamFormAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_SUBMISSION, data: undefined });
    return dispatch({
      type: EReduxActionTypes.CLEAR_EXAM_FORM,
      data: undefined
    });
  };
}

export function setSudentMark(idAnswer: number, mark: number, comment: string, examForm: any, statusExam: any,idQna:number): ThunkAction<Promise<IReduxSetMarkAction>, IReduxGetExamListState, undefined, IReduxSetMarkAction> {
  let answercomment = comment
  if (answercomment.length === 0) {
    answercomment = "Pas de commentaire"
  }

  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxSetMarkAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const baseUrlOrchestrator = process.env.REACT_APP_BASE_URL14;
    let idProgressQna = 0;
    const exist = examForm?.progressQna?.id;
    if (exist) {
      idProgressQna = exist
    }
    const setMark: any = await axios.post(baseUrl + "answers/profCorr/" + idAnswer + "/" + mark + "/" + answercomment).then(response => { return response.data; }).catch(err => { return [] });
    if (statusExam === "TOREVIEW") {
      await axios.post(baseUrlOrchestrator + "progress/updateProgressExam/" + idQna + "/" + idProgressQna + "/" + examForm?.studentId + "/" + examForm?.progressQna?.id, examForm?.follow?.progressTraining).then(response => {

        axios.post(baseUrlOrchestrator + `progress/calculateProgress/${examForm?.follow?.id}`, null)
          .then(res => { return res.data })
          .catch(err => console.error(err));


      }).catch(err => { return [] });

    }
    return dispatch({
      type: EReduxActionTypes.SET_OPEN_QUESTION_MARK,
      data: setMark
    })
  };
}

export function getActualTab(actualTab: string): ThunkAction<Promise<IReduxGetActualTabAction>, IReduxGetExamListState, undefined, IReduxGetActualTabAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetActualTabAction>) => {

    return dispatch({
      type: EReduxActionTypes.ACTUAL_TAB,
      data: actualTab
    });
  };
}

export function changingActualTab(NewactualTab: string): ThunkAction<Promise<IReduxChangingActualTabAction>, IReduxGetExamListState, undefined, IReduxChangingActualTabAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxChangingActualTabAction>) => {

    return dispatch({
      type: EReduxActionTypes.CHANGING_ACTUAL_TAB,
      data: NewactualTab
    });
  };
}


export function getExamToPass(id: number): ThunkAction<Promise<IReduxPassExamAction>, IReduxGetExamListState, undefined, IReduxPassExamAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxPassExamAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let examToPass: any = await axios.get(baseUrl + "exams/findby/" + id).then(response => { return response.data; }).catch(err => { return [] });
    return dispatch({
      type: EReduxActionTypes.PASS_EXAM,
      data: examToPass
    });
  };
}


export function clearExamProgress(): ThunkAction<Promise<IReduxClearExamProgressAction>, IReduxGetExamListState, undefined, IReduxClearExamProgressAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxClearExamProgressAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_EXAM_PROGRESS,
    });
  };
}

export function setQuestions(questions: any): ThunkAction<Promise<IReduxSetQuestionsToPassAction>, IReduxGetExamListState, undefined, IReduxSetQuestionsToPassAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxSetQuestionsToPassAction>) => {
    return dispatch({
      type: EReduxActionTypes.SET_QUESTIONS_TO_PASS,
      data: questions
    });
  };
}

export function getExistingAnswers(idExam: number, idStudent: number, idFollow: number): ThunkAction<Promise<IReduxGetExisitingAnswersAction>, IReduxGetExamListState, undefined, IReduxGetExisitingAnswersAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetExisitingAnswersAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let existingAnswers: any = await axios.get(baseUrl + "answers/getAnswers/" + idFollow + "/" + idStudent + "/" + idExam).then(response => { return response.data; }).catch(err => { return [] });
    return dispatch({
      type: EReduxActionTypes.EXISTING_ANSWERS,
      data: existingAnswers
    });
  };
}
export function getResultAnswers(idExam: number, idStudent: number, idFollow: number): ThunkAction<Promise<IReduxGetExisitingAnswersAction>, IReduxGetExamListState, undefined, IReduxGetExisitingAnswersAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxGetExisitingAnswersAction>) => {
    let existingAnswers = { answerList: [] }
    if (idExam) {
      existingAnswers = await axios.get(`${process.env.REACT_APP_BASE_URL6}answers/answerDetails/${idFollow}/${idStudent}/${idExam}`).then((response1) => { return response1.data }).catch(err => { return [] });
    }
    return dispatch({
      type: EReduxActionTypes.EXISTING_ANSWERS,
      data: existingAnswers.answerList
    });
  };
}

export function setCurrentQuestion(index: any): ThunkAction<Promise<IReduxSetCurrentQuestionAction>, IReduxGetExamListState, undefined, IReduxSetCurrentQuestionAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxSetCurrentQuestionAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_CURRENT_QUESTION,
      data: index
    });
  };
}

export function submitOpenQuestionResponse(responseOQ: any, index: number, progress: any, currentTraning: any): ThunkAction<Promise<IReduxStudentResponseOpenAction>, IReduxGetExamListState, undefined, IReduxStudentResponseOpenAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxStudentResponseOpenAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const baseUrlFollowUp = process.env.REACT_APP_BASE_URL3;
    const baseUrlOrchestrator = process.env.REACT_APP_BASE_URL14;


    let existingAnswers: any = await axios.post(baseUrl + "answers/passOpen/", responseOQ).then(response => {
      return response.data;
    }).catch(err => { return [] });
    let trainingProgress = undefined;
    let idProgressQna = 0;
    if (progress === null) {
      const object = { id: null, entityType: currentTraning?.type, entityId: currentTraning?.id, follow: currentTraning?.followUp }
      trainingProgress = await axios.post(baseUrlFollowUp + `progress/progressTraining`, object).then(res => { return res.data }).catch(err => console.error(err));
    } else {
      trainingProgress = progress;
    }
    const exist = trainingProgress?.progressQna?.find((obj: any) => obj?.idQna === Number(responseOQ?.examId));
    if (exist) {
      idProgressQna = exist.id
    }
    const result = await axios.post(baseUrlOrchestrator + `progress/updateProgressExam/${responseOQ.examId}/${idProgressQna}/${responseOQ.studentId}/${responseOQ.idProgressQna}`, trainingProgress).then(res => { return res.data }).catch(err => console.error(err));

    if (exist) {
      const indexExam = trainingProgress.progressQna.findIndex((obj: any) => obj.idQna === Number(responseOQ.examId))
      trainingProgress.progressQna[indexExam] = result;
    } else {
      trainingProgress.progressQna.push(result);
    }
    if (currentTraning.type === 'COURSE') {
      await axios.post(baseUrlOrchestrator + `progress/calculateProgress/${currentTraning.followUp.id}`, null)
      .catch(err => console.error(err));
    }
    return dispatch({
      type: EReduxActionTypes.SUBMIT_RESPONSE_OPEN,
      data: { data: existingAnswers, index: index, trainingProgress: trainingProgress }
    });
  };
}


export function submitCloseQuestionResponse(responseCQ: any, index: number, progress: any, currentTraning: any): ThunkAction<Promise<IReduxStudentResponseCloseAction>, IReduxGetExamListState, undefined, IReduxStudentResponseCloseAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxStudentResponseCloseAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const baseUrlFollowUp = process.env.REACT_APP_BASE_URL3;
    const baseUrlOrchestrator = process.env.REACT_APP_BASE_URL14;

    let existingAnswers: any = await axios.post(baseUrl + "answers/passClosed/", responseCQ).then(response => {
      return response.data;
    }).catch(err => { return [] });

    let trainingProgress = undefined;
    let idProgressQna = 0;

    if (progress === null) {
      const object = { id: null, entityType: currentTraning.type, entityId: currentTraning.id, follow: currentTraning.followUp }
      trainingProgress = await axios.post(baseUrlFollowUp + `progress/progressTraining`, object).then(res => { return res.data }).catch(err => { return null });
    } else {
      trainingProgress = progress;

    }

    const exist = trainingProgress?.progressQna?.find((obj: any) => obj?.idQna === Number(responseCQ?.examId));

    if (exist) {
      idProgressQna = exist?.id
    }
    
    const result = await axios.post(baseUrlOrchestrator + `progress/updateProgressExam/${responseCQ?.examId}/${idProgressQna}/${responseCQ?.studentId}/${responseCQ?.idProgressQna}`, trainingProgress).then(res => { return res.data }).catch(err => console.error(err));

    if (exist) {
      const index = trainingProgress.progressQna.findIndex((obj: any) => obj.idQna === Number(responseCQ.examId))
      trainingProgress.progressQna[index] = result;
    } else {
      trainingProgress.progressQna.push(result);
    }
    if (currentTraning.type === 'COURSE') {
      await axios.post(baseUrlOrchestrator + `progress/calculateProgress/${currentTraning.followUp.id}`, null)
        .catch(err => console.error(err));
    }

    return dispatch({
      type: EReduxActionTypes.SUBMIT_RESPONSE_OPEN,
      data: { data: existingAnswers, index: index, trainingProgress: trainingProgress }
    });
  };
}


export function submitCloseQuestionQnaResponse(responseCQ: any, index: number, progress: any, currentTraning: any): ThunkAction<Promise<IReduxUserResponseCloseAction>, IReduxGetExamListState, undefined, IReduxUserResponseCloseAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxUserResponseCloseAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;

    let existingAnswers: any = await axios.post(baseUrl + "exams/passClosedQna", responseCQ).then(response => {
      return response.data;
    }).catch(err => { return [] });

    return dispatch({
      type: EReduxActionTypes.SUBMIT_RESPONSE_QNA_CLOSED,
      data: { data: existingAnswers, index: index }
    });
  };
}

export function submitOpenQuestionQnaResponse(responseOQ: any, index: number): ThunkAction<Promise<IReduxStudentResponseOpenQnaAction>, IReduxGetExamListState, undefined, IReduxStudentResponseOpenQnaAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxStudentResponseOpenQnaAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;

    let existingAnswers: any = await axios.post(baseUrl + "exams/passOpenQna", responseOQ).then(response => {
      return response.data;
    }).catch(err => { return [] });

    return dispatch({
      type: EReduxActionTypes.SUBMIT_RESPONSE_OPEN_QNA,
      data: { data: existingAnswers, index: index }
    });
  };
}
export function saveAnswers(object: any): ThunkAction<Promise<IReduxSaveAnswersAction>, IReduxGetExamListState, undefined, IReduxSaveAnswersAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxSaveAnswersAction>) => {

    return dispatch({
      type: EReduxActionTypes.SAVE_ANSWERS,
      data: object
    });
  };
}


export function createProgressQna(responseOQ: any): ThunkAction<Promise<IReduxCreateProgressQnaAction>, IReduxGetExamListState, undefined, IReduxCreateProgressQnaAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxCreateProgressQnaAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;

    let progress: any = await axios.post(baseUrl + "progress/createProgressQna", responseOQ).then(response => {
      return response.data;
    }).catch(err => { return [] });

    return dispatch({
      type: EReduxActionTypes.CREATE_PROGRESS_QNA,
      data: progress
    });
  };
}
export function emptyProgress(): ThunkAction<Promise<IReduxEmptyProgressAction>, IReduxGetExamListState, undefined, IReduxEmptyProgressAction> {
  return async (dispatch: ThunkDispatch<IReduxGetExamListState, undefined, IReduxEmptyProgressAction>) => {

    return dispatch({
      type: EReduxActionTypes.EMPTY_PROGRESS,

    });
  };
}