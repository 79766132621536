
export const validStatuses = ["CERTIFIED", "CANDIDATE", "DRAFT"];
export const blackBtnStatus = ["CANDIDATE", "DRAFT" , "UNPAID" , "REFUSED" , "INSCRIBED"];
export const chatBtnValidStatus = ["CANDIDATE","REFUSED"];
export const studentsTrainingsTags = [
    { value: "CANDIDATE", label: "Candidate", color: "warning" },
    { value: "DRAFT", label: "DraftStudent", color: "secondary" },
    { value: "CERTIFIED", label: "CertifiedStudent", color: 'primary' },
    { value: "COMPLETED", label: "CompletedStudent", color: 'primary' },
    { value: "INSCRIBED", label: "Inscribed", color: 'success' },
    { value: "REFUSED", label: "Refused", color: 'danger' },
    { value: "UNPAID", label: "Unpaid", color: 'warning' },
]
export const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]