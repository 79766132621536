import  { FC } from "react";
import { Switch } from "react-router"; 
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import SchoolPreviewPage from "./school-preview-page";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
const PreviewSchoolContainer: FC<any> = (props: any) => { 
          return (<>
         <div className="page-wrapper-height">
            <CustomSwitch> 
                <RouteWithNavbar exact={true} history={props?.history} path={`${props?.match.path}`} component={SchoolPreviewPage} /> 
            </CustomSwitch>
            </div>
            <FooterComponent/>
          </>
          )
      }
export default PreviewSchoolContainer;