import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FC } from "react";
interface Props{
  label:any,
  spanClasses:any,
  required:any,
  classes:any,
  onChange:any,
  dateValue:any,
  status:any

}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
const DatePickerElement: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const checkSpanClassesIfExist = (spanClasses: string | undefined): string => spanClasses || "label";
  const check = (date:any) => {
    props?.onChange(String((date as any).target.value));
  };
  return (
    <input type="date" className="form-control input input-date text-default" value={props?.dateValue} onChange={check}/>

     
  );
};
export default DatePickerElement;
