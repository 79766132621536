import { FC ,useEffect,useMemo} from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import "../../styles/koors.scss"

import DefaultImg from '../../styles/images/default-img-profile.svg'
import { getUserProfileDetails } from "../../global-state/actions/auth-actions";
import { getSchoolInfo } from "../../global-state/actions/app-container-actions";

const SchoolPreviewComponent: FC<ReturnType<typeof mapStateToProps>> = (props:any) => {

    let coverPhoto = useMemo(() => props?.schoolInfo?.coverPhoto, [props?.schoolInfo?.coverPhoto])
    let centralLogo = useMemo(() => props?.schoolInfo?.centralLogo, [props?.schoolInfo?.centralLogo])
    let name = useMemo(() => props?.schoolInfo?.name, [props?.schoolInfo?.name])
    let description = useMemo(() => props?.schoolInfo?.description, [props?.schoolInfo?.description])

    useEffect(() => {
        props?.getSchoolInfo(props?.schoolInfo?.code)   
    }, [])
    
    return (
        <>
            <div className="col-md-12">
                <div className="header-school-preview">
                        {props?.schoolInfo?.coverPhoto === null ? (
                            <div className="header-school-preview-default-cover">
                            </div> 
                            ) : (
                            <div 
                                className="upload-input-photo-ecoles" 
                                style={{
                                    backgroundImage: `url(${coverPhoto})`, 
                                    height: '400px',
                                    backgroundPosition: '25% 21%',
                                    backgroundSize: '100%',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                            </div>
                            )
                        }

                        {props?.schoolInfo?.logo === null ? (
                            <div className="header-school-preview-profile-img">
                                <img src={DefaultImg} />                        
                        </div>
                        ) : <div 
                                className="header-school-preview-profile-img-isexiste"
                            >
                                <div 
                                    style={{
                                        backgroundImage: `url(${centralLogo})`,
                                        width: '150px',
                                        height: '150px',
                                        backgroundSize: 'cover',
                                        borderRadius: '50%',
                                        border: '4px solid #f8f8fa',
                                        backgroundColor: '#fff'
                                    }}
                                >    
                            </div>                    
                        </div>
                        }
                    
                </div>
                <div className=" mx-auto align-items-center text-center mb-4 pb-3 mt-5" >
                <span className="H1-Headline black-800">{name?.toUpperCase()}</span>
                </div>
            </div>
            <div className="col-md-12">
                <div style={{ textAlign: "center", width: "796px", margin: "auto" }}>
                    <div id="school-description" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    schoolConnected: state.authReducer.connectedSchoolInfo,
    connectedUser: state.authReducer.connectedUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getSchoolInfo
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolPreviewComponent);