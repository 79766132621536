 import { EReduxActionTypes,IReduxMessageSetAction , IReduxMessageClearAction } from '../actions/user-auth-actions';


export interface IReduxMessageState {
  error?: string;
}

const  initialState: IReduxMessageState = {
    error: "",
};

type TMessageReducerActions = IReduxMessageSetAction | IReduxMessageClearAction;

export default function(state: IReduxMessageState = initialState, action: TMessageReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.MESSAGE_SET:
      return  {  error: action.data.message }
      case EReduxActionTypes.CLEAR_MESSAGE:
        return  { error: undefined }
    default:
      return state;
  }
}
