import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from '../../helpers/interceptors';
import { IReduxAuthState } from '../reducers/user-auth-reducer';
import qs from 'querystring';
import { getCodeFromUrl, getCodeFromUrlWithParams, getDomainFromUrl } from '../../helpers/domainCheck';
export enum EReduxActionTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  MESSAGE_SET = 'MESSAGE_SET',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
  REGISTER_FAIL = 'REGISTER_FAIL',
  REGISTER_USER = 'REGISTER_USER',
  GET_CONNECTED_USER = 'GET_CONNECTED_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  REGISTER_PROF_DIRECTOR = 'REGISTER_PROF_DIRECTOR',
  REGISTER_STUDENT = 'REGISTER_STUDENT',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  INVITE_PERSON = 'INVITE_PERSON',
  CHECK_TOKEN = 'CHECK_TOKEN',
  CHECK_PASSWORD = 'CHECK_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  INVALIDE_TOKEN = 'INVALIDE_TOKEN',
  VERIFY_EMAIL_EXIST = 'VERIFY_EMAIL_EXIST',
  UPDATE_USER_ID='UPDATE_USER_ID'


}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}


export interface IReduxLoginSuccessAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGIN_SUCCESS;
  data: any;
}

export interface IReduxResetPasswordAction extends IReduxBaseAction {
  type: EReduxActionTypes.RESET_PASSWORD;
  data: any;
}
export interface IReduxUploadImageAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPLOAD_IMAGE;
  data: any;
}

export interface IReduxCheckPasswordAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_PASSWORD | EReduxActionTypes.INVALIDE_TOKEN;
  data: any;
}
export interface IReduxCheckTokenAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_TOKEN | EReduxActionTypes.INVALIDE_TOKEN;
  data: any;
}

export interface IReduxInvitePersonAction extends IReduxBaseAction {
  type: EReduxActionTypes.INVITE_PERSON;
  data: any;
}


export interface IReduxRegisterStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.REGISTER_STUDENT;
  data: any;
}


export interface IReduxRegisterProfDirectorAction extends IReduxBaseAction {
  type: EReduxActionTypes.REGISTER_PROF_DIRECTOR;
  data: any;
}
export interface IReduxGetConnectedUserAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CONNECTED_USER;
  data: any;
}

export interface IReduxInvalideTokenAction extends IReduxBaseAction {
  type: EReduxActionTypes.INVALIDE_TOKEN;
  data: any;
}
export interface IReduxLoginFailsction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGIN_FAIL;
  data: any;
}

export interface IReduxRegisterUserAction extends IReduxBaseAction {
  type: EReduxActionTypes.REGISTER_USER;
  data: any;
}

export interface IReduxLogoutAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOGOUT_USER;
}

export interface IReduxMessageSetAction extends IReduxBaseAction {
  type: EReduxActionTypes.MESSAGE_SET;
  data: any;
}

export interface IReduxMessageClearAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_MESSAGE;
  data: any;
}

export interface IReduxVerifyEmailAction extends IReduxBaseAction {
  type: EReduxActionTypes.VERIFY_EMAIL_EXIST;
  data: any;
}

export interface IReduxUpdateUserIdAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_USER_ID;
  data: any;
}

export function updateSchoolId(schoolId:string): ThunkAction<Promise<IReduxUpdateUserIdAction>, IReduxAuthState, undefined, IReduxUpdateUserIdAction> {
  return async (dispatch: ThunkDispatch<any, undefined, IReduxUpdateUserIdAction>) => {
    return dispatch({
      type: EReduxActionTypes.UPDATE_USER_ID,
      data:schoolId
    });

  }
}

export function loginUser(
  login: any, password: any
): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {
    let baseURL = process.env.REACT_APP_BASE_URL1;
    let baseURL1 = process.env.REACT_APP_BASE_URL2
    let data;
    let code = getCodeFromUrl()
    axios.get(baseURL1 + "person/relation/" + login + "/" + code).then((response) => {
      axios.request({
        url: "/oauth/token",
        method: "post",
        baseURL: baseURL,
        headers: {
          'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
          "grant_type": "password",
          "username": login,
          "password": password,
          "schoolId": response.data.to.id,
        })
      }).then((res) => {


        data = {
          "access_token": res.data.access_token,
          "refresh_token": res.data.refresh_token,
          "expires_in": res.data.expires_in,
          "lastConnexion": res.data.lastConnexion,
          user: {
            "id": res.data.id,
            "idPerson": res.data.id_Person,
            "idSchool": response.data.to.id,
            "logo": response.data.to.logo,
            "role": res.data.scope
          },
          userInfo: {
            "photo": response.data.from.photo,
            "firstName": response.data.from.firstName,
            "lastName": response.data.from.lastName,
          },
          schoolInfo: {

            "code": response.data.to.code,
            "color": response.data.to.color,
            "coverPhoto": response.data.to.coverPhoto,
            "centralLogo": response.data.to.centralLogo,
            "creationDate": response.data.to.creationDate,
            "description": response.data.to.description,
            "logo": response.data.to.logo,
            "name": response.data.to.name,
            "shortDescription": response.data.to.shortDescription
          }
        }


        dispatch({
          type: EReduxActionTypes.LOGIN_SUCCESS,
          data: data,
        });
        if (res.data.scope === "role_student") {
          axios.post(baseURL + "history/students", { action: 'LOGIN', idPerson: res.data.id_Person })
        }


      }).catch((err) => {

        if (err.response.data.error_description === "VI")
          data = { message: "E-mail ou mot de passe incorrecte" }

        else if (err.response.data.error_description === "CB") {
          data = { message: "Merci d'activer votre compte en cliquant sur lien envoyé à votre adresse mail" }
        }
        else if (err.response.data.error_description === "Le compte utilisateur est d&eacute;sactiv&eacute;") {
          data = { message: "Votre compte a été désactivé" }

        }
        else if (err.response.data.error_description === "Bad credentials") {
          data = { message: "E-mail ou mot de passe incorrecte" }
        }
        else {
          data = { message: "Problème de connexion au serveur. Veuillez réessayer ultérieurement." }
        }
        dispatch({
          type: EReduxActionTypes.LOGIN_FAIL,
        });

        dispatch({
          type: EReduxActionTypes.MESSAGE_SET,
          data: data
        });


      })
    }).catch((err) => {
      if (err.response.data.message === "Le compte utilisateur est d&eacute;sactiv&eacute") {
        data = { message: "Votre compte a été désactivé" }

      } else {
        data = { message: "E-mail ou mot de passe incorrecte" }

      }
      dispatch({
        type: EReduxActionTypes.LOGIN_FAIL,
      });

      dispatch({
        type: EReduxActionTypes.MESSAGE_SET,
        data: data
      });
    })
  };
}

export function logout(): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {


    return dispatch({
      type: EReduxActionTypes.LOGOUT_USER,
    });


  };

}

export function clearMessage(): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {


    return dispatch({
      type: EReduxActionTypes.CLEAR_MESSAGE,
    });

  }
}

export function registerStudent(register:any): ThunkAction<Promise<IReduxRegisterStudentAction>, IReduxAuthState, undefined, IReduxRegisterStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxRegisterStudentAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    const registerStudents = await axios.post(baseUrl + "person/register", {
      "email": register.email,
      "lastName": register.lastName,
      "firstName": register.firstName,
      "password": register.password,
      "dateNaiss": register.birthDay,
      "photo": register.photo,
      "gender": register.gender,
      "schooling": register.levelStudy === "" ? null : register.levelStudy,
      "country": register.country === "" ? null : register.country,
      "city": register.city,
      "role": "role_student",
      "domain": getCodeFromUrlWithParams(register.link),
      "domainUrl":getDomainFromUrl()
    }).then((response) => {
      axios.get(baseUrl + "person/getlegalpersonbycode/" + ( getCodeFromUrlWithParams(register.link))).then((responsel) => {
        axios.post(baseUrl + "person/attach", {
          "invitation": {
            "relationType": "STUDENT",
            "to": {
              "id": responsel.data.id,
              "code": responsel.data.code,
              "type": "LP"
            },
            "invited": {
              "id": response.data.id,
              "firstName": response.data.firstName,
              "type": "NP"
            }
          }
        }).then(resp => {  return resp; }).catch(err => console.error(err));
      })
    })

    return dispatch({
      type: EReduxActionTypes.REGISTER_STUDENT,
      data: registerStudents
    });

  }
}
export function registerProfDirector(register:any): ThunkAction<Promise<IReduxRegisterProfDirectorAction>, IReduxAuthState, undefined, IReduxRegisterProfDirectorAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxRegisterProfDirectorAction>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL2;


    const registerProfDirectors = await axios.post(baseUrl + "person/register", {
      "id": register.id,
      "email": register.email,
      "lastName": register.lastName,
      "firstName": register.firstName,
      "password": register.password,
      "dateNaiss": register.birthDay,
      "photo": register.photo,
      "gender": register.gender,
      "schooling": register.levelStudy === "" ? null : register.levelStudy,
      "country": register.country === "" ? null : register.country,
      "city": register.city,
      "token": register.token.substring(register.token.indexOf("-") + 1),
      "role": register.relationType,
      "bio": register.bio,
      "domainUrl":getDomainFromUrl()


    }).then(response => {  return response.data; }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.REGISTER_PROF_DIRECTOR,
      data: registerProfDirectors
    });

  }
}

export function uploadImage(form: any): ThunkAction<Promise<IReduxUploadImageAction>, IReduxAuthState, undefined, IReduxUploadImageAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxUploadImageAction>) => {
    const baseUrlGed = process.env.REACT_APP_BASE_URL4;
    const res = axios.post(baseUrlGed + "upload/image",
      form
    ).then(response => {  return response.data; }).catch(err => console.error(err));

    return dispatch({
      type: EReduxActionTypes.UPLOAD_IMAGE,
      data: res
    });

  }
}

export function checkPassword(token: any): ThunkAction<Promise<IReduxCheckPasswordAction>, IReduxAuthState, undefined, IReduxCheckPasswordAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxCheckPasswordAction>) => {
    const res = axios.get(process.env.REACT_APP_BASE_URL1 + "person/checkmail/password?token=" + token).then(response => { ; return response.data; })
    .catch(err => {
      dispatch({ type: EReduxActionTypes.INVALIDE_TOKEN, data: err })
    });

    return dispatch({
      type: EReduxActionTypes.CHECK_PASSWORD,
      data: res
    });

  }
}

export function resetPassword(row: any, confirmPass: any): ThunkAction<Promise<IReduxResetPasswordAction>, IReduxAuthState, undefined, IReduxResetPasswordAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxResetPasswordAction>) => {

    const res = await axios.post(process.env.REACT_APP_BASE_URL1 + "person/resetpassword", {
      "email": row,
      "password": confirmPass
    }).then(response => {  return response.data; }).catch(err => console.error(err));



    return dispatch({
      type: EReduxActionTypes.RESET_PASSWORD,
      data: res
    });

  }
}


export function CheckToken(token: any): ThunkAction<Promise<IReduxCheckTokenAction>, IReduxAuthState, undefined, IReduxCheckTokenAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxCheckTokenAction>) => {
  

    const res = axios.get(process.env.REACT_APP_BASE_URL1 + "person/checkmail/mail?token=" + token)
      .then(data => { return "token Verifié"; }).catch(err => {
        dispatch({ type: EReduxActionTypes.INVALIDE_TOKEN, data: "token invalide" })
      });

    return dispatch({
      type: EReduxActionTypes.CHECK_TOKEN,
      data: res
    });

  }
}
export function invitePerson(token: string): ThunkAction<Promise<IReduxInvitePersonAction>, IReduxAuthState, undefined, IReduxInvitePersonAction> {
  return async (dispatch: ThunkDispatch<IReduxAuthState, undefined, IReduxInvitePersonAction>) => {

    const res = await axios.get(process.env.REACT_APP_BASE_URL2 + "person/invitation/" + token.substring(token.indexOf("-") + 1))
      .then(response => { return response.data; }).catch(err => console.error(err));
            

    return dispatch({
      type: EReduxActionTypes.INVITE_PERSON,
      data: res
    });

  }
}
export function verifyEmailExist(email:any): ThunkAction<Promise<IReduxVerifyEmailAction>, IReduxAuthState, undefined, IReduxVerifyEmailAction> {
  return async (dispatch: ThunkDispatch<any, undefined, IReduxVerifyEmailAction>) => {

  const res = await axios.get(process.env.REACT_APP_BASE_URL2 + "person/getpersonbyemail/" + email?.toLowerCase() )
      .then(response => { return response.data; }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.VERIFY_EMAIL_EXIST,
      data: res
    });
  }
}
export function getUserById(id: any): ThunkAction<Promise<any>, IReduxAuthState, undefined, any> {
  return async (dispatch: ThunkDispatch<any, undefined, any>) => {

    let auth = ('Bearer ' + localStorage.getItem("access_token")).replace(/\"/g, "")
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    let data;
    axios.get(baseUrl + "users/" + id,
      {
        headers: {
          'Authorization': auth
        }
      }).then((response) => {
        data = {

          userInfo: {

            "photo": response.data.photo,
            "firstName": response.data.firstName,
            "lastName": response.data.lastName,

          },
        }
        dispatch({
          type: EReduxActionTypes.GET_CONNECTED_USER,
          data: data,
        });
      })
  }


}
