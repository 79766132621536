import { useState } from 'react';
import { useTranslation } from "react-i18next";
export default function AccordionFAQ() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const openAccordion = () => {
        setOpen(!open);
    }
    return (
        <>
            <div className="d-flex flex-column py-4 bg-accordion-border accordion-FAQ inner pe-2">
                <div style={{cursor: "pointer"}} className="d-flex flex-row justify-content-between align-items-center black-800" onClick={() => openAccordion()}>
                    <span className="body-sm bold">{t('textFaq')}</span>
                    {!open ? <>
                        <span className="material-icons-outlined">expand_less</span>
                    </> :
                        <> <span className="material-icons-outlined">expand_more</span></>
                    }
                </div>
                <div className={`${(open !== true) ? "mt-3" : ""}`} style={!(open) ?
                    { height: 'max-content', transition: 'all .3s ease', overflow: 'hidden' } :
                    { height: '0px', transition: 'all .3s ease', overflow: 'hidden' }}>
                    <span className="body-sm neutral-2 pe-3 me-4 accordion-faq-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                        sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
                </div>
            </div >
        </>

    );
}