import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { couponStatus } from '../../helpers/commons';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { createCoupon, getCoupons, getCouponsByProductAndPromoCode } from '../../services/coupon.service';
import { activateCoupon, cancelCoupon, desactivateCoupon } from '../../global-state/actions/form-actions';
const status = [
    { label: "Planifié", color: "warning" },
    { label: "En cours", color: "success" },
    { label: "Éxpiré", color: "secondary" },
    { label: "Brouillon", color: "primary" },
    { label: "Annulé", color: "dark" }
]


const NavbarCouponComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [actualStatus, setActualStatus] = useState<any>()
    const [statusCoupon, setCouponStatus] = useState<any>()
    const [couponForm, setCouponForm] = useState<any>(props.liveCoupon);
    const [errors, setErrors] = useState<any>();


    useEffect(() => {
        setCouponForm((prevState: any) => ({
            ...prevState,
            status: props.couponStatus === null ? "DRAFT" : (props.couponStatus ? "ACTIVE" : "INACTIVE")
        }));
        
        
    }, [props.couponStatus]);

    useEffect(() => {
        setCouponForm(props.liveCoupon);
    }, [props.liveCoupon]);
    
    useEffect(() => {
        let statusCouponVar = "";
        if (couponForm.status === "DRAFT" || couponForm.status === null) {
            statusCouponVar = "Brouillon";
            props?.cancelCoupon()
        } else if (couponForm.status === "INACTIVE") {
            statusCouponVar = "Annulé";
             props?.desactivateCoupon();

        } else {
            props?.activateCoupon();
            statusCouponVar = couponStatus(couponForm.startAt, couponForm.expireAt);
        }

        setActualStatus(status.find((e: any) => e?.label === statusCouponVar));
        setCouponStatus(statusCouponVar);
        return ()=>{
            props?.cancelCoupon();
        }
    }, [props.cancelCoupon, props.desactivateCoupon, props.activateCoupon])


    const checkConditions = async (coupon: any) => {

        
        let check = true;
        const errorsCopy = { ...errors };
        let isValid = false;
        if (coupon?.promotion?.promoCode != '') {
            await getCouponsByProductAndPromoCode("KOORS",coupon?.promotion?.promoCode).then((response: any) => {
        
                if (response?.data?.id == null) {
                    isValid = true
                } else {
                    isValid = false
                }

            })

        }

        if (coupon?.offers?.length === 0) {
            errorsCopy['offerError'] = "Champ “S’applique à” incomplet (obligatoire).";

            check = false;
        } else {
            errorsCopy['offerError'] = '';
        }
        if (coupon?.promotion?.promoValue == null || coupon?.promotion?.promoValue === '') {
            errorsCopy['valueError'] = "Champ “Valeur de réduction” incomplet (obligatoire)."
            check = false;
        } else {
            errorsCopy['valueError'] = '';
        }
        if (coupon?.startAt == null) {
            errorsCopy['timeError'] = "La date d’activation  n’a pas été saisi (obligatoire)."
            check = false;
        } else {
            errorsCopy['timeError'] = "";
        }
        if (!props.liveCoupon || coupon?.promotion?.promoCode == '') {
            errorsCopy['codeError'] = "Le code du coupon existe déjà."
            check = false;
        } else {
            errorsCopy['codeError'] = '';
        }
        setErrors(errorsCopy);
        return { check: check, errors: errorsCopy };
    }
    const verifConditions = async () => {
        const check = await checkConditions(couponForm);
        
        if (check?.check && !!couponForm) {
            const copForm = { ...couponForm, status: "ACTIVE" };
    
            try {
                await createCoupon(copForm);
                await getCoupons(props?.connectedUser?.idSchool, "SCHOOL");
    
                await props?.openModal({
                    idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON,
                    size: "md",
                    lines: ["Coupon publié avec succès"],
                    path: "/coupon",
                    buttonText: "retour à la liste des coupons"
                });
            } catch (error) {
                console.error("Error creating coupon:", error);
            }
        } else {
            props?.openModal({
                idModal: ModalIDS.CHECK_FORM_COUPON_CONDITIONS,
                size: "md",
                data: { errors: check.errors }
            });
        }
    };
    
    const openModalFn = async (statusCouponValue: string) => {
        const updatedCouponForm = { ...couponForm };
        updatedCouponForm.status = statusCouponValue;
      await props?.openModal({ idModal: (statusCouponValue === "INACTIVE") ? ModalIDS.DEACTIVATE_COUPON : ModalIDS.ACTIVATE_COUPON, size: "md", data: updatedCouponForm,originalStatus:couponForm.status });
    }
    const goBackToList = async () => {
        let returnBaseUrl= await returnStartPathUrl()        
        if(returnBaseUrl!=='' && returnBaseUrl!==undefined){
            history.push(`${returnBaseUrl}/coupon`);
            await setCouponForm({
                product: "KOORS",
                promotion: {
                    promoCode: null,
                    promoType: "DISCOUNT",
                    promoValue: "",
                },
                referenceCode: null,
                startAt: null,
                expireAt: null,
                status: "DRAFT",
                offers: [],
                entityId: props?.connectedUser?.idSchool,
                entityType: "SCHOOL",
            });
        }
        history.push(`${returnStartPathUrl()}/coupon`)

    }

    const deleteCouponDraft = async () => {
        if (!!couponForm && !!couponForm?.referenceCode) {
            await props?.openModal({ idModal: ModalIDS.DELETE_COUPON, size: "md", data: { coupon: couponForm } })
        }else{
            await goBackToList()
        }
    }
    return (
        <>
            <div className="cours-nav">
                <div className="navbar navbar-expand-lg d-flex justify-content-between p-0 flex-nowrap">
                    <div className="d-flex order-0">
                        <a onClick={() => { goBackToList() }} style={{ cursor: 'pointer' }} className="d-flex justify-content-center align-items-center nav-back">
                            <span className="material-icons-round">
                                chevron_left
                            </span>
                        </a>
                        <div className="d-flex px-4 align-items-center">


                            <span className='body-md bold breadcrumb-links ' onClick={()=>{history.push(`${returnStartPathUrl()}/coupon`)}}>Coupons</span>
                            <div className="d-flex flex-row align-items-center" >
                                <hr className="breadcrumb-links hr-separator" />

                            </div>
                            <span className='body-md bold breadcrumb-links active' style={{ width: 200 }}>Nouveau coupon</span>

                        </div>

                    </div>
                    {statusCoupon === "Brouillon" ? <div className="d-flex justify-content-end order-1 w-100">
                        <div className="d-flex justify-content-center align-items-center px-4">
                            <span className={`badge-text ${actualStatus?.color}`}>
                                {statusCoupon}
                            </span>
                        </div>
                        <a style={{ cursor: 'pointer' }} onClick={() => deleteCouponDraft()} className="d-flex justify-content-center align-items-center px-4 nav secondary-action">
                            <span>
                                {t('delete')}
                            </span>
                        </a>
                        <button style={{ cursor: 'pointer', border: 'none' }} onClick={() => verifConditions()} className={`d-flex justify-content-center align-items-center nav publish success`}>
                            <span>
                                {t('publishMyReview')}
                            </span>
                        </button>
                    </div> : <>
                        <div style={{ cursor: 'pointer', border: 'none' }} className={`d-flex justify-content-center align-items-center nav-state-${(couponForm?.status === 'ACTIVE') ? 'enligne' : 'horsligne'}-prof`}>
                            <input dir="ltr" readOnly={true} checked={couponForm?.status === 'ACTIVE'} className="input-style" onClick={() => openModalFn((couponForm?.status === 'INACTIVE') ? 'ACTIVE' : 'INACTIVE')} type="checkbox" id="toggleBtn" />
                            <div className="toggle-container" onClick={() => openModalFn((couponForm?.status === 'INACTIVE') ? 'ACTIVE' : 'INACTIVE')}>
                                <label className="label-style" htmlFor="toggleBtn">
                                    <span className="on">Actif</span>
                                    <span className="off">Inactif </span>
                                    <input dir="ltr" type="text" style={{ display: 'none' }} />
                                </label>
                            </div>
                        </div>

                    </>}
                </div>
            </div>

        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    lastPath: state.historyReducer.lastPathBeforeBreadCrumb,
    returnPathPlateform: state.historyReducer.returnPathPlateform,
    couponStatus:state.formReducer.couponStatus
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            cancelCoupon,
            activateCoupon,
            desactivateCoupon,
            openModal,

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(NavbarCouponComponent);