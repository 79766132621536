import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';

const CheckFormCouponModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();

    const [errors, setErrors] = useState<any>();
    useEffect(() => {
        setErrors(props?.modalContent?.data?.errors);
    }, [props?.modalContent]);
    return (
        <>
            <div className="d-flex w-100 align-items-center justify-content-end pt-3 pe-3">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
            <div className="d-flex justify-content-center" style={{ paddingTop: 0, marginLeft: '10px', overflow:'hidden'}}>
                <div className="col-md-10 text-left">                   
                        <h3 className="H3-Headline black-800 " style={{ textAlign: 'left' }}>Désolé vous ne pouvez pas publier ce coupon</h3>
                       
                        {(errors && errors.codeError && errors.codeError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.codeError}</p>}
                        {(errors && errors.valueError && errors.valueError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.valueError}</p>}
                        {(errors && errors.offerError && errors.offerError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.offerError}</p>}
                        {(errors && errors.timeError && errors.timeError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.timeError}</p>}                        

                </div>
            </div>
            <div className="d-flex mb-5 mt-3 me-5 pe-3 justify-content-end" >
                <button type="button" onClick={() => props?.closeModal()} className="btn-Primary large">retour au coupon</button>
            </div>
        </>

    )

}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal,
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    courseForm: state.courseReducer.courseForm,
    modalContent: state.modalReducer.modalContent
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckFormCouponModalComponent);