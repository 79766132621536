import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import "../../translations/i18n";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';


const DisplayOpenQuestionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const answer = useMemo(() => { return props?.answer }, [props?.answer]);
    const question = useMemo(() => {return props?.question }, [props?.question]);
    const [index] = useState(props?.index);

    const { t } = useTranslation();
    return (
        <div>
            <div className="d-flex flex-column" style={(props?.color)&& {backgroundColor: 'white'}}>
                <div className="d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40">
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                        <div className="d-flex flex-column" >
                            <div className="d-flex flex-row flex-wrap align-items-center">
                                <span className="H3-Headline mb-2 me-2 pe-1"><span className="H3-Headline neutral-3">{t('Question')} </span>{index + 1}</span>
                                <span className="body-sm bold"><span className="body-sm bold neutral-3">{t('openQuestion')} <span className={`badge-text ${((answer?.questionMark / question?.scaleNote) < 0.5) ? 'danger' : 'success'}`}>{answer?.questionMark}</span>/{question?.scaleNote} {t('pointsQuestion')}</span></span>
                            </div>
                            <span className="H4-Subtitle bold "> {question?.question?.questionStatement} </span>
                        </div>
                    </div>
                </div>

                <div className="bg-accordion-border accordion-body pb-3">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row mb-4">
                            <div className={`badge-square ${((answer?.questionMark / question?.scaleNote) >= 0.5) ? 'success' : 'danger'} me-4`}>
                                <span className="material-icons-outlined">{((answer?.questionMark / question?.scaleNote) >= 0.5) ? 'check' : 'close'}</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="form-title mb-2">{t('Commentaire_du_professeur')}</span>
                                <span className="body-md neutral-2" > {answer?.teacherComment}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {


        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    question: ownProps?.question,
    color: ownProps?.color,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    exam: state.examReducer.examToPass,
    studentAnswers: state.examReducer.listOfResponses,
    sectionContent: state.studentTraining.sectionContentDetails

});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayOpenQuestionComponent);
