import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import axiosToken from '../../helpers/interceptors';
import { IReduxCoursesState } from '../reducers/course-reducer';
import axiosInstance from '../../helpers/interceptors';

export enum EReduxActionTypes {
  GET_CATALOG = 'GET_CATALOG',
  LOADING_CATALOG = 'LOADING_CATALOG',
  CHANGE_STYLE_CARD = 'CHANGE_STYLE_CARD',
  GET_COURSES_PROF = 'GET_COURSES_PROF',
  LOADING_COURSES = 'LOADING_COURSES',
  GET_COURSES = 'GET_COURSES',
  GET_MY_COURSES= 'GET_MY_COURSES',
  GET_COUNT_COURSES = 'GET_COUNT_COURSES',
  LOADING_CATALOG_SCROLL = 'LOADING_CATALOG_SCROLL',
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}
export interface IReduxGetCatalogAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CATALOG | EReduxActionTypes.LOADING_CATALOG  | EReduxActionTypes.LOADING_CATALOG_SCROLL;
  data: any;
  pageNumber:any
  scrollOrNot:any
}
export interface IReduxGetCountCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COUNT_COURSES;
  data: any;
}
export interface IReduxLoadingScrollCatalogAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_CATALOG_SCROLL
  data: any;
  pageNumber:any
  scrollOrNot:any
}
export interface IReduxGetCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES;
  data: any;
}

export interface IReduxGetCoursesProfAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_PROF | EReduxActionTypes.LOADING_COURSES;
  data: any;
}

export interface IReduxGetMyCoursesStudentCatalogAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_MY_COURSES ;
  data: any;
}
export interface IReduxChangeStyleCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_STYLE_CARD;
  data: any;
}


export function getCoursesProf(filter: any): ThunkAction<Promise<IReduxGetCoursesProfAction>, IReduxCoursesState, undefined, IReduxGetCoursesProfAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCoursesProfAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_COURSES, data: undefined })


    const baseUrl = process.env.REACT_APP_BASE_URL14;
    const courses = axiosToken.post(baseUrl + `training/coursebyprof/${filter.idProf}/${filter.entityType}/${filter.entityId}`)
      .then((resp: any) => resp)
      .catch(e => { return { data: [] } })
    const listIdCours = {
      ids: (await courses).data.map((obj: any) => { return obj.id })
    }
    const baseUrl2 = process.env.REACT_APP_BASE_URL11;
    const price = await axiosInstance.post(baseUrl2 + `offers/training`, listIdCours.ids)
      .then((resp: any) => resp)
      .catch(e => { return { data: [] } })
    const coursesList = await axios.all([courses, price]).then(axios.spread((...responses) => {
      return (responses[0].data.map((course: any) => {
        return {

          price: {
            price: responses[1].data.find((obj: any) => Number(obj.trainingId) === Number(course.id))
          },
          ...course
        }
      }
      ))
    })).catch(errors => {
      console.error(errors)
    })
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_PROF,
      data:coursesList
    })
  }
}


export function getCatalog(filter: any,action:any): ThunkAction<Promise<IReduxGetCatalogAction>, IReduxCoursesState, undefined, IReduxGetCatalogAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCatalogAction>) => {
    if(action === 'first time') {
      dispatch({type:EReduxActionTypes.LOADING_CATALOG,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
    } else {
      dispatch({type:EReduxActionTypes.LOADING_CATALOG_SCROLL,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
    }
    const catalogeCourses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/catalog`, filter)
      .then((resp: any) => resp.data)
      .catch(e => { return [] })
      let pageNumber=catalogeCourses? filter?.pageNumber+1:filter?.pageNumber
      return dispatch({
        type: EReduxActionTypes.GET_CATALOG,
        data: catalogeCourses,
        pageNumber,
        scrollOrNot: action
      })
  }
}
export function getCountCourses(filter: any): ThunkAction<Promise<IReduxGetCountCoursesAction>, IReduxCoursesState, undefined, IReduxGetCountCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCountCoursesAction>) => {


    const catalogeCourses = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}courses/count/`+ filter)
      .then((resp: any) => resp.data)
      .catch(e => { return [] })
    return dispatch({
      type: EReduxActionTypes.GET_COUNT_COURSES,
      data: catalogeCourses
    })
  }
}
export function getCoursesPath(id: any, trainingType: string): ThunkAction<Promise<IReduxGetCoursesAction>, IReduxCoursesState, undefined, IReduxGetCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCoursesAction>) => {
    
    const catalogeCourses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}follow/path/course/${trainingType}/${id}`)
      .then((resp: any) =>



        axiosInstance.post(`${process.env.REACT_APP_BASE_URL}courses/list/`, resp.data).then((res: any) => {
          return res.data;
        })
          .catch(e => { return [] }));





 
    return dispatch({
            type: EReduxActionTypes.GET_COURSES,
            data: catalogeCourses
          })
  }
}

export function changeStyleCardCourse(style: string): ThunkAction<Promise<IReduxChangeStyleCourseAction>, IReduxCoursesState, undefined, IReduxChangeStyleCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxChangeStyleCourseAction>) => {

    return dispatch({
      type: EReduxActionTypes.CHANGE_STYLE_CARD,
      data: style
    });
  }
}

export function getMyCoursesStudentCatalog(filter: any): ThunkAction<Promise<IReduxGetMyCoursesStudentCatalogAction>, IReduxCoursesState, undefined, IReduxGetMyCoursesStudentCatalogAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetMyCoursesStudentCatalogAction>) => {

    const myCourses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/filter`, filter)
      .then((resp: any) => resp.data)
      .catch((e: any) => { return [] })
    let Subject = myCourses?.filter((training: any) => {
      return training?.followUp?.statusList?.find((status: any) => {
        return new Date(status?.creationDate).getTime() === Math.max(...training.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))
      })?.status === "CANDIDATE"
        || training.followUp.statusList.find((status: any) => {
          return new Date(status?.creationDate).getTime() === Math.max(...training.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))
        })?.status === "REFUSED"
    })

    let myCoursesFinal = myCourses?.map((obj: any) => {
      let statusFinal = obj.followUp.statusList.find((status: any) => { return new Date(status?.creationDate).getTime() === Math.max(...obj.followUp?.statusList?.map((e: any) => new Date(e.creationDate))) })

      if (statusFinal.status === "CANDIDATE" || statusFinal.status === "REFUSED") {
               return obj
      }
      return obj

    })

    return dispatch({
      type: EReduxActionTypes.GET_MY_COURSES,
      data: myCoursesFinal
    })
  }
}

