import { ComponentProps, FC, useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { createFollow, clearAllData, sendIpAddressCourse, getOfferCourse, getContent, getRegistrationForm, checkFollowStudentCourse, updateShow, createOrUpdateOfferCourse, getOfferPricingCourse, getFollowByFilter, getApplicationFile } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentPathSectionComponent from "../../components/preview-course-components/content-path-section-component";
import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import { clearFeedbacks, getAllFeedbacks } from '../../global-state/actions/feedback-actions';
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";
import DescPreviewCourse from "../../components/preview-course-components/desc-preview-course";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { useTranslation } from "react-i18next";
import { getProfDataByDirector } from "../../global-state/actions/auth-actions";
import { getTrainingById } from "../../global-state/actions/training-actions";
import axiosInstance from "../../helpers/interceptors";
import { saveReturnPath, redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { refreshToken } from '../../global-state/actions/auth-actions';
import { getCurrentTraining } from "../../global-state/actions/student-training-actions";
import { ModalIDS } from "../../components/modals/modal-ids";
import { openModal } from "../../global-state/actions/modal-actions";
import ButtonFollowLoading from "../../components/preview-course-components/button-follow-loading";
import ListFeedbackSectionComponentLoading from "../../components/preview-course-components/list-feedback-section-component-loading";
import HowToWorkSectionComponentLoading from "../../components/preview-course-components/how-to-work-section-component-loading";
import SchoolProfSectionComponentLoading from "../../components/preview-course-components/school-prof-section-component-loading";
import CourseContentLoading from "../../components/preview-course-components/course-content-loading";
import SkillsSectionComponentLoading from "../../components/preview-course-components/skills-section-component-loading";
import TrainingHeaderComponentLoading from "../../components/preview-course-components/training-header-component-loading";



interface Params {
    id: string
}
const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]
const PreviewSessionPageFromProf: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [show, setShow] = useState()
    const [courseInfo, setCourseInfo] = useState<any>();
    const location = useLocation();
    const [price, setPrice] = useState<any>()
    const [profData, setProfData] = useState<any>();
    const [ips, setIps] = useState('')
    const { id } = useParams<Params>();
    const [trainingLevel, setTrainingLevel] = useState<any>()
    const [contentWidth, setContentWidth] = useState<any>()
    const [enableOfferCreation, setenableOfferCreation] = useState<boolean>(true)
    const [courseTarificationForm, setCourseTarificationForm] = useState(undefined)
    const [clicked, setClicked] = useState<boolean>(false);
    const [trainingId, setTrainingId] = useState<number>(0)
    const [disableFollowTrainingBtn, setDisableFollowTrainingBtn] = useState<boolean>(false)
    const history = useHistory();
    const { t } = useTranslation();
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState<boolean>(true)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);


    useEffect(() => {

        if (props?.from?.path.includes('sign-in')) {
            setIsConnected(true)
        } else {
            setIsConnected(false)
        }
    }, [props?.from])

    useEffect(() => {
        if (!!props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
            setIsConnected(true)
        }
    }, [props?.connectedUser])

    useEffect(() => {

        if (windowSize != undefined && windowSize.current[0] < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [windowSize])




    useEffect(() => {
        if (props?.courseInfo?.id != undefined && props?.courseInfo?.id !== null) {
            setTrainingId(props?.courseInfo?.id)
        }

    }, [props?.courseInfo])

    useEffect(() => {
        if (props?.courseTarificationForm !== undefined && props?.courseTarificationForm !== courseTarificationForm) {
            setCourseTarificationForm(props?.courseTarificationForm)
        }
    }, [props?.courseTarificationForm])


    useEffect(() => {
        if (trainingId !== 0) {
            getSessionOfferFn()
        }
    }, [trainingId])


    const getSessionOfferFn = async () => {
        const offerSession = {
            id: null,
            idEntity: props?.connectedUser?.idSchool,
            label: props?.courseInfo?.title,
            status: "VALIDE",
            typePricing: "STATIC",

            rules: [
                {
                    id: null,
                    key: "id_session",
                    value: props?.courseInfo?.id,
                    valueType: "number",
                    code_product: "SSN"
                },
                {
                    id: null,
                    key: "nb_month_access",
                    value: "-1",
                    valueType: "number",
                    code_product: "SSN"
                }
            ],
            paymentEngine: "STRIPE",
            pricings: [{
                id: null,
                period: "ONESHOT",
                status: "ACTIVE",
                price: 0,
                currency: "EUR",
                startDate: new Date(),
                endDate: null
            }],
            stripeAccount: props?.paymentMethod?.accountId
        }
        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        await axiosInstance.get(baseUrl3 + `offers/offerId/` + props?.courseInfo?.id?.toString()).then(async res => {
            if (res.data) {
                await props?.getOfferPricingCourse(res.data)
            } else {
                if (props?.courseInfo?.status === "PENDING" && courseTarificationForm === undefined && enableOfferCreation === true && props?.courseInfo?.id != undefined) {
                    setTimeout(() => {
                        props.createOrUpdateOfferCourse(offerSession).then(() => {
                            setenableOfferCreation(false)
                            getSessionOfferFn()

                        });
                    }, 500)

                }
            }
        }).catch(err => console.error(err));

    }

    useEffect(() => {
        if (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
            let object = {
                studentId: props?.connectedUser?.idPerson,

                sessionId: id,
                trainingType: "SESSION",
                entityType: "SCHOOL",
                entityId: props?.schoolInfo?.id
            }
            props?.getFollowByFilter(object)
        }
    }, [])

    useEffect(() => {
        if (ips !== '') {
            props?.sendIpAddressCourse({ address: ips, idCourse: props?.courseInfo?.id })
        }
    }, [ips])

    useEffect(() => {

        if (props?.courseInfo === undefined || props?.courseInfo === null || (props?.courseInfo && props?.courseInfo?.id !== Number(id))) {
            props?.getOfferCourse(Number(id));
            let filter = {
                trainingType: "SESSION",
                trainingId: Number(id)
            }

            props?.getContent(filter);
            props?.getOfferCourse(Number(id));
            if (props?.courseInfo?.idRegistrationForm !== undefined && props?.courseInfo?.idRegistrationForm !== null && props?.courseInfo?.idRegistrationForm !== 0) {
                props?.getRegistrationForm(props?.courseInfo?.idRegistrationForm);
            }
        } else {
            setCourseInfo(props?.courseInfo);
        }
        if (location.pathname.includes("preview")) {
            fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=b6078e4a8db0480488ea97bd57ee755f").then(response => response.json())
                .then(dataInfo => setIps(dataInfo.ip_address));

        }
        const data = async () => {
            await props?.clearFeedbacks();
            await props?.getAllFeedbacks(Number(id), 0, 'refresh');
        }
        data();
        return () => {
            props?.clearFeedbacks();
        };
    }, []);

    useEffect(() => {
        if (props?.connectedUser?.role?.includes("role_student")) {
            props?.checkFollowStudentCourse(id, props?.connectedUser?.idPerson, props?.courseInfo?.type)

        }
        getTrainingPricing()
    }, []);

    useEffect(() => {
        setShow(props?.show)
    }, [props?.show]);

    useEffect(() => {
        if (props?.courseInfo) {
            props?.courseInfo?.orders?.sort((a: any, b: any) => {
                if (a.index > b.index) {
                    return 1;
                } else if (a.index < b.index) {
                    return -1
                } else {
                    return 0
                }
            })
            setCourseInfo(props?.courseInfo);
        }
    }, [props?.courseInfo])

    useEffect(() => {
        setPrice(props?.priceCourse);
    }, [props?.priceCourse])


    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }

    const avisClicked = () => {
        document.getElementById('clickLink')?.click();
    }

    useEffect(() => {
        if (props?.courseInfo != undefined) {
            const levelFouned = levels.find(obj => obj.value === props?.courseInfo?.level);
            setTrainingLevel((levelFouned) ? levelFouned?.label : '...');
        }

        if (
            props &&
            props.courseInfo &&
            props.courseInfo.prerequisite !== undefined &&
            props.courseInfo.prerequisite !== null &&
            props.courseInfo.skills !== undefined &&
            props.courseInfo.skills !== null &&
            props.courseInfo.skills.length > 0 &&
            props.courseInfo.objectives !== undefined &&
            props.courseInfo.objectives !== null
        ) {
            setContentWidth("100%");
        } else {
            setContentWidth("70%");
        }
    }, [props?.courseInfo])

    useEffect(() => {
        setProfData(props?.profData);
    }, [props?.profData])

    useEffect(() => {
        if (props?.followByTraining != undefined && props?.followByTraining?.length > 0) {
            setDisableFollowTrainingBtn(true)
        } else {
            setDisableFollowTrainingBtn(false)
        }
    }, [props?.followByTraining])

    const getTrainingPricing = async () => {
        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        if (props.courseInfo !== null && props.courseInfo !== undefined && props.courseInfo?.id) {
            axiosInstance.get(baseUrl3 + `offers/offerId/` + props?.courseInfo?.id.toString()).then(res => {
                if (res?.data && res.data !== "") {
                    axiosInstance.get(baseUrl3 + `offers/getOffer/` + res.data).then(offerResponse => {
                        setPrice(offerResponse?.data?.pricings)
                    }).catch((error: any) => {
                        console.error(error)
                    })
                }
            }).catch((error: any) => {
                console.error(error)
            })
        }
    }

    useEffect(() => {
        if (props?.follow && props?.follow[0] && props?.follow[0]?.id && clicked) {
            props.getCurrentTraining(props?.follow[0]?.id);
            history.push(`${returnStartPathUrl()}${(courseInfo?.type === 'COURSE') ? '/passCourse/course' : (courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.follow[0]?.id}`);
        }
    }, [props?.follow, clicked]);

    useEffect(() => {
        props?.clearHistoryArray();
        props?.redirect(t('Catalogue'), `${returnStartPathUrl()}/catalog`);
        props?.redirect(props?.courseInfo?.title, `${returnStartPathUrl()}/catalog/preview/session/${props?.courseInfo?.id}`);
    }, [courseInfo]);

    const followTraining = async (trainingType: string) => {

        if (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
            if (!!props?.followByTraining && props?.followByTraining?.length > 0) {
                history.push(`${returnStartPathUrl()}${(courseInfo?.type === 'COURSE') ? '/passCourse/course' : (courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.followByTraining[0]?.id}`);

            } else if (
                (
                    courseInfo?.idRegistrationForm === 0
                    || courseInfo?.idRegistrationForm === null
                    || courseInfo?.idRegistrationForm === undefined
                )
                && (price !== undefined && price[0]?.price === 0)
            ) {

                let contractToCreate = {
                    contractIdentifier: {
                        contractId: null,
                        contractReference: null
                    },
                    contractDetails: {
                        offerId: props?.priceCourse?.offer_id,
                        contractDuration: {
                            startAt: new Date(Date.now()),
                            endAt: null
                        },
                        pricingId: props?.courseOffer?.idPricings[0],
                        paymentMethod: "CARD",
                        paymentEngine: "STRIPE"
                    },
                    contractRoles: [
                        {
                            personId: props?.connectedUser?.idPerson,
                            roleType: "STUDENT"
                        }
                    ],
                    status: [
                        {
                            statusType: "ONGOING"
                        }
                    ]
                }

                let follow = {
                    studentId: props?.connectedUser?.idPerson,
                    trainingId: courseInfo.id,
                    trainingType: courseInfo.type,
                    entityId: courseInfo.entityId,
                    entityType: courseInfo.entityType,
                    statusList: [{
                        status: "INSCRIBED"
                    }],
                }

                await props?.createFollow(follow, contractToCreate);
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
                setClicked(true);
            } else if ((courseInfo?.idRegistrationForm === 0 || courseInfo?.idRegistrationForm === null || courseInfo?.idRegistrationForm === undefined) && (price !== undefined && price[0]?.price !== 0)) {

                props?.clearHistoryArray()
                props?.redirect(courseInfo?.title, "")
                props?.redirect("Paiement", "")
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${returnStartPathUrl()}/courses/payement/${trainingType}/${courseInfo?.id}`)
                });
            }
            else if ((courseInfo?.idRegistrationForm !== 0 && courseInfo?.idRegistrationForm !== null && courseInfo?.idRegistrationForm !== undefined) ||
                ((courseInfo?.idRegistrationForm !== 0 && courseInfo?.idRegistrationForm !== null && courseInfo?.idRegistrationForm !== undefined) && (price[0].price !== 0))) {
                await props?.getApplicationFile(props?.connectedUser?.idPerson, props?.registration?.id);
                await props?.getRegistrationForm(props?.registration?.id);
                props?.redirect(t('Subscription'), `${returnStartPathUrl()}/catalog/inscription/${courseInfo?.type?.toLowerCase()}/${courseInfo?.idRegistrationForm}`)
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${returnStartPathUrl()}/catalog/inscription/${courseInfo?.type?.toLowerCase()}/${courseInfo?.idRegistrationForm}`);
                });
            }
        }

        else {
            await props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/course/${courseInfo?.id}`, tabPage: '' });
            history.push(`${returnStartPathUrl()}/auth/login`);
        }
    }

    const followTrainingCheck = async () => {
        setDisableFollowTrainingBtn(true)
        if (!!props?.followByTraining && props?.followByTraining?.length > 0) {
            history.push(`${returnStartPathUrl()}${(props?.courseInfo?.type === 'COURSE') ? '/passCourse/course' : (props?.courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.followByTraining[0]?.id}`);
        } else if (isMobile === false && isConnected === false) {
            await props?.openModal({ idModal: ModalIDS.SIGN_UP_MODAL, size: "sm", data: {} });
            await setDisableFollowTrainingBtn(false)

        } else if (isMobile === false && isConnected === true) {
            await props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/session/${props?.courseInfo?.id}`, tabPage: '' });
            await followTraining(props?.courseInfo.type)

        } else if (isMobile === true && isConnected === false) {
            await props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/session/${props?.courseInfo?.id}`, tabPage: '' });
            await history.push(`${returnStartPathUrl()}/auth/sign-up`);

        } else if (isMobile === true && isConnected === true) {
            await followTraining(props?.courseInfo.type)
        }

    }

    useEffect(() => {
        gotoTrainingToStart()
    }, [props?.follow, clicked]);

    const gotoTrainingToStart = async () => {
        if (props?.follow && props?.follow[0] && props?.follow[0]?.id && clicked) {
            if (props.courseForm?.type !== 'COURSE') {
                await props.getCurrentTraining(props?.follow[0]?.id);

            } else {
                if (!!props?.follow[0]?.id) {
                    await props.getCurrentCourse(props?.follow[0]?.id);
                }
            }
            await history.push(`${returnStartPathUrl()}${(courseInfo?.type === 'COURSE') ? '/passCourse/course' : (courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.follow[0]?.id}`);
        }
    }

    return (
        (courseInfo?.id !==0 && props?.courseTarificationForm ) ? 
        <div >
            <a href="#listFeedbacks" id="clickLink" style={{ display: 'none' }}></a>
            <DescPreviewCourse
                avisClicked={avisClicked}
                courseForm={courseInfo}
                owner={props?.courseInfo?.professor?.firstName + " " + props?.courseInfo?.professor?.lastName}
                price={price}
                type={courseInfo?.type}
                time={convertTime(props?.sessionExam?.duration)}
                resume={props?.courseInfo?.resume}
                objectives={props?.courseInfo?.objectives}
                prerequisite={props?.courseInfo?.prerequisite}
                trainingLevel={trainingLevel}
                title={props?.courseInfo?.title}
                media={props?.courseInfo?.linkImg}
                video={props?.courseInfo?.video}
            />

            {(props?.courseInfo?.skills != 0) && <SkillsSectionComponent
                skills={courseInfo?.skills}
                objectives={props?.courseInfo?.objectives}
                prerequisite={props?.courseInfo?.prerequisite}
            />}
            <ContentPathSectionComponent exam={props?.sessionExam} orders={courseInfo?.subjectsBySession} time={convertTime(courseInfo?.duration)} title={courseInfo?.title} courseInfo={courseInfo} width={contentWidth} />
            <SchoolProfSectionComponent profData={courseInfo?.professor} photoProf={courseInfo?.professor?.photo} />
            <HowToWorkSectionComponent courseForm={courseInfo} price={price} courseInfo={courseInfo} show={show} />
            <div id="listFeedbacks">
                <ListFeedbackSectionComponent />
            </div>
            <div className='suivre' style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px", backgroundColor: '#fff' }}>
                <button
                    className="btn-Primary large"
                    onClick={() => followTrainingCheck()}
                    disabled={(props.connectedUser && props.connectedUser?.role === "role_director") || disableFollowTrainingBtn}
                > suivre {courseInfo?.type === "COURSE" ? "COURS" : courseInfo?.type}</button>
            </div>
        </div>
        :
        <>
            <TrainingHeaderComponentLoading/>
            <SkillsSectionComponentLoading/>
            <CourseContentLoading/>
            <SchoolProfSectionComponentLoading/>
            <HowToWorkSectionComponentLoading/>
            <ListFeedbackSectionComponentLoading/>
            <ButtonFollowLoading/>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    priceCourse: state.courseReducer.priceCourse,
    courseInfo: state.courseReducer.training,
    profData: state.authReducer.profData,
    show: state.courseReducer.show,
    sessionExam: state.courseReducer.training,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    followByTraining: state.courseReducer.followsByTraining,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    follow: state.courseReducer.follow,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    clearAllData,
    sendIpAddressCourse,
    getOfferCourse,
    getContent,
    getRegistrationForm,
    checkFollowStudentCourse,
    clearFeedbacks,
    getAllFeedbacks,
    updateShow,
    getProfDataByDirector,
    createOrUpdateOfferCourse,
    getOfferPricingCourse,
    getTrainingById,
    getFollowByFilter,
    saveReturnPath, redirect, clearHistoryArray,
    createFollow,
    refreshToken,
    getApplicationFile,
    getCurrentTraining,
    openModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSessionPageFromProf);