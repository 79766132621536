import Skeleton from '@material-ui/lab/Skeleton';

export default function TableAllCouponsLoadingComponent(){
    return (
        <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12 mt-5">
            <div className="my-3" style={{ backgroundColor: "#FBFBFD" }}>
              <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
                <table className="w-100 custom-table">
                  <thead className="mb-4">
                    <tr>
                      <th className="w-35"> <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table" /> </th>
                      <th className=""> <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table" /> </th>
                      <th className=""> <Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /> </th>
                      <th className=""> <Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /> </th>
                      <th className=""> <Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-white">
                      <td><Skeleton variant="text" animation="wave" width={'60%'} className="skeleton data-table" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                    </tr>
                      <tr className="table-white">
                      <td><Skeleton variant="text" animation="wave" width={'60%'} className="skeleton data-table" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                    </tr> 
                      <tr className="table-white">
                      <td><Skeleton variant="text" animation="wave" width={'60%'} className="skeleton data-table" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                      <td className="text-center"><Skeleton variant="text" animation="wave" width={'40%'} className="skeleton data-table m-auto" /></td>
                    </tr> 
                  </tbody>
                </table>
              </div>
            </div>
  
          </div>
        </div >
      </div>
    );



}