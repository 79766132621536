import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function TextareaCustomparagraphDiscussionElement(props: any) {
    const { t } = useTranslation();
    const [text, setText] = useState('');
    useEffect(() => {
        setText(props?.value);
    }, [props?.value]);

    useEffect(() => {
        const b: any = document.getElementById(`textArea${props?.id}`);
        if (b.scrollHeight !== 0) {
            b.style.height = "1px";
            b.style.height = (1 + b.scrollHeight) + "px";
        }
    }, [text])

    const changeInputValue = (value: string) => {
        const b: any = document.getElementById(`textArea${props?.id}`);
        if (b.scrollHeight !== 0) {
            b.style.height = "1px";
            b.style.height = (1 + b.scrollHeight) + "px";
        }
        setText(value);

        props?.onChange(value);
    }
    return (
        <>

            <textarea id={`textArea${props?.id}`} value={text} className="input-discussion" rows={1} style={{ resize: "none" }} onChange={(e) => { changeInputValue(e.target.value); }} placeholder={t('votreMessage')} disabled={props?.disabled}></textarea>
        </>
    );
}
