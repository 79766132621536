import axios from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxCouponState } from '../reducers/coupon-reducer';


export enum EReduxActionTypes {
    GET_COUPONS = 'GET_COUPONS',
    GET_COUPONS_BY_PRODUCT_AND_PROMOCODE='GET_COUPONS_BY_PRODUCT_AND_PROMOCODE',
    LIVE_COUPON='LIVE_COUPON',
    CREATE_COUPON='CREATE_COUPON',
    EMPTY_COUPON="EMPTY_COUPON",
    DELETE_COUPON_DRAFT="DELETE_COUPON_DRAFT"
}

interface IReduxBaseAction {
    type: EReduxActionTypes;
}

export interface IReduxGetCouponsAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_COUPONS;
    data: any;
}
export interface IReduxDeleteCouponDraftAction extends IReduxBaseAction {
    type: EReduxActionTypes.DELETE_COUPON_DRAFT;
}
export interface IReduxEmptyAction extends IReduxBaseAction {
    type: EReduxActionTypes.EMPTY_COUPON;
    data: any;
}
export interface IReduxGetLiveCouponAction extends IReduxBaseAction {
    type: EReduxActionTypes.LIVE_COUPON;
    data: any;
}
export interface IReduxGetCouponsByProductAndPromoCodeAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_COUPONS_BY_PRODUCT_AND_PROMOCODE;
    data: any;
}
export interface IReduxCreateCouponAction extends IReduxBaseAction {
    type: EReduxActionTypes.CREATE_COUPON;
    data: any;
}


export function getCoupons(entityId:any,entityType:any): ThunkAction<Promise<IReduxGetCouponsAction>, IReduxCouponState, undefined, IReduxGetCouponsAction> {
    return async (dispatch: ThunkDispatch<IReduxCouponState, undefined, IReduxGetCouponsAction>) => {
        let result = await axios.get(`${process.env.REACT_APP_BASE_URL22}coupons/`+entityId+`/`+ entityType+`/client`).then((responseCoupon) => { return responseCoupon.data });       
        return dispatch({
            type: EReduxActionTypes.GET_COUPONS,
            data: result,
        });
    };
}

export function getCouponsByProductAndPromoCode(product:any,promoCode:any): ThunkAction<Promise<IReduxGetCouponsByProductAndPromoCodeAction>, IReduxCouponState, undefined, IReduxGetCouponsByProductAndPromoCodeAction> {
    return async (dispatch: ThunkDispatch<IReduxCouponState, undefined, IReduxGetCouponsByProductAndPromoCodeAction>) => {
        const couponObject ={
            "product":"KOORS",
            "promoCode":promoCode
          }
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL22}coupons/couponsSearch` , couponObject).then((responseCoupon) => { return responseCoupon.data });
        return dispatch({
            type: EReduxActionTypes.GET_COUPONS_BY_PRODUCT_AND_PROMOCODE,
            data: result,
        });
    };
}

export function deleteCouponDraft(couponReference:any): ThunkAction<Promise<IReduxDeleteCouponDraftAction>, IReduxCouponState, undefined, IReduxDeleteCouponDraftAction> {
    return async (dispatch: ThunkDispatch<IReduxCouponState, undefined, IReduxDeleteCouponDraftAction>) => {
        let result = await axios.delete(`${process.env.REACT_APP_BASE_URL22}coupons/delete/`+couponReference).then((responseCoupon) => { return responseCoupon.data });
        return dispatch({
            type: EReduxActionTypes.DELETE_COUPON_DRAFT,
        });
    };
}
export function getLiveCoupon(coupon:any): ThunkAction<Promise<IReduxGetLiveCouponAction>, IReduxCouponState, undefined, IReduxGetLiveCouponAction> {
    return async (dispatch: ThunkDispatch<IReduxCouponState, undefined, IReduxGetLiveCouponAction>) => {
      return dispatch({
            type: EReduxActionTypes.LIVE_COUPON,
            data: coupon,
        });
    };
}
export function emptyCoupon(coupon:any): ThunkAction<Promise<IReduxEmptyAction>, IReduxCouponState, undefined, IReduxEmptyAction> {
    return async (dispatch: ThunkDispatch<IReduxCouponState, undefined, IReduxEmptyAction>) => {
      return dispatch({
            type: EReduxActionTypes.EMPTY_COUPON,
            data: coupon,
        });
    };
}

export function createCoupon(coupon:any): ThunkAction<Promise<IReduxCreateCouponAction>, IReduxCouponState, undefined, IReduxCreateCouponAction> {
    return async (dispatch: ThunkDispatch<IReduxCouponState, undefined, IReduxCreateCouponAction>) => {
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL22}coupons/create`,coupon).then(async(responseCoupon) => { return responseCoupon.data });
        return dispatch({
            type: EReduxActionTypes.CREATE_COUPON,
            data: result,
        });
    };
}