import { ComponentProps, FC, useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal} from '../../../global-state/actions/modal-actions';
import { reinstateStudent } from '../../../global-state/actions/profile-actions';
import { ModalIDS } from '../modal-ids';
import { getActiveStudents } from '../../../global-state/actions/finance-actions';

const ReintegrateStudentModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [comment, setComment]= useState("")
    const [studentData, setStudentData] = useState(props?.content?.data?.student);
    useEffect(()=>{
        setStudentData(props?.content?.data?.student)
    },[props?.content])

    const  reinstateStudentButton = async () => {
        const object = { idStudent: studentData.id, idSchool: props?.connectionUser?.idSchool, comment: comment };
       await props?.reinstateStudent(object).then((resp:any)=>props?.getActiveStudents(props?.schoolInfo?.id));
        const studentReinstated = { ...studentData };
        studentReinstated.relation.endDate = null;
        setStudentData(studentReinstated)
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToStudentList'), path: "/students/all",lines: [t('successReinstateStudent')]});
    }
    return (
        <>
       
                    <div className="modal-header p-3 pb-0" style={{ border: "none" }}>
                        <div className="ms-auto">
                            <button type="button" className="btn-QuickActions" onClick={()=>{props?.closeModal()}}>
                                <span className="material-icons-outlined" >close</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                        <div className="row body-modal-publish">
                            <div className="col-md-9 p-0 center " style={{ margin: 'auto' }}>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <span className="H3-Headline"> {t('ReintegrateProf')}</span>
                                        <br />
                                        <span className="H4-Subtitle bold neutral-3"> {studentData?.firstName + " " + studentData?.lastName} </span>

                                    </div>
                                    <div className="col-md-12 mt-4 mb-3 ">
                                        <p className="body-sm">{t('ReintegrateStudentText')}</p>
                                        <p className="body-sm">{t('ReintegrateStudentText_2')}</p>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                    <label className="form-title">{t('commentStudentSuspend')}</label>
                                    </div>
                                    <div className="col-md-12 mt-2 ">
                                    <textarea style={{  maxHeight:'75px',fontSize: "16px" }} rows={1} value={comment} onChange={(e) => { setComment( e.target.value) }} placeholder={t('commentSuspendInputProf')}  className="textaria-textField"  ></textarea>
                                          

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-0" style={{ paddingTop: 0 ,border: "none"}}>
                        <div className="row mt-3 mb-5 w-100">
                            <div className='col-md-9 m-auto p-0 text-center justify-content-end buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                                <button type="button" className="btn-Secondary large" onClick={() => { setComment( '') ; props?.closeModal()}} style={{ textTransform: 'uppercase', marginRight: '2%' }} >{t('annuler')}</button>
                                <button type="button" className="btn-Primary large success" onClick={() => { reinstateStudentButton(); }} style={{ textTransform: 'uppercase' }}  disabled={comment === ''}>{t('AcceptProf')}</button>
                            </div>
                        </div>
                    </div>
                
    </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    schoolInfo:state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal,
    closeModal,
      reinstateStudent,
      getActiveStudents
     
    },
    dispatch
  );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReintegrateStudentModalComponent);