import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import { useParams } from 'react-router-dom';
import { getExamToPass } from '../../global-state/actions/exam-actions';
import HeaderPreviewSondageComponent from '../../components/sondage/header-preview-sondage-component';
import SondageDirectorPage from './sondage-director-page';
import BredCrumbPassSondage from './BredCrumbPassSondage';
interface Props {
    t: any

}
interface Params {
    id: any
}
const PreviewSondagePage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const { id } = useParams<Params>();
    const [currentExam, setCurrentExam] = useState<any>()
    useEffect(() => {
        setCurrentExam(props?.examToPass)

    }, [props?.examToPass])

    return (<>

        <div >
        <BredCrumbPassSondage title={props?.examToPass?.title}/>
        <HeaderPreviewSondageComponent/>
        <SondageDirectorPage />
        </div>
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    school: state.schoolInfoReducer.schoolInfo,
    examToPass: state.examReducer.examToPass,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamToPass
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,

)(PreviewSondagePage);
