import { useEffect, useState } from "react";

interface Props {
  onChange: any;
  placeholder: string;
  value: string;
}
export default function InputSearch(props: Props) {
    const [value, setValue] = useState('');
    useEffect(()=> {

      setValue(props?.value);
    }, [props?.value]);
    const handleChange = (event: any) => {
        setValue(event.target.value);
        props?.onChange(event.target.value);
      };
    return (
        <div className="input-form-control-community">
            <input dir="ltr"type="text" className="form-control input text-default" value={value} onChange={(e) => handleChange(e)} placeholder={props?.placeholder} />
            <span className="material-icons-outlined">search</span>
        </div>
    )
}