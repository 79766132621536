import { ComponentProps, FC, useEffect,useMemo,useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import {getSubjectCommunity, updatelistDiscussion ,createPost, setOpenChat, createSeens} from '../../global-state/actions/chat-actions';
import { connectSocketChat ,initSocket} from '../../global-state/actions/socket-actions';
import { getFollow } from '../../global-state/actions/course-actions';
import HeaderRegistrationPreviewStudentPageComponent from '../../components/headers-page/header-registration-preview-student-page-component';
import AdmissionPreviewStudentComponent from '../../components/admission/admission-preview-student-component';
import AdmissionDiscussionSidbarElement from '../../elements/sidebar/admission-discussion-sidbar-element';
import { useLocation, useParams } from "react-router-dom";
import { returnDynamicValueFromUrl } from "../../helpers/domainCheck";
interface Params {
    idFollow: string;
}
const RegistrationPreviewStudentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

  
    const [showChat, setShowChat] = useState(false)
    const location = useLocation();
    const [listPosts, setListPosts] = useState<any>()
    const [page] = useState(0)
    const { idFollow } = useParams<Params>();
    const training = useMemo<any>(() => {
        return  props?.mycourses?.find((obj: any) => { return obj.followUp.id === Number(returnDynamicValueFromUrl(3)) })
    }, [props?.myCourses])
    useEffect(() => {
        if(props?.socket=== undefined) {
            props?.connectSocketChat()
        } else {
            if(!props?.socketInit) {
                props?.initSocket();

                props?.socket?.on("newMessageSended", (data: any) => {
                    
                    
                if(Number(data?.idAuthor) !== props?.connectedUser?.idPerson && returnDynamicValueFromUrl(3) !== undefined) {
                    props?.updatelistDiscussion(page, data, props?.connectedUser?.idSchool, { idSubject: returnDynamicValueFromUrl(3), category: "CHAT", type: "ADMISSION" });
                }
            }); 
        }
        }

    }, [props?.socket])
    useEffect(() => {
        
        let object = {
            subject: [returnDynamicValueFromUrl(3) + " CHAT ADMISSION"],
            trie: 'recent',
            searchFilter: "",
            page: 0,
            chat: true,
            event: "ADMISSION_CHAT",
           
           

        }
        if(typeof object!= undefined && object?.subject?.length!=0){
        props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
        }
        props?.getFollow(Number(idFollow))
        return () => {
            setShowChat(false);
            props?.setOpenChat(false)
        };
    }, [])
   
    useEffect(() => {
        setListPosts(props?.listPosts)
       if(showChat===true){
        let listObjects: any[];
        let responsesIds = props?.listPosts?.filter((obj: any) => { return ((obj?.idAuthor?.id !== props?.connectedUser?.idPerson) && ((obj?.seens?.map((seen: any) => { return seen?.idPerson }))?.includes(props?.connectedUser?.idPerson))) || (obj?.idAuthor?.id !== props?.connectedUser?.idPerson && obj?.seens?.length === 0) })?.map((obj: any) => { return obj?.id })
        
        listObjects = responsesIds?.map((obj: any) => {
            return  { idPerson: props?.connectedUser?.idPerson, isSeen: true, postId: obj }
            
        });
       props?.createSeens(listObjects, props?.what);}
    }, [props?.listPosts])

    const createPostFN=(value:string)=>{
        let object = {
            subjectId: (listPosts.length === 0) ? null : listPosts[0].subjectId,
            idAuthor: props?.connectedUser?.idPerson,
            title: null,
            type: "POST",
            event: "ADMISSION_CHAT",
            comment: {
              description:value,
              type: "TEXT"
            },
            subject:[returnDynamicValueFromUrl(3)+" "+'CHAT'+" "+'ADMISSION']
          
          }
          props?.createPost(object, { idSubject: returnDynamicValueFromUrl(3), category: 'CHAT', type: 'ADMISSION' }, props?.connectedUser?.idSchool);
         
    }
    const openChat = (value: boolean) => {
        setShowChat(!showChat);
    }
    return (
        <>
        <div className="d-flex align-items-top">
            <div className="w-100">
             <div style={{ backgroundColor: '#FFFFFF' }}>
                <HeaderRegistrationPreviewStudentPageComponent isOpen={openChat}/>
             </div>
             <AdmissionPreviewStudentComponent/>
             </div>
             {showChat && <div id="chatSidebar">
                <AdmissionDiscussionSidbarElement   listPosts={listPosts} follow={props?.follow} createPost={createPostFN} openChat={showChat}  />
                </div>}
             </div>
             
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    socket: state.socketReducer.socketChat,
    listPosts: state.chatReducer.listPosts,
    follow: state.courseReducer.follow,
    socketInit: state.socketReducer.socketInit,
    myCourses: state.courseReducer.myCourses,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
     getSubjectCommunity, connectSocketChat, updatelistDiscussion, getFollow, createPost, initSocket, setOpenChat,createSeens
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPreviewStudentPage);