import { useState, ComponentProps, FC, useEffect} from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { activateModal, closeModal } from '../../../global-state/actions/modal-actions';
import { affectStudentToGroup, disaffectStudentFromGroup, getGroupBySchool, searchGroupByLabel } from '../../../services/groupe.student.service';

import TableScrollElement from '../../../elements/table/table-scroll-element';

const AddAStudentToGroupsModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [searchFilter, setSearchFilter] = useState("");
    const [data, setData] = useState<any>(null);
    const [listGroup, setListGroup] = useState<any[]>([]);
    const [student] = useState(props?.student);

    const [groupForStudent, setGroupForStudent] = useState<any[]>(props.groupForStudent)

   
    const tabHeaders = [
        { title: t('groupes'), sorted: true, classNames: "w-30", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Etudiants'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Action'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    ]
    useEffect(()=>{
        getGroupBySchool(props?.schoolInfo?.id).then((response)=>{
            setListGroup(response)

        })
    }, [])



    useEffect(() => {
        if(listGroup){
        let list = {
            headers: tabHeaders,
            body: listGroup?.map((group: any) => {
                return {
                    row: {
                        group: group,
                        classNames: "table-white",
                        columns: [
                            { content: group?.label, classNames: "w-50", actionName: '' },
                            { content: <span style={{ textTransform: "capitalize" }}> {group?.personList?.length + " " + t('Etudiants').toLowerCase()}</span>, classNames: "", actionName: "" },
                            {
                                content: (checkStudent(group.id) === false) ? <button  type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> add </span>
                                    <div className="m-auto ps-2">{t('add')}</div></button> :
                                    <button type="button" className="btn-Secondary medium icon-left adding-done">
                                        <span className="material-icons-outlined"> check </span>
                                        <div className="m-auto ps-2">{t('add')}</div></button>
                                , classNames: "text-end with-icon p-0 m-0 mt-4 w-78 d-flex justify-content-end aligns-items-center me-4 ", actionName: (checkStudent(group.id) === false) ? 'addstudentToGroup' : 'disaffectStudent'
                            },
                        ]
                    }
                }
            })
        }
        setData(list)

    }
    }, [listGroup,groupForStudent])

    const checkStudent=(idGroup: any)=>{
        let indexgroup= groupForStudent.findIndex((obj:any)=> {return obj?.id===idGroup})
        if(indexgroup!==-1){return true}
        else return false 
        
        
    }
    const addSudentToGroup=async (idGroup:any)=>{
        const studentAlreadyInGroup = groupForStudent.some((group) => group.id === idGroup);
        let objects={
            person:{...student, type:'NP'},
            idGroup: idGroup,
            idSchool:props?.connectionUser.idSchool,
            subGroup:false,
            parentGroup:false,
            idGroupParent:idGroup,

         }
         await affectStudentToGroup(objects).then((response:any)=>{
            setGroupForStudent([...groupForStudent, response]);
          
        })

    }

    const disaffectstudentFromGroup =async (idGroup: any)=>{
        let objects={
            person:{...student, type:'NP'},
            idGroup: idGroup,
            idSchool:props?.connectionUser.idSchool,
            subGroup:false,
            parentGroup:false,
            idGroupParent:idGroup,
        }
        await disaffectStudentFromGroup(objects).then((response:any)=>{
            const updatedGroupForStudent = groupForStudent.filter((group: any) => response.id !== group.id);
            setGroupForStudent(updatedGroupForStudent);
          
        })
    }
   
       
    
    const doAction = (value: any) => {
        if (value.action === 'addstudentToGroup') {
            addSudentToGroup(value.row.group.id);
            props?.activateModal();
        }else if(value.action === 'disaffectStudent'){
            disaffectstudentFromGroup(value.row.group.id);
            props?.activateModal();
        }
    }
    const serachGroup=(value:string)=>{
        if(value.length>3 ||value.length===0 ){
            searchGroupByLabel(value, props?.connectionUser.idSchool).then((response:any)=>{
                setListGroup(response);
              
            })
        }
    }
    return (
        <>
            <div style={{ height: 696, background: "#F8F8FA" }}>
                <div className="modal-header mb-2" style={{paddingBottom: 0 , borderBottom: "none"}}>
                    <div className="mx-3 px-2  d-flex align-items-center justify-content-between  w-100 ">
                        <h3 className="H3-Headline">{t('addToGroup')}</h3>
                    <div className="d-flex align-items-center justify-content-end py-2 ">
                        <div className="input-form-control me-2" style={{ maxWidth: '220px' }}>
                            <input dir="ltr"type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={searchFilter} onChange={(e: any) => {serachGroup(e.target.value); setSearchFilter(e.target.value) }} />
                            <span className="material-icons-outlined">search</span>
                        </div>
                        <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>
                    </div>
                </div>

                <div className="modal-body  mt-3" style={{ paddingTop: 0, overflow:'hidden'}}>
                    <TableScrollElement data={data} doAction={(value: any) => { doAction(value)}} />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo, 
    groupForStudent: state.modalReducer?.modalContent?.data?.groupForStudent,
    student: state.detailsProfileReducer.actualStudent,
    connectionUser: state.authReducer.connectedUser,
   
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {closeModal,activateModal},
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(AddAStudentToGroupsModalComponent);