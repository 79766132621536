import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const CourseContentLoading: FC<ComponentProps<any>> = () => {

  return (
    <>
    <section className="mb-5 pb-3" style={{ maxWidth: 1200, margin: 'auto' }}>

      <div className="d-flex flex-column justify-content-center w-100 px-2">
        <h3 className="d-flex justify-content-center mt-5">
        <Skeleton variant="rect" animation="wave" width={'25%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
        </h3>
        <div className={`d-flex align-items-center bg-with-border white pointer py-28 ps-40 pe-4 mt-5 bg-with-border white opened`} >
          <div className="w-100 d-flex align-items-center">
            <div style={{width:'10%'}}>
                <Skeleton variant="rect" animation="wave" width={'25%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />

            </div>
            <div style={{width:'85%'}} className='d-flex justify-content-between align-items-center' >
                <div style={{width:'80%'}}>
                    <Skeleton variant="rect" animation="wave" width={'40%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    <div className=" d-flex align-items-center mt-2">
                        <div style={{marginRight:'8px'}}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                                <line x1="5.38184" y1="9.77632" x2="10.6187" y2="9.77632" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                                <line x1="10.6182" y1="6.22368" x2="5.38132" y2="6.22368" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </div>
                        <Skeleton variant="rect" animation="wave" width={'10%'} height={20} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                </div>
                <div style={{width:'12%'}}>
                    <div className="w-100 d-flex align-items-center">
                        <div className="material-icons-outlined neutral-3 px-1">timer</div>
                        <div className="w-100">
                            <Skeleton variant="rect" animation="wave" width={'50%'} height={28} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                        </div>
                    </div>
                </div>

            </div>
            <div style={{width:'5%'}}>
                <button type="button" className="btn-QuickActions small mt-1">
                    
                    <span className="material-icons-outlined">
                        expand_more</span> 
                </button> 
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default CourseContentLoading;