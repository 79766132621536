import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { updatePath } from '../../global-state/actions/breadcrumb-actions';
import { createChapter, updateChapter, getChapter, clearUploadChapterLoader, uploadChapterLoader } from '../../global-state/actions/course-actions';
import InputUploadFile from "../../elements/inputs/input-upload-file";
import CardFileFormComponent from "../files/card-file-form-component";
import { debounce } from 'lodash';
import { clearFormVideo } from '../../global-state/actions/upload-video-course-actions';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import ReactQuill from 'react-quill';
import VideoUploader from "../card-uploads/video-uploader";


const ContentChapterFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState<boolean>(true);
    const [errorFileSize, setErrorFileSize] = useState("")
    const [chapterForm, setChapterForm] = useState<any>({
        id: null,
        status: "ACTIVE",
        title: t('chapterTitleBegin'),
        duration: 0,
        text: "",
        files: [],
        video: ''
    });

    useEffect(() => {
        if (props?.title !== '' && props?.title !== chapterForm.title && ((props?.chapterForm && props?.chapterForm.id === chapterForm.id) || !props?.chapterForm)) {
            const copyChapter = { ...chapterForm };
            copyChapter.title = props?.title;
            if (props?.chapterForm?.duration) {
                copyChapter.duration = props?.chapterForm?.duration
            } else {
                copyChapter.duration = 0
            }
            setChapterForm(copyChapter);
            debouncedChangeHandler(copyChapter);
        }
    }, [props?.title]);


    function deleteVideo() {
        const copyChapter = { ...chapterForm };
        copyChapter.video = '';
        setChapterForm(copyChapter);
        debouncedChangeHandler(copyChapter);
    }

    function uploadVideo(link: any) {
        const copyChapter = { ...chapterForm };
        copyChapter.video = link;
        setChapterForm(copyChapter);
        debouncedChangeHandler(copyChapter);
    }

    const addFile = (file: any) => {
        if (file[0].size > 10485760) {
            setErrorFileSize("La taille du fichier importé ne doit pas dépasser 10MB. Veuillez sélectionner un autre fichier")
            return;
        }
        props?.uploadChapterLoader(file[0].name + file[0].size)
        setErrorFileSize("")
        const copyChapter = { ...chapterForm };
        copyChapter.files = copyChapter.files.concat(file);
        setChapterForm(copyChapter);
        debouncedChangeHandler(copyChapter);
    }

    const deleteFile = (index: number) => {
        const copyChapter = { ...chapterForm };
        copyChapter.files.splice(index, 1);
        setChapterForm(copyChapter);
        debouncedChangeHandler(copyChapter);
    }

    const saveDataChapter = (chapter: any) => {
        if (chapter.id != null) {
            props?.updateChapter(chapter, props?.courseForm.id).then(() => {
                props?.clearUploadChapterLoader(chapter?.files[0]?.name + chapter?.files[0]?.size)
            })
        } else {
            chapter['index'] = props?.indexContent;
            props?.createChapter(chapter, props?.courseForm.id).then(() => {
                props?.clearUploadChapterLoader(chapter?.files[0]?.name + chapter?.files[0]?.size)
            })
        }
    }

    function onUrlAdd(e: any) {
        e.stopPropagation()
        props?.openModal({ idModal: ModalIDS.ADD_VIDEO_URL, size: 'md', data: { chapterForm } })
    }

    const debouncedChangeHandler = useCallback(debounce(saveDataChapter, 900), []);

    useEffect(() => {
        if (refresh) {
            if (props?.chapterForm) {
                const copyChapter = { ...chapterForm };
                copyChapter.id = props?.chapterForm.id;

                setChapterForm(copyChapter)
                setRefresh(false);
            }
        } else if ((props?.chapterForm && props?.chapterForm.id !== chapterForm.id)) {
            setChapterForm(props?.chapterForm);
        } else if (props?.chapterForm !== chapterForm) {
            const copyChapter = { ...props?.chapterForm };
            setChapterForm(copyChapter)
        }
    }, [props?.chapterForm]);

    useEffect(() => {
        let compteurChapter = 1;
        let compteurTest = 1;
        const liste = props?.courseForm?.orders?.
            concat(props?.listTest?.filter((test: any) => test.qnaType === "KNOWLEDGETEST"))?.
            sort((a: any, b: any) => {
                if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 }
            })?.map((obj: any, index: number) => {
                if (obj?.chapter) {
                    obj['compteur'] = compteurChapter;
                    compteurChapter++;
                } else {
                    obj['compteur'] = compteurTest;
                    compteurTest++;
                }
                return { label: '', value: JSON.stringify(obj) };
            });
        if (props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM").length > 0) {
            liste.push(props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM")[0]);
        }
        if (props?.chapterForm) {
            setChapterForm(props?.chapterForm)
        }
        const clearData = async () => {
            await props?.clearFormVideo()
        }
        clearData();
        return () => {
            clearData();
        }
    }, []);

    const changeText = (text: string) => {
        if (text?.length != 0 && chapterForm?.id != null) {
            const copyChapter = { ...chapterForm };
            copyChapter.text = text;
            setChapterForm(copyChapter);
            debouncedChangeHandler(copyChapter);
        }

    }
    return (
        <>
            <style>
                {`
                .ql-container.ql-snow {
                    border:none;
                    background-color:white;
                    padding-top:8px;
                }
                .ql-editor {
                    min-height:30px !important;
                    padding:0;
                    font-size:16px;
                    font-family: "Public", sans-serif;
                }

                .ql-editor.ql-blank::before {
                    color: #cacbce;
                    font-style: normal; 
                    left: 0px;
                    right: 8px;
                    
                }
            `}
            </style>
            <div className="mt-4 ">
                <section className="row py-4" style={{ maxWidth: "1200px", margin: 'auto' }}>
                    <h3 className="H3-Headline mb-3" >{t('chapterPresentation')}</h3>
                    <div className="d-flex justify-content-center w-100 bg-with-border" style={{ borderRadius: "3px" }}>
                        <div style={{ width: 'calc(100% - 408px)', marginTop: 56, marginBottom: 56 }}>
                            <div className='' style={{ width: "100%" }}>
                                <div className="d-flex align-items-start mb-2">
                                    <label className="body-md bold black-800">{t('resume')}</label>
                                    <span className="material-icons-outlined neutral-3 ms-2" style={{ cursor: 'pointer' }}>info</span>
                                </div>
                                <ReactQuill modules={{ "toolbar": false }} placeholder={t('textAreaPlaceholder')} value={chapterForm?.text} onChange={(e: string) => { changeText(e) }} />
                            </div>
                        </div>
                    </div>
                </section>


                
                <section style={{ backgroundColor: '#FFFFFF' }} className=" py-4 mt-3 ">
                    <div className="row" style={{ maxWidth: "1200px", margin: 'auto' }}>
                        <VideoUploader
                            description={t('uploadVideoChpter_1')}
                            buttonText2={t('addVideoToIntegrate')}
                            buttonText1={t('uploadVideo_2')}
                            t={t}
                            deleteFile={deleteVideo}
                            uploadVideo={uploadVideo}
                            file={chapterForm?.video}
                            uploadSubtitles={true}
                            onUrlAdd={onUrlAdd}
                        />
                    </div>
                </section>
                <section className="row py-4" style={{ maxWidth: "1200px", margin: 'auto' }}>
                    <h3 className="H3-Headline mb-3" >{t('uploadFiles')}</h3>
                    <div className="mb-3">
                        {chapterForm?.files?.map((file: any, i: number) => <div ><CardFileFormComponent onDelete={() => { deleteFile(i) }} file={file.link} filename={file.name} filesize={file.size} /></div>)}
                    </div>
                    <InputUploadFile addFile={(e: any) => { addFile(e) }} />
                    {
                        errorFileSize.length > 0 && <div style={{ color: "#E06A6A" }} className="mt-2">
                            {errorFileSize}
                        </div>
                    }
                </section>
            </div>
        </>

    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ uploadChapterLoader, clearUploadChapterLoader, updatePath, getChapter, createChapter, updateChapter, clearFormVideo, openModal }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseForm,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    indexChapter: state.courseReducer.indexChapter,
    listTest: state.courseReducer.listTest,
    indexContent: state.courseReducer.indexContent,
    title: ownProps?.title
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentChapterFormComponent)