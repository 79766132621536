import { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import TrainingPaymentComponent from '../../components/finance/training-payment-component';
interface Props {
    t: any
    filter: any
}
const TrainingPaymentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {

    const user = useMemo(() => { return props?.connectedUser }, [props?.connectedUser]);

    return (<>
        {user?.role.includes("role_student") &&
            <>
                <TrainingPaymentComponent />
            </>

        }

    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingPaymentPage);
