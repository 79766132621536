import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal} from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import {createPost} from "../../../global-state/actions/chat-actions";
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import axios from '../../../helpers/interceptors';
import { getCards } from '../../../global-state/actions/auth-actions';
const DeleteCardModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();

    const deleteCard=async ()=>{
        await axios.post(process.env.REACT_APP_BASE_URL15 + "strip/delete-card/"+props?.content.cusId+"/"+props?.content.cardId, ).then((resp:any)=>{
            props?.getCards(props?.content.cusId)
            props?.closeModal()
        })
    }

    return(
        <>
        
                <div className="modal-header" style={{paddingBottom: 0 , borderBottom: "none"}}>
                    <div className="w-100 d-flex align-items-center justify-content-end p-3">
                         <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>
                </div>

                    <div  className="modal-body mb-3" style={{ paddingTop: 0, zIndex:1000, overflow:'hidden' }}>
                        <div className="row">
                            <div className="col-md-10 center" style={{ margin: 'auto' }}>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                    <span className="H3-Headline">{t('deletePaymentMethod')}</span>
                                    </div>
                                    <div className="col-md-12 mb-1 mt-2">
                                    <span className="body-sm">{t('deletePaymentMethodConfirmation')}</span>
                                    </div>
                                    <div className="col-md-12  mb-2">
                                      <span className="body-sm bold">***** ***** ***** ***** ({props?.content?.last4})</span>
                                      
                                  </div>
                                  
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{paddingBottom: 0 , borderTop: "none"}}>
                        <div className="row mt-3 mb-5">
                            <div className='col-md-9 d-flex  justify-content-end' >
                                <button className="btn-Secondary large me-3" onClick={() => {props?.closeModal()}}>{t('annuler')}</button>
                                <button className="btn-Primary danger large  ml-2" onClick={() => { deleteCard() }} >{t('delete')}</button>
                            </div>
                        </div>
                    </div>



    </>
    
    )
}
const mapStateToProps = (state: AppState) => ({
    connectionUser: state.authReducer.connectedUser,
    listPostsChapter: state.chatReducer.listPosts,
    content: state.modalReducer.modalContent,
    
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      createPost,
      getCards
    },
    dispatch
  );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteCardModalComponent);