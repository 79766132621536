import { FC } from "react";
import PrivateRoute from "../../navigations/PrivateRoute";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import FooterComponent from "../../components/footer-component";
import TrainingDetailsContainer from "./course-details/training-details-container";
import AllCoursesPage from "./all-courses-page";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import ExamChapterPreviewPage from "./course-details/exam-chapter-preview-page";
import PathDetailsContainer from "./path-details/path-details-container";
import SessionDetailsContainer from "./session-details/session-details-container";
import CustomSwitch from "../../helpers/custom-switch";
import assistantAllCoursesPage from "./assistant-all-courses-page";

const CoursesContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height">
      <CustomSwitch>
        <PrivateRoute history={props?.history} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/course/:id`} component={TrainingDetailsContainer} />
        <PrivateRoute history={props?.history} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/path/:id`} component={PathDetailsContainer} />
        <PrivateRoute history={props?.history} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/session/:id`} component={SessionDetailsContainer} />
        <RouteWithBreadCrumb exact={true} path={`${props.match.path}/chapter-test-preview`} component={ExamChapterPreviewPage} />
        <RouteWithNavbar history={props?.history} roles={['role_director', "role_adminstrator"]} exact={true} path={`${props.match.path}`} component={AllCoursesPage} />
        <RouteWithNavbar history={props?.history} roles={[ "role_adminstrator"]} exact={true} path={`${props.match.path}/assistant`} component={assistantAllCoursesPage} />

      </CustomSwitch>

    </div>
    <FooterComponent />
  </>
  )
}
export default CoursesContainer;