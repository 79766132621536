import { ComponentProps, FC} from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import RecoverPassword from "../../components/auth-components/password-management-components/recover-password-component";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";



const RecoverPasswordPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    return (<>
                <RecoverPassword t={t} />
            </>
        
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({

    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearAllData }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordPage);