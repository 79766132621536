import { ComponentProps, FC, useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';


const VideoImageSectionComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
  const [videoHeight,setVideoHeight] = useState<any>('')
  const { t } = useTranslation();
  useEffect(()=>{
    if(props?.video && (props?.video.includes('dai.ly')|| props?.video.includes('dailymotion.com'))){
        setVideoHeight('500px')
    }
    else{
        setVideoHeight('100%')
    }
},[props?.video])
    return <>
    <section >
      {(!!props?.video || !!props?.image) ?
          <div className='d-flex align-items-center justify-content-center' style={{maxWidth:'1200px', minHeight:'500px', backgroundColor: "#272727", margin: 'auto'}}>
          {!!props?.video ?

            <ReactPlayer 
                                controls={true}
                                config={{
                                    vimeo: {
                                        playerOptions: {
                                            responsive: true,
                              
                                            
                                        },
                                    },
                                    youtube: {
                                        embedOptions: {
                                            height: '500px',
                                        }
                                    },
                                    dailymotion:{
                                       
                                    }
                                }}
                                width='80%'
                                height={videoHeight}
                                stopOnUnmount={false}
                                url={props?.video}

                            />

            :
            <img style={{ width: "100%", height: "500px", objectFit: "cover", padding: 0, margin: 0 }} src={(props?.image !== '' && props?.image !== null) ? props?.image : `${process.env.PUBLIC_URL}/imgs/image.png`} />
          } </div> :
          
          <div className="d-flex justify-content-center align-items-center" style={{ margin: 0, padding: 0, height: 500, backgroundColor: "#272727", boxShadow: "inset 0px -1px 0px #DFDFDF" }}>
            

            <div className=" col-md-12 align-items-center text-center"><span className="H2-Headline" style={{color: "white"}}>{t('testConn')}</span></div>
             </div>}
  
    </section>
    </>
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    video: ownProps?.video,
    image: ownProps?.image
});

export default connect(mapStateToProps, null)(VideoImageSectionComponent);