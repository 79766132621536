import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { getSubscriptions, getOffer, getContract } from '../../global-state/actions/app-container-actions';
import axiosInstance from "../../helpers/interceptors";


const OfferListPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    const [contract, setContract] = useState<any>()
    const [offerFree, setOfferFree] = useState<any>()
    const [loadingFree, setLoadingFree] = useState<any>(false)


    const getContract = async () => {
        let baseURL = process.env.REACT_APP_BASE_URL23;
        let response = await axiosInstance.post(baseURL + "contract", {
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL",
            paymentEngine: null,
            paymentMethod: null
        })
        setContract(response.data);
    }

    const getOfferFree = async () => {
        let baseURL = process.env.REACT_APP_BASE_URL11;
        let offer: any = await axiosInstance.get(baseURL + "offers/getOffer/" + process.env.REACT_APP_OFFER_ID_FREE).then(response => { return response.data; }).catch(err => { return console.error(err) });
        setOfferFree(offer)
    }



    useEffect(() => {
        getContract();
        getOfferFree();
    }, [])

    const submitFree = async () => {
        setLoadingFree(true)
        let newContract = {
            ...contract[0],
            contractDetails: {
                offerId: offerFree?.id,
                pricingId: offerFree?.pricings[0]?.id,
                contractDuration: {
                    startAt: new Date(Date.now()),
                    endAt: null,
                },
                paymentMethod: "CARD",
                paymentEngine: "STRIPE"
            },
            status: [{
                statusType: "ONGOING"
            }],
            contractRoles: []
        }
        await axiosInstance.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract", newContract).then(() => {
            props?.getContract(props?.schoolInfo?.id, "SCHOOL").then(() => {
                props?.getSubscriptions(props?.schoolInfo?.id).then((response: any) => {
                    props?.getOffer(process.env.REACT_APP_OFFER_ID_FREE).then(() => {
                        history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined });
                        setLoadingFree(false)
                    })
                })
            })
        })

    }

    const submitPayed = async (label:string) => {
        if (label === process.env.REACT_APP_OFFER_LABEL_PRO)
        {
            props?.getOffer(process.env.REACT_APP_OFFER_ID_PRO).then(() => {
                history.push({ pathname: `${returnStartPathUrl()}/offers/payment`, state: undefined });
            })
        }else if (label === process.env.REACT_APP_OFFER_LABEL_BUSINESS)
        {
            props?.getOffer(process.env.REACT_APP_OFFER_ID_BUSINESS).then(() => {
                history.push({ pathname: `${returnStartPathUrl()}/offers/payment`, state: undefined });
            })
        }
    }

    return (
        <div className="page-wrapper-height mx-auto" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "auto", backgroundColor: "white" }}>

            <div >

                <div className='d-flex flex-column align-items-center justify-content-center' style={{ paddingTop: "128px" }}>
                    <span className='H3-HeadlineOffer '>Finalisez la création de votre école en choisissant votre abonnement</span>
                    <span className='body-xl neutral-3' style={{ marginTop: "8px" }}>Choisissez{` l’abonnement `}qui correspond à votre nouvelle école</span>
                </div>
                <div className='d-flex flex-row align-item-center' style={{ justifyContent: "space-between", flexWrap: "wrap", paddingTop: "40px", paddingRight: "112px", paddingLeft: "112px" }}>

                    <div className="bg-with-border offer-card mb-3 mt-1 me-4" style={{ backgroundColor: "#FFFFFF" }}>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column align-items-start mb-2">
                                <div className="d-flex flex-column align-items-start mb-2">
                                    <span className="H3-Headline black-800">Gratuit</span>

                                    <span className="mt-1 body-xl neutral-2">Les outils essentiels pour démarrer, sans frais cachés</span>
                                </div>
                                <span className="black-800 offer-price2">0€</span>
                                <span className="mt-2 body-xl neutral-2">pour toujours </span>
                                <div style={{ paddingTop: "46px" }}>
                                    {!loadingFree ? <button type="button" className="btn-Primary large" style={{ width: 304 }} onClick={submitFree} >
                                        Démarrez gratuitement
                                    </button> :
                                        <button type="button" className="btn-Primary loaddata large " style={{ width: 304 }} >
                                            <div className="threedots-large-Animation">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </button>}
                                </div>
                            </div>

                            <div className="w-100 d-flex my-4">
                                <hr className="hr-payment" style={{ width: '100%', margin: 0 }} />
                            </div>
                            <div className="mt-2 d-flex flex-column">

                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">20 Étudiants</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">3 cours</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Parcours et sessions</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">1 Professeur</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">1 Administrateur</span>
                                </div>
                      
                            </div>
                        </div>
                    </div>




                    <div className="bg-with-border offer-card mb-3 mt-1 me-4" style={{ backgroundColor: "#FFFFFF" }}>
                        <div className="d-flex flex-column align-item-center" >
                            <div className="d-flex flex-column align-items-start mb-2">
                                <div className="d-flex flex-column align-items-start mb-2">
                                    <span className="H3-Headline black-800">Professionnelle</span>
                                    <span className="mt-1 body-xl neutral-2">Pour les formateurs professionnels et infopreneurs</span>
                                </div>
                                <span className="offer-price2">99€ </span>
                                <span className="body-xl neutral-2">HT / par mois </span>
                                <span className="body-xl neutral-2" style={{ paddingTop: "6px" }}>( Facturé mensuellement )</span>
                                <div style={{ paddingTop: "16px" }}>
                                    <button type="button" className="btn-Primary large" style={{ width: 304 }}  onClick={()=>{submitPayed(`${process.env.REACT_APP_OFFER_LABEL_PRO}`)}}>
                                        Choisir l’offre Professionnelle
                                    </button>
                                </div>
                            </div>

                            <div className="w-100 d-flex my-4">
                                <hr className="hr-payment" style={{ width: '100%', margin: 0 }} />
                            </div>
                            <div className="mt-2 d-flex flex-column">

                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">200 Étudiants</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">20 cours</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Parcours et sessions</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">2 Professeurs</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">2 Administrateurs</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Webinaires</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Coupons de réduction</span>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="bg-with-border offer-card mb-3 mt-1 me-4" style={{ backgroundColor: "#FFFFFF" }}>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column align-items-start mb-2">
                                <div className="d-flex flex-column align-items-start " style={{ marginBottom: "40px" }}>
                                    <span className="H3-Headline black-800">Business</span>
                                    <span className="mt-1 body-xl neutral-2">Pour profiter de Koors en illimité.</span>


                                </div>
                                <span className="offer-price2">8€ </span>
                                <span className="body-xl neutral-2">par étudiant ( minimum 20 ) </span>
                                <span className="body-xl neutral-2" style={{ paddingTop: "6px" }}>HT / par mois</span>
                                <div style={{ paddingTop: "16px" }}>
                                    <button type="button" className="btn-Primary large" style={{ width: 304 }} onClick={()=>{submitPayed(`${process.env.REACT_APP_OFFER_LABEL_BUSINESS}`)}} >
                                        Choisir{` l’offre `}business
                                    </button>
                                </div>
                            </div>

                            <div className="w-100 d-flex my-4">
                                <hr className="hr-payment" style={{ width: '100%', margin: 0 }} />
                            </div>
                            <div className="mt-2 d-flex flex-column">

                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Nombre{` d'étudiants `}illimité</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Nombre de cours illimité</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Parcours et sessions</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Nombre de Professeurs illimité</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Nombre{` d’administrateurs `}illimité</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Webinaires</span>
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <span className="material-icons-outlined green-800" style={{ marginTop: '2px' }}>
                                        check_circle_outline
                                    </span>
                                    <span className="ms-3 body-md black-800">Coupons de réduction</span>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getSubscriptions,
    getOffer,
    getContract,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(OfferListPage);