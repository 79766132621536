import axiosInstance from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {IReduxGroupState} from '../reducers/group-reducer';
export enum EReduxActionTypes{
    CREATE_GROUP='CREATE_GROUP',
    GET_GROUPS_BY_SCHOOL='GET_GROUPS_BY_SCHOOL',
    CLEAR_FORM_GROUP='CLEAR_FORM_GROUP',
    DELETE_GROUP='DELETE_GROUP',
    SET_ACTUAL_GROUP = 'SET_ACTUAL_GROUP',
    AFFECT_STUDENT_TO_GROUP='AFFECT_STUDENT_TO_GROUP',
    DISAFFECT_STUDENT_FROM_GROUP='DISAFFECT_STUDENT_FROM_GROUP',
    ADD_SUB_GROUP='ADD_SUB_GROUP',
    REMOVE_SUB_GROUP='REMOVE_SUB_GROUP',
    SET_ACTUAL_TAB_GROUP='SET_ACTUAL_TAB_GROUP',
    GET_GROUPS_BY_STUDENT='GET_GROUPS_BY_STUDENT',
    SEARCH_GROUP='SEARCH_GROUP',
    FILTER_STUDENT_IN_GROUP="FILTER_STUDENT_IN_GROUP",
    GET_FILTRED_GROUP='GET_FILTRED_GROUP'
    
}
interface IReduxBaseAction {
    type: EReduxActionTypes;
  }
export interface IReduxCreateGroupAction extends IReduxBaseAction {
    type: EReduxActionTypes.CREATE_GROUP;
    data: any;
} 
export interface IReduxSearchGroupByLabelAction extends IReduxBaseAction {
  type: EReduxActionTypes.SEARCH_GROUP;
  data: any;
} 
export interface IReduxSetActualGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_GROUP;
  data: any;
} 
export interface IReduxSetActualtABGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_GROUP;
  data: any;
} 

export interface IReduxDeleteGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_GROUP;
  data: any;
} 
export interface IReduxGetGroupBySchoolAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_GROUPS_BY_SCHOOL;
    data: any;
} 
export interface IReduxClearFormGroupAction  extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_FORM_GROUP;
} 
export interface IReduxAffectStudentToGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.AFFECT_STUDENT_TO_GROUP;
  data: any;
} 
export interface IReduxDisaffectStudentfromGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.DISAFFECT_STUDENT_FROM_GROUP;
  data: any;
} 
export interface IReduxAddSubGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_SUB_GROUP;
  data: any;
} 
export interface IReduxRemoveSubGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.REMOVE_SUB_GROUP;
  data: any;
} 
export interface IReduxGetGroupsByStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_GROUPS_BY_STUDENT;
  data: any;
} 
export interface IReduxFilterStudentInGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.FILTER_STUDENT_IN_GROUP;
  data: any;
}
export interface IReduxFiltredGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_FILTRED_GROUP;
  data: any;
}


export function setActualGroup(group: any): ThunkAction<Promise<IReduxSetActualGroupAction>, IReduxGroupState, undefined, IReduxSetActualGroupAction> {
  return async (dispatch: ThunkDispatch<IReduxGroupState, undefined, IReduxSetActualGroupAction>) => {
    let finalList:any=[];
    let newPersonList=[group?.personList?.map((obj:any)=>{return obj}), group?.childsList?.map((subGroup:any)=>{ return subGroup?.personList?.map((p:any)=>{return p})})].flat(Infinity)
          newPersonList.forEach((item: any)=>{
            let i = finalList?.findIndex((x:any) => (x?.id == item?.id));
                if(i === -1){
                    finalList.push(item);
                }
                return null;
              });
              group["allStudent"]=finalList;
    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_GROUP,
      data: group
    })
  }
}
export function setActualTabGroup(tab: string): ThunkAction<Promise<IReduxSetActualtABGroupAction>, IReduxGroupState, undefined, IReduxSetActualtABGroupAction> {
  return async (dispatch: ThunkDispatch<IReduxGroupState, undefined, IReduxSetActualtABGroupAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_GROUP,
      data: tab
    })
  }
}
export function clearFormGroup(): ThunkAction<Promise<IReduxClearFormGroupAction>, IReduxGroupState, undefined, IReduxClearFormGroupAction> {
    return async (dispatch: ThunkDispatch<IReduxGroupState, undefined, IReduxClearFormGroupAction>) => {
      return dispatch({
        type: EReduxActionTypes.CLEAR_FORM_GROUP
      })
    }
  }
export function deleteGroup(group:any):ThunkAction<Promise<IReduxDeleteGroupAction>,IReduxGroupState, undefined, IReduxDeleteGroupAction> {
    return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxDeleteGroupAction>)=>{
       const baseUrl=process.env.REACT_APP_BASE_URL2;
        await axiosInstance.get(baseUrl+`group/deleteGroup/${group.id}`).then(res => { return res.data }).catch(err => console.error(err));
       return dispatch({
           type:EReduxActionTypes.DELETE_GROUP,
           data:group
       });

    }
 }
 export function createGroup(group:any):ThunkAction<Promise<IReduxCreateGroupAction>,IReduxGroupState, undefined, IReduxCreateGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxCreateGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let newGroup= await axiosInstance.post(baseUrl+`group/createOrUpdate`,group).then(res => { return res.data }).catch(err => {return null});
     if(group?.personList?.length!==0)
     {newGroup['personList']=group?.personList}
     else {
       newGroup['personList']=[]
       newGroup['childsList']=[]}
     return dispatch({
         type:EReduxActionTypes.CREATE_GROUP,
         data:newGroup
     });

  }
}
 export function getGroupBySchool(idSchool:number):ThunkAction<Promise<IReduxGetGroupBySchoolAction>,IReduxGroupState, undefined, IReduxGetGroupBySchoolAction> {
    return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxGetGroupBySchoolAction>)=>{
       const baseUrl=process.env.REACT_APP_BASE_URL2;
       let groupList= await axiosInstance.get(baseUrl+`group/findGoursByLegalPerson/${idSchool}`).then(res => { return res.data }).catch(err => {return []});
     
       return dispatch({
           type:EReduxActionTypes.GET_GROUPS_BY_SCHOOL,
           data:groupList
       });

    }
 }
 export function getGroupByStudent(idStudent:number):ThunkAction<Promise<IReduxGetGroupsByStudentAction>,IReduxGroupState, undefined, IReduxGetGroupsByStudentAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxGetGroupsByStudentAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let groupList= await axiosInstance.get(baseUrl+`group/findGoursByNaturalPerson/${idStudent}`).then(res => { return res.data }).catch(err => {return []});
     return dispatch({
         type:EReduxActionTypes.GET_GROUPS_BY_STUDENT,
         data:groupList
     });

  }
}

 export function affectStudentToGroup(objects:any):ThunkAction<Promise<IReduxAffectStudentToGroupAction>,IReduxGroupState, undefined, IReduxAffectStudentToGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxAffectStudentToGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let groupForm=await axiosInstance.post(baseUrl+`group/affectPersonToGroup/${objects.idGroup}/${objects.idSchool}/${objects.idGroupParent}`,objects.person).then(res => { return res.data }).catch(err => console.error(err));
     objects.person['creationDate']=objects.person.inscription
     return dispatch({
         type:EReduxActionTypes.AFFECT_STUDENT_TO_GROUP,
         data:{student:objects.person, group: objects.idGroup, subGroup:objects.subGroup, parentGroup:objects.parentGroup, groupForm:groupForm}
     });

  }
}

export function disaffectStudentFromGroup(objects:any):ThunkAction<Promise<IReduxDisaffectStudentfromGroupAction>,IReduxGroupState, undefined, IReduxDisaffectStudentfromGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxDisaffectStudentfromGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let groupForm= await axiosInstance.post(baseUrl+`group/disaffectPersonToGroup/${objects.idGroup}/${objects.idSchool}/${objects.idGroupParent}`,objects.person ).then(res => { return res.data }).catch(err => console.error(err));
     
     return dispatch({
         type:EReduxActionTypes.DISAFFECT_STUDENT_FROM_GROUP,
         data:{student:objects.person, group: objects.idGroup, subGroup:objects.subGroup, parentGroup:objects.parentGroup, groupForm:groupForm}
     });

  }
}

export function addSubGroup(subGroup:any ,idParentGroup: number):ThunkAction<Promise<IReduxAddSubGroupAction>,IReduxGroupState, undefined, IReduxAddSubGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxAddSubGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let parentGroup= await axiosInstance.post(baseUrl+`group/addSubGroupToGroup/${idParentGroup}`,subGroup ).then(res => { return res.data }).catch(err => console.error(err));
     
     return dispatch({
         type:EReduxActionTypes.ADD_SUB_GROUP,
         data:parentGroup
     });

  }
}
export function removeSubGroup(subGroup:any ,idParentGroup: number):ThunkAction<Promise<IReduxRemoveSubGroupAction>,IReduxGroupState, undefined, IReduxRemoveSubGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxRemoveSubGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let parentGroup= await axiosInstance.post(baseUrl+`group/removeSubGroupFromGroup/${idParentGroup}`,subGroup ).then(res => { return res.data }).catch(err => console.error(err));
     
     return dispatch({
         type:EReduxActionTypes.REMOVE_SUB_GROUP,
         data:parentGroup
     });

  }
}


export function searchGroupByLabel(search:string ,idSchool: number):ThunkAction<Promise<IReduxSearchGroupByLabelAction>,IReduxGroupState, undefined, IReduxSearchGroupByLabelAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxSearchGroupByLabelAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let Groups= await axiosInstance.get(baseUrl+`group/searchGroup/${idSchool}/${search}`).then(res => { return res.data }).catch(err => []);
     
     return dispatch({
         type:EReduxActionTypes.SEARCH_GROUP,
         data:Groups
     });

  }
}

export function filterStudentInGroup(filter:any ,idSchool: number, all:boolean,parent:boolean, idGroup:number):ThunkAction<Promise<IReduxFilterStudentInGroupAction>,IReduxGroupState, undefined, IReduxFilterStudentInGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxFilterStudentInGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
     let Student= await axiosInstance.post(baseUrl+`group/searchStudent/${idSchool}`,filter ).then(res => { return res.data }).catch(err => []);
     return dispatch({
         type:EReduxActionTypes.FILTER_STUDENT_IN_GROUP,
         data:{students:Student,all:all,parent:parent, idGroup:idGroup}
     });

  }
}

export function getFiltredGroup(filter:any):ThunkAction<Promise<IReduxFiltredGroupAction>,IReduxGroupState, undefined, IReduxFiltredGroupAction> {
  return async (dispatch:ThunkDispatch<IReduxGroupState,undefined,IReduxFiltredGroupAction>)=>{
     const baseUrl=process.env.REACT_APP_BASE_URL2;
    let groupList= await axiosInstance.post(baseUrl+`group/findGoursByIds`,filter ).then(res => { return res.data }).catch(err => []);
     
     return dispatch({
         type:EReduxActionTypes.GET_FILTRED_GROUP,
         data:groupList
     });

  }
}