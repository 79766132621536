import { ComponentProps, FC} from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import HeaderRegistrationPreviewStudentPageComponent from '../../components/headers-page/header-registration-preview-student-page-component';
import AdmissionPreviewStudentComponent from '../../components/admission/admission-preview-student-component';
const CatalogRegistrationPreviewStudentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    return (
        <>
             <div style={{ backgroundColor: '#FFFFFF' }}>
                <HeaderRegistrationPreviewStudentPageComponent/>
             </div>
             <AdmissionPreviewStudentComponent/>
           
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
   
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
   
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogRegistrationPreviewStudentPage);