import { FC } from "react";
import CustomSwitch from "../../helpers/custom-switch";
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import manageFaqComponent from "./manageFaqComponent";


const FaqContainer: FC<any> = (props: any) => {
    return (<>
        <CustomSwitch>
            <RouteWithNavbar exact={true} history={props?.history} path={`${props?.match.path}/presentation`} component={manageFaqComponent} />
        </CustomSwitch>
    </>
    )
}
export default FaqContainer;