
import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../helpers/interceptors';
import { changingActualTab, getExamsList, getExamsWithFilter } from '../../global-state/actions/exam-actions';
import InputSearch from '../../elements/inputs/input-search';
import SelectElement from '../../elements/select/select-element';

interface Props {
    isSearching: any,
}
const HeaderExamDetailsDashboard: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    {

        const [actualTab, setActualTab] = useState<string>("ALL");
        const [nbrExamsToReview, setnbrExamsToReview] = useState<number>(props?.examStats?.nbrExamsToReview);
        const [nbrCompletedExams, setnbrCompletedExams] = useState<number>(props?.examStats?.nbrCompletedExams);
        const [nbrAllExams, setNbrAllExams] = useState<number>(props?.examStats?.nbrAllExams)
        const { t } = useTranslation();
        const [idProf] = useState(props?.connectedUserDetails.idPerson);
        const [idSchool] = useState(props?.connectedUserDetails.idSchool);
        const [filterOption, setFilterOption] = useState<any>();
        const [filtre, setFiltre] = useState<string>("ALL");
        const [searchFilter, setSearchFilter] = useState<string>("");
        const [listExams, setListExams] = useState(props?.examsList?.listAllExam)

        useEffect(() => {
            setFiltre("ALL");
            setSearchFilter("")
            setListExams(props?.examsList?.listAllExam)
            return () => {
                props?.changingActualTab("ALL")
              }
        }, [])


        

        useEffect(() => {        
          props?.searchExams(filtre,searchFilter)
        }, [filtre,searchFilter])
        

        useEffect(() => {
            setActualTab(props?.actualTab);
        }, [props?.actualTab])

        useEffect(() => {
            setnbrExamsToReview(props?.examStats?.nbrExamsToReview)
            setnbrCompletedExams(props?.examStats?.nbrCompletedExams);
            setNbrAllExams(props?.examStats?.nbrAllExams)
        }, [props?.examStats])

        useEffect(() => {
            setnbrExamsToReview(props?.examStats?.nbrExamsToReview)
            setnbrCompletedExams(props?.examStats?.nbrCompletedExams);
            setNbrAllExams(props?.examStats?.nbrAllExams)
        }, [])

        useEffect(() => {
            if(nbrAllExams ===props?.examsList?.listAllExam?.length){
                setListExams(props?.examsList?.listAllExam)
            }
            
        }, [props?.examsList?.listAllExam])
        
       

        useEffect(() => {
                if((listExams?.length === props?.examsList?.listAllExam?.length) ){
                let liste = [{ value: "ALL", label: t('allFormations') }];
                listExams?.map((obj: any, i: number) => {
                    
                    let verif=liste.findIndex((check:any)=>check?.value === obj?.entityId)
                   if(verif===-1){
                    liste?.push({ value: obj?.courseName, label: obj?.courseName })
                    
                   }
                
                })
                setFilterOption(liste);
                
                }
            
        }, [ listExams])
        


        const exportData = (actualTab1: string, idSchool1: number, idProf1: number) => {
            let nameSheet = "fiche";
            let res;
            axiosInstance.post(`${process.env.REACT_APP_BASE_URL6}exams/export/${actualTab1}/${idSchool1}/${idProf1}/${nameSheet}`, {
                responseType: 'blob',
            }
            )
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', actualTab1 + '.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
        }

        const displayFilter = (actualTab: any, examsList: any) => {
            if(actualTab === 'ALL' && examsList?.listAllExam?.length >0 ){
                return <div style={{ backgroundColor: "#FBFBFD" }}>
                        <div className="row" style={{ maxWidth: "1200px", margin: 'auto' }} >
                            <div className="d-flex align-items-center justify-content-between" style={{ paddingTop: "32px" }}>
                                <button onClick={() => exportData(actualTab, idSchool, idProf)} type="button" className="btn-Secondary large icon-left">
                                    <span className="material-icons-outlined">
                                        download
                                    </span>
                                    <div className="m-auto ps-2">{t("exportListe")}</div>
                                </button>
                                <div className="d-flex align-items-center">
                                    <div style={{ paddingRight: "8px", width: "220px" }}>
                                        <SelectElement disabled={false} taille={'lg'} defaultValue={"ALL"} options={filterOption} placeholder={t('allFormations')}  onChange={(e:any)=>{setFiltre(e)}} />
                                    </div>

                                    <div>
                                        <div style={{ maxWidth: '300px', backgroundColor: "#F2F2F5" }}>
                                            <InputSearch value={searchFilter} onChange={(e:any)=>{setSearchFilter(e)}} placeholder={t('searchProfessors')} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                </div>
            }
            if(actualTab === 'COMPLETED' && props?.examsList?.listExamCompleted?.length > 0) {
                return <div style={{ backgroundColor: "#FBFBFD" }}>
                        <div className="row" style={{ maxWidth: "1200px", margin: 'auto' }} >
                            <div className="d-flex align-items-center justify-content-between" style={{ paddingTop: "32px" }}>
                                <button onClick={() => exportData(actualTab, idSchool, idProf)} type="button" className="btn-Secondary large icon-left">
                                    <span className="material-icons-outlined">
                                        download
                                    </span>
                                    <div className="m-auto ps-2">{t("exportListe")}</div>
                                </button>
                                <div className="d-flex align-items-center">
                                    <div style={{ paddingRight: "8px", width: "220px" }}>
                                        <SelectElement disabled={false} taille={'lg'} defaultValue={"ALL"} options={filterOption} placeholder={t('allFormations')}  onChange={(e:any)=>{setFiltre(e)}} />
                                    </div>

                                    <div>
                                        <div style={{ maxWidth: '300px', backgroundColor: "#F2F2F5" }}>
                                            <InputSearch value={searchFilter} onChange={(e:any)=>{setSearchFilter(e)}} placeholder={t('searchProfessors')} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                </div>
            }
            if(actualTab === "TOREVIEW" && props?.examsList?.listExamToReview?.length > 0) {
                return <div style={{ backgroundColor: "#FBFBFD" }}>
                <div className="row" style={{ maxWidth: "1200px", margin: 'auto' }} >
                    <div className="d-flex align-items-center justify-content-between" style={{ paddingTop: "32px" }}>
                        <button onClick={() => exportData(actualTab, idSchool, idProf)} type="button" className="btn-Secondary large icon-left">
                            <span className="material-icons-outlined">
                                download
                            </span>
                            <div className="m-auto ps-2">{t("exportListe")}</div>
                        </button>
                        <div className="d-flex align-items-center">
                            <div style={{ paddingRight: "8px", width: "220px" }}>
                                <SelectElement disabled={false} taille={'lg'} defaultValue={"ALL"} options={filterOption} placeholder={t('allFormations')}  onChange={(e:any)=>{setFiltre(e)}} />
                            </div>

                            <div>
                                <div style={{ maxWidth: '300px', backgroundColor: "#F2F2F5" }}>
                                    <InputSearch value={searchFilter} onChange={(e:any)=>{setSearchFilter(e)}} placeholder={t('searchProfessors')} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
        </div>
            }
        }

        return (
            <div style={{ backgroundColor: "#FFFFFF" }}>
                <div className="row" style={{ maxWidth: "1200px", margin: 'auto' }} >
                    <div>
                        <div className="d-flex w-100" style={{ paddingTop: "32px" }} >
                            <h2 className="H2-Headline neutral-3">{t('Examens')} <span style={{ color: "#272727" }}>{nbrAllExams}</span></h2>
                        </div>

                        <div className="d-flex mb-4 pb-2 text-left" style={{ marginTop: "20px" }}>
                            <button className={`btn-Tabs ${(actualTab !== 'ALL') ? 'unselected' : ''}`} style={{ marginRight: "16px" }} onClick={() => { props?.changingActualTab("ALL") }} >{t("Tous_les_examens")} ({nbrAllExams})</button>
                            <button className={`btn-Tabs ${(actualTab !== 'TOREVIEW') ? 'unselected' : ''}`} style={{ marginRight: "16px" }} onClick={() => { props?.changingActualTab("TOREVIEW") }} >{t("Examens_à_noter")} ({nbrExamsToReview})</button>
                            <button className={`btn-Tabs ${(actualTab !== 'COMPLETED') ? 'unselected' : ''}`} style={{ marginRight: "16px" }} onClick={() => { props?.changingActualTab("COMPLETED") }} >{t("Examen_notés")} ({nbrCompletedExams})</button>
                        </div>
                    </div>
                </div>
                {displayFilter(actualTab, props?.examsList)}
            </div>
        )
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    connectedUserDetails: state.authReducer.connectedUser,
    history: ownProps?.history,
    examStats: state.examReducer.examStats,
    actualTab: state.examReducer.actualTab,
    isSearching: ownProps?.isSearching,
    
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            changingActualTab,
            getExamsWithFilter,
            getExamsList
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(HeaderExamDetailsDashboard);
