import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { getUserProfileDetails } from '../../global-state/actions/auth-actions';
import { getActualTab } from '../../global-state/actions/auth-actions';
import OfferListComponent from '../../components/subscription/offer-list-component';

const SubscriptionPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(props?.loadingProfile);
    const [userProfileDetails, setUserProfileDetails] = useState<any>(props?.userProfileDetails);
    useEffect(() => {
        props?.getUserProfileDetails(props?.connectedUser?.id)
        setLoading(props?.loadingProfile)
    }, [])

    useEffect(() => {
        setLoading(props?.loadingProfile);
    }, [props?.loadingProfile])

    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
    }, [props?.userProfileDetails])


    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
    }, [props?.userProfileDetails?.photo])


    return (
        <OfferListComponent/>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loadingProfile: state.authReducer.loadingProfile,
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    actualTab:state.authReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,getActualTab
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);