import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import UpdateProfileInfosComponent from '../../components/profile-components/update-profile-infos-component';
import DeleteAccountComponent from '../../components/profile-components/delete-account-component';
import UpdatePasswordComponent from '../../components/profile-components/update-password-component';
import UpdateUserMailComponent from '../../components/profile-components/update-user-mail-component';
import DeleteAccountLoadingComponent from '../../components/profile-components/delete-account-loading-component';
import UpdatePasswordLoadingComponent from '../../components/profile-components/update-password-loading-component';
import UpdateUserMailLoadingComponent from '../../components/profile-components/update-user-mail-loading-component';
import UpdateProfileInfosLoadingComponent from '../../components/profile-components/update-profile-infos-loading-component';
import { getUserProfileDetails } from '../../global-state/actions/auth-actions';
import { getActualTab } from '../../global-state/actions/auth-actions';
import InputUploadProfilePhoto from '../../elements/inputs/input-upload-profile-photo';
const AccountPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(props?.loadingProfile);
    const [userProfileDetails, setUserProfileDetails] = useState<any>(props?.userProfileDetails);
    const [actualTab, setActualTab] = useState(props?.actualTab)
    const [role, setRole] = useState(props?.connectedUser?.role)
    const [photo, setPhoto] = useState<any>(props?.userProfileDetails?.photo);


    useEffect(() => {
        setPhoto(props?.userProfileDetails?.photo)
    }, [props?.userProfileDetails])
    

    useEffect(() => {
        setRole(props?.connectedUser?.role)
    }, [props?.connectedUser?.role])

    useEffect(() => {
        props?.getUserProfileDetails(props?.connectedUser?.id)
        setLoading(props?.loadingProfile)
    }, [])

    useEffect(() => {
        setLoading(props?.loadingProfile);
    }, [props?.loadingProfile])

    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
    }, [props?.userProfileDetails])


    useEffect(() => {
        setUserProfileDetails(props?.userProfileDetails);
    }, [props?.userProfileDetails?.photo])
    useEffect(() => {
    setActualTab(props?.actualTab)
        
    }, [props?.actualTab])

    return (
        <div className="container-fluid " style={{ backgroundColor: "#FFFFFF", paddingRight: "40px", paddingLeft: "40px" }}>
            {loading === false ? (
                <>
                    <> <div className="d-flex mb-2" style={{ maxWidth: "1200px", margin: 'auto' }}>
                        <h2 className="H2-Headline py-4 my-2" > {t("myAccount")}</h2>
                    </div>
                        <div>
                            <div className='profile-info align-items-start'>
                                <div className='profile-info-photo'>
                                    <InputUploadProfilePhoto photo={photo} />
                                </div>
                                <div className='update-profile-Info d-flex flex-column w-100' >
                                    <UpdateProfileInfosComponent />
                                    <div className='detail-profile'>
                                        <UpdateUserMailComponent />
                                        <UpdatePasswordComponent />
                                        <DeleteAccountComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </>
            ) : (
                <div>
                    <div>
                        <UpdateProfileInfosLoadingComponent />
                    </div>
                    <div style={{ paddingTop: "33px" }}>
                        <UpdateUserMailLoadingComponent />
                    </div>
                    <div style={{ paddingTop: "33px" }}>
                        <UpdatePasswordLoadingComponent />
                    </div>
                    <div style={{ paddingTop: "33px", paddingBottom: "56px" }}>
                        <DeleteAccountLoadingComponent />
                    </div>
                </div>
            )}
        </div >
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loadingProfile: state.authReducer.loadingProfile,
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    actualTab: state.authReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails, getActualTab
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);