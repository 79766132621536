import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect} from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import userLogo from '../../../styles/imgs/Default_user_icon.svg';
import { createResponse, createSeens } from '../../../global-state/actions/chat-actions';
import ResponseCard from './response-card';
import QuestionCardHeader from '../question-card/question-card-header';
import TextareaCustomparagraphCommentElement from '../../../elements/text-area/text-area-custom-paragraph-comment-element';
interface Props {
    post: any,
    what: string,
    t: any
}
const QuestionCard: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props) => {
    const { t } = useTranslation();
    const [post, setPost] = useState(props?.post);
    const [schowInput, setSchowInput] = useState(false);
    const [comment, setComment] = useState("");
    const [schowed, setSchowed] = useState(false)
    const [newResponseNumber, setnewResponseNumber]= useState(0);
    const [newResponses, setNewResponses]= useState(false)

    useEffect( () => {
        let responseNumber= 0;
        setPost(props?.post)
        post.responses?.map( (obj:any)=>{
            if(obj.seens.length===0 && obj.idAuthor.id!== props?.connectedUser?.idPerson){responseNumber=responseNumber+1}
        })
        setnewResponseNumber(responseNumber);
        if(responseNumber > 0) {
            setNewResponses(true);
        }
        
        
    }, [props?.post?.responses, post, props?.post]);
    
  
    const buttonHandler = () => {
        setSchowInput(current => !current)
    }
    const createSeensFN=(responses: any)=>{
     let listObjects:any[];
     let responsesIds= responses.filter((obj:any)=>{return ((obj.idAuthor.id!==props?.connectedUser?.idPerson) && ((obj.seens.map((seen: any) =>{return seen.idPerson})).includes(props?.connectedUser?.idPerson)))|| (obj.idAuthor.id!==props?.connectedUser?.idPerson&&obj.seens.length===0)}).map((obj: any)=>{return obj.id})

     listObjects= responsesIds.map((obj:any)=>{
          return {idPerson: props?.connectedUser?.idPerson,isSeen: true,postId:obj }
      
     });
        props?.createSeens(listObjects, props?.what);
        setNewResponses(false);    
    }

    function changeInputValue(value:string ){
        setComment(value)
    }

    const addResponse = async () => {
        let object = {
            post: {
                idAuthor: props?.connectedUser?.idPerson,
                type: "RESPONSE",
                postId: props?.post.id
            },
            comment: {
                description: comment,
                type: "TEXT"
            },
            subject: [props?.post?.subject?.idSubject+" "+props?.post?.subject?.category?.category+" "+"RESPONSE"]

           
        }
       
        await props?.createResponse(object, props?.what, props?.connectedUser?.idSchool );
        setSchowInput(current => !current)
    }
    return (
        <>
            <div className="my-3">
                <div className="d-flex align-items-top mb-2">
                    <div className="me-3">
                        <img className="img-profil small" src={(post?.idAuthor?.photo) ? post?.idAuthor?.photo : userLogo} alt="profile img" />
                    </div>
                    <div className="d-flex flex-wrap badge-text dark align-items-center">
                        <span className="body-sm bold me-3 mt-2 discuttion-titles">{post?.idAuthor?.firstName + " " + post?.idAuthor?.lastName}</span>
                        <QuestionCardHeader post={post}></QuestionCardHeader>
                    </div>
                </div>
                <div className="d-flex flex-column ms-5 ps-1">
                    <span className="body-md bold badge-text dark mb-3">{post?.comment?.description}</span>
                    <div className="d-flex flex-column">
                        <div className="mb-3">
                            <div className="row" id="accordin" >
                                <div className="col-md-12" id="headingOne">
                                <div className=" d-flex flex-row  flex-wrap">
                                    {(post?.responses?.length !== 0) &&
                                        <><button className="btn-text with-icon me-2" onClick={() => { setSchowed(!schowed); createSeensFN(post.responses) }} data-bs-toggle="collapse" data-bs-target={"#id" + post.id} aria-expanded="false" aria-controls="collapseOne">
                                            {post?.responses?.length + " " + t('responses')}{(schowed === false) ? <span className="material-icons">expand_more</span> : <span className="material-icons">expand_less</span>}
                                        </button>
                                        {newResponseNumber!==0 && newResponses &&  <div className="d-flex align-items-center"><span className="badge-text success me-2">{t('newResponses')}</span><div className="notif-badge">{newResponseNumber}</div></div>}
                                   </> }

                                    {(post?.responses?.length === 0) &&
                                        <button className="btn-text with-icon">
                                            <span className="badge-text danger">{post?.responses?.length + " " + t('responses')}</span>
                                        </button>}
                                        </div> 
                                </div>
                                <div id={"id" + post.id} className="collapse  col-md-12 mt-3" aria-labelledby="headingOne" >
                                    {post?.responses?.map((res: any) => {
                                        return <><ResponseCard post={res} t={t} /></>
                                    })}
                                </div>
                            </div>

                        </div>
                        {(schowInput === false) && <button className="btn-text small" onClick={buttonHandler}>{t('repondre')}</button>}
                        {(schowInput === true) && <div className="col-md-12" id="text">
                            <div className="row mt-2">
                                <div className="col-md-12  comment-reply">
                                    <img className="comment-reply pic me-2 mt-auto" src={(props?.connectedUserInfo?.photo) ? props?.connectedUserInfo?.photo : userLogo} alt="userImg" />
                                    <div className=" comment">
                                        <div className="w-100 me-1 d-flex align-items-center">
                                            <TextareaCustomparagraphCommentElement id={props?.post.id}  value={comment} onChange={changeInputValue} placeholder={t('votreCommentaie')}/>
                                        </div>
                                    </div>
                                    <button className="btn-Primary medium ms-2  mt-auto" style={{ maxHeight: '36px', fontSize: '14px' }} disabled={comment === ""} onClick={() => { addResponse(); setComment("") }}> {t('publishMyReview')}</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    connectedUserInfo: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createResponse,
            createSeens,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(QuestionCard);