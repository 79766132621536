import { ComponentProps, FC, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';


const ResponseQuestionElement: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [open, setOpen] = useState(false);

    let data = props.response;
;

    return (
        <div style={{ marginBottom: "16px" }}>
            <div className="d-flex flex-row justify-content-between" style={{ width: "686px", border: "1px solid #EBECEE", padding: "20px" }}>
                <div className="d-flex flex-column" >
                    <span className="body-sm" style={{ width: "492px", marginBottom: "12px" }} dangerouslySetInnerHTML={{ __html: !open && data?.length > 67 ? `${data.substring(0, 67)}...` : data }} />
                    <span className="body-sm bold ">
                        {props?.date?.substring(0, 10).split("-").reverse().join("/")}
                    </span>
                </div>

                {data?.length > 67 && (
                    <div className="d-flex flex-column" >
                        <button type="button" className="btn-QuickActions  me-2" onClick={() => { setOpen(!open); }}>
                            {!open ? <span className="material-icons-outlined">expand_more</span> : <span className="material-icons-outlined">expand_less</span>}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {},
        dispatch
    );

const mapStateToProps = (state: AppState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseQuestionElement);
