import { ComponentProps, FC, useEffect, useState } from "react";
import InputUploadImage from "../../elements/inputs/input-upload-image";
import CropImage from "./crop-image-course-component";
import { connect } from 'react-redux';
import { createCourse, uploadImageCourse } from '../../global-state/actions/course-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { clearMyTrainings } from "../../global-state/actions/training-actions";
const UploadImageCourseComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [courseForm, setCourseForm] = useState<any>();
    const [files, setFiles] = useState<any[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        setCourseForm(props?.courseForm);
        setFiles([props?.courseForm?.linkImg]);
        props?.getFile(props?.courseForm?.linkImg);
    }, [props?.courseForm]);
    const deleteFile = () => {
        const copyCourseForm = { ...courseForm };
        copyCourseForm['linkImg'] = '';
        copyCourseForm['type'] = 'COURSE';
        if (props?.connectedUser?.role === "role_prof") {
            copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
            copyCourseForm['creator'] = props?.connectedUser?.idPerson;
        }
        setCourseForm(copyCourseForm);
        props?.createCourse(copyCourseForm, props?.connectedUser);
        props?.clearMyTrainings()
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        setFiles([]);
    }
    const addFile = (file: any) => {
        const form = new FormData();
        form.append('file', file[0]);
        const copyCourseForm = { ...courseForm };
        copyCourseForm['type'] = 'COURSE';
        if (props?.connectedUser?.role === "role_prof") {
            copyCourseForm['idProf'] = props?.connectedUser?.idPerson;
            copyCourseForm['creator'] = props?.connectedUser?.idPerson;
        }
        props?.uploadImageCourse(form, copyCourseForm);
    };
    return (<>{files && files[0] && files[0] !== '' ?
        <CropImage file={files[0]+"?cacheblock=true"} width={840} addFile={(image: any) => addFile(image)} deleteFile={deleteFile} />
        : <InputUploadImage description={t('uploadImage_1')} textButton={t('uploadImage_2')} addFile={(image: any) => addFile(image)} />}
    </>);
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ createCourse, uploadImageCourse, clearMyTrainings }, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    courseTitle: ownProps?.courseTitle,
    courseForm: state.courseReducer.courseForm,
    history: ownProps?.history,
    t: ownProps?.t,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImageCourseComponent);
