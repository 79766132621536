import { ComponentProps, FC, useEffect, useState, useRef, useMemo } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { getUserProfileDetails, updateProfile } from '../../global-state/actions/auth-actions';
import { setConnectedUser } from '../../global-state/actions/auth-actions';
import { getUserById } from '../../global-state/actions/user-auth-actions';
import { loginUser , getCards} from '../../global-state/actions/auth-actions';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { getOffer } from '../../global-state/actions/app-container-actions';

const CardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const openRef = useRef(null);
    const openRef1 = useRef(null);
    const [last4, setLast4] = useState<any>()
    const openCloseUserInfo = (card: any) => {


        setOpen(!open);
    }
    useEffect(() => { setLast4(props?.last4) }, [props?.last4])
    return (<>

        <div className='d-flex flex-column' >
            <div className='d-flex flex-row justify-content-between align-items-center' style={{ position: 'relative' }}>
                <div className='d-flex flex-column w-100'>
                    <div className='d-flex flex-row align-items-center w-100'>
                        <span className="body-xl bold neutral-3 me-3">{props?.brand}</span>
                        

                      {props?.index ==0 && <div className="badge-primary">{t('principal')}</div>}  
                    </div>

                    <span className="H4-Subtitle black-800 mt-3">***** ***** ***** ***** ({props?.last4})</span>
                </div>

      
                <button type="button" className={`btn-QuickActions transparent ${(open == true) ? "bggrey" : ""}`} onClick={() => openCloseUserInfo(props?.cardId)}>
                    <span className="material-icons-outlined">more_horiz</span>
                </button>


                {(open == true) &&
                    <>
                        <div className="d-flex flex-column sub-liste" style={{ position: 'absolute', right: 0, top: 40 }}>
                            <a style={{ cursor: 'pointer' }} className="submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { openCloseUserInfo(props?.cardId); props?.openModal({ idModal: ModalIDS.DEFAULT_CARD, size: "md", cardId: props?.cardId, cusId: props?.cusId, last4: props?.last4 }) }}>

                                <span className="body-md bold ms-2" >{t('setAsPrincipal')}</span>
                            </a>

                            <a style={{ cursor: 'pointer' }} className="submenu-list-button d-flex align-items-center p-2 px-3" onClick={() => { openCloseUserInfo(props?.cardId); props?.openModal({ idModal: ModalIDS.DELETE_CARD, size: "md", cardId: props?.cardId, cusId: props?.cusId, last4: props?.last4 }) }}>

                                <span className="body-md bold  ms-2">{t('delete')}</span>
                            </a>

                        </div></>}

            </div>


        </div>


        <hr className="hr-nav my-3" />


    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    subscription: state.schoolInfoReducer.subscription,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedSchool: state.authReducer.connectedSchoolInfo,
    cards: state.authReducer.cards
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    updateProfile,
    getUserById,
    setConnectedUser, getOffer,
    loginUser,
    openModal,
    getCards,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardComponent);


