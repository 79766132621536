import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxUserGuideState } from '../reducers/user-guide-reducer'
import { CallBackProps } from 'react-joyride';
import { generateClassNamesSpotlight, generateClassNamesFloaterOpen } from '../../helpers/user-guide/generate-classnames';
import { returnStartPathUrl } from '../../helpers/domainCheck';

export enum EReduxActionTypes {
    SET_NEXT_STEP = 'SET_NEXT_STEP',
    SET_RESET_STEP = 'SET_RESET_STEP',
    SET_DEFAULT_STEP = 'SET_DEFAULT_STEP',
    SET_START_USER_GUIDE = 'SET_START_USER_GUIDE',
    SET_RELOAD_USER_GUIDE = 'SET_RELOAD_USER_GUIDE',
    SET_START_PROF_USER_GUIDE = 'SET_START_PROF_USER_GUIDE',
    SET_COMPLETE_USER_GUIDE = 'SET_COMPLETE_USER_GUIDE'
}

interface IReduxBaseAction {
   type: EReduxActionTypes;
   data: any
}

export interface IReduxHandleCallbackAction extends IReduxBaseAction {
   type: EReduxActionTypes.SET_NEXT_STEP | EReduxActionTypes.SET_RESET_STEP | EReduxActionTypes.SET_DEFAULT_STEP | EReduxActionTypes.SET_COMPLETE_USER_GUIDE;
   data: any;
}

export interface IReduxHandleStartAction extends IReduxBaseAction {
   type: EReduxActionTypes.SET_START_USER_GUIDE;
   data: any;
}

export interface IReduxHandleReloadAction extends IReduxBaseAction {
   type: EReduxActionTypes.SET_RELOAD_USER_GUIDE;
   data: any;
}

export interface IReduxHandleResetAction extends IReduxBaseAction {
   type: EReduxActionTypes.SET_RESET_STEP;
   data: any;
}

export interface IReduxHandleStartProfUserGuideAction extends IReduxBaseAction {
   type: EReduxActionTypes.SET_START_PROF_USER_GUIDE;
   data: any;
}

export function handleCallbackAction(
  data: CallBackProps,
  isIncrement: number | null,
  historyBrowser: any,
  schoolName: string,
  isMailChecked: boolean,
  roleUser: string
): ThunkAction<Promise<IReduxHandleCallbackAction>, IReduxUserGuideState, undefined, IReduxHandleCallbackAction> {

  const { action, index, lifecycle, type, status } = data;
  
  return async (dispatch: ThunkDispatch<IReduxUserGuideState, undefined, IReduxHandleCallbackAction>) => {
      const checkNext = {
         [index]: (type: string) => (action: string) => {
           if (type === "step:after" || type === 'tour:start') {
            if(action === 'next' || (action === 'start' && index === 8)) {
               
               if(index === 0) historyBrowser.push(`${returnStartPathUrl()}/professors/all`);
               if(index === 2) historyBrowser.push(`${returnStartPathUrl()}/students/all`);
               if(index === 4) historyBrowser.push(`${returnStartPathUrl()}/all-courses`);
               if(index === 7) document.getElementById('go-to-prof-role')?.click();
               if(index === 8 && lifecycle !== 'init') historyBrowser.push(`${returnStartPathUrl()}/courses/my-courses`);
               if(index === 10) historyBrowser.push(`${returnStartPathUrl()}/examsDetails/details`);
               if(index === 12) historyBrowser.push(`${returnStartPathUrl()}/questions/all`);

               return dispatch({
                  type: EReduxActionTypes.SET_NEXT_STEP,
                  data: {
                     run: true,
                     stepIndex: index + 1,
                     isIncrement: index + 1,
                     isOpenCloseUserInfo: index === 6 ? false : true,
                     tourActive: true,
                  }
               });
             } else if (action === 'skip') {
               return dispatch({
                  type: EReduxActionTypes.SET_RESET_STEP,
                  data: {
                     run: false,
                     stepIndex: 0,
                     isIncrement: 0,
                     isOpenCloseUserInfo: true,
                     tourActive: false
                  }
               });
            }
           } 
           if(status === 'paused' && action !== 'close') {
               
               return dispatch({
                  type: EReduxActionTypes.SET_NEXT_STEP,
                  data: {
                     run: true,
                     isOpenCloseUserInfo: index === 6 ? false : true,
                     tourActive: true,
                  }
               });
           }
           if(lifecycle === 'close') {
               return dispatch({
                  type: EReduxActionTypes.SET_RESET_STEP,
                  data: {
                     run: false,
                     stepIndex: 0,
                     isIncrement: 0,
                     isOpenCloseUserInfo: true,
                     tourActive: false
                  }
               });
           }
         },
       };
       checkNext[index](type)(action);

      return dispatch({
         type: EReduxActionTypes.SET_DEFAULT_STEP,
         data: {
            stepIndex: index,
            isIncrement: index,
         }
      });
   }
}

export function handleStartUserGuide(): ThunkAction<Promise<IReduxHandleStartAction>, IReduxUserGuideState, undefined, IReduxHandleStartAction> {
 
    return async (dispatch: ThunkDispatch<IReduxUserGuideState, undefined, IReduxHandleStartAction>) => {
 
       return dispatch({
          type: EReduxActionTypes.SET_START_USER_GUIDE,
          data: {
            run: true,
            tourActive: true,
            startGuide: true
          }
       });
    }
 }


export function handleReloadUserGuide(isRun: boolean): ThunkAction<Promise<IReduxHandleReloadAction>, IReduxUserGuideState, undefined, IReduxHandleReloadAction> {
 
   return async (dispatch: ThunkDispatch<IReduxUserGuideState, undefined, IReduxHandleReloadAction>) => {

      return dispatch({
         type: EReduxActionTypes.SET_RELOAD_USER_GUIDE,
         data: {
            run: isRun,
            isOpenCloseUserInfo: true,
            tourActive: isRun,
         }
      });
   }
}

export function closeUserGuide(): ThunkAction<Promise<IReduxHandleResetAction>, IReduxUserGuideState, undefined, IReduxHandleResetAction> {
 
   return async (dispatch: ThunkDispatch<IReduxUserGuideState, undefined, IReduxHandleResetAction>) => {

      return dispatch({
         type: EReduxActionTypes.SET_RESET_STEP,
         data: {
            run: false,
            tourActive: false,
            startGuide: true
         }
      });
   }
}

export function handleStartProfUserGuide(): ThunkAction<Promise<IReduxHandleStartProfUserGuideAction>, IReduxUserGuideState, undefined, IReduxHandleStartProfUserGuideAction> {
 
   return async (dispatch: ThunkDispatch<IReduxUserGuideState, undefined, IReduxHandleStartProfUserGuideAction>) => {

      return dispatch({
         type: EReduxActionTypes.SET_START_PROF_USER_GUIDE,
         data: {
            stepIndex: 8,
            run: true,
            tourActive: true,
            isOpenCloseUserInfo: true,
            isIncrement: 8
         }
      });
   }
}

