export const checkUploadFileSubtitle = {
    emptyFile: (fileSubtitle, setMsgError) => {
        if (!fileSubtitle)
            setMsgError("Veuillez sélectionner le fichier de sous-titrage");
    },
    emptyLanguage: (language, setMsgError) => {
        if (language === "" || language === "none") setMsgError("Veuillez choisir la langue");
    },
    emptyFileAndLanguage: (language, fileSubtitle, setMsgError) => {
        if (language === "" && !fileSubtitle)
            setMsgError(
                "Veuillez choisir la langue et sélectionner le fichier de sous-titrage"
            );
    },
    incorrectFile: (isIncorrectFile, setMsgError) => {
        if (isIncorrectFile)
            setMsgError(
                "Le format du fichier est incorrecte. Veuillez sélectionner un autre fichier (vtt, srt...)"
            );
    },
    readyToUpload: (fileSubtitle, language, isIncorrectFile, setMsgError) => {
        if (fileSubtitle === null) return;
        if (
            (fileSubtitle.name.split(".")[1] === "vtt" ||
            fileSubtitle.name.split(".")[1] === "srt" ) &&
            language !== "none" &&
            language !== "" &&
            fileSubtitle &&
            !isIncorrectFile
        ) {
            setMsgError("");
            return "ready";
        }
    },
};

export const callCheckHandleMsgError = (fileSubtitle, setMsgError, language, isIncorrectFile) => {
    checkUploadFileSubtitle["emptyFile"](fileSubtitle, setMsgError);
    checkUploadFileSubtitle["emptyLanguage"](language, setMsgError);
    checkUploadFileSubtitle["emptyFileAndLanguage"](language, fileSubtitle, setMsgError);
    checkUploadFileSubtitle["incorrectFile"](isIncorrectFile, setMsgError);
    checkUploadFileSubtitle["readyToUpload"](fileSubtitle, language, isIncorrectFile, setMsgError);
}