import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { publishCourse, saveFormPaymentInfo, affectationProfMailing, clearFormPayment, updateCoursesLists, getOfferCourse, getOfferPricingCourse, updateTraining } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { saveReturnPath, RedirectPopup } from '../../../global-state/actions/breadcrumb-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';
import SelectElement from '../../../elements/select/select-element';
import { valideTraining, publishTraining, createTraining } from '../../../global-state/actions/training-actions';
import { createCourse } from '../../../global-state/actions/course-actions';


import Axios from '../../../helpers/interceptors';

const TransferDirectorTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseInfo, setCourseInfo] = useState<any>();
    const [type, setType] = useState<any>();
    const [defaultValueProfessors, setDefaultValueProfessors] = useState("none");
    const [professors, setProfessors] = useState<any[]>([]);
    const [comment, setComment] = useState<string>("");
    const [training, setTraining] = useState<any>("");
    const [trainingSession, setTrainingSession] = useState<any>("");
    const [check, setCheck] = useState<any>(false);
    const [receiverData, setReceiverData] = useState<any>({ label: "", value: "", id: "", email: "" })
    const [offerCourse, setOfferCourse] = useState<any>({
        id: null,
        idEntity: props?.connectedUser?.idSchool,
        label: props?.courseInfo?.title,
        status: "VALIDE",
        typePricing: "STATIC",

        rules: [
            {
                id: null,
                key: "id_course",
                value: props?.courseInfo?.id,
                valueType: "number",
                code_product: "CRS"
            }
        ],
        paymentEngine: props?.paymentMethod?.paymentEngine,
        pricings: [{
            id: null,
            period: "ONESHOT",
            status: "ACTIVE",
            price: (props?.formPaymentInfo) ? props?.formPaymentInfo?.price : 0,
            currency: props?.paymentMethod?.currency,
            startDate: new Date(),
            endDate: null
        }]
    })


    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.connectedUser?.idSchool}/profs`).then((response: any) => {
            const data = []

            props?.connectedUser?.role === "role_director" && data.push({ label: props?.userInfo?.firstName + " " + props?.userInfo?.lastName + " (Moi même)", value: props?.connectedUser.idPerson, id: props?.connectedUser.idPerson, email: props?.connectedUserInfo?.email })
            response?.data?.forEach((el: any) => {
                data.push({ label: el.name, value: el.id, id: el.id, email: el.email })
            });


            setProfessors(data);
        })
    }, [])


    useEffect(() => {
        setCourseInfo(props?.courseInfo)
    }, [props?.courseInfo]);

    useEffect(() => {
        setTraining(props?.training);

    }, [props?.training])

    useEffect(() => {
        setTrainingSession(props?.trainingSession)
    }, [props?.trainingSession])
    useEffect(() => {
        setType(props?.content?.data?.type);

        const copyOfferCourse = { ...offerCourse };
        switch (props?.content?.data?.type) {
            case 'course':
                copyOfferCourse.label = props?.courseInfo?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idEntity = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_course",
                        value: props?.courseInfo?.id,
                        valueType: "number",
                        code_product: "CRS"
                    }
                ]
                break;
            case 'path':
                copyOfferCourse.label = props?.training?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idEntity = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_path",
                        value: props?.training?.id,
                        valueType: "number",
                        code_product: "PTH"
                    }
                ]
                break;
            case 'session':
                copyOfferCourse.label = props?.trainingSession?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idSchool = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_session",
                        value: props?.trainingSession?.id,
                        valueType: "number",
                        code_product: "SSN"
                    }
                ]
                break;

        }
        setOfferCourse(copyOfferCourse);
    }, [props?.content])
    const validateCours = async (value: string) => {
        let code = getDomainFromUrl()
        let obj = {
            comment: (comment !== "") ? comment : t('nothingTOSay'),
            courseName: (props?.content?.data?.type === "course") ? courseInfo?.title : (props?.content?.data?.type === "path") ? training?.title : trainingSession?.title,
            receiver: receiverData?.email,
            firstName: props?.connectedUserInfo?.firstName,
            gender: props?.connectedUserInfo?.gender,
            code: code,
            lastName: props?.connectedUserInfo?.lastName,
            trainingType: (props?.content?.data?.type === "course") ? courseInfo?.type : (props?.content?.data?.type === "path") ? training?.type : trainingSession?.type,
        }



        await props?.clearFormPayment();

        switch (type) {
            case 'course':
                let filter = {
                    id: props?.content?.data?.id,
                    profId: courseInfo.idProf,
                    creator: courseInfo.creator


                }
                if(filter?.id!=null && filter?.id!==undefined){
                await props?.updateTraining(filter)
                }
                await props?.affectationProfMailing(obj);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToCourse'), lines: ["Formation transférée avec succès"] });
                break;
            case 'path':


                let filterPath = {
                    id: props?.content?.data?.id,
                    profId: training.idProf,
                    creator: training.creator
                }

                if(filterPath?.id!==null && filterPath?.id!==undefined){
                await props?.updateTraining(filterPath)
                }
                await props?.affectationProfMailing(obj);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToPathButton'), lines: ["Formation transférée avec succès"] });


                break;
            case 'session':



                let filterSession = {
                    id: props?.content?.data?.id,
                    profId: trainingSession.idProf,
                    creator: trainingSession.creator

                }
                if(filterSession?.id!==null && filterSession?.id!==undefined){
                await props?.updateTraining(filterSession)
                }
                await props?.affectationProfMailing(obj);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToSessionButton'), lines: ["Formation transférée avec succès"] });

                break;
        }
    }



    useEffect(() => {
        props.getOfferPricingCourse(props?.courseTarificationForm?.id)


    }, []);

    
    const handleChangeProf = (e: any) => {
        setCheck(true)
        setReceiverData(professors.find((item: any) => item.id === e))
        switch (props?.content?.data?.type) {
            case 'course':
                const courseForm: any = { ...courseInfo };
                courseForm['id'] = courseInfo?.id
                courseForm['idProf'] = e;
                courseForm['creator'] = e
                setCourseInfo(courseForm)
                break;
            case 'path':
                const pathForm: any = { ...training };
                pathForm['id'] = training?.id
                pathForm['idProf'] = e;
                pathForm['creator'] = e
                setTraining(pathForm)
                break;
            case 'session':
                const sessionForm: any = { ...trainingSession };
                sessionForm['id'] = trainingSession?.id
                sessionForm['idProf'] = e;
                sessionForm['creator'] = e
                setTrainingSession(sessionForm)
                break;

        }

    }

    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto" style={{ marginRight: 20 }}>
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3 py-2" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-9" style={{ margin: 'auto' }}>
                        <div className="d-flex justify-content-start flex-column mb-4">
                            <span className="H3-Headline black-800 mb-1"> Transférer la formation</span>
                            <span className="H4-Subtitle neutral-3 mb-3"> {(type === 'course') ? courseInfo?.title : (type === 'path') ? training?.title : trainingSession?.title} </span>

                            <span className="body-sm black-800"> Veuillez sélectionner à quel professeur souhaitez-vous transférer cette formation</span>

                        </div>

                        <div className='w-100 mt-2 mb-4'>
                            <p className="form-title ">Transférer la formation à</p>
                            <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueProfessors} options={professors} onChange={(e: any) => {
                                handleChangeProf(e);
                            }} placeholder="Sélectionnez" />

                        </div>

                        <div className="col-md-12 mt-4 input-full">
                            <p className="form-title mb-2">{t('modalPublishCourse_2_2')}</p>
                            <div className="input-text-form-control">
                                <TextareaUnderlineElement type="text" id={"commentReactvateCourse"} value={comment} onChange={(e: string) => { setComment(e) }} placeholder={t('commentSuspendInputProf')} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ border: "none", paddingTop: 0 }}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-7 text-center justify-content-center' style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}>
                        <button type="button" className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large " disabled={!check} onClick={() => { validateCours("PUBLISHED"); }}

                        >Transférer</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            publishCourse,
            updateCoursesLists,
            saveFormPaymentInfo,
            affectationProfMailing,
            clearFormPayment,
            getOfferCourse,
            getOfferPricingCourse,
            saveReturnPath,
            valideTraining,
            publishTraining,
            RedirectPopup,
            createCourse,
            createTraining,
            updateTraining

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(TransferDirectorTrainingModalComponent);