import { ComponentProps, FC } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import RegisterComponent from "../../components/auth-components/login-register-components/register-component";


const RegisterPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    return (<>

        <RegisterComponent />
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);