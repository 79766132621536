import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { UploadVideoCourse } from '../../global-state/actions/course-actions';
import { AppState } from "../../global-state/store/root-reducers";
import { uploadVimeo, clearFormVideo, checkTranscode, clearLoadingVideo, checkTranscodeOnLoad, checkVideoAvalibility } from '../../global-state/actions/upload-video-course-actions';
import InputUploadVideo from "../../elements/inputs/input-upload-video";
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import LoadingUploadVideoModalComponent from "../modals/modal-forms/loading-upload-video-modal-component";
import usePrevious from "../../hooks/usePrevious";
import CircularProgress from '@material-ui/core/CircularProgress';

const UploadVideoCourseComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [videoHeight, setVideoHeight] = useState<any>('')
    const [readyToStream, setReadyToStream] = useState<string>('');
    const [video, setVideo] = useState(props?.idVideo)
    const [file, setFile] = useState(decodeURI(props?.file))
    const [displayMode, setDisplayMode] = useState<string>("upload")

    const { t } = useTranslation();

    useEffect(() => {
        setFile(decodeURI(props?.file))
    }, [props?.file])


    useEffect(() => {
        setVideo(props?.idVideo)
    }, [props?.idVideo])



    useEffect(() => {
        if (props?.file && (props?.file.includes('dai.ly') || props?.file.includes('dailymotion.com'))) {
            setVideoHeight('502px')
        }
        else {
            setVideoHeight('100%')
        }

        const checkData = async () => {
            if (props?.file && props?.file !== '' && !(props?.file && !props?.file.includes('vimeo'))) {
                await props?.checkTranscodeOnLoad(props?.file);
            } else if (!props?.file) {
                setVideo("");
            }
        }
        checkData();

    }, [props?.file])



    useEffect(() => {
        const checkData = async () => {
            if (props?.file && props?.file !== '' && props?.loading && !(props?.file && !props?.file.includes('vimeo'))) {
                await props?.clearLoadingVideo();
                await props?.checkTranscodeOnLoad(props?.file);
            } else {
                if (props?.transcoding && (props?.file !== '' && props?.file === props?.idVideo)) {
                    setTimeout(() => {
                        props?.checkTranscode(props?.idVideo);
                    }, 2000)
                } else if (props?.uploadStatus === 'success') {
                    await props?.uploadVideo(props?.idVideo);
                    await props?.checkTranscode(props?.idVideo);
                }
            }
            setVideo(props?.file);
        }
        checkData();
    }, [props?.file, props?.transcoding, props?.uploadStatus, props?.idUUID]);
    useEffect(() => {
        if (props?.file !== '' && props?.file === props?.idVideo) {
            setReadyToStream(props?.readyToStream);
        }
    }, [props?.readyToStream])
    useEffect(() => {
        if (props?.idVideo !== undefined && props?.idVideo !== "" && !props?.transcoding && readyToStream === 'false') {
            setTimeout(() => {
                props?.checkVideoAvalibility(props?.idVideo, '')
            }, 3000);
        }
        if (props?.readyToStream === 'true') {
            setTimeout(() => {
                props?.checkVideoAvalibility(props?.idVideo, 'terminate');
            }, 3000);
        }
    }, [readyToStream, props?.uuidReadyToDisplay])
    function onDrop(accepted: any) {
        const { createVideoLink, getVideoLink }: any = props;
        props?.isUploading();
        props?.uploadVimeo({ createVideoLink, getVideoLink, checkStopped: false, domain: window.location.host, videoData: accepted[0], size: accepted[0].size });
    };
    const onDelete = (e: any) => {
        props?.deleteFile(e);
        props?.clearFormVideo();
        setReadyToStream("");
        setVideo("");
    }

    let subtitles;

    const refreshSubtitles: any = {
        course: () => subtitles = props.getCourseSubtitleUploaded,
        path: () => subtitles = props.getPathSubtitleUploaded,
        session: () => subtitles = props.getSessionSubtitleUploaded
    }

    refreshSubtitles[props.isLoadingVideoToGetSubtitle.from]()

    const prevAmount: any = usePrevious(subtitles);

    useEffect(() => {
        if (!props.withUpload) return
        if (!prevAmount) return
        if (prevAmount.length !== subtitles.length) {
            setTimeout(() => {
                props.setIsLoadingVideoToGetSubtitle((prev: { from: string, isLoading: boolean }) => ({ ...prev, isLoading: false }))
            }, 2000);
        }
        props.setIsLoadingVideoToGetSubtitle((prev: { from: string, isLoading: boolean }) => ({ ...prev, isLoading: false }))

    }, [props.getCourseSubtitleUploaded, prevAmount])
    return (
        <>
            {/* cours description / parcour(path) / session */}
            <h3 className="H3-Headline mb-3">{t('titleVideoUpload')} {props.titleComponent} ({t('optional')})</h3>
            {(props?.file && props?.file !== null && props?.file !== '') && (readyToStream === 'terminate') ?
                <div className="bg-full-black d-flex align-items-center justify-content-center" style={{ minHeight: '502px', position: 'relative' }}>
                    <button onClick={(e) => onDelete(e)} className="btn-QuickActions white" style={{ position: 'absolute', right: 32, top: 32 }}><span className="material-icons-outlined" color="white">delete</span></button>
                    {props?.isLoadingVideoToGetSubtitle.isLoading ?
                        <div className="container-video-loading">
                            <ReactPlayer />
                            <div className="color-spinner">
                                <CircularProgress />
                            </div>
                        </div> : <ReactPlayer
                            controls={true}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        responsive: true,

                                    },

                                },
                                youtube: {
                                    embedOptions: {
                                        height: '502px',
                                    }
                                },
                                dailymotion: {

                                }
                            }}
                            width='80%'
                            height={videoHeight}
                            stopOnUnmount={false}
                            url={file}
                        />
                    }
                </div> :
                <div>
                    {
                        ((((props?.uploading || props?.transcoding || props?.progress > 0) || (props?.file && props?.file.includes("player.vimeo.com"))) && props?.withUpload)) &&

                        <LoadingUploadVideoModalComponent
                            progress={props.progress}
                            transcoding={props.transcoding}
                            readyToStream={props.readyToStream}
                            uploading={props.uploading}
                        />
                    }

                </div>}
            {(props?.file && props?.file !== null && props?.file !== '') && !props?.file.includes("player.vimeo.com") &&
                <div className="bg-full-black d-flex align-items-center justify-content-center" style={{ minHeight: '502px', position: 'relative' }}>
                    <button onClick={(e) => onDelete(e)} className="btn-QuickActions white" style={{ position: 'absolute', right: 32, top: 32 }}><span className="material-icons-outlined" color="white">delete</span></button>
                    <ReactPlayer
                        controls={true}
                        config={{
                            vimeo: {
                                playerOptions: {
                                    responsive: true,

                                },

                            },
                            youtube: {
                                embedOptions: {
                                    height: '502px',
                                }
                            },
                            dailymotion: {

                            }
                        }}
                        width='80%'
                        height={videoHeight}
                        stopOnUnmount={false}
                        url={file}
                    />
                </div>}
            {(!(props?.file && props?.file !== null && props?.file !== '') && !props?.uploading) && <>
                <InputUploadVideo addFile={(video: any) => onDrop(video)} description={props?.description} textButton2={props?.buttonText2} textButton1={props?.buttonText1} onUrlAdd={props?.onUrlAdd} />
            </>}
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            UploadVideoCourse,
            uploadVimeo,
            clearFormVideo,
            checkTranscode,
            clearLoadingVideo,
            checkTranscodeOnLoad,
            openModal,
            closeModal,
            checkVideoAvalibility
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    uploading: state.uploadReducer.uploading,
    uploadStatus: state.uploadReducer.uploadStatus,
    progress: state.uploadReducer.progress,
    file: ownProps?.file,
    type: ownProps?.type,
    withUpload: ownProps?.withUpload,
    idVideo: state.uploadReducer.idVideo,
    uploadVideo: ownProps?.uploadVideo,
    transcoding: state.uploadReducer.transcoding,
    deleteFile: ownProps?.deleteFile,
    history: ownProps?.history,
    transcodingProgress: state.uploadReducer.transcodingProgress,
    transcodeStatus: state.uploadReducer.transcodeStatus,
    t: ownProps?.t,
    isUploading: ownProps?.isUploading,
    idUUID: state.uploadReducer.id,
    loading: state.uploadReducer.loading,
    readyToStream: state.uploadReducer.readyToDisplay,
    uuidReadyToDisplay: state.uploadReducer.uuidReadyToDisplay,
    getCourseSubtitleUploaded: state.courseReducer.getCourseSubtitleUploaded,
    getChapterSubtitleUploaded: state.uploadChapterReducer.getChapterSubtitleUploaded,
    getSessionSubtitleUploaded: state.trainingReducer.getSessionSubtitleUploaded,
    getPathSubtitleUploaded: state.trainingReducer.getPathSubtitleUploaded,
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoCourseComponent);
