import { FC } from "react";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import ExamDashboardPage from "./exam-dashboard-page";
import FinalExamCorrectionPage from "./final-exam-correction-page";
import openQuestionNotationPage from "./open-question-notation-page";

const ExamsContainer: FC<any> = (props: any) => {
    return (<>
        <div className="page-wrapper-height">
            <CustomSwitch>
                <RouteWithNavbar history={props.history} roles={['role_prof', "role_adminstrator"]} exact={true} path={`${props.match.path}/details`} component={ExamDashboardPage} />
                <RouteWithBreadCrumb exact={true} path={`${props.match.path}/submission/:actualTab/:id`} access={true} component={FinalExamCorrectionPage} />
                <RouteWithBreadCrumb exact={true} path={`${props.match.path}/submission/correction`} access={true} component={openQuestionNotationPage} />

            </CustomSwitch>
        </div>
        <FooterComponent />
    </>
    )
}
export default ExamsContainer;