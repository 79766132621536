import {  FC,  useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
interface Props {
  index: any
  title: any,
  orders: any,
  exams: any,
  duration: any,
  width: any,
  margin: any,
  type:string
}
const ContentChaptersSectionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const openPathDetails = () => {
    setOpen(!open);
  }

  return <>
    <section className="mb-5 pb-3" style={{ maxWidth: 1200, margin: 'auto' }}>

      <div className="d-flex flex-column justify-content-center  px-2" style={{width:props?.width}}>
        <h3 className="H3-Headline black-800 text-center mt-5">{t('courseContentTitle')}</h3>
        <div className={`d-flex align-items-center bg-with-border white pointer py-28 ps-40 pe-4 mt-5 bg-with-border white opened`} >
          <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <span className="H3-Headline mb-6 formation-card horizontal title">{props?.title}</span>
                <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                  <div className="d-flex me-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                      <line x1="5.38184" y1="9.77632" x2="10.6187" y2="9.77632" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                      <line x1="10.6182" y1="6.22368" x2="5.38132" y2="6.22368" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                  </div>
                  <div className="body-sm bold neutral-2">
                    {t(props.type)}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center detail-liste-responsive-actions detail-liste-responsive-session justify-content-end" style={{ minWidth: 200 }}>
              <div className=" ms-2 d-flex flex-row detail-liste-responsive-other progress-training flex-wrap">
                <div className="d-flex align-items-center me-4">
                  <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
                  <span className="body-md bold">{props?.time}</span>
                </div>
              </div>
            </div>
          </div>
          <button type="button" className="btn-QuickActions small me-2" onClick={() => openPathDetails()}>
            {!open ?
              <span className="material-icons-outlined">
                expand_more</span> :
              <span className="material-icons-outlined">
                expand_less</span>
            }
          </button>
        </div>
        <div style={(open) ?
          { height: (58 + (76 * props?.orders?.length)), transition: 'height .5s ease', overflowY: 'hidden' } :
          { height: '0px', transition: 'height .5s ease', overflowY: 'hidden' }}>
          <div className="bg-with-border subliste white p-3 w-100">
            <div className="mx-4 mt-4 d-flex flex-column">
              {props?.orders?.sort((a:any, b:any) => a?.index - b?.index)?.map((obj: any, index: number) => {
                return <>
                  {(!!obj.chapter) &&
                    <div className="d-flex flex-column mb-4">
                      <div className="d-flex flex-row align-items-center mb-2 pb-1">
                        <div className="d-flex me-2">
                          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 1.07141H9.48145V2.7857H16V1.07141Z" fill="#CACBCE" />
                            <path d="M16 5.64276H9.48145V7.35705H16V5.64276Z" fill="#CACBCE" />
                            <path d="M16 10.2143H0V11.9286H16V10.2143Z" fill="#CACBCE" />
                            <path d="M16 14.7856H0V16.4999H16V14.7856Z" fill="#CACBCE" />
                            <path d="M1.18519 7.35708H5.62963C5.94396 7.35708 6.24542 7.23667 6.46768 7.02235C6.68995 6.80802 6.81482 6.51733 6.81482 6.21422V1.6428C6.81482 1.33969 6.68995 1.049 6.46768 0.834674C6.24542 0.620347 5.94396 0.499939 5.62963 0.499939H1.18519C0.870854 0.499939 0.569398 0.620347 0.347133 0.834674C0.124868 1.049 0 1.33969 0 1.6428V6.21422C0 6.51733 0.124868 6.80802 0.347133 7.02235C0.569398 7.23667 0.870854 7.35708 1.18519 7.35708ZM1.77778 2.21422H5.03704V5.6428H1.77778V2.21422Z" fill="#CACBCE" />
                          </svg>
                        </div>
                        <span className="body-sm black-800">{obj?.chapter?.title}</span>
                      </div>
                      
                    </div>}
                </>
              })
              }
     





           
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  orders: ownProps?.orders,
  time: ownProps?.time,
  listTest: state.courseReducer.listTest
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, null)(ContentChaptersSectionComponent);