import { FC, useEffect, useState } from "react";
import { Bar, Line } from 'react-chartjs-2';

import { connect } from "react-redux";
import { getCatalog } from "../../../global-state/actions/catalog-duplicaction-actions";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { getTimes } from  "../../../global-state/actions/activity-actions";
import { fetchData } from "../../../base/functions/Functions";
import { getStatisticsCertificationApi, getTimesApi } from "../../../services/activity.service";
interface Props {
    courseList: any[]
    courseFormId:any,
    date:any
  }

const InscriptionChart: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>& Props> = (props) => {
    const { t } = useTranslation();
    const [subscription, setSubscription] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [Certification, setCertification] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [months, setMonths] = useState([])
    const [times, setTimes] = useState<any>()

    useEffect(() => {

        let currentDate = new Date()
        let nbrMonth = currentDate.getMonth()
        
        let labels :any= [
            t('Jan'),
            t('Fev'),
            t('Mar'),
            t('Avr'),
            t('May'),
            t('Jui'),
            t('Jul'),
            t('Aug'),
            t('Sep'),
            t('Oct'),
            t('Nov'),
            t('Dec')
        ]
        if(props?.date === currentDate.getFullYear()){
            setMonths(labels.slice(0, nbrMonth+1))


        }else{
            setMonths(labels)
        }
    }, [])

    const getTimes = async () =>{
        const result = await getTimesApi()
        setTimes(result)
    }

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) { getTimes(); }


    }, [])

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) {
                    props?.getYears(times?.visit)
        }
    }, [times]);

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) {
            getStatisticsCertification(props?.selectedValue, [props?.courseFormId])

        }
    }, [props?.courseFormId, props?.selectedValue]);

    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0 && props?.courseFormId === undefined) {
            getTimes();
            
        }
    }, [props?.courseList]);
    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0 && props?.courseFormId === undefined)
       { props?.getYears(times?.visit)}

    }, [times]);

    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0 && !!props?.selectedValue && props?.courseFormId === undefined){
            getStatisticsCertification(props?.selectedValue, props?.courseList)

        }
    }, [ props?.courseList,props?.selectedValue]);

    const getStatisticsCertification = async (year: any, list: any)=>{
        const result = await getStatisticsCertificationApi(year,list)
        setSubscription(result?.subscription)
        setCertification(result?.certification)
    }


    const data = {
        labels: months,
        datasets: [
            {
                label: t('currentStudentNumber'),
                data:(subscription==undefined?[]:subscription),
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                backgroundColor: "#8A57CC",
                borderColor: "#8A57CC",
                borderWidth: 1,
                yAxisID: "leftAxe"
            },
            
            {
                label: t('currentStudentsWithDiploma'),
                data: (Certification==undefined?[]:Certification),
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                backgroundColor: "#9DDBA9",
                borderColor: "#9DDBA9",
                borderWidth: 1,
                yAxisID: "rightAxe"
            }
        ]
    }


    return (

        <div style={{pointerEvents:"none"}}>

            <Bar options={{
                legend: {
                    display: true,
                    labels: {
                        fontColor: '#333',
                        usePointStyle: true,
                        
                    }
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false
                    }
                }, scales: {
                    xAxes: [{
                        offset: true,
                        gridLines: {
                            display: true,
                            drawOnChartArea: false
                        },

                    }],
                    yAxes: [{
                        id: "leftAxe",
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 2,
                        },
                        offset: false,
                        scaleLabel: {
                            display: true,
                            labelString: t('currentStudentNumber'),
                            
                        },
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            color: "#8A57CC"
                        }
                    }, 
                    {
                        id: "rightAxe",
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 1,
                        },
                        offset: false,
                        position: "right",
                        scaleLabel: {
                            display: true,
                            labelString: t('currentStudentsWithDiploma')
                        },
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            color: "#9DDBA9"
                        }
                    }
                ]

                }
            }} data={data} />
        </div>)
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courses: state.catalogReducer.courses,
    courseForm: state.courseReducer.courseForm,
    user: state.authReducer.connectedUser,
    getYears: ownProps?.getYears,
    selectedValue: ownProps?.selectedValue,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getCatalog,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InscriptionChart);