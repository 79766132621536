import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import QuestionCard from '../questions/question-card/question-card-professor';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
interface Props{
  questionList:any,
  what:string,
  doSearch:boolean,
}
export default function ListCardQuestionProfessor(props: any) {
  const { t } = useTranslation();
  const [posts, setPost]= useState(props?.questionList)
  
  useEffect(()=>{
    setPost(props?.questionList)
  }, [props?.questionList]) 

  function noQuestionMessageFn(doSearch:any,actualTab:any){
    if(doSearch===false){
      if(actualTab==="all"){
        return <div className="col-md-12  mt-5 d-flex justify-content-end text-right px-0">
            <div className="w-100 text-center mt-5 pt-5" ><EmptyListElement lines={[t('noQuestionFound_5')]}/></div>
          </div>
      } 
      else if(actualTab==="settled"){
        return <div className="col-md-12  mt-5 d-flex justify-content-end text-right px-0">
          <div className="w-100 text-center mt-5 pt-5" ><EmptyListElement lines={[t('noQuestionFound_4')]}/></div>
          </div>
       
        
      }
      else if(actualTab==="unsettled"){
        return <div className="col-md-12  mt-5 d-flex justify-content-end text-right px-0">
          <div className="w-100 text-center mt-5 pt-5" ><EmptyListElement lines={[t('noQuestionFound')]}/></div>
        </div>
        
      }

    }else if(doSearch!==false){
      return <div className="w-100 text-center mt-5 pt-5" ><EmptyListElement lines={[t('noQuestionFound_2'),t('noQuestionFound_3')]}/></div>
    }
  }

  

  return (
    <>
      <div>
        { posts?.length===0  && noQuestionMessageFn(props?.doSearch,props?.what)}
        {posts?.map((post:any) => {
             return <div key={post.id}><QuestionCard key={post.id} t={t}  post= {post} what={props?.what}/> <hr className="neutral-5 my-4" style={{opacity:1}}/></div>
             })}
      </div>
 </>

    )
}

