import { ComponentProps, FC } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { openModal } from '../../global-state/actions/modal-actions';
import { getStudentInfoById } from '../../global-state/actions/course-actions';
import koolog from '../../styles/images/koolog.png';
const HeaderSondageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    return (
        <>
            <div style={{ backgroundColor: '#272727' }}>
                <div className="px-4 mx-auto py-3" style={{ maxWidth: '987.5px' }}>
                    <div className="d-flex flex-row justify-content-between my-4">
                        <div className="d-flex align-items-center">
                            <div >
                                <img style={{ borderRadius: "50%", width: "120px", height: "120px", objectFit: 'cover' }} src={(props?.schoolInfo?.logo) ? props?.schoolInfo?.logo : koolog} />
                            </div>
                            <div className="ms-4 ps-3" >
                                <div className="d-flex flex-column">
                                    <span className="body-xl bold " style={{ color: "#FFFFFF" }}>{props?.schoolInfo?.name}</span>
                                    <span  className="H3-Headline" style={{ color: "#FFFFFF" }} >{props?.examToPass?.title}</span>
                                    <span className="body-md" style={{ color: "#FFFFFF", maxWidth:792 }}>{props?.examToPass?.description}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    student: state.courseReducer.studentInfo,
    connectionUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    content: state.modalReducer.modalContent,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    examToPass:state.examReducer.examToPass

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        openModal, getStudentInfoById
    }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSondageComponent);

