
import { FC, useMemo } from "react";
import {  useLocation } from "react-router";
import NavbarSessionComponent from "../../../../components/navigation-components/navbar-session-component";
import PrivateRoute from "../../../../navigations/PrivateRoute";
import SessionContentPage from "./session-content-page";
import SessionDescriptionPage from "./session-description-page";
import StudentsSessionPage from "./students-session-page";
import AdmissionSessionPage from './admission-session-page';
import CustomSwitch from "../../../../helpers/custom-switch";
interface Params {
    id: any
}

const SessionCreationContainer: FC<any> = (props: any) => {
  const location=useLocation()
    const activeTab:string=useMemo(()=>{
      return  (location.pathname.split("/").pop() as string);
  },[location?.pathname])
  return (<>
    <NavbarSessionComponent activeTab={activeTab} />
      <CustomSwitch>
         <PrivateRoute exact={true} path={`${props?.match.path}/description`} component={SessionDescriptionPage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/content`} component={SessionContentPage} /> 
         <PrivateRoute exact={true} path={`${props?.match.path}/inscription`} component={AdmissionSessionPage} />
         <PrivateRoute exact={true} path={`${props?.match.path}/students`} component={StudentsSessionPage} />
      </CustomSwitch>
  </>
  )
}
export default SessionCreationContainer;