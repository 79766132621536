import { fetchData } from "../base/functions/Functions";
import { studentTrainingDetailsBody } from "../data-structures/interfaces/trainings.interfaces";
import axiosInstance from "../helpers/interceptors";

export const getNumberOfCourses = async (filter:any) => {

    let baseURL = process.env.REACT_APP_BASE_URL3;
    const data = await axiosInstance
      .post(baseURL + "follow/getNumberofTraining", filter)
      .then((response) => {
        return response.data;
      });
    return data;
  };
  

export const getFollowedTrainings = async (payload: any): Promise<studentTrainingDetailsBody[]> => {
  try {
    let fetchedTrainings: studentTrainingDetailsBody[] = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}training/getStudentsFollowedTrainings`, payload)
    return fetchedTrainings;
  } catch (e) {
    console.error("error while fetching when getting list of followed trainings ", e);
    return []
  }
}

export const getStudentsPerSchoolId = async (id:any,page:number,size: number): Promise<any[]> => {
  try {
    let students: any[] = await fetchData('GET', `${process.env.REACT_APP_BASE_URL2}person/getStudentsPerSchoolId/${id}?page=${page}&size=${size}`, {})
    return students;
  } catch (e) {
    console.error("error while fetching when getting list of students per school id ", e);
    return []
  }
}