import { ComponentProps, FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCoursesForDirector, changeTabAllCourses, findGroups,clearFormCourse, getCoursesLength, getCoursesOtherLength,getCoursesPendingLength,getOtherCoursesForDirector, getCoursesPublishedLength, getPendingCoursesForDirector, getOnlineCoursesForDirector, clearTarification } from '../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions'
import TableAllCoursesComponent from '../../components/tables/table-all-courses-component';
import TablePendingComponent from '../../components/tables/table-pending-courses-component';
import TableOnlineComponent from '../../components/tables/table-online-courses-component';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import TableAllCoursesLoadingComponent from '../../components/tables/table-all-courses-loading-component';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import HtmlTooltip from '../../elements/tooltip/Tooltip-Customized-element';
import { getAllProfessorsByIdSchool } from '../../global-state/actions/finance-actions';
import { saveActionHistory } from '../../services/history.service';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import TableOtherComponent from '../../components/tables/table-other-courses-component';
import { getCountsApi } from '../../services/finance.service';
const AllCoursesPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [tab, setTab] = useState('all');
  const [courses, setCourses] = useState(props?.allCourses);
  const [pendingCourses, setPendingCourses] = useState(props?.pendingCourses);
  const [size, setSize] = useState(props?.lengthCourses);
  const [sizePending, setSizePending] = useState(props?.LengthCoursesPending);
  const [sizePublshed, setSizePublshed] = useState(props?.LengthCoursesPublished);
  const [sizeOther,setSizeOther]=useState(props?.LengthCoursesOther)
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState<any>()
  const history = useHistory();
  const [groups,setGroups]=useState<any>()

  const getProfiles = async (idEntity: number) =>{
    const result = await getCountsApi(idEntity)
    setProfiles(result)
  }

  useEffect(() => {
    getProfiles(props?.schoolInfo?.id)
    props?.clearTarification();
    props?.getAllProfessorsByIdSchool(props?.user?.idSchool)
    props?.clearHistoryArray();
    let filter = {
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
      pageSize: 12,
      pageNumber: 0,
    }
    let filterPending = {
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
      pageSize: 12,
      pageNumber: 0,
      status: "PENDING"
    }
    let filterOnline = {
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
      pageSize: 12,
      pageNumber: 0,
      status: "PUBLISHED"
    }
    let filterOther = {
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
      pageSize: 12,
      pageNumber: 0,
    }

    props?.getCoursesLength(filter);
    props?.getCoursesPendingLength(filter);
    props?.getCoursesOtherLength(filter);
    props?.getCoursesPublishedLength(filter);
    props?.getCoursesForDirector(filter, 'yes');
    props?.getPendingCoursesForDirector(filterPending, 'yes');
    props?.getOtherCoursesForDirector(filterOther, 'yes');
    props?.getOnlineCoursesForDirector(filterOnline, 'yes');
    props?.findGroups(props?.user?.idSchool);
  }, []);
  useEffect(()=>{setGroups(props?.groups)}
  ,[props?.groups])
  useEffect(() => {
    setSize(props?.lengthCourses);
  }, [props?.lengthCourses])

  useEffect(() => {
    setSizePending(props?.LengthCoursesPending);
  }, [props?.LengthCoursesPending])
  useEffect(() => {
    setSizeOther(props?.LengthCoursesOther);
  }, [props?.LengthCoursesOther])

  useEffect(() => {
    setSizePublshed(props?.LengthCoursesPublished);
  }, [props?.LengthCoursesPublished])

  useEffect(() => {
    setCourses(props?.allCourses);
  }, [props?.allCourses])

  useEffect(() => {
    setPendingCourses(props?.pendingCourses);
  }, [props?.pendingCourses])

  useEffect(() => {
    setTab(props?.actualTabAllCourses);

  }, [props?.actualTabAllCourses]);

  useEffect(() => {
    setLoading(props?.loadingAllCourses)
  }, [props?.allCourses]);

  const createTraining = async () => {
    await props?.clearFormCourse();
    props?.redirect(t('createNewFormation'), `${returnStartPathUrl()}/courses/create-course/choice`)
    history.push(`${returnStartPathUrl()}/courses/create-formation/choice`);
  }

  const goTo = async () => {
    props?.clearHistoryArray()
    props?.redirect(t("offersList"),"")
    await saveActionHistory("koors_upgrade_training", props?.user?.idPerson, props?.user?.idSchool)
    history.push(`${returnStartPathUrl()}/list`);
  }


  return (
    <>
      <div className="container-fluid">
        <span id='step-training-page_director' style={{ position: 'absolute' }}></span>
        <div className="row justify-content-center">
          <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
              <div className="col-md-12 mt-5" >
                {loading ?
                  <Skeleton className="skeleton me-3" animation="wave" variant="rect" width={260} height={50} /> :
                  <div className="d-flex align-items-center mb-3 " style={{ justifyContent: "space-between" }}>
                    <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left' }}>
                      {t('TitleFormations')}

                      <span className="ms-3" style={{ color: "#272727" }}>
                        {size && (size < 10) ? "0" + size : size}
                      </span>
                      {
                        (!!profiles &&
                          profiles?.find((profile: any) => profile.key === "nb_cours")?.valueSchool >= profiles?.find((profile: any) => profile.key == "nb_cours")?.valueContract &&
                          profiles?.find((profile: any) => profile.key === "nb_cours")?.valueContract > 0) &&
                        <HtmlTooltip interactive={true}
                          content={<div className='d-flex flex-column'>
                            <div className='content-tooltip'>
                              {t('atteinedMaximum')} {!!props?.offer && props?.offer?.label}. {t('updatePlan')}
                            </div>
                            <div className='w-100 d-flex justify-content-end link-tooltip'>
                              <button className='btn-text outlined body-sm' onClick={() => goTo()}>{t('Voirlesforfaits')}</button>
                            </div>
                          </div>}>
                          <span className="material-icons-outlined align-items-center" style={{ color: "#F5B95F", cursor: "pointer", fontSize: 26, position: 'static', width: 32, height: 32 }}>error</span>
                        </HtmlTooltip >
                      }
                    </h1>

                    {props?.user.role === "role_adminstrator" &&
                      <button className="btn-Primary large icon-left" onClick={() => createTraining()}>
                        <span className="material-icons">add</span>
                        <div className="m-auto ps-2">{t('createNewFormation')}</div>
                      </button>}
                  </div>
                }

              </div>
            </div>
            {props?.user.role === "role_director" && <div className="d-flex align-items-center flex-wrap px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}  >
              {loading ? <>
                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
              </> :
                <>
                  <button onClick={() => props?.changeTabAllCourses('all')} className={`btn-Tabs ${(tab === 'all') ? '' : 'unselected'} me-3 d-flex align-items-center`}>
                    {t('allFormations')} ({size})
                  </button>
                  <button onClick={() => props?.changeTabAllCourses('pending')} className={`btn-Tabs ${(tab === 'pending') ? '' : 'unselected'} me-3 d-flex align-items-center`}>
                    {t('validateFormations')} ({sizePending})
                  </button>
                  <button onClick={() => props?.changeTabAllCourses('online')} className={`btn-Tabs ${(tab === 'online') ? '' : 'unselected'}  me-3  d-flex align-items-center`}>
                    {t('onlineFormations')} ({sizePublshed})
                  </button>

                  {!!groups && groups?.length>0 &&     <button onClick={() => props?.changeTabAllCourses('other')} className={`btn-Tabs ${(tab === 'other') ? '' : 'unselected'} d-flex align-items-center`}>
                  Autres formations ({sizeOther})
                  </button>}
              
                </>
              }
            </div>}
          </div>
        </div >
      </div>
      <div className="mx-auto" style={{ maxWidth: '1200px' }}>
        {loading && (props?.user?.role === "role_director" || props?.user?.role === "role_adminstrator") ?
          <TableAllCoursesLoadingComponent />
          :
          <>
            {props?.actualTabAllCourses === 'all' &&
              <TableAllCoursesComponent />}

            {props?.actualTabAllCourses === 'pending' &&
              <TablePendingComponent />}

            {props?.actualTabAllCourses === 'online' &&
              <TableOnlineComponent />}


            {props?.actualTabAllCourses === 'other' &&
              <TableOtherComponent />}
          </>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  allCourses: state.courseReducer.allCourses,
  pendingCourses: state.courseReducer.pendingCourses,
  lengthCourses: state.courseReducer.LengthCourses,
  user: state.authReducer.connectedUser,
  actualTabAllCourses: state.courseReducer.actualTabAllCourses,
  loadingAllCourses: state.courseReducer.loadingAllCourses,
  pageNumber: state.courseReducer.pageNumber,
  LengthCourses: state.courseReducer.LengthCourses,
  LengthCoursesPending: state.courseReducer.LengthCoursesPending,
  LengthCoursesOther: state.courseReducer.LengthCoursesOther,
  LengthCoursesPublished: state.courseReducer.LengthCoursesPublished,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  offer: state.schoolInfoReducer.offer,
  groups:state.courseReducer.groups
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      findGroups,
      getCoursesLength,
      getOtherCoursesForDirector,
      getOnlineCoursesForDirector,
      getCoursesPendingLength,
      getCoursesOtherLength,
      getCoursesPublishedLength,
      getCoursesForDirector,
      getPendingCoursesForDirector,
      changeTabAllCourses,
      clearFormCourse,
      getAllProfessorsByIdSchool,
      clearTarification,
      redirect, clearHistoryArray
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllCoursesPage);
