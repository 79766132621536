import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';

import { ModalIDS } from '../modal-ids';
import { getTeam, suspendTeamMember } from '../../../global-state/actions/professor-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';



const DeactivateAssistantModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState("")

    const [assistant, setAssistant] = useState(props?.content?.data?.assistant)

    useEffect(() => {
        setAssistant(props?.content?.data?.assistant)
    }, [props?.content])
    const affectOtherProfButton = async () => {
        let code = getDomainFromUrl()
        let object = {
            comment: comment,
            code: code,
            idSchool: props?.connectionUser.idSchool,

        }        
        await props?.suspendTeamMember(assistant?.id,null , props?.connectionUser?.idPerson, object, "ADMINSTRATOR")
        
        setComment('');
        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", path: "", buttonText: t('returnToProfProfile'), lines: [t('successSuspendAssitant')] });
    }

    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-9 p-0" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 ">
                                <span className="H3-Headline">{t('SuspendProf')} </span>
                                <br />
                                <span className="H4-Subtitle bold neutral-3"> {assistant?.name} </span>

                            </div>
                            <div className="col-md-12 mt-5 ">
                                <span className="body-sm">{t('disableAssistant')}</span>
                            </div>

                            <div className="col-md-12 mt-5 input-full">
                                <label className="form-title">{t('addCommentToAssistant')}</label>


                                <div className="col-md-12 mt-3 ">
                                    <textarea style={{  maxHeight:'75px',fontSize: "16px" }} rows={1} value={comment} onChange={(e) => { setComment(e.target.value) }} id="professorDetails" className="textaria-textField" placeholder={t('commentSuspendInputProf')} ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer p-0" style={{ paddingTop: 0, border: "none" }}>
                <div className="row mt-3 mb-5 w-100">
                    <div className='col-md-9 m-auto p-0 text-center justify-content-end buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large" onClick={() => { setComment(''); props?.closeModal() }} style={{ textTransform: 'uppercase', marginRight: '2%' }} >{t('annuler')}</button>
                        <button type="button" className="btn-Primary large danger" onClick={() => { affectOtherProfButton(); }} disabled={(comment === '')}>{t('SuspendProf')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    assistants: state.profReducer.assistants,
    teamPage: state.profReducer.teamPage,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            suspendTeamMember,
            getTeam
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateAssistantModalComponent);