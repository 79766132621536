import { useEffect, useState } from "react";

  export default function TextareaCustomparagraphCommentElement(props: any) {
    const [text, setText] = useState('');
    
    useEffect(() => {
        setText(props?.value);
      }, [props?.value]);

      useEffect(() => {
        const b: any = document.getElementById(`textArea${props?.id}`);
        if (b.scrollHeight !== 0) {
          b.style.height = "1px";
          b.style.height = (1 + b.scrollHeight) + "px";
        }
      }, [text])
    const changeInputValue = (value:string) => {
      const b: any = document.getElementById(`textArea${props?.id}`);
      if (b.scrollHeight !== 0) {
        b.style.height = "1px";
        b.style.height = (1 + b.scrollHeight) + "px";
      }
      setText(value);
      
      props?.onChange(value);
    }
    return (
      <>
        <textarea id={`textArea${props?.id}`} value={text} className="input-comments" rows={1} style={{ resize: "none" }} onChange={(e) => { changeInputValue(e.target.value); }}  placeholder={props?.placeholder}></textarea>
      </>
    );
  }
    