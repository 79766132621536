import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import ResponseQuestionElement from './question-element-component'

const ResponseOpenQuestion: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [displayCount, setDisplayCount] = useState(3);
    const handleSeeMore = () => {
        setDisplayCount((prevCount) => prevCount + 3);
    };

    return (

        <div style={{ marginBottom: "20px" }}>
            <div className="d-flex flex-column" style={{ width: "724px", border: "1px solid #EBECEE", padding: "20px" }}>
                <span className="H3-Headline neutral-3" style={{ lineHeight: '43px' }}>Question
                    <span style={{ color: "black" }}> {props?.index}*</span>
                </span>
                <span className="H4-Subtitle ">
                    {props?.response?.questionStatement}
                </span>
            </div>
            <div className="d-flex flex-column" style={{ width: "724px", border: "1px solid #EBECEE", padding: "20px" }}>

                <span className="body-md " style={{ marginBottom: "22px" }}>
                Réponse(s) obtenue(s): {props?.response?.studentAnswerList?.length}
                </span>

                {props?.response?.studentAnswerList?.length != 0 ? <div >
                    {props?.response?.studentAnswerList?.slice(0, displayCount).map((response: any, index: any) => (
                        <ResponseQuestionElement response={response?.studentOpenQuestionSubmission} key={index} date={response?.creationDate} />
                    ))}

                    {displayCount < props?.response?.studentAnswerList?.length && (
                        <div className=' d-flex justify-content-center'>
                            <button
                                type="submit"
                                className="btn-text outlined  "
                                onClick={handleSeeMore}
                            >
                                Voir plus
                            </button>
                        </div>


                    )}
                </div> :
                    <div>
                        <div style={{ marginBottom: "16px" }}>
                            <div className="d-flex flex-row justify-content-between" style={{ width: "686px", border: "1px solid #EBECEE", padding: "20px" }}>
                                <div className="d-flex flex-column" >
                                    <span className="body-sm" style={{ width: "492px", marginBottom: "12px" }} >
                                        Il n'y a actuellement aucune réponse à cette question.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
            </div>
        </div>


    );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseOpenQuestion);
