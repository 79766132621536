import { FC } from "react";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import ActivitiesCardPage from "./activities-card-page";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
const ActivityContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height">
      <CustomSwitch>
        <RouteWithNavbar roles={['role_prof', 'role_director', "role_adminstrator"]} history={props?.history} path={`${props?.match?.path}`} exact={true} component={ActivitiesCardPage} />
      </CustomSwitch>
    </div>
    <FooterComponent />
  </>
  )
}
export default ActivityContainer;