import axiosInstance from "../helpers/interceptors";

const createPayment = async (data: any) => {
    const dataResponse = await axiosInstance.post(
     process.env.REACT_APP_BASE_URL14 + "person/createPayment", data
   ).then((res) => {    
     return res.data;
   });
   return dataResponse;
  }

  const upgradeSubscription = async (data: any) => {
    const dataResponse = await axiosInstance.post(
     process.env.REACT_APP_BASE_URL14 + "person/upgradeSubscription", data
   ).then((res) => {    
     return res.data;
   });
   return dataResponse;
  }

  const upgradeContract = async (data: any) => {
    const dataResponse = await axiosInstance.post(
     process.env.REACT_APP_BASE_URL14 + "person/upgradeContract", data
   ).then((res) => {    
     return res.data;
   });
   return dataResponse;
  }

  export { createPayment , upgradeSubscription , upgradeContract };