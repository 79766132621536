import { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
const LargeSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '12px 26px 12px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);

const MeduimSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '6px 26px 6px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
    width: '100%'
  },
}));

export default function SelectsMultiple(props: any) {
  const classes = useStyles();
  const [liste, setListe] = useState([]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setListe(props?.defaultValue);
  }, [props?.defaultValue])
  useEffect(() => {
    setOptions(props?.options);
  }, [props?.options])
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    let copyList = [...liste];
    copyList = typeof value === 'string' ? value.split(',') : value;
    setListe(copyList);
    props?.onChange(copyList)
  };
  return (
    <div>
      <FormControl className={classes?.margin}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          IconComponent={ExpandMoreOutlined}
          value={liste}
          defaultValue={liste}
          onChange={(e) => handleChange(e)}
          input={(props?.taille === 'lg') ? <LargeSelect /> : <MeduimSelect />}
          className={classes?.margin}
          renderValue={((selected: any) => {
            if (liste.length === 0) {
              return <span className="select-placeholder">{props?.placeholder}</span>;
            }
            return selected.join(', ')
          })}
        > 
            <MenuItem className="select-placeholder" hidden={true} value={"none"}></MenuItem>
          
          {options?.map((option: any) => <MenuItem key={option?.value} className="select-placeholder" value={option?.value}>
            <Checkbox color="default" checked={liste.findIndex(opt => opt === option?.value) > -1} />
            <ListItemText primary={option?.label} />
          </MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
}