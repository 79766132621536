import { ReactNode } from "react";

interface IPropsModalUserGuide {
  icon?: string;
  title: string;
  body: () => ReactNode;
  steps: () => ReactNode;
}

const StepUserGuide = ({ icon, title, body, steps }: IPropsModalUserGuide) => {

  const getIdLastStep = document.getElementById("react-joyride-step-14") 

  return (
    <div className="text-align issam">
      <div className="H4-Subtitle mb-15">
        {title} <img src={icon} alt="" />
      </div>
      <div>{body()}</div>
      <div className={`count-of-steps ${getIdLastStep ? 'count-of-steps--center' : ''}`}>
      {steps()}
      </div>
    </div>
  );
};

export default StepUserGuide;
