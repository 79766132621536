import { ComponentProps, FC, useEffect, useMemo } from "react"
import StudentListCardComponent from "../../components/list-cards-courses/student-list-card-component";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import ProfessorListCardComponent from "../../components/list-cards-courses/professor-list-card-component";
import { clearHistory } from '../../global-state/actions/breadcrumb-actions';
import { clearCurrentCourse, clearIndexProgress } from "../../global-state/actions/student-training-actions";

const CoursesListCardPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const user = useMemo(() => {return props?.connectedUser}, [props?.connectedUser] );
    useEffect(() => {
        onInit()
    }, [])
    const onInit = async ()=>{
        await props?.clearHistory();
        await props?.clearCurrentCourse()
        await props?.clearIndexProgress()

    }
    return (<>
        {user?.role.includes("role_student") ?
            <>
                <StudentListCardComponent userInfo={props?.connectedUser}/>
            </>
            :
            <>
                <ProfessorListCardComponent />
            </>
        }
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearHistory, clearCurrentCourse, clearIndexProgress}, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(CoursesListCardPage);