import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import "../../translations/i18n";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';


const DisplayClosedQuestionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const answer = useMemo(() => { return props?.answer }, [props?.answer]);
    const question = useMemo(() => {  return props?.question }, [props?.question]);
    const [index] = useState(props?.index)

    return (
        <div>
            <div className="d-flex flex-column" style={(props?.color) && { backgroundColor: 'white' }}>
                <div style={props.isMobile && {borderBottom: "1px solid #EDEDED", borderTop: "1px solid #EDEDED"}} className={props.isMobile ? "py-4 mb-4 px-4" : "d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40"}>
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                        <div className="d-flex flex-column" >
                            <div className="d-flex flex-row flex-wrap align-items-center mb-2">
                                <span className="H3-Headline  me-2 pe-1"><span className="H3-Headline neutral-3">{t('Question')} </span>{index + 1}</span>
                                <span className="body-sm bold"><span className="body-sm bold neutral-3">{t('singleClosedQuestion')} <span className={`badge-text ${(answer?.questionMark === 0) ? 'danger' : 'success'}`}>{answer?.questionMark}</span>/{question?.scaleNote} {t('pointsQuestion')}</span></span>
                            </div>
                            <span className="H4-Subtitle bold ">{question?.question?.questionStatement}</span>
                        </div>
                    </div>
                </div>

                <div className={props.isMobile ? "px-4": "bg-accordion-border accordion-body pb-3"}>
                    <div className="d-flex flex-column">
                        {(answer?.questionMark === 0) && <>
                            {answer?.responseQuestionChoice?.filter((obj: any) => obj?.responseChoiceState === true)?.map((response: any, indexQuestionResp: number) => {
                                return <div key={response?.id} className="d-flex flex-row mb-4">
                                    <div className="badge-square danger me-4">
                                        <span className="material-icons-outlined">close</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="form-title mb-2">{t('answer')}</span>
                                        <span className="body-md neutral-2">{response?.responseChoice} </span>
                                    </div>
                                </div>
                            })}
                        </>}
                        {question?.question?.questionChoice?.filter((obj: any) => obj?.choiceState === true)?.map((response: any, indexQuestion: number) => {
                            return <div key={response?.id} className="d-flex flex-row mb-4">
                                <div className="badge-square success me-4">
                                    <span className="material-icons-outlined">check</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="form-title mb-2">{(answer?.questionMark === 0) ? "Bonne réponse" : "Votre réponse"}</span>
                                    <span className="body-md neutral-2">{response?.choice} </span>
                                </div>
                            </div>
                        })}


                        <div className="d-flex flex-row mb-4">
                            <div className="badge-square info me-4">
                                <span className="material-icons-outlined">info</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="form-title mb-2">{t('Explication')}</span>
                                <span className="body-md neutral-2">{(question?.question?.questionAnswerExplanation === '') ? "Pas d'explication" : question?.question?.questionAnswerExplanation} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {


        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    question: ownProps?.question,
    answer: ownProps?.answer,
    color: ownProps?.color,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    exam: state.examReducer.examToPass,
    studentAnswers: state.examReducer.listOfResponses,
    sectionContent: state.studentTraining.sectionContentDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayClosedQuestionComponent);
