import { useHistory, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker = (props: any) => {
    const history = useHistory();
    history.listen((locationPath, action) => {
        if(locationPath?.pathname !== props?.location?.pathname) {
            window.scrollTo(0, 0) 
        }
  }) 
    const pathname = props?.location?.pathname;
    const pageView = pathname;
    ReactGA.set({ page: pageView });
    ReactGA.pageview(pageView);
    return <div></div>;
};

export default withRouter(RouteChangeTracker);