import { Dayjs } from "dayjs";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FC } from "react";
interface Props {
  label: any,
  spanClasses: any,
  required: any,
  classes: any,
  onChange: any,
  dateValue: any,
  status: any,
  timeValue: any

}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
   
  },

  input: {
    fontFamily:  '"Public", sans-serif',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 24,
  }
}));
const TimePickerElement: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const checkSpanClassesIfExist = (spanClasses: string | undefined): string => spanClasses || "label";
  const check = (date:any) => {
    
    props?.onChange(String((date as any)?.target?.value));
  };
  return (
    <input type="time" className="form-control input input-time text-default" onChange={check} value={props?.timeValue}/>

   

  );
};
export default TimePickerElement;
