import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';

const CheckFormCourseModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseForm, setCourseForm] = useState<any>();
    const [errors, setErrors] = useState<any>();
    useEffect(() => {
        setCourseForm(props?.courseForm);
        setErrors(props?.modalContent?.data?.errors);
    }, [props?.modalContent]);
    return (
        <>
            <div className="d-flex w-100 align-items-center justify-content-end pt-3 pe-3">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
            <div className="d-flex justify-content-center" style={{ paddingTop: 0, marginLeft: '10px' }}>
                <div className="col-md-10 text-left">                   
                        <h3 className="H3-Headline black-800 " style={{ textAlign: 'left' }}>{t('continueCreatingCourse')}</h3>
                        <h3 className="H4-Subtitle neutral-3 mb-4">{courseForm?.title} </h3>
                        {(errors && errors.titleError && errors.titleError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.titleError}</p>}
                        {(errors && errors.linkImgError && errors.linkImgError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.linkImgError}</p>}
                        {(errors && errors.descriptionError && errors.descriptionError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.descriptionError}</p>}
                        {(errors && errors.chaptersError && errors.chaptersError !== '') && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.chaptersError}</p>}                        

                </div>
            </div>
            <div className="d-flex mt-3 mb-5 me-5 pe-3 justify-content-end" >
                <button type="button" onClick={() => props?.closeModal()} className="btn-Primary large">{t('returnToCourse')}</button>
            </div>
        </>

    )

}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal,
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    courseForm: state.courseReducer.courseForm,
    modalContent: state.modalReducer.modalContent
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckFormCourseModalComponent);