export const displayTagSubscription = (trainingOffer: any) => {
    let typeOffer: any[] = [];
    trainingOffer?.pricings?.map((pricing:any) => {
        if (pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price != 0) {
            typeOffer.push({title:'Paiement unique', className: 'oneshot'});
        } 
        if (pricing.period === "MONTH" && pricing.nbPeriod != null) {
            typeOffer.push({title:'Paiement par facilité', className: 'facilitated'});
        }
        if (pricing.price == 0 && pricing.period === "ONESHOT" && pricing.nbPeriod === null) {
            typeOffer.push({title: 'Gratuit', className: 'free'});
        }
        if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing.period) && pricing.nbPeriod == null) {
            typeOffer.push({title:'Abonnement', className: 'subscription'});
        }
    });
     
    let typeOfferFiltred = typeOffer.filter((obj, index) => index === typeOffer.findIndex(o => obj.title === o.title));
    if(typeOfferFiltred.length > 1) return [{title:'Plusieurs types de paiement', className: 'mutiple'}]
    return typeOfferFiltred
}