import { FC } from "react";
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import FooterComponent from "../../components/footer-component";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import StudentsListPage from "./students-list-page";
import RegistrationPreviewFromListStudentPage from './preview-registration-page';
import StudentProfilePage from './student-profil-page';
import GroupPage from './group-page';
import CustomSwitch from "../../helpers/custom-switch";
import StudentsNotFoundPage from "./students-not-found-page";


const StudentsContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height">
      <CustomSwitch>
        <RouteWithNavbar exact={true} history={props.history} roles={["role_prof", "role_director", "role_adminstrator"]} path={`${props.match.path}/all`} component={StudentsListPage} />
        <RouteWithNavbar exact={true} history={props.history} roles={["role_prof", "role_director", "role_adminstrator"]} path={`${props.match.path}/all/not-found`} component={StudentsNotFoundPage} />
        <RouteWithBreadCrumb exact={true} roles={['role_prof', 'role_director', "role_adminstrator"]} path={`${props.match.path}/profile/student/:id`} component={StudentProfilePage} />
        <RouteWithBreadCrumb exact={true} history={props.history} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/group/:id`} component={GroupPage} />
        <RouteWithBreadCrumb exact={true} roles={['role_prof', 'role_director', "role_adminstrator"]} path={`${props.match.path}/registration/:idFollow/:idStudent/:idRF`} component={RegistrationPreviewFromListStudentPage} />

      </CustomSwitch>
    </div>
    <FooterComponent />
  </>
  )
}
export default StudentsContainer;