import { FC } from "react";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import FooterComponent from "../../components/footer-component";
import SubscriptionPage from "./subscription-page";
import CustomSwitch from "../../helpers/custom-switch";
import ParametrePage from "./parametre-page";
const ParametreContainer: FC<any> = (props: any) => {
    return (<>
        <div className="page-wrapper-height">
            <CustomSwitch>
                <RouteWithNavbar exact={true} history={props?.history} path={`${props?.match.path}`} component={ParametrePage} roles={["role_director", "role_prof", "role_student"]} />
            </CustomSwitch>
        </div>
        <FooterComponent />
    </>
    )
}
export default ParametreContainer;