import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import CardChapterComponent from './card-chapter-component';
import CardTestComponent from "./card-test-component";
const style = {
    cursor: 'move',
    backgroundColor: 'transparent'
};
export default function CardDragDropComponent({ id, chapter, index, moveCard, getChapter, getExam, t ,options}: any) {
    const ItemTypes = {
        CARD: 'card',
    }
    const ref: any = useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item: any, monitor: any) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            if (clientOffset.y < 230) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const updateChapter = () => {
        getChapter(chapter);
    }
    const updateExam = () => {
        getExam(chapter);
    }
    const opacity = isDragging ? 0.5 : 1;
    drag(drop(ref));
    return (
        (chapter?.chapter) ?
            <div    ref={ref} style={{ ...style, opacity }}>
                <CardChapterComponent chapter={chapter} index={index} options={options} moveCard={moveCard} updateChapter={updateChapter}/>
            </div>
            :
            <div onClick={() => updateExam()} ref={ref} style={{ ...style, opacity }}>
                <CardTestComponent test={chapter} index={index + 1} />
            </div>
    );
};
