import { Component, ComponentProps } from 'react';
import AvatarEditor from 'react-avatar-editor';
import SliderZoomInOut from '../slider/slider-zoom-in-out';
import "../../translations/i18n";

class CropImageSchool extends Component<any, ComponentProps<any>> {
    editor: AvatarEditor | undefined;
    container: any;
    constructor(props: any) {
        super(props)
        this.state = {
            loading:false,
            modified: false,
            file: props?.file,
            config: {
                scale: 1,
                position: { x: 0, y: 0 },
                cropped: false,
                crop: { x: 0, y: 0 },
                aspect: 1,
                width: props?.width, // course 840, webinar 700
                maxWidth: props?.width
            }
        }

    }
    

loadingHandler(loading:boolean){
 this.setState({loading:loading})
}
saveButtonHandler(e:any){
    this.cropping(e);
    this.loadingHandler(true)
}

    componentDidMount() {
        this.setState({modified: true});
        window.addEventListener("resize", this.changeWindowSize);
        this.changeWindowSize();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.changeWindowSize);
    }
    changeWindowSize = () => {
        if ( (this?.container?.offsetWidth!=undefined) && (this?.container?.offsetWidth!= null) && (this?.container?.offsetWidth < this?.state?.config?.maxWidth) ) {
            const copyState = { ...this.state.config };
            copyState.width = this?.container?.offsetWidth - 80;
            this.setState({ config: copyState });
        } else if (this.state.width !== this.state.config.maxWidth - 80) {
            const copyState = { ...this.state.config };
            copyState.width = this.state.config.maxWidth - 80;
            this.setState({ config: copyState });
        }
    }
    componentWillReceiveProps(nextProps: any) {
        if( !this.state.modified) {
            this.setState({
                file: nextProps?.file
            })
        }
    }

    handleScale = (value: any) => {
        const copyConfig = { ...this.state.config };
        const newScale = parseFloat(value);
        copyConfig.scale = newScale;
        this.setState({ config: copyConfig });
        if(newScale !== this.state.scale){
            this.setState({modified: true});
        }
    }
    handlePositionChange = (position: any) => {
        const copyConfig = { ...this.state.config };
        copyConfig.position = position;
        this.setState({ config: copyConfig });

        if(position.x !== this.state.config.position.x || position.y !== this.state.config.position.y){
            this.setState({modified: true});
        }
    }
    onCrop = (e: any) => {
        if (this.editor) {
            const canvasScaled = this.editor.getImageScaledToCanvas();
            const canvas = canvasScaled.toDataURL();

            fetch(canvas).then(res => res.blob()).then((blob: any) => {
                let name = "";
                decodeURIComponent(this?.state?.file.split("/")[3]).split("-").forEach((substr: string, index: number) => {
                    if (index > 0 && index < this.state.file.split("/")[3].split("-").length - 1) {
                        name = name + substr + "-";
                    } else if (index === this.state.file.split("/")[3].split("-").length - 1) {
                        name = name + substr;
                    }
                })

                let newFile = new File([blob], (this.state.file.name) ? this.state.file.name : name +".png", { type: blob.type, lastModified: Date.now() });
                this.props?.addFile([newFile])
            });
        }
    }
    onDelete = async () => {
        await this.props?.deleteFile();
    }

    setEditorRef = (editor: any) => {
        this.editor = editor
    }

    cropping = async (e: any) => {
        if(this.state.modified) {
            await this.onCrop(e);
        }

    }

    render() {
        const {t} = this.props;
        return <>
            <div style={{ position: 'relative' }}>
                <div ref={(ref) => this.container = ref} className=" d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center" style={{ position: "absolute", top: 10, right: 40, width: 18, height: 18 }}>
                        <div style={{ paddingLeft: "3px" }}>
                            <button className="btn-QuickActions white d-flex align-items-center justify-content-center" style={{ width: 2, height: 2 }} onClick={() => this.onDelete()}><span className="material-icons-outlined" color="white">close</span></button>
                        </div>

                    </div>
                    {this.state.file && <AvatarEditor
                        crossOrigin="anonymous"
                        ref={(ref) => this.setEditorRef(ref)}
                        border={4}
                        borderRadius={0}
                        scale={parseFloat(this.state.config.scale)}
                        width={238}
                        height={238}
                        position={this.state.config.position}
                        onPositionChange={this.handlePositionChange}
                        image={this.state?.file}
                    />}
                    <div className=" w-90" style={{ position: "absolute", bottom: -46 }}>
                        <SliderZoomInOut scale={parseFloat(this.state.config.scale)} newScale={this.handleScale} />
                    </div>
                </div>

            </div>
            <div className=" justify-content-center align-items-center d-flex" style={{ paddingTop: "70px" }}>
                {!this.state.loading?<button type="button" className="btn-Primary large " onClick={(e) => {this.saveButtonHandler(e) }} >
                    {t("save")}
                </button>
                :<button className="btn-Primary loaddata large">
               <div className="threedots-large-Animation">
               <span></span>
               <span></span>
               <span></span>
               </div>
               </button>}
            </div>
        </>
    }
}


export default CropImageSchool;