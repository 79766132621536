import { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import koolog from '../../styles/images/koolog.png';
import { useTranslation } from 'react-i18next';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { createProgressQna } from '../../global-state/actions/exam-actions'
interface Props {
    t: any

}
interface Params {
    id: any
}
const SondageDirectorPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const { t } = useTranslation();
    const { id } = useParams<Params>();
    const history = useHistory();
    const startQna = async () => {
        props?.clearHistoryArray()
        props?.redirect(props?.examToPass?.title, `${returnStartPathUrl()}/survey/view/pass/` + props?.examToPass?.id)
        await history.push({ pathname: `${returnStartPathUrl()}/survey/view/pass/` + props?.examToPass?.id });
    }

    return (<>

        <div style={{ backgroundColor: "#FFFFFF" }}>
            <div className="d-flex flex-column align-items-center mx-4" style={{ backgroundColor: "#FBFBFD", paddingTop: "41px" }}>
                <div className="w-100  d-flex align-items-center justify-content-center flex-column px-5" style={{ minHeight: 300 }}>

                    <img style={{ width: 120, height: 120, objectFit: 'fill', borderRadius: '50%' }} src={(props?.school?.logo && props?.school?.logo !== null && props?.school?.logo !== '') ? props?.school?.logo : koolog} alt="user icon" />

                </div>
                <span className="body-xl bold" >{props?.school?.name} </span>

                <h3 className="H3-Headline mt-4" >{props?.examToPass?.title}</h3>

                <div className="d-flex flex-column mx-auto" style={{ textAlign: 'center', width: 544 }}>
                    <span className="body-md neutral-2" >{props?.examToPass?.description}  </span>
                </div>
                <div style={{ paddingBottom: "40px" }} >

                    <button type="button" className="btn-Primary large" onClick={() => { startQna() }}>{t("StartExam")}</button>


                </div>
            </div>



        </div>



    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    school: state.schoolInfoReducer.schoolInfo,
    examToPass: state.examReducer.examToPass,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect, clearHistoryArray,
            createProgressQna
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,

)(SondageDirectorPage);
