import { ComponentProps, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/';
import "../../translations/i18n";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { setCurrentQuestion, submitCloseQuestionQnaResponse, saveAnswers, createProgressQna, emptyProgress } from '../../global-state/actions/exam-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { updateCurrentProgressExam, calculateTraining, getCurrentCourse } from '../../global-state/actions/student-training-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from "../../components/modals/modal-ids";
interface Params {
    id: any
}
const PassClosedQuestionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [question, setQuestion] = useState<any>();
    const { t } = useTranslation();
    const { id } = useParams<Params>();
    const [isAnswered, setIsAnswered] = useState(false);
    const [checkResponse, setCheckResponse] = useState(false);
    const history = useHistory();
    const [openQuestionForm, setOpenQuestionForm] = useState<any>()
    useEffect(() => {
        setQuestion(props?.question)

    }, [])
    useEffect(() => {
        setQuestion(props?.question)
    }, [props?.question])
    useEffect(() => {
        if (props?.progressTraining && props?.currentCourse?.followUp?.progressTraining && props?.currentCourse?.followUp?.progressTraining !== null && props?.currentCourse?.followUp?.progressTraining?.progressQna?.findIndex((obj: any) => props?.progressTraining?.id === obj?.id) !== -1) {
            props?.updateCurrentProgressExam(props?.progressTraining);
        }
    }, [props?.progressTraining])
    useEffect(() => {
        const liste = question?.question?.questionChoice?.map((questionTopass: any) => {
            return {
                id: null,
                responseChoice: questionTopass.choice,
                responseChoiceState: false,
            };
        });
        if (props?.studentAnswers && props?.studentAnswers[props?.indexQuestionToPass] && props?.studentAnswers[props?.indexQuestionToPass]?.id !== null) {
            const obj = { ...props?.studentAnswers[props?.indexQuestionToPass] };
            obj['questionByExamId'] = props?.question?.id;
            if (obj?.examId === undefined) {
                obj['examId'] = Number(id);
            }
            if (obj?.responseQuestionChoice === undefined || obj?.responseQuestionChoice?.length === 0) {
                const copy = { ...obj }

                obj['responseQuestionChoice'] = obj?.responseQuestionChoiceDtos;
                obj.responseQuestionChoiceDtos = undefined;
            }
            obj['studentId'] = props?.connectedUser?.idPerson;
            setOpenQuestionForm(obj);
            setIsAnswered(true);
        } else {
            setOpenQuestionForm({
                studentId: props?.connectedUser?.idPerson,
                questionByExamId: props?.question?.id,
                examId: Number(id),
                idProgressExam: props?.currentCourse?.exams?.find((obj: any) => obj?.id === Number(id))?.progression?.id,
                responseQuestionChoice: liste,
            })
            setIsAnswered(false);
        }

    }, [question, props?.studentAnswers])

    const handleEvent = (position: number) => {
        setCheckResponse(true);
        let nextState = { ...openQuestionForm };
        nextState.responseQuestionChoice = nextState.responseQuestionChoice.map((questionResp: any, index: number) => {
            if (index === position) {
                return {
                    id: questionResp.id,
                    responseChoice: questionResp.responseChoice,
                    responseChoiceState: true,
                };
            } else {
                return {
                    id: questionResp.id,
                    responseChoice: questionResp.responseChoice,
                    responseChoiceState: false,
                };
            }
        });
        setOpenQuestionForm(nextState);
    };

    const submitResponse = async () => {

        if (props.connectedUser == undefined || (!!props.connectedUser && props?.connectedUser.role == "role_student")) {
            const copy = { ...openQuestionForm };
            copy['responseQuestionChoiceDtos'] = copy.responseQuestionChoice;
            copy.responseQuestionChoice = undefined;
            if (props && props.answers) {
                const indexQna = props?.answers.findIndex((answer: any) => answer?.examId == copy?.examId && copy?.questionByExamId == answer?.questionByExamId);
                if (indexQna !== -1) {
                    copy['id'] = props?.answers.find((answer: any) => answer?.examId == copy?.examId && copy?.questionByExamId == answer?.questionByExamId)?.id
                }
            }
            await props?.submitCloseQuestionQnaResponse(copy, props?.indexQuestionToPass)
                .then(async (resp: any) => {
                    await props?.saveAnswers(resp?.data?.data)
                });
        }


        await postVAlid();
    }

    const postVAlid = async () => {

        if (props?.indexQuestionToPass < props?.exam?.questionByQnaList?.length - 1) {
            props?.setCurrentQuestion(props?.indexQuestionToPass + 1);
        } else {

            if (props.connectedUser == undefined || (!!props.connectedUser && props?.connectedUser.role == "role_student")) {
                let object = { ...props?.progressQna }
                object["status"] = "COMPLETED"
                await props?.createProgressQna(object)
                await props?.emptyProgress()
                await history.push(`${returnStartPathUrl()}/survey/passed`);
            } else {
                await props?.openModal({ idModal: ModalIDS.SUCEESS_PREVISIALIZATION, size: "md", lines: ["C’est la fin de la prévisualisation !"] });
            }

        }
    };

    useEffect(() => {

        if (!!question && !question?.isObligatory) {
            setCheckResponse(true)
        }

    }, [question])
    return (
        <div>
            <div className="d-flex flex-column align-items-center justify-content-center" >
                <span className='body-md'>
                    Un seul choix de réponse
                </span>
            </div>
            <div className="d-flex flex-column mt-4" >
                {openQuestionForm?.responseQuestionChoice?.map((item: any, index: number) => {
                    return (

                        <>

                            <div style={{ cursor: 'pointer', height: 90, maxWidth: 734 }} className={`bg-with-border d-flex flex-wrap align-items-center justify-content-between px-3 mb-2`}>
                                <div className="d-flex align-items-center">
                                    <input
                                        disabled={isAnswered}
                                        className="input-style-radio"
                                        readOnly={true}
                                        type="radio"
                                        style={{ border: "1px solid #F2F2F5" }}
                                        name={"exampleradio"}
                                        id={"outlinedradiobutton" + index}
                                        value="option1"
                                        checked={item?.responseChoiceState}
                                    />                  <label style={{ textTransform: "none", cursor: (isAnswered) ? 'default' : 'pointer' }} htmlFor={"outlinedradiobutton" + index} onClick={() => (!isAnswered) ? handleEvent(index) : {}} >
                                        <div className="d-flex flex-column align-items-center justify-content-center" >

                                            <div className="d-flex">

                                            </div>
                                        </div>
                                    </label>
                                    <span className="ms-3 body-md ">{item?.responseChoice}</span>
                                </div>
                            </div>

                        </>

                    )
                })}
            </div>
            <div>
                <div className="row " style={{ margin: "32px 0px 0px 0px " }}  >
                    <button disabled={!checkResponse} style={{ height: 80 }} className="btn-Primary large py-4" onClick={() => { submitResponse() }}>  {props?.indexQuestionToPass < props?.exam?.questionByQnaList.length - 1 ? t("Valider_ma_réponse") : "Terminer"} </button>
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            submitCloseQuestionQnaResponse,
            setCurrentQuestion,
            updateCurrentProgressExam,
            calculateTraining,
            getCurrentCourse,
            saveAnswers, createProgressQna, emptyProgress,
            openModal, closeModal
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    question: ownProps?.question,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    exam: state.examReducer.examToPass,
    studentAnswers: state.examReducer.listOfResponses,
    currentCourse: state.studentTraining.currentCourse,
    currentTraining: state.studentTraining.currentTraining,
    listFollowUps: state.studentTraining.listFollowUps,
    currentProgress: state.studentTraining.currentTrainingProgress,
    sectionContentDetails: state.studentTraining.sectionContentDetails,
    progressTraining: state.examReducer.progressTraining,
    answers: state.examReducer.answers,
    progressQna: state.examReducer.progressQna
});

export default connect(mapStateToProps, mapDispatchToProps)(PassClosedQuestionComponent);

