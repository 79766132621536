import { ComponentProps, FC} from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DetailsStudentComponent from "../../components/students-profile/details-students-component";
import HeaderStudentProfilPageComponent from '../../components/headers-page/header-student-profil-page-component';

const StudentProfilePage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    return (<>
        <div style={{marginTop:64}}> 
            <HeaderStudentProfilPageComponent/>
        </div>
       
        <DetailsStudentComponent />
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfilePage);