import Skeleton from '@material-ui/lab/Skeleton';

function HeaderExamDetailsLoadingDashboard() {

    return (
        <div style={{ backgroundColor: "#FFFFFF" }}>
            <div className="container-fluid" style={{ maxWidth: "1200px" }} >
                <div className="row" >
                    <div className="px-0" style={{ paddingTop: "32px" }} >
                        <Skeleton className="skeleton " animation="wave" variant="rect" width={260} height={50} />
                    </div>

                    <div className=" mt-3 px-0 pb-4" >
                        <div className="d-flex w-100 align-items-center jsutify-content-start">
                            <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                            <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                            <Skeleton className="skeleton radius" animation="wave" variant="rect" width={130} height={36} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#FBFBFD" }}>
                <div className="container-fluid" style={{ maxWidth: "1200px" }} >
                    <div className="row" style={{ paddingTop: "32px" }}>
                        <div className="col-md-4">
                            <Skeleton className="skeleton square" animation="wave" variant="rect" width={212} height={48} />
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-6" style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}>
                            <div className="d-flex -align-items-center" >
                                <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={212} height={48} />
                                <div className="" style={{ width: '220px' }}>
                                    <Skeleton className="skeleton square " animation="wave" variant="rect" width={'100%'} height={48} />
                                </div>
                            </div>

                            <div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HeaderExamDetailsLoadingDashboard
