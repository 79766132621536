/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import HeaderPathProgress from '../../../components/headers-page/header-path-progress';
import { getStudentTrainings, getCurrentTraining } from '../../../global-state/actions/student-training-actions';
import { useParams } from 'react-router-dom';
import SessionProgressContentComponent from '../../../components/path-components/session-progress-content-component';
import LoadingHeaderPathSessionComponent from '../../../components/headers-page/loading-header--path-session-component';
import LoadingPathSessionContentComponent from '../../../components/path-components/path-course-components/loading-path-session-content-component';
import axiosInstance from '../../../helpers/interceptors';

interface Params {
    idSession: string
}
const SessionProgressPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { idSession } = useParams<Params>();
    const [loadingPage, setLoadingPage] = useState<boolean>(true);
    let currentTraining = useMemo(() => props?.currentTraining, [props?.currentTraining])

    useEffect(() => {
        setLoadingPage(true)
    }, [])
    
    useEffect(() => {
        if (currentTraining === undefined  || props?.currentTraining?.followUp?.id !== Number(idSession)) {
            props?.getCurrentTraining(Number(idSession))
            setLoadingPage(true)
        } else {
            setLoadingPage(false)
        }

        
    }, [currentTraining, props?.currentTraining?.followUp?.id,idSession])

    useEffect(() => {
        if(props?.currentTraining!==undefined&& props?.currentTraining?.diploma === null && props?.currentTraining?.followUp?.progressTraining?.progress===100){
              let verif:any =props?.currentTraining?.followUp?.statusList?.filter((status:any)=>status?.status==="COMPLETED")
              if(verif?.length===0){
                  let status={
                      "id":null,
                      "status":"COMPLETED",
                      "follow":props?.currentTraining?.followUp
                  }
                  axiosInstance.post( `${process.env.REACT_APP_BASE_URL3}follow/status`,status)
                  
                 
              }
          } 
      
      }, [props?.currentTraining])


    return (


        <div style={{ maxWidth: "1200px", margin: "auto", marginTop: "64px" }}>
            {loadingPage ?
                <div>
                    <LoadingHeaderPathSessionComponent />
                    <LoadingPathSessionContentComponent />
                </div>

                : <div>
                    <HeaderPathProgress />
                    <SessionProgressContentComponent />
                </div>}

        </div>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudentTrainings, getCurrentTraining
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionProgressPage);