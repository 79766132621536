import { ComponentProps, FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import CreateCouponsComponent from '../../../components/coupons/create-coupon-component';
import { AppState } from '../../../global-state/store/root-reducers';
const CreateCouponsPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  return (
    <>
      <CreateCouponsComponent/>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  user: state.authReducer.connectedUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCouponsPage);
