
import { EReduxActionTypes, IReduxGetProfAction, IReduxGetStudentsOfProfLoadingAction, IReduxGetLastTabAction, IReduxLoadingProfessorsListAction, IReduxGetStudentsOfProfAction, IReduxGetStudentsOfCourseAction, IReduxGetAssistantAction, IReduxGetTeamNumberAction, IReduxClearNumberPagesTeamAction, IReduxGetTeamAction, IReduxGetTeamSearchAction, IReduxReinstateTeamMemberAction, IReduxClearTeamListTeamAction, IReduxTypeSuspendAssistantAction, IReduxTypeDeleteInvitationAction, IReduxTypeExportListAction } from '../actions/professor-actions'
export interface IProf {
  id: string,
  name: string,
  inscription: string,
  lastVisit: string,
  cours: number,
  pp: boolean
  status: string

}

export interface IReduxIProfState {
  profs: IProf[],
  listeStudents?: any
  listeStudentsByCourse?: any
  lastTabClicked: string
  loadingAllProfessors: boolean
  loadingAllStudentsList: boolean
  sizeProfs: number
  loadStudentsGeneral: boolean
  loadStudentsSuspended: boolean
  loadStudentsCandidate: boolean
  loadStudentsInscribed: boolean
  loadStudentsCertified: boolean
  loadStudentsUnpaid: boolean
  sizeAssistants: number,
  loadingAssistant: boolean,
  teamSize: number,
  assistantPage: number,
  profPage: number,
  teamPage: number,
  profList: any,
  assistants: any,
  teamList: any,
  loadingTeam: boolean,
  countFormationStudents: {
    data: object,
    isLoading: boolean
  },
  loadingExport: boolean
}

const initialState: IReduxIProfState = {
  profs: [],
  sizeProfs: 0,
  listeStudents: undefined,
  listeStudentsByCourse: undefined,
  lastTabClicked: 'All',
  loadingAllProfessors: true,
  loadingAllStudentsList: true,
  loadStudentsGeneral: true,
  loadStudentsSuspended: true,
  loadStudentsCandidate: true,
  loadStudentsInscribed: true,
  loadStudentsCertified: true,
  loadStudentsUnpaid: true,
  assistants: [],
  sizeAssistants: 0,
  loadingAssistant: true,
  teamSize: 0,
  assistantPage: 0,
  profPage: 0,
  teamPage: 0,
  profList: [],
  teamList: [],
  loadingTeam: true,
  countFormationStudents: {
    data: {},
    isLoading: true
  },
  loadingExport: false
};

type TProfReducerActions = IReduxGetProfAction | IReduxGetStudentsOfProfLoadingAction | IReduxGetStudentsOfProfAction | IReduxGetStudentsOfCourseAction | IReduxGetLastTabAction | IReduxLoadingProfessorsListAction
  | IReduxGetAssistantAction | IReduxGetTeamNumberAction | IReduxClearNumberPagesTeamAction | IReduxGetTeamAction
  | IReduxGetTeamSearchAction | IReduxReinstateTeamMemberAction | IReduxClearTeamListTeamAction | IReduxTypeSuspendAssistantAction | IReduxTypeDeleteInvitationAction
  | IReduxTypeExportListAction;

export default function (state: IReduxIProfState = initialState, action: TProfReducerActions) {
  switch (action.type) {

    case EReduxActionTypes.DELETE_INVITATION:
      let listAll = [...state.teamList];
      if (action?.data?.type === "PROF") {
        let copyListProf = [...state?.profList];
        let indexProfToDelete = copyListProf.findIndex((prof: any) => prof?.id === action?.data?.id)
        if (indexProfToDelete != -1) {
          copyListProf.splice(indexProfToDelete, 1)
        }
        let indexTeamMemberToDelete = listAll.findIndex((team: any) => team?.id === action?.data?.id)
        if (indexTeamMemberToDelete != -1) {
          listAll.splice(indexTeamMemberToDelete, 1)
        }
        return { ...state, profList: copyListProf, teamList: listAll };
      } else {
        let copyListassistant = [...state?.assistants];
        let indexAssistantToDelete = copyListassistant.findIndex((assistant: any) => assistant?.id === action?.data?.id)
        if (indexAssistantToDelete != -1) {
          copyListassistant.splice(indexAssistantToDelete, 1)
        }
        let indexTeamMemberToDelete = listAll.findIndex((team: any) => team?.id === action?.data?.id)
        if (indexTeamMemberToDelete != -1) {
          listAll.splice(indexTeamMemberToDelete, 1)
        }
        return { ...state, assistants: copyListassistant, teamList: listAll };
      }
    case EReduxActionTypes.TEAM_SEARCH:

      if ((action?.data?.refresh !== undefined)) {
        return { ...state, teamList: action?.data?.liste, loadingTeam: false };
      } else {
        return { ...state, teamList: action?.data, loadingTeam: false };
      }
    case EReduxActionTypes.GET_PROF:
      if (action.data.refresh !== undefined) {
        return { ...state, profs: action?.data?.liste, profList: action?.data?.liste, loadingAllProfessors: false };
      } else {
        return { ...state, profs: action?.data, profList: action?.data, loadingAllProfessors: false };
      }
    case EReduxActionTypes.GET_ASSISTANT:

      if ((action?.data?.refresh !== undefined)) {
        return { ...state, assistants: action?.data?.liste, loadingAssistant: false };
      } else {
        return { ...state, assistants: action?.data, loadingAssistant: false };
      }
    case EReduxActionTypes.GET_LAST_TAB:
      return { ...state, lastTabClicked: action.data };
    case EReduxActionTypes.GET_STUDENTS_BY_PROF:
      if (action.data !== null) {
        let copyListStudents = { ...state.listeStudents }
        if (!copyListStudents) {
          copyListStudents = {};
        }
        copyListStudents[action.data.key] = action.data[action.data.key];
        return { ...state, listeStudents: copyListStudents, [action.data.keyLoad]: false, loadingAllStudentsList: false };
      } else {
        return { ...state };
      }
    case EReduxActionTypes.LOADING_GET_STUDENTS_BY_PROF:
      return { ...state, loadStudentsGeneral: true, loadStudentsSuspended: true, loadStudentsCandidate: true, loadStudentsInscribed: true, loadStudentsCertified: true, loadingAllStudentsList: true, loadStudentsUnpaid: true };
    case EReduxActionTypes.GET_STUDENTS_BY_COURSE:
      return { ...state, listeStudents: action.data, loadingAllStudentsList: false };
    case EReduxActionTypes.LOADING_PROFESSORS_LIST:
      return { ...state, loadingAllProfessors: true };
    case EReduxActionTypes.GET_TEAM_NUMBER:
      return { ...state, sizeProfs: action?.data?.professorNumber, sizeAssistants: action?.data?.assistantNumber, teamSize: action?.data?.total };
    case EReduxActionTypes.CLEAR_TEAM_PAGE:
      return { ...state, assistantPage: 0, profPage: 0, teamPage: 0 };
    case EReduxActionTypes.GET_TEAM:
      if (action?.data?.type === "PROF" && action.data.refresh !== undefined) {

        if (action.data.refresh === "firstLoad") {
          return { ...state, profList: action?.data?.liste, loadingAllProfessors: false, profPage: action?.data.page }
        }
        let copyListProf = [...state?.profList];
        copyListProf = copyListProf.concat(action?.data?.liste);
        return { ...state, profList: copyListProf, loadingAllProfessors: false, profPage: action?.data.page }
      }
      else if (action?.data?.type === "ADMINSTRATOR" && action.data.refresh !== undefined) {
        if (action.data.refresh === "firstLoad") {
          return { ...state, assistants: action?.data?.liste, loadingAssistant: false, assistantPage: action?.data?.page };
        }
        let copyListassistant = [...state?.assistants];
        copyListassistant = copyListassistant.concat(action?.data?.liste)
        return { ...state, assistants: copyListassistant, loadingAssistant: false, assistantPage: action?.data?.page };
      }
      else if (action?.data?.type === "ALL" && action?.data?.refresh !== undefined) {
        if (action?.data?.refresh === "firstLoad") {
          return { ...state, teamList: action?.data?.liste, loadingTeam: false, teamPage: action?.data?.page, loadingAllProfessors: false, loadingAssistant: false };
        }
        let copy = [...state.teamList];
        copy = copy.concat(action?.data?.liste)
        return { ...state, teamList: copy, loadingTeam: false, teamPage: action?.data?.page, loadingAllProfessors: false, loadingAssistant: false };
      } else {
        return { ...state, assistants: action?.data, loadingAllProfessors: false, loadingAssistant: false, loadingTeam: false, profs: action?.data, assistantPage: action?.data?.page, profPage: action?.data?.page, teamPage: action?.data.page };
      }
    case EReduxActionTypes.REINSTATE_TEAM_MEMBER:

      if (action.data.type === "PROF") {
        let indexProf = state?.profList?.findIndex((prof: any) => prof.id === Number(action.data.id));
        let indexTeam = state?.teamList?.findIndex((prof: any) => prof.id === Number(action.data.id));
        let copyListProf = [...state?.profList];
        let copyListTeam = [...state?.teamList];
        if (indexTeam != -1) {
          copyListTeam[indexTeam].status = "Admis";
        }
        if (indexTeam != -1) {
          copyListProf[indexProf].status = "Admis";
        }
        return { ...state, teamList: copyListTeam, profList: copyListProf };
      } else {
        let indexAssistant = state?.assistants?.findIndex((ass: any) => ass.id === parseInt(action.data.id));
        let copyListProf = [...state?.assistants];

        copyListProf[indexAssistant].status = "Admis";

        let indexTeam = state?.teamList?.findIndex((prof: any) => prof.id === parseInt(action.data.id));
        let copyListTeam = [...state?.teamList];
        if (indexTeam != -1) {
          copyListTeam[indexTeam].status = "Admis";
        }
        return { ...state, assistants: copyListProf, teamList: copyListTeam };
      }
    case EReduxActionTypes.SUSPEND_TEAM_MEMBER:
      if (action.data.type === "PROF") {
        let indexProf = state?.profList?.findIndex((prof: any) => prof.id === parseInt(action.data.id));
        let copyListProf = [...state?.profList];
        copyListProf[indexProf].status = "Suspendu";
        let indexTeam = state?.teamList?.findIndex((prof: any) => prof.id === parseInt(action.data.id));
        if (indexTeam != -1) {
          let copyListTeam = [...state?.teamList];
          copyListTeam[indexTeam].status = "Suspendu";

          return { ...state, teamList: copyListTeam, profList: copyListProf };
        }

        return { ...state, profList: copyListProf };
      } else {
        let indexAssistant = state?.assistants?.findIndex((ass: any) => ass.id === parseInt(action.data.id));
        let copyListProf = [...state?.assistants];
        copyListProf[indexAssistant].status = "Suspendu";
        let indexTeam = state?.teamList?.findIndex((ass: any) => ass.id === parseInt(action.data.id));
        let copyTeam = [...state?.teamList];
        if (indexTeam != -1) {
          copyTeam[indexTeam].status = "Suspendu";

        }
        return { ...state, assistants: copyListProf, teamList: copyTeam };
      }
    case EReduxActionTypes.CLEAR_TEAM_LIST:
      return { ...state, assistants: [], profList: [], teamList: [] };
    case EReduxActionTypes.GET_COUNT_FORMATION_STUDENTS:
      return {
        ...state, countFormationStudents: {
          data: action.data,
          isLoading: false
        }
      }
    case EReduxActionTypes.EXPORT_LIST:
      return { ...state, loadingExport: action.data };
    case EReduxActionTypes.LOADING_EXPORT_BUTTON:
      return { ...state, loadingExport: action.data };
    default:
      return state;
  }
}



