import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { updatePath } from "../../global-state/actions/breadcrumb-actions";
import {
  createExam,
  deleteExam,
  updateExam
} from "../../global-state/actions/course-actions";
import { useTranslation } from "react-i18next";
import KnowledgeTestFormComponent from "../formation-course-form/knowledge-test-form-component";
import { debounce, sum } from "lodash";
import FinalExamFormComponent from "../formation-course-form/final-exam-form-component";


const ContentExamComponent: FC<
  ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ComponentProps<any>
> = (props: any) => {
  const typeTest = useMemo(() => {
    return props?.typeTest;
  }, [props?.typeTest]);
  const { t } = useTranslation();

  const [points, setPoints] = useState<number>(0);
  const [testForm, setTestForm] = useState<any>({
    id: null,
    questionByQnaList: [],
    index: props?.indexContent + 1,
    title:
      props?.typeTest === "FINALEXAM"
        ? t("examTitleBegin")
        : t("testTitleBegin"),
    duration: 0,
    scaleNote: 0,
    entityId: props?.courseForm.id,
    entityType: "COURSE",
    status: "ACTIVE",
    qnaType: props?.typeTest,
    openQuestion: null
  });

  const [examTitle, setExamTitle] = useState(props?.title);
  useEffect(() => {
    if (props?.title === examTitle) {
      setExamTitle(props?.title);
    } else {
      updateTitle();
    }
  }, [props?.title]);


  const updateTitle = () => {
    if (
      props?.title !== "" &&
      props?.title !== testForm.title &&
      ((props?.testForm && props?.testForm.id === testForm.id) || !props?.testForm)
    ) {
      const copyTestForm = { ...testForm };
      copyTestForm.title = props?.title;
      if (props?.typeTest === "FINALEXAM" && copyTestForm.duration === 0) {
        copyTestForm.duration = 0;
      }
      setTestForm(copyTestForm);
      if (props?.typeTest === "FINALEXAM" && copyTestForm.duration === 0) {
        copyTestForm.duration = 0;
      }
      debouncedChangeTestHandler(copyTestForm);
    }
  };

  useEffect(() => {
    if (props?.title === examTitle) {
      updateTitle();
    }
  }, [examTitle]);

  useEffect(() => {
    if (props?.testForm !== undefined) {
      const copyTestForm = { ...props?.testForm };
      if (typeTest === "FINALEXAM" && copyTestForm.duration === 0) {
        copyTestForm.duration = 0;
      }
      setTestForm(copyTestForm);
      setPoints(
        sum(
          props?.testForm.questionByQnaList.map((obj: any) => {
            return obj.scaleNote;
          })
        )
      );
    }
  }, [props?.testForm]);
  const addQuestion = () => {
    const copyTestForm = { ...testForm };
    copyTestForm.questionByQnaList.push({
      id: null,
      question: {
        id: null,
        questionStatement: "",
        questionAnswerExplanation: "",
        type: "CQ",
        quizType: "QCU",
        questionChoice: [
          { id: null, choice: "", choiceState: false },
          { id: null, choice: "", choiceState: false }
        ]
      },
      scaleNote: 1
    });
    copyTestForm.scaleNote = copyTestForm.scaleNote + 1;
    if (typeTest !== "FINALEXAM") {
      copyTestForm.duration = copyTestForm.duration + 1;
    }
    setPoints(points + 1);
    setTestForm(copyTestForm);
  };

  const addOpenQuestion = () => {
    const copyTestForm = { ...testForm };
    copyTestForm.questionByQnaList.push({
      id: null,
      scaleNote: 1,
      question: {
        id: null,
        questionStatement: "",
        type: "OQ",
        questionAssessmentLink: "",
        questionAnswerExplanation: ""
      }
    });
    setTestForm(copyTestForm);
  };
  const saveQuestion = (object: any, index: number) => {
    const copyTestForm = { ...testForm };
    copyTestForm.questionByQnaList[index] = object;
    if (typeTest !== "FINALEXAM") {
      copyTestForm.duration = copyTestForm.questionByQnaList.length;
    }
    setTestForm(copyTestForm);
    saveExam(copyTestForm);
  };
  const saveExam = async (test: any) => {
    if (test.duration === 0 && typeTest === "FINALEXAM") {
      test.duration = 0;
    }
    if (test.id !== null) {
      const index = props?.listTest.findIndex((obj: any) => obj.id === test.id);

      if (test && test.questionByQnaList && test.questionByQnaList.length > 0)
        props?.updateExam(test, index);
    } else {
      let date: Date = new Date();
      test["creationDate"] = date;
      if (test && test.questionByQnaList && test.questionByQnaList.length > 0)
        props?.createExam(test);
      setTestForm(test);
    }
  };
  const debouncedChangeTestHandler = useCallback(debounce(saveExam, 1000), []);

  const deleteQuestion = (index: any) => {
    const copyTestForm = { ...testForm };
    copyTestForm.questionByQnaList.splice(index, 1);
    if (typeTest !== "FINALEXAM") {
      copyTestForm.duration = copyTestForm.duration - 1;
    }
    copyTestForm.scaleNote = copyTestForm.scaleNote - 1;
    setPoints(points - 1);
    setTestForm(copyTestForm);
    if (copyTestForm && copyTestForm.questionByQnaList && copyTestForm.questionByQnaList.length === 0){
      const index = props?.listTest.findIndex((obj: any) => obj.id === copyTestForm.id);
      props?.deleteExam(copyTestForm.id, index);
    }
    debouncedChangeTestHandler(copyTestForm);
  };

  return (
    <>
      <div
        className='row mt-4 pt3'
        style={{ maxWidth: "1200px", margin: "auto" }}
      >
        <h3 className='H3-Headline'>
          {typeTest === "FINALEXAM"
            ? t("finalExmBreadcrunbTitle")
            : t("textConnaissance")}{" "}
          / {points} <span className='neutral-3'> {t("points")} </span>
        </h3>
        {typeTest === "FINALEXAM" ? (
          <FinalExamFormComponent
            listQuestions={testForm.questionByQnaList}
            addOpenQuestion={addOpenQuestion}
            saveQuestion={saveQuestion}
            addQuestion={addQuestion}
            deleteQuestion={deleteQuestion}
          />
        ) : (
          <KnowledgeTestFormComponent
            listQuestions={testForm.questionByQnaList}
            saveQuestion={saveQuestion}
            addQuestion={addQuestion}
            deleteQuestion={deleteQuestion}
          />
        )}
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { updatePath, createExam, updateExam, deleteExam },
    dispatch
  );
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  courseForm: state.courseReducer.courseForm,
  listHistory: state.historyReducer.listHistoryBreadCrumb,
  history: ownProps?.history,
  chapterForm: state.courseReducer.chapterForm,
  indexChapter: state.courseReducer.indexChapter,
  listTest: state.courseReducer.listTest,
  typeTest: state.courseReducer.typeContentCourse,
  testForm: state.courseReducer.testForm,
  indexTest: state.courseReducer.indexTest,
  indexContent: state.courseReducer.indexContent
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentExamComponent);
