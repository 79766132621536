import { FC } from "react"
import { useTranslation } from "react-i18next"

const FooterComponent: FC = () => {
const {t} = useTranslation()
    return (<footer className="footer" id="footer">
        <div className="first-s m-auto">
            <div className="second-s" style={{ maxWidth: '1200px' }}>
                <div className="d-flex flex-column" >
                    <div className="third-s d-flex align-items-center justify-content-center flex-wrap" >
                        <div className="fourth-s d-flex">
                            <span className="body-sm">© {t('Copyright')} {new Date().getFullYear()}. {t('rights')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>)
}
export default FooterComponent;