import { ComponentProps, FC, useEffect } from 'react';

import { AppState } from '../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { checkIfPersonIsInvited, setPersonInvited } from '../global-state/actions/auth-actions';
import { useHistory, useParams } from "react-router";
import LoadingComponent from '../components/auth-components/login-register-components/loading-component';
import { returnStartPathUrl } from '../helpers/domainCheck';

interface RouteParams {
    code: string
    role: string
}

const InvitationContainer: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {

    const { code, role } = useParams<RouteParams>();
    const history = useHistory();
    useEffect(() => {

        if (!!code) {
            props?.checkIfPersonIsInvited(code);
        }
    }, [code])
    useEffect(() => {

        if (props?.isPersonInvited === false) {
            history.push(`${returnStartPathUrl()}/invitation/expired`);
        }
        else history.push(`${returnStartPathUrl()}/auth/register/${code}/${role}`);
        setPersonInvited(false)
    }, [props?.isPersonInvited])
    return (
        <LoadingComponent />
    );

}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    isPersonInvited: state.authReducer.isPersonInvited,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            checkIfPersonIsInvited, setPersonInvited
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(InvitationContainer);