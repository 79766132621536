import { FC, useCallback, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import SelectElement from "../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import {getFilteredQuestion} from '../../global-state/actions/chat-actions';
import InputSearch from '../../elements/inputs/input-search';
import { debounce } from "lodash";
interface Props{
    isSearching:any,
}
const SearchFilterQuestionComponent: FC<ReturnType<typeof mapStateToProps> &ReturnType<typeof mapDispatchToProps>& Props>=(props)=>{
    const [tab,setTab] = useState<string>("");
    const [trieOption, setTrieOption]= useState<any[]>([]);
    const [trie, setTrie]= useState("recent");
    const [filtre, setFiltre]= useState("all");
    const [doSearch, setDoSearch]= useState(false);
    const [doSearchSettled, setDoSearchSettled]= useState(false);
    const [doSearchAll, setDoSearchAll]= useState(false);
    const [searchFilter, setSearchFilter]= useState("");
    const { t } = useTranslation();
    const [filterOption, setFilterOption]= useState<any>();
    useEffect(() => {
        
        setTab(props?.tabQuestionManager);
        setTrie("recent");
        setFiltre("all");
        setSearchFilter("") 
        setFilterOption([{value:"all", label:t('allSubjects') },props?.myCourses?.filter((course:any)=>{return course.status==="PUBLISHED"}).map((obj:any)=>{
            return {value:`${JSON.stringify({id: obj.id, type:obj.type})}`,label:`${obj.title}` }})].flat(Infinity))
        switch(props?.tabQuestionManager) {
            case "all":
                if(doSearchAll) {
                    props?.getFilteredQuestion(props?.connectedUser?.idPerson,props?.connectedUser?.idSchool,{idCours:null, trie:"recent", actualTab: props?.tabQuestionManager, searchFilter:"" , page:0}, true );
                    setDoSearchAll(false)  
                }
                setTrieOption([{value:"recent",label:t('recent') },{value:"oldest", label:t('oldest')},{value:"maxResponses", label:t('maxResponses')}]) 
                break;
            case "settled":
                if(doSearchSettled) {
                   props?.getFilteredQuestion(props?.connectedUser?.idPerson,props?.connectedUser?.idSchool,{idCours:null, trie:"recent", actualTab: props?.tabQuestionManager,page:0}, true );
                    setDoSearchSettled(false)  
                }
                setTrieOption([{value:"recent",label:t('recent') },{value:"oldest", label:t('oldest')},{value:"maxResponses", label:t('maxResponses')}])  
                break;
            case "unsettled":
                if(doSearch) {
                    props?.getFilteredQuestion(props?.connectedUser?.idPerson,props?.connectedUser?.idSchool,{idCours:null, trie:"recent", actualTab: props?.tabQuestionManager, searchFilter:"", page:0}, true );
                    setDoSearch(false)
                }
                setTrieOption([{value:"recent", label:t('recent')},{value:"oldest", label:t('oldest')}])   
                break;
            default: 
                break;
        } 
        
    }, [props?.tabQuestionManager])
    const setDoSearchFn= (tabInput: string )=>{
        switch(tabInput) {
            case 'all':
                setDoSearchAll(true);
                break;
            case 'settled':
                setDoSearchSettled(true)
                break;
            default:
                setDoSearch(true) 
                break;
        }
    }
    const sort=(trieSearch:string)=>{
        setTrie(trieSearch);
        setDoSearchFn(tab);
        props?.isSearching({trie: trieSearch, filtre: filtre, searchFilter: searchFilter});
    }
    const filter=(filterInput:string)=>{
        setFiltre(filterInput);
        setDoSearchFn(tab);
        props?.isSearching({trie: trie, filtre: filterInput, searchFilter: searchFilter});
    }
    const Search=(search:string)=>{
        setSearchFilter(search);
        setDoSearchFn(tab);
        props?.isSearching({trie: trie, filtre: filtre, searchFilter: search});
    }
    const debounceSearch = useCallback( debounce(Search, 300), []);
    return(
        <>
            <div className="col-md-12  mt-5 d-flex justify-content-end text-right px-0">
                <div className="select-input me-2" style={{ width: '225px' }}>
                
                   <SelectElement disabled={false} taille={'lg'} defaultValue={trie} options={trieOption}  placeholder ={""} onChange={sort}></SelectElement> 
                </div> 
                <div className="select-input me-2" style={{ width: '225px' }}>
                    
                   <SelectElement disabled={false} taille={'lg'} defaultValue={filtre} options={filterOption}  placeholder ={""}  onChange={filter} ></SelectElement> 
                </div> 
                <InputSearch  value={searchFilter}  onChange={ debounceSearch} placeholder={t('searchProfessors')}/>
            </div>
                        
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    myCourses: state.courseReducer.myCourses,
    tabQuestionManager: state.chatReducer.tabQuestionManager,
    connectedUser: state.authReducer.connectedUser,
  });
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
      {
        getFilteredQuestion 
      },
      dispatch
    );
export default connect(mapStateToProps,mapDispatchToProps)(SearchFilterQuestionComponent);