import { ComponentProps, FC, useEffect, useMemo } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import CatalogComponent from "../../components/catalog/catalog-component";
import { useHistory, } from "react-router";
import { returnStartPathUrl } from "../../helpers/domainCheck";


const CatalogPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

  const user = useMemo(() => { return props?.connectedUser }, [props?.connectedUser]);
  const history = useHistory();

  useEffect(() => {
    if (user?.role.includes("role_director") || user?.role.includes("role_prof")) {
      history.push(`${returnStartPathUrl()}/`)
    }
  }, [user])


  useEffect(() => {
    dataLayerGTMPushEvent("test catalog page")
  }, [])

  const dataLayerGTMPushEvent = (eventName: string) => {
    (window as any).dataLayer = (window as any).dataLayer || [];

    (window as any).dataLayer.push({
      event: eventName,
    });
  };

  return (

    <>
      {(!user?.role.includes("role_director")) && (!user?.role.includes("role_prof")) &&
        <CatalogComponent schoolInfo={props.schoolInfo} />}
    </>

  )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  history: ownProps?.history,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPage);