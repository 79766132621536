import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { setCurrentQuestion } from "../../global-state/actions/exam-actions";

const HeaderPassSondage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [currentQuestion, setCurrentQuestion] = useState<any[]>([])
    const [index, setIndex] = useState(0);
    const [nbrQuestions, setNbrQuestions] = useState();

    useEffect(() => {
        setIndex(props?.indexQuestionToPass);
    }, [props?.indexQuestionToPass])

    const nextQuestion = (nextValue: any) => {
        props?.setCurrentQuestion(nextValue);
    }

    useEffect(() => {
        setCurrentQuestion(props?.listOfQuestions);
        setNbrQuestions(props?.listOfQuestions?.length);
    }, [props?.listOfQuestions])


    return (
        <div className="bg-with-border grey-backgounrd p-4">
             {currentQuestion && currentQuestion[index] && currentQuestion[index]?.isObligatory ==true && 
         
            <div className="d-flex flex-row justify-content-between mx-2">
                <div className="d-flex align-items-center justify-content-start me-4">
                    <button disabled={index === 0} type="button" className="btn-QuickActions" onClick={() => { nextQuestion(index - 1) }}>
                        <span className="material-icons-outlined">keyboard_arrow_left</span>
                    </button>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1"> {/* Updated CSS class */}
                    <div className="d-flex flex-row justify-content-center">
                        <span className="H3-Headline mb-2 pb-1">
                            <span className="H3-Headline neutral-3">{t('Question')}</span> {index + 1}<span className="H3-Headline neutral-3">/ {nbrQuestions}</span>
                        </span>
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                        <span className="H4-Subtitle bold text-center">{(currentQuestion && currentQuestion[index]) && currentQuestion[index]?.question?.questionStatement}*</span>
                    </div>
                  
               
                </div>

            </div>

             }
            {currentQuestion && currentQuestion[index] && currentQuestion[index]?.isObligatory ==false && 
                 
            <div className="d-flex flex-row justify-content-between mx-2">
                <div className=" d-flex align-items-center justify-content-start me-4">
                    <button disabled={index === 0} type="button" className="btn-QuickActions" onClick={() => { nextQuestion(index - 1) }}><span className="material-icons-outlined">keyboard_arrow_left</span></button>
                </div>
                <div className="d-flex flex-column" >
                    <div className=" d-flex flex-row justify-content-center">
                        <span className="H3-Headline mb-2 pb-1"><span className="H3-Headline neutral-3">{t('Question')} </span> {index + 1}<span className="H3-Headline neutral-3">/ {nbrQuestions} </span></span>
                    </div>
                    <div className=" d-flex flex-row justify-content-center">
                        <span className="H4-Subtitle bold text-center">{(currentQuestion && currentQuestion[index]) && currentQuestion[index]?.question?.questionStatement}</span>
                    </div>
                </div>
                <div className=" d-flex align-items-center justify-content-end ms-4">
                    <button disabled={index === currentQuestion?.length - 1} type="button" className="btn-QuickActions" onClick={() => { nextQuestion(index + 1) }}><span className="material-icons-outlined"><span className="material-icons-outlined">keyboard_arrow_right</span></span></button>
                </div>
            </div>
               }
        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    listOfQuestions: state.examReducer.questionsToPass,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        setCurrentQuestion
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPassSondage);

