import { ComponentProps, FC, useMemo } from "react"
import StudentListCardComponent from "../../components/list-cards-webinars/student-list-card-component";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import ProfessorListCardComponent from "../../components/list-cards-webinars/professor-list-card-component";

const WebinarsListCardPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const user = useMemo(() => {return props?.connectedUser}, [props?.connectedUser] );
    
    return (<>
        {user?.role.includes("role_student") ?
            <>
                <StudentListCardComponent />
            </>
            :
            <>
                <ProfessorListCardComponent />
            </>
        }
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearAllData }, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(WebinarsListCardPage);