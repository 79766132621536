import { EReduxActionTypes, IReduxCreateTrainingAction,IReduxGetCountStudentAction ,IReduxLoadingScrollTrainingsAction, IReduxValideTrainingAction, IReduxGetTrainingAction, IReduxGetTrainingsAction, IReduxClearTrainingAction, IReduxDeleteSubjectAction, IReduxAddSubjectAction, IReduxHandleErrorsAction, IReduxAddTestAction, IReduxRemoveTestAction, IReduxGetTestAction, IReduxPatchGroupAction, IReduxGetMyTrainingsAction,IReduxTrainingNumberAction,IReduxClearMyTrainingsAction, IReduxGetTrainingsFinanceAction, IReduxSearchTrainingAssistantAction, IReduxGetMyTrainingsForAssistantAction, IReduxChangeTabAllTrainingsAction, IReduxGetSessionSubtitleAction, IReduxGetPathSubtitleAction, IReduxGetSummaryTrainingsAction } from "../actions/training-actions"

interface Training {
    id: number | null,
    status: string;
    level: string;
    title: string;
    type: string;
    creationDate: string;
    idProf: number;
    idRegistrationForm: number | null;
    entityId: number;
    entityType: string;
    resume: string;
    objectives: string;
    prerequisite: string;
    skills: string[]
    exams: any[],
    isDiplomat:any
    
}

export interface Path extends Training {
    subjectsByPath: any[]
}
export interface Session extends Training {
    subjectsBySession: any[],
    
}
export interface Course extends Training {
    orders: any[]
}


export interface IReduxTrainingState {
    trainings: Training[],
    allowedTrainings: Training[],
    errors: any
    course: Course,
    path: Path,
    countStudent:any,
    session: Session,
    pageNumber:number,
    actualTabTraining: string,
    loadingTrainings:boolean,
    loadingAllTrainings : boolean,
    loadingcountStudent:boolean,
    trainingNumber:number|undefined,
    loadingScrollTrainings: boolean,
    getSessionSubtitleUploadedLoading: boolean,
    getSessionSubtitleUploaded: any,
    getPathSubtitleUploadedLoading: boolean,
    getPathSubtitleUploaded: any
    
}

export const  initialState: IReduxTrainingState = {
    trainings: [],
    allowedTrainings:[],
    errors: {
        course: {

        },
        path: {

        },
        session: {

        }
    },
    course: {
        id: null,
        status: "DRAFT",
        level: "ANYLEVEL",
        title: "titre de Path",
        type: "COURSE",
        creationDate: Date.now().toString(),
        idProf: 0,
        idRegistrationForm: null,
        entityId: 0,
        entityType: "SCHOOL",
        resume: "",
        objectives: "",
        prerequisite: "",
        skills: [],
        exams: [],
        orders: [],
        isDiplomat:false
    },
    path: {
        id: null,
        status: "DRAFT",
        level: "ANYLEVEL",
        title: "",
        type: "PATH",
        creationDate: Date.now().toString(),
        idProf: 0,
        idRegistrationForm: null,
        entityId: 0,
        entityType: "SCHOOL",
        resume: "",
        objectives: "",
        prerequisite: "",
        skills: [],
        exams: [],
        subjectsByPath: [],
        isDiplomat:false
    },
    session: {
        id: null,
        status: "DRAFT",
        level: "ANYLEVEL",
        title: "",
        type: "SESSION",
        creationDate: Date.now().toString(),
        idProf: 0,
        idRegistrationForm: null,
        entityId: 0,
        entityType: "SCHOOL",
        resume: "",
        objectives: "",
        prerequisite: "",
        skills: [],
        exams: [],
        subjectsBySession: [],
        isDiplomat:false
        
    },
    pageNumber:0,
    loadingTrainings:true,
    actualTabTraining: 'all',
    loadingAllTrainings:true,
    loadingcountStudent:true,
    trainingNumber:undefined,
    countStudent:undefined,
    loadingScrollTrainings: false,
    getSessionSubtitleUploadedLoading: false,
    getSessionSubtitleUploaded: [],
    getPathSubtitleUploadedLoading: false,
    getPathSubtitleUploaded: [],
}

type TrainingReducerActions = IReduxGetPathSubtitleAction | IReduxGetSessionSubtitleAction | IReduxCreateTrainingAction | IReduxChangeTabAllTrainingsAction | IReduxGetTrainingsFinanceAction |IReduxGetCountStudentAction| IReduxLoadingScrollTrainingsAction | IReduxValideTrainingAction | IReduxGetTrainingAction | IReduxGetTrainingsAction | IReduxClearTrainingAction | IReduxDeleteSubjectAction | IReduxAddSubjectAction | IReduxHandleErrorsAction | IReduxAddTestAction | IReduxRemoveTestAction | IReduxGetTestAction | IReduxPatchGroupAction | IReduxGetMyTrainingsAction |IReduxTrainingNumberAction | IReduxClearMyTrainingsAction | IReduxGetMyTrainingsForAssistantAction | IReduxSearchTrainingAssistantAction | IReduxGetSummaryTrainingsAction
export default function (state: IReduxTrainingState = initialState, action: TrainingReducerActions) {
    switch (action.type) {

        case EReduxActionTypes.SET_TAB_ALL_TRAINING:
            return { ...state, actualTabTraining: action.data.tab }
        case EReduxActionTypes.LOADING_ALL_COURSES:
            return { ...state, loadingAllTrainings: true }
        case EReduxActionTypes.VALIDE_TRAINING:
            switch (action.data.type) {
                case "COURSE": return { ...state, course: initialState.course, errors: { ...state.errors, course: {} } }
                case "paths": return { ...state, path: action.data.content }
                case "sessions": return { ...state, session: action.data.content }
                default: return state
            }
        case EReduxActionTypes.CLEAR_TRAININGS:
            switch (action.data) {
                case "COURSE": return {  ...state, trainings:[],pageNumber:0,trainingNumber:undefined}
                case "PATH": return { ...state, trainings:[],pageNumber:0,trainingNumber:undefined, path: initialState.path, errors: { ...state.errors, path: {} } }
                case "SESSION": return { ...state, trainings:[],pageNumber:0,trainingNumber:undefined, session: initialState.session, errors: { ...state.errors, session: {} } }
                default: return state
            }
        case EReduxActionTypes.CREATE_TRAINING:
            switch (action.data?.type) {
                case "COURSE": return { ...state, course: { ...state.course, ...action.data, exams: state.course.exams },trainings:[],pageNumber:0 }
                case "PATH": return {
                    ...state, path: {
                        ...state.path, ...action.data,
                        subjectsByPath: action.data.subjectsByPath.sort((a: any, b: any) => {
                            return a?.index > b?.index ? 1 : -1
                        }), exams: state.path.exams
                    },trainings:[],pageNumber:0
                }
                case "SESSION": return {
                    ...state, session: {
                        ...state.session, ...action.data,
                        subjectsByPath: action.data.subjectsBySession.sort((a: any, b: any) => {
                            return a?.index > b?.index ? 1 : -1
                        }), exams: state.session.exams
                    },trainings:[],pageNumber:0
                }
                default: return state
            }
        case EReduxActionTypes.GET_TRAINING:
            switch (action.data?.type) {
                case "COURSE": return { ...state, course: { ...state.course, ...action.data.training } }
                case "paths": return { ...state, path: { ...state.path, ...action.data.training } }
                case "sessions": return { ...state, session: { ...state.session, ...action.data.training } }
                default: return state
            }
        case EReduxActionTypes.GET_TRAININGS:
            return { ...state, allowedTrainings: action.data }
        case EReduxActionTypes.ADD_SUBJECT:
            switch (action.trainingType) {
                case "COURSE": return { ...state, course: { ...state?.course, orders: [...state?.course?.orders, action.data] } }
                case "PATH": return { ...state, path: { ...state?.path, subjectsByPath: [...state.path?.subjectsByPath, action.data] } }
                case "SESSION": return { ...state, session: { ...state?.session, subjectsBySession: [...state?.session?.subjectsBySession, action.data] } }
                default: return state
            }
        case EReduxActionTypes.DELETE_SUBJECT:
            switch (action.data?.type) {
                case "COURSE": return { ...state, course: { ...state?.course, ...action.data } }
                case "PATH": return { ...state, path: { ...state?.path, ...action.data } }
                case "SESSION": return { ...state, session: { ...state?.session, ...action.data } }
                default: return state
            }
        case EReduxActionTypes.HANDLE_ERRORS:
            switch (action?.trainingType) {
                case "COURSE": return { ...state, errors: { ...state.errors, course: action.data } }
                case "PATH": return { ...state, errors: { ...state.errors, path: action.data } }
                case "SESSION": return { ...state, errors: { ...state.errors, session: action.data } }
                default: return state
            }
        case EReduxActionTypes.ADD_TEST:
            switch (action?.trainingType) {
                case "COURSE": return { ...state, course: { ...state.course, exams: [...state?.course?.exams, action.data] } }
                case "PATH": return {
                    ...state, path: {
                        ...state.path, exams: [
                            action.data]
                    }
                }
                case "SESSION": return {
                    ...state, session: {
                        ...state.session, exams: [
                            action.data]
                    }
                }
                default: return state
            }
        case EReduxActionTypes.REMOVE_TEST:
            switch (action?.trainingType) {
                case "COURSE": return { ...state, course: { ...state.course, exams: state.course.exams.filter((exam: any) => exam.id !== action.data) } }
                case "PATH": return {
                    ...state, path: {
                        ...state.path, exams: state.course.exams.filter((exam: any) => exam.id !== action.data)
                    }
                }
                case "SESSION": return { ...state, session: { ...state.session, exams: state.course.exams.filter((exam: any) => exam.id !== action.data) } }
                default: return state
            }

        case EReduxActionTypes.GET_TEST:
            switch (action?.trainingType) {
                case "COURSE": return { ...state, course: { ...state.course, exams: action.data } }
                case "PATH": return { ...state, path: { ...state.path, exams: action.data } }
                case "SESSION": return { ...state, session: { ...state.session, exams: action.data } }
                default: return state
            }
        case EReduxActionTypes.PATCH_GROUP:
            switch (action?.trainingType) {
                case "COURSE": return { ...state, course: { ...state.course, groups: action.data } }
                case "PATH": return { ...state, path: { ...state.path, groups: action.data } }
                case "SESSION": return { ...state, session: { ...state.session, groups: action.data } }
                default: return state
            }
        case EReduxActionTypes.GET_MY_TRAININGS:   
        if(action.scrollOrNot === 'first time') {
            return {...state, trainings:action.data,pageNumber:action?.pageNumber,loadingTrainings:false, loadingScrollTrainings:false }
        } else if(action.scrollOrNot === 'scroll'){
            return {...state, trainings:[...state.trainings,...action.data],pageNumber:action?.pageNumber,loadingTrainings:false, loadingScrollTrainings:false }
        } else {
            return {...state}
        }
        case EReduxActionTypes.GET_SUMMARY_TRAININGS:   
        if(action.scrollOrNot === 'first time') {
            return {...state, trainings:action.data,pageNumber:action?.pageNumber,loadingTrainings:false, loadingScrollTrainings:false }
        } else if(action.scrollOrNot === 'scroll'){
            return {...state, trainings:[...state.trainings,...action.data],pageNumber:action?.pageNumber,loadingTrainings:false, loadingScrollTrainings:false }
        } else {
            return {...state}
        }


        case EReduxActionTypes.GET_TRAININGS_FINANCE:   
        return {...state, trainings:action.data,pageNumber:action?.pageNumber,loadingTrainings:false, loadingScrollTrainings:false }
    

        case EReduxActionTypes.GET_TRAININGS_FOR_ASSISITANT:   
            return {...state,trainings:action.data, loadingAllTrainings : false } 
        case EReduxActionTypes.SEARCH_TRAINING_ASSISTANT:   
            return {...state,trainings:action.data } 

        case EReduxActionTypes.GET_COUNT_STUDENT:   
        return {...state,countStudent:action.data ,loadingcountStudent:false}     
        case EReduxActionTypes.LOADING_COUNT_STUDENT:   
        return {...state,loadingcountStudent:true } 
        case EReduxActionTypes.LOADING_TRAININGS:   
            return {...state,loadingTrainings:true } 
     
        case EReduxActionTypes.LOADING_TRAININGS_SCROLL:   
            return {...state,loadingScrollTrainings:true } 
        case EReduxActionTypes.TRAINING_NUMBER:   
            return {...state,trainingNumber:action.data}
        case EReduxActionTypes.CLEAR_MY_TRAININGS:   
            return {...state,trainings:[],pageNumber:0,trainingNumber:undefined}
        case EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_LOADING:
            return {
                ...state, getSessionSubtitleUploadedLoading: true
            }
        case EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_SUCCESS:
            return {
                ...state, 
                getSessionSubtitleUploadedLoading: false,
                getSessionSubtitleUploaded: action.data
            }
        case EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_FAILED:
            return {
                ...state, 
                getSessionSubtitleUploadedLoading: false,
            }
        case EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_FAILED:
            return {
                ...state, 
                getPathSubtitleUploadedLoading: false,
            }
        case EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_SUCCESS:
            return {
                ...state, 
                getPathSubtitleUploaded: action.data,
            }
        case EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_LOADING:
            return {
                ...state, getPathSubtitleUploadedLoading: true
            }
        default:
            return state;
    }

}
