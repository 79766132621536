import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
import inscription_file from '../../styles/imgs/inscription_file.svg';
import acces from '../../styles/imgs/acces.svg';

const TrainingHeaderComponentLoading: FC<ComponentProps<any>> = () => {

  return (
    <div className='container-desc-preview-course mx-auto' style={{ position: 'relative' }}>
            <div className={`relations-theory padding-description mt-40`} style={{ backgroundColor: "#212529", color: '#fff' }}>

                <div className='mx-auto d-none d-xl-block' style={{ maxWidth: "1200px" }}>
                    <div className="title H2-Headline" style={{ color: '#fff', maxWidth: '687px'}}>
                      <Skeleton variant="rect" animation="wave" width={'60%'} height={32} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                      <Skeleton variant="rect" animation="wave" width={'15%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                    <div className="description" style={{ maxWidth: '687px', margin: '15px 0'}} > 
                      <Skeleton variant="rect" animation="wave" height={15} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                      <Skeleton variant="rect" animation="wave" height={15} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                      <Skeleton variant="rect" animation="wave" height={15} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" /> 
                      <Skeleton variant="rect" animation="wave" width={'85%'} height={15} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" /> 

                    </div>
                    <div className='d-flex'>
                      <Skeleton variant="rect" animation="wave" width={'10%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                      <Skeleton variant="rect" animation="wave" width={'10%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                    <div className="d-flex align-items-center justify-content-start mt-3">
                        <div className="d-flex align-items-center" style={{width:'180px'}}>
                            <span className="material-icons-outlined neutral-3 me-1">signal_cellular_alt</span>
                            <Skeleton variant="rect" animation="wave" width={'100%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                        </div>
                        <div className="d-flex align-items-center" style={{width:'180px'}}>
                            <span className="material-icons-outlined neutral-3 me-1">timer</span>
                            <Skeleton variant="rect" animation="wave" height={32} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                        </div>

                        <div className="d-flex align-items-center me-3" style={{width:'180px'}}>
                            <span className=" me-2"><img src={inscription_file} alt="" /></span>
                            <Skeleton variant="rect" animation="wave" height={32} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                            
                        </div>
                        
                    </div>
                    <div className="d-flex align-items-center me-3 mt-2" style={{width:'180px'}}>
                            <span className="neutral-3 me-2"><img src={acces} alt="" /></span>
                            <Skeleton variant="rect" animation="wave" height={32} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                        </div>
                </div>
            </div>
            <div className="objective padding-description px-3 px-xl-0 mt-4 mt-xl-0" style={{ backgroundColor: '#fff' }}>
                <div className='mx-auto max-width'>
                    <div className='width'>
                        <div className="title H3-Headline">
                          <Skeleton variant="rect" animation="wave" width={'20%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                        </div>
                        <div className="desc" style={{ maxWidth: '687px', margin: '15px 0', wordWrap: "break-word" }}>
                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='prerequisites px-3 px-xl-0 mt-1 mt-xl-0' style={{ backgroundColor: '#FBFBFD' }}>
                <div className='mx-auto max-width'>
                    <div className="title H3-Headline">

                      <Skeleton variant="rect" animation="wave" width={'20%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                    </div>
                    <div className="des" style={{ maxWidth: '687px', margin: '15px 0', wordWrap: "break-word" }}>
                      <Skeleton variant="rect" animation="wave" width={'100%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                      <Skeleton variant="rect" animation="wave" width={'100%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                      <Skeleton variant="rect" animation="wave" width={'100%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                </div>
            </div>


            <div
                className="more-info d-none d-xl-block"
                style={{ position: 'absolute', top: '50px', right: '86px', backgroundColor: '#fff', boxShadow: '0px 8px 12px rgba(159, 172, 173, 0.25)' }}>
                    <div className='img-more-info custom-video-youtube' style={{ width: "370px", height: "254px" }}>
                    <Skeleton variant="rect" animation="wave" width={'370px'} height={"254px"} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                    </div> 
                <div className="content-more-info" style={{ position: 'relative', padding: '20px 25px 0px 25px' }}>
                    <div style={{ marginBottom: 12 }}>
                      <Skeleton variant="rect" animation="wave" width={'75%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                      <Skeleton variant="rect" animation="wave" width={'45%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                    <hr style={{color:"gray"}} />
                    <div className='d-flex'>                      
                      <Skeleton variant="rect" animation="wave" width={'35%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                      <Skeleton variant="rect" animation="wave" width={'25%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                      <Skeleton variant="rect" animation="wave" width={'25%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                    </div>
                    <Skeleton variant="rect" animation="wave" width={'35%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-2" />
                    <hr style={{color:"gray"}}/>
                    <div className='d-flex flex-column  mb-2'>
                      <Skeleton variant="rect" animation="wave" width={'65%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities" />
                      <Skeleton variant="rect" animation="wave" width={'80%'} height={25} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                    <hr style={{color:"gray"}}/>
                    <div className='' style={{ textAlign: 'center', paddingBottom: "24px" }}>
                      <Skeleton variant="rect" animation="wave" width={'25%'} height={40} className="skeleton w-100 skeleton-main col-xs-7 me-4 second-card-list-activities" />
                    </div>
                </div>
            </div>
        </div >
  );
}

export default TrainingHeaderComponentLoading;