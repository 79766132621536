

import InviteProfessorModalComponent from '../../components/modals/modal-forms/invite-professor-modal-component';
import LoadingUploadVideoModalComponent from '../../components/modals/modal-forms/loading-upload-video-modal-component';
import AddCommentModalComponent from '../../components/modals/modal-forms/add-comment-modal-component';
import AddQuestionModalComponent from '../../components/modals/modal-forms/add-question-modal-component';
import AddCommentProgressModalComponent from '../../components/modals/modal-forms/add-comment-progress-modal-component';
import AddQuestionProgressModalComponent from '../../components/modals/modal-forms/add-question-progress-component';
import ChoiceTypeTestModalComponent from '../../components/modals/modal-states/choice-type-test-modal-component';
import AddNewGroupModalComponent from '../../components/modals/modal-forms/add-new-group-modal-component';
import UpdateGroupTitleModalComponent from '../../components/modals/modal-forms/update-group-title-modal-component';
import AddStudentToGroupModalComponent from '../../components/modals/modal-forms/add-student-to-group-modal-component';
import { ModalIDS } from '../../components/modals/modal-ids';
import AddPathTrainingModalComponent from '../../components/modals/modal-forms/add-path-training-modal';
import SuccessModalWithButtonComponent from '../../components/modals/modal-states/success-modal-component';
import FailedModalWithButtonComponent from '../../components/modals/modal-states/failed-modal-component';
import DeleteGroupModalComponent from '../../components/modals/modal-forms/delete-group-modal-component';
import AddsubGroupModalComponent from '../../components/modals/modal-forms/add-sub-group-modal-component';
import DeleteUserProfileModalComponent from '../../components/modals/modal-forms/delete-user-profile-modal-component';
import AddSessionTrainingModal from '../../components/modals/modal-forms/add-session-training-modal';
import CheckFormCourseModalComponent from '../../components/modals/modal-states/check-form-course-modal-component';
import PublishCourseProfModalComponent from '../../components/modals/modal-forms/publish-course-prof-modal-component';
import DeactivateStudentModalComponent from '../../components/modals/modal-forms/deactivate-student-modal-component';
import ReintegrateStudentModalComponent from '../../components/modals/modal-forms/reintegrate-student-modal-component';
import AddAStudentToGroupsModalComponent from '../../components/modals/modal-forms/add-a-student-to-diff-groups-modal-component';
import CheckFormTrainingModalComponent from '../../components/modals/modal-states/check-form-training-modal-component';
import AddCommentSessionModalComponent from '../../components/modals/modal-forms/add-comment-session-modal-component';
import AddQuestionSessionModalComponent from '../../components/modals/modal-forms/add-question-session-modal-component';
import AddCommentPathModalComponent from '../../components/modals/modal-forms/add-comment-path-modal-component';
import AddQuestionPathModalComponent from '../../components/modals/modal-forms/add-question-path-modal-component';
import DeactivateTrainingModalComponent from '../../components/modals/modal-forms/deactivate-training-modal-component';
import ReactivateTrainingModalComponent from '../../components/modals/modal-forms/reactivate-training-modal-component';
import AcceptTrainingModalComponent from '../../components/modals/modal-forms/accept-training-modal-component';
import RefuseTrainingModalComponent from '../../components/modals/modal-forms/refuse-training-modal-component';
import PublishTrainingModalComponent from '../../components/modals/modal-forms/publish-training-modal-component';
import RefuseRegistrationModalComponent from '../../components/modals/modal-forms/refuse-registration-modal-component';
import SuccessRegistrationModalComponent from '../../components/modals/modal-states/success-registration-modal-component';
import GiveFeedbackTrainingModal from '../../components/modals/modal-forms/give-feedback-training-modal';
import UpdateProfilePhotoModalComponent from '../../components/modals/modal-forms/update_profile_photo_modal_component';
import DeactivateProfModalComponent from '../../components/modals/modal-forms/deactivate-prof-modal-component';
import ReintegrateProfModalComponent from '../../components/modals/modal-forms/reintegrate-prof-modal-component';
import SchoolPhotoModal from '../../components/modals/modal-forms/school-photo-modal';
import ConfirmNewPriceModalComponent from '../../components/modals/modal-forms/confirm-new-price-modal-component';
import IdAccountPaymeeModalComponent from '../../components/modals/modal-forms/id-account-paymee-modal-component';
import InviteAssistantModalComponent from '../../components/modals/modal-forms/invite-assistant_modal-component';
import DesactivateAssistantModalComponent from '../../components/modals/modal-forms/desactivate-assistant-modal-component';
import ReintegrateAssistantModalComponent from '../../components/modals/modal-forms/ReintegrateAssistantModalComponent';
import InviteTeamMemberModalComponent from '../../components/modals/modal-forms/InviteTeamMemberModalComponent';
import ProfessorChoiceModalComponent from '../../components/modals/modal-forms/professor-choice-modal-component';
import DeleteCardModalComponent from '../../components/modals/modal-forms/delete-card-modal-component';
import DefaultCardModalComponent from '../../components/modals/modal-forms/default-card-modal-component';
import LimitationNotificationModalComponent from '../../components/modals/modal-forms/limitation-notification-modal-component';
import ComparisationModalComponent from '../../components/modals/modal-forms/comparisation-modal-component';
import SuccessSubscriptionModalComponent from '../../components/modals/modal-forms/success-subscription-modal-component';
import EmptyPathModalComponent from '../../components/modals/modal-forms/empty-path-modal-component';
import InviteStudentModalComponent from '../../components/modals/modal-forms/invite-student-modal-component';
import InvitationStudentListModalComponent from '../../components/modals/modal-forms/invitation-student-list-modal-component'
import ErrorInvitationModalComponent from '../../components/modals/modal-forms/error-invitation-modal-component';
import ValideInvitationModalComponent from '../../components/modals/modal-forms/valide-invitation-modal-component';
import AnnuleInvitationModalComponent from '../../components/modals/modal-forms/annule-invitation-modal-component';
import DeleteTeamMemberInvitation from '../../components/modals/modal-forms/delete-team-member-invitation';
import AddVideoUrlModalComponent from '../../components/modals/modal-forms/add-video-url-modal-component';
import CheckFormCouponModalComponent from '../../components/modals/modal-states/check-form-coupon-modal-component';
import DeleteCouponModalComponent from '../../components/modals/modal-forms/delete-coupon-modal-component';
import ActivateCouponModalComponent from '../../components/modals/modal-forms/activate-coupon-modal-component';
import DeactivateCouponModalComponent from '../../components/modals/modal-forms/deactivate-coupon-modal-component ';
import TransferModalComponent from '../../components/modals/modal-forms/transfer-modal-component';
import ValidateNewPriceModalComponent from '../../components/modals/modal-forms/validate-new-price-modal-component';
import AttentionModalComponentCopy from '../../components/modals/modal-forms/attention-modal-component copy';
import CheckTarificationModalComponent from '../../components/modals/modal-states/check-form-tarification-modal-component';
import TransferTrainingModalComponent from '../../components/modals/modal-forms/transfer-training-modal-component';
import VisibleModalComponent from '../../components/modals/modal-forms/visible-modal-component';
import NotVisibleModalComponent from '../../components/modals/modal-forms/not-visible-modal-component';
import TransferDirectorTrainingModalComponent from '../../components/modals/modal-forms/transfer-director-training-modal-component';
import DeactivateSubscriptionModalComponent from '../../components/modals/modal-forms/deactivate-subscription-modal-component ';
import SuccessAnnulationModalComponent from '../../components/modals/modal-forms/success-annulation-modal-component';
import DeletePaymentMethod from '../../components/modals/modal-forms/delete-payment-method';
import EditSchoolLogoModalComponent from '../../components/modals/modal-forms/edit-school-logo-modal-component';
import ArchiveSondageModalComponent from '../../components/modals/modal-forms/archive-sondage-modal-component';
import ModalShareSondage from '../../components/modals/modal-forms/modal-share-survey-modal-component';
import DepositModalComponent from '../../components/modals/modal-forms/deposit-modal-component';
import SuccessPrevisializationModalComponent from '../../components/modals/modal-forms/success-previsialization-modal-component';
import DirectorPaymentWariningModalComponent from '../../components/modals/modal-forms/director-payment-warining-modal-component';
import SignInModalComponent from '../../components/modals/modal-forms/sign-in-modal-component';
import SignUpModalComponent from '../../components/modals/modal-forms/sign-up-modal-component';



export const getModalContent = (modalContent: string, props: any) => {
  switch (modalContent) {
    case ModalIDS.DEACTIVATE_SUBSCRIPTION_MODAL:
      return <DeactivateSubscriptionModalComponent />
    case ModalIDS.ATTENTION:
      return <AttentionModalComponentCopy />
    case ModalIDS.TRANSFER:
      return <TransferModalComponent />
    case ModalIDS.VALIDATE_NEW_PRICE_MODAL:
      return <ValidateNewPriceModalComponent />
    case ModalIDS.FAILED_PAYMENT:
      return <FailedModalWithButtonComponent />
    case ModalIDS.ERROR_INVITATION:
      return <ErrorInvitationModalComponent />
    case ModalIDS.VISE_TRAINING:
      return <VisibleModalComponent />
    case ModalIDS.NOT_VISE_TRAINING:
      return <NotVisibleModalComponent />
    case ModalIDS.INVITE_PROF_TO_SCHOOL:
      return <InviteProfessorModalComponent />;
    case ModalIDS.DELETE_CARD:
      return <DeleteCardModalComponent />;
    case ModalIDS.DEFAULT_CARD:
      return <DefaultCardModalComponent />;
    case ModalIDS.ADD_COMMENT:
      return <AddCommentModalComponent />;
    case ModalIDS.ADD_QUESTION:
      return <AddQuestionModalComponent />;
    case ModalIDS.ADD_COMMENT_PROGRESS:
      return <AddCommentProgressModalComponent />;
    case ModalIDS.ADD_QUESTION_PROGRESS:
      return <AddQuestionProgressModalComponent />
    case ModalIDS.CHOICE_TYPE_TEST:
      return <ChoiceTypeTestModalComponent />
    case ModalIDS.ADD_NEW_GROUP:
      return <AddNewGroupModalComponent />
    case ModalIDS.UPDATE_GROUP_TITLE:
      return <UpdateGroupTitleModalComponent />
    case ModalIDS.ADD_STUDENTS_TO_GROUP:
      return <AddStudentToGroupModalComponent />
    case ModalIDS.ADD_TRAINING:
      return <AddPathTrainingModalComponent />
    case ModalIDS.ADD_PATH_TRAINING:
      return <AddPathTrainingModalComponent />
    case ModalIDS.ADD_SESSION_TRAINING:
      return <AddSessionTrainingModal />
    case ModalIDS.SUCCESS_MODAL_WITH_BUTTON:
      return <SuccessModalWithButtonComponent />
    case ModalIDS.SUCCESS_ANNULATION_MODAL_WITH_BUTTON:
      return <SuccessAnnulationModalComponent />
    case ModalIDS.DELETE_GROUP:
      return <DeleteGroupModalComponent />
    case ModalIDS.DELETE_COUPON:
      return <DeleteCouponModalComponent />
    case ModalIDS.ADD_SUB_GROUP:
      return <AddsubGroupModalComponent />
    case ModalIDS.DELETE_USER_ACCOUNT:
      return <DeleteUserProfileModalComponent />
    case ModalIDS.CHECK_FORM_COURSE_CONDITIONS:
      return <CheckFormCourseModalComponent />
    case ModalIDS.CHECK_FORM_TARIFICATION_CONDITIONS:
      return <CheckTarificationModalComponent />
    case ModalIDS.CHECK_FORM_COUPON_CONDITIONS:
      return <CheckFormCouponModalComponent />
    case ModalIDS.PUBLISH_COURSE_PROF:
      return <PublishCourseProfModalComponent />
    case ModalIDS.DEACTIVATE_STUDENT:
      return <DeactivateStudentModalComponent />
    case ModalIDS.REINTEGRATE_STUDENT:
      return <ReintegrateStudentModalComponent />
    case ModalIDS.ADD_STUDENT_TO_GROUPS:
      return <AddAStudentToGroupsModalComponent />
    case ModalIDS.STUDENT_LIST:
      return <InvitationStudentListModalComponent />
    case ModalIDS.ADD_COMMENT_PATH:
      return <AddCommentPathModalComponent />
    case ModalIDS.ADD_QUESTION_PATH:
      return <AddQuestionPathModalComponent />
    case ModalIDS.DEACTIVATE_TRAINING:
      return <DeactivateTrainingModalComponent />
    case ModalIDS.REACTIVATE_TRAINING:
      return <ReactivateTrainingModalComponent />
    case ModalIDS.ACCEPT_TRAINING:
      return <AcceptTrainingModalComponent />
    case ModalIDS.TRANSFER_TRAINING:
      return <TransferTrainingModalComponent />
    case ModalIDS.TRANSFER_DIRECTOR_TRAINING:
      return <TransferDirectorTrainingModalComponent />
    case ModalIDS.REFUSE_TRAINING:
      return <RefuseTrainingModalComponent />
    case ModalIDS.CHECK_FORM_TRAINING_CONDITIONS:
      return <CheckFormTrainingModalComponent />
    case ModalIDS.ADD_COMMENT_SESSION:
      return <AddCommentSessionModalComponent />
    case ModalIDS.ADD_QUESTION_SESSION:
      return <AddQuestionSessionModalComponent />
    case ModalIDS.PUBLISH_TRAINING:
      return <PublishTrainingModalComponent />
    case ModalIDS.REFUSE_REGISTRATION:
      return <RefuseRegistrationModalComponent />
    case ModalIDS.ACCEPT_REGISTRATION:
      return <SuccessRegistrationModalComponent />
    case ModalIDS.GIVE_FEEDBACK_MODAL:
      return <GiveFeedbackTrainingModal />
    case ModalIDS.UPDATE_PROFILE_PHOTO:
      return <UpdateProfilePhotoModalComponent />
    case ModalIDS.DEACTIVATE_PROF:
      return <DeactivateProfModalComponent />
    case ModalIDS.ACTIVATE_COUPON:
      return <ActivateCouponModalComponent />
    case ModalIDS.DEACTIVATE_COUPON:
      return <DeactivateCouponModalComponent />
    case ModalIDS.REINTEGRATE_PROF:
      return <ReintegrateProfModalComponent />
    case ModalIDS.UPLOAD_SCHOOL_PHOTO:
      return <SchoolPhotoModal />
    case ModalIDS.CONFIRM_NEW_TRAINING_PRICE:
      return <ConfirmNewPriceModalComponent />
    case ModalIDS.ID_ACCOUNT_PAYMEE:
      return <IdAccountPaymeeModalComponent />;
    case ModalIDS.INVITE_ASSISTANT_TO_SCHOOL:
      return <InviteAssistantModalComponent />;
    case ModalIDS.DEACTIVATE_ASSISTANT:
      return <DesactivateAssistantModalComponent />;
    case ModalIDS.REINTEGRATE_ASSISTANT:
      return <ReintegrateAssistantModalComponent />
    case ModalIDS.INVITE_TEAM_MEMBER:
      return <InviteTeamMemberModalComponent />
    case ModalIDS.PROFESSOR_CHOICE:
      return <ProfessorChoiceModalComponent />
    case ModalIDS.LIMITATIONS_MODAL:
      return <LimitationNotificationModalComponent />
    case ModalIDS.COMPARISATION_MODAL:
      return <ComparisationModalComponent />
    case ModalIDS.SUCCESS_SUBSCRIPTION:
      return <SuccessSubscriptionModalComponent />
    case ModalIDS.SUCEESS_PREVISIALIZATION:
      return <SuccessPrevisializationModalComponent />
    case ModalIDS.PATH_EMPTY:
      return <EmptyPathModalComponent />
    case ModalIDS.CREATE_STUDENT:
      return <InviteStudentModalComponent />
    case ModalIDS.VALIDE_INVITATION:
      return <ValideInvitationModalComponent />
    case ModalIDS.ANNULE_INVITATION:
      return <AnnuleInvitationModalComponent />
    case ModalIDS.DECLINE_TEAM_INVITATION:
      return <DeleteTeamMemberInvitation />
    case ModalIDS.ADD_VIDEO_URL:
      return <AddVideoUrlModalComponent />
    case ModalIDS.DELETE_PAYMENT_METHOD:
      return <DeletePaymentMethod />
    case ModalIDS.UPDATE_SCHOOL_LOGO:
      return <EditSchoolLogoModalComponent />
    case ModalIDS.ARCHIVE_SONDAGE:
      return <ArchiveSondageModalComponent />
    case ModalIDS.SHARE_SONDAGE:
      return <ModalShareSondage />
    case ModalIDS.DEPOSIT_MODAL:
      return <DepositModalComponent />
    case ModalIDS.PAYMENT_WARNING:
      return <DirectorPaymentWariningModalComponent />
    case ModalIDS.SIGN_IN_MODAL:
      return <SignInModalComponent />
    case ModalIDS.SIGN_UP_MODAL:
      return <SignUpModalComponent />


  }
}


