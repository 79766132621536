import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxHistoryState, IHistory } from './../reducers/breadcrumb-reducer';

export enum EReduxActionTypes {
  GO_TO = 'GO_TO',
  GO_TO_PROFESSEUR = 'GO_TO_PROFESSEUR',
  CLEAR_HISTORY_PROFESSEUR = 'CLEAR_HISTORY_PROFESSEUR',
  CLEAR_HISTORY = "CLEAR_HISTORY",
  UPDATE_PATH = 'UPDATE_PATH',
  ADD_LAST_PATH = "ADD_LAST_PATH",
  ADD_LAST_PATH_SECOND = 'ADD_LAST_PATH_SECOND',
  SAVE_RETURN_PATH = "SAVE_RETURN_PATH",
  CLEAR_SAVE_RETURN_PATH = "CLEAR_SAVE_RETURN_PATH",
  REDIRECT_POPUP = "REDIRECT_POPUP",
  SAVE_TITLE = "SAVE_TITLE",
  SAVE_TEAM_ROLE = "SAVE_TEAM_ROLE",
  REDIRECT = "REDIRECT",
  GO_TO_REDIRECTION = "GO_TO_REDIRECTION",
  CLEAR_HISTORY_ARRAY = "CLEAR_HISTORY_ARRAY",
  SAVE_ENV_URL="SAVE_ENV_URL",
  ADD_LAST_URL="ADD_LAST_URL",
  CLEAR_LAST_URL="CLEAR_LAST_URL",
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
  data?: any;
}
export interface IReduxRedirectPopupAction extends IReduxBaseAction {
  type: EReduxActionTypes.REDIRECT_POPUP;
  data: boolean;
}
export interface IReduxSaveReturnPathAction extends IReduxBaseAction {
  type: EReduxActionTypes.SAVE_RETURN_PATH;
  data: any;
}
export interface IReduxClearSaveReturnPathAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_SAVE_RETURN_PATH;
}

export interface IReduxGoToAction extends IReduxBaseAction {
  type: EReduxActionTypes.GO_TO;
  data: any;
}
export interface IReduxAddLastPathAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_LAST_PATH;
  data: any;
}
export interface IReduxUpdatePathAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_PATH;
  data: any;
}
export interface IReduxClearHistoryAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_HISTORY;
}
export interface IReduxClearHistoryProfesseurAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_HISTORY_PROFESSEUR
}
export interface IReduxGoToProfesseurAction extends IReduxBaseAction {
  type: EReduxActionTypes.GO_TO_PROFESSEUR
  data: any
}
export interface IReduxAddLastPathSecondAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_LAST_PATH_SECOND
  data: any
}

export interface IReduxGoToRedirectionAction extends IReduxBaseAction {
  type: EReduxActionTypes.GO_TO_REDIRECTION
  data: any
}

export interface IReduxRedirectAction extends IReduxBaseAction {
  type: EReduxActionTypes.REDIRECT
  data: IHistory
}

export interface IReduxClearHistoryArrayAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_HISTORY_ARRAY
}

export interface IReduxSaveEnvUrlAction extends IReduxBaseAction {
  type: EReduxActionTypes.SAVE_ENV_URL,
  data: string
}

export interface IReduxAddLastTrainingIdAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_LAST_URL,
  data: number
}

export interface IReduxClearLastTrainingIdAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_LAST_URL,
}

export function clearLastTrainingId(): ThunkAction<Promise<IReduxClearLastTrainingIdAction>, IReduxHistoryState, undefined, IReduxClearLastTrainingIdAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxClearLastTrainingIdAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_LAST_URL,
    });
  };
}

export function addLastTrainingId(url:number): ThunkAction<Promise<IReduxAddLastTrainingIdAction>, IReduxHistoryState, undefined, IReduxAddLastTrainingIdAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxAddLastTrainingIdAction>) => {
    return dispatch({
      type: EReduxActionTypes.ADD_LAST_URL,
      data: url
    });
  };
}

export function saveEnvUrl(url:string): ThunkAction<Promise<IReduxSaveEnvUrlAction>, IReduxHistoryState, undefined, IReduxSaveEnvUrlAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxSaveEnvUrlAction>) => {
    return dispatch({
      type: EReduxActionTypes.SAVE_ENV_URL,
      data: url
    });
  };
}

export function goToRedirection(historyArray:any): ThunkAction<Promise<IReduxGoToRedirectionAction>, IReduxHistoryState, undefined, IReduxGoToRedirectionAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxGoToRedirectionAction>) => {
    return dispatch({
      type: EReduxActionTypes.GO_TO_REDIRECTION,
      data: historyArray
    });
  };
}

export function redirect(title:string, path:string): ThunkAction<Promise<IReduxRedirectAction>, IReduxHistoryState, undefined, IReduxRedirectAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxRedirectAction>) => {
    return dispatch({
      type: EReduxActionTypes.REDIRECT,
      data: {
        title,
        path
      }
    });
  };
}

export function clearHistoryArray(): ThunkAction<Promise<IReduxClearHistoryArrayAction>, IReduxHistoryState, undefined, IReduxClearHistoryArrayAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxClearHistoryArrayAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_HISTORY_ARRAY,
    });
  };
}


export function RedirectPopup(redirectPopup: boolean): ThunkAction<Promise<IReduxRedirectPopupAction>, IReduxHistoryState, undefined, IReduxRedirectPopupAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxRedirectPopupAction>) => {
    return dispatch({
      type: EReduxActionTypes.REDIRECT_POPUP,
      data: redirectPopup
    });
  };
}
export function saveReturnPath(path: any): ThunkAction<Promise<IReduxSaveReturnPathAction>, IReduxHistoryState, undefined, IReduxSaveReturnPathAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxSaveReturnPathAction>) => {
    return dispatch({
      type: EReduxActionTypes.SAVE_RETURN_PATH,
      data: path
    });
  };
}
export function clearSaveReturnPath(): ThunkAction<Promise<IReduxClearSaveReturnPathAction>, IReduxHistoryState, undefined, IReduxClearSaveReturnPathAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxClearSaveReturnPathAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_SAVE_RETURN_PATH,
    });
  };
}
export function goToPath(object: any): ThunkAction<Promise<IReduxGoToAction>, IReduxHistoryState, undefined, IReduxGoToAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxGoToAction>) => {
    

    return dispatch({
      type: EReduxActionTypes.GO_TO,
      data: object
    });
  };
}
export function addLastPath(object: string): ThunkAction<Promise<IReduxAddLastPathAction>, IReduxHistoryState, undefined, IReduxAddLastPathAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxAddLastPathAction>) => {
    return dispatch({
      type: EReduxActionTypes.ADD_LAST_PATH,
      data: object
    });
  };
}
export function addLastPathSecond(object: string): ThunkAction<Promise<IReduxAddLastPathSecondAction>, IReduxHistoryState, undefined, IReduxAddLastPathSecondAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxAddLastPathSecondAction>) => {
    return dispatch({
      type: EReduxActionTypes.ADD_LAST_PATH_SECOND,
      data: object
    });
  };
}
export function goToProfesseurs(object: any): ThunkAction<Promise<IReduxGoToProfesseurAction>, IReduxHistoryState, undefined, IReduxGoToProfesseurAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxGoToProfesseurAction>) => {
    return dispatch({
      type: EReduxActionTypes.GO_TO_PROFESSEUR,
      data: object
    });
  };
}
export function updatePath(object: any): ThunkAction<Promise<IReduxUpdatePathAction>, IReduxHistoryState, undefined, IReduxUpdatePathAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxUpdatePathAction>) => {
    return dispatch({
      type: EReduxActionTypes.UPDATE_PATH,
      data: object
    });
  };
}
export function clearHistory(): ThunkAction<Promise<IReduxClearHistoryAction>, IReduxHistoryState, undefined, IReduxClearHistoryAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxClearHistoryAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_HISTORY,
      data: null,
    });
  };
}
export function clearHistoryProfesseurs(): ThunkAction<Promise<IReduxClearHistoryProfesseurAction>, IReduxHistoryState, undefined, IReduxClearHistoryProfesseurAction> {
  return async (dispatch: ThunkDispatch<IReduxHistoryState, undefined, IReduxClearHistoryProfesseurAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_HISTORY_PROFESSEUR,
      data: null,
    });
  };
}
