import { fetchData } from "../base/functions/Functions";
import { trainingPreview } from "../data-structures/interfaces/trainings.interfaces";
import axiosInstance from "../helpers/interceptors";

let followUpBaseUrl = process.env.REACT_APP_BASE_URL3;

export const getListCoursesApi = async (filter: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL14;
  let coursesList = await fetchData("POST", baseUrl + `training/allT`, filter).then(res => { return res }).catch(err => { return { data: [] } });
  return coursesList;
}

export const updateIndexTraining = async (list: any) => {

  let baseURL = process.env.REACT_APP_BASE_URL;
  const data = await axiosInstance
    .post(baseURL + "training/updateIndexTraining", list)
    .then((response) => {
      return response.data;
    });
  return data;
};

const sortByOrder = (a: any, b: any) => {
  if (a?.index < b?.index) {
    return -1;
  }
  if (a?.index > b?.index) {
    return 1;
  }
  return 0;
}

export const formatTrainingSection = (course: any, courseProgress: any) => {
  let formatted = [...[...course?.orders, ...course?.exams].sort(sortByOrder).map((section: any) => {
    let progress = null;
    if (courseProgress && courseProgress?.progressChapters) {
      if (!!section?.chapter) {
        const object = courseProgress?.progressChapters.find((obj: any) => obj?.chapterId === section?.chapter?.id);
        if (object) {
          progress = object;
        }
      } else {

        const object = courseProgress?.progressQna?.find((obj: any) => obj?.idQna === section?.id);
        if (object) {
          progress = object;
        }
      }
    }
    let content = !!section?.chapter ? { ...section?.chapter, type: 'CHAPTER', progress: progress } : { ...section, type: section?.qnaType, progress: progress }
    let index = !!section?.chapter ? getChapterTrainingIndex(section.id, course?.orders) : -1
    return {
      ...content, index: index
    }
  })]
  if (course?.isDiplomat) {
    formatted.push({ title: 'diploma', index: (course?.orders?.length + course?.exams?.length + 1), type: "diploma" })
  }
  return formatted;
}

export const getChapterTrainingIndex = (chapterId: number, chaptersList: any) => {
  return chaptersList.sort(sortByOrder).indexOf(chaptersList.find((item: any) => item.chapter.id === chapterId)) + 1
}

export const getTrainingPreviewDetails = async (filter: any): Promise<trainingPreview> => {  
  const training = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}training/previewTraining`, filter);
  return training;
}

export const updateVisit = (idCourse: number) => {
  fetch(`${process.env.REACT_APP_BASE_ABSTRACT}?api_key=${process.env.REACT_APP_BASE_ABSTRACT_LOCALISATION_API_KEY}`)
    .then((data: any) => {
      if (data.ip_address != '') {
        fetchData('POST', `${process.env.REACT_APP_BASE_URL2}visit/info`, { address: data.ip_address, idCourse: idCourse })
      }
    });
}

export const avisClicked = () => {
  document.getElementById('clickLink')?.click();
}


export const fetchFeedbacks = async (courseId: number) => {
  let feedbacks: any = await fetchData("POST", process.env.REACT_APP_BASE_URL8 + "feedback/all/" + courseId, {}).then(res => {
    return res
  })
    .catch(err => { return { feedbacks: [] } })
  let listPersonId: any = feedbacks?.feedbacks.filter((filter: any) => filter.userStatus === 'USER').map((obj: any) => { return obj.idPerson })

  const listPerson = await fetchData('POST', process.env.REACT_APP_BASE_URL2 + "person/student", listPersonId).then(response => { return response })

  feedbacks['feedbacks'] = feedbacks.feedbacks.map((obj: any) => {
    let idPer = obj.idPerson;
    const person = listPerson.find((personItem: any) => personItem.id === Number(idPer));

    if (person) {

      obj.idPerson = person;
    }
    return obj;
  })
  return feedbacks;
}
export const getTrainingDetails = async (filter: any): Promise<trainingPreview> => {
  const training = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}training/getTraining`, filter);
  return training;
}

export const fetchOverallRatingPerCourseApi = async (courseIds: any[]) => {
  try {
    const response = await fetchData("POST", process.env.REACT_APP_BASE_URL8 + "feedback/rating/", { ids: courseIds });
    return response
  } catch (err) {
    return { rating: 0 };
  }
};

export const fetchListTrainingForSelectInput = async (filter: any) => {
  const selectOptions = [{ value: "all", label: 'Toutes les formations' }];
  try {
    const response = await fetchData("POST", process.env.REACT_APP_BASE_URL + "training/getTrainingsBreveDetails", filter);
    response?.filter((a: any) => !!a.orders).map((obj: any) => {
      selectOptions.push({ value: obj.id, label: obj.title });
    });
    return selectOptions
  } catch (err) {
    return selectOptions;
  }
}


export const updateLastStudentProgress =(newIndex:number, followUpId:number)=>{
    fetchData("POST", followUpBaseUrl + "progress/updateIndexTraining/"+followUpId+"/"+newIndex, {});
}
