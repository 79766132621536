import { FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import TooltipCustomized from '../../UI/TooltipCustomized';
import { getRegistrationForm, getApplicationFile, clearApplicationFile, getStudentById, getCourse, updateShow, sendMyRequest } from '../../global-state/actions/course-actions';
import { saveReturnPath } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import axios from "../../helpers/interceptors"
import { refreshToken } from "../../global-state/actions/auth-actions";
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { log } from "console";
import { clearCurrentCourse, getCurrentTraining, checkProgressCourse, getCurrentCourse } from "../../global-state/actions/student-training-actions";
import { fetchData } from "../../base/functions/Functions";
const CARD_OPTIONS: any = {
  showIcon: true,
  iconStyle: "solid",
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      iconColor: '#E06A6A',
      color: '#272727',
    },
  },
};
const CARD_OPTIONS_EXP = {
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      color: '#272727',
    },
  },
};
const CARD_OPTIONS_CVC = {
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      color: '#272727',
    },
  },
};
interface Props {
  priceCoupon: any,
  couponReference: any,
  valide: any,
  offerId: any,
  pricings: any,
  trainingoffer: any,
  actualPricings: any,
  courseInfo: any
}

const CheckoutStripeComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory()
  const [errorCVC, setErrorCVC] = useState(undefined);
  const [msg, setMsg] = useState("");
  const [errorCardNumber, setErrorCardNumber] = useState(undefined);
  const [errorDateExp, setErrorDateExp] = useState(undefined);
  const stripe = useStripe();
  const [loading, setLoading] = useState<boolean>(false);
  const elements = useElements();
  const [checked, setChecked] = useState<any>(true)
  const [multiplePayment, setMultiplePayment] = useState(false)
  const [price, setPrice] = useState()
  const location = useLocation();
  const currentPath = location.pathname;
  const [training, setTraining] = useState<any>()

  useEffect(() => {    
      setPrice(props?.priceCoupon)
  }, [props?.priceCoupon])

  useEffect(() => {
    if (currentPath.includes("COURSE")) {
      setTraining(props?.courseInfo)
    } else {
      setTraining(props?.training)
    }
  }, [currentPath])
  


  let userId : number = useMemo(() => props?.connectedUser?.idPerson, [props?.connectedUser?.idPerson])
  let schoolId : number = useMemo(() => props?.connectedUser?.idSchool, [props?.connectedUser?.idSchool])
  let userMailAddress : string = useMemo(() => props?.connectedUserInfo?.email , [props?.connectedUserInfo?.email])

  useEffect(() => {
      if(props?.trainingoffer?.pricings?.find((item: any) => item?.nbPeriod != null)){
        setMultiplePayment(true);
      }
  }, [props?.trainingoffer])

  const handleSubmit = async (event: any) => {
    try {
      setMsg("");
      setLoading(true);
      event.preventDefault();
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      const promise = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)!
      });

      if (!promise.paymentMethod) {
        setLoading(false);
        return;
      }
      if (!!props.actualPricings && props?.actualPricings?.period != "ONESHOT" && props?.actualPricings?.nbPeriod === null) {
        await handleSubscription(promise)

      }
      if (!!props.actualPricings && props?.actualPricings?.period != "ONESHOT" && props?.actualPricings?.nbPeriod !== null) {
        await handleSchedle(promise)

      }
      if (!!props?.actualPricings && props?.actualPricings?.period === "ONESHOT") {
        await handleUnique(promise)
      }
      setLoading(false);
      setChecked(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleSubscription = async (promise: any) => {

    const { id, email } = props?.connectedUserInfo;
    const [responsePrice, response] = await Promise.all([
      axios.get(process.env.REACT_APP_BASE_URL13 + `paymentsetting/getPaymentSetting/${props?.actualPricings?.id}/PRICE`),
      axios.post(process.env.REACT_APP_BASE_URL15 + 'strip/create-customer', { id, email, accountStripe: props?.paymentSchool.accountId })
    ]);

    const enginePriceId = responsePrice.data.engineId;
    const { id: customerId } = response.data;
    const [reponseSetting, responsePayment] = await Promise.all([

      axios.post(process.env.REACT_APP_BASE_URL13 + 'paymentsetting/createSetting/', {
        "subjectId": props?.connectedUserInfo?.id,
        "subjectType": "CUSTOMER",
        "enginePaymentId": customerId
      }),
      axios.post(process.env.REACT_APP_BASE_URL15 + `strip/update-payment/${responsePrice.data.engineId}/${customerId}/${promise.paymentMethod.id}`, {

        "stripeAccount": props?.paymentSchool.accountId,

      }),
    ]);

    let status = (responsePayment.data.codeDescription === null || responsePayment.data.codeDescription === undefined || responsePayment.data.codeDescription === "") ? responsePayment.data.code : responsePayment.data.codeDescription

    if (status === "generic_decline" || status === "insufficient_funds" || status === "lost_card" || status === "expired_card" || status === "incorrect_cvc" || status === "processing_error" || status === "stolen_card") {
      setLoading(false);
      setMsg(t(status))
      await axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
        status: "REFUSED",
        amount: props?.actualPricing?.price,
        pricingsId: props?.actualPricings?.id,
        stripeEmail: (userMailAddress !==undefined && userMailAddress!="") ? userMailAddress :props?.connectedUserInfo?.email,
        entityId: schoolId !==undefined ? schoolId : props?.connectedUser?.idSchool,
        personId: userId!==undefined ? userId :props?.connectedUser?.idPerson,
        currency: "eur",
        stripeToken: promise.paymentMethod?.id,
        offerId: props?.offerId
      }).then(resp => { return resp.data }).catch(err => console.error(err));
      await props?.openModal({ idModal: ModalIDS.FAILED_PAYMENT, size: "md", lines: [t('Yourpaymenthasbeendeclined')], path: ``, buttonText: "Annuler" });
    }
    else {
      const [responseSubscription, finalResponse] = await Promise.all([

        axios.post(process.env.REACT_APP_BASE_URL15 + `strip/create-subscription/${enginePriceId}/${customerId}`, {
          stripeAccount: props?.paymentSchool.accountId,

        }),
        axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
          status: "PAYED",
          amount: props?.actualPricing?.price,
          pricingsId: props?.actualPricings?.id,
          entityId: props?.connectedUser?.idSchool,
          stripeEmail: props?.connectedUserInfo?.email,
          personId: props?.connectedUser?.idPerson,
          currency: "eur",
          stripeToken: promise.paymentMethod?.id,
          offerId: props?.offerId
        })
      ])

      if (responseSubscription?.data?.nextAction) {
        let data = await stripe?.confirmCardPayment(responseSubscription?.data?.clientSecret);
        if (data?.paymentIntent?.status === "succeeded") {
          await handleContract(responseSubscription)

        } else {
          setLoading(false);
          await axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
            status: "REFUSED",
            amount: props?.actualPricing?.price,
            pricingsId: props?.actualPricings?.id,
            stripeEmail: props?.connectedUserInfo?.email,
            entityId: props?.connectedUser?.idSchool,
            personId: props?.connectedUser?.idPerson,
            currency: "eur",
            stripeToken: promise.paymentMethod?.id,
            offerId: props?.offerId
          }).then(resp => { return resp.data }).catch(err => console.error(err));
          await props?.openModal({ idModal: ModalIDS.FAILED_PAYMENT, size: "md", lines: [t('Yourpaymenthasbeendeclined')], path: ``, buttonText: "Annuler" });
        }
      }else {
        await handleContract(responseSubscription)
      }
    }
  }

  const handleSchedle = async (promise: any) => {
    const { id, email } = props?.connectedUserInfo;
    const [responsePrice, response] = await Promise.all([
      axios.get(process.env.REACT_APP_BASE_URL13 + `paymentsetting/getPaymentSetting/${props?.actualPricings?.id}/PRICE`),
      axios.post(process.env.REACT_APP_BASE_URL15 + 'strip/create-customer', { id, email, accountStripe: props?.paymentSchool.accountId })
    ]);

    const enginePriceId = responsePrice.data.engineId;
    const { id: customerId } = response.data;
    const [reponseSetting, responsePayment] = await Promise.all([

      axios.post(process.env.REACT_APP_BASE_URL13 + 'paymentsetting/createSetting/', {
        "subjectId": props?.connectedUserInfo?.id,
        "subjectType": "CUSTOMER",
        "enginePaymentId": customerId
      }),
      axios.post(process.env.REACT_APP_BASE_URL15 + `strip/update-payment/${responsePrice.data.engineId}/${customerId}/${promise.paymentMethod.id}`, {
        stripeAccount: props?.paymentSchool.accountId,
      }),
    ]);
    let status = (responsePayment.data.codeDescription === null || responsePayment.data.codeDescription === undefined || responsePayment.data.codeDescription === "") ? responsePayment.data.code : responsePayment.data.codeDescription

    if (status === "generic_decline" || status === "insufficient_funds" || status === "lost_card" || status === "expired_card" || status === "incorrect_cvc" || status === "processing_error" || status === "stolen_card") {
      setLoading(false);
      setMsg(t(status))
      await axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
        status: "REFUSED",
        amount: props?.actualPricing?.price,
        pricingsId: props?.actualPricings?.id,
        stripeEmail: props?.connectedUserInfo?.email,
        entityId: props?.connectedUser?.idSchool,
        personId: props?.connectedUser?.idPerson,
        currency: "eur",
        stripeToken: promise.paymentMethod?.id,
        offerId: props?.offerId
      }).then(resp => { return resp.data }).catch(err => console.error(err));
      await props?.openModal({ idModal: ModalIDS.FAILED_PAYMENT, size: "md", lines: [t('Yourpaymenthasbeendeclined')], path: ``, buttonText: "Annuler" });
    }
    else {
      const [responseSchedle, finalResponse] = await Promise.all([

        axios.post(process.env.REACT_APP_BASE_URL15 + `strip/create-schedule/${enginePriceId}/${customerId}`, {
          nbPeriod: props?.actualPricings?.nbPeriod,
          stripeAccount: props?.paymentSchool.accountId,

        }),
        axios.post(process.env.REACT_APP_BASE_URL13 + `payment/createPayment`, {
          status: "PAYED",
          amount: props?.actualPricing?.price,
          pricingsId: props?.actualPricings?.id,
          stripeEmail: props?.connectedUserInfo?.email,
          entityId: props?.connectedUser?.idSchool,
          personId: props?.connectedUser?.idPerson,
          currency: "eur",
          stripeToken: promise.paymentMethod?.id,
          offerId: props?.offerId
        })
      ])
      await handleContract(responseSchedle)
    }
  }

  const handleContract = async (responseSubscription: any) => {

    let payload = {
      contractIdentifier: {
        contractId: null,
        contractReference: null
      },
      contractDetails: {
        offerId: props?.trainingoffer?.id,
        contractDuration: {
          startAt: new Date(Date.now()),
          endAt: null,
        },
        pricingId: props?.actualPricings?.id,
        paymentMethod: "CARD",
        paymentEngine: "STRIPE"
      },
      contractRoles: [{
        personId: props?.connectedUser?.idPerson,
        roleType: "STUDENT"
      }],
      status: [{
        statusType: "ONGOING"
      }]
    };
    const createdContractContract = await fetchData('POST', process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract", payload)    
    if (!!responseSubscription && responseSubscription !== "") {
      await fetchData('POST', process.env.REACT_APP_BASE_URL13 + 'paymentsetting/createSetting/', {
        subjectId: createdContractContract?.data?.contractIdentifier?.contractId,
        subjectType: "CONTRACT",
        enginePaymentId: responseSubscription.data.subscriptionId
      })
    }
    let follow = {
      courseId: training?.id,
      studentId: props?.connectedUser?.idPerson,
      trainingId: training?.id,
      trainingType: training?.type,
      entityId: training?.entityId,
      entityType: training?.entityType,
      statusList: [{
        status: "INSCRIBED"
      }],
      contractReference: createdContractContract?.contractIdentifier?.contractReference
    }

    const createdFollow = await fetchData('POST', process.env.REACT_APP_BASE_URL14 + "training", follow)    
    await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
    if (training.type === "COURSE") {
      await props?.getCurrentCourse(Number(createdFollow[0]?.id));
      await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passCourse/course/${createdFollow[0]?.id}`, buttonText: t('StartTheCourse') });
    }
    else if (training?.type === "PATH") {
      await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passPath/progressPath/${createdFollow[0]?.id}`, buttonText: t('StartThePath') });
    } else {
      await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passSession/progressSession/${createdFollow[0]?.id}`, buttonText: t('StartTheSession') });
    }
    await props?.updateShow(false)


    // if (props?.connectedUser?.role?.includes("role_student")) {
    //   axios.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract", {
    //     contractIdentifier: {
    //       contractId: null,
    //       contractReference: null
    //     },
    //     contractDetails: {
    //       offerId: props?.trainingoffer?.id,
    //       contractDuration: {
    //         startAt: new Date(Date.now()),
    //         endAt: null,
    //       },
    //       pricingId: props?.actualPricings?.id,
    //       paymentMethod: "CARD",
    //       paymentEngine: "STRIPE"
    //     },
    //     contractRoles: [{
    //       personId: props?.connectedUser?.idPerson,
    //       roleType: "STUDENT"
    //     }],
    //     status: [{
    //       statusType: "ONGOING"
    //     }]
    //   }).then(async (response: any) => {

    //     if (!!responseSubscription && responseSubscription !== "") {


    //       await Promise.all([
    //         axios.post(process.env.REACT_APP_BASE_URL13 + 'paymentsetting/createSetting/', {
    //           subjectId: response?.data?.contractIdentifier?.contractId,
    //           subjectType: "CONTRACT",
    //           enginePaymentId: responseSubscription.data.subscriptionId
    //         }),
    //       ]);
    //     }
    //   }).then((createdContract: any) => {

    //     let follow = {
    //       courseId: training?.id,
    //       studentId: props?.connectedUser?.idPerson,
    //       trainingId: training?.id,
    //       trainingType: training?.type,
    //       entityId: training?.entityId,
    //       entityType: training?.entityType,
    //       statusList: [{
    //         status: "INSCRIBED"
    //       }],
    //       contractReference: createdContract?.data?.contractIdentifier?.contractReference
    //     }
        
    //     axios.post(process.env.REACT_APP_BASE_URL14 + "training", follow)
    //       .then(async (response3: any) => {
    //         props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
    //         if (training.type === "COURSE") {
    //           await props?.getCurrentCourse(Number(training?.id));
    //           //await props?.checkProgressCourse(response3?.data, props?.courseInfo?.id, props?.connectedUser?.idPerson, true, props?.courseInfo);
    //           await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passCourse/course/${response3?.data[0]?.id}`, buttonText: t('StartTheCourse') });

    //         }
    //         else if (training?.type === "PATH") {
    //           await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passPath/progressPath/${response3?.data[0]?.id}`, buttonText: t('StartThePath') });


    //         } else {
    //           await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [t('Yourpaymentwassuccessful')], path: `${returnStartPathUrl()}/passSession/progressSession/${response3?.data[0]?.id}`, buttonText: t('StartTheSession') });
    //         }
    //       })
    //       .catch(err => console.error(err));
    //   }).catch(err => { console.error(err); }
    //   )
    //   props?.updateShow(false)
    // }
    // else {
    //   props?.saveReturnPath({ path: `${returnStartPathUrl()}/courses/my-courses/`, tabPage: '' });
    //   history.push(`${returnStartPathUrl()}/auth/login`);
    // }

  }

  const handleUnique = async (promise: any) => {
    let idPerson = props?.connectedUser?.idPerson;
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl2 = process.env.REACT_APP_BASE_URL13
    let pricingsId = props?.actualPricings?.id;
    let amount = props?.actualPricings?.price;
    let emailStripe = await axios.get(baseUrl1 + `person/email/` + props?.connectedUser?.idPerson).then(res3 => { return res3.data }).catch(err => console.error(err));
    let currency = props?.actualPricings?.currency;

    let payment = await axios.post(process.env.REACT_APP_BASE_URL13 + `payment/handleStrip`, {
      status: "",
      amount: !!props?.priceCoupon && props?.priceCoupon > 0 ? props?.priceCoupon : Number(amount),
      pricingsId: pricingsId,
      stripeEmail: emailStripe,
      personId: idPerson,
      currency: currency,
      stripeAccount: props?.paymentSchool.accountId,
      stripeToken: promise.paymentMethod?.id,
      offerId: props?.offerId
    }).then(response2 => { return response2.data }).catch(err => console.error(err))

    let status = (payment.codeDescription === null || payment.codeDescription === undefined || payment.codeDescription === "") ? payment.code : payment.codeDescription

    if (status === "generic_decline" || status === "insufficient_funds" || status === "lost_card" || status === "expired_card" || status === "incorrect_cvc" || status === "processing_error" || status === "stolen_card") {
      setLoading(false);
      setMsg(t(status))
      await axios.post(baseUrl2 + `payment/createPayment`, {
        status: "REFUSED",
        amount: !!props?.priceCoupon ? props?.priceCoupon : Number(amount),
        pricingsId: pricingsId,
        stripeEmail: emailStripe,
        entityId: props?.connectedUser?.idSchool,
        personId: idPerson,
        currency: currency,
        stripeToken: "",
        offerId: props?.offerId
      }).then(resp => { return resp.data }).catch(err => console.error(err));
      await props?.openModal({ idModal: ModalIDS.FAILED_PAYMENT, size: "md", lines: [t('Yourpaymenthasbeendeclined')], path: ``, buttonText: "Annuler" });
    }
    else {
      let data = await stripe?.confirmCardPayment(payment?.code);
      if (data?.paymentIntent?.status === "succeeded") {

        setLoading(false);
        axios.post(baseUrl2 + `payment/createPayment`, {
          status: "PAYED",
          amount: !!props?.priceCoupon && props?.priceCoupon > 0 ? props?.priceCoupon : Number(amount),
          pricingsId: pricingsId,
          stripeEmail: emailStripe,
          entityId: props?.connectedUser?.idSchool,
          personId: idPerson,
          currency: currency,
          stripeToken: promise.paymentMethod?.id,
          offerId: props?.offerId
        }).then(async () => {
          if (!!props?.priceCoupon) {
            await axios.post(`${process.env.REACT_APP_BASE_URL22}coupons/` + props?.couponReference + "/" + props?.connectedUser?.idPerson + "/create")

          }

        }).catch(err => console.error(err));

        await handleContract("")

      } else {

        setLoading(false);
        await axios.post(baseUrl2 + `payment/createPayment`, {
          status: "REFUSED",
          amount: !!props?.priceCoupon ? props?.priceCoupon : Number(amount),
          pricingsId: pricingsId,
          stripeEmail: emailStripe,
          entityId: props?.connectedUser?.idSchool,
          personId: idPerson,
          currency: currency,
          stripeToken: "",
          offerId: props?.offerId
        }).then(resp => { return resp.data }).catch(err => console.error(err));
        await props?.openModal({ idModal: ModalIDS.FAILED_PAYMENT, size: "md", lines: [t('Yourpaymenthasbeendeclined')], path: ``, buttonText: "Annuler" });
      }
    }
  }
  return (<>


    <div style={{ width: "100%" }} onSubmit={handleSubmit} className={price===0 ? " disabledbutton":"bg-with-border" } id={"stripe-pay"}>

      <h3 className="H3-Headline mb-3">{t('PaymentDetails')}</h3>
      <p className="body-xl mb-3">{t('Completeyourpurchasebyprovidingyourpaymentinformation')}</p>
      <div className="d-flex flex-column ml-3 mb-4">
        <label className="form-title">{t('LastNameandFirstName')}</label>
        <input dir="ltr" disabled type="text" placeholder={props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName} className="form-control input text-default" />
      </div>
      <div className="ml-3 mb-4">
        <label className="form-title mb-2">{t('CreditCardNumber')}</label>
        <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorCardNumber) ? "#F2F2F5" : "#fae9e9" }}>
          <CardNumberElement onChange={(e: any) => setErrorCardNumber(e.error)} options={CARD_OPTIONS} />
        </div>
      </div>
      <div className="ml-3 d-flex align-items-center justify-content-between flew-wrap ">
        <div className="w-100 me-2">
          <label className="form-title mb-2">{t('ExpirationDate')}</label>
          <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorDateExp) ? "#F2F2F5" : "#fae9e9" }}>
            <CardExpiryElement onChange={(e: any) => setErrorDateExp(e.error)} options={CARD_OPTIONS_EXP} />
          </div>
        </div>
        <div className="w-100 ml-3 ">
          <label className="form-title mb-2">CVC</label>
          <div className=" px-3 py-3" style={{ position: 'relative', borderRadius: '3px', backgroundColor: (!errorCVC) ? "#F2F2F5" : "#fae9e9" }}>
            <div className="w-60">
              <CardCvcElement onChange={(e: any) => setErrorCVC(e.error)} options={CARD_OPTIONS_CVC} />
            </div>

          </div>

        </div>

      </div>
      {(msg !== "") && <div className="w-100 ml-3 mt-2 ">
        <label className="body-error bold red-800 mb-2"> {msg}</label>
      </div>}

     
      
      {/* mobile */}
      <div className="d-block d-lg-none" style={{ width:"100%",height: "148px"}} />
      <div className="d-block d-lg-none d-flex justify-content-center align-items-center fixed-bottom mt-4" style={{ width:"100%",height: "112px", backgroundColor:"white"}} >
          {!loading ?
            <button type="button" style={{width:"50%", height:"48px"}} disabled={errorCVC || errorCardNumber || errorDateExp || !checked} className="btn-Primary large" onClick={handleSubmit}>
              Payer
            </button>
            : <button className="btn-Primary large " style={{width:"50%"}}>
              <div className="threedots-large-Animation">
                {t('traitement')}
              </div>
          </button> }
        
      </div>

      {/* Web */}

     

    </div>
    {price != 0 ? <div className="d-none d-lg-block" >
      {!loading ?
        <button type="button" disabled={errorCVC || errorCardNumber || errorDateExp || !checked} className="btn-Primary large mt-4 w-100" onClick={handleSubmit}>
          {t('confirmandpay')}
        </button>
        : <button className="btn-Primary large mt-4 w-100">
          <div className="threedots-large-Animation">
            {t('traitement')}
          </div>
        </button>}
      </div> :
      <div className="d-none d-lg-block" >
        {price!==0 && <button type="button" className="btn-Primary large mt-4 w-100" onClick={()=>handleContract("")}>
          Commencer le { training?.type==="COURSE"?"cours":(training?.type==="PATH"?"parcours":"session")}
        </button>}
      </div>
      }
  </>

  )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({

  connectedUser: state.authReducer.connectedUser,
  loggedIn: state.authReducer.loggedIn,
  applicationFile: state.courseReducer.applicationFile,
  follow: state.courseReducer.follow,
  registration: state.courseReducer.registration,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  paymentSchool: state.schoolInfoReducer.paymentSchoolInfo,
  connectedUserInfo: state.authReducer.connectedUserInfo,
  training: state.courseReducer.training,
  courseInfo: state.courseReducer.courseInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal, refreshToken, updateShow, getRegistrationForm, getApplicationFile, clearApplicationFile, getStudentById, getCourse, saveReturnPath, sendMyRequest,
        clearCurrentCourse,
        getCurrentTraining,
        checkProgressCourse,
        getCurrentCourse
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStripeComponent);
