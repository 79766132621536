import { FC } from "react";
import CustomSwitch from "../../../helpers/custom-switch";
import RouteWithNavbar from "../../../navigations/RouteWIthNavbar";
import SessionNotFoundPage from "./session/session-not-found-page";
import PathNotFoundPage from "./path/path-not-found-page";
interface Params {
    id: any
}

const FormationNotFoundCreationContainer: FC<any> = (props: any) => {
  return (<>
      <CustomSwitch>
      <RouteWithNavbar history={props?.history} path={`${props?.match.path}/session`} exact={true} component={SessionNotFoundPage} />
      <RouteWithNavbar history={props?.history} path={`${props?.match.path}/path`} exact={true} component={PathNotFoundPage} />
      </CustomSwitch>
  </>
  )
}
export default FormationNotFoundCreationContainer;