import { EReduxActionTypes, IReduxCreatePostAction, IReduxSetOpenChatAction ,IReduxSetActualTabChatAction, IReduxCreateResponseAction, IReduxGetPostsCommunityAction, IReduxGetSubjectAction, IReduxSetActualTabQuestionAction, IReduxUpdateListDiscussionAction, IReduxGetQuestionsByTypeAction, IReduxGetFilteredQuestionsAction, IReduxCreateSeensAction, IReduxGetQuestionsForAssistantAction } from '../actions/chat-actions';

export interface IComment {
    id: string | null,
    description: string
}
export interface IResponse {
    id: number | null,
    idAuthor: string,
    type: string,
    createdAt: string
    comment: IComment,
}
export interface IPost {
    id: number | null,
    idAuthor: string,
    title: string,
    type: string,
    createdAt: string
    comment: IComment,
    subject: ISubject,
    responses: IResponse[],
   

}
export interface ISubject {
    id: number | null,
    idSubject: string,
    category: string,
    type: string,
    status: string,
   
   

}

export interface IReduxChatState {
    tabQuestionManager: string,
    tabChatManager: string,
    listPosts:any[],
    listQuestions: any,
    subject?:ISubject,
    loadingQuestions: boolean,
    loadingPosts:boolean,
    refreshPosts:boolean,
    openChat:boolean,
    assisatantQeustions:any

}
const  initialState: IReduxChatState = {
    tabQuestionManager:'unsettled',
    tabChatManager: 'discussion',
    listPosts: [],
    listQuestions:undefined,
    subject:undefined,
    loadingQuestions: true,
    loadingPosts: true,
    refreshPosts:false,
    openChat:false,
    assisatantQeustions:undefined
}
type ChatReducerActions = IReduxCreatePostAction | IReduxSetOpenChatAction | IReduxSetActualTabChatAction | IReduxCreateResponseAction | IReduxGetPostsCommunityAction |IReduxGetSubjectAction  | IReduxSetActualTabQuestionAction | IReduxGetQuestionsByTypeAction 
| IReduxUpdateListDiscussionAction | IReduxGetFilteredQuestionsAction | IReduxCreateSeensAction |IReduxGetQuestionsForAssistantAction

export default function (state: IReduxChatState = initialState, action: ChatReducerActions) {
    switch (action.type) {
        case EReduxActionTypes.GET_QUESTIONS_FOR_ASSISTANTS:
            return {...state, listQuestions: action.data.liste}
        case EReduxActionTypes.LOADING_QUESTION:
            return {...state, loadingQuestions: true}
        case EReduxActionTypes.LOADING_POSTS:
            return {...state, loadingPosts: true}
        case EReduxActionTypes.REFRESH_POSTS:
            return {...state, refreshPosts: true}
        case EReduxActionTypes.SET_OPEN_CHAT:
            return {...state ,openChat: action.data }
        case EReduxActionTypes.CREATE_POST:
            let listPost = [...state.listPosts]
            listPost.unshift(action.data.post)
            return { ...state, listPosts:listPost };
        case EReduxActionTypes.CREATE_RESPONSE:
                if(action.data.what==="unsettled") {
                    let list = [...state.listQuestions.listUnsettledQuestions]
                    let listsettled= [...state.listQuestions.listSettledQuestions]
                    let listAll= [...state.listQuestions.allQuestions]
                    let statistic= {...state.listQuestions.statistic}
                const indexPost =list.findIndex((postItem:any) => postItem.id === action.data.response.postId);
                const post =list.find((item:any) => item.id === action.data.response.postId);

                if(indexPost !== -1) {
                    list.splice(indexPost,1);
                    statistic.totalUnsettledQuestion=statistic.totalUnsettledQuestion-1;
                    statistic.totalSettledQuestion=statistic.totalSettledQuestion+1;
                    post.responses.push(action.data.response);
                    const indexListAll = listAll.findIndex(obj => obj.id === action.data.response.postId);
                    if(indexListAll !== -1) {
                        listAll[indexListAll] = post;
                    }
                    listsettled.push(post);
                    listsettled = listsettled.sort((a, b) => {
                        if(a.createdAt < b.createdAt) {return 1;}
                        else if(a.createdAt > b.createdAt) {return -1;}
                        else {return 0;}
                    })
                    }
                    return{ ...state, listQuestions:{...state.listQuestions,listSettledQuestions: listsettled, listUnsettledQuestions:list,allQuestions:listAll, statistic:statistic }}
                
                }else if(action.data.what==="settled"|| action.data.what==="all") {
                    let listsettled= [...state.listQuestions.listSettledQuestions]
                    let listAllQuestion=[...state.listQuestions.allQuestions]
                    const indexPostinAllList=listAllQuestion.findIndex((post:any) => post.id === action.data.response.postId);
                    const indexPost =listsettled.findIndex((post:any) => post.id === action.data.response.postId);
                    if(indexPostinAllList !== -1) {
                        listAllQuestion[indexPostinAllList]?.responses?.push(action.data.response);
                    }
                    if(indexPost !== -1) {
                        listsettled[indexPost]?.responses?.push(action.data.response);
                    }
                    return{ ...state, listQuestions:{...state.listQuestions, listSettledQuestions: listsettled, allQuestions:listAllQuestion}}
                }else {
                let listSubject = [...state.listPosts]
                const indexPost =listSubject.findIndex((post:any) => post.id === action.data.response.postId);
                if(indexPost !== -1) {
                    listSubject[indexPost]?.responses?.push(action.data.response);
                }
             return {...state, listPosts:listSubject }
            }
        case EReduxActionTypes.SET_ACTUAL_TAB_CHAT:
            return { ...state, tabChatManager: action.data }
        case EReduxActionTypes.SET_ACTUAL_TAB_QUESTION:
            return { ...state, tabQuestionManager: action.data }
        case EReduxActionTypes.GET_POSTS_COMMUNITY:
            if(action.data.liste.length===0){
                return{...state, listPosts: [] , refreshPosts: false, loadingPosts: false}
            } else 
            if(action.data.isSearch && !action.data.refresh) {
                return{...state, listPosts: action.data.liste , loadingPosts: false}
            } else if(action.data.isSearch && action.data.refresh){
                return{...state, listPosts: action.data.liste , refreshPosts: false, loadingPosts: false}
            }else if (action.data.chat && action.data.chat === true) {
                let listConcat = [...state.listPosts];
                listConcat = listConcat.concat(action.data.liste);
                return{...state, listPosts: listConcat}
            } else {
                let listConcat = [...state.listPosts];
                listConcat = listConcat.concat(action.data.liste);
                return{...state, listPosts: listConcat, loadingPosts: false}
            }
        case EReduxActionTypes.GET_SUBJECT:
            return{...state, subject: action.data}
        case EReduxActionTypes.GET_QUESTION_BY_TYPE:
                if(action.data.isSearch){
                    return{...state, listQuestions: action.data.liste, loadingQuestions:false }
                }else {
                    let listConcat = {...state.listQuestions};
                listConcat = listConcat.concat(action.data.liste);
                return{...state, listQuestions: listConcat, loadingQuestions:false}
                }
        case EReduxActionTypes.GET_FILTERED_QUESTIONS:
             if(action.data.isSearch){
                const listQuestionsCopy = {...state.listQuestions};
                switch(action.data.tab) {
                    case "all":
                        listQuestionsCopy.allQuestions = action.data.filteredQuestions;
                        break;
                    case "settled":
                        listQuestionsCopy.listSettledQuestions = action.data.filteredQuestions;
                        break;
                    case "unsettled":
                        listQuestionsCopy.listUnsettledQuestions = action.data.filteredQuestions;
                        break;
                    default:
                        break;
                }
                return{...state, listQuestions: listQuestionsCopy}
            }else {
                const listQuestionsConcat = {...state.listQuestions};
                switch(action.data.tab) {
                    case "all":
                        listQuestionsConcat.allQuestions = listQuestionsConcat.allQuestions.concat(action.data.filteredQuestions);
                        break;
                    case "settled":
                        listQuestionsConcat.listSettledQuestions =listQuestionsConcat.listSettledQuestions.concat(action.data.filteredQuestions);
                        break;
                    case "unsettled":
                        listQuestionsConcat.listUnsettledQuestions = listQuestionsConcat.listUnsettledQuestions.concat(action.data.filteredQuestions);
                        break;
                    default:
                        break;
                }
                return{...state, listQuestions: listQuestionsConcat}
            }
          
        case EReduxActionTypes.CREATE_SEENS:
            
            if(action.data.what==="settled" || action.data.what==="all"){
                let listsettled= [...state.listQuestions.listSettledQuestions]
                let listAll= [...state.listQuestions.allQuestions]
                listsettled= listsettled.map((obj:any)=>{
                    obj.responses.map((res:any)=>{
                        let seen = action.data.seens.find((seenItem: any)=>{return Number(seenItem.postId)===Number(res.id)})
                        if(seen){
                            res.seens.push(seen);
                        }
                        return res;
                    })
                    return obj;
                })
                listAll= listAll.map((obj:any)=>{
                    obj.responses.map((res:any)=>{
                        let seen = action.data.seens.find((seensItem: any)=>{return Number(seensItem.postId)===Number(res.id)})
                        if(seen){
                            res.seens.push(seen);
                        }
                        return res;
                    })
                    return obj;
                }) 
                return {...state,listQuestions:{...state.listQuestions,listSettledQuestions: listsettled,allQuestions:listAll}}   
            } else {
                return {...state}
            }
        case EReduxActionTypes.UPDATE_LIST_DISCUSSION:
            let listDiscussion = [...state.listPosts];
            listDiscussion.unshift(action.data.data);
            if(listDiscussion.length > (action.data.page + 1) * 15) {
              listDiscussion.pop();
            }
            return {...state, listPosts: listDiscussion};
        default:
            return state;
    }
}