import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updatePath } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardClosedQuestionComponent from "./cards-questions/card-closed-question-component";

const KnowledgeTestFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [listQuestions, setListQuestions] = useState<any>([]);
    const [copyListQuestions, setCopyListQuestions] = useState<any>([]);
    
    useEffect(()=>{
        if(props?.testForm !== undefined ){
            setCopyListQuestions(JSON.parse(JSON.stringify(props?.testForm?.questionByQnaList)))
        }
        
    },[])

    useEffect(() => {
        setListQuestions(props?.listQuestions);
    }, [props?.listQuestions]);
    const { t } = useTranslation();
    const saveQuestion = (object: any, index: number) => {
        props?.saveQuestion(object, index);
    }

    const addQuestion = () => {
        props?.addQuestion();
    }
    return (<>
        <div style={{ margin: 0 }}>
            {listQuestions?.length === 0 ? (
                <div className="grid-container mt-20" style={{ flexDirection: "column", alignItems: "center" }} >
                    <EmptyListElement lines={[t('Créer_votre_premiere_question')]} >
                        <button className="btn-Primary large icon-left" onClick={() => addQuestion()} >
                            <span className="material-icons"> add </span>
                            <div className="m-auto ps-2">{t("Question_fermées")}</div>
                        </button>
                    </EmptyListElement>
                </div>
            ) : (
                <div className="mt-4">
                    {listQuestions?.map((question: any, index: number) => {
                        return (
                            <CardClosedQuestionComponent key={index}
                                saveQuestion={saveQuestion}
                                deleteQuestion={props?.deleteQuestion}
                                formQuestion={question}
                                indexQuestion={index}
                                initForm={copyListQuestions[index]}
                            />
                        );
                    }
                    )}
                    <button className="btn-Secondary large icon-left mb-5" onClick={() => addQuestion()} >
                        <span className="material-icons" >  add </span>
                        <div className="m-auto ps-2">{t("closedQuestion")}</div>
                    </button>

                </div>


            )}

        </div>
    </>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ updatePath }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    testForm: state.courseReducer.testForm,
    addQuestion: ownProps?.addQuestion,
    deleteQuestion: ownProps?.deleteQuestion,
    saveQuestion: ownProps?.saveQuestion,
    listQuestions: ownProps?.listQuestions
});
export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeTestFormComponent)
