


import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updatePath } from '../../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import CardClosedQuestionPreviewComponent from "./card-closed-question-preview-component";
import CardOpenQuestionPreviewComponent from "./card-open-question-preview-component";
import { sum } from "lodash";

const ExamPreviewComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [test, setTest] = useState<any>([]);
    useEffect(() => {
        setTest(props?.testForm);

    }, [props?.testForm]);
    const { t } = useTranslation();
    return (
        <div style={{ margin: 0 }}>

   <div className="pt-4 mx-2">
   <h3 className="H3-Headline" >{(props?.typeTest === "FINALEXAM") ? t('finalExmBreadcrunbTitle') : t('textConnaissance')} / {sum(test?.questionByQnaList?.map((obj: any) => {return obj?.scaleNote}))} <span className="neutral-3"> {t('points')} </span></h3>
     {test?.questionByQnaList?.map( (question: any, index: number) => {
         if (question?.question?.type === "CQ") {
            return (
              <div key={index}>
                <CardClosedQuestionPreviewComponent key={index} question={question} index={index + 1} />
              </div>
            );
          } else {
            return (
              <div key={index}>
                <CardOpenQuestionPreviewComponent
                  index={index + 1}
                  key={index}
                  question={question}
                />
              </div>
            );
          }
       }
     )}
   </div>

</div>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ updatePath }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    testForm: state.courseReducer.testForm,
    addQuestion: ownProps?.addQuestion,
    deleteQuestion: ownProps?.deleteQuestion,
    saveQuestion: ownProps?.saveQuestion,
    listQuestions: state.courseReducer.listTest,
    typeTest: state.courseReducer.typeContentCourse,
});
export default connect(mapStateToProps, mapDispatchToProps)(ExamPreviewComponent)
 