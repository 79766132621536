
import  { FC,useEffect, useRef, useState } from "react";
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';

const LargeSelect = withStyles((theme:any) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
  },
  input: {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '12px 26px 12px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      backgroundColor: '#fbfbfd!important',
      border: '1px solid #272727',
      borderRadius: 4,
      boxShadow: '0 0 0 3.5px #dadbdf',
    },
    '&:hover': {
      border: '1px solid #272727',
    },
  },
}))(InputBase);

const MeduimSelect = withStyles((theme:any) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%'
  },
  input: {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '6px 26px 6px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      backgroundColor: '#fbfbfd!important',
      border: '1px solid #272727',
      borderRadius: 4,
      boxShadow: '0 0 0 3.5px #dadbdf',
    },
    '&:hover': {
      border: '1px solid #272727',
    },
  },
}))(InputBase);
const useStyles = makeStyles((theme:any) => ({
  margin: {
    margin: theme?.spacing(0),
    width: '100%',
  },
  menuItem:{
    ' &.MuiMenuItem-root':{
      paddingTop:"1px",
      paddingBottom:"1px"
    },
    '& .MuiTypography-body1': {
      fontSize:"16px",
      fontWeight:500,
      fontFamily:"Public, sans-serif",
      fontStyle:"normal",
      color:"#272727",
      lineHeight:"24px",
      paddingButtom:"1px"
    },


}
}));

interface Props{
  taille?: "lg" | "md";
  optionsProps: Array<any>;
  status?: string;
  offerList: string[];
  setOfferList: any;
}


const CustomizedSelectsMultiple : FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [liste, setListe] = useState<any>([]);
  const [options, setOptions] = useState<Array<any>>([]);
  const[checked,setChecked]=useState<any>(false);
  const selectRef = useRef(null);
  const[primary,setPrimary]=useState<any>();

  useEffect(() => {
    setListe(props?.offerList);
  },[props.offerList])

  useEffect(() => {
    setPrimary("Toutes les formations ("+props?.optionsProps?.length+")")
    setOptions(props.optionsProps);
  }, [props.optionsProps]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event; 
    let copyList = [...liste];
    if ( event.target.value.includes("*") ) { 
      const array :any[]= [];
      
      if(checked){
        props.optionsProps.map((a:any)=>{
          array.push(a.id);
  
        })
        
      }
        copyList=array
        setListe(copyList)
        props.setOfferList(copyList)
    }else{
      copyList =  typeof value === 'string' ? value.split(',') : value;
      setListe(copyList);
      props.setOfferList(copyList)
    }
  };
  
  const handleSelect =()=>{
    let copyList = [...liste];
    setChecked(!checked)
    if(!checked){
      copyList=[]
      for(let i = 0 ; i < options.length ; i++){
        copyList.push(options[i].id)
      }
      setListe(copyList)
      props.setOfferList(copyList)
    }
    else{
      setListe([])
      props.setOfferList([])
    }
  }

  const handleSelectItem=(id:number)=>{
    let copyList = [...liste];
    if(copyList.includes(id)){
      copyList.splice(copyList.indexOf(id), 1);
      setListe(copyList)
      props.setOfferList(copyList)
    }
    else{
      copyList.push(id)
      setListe(copyList)
      props.setOfferList(copyList)
    }
  }


  return (
    <div>
    <FormControl className={classes?.margin}>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="grouped-select"
        multiple
        IconComponent={ExpandMoreOutlined}
        value={liste}
        defaultValue={liste}
        onChange={(e) => handleChange(e)}
        input={props.taille === "lg" ? <LargeSelect /> : <MeduimSelect />}
        className={classes?.margin}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        }}
        renderValue={() => {
          if (liste.length === 0) {
            return "Sélectionnez";
          }
          else{
            return liste.length +" formations sélectionnées";
          }
          
        }}
      >
        
        {
          options && options?.length!==0 ? 
          <>
            <MenuItem className={classes.menuItem} value={"*"} onClick={handleSelect} >
              <Checkbox color="default" checked={checked}  indeterminate={liste.length < options.length && liste.length !==0}/>
              <ListItemText primary={primary} />
            </MenuItem>
            {
            options?.map(option => <MenuItem className={classes.menuItem} value={option.id} onClick={()=>handleSelectItem(option.id)}>
                  <Checkbox color="default" checked={ liste.findIndex((opt:any) => opt === option.id) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ) 
            }
          </> 
          :
          <div className="d-flex w-100 align-items-center justify-content-center">
              <div className="m-3">
                  <svg className="spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                  </svg>
              </div>
          </div>
        }
         
      </Select>
    </FormControl>
  </div>
  );
};
export default CustomizedSelectsMultiple;
