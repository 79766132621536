import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import TextareaCustomparagraphDiscussionElement from '../../elements/text-area/text-area-custom-paragraph-discussion-element';
import { useTranslation } from "react-i18next";
import userLogo from '../../styles/imgs/Default_user_icon.svg';
import { getSubjectCommunity } from '../../global-state/actions/chat-actions';
import { returnDynamicValueFromUrl } from "../../helpers/domainCheck";

const AdmissionDiscussionSidbarElement: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const listPosts = useMemo(() => { return props?.listPosts }, [props?.listPosts])
  const [comment, setComment] = useState<string>()
  const [atTop, setAtTop] = useState(false)
  const [pageChat, setPageChat] = useState(0)


  useEffect(() => {

    const element: any = document.getElementById('chatSidebar');

    if (props?.openChat === true) {
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
        element.addEventListener('scroll', handleScroll, false);
      }, 50)
    }
    return () => {
      element.removeEventListener('scroll', handleScroll);
      setPageChat(0)
    }
  }, [])
  useEffect(() => {
    const element: any = document.getElementById('chatSidebar');
    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    }
  })
  const handleScroll = async () => {

    const element: any = document.getElementById('chatSidebar');

    if (!atTop && isTop(element)) {
      setAtTop(true);
      let object = {
        subject: [returnDynamicValueFromUrl(3) + " CHAT ADMISSION"],
        trie: 'recent',
        searchFilter: "",
        page: pageChat + 1,
        chat: true,
        event: "ADMISSION_CHAT",
      }
      const tempHeight = element.firstChild.clientHeight;
      if(typeof object!= undefined && object?.subject?.length!=0){
      await props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, false);
      }
      setPageChat(pageChat + 1)
      const element2: any = document.getElementById('chatSidebar');
      element.scrollTop = element2.firstChild.clientHeight - tempHeight;
    }

    if (!isTop(element)) {
      setAtTop(false);
    }
  }
  const isTop = (element: any) => {
    return element.scrollTop === 0;
  }

  const changeInputValue = (value: string) => {
    setComment(value)
  }
  const createPost = () => {
    props?.createPost(comment)
    setComment("")
  }

  const checkmessage = (index: number) => {
    if (index === listPosts?.length) {
      return 'mt-3';
    } else {
      if (listPosts[index + 1]?.idAuthor?.id !== listPosts[index]?.idAuthor?.id) {
        return 'mt-3';
      } else {
        return 'mt-1'
      }
    }
  }
  const checkImageMessage = (index: number) => {
    if ((listPosts[index]?.idAuthor?.id !== props?.connectedUser?.idPerson)
      && (listPosts[index - 1] && listPosts[index - 1]?.idAuthor?.id !== listPosts[index]?.idAuthor?.id)) {
      return true;
    } else if ((listPosts[index]?.idAuthor?.id !== props?.connectedUser?.idPerson) && (index === listPosts.length + 1)) {
      return true;
    } else if (listPosts[index]?.idAuthor?.id !== props?.connectedUser?.idPerson && index === 0) {
      return true;
    } else {
      return false
    }
  }
  const disabledFn = () => {
    return (listPosts.length === 0 && (props?.connectedUser?.role.includes("role_student")))
      || (props?.follow?.statusList?.find((status: any) =>
        new Date(status?.creationDate).getTime() === Math.max(...props?.follow?.statusList?.map((e: any) => new Date(e.creationDate)))).status === "INSCRIBED")
  }
  return (
    <>

      <div className="admission-sidebar ms-auto" id="chatSidebarParent" >
        <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
        {listPosts.length === 0 && props?.connectedUser?.role === "role_prof" &&
         <div className="ms-4 mb-4 d-flex justify-content-center align-items-center admission-sidebar-discussion">
              <div className="d-flex flex-column justify-content-center ms-4">
                <div className="mb-4 black-800 d-flex justify-content-center">
                  <span className="material-icons-outlined" style={{ fontSize: 96 }}>forum</span>
                </div>
                <div className="d-flex flex-column justify-content-center " style={{ maxWidth: 260 }}>
                  <span className="H4-Subtitle text-center black-800 mb-2">{t('noMessage')}<br />{t('noMessageDispo')}</span>
                  <span className="body-sm neutral-3 text-center">{t('message_1_prof')}{" "+t('message_2_prof')}</span>
                </div>
              </div>
          </div>}
          {listPosts.length === 0 && props?.connectedUser?.role === "role_student" &&
         <div className="ms-4 mb-4 d-flex justify-content-center align-items-center admission-sidebar-discussion">
              <div className="d-flex flex-column justify-content-center ms-4">
                <div className="mb-4 black-800 d-flex justify-content-center">
                  <span className="material-icons-outlined" style={{ fontSize: 96 }}>forum</span>
                </div>
                <div className="d-flex flex-column justify-content-center " style={{ maxWidth: 260 }}>
                  <span className="H4-Subtitle text-center black-800 mb-2">{t('noMessage')}<br />{t('noMessageDispo')}</span>
                  <span className="body-sm neutral-3 text-center">{t('message_1')}{" "+t('message_2')}</span>
                </div>
              </div>
          </div>}
          {listPosts.length !== 0&&<div className="ms-4 mb-4 d-flex flex-column-reverse admission-sidebar-discussion" id="chatSidebar">
            {listPosts?.map((message: any, index: number) => {
              return (
                <div key={message?.idAuthor?.id} className={(message?.idAuthor?.id !== props?.connectedUser?.idPerson) ? `d-flex flex-row align-items-end ${checkmessage(index)}` : `d-flex flex-column ${checkmessage(index)} me-4`}>

                  {((message?.idAuthor?.id !== props?.connectedUser?.idPerson) && ((listPosts[index - 1] && listPosts[index - 1].idAuthor?.id !== message?.idAuthor?.id) || 0 === index)) &&
                    <div className="me-2">
                      <img className="img-profil xsmall" src={(message?.idAuthor?.photo) ? message?.idAuthor?.photo : userLogo} alt="profile img" />
                    </div>}

                  <div className={(message?.idAuthor?.id !== props?.connectedUser?.idPerson) ? `d-flex flex-column ${!checkImageMessage(index) ? "ms-4 ps-3" : ""}` : ""}>
                    <div className={(message?.idAuthor?.id !== props?.connectedUser?.idPerson) ? `admission-sidebar-discussion-secondaruser body-sm` : "admission-sidebar-discussion-mainuser body-sm"}>
                      {message?.comment?.description}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>}
          <div className="admission-sidebar-adding-discussion d-flex flex-row justify-content-between align-items-center" style={{ position: 'relative' }}>
            <div style={{ minHeight: 100 }} className="w-100 me-1  d-flex align-items-center">
              <TextareaCustomparagraphDiscussionElement disabled={disabledFn} id={props?.follow.id} value={comment} onChange={changeInputValue} placeholder={t('votreCommentaie')} />
            </div>
            <div className="d-flex flex-column" style={{ position: 'absolute', bottom: '12px', right: '24px' }}>
              <button type="button" className="btn-discuss mb-1" disabled>
                <span className="material-icons-outlined">attach_file</span>
              </button>
              <button type="button" className="btn-discuss" onClick={createPost} disabled={(listPosts.length === 0 &&
                (props?.connectedUser?.role.includes("role_student")))
                || (props?.follow?.statusList?.find((status: any) =>
                  new Date(status?.creationDate).getTime() === Math.max(...props?.follow?.statusList?.map((e: any) => new Date(e.creationDate)))).status === "INSCRIBED")} >
                <span className="material-icons-outlined">send</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getSubjectCommunity,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionDiscussionSidbarElement);