import {FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useHistory } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SelectMultipleElements from '../../elements/multipleSelect/select-multiple-elements';
import { AppState } from '../../global-state/store/root-reducers';
import { createTraining, deleteSubject, removeTest } from '../../global-state/actions/training-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { connect } from 'react-redux';
import { formatNumber } from '../../base/functions/Functions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import axiosInstance from '../../helpers/interceptors';
interface Props {
  options: any[],
  data: any,
  cardsLength?: number,
  index: number,
  moveCard?: Function,
  t: any
}
const SessionCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const history = useHistory()
  const ItemTypes = {
    CARD: 'card',
  }

  const dateFormat = require('dateformat');
  const ref = useRef<HTMLDivElement>(null)
  const [isShown, setIsShown] = useState(false)
  const index = useMemo(() => props?.index, [props?.index])
  const prevOptions: any = usePrevious(props?.options)
  const [startDay, setSartDay] = useState<any>()
  const [mandatories, setMandatories] = useState<any | undefined>(undefined)
  const [options, setOptions] = useState<any[] | undefined>(undefined)
  const { t } = useTranslation();

  useEffect(() => {
    if (Array.isArray(props?.data?.mandatories)) {
      let differences = prevOptions?.filter((x: any) => props?.options?.find((y: any) => y?.id == x?.id)?.value !== x?.value);
      if (Array.isArray(differences) && differences.length > 0) {
        setMandatories(props?.data?.mandatories.map((mandatory: number) => {
          let id = prevOptions.find((prevOption: any) => prevOption?.value === mandatory)?.id
          if (!!id) {
            return props?.options.find((option: any) => option?.id === id)?.value
          }
          return mandatory;
        }))
      }

      else {
        setMandatories(props?.data?.mandatories)
      }
      setOptions(Array.isArray(props?.options) ? props?.options.slice(0, index).concat(props?.options.slice(index + 1)) : [])
    }
    else {
      setMandatories(undefined)

    }
  }
    , [props?.options, props?.data?.mandatories])
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (clientOffset.y < 430) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
     
      props?.moveCard&&props?.moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  const formatedIndex = useMemo(() => {
      return formatNumber(index+1)
  }, [index])
  useEffect(() => {

    if (props?.data?.startDay) {
      setSartDay(dateFormat(props?.data?.startDay, "yyyy-mm-dd"))

    }
  }, [props?.data?.startDay])
  const deleteSessionSubject = async () => {
    
    if (mandatories !== undefined) {
      await props?.deleteSubject(props?.session?.id, props?.data?.id)
      if (props?.session?.status === "PUBLISHED") {
        let updateFollows =
        {
            "action": "DELETE",
            "trainingType": props?.session?.type,
            "trainingId": props?.session?.id,
            "idSubject": props?.data?.id,
            "typeSubject": props?.data?.type === "Cours" ? "COURSE" : "PATH"
        }

        axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdate", updateFollows)

    }
      
    }
    else {
      props?.removeTest("SESSION", props?.data?.id)
    }
  }
  useEffect(() => {
    let mandatoriesBySubject = mandatories?.map((e: any) => e - 1)
    let subjectBySessionIndex = props?.session?.subjectsBySession.findIndex((e: any) => e?.id == props?.data?.id)
    if (mandatoriesBySubject && props?.session?.subjectsBySession[subjectBySessionIndex]?.mandatories && JSON.stringify(mandatoriesBySubject) != JSON.stringify(props?.session?.subjectsBySession[subjectBySessionIndex]?.mandatories)) {
      let session = props?.session
      session.subjectsBySession[subjectBySessionIndex] = { ...props?.session?.subjectsBySession[subjectBySessionIndex], mandatories: mandatoriesBySubject }
      props?.createTraining("sessions", session)
    }
  }, [mandatories, options])
  const goTo = async () => {
    if (mandatories === undefined) {
      props?.clearHistoryArray()
      props?.redirect(t('Session'), `${returnStartPathUrl()}/courses/create-formation/session/content`)
      props?.redirect(props?.session?.title, `${returnStartPathUrl()}/courses/create-formation/session/content`)
      props?.redirect(t('finalExam'), "")
       history.push({ pathname: `${returnStartPathUrl()}/courses/create-formation/session/test` })
    }
  }


  useEffect(() => {
    if (startDay != undefined && props?.data?.startDay !== undefined && startDay !== dateFormat(props?.data?.startDay, "yyyy-mm-dd")) {
      let subjectBySessionIndex = props?.session?.subjectsBySession.findIndex((e: any) => e?.id == props?.data?.id)
      let session = props?.session
      session.subjectsBySession[subjectBySessionIndex] = { ...props?.session?.subjectsBySession[subjectBySessionIndex], startDay: new Date(startDay) }
      props?.createTraining("sessions", session)
    }
  }, [startDay])

  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity, minHeight: 120 }} className="d-flex align-items-center bg-with-border white vignette-hover dragAndDrop py-19 ps-40 pe-4 mb-4" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} onClick={() => { goTo() }}>
      {!isShown || mandatories === undefined ? <>
        <div className="me-40" style={{ width: 32 }}>
          <span className="H3-Headline">{formatedIndex}</span>
        </div>
      </> :
        <><div className="me-38">
          <button type="button" disabled={index === 0} onClick={() => {props?.moveCard&& props?.moveCard(index, index - 1) }} className="btn-QuickActions small mb-2" >
            <span className="material-icons-outlined">
              arrow_upward</span>
          </button>
          <button type="button" disabled={index === options?.length} onClick={() => { props?.moveCard&&props?.moveCard(index, index + 1) }} className="btn-QuickActions small" >
            <span className="material-icons-outlined">
              arrow_downward</span>
          </button>
        </div></>
      }
      <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span className="H3-Headline mb-6 formation-card horizontal title">{props?.data?.title}</span>
            <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
              <div className="d-flex me-2">
                {props?.data?.icon}
              </div>
              <div className="body-sm bold neutral-2">
                {props?.data?.type}
              </div>
            </div>
          </div>
        </div>
        <div className=" d-flex align-items-center detail-liste-responsive-actions detail-liste-responsive-session me-2 justify-content-end" style={{ minWidth: 520 }}>
          <div className="me-4 detail-liste-responsive-session-inputs d-flex flex-column" style={{ width: 147 }}>
            {mandatories !== undefined &&<>
              {props?.cardsLength && props?.cardsLength > 1 && <div className="detail-liste-responsive-session-inputs-select">
                <SelectMultipleElements defaultValue={mandatories} placeholder={"access"} taille={'md'} options={options} onChange={setMandatories} />

              </div>}
              <input dir="ltr"type="date" className="form-control input medium input-date text-default mt-2 detail-liste-responsive-session-inputs-date" value={dateFormat(startDay, "yyyy-mm-dd")} onChange={(e: any) => { setSartDay(e.target.value) }} /></>
              }
          </div>

          <div className={props?.data?.classNames}>
            <div className="d-flex align-items-center detail-liste-responsive-level training-card-lvl justify-content-start me-4 pe-2" style={{textTransform: 'capitalize'}}>{/*updated*/}

              {props?.data?.other}
            </div>
            {!isShown ? <>
              <div className="d-flex align-items-center training-card-timing justify-content-end ">{/*updated*/}
                <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
                <span className="body-md bold">{props?.data?.duration}</span>
              </div>
            </> :
              <><div className="d-flex flex-row training-card-timing justify-content-end">{/*updated*/}
                <button onClick={(event) => { event.stopPropagation(); deleteSessionSubject() }} type="button" className="btn-QuickActions small" >
                  <span className="material-icons-outlined">
                    delete</span>
                </button>
              </div></>}
          </div>
        </div>
      </div>
    </div>

  );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createTraining,
      removeTest,
      deleteSubject,
      redirect, clearHistoryArray
    },
    dispatch
  );
const mapStateToProps = (state: AppState) => ({
  session: state.trainingReducer.session
});
// Hook
function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionCardComponent);
