import { ComponentProps, FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect } from '../../../global-state/actions/breadcrumb-actions';
import { AppState } from '../../../global-state/store/root-reducers';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import "../../../translations/i18n";
const OpenQuestionCorrectedComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [numQuestion] = useState(props?.numQuestion);
    const { t } = useTranslation();
    const [resp] = useState(props?.obj);
    const history = useHistory();

    const correctQuestion = (obj: any, i: number) => {
        props?.redirect(t('Question', `${returnStartPathUrl()}/examsDetails/submission/correction`,))
        history.push({ pathname: `${returnStartPathUrl()}/examsDetails/submission/correction`, state: { obj, i } });

    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40">
                <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                    <div className="d-flex flex-column" >
                        <div className="d-flex flex-row flex-wrap align-items-center mb-2">
                            <span className="H3-Headline me-2 pe-1"><span className="H3-Headline neutral-3">{t("Question")}</span> {numQuestion}</span>
                            <span className="body-sm bold"><span className="body-sm bold neutral-3">{t("openQuestion")} <span className={`badge-text ${((resp.questionMark / resp?.questionByQna?.scaleNote) >= 0.5) ? 'success' : 'danger'}`}>{resp.questionMark}</span>/{resp?.questionByQna?.scaleNote} {t("point")}</span></span>
                        </div>
                        <span className="H4-Subtitle bold ">{resp?.questionByQna?.question?.questionStatement}</span>
                    </div>
                </div>
            </div>

            <div className="bg-accordion-border accordion-body pb-3">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row mb-4">
                        <div className={`badge-square ${((resp.questionMark / resp?.questionByQna?.scaleNote) >= 0.5) ? 'success' : 'danger'} me-4`}>
                            <span className="material-icons-outlined">{((resp.questionMark / resp?.questionByQna?.scaleNote) >= 0.5) ? 'check' : 'close'}</span>
                        </div>
                        <div >
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-column">
                                    <span className="form-title mb-2">{t("Commentaire_du_professeur")}</span>
                                    <span className="body-md neutral-2">{resp?.teacherComment}</span>
                                </div>
                                <div className='d-flex align-items-center flex-wrap' style={{ float: 'right' }}>
                                    <button type="button" className="btn-Secondary large mt-3 " onClick={() => { correctQuestion(resp, numQuestion - 1) }} >
                                    {t("markUpdate")}
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    connectedUserDetails: state.authReducer.connectedUser,
    history: ownProps?.history,
    examStats: state.examReducer.examStats,
    actualTab: state.examReducer.actualTab,
    examForm: state.examReducer.examForm
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestionCorrectedComponent);

