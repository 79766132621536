import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
export default function CloseQuestionComponent(props: any) {
    const { t } = useTranslation();
    const numQuestion = useMemo(() => { return props?.numQuestion }, [props?.numQuestion]);
    const resp = useMemo(() => { return props?.obj }, [props?.obj]);
    const [trueResponsesToDisplay, setTrueResponsesToDisplay] = useState([]);
    const [falseREsponsesToDisplay, setFalseREsponsesToDisplay] = useState([]);
    

    useEffect(() => {

        if (props?.obj != undefined) {
            setTrueResponsesToDisplay(props?.obj?.questionByQna?.question?.questionChoice?.filter((obj: any) => obj?.choiceState === true));
            setFalseREsponsesToDisplay(props?.obj?.responseQuestionChoice?.filter((obj: any) => obj?.responseChoiceState === true));
        }
        
    }, [props?.obj])

    
    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40">
                <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                    <div className="d-flex flex-column" >
                        <div className="d-flex flex-row flex-wrap align-items-center mb-2">
                            <span className="H3-Headline  me-2 pe-1"><span className="H3-Headline neutral-3">{t("Question")} </span> {numQuestion}</span>
                            <span className="body-sm bold"><span className="body-sm bold neutral-3">{t("Question_fermées")} <span className={`badge-text ${((resp.questionMark / resp?.questionByQna?.scaleNote) >= 0.5) ? 'success' : 'danger'}`}>{resp.questionMark}</span>/1 {t("point")}</span></span>
                        </div>
                        <span className="H4-Subtitle bold ">{resp?.questionByQna?.question?.questionStatement}</span>
                    </div>
                </div>
            </div>

            <div className="bg-accordion-border accordion-body pb-3">
                <div className="d-flex flex-column">
                    {(resp.questionMark !== 1) && <>{falseREsponsesToDisplay?.map((obj: any, i: number) => {
                        return <div className="d-flex flex-row mb-4">
                            <div className="badge-square danger me-4">
                                <span className="material-icons-outlined">close</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="form-title mb-2">{t("studentResponse")}</span>
                                <span className="body-md neutral-2">{obj.responseChoice} </span>
                            </div>
                        </div>
                    })}</>}

                    {trueResponsesToDisplay?.map((obj: any, i: number) => {
                        return <div className="d-flex flex-row mb-4" key={obj.id}>
                            <div className="badge-square success me-4">
                                <span className="material-icons-outlined">check</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="form-title mb-2">{t("choiceLabelBON")}</span>
                                <span className="body-md neutral-2">{obj.choice}</span>
                            </div>
                        </div>
                    })}

                    {(resp.questionByQna?.question?.questionAnswerExplanation.length > 0) && (
                        <div className="d-flex flex-row mb-4">
                            <div className="badge-square info me-4">
                                <span className="material-icons-outlined">info</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="form-title mb-2">{t("Explication")}</span>
                                <span className="body-md neutral-2">{resp.questionByQna?.question?.questionAnswerExplanation}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
