
  import React from 'react';
  import { withStyles } from '@material-ui/core/styles';
  import Tooltip from '@material-ui/core/Tooltip';
  import Typography from '@material-ui/core/Typography';
  
  const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: '#ffffff',
        color: '#272727',
        Width: 300,
        wordBrea: 'break-word',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #FBFBFD',
        padding: 16,
        borderRadius: '3px',
        boxShadow: ' 0px 3px 12px rgba(195, 205, 206, 0.25);',
        
      },
      arrow: {
          color: '#ffffff',
          boxShadow: ' 0px 3px 12px rgba(195, 205, 206, 0.25);'
      }
    }))(Tooltip);

    interface Props {
        title?:string,
        content?:any,
        children?:any
    }
  export default function TooltipCustomizedElement(props:any) {
  
  
    return <>
        <HtmlTooltip 
        interactive={props?.interactive}
        placement={'bottom'} 
        arrow={true} 
        title={ 
            <React.Fragment> 
                <Typography color="inherit" >{props?.title}</Typography>
                  {props?.content}
            </React.Fragment>
          }
        >
            {props?.children}
        </HtmlTooltip>
    </>
  }