import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import HeaderPathProgress from '../../../components/headers-page/header-path-progress';
import PathProgresscontentComponent from '../../../components/path-components/path-progress-content-component';
import { getCurrentTraining } from '../../../global-state/actions/student-training-actions';
import { useParams } from 'react-router-dom';
import LoadingHeaderPathSessionComponent from '../../../components/headers-page/loading-header--path-session-component';
import LoadingPathSessionContentComponent from '../../../components/path-components/path-course-components/loading-path-session-content-component';
import { getMyFeedbackApi } from '../../../services/feedBack.service';
import { clearModal } from '../../../global-state/actions/modal-actions';

interface Params {
    idPath: string;
}

const PathProgressPage: FC<
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ComponentProps<any>
> = (props) => {
    const { idPath } = useParams<Params>();
    const [loadingPage, setLoadingPage] = useState<boolean>(true);
    const [training, setTraining] = useState();
    const [feedBack, setFeedBack] = useState<any>({});

    const getMyFeedback = async () => {
        setLoadingPage(true); // Start loading when fetching feedback
        try {
            const result = await getMyFeedbackApi({
                idPerson: props?.connectedUserDetails?.idPerson,
                idSubject: props?.currentTraining?.id,
                subjectType: 'PATH',
            });
            setFeedBack(result);
        } catch (error) {
            console.error("Error fetching feedback: ", error);
        } finally {
            setLoadingPage(false); 
        }
    };

    useEffect(() => {
        if (props?.currentProgress?.progress === 100) {
            getMyFeedback();
        } else {
            setLoadingPage(false); 
        }

        if (props?.modalAction) props?.clearModal();
    }, [props?.currentProgress, props?.modalAction]);

    useEffect(() => {
        if (props?.currentTraining !== training) {
            setTraining(props?.currentTraining);
        }
    }, [props?.currentTraining]);

    useEffect(() => {
        props?.getCurrentTraining(Number(idPath));
        setLoadingPage(true);
    }, []);

    return (
        <div style={{ maxWidth: '1200px', margin: 'auto', marginTop: '64px' }}>
            {loadingPage ? (
                <div>
                    <LoadingHeaderPathSessionComponent />
                    <LoadingPathSessionContentComponent />
                </div>
            ) : (
                <div>
                    <HeaderPathProgress />
                    <PathProgresscontentComponent feedBack={feedBack} />
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getCurrentTraining,
            clearModal
        },
        dispatch
    );

const mapStateToProps = (state: AppState) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining,
    currentProgress: state.studentTraining.currentProgress,
    modalAction: state.modalReducer.modalAction,
});

export default connect(mapStateToProps, mapDispatchToProps)(PathProgressPage);
