import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearHistory } from '../../global-state/actions/breadcrumb-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import HeaderExamDetailsDashboard from '../../components/headers-page/header-exam-details-dashboard';
import TableListExamDetailComponent from '../../components/tables/table-list-exam-details-component';
import { changingActualTab, getExamsList, getExamsStats } from '../../global-state/actions/exam-actions';
import HeaderExamDetailsLoadingDashboard from '../../components/headers-page/header-exam-details-loading-dashboard';
import TableListExamDetailsLoadingComponent from '../../components/tables/table-list-exam-details-loading-component';


const ExamDashboardPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [loadingTabs, setLoadingTabs] = useState(props?.loadingTabs);
    const [loadingTables, setLoadingTables] = useState(props?.loadingLists);

    const [role, setRole] = useState(props?.connectedUser)


    const [listToReviewExam, setListToReviewExam] = useState<any>(props?.examsList?.listExamToReview);
    const [listCompletedExam, setListCompletedExam] = useState<any>(props?.examsList?.listExamCompleted);
    const [listAllExam, setListAllExam] = useState<any>(props?.examsList?.listAllExam);

    const [initialToReview, setInitialToReview] = useState(props?.examsList?.listExamToReview)
    const [initialCompleted, setInitialCompleted] = useState(props?.examsList?.listExamCompleted)
    const [initialAll, setInitialAll] = useState(props?.examsList?.listAllExam)



    const verif =(exam:any , str:string)=>{
        let verif:string=exam?.courseName?.toLowerCase()
        return verif?.includes(str)
     }

     


    const searchExams = async( courseName:string , label:string )=>{
        
        let filtredAll={}
        let filtredToReview={}
        let filtredCompleted={}

        if(label==="" && courseName==="ALL"){
            setListToReviewExam(initialToReview)
            setListCompletedExam(initialCompleted)
            setListAllExam(initialAll)
        }else if((label!=="" && courseName==="ALL")){
            filtredAll= initialAll?.filter((exam:any)=>  verif(exam , label.toLowerCase())  )
            filtredToReview = initialToReview?.filter((exam:any)=> ( verif(exam, label.toLowerCase())))
            filtredCompleted=initialCompleted?.filter((exam:any)=> ( verif(exam , label.toLowerCase())))
            
            setListAllExam(filtredAll)
            setListToReviewExam(filtredToReview)
            setListCompletedExam(filtredCompleted)
        }else{ 
            filtredAll= initialAll?.filter((exam:any)=> verif(exam, label.toLowerCase()) && verif(exam, courseName.toLowerCase()  ))
            filtredToReview= initialToReview?.filter((exam:any)=> verif(exam, label.toLowerCase()) && verif(exam, courseName.toLowerCase()  ) )
            filtredCompleted=initialCompleted?.filter((exam:any)=> verif(exam, label.toLowerCase()) && verif(exam, courseName.toLowerCase()  ))
            
            setListAllExam(filtredAll)
            setListToReviewExam(filtredToReview)
            setListCompletedExam(filtredCompleted)
        }

    }


    useEffect(() => {
        if (props?.connectedUser !== role) {
            setRole(props?.connectedUser.role)
        }
    }, [props?.connectedUser,role])





    useEffect(() => {
        setInitialToReview(props?.examsList?.listExamToReview)
        setInitialCompleted(props?.examsList?.listExamCompleted)
        setInitialAll(props?.examsList?.listAllExam)
        
        setListToReviewExam(props?.examsList?.listExamToReview)
        setListCompletedExam(props?.examsList?.listExamCompleted)
        setListAllExam(props?.examsList?.listAllExam)
    }, [props?.examsList])


    useEffect(() => {

        if (props?.connectedUser?.role === "role_prof") {
            props.getExamsList(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson, "PROF");
        } else if (props?.connectedUser?.role === "role_adminstrator") {
            props.getExamsList(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson, "ADMINSTRATOR");
        }
        getStats()

    }, [])

    const getStats = () => {
        if (props?.connectedUser?.role === "role_prof") {
            props.getExamsStats(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson, "PROF");
        } else if (props?.connectedUser?.role === "role_adminstrator") {
            props.getExamsStats(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson, "ADMINSTRATOR");
        }
    }




    useEffect(() => {
        setLoadingTabs(props?.loadingTabs);
        if (props?.examStats.nbrExamsToReview != undefined || props?.examStats.nbrCompletedExams != undefined || props?.examStats.nbrAllExams != undefined) {
            setLoadingTabs(false)
        }

    }, [props?.loadingTabs])

    useEffect(() => {
        if (props?.examStats?.nbrExamsToReview != props?.examsList?.listExamToReview?.length || props?.examStats?.nbrCompletedExams != props?.examsList?.listExamCompleted?.length || props?.examStats?.nbrAllExams != props?.examsList?.listAllExam?.length) {
            getStats()
        }
    }, [])


    useEffect(() => {
        setLoadingTables(props?.loadingLists);
    }, [props?.loadingLists])



    return (
        <div className="container-fluid" >
            <span id='step-examen-page_prof'></span>
            <div className="row" >
                {loadingTabs ? <HeaderExamDetailsLoadingDashboard /> : <HeaderExamDetailsDashboard  searchExams={searchExams} />}
            </div>
            <div className="row" style={{ marginTop: "32px" }}>
                { loadingTables? <TableListExamDetailsLoadingComponent /> : <TableListExamDetailComponent listToReviewExam={listToReviewExam}  listCompletedExam={listCompletedExam} listAllExam={listAllExam}/>}
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    actualTab: state.examReducer.actualTab,
    loadingTabs: state.examReducer.loadingTabs,
    loadingLists: state.examReducer.loadingLists,
    examStats: state.examReducer.examStats,
    examsList: state.examReducer.examsList,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    clearHistory,
    changingActualTab,
    getExamsList,
    getExamsStats
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExamDashboardPage);


