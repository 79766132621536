import { useEffect, useState, useRef } from 'react';

interface Props {
  data: any
  doAction: any
  fetchMoreData: any
  loader: boolean
  page: number
  setPage: (page: number) => void
  setLoader: (loader: boolean) => void
  height?: number | string
}

export default function TableScrollElementStudentsGroup(props: Props) {
  const [data, setData] = useState<any>();
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const doAction = (e: any, actionName: string, row: any, index: number) => {
    if (actionName !== '') {
      e.stopPropagation();
      const object = {
        row: row,
        action: actionName,
        index: index
      };
      props?.doAction(object);
    }
  };

  const handleScroll = async () => {
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !props.loader) {
        props.setLoader(true);
        await props.fetchMoreData(props.page + 1);
        props.setPage(props.page + 1);
        props.setLoader(false);
      }
    }
  };

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <>
      <div className="table-responsive d-flex flex-column mx-auto" style={{ maxWidth: 1136, height: props?.height }}>
        <div>
          <table className="w-100 custom-table">
            <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any, index: number) => (
                  <th key={index} scope={header.scope} className={header.classNames}>
                    <button className={header.buttonClasses}>
                      {header.title}
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
        <div ref={tableRef} className="w-100 body-table-scroll" style={{ height: '400px', overflowY: 'auto' }}>
          <table className="w-100 custom-table px-2">
            <tbody>
              {data?.body?.map((dataItem: any, index: number) => (
                <tr
                  key={index}
                  style={{ cursor: dataItem.row.actionName === "" ? 'default' : 'pointer' }}
                  onClick={(e) => doAction(e, dataItem.row.actionName, dataItem.row, index)}
                  className={dataItem.row.classNames}
                >
                  {dataItem.row.columns.map((column: any, indexItem: number) => (
                    <td
                      key={indexItem + 'column'}
                      onClick={(e) => doAction(e, column.actionName, dataItem.row, indexItem)}
                      className={column.classNames}
                      colSpan={column.colspan}
                    >
                      {column.content}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {props.loader && (
            <div className="d-flex w-100 align-items-center justify-content-center">
              <div className="m-3">
                <svg className="spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
