import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from '../../helpers/interceptors';
import { IReduxSchoolInfoState } from '../reducers/app-container-reducers';
import jwt from 'jwt-decode';
import { getDomainFromUrl } from '../../helpers/domainCheck';
export enum EReduxActionTypes {
  GET_SCHOOL_INFO = 'GET_SCHOOL_INFO',
  PAYMENT_SCHOOL_INFO = 'PAYMENT_SCHOOL_INFO',
  CREATE_UPDATE_PAYMENT_SCHOOL_INFO = 'CREATE_UPDATE_PAYMENT_SCHOOL_INFO',
  CREATE_PAYEMENT_SETTING = 'CREATE_PAYEMENT_SETTING',
  PAYEMENT_METHOD = 'PAYEMENT_METHOD',
  GET_SUBSCRIPTIONS = ' GET_SUBSCRIPTIONS',
  UPDATE_SCHOOL_INFO = 'UPDATE_SCHOOL_INFO',
  CREATE_WEBHOOK = 'CREATE_WEBHOOK',
  GET_CONNECTION_NUMBER = 'GET_CONNECTION_NUMBER',
  GET_OFFER = "GET_OFFER",
  GET_CONTRACT = "GET_CONTRACT",
  SAVE_CHANGE_TO="SAVE_CHANGE_TO",
  GET_STUDENT_STATUS = 'GET_STUDENT_STATUS',
  GET_COURSE_STATUS = 'GET_COURSE_STATUS',
  GET_PROF_STATUS = 'GET_PROF_STATUS',
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}
export interface IReduxUpdateSchoolInfoAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_SCHOOL_INFO;
  data: any;
}

export interface IReduxGetContractAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CONTRACT;
  data: any;
}

export interface IReduxGetOfferAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_OFFER;
  data: any;
}

export interface IReduxGetPaymentSchoolInfoAction extends IReduxBaseAction {
  type: EReduxActionTypes.PAYMENT_SCHOOL_INFO;
  data: any;
}
export interface IReduxCreateWebhookAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_WEBHOOK;
}
export interface IReduxShowPayementMethodAction extends IReduxBaseAction {
  type: EReduxActionTypes.PAYEMENT_METHOD;
  data: any;
}

export interface IReduxCreatePayementSettingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_PAYEMENT_SETTING;
  data: any;
}
export interface IReduxGetSchoolInfoAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SCHOOL_INFO;
  data: any;
}
export interface IReduxCreateOrUpdateShoolPaymentInfoAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_UPDATE_PAYMENT_SCHOOL_INFO;
  data: any;
}
export interface IReduxGetSubscriptionsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SUBSCRIPTIONS;
  data: any;
}
export interface IReduxGetConnectionAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CONNECTION_NUMBER;
  data: any;
}

export interface IReduxSaveChangeToAction extends IReduxBaseAction {
  type: EReduxActionTypes.SAVE_CHANGE_TO;
  data: any;
}

export interface IReduxGetStudentStatusAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENT_STATUS;
  data: any;
}

export interface IReduxGetCourseStatusAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE_STATUS;
  data: any;
}

export interface IReduxGetProfStatusAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_PROF_STATUS;
  data: any;
}

export function getProfStatus(status: any): ThunkAction<Promise<IReduxGetProfStatusAction>, IReduxSchoolInfoState, undefined, IReduxGetProfStatusAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetProfStatusAction>) => {
      return dispatch({
          type: EReduxActionTypes.GET_PROF_STATUS,
          data: status
      });
  };
}

export function getCourseStatus(status: any): ThunkAction<Promise<IReduxGetCourseStatusAction>, IReduxSchoolInfoState, undefined, IReduxGetCourseStatusAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetCourseStatusAction>) => {
      return dispatch({
          type: EReduxActionTypes.GET_COURSE_STATUS,
          data: status
      });
  };
}

export function getStudentStatus(status: any): ThunkAction<Promise<IReduxGetStudentStatusAction>, IReduxSchoolInfoState, undefined, IReduxGetStudentStatusAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetStudentStatusAction>) => {
      return dispatch({
          type: EReduxActionTypes.GET_STUDENT_STATUS,
          data: status
      });
  };
}

export function saveChangeTo(changeTo: any): ThunkAction<Promise<IReduxSaveChangeToAction>, IReduxSchoolInfoState, undefined, IReduxSaveChangeToAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxSaveChangeToAction>) => {
          return dispatch({
          type: EReduxActionTypes.SAVE_CHANGE_TO,
          data: changeTo
      });
  };
}

export function getOffer(id: any): ThunkAction<Promise<IReduxGetOfferAction>, IReduxGetOfferAction, undefined, IReduxGetOfferAction> {


  return async (dispatch: ThunkDispatch<IReduxGetOfferAction, undefined, IReduxGetOfferAction>) => {

      let baseURL = process.env.REACT_APP_BASE_URL11;
      let offer: any = await axios.get(baseURL + "offers/getOffer/" + id).then(response => { return response.data; }).catch(err => { return console.error(err) });

      return dispatch({
          type: EReduxActionTypes.GET_OFFER,
          data: offer
      });
  };
}

export function getContract(idEntity: any, entityType: string): ThunkAction<Promise<IReduxGetContractAction>, IReduxGetContractAction, undefined, IReduxGetContractAction> {
  return async (dispatch: ThunkDispatch<IReduxGetContractAction, undefined, IReduxGetContractAction>) => {
      let baseURL = process.env.REACT_APP_BASE_URL17;
      let contract: any = await axios.post(baseURL + "contracts/getContractsWithFilter", {
          entityId: idEntity,
          entityType: entityType,
          paymentEngine: null,
          paymentMethod: null
      }).then(response => {

          return response.data;
      }).catch(err => { return console.error(err) });

      return dispatch({
          type: EReduxActionTypes.GET_CONTRACT,
          data: contract
      });
  };
}


export function updateSchoolInfo(code: string): ThunkAction<Promise<IReduxUpdateSchoolInfoAction>, IReduxSchoolInfoState, undefined, IReduxUpdateSchoolInfoAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxUpdateSchoolInfoAction>) => {

    const schoolInfo = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/getlegalpersonbycode/${code}`).then((response) => {
      return response.data
    });

    return dispatch({
      type: EReduxActionTypes.UPDATE_SCHOOL_INFO,
      data: { schoolInfo: schoolInfo }
    })
  }
}


export function getSchoolInfo(code: string): ThunkAction<Promise<IReduxGetSchoolInfoAction>, IReduxSchoolInfoState, undefined, IReduxGetSchoolInfoAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetSchoolInfoAction>) => {

    const schoolInfo = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/getlegalpersonbycode/${code}`).then((response) => {
      return response.data
    });
    
    const paymentInfo = await axios.get(`${process.env.REACT_APP_BASE_URL13}paymentmethod/getPaymentMethod/${schoolInfo.id}`).then((response) => {
      return (response.data === "") ? null : response.data;
    }).catch(() => {
      return null
    });
    return dispatch({
      type: EReduxActionTypes.GET_SCHOOL_INFO,
      data: { schoolInfo: schoolInfo, paymentInfo: paymentInfo }
    })
  }
}
export function getPaymentSchoolInfo(idSchool: number): ThunkAction<Promise<IReduxGetPaymentSchoolInfoAction>, IReduxSchoolInfoState, undefined, IReduxGetPaymentSchoolInfoAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetPaymentSchoolInfoAction>) => {

    const paymentInfo = await axios.get(`${process.env.REACT_APP_BASE_URL13}paymentmethod/getPaymentMethod/${idSchool}`).then((response) => {
      return (response.data === "") ? null : response.data;
    });

    return dispatch({
      type: EReduxActionTypes.PAYMENT_SCHOOL_INFO,
      data: paymentInfo
    })
  }
}

export function showPaymentSchoolInfo(object: any): ThunkAction<Promise<IReduxShowPayementMethodAction>, IReduxSchoolInfoState, undefined, IReduxShowPayementMethodAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxShowPayementMethodAction>) => {

    return dispatch({
      type: EReduxActionTypes.PAYEMENT_METHOD,
      data: object
    })
  }
}
export function getNuberUserConnexion(idPerson: any): ThunkAction<Promise<IReduxGetConnectionAction>, IReduxSchoolInfoState, undefined, IReduxGetConnectionAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetConnectionAction>) => {
  
    const userNbrConnexionsAPI = await axios.get(`${process.env.REACT_APP_BASE_URL1}person/userNbrConnexions/${idPerson}`)
    return dispatch({
      type: EReduxActionTypes.GET_CONNECTION_NUMBER,
      data: userNbrConnexionsAPI
    })
  }
}
export function createWebhook(object: any): ThunkAction<Promise<IReduxCreateWebhookAction>, IReduxSchoolInfoState, undefined, IReduxCreateWebhookAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxCreateWebhookAction>) => {
    const paymentInfo = await axios.post(`${process.env.REACT_APP_BASE_URL18}strip/webhook_endpoints`, object).then((response) => {
      return (response.data === "") ? null : response.data;
    });
    return dispatch({
      type: EReduxActionTypes.CREATE_WEBHOOK

    })
  }
}



export function createPayementSetting(object: any): ThunkAction<Promise<IReduxCreatePayementSettingsAction>, IReduxSchoolInfoState, undefined, IReduxCreatePayementSettingsAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxCreatePayementSettingsAction>) => {

    const paymentInfo = await axios.post(`${process.env.REACT_APP_BASE_URL13}paymentsetting/createpaymentsetting/${object.type}/${object.id}`, object.offer).then((response) => {
      return (response.data === "") ? null : response.data;
    });

    return dispatch({
      type: EReduxActionTypes.CREATE_PAYEMENT_SETTING,
      data: paymentInfo
    })
  }
}

export function createOrUpdateShoolPaymentInfo(object: any): ThunkAction<Promise<IReduxCreateOrUpdateShoolPaymentInfoAction>, IReduxSchoolInfoState, undefined, IReduxCreateOrUpdateShoolPaymentInfoAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxCreateOrUpdateShoolPaymentInfoAction>) => {

    const paymentInfo = await axios.post(`${process.env.REACT_APP_BASE_URL13}paymentmethod/createOroUpdatePaymentMethod`, object).then((response) => {
      return (response.data === "") ? null : response.data;
    });

    return dispatch({
      type: EReduxActionTypes.CREATE_UPDATE_PAYMENT_SCHOOL_INFO,
      data: paymentInfo
    })
  }
}

export function getSubscriptions(entityId: number): ThunkAction<Promise<IReduxGetSubscriptionsAction>, IReduxSchoolInfoState, undefined, IReduxGetSubscriptionsAction> {
  return async (dispatch: ThunkDispatch<IReduxSchoolInfoState, undefined, IReduxGetSubscriptionsAction>) => {
    const subscription = await axios.post(`${process.env.REACT_APP_BASE_URL14}offer/token`, { entityId })
      .then((response) => {
        if (response.data === "This entity doesn't have an offer or the contract is expired." ||
          response.data === null ||
          response.data === undefined ||
          response.data === "") {
          console.error("This entity  doesn't have an offer or the contract is expired.", getDomainFromUrl())
        } else {
          let decoded_token: any = jwt(response.data);
          return {
            ...decoded_token.data,
            "token": response.data,
          }
        }
      });

    return dispatch({
      type: EReduxActionTypes.GET_SUBSCRIPTIONS,
      data: subscription
    })
  }
}