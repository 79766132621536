import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearRegistrationForm } from '../../../global-state/actions/course-actions';
import { useTranslation } from "react-i18next";
import HeaderTrainingFormComponent from "../../../components/formations-card/header-formation-form";
const AdmissionPathPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState<any>();
  const [registration, setRegistration] = useState<any>();
  const { t } = useTranslation();
  useEffect(() => {
    if (props?.path?.idRegistrationForm === null || props?.path?.idRegistrationForm === 0) {
      props?.clearRegistrationForm();
      setShowForm(false);
    } else {
      setRegistration(props?.registration);
      setShowForm(true);
    }
    setCourseInfo(props?.path)
  }, [props?.path])
  return (<>
    <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
      <HeaderTrainingFormComponent type={"PATH"} title={t('inscriptionTitle')} status={courseInfo?.status} trainingTitle={courseInfo?.title} />
    </div>
    <div className="m-auto" style={{ maxWidth: 1200 }}>
      <div className="px-2">
        <h3 className="mt-5 H3-Headline black-800">{t('conditionDeValidation')}</h3>
      </div>
      <div className="px-2 mt-5">
        {!showForm && <label className="body-md black-800" htmlFor="radFalse" > {t('Acceptation')}</label> }
        {showForm && <label className="body-md black-800" htmlFor="radTrue" > {t('AcceptationSousDossier')}</label> }
      </div>
      {showForm &&
        <div className="mt-5 pt-4 px-2">
          <h3 className="mb-4 pb-3 H3-Headline black-800">{t('questions')}</h3>
          {registration?.questions.map((question: any, index: any) => (
            <div key={index}>
                <label className="body-md bold black-800" >{t('Question')} {index + 1}</label>
                <div className="mt-2 pt-1 body-md black-800" dangerouslySetInnerHTML={{__html:question?.question}}></div>
            </div>
          ))}
          <h3 className="mt-5 mb-4 pb-3 H3-Headline black-800">{t('documents')}</h3>
          {registration?.documents?.map((document: any, index: any) => (
            <div  key={index}>
                  <label className="body-md bold black-800">{t('documentTextForm')} {index + 1}</label>
                  <div className="body-md black-800 mt-2 pt-1" dangerouslySetInnerHTML={{__html:document?.document}}></div>
            </div>

          ))}

          <div className="mt-5 mb-5"></div>
        </div>}
    </div>
  </>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearRegistrationForm, }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  loggedIn: state.authReducer.loggedIn,
  courseTitle: ownProps?.courseTitle,
  path: state.trainingReducer.path,
  courseInfo: state.courseReducer.courseInfo,
  registration: state.courseReducer.registration,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionPathPage);