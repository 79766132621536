import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { setActualTab } from '../../global-state/actions/professor-actions';
import StudentsGeneralInfoTableComponent from './table-all-student/students-general-info-table-component';
import StudentsSuspendedInfoTableComponent from './table-all-student/students-suspended-info-table-component';
import StudentsCandidateInfoTableComponent from './table-all-student/students-candidate-info-table-component';
import StudentsCertifiedInfoTableComponent from './table-all-student/students-certified-info-table-component';
import StudentsInscribedInfoTableComponent from './table-all-student/students-inscribed-info-table-component';
import StudentsUnpaidInfoTableComponent from './table-all-student/Student-unpaid-info-tables-component';


const TableStudentsListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const moment = require('moment');
    const [sizeGeneral, setSizeGeneral] = useState(20);
    const [sizeCandidates, setSizeCandidates] = useState(20);
    const [sizeCertified, setSizeCertified] = useState(20);
    const [sizeInscribed, setSizeInscribed] = useState(20);
    const [sizeSuspended, setSizeSuspended] = useState(20);
    const [sizeUnpaid, setSizeUnpaid] = useState(20);

    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);
    
    useEffect(() => {
        setSizeGeneral(props?.data?.sizeGeneral);
        setSizeCandidates(props?.data?.sizeCandidates);
        setSizeCertified(props?.data?.sizeCertified);
        setSizeInscribed(props?.data?.sizeInscribed);
        setSizeSuspended(props?.data?.sizeSuspended);
        setSizeUnpaid(props?.data?.sizeUnpaid);
    }, [props?.data])
    
    const addDataListFn = (tabText: string) => {
        props?.addDataList(props?.actualTab, tabText);
    }

    return (
        <div className="col-md-12 mt-2">
            {(props.actualTab === "All") && 
            <StudentsGeneralInfoTableComponent getNextPageGeneral={props.getNextPageGeneral} data={{ sizeGeneral: sizeGeneral, searchGeneral : props?.data?.searchGeneral }} 
                            followCount={props.followCount} addDataList={(e: string) => addDataListFn(e)} updateFilterData={props?.updateFilterData} studentsList={props?.studentsList} 
                            pageNumber={props?.pageNumber} getNextPage={props?.getNextPage} loader={props?.loader} general={props?.general}
                            modalOpened={props.modalOpened}
                            setModalOpened={props.setModalOpened}
                            setActualTabModal={props.setActualTabModal}
            />}
            {(props.actualTab === "Candidate") && <StudentsCandidateInfoTableComponent getNextPageCandidate={props.getNextPageCandidate} studentsList={props.candidate} followCount={props.followCount} data={{ sizeCandidates: sizeCandidates, searchCandidate : props?.data?.searchCandidate}} addDataList={(e: string) => addDataListFn(e)}/>}
            {(props.actualTab === "Certified") && <StudentsCertifiedInfoTableComponent getNextPageCertified={props.getNextPageCertified} studentsList={props.certified} followCount={props.followCount} data={{ sizeCertified: sizeCertified, searchCertified : props?.data?.searchCertified}} addDataList={addDataListFn} /> }
            {(props.actualTab === "Inscribed") && <StudentsInscribedInfoTableComponent studentsList={props.inscribed} followCount={props.followCount} data={{ sizeInscribed: sizeInscribed, searchInscribed : props?.data?.searchInscribed}} addDataList={(e: string) => addDataListFn(e)} pageNumberInscribed={props?.pageNumberInscribed} getNextPageInscribed={props?.getNextPageInscribed}loader={props?.loader} />}
            {(props.actualTab === "Suspended") && <StudentsSuspendedInfoTableComponent getNextPageSuspended = {props.getNextPageSuspended}studentsList={props.suspended} followCount={props.followCount} data={{ sizeSuspended: sizeSuspended, searchSuspended : props?.data?.searchSuspended}} addDataList={(e: string) => addDataListFn(e)} />}
            {(props.actualTab === "Unpaid") && <StudentsUnpaidInfoTableComponent getNextPageUnpaid={props.getNextPageUnpaid} studentsList={props.unpaid}  followCount={props.followCount}data={{ sizeUnpaid: sizeUnpaid, searchUnpaid : props?.data?.searchUnpaid}} addDataList={(e: string) => addDataListFn(e)} />
            }
        </div>
    );
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    addDataList: ownProps?.addDataList,
    data: ownProps?.data
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { setActualTab }, dispatch );
export default connect(mapStateToProps, mapDispatchToProps)(TableStudentsListComponent);