import { useState, useEffect, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import TableElement from '../../elements/table/normal-table-element';
import TableLoading from './table-loading'
import { getTrainingsFinance } from '../../global-state/actions/training-actions';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getListPaymentsApi, getSizeSubApi } from '../../services/finance.service';
interface ObjectCoursesPayed {
    t: any
    filter: any
}


const FaciliteListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ObjectCoursesPayed> = (props: any) => {
    const dateFormat = require('dateformat');

    const t = props?.t
    const status = [
        { label: t("ONGOING"), value: "ONGOING", color: "primary" },
        { label: "Résilié", value: "CANCELLED", color: "warning" },
        { label: t("Refused"), value: "REFUSED", color: "danger" },
        { label: t("PAYED"), value: "PAYED", color: "success" },
    ]

    let [filter, setfilter] = useState(props?.filter)
    const history = useHistory()
    const [size, setSize] = useState(props?.size)
    const [atBottom, setAtBottom] = useState<any>(false)
    const [loading, setLoading] = useState(true);
    const [loadingScroll, setLoadingScroll] = useState(true);
    const [paymentListEasy, setPaymentListEasy] = useState<any>([]);

    const headers = [
        { title: "DEPUIS LE", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('Etudiants'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('PRIX'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: "nbr mensualité", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('Total'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto me-3" },
    ]

    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);


    useEffect(() => {
        
        setSize(props?.size)
        filterOffersPayed(filter)
    }, [])

    useEffect(() => {
        setSize(props?.size)
    }, [paymentListEasy])


    useEffect(() => {

        setLoadingScroll(props?.loadingscroll)
    }, [props?.loadingscroll]);

    const getSizeSub = async (filter: any) =>{
        const result = await getSizeSubApi(filter)
        setSize(result)
    }

    useEffect(() => {
        setfilter(props?.filter)
        setSize(props?.size)
        getSizeSub(props?.filter);
        filterOffersPayed(props?.filter)
    }, [props?.filter])

    const handleScroll = async () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY) >= document.body.offsetHeight && paymentListEasy.length < size) {
            setAtBottom(true);
            filter.pageNumber++;
            getListPayments(filter, "no");
            document.removeEventListener('scroll', handleScroll);
        }
        if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
            setAtBottom(false);
        }
    }

    const getListPayments = async (filter: any, action: any) =>{
        if (action === "first") {
            setLoading(true)
        }
        else{
            setLoadingScroll(true)
        }
        const result = await getListPaymentsApi(filter,action)
        setPaymentListEasy([...paymentListEasy, ...result])
        setLoading(false)
        setLoadingScroll(false)
    }
    

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    const filterOffersPayed = (e: any) => {
        getListPayments(e, "first");
    }

    const data: any = useMemo(() => {
        return {
            headers,
            body: paymentListEasy?.map((offer: any) => {
                const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === offer?.status?.toLowerCase());
                return {
                    row: {

                        classNames: "table-white",
                        columns: [
                            { content: dateFormat(offer?.creationDate, "dd/mm/yyyy"), classNames: "", actionName: '' },
                            {
                                content: <div className='d-flex flex-column'>
                                    <span> {offer?.naturalperson?.firstName}  {offer?.naturalperson?.lastName}</span>
                                    
                                </div>, actionName: ''
                            },
                            { content: offer?.courseOfferDto?.profByCourseDto?.label, classNames: "", actionName: '' },
                            {
                                content: <div className='d-flex flex-row '>

                                    <span>    {offer?.pricing?.price}</span>
                                    <span>   {offer?.pricing?.currency === "TND" ? "TND" : "€"}</span>

                                </div>
                                , classNames: ""
                            },
                            {
                                content: <div className='d-flex flex-row'>

                                    <span>    {offer?.nbrPayment}/{offer?.pricing?.nbPeriod}</span>

                                </div>
                                , classNames: "", actionName: ''
                            },
                            {
                                content:
                                    <div className='d-flex flex-row '>

                                        <span>    {offer?.nbrPayment * offer?.pricing?.price}</span>
                                        <span>   {offer?.pricing?.currency === "TND" ? "TND" : "€"}</span>
                                        <span className='body-sm'>  /  {offer?.pricing?.nbPeriod * offer?.pricing?.price}</span>
                                        <span className='body-sm'>   {offer?.pricing?.currency === "TND" ? "TND" : "€"}</span>
                                    </div>
                                , classNames: "", actionName: ''
                            },
                            { content: actualStatus?.label, classNames: `text-end badge-text ${actualStatus?.color}`, actionName: '' },

                        ]
                    }
                }

            })

        }




    }, [paymentListEasy])

    const doAction = async (obj: any) => {
        props?.clearHistoryArray()
        props?.redirect("Finance", `${returnStartPathUrl()}/finance/all`)
        props?.redirect(paymentListEasy[obj?.index]?.courseOfferDto?.profByCourseDto?.label, `${returnStartPathUrl()}/finance/detail-course/${paymentListEasy[obj?.index]?.courseOfferDto?.idOffer}`)
        history.push(`${returnStartPathUrl()}/finance/detail-course/${paymentListEasy[obj?.index]?.courseOfferDto?.idOffer}`)
    }

    return (
        <>

            <div className=" justify-content-center mt-0 w-100 mx-auto finance-height-table" style={{ maxWidth: '1200px' }}>

                {!loading && (paymentListEasy?.length > 0) &&
                    <>
                        <TableElement data={data} doAction={doAction} />

                        {(paymentListEasy?.length > 0 && loadingScroll) &&
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>
                        }

                    </>}
                {!loading && (paymentListEasy?.length === 0)
                    && <div className='mt-5 pt-5'>
                        <div className="msg-list-empty">
                            <EmptyListElement lines={[t('noCoursePayedDefault')]} />
                        </div>
                    </div>
                }
                {loading && <TableLoading />}
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    school: state.schoolInfoReducer.schoolInfo,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    user: state.authReducer.connectedUser,
    pageNumber: state.courseReducer.pageNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect, clearHistoryArray, getTrainingsFinance, 
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaciliteListComponent);
