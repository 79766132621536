import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updatePath } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import KnowledgeTestFormComponent from "../formation-course-form/knowledge-test-form-component";
import { debounce, sum } from "lodash";
import FinalExamFormComponent from "../formation-course-form/final-exam-form-component";
import { addTest } from "../../global-state/actions/training-actions";

const ContentExamComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [points, setPoints] = useState<number>(0);
    const [testForm, setTestForm] = useState<any>();
    useEffect(() => { 
         
            setTestForm(props?.testForm);
            setPoints(sum(props?.testForm?.questionByQnaList.map((obj: any) => {return obj.scaleNote})))
        
    }, [props?.testForm]);
    const addQuestion = () => {

        const copyTestForm = { ...testForm };
        copyTestForm.questionByQnaList.push({
            id: null,
            question: {
                id: null,
                questionStatement: "",
                questionAnswerExplanation: "",
                type: "CQ",
                quizType: "QCU",
                questionChoice: [
                    { id: null, choice: "", choiceState: false },
                    { id: null, choice: "", choiceState: false },
                ],
            },
            scaleNote: 1,
        });
        copyTestForm.scaleNote = copyTestForm.scaleNote + 1;
        if(testForm?.qnaType !== "FINALEXAM") {

            copyTestForm.duration = copyTestForm.duration + 1;
        }
        setPoints(points + 1);
        setTestForm(copyTestForm);
    };

    const addOpenQuestion = () => {

        const copyTestForm = { ...testForm };
        copyTestForm.questionByQnaList.push({
            id: null,
            scaleNote: 1,
            question: {
                id: null,
                questionStatement: "",
                type: "OQ",
                questionAssessmentLink: "",
                questionAnswerExplanation: "",
            },
        });
        setTestForm(copyTestForm)
    };
    const saveQuestion = (object: any, index: number) => {
        const copyTestForm = { ...testForm };
        copyTestForm.questionByQnaList[index] = object;
        if(testForm?.qnaType !== "FINALEXAM") {
            copyTestForm.duration = copyTestForm.questionByQnaList.length;
        }
        setTestForm(copyTestForm);
        saveExam(copyTestForm)
    };
    const saveExam = async (test: any) => {
            props?.addTest(test);
    };
    const debouncedChangeTestHandler = useCallback(debounce(saveExam, 1000), []);

    const deleteQuestion = (index: any) => {
        const copyTestForm = { ...testForm };
        copyTestForm.questionByQnaList.splice(index, 1);
        if(testForm?.qnaType !== "FINALEXAM") {
        copyTestForm.duration = copyTestForm.duration - 1;
        }
        copyTestForm.scaleNote = copyTestForm.scaleNote - 1;
        setPoints(points - 1);
        setTestForm(copyTestForm);
        debouncedChangeTestHandler(copyTestForm);

    };
    return (<>
        <div className="row mt-4 pt3" style={{ maxWidth: "1200px", margin: 'auto' }}>
            <h3 className="H3-Headline" >{(testForm?.qnaType === "FINALEXAM") ? t('finalExmBreadcrunbTitle') : t('textConnaissance')} / {points} <span className="neutral-3"> {t('points')} </span></h3>
            {(testForm?.qnaType === "FINALEXAM") ?
            <FinalExamFormComponent listQuestions={testForm?.questionByQnaList} addOpenQuestion={addOpenQuestion} saveQuestion={saveQuestion} addQuestion= {addQuestion} deleteQuestion={deleteQuestion} />:
            <KnowledgeTestFormComponent listQuestions={testForm?.questionByQnaList}  saveQuestion={saveQuestion} addQuestion= {addQuestion} deleteQuestion={deleteQuestion}/>}
        </div>
    </>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ updatePath, addTest }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser, 
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    testForm:state.trainingReducer.session.exams[0]
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentExamComponent)