import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import { getTrainingById } from '../../global-state/actions/training-actions';
import { sendMyRequest, getCourse, getOfferCourse } from '../../global-state/actions/course-actions';
import { useLocation } from "react-router-dom";
import { getDomainFromUrl, returnDynamicValueFromUrl } from "../../helpers/domainCheck";

const HeaderRegistrationPreviewProfPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [showChat, setShowChat] = useState(false)
    const location = useLocation();
    const [follow, setFollow] = useState<any>()
    const idFollow = returnDynamicValueFromUrl(3);
    let actualStudent = useMemo(() => props?.actualStudent, [props?.actualStudent])

    const handleTitle = () => {
        if (props?.session) {
            return props?.session?.title
        }
        if (props?.path) {
            return props?.path?.title
        } else {
            return props?.courseForm.courseTitle
        }
    }
    
    const title = useMemo(() => {
        return handleTitle();
    }, [props?.session, props?.path, props?.courseForm])
    useEffect(() => {

        let object = props?.follow?.find((followObj: any) => { return followObj.id === Number(returnDynamicValueFromUrl(3)) })
        setFollow(object)

    }, [props?.follow])
    useEffect(() => {

        if (follow && follow.courseId !== null) {
            props?.getCourse(follow.courseId);
        } else if (follow && follow.pathParentId !== null) {
            props?.getTrainingById('paths', follow.pathParentId);
        } else if (follow && follow.sessionId !== null) {
            props?.getTrainingById('sessions', follow.sessionId);
        }
    }, [follow])
    const openChat = (value: boolean) => {
        props?.openChat(value);
        setShowChat(!showChat);
    }
    const openModalFN = async () => {
        await props?.openModal({ idModal: ModalIDS.REFUSE_REGISTRATION, size: "md" });
    }
    const getMessage = (type: string) => {
        switch (type) {
            case 'COURSE':
                return t('messageValiderRegistration');
            case 'PATH':
                return t('messageValiderRegistrationParcours');
            default:
                return t('messageValiderRegistrationSession');
        }
    }
    const validerRegistration = async () => {
        let code = getDomainFromUrl()

        let status = {
            status: (props?.priceCourse && props?.priceCourse.price !== 0) ? "UNPAID" : "INSCRIBED",
            follow: { id: idFollow }
        };

        let obj = {
            comment: t('nothingTOSay'),
            idStudent: props?.actualStudent?.id,
            idRegistration: props?.registration?.id,
            status: "INSCRIBED",
            code: code,
            idFollow: returnDynamicValueFromUrl(3),
            title: (props?.actualStudent?.courseTitle) ? props?.actualStudent?.courseTitle : props?.actualStudent?.title,
            idSchool: props?.connectedUser?.idSchool,
            trainingType: props?.actualStudent?.type,

        }
        await props?.sendMyRequest(status, obj, props?.connectedUser?.idPerson, actualStudent?.id);
        await props?.openModal({ idModal: ModalIDS.ACCEPT_REGISTRATION, size: "md", buttonText: t('backtodemand'), lines: [getMessage(props?.actualStudent.type)] });

    }
    const changeMyDecision = async () => {

        let status = {
            status: "CANDIDATE",
            follow: { id: returnDynamicValueFromUrl(3) }
        };
        await props?.sendMyRequest(status, null, null, null);

    }

    return (
        <>
            <div className="px-3 pt-3">
                <div className="px-0 mx-auto pt-5" style={{ maxWidth: '1200px' }}>
                    <div className="py-3 justify-content-between">
                        <div className=" py-4 d-flex align-items-center justify-content-between flex-wrap ">
                            <div className="col-md-7">
                                <h1 style={{ textAlign: 'left' }} className="H2-Headline ">{t('demandeDinscription')}</h1>
                                <span className="H3-Headline neutral-3"><span>{title}</span></span>
                            </div>
                            <div className="col-md-5 d-flex align-items-center justify-content-end">
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "CANDIDATE") &&

                                    <> <button type="button" className="btn-Primary large success me-2" onClick={() => { validerRegistration() }}>
                                        {t('valider')}
                                    </button>
                                        <button type="button" className="btn-Primary large danger" onClick={() => openModalFN()}>
                                            {t('refuser')}
                                        </button> </>}
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "REFUSED") &&
                                    <>
                                        <button className="btn-text outlined me-3" onClick={() => { changeMyDecision() }}>{t('changeMyDecision')}</button>
                                        <div className="badge-withicon unvalide">
                                            <span className="material-icons-outlined">close</span>
                                            <span className="ms-2">
                                                {t('fileRefused')}
                                            </span>
                                        </div></>}
                                {(follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "INSCRIBED" || follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "CERTIFIED" || follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "UNPAID")
                                    &&
                                    <div className="badge-withicon validated">
                                        <span className="material-icons-round">done</span>
                                        <span className="ms-2">
                                            {t('fileValidated')}
                                        </span>
                                    </div>}
                                <button type="button" className="btn-QuickActions ms-3" id="openChat" onClick={() => { openChat(!showChat) }}><span className="material-icons-outlined">chat_bubble_outline</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    follow: state.courseReducer.follow,
    actualStudent: state.detailsProfileReducer.actualStudent,
    registration: state.courseReducer.registration,
    connectedUser: state.authReducer.connectedUser,
    socket: state.socketReducer.socketChat,
    listPosts: state.chatReducer.listPosts,
    openChat: ownProps?.openChat,
    priceCourse: state.courseReducer.priceCourse,
    session: state.trainingReducer.session,
    path: state.trainingReducer.path,
    courseForm: state.courseReducer.courseInfo,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    openModal,
    sendMyRequest,
    getOfferCourse,
    getTrainingById,
    getCourse
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRegistrationPreviewProfPageComponent);