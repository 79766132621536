import { ComponentProps, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getCurrentCourse } from '../../../global-state/actions/student-training-actions';
import { getDataFromURL, getDuration } from '../../../base/functions/Functions';


const iconClosed = <div className="state-cours locked">
    <span className="material-icons-outlined neutral-4 state-cours-icon small">
        lock
    </span>
</div>;

const iconCompleted = <div className="state-cours certified">
    <svg className="state-cours-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7023 4.92903V1.77235C15.7023 0.79179 14.8927 0 13.8712 0H1.84732C0.831296 0 0 0.79179 0 1.77235V15.5212C0 16.507 0.831296 17.304 1.84732 17.304H10.8666V19.2651C10.8666 19.5326 11.0242 19.7738 11.2632 19.9049C11.5023 20.036 11.8011 20.0307 12.0348 19.8944L14.6917 18.3475L17.3485 19.8944C17.4681 19.9626 17.6039 19.9993 17.7452 19.9993C17.8756 19.9993 18.0168 19.9678 18.1309 19.9049C18.37 19.7738 18.5276 19.5326 18.5276 19.2651V13.4971C19.4512 12.5743 20 11.3263 20 9.94719C19.9946 7.46695 18.1472 5.39047 15.7023 4.92903ZM10.8666 15.8358H1.84732C1.66803 15.8358 1.52133 15.6995 1.52133 15.5212V1.77235C1.52133 1.59931 1.66803 1.46822 1.84732 1.46822H13.8712C14.0505 1.46822 14.1809 1.59931 14.1809 1.77235V4.85037C11.5186 5.09158 9.37789 7.28342 9.37789 9.94195C9.37789 11.321 9.94295 12.5743 10.8666 13.4919V15.8358ZM17.0062 17.9647L15.0992 16.8583C14.8547 16.7167 14.5395 16.7167 14.295 16.8583L12.3879 17.9647V14.5564C13.0943 14.8762 13.8767 15.0597 14.6971 15.0597C15.5175 15.0597 16.2999 14.8815 17.0062 14.5564V17.9647ZM14.6971 13.5915C12.6161 13.5915 10.9209 11.9555 10.9209 9.94719C10.9209 7.93888 12.6161 6.30286 14.6971 6.30286C16.778 6.30286 18.4732 7.93363 18.4732 9.94719C18.4732 11.9555 16.778 13.5915 14.6971 13.5915Z" fill="#9DDBA9" />
        <path d="M9.83425 3.88025H2.98828V5.34847H9.83425V3.88025Z" fill="#9DDBA9" />
        <path d="M7.60659 7.91785H2.98828V9.38607H7.60659V7.91785Z" fill="#9DDBA9" />
        <path d="M8.63892 11.9556H2.98828V13.4238H8.63892V11.9556Z" fill="#9DDBA9" />
    </svg>
</div>
const iconOpened = <div className="state-cours unlocked-inprogress">
    <div className="threedots-small d-flex">
        <span className=""></span>
        <span className=""></span>
        <span className=""></span>
    </div>
</div>;
const iconProgress = <div className="state-cours unlocked-inprogress">
    <div className="threedots-small d-flex">
        <span className="inprogress"></span>
        <span className="inprogress"></span>
        <span className="inprogress"></span>
    </div>
</div>;

const PathCourseComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [contentCourse, setContentCourse] = useState<any>();
    const [progress, setProgress] = useState<number>(0);
    const [status, setStatus] = useState<string>("");

    const sortByOrder = (a: any, b: any) => {
        if ((new Date(a?.creationDate).getTime() - new Date(b?.creationDate).getTime()) > 0) {
            return -1;
        }
        if ((new Date(a?.creationDate).getTime() - new Date(b?.creationDate).getTime()) < 0) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {
        (typeof props?.course != undefined) && setContentCourse(props?.course);
        setStatus(props?.course?.followUp?.statusList?.sort(sortByOrder)[0]?.status);
        if (props?.course?.followUp?.progressTraining && props?.course?.followUp?.progressTraining !== null) {

            setProgress(props?.course?.followUp?.progressTraining?.progress);
        }
    }, [])
    useEffect(() => {
        (typeof props?.course != undefined) && setContentCourse(props?.course);
        setStatus(props?.course?.followUp?.statusList?.sort(sortByOrder)[0]?.status);
        if (props?.course?.followUp?.progressTraining && props?.course?.followUp?.progressTraining !== null) {

            setProgress(props?.course?.followUp?.progressTraining?.progress);
        }
    }, [props?.course])
    const clickedCourse = async (idFollow: number) => {
        if (!((props?.statusParent && props?.statusParent === 'CLOSED') || status === 'CLOSED') && (typeof idFollow !== undefined)) {
            props?.clickedCourse(idFollow)
        }

    }


    return (
        <>
            {
                !props.isMobile && <div onClick={() => { clickedCourse(contentCourse?.followUp?.id) }} className="d-flex align-items-center bg-with-border white vignette-hover dragAndDrop py-28 ps-40 pe-4 " style={{ maxWidth: props?.width, width: '-webkit-fill-available' }}>
                <div className="me-40">
                    {(props?.secondIndex === undefined) && <span className="H3-Headline">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span>}
                    {(props?.secondIndex !== undefined) && <span className="H3-Headline">{props?.index + 1}.{props?.secondIndex + 1}</span>}

                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <span className="H3-Headline mb-6 formation-card horizontal title">{contentCourse?.title}</span>
                            <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                                <div className="d-flex me-2">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                                        <line x1="5.38184" y1="9.77632" x2="10.6187" y2="9.77632" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                                        <line x1="10.6182" y1="6.22368" x2="5.38132" y2="6.22368" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </div>
                                <div className="body-sm bold neutral-2">
                                    {t('Course')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex align-items-center detail-liste-responsive-actions detail-liste-responsive-session me-4 pe-2 justify-content-end" style={{ minWidth: 520 }}>
                        <div className="ms-2 d-flex flex-row detail-liste-responsive-other progress-training flex-wrap me-4 pe-2">
                            <div className="d-flex align-items-center detail-liste-responsive-level me-5 pe-3">
                                <div className="me-4 " style={{ width: 148, textAlign: 'left' }}>
                                    <span className="body-md bold neutral-3" style={{ textTransform: 'capitalize' }}>{t('progress')} :
                                        <span className={`${(progress === 0) ? 'neutral-3' : (progress ==100 ) ? 'green-800' : 'black-800'}`}> {progress}% </span></span>
                                </div>
                                <div className="ms-2">
                                    {(status === "CLOSED" || (props?.statusParent && props?.statusParent === 'CLOSED')) ? iconClosed : (progress === 0 && status === "OPENED") ? iconOpened : (status === "CERTIFIED") ? iconCompleted : iconProgress}
                                </div>
                            </div>
                            {progress !== 100 ? <div className="d-flex align-items-center">
                                <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
                                <span className="body-md bold">{getDuration(contentCourse?.duration)}</span>
                            </div> : (contentCourse?.diploma != null && contentCourse?.followUp?.certificationLink!=null) &&
                            <button type="button" className="btn-Secondary medium icon-left" onClick={(e) => { e.stopPropagation(); getDataFromURL(contentCourse?.followUp?.certificationLink) }}>
                                <span className="material-icons-outlined">download</span>
                                <div className="m-auto ps-2">{t('diplomeButton')}</div>
                            </button>}
                        </div>
                    </div>
                </div>
                </div>
            }

            {props.isMobile && <div onClick={() => { clickedCourse(contentCourse?.followUp?.id) }} className="w-100 bg-with-border white vignette-hover p-3" style={{ maxWidth: props?.width, width: '-webkit-fill-available' }}>
                <div className='d-flex align-items-center'>
                    <div className="col-2">
                        {(props?.secondIndex === undefined) && <span className="H4-Subtitle">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span>}
                        {(props?.secondIndex !== undefined) && <span className="H4-Subtitle">{props?.index + 1}.{props?.secondIndex + 1}</span>}

                    </div>
                    <div className="col-7">
                        <span className="H4-Subtitle formation-card ">{contentCourse?.title}</span>
                    </div>

                    <div className="d-flex align-items-center mx-2">
                        <span className="material-icons-outlined neutral-3">timer</span>
                        <span className="body-md bold">{getDuration(contentCourse?.duration)}</span>
                    </div>
                    
                </div>

                <div className='d-flex'>
                    <div className='col-2'/>
                    <div className="d-flex flex-row align-items-center mt-2" style={{ height: 24 }}>
                        <div className="d-flex me-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222Z" fill="#5693EE" />
                                <line x1="5.38184" y1="9.77632" x2="10.6187" y2="9.77632" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                                <line x1="10.6182" y1="6.22368" x2="5.38132" y2="6.22368" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </div>
                        <div className="body-sm bold neutral-2">
                            {t('Course')}
                        </div>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className="col-2"/>
                    <div className="d-flex align-items-center detail-liste-responsive-level col-9">
                        <div className="" style={{ width: 148, textAlign: 'left' }}>
                            <span className="body-md bold neutral-3" style={{ textTransform: 'capitalize' }}>{t('progress')} :
                                <span className={`${(progress === 0) ? 'neutral-3' : (progress === 100 ) ? 'green-800' : 'black-800'}`}> {progress}% </span></span>
                        </div>
                        <div className="">
                            {(status === "CLOSED" || (props?.statusParent && props?.statusParent === 'CLOSED')) ? iconClosed : (progress === 0 && status === "OPENED") ? iconOpened : (status === "CERTIFIED") ? iconCompleted : iconProgress}
                        </div>
                        {progress !== 100 ? <>
                        </> : (contentCourse?.diploma != null && contentCourse?.followUp?.certificationLink!=null) &&
                        <button type="button" className="btn-Secondary medium icon-left" onClick={(e) => { e.stopPropagation(); getDataFromURL(contentCourse?.followUp?.certificationLink) }}>
                            <span className="material-icons-outlined">download</span>
                            <div className="m-auto ps-2">{t('diplomeButton')}</div>
                        </button>}
                    </div>
                </div>
            </div>}

         
        </>
    )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ getCurrentCourse }, dispatch);

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    index: ownProps?.index,
    clickedCourse: ownProps?.clickedCourse,
    statusParent: ownProps?.statusParent
});

export default connect(mapStateToProps, mapDispatchToProps)(PathCourseComponent);
