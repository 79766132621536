import { EReduxActionTypes } from '../actions/upload-video-course-actions';


export interface IReduxUploadState {
    vimeoLink?: any,
    uploading: any,
    uploadStatus: any,
    idVideo: string,
    transcoding: any,
    transcodingProgress: any,
    transcodeStatus: string,
    checkStopped: boolean,
    id?: any
    uuidReadyToDisplay?: any,
    loading: boolean,
    readyToDisplay: string

}

const initialState: IReduxUploadState = {
    vimeoLink: undefined,
    uploading: false,
    uploadStatus: '',
    idVideo: '',
    transcoding: false,
    transcodingProgress: 0,
    transcodeStatus: '',
    checkStopped: false,
    id: undefined,
    loading: true,
    readyToDisplay: '',
    uuidReadyToDisplay: undefined
};
export default (state = initialState, action: any) => {
    switch (action.type) {
        case EReduxActionTypes.POST_VIMEO_START:
            return {
                ...state,
                ...action.payload
            };
        case EReduxActionTypes.CLEAR_FORM:
            return {
                ...state,
                ...action.payload,
                readyToDisplay: ''
            };

        case EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE:
            return {
                ...state,
                ...action.payload
            };

        case EReduxActionTypes.POST_VIMEO_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case EReduxActionTypes.POST_VIMEO_FAILED:
            return {
                ...state,
                ...action.payload
            };

        case EReduxActionTypes.POST_VIMEO_PROGRESS:
            return {
                ...state,
                ...action.payload
            };
        case EReduxActionTypes.CHECK_VIDEO_AVALIBILITY:
            const copyState = { ...state };
            if (copyState.idVideo !== '') {

                return { ...state, readyToDisplay: action.payload.status, uuidReadyToDisplay: action.payload.uuidReadyToDisplay };
            } else {
                return { ...state };
            }

        default:
            return state;
    }
};