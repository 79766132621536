import { ComponentProps, FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { useHistory } from "react-router";
import { connect } from 'react-redux';

import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { redirect } from '../../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const OpenQuestionToCorretComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    
        const [numQuestion, setNumQuestion] = useState(props?.numQuestion);
        const { t } = useTranslation();
        const [resp, setResp] = useState(props?.obj);
        const [progressId, setProgressId] = useState(props?.Idprogress);
        const history = useHistory();
        const correctQuestion = (obj: any, i: number) => {
            props?.redirect(t('Question'), `${returnStartPathUrl()}/examsDetails/submission/correction`)
            history.push({ pathname: `${returnStartPathUrl()}/examsDetails/submission/correction`, state: { obj, i, progressId } });

        }

        return (
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between bg-accordion-border accordion-head-open py-28 px-40">
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                        <div className="d-flex flex-column" >
                            <div className="d-flex flex-row flex-wrap align-items-center mb-2 ">
                                <span className="H3-Headline me-2 pe-1"><span className="H3-Headline neutral-3">{t("Question")}</span> {numQuestion}</span>
                                <span className="body-sm bold"><span className="body-sm bold neutral-3">{t("openQuestion")}</span> ...<span className="body-sm bold neutral-3">/{resp?.questionByQna?.scaleNote} {t("point")}</span></span>
                            </div>
                            <span className="H4-Subtitle bold ">{resp?.questionByQna?.question?.questionStatement}</span>
                        </div>
                    </div>
                </div>

                <div className="bg-accordion-border accordion-body pb-3">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row mb-4">
                            <div className="badge-square waiting d-flex align-items-center justify-content-center me-3">
                                <div className="threedots-large-Animation d-flex">
                                    <span className="small"></span>
                                    <span className="small"></span>
                                    <span className="small"></span>
                                </div>
                            </div>
                            <button type="button" className="btn-Primary large ms-1" onClick={() => correctQuestion(resp, numQuestion - 1)}>
                                {t("NOTER_LA_QUESTION")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    connectedUserDetails: state.authReducer.connectedUser,
    history: ownProps?.history,
    examStats: state.examReducer.examStats,
    actualTab: state.examReducer.actualTab,
    examForm: state.examReducer.examForm
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestionToCorretComponent);
