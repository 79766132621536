
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useParams } from 'react-router-dom';
import HeaderPassSondage from '../../components/headers-page/header-pass-sondage'
import { AppState } from '../../global-state/store/root-reducers';
import { getExamToPass, getExistingAnswers, setCurrentQuestion, setQuestions, clearExamProgress } from '../../global-state/actions/exam-actions';
import { checkProgressCourse, getCurrentCourse } from '../../global-state/actions/student-training-actions';
import LoadingPassExam from './loading-pass-exam';
import HeaderPreviewSondageComponent from '../../components/sondage/header-preview-sondage-component';
import PassSondageComponent from '../../components/sondage/pass-sondage-component';



interface Params {
    id: any
}
const PassQnaDirectorPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { id } = useParams<Params>();
    const [exam, setExam] = useState(props?.examToPass)

    useEffect(() => {
        if(props?.examToPass!=undefined){
            setExam(props?.examToPass)
        }
    }, [props?.examToPass])
    
    


    useEffect(() => {
        onInit(id);
        return () => {
            const finish = async () => {
                if (props?.currentCourse?.type === 'COURSE') {
                    if (props?.currentCourse?.followUp && props?.currentCourse?.followUp?.id) {
                    await props?.checkProgressCourse(props?.currentCourse?.followUp, props?.currentCourse?.id, props?.connectedUser?.idPerson, true);
                    }
                    await props?.getCurrentCourse(props?.currentCourse?.followUp?.id);
                }
                await props?.clearExamProgress();
            }
            finish();
        };
    }, [id])

    const onInit = async (id: any) => {
        await props?.setCurrentQuestion(0)
    }

    useEffect(() => {
        
        if (props?.examToPass) {
            props?.setQuestions(props?.examToPass?.questionByQnaList)
        }
    }, [props?.examToPass])

    return (
        <div>
            {(exam!=undefined && props?.index!=undefined)?<div >
            <HeaderPreviewSondageComponent/>            
            <div  className="px-4 mx-auto py-3" style={{ maxWidth: '1232px' }}>
                <HeaderPassSondage />
            </div>
            
            <div className="px-4 mx-auto py-3" style={{ maxWidth: '750px' }}>
                <PassSondageComponent />
            </div>
        </div> :
            <div className="px-4 mx-auto py-3"  style={{maxWidth: '749px' , paddingTop: "104px" }}>
               <LoadingPassExam />
            </div>}
        </div>
    )
}



const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamToPass,
            setQuestions,
            getExistingAnswers,
            setCurrentQuestion,
            clearExamProgress,
            checkProgressCourse,
            getCurrentCourse
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    examToPass: state.examReducer.examToPass,
    currentCourse: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    index:state.examReducer.indexQuestionToPass,
    user: state.authReducer.connectedUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(PassQnaDirectorPage);

