import { FC } from "react";
import { Route, Switch } from "react-router";

import RouteWithBreadCrumb from '../../navigations/RouteWithBreadCrumb';
import FooterComponent from "../../components/footer-component";
import CoursePaymentPage from "../finance/training-payment-page";
import PaymentPage from "./payment-page";
import VerificationPage from "./verification-page";
import CustomSwitch from "../../helpers/custom-switch";
import CheckoutSuccededPage from "./checkout-succeeded-page";
const PaymentContainer: FC<any> = (props: any) => {
  return (<>
    <div className="page-wrapper-height" >
      <CustomSwitch>
        <Route exact={true} path={`${props?.match.path}/success`} component={CheckoutSuccededPage} />
        <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/payment/:id`} component={PaymentPage} />
        <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/verification/:id`} component={VerificationPage} />
      </CustomSwitch>

    </div>
    <FooterComponent />
  </>
  )
}
export default PaymentContainer;