import { ComponentProps, FC, useEffect} from 'react';

import { AppState } from '../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory, useParams } from "react-router";
import LoadingComponent from '../components/auth-components/login-register-components/loading-component';
import { CheckToken } from '../global-state/actions/auth-actions';
import { returnStartPathUrl } from '../helpers/domainCheck';

interface RouteParams {
    token: string
}

const InvitationDirectorContainer: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {

    const { token } = useParams<RouteParams>();
    const history = useHistory();
    useEffect(() => {
        if(token !==undefined && token?.length!=0){
            props?.CheckToken(token);
        }
    }, [token])

    useEffect(() => {
        if (props?.tokenStatus === "token Verifié") {
            if (props?.loggedIn){
                history.push(`${returnStartPathUrl()}/activity`)
            }else {
                history.push(`${returnStartPathUrl()}/activated`)
            }
        }
        else if(props?.tokenStatus == "token doesn't exist"){
            history.push(`${returnStartPathUrl()}/auth/login`)
        }
    }, [props?.tokenStatus])

    return (
        <LoadingComponent/>
    );

}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    tokenStatus: state.authReducer.tokenStatus
    
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            CheckToken
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(InvitationDirectorContainer);