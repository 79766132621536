import Skeleton from '@material-ui/lab/Skeleton';

export default function FilterStudentsListLoadingComponent() {


    return (
        <div className="mt-4" style={{maxWidth: '1200px'}}>
            <div className="d-flex -align-items-center justify-content-between w-100 px-0" >
                <Skeleton className="skeleton square" animation="wave" variant="rect" width={212} height={48} />
                <div className="input-form-control" style={{ width: '220px' }}>
                    <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={'100%'} height={48} />
                </div>
            </div>
        </div>
    );
}
