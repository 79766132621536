import Axios from '../../../helpers/interceptors';
import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { getProfessor, } from '../../../global-state/actions/professor-actions';
import { ModalIDS } from '../modal-ids';
import { getCodeFromUrl } from '../../../helpers/domainCheck';
const InviteAssistantModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const { t } = useTranslation();
    let [email, setEmail] = useState("");
    let [msg, setMsg] = useState("")
    let link = getCodeFromUrl();
    const regx = RegExp(/^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    const Inviter = () => {
        let auth = ('Bearer ' + localStorage.getItem("access_token")).replace(/\"/g, "")
        Axios.get(baseUrl + "users/" + props?.director?.id, {
            headers: {
                'Authorization': auth
            }
        })
            .then((result) => {

                Axios.post(baseUrl1 + "person/invitationWithLimitations", {
                    "invitation": {
                        "invitedBy": {
                            "id": result.data.id,
                            "firstName": result.data.firstName,
                            "lastName": result.data.lastName,
                            "gender": result.data.gender,
                            "type": "NP"
                        },

                        "invited": {
                            "email": email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                            "type": "NP"
                        },
                        "relationType": "ADMINSTRATOR",

                        "to": {
                            "id": props?.director?.idSchool,
                            "code": props?.schoolInfo?.domain ?? document.location.hostname,
                            "name": props?.schoolInfo?.name,
                            "type": "LP",
                        }
                    },
                    "domain":props?.director?.role


                }).then((res) => {

                    Axios.post(baseUrl1 + "person/attach", {
                        "invitation":
                        {
                            "id": res.data.id,
                            "invitedBy": res.data.invitedBy,
                            "invited": res.data.invited,
                            "relationType": "ADMINSTRATOR",
                            "to": res.data.to,
                        }
                    }).then(async (ress) => {
              
                        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'ok', lines: [t('inviteSuccess')] });
                        props?.getProfessor(props?.director.idSchool, 'refresh');
                    })
                }).catch((error) => {

                    setMsg(error.response.data.message)
                })

            }).catch((error) => {

                setMsg(error?.response?.data?.message)
            })
    }
    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0 , overflow:'hidden'}}>
                <div className="row justify-content-center">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="H3-Headline">{t('assistantInvitation')}</h3>
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="form-title">{t('mailAssistant')}</label>
                                <input dir="ltr"type="text" className={`form-control input text-default ${(!regx.test(email) && (email !== '')) ? "error" : "success"}`} placeholder={t('emailLogin')} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                                <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >
                                    {t('annuler')}
                                </button>
                                <button className={`btn-Primary large ${(email !== '' && regx.test(email)) ? "success" : ""}`} disabled={!(email !== '' && regx.test(email))} onClick={Inviter} >
                                    {t('send')}
                                </button>
                                {msg}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({

    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    loadingAllProfessors: state.profReducer.loadingAllProfessors
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            getProfessor
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteAssistantModalComponent);