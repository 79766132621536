import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton'

const CommentCardLoading: FC<ComponentProps<any>> = () => {
    return (
        <>
            <div className="my-3">
                <div className="d-flex align-items-top mb-2">
                    <div className="me-3">
                        <Skeleton className="skeleton" animation="wave" variant="circle" width={36} height={36} />
                    </div>
                    <div className="d-flex flex-wrap badge-text dark align-items-center w-50">
                        <Skeleton variant="text" animation="wave" width={'25%'} className="skeleton data-post me-3 discuttion-titles" />
                        <div className="d-flex flex-wrap discuttion-titles align-items-center w-50">
                            <Skeleton variant="text" animation="wave" width={'45%'} className="skeleton data-post discuttion-titles" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column ms-5 ps-1 my-3">
                    <Skeleton variant="text" animation="wave" width={'85%'} className="skeleton data-post discuttion-titles" />
                    <Skeleton variant="text" animation="wave" width={'75%'} className="skeleton data-post discuttion-titles my-3" />
                    <Skeleton variant="text" animation="wave" width={'10%'} className="skeleton data-post discuttion-titles" />
                    <div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CommentCardLoading;