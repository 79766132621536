import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { handleAddNewResponse, handleChangeResponse } from "../../global-state/actions/sondage-actions";

const QuestionInputComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({
  handleChangeResponse,
  choiceQuestion,
  questionByExam,
  handleAddNewResponse,
  index,
  indexResponse,
  lengthOfChoices
}: any) => {

  const newResponse = {
    id: 1,
    choice: "ds", //reponse
    choiceState: false, //par defaut
  };

  return (
    <div className="wrapper-question-input">
      <div className="question-input">
          {questionByExam?.question?.quizType === "QCM" &&
            <div className="checkbox-input">
                <input
                  className="input-style-checkbox"
                  type="checkbox"
                  id={`checkBtn${indexResponse}`}
                />
                <label htmlFor={`checkBtn${indexResponse}`} />
              <span>Réponse {indexResponse+1}</span>
            </div>
          }
           {questionByExam?.question?.quizType === "QCU" &&
           <div className="d-flex align-items-top">
           <input className="input-style-radio" type="radio" name="exampleRadios" id={`exampleRadios${indexResponse}`} value="option" />
           <label htmlFor={`exampleRadios${indexResponse}`}></label>
           <span className="form-title ps-1 ms-2">Réponse {indexResponse+1}</span>
         </div>
          }
        <input
          type="text"
          className="form-control input-welcome input-response"
          placeholder="Saisissez votre réponse ici"
          onChange={(e) => handleChangeResponse(e.target.value, index, indexResponse) }
          value={questionByExam.question.questionChoice[indexResponse].choice}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  sondageReducer: state.examReducer.examToPass,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      handleAddNewResponse,
      handleChangeResponse
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionInputComponent);
