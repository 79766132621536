import { useTranslation } from "react-i18next";




export default function CardChapterShowOnlyComponent(props: any) {
    const { t } = useTranslation();
    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {
            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    return (<div className={` d-flex align-items-center bg-with-border white vignette-hover dragAndDrop  ps-40 pe-4 py-28 mt-3`} >
      <div style={{width: 70}}>
        <span className="H3-Headline">{(props?.index < 10)? "0" + props?.index: props?.index}</span>
      </div>

    <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span className="H3-Headline mb-6 formation-card horizontal title">{props?.chapter?.chapter?.title}</span>
          <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
            <div className="d-flex me-2">
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 1.07141H9.48145V2.7857H16V1.07141Z" fill="#CACBCE" />
                <path d="M16 5.64276H9.48145V7.35705H16V5.64276Z" fill="#CACBCE" />
                <path d="M16 10.2143H0V11.9286H16V10.2143Z" fill="#CACBCE" />
                <path d="M16 14.7856H0V16.4999H16V14.7856Z" fill="#CACBCE" />
                <path d="M1.18519 7.35708H5.62963C5.94396 7.35708 6.24542 7.23667 6.46768 7.02235C6.68995 6.80802 6.81482 6.51733 6.81482 6.21422V1.6428C6.81482 1.33969 6.68995 1.049 6.46768 0.834674C6.24542 0.620347 5.94396 0.499939 5.62963 0.499939H1.18519C0.870854 0.499939 0.569398 0.620347 0.347133 0.834674C0.124868 1.049 0 1.33969 0 1.6428V6.21422C0 6.51733 0.124868 6.80802 0.347133 7.02235C0.569398 7.23667 0.870854 7.35708 1.18519 7.35708ZM1.77778 2.21422H5.03704V5.6428H1.77778V2.21422Z" fill="#CACBCE" />
              </svg>
            </div>
            <span className="body-sm bold neutral-2">{t('breadCrumbChapter')} {props?.chapter?.compteur}</span>
          </div>
        </div>
      </div>
      <div className=" d-flex align-items-center detail-liste-responsive-actions me-2 justify-content-end" style={{ minWidth: 290 }}>
        <div className="d-flex align-items-center detail-liste-responsive-chapter">
          <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
          <span className="body-md bold">{convertTime(props?.chapter?.chapter?.duration)}</span>
        </div>
      </div>
    </div>
  </div>)
}