
import { FC, useEffect, useState, } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AppState } from "../../../global-state/store/root-reducers";
import { CheckToken } from "../../../global-state/actions/auth-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { returnStartPathUrl } from "../../../helpers/domainCheck";
interface RouteParams {
    token: string
}
const MailValidation: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { token } = useParams<RouteParams>();
    const [msg, setMsg] = useState("");
    useEffect(() => {


        props?.CheckToken(token);

    }, [])
    useEffect(() => {

        if (props?.tokenStatus == "token Verifié") {
            setMsg(t('verifMail_1'));
        }
        else {

            setMsg(t('invalidToken'))
        }

    }, [props?.tokenStatus])

    return (
        <>
        <div className="auth-close">
                    <button type="button" className="btn-QuickActions">
                    <span className="material-icons-outlined">close</span>
                </button>
                        
                    </div>
            <div style={{ marginBottom: '20%' }}></div>
            <div className="form-login row" >
                <div className="col-md-12 text-center">
                <span className="H3-Headline">{t('verifMail_3')}</span>
             

                </div>
                <div style={{ marginBottom: '5%' }}></div>
                <div className="col-md-12 text-center">
                    <span className="H4-Subtitle col-md-12 text-center">  {msg}</span>

                </div>
                <div style={{ marginBottom: "2%" }}></div>

                <div className="col-md-12 text-center">
                    <button type="button" className="btn-Secondary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                        {t('cnxButton')}
                    </button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    tokenStatus: state.authReducer.tokenStatus

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            CheckToken
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MailValidation);