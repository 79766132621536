import React, { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { useHistory } from "react-router-dom";
  const HeaderPreviewComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [sondageVision, setSondageVesion] = useState(true)
  const history = useHistory()

  const redirect = async () => {
    props?.clearHistoryArray()
    props?.redirect("Title", `${returnStartPathUrl()}/survey/view/`+props?.examToPass?.id)
    await history.push(`${returnStartPathUrl()}/survey/view/`+props?.examToPass?.id)
 
  }
  useEffect(() => {
    
    if (!sondageVision && !!props?.examToPass) {
      redirect();
    }
  }, [sondageVision])
  return (
    <div className="container-header-create-sondage">
      <div className="header-creacte-sondage">
        <div className="title-header-creacte-sondage">Nouveau sondage </div>
        <div className="btn-header-creacte-sondage">
        <input dir="ltr" readOnly className="input-style" type="checkbox" id="toggleBtn4" onClick={() => setSondageVesion(!sondageVision)} checked={!sondageVision} />
          <div className="toggle-container cours-editing">
            <label className="label-style cours-editing" htmlFor="toggleBtn4">
              <span className="on cours-editing">Éditer</span>
              <span className="off cours-preview" >Prévisualiser</span>
              <input type="text" style={{ display: "none" }} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  title: ownProps.title,
  examToPass: state.examReducer.examToPass,
  school:state.schoolInfoReducer
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
          redirect, clearHistoryArray,
        },
        dispatch
    );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPreviewComponent);
