import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutPaymeeComponent from "./checkout-paymee-component";
import { useParams } from 'react-router-dom';
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { getCards } from '../../global-state/actions/auth-actions';
import axiosInstance from '../../helpers/interceptors';
import { closeModal, openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import UpgradeStripeComponent from '../finance/upgrade_paymentComponent';
import { getPaymentSettingApi, getProductListApi } from '../../services/finance.service';
import { getOffer } from '../../global-state/actions/app-container-actions';
interface Params {
  id: string
  period: string
}
let currency: string;

const PaymentStripeComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [price, setPrice] = useState<any>(undefined);
  const [priceId, setPriceId] = useState<any>();
  const stripePromise = loadStripe(`${process.env.REACT_APP_BASE_STRIPE_KEY}`);
  const [localisation, setLocalisation] = useState<any>("FR")
  const [offer, setOffer] = useState<any>(undefined)
  const [clicked, setClicked] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState('')
  const [msg, setMsg] = useState<string>('')
  const [coupon, setCoupon] = useState<any>()
  const [valid, setValid] = useState<boolean>()
  const { id } = useParams<Params>();
  const [payaction, setpayAction] = useState<Boolean>(false);

  const [buttonStyle, setButtonStyle] = useState<any>(undefined)
  const [cardStyle, setCardStyle] = useState<any>(true)
  const [cards, setCards] = useState<any>(props?.cards)
  const [customer, setCustomer] = useState<any>()
  const [ruleName, setRuleName] = useState<any>()
  const { t } = useTranslation()
  const params = new URLSearchParams(window?.location?.search).get("period");
  const [period, setPeriod] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [productList,setProductList] = useState<any>();

  useEffect(() => {
    if (!!offer && props?.offer?.pricings?.length > 1) {
      setPriceId(props?.offer?.pricings[1]?.id)
    } else {
      setPriceId(props?.offer?.pricings[0]?.id)
    }
  }, [props?.offer])

  useEffect(() => {
    props?.getOffer(parseInt(id)).then((resp: any) => {
      if (!!resp?.data && resp?.data?.pricings.length > 1) {
        setOffer(resp?.data);
        if (params === "monthly") {
          setPrice(resp?.data?.pricings[1]?.price)
        } else {
          setPrice(resp?.data?.pricings[0]?.price)
        }
      }
      if (!!resp?.data && resp?.data?.pricings.length == 1) {
        setOffer(resp?.data);
        setPrice(resp?.data?.pricings[0]?.min)
      }

    })
  }, [id])

  useEffect(() => {
    if (params === "monthly") {
      setButtonStyle(true)
      setPeriod('monthly')
    } else {
      setButtonStyle(false)
      setPeriod('yearly')
    }
  }, [params])

  useEffect(() => {
    if (!!props?.userProfileDetails?.id) {
      getPaymentSettingApi(props?.schoolInfo?.id, "CUSTOMER").then(async (resp: any) => {
        setCustomer(resp?.data)
        if (!!resp?.data && !!resp?.data?.engineId) {
          props?.getCards(resp?.data?.engineId).then((response: any) => {
            setCards(response.data);

          })
        }
      })
    }
  }, [props?.userProfileDetails])

  useEffect(() => {

    if (!!props?.cards) {
      setCards(props?.cards)
    }
  }, [props?.cards])

  useEffect(() => {
    if (cards?.length > 0) {
      setCardStyle(true)
    } else {
      setCardStyle(false)
    }

  }, [cards])

  useEffect(() => {
    getProductListApi("SUB").then((res)=>{
      setProductList(res)
    });
  }, [])


  useEffect(() => {
    if (!!props?.offer && !!props?.offer?.pricings && props?.offer?.pricings?.length === 1) {


      const rulesPrices = props?.offer?.pricings?.find((year: any) => year?.period == "MONTH")?.rulesPrices[0]
      const product = props?.offer.rules.find((rule: any) => rule.id == rulesPrices?.ruleId)
      let name = ""
      if (!!product && product.key == "nb_cours") {
        name = "formation"
      }
      if (!!product && product.key == "nb_student") {
        name = "étudiant"
      }
      if (!!product && product.key == "nb_administrateur") {
        name = "ADMINSTRATOR"
      }
      if (!!product && product.key == "nb_prof") {
        name = "prof"
      }
      const local = localisation == "TN" ? "TND/" : "€"

      setRuleName(props?.offer?.pricings?.find((year: any) => year?.period == "MONTH")?.rulesPrices[0]?.price + local + name)
    }
  }, [offer, productList, localisation])

  function priceAfterCouponFn(price: any) {
    if (coupon?.promotion?.promoValue) {
      if (coupon?.promotion?.promoType === "CASHBACK") {
        return (price - parseInt(coupon?.promotion?.promoValue))
      } else {
        return (price - (parseInt(price) / 100) * parseInt(coupon?.promotion.promoValue))
      }
    } else {
      return price
    }
  }
  const getprice = (price: any) => {
    const priceWithoutTax = price / 1.2;
    if (Number.isInteger(priceWithoutTax)) {
      return priceWithoutTax;
    } else {
      return priceWithoutTax.toFixed(2);
    }
  }

  const verifyCoupon = async () => {
    if (promoCode !== "") {

      const couponObject ={
        "product":"KOORS",
        "promoCode":promoCode
      }

      let coupon = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL22}coupons/couponsSearch` , couponObject).then(res => { return res.data })
      if (coupon && coupon.couponError?.errorMessage === "promoCode doesn't exists") {
        setMsg("code promo invalide")
        setValid(false)
      }
      else if (coupon?.expireAt !== null && (new Date(coupon?.expireAt) < new Date())) {
        setMsg("Le code promo est expiré")
      }
      else if (!coupon.offers.includes(offer.id)) {
        setMsg("code promo invalide")
      }
      else if (coupon?.promotion?.promoType !== "CASHBACK" && coupon?.promotion.promoType !== "DISCOUNT") {
        setMsg("code promo invalide")
      } else {
        await setCoupon(coupon)
        setValid(true)
        setMsg('')
      }
    }
    else {
      setMsg("Veuillez entrer un code promo")
      setValid(false)
    }
  }

  function calculateReducedPrice(price: number, coupon?: any) {
    let reducedPrice: number = price;
    reducedPrice -= (coupon/1.2);
    if (Number.isInteger(reducedPrice)) {
      return reducedPrice;
    } else {
      return reducedPrice.toFixed(2);
    }
  }

  function calculateReducedPriceDiscount(price: number, coupon?: any) {
    let reducedPrice: number = price;
    reducedPrice -= (reducedPrice / 100) * coupon;

    if (Number.isInteger(reducedPrice)) {
      return reducedPrice;
    } else {
      return reducedPrice.toFixed(2);
    }
  }

  const getFinalTva = (prix: any, couponValeur: any, couponType: any) => {
    let prixFinal = prix / 1.2;
    if (couponType === 'CASHBACK') {
      prixFinal = (prixFinal - (couponValeur/1.2)) * 0.2;
    } else if (couponType === 'DISCOUNT') {
      prixFinal = (prixFinal - (couponValeur / 100) * prixFinal) * 0.2;
    }
    if (Number.isInteger(prixFinal)) {
      return prixFinal;
    } else {
      return prixFinal.toFixed(2);
    }
  };


  const getFinalPrice = (prix: any, couponValeur: any, couponType: any) => {
    let prixFinal = prix;
    if (couponType === 'CASHBACK') {
      prixFinal -= couponValeur;
    } else if (couponType === 'DISCOUNT') {
      const cashbackAmount = (couponValeur / 100) * prix;
      prixFinal -= cashbackAmount;
    }
    if (Number.isInteger(prixFinal)) {
      return prixFinal;
    } else {
      return prixFinal.toFixed(2);
    }
  };


  const idRule = props?.offer?.rules?.find((rule: any) => rule.key === "nb_student")?.id;
  const priceRule = props?.offer?.pricings?.map((pricing: any) => {
    switch (pricing.currency) {
      case 'eur':
        currency = '€'
        break;

      default:
        currency = 'TND'
        break;
    }
    return pricing?.period === "MONTH" && pricing?.rulesPrices?.find((rulesPrice: any) => {
      return rulesPrice.ruleId === idRule
    })
  })[0]?.price;

  const disablePayAction = () => {
    if (payaction === true) {
      setpayAction(false);
    }
  };

  const loadingAction = ((btn: boolean) => {
    setLoading(btn)
  })

  const errorAction = ((btn: boolean) => {
    setError(btn)
  })
  return (<>

    <div className="container-fluid mt-4 mb-4" >



      <div className="d-flex mx-auto px-4" style={{ maxWidth: '1248px' }}>
        <div className=" grid-offer justify-content-around w-100">
          <div className="d-flex me-4 mt-4">
            <div className="d-flex flex-column pe-3 w-100">

              <span className="H3-Headline mb-3 mt-2">{t('FacturationCycle')}</span>

              {!!props?.offer &&
                props?.offer.pricings.length > 1 &&
                period == "monthly" && (
                  <>
                    <div className="d-flex flex-row ">
                      <button
                        style={{ height: "fit-content", width: "100%" }}
                        className={
                          buttonStyle
                            ? "btn-outlined-offer selected w-100 me-4 "
                            : "btn-outlined-offer default me-4 w-100"
                        }
                        onClick={(e) => {
                          setPrice(props?.offer?.pricings[1]?.price);
                          setPriceId(props?.offer?.pricings[1]?.id);
                          setButtonStyle(true);
                        }}
                      >
                        <div className="d-flex flex-column">
                          <span className="body-sm mb-2 pb-1">
                            Facturation - Mensuel
                          </span>
                          <div className="d-flex flex-row align-items-center">
                            <span className="H3-Headline black-800">
                              {(((props?.offer?.pricings[1]?.price / 1.2) - Math.floor(props?.offer?.pricings[1]?.price / 1.2)) != 0) ? (props?.offer?.pricings[1]?.price / 1.2).toFixed(2) : (props?.offer?.pricings[1]?.price / 1.2).toFixed(0)}
                              {localisation == "TN" ? "dt" : "€"}&nbsp;
                            </span>
                            <span className="H4-Subtitle neutral-3">
                              {" "}
                              HT/par mois
                            </span>
                          </div>
                        </div>
                      </button>
                      <div className="w-100 d-flex flex-column justify-content-center">
                        <button
                          className={
                            !buttonStyle
                              ? "btn-outlined-offer selected w-100"
                              : "btn-outlined-offer default me-2 w-100"
                          }
                          onClick={(e) => {
                            setPrice(props?.offer?.pricings[0]?.price);
                            setPriceId(props?.offer?.pricings[0]?.id);
                            setButtonStyle(false);
                          }}
                        >
                          <div className="d-flex flex-column">
                            <span className="body-sm mb-2 pb-1">
                              Facturation - Annuel
                            </span>
                            <div className="d-flex flex-row align-items-center">
                              <span className="H3-Headline black-800">
                                {(((props?.offer?.pricings[0]?.price / 1.2) - Math.floor(props?.offer?.pricings[0]?.price / 1.2)) != 0) ? (props?.offer?.pricings[0]?.price / 1.2).toFixed(2) : (props?.offer?.pricings[0]?.price / 1.2).toFixed(0)}
                                {localisation == "TN" ? "dt" : "€"}&nbsp;
                              </span>
                              <span className="H4-Subtitle neutral-3">
                                {" "}
                                HT/par an
                              </span>
                            </div>
                          </div>
                        </button>

                      </div>
                    </div>
                  </>
                )}
              {!!props?.offer &&
                props?.offer.pricings.length > 1 &&
                period == "yearly" && (
                  <>
                    <div className="d-flex flex-row ">
                      <button
                        className={
                          !buttonStyle
                            ? "btn-outlined-offer selected w-100"
                            : "btn-outlined-offer default me-2 w-100"
                        }
                        onClick={(e) => {
                          setPrice(props?.offer?.pricings[0]?.price);
                          setPriceId(props?.offer?.pricings[0]?.id);
                          setButtonStyle(false);
                        }}
                      >
                        <div className="d-flex flex-column">
                          <span className="body-sm mb-2 pb-1">
                            Facturation - Annuel
                          </span>
                          <div className="d-flex flex-row align-items-center">
                            <span className="H3-Headline black-800">
                              {(((props?.offer?.pricings[0]?.price / 1.2) - Math.floor(props?.offer?.pricings[0]?.price / 1.2)) != 0) ? (props?.offer?.pricings[0]?.price / 1.2).toFixed(2) : (props?.offer?.pricings[0]?.price / 1.2).toFixed(0)}
                              {localisation == "TN" ? "dt" : "€"}&nbsp;
                            </span>
                            <span className="H4-Subtitle neutral-3">
                              {" "}
                              HT/par an
                            </span>
                          </div>
                        </div>
                      </button>
                    </div>
                  </>
                )}

              {!!props.offer && props.offer.pricings.length == 1 && (
                <>
                  <div className="d-flex flex-row">
                    <button
                      className={
                        buttonStyle
                          ? "btn-outlined-offer selected w-100"
                          : "btn-outlined-offer default me-2 w-100"
                      }
                      onClick={(e) => {
                        setPrice(props?.offer?.pricings[0]?.min);
                        setPriceId(props?.offer?.pricings[0]?.id);
                        setButtonStyle(true);

                      }}
                    >
                      <div className="d-flex flex-column">
                        <span className="body-sm mb-2 pb-1">
                          Facturation-Mensuel-Minimum
                        </span>
                        <div className="d-flex flex-row align-items-center">
                          <span className="H3-Headline black-800">
                            {(((props?.offer?.pricings[0]?.min / 1.2) - Math.floor(props?.offer?.pricings[0]?.min / 1.2)) != 0) ? (props?.offer?.pricings[0]?.min / 1.2).toFixed(2) : (props?.offer?.pricings[0]?.min / 1.2).toFixed(0)}
                            {localisation == "TN" ? "dt" : "€"}&nbsp;
                          </span>
                          <span className="H4-Subtitle neutral-3">
                            {" "}
                            HT/ mois
                          </span>
                        </div>
                      </div>
                    </button>
                  </div>
                </>
              )}
              <div className="  mt-4" >
                <hr className="hr-nav my-3" />
                <span className="H3-Headline  mt-2 ">Moyen de paiement</span>

                <div style={{ marginTop: "32px" }}>
                  {
                    <>   <Elements stripe={stripePromise}>

                      <UpgradeStripeComponent 
                        priceId={priceId}
                        price={
                          price
                        }
                        coupon={coupon}
                        offer={props?.offer}
                        disablePayAction1={disablePayAction}
                        loadingAction1={loadingAction}
                        errorAction ={errorAction}
                        payAction={payaction}
                      />
                    </Elements></>
                  }

                  {localisation === "TN" && <CheckoutPaymeeComponent price={price} token={props?.paymeePayment.paymentToken} offer={props?.offer} />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className=" ms-4">
            <div className="d-flex flex-column ps-3">


              <div className="product-detail-payment">
                <div className="d-flex flex-column">
                  <div className="col-12">
                    {clicked ? (
                      <span className="form-title">
                        Code promo / coupon
                      </span>
                    ) : (
                      <button
                        className="btn-text body-sm bold outlined"
                        onClick={() => setClicked(true)}
                      >
                        Avez-vous un code promo / coupon ?
                      </button>
                    )}
                    {valid ? (
                      <div
                        className="d-flex flex-row p-2 mt-3"
                        style={{
                          border: "2px solid #9DDBA9",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center pe-2">
                          <span className='material-icons-outlined green-800 pe-1 pt-1'>local_offer</span>
                        </div>
                        <div className="me-auto body-md bold justify-content-center align-items-center">
                          {promoCode}
                        </div>
                        <button className="btn-text small">
                          <span
                            className="d-flex justify-content-end body-md bold"
                            onClick={() => {
                              setValid(false);
                              setPromoCode("");
                              setMsg("Code promo retiré");
                              setCoupon({});
                            }}
                          >
                            Retirer
                          </span>
                        </button>
                      </div>
                    ) : (
                      clicked && (
                        <div className="d-flex flex-row mt-3">
                          <div className="d-flex col-8">
                            <label className="form-title"></label>
                            <input
                              type="text"
                              value={promoCode}
                              className={`form-control input text-default ${msg !== "" &&
                                msg !== "Code promo retiré"
                                ? "error"
                                : ""
                                }`}
                              onChange={(e) => {
                                setPromoCode(e.target.value);
                              }}
                              placeholder="Entrez le code"
                            />
                          </div>
                          <div className="d-flex ms-2 col-4">
                            <button
                              type="button"
                              className="btn-Primary large"
                              onClick={() => verifyCoupon()}
                            >
                              Appliquer{" "}
                            </button>
                          </div>
                        </div>
                      )
                    )}
                    {msg != "Code promo retiré" ? (
                      <span className="error-input mt-1">{msg}</span>
                    ) : (
                      <span className="form-error mt-1 green-800">
                        {msg}
                      </span>
                    )}
                  </div>

                  <hr
                    className="hr-payment my-4"
                    style={{ margin: 0 }}
                  />


                  <div className="d-flex flex-row justify-content-between mb-8">
                    <div className="d-flex flex-start">
                      <span className="neutral-2 body-md">
                        Offre {props?.offer?.label} -{" "}
                        {buttonStyle ? "Mensuel" : "Annuel"}{" "}
                      </span>
                    </div>
                    <div className="d-flex flex-end">
                      <span className="H4-Subtitle black-800 body-md bold">
                        { }

                        {getprice(price)}€
                      </span>
                    </div>
                  </div>

                  <hr
                    className="hr-payment my-4"
                    style={{ margin: 0 }}
                  />

                  <div className="d-flex justify-content-between mb-1">
                    <span className="H4-Subtitle body-md neutral-2">
                      Prix total HT
                    </span>
                    <span className="H4-Subtitle body-md neutral-2 bold">
                      {getprice(price)}€
                    </span>
                  </div>


                  {valid && (
                    <div
                      className="d-flex flex-row justify-content-between  "
                      style={{ paddingBottom: "8px" }}
                    >
                      <div className="d-flex flex-start">
                        <div className="d-flex justify-content-center align-items-center pe-2">
                          <span className='material-icons-outlined green-800 pe-1 pt-1'>local_offer</span>
                        </div>
                        <span className="body-md green-800">
                          {" "}
                          {promoCode}{" "}
                        </span>
                      </div>
                      <div className="d-flex flex-end">
                        {coupon?.promotion.promoType ===
                          "CASHBACK" && (
                            <span className="H4-Subtitle body-md green-800 bold">
                              -{(coupon?.promotion.promoValue/1.2).toFixed(2)}
                              €
                            </span>
                          )}
                        {coupon?.promotion.promoType ===
                          "DISCOUNT" && (
                            <span className="H4-Subtitle body-md green-800 bold">
                              -{getprice((price * (coupon?.promotion.promoValue / 100)))}
                              €
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  {valid && (
                    <div
                      className="d-flex flex-row justify-content-between  "
                      style={{ paddingBottom: "8px" }}
                    >
                      <div className="d-flex flex-start">

                        <span className="body-md neutral-2">
                          Prix HT après réduction
                        </span>
                      </div>
                      <div className="d-flex flex-end">
                        {coupon?.promotion.promoType ===
                          "CASHBACK" && (
                            <span className="H4-Subtitle body-md neutral-2 bold">
                              {calculateReducedPrice((price / 1.2), coupon?.promotion.promoValue)}
                              €
                            </span>
                          )}
                        {coupon?.promotion.promoType ===
                          "DISCOUNT" && (
                            <span className="H4-Subtitle body-md neutral-2 bold">
                              {calculateReducedPriceDiscount((price / 1.2), coupon?.promotion.promoValue)}
                              €
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-between mb-3">
                    <span className="H4-Subtitle body-md neutral-2">
                      TVA 20%
                    </span>
                    <span className="H4-Subtitle body-md neutral-2 bold">

                      {coupon?.promotion?.promoValue
                        ? getFinalTva(price, coupon?.promotion?.promoValue, coupon?.promotion?.promoType)
                        : getprice(price * (20 / 100))}

                      <span className="H4-Subtitle body-md neutral-2">
                        €
                      </span>
                    </span>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <span className="H3-Headline black-800">
                      Prix total TTC
                    </span>
                    <span className="H3-Headline black-800">
                      {coupon?.promotion?.promoValue
                        ? getFinalPrice(price, coupon?.promotion?.promoValue, coupon?.promotion?.promoType)
                        : price}
                      <span className="H3-Headline neutral-3">
                        €
                      </span>
                    </span>
                  </div>
                  {!loading ? (
                    <div>
                      <button
                        type="button"
                        className="btn-Primary large mt-4"
                        style={{ width: "100%" }}
                        onClick={() => setpayAction(true)}
                      >
                        confirmer et payer
                      </button>

                      {!!props.offer &&
                        !!props.props?.offer?.pricings &&
                        props.props?.offer?.pricings[0]?.type === "DYNAMIC" && (
                          <button
                            type="button"
                            className="btn-Secondary large mt-4"
                            style={{ width: "100%" }}
                            onClick={() => { props?.openModal({ idModal: ModalIDS.TRANSFER, size: "md", data: { price: price, offer: props?.offer, email: props?.userProfileDetails?.email } }); }}
                          >
                            Payer par virement bancaire
                          </button>
                        )}
                    </div>
                  ) : (
                    <button className="btn-Primary large mt-4 w-100 loaddata ">
                      <div className="threedots-large-Animation">
                        Traitement...
                      </div>
                    </button>
                  )}
                  {error && <span className="body-sm" style={{ color: "#E06A6A", marginTop: "6px" }}>Erreur de paiement, vous n&apos;avez pas été prélevés. Veuillez
                    réessayer plus tard</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  userProfileDetails: state.authReducer.connectedUserInfo,
  subscription: state.schoolInfoReducer.subscription,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  offer: state.schoolInfoReducer.offer
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getCards,
  closeModal, openModal,
  getOffer
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStripeComponent);