import { ComponentProps, FC, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import PrivateRoute from "../../../navigations/PrivateRoute";
import { getTrainingById } from '../../../global-state/actions/training-actions';
import { useLocation } from 'react-router-dom';
import CustomSwitch from "../../../helpers/custom-switch";
import { getContent } from "../../../global-state/actions/course-actions";
import NavbarSurveyComponent from "../../../components/navigation-components/navbar-survey-component";
import surveyPage from "./survey-preview-page";
import responseSurveyPage from "./response-survey-page";
const SurveyDetailsContainer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
  const { id } = useParams<any>();
  const location = useLocation()

  const activeTab: string = useMemo(() => {
    return (location.pathname.split("/").pop() as string);
  }, [location?.pathname])

  

  return (<>

    <NavbarSurveyComponent activeTab={activeTab} type={'survey'} id={id} />
    <CustomSwitch>
      <PrivateRoute exact={true} path={`${props?.match.path}/preview`} component={surveyPage} />
      <PrivateRoute exact={true} path={`${props?.match.path}/response`} component={responseSurveyPage} />

    </CustomSwitch>
  </>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ getTrainingById, getContent }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  courseForm: state.courseReducer.courseInfo,
  listHistory: state.historyReducer.listHistoryBreadCrumb,
  history: ownProps?.history,
  chapterForm: state.courseReducer.chapterForm,
  indexChapter: state.courseReducer.indexChapter,
  typeContent: state.courseReducer.typeContentCourse,
});
export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailsContainer);