import { useState, useEffect, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router';
import TableElement from '../../elements/table/normal-table-element';
import TableLoadingPayment from'./table-loading-payment'
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { getAllPayedCourseFinanceApi, getSizePaimentFinanceApi } from '../../services/finance.service';
import { id } from 'date-fns/locale';
interface ObjectCoursesPayed {
    t: any
    filter: any
}

const status = [
    { label: "PAYED", color: "success" },
    { label: "REFUSED", color: "danger" },
]
const CoursesPayedListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ObjectCoursesPayed> = (props: any) => {
    const [loading, setLoading] = useState(true);
    const t = props?.t
    const dateFormat = require('dateformat');
    const [atBottom, setAtBottom] = useState<any>(false)
    const [loadingScroll, setLoadingScroll] = useState(true);
    
    const headers = [
        { title: t('Etudiants'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('paiement'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('DATEACHAT'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('PRIX'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto me-3" },

    ]
    const [filter, setfilter] = useState(props?.filter)
    const [filterSize, setFilterSize] = useState<any>();
    const [listPayedCourse, setListPayedCourse] = useState<any>([]);
    const history = useHistory()

    const getSizePaimentFinance = async (filter: any, idSchool: any) =>{
        const result = await getSizePaimentFinanceApi(filter, idSchool)
        setFilterSize(result)
    }

    const getAllPayedCourseFinance = async (filter: any, idSchool: any, action: any) =>{
        if (action === "first") {
            setLoading(true)
        }
        else{
            setLoadingScroll(true)
        }
        const result = await getAllPayedCourseFinanceApi(filter, idSchool, action);
        setListPayedCourse([...listPayedCourse, ...result])
        setLoading(false)
        setLoadingScroll(false)
    }

    useEffect(() => {
        filterOffersPayed(filter)
        getSizePaimentFinance(filter,props?.school.id)
    }, [])

    useEffect(() => {
        setfilter(props?.filter)
        filterOffersPayed(props?.filter)
    }, [props?.filter])

    const handleScroll = async () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY+1) >= document.body.offsetHeight && listPayedCourse?.length < filterSize) {
            setAtBottom(true);
            filter.pageNumber++;
            getAllPayedCourseFinance(filter, props?.school.id, "no");
            document.removeEventListener('scroll', handleScroll);
        }
        if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    const filterOffersPayed = (e: any) => {
        getAllPayedCourseFinance(e, props?.school.id,"first");
    }

    const doAction = async(obj: any) => {
        props?.clearHistoryArray()
        props?.redirect("Finance", `${returnStartPathUrl()}/finance/all`)
        props?.redirect(listPayedCourse[obj?.index]?.courseOfferDto?.profByCourseDto?.label, `${returnStartPathUrl()}/finance/detail-course/${listPayedCourse[obj?.index]?.courseOfferDto?.idOffer}`)
        history.push(`${returnStartPathUrl()}/finance/detail-course/${listPayedCourse[obj?.index]?.courseOfferDto?.idOffer}`)
    }

    const data: any = useMemo(() => {
        return {
            headers,
            body: listPayedCourse?.map((payed: any) => {
                let statusPayment = status.find((e: any) => e?.label === payed?.status)
                return {
                    row: {

                        classNames: "table-white",
                        columns: [
                            { content: payed?.naturalperson?.firstName + " " + payed?.naturalperson?.lastName, classNames: "", actionName: '' },
                            { content: payed?.courseOfferDto?.profByCourseDto?.label, classNames: "", actionName: '' },
                            { content: payed?.pricing?.nbPeriod !== null ? "Par Facilité":(payed?.pricing?.period === "ONESHOT" ? "Unique":"Abonnement"), classNames: "", actionName: '' },
                            { content: dateFormat(payed?.creationDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: payed?.pricing?.price === 0 ? "Gratuit" : payed?.pricing?.price +"€", classNames: "text-center" },
                            { content: t(statusPayment?.label), classNames: `text-end badge-text ${statusPayment?.color}`, actionName: '' }
                        ]
                    }
                }
            })
        }
    }, [listPayedCourse])

    return (
        <>

            <div className="w-100 mx-auto justify-content-center mt-0" style={{ maxWidth: '1200px' }}>


                <div className="col-md-12" >
                    {!loading&&(listPayedCourse?.length > 0) &&
                        <>
                            <TableElement data={data} doAction={doAction} />
                            {(listPayedCourse.length > 0 && loadingScroll )  &&
                             <div className="d-flex w-100 align-items-center justify-content-center">
                              <div className="m-3">
                               <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                               </svg>
                              </div>
                             </div>
                             }
                        </>}
                    {!loading&&(listPayedCourse?.length === 0)
                        &&  <div className="mt-5 pt-5">
                                <div className="msg-list-empty">
                                    <EmptyListElement lines={[t('noCoursePayed_1'), t('noCoursePayed_2')]} />
                                </div>
                        </div>
                    }
                      {loading&&<TableLoadingPayment/>}
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    school: state.schoolInfoReducer.schoolInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {   
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(CoursesPayedListComponent);