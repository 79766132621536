import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import {dataNavigation} from './data';
const SidebarComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & any> = (props) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(dataNavigation[0].id)
    return (
        <>
        <div className="row">
            <ol>
                {dataNavigation.map((nav: any, i: number) => (
                    <li id={nav.id} onClick={() => setSelectedItem(nav.id)} className={`sidebar-item ${(selectedItem === nav.id) ?'active': ''} mb-3`} key={nav.id}> {t(nav.text)} </li>
                ))}
            </ol>
        </div>
        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    profs: state.profReducer.profs,
    connectionUser: state.authReducer.connectedUser,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);