import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";

interface Props {
    data: any
    doAction?: any
    height?: number | string
}

function TableStudentsWithActions(props: Props) {
    const { t } = useTranslation();
    let data = useMemo(() => {
        if (props?.data?.length != 0) {
            return props?.data
        } else {
            return []
        }
    }, [props?.data])

    const doAction = (e: any, actionName: string, row: any, index: number) => {
        if (actionName !== '') {
            e.stopPropagation();
            const object = {
                row: row,
                action: actionName,
                index: index
            }
            props?.doAction(object);
        }
    }

    return (
        <>
            <div className="mt-3" style={{ minHeight: 'calc(100% - 134px)' }}>
                <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200, overflow: 'initial' }}>
                    <table className="w-100 custom-table">
                        <thead className="mb-4">
                            <tr>
                                {data?.headers?.map((header: any) =>
                                (<th key={header.title} scope={header.scope} className={header.classNames}>
                                    <button className={header.buttonClasses}>
                                        {t(header.title)}
                                    </button>
                                </th>)
                                )}
                            </tr>
                        </thead>
                        <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
                            {data?.body?.map((data: any, index: number) =>
                            (
                                <tr key={index + 'row'} style={{ cursor: (data.row.actionName === "") ? 'default' : 'pointer', position: "relative" }} onClick={(e) => doAction(e, data.row.actionName, data.row, index)} className={data.row.classNames}>
                                    {data.row.columns.map((column: any, index: number) =>
                                    (
                                        <td key={index + 'column'} onClick={(e) => doAction(e, column.actionName, data.row, index)} className={column.classNames} colSpan={column.colspan}>{column.content}</td>
                                    )
                                    )}
                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    
});

export default connect(mapStateToProps)(TableStudentsWithActions)