import { FC, useEffect, useState, ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import {  useHistory } from "react-router-dom";
import HeaderTrainingFormComponent from '../../../components/formations-card/header-formation-form';
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import CardChapterShowOnlyComponent from "../../../components/formation-course-form/card-list-content-course/card-chapter-show-only-component";
import CardTestShowOnlyComponent from "../../../components/formation-course-form/card-list-content-course/card-test-show-only-component";
import CardExamComponent from "../../../components/formation-course-form/card-list-content-course/card-exam-component";
import axios from '../../../helpers/interceptors';
import { getChapter, getExam, setTypeFormContentCourse } from '../../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from "../../../helpers/domainCheck";
import { createCourse } from '../../../global-state/actions/course-actions';

interface Params {
  type: string;
  id: string;
}
const ContentTrainingPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [courseInfo, setCourseInfo] = useState<any>();
  const [listTest, setListTest] = useState<any>();
  const [sign, setSign] = useState<string>("director");
  const [allListe, setAllListe] = useState<any>([]);
  const history = useHistory();
  const [checkDiplome, setCheckDiplome] = useState<any>(props?.courseForm?.isDiplomat)
  useEffect(() => {
    setCourseInfo(props?.courseForm);
    let compteurChapter = 1;
    let compteurTest = 1;
    setListTest(props?.listTest);
    setAllListe(props?.courseForm?.orders.
      concat(props?.listTest).
      sort((a: any, b: any) => {
        if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 }
      }).map((obj: any, i: number) => {
        if (obj.chapter) {
          obj['compteur'] = compteurChapter;
          compteurChapter++;
        } else {
          obj['compteur'] = compteurTest;
          compteurTest++;
        }
        return obj;
      }))
  }, [props?.courseForm, props?.listTest]);
useEffect(()=>{
  setCheckDiplome(props?.courseForm?.isDiplomat)
},[props?.courseForm])
  const verif = async () => {
    let checked = await axios.get(`${process.env.REACT_APP_BASE_URL}diploma/course/` + props?.courseForm?.id).then(res => { return res.data })
    if (checked.signingBy != 0) {
      if (checked.signingBy === props?.courseForm?.idProf) {
        setSign("professor")
      }
      else {
        setSign("director")
      }
    }



  }

  useEffect(() => {
    verif()
  }, [courseInfo]);

const handleNotDiploma=async()=>{
  setCheckDiplome(false);
  courseInfo["isDiplomat"] =false;
  courseInfo["diploma"] =null
  courseInfo["signingBy"]=null
  await props?.createCourse(courseInfo)
  setSign("professor")

}


const handleDiploma=async()=>{
  setCheckDiplome(true);
  courseInfo["isDiplomat"] =true;
  courseInfo["signingBy"]=sign === "director" ? props?.connectedUser?.id:courseInfo?.idProf
  let diploma = {
    training: { id: courseInfo.id, type: 'COURSE' },
    signingBy: props?.connectedUser?.id,
    id: null
  }
  courseInfo["diploma"] =diploma
  await props?.createCourse(courseInfo)

}
  const check = async (event: any) => {
    let checked = await axios.get(`${process.env.REACT_APP_BASE_URL}diploma/course/` + courseInfo.id).then(res => { return res.data })

    if (checked.signingBy != 0) {
      setSign(event.target.value)
      let diploma = {
        training: { id: courseInfo.id, type: 'COURSE' },
        signingBy: props?.connectedUser?.id,
        id: checked.id
      }
      if (event.target.value === "professor") {
        diploma.signingBy = courseInfo.idProf
      }

      axios.post(`${process.env.REACT_APP_BASE_URL}diploma`, diploma)
      .then((res)=>{
        courseInfo["isDiplomat"] =true
        courseInfo["diploma"] =res.data
        courseInfo["signingBy"]=res.data.signingBy
        props?.createCourse(courseInfo)
      })
      
    } else {
      setSign(event.target.value)
      let diploma = {
        training: { id: courseInfo.id, type: 'COURSE' },
        signingBy: props?.connectedUser?.id,

      }
      if (event.target.value === "professor") {
        diploma.signingBy = courseInfo.idProf
      }

      axios.post(`${process.env.REACT_APP_BASE_URL}diploma`, diploma)
      .then((res)=>{
        courseInfo["isDiplomat"] =true
        courseInfo["diploma"] =res.data
        courseInfo["signingBy"]=res.data.signingBy
        props?.createCourse(courseInfo)
      })
    }




  }
  const getChapterFn = async (chapter: any) => {
    const numberChapter = courseInfo?.orders?.findIndex((obj: any) => obj?.chapter?.id === chapter?.id);
    if (numberChapter !== -1) {
      await props?.getChapter(chapter, numberChapter);
      await props?.setTypeFormContentCourse('CHAPTER');
      props?.clearHistoryArray()
      props?.redirect(t('courseTable'), `${returnStartPathUrl()}/all-courses/course/${courseInfo?.id}/content`)
      props?.redirect(courseInfo.title, `${returnStartPathUrl()}/all-courses/course/${courseInfo?.id}/content`)
      props?.redirect(t('breadCrumbChapter') + (numberChapter + 1), `${returnStartPathUrl()}/all-courses/chapter-test-preview`)
      history.push(`${returnStartPathUrl()}/all-courses/chapter-test-preview`);
    }
  }
  const getExam = async (exam: any) => {
    const numberExam = listTest.sort((a: any, b: any) => { if (a.index > b.index) { return 1; } else if (a.index < b.index) { return -1 } else { return 0 } }).findIndex((obj: any) => obj?.id === exam?.id);
    if (numberExam !== -1) {
      props?.clearHistoryArray()
      await props?.getExam(exam, numberExam);
      await props?.setTypeFormContentCourse(exam?.qnaType);
      props?.redirect(t('courseTable'), `${returnStartPathUrl()}/all-courses/course/${courseInfo?.id}/content`)
      props?.redirect(courseInfo?.title, `${returnStartPathUrl()}/all-courses/course/${courseInfo?.id}/content`)
      props?.redirect(t('breadCrumbTest') + ' ' + (numberExam + 1), `${returnStartPathUrl()}/all-courses/chapter-test-preview`)
      history.push(`${returnStartPathUrl()}/all-courses/chapter-test-preview`);
    }
  }
  return (<>
    <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
      <HeaderTrainingFormComponent type={"COURSE"} title={t('courseContentTitle')} status={courseInfo?.status} trainingTitle={courseInfo?.title} />
    </div>
    <div className="px-2 pt-5" style={{ maxWidth: 1200, margin: 'auto' }}>
      <div className="d-flex flex-row align-items-center justify-content-start flex-wrap w-100 mt-2 mb-4"
           style={{
            pointerEvents: (courseInfo?.status ==="PUBLISHED") ? 'none' : 'auto',
            opacity: (courseInfo?.status ==="PUBLISHED" || courseInfo?.status ==="DESACTIVATED") ? '0.3' : '1'
        }}
      >

        <span className="body-md bold me-2"> Cours</span>
        <input disabled={courseInfo?.status ==="PUBLISHED" || courseInfo?.status ==="DESACTIVATED" } dir="ltr" className="input-style-radio-button"  type="radio" name="exampleRadios3" id="anyLevel" value="option1" checked={!checkDiplome} onClick={() => { handleNotDiploma() }} />
        <label style={{ cursor: 'pointer', width: 189, height: 48, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='me-4' htmlFor="anyLevel">Non diplômant</label>
        <input disabled={courseInfo?.status ==="PUBLISHED" || courseInfo?.status ==="DESACTIVATED"} dir="ltr" className="input-style-radio-button" type="radio"    name="exampleRadios3" id="beginner" value="option2" checked ={checkDiplome} onClick={() => { handleDiploma() }} />
        <label  style={{ cursor: 'pointer', width: 169, height: 48, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='me-4' htmlFor="beginner">diplômant</label>

      </div>

      {
        checkDiplome && <div className="d-flex align-items-center ms-4" 

        >
       
          <div className="d-flex align-items-top me-4 ms-4">
            <input  dir="ltr" className="input-style-radio" readOnly   type="radio" value={"professor"} name="professor" id="professor" onClick={(e) => check(e)} checked={sign === 'professor'} />
            <label htmlFor="professor"></label>
            <span onClick={() => check({ target: { value: 'professor' } })} style={{ cursor: 'pointer' }} className=" body-md black-800 ps-1 ms-2"> {t('signatureProf')}</span>
          </div>
          <div className="d-flex align-items-top">
            <input dir="ltr" className="input-style-radio" readOnly  type="radio" name="director" id="director" value="director" onClick={(e) => check(e)} checked={sign === 'director'} />
            <label htmlFor="director"></label>
            <span onClick={() => check({ target: { value: 'director' } })} style={{ cursor: 'pointer' }} className=" body-md black-800 ps-1 ms-2"> {t('signatureDirector')}</span>
          </div>
        </div>
      }

      <div className='mt-4 pt-3 mb-4'>
        {allListe?.map((obj: any, index: number) => {
          return (obj?.chapter) ?
            <div key={index} onClick={() => getChapterFn(obj?.chapter)}>
              <CardChapterShowOnlyComponent chapter={obj} index={index + 1} />
            </div>
            : (obj.qnaType === 'KNOWLEDGETEST') ?
              <div key={index} onClick={() => getExam(obj)}>
                <CardTestShowOnlyComponent test={obj} index={index + 1} />
              </div> :
              <div key={index} className="mt-3" onClick={() => getExam(obj)}>
                <CardExamComponent getExam={() => { }} exam={obj} index={index + 1} />
              </div>

        })}
      </div>
    </div>
  </>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getChapter,
  getExam,
  setTypeFormContentCourse,
  createCourse,
  redirect, 
  clearHistoryArray
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  loggedIn: state.authReducer.loggedIn,
  courseForm: state.courseReducer.courseInfo,
  listeHistory: state.historyReducer.listHistoryBreadCrumb,
  history: ownProps?.history,
  listTest: state.courseReducer.listTest,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentTrainingPage);