import { useTranslation } from "react-i18next";
import DefaultTitle from '../../helpers/default-title';
import { clearHistoryArray, redirect} from '../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { AppState } from '../../global-state/store/root-reducers';


const HeaderTrainingFormComponent : FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const label = window.location.href.split("//")[1].split("/")
    const [headerTitle, setHeaderTitle] = useState<string>("")

    useEffect(()=>{
        formatTitle(label[label.length-1])
    },[])

    const formatTitle = (title:string) =>{
        switch(title) {
            case "description":
                setHeaderTitle("Description")
                break;
            case "content":
                setHeaderTitle("Contenu du cours")
                break;
            case "inscription":
                setHeaderTitle("Inscription")
                break;
                
            default:
                setHeaderTitle(props.title)
              // code block
          }
    }


    const types = [
        { type: "COURSE", label: t('courseStatus_1') },
        { type: "PATH", label: t('Le parcours') },
        { type: "SESSION", label: t('La session') },
        { type: "SURVEY", label: "Sondage" },
    ]
    return (
        <>
            <div style={{ maxWidth: 1200 }} className="py-4 d-flex align-items-center justify-content-between mx-auto">
                <div className="d-flex flex-column my-2">
                    <h2 className="H2-Headline neutral-3">{headerTitle}</h2>
                    <div className="d-flex flex-row">
                        <p className="body-sm bold black-800 pe-3">
                           
                          {props?.type === "SURVEY" && <span className="body-sm bold neutral-3 pe-2">Sondage créé par</span>}
                           
                            <DefaultTitle title={props?.trainingTitle} type={props?.type} /> 
                            &nbsp; {props?.creationDate && <span className="body-sm bold neutral-3 pe-2">{t('createdAt')}</span>}
                             <span className="body-sm bold black-800">{props?.creationDate}</span> &nbsp;  {(props?.status === 'DRAFT' || props?.status === 'PENDING') && <><span className="neutral-3">{types.find(e => e.type === props?.type)?.label}</span> {t('courseStatus_2')} </>}</p>


                    </div>
                </div>
                {props?.children}
            </div>
        </>
    );
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
          
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(HeaderTrainingFormComponent);
