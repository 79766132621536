import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import TableElement from '../../../elements/table/normal-table-element';
import { createTraining,addSubject, deleteSubject } from '../../../global-state/actions/training-actions';
import { useHistory } from 'react-router';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import axiosInstance from '../../../helpers/interceptors';

const AddPathTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const types = [{ label: t("Course"), value: "COURSE" }, { label: t("parcoursTitle"), value: "PATH" }]

    const headers = [
        { title: t("courseTable"), sorted: true, classNames: "w-38", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("professeur"), sorted: true, classNames: "w-23", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("typeTable"), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("Actions"), sorted: false, classNames: "w-10", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-5 pe-1" },
    ]
    const [data, setData] = useState<any>({ headers }) 
    const path= useMemo(()=>{ return props?.path},[props?.path?.subjectsByPath])
    const history=useHistory() 
    const [indexNewContent, setIndexNewContent] = useState<number>(path?.subjectsByPath?.length)

    useEffect(() => {
        if (path !== undefined) {            
            setIndexNewContent(path?.subjectsByPath?.length)
        }
    }, [path])
    
    
    const addContent = async (id: number, type: string) => {
        
        let subjectByPath = {
            entityType: type,
            entityId: id,
            index: indexNewContent,
            mandatories:[]
        }
        
    
     props?.createTraining("paths",{...path, subjectsByPath: [...path?.subjectsByPath ,subjectByPath]} )
        .then(()=>{
                 if(path?.status==="PUBLISHED"){
        let updateFollows =
            {
                "action":"ADD",
                "trainingType":path?.type,
                "trainingId":path?.id,
                "idSubject":id,
                "typeSubject":type==="Cours" ? "COURSE":"PATH"
            }

        axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdate", updateFollows)
        
     }
        })



    }
    const redirect = (id: number) => {
        props?.closeModal()
        history.push(`${returnStartPathUrl()}/courses/preview/${id}`)
    }


    const deleteSubject = async  (e:any)=>{
        await props?.deleteSubject(props?.path?.id,path?.subjectsByPath.find((subjectByPath:any)=>subjectByPath.entityId===e?.id)?.id)
        .then(()=>{
            if(path?.status==="PUBLISHED"){
            let updateFollows =
                {
                    "action":"DELETE",
                    "trainingType":path?.type,
                    "trainingId":path?.id,
                    "idSubject":e?.id,
                    "typeSubject":e?.type==="Cours" ? "COURSE":"PATH"
                }
    
            axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdate", updateFollows)
            
         }
        })

        
    }

    useEffect(() => {
        if (Array.isArray(props?.trainings)) {
            let name = (props?.connectedUserInfo?.firstName as string).charAt(0).toUpperCase().concat(". ").concat((props?.connectedUserInfo?.lastName as string).charAt(0).toUpperCase()).concat((props?.connectedUserInfo?.lastName as string).substr(1, (props?.connectedUserInfo?.lastName as string).length));
            setData({
                ...data, body: props?.trainings?.map((e: any) => {
                    return {
                        row: {
                            actionName: "",
                            classNames: "table-white",
                            columns: [
                                { content: e?.title, classNames: "", actionName: '' },
                                { content: name, classNames: "", actionName: '' },
                                { content: types.find((type: any) => type?.value === e?.type)?.label, classNames: "", actionName: '' },
                                {
                                    content: <div className="d-flex justify-content-end">
                                 
                                        {!!path?.subjectsByPath.find((subjectByPath:any)=>subjectByPath.entityId===e?.id)?
                                        <button onClick={()=>{ deleteSubject(e)}} type="button" className="btn-Secondary medium icon-left adding-done">
                                            <span className="material-icons-outlined">
                                                check
                                            </span>
                                            <div className="my-auto" style={{ paddingLeft: 6 }}>{t("added")}</div>
                                        </button>                                      
                                            :
                                          <button type="button" onClick={() => { addContent(e?.id,e?.type) }} className="btn-Secondary medium icon-left" style={{ width: 116 }}>
                                          <span className="material-icons-outlined">
                                              add
                                          </span>
                                          <div className="my-auto" style={{ paddingLeft: 6 }}>{t("add")}</div>
                                      </button>}
                                    </div>, classNames: "text-end with-icon", actionName: ''
                                },
                            ]
                        }
                    }
                }
                )
            })
        }

    }, [props?.trainings, path])


    return (
        <div style={{ backgroundColor: "#F8F8FA" }}>

            <div className="modal-header" style={{ paddingBottom: 0,borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-between p-3">
                    <span className='H3-Headline black-800'>{t("Ajouter un contenu")}</span>
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className='m-3'>

                <TableElement data={data} />

            </div>


            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none", overflow:'hidden' }}>
            </div>
         
        </div>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    trainings: state.trainingReducer.allowedTrainings,
    path: state.trainingReducer.path,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createTraining,
            addSubject,
            deleteSubject,
            closeModal
        },
        dispatch
    );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPathTrainingModalComponent);