import { FC } from "react";
import ChoiceFormationPage from "./choice-formation-page";
import RouteWithBreadCrumb from "../../../navigations/RouteWithBreadCrumb";
import PrivateRoute from "../../../navigations/PrivateRoute";
import CourseCreationContainer from "./course/course-creation-container";
import PathCreationContainer from "./path/path-creation-container";
import SessionCreationContainer from "./session/session-creation-container";       
import PathExamPage from "./path/path-exam-page";
import SessionExamPage from "./session/session-exam-page";
import CustomSwitch from "../../../helpers/custom-switch";
interface Params {
    id: any
}

const FormationCreationContainer: FC<any> = (props: any) => {
  return (<>
      <CustomSwitch>
      <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/choice`} component={ChoiceFormationPage} />
      <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/path/test`} component={PathExamPage} />
      <RouteWithBreadCrumb exact={true} path={`${props?.match.path}/session/test`} component={SessionExamPage} />
      <PrivateRoute path={`${props?.match.path}/course`} component={CourseCreationContainer}   />
      <PrivateRoute path={`${props?.match.path}/path`} component={PathCreationContainer}   />
      <PrivateRoute path={`${props?.match.path}/session`} component={SessionCreationContainer}   />
      </CustomSwitch>
  </>
  )
}
export default FormationCreationContainer;