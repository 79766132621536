import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal} from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import {createPost} from "../../../global-state/actions/chat-actions";
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';

const AddCommentProgressModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const[title, setTitle]=useState("");
    const[comment, setComment]=useState("");
    const changeInputValue=(value: string)=>{
        setComment(value)
    }

    const addPost = async() => {
       let object = {
            subjectId: (props?.listPostsChapter.length === 0) ? null:props?.listPostsChapter[0].subject.id,
            idAuthor: props?.connectionUser.idPerson,
            title:title ,
            type: "POST",
            comment: {
                description: comment,
                type: "TEXT"
            },
            subject: [props?.sectionContent?.id+" "+props?.sectionContent?.type+" "+"FORUM"] 
               }



        await props?.createPost(object, {idSubject:props?.content?.data?.chapter?.id, category:'CHAPTER', type: "FORUM"},props?.connectionUser.idSchool);
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'ok', lines: [t('addCommentSuccess')]} );
    }

    return(
        <>
        
                <div className="modal-header" style={{paddingBottom: 0 , borderBottom: "none"}}>
                    <div className="w-100 d-flex align-items-center justify-content-end p-3">
                         <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>
                </div>

                    <div  className="modal-body mb-3" style={{ paddingTop: 0, zIndex:1000, overflow:'hidden' }}>
                        <div className="row">
                            <div className="col-md-10 center" style={{ margin: 'auto' }}>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <h3 className="heading-h3-noirstd">{t('addcommentForCourse')}</h3>
                                    </div>
                                    <div className="col-md-12 mb-1 mt-2">
                                        <p className="body-md bold">{t('commentTitle')}</p>
                                    </div>
                                    <div className="col-md-12  mb-4 ">
                                        <input dir="ltr"type="text" className="form-control input text-default" value={title} onChange={(e) => {setTitle( e.target.value ) }} placeholder={t('commentTitle')} />
                                    </div>
                                    <div className="col-md-12 mb-1 mt-2">
                                        <p className="body-md bold">{t('yourComment')}</p>
                                    </div>
                                    <div className="col-md-12  mb-1">
                                    <TextareaUnderlineElement id="commentId"  value={comment} onChange={ changeInputValue} placeholder={t('votreCommentaie')} />
                                   </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{paddingBottom: 0 , borderTop: "none"}}>
                        <div className="row mt-3 mb-5">
                            <div className='col-md-9 d-flex  justify-content-end' >
                                <button className="btn-Secondary large me-3" onClick={() => {props?.closeModal(); setTitle(""); setComment("")}}>{t('annuler')}</button>
                                <button type="button" className="btn-Primary large" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { addPost() }} disabled={comment === ''}>{t('publishMyReview')}</button>
                            </div>
                        </div>
                    </div>
                
    </>
    )
}
const mapStateToProps = (state: AppState) => ({
    connectionUser: state.authReducer.connectedUser,
    listPostsChapter: state.chatReducer.listPosts,
    content: state.modalReducer.modalContent,
    sectionContent: state.studentTraining.sectionContentDetails.sectionContent
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      createPost
    },
    dispatch
  );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCommentProgressModalComponent);