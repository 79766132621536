import { ComponentProps, FC, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { setActualTab } from '../../global-state/actions/professor-actions';

const HeaderStudentsListTrainingComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [actualTab, setActualTab] = useState((props?.lastTabClicked) ? props?.lastTabClicked : "All");
    const setActualTabFn = (tab: string) => {
        props?.setActualTab(tab);
    }

    
    return (
        <div style={{ backgroundColor: '#FFFFFF' }} >
            <div className="col-md-12 mb-3 px-0 mx-auto mt-5" style={{ maxWidth: '1200px' }}>
                <div className="row justify-content-between">
                    <div className="col-md-12">
                        <h1 className="H2-Headline neutral-3">
                            {t('Etudiants')}
                            <span className="ms-3" style={{ color: "#272727" }}>
                                {(props?.listeStudents?.generalInfo?.length < 10) ? "0" + props?.listeStudents?.generalInfo?.length : props?.listeStudents?.generalInfo?.length}
                            </span>
                        </h1>
                        {props?.trainingTitle && <p className="body-sm bold black-800 ms-1">{props?.trainingTitle}</p>}
                    </div>

                </div>
            </div>
            {(props?.courseInfo?.status !== 'DRAFT' && props?.courseInfo?.status !== 'PENDING') && <div className="col-md-12  mt-3 px-0 pb-4 mx-auto" style={{ maxWidth: '1200px' }}>
                <div className="row justify-content-between">
                    <div className="d-flex w-100 align-items-center jsutify-content-start">
                        <button className={`btn-Tabs ${(actualTab === 'All') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('All'); setActualTab("All"); }} style={{ marginRight: "16px"}}>{t('allStudent')} ({props?.listeStudents?.generalInfo?.length})</button>
                        <button className={`btn-Tabs ${(actualTab === 'Inscribed') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('Inscribed'); setActualTab("Inscribed"); }} style={{ marginRight: "16px"}}>{t('Inscribed')} ({props?.listeStudents?.inscribedInfo?.length})</button>
                        {(props?.courseInfo?.idRegistrationForm !== undefined && props?.courseInfo?.idRegistrationForm !== null && props?.courseInfo?.idRegistrationForm !== 0) && <button className={`btn-Tabs ${(actualTab === 'Candidate') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('Candidate'); setActualTab("Candidate"); }} style={{ marginRight: "16px" , textTransform: 'capitalize'}}>{t('candidatures')} ({props?.listeStudents?.candidatesInfo?.length})</button>}
                        <button className={`btn-Tabs ${(actualTab === 'Unpaid') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('Unpaid'); setActualTab("Unpaid"); }} style={{ marginRight: "16px" , textTransform: 'capitalize'}}>{t('Unpaid').toLowerCase()} ({props?.listeStudents?.unpaidInfo?.length})</button>
                       
{     props.isDiplomat===true &&                   <button className={`btn-Tabs ${(actualTab === 'Certified') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('Certified'); setActualTab("Certified"); }} style={{ marginRight: "16px" , textTransform: 'capitalize'}}>{t('validatedDiploma').toLowerCase()} ({props?.listeStudents?.certifiedInfo?.length})</button>
}                        <button className={`btn-Tabs ${(actualTab === 'Suspended') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('Suspended'); setActualTab("Suspended"); }} style={{ marginRight: "16px" , textTransform: 'capitalize'}}>{t('Suspended').toLowerCase()} ({props?.listeStudents?.suspendedInfo?.length})</button>
                        {props?.connectuser?.role.includes("role_director") && <button className={`btn-Tabs ${(actualTab === 'Groups') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabFn('Groups'); setActualTab("Groups"); }} style={{ marginRight: "16px" , textTransform: 'capitalize'}}>{t('autorisationTraining')}</button>}
                    </div>
                </div>
            </div>}
        </div>
    );
}

const mapStateToProps = (state: AppState) => ({
    listeStudents: state.profReducer.listeStudents,
    lastTabClicked: state.profReducer.lastTabClicked,
    connectuser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ setActualTab }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStudentsListTrainingComponent);
