import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import { reinstateTeamMember } from '../../../global-state/actions/professor-actions';
import { getOffer } from '../../../global-state/actions/app-container-actions';
import { useHistory } from 'react-router';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const ComparisationModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory()
    let [commentReinstate, setCommentReinstate] = useState('');

    const [assistant, setassistant] = useState(props?.content?.data?.assistant)



    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="body-modal-publish">
                    <div className="mx-5 px-5 center" style={{ margin: 'auto' }}>
                        <div className="d-flex flex-column">
                                <div className='d-flex flex-row align-items-center mb-3'>
                                    <span className="material-icons Orange-800 me-2" style={{ fontSize: 32 }} >cancel</span>
                                    <span className="H3-Headline align-items-center mt-1">{t('failedRequest')} </span>
                                </div>

                            <div className=" mt-1">
                                <span className="body-sm">{t('lowerDeal')}</span>

                            </div>
                            <div className=" mt-4 mb-1">
                                <span className="body-md bold">{t('Limitationduforfait')} ({props?.content?.selectedOffer})</span>

                            </div>
                            {props?.content?.limitations?.map((limitation: any, index: any) => {
                                return <> <div className=" mt-2 pt-1 ">
                                    {
                                        limitation?.status == true && <>
                                            <div className="d-flex align-items-center me-5">
                                                <div className="d-flex align-items-center justify-content-center me-3 check-icon checked" style={{ backgroundColor: "#9DDBA9" }}>
                                                    <span className="material-icons-outlined" style={{ color: "#FFFFFF", fontSize: 20}}>check </span>

                                                </div>
                                                {limitation.key=="nb_prof" &&
                                                     <span  className="body-sm">{t('activeProfessors')}  <span style={{color:"#F5B95F"}}>{ limitation.valueContract.toString() == "-1" ? "illlimité" :"("+limitation?.valueSchool +"sur "+limitation.valueContract+")"}</span></span> }
                                                                {limitation.key=="nb_cours" &&
                                                     <span className="body-sm" >{t('onlineFormations')} <span style={{color:"#F5B95F"}}>{ limitation.valueContract.toString() == "-1" ? "illlimité" :"("+limitation?.valueSchool +"sur "+limitation.valueContract+")"}</span></span> }
                                                                     {limitation.key=="nb_student" &&
                                                     <span  className="body-sm">{t('activeStudents')}<span style={{color:"#F5B95F"}}>{ limitation.valueContract.toString() == "-1" ? "illlimité" :"("+limitation?.valueSchool +"sur "+limitation.valueContract+")"}</span></span> }
                                                                     {limitation.key=="nb_administrateur" &&
                                                     <span  className="body-sm">{t('activeAssistants')}<span style={{color:"#F5B95F"}}>{ limitation.valueContract.toString() == "-1" ? "illlimité" :"("+limitation?.valueSchool +"sur "+limitation.valueContract+")"}</span></span> }   


                          
                                           </div>
                                        </>
                                    }

                                    {
                                        limitation?.status == false && <>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <div className='d-flex flex-row'>
                                                    <div className="d-flex align-items-center justify-content-center me-3 check-icon unchecked">
                                                        <span className="material-icons-outlined" style={{ color: "#FFFFFF" }}> </span>

                                                    </div>
                                                    {limitation.key=="nb_prof" &&
                                                     <span  className="body-sm">{t('activeProfessors')}<span style={{color:"#F5B95F"}}>({limitation?.valueSchool} sur {limitation.valueContract})</span></span> }
                                                                {limitation.key=="nb_cours" &&
                                                     <span className="body-sm" >{t('onlineFormations')}<span style={{color:"#F5B95F"}}>({limitation?.valueSchool} sur {limitation.valueContract})</span></span> }
                                                                     {limitation.key=="nb_student" &&
                                                     <span  className="body-sm">{t('activeStudents')}<span style={{color:"#F5B95F"}}>({limitation?.valueSchool} sur {limitation.valueContract})</span></span> }
                                                                     {limitation.key=="nb_administrateur" &&
                                                     <span  className="body-sm">{t('activeAssistants')}<span style={{color:"#F5B95F"}}>({limitation?.valueSchool} sur {limitation.valueContract})</span></span> }   


                                                </div>
                                                <div>
                                                    {limitation.key=="nb_prof" &&
                                                     <button className="btn-text outlined" onClick={()=>{history.push(`${returnStartPathUrl()}professors/all`);props?.closeModal()}}>{t('professorList')}</button> }
                                                                {limitation.key=="nb_cours" &&
                                                     <button className="btn-text outlined" onClick={()=>{history.push(`${returnStartPathUrl()}all-courses`);props?.closeModal()}}>{t('trainingList')}</button> }
                                                                     {limitation.key=="nb_student" &&
                                                     <button className="btn-text outlined" onClick={()=>{history.push(`${returnStartPathUrl()}students/all`);props.closeModal()}}>{t('ListStudents')}</button> }
                                                                     {limitation.key=="nb_administrateur" &&
                                                     <button className="btn-text outlined" onClick={()=>{history.push(`${returnStartPathUrl()}professors/all`);props.closeModal()}}>{t('listeDesAssistants')}</button> }   

                                                </div></div>
                                        </>
                                    }

                                </div>
                                </>

                            })}


                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingTop: 0, border: "none" }}>
                <div className=" d-flex mt-3 mb-5 mx-5 px-5">
                    <div className='text-center justify-content-center buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large" onClick={() => { props?.closeModal() }} style={{ textTransform: 'uppercase', marginRight: '2%' }} >Fermer</button>

                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            reinstateTeamMember,
            getOffer
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ComparisationModalComponent);