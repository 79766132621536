import { ComponentProps, FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { returnDynamicValueFromUrl, returnStartPathUrl } from '../../../helpers/domainCheck';
import { getOfferPricingCourse } from '../../../global-state/actions/course-actions';
import { saveChangeTo } from '../../../global-state/actions/app-container-actions';
import { useHistory } from 'react-router';
const ValidateNewPriceModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const history = useHistory();
    const offerData = useMemo(() => { return props?.content?.data }, [props?.content]);
    useEffect(() => {
        props?.getOfferPricingCourse(parseInt(returnDynamicValueFromUrl(3), 10))
    }, [])

    const { t } = useTranslation();

    const stayPage = async () => {
        props?.getOfferPricingCourse(parseInt(returnDynamicValueFromUrl(3), 10)).then(async () => {
            await props?.closeModal();
            await props?.saveChangeTo(false)
        })

    }
    const leavePage = async () => {
        await props?.closeModal();
        history.push(`${returnStartPathUrl()}/all-courses`);
    }


    return (
        <div>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                <div className="row " style={{ textAlign: "center" }} >
                    <div>
                        <span className="material-icons-outlined" style={{ color: "#9DDBA9", fontSize: "55px", marginBottom: "39px" }}>
                            done</span>
                    </div>
                    <div>
                        <span className="H4-Subtitle black-800" >La tarification de votre {offerData.trainingType}  a été mise à jours avec succès</span>
                    </div>

                </div>
            </div>
            <div style={{ paddingBottom: 80, borderTop: "none", marginTop: 20 }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }} >
                    <button className="btn-Secondary large me-3" onClick={() => { leavePage() }}>VOIR liste des formations</button>
                    <button type="button" className="btn-Primary large large" onClick={() => { stayPage() }} >Rester sur cette page</button>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {saveChangeTo,
            closeModal,
            getOfferPricingCourse,

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ValidateNewPriceModalComponent);

