import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SelectElement from "../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import { updatePath } from '../../global-state/actions/breadcrumb-actions';
import { useHistory } from "react-router-dom";
import { addTest, removeTest } from "../../global-state/actions/training-actions";
import { debounce } from "lodash";
import { returnStartPathUrl } from "../../helpers/domainCheck";

const times = [
    { value: "15", label: '15 mn' },
    { value: "30", label: '30 mn' },
    { value: "45", label: '45 mn' },
    { value: "60", label: '1h00' },
    { value: "75", label: '1h15' },
    { value: "90", label: '1h30' },
    { value: "165", label: '2h45' },
    { value: "180", label: '3h00' },
]

const HeaderTestExamFormComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [testForm, setTestForm]= useState(props?.testForm);
    const [msgLimitInput, setmsgLimitInput] = useState("")

    useEffect(() => {
        if(props?.testForm?.title === testForm.title)
            setTestForm(props?.testForm)
    },[props?.testForm])

    const [duration, setDuration] = useState(testForm?.duration)

    useEffect(() => {
        setDuration(testForm?.duration)
    }, [testForm?.duration])
    
    const changeTitle = (value: string) => { 
        if(value.length === 254) setmsgLimitInput("Le titre de l'examen ne doit pas dépasser les 254 caractères (Espaces inclus)")
        if(value.length < 254) setmsgLimitInput("")

        const test = { ...testForm };
        test.title = value;
        setTestForm(test);
        debouncecallApiAddTest(test);        
    }

    const callApiAddTest = (test: any)=>{
        props?.addTest(test); 
    }

    const debouncecallApiAddTest = useCallback( debounce(callApiAddTest, 1000), []);

    const saveDataDuration = (value: string) => {

        const test = { ...testForm };
        test.duration = parseInt(value);

        props?.addTest(test);
    }

    const removeExam = async () => {
        await props?.removeTest("PATH", testForm?.id);
         history.push(`${returnStartPathUrl()}/courses/create-formation/path/content`);

    }

    return (<div style={{ backgroundColor: '#FFFFFF', marginTop: 64 }} className="w-100 py-4">
        <div style={{ maxWidth: "1200px", margin: 'auto' }} className="row my-3">
            <div className="d-flex align-items-center justify-content-between ">
                <div className="d-flex align-items-center flex-wrap w-100 position-relative">
                    <div className="d-flex w-100">
                        <input 
                            dir="ltr"
                            type="text" 
                            className="input-title" 
                            value={testForm?.title} 
                            placeholder={t('exam1')} 
                            onChange={(e) => { changeTitle(e.target.value) }} 
                            maxLength={254}
                        />
                         <span className='msg-error'>{msgLimitInput}</span>
                    </div>

                </div>
                <div className='d-flex flex-row'>
                    <button type="button" className="btn-QuickActions me-3" onClick={() => { removeExam() }}>
                        <span className="material-icons-outlined">delete</span>
                    </button>
                    <div style={{ width: '130px' }}>
                    <SelectElement disabled={false} taille={"lg"} onChange={(value: string) => { saveDataDuration(value) }} defaultValue={duration.toString()} options={times} placeholder={'time'} />
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    updatePath,
    removeTest,
    addTest,
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseForm,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    testForm: state.trainingReducer.path.exams[0],
    indexChapter: state.courseReducer.indexChapter
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderTestExamFormComponent)
