import { useEffect, useState } from 'react';
import { createStyles, makeStyles, withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import InputBase from '@material-ui/core/InputBase';

const LargeSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    border: '1px solid transparent',
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '12px 26px 12px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);

const MeduimSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '6px 26px 6px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0),
      width: '100%'

    },
  }),
);

interface Props {
  options: any[];
  onChange: any;
  placeholder: string;
  defaultValue: string;
  taille?: string;
}
export default function SelectElemenWithOptGroup(props: Props) {
  const classes = useStyles();
  const [value, setValue] = useState("none");
  useEffect(() => {
    if (props?.defaultValue && props?.defaultValue !== value) {
      setValue(props?.defaultValue);
    }
  }, [props?.defaultValue]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    props?.onChange(event.target.value);
  };
  return (
    <>
      <FormControl className={classes.formControl}>
        <Select
          value={value}
          defaultValue={value}
          id="demo-dialog-select"
          onChange={(e) => handleChange(e)}
          input={props?.taille !== "lg" ? <MeduimSelect /> : <LargeSelect />}
          className={classes?.formControl}
          renderValue={((valueInput: any) => {
            if (valueInput === "none") {
              return <span className="neutral-3">{props?.placeholder}</span>;
            }
            return <>{
              props?.options?.find(option => option?.value === valueInput)?.label}</>
          })}
        >
          <MenuItem value={'none'} hidden></MenuItem>
          {props?.options?.map((option, index) => {
            return <MenuItem onClick={() => {setValue(option.value)}}key={index} value={option?.value}><pre className="m-0 p-0 body-md black-800">{option?.label}</pre></MenuItem>
          })}

        </Select>
      </FormControl>
    </>
  )
}