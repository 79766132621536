import { FC, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import HeaderCardChoiceComponent from "./header-card-choice-component"
import FooterChoiceCardComponent from "./footer-card-choice-component";
import ContentChoiceCardComponent from "./content-choice-card-component";


const CardServeyComponent: FC<
ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({
  questionByExam,
  handleShowCardQuestion,
  handleChangeResponse,
  handleAddNewResponse,
  index,
  sondageReducer,
}: any) => {

  const [isOpen, setIsOpen] = useState(false)

  const currentIndex = sondageReducer.questionByQnaList.indexOf(sondageReducer.questionByQnaList[index])
  const handleOpenCardQuestion = (myindex: any) => {
    currentIndex === myindex ? setIsOpen(!isOpen) : setIsOpen(false)
  };

  return (
    <div className="container-card-choice-sondage">
      <div
        
        className={`card-choice-sondage 
        ${
          isOpen ? `card-choice-sondage--opened` : `card-choice-sondage--closed`
        }
        `}
      >
        <HeaderCardChoiceComponent
          questionByExam={questionByExam}
          handleShowCardQuestion={handleShowCardQuestion}
          index={index}
          handleOpenCardQuestion={handleOpenCardQuestion}
          isOpen={isOpen}
        />
        <ContentChoiceCardComponent
          questionByExam={questionByExam}
          handleChangeResponse={handleChangeResponse}
          index={index}
        />
        <FooterChoiceCardComponent
          questionByExam={questionByExam}
          index={index}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  questionByExam: ownProps.questionByExam,
  sondageReducer: state.examReducer.examToPass,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardServeyComponent);
