import { FC, useEffect } from "react";
import { Switch } from "react-router";
import PrivateRoute from "../../navigations/PrivateRoute";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
import AllCouponsPage from "./coupon.page";
import CreateCouponsPage from "./create-coupon/create-coupon.page";

import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { closeModal } from '../../global-state/actions/modal-actions';
import { getSchoolInfo, getSubscriptions } from '../../global-state/actions/app-container-actions';
import CouponNotFoundPage from "./coupon-not-found-page";
import { saveActionHistory } from "../../services/history.service";
const CouponContainer: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props: any) => {
  useEffect(() => {
    if (props?.schoolInfo?.id) {
      props?.getSubscriptions(props?.schoolInfo?.id)
    }
  }, [props?.schoolInfo])
  return (<>

    {
      ((!!props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon')&& props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon') && props?.subscription?.limitations?.find((obj: any) => obj.key === 'coupon')?.value === "true")) ?
        <>  <div className="page-wrapper-height">
          <CustomSwitch>
            <RouteWithNavbar history={props?.history} roles={['role_director', "role_adminstrator"]} exact={true} path={`${props.match.path}`} component={AllCouponsPage} />
            <PrivateRoute exact={true} path={`${props?.match.path}/create-coupon`} roles={['role_director', "role_adminstrator"]} component={CreateCouponsPage} />
          </CustomSwitch>
        </div>
          <FooterComponent />
        </>


        : <>
          <div className="page-wrapper-height" style={{ backgroundColor: '#ffffff' }}>
            <CustomSwitch>
              <RouteWithNavbar history={props?.history} path={`${props?.match.path}`} exact={true} component={CouponNotFoundPage} />

            </CustomSwitch>
          </div>
          <FooterComponent />
        </>}

  </>
  )
}
const mapStateToProps = (state: AppState) => ({
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  connectuser: state.authReducer.connectedUser,
  modalOpened: state.modalReducer.modalOpened,
  modalContent: state.modalReducer.modalContent,
  subscription: state.schoolInfoReducer.subscription,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ getSchoolInfo, closeModal, getSubscriptions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CouponContainer);