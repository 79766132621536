import React, { useRef, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import SliderZoomInOut from './../../elements/slider/slider-zoom-in-out';



const CropImage: React.FC<any> = (props:any) => {
    const editorRef = useRef<AvatarEditor | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [state, setState] = useState<any>({
        file: '',
        config: {
            scale: 1,
            position: { x: 0, y: 0 },
            cropped: false,
            crop: { x: 10, y: 20 },
            aspect: 1,
            width: props.width,
            maxWidth: props.width + 80,
        },
    });

    useEffect(() => {
        const changeWindowSize = () => {
            if (containerRef.current && containerRef.current.offsetWidth < state.config.maxWidth) {
                const copyState = { ...state.config };
                copyState.width = containerRef.current.offsetWidth - 80;
                let newState = { ...state }
                newState.config = copyState
                setState(newState);
            } else if (state.config.width !== state.config.maxWidth - 80) {
                const copyState = { ...state.config };
                copyState.width = state.config.maxWidth - 80;
                let newState = { ...state }
                newState.config = copyState
            }
        };

        window.addEventListener('resize', changeWindowSize);

        return () => {
            window.removeEventListener('resize', changeWindowSize);
        };
    }, [state.config.maxWidth, state.config.width]);

    useEffect(() => {
        let newState = {
            file: props.file,
            config: {
                scale: 1,
                position: { x: 0, y: 0 },
                cropped: false,
                crop: { x: 10, y: 20 },
                aspect: 1,
                width: props.width,
                maxWidth: props.width + 80
            }
        }
        setState(newState);
    }, [props.file, props.width]);

    const handleScale = (value: any) => {
        const copyConfig = { ...state.config };
        const newScale = parseFloat(value);
        copyConfig.scale = newScale;
        let newState = {...state}
        newState.config=copyConfig
        setState(newState);
    };

    const handlePositionChange = (position: any) => {
        const copyConfig = { ...state.config };
        copyConfig.position = position;
        let newState = {...state}
        newState.config=copyConfig
        setState(newState);
    };

    const onCrop = () => {
        if (editorRef.current) {
            const canvasScaled = editorRef.current.getImageScaledToCanvas();
            const canvas = canvasScaled.toDataURL();

            fetch(canvas)
                .then((res) => res.blob())
                .then((blob: any) => {
                    let name = '';
                    decodeURIComponent(state.file.split('/')[3])
                        .split('-')
                        .map((substr: string, index: number) => {
                            if (index > 0 && index < state.file.split('/')[3].split('-').length - 1) {
                                name = name + substr + '-';
                            } else if (index === state.file.split('/')[3].split('-').length - 1) {
                                name = name + substr;
                            }
                        });

                    let newFile = new File([blob], state.file.split('/')[3], { type: blob.type, lastModified: Date.now() });
                    props.addFile([newFile]);

                    const copyState = {
                        ...state,
                        config: {
                            scale: 1,
                            position: { x: 0, y: 0 },
                            cropped: false,
                            crop: { x: 10, y: 20 },
                            aspect: 1,
                            width: props.width,
                            maxWidth: props.width + 80,
                        },
                    };
                    setState(copyState);
                });
        }
    };

    const onDelete = () => {
        props.deleteFile();
    };

    return (
        <>
            <div style={{ position: 'relative' }}>
                <div
                    ref={containerRef}
                    className="bg-full-black d-flex align-items-center justify-content-center"
                >
                    <div className="d-flex align-items-center" style={{ position: 'absolute', top: 32, right: 32 }}>
                        <button className="btn-QuickActions white me-2" onClick={onDelete}>
                            <span className="material-icons-outlined" color="white">
                                delete
                            </span>
                        </button>
                        <button className="btn-QuickActions white" onClick={onCrop}>
                            <span className="material-icons-outlined" color="white">
                                save
                            </span>
                        </button>
                    </div>
                    {state.file && (
                        <AvatarEditor
                            crossOrigin="anonymous"
                            ref={editorRef}
                            border={40}
                            scale={parseFloat(state.config.scale)}
                            width={state.config.width}
                            height={state.config.width * 0.585}
                            position={state.config.position}
                            onPositionChange={handlePositionChange}
                            image={state.file}
                        />
                    )}
                    <div
                        className="d-flex align-items-center justify-content-center w-100"
                        style={{ position: 'absolute', bottom: 4 }}
                    >
                        <SliderZoomInOut scale={parseFloat(state.config.scale)} newScale={handleScale} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CropImage;
