import { connect } from "react-redux";
import { FC,useState } from "react"
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { useTranslation } from "react-i18next";
import {setActualTabManageQuestion} from '../../global-state/actions/chat-actions';
import SearchFilterQuestionComponent from '../filters/searchFilter-question-component';

interface Props{
    Searching:any
}
const ManageQuestionComponent: FC <ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>&  Props>=(props)=>{
    const { t } = useTranslation();
    const [actualTab, setTab]= useState((props?.tabQuestionManager) ? props?.tabQuestionManager : "unsettled",)

    const setActualTab = (tab: string) => {
        props?.setActualTabManageQuestion(tab); 
    }
    function isSearching (doSearch:any){
        props?.Searching(doSearch)
    }

    
  
    return(
       <div >
            <div className="row justify-content-center m-0">
                <div style={{backgroundColor: "#FFFFFF"}} className="col-md-12">
                    <div style={{maxWidth:'1200px', margin : 'auto'}}  className="row justify-content-between">
                        <div className="col-md-12 mt-4 pt-2 px-0">
                            <span className="H2-Headline neutral-3" > {t('Questions')} <span className="H2-Headline " style={{ marginLeft: '10px' }}> {props?.listQuestions?.statistic?.totalQuestion<10? ""+props?.listQuestions?.statistic?.totalQuestion:props?.listQuestions?.statistic?.totalQuestion} </span></span>
                        </div>
                        <div className="col-md-12 mb-5 text-left mt-4 px-0">
                            {(actualTab === 'unsettled') && <button className="btn-Tabs me-2" onClick={() => {setActualTab('unsettled'); setTab("unsettled") }} style={{ marginRight: "16px"}}>{t('unsettledQuestion')} ({props?.listQuestions?.statistic?.totalUnsettledQuestion})</button>}
                            {(actualTab !== 'unsettled') && <button className="btn-Tabs unselected me-2" onClick={() => {setActualTab('unsettled'); setTab("unsettled") }} style={{ marginRight: "16px"}}>{t('unsettledQuestion')} ({props?.listQuestions?.statistic?.totalUnsettledQuestion})</button>}
                            {(actualTab === 'settled') && <button className="btn-Tabs me-2" onClick={() => {setActualTab('settled'); setTab("settled") }} style={{ marginRight: "16px"}}>{t('settledQuestion')} ({props?.listQuestions?.statistic?.totalSettledQuestion})</button>}
                            {(actualTab !== 'settled') && <button className="btn-Tabs unselected me-2" onClick={() => {setActualTab('settled'); setTab("settled")}} style={{ marginRight: "16px"}}>{t('settledQuestion')} ({props?.listQuestions?.statistic?.totalSettledQuestion})</button>}
                            {(actualTab === 'all') && <button className="btn-Tabs me-2" onClick={() => {setActualTab('all'); setTab("all") }} style={{ marginRight: "16px"}}>{t('allQuestion')} ({props?.listQuestions?.statistic?.totalQuestion})</button>}
                            {(actualTab !== 'all') && <button className="btn-Tabs unselected me-2" onClick={() => {setActualTab('all'); setTab("all") }} style={{ marginRight: "16px"}}>{t('allQuestion')} ({props?.listQuestions?.statistic?.totalQuestion})</button>}
                        </div>
                    </div>
                </div>
                <div style={{maxWidth:'1200px', margin : 'auto'}} className="col-md-12">
                    <div className="row justify-content-between">
                    {(actualTab === 'unsettled') && props?.listQuestions?.statistic?.totalUnsettledQuestion !==0 && <SearchFilterQuestionComponent isSearching={isSearching}/>}
                    {(actualTab === 'settled') && props?.listQuestions?.statistic?.totalSettledQuestion !==0 && <SearchFilterQuestionComponent isSearching={isSearching}/>}
                    {(actualTab === 'all') && props?.listQuestions?.statistic?.totalQuestion !==0 && <SearchFilterQuestionComponent isSearching={isSearching}/>}
                     </div>
                </div>
            </div> 
       </div>
   ) 
}

const mapStateToProps = (state: AppState,ownProps: any) => ({
    t: ownProps?.t,
    tabQuestionManager: state.chatReducer.tabQuestionManager,
    listQuestions: state.chatReducer.listQuestions, 
  });
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({setActualTabManageQuestion},dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(ManageQuestionComponent);