import { ComponentProps, FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCoursesForDirector, clearFormCourse } from '../../global-state/actions/course-actions';
import { redirect } from '../../global-state/actions/breadcrumb-actions'
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { useHistory } from "react-router-dom";
import { changeTabAllTrainings, getTrainingsForAssistant } from '../../global-state/actions/training-actions';
import TableAssistantAllCoursesComponent from '../../components/tables/table-assistant-all-courses-component';
import TableAllCoursesLoadingComponent from '../../components/tables/table-all-courses-loading-component';
import { returnStartPathUrl } from '../../helpers/domainCheck';

let outsideAtBottom = false;

const AssistantAllCoursesPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [courses, setCourses] = useState(props?.allCourses);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();


  useEffect(() => {
    let filter = {
        entityId: props?.user?.idSchool,
        entityType: "SCHOOL",
        }
        props?.getTrainingsForAssistant(filter);
  }, [])
  


  useEffect(() => {
    setCourses(props?.allCourses);
  }, [props?.allCourses])
  
  useEffect(() => {
    setLoading(props?.loadingAllCourses)
  }, [props?.loadingAllCourses]);


  const createTraining = async () => {
    await props?.clearFormCourse();
    props?.redirect(t('createNewFormation'), `${returnStartPathUrl()}/courses/create-course/choice`)
    history.push(`${returnStartPathUrl()}/courses/create-formation/choice`);
  }

  const displayCourseLength = () => {
    if(courses?.length > 0) return `0${courses?.length}`
    if(courses?.length === 0) return '0'
    return courses?.length; 
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
              <div className="col-md-12 mt-5" >
                
                  
                  <div className="d-flex align-items-center mb-3" style={{ justifyContent: "space-between" }}>
                    <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left' }}>
                      {t('TitleFormations')}
                      <span className="ms-3" style={{ color: "#272727" }}>
                        {courses?.length < 10 && displayCourseLength()}
                      </span> 
                    </h1>
                    {props?.user?.role === "role_adminstrator" && props?.allCourses?.length > 0 &&
                      <button className="btn-Primary large icon-left" onClick={() => createTraining()}>
                        <span className="material-icons">add</span>
                        <div className="m-auto ps-2">{t('createNewFormation')}</div>
                      </button>
                    }
                  </div>
                
              </div>
            </div>
            
          </div>
        </div >
      </div>
      <div className="mx-auto" style={{ maxWidth: '1200px' }}>
      {loading ?
          <TableAllCoursesLoadingComponent />
          :
          <TableAssistantAllCoursesComponent  createTraining={createTraining}/> 
        }
        
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  allCourses: state.trainingReducer.trainings,
  user: state.authReducer.connectedUser,
  actualTabAllCourses: state.trainingReducer.actualTabTraining,
  loadingAllCourses: state.trainingReducer.loadingAllTrainings,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCoursesForDirector,
      changeTabAllTrainings,
      clearFormCourse,
      getTrainingsForAssistant,
      redirect
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistantAllCoursesPage);
