import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import ModalShareSondage from "../../components/modals/modal-forms/modal-share-survey-modal-component";
import { ModalIDS } from "../../components/modals/modal-ids";
import { openModal } from '../../global-state/actions/modal-actions';
import { closeModal } from '../../global-state/actions/modal-actions';
import { useHistory } from "react-router";
import { returnStartPathUrl } from "../../helpers/domainCheck";
const BredCrumbPassSondage: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ title }: any) => {
  const history = useHistory()
const goBack=async()=>{
  await history.push(`${returnStartPathUrl()}/survey`)
}

  return (
    <div
      className="container-bredcrumb-sondage cours-nav w-100"
      style={{ zIndex: 1000, position: "fixed", top: 0 }}
    >
      <div className="navbar navbar-expand-lg box-shadow-navbar-bredcrumb d-flex justify-content-between p-0 flex-nowrap">
        <div className="d-flex order-0">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {goBack() }}
            className="d-flex justify-content-center align-items-center nav-back"
          >
            <span className="material-icons-round">chevron_left</span>
          </a>
          <div className="d-flex px-4 align-items-center">
            <span
              style={{ color: "#CACBCE" }}
              className="body-md bold"
            >
              {title}
            </span>
          </div>
        </div>

      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  countOfSondage: ownProps.title,
  // sondageReducer: state.sondageReducer.serveys,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    openModal
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BredCrumbPassSondage);
