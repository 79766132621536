import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const HowToWorkSectionComponentLoading: FC<ComponentProps<any>> = () => {

  return (
    <>
        <section>
            <div className="py-5 max-width" style={{ margin: 'auto', minHeight: 250 }}>
                <div className="d-flex align-items-center justify-content-center text-center mb-5" >
                    <Skeleton variant="rect" animation="wave" width={'25%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                </div>
                <div className={"d-sm-flex align-items-center justify-content-around"}>
                    <div style={{width:"250px"}}>
                        <Skeleton variant="rect" animation="wave" width={'50%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                        <Skeleton variant="rect" animation="wave" width={'100%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />
                        <Skeleton variant="rect" animation="wave" width={'80%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                    <div className="justify-content-center text-center" style={{ margin: 'auto' }}>
                        <span className="material-icons-outlined rotate-how-it-works">arrow_right_alt</span>
                    </div>
                    <div style={{width:"250px"}}>
                        <Skeleton variant="rect" animation="wave" width={'50%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                        <Skeleton variant="rect" animation="wave" width={'100%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />
                        <Skeleton variant="rect" animation="wave" width={'80%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                    <div className="justify-content-center text-center" style={{ margin: 'auto' }}>
                        <span className="material-icons-outlined rotate-how-it-works">arrow_right_alt</span>
                    </div>
                    <div style={{width:"250px"}}>
                        <Skeleton variant="rect" animation="wave" width={'50%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                        <Skeleton variant="rect" animation="wave" width={'100%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-4" />
                        <Skeleton variant="rect" animation="wave" width={'80%'} height={24} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default HowToWorkSectionComponentLoading;