import { ComponentProps, FC } from 'react';

import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { useHistory } from 'react-router-dom';
import { AppState } from "../../../global-state/store/root-reducers";
import { getSubscriptions } from '../../../global-state/actions/app-container-actions';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { returnStartPathUrl } from '../../../helpers/domainCheck';
const EmptyPathComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const redirect = async () => {
   
        props?.closeModal();
        history.push(`${returnStartPathUrl()}/courses/create-formation/choice`)
    }


    return (
        <>
            <div style={{ backgroundColor: "#F8F8FA" }}>

                <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                    <div className="w-100 d-flex align-items-center justify-content-between p-3">
                        <span className='H3-Headline black-800'>{t("Ajouter un contenu")}</span>
                        <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>

                </div>
                <div className='m-3'>

                    <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
                        <div className="d-flex align-items-center justify-content-center w-100">

                            <span className="H3-Headline mb-3"> {t('noCourseFound')}</span>

                        </div>
                        <div className="d-flex align-items-center justify-content-center w-100">

                            <span className="body-sm mb-4">{t('wantCreateCourse')}</span>

                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center w-100" style={{ paddingBottom: 80, borderTop: "none" }}>

                        <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >{t('annuler')}</button>
                        <button type="button" className="btn-Primary large icon-left">
                            <span className="material-icons-outlined">
                                add
                            </span>
                            <div className="m-auto ps-2" onClick={() => redirect()}>{t('newCourse')}</div>
                        </button>


                    </div>

                </div>


                <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none" }}>
                </div>

            </div>
        </>
    )

}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    schoolInfo: state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal,
        getSubscriptions
    },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(EmptyPathComponent);