import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

function LoadingPathSessionContentComponent() {
  return (
    <div style={{ maxWidth: "1200px", margin: "auto" }}>
            
            <div className="mb-4" style={{ marginTop: "40px" }}>
                <div className="d-flex mx-2 justify-content-between" >
                    <div className="bg-with-border p-4 progress-percent-card me-4 " style={{ width: 468 }}>
                        <div className="d-flex flex-column mx-2">
                            <Skeleton className="skeleton square" animation="wave" variant="rect" width={137} height={32} />
                                <div className="d-flex flex-row align-items-center justify-content-between pb-3">
                                    <div >
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={337} height={20} />
                                    </div>
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={30} height={38} />
                                </div>
                                <Skeleton className="skeleton square" animation="wave" variant="rect" width={180} height={16} />
                            
                            
                        </div>
                    </div>
                   
                        <div className="bg-with-border  in-progress-card " style={{ width: '80%' , padding:"10px 32px"}} >
                            <div className="d-flex flex-column mx-2 in-progress-card-content">
                                <div className='d-flex flex-row justify-content-between'> 
                                <Skeleton className="skeleton square" animation="wave" variant="rect" width={156} height={24} />
                                <Skeleton className="skeleton square" animation="wave" variant="rect" width={130} height={36} />
                                </div>
                                <div className="d-flex flex-row align-items-center" style={{ gap:"20px"}}>
                                    <div  style={{paddingTop:"20px", gap:"20px"}}>
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={75} height={75} />
                                    </div>
                                    <div className="d-flex flex-column" style={{gap:"10px" }}>
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={415} height={24} />
                                    <Skeleton className="skeleton square" animation="wave" variant="rect" width={275} height={20} />    
                                    </div>
                                </div>
                                
                            </div>
                        </div> 
                </div>
                <div className="row  mx-0 px-0" style={{ paddingTop: "40px" , gap:"16px" }}>
                <Skeleton className="skeleton square" animation="wave" variant="rect" width={1200} height={120} />
                <Skeleton className="skeleton square" animation="wave" variant="rect" width={1200} height={120} />
                <Skeleton className="skeleton square" animation="wave" variant="rect" width={1200} height={120} />
                </div>
            </div>
    </div>
            
    )


}

export default LoadingPathSessionContentComponent