import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../../global-state/actions/profile-actions';
import { setActualTab } from '../../../global-state/actions/professor-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { getStudents, getRegistrationForm, setRegistrationForm, setApplicationFile, getApplicationFile, getFollow, getStudentInfoById } from '../../../global-state/actions/course-actions';
import TableElement from '../../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';


const StudentsCandidateInfoTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const iconCourse = <svg className="me-2" width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
        </g>
        <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
        <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
            </clipPath>
        </defs>
    </svg>;
    const iconPath = <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
            fill="#FA83EE" />
    </svg>
    const iconSession = <svg className="me-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
        </g>
        <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
        <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
        <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
            </clipPath>
        </defs>
    </svg>
    const [sizeCandidates, setSizeCandidates] = useState(20);
    const [candidateDataLength, setCandidateDataLength] = useState<any>(null);
    const [candidateData, setCandidateData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false)
    const [emptysearch, setemptysearch] = useState<any>(false);
    const candidateHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-22", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('demandTable'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]

    const [actualTab, setActualTab] = useState((props?.lastTabClicked) ? props?.lastTabClicked : "All");
    const dateFormat = require('dateformat');
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);
    useEffect(() => {
        setLoadingTable(props?.loadStudentsCandidate);
    }, [props?.loadStudentsCandidate])
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    useEffect(() => {
      
        if (!props?.data?.searchCandidate  && props?.studentsList?.length === 0 && props?.followCount?.CANDIDATE > 0) 
        {
            setemptysearch(true)
        }else{
            setemptysearch(false)
        }
    }, [props?.data])
    const handleScroll = () => {
        if (!(atBottom) && (window.innerHeight + window.scrollY+1) >= document.documentElement.scrollHeight) {
            setAtBottom(true);
            if (candidateDataLength > sizeCandidates) {
                props.getNextPageCandidate()            }
            document.removeEventListener('scroll', handleScroll);
        }
        if (!(((window.innerHeight + window.scrollY) -5) < document.documentElement.scrollHeight)) {
            setAtBottom(false);
        }
    }
    useEffect(() => {
        setSizeCandidates(props?.data?.sizeCandidates);
    }, [props?.data])
    useEffect(() => {
        setActualTab(props?.lastTabClicked);
    }, [props?.lastTabClicked])

    useEffect(() => {
        const list = {
            headers: candidateHeaders,
            body: props?.studentsList?.list.map((student: any) => {
                const dateSent = Math.ceil((new Date().getTime() - new Date(student?.demandDate).getTime()) / (1000 * 3600 * 24));
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            {
                                content: <span>{dateFormat(student?.demandDate, "dd/mm/yyyy ")} {(student?.status === 'CANDIDATE') ?
                                    <span className={`badge-text ${(dateSent < 3) ? 'secondary' : (dateSent >= 3 && dateSent < 6) ? 'warning' : 'refuse'}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                    :
                                    <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                }
                                </span>,
                                classNames: "",
                                actionName: ''
                            },
                            {
                                content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0) ? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document ${(student?.status === 'CANDIDATE') ? '' : 'refuse'}`}>
                                    <span className="material-icons-outlined" style={{ color: '#272727' }}>description</span>
                                </button> : <button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0) ? 'goToRegistration' : ''
                            },
                            {
                                content: <div className="d-flex align-items-center">
                                    {(student?.type === 'COURSE') ? iconCourse : (student?.type === 'PATH') ? iconPath : iconSession}
                                    <span className="ms-2">{student?.courseTitle}</span></div>,
                                classNames: "",
                                actionName: ''
                            },
                            {
                                content: (student.status === "CANDIDATE") ? t("Candidat") : t('Refused'),
                                classNames: `text-end badge-text  ${(student?.status === "CANDIDATE") ? 'primary' : 'danger'}`,
                                actionName: ''
                            },
                        ]
                    }
                }
            })
        }
        setCandidateData(list);
        setCandidateDataLength(props?.studentsList?.length);
        setLoadingTable(false)
    }, [props?.studentsList?.list])


    const goToregistration = async (Student: any) => {
        props?.clearHistoryArray()
        await props?.getStudentInfoById(Student.id, props?.schoolInfo?.id);
        await props?.setApplicationFile(Student.applicationFile);
        await props?.setRegistrationForm(Student.registrationForm);
        await props?.getFollow(Student.idFollow);
        await props?.getStudent(Student);
        props?.redirect(t('Etudiant_1'), `${returnStartPathUrl()}/students/all`)
        props?.redirect(Student?.firstName + " " + Student?.lastName, `${returnStartPathUrl()}/students/profile/student/${Student.id}`)
        props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/students/registration/${Student.idFollow}/${Student.id}/${Student.registrationForm.id}`)
         history.push({ pathname: `${returnStartPathUrl()}/students/registration/${Student.idFollow}/${Student.id}/${Student.registrationForm.id}`, state: { data: Student } })
    }
    const addDataListFn = (tabText: string) => {
        props?.addDataList(tabText);
    }
    const doAction = async (value: any) => {

        if (value.action === 'goToPath') {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray()
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
             history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
        if (value.action === 'goToRegistration') {
            goToregistration(value.row.student);
        }
    }

    return (<>
        {!loadingTable ?
            <div>
                {(candidateData?.body?.length > 0) && <>
                    <TableElement data={candidateData} doAction={(value: any) => { doAction(value) }} />
                    {(atBottom 
                    && (props?.studentsList.list?.length < props?.followCount?.CANDIDATE ))
                    && <div className="d-flex w-100 align-items-center justify-content-center">
                        <div className="m-3">
                            <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div>
                    </div>}
                </>
                }
                {(candidateData?.body?.length === 0 && !props?.data?.searchCandidate && !emptysearch ) &&
                    <div className="w-100 text-center mt-5 pt-5">
                        <EmptyListElement lines={[t('noCandidateFound')]} />
                    </div>
                }
                {(emptysearch) &&
                    <div>
                    <TableStudentsListLoadingComponent />
                   </div>
               }
                {(candidateData?.body?.length === 0 && props?.data?.searchCandidate) &&
                    <div className="w-100 text-center mt-5 pt-5">
                          <EmptyListElement lines={[t('noStudentFound_2'),t('noProfessorsList_2')]} />
                </div>}
            </div> :
            <div>
                <TableStudentsListLoadingComponent />
            </div>}
    </>);
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    myStudents: state.courseReducer.myStudents,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    courseInfo: state.courseReducer.courseInfo,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    addDataList: ownProps?.addDataList,
    loadStudentsCandidate: state.profReducer.loadStudentsCandidate,
    data: ownProps?.data
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudents,
            getApplicationFile,
            getRegistrationForm,
            getFollow,
            getStudentInfoById,
            getStudent,
            setApplicationFile,
            setRegistrationForm,
            setActualTab,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsCandidateInfoTableComponent);