import { ComponentProps, FC } from "react";




const EmptyListElement: FC<ComponentProps<any>> = (props) => {
    return (
        <div className="d-flex flex-column align-items-center">
            <span className="material-icons-outlined black-800 mb-3">waves</span>
            <div className="d-flex flex-column align-items-center mb-4">
                {props?.lines.map((line:string, i: number) => (
                <span key={i} className="body-md bold black-800">{line}</span>
                ))}
            </div>
            { props?.children }
        </div>
    );
}
export default EmptyListElement;
