import { ComponentProps, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { getCountStudent } from "../../global-state/actions/training-actions";
import { AppState } from "../../global-state/store/root-reducers";
import EmptyListElement from "../empty-list/normal-empty-list-element";
import RowExpandElement from "./row-expanded-element";
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from "../../components/modals/modal-ids";
import { log } from "console";

interface Props {
  data: any
  doAction?: any
  height?: number | string
  size?: number
}
const TableWithExpandElementNotVisible: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [open, setOpen] = useState([true]);
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(props?.loading)
  }, [props?.loading]);

  useEffect(() => {
    setData(props?.data)

  }, [props?.data]);
  const doAction = async (e: any, actionName: string, row: any, index: number) => {

    const openCopy = [...open];
    if ((actionName == "openexpand")) {

      openCopy[index] = !openCopy[index];
      if (openCopy[index] === true) {
        setIndex(index);
      }

      setOpen(openCopy);
      if (data.body[index].row.course.countInscribed == null && data.body[index].row.course.countCertified == null && data.body[index].row.course.countCandidates == null) {
        props?.getCountStudent(data.body[index].row.course).then(
          (res: any) => {
            data.body[index].row.course.countInscribed = res.data.countInscribed;
            data.body[index].row.course.countCertified = res.data.countCertified;
            data.body[index].row.course.countCandidates = res.data.countCandidates;
          }
        );
      }
      e.stopPropagation();

    } else if ((actionName == "open")) {


      if (data.body[index].row.course.isVisible && data.body[index].row.course.status == "PUBLISHED") {
        await props?.openModal({ idModal: ModalIDS.VISE_TRAINING, size: "md", data: data.body[index] })//masquer
        e.stopPropagation();
      } else if (!data.body[index].row.course.isVisible && data.body[index].row.course.status == "PUBLISHED") {
        await props?.openModal({ idModal: ModalIDS.NOT_VISE_TRAINING, size: "md", data: data.body[index] })//afficher
        e.stopPropagation();

      }


    } else if (actionName !== '') {

      e.stopPropagation();

      const object = {
        row: row,
        action: actionName,
        index: index
      }
      props?.doAction(object);
    }
  }

  useEffect(() => {
    for (let index = 0; index < props?.size; index++) {
      open[index] = true;
    }
  }, []);


  return (
    <>
      <div className="my-3" >
        <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>
          <table className="w-100 custom-table">
            <thead className="mb-4">
              <tr>
                {data?.headers?.map((header: any) =>
                (<th key={header.title} scope={header.scope} className={header.classNames}>
                  <button className={header.buttonClasses}>
                    {t(header.title)}
                  </button>
                </th>)

                )}
                <th key="Actions" scope="col" className="">

                 
                </th>
           

            </tr>
          </thead>
          <tbody className="w-100" style={{ height: 30, overflow: 'scroll' }}>
            {data?.body?.map((obj: any, index: number) =>
            (<>

              <tr style={{ cursor: 'pointer' }} onClick={(e) => doAction(e, obj.row.actionName, obj.row, index)} className={obj.row.classNames}>
                {obj.row.columns.map((column: any, index: number) => (
                  <td
                    key={index + 'column'}
                    onClick={(e) => doAction(e, column.actionName, obj.row, index)}
                    className={column.classNames}
                    style={{ position: 'relative' }}
                    colSpan={column.colspan}
                  >
                    {column.content}
                  </td>
                )

                )}

                <td key={index + 'columnc'} >
                  <div className="d-flex mx-auto  flex-row justify-content-between" style={{ maxWidth: 35 }}>


                    <div className="d-flex flex-row  mx-auto  align-items-center black-800 " style={{ position: "relative" }} onClick={(e) => { doAction(e, "openexpand", obj.row, index) }}>

                      {!open[index] ? <>
                        <span className="material-icons-outlined">expand_less</span>

                      </> :
                        <> <span className="material-icons-outlined">expand_more</span></>}
                    </div>
                  </div>

                </td>



              </tr>
              {!open[index] ? <>

                <RowExpandElement data={obj?.row?.course} index={index} open={open} loading={loading} dragable={false} />
              </> :
                <>
                </>}

            </>
            )
            )}
          </tbody>
        </table>
      </div>
      {data?.body?.length === 0 && (
        <div className="w-100 mt-7">
          <div className="msg-formation-list-empty">
            <EmptyListElement lines={[t('emptyListFormation1'), t('emptyListFormation2')]} />
          </div>
        </div>
      )}
    </div >
    </>
  );

}
const mapStateToProps = (state: AppState) => ({
  countStudent: state.trainingReducer.countStudent,
  loading: state.trainingReducer.loadingcountStudent,
  LengthCourses: state.courseReducer.LengthCourses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCountStudent, openModal,
      closeModal,
    }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableWithExpandElementNotVisible);

