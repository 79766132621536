import Skeleton from '@material-ui/lab/Skeleton';

function UpdateUserMailLoadingComponent() {
    return (
        <div className=" d-flex justify-content-end"  >
            <div className="row" style={{ width:'100%', maxWidth: 1200-420, margin: 'auto' }}>
                <div className="col-md-4"></div>
                <div className="col-md-8">
                    <div className="container-fluid">
                        <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Skeleton style={{ marginLeft: "10px" }} className="skeleton subtext mb-2" animation="wave" variant="text" width={'220px'} />
                            <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'220px'} />
                        </div>
                        <div className="row" style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'108px'} />
                            </div>
                            <div>
                                <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'220px'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-8">
                                <hr className="mb-5" style={{ border: "1px solid #F2F2F5" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateUserMailLoadingComponent
