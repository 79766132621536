import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect} from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../../../global-state/store/root-reducers";
import userLogo from '../../../../../styles/imgs/Default_user_icon.svg';
import { createResponse} from '../../../../../global-state/actions/chat-actions';
import ResponseCard from '../../../../questions/question-card/response-card';
import CommentCardHeader from './comment-card-header';
import TextareaCustomparagraphCommentElement from '../../../../../elements/text-area/text-area-custom-paragraph-comment-element';
interface Props {
    post: any,
    what: string,
    t: any,
    from?:string,
}
const CommentCard: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props) => {
    const { t } = useTranslation();
    const [post, setPost] = useState(props?.post);
    const [schowInput, setSchowInput] = useState(false);
    const [comment, setComment] = useState("");
    const [schowed, setSchowed] = useState(false)

    useEffect( () => {
        setPost(props?.post)   
 
     
         
    }, [post, props?.post]);
    
    const buttonHandler = () => {
        setSchowInput(current => !current)
    }
    const addResponse = async () => {
        let object = {
            post: {
                idAuthor: props?.connectedUser?.idPerson,
                type: "RESPONSE",
                postId: props?.post.id
            },
            comment: {
                description: comment,
                type: "TEXT"
            },
            subject: [props?.post.subject.idSubject+" "+props?.post.subject.category+" "+props?.post.subject.type]
        }
        
        await props?.createResponse(object, props?.what, props?.connectedUser?.idSchool );
        setSchowInput(current => !current)
    }
    const changeInputValue=(value:string )=>{
        setComment(value)
    }
    return (
        <>
            <div className="my-3">
                <div className="d-flex align-items-top mb-2">
                    <div className="me-3">
                        <img className="img-profil small" src={(post?.idAuthor?.photo) ? post?.idAuthor?.photo : userLogo} alt="profile img" />
                    </div>
                    <div className="d-flex flex-wrap badge-text dark align-items-center">
                        <span className="body-sm bold me-3 mt-2 discuttion-titles">{post?.idAuthor?.firstName + " " + post?.idAuthor?.lastName}</span>
                        <CommentCardHeader post={post} from={props?.from}></CommentCardHeader>
                       
                    </div>
                </div>
                <div className="d-flex flex-column ms-5 ps-1">
                    <span className="body-md bold badge-text dark">{post?.title}</span>
                    <span className="body-md neutral-2 my-3">{post?.comment?.description}</span>
                    <div className="d-flex flex-column">
                        <div className="mb-3">
                            <div className="row px-0 mx-0" id="accordin" >
                                <div className="col-md-12" id="headingOne">
                                    <div className=" d-flex flex-row  flex-wrap">
                                        {(post?.responses?.length !== 0 ) &&
                                            <button style={{backgroundColor:"#FBFBFD"}} className="btn-text with-icon me-2" onClick={() => { setSchowed(!schowed)}} data-bs-toggle="collapse" data-bs-target={"#id" + post?.id} aria-expanded="false" aria-controls="collapseOne">
                                                {post?.responses?.length + " " + t('responses')}{(schowed === false) ? <span className="material-icons">expand_more</span> : <span className="material-icons">expand_less</span>}
                                            </button>}   
                                    </div> 
                                </div>
                                <div id={"id" + post?.id} className="collapse  col-md-12 mt-3" aria-labelledby="headingOne" >
                                    {post?.responses?.map((res: any) => {
                                        return <><ResponseCard post={res} t={t} /></>
                                    })}
                                </div>
                            </div>

                        </div>
                        {((schowInput === false && post?.responses?.length!==0 && props?.connectedUser?.role.includes("role_student") && props?.tabChatManager!=="question") ||
                        (schowInput === false && (props?.connectedUser?.role.includes("role_prof") || props?.connectedUser?.role.includes("role_adminstrator") ) )  ||
                        (schowInput === false && post?.responses?.length!==0 && props?.connectedUser?.role.includes("role_student") && props?.tabChatManager==="question")  || 
                        (schowInput === false && post?.responses?.length===0 && props?.connectedUser?.role.includes("role_student") && props?.tabChatManager==="discussion"))   && <button className="btn-text small" onClick={buttonHandler} style={{backgroundColor:"#FBFBFD"}}>{t('repondre')}</button>}
                        {(schowInput === true) && <div className="col-md-12" id="text">
                            <div className="row mt-2">
                                <div className="col-md-12  comment-reply">
                                    <img className="comment-reply pic me-2 mt-auto" src={(props?.connectedUserInfo?.photo) ? props?.connectedUserInfo?.photo : userLogo} alt="userImg" />
                                    <div className=" comment">
                                        <div className="w-100 me-1 d-flex align-items-center">
                                            <TextareaCustomparagraphCommentElement id={props?.post?.id} value={comment} onChange={changeInputValue} placeholder={t('votreCommentaie')}/>
                                        </div>
                                    </div>
                                    <button className="btn-Primary large ms-4" style={{  fontSize: '14px', textAlign:"center" , margin:"auto" }} disabled={comment === ""} onClick={() => { addResponse(); setComment("") }}> {t('publishMyReview')}</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    connectedUserInfo: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    tabChatManager: state.chatReducer.tabChatManager,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createResponse  
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(CommentCard);
