import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axiosInstance from "../../helpers/interceptors";
import { IReduxStudentTrainingeState } from "../reducers/student-training-reducer";
import { set } from 'lodash';

export enum EReduxActionTypes {
   GET_STUDENT_TRAINING = "GET_STUDENT_TRAINING",
   GET_CURRENT_COURSE = "GET_CURRENT_COURSE",
   GET_SIDEBAR_SECTION_CONTENT = "GET_SIDEBAR_SECTION_CONTENT",
   GET_TRAINING_SECTION_CONTENT = "GET_TRAINING_SECTION_CONTENT",
   GET_SIDEBAR_SECTION_CONTENT_Without_Api="GET_SIDEBAR_SECTION_CONTENT_Without_Api",
   SET_STUDENT_PROGRESS = 'SET_STUDENT_PROGRESS',
   CHECK_PROGRESS_COURSE = 'CHECK_PROGRESS_COURSE',
   CREATE_EXAM_PROGRESS = 'CREATE_EXAM_PROGRESS',
   GET_CURRENT_TRAINING = "GET_CURRENT_TRAINING",
   UPDATE_PROGRESS_EXAM = "UPDATE_PROGRESS_EXAM",
   CALCULATE_TRAINING = "CALCULATE_TRAINING",
   UPDATE_INDEX_PATH_SESSION = "UPDATE_INDEX_PATH_SESSION",
   CLEAR_CURRENT_COURSE = "CLEAR_CURRENT_COURSE",
   SET_EXAM_RESULT = 'SET_EXAM_RESULT',
   INITIALIZE_INDEX = 'INITIALIZE_INDEX',
   UPDATE_INDEX = 'UPDATE_INDEX',
   GET_INVITATIONS_LIST='GET_INVITATIONS_LIST'
}


interface IReduxBaseAction {
  type: EReduxActionTypes;
}



export interface IReduxGetTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENT_TRAINING;
  data: any;
}
export interface IReduxUpdateIndexPathSessionPassAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_INDEX_PATH_SESSION;
  data: any;
}

export interface IReduxGetCurrentCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CURRENT_COURSE;
  data: any;
}
export interface IReduxGetSidebarSectionContentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SIDEBAR_SECTION_CONTENT;
  data: any;
}
export interface IReduxGetInvitationsListAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_INVITATIONS_LIST;
  data: any;
}
export interface IReduxGetTrainingSectionContentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TRAINING_SECTION_CONTENT;
  data: any;
}

export interface IReduxGetSidebarSectionContentWithoutApiAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SIDEBAR_SECTION_CONTENT_Without_Api;
  data: any;
}
export interface IReduxSetProgressAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_STUDENT_PROGRESS;
  data: any;
}
export interface IReduxStudentcheckProgressCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_PROGRESS_COURSE;
  data: any;
}
export interface IReduxCreateProgressExamAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_EXAM_PROGRESS,
  data: any
}
export interface IReduxGetCurrentTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CURRENT_TRAINING,
  data: any
}
export interface IReduxUpdateCurrentProgressExamAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_PROGRESS_EXAM,
  data: any
}
export interface IReduxCalculateTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.CALCULATE_TRAINING,
  data: any
}
export interface IReduxClearCurrentCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_CURRENT_COURSE,
}
export interface IReduxsetExamResultAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_EXAM_RESULT,
  data: any
}

export interface IReduxGetUpdateIndexAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_INDEX;
  data: any;
}

export interface IReduxGetInitilizeIndexAction extends IReduxBaseAction {
  type: EReduxActionTypes.INITIALIZE_INDEX;
  data: any;
}



export function updateIndexProgress(exam: any): ThunkAction<Promise<IReduxGetUpdateIndexAction>, IReduxStudentTrainingeState, undefined, IReduxGetUpdateIndexAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetUpdateIndexAction>) => {
    return dispatch({
      type: EReduxActionTypes.UPDATE_INDEX,
      data: exam
    });
  };
}
export function getInvitationsList(object: any): ThunkAction<Promise<IReduxGetInvitationsListAction>, IReduxStudentTrainingeState, undefined, IReduxGetInvitationsListAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetInvitationsListAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_INVITATIONS_LIST,
      data: object
    });
  };
}

export function clearIndexProgress(exam: any): ThunkAction<Promise<IReduxGetInitilizeIndexAction>, IReduxStudentTrainingeState, undefined, IReduxGetInitilizeIndexAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetInitilizeIndexAction>) => {
    return dispatch({
      type: EReduxActionTypes.INITIALIZE_INDEX,
      data: exam
    });
  };
}


export function setExamResult(exam: any): ThunkAction<Promise<IReduxsetExamResultAction>, IReduxStudentTrainingeState, undefined, IReduxsetExamResultAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxsetExamResultAction>) => {
    return dispatch({
      type: EReduxActionTypes.SET_EXAM_RESULT,
      data: exam
    });
  };
}
export function getCurrentPath(training: any): ThunkAction<Promise<IReduxGetCurrentCourseAction>, IReduxStudentTrainingeState, undefined, IReduxGetCurrentCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetCurrentCourseAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_CURRENT_COURSE,
      data: { training: [training] }
    });
  };
}

export function clearCurrentCourse(): ThunkAction<Promise<IReduxClearCurrentCourseAction>, IReduxStudentTrainingeState, undefined, IReduxClearCurrentCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxClearCurrentCourseAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_CURRENT_COURSE,
    });
  };
}
export function getStudentTrainings(filter: any): ThunkAction<Promise<IReduxGetTrainingAction>, IReduxStudentTrainingeState, undefined, IReduxGetTrainingAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetTrainingAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL14;
      let trainings = await axiosInstance.post(baseUrl + `training/filter` , filter).then(res => { return res.data }).catch(err => {return []});
      return dispatch({
        type: EReduxActionTypes.GET_STUDENT_TRAINING,
        data: trainings
      });
  
    }
  }
  export function updateCurrentProgressExam(object: any): ThunkAction<Promise<IReduxUpdateCurrentProgressExamAction>, IReduxStudentTrainingeState, undefined, IReduxUpdateCurrentProgressExamAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxUpdateCurrentProgressExamAction>) => {
      return dispatch({
        type: EReduxActionTypes.UPDATE_PROGRESS_EXAM,
        data: object
      });
  
    }
  }
  
  export function getCurrentCourse(id: number): ThunkAction<Promise<IReduxGetCurrentCourseAction>, IReduxStudentTrainingeState, undefined, IReduxGetCurrentCourseAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetCurrentCourseAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL14;
         let course = null;
      if(id){
        course = await axiosInstance.get(baseUrl + `followUp/currentFollowUp/` + id).then(res => { return res.data }).catch(err => console.error(err));
      }
 return dispatch({
        type: EReduxActionTypes.GET_CURRENT_COURSE,
        data: course
      });
    };
  }
  export function getCurrentTraining(id: number): ThunkAction<Promise<IReduxGetCurrentTrainingAction>, IReduxStudentTrainingeState, undefined, IReduxGetCurrentTrainingAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetCurrentTrainingAction>) => {
      const baseUrl = process.env.REACT_APP_BASE_URL14;
      const baseUrlFollowUp = process.env.REACT_APP_BASE_URL3;
      let course = await axiosInstance.get(baseUrl + `followUp/` + id).then(res => { return res.data }).catch(err => console.error(err));
      if(course?.training[0]?.followUp?.progressTraining === null) {
        const object = {id: null, entityType: course.training[0].type, entityId: course.training[0].id, follow: course.training[0].followUp }
        const progress = await axiosInstance.post(baseUrlFollowUp + `progress/progressTraining`, object).then(res => { return res.data }).catch(err => console.error(err));
        course.training[0].followUp.progressTraining = progress;
      }
      return dispatch({
        type: EReduxActionTypes.GET_CURRENT_TRAINING,
        data: course
      });
    };
  }
  
  export function getSideBarSectionContent(sectionContent :any, index :number, contentType:string, idFollow: number): ThunkAction<Promise<IReduxGetSidebarSectionContentAction>, IReduxStudentTrainingeState, undefined, IReduxGetSidebarSectionContentAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetSidebarSectionContentAction>) => {
      let progressTraining = undefined;
      if(idFollow && index) {
        progressTraining =  await axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}progress/updateIndexTraining/${idFollow}/${index}`).then((response: any) => {return response.data}).catch(err => {return undefined});
      }
      return dispatch({
        type: EReduxActionTypes.GET_SIDEBAR_SECTION_CONTENT,
        data:{ section: {sectionContent, index, contentType}, progress: progressTraining}
      });
    };
  }

  export function getTrainingSectionContent(sectionContent :any, index :number, contentType:string, idFollow: number): ThunkAction<Promise<IReduxGetTrainingSectionContentAction>, IReduxStudentTrainingeState, undefined, IReduxGetTrainingSectionContentAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetTrainingSectionContentAction>) => {
      let progressTraining = undefined;
      if(idFollow && index) {
        progressTraining =  await axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}progress/getTraining/${idFollow}/${index}`).then((response: any) => {return response.data}).catch(err => {return undefined});
      }
      return dispatch({
        type: EReduxActionTypes.GET_TRAINING_SECTION_CONTENT,
        data:{ section: {sectionContent, index, contentType}, progress: progressTraining}
      });
    };
  }


  export function getSideBarSectionContentWithoutApi(sectionContent :any, index :number, contentType:string): ThunkAction<Promise<IReduxGetSidebarSectionContentWithoutApiAction>, IReduxStudentTrainingeState, undefined, IReduxGetSidebarSectionContentWithoutApiAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxGetSidebarSectionContentWithoutApiAction>) => {
      return dispatch({
        type: EReduxActionTypes.GET_SIDEBAR_SECTION_CONTENT_Without_Api,
        data:{ section: {sectionContent, index, contentType}}
      });
    };
  }

  export function updateIndexPathSessionPass(index :number, idFollow: number): ThunkAction<Promise<IReduxUpdateIndexPathSessionPassAction>, IReduxStudentTrainingeState, undefined, IReduxUpdateIndexPathSessionPassAction> {
    return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxUpdateIndexPathSessionPassAction>) => {
      let progressTraining = undefined;
      if(idFollow && index) {
        progressTraining = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}progress/updateIndexTraining/${idFollow}/${index}`).then((response: any) => {return response.data}).catch(err => {return undefined});
      }  
        return dispatch({
        type: EReduxActionTypes.UPDATE_INDEX_PATH_SESSION,
        data: progressTraining
      });
    };
}

export function createProgressExam(progress: any): ThunkAction<Promise<IReduxCreateProgressExamAction>, IReduxStudentTrainingeState, undefined, IReduxCreateProgressExamAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxCreateProgressExamAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    let progressData: any = await axiosInstance.post(baseUrl + "progress/createProgressExam", progress).then(response => { return response.data; }).catch(err => { return [] });    
    return dispatch({
      type: EReduxActionTypes.CREATE_EXAM_PROGRESS,
      data: progressData
    });
  };
}
export function setStudentProgress(progress: any, currentTraining: any, progressTraining: any, listFollowUps: any): ThunkAction<Promise<IReduxSetProgressAction>, IReduxStudentTrainingeState, undefined, IReduxSetProgressAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxSetProgressAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL3;
    const baseUrlOrchestrator = process.env.REACT_APP_BASE_URL14;

    let trainingProgress = undefined;
    
    
    if (progressTraining === null) {
      const object = { id: null, entityType: "COURSE", entityId: currentTraining?.id, follow: currentTraining?.followUp }
      trainingProgress = await axiosInstance.post(baseUrl + `progress/progressTraining`, object).then(res => { return res.data }).catch(err => console.error(err));
      progress.progressTrainingId = trainingProgress?.id;
    } else {
      trainingProgress = progressTraining;
    }
    let studentProgress = await axiosInstance.post(baseUrl + `progress/progress`, progress).then(res => { return res.data }).catch(err => console.error(err));
    let newProgressTraining = await axiosInstance.post(baseUrlOrchestrator + `progress/calculateProgress/${currentTraining.followUp.id}`, null).then(res => { return res.data }).catch(err => console.error(err));

    return dispatch({
      type: EReduxActionTypes.SET_STUDENT_PROGRESS,
      data: { studentProgress, newProgressTraining }
    });
  };
}
export function checkProgressCourse(followContent: any, idTraining: number, idStudent: number, doCkech: Boolean , training:any): ThunkAction<Promise<IReduxStudentcheckProgressCourseAction>, IReduxStudentTrainingeState, undefined, IReduxStudentcheckProgressCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxStudentcheckProgressCourseAction>) => {
    let status = "UNCOMPLETED";
    let follow: any = undefined;
    let verifDone: any = undefined;
    verifDone = await axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "progress/checkProgress/" + followContent?.id, null).then(response => {return response.data }).catch(err => { return false });
    if (doCkech && training?.diploma!=null ) {
      if (followContent?.certificationLink === null || followContent?.certificationLink === "") {
        if (verifDone) {
          status = "PENDING";
          dispatch({ type: EReduxActionTypes.CHECK_PROGRESS_COURSE, data: { status: status } });
          follow = await axiosInstance.post(process.env.REACT_APP_BASE_URL14 + "progress/getCertificate/" + followContent.id + '/' + idStudent + '/' + idTraining, null).then(response => {
            return response.data;
          }).catch(err => { return [] });
          status = "CERTIFIED";
        }
      } else {
        status = "COMPLETED";
      }
    } else {
      if (verifDone) {
        status = "COMPLETED";
      }
    }
    return dispatch({
      type: EReduxActionTypes.CHECK_PROGRESS_COURSE,
      data: { status: status, follow: follow }
    });
  };
}
export function calculateTraining(currentCourse: any, currentTraining: any, listFollows: any, currentProgress: any): ThunkAction<Promise<IReduxCalculateTrainingAction>, IReduxStudentTrainingeState, undefined, IReduxCalculateTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxCalculateTrainingAction>) => {
    
    let ref = listFollows?.find((obj: any) => obj.id === currentCourse?.followUp?.id)?.ref;
    currentCourse.followUp.ref = ref;
    currentCourse.followUp.progressTraining = currentProgress;
    set(currentTraining, ref, currentCourse?.followUp);
    const resultTraining = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}progress/calculateTraining`, currentTraining).then((response: any) => { return response.data }).catch(err => { return null })
    return dispatch({
      type: EReduxActionTypes.CALCULATE_TRAINING,
      data: resultTraining
    });
  };
}
export function calculateProgressTraining(follow: any): ThunkAction<Promise<IReduxCalculateTrainingAction>, IReduxStudentTrainingeState, undefined, IReduxCalculateTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxStudentTrainingeState, undefined, IReduxCalculateTrainingAction>) => {
    const resultTraining = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}progress/calculateTraining`, follow).then((response: any) => { return response.data }).catch(err => { return null })
    return dispatch({
      type: EReduxActionTypes.CALCULATE_TRAINING,
      data: resultTraining
    });
  };
}
