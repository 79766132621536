import { FC, useEffect, useState} from "react";
import { connect } from "react-redux";
import { getCatalog } from "../../global-state/actions/catalog-actions";
import { getStudent } from '../../global-state/actions/profile-actions';
import { getRegistrationForm, getApplicationFile, getFollow, getStudentInfoById } from '../../global-state/actions/course-actions';
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import TableElement from '../../elements/table/normal-table-element';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import "../../translations/i18n";
import { returnStartPathUrl } from "../../helpers/domainCheck";
const moment= require('moment');
interface Props {

  listCandidates: any
  goToregistration: any,
  goToCourse: any,
  downloadFile: any
}
const SubscriptionApplicationComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const { t } = useTranslation();
  const [data, setData]= useState<any>(null);
  const [listCandidates, setListCandidates] = useState<any>([])
  const history = useHistory();
  const dateFormat = require('dateformat');
  const iconCourse = <svg className="me-2" width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
      <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
  </g>
  <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
  <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
  <defs>
      <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
      </clipPath>
  </defs>
</svg>;
const iconPath = <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
      fill="#FA83EE" />
</svg>
const iconSession = <svg className="me-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
      <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
  </g>
  <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
  <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
  <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
  <defs>
      <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
      </clipPath>
  </defs>
</svg>

  const tabHeaders = [
    { title: t('courseTable'), sorted: true, classNames: "w-34", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('Professeur_1'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('Demande'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 pe-3 ms-auto" },
]
  useEffect(() => {
    setListCandidates(props?.listCandidates)
  }, []);
  const getTrainingIcon=(typeInput: string )=>{
    switch(typeInput){
      case 'COURSE':
        return iconCourse;
      case 'PATH':
        return iconPath;
      default:
        return iconSession;
    }
  
  }
  useEffect(()=>{
    let list={ 
        headers:tabHeaders,
        body: listCandidates?.map((follow: any, index: number ) => {
          const statusCandidate= follow?.followUp?.statusList?.find((el:any)=>{return el.status==="CANDIDATE"})
          const statusFinal= follow?.followUp?.statusList?.find((el: any) => new Date(el?.creationDate).getTime() === Math.max(...follow?.followUp?.statusList?.map((e: any) => new Date(e.creationDate))))
          const dateSent = Math.ceil((new Date().getTime() - new Date(statusCandidate?.creationDate).getTime()) / (1000 * 3600 * 24));
            return {
                row: {
                    student: follow,
                    actionName: "goToPath",
                    classNames: "table-white relative",
                    columns: [
                        { content: <div className="d-flex align-items-center">
                        {getTrainingIcon(follow?.type)}
                        <span className="ms-2">{follow?.title}</span></div>, classNames: "", actionName: '' },
                        {content: follow?.personName, classNames: "text-center", actionName: ''},
                        {
                          content: <span>{dateFormat(statusCandidate?.creationDate, "dd/mm/yyyy ")} {(statusFinal?.status === 'CANDIDATE') ?
                              <span className={`badge-text ${(dateSent < 3) ? 'secondary' : (dateSent >= 3 && dateSent < 6) ? 'warning' : 'danger'}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                              :
                              <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                          }
                          </span>,
                          classNames: "text-center",
                          actionName: ''
                      },
                            {
                              content: <button style={{ position: 'absolute', top: '48%' }} className={`btn-Document ${(statusFinal?.status=== 'CANDIDATE') ? '' :(statusFinal?.status=== 'UNPAID') ?'accepted':'refuse'}`}>
                                  <span className="material-icons-outlined" >description</span>
                              </button>,
                              classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                              actionName: 'goToRegistration'
                          },
                          {
                            content: (statusFinal?.status === "CANDIDATE") ? t('candidat') :(statusFinal?.status === "UNPAID") ?t('waitingToPayment'): t('Refused'),
                            classNames: `text-end badge-text  ${(statusFinal?.status=== "CANDIDATE") ? 'primary' :(statusFinal?.status=== "UNPAID")?'warning':'danger'}`,
                            actionName: ''
                        },
                    ]
                }
            }
        })
    }
    setData(list)
}, [props?.listCandidates,listCandidates ])
const doAction =(value:any)=>{

  if (value.action === 'goToRegistration') {
    goToregistration(value.row.student);
}

}

const goToregistration = async (Student: any) => {
  props?.clearHistoryArray()
  await props?.getStudentInfoById(Student?.followUp?.studentId,props?.schoolInfo?.id);
  await props?.getApplicationFile(Student?.followUp?.studentId,Student.idRegistrationForm);
  await props?.getRegistrationForm(Student.idRegistrationForm);
  await props?.getFollow(Student?.followUp?.id);
  await props?.getStudent(Student);
  props?.redirect(t('Etudiant_1'), `${returnStartPathUrl()}/students/all`)
  props?.redirect(props?.studentInfo?.firstName + " " + props?.studentInfo?.lastName, `${returnStartPathUrl()}/students/profile/student/${Student?.followUp?.studentId}`)
  props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/students/registration/${Student?.followUp?.id}/${Student?.followUp?.studentId}/${Student.idRegistrationForm}`)
  history.push({ pathname: `${returnStartPathUrl()}/students/registration/${Student?.followUp?.id}/${Student?.followUp?.studentId}/${Student.idRegistrationForm}`, state: { data: Student} })
}
  return (
    <TableElement data={data} doAction={(value: any) => { doAction(value) }} />

    
  )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
  history: ownProps?.history,
  actualStudent: state.detailsProfileReducer.actualStudent,
  schoolInfo:state.schoolInfoReducer.schoolInfo,
  studentInfo: state.courseReducer.studentInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCatalog,
      getStudentInfoById,getApplicationFile,getRegistrationForm,getFollow,getStudent,
      redirect, clearHistoryArray
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionApplicationComponent);