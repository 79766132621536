import { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from "../../global-state/store/root-reducers";
import { closeModal, openModal } from "../../global-state/actions/modal-actions";
import { createCoupon } from "../../global-state/actions/coupon-actions";
import { getSchoolInfo } from "../../global-state/actions/app-container-actions";
import { ModalIDS } from "../../components/modals/modal-ids";



const RowExpandElementStudentTable: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

  const [open, setOpen] = useState([true]);
  const [index, setIndex] = useState(-1);
  const { t } = useTranslation();
  let data = useMemo(() => props?.data, [props?.data])
  let loading = useMemo(() => props?.loading, [props?.loading])
  let trainingOffersList = useMemo(() => props?.trainingOffersList, [props?.trainingOffersList])
  let student = useMemo(() => props?.student, [props?.student])
  const [trainingOffer, setTrainingOffer] = useState<any>()
  const [nextPayment, setNextPayment] = useState<any>()
  const dateFormat = require('dateformat');

  const getTrainingOffer = (studentFollow: any, trainingOffersList: any) => {
    let trainingId: number

    if (studentFollow?.courseId != null && studentFollow?.sessionId === null && studentFollow?.pathParentId === null && studentFollow?.pathChildId === null) {
      trainingId = studentFollow?.courseId
    } else if (studentFollow?.courseId === null && studentFollow?.sessionId != null && studentFollow?.pathParentId === null && studentFollow?.pathChildId === null) {
      trainingId = studentFollow?.sessionId
    } else {
      trainingId = studentFollow?.pathParentId
    }

    let trainingOffer: any = trainingOffersList.filter((offer: any) => offer?.idTraining === trainingId)
    
    setTrainingOffer(trainingOffer[0])

  }

  useEffect(() => {
    if (typeof student != undefined && typeof trainingOffersList != undefined) {
      getTrainingOffer(student?.followUp, trainingOffersList)
    }
  }, [student, trainingOffersList])

  useEffect(() => {
    for (let index = 0; index < data?.body?.length; index++) {
      open[index] = true;
    }
  }, [props]);


  const openModalInfo =()=>{
    props?.openModal({ idModal: ModalIDS.PAYMENT_WARNING, size: "md" });
  }

  useEffect(() => {
    if(trainingOffer!=undefined && trainingOffer!=null){
      nextPaymentDate(trainingOffer)
    }
    
  }, [trainingOffer])

  const nextPaymentDate = (trainingOffer: any) => {
    if (trainingOffer != undefined && trainingOffer != null) {
      const date = new Date(trainingOffer?.pricings[0]?.startDate);
      const month = date.getMonth() + 1;
      let dateNow = new Date()
      let currentMonth = dateNow.getMonth() + 1
      if (month + trainingOffer?.pricings[0]?.nbPeriod > currentMonth) {
        const newDay: number = date.getDay();
        setNextPayment(new Date(dateNow.getFullYear(), dateNow.getMonth(), newDay).toISOString());
      }
    }
  }



  
  




  return (
    <tr className="table-white shadow-table" style={{ height: '75px' }}>

      {!open[index] ? <>

        </> :
          <>
          </>
      }
      <>
        {
          !open[index] &&
          <>
            <td className="table-white shadow-table" colSpan={9} style={{ height: '75px' }}>
              <tr >

                {(data != undefined && data[6]?.content === "Par facilité") && <div className="d-flex flex-row align-item-center" style={{ paddingLeft: "306px" }} >
                  <div className="d-flex flex-row align-item-center justify-content-center" >
                    <span className="neutral-3  button-trie" >Montant </span>
                    <span className="ms-3">{trainingOffer?.pricings[0]?.price} €</span>
                  </div>

                  <div className="d-flex flex-row align-item-center justify-content-center" style={{ paddingLeft: "40px" }}>
                    <span className="neutral-3  button-trie">Prochain paiement </span>
                    <span className="ms-3">{ dateFormat(nextPayment, "dd/mm/yyyy")}</span>
                  </div>

                  <div style={{ paddingLeft: "55px", paddingRight: "55px" }}>
                    <button type="button" className="btn-Primary small icon-left" onClick={()=>openModalInfo()}>
                      <span className="material-icons-outlined">
                        add
                      </span>
                      <div className="m-auto ps-2">Arrêter le prélèvement</div>
                    </button>
                  </div>
                </div>}

                {(data != undefined && data[6]?.content === "Unique") && <div className="d-flex flex-row align-item-center" style={{ paddingLeft: "840px" }} >
                  <div className="d-flex flex-row align-item-center justify-content-center" >
                    <span className="neutral-3  button-trie" style={{ paddingRight: "10px" }}>Montant</span>
                    <span className="" style={{ paddingRight: "10px" }}>{trainingOffer?.pricings[0]?.price} €</span>
                    <span style={{ color: "#9DDBA9" }}>PAYÉ</span>
                  </div>
                </div>}

                {(data != undefined && data[6]?.content === "Abonnement") && <div className="d-flex flex-row align-item-center" style={{ paddingLeft: "636px" }} >
                  <div className="d-flex flex-row align-item-center justify-content-center" >
                    <span className="neutral-3 me-3 button-trie" >Montant</span>
                    <span className="">{trainingOffer?.pricings[0]?.price}€/ {t(trainingOffer?.pricings[0]?.period)}</span>
                  </div>

                  <div className="d-flex flex-row align-item-center" style={{ paddingLeft: "40px" }}>
                    <span className=" button-trie" style={{ color: "#F5B95F" }}>Prochain paiement</span>
                    <span className="ms-3">{nextPayment}</span>
                  </div>
                </div>}
              </tr>
            </td>
          </>

        }
      </>
    </tr>

  )
}

const mapStateToProps = (state: AppState) => ({
  content: state.modalReducer.modalContent,
  connectionUser: state.authReducer.connectedUser,
  profs: state.profReducer.profList,
  paymentMethod: state.schoolInfoReducer.paymentMethod,
  schoolInfo: state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      createCoupon,
      getSchoolInfo

    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(RowExpandElementStudentTable);