import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal} from '../../../global-state/actions/modal-actions';
import SelectElemenWithOptGroup from "../../../elements/select/select-element-with-optgroup";
import { ModalIDS } from '../modal-ids';
import {createPost} from "../../../global-state/actions/chat-actions";
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';

const AddQuestionPathModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [thematicOption, setThematicOption]= useState<any[]>([]);
    const [thematic, setThematic]= useState("none");
    const[title, setTitle]=useState("");
    const[comment, setComment]=useState("");

    useEffect(() => {
        let listFilter = [{ value: "all", label: t('allSubjects_2') },{ value: `${JSON.stringify({ id: props?.pathInfo.id, typeCategory: props?.pathInfo.type })}`, label: t('pathTitle')+" :  " + props?.pathInfo.title },
         props?.pathInfo?.subjectsByPath.map((obj: any) => {
            if (obj.course) {
                return [{ value: `${JSON.stringify({ id: obj.course.id, typeCategory: obj.course.type })}`, label: "    "+t('Cours')+" :  " + obj.course.title },
                obj.course.orders.map((chap: any) => {
                    return { value: `${JSON.stringify({ id: chap.chapter.id, typeCategory: 'CHAPTER' })}`, label: "       "+t('chapitre')+" : " + chap.chapter.title }
                })
                ]
            }

            if (obj.path) {
                return [{ value: `${JSON.stringify({ id: obj.path.id, typeCategory: obj.path.type })}`, label: "    "+t('pathTitle')+" :" + obj.path.title },
                obj.path.subjectsByPath.map((chap: any) => {
                    if (chap.course) {
                        return [{ value: `${JSON.stringify({ id: chap.course.id, typeCategory: chap.course.type })}`, label: "       "+t('Cours')+" : " + chap.course.title },
                        chap.course.orders.map((chap1: any) => {
                            return { value: `${JSON.stringify({ id: chap1.chapter.id, typeCategory: 'CHAPTER' })}`, label: "          "+t('chapitre')+" : "+ chap1.chapter.title }
                        })
                        ]
                    }
                })
                ]
            }

        })].flat(Infinity)
        setThematicOption(listFilter)
    }, [])
    const changeThematic= (thematicInput:string)=>{
        setThematic(thematicInput);
    }
    const changeInputValue=(value:string )=>{
        setComment(value)
    }
    const addPost = async() => {
        let thematicObj = JSON.parse(thematic);
        const exist = props?.listPosts?.find((obj: any) => (obj.subject.idSubject === thematicObj.id && obj.subject.category === thematicObj.typeCategory && obj.subject.type === "FORUM"));
        let object = {
            subjectId: (exist) ? exist.id : null,
            idAuthor: props?.connectedUser?.idPerson,
            title:title ,
            type: "POST",
            comment: {
                description: comment,
                type: "TEXT"
            },
           
        }
        await props?.createPost(object, { idSubject: thematicObj.id, category: thematicObj.typeCategory, type: "QUESTION" },props?.connectedUser?.idSchool);
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'ok', lines: [t('addQuestionSuccess')]} );
    }

    return(
        <>
        
                <div className="modal-header" style={{paddingBottom: 0 , borderBottom: "none"}} >
                    <div className="w-100 d-flex align-items-center justify-content-end p-3">
                         <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>
                </div>

                    <div  className="modal-body mb-3" style={{ paddingTop: 0, zIndex:1000, overflow:'hidden' }}>
                        <div className="row">
                            <div className="col-md-10 center" style={{ margin: 'auto' }}>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <h3 className="heading-h3-noirstd">{t('addQuestionForChapter')}</h3>
                                    </div>
                                    <div className="col-md-12 mb-1 mt-2">
                                        <p className="body-md bold">{t('thematic')}</p>
                                    </div>
                                    <div className="col-md-12  mb-4" style={{position: "relative"}}>
                                         <SelectElemenWithOptGroup  taille={'lg'} defaultValue={thematic} options={thematicOption}  placeholder ={t('thematic')} onChange={(e: string) => changeThematic(e)}  /> 
                                    </div>
                                    <div className="col-md-12 mb-1 mt-2">
                                        <p className="body-md bold">{t('yourComment')}</p>
                                    </div>
                                    <div className="col-md-12  mb-1 ">
                                        <TextareaUnderlineElement id="commentId"  value={comment} onChange={ changeInputValue} placeholder={t('votreCommentaie')} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{paddingBottom: 0 , borderTop: "none"}}>
                        <div className="row mt-3 mb-5">
                            <div className='col-md-9 d-flex  justify-content-end' >
                                <button className="btn-Secondary large me-3" onClick={() => {props?.closeModal(); setTitle(""); setComment(""); setThematic("")}}>{t('annuler')}</button>
                                <button type="button" className="btn-Primary large" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { addPost() }} disabled={!(comment !== '' && thematic !== '')}>{t('publishMyReview')}</button>
                            </div>
                        </div>
                    </div>
                
    </>
    )
}
const mapStateToProps = (state: AppState) => ({
    pathInfo: state.courseReducer.pathInfo,
    connectedUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      createPost
    },
    dispatch
  );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddQuestionPathModalComponent);