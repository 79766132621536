import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import ModalShareSondage from "../../components/modals/modal-forms/modal-share-survey-modal-component";
import { ModalIDS } from "../../components/modals/modal-ids";
import { openModal } from '../../global-state/actions/modal-actions';
import { closeModal } from '../../global-state/actions/modal-actions';
import { useHistory, useParams } from "react-router";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { deleteSurvey } from "../../global-state/actions/sondage-actions";

const BredCrumbSondage: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ title, survey, openModal ,deleteSurvey}: any) => {
  const [modalShareSondage, setModalShareSondage] = useState(false);
  const history = useHistory();

  const showModal = () => {
    openModal({ idModal: ModalIDS.SHARE_SONDAGE, size: "md" });
  };

  const goBack = () => {
    history.push(`${returnStartPathUrl()}/survey`)

  }

  const deleteSurveyForm =()=>{
    deleteSurvey(survey?.id)
    history.push(`${returnStartPathUrl()}/survey`)
  }

  return (
    <div
      className="container-bredcrumb-sondage cours-nav w-100"
      style={{ zIndex: 1000, position: "fixed", top: 0 }}
    >
      <div className="navbar navbar-expand-lg box-shadow-navbar-bredcrumb d-flex justify-content-between p-0 flex-nowrap">
        <div className="d-flex order-0">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => goBack()}
            className="d-flex justify-content-center align-items-center nav-back"
          >
            <span className="material-icons-round">chevron_left</span>
          </a>
          <div className="d-flex px-4 align-items-center">
            <div className="d-flex flex-row align-items-center title-bredcrumb-sondage">
              {title}
            </div>
          </div>
        </div>
        <div className="d-flex order-0 bredcrumb-sondage-right">
          <span
            style={{ color: "#5693EE" }}
            className="draft-bredcrumb-sondage"
          >
            Brouillon
          </span>
          <button
            type="button"
            className="btn-Primary large btn-delete-bredcrumb-sondage"
            onClick={()=>deleteSurveyForm()}
          >
            supprimer
          </button>
          <button
            type="button"
            className="btn-disabled-share-bredcrumb-sondage"
            disabled={survey?.questionByQnaList?.length===0}
            onClick={() => showModal()}
          >
            PARTAGER
          </button>
        </div>
      </div>
      {modalShareSondage && <ModalShareSondage closeModal={closeModal} />}
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  countOfSondage: ownProps.title,
  // survey: state.sondageReducer.serveys,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    openModal,
    deleteSurvey
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BredCrumbSondage);
