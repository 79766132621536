import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxProgressState } from '../reducers/progress-reducer';

export enum EReduxActionTypes {
    GET_PROGRESS = 'GET_PROGRESS',
    GET_COURSE_PROGRESS = 'GET_COURSE_PROGRESS',
    GET_COURSE_PROGRESS_DETAILS = 'GET_COURSE_PROGRESS_DETAILS',
    GET_CONTENT='GET_CONTENT'
}

interface IReduxBaseAction {
    type: EReduxActionTypes;
}

export interface IReduxGetProgressAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_PROGRESS;
    data:any;
}

export interface IReduxGetCourseAction extends IReduxBaseAction {
    type: EReduxActionTypes.GET_COURSE_PROGRESS;
    data:any;
}

export interface IReduxGetCourseProgressDetailsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE_PROGRESS_DETAILS;
  data:any;
}

export interface IReduxGetContentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CONTENT;
  data:any;
}

export function getStudentProgress(aaa:string): ThunkAction<Promise<IReduxGetProgressAction>, IReduxProgressState, undefined,IReduxGetProgressAction> {
    return async (dispatch: ThunkDispatch<IReduxProgressState, undefined, IReduxGetProgressAction>) => {
  
      return dispatch({
        type: EReduxActionTypes.GET_PROGRESS,
        data:aaa
      })
    }
}

export function getCourseProgress(idCourse:number): ThunkAction<Promise<IReduxGetCourseAction>, IReduxProgressState, undefined,IReduxGetCourseAction> {
    return async (dispatch: ThunkDispatch<IReduxProgressState, undefined, IReduxGetCourseAction>) => {
      return dispatch({
        type: EReduxActionTypes.GET_COURSE_PROGRESS,
        data:idCourse
      })
    }
}