import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_EN } from "./en/Koors-en";
import { TRANSLATIONS_FR } from "./fr/Koors-fr";
import { TRANSLATIONS_NL } from "./nl/Koors-nl";
import { TRANSLATIONS_IT } from "./it/Koors-it";
import { TRANSLATIONS_ES } from "./es/Koors-es";
import { TRANSLATIONS_DE } from "./de/Koors-de";
import { TRANSLATIONS_SV } from "./sv/Koors-sv";
import { TRANSLATIONS_AR } from "./ar/Koors-ar";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     ar: {
      translation: TRANSLATIONS_AR
    },
     fr: {
       translation: TRANSLATIONS_FR
     },
     es: {
       translation: TRANSLATIONS_ES
     },
     de: {
       translation: TRANSLATIONS_DE
     },
     it: {
       translation: TRANSLATIONS_IT
     },
     sv: {
       translation: TRANSLATIONS_SV
     },
     nl: {
       translation: TRANSLATIONS_NL
     }
   },

 });

i18n.changeLanguage(localStorage.getItem("language") || "fr");

export default i18n;