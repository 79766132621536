
import { ComponentProps, FC,useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import {setActualTabManageChat, getSubjectCommunity} from '../../../global-state/actions/chat-actions';
import FilterCommunityPathComponent from '../../../components/filters/filter-community-path-component';
interface Props{
    Searching:any
}
const ManageCommunityPathComponent :FC <ReturnType<typeof mapStateToProps> & ReturnType<typeof  mapDispatchToProps> &  ComponentProps<any>>= (props:any)=>{
    const { t } = useTranslation();
    const [actualTab, setTab]= useState(props?.tabChatManager)
    const [searching, setSearching]= useState(false);
    
    const getSubjec=(typeInput:string)=>{
        return [props?.pathInfo.id+" PATH "+typeInput ,
        props?.pathInfo?.subjectsByPath.map((obj: any) => {
           if (obj.course) {
               return [obj.course.id+" COURSE "+typeInput,
               obj.course.orders.map((chap: any) => {
                   return chap.chapter.id+" CHAPTER "+typeInput
               })
               ]
           }
           if (obj.path) {
               return [obj.path.id+" PATH "+typeInput,
               obj.path.subjectsByPath.map((chap: any) => {
                   if (chap.course) {
                       return [chap.course.id+" COURSE "+typeInput,
                       chap.course.orders.map((chap1: any) => {
                           return chap1.chapter.id+" CHAPTER "+typeInput
                       })
                       ]
                   }
               })
               ]
           }

       })].flat(Infinity)

    }
  
    const setActualTab = (tab: string) => {
        props?.setActualTabManageChat(tab); 
        if ((tab === "discussion") && (actualTab !== tab)) {
            let object = {
                subject: getSubjec("FORUM"),
                trie: "recent",
                searchFilter: "",
                page: 0,
                refresh:false,
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }
        } else if ((tab === "question") && (actualTab !== tab)) {
            let object = {
                subject: getSubjec("QUESTION"),
                trie: "recent",
                searchFilter: "",
                page: 0,
                refresh:false,
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }
        }
    }
    const isSearching =(doSearch:any)=>{
        props?.Searching(doSearch)
        setSearching(true)
    }
    return(
        <>
        <div >
            <div className="row justify-content-center m-0">
                <div style={{backgroundColor: "#FFFFFF"}} className="col-md-12">
                    <div style={{maxWidth:'1200px', margin : 'auto'}}  className="row justify-content-center">
                        <div className=" d-flex flex-column align-items-center mt-4 pt-2 px-0">
                            <span className="H2-Headline black-800">{props?.pathInfo?.title}</span>
                            <div className="d-flex mt-4 mb-4">
                            <button className={(actualTab === 'discussion') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} onClick={() => {setActualTab('discussion'); setTab('discussion') }}>{t('discussionButton')}</button>
                            <button className={(actualTab === 'question') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} onClick={() => { setActualTab('question'); setTab('question' ) }}> {t('questionButton')}</button>
                            </div>
                        </div>
                    </div>
                </div>
               { (props?.listPosts.length!==0 || (props?.listPosts.length===0 && searching) ) && <div style={{maxWidth:'1200px', margin : 'auto'}} className="col-md-12">
                    <div className="justify-content-between">
                        <FilterCommunityPathComponent isSearching={isSearching}/>
                    </div>
                </div>}
            </div> 
        </div>
    </>
 )
}
const mapStateToProps=(state: AppState)=>({
    tabChatManager: state.chatReducer.tabChatManager,
    connectedUser: state.authReducer.connectedUser,
    listPosts:state.chatReducer.listPosts,
    pathInfo: state.studentTraining.currentTraining

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => 
bindActionCreators({ 
    setActualTabManageChat, getSubjectCommunity
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageCommunityPathComponent);

