import { useTranslation } from "react-i18next";
import { useState } from "react";
import Dropzone from "react-dropzone";

export default function StudentMultiInvitationBeforeUploadElement(props: any) {
    const { t } = useTranslation();
    const [warningMsg, setWarningMsg]= useState("")
    const onDrop = (accepted: any, rejected: any) => {
        
        if (Object.keys(rejected).length !== 0) {
            const message = "Vous devez ajouter votre fichier Excel";
            setWarningMsg(message)
            
        } else {
           props?.addFile(accepted);
           setWarningMsg("")


            let blobPromise = new Promise((resolve, reject) => {
                const reader = new window.FileReader();
                reader.readAsDataURL(accepted[0]);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
            blobPromise.then(value => {
                console.info(value);
                
            })
        }
    };



    const render = <>
     <div className="bg-with-border grey-background dashed p-4">
            <div className="d-flex m-2 align-items-center justify-content-between">
                <div className="d-flex align-items-center me-2">
                    <div className="me-3 d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                        <span className="material-icons-outlined neutral-4"> cloud_upload </span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="form-default"> Glissez-déposez votre fichier Excel <br></br> (XLS, XLSX)</span>
                    </div>
                </div>
                <button className="btn-Secondary large" disabled={props?.isDisabled!==undefined?props?.isDisabled:false}>{t('uploadFile')}</button>
            </div>
        </div>
        { (props?.clicked!=undefined && props?.clicked===false ) &&<div className="mt-1">
            <label className="error-input mt-1">Vous devez ajouter votre fichier Excel.</label>
        </div>} 
    </>
    return (
        <div>
                <Dropzone
                    style={{ width: '100%' }}
                    multiple={true}
                    accept= {!!props?.text && props?.text =="Glissez-déposez votre fichier Excel (XLS, XLSX)" ? ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  :"application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                    onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                    disabled={props?.isDisabled!==undefined?props?.isDisabled:false}
                >
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        return render;
                    }}
                </Dropzone>
                <div className="mt-1">
                        <label className="error-input mt-1">{warningMsg}</label>
                    </div> 
            </div>

    )
}