
import { FC } from "react"; 
import ContentTestExamFormComponent from "../../../../components/formation-session-form/content-test-exam-form-component";
import HeaderTestExamFormComponent from "../../../../components/formation-session-form/header-test-exam-component"

const SessionExamPage: FC<any> = (props: any) => { 
  return (<>  
  <HeaderTestExamFormComponent/>
  <ContentTestExamFormComponent/>

  </>
  )
}
export default SessionExamPage;