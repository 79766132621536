import { ComponentProps, FC, useEffect, useMemo } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearChapter } from '../../../global-state/actions/course-actions';
import HeaderChapterExamPreviewComponent from "../../../components/preview-course-components/chapter-exam/header-chapter-exam-component";
import ChapterPreviewComponent from "../../../components/preview-course-components/chapter-exam/chapter-preview-component";
import ExamPreviewComponent from "../../../components/preview-course-components/chapter-exam/exam-preview-component";
import { clearHistory } from "../../../global-state/actions/breadcrumb-actions";
const ExamChapterPreviewPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const typeContent = useMemo(() => { return props?.typeContent }, [props?.typeContent]);
    useEffect(() => {
        return () => {
            props?.clearHistory();
        }
    }, [])
    return (<>
        <HeaderChapterExamPreviewComponent />
        <div style={{maxWidth: 1200, margin: 'auto'}}>
        {typeContent === 'CHAPTER' ?
            <ChapterPreviewComponent />
            :<ExamPreviewComponent />
        }
        </div>
    </>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ clearChapter, clearHistory }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseInfo,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    indexChapter: state.courseReducer.indexChapter,
    typeContent: state.courseReducer.typeContentCourse,
});
export default connect(mapStateToProps, mapDispatchToProps)(ExamChapterPreviewPage);