import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import 'reactjs-popup/dist/index.css';
import { getCatalog } from "../../../global-state/actions/catalog-duplicaction-actions";
import { useParams } from "react-router";
import { fetchData, getDate, getDuration } from '../../../base/functions/Functions';
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
interface Params {
  id: any
}

interface Props {
  organizerNames: any

}

const Record: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
  const params = useParams<Params>();
  const [webinar, setWebinar] = useState<any>();
  const { t } = useTranslation();

  const getWebinarById = async(id: Number) =>{
    const baseUrl = process.env.REACT_APP_BASE_URL9;
    let webinar = await fetchData("GET",baseUrl + `webinars/` + id, {}).then(res => { return res }).catch(err => console.error(err));
    setWebinar(webinar)
  } 

  useEffect(() => {
    getWebinarById(Number(params?.id))
  }, [params?.id])

  return (<>

    <div className="preview-course row justify-content-center">

      <div className=" py-5" style={{ padding: 0, margin: 0, backgroundColor: "white", marginTop: 64 }}>
        <div className="my-2 d-flex flex-column m-auto px-4" style={{ maxWidth: '1248px' }}>

          <div className="d-flex mb-3">
            <span className="H2-Headline ">{webinar?.title}</span>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <div className="d-flex flex-row me-3">
              <span className="body-sm bold neutral-3 me-2">{t('WebinarGivenBy')}</span>
              <span className="body-sm bold black-800">{webinar?.organiserName}</span>
            </div>
            <div className="d-flex flex-row">
              <span className="body-sm bold neutral-3 me-2">{t('ForStudentsOf')}</span>
              <span className="body-sm bold black-800">{webinar?.courseTitle}</span>
            </div>
          </div>




          <span className="resume-preview-course my-4 py-2" style={{ color: "#5A5A5A", fontSize: 16 }}>
            {webinar?.description}
          </span>

          <div className="d-flex">
            <span className="material-icons-outlined icon-grey pe-1">
              calendar_today
            </span>
            <span className="body-md bold details me-3">
              {getDate(webinar?.startDate)}
            </span>
                &nbsp;&nbsp;
                                <span className="material-icons-outlined icon-grey pe-1">
              schedule
                </span>
            <span className="body-md bold details">
              {getDuration(webinar?.duration)}

            </span>
          </div>
        </div>
      </div>
      <div className=" d-flex flex-row header-title-course w-auto my-5 py-2">
        <section>
          <div className="show-video-upload " style={{ width: 1200, height: 502 }}>
            <iframe src={webinar.recordingLink + '?portrait=false&byline=false&title=false'} allowFullScreen={true} width="100%" height="100%"></iframe>
          </div></section>
      </div>

    </div>




  </>

  )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  courses: state.catalogReducer.courses,
  user: state.authReducer.connectedUser,
  school: state.schoolInfoReducer.schoolInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCatalog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Record);