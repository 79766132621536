import { FC } from "react";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import FooterComponent from "../../components/footer-component";
import accountPage from "./account-page";
import CustomSwitch from "../../helpers/custom-switch";
const AccountContainer: FC<any> = (props: any) => {
    return (<>
        <div className="page-wrapper-height">
            <CustomSwitch>
                <RouteWithNavbar exact={true} history={props?.history} path={`${props?.match?.path}/info`} component={accountPage} roles={["role_director", "role_prof", "role_student", "role_adminstrator"]} />
            </CustomSwitch>
        </div>
        <FooterComponent />
    </>
    )
}
export default AccountContainer;