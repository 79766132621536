import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SkillsSectionComponentLoading: FC<ComponentProps<any>> = () => {

  return (
    <section className={`btnTestColor mt-5 py-4`} style={{background: "#212529"}} >
      <div className={`d-flex flex-column max-width `} style={{ boxShadow: "inset 0px 0px 0px #DFDFDF", margin: 'auto' }}>
        <div className={"mx-3 mx-sm-0"}>
        <Skeleton variant="rect" animation="wave" width={'85%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
          <div className="d-flex align-items-center flex-wrap mt-3" style={{ maxWidth: "60%" }}>
            <Skeleton variant="rect" animation="wave" width={'20%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
            <Skeleton variant="rect" animation="wave" width={'20%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
            <Skeleton variant="rect" animation="wave" width={'20%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
            <Skeleton variant="rect" animation="wave" width={'20%'} height={32} className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities mt-1" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SkillsSectionComponentLoading;