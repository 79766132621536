import { FC, useEffect, useState } from "react";

import { Bar } from 'react-chartjs-2';
import { connect } from "react-redux";
import { getCatalog } from "../../../global-state/actions/catalog-duplicaction-actions";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { getLevelStudent } from  "../../../global-state/actions/activity-actions";
import { fetchData, sortEducationLevel } from "../../../base/functions/Functions";
interface Props {
    courseList: any[]
    courseFormId:any
}

const EtudeChart: FC<any> = (props: any) => {
    const { t } = useTranslation();
    const [levelStudent, setLevelStudent] = useState({
        studentNbre: 0,
        schoolingList: [
            {
                schoolingNbr: 0,
                schoolingPercent: 0,
                schooling: "",
            }
        ]
    })

    const schoolings = sortEducationLevel(levelStudent?.schoolingList?.map((b: any) => b?.schooling))
    const schoolingNbr = levelStudent?.schoolingList?.map((b: any) => b?.schoolingNbr)
    levelStudent?.schoolingList?.map((b: any) => b?.schoolingPercent)
    const data = {
        labels: (schoolings),
        datasets: [
            {
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                label: t('LevelOfStudy'),
                data: schoolingNbr,
                backgroundColor: "#8A57CC",
                borderColor: "#8A57CC",
                borderWidth: 1,
                yAxisID: "leftAxe"
            }
        ]
    }

    const fetchChartData=async(role:string, idUser:number , trainingIds: number[])=>{
        const payload = {
            "role": role ==="role_director" ? "director":"prof",
            "idUser": idUser,
            "trainingIds":trainingIds
        }
        const data = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}kpi/getStudentsEducationalLevelsByFilter`,payload)
        await setLevelStudent(data)
    }

    useEffect(() => {
        fetchChartData(props?.user.role , props?.user?.idPerson,props?.courseList)
    }, [])
    

    return (
        <div style={{pointerEvents:"none"}}>
        <Bar options={{
            legend: {
                display: true,
                labels: {
                    fontColor: '#333',
                    usePointStyle: true,
                }
            },
            elements: {
                line: {
                    tension: 0,
                    fill: false
                }
            }, scales: {
                xAxes: [{
                    offset: true,
                    gridLines: {
                        display: true,
                        drawOnChartArea: false
                    },
                }],
                yAxes: [{
                    id: "leftAxe",
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 1,
                    },
                    offset: false,
                    scaleLabel: {
                        display: true,
                        labelString: t('LevelOfStudy')
                    },
                    gridLines: {
                        display: true,
                        drawOnChartArea: false,
                        color: "#8A57CC"
                    }
                }]

            }
        }} data={data} />
        </div>
    )
}
const mapStateToProps = (state: AppState) => ({
    courses: state.catalogReducer.courses,
    user: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseForm,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getCatalog,
            getLevelStudent
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(EtudeChart);