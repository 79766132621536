import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../../global-state/store/root-reducers";
import { loginUser, clearMessage, loginWithCookies } from "../../../global-state/actions/auth-actions";
import { clearSaveReturnPath } from '../../../global-state/actions/breadcrumb-actions';
import { getMyCoursesStudent } from '../../../global-state/actions/course-actions';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import logoSchool from '../../../styles/imgs/koorsK.png'
import { getNumberOfCourses } from '../../../services/student.service';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import { getNuberUserConnexion } from '../../../global-state/actions/app-container-actions';
const LoginComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [showP, setShowP] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const [firstStep, setFirstStep] = useState(true);
    const [cookieValue, setcookieValue] = useState<any>()
    const [code, setCode] = useState<string>("")
    let regex = "/(?:(?:^|.*;\s*)" + code + "Token" + "\s*\=\s*([^;]*).*$)|^.*$/"
    useEffect(() => {
        if (typeof window !== "undefined") {
            getCode()
        }
    }, [])
    useEffect(() => {
        if (code != "" && code?.length != 0) {
            setcookieValue(findCookie(code + "Token"))
        }
    }, [code])
    const findCookie = (cname: string) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return "";
    }

    const getCode = () => {
        let schoolCode: string = ""

        if ((window.location.href === "https://" + process.env.REACT_APP_SCHOOL_DOMAIN1) || (window.location.href === "https://" + process.env.REACT_APP_SCHOOL_DOMAIN)) {
            window.location.href = "https://" + process.env.REACT_APP_GENERIC_LOGIN
        }


        if (window.location.href.includes("http://localhost:3000/")) {
            setCode("HSI")
        } else if (window.location.href.includes(process.env.REACT_APP_SCHOOL_DOMAIN as string)) {
            schoolCode = window.location.href.split("/")[3]

        } else {
            let index = window.location.href.indexOf(process.env.REACT_APP_BASE_SUBDOMAIN as string)
            schoolCode = window.location.href.substring(8, index)
        }
        if (schoolCode?.length != 0) {
            setCode(schoolCode)
        }

    }



    useEffect(() => {
        if (typeof window !== "undefined") {
            if ((window.location.href === "https://" + process.env.REACT_APP_SCHOOL_DOMAIN1) || (window.location.href === "https://" + process.env.REACT_APP_SCHOOL_DOMAIN)) {
                window.location.href = "https://" + process.env.REACT_APP_GENERIC_LOGIN
            }
        }
        setcookieValue(document.cookie.replace(/(?:(?:^|.*;\s*)oauthToken\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        props?.clearMessage();
    }, [])


    useEffect(() => {
        setMsg(props?.error)
    }, [props?.error]);

    const loginWithCookies = async () => {
        const offer_token: any = localStorage.getItem("access_limitation");
        localStorage.clear();
        localStorage.setItem("access_limitation", offer_token);
        props?.loginWithCookies(cookieValue)
        if (typeof window !== 'undefined' && (!window?.location?.href.includes("localhost"))) {
            document.cookie = code + "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.koors.io";
        } else {
            document.cookie = code + "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ";
        }
        setcookieValue("")
    }

    const getNbr = async () => {
        let filter = {
            studentId: props?.connectedUser?.idPerson,
            entityId: props?.connectedUser?.idSchool,
            entityType: "SCHOOL",

        }
        let data = await getNumberOfCourses(filter);
        if (data == 0) {

            history.push({ pathname: `${returnStartPathUrl()}/catalog`, state: undefined })
        } else {
            history.push({ pathname: `${returnStartPathUrl()}/courses/my-courses`, state: undefined })
        }
    }
    const getConnexionsNbr = async (idPerson: number) => {
        props?.getNuberUserConnexion(idPerson)
    }

    useEffect(() => {
        if (!!cookieValue && cookieValue?.length != 0) {
            loginWithCookies()

        } else
            if (props?.connectedUser && props?.returnPathPlateform?.path !== '') {
                history.push({ pathname: props?.returnPathPlateform?.path, state: undefined });
                props?.clearSaveReturnPath();
            } else if (props?.connectedUser && props?.location?.state && props?.location?.state?.from && props?.location?.state?.from?.pathname && props?.location?.state?.from?.pathname !== "" && props?.location?.state?.from?.pathname !== `${returnStartPathUrl()}/professors/all` && props?.location?.state?.from?.pathname !== `${returnStartPathUrl()}/courses/my-courses` && props?.location?.state?.from?.pathname !== null) {
                history.push({ pathname: props?.location?.state?.from?.pathname, state: undefined })
            } else if (props?.connectedUser && localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== '') {

                if (props.connectedUser?.idPerson && typeof props.connectedUser?.idPerson === 'number') {
                    getConnexionsNbr(props?.connectedUser?.idPerson)
                }

                switch (props?.connectedUser?.role) {
                    case "role_director":
                        history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined });
                        break;
                    case "role_adminstrator": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                    case "role_prof": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                    case "role_student": getNbr();
                        break;
                }
            }

    }, [props?.connectedUser, cookieValue]);

    const signIn = () => {
        const offer_token: any = localStorage.getItem("access_limitation");
        localStorage.clear();
        localStorage.setItem("access_limitation", offer_token);
        props?.loginUser(login.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), password)
    }

    const signOn = () => {
        history.push(`${returnStartPathUrl()}/auth/register`);
    }
    const checkMsg = () => {
        if (msg && msg !== "") {
            props?.clearMessage();
        }
    }
    return (

        <>
            <div className="auth-close">

            </div>
            <div className="d-flex flex-column align-items-center w-100  auth-form mx-5">
                <div className="auth-form-logo">
                    <img style={{ height: 120 }} src={props?.logoschool?.logo === null ? logoSchool : props?.logoschool?.logo} alt="logo school" />
                </div>

                <div className="d-flex flex-column align-items-center w-100 mb-4">
                    <div className="H3-Headline black-800 mb-3">
                        {t('hello')}
                    </div>
                    <div className="mb-3 w-100" id="email">
                        <label className="form-title">{t('emailLogin')} </label>
                        <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('emailLogin')} value={login} onChange={(event) => { checkMsg(); setLogin(event.target.value.trim()) }} />
                    </div>
                    <div className="w-100" id="password">
                        <label className="form-title">{t('password')} </label>
                        <div className="input-form-control">
                            <input dir="ltr" type={showP ? "text" : "password"} className="form-control input text-default" placeholder={t('password')} value={password} onChange={(event) => { checkMsg(); setPassword(event.target.value) }} />
                            {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                            {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility</span>}
                            {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                            {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                        </div>
                    </div>
                    <div className="mt-1">
                        <label className="error-input mt-1">{msg}</label>
                    </div>
                    <div className="mt-3" id="btn-cnx">
                        <button type="button" className="btn-Primary large" disabled={msg?.length > 1} onClick={(event) => { event.preventDefault(); signIn() }}>
                            {t('cnxButton')}
                        </button>

                    </div>

                    <div className="mt-3">
                        <button className="mt-3 btn-text forgot-password" onClick={() => { history.push(`${returnStartPathUrl()}/auth/forgotpassword`); }}>{t('forgotLogin')}</button>
                    </div>

                    <div className="mt-5 d-flex  flex-column align-items-center">
                        <div className=" text-center">
                            <span className="body-md bold black-800" >{t('questionCreateAccount')}</span>

                        </div>

                        <div className="mt-3 ">
                            <button type="button" className="btn-Secondary large" onClick={() => signOn()}>
                                {t('createAccountMsg')}
                            </button>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    error: state.messageReducer.error,
    logoschool: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    location: ownProps?.location,
    connectedUser: state.authReducer.connectedUser,
    returnPathPlateform: state.historyReducer.returnPathPlateform,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            loginUser,
            clearMessage,
            clearSaveReturnPath,
            loginWithCookies,
            getMyCoursesStudent,
            getNuberUserConnexion
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent);
