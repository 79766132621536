import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { publishCourse, saveFormPaymentInfo, publishCourseWithOfferInit, clearFormPayment, updateCoursesLists, getOfferCourse, getOfferPricingCourse } from '../../../global-state/actions/course-actions';
import { ModalIDS } from '../modal-ids';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { useHistory } from 'react-router-dom';
import { saveReturnPath, RedirectPopup } from '../../../global-state/actions/breadcrumb-actions';
import { saveActionHistory } from '../../../services/history.service';
import { getDomainFromUrl, returnStartPathUrl } from '../../../helpers/domainCheck';
import SelectElement from '../../../elements/select/select-element';
import { valideTraining, publishTraining, createTraining } from '../../../global-state/actions/training-actions';
import { createCourse } from '../../../global-state/actions/course-actions';


import Axios from '../../../helpers/interceptors';
import axiosInstance from '../../../helpers/interceptors';

const TransferTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [courseInfo, setCourseInfo] = useState<any>();
    const [type, setType] = useState<any>();
    const [defaultValueProfessors, setDefaultValueProfessors] = useState("none");
    const [check,setCheck]=useState<any>(false);
    const [professors, setProfessors] = useState<any[]>([]);
    const [comment, setComment] = useState<string>("");
    const [training, setTraining] = useState<any>("");
    const [trainingSession, setTrainingSession] = useState<any>("");
    const [offerCourse, setOfferCourse] = useState<any>({
        id: null,
        idEntity: props?.connectedUser?.idSchool,
        label: props?.courseInfo?.title,
        status: "VALIDE",
        typePricing: "STATIC",

        rules: [
            {
                id: null,
                key: "id_course",
                value: props?.courseInfo?.id,
                valueType: "number",
                code_product: "CRS"
            }
        ],
        paymentEngine: props?.paymentMethod?.paymentEngine,
        pricings: [{
            id: null,
            period: "ONESHOT",
            status: "ACTIVE",
            price: (props?.formPaymentInfo) ? props?.formPaymentInfo?.price : 0,
            currency: props?.paymentMethod?.currency,
            startDate: new Date(),
            endDate: null
        }]
    })

    
    function extractVideoLinks(course: any): string[] {
        const validVideoLinks: string[] = [];
    
        const isVimeoVideo = (videoUrl: string) => {
            return videoUrl.startsWith('https://player.vimeo.com/video/');
        };
    
        const extractVimeoVideoId = (videoUrl: string) => {
            const urlParts = videoUrl.split('/');
            return urlParts[urlParts.length - 1];
        };
    
        course.orders.forEach((order: any) => {
            if (isVimeoVideo(order.chapter.video)) {
                const videoId = extractVimeoVideoId(order.chapter.video);
                validVideoLinks.push(videoId);
            }
        });
    
        if (course.video && isVimeoVideo(course.video)) {
            const videoId = extractVimeoVideoId(course.video);
            validVideoLinks.push(videoId);
        }
    
        return validVideoLinks;
    }
    

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.connectedUser?.idSchool}/profs`).then((response:any) => {
            const data = []

            props?.connectedUser?.role==="role_director" && data.push({label:props?.userInfo?.firstName+" "+props?.userInfo?.lastName +" (Moi même)",value:props?.connectedUser.idPerson,id:props?.connectedUser.idPerson})
            response?.data?.forEach((el: any) => {
                data.push({ label: el.name, value: el.id, id: el.id })
            });

            setProfessors(data);
        })
    }, [])


    useEffect(() => {
        setCourseInfo(props?.courseInfo)
    }, [props?.courseInfo]);

    useEffect(() => {
        setTraining(props?.training);

    }, [props?.training])

    useEffect(() => {
        setTrainingSession(props?.trainingSession)
    }, [props?.trainingSession])
    useEffect(() => {
        setType(props?.content?.data?.type);

        const copyOfferCourse = { ...offerCourse };
        switch (props?.content?.data?.type) {
            case 'course':
                copyOfferCourse.label = props?.courseInfo?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idEntity = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_course",
                        value: props?.courseInfo?.id,
                        valueType: "number",
                        code_product: "CRS"
                    }
                ]
                break;
            case 'path':
                copyOfferCourse.label = props?.training?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idEntity = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_path",
                        value: props?.training?.id,
                        valueType: "number",
                        code_product: "PTH"
                    }
                ]
                break;
            case 'session':
                copyOfferCourse.label = props?.trainingSession?.title
                copyOfferCourse.paymentEngine = props?.paymentSchoolInfo?.paymentEngine;
                copyOfferCourse.idSchool = props?.schoolInfo?.id;
                copyOfferCourse.pricings[0].currency = props?.paymentSchoolInfo?.currency;
                copyOfferCourse.rules = [
                    {
                        id: null,
                        key: "id_session",
                        value: props?.trainingSession?.id,
                        valueType: "number",
                        code_product: "SSN"
                    }
                ]
                break;

        }
        setOfferCourse(copyOfferCourse);
    }, [props?.content])
    const validateCours = async (value: string) => {
        let code = getDomainFromUrl()
        let obj = {
            comment: (comment !== "") ? comment : t('nothingTOSay'),
            idCours: (type === "course") ? courseInfo?.id : (type === "path") ? training?.id : trainingSession?.id,
            status: "",
            title: (type === "course") ? courseInfo?.title : (type === "path") ? training?.title : trainingSession?.title,
            code: code,
            userRole: props?.connectedUser?.role,
            idSchool: props?.connectedUser?.idSchool,
            trainingType: (type === "course") ? courseInfo?.type : (type === "path") ? training?.type : trainingSession?.type,
        }
        await props?.clearFormPayment();
        let videos = extractVideoLinks(courseInfo)
        if (videos.length > 0)
        {
            axiosInstance.post(process.env.REACT_APP_BASE_URL4 + "update/domain", {
                videos:videos,
                domain: getDomainFromUrl()
            })    
        }
        switch (type) {
            case 'course':
                await props?.createCourse(courseInfo,props?.connectedUser)
                await props?.publishCourseWithOfferInit(value, courseInfo?.idProf, props?.connectedUser?.idPerson, obj, offerCourse);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToCourse'), lines: [t('publishCourseSuccessModal')] });
                break;
            case 'path':
                await props?.createTraining("paths", training)
                await props?.publishTraining(value, props?.connectedUser?.idPerson, training?.idProf, obj)
                await props?.valideTraining("paths", { ...training, status: "PUBLISHED" }, offerCourse);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToPathButton'), lines: [t('publishPathSuccessModal')] });
                break;
            case 'session':

                await props?.createTraining("sessions", trainingSession)
                await props?.publishTraining(value, props?.connectedUser?.idPerson, trainingSession?.idProf, obj)
                await props?.valideTraining("sessions", { ...trainingSession, status: "PUBLISHED" }, offerCourse);
                await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToSessionButton'), lines: [t('publishSessionSuccessModal')] });
                break;
        }
    }



    useEffect(() => {
        props.getOfferPricingCourse(props?.courseTarificationForm?.id)


    }, []);
    const handleChangeProf = (e: any) => {
        setCheck(true)
        switch (props?.content?.data?.type) {
            case 'course':
                const courseForm: any = { ...courseInfo };
                courseForm['idProf'] = e;
                courseForm['creator']=e
                setCourseInfo(courseForm)
                break;
            case 'path':
                const pathForm: any = { ...training };
                pathForm['idProf'] = e;
                pathForm['creator']=e
                setTraining(pathForm)
                break;
            case 'session':
                const sessionForm: any = { ...trainingSession };
                sessionForm['idProf'] = e;
                sessionForm['creator']=e
                setTrainingSession(sessionForm)
                break;

        }

    }

    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto" style={{marginRight:20 }}>
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0,paddingLeft:60, overflow:'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-11" style={{ margin: 'auto' }}>
                        <div className="d-flex justify-content-start flex-column mb-3">
                            <span className="H3-Headline black-800 mb-1"> Accepter la formation</span>
                            <span className="H4-Subtitle neutral-3 mb-3"> {(type === 'course') ? courseInfo?.title : (type === 'path') ? training?.title : trainingSession?.title} </span>

                            <span className="body-sm black-800"> Avant d’accepter la formation, veuillez choisir à qui souhaitez vous l’affecter?</span>

                        </div>

                        <div style={{maxWidth:587 }} className='mt-3'>
                        <p className="form-title mb-2">Sélectionnez l’auteur de cette formation</p>
                        <SelectElement disabled={false} taille={'lg'} defaultValue={defaultValueProfessors} options={professors} onChange={(e: any) => { handleChangeProf(e);
                       }}  placeholder="Sélectionnez" />

                        </div>
  
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ border: "none" }}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-12 text-center justify-content-center' style={{ display: 'flex', alignItems: 'center',marginRight:165 }}>
                        <button type="button" className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large " disabled={!check} onClick={() => { validateCours("PUBLISHED"); }}
                            
                        >valider</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            publishCourse,
            updateCoursesLists,
            saveFormPaymentInfo,
            publishCourseWithOfferInit,
            clearFormPayment,
            getOfferCourse,
            getOfferPricingCourse,
            saveReturnPath,
            valideTraining,
            publishTraining,
            RedirectPopup,
            createCourse,
            createTraining

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(TransferTrainingModalComponent);