





import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import CardClosedQuestionPreviewComponent from "../../../components/preview-course-components/chapter-exam/card-closed-question-preview-component";
import CardOpenQuestionPreviewComponent from "../../../components/preview-course-components/chapter-exam/card-open-question-preview-component";
import { getDuration } from "../../../base/functions/Functions";
import "../../../translations/i18n";
import { useTranslation } from 'react-i18next';

const ExamPathListQuestions: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [exam, setExam] = useState(props?.pathExam?.exams[0]);
    const [scaleNote, setScaleNote] = useState(0);
    const { t } = useTranslation();
    const calculateScaleNote = (questions: any) => {

        let sum = 0;
        sum += questions?.map((qbe: any) => qbe?.scaleNote).reduce((a: number, b: number) => a + b, 0)
        return sum;
    }

    useEffect(() => {
        setExam(props?.pathExam?.exams[0])

    }, [props?.pathExam])

    useEffect(() => {
        setScaleNote(calculateScaleNote(exam?.questionByQnaList))
    }, [exam])

    return (
        <div style={{ paddingRight: "120px", paddingLeft: "120px", paddingTop: "32px" }}>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <span className="H3-Headline">{exam?.title} / {scaleNote} <span className="H3-Headline" style={{ color: "#CACBCE" }} >{t("points")}</span></span>
                <div className="d-flex align-items-center">
                    <span className="material-icons-outlined icon-grey pe-1">
                        timer</span>
                    <span className="body-md bold">{getDuration(exam?.duration)}</span>
                </div>
            </div>
            <div style={{ paddingTop: "32px" }}>
                {exam?.questionByQnaList?.map((question: any, index: number) => {
                    if (question?.question?.type === "CQ") {
                        return (
                            <div key={index}>
                                <CardClosedQuestionPreviewComponent key={index} question={question} index={index + 1} />
                            </div>
                        );
                    } else {
                        return (
                            <div key={index}>
                                <CardOpenQuestionPreviewComponent
                                    index={index + 1}
                                    key={index}
                                    question={question}
                                />
                            </div>
                        );
                    }
                }
                )}
            </div>
        </div>

    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    pathExam: state.courseReducer.training,


});
export default connect(mapStateToProps, mapDispatchToProps)(ExamPathListQuestions);
