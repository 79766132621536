import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCoursesForDirector,getCourse, getCourseTests, getRegistrationForm } from '../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { useHistory } from 'react-router';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import FilterAssistantListComponent from '../filters/FilterAssistantListComponent';
import TableTeamWithActions from '../../elements/table/table-team-with-actions';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TeamTableAction from '../../elements/table-actions/team-table-action';
import { returnStartPathUrl } from '../../helpers/domainCheck';



const TableAssistantListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const dateFormat = require('dateformat');
    const { t } = useTranslation();
    const history = useHistory();
    const [listOfAssistant, setListOfAssistant] = useState<any>();
    const [filteredAssistant, setFilteredAssistant] = useState<any>();
    const [nbrAssistant, setNbrAssistant] = useState(props?.sizeAssistants)
    const headers = [
        { title: t('assistant'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },

        { title: t('biography'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    const status = [
        { label: t('Enattente'), value: "Enattente", color: "warning" },
        { label: t('Admis'), value: "Admis", color: "success" },
        { label: t('Suspendu'), value: "Suspendu", color: "danger" },
    ]
    const [activateSnakbar, setActivateSnakbar] = useState<boolean>(false)
    const activateSnakBar = ()=>{
      setActivateSnakbar(true)
    }
    const doFilter = (value: string) => {
        let copyAssistants = { ...listOfAssistant };
        let actualStatus: any = status.find((e: any) => e?.value === value)?.value;
        copyAssistants.body = copyAssistants?.body?.filter((assistant: any) => {
            return (value !== "" ? assistant.row.columns[4]?.content.includes(t(actualStatus)) : true)
        });

        setFilteredAssistant(copyAssistants);
    }
    const doAction = async (value: any) => {
        if (value.action === 'goToPath') {
            props?.clearHistoryArray();
            props?.redirect(t("schoolAssistant"), `${returnStartPathUrl()}/professors/all`)
            props?.redirect(value.row.assistant.name, `${returnStartPathUrl()}/professors/infoteam/${value.row.assistant.id}`)
            history.push(`${returnStartPathUrl()}/professors/infoteam/${value.row.assistant.id}`);
        }
    }


    useEffect(() => {
        setNbrAssistant(props?.sizeAssistants)
    }, [props?.sizeAssistants])

    useEffect(() => {
        setListOfAssistant(props?.listOfAssistant)
    }, [props?.listOfAssistant])
    useEffect(() => {
        const listOfAssistant = {
            headers: headers,
            body: props?.listOfAssistant?.map((assistant: any) => {
                const actualStatus: any = status.find(obj => obj.value.toLowerCase() === assistant.status.toLowerCase());
                return {
                    row: {
                        assistant: assistant,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: assistant?.name, classNames: "", actionName: '' },
                            { content: assistant?.inscription === null ? "-" : dateFormat(assistant?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            { content: assistant?.lastVisit === "-" ? "-" : assistant?.lastVisit + " " + t('joursVisit'), classNames: "text-center", actionName: '' },

                            {
                                content: (assistant?.pp !== null && assistant?.pp !== '') ?
                                    <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">check</span> :
                                    <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">highlight_off</span>,
                                classNames: (assistant?.pp !== null && assistant?.pp !== '') ?
                                    "text-center with-icon badge-text success p-0 m-0 relative-position" :
                                    "text-center with-icon badge-text secondary p-0 m-0 relative-position",
                                actionName: ''
                            },
                            { content:assistant?.status==="Enattente" ?< TeamTableAction status={actualStatus?.label}  color={actualStatus?.color}  id={assistant?.id} role={assistant?.role} activateSnakBar={activateSnakBar}/> : <span className={`text-end badge-text ${actualStatus?.color}`}>{assistant?.status}</span>, classNames: `text-end with-action-edit`, actionName: '' },
                        ]
                    }
                }
            })
        }
        setListOfAssistant(listOfAssistant);
        setFilteredAssistant(listOfAssistant)
    }, [props?.listOfAssistant]);


    return (
        <>
        <div className="container-fluid m-0 p-0 mx-auto" style={{ maxWidth: '1200px' }}>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    {props?.actualTab === "ADMINSTRATOR" && props?.sizeAssistants > 0 && <FilterAssistantListComponent doFilter={doFilter} actualTab= "ADMINSTRATOR"/>}
                </div>
                <div className="col-md-12 p-0 m-0 mt-5">
                    {(props?.sizeAssistants > 0) ? <>
                        <TableTeamWithActions height={10} data={filteredAssistant} doAction={(value: any) => { doAction(value) }} actualTab={props?.actualTab} />
                    </> : <>
                        <div className="w-100">
                            <div className='wrapper-empty-list mt-5 pt-5'>
                                <EmptyListElement lines={[t('noAssistantDefault_1'), t('noAssistantDefault_2')]} />
                                {props?.inviteMember()}
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
        <Snackbar open={activateSnakbar} autoHideDuration={6000}  onClose={()=>{setActivateSnakbar(!activateSnakbar)}}>
    <Alert severity="success">
    Invitation envoyée avec succes
    </Alert>
</Snackbar>
        </>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    listOfAssistant: state.profReducer.assistants,
    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    sizeAssistants: state.profReducer.sizeAssistants,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { getCoursesForDirector, getCourse, getCourseTests, getRegistrationForm, redirect, clearHistoryArray }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableAssistantListComponent);
