import { FC } from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WhiteCardComponent: FC<any> = ({ children }: any) => {
  return (
    <>
      <div className="p-4 mb-4 bg-with-border white">
        <div className=" d-flex flex-column  flex-wrap">{children}</div>
      </div>
    </>
  );
};
export default WhiteCardComponent;
