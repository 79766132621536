import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#272727',
      maxWidth: '70%',
      wordBrea: 'break-word',
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #FBFBFD',
      padding: 16,
      borderRadius: '3px',
      boxShadow: ' 0px 3px 12px rgba(195, 205, 206, 0.25);'
    },
    arrow: {
        color: '#ffffff',
        boxShadow: ' 0px 3px 12px rgba(195, 205, 206, 0.25);'
    }
  }))(Tooltip);
export default function TooltipCustomized(props) {


  return <>
<HtmlTooltip arrow={true} title={ <React.Fragment> 
              <Typography color="inherit">{props?.title}</Typography>
                {props?.content}
          </React.Fragment>
        }
      >
          {props?.children}
      </HtmlTooltip>
  </>
}


