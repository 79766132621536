import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import TableElement from '../../elements/table/normal-table-element';
import { getExamForm, getNextExam, clearExams } from '../../global-state/actions/exam-actions';
import { useHistory } from 'react-router-dom';
import { returnStartPathUrl } from '../../helpers/domainCheck';

let dateFormat = require('dateformat');

const TableListExamDetailComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    const [actualTab, setActualTab] = useState("TOREVIEW");
    const [listToReviewExam, setListToReviewExam] = useState<any>(props?.listToReviewExam);
    const [listCompletedExam, setListCompletedExam] = useState<any>(props?.listCompletedExam);
    const [listAllExam, setListAllExam] = useState<any>(props?.listAllExam);
    const { t } = useTranslation();
    const headers = [
        { title: t("TitleFormations"), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("fromWhereTraining"), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t("students"), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("Rendu_le"), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t("NOTE"), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" }
    ]

    const calculateNumberOfDays = (a: any, b: any) => {
        let date1 = new Date(a);
        let date2 = new Date(b);
        let result = Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
        return result;
    }

    const numberOfDaysClass = (a: any, b: any) => {
        let date1 = new Date(a);
        let date2 = new Date(b);
        let result = Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
        if (result < 2) {
            return "badge-text secondary"
        } else if (result > 2 && result <= 5) {
            return "badge-text warning"
        } else if (result >= 6) {
            return "badge-text danger"
        }
    }

    const markColor = (a: any, b: any) => {
        if (b / a < 0.5) {
            return "badge-text secondary"
        } else {
            return "text-end badge-text success"
        }
    }

    useEffect(() => {
        setActualTab(props?.actualTab);
    }, [props?.actualTab])


    useEffect(() => {
        
        const list = {
            headers: headers,
            body: props?.listToReviewExam?.map((examFinal: any) => {
                let type = '';
                if (examFinal?.trainingName === null) {
                    type = (examFinal?.entityType === 'PATH') ? t('pathTitle') : (examFinal?.entityType === 'SESSION') ? t('sessionTitle') : t('Course')
                } else {
                    type = (examFinal?.trainingType === 'PATH') ? t('pathTitle') : (examFinal?.trainingType === 'SESSION') ? t('sessionTitle') : t('Course');
                }
                return {
                    row: {
                        examFinal: examFinal,
                        actionName: "goToCorrection",
                        classNames: "table-white",

                        columns: [
                            { content: (examFinal?.courseName !== null)?examFinal?.courseName :examFinal?.trainingName, classNames: "", actionName: '' },
                            { content: type, classNames: "text-center", actionName: '' },
                            { content: examFinal?.studentName, classNames: "", actionName: '' },
                            { content: <span>{dateFormat(examFinal?.progressQna?.createDate, "dd/mm/yyyy")} {t("TimePassed")} <span className={numberOfDaysClass(examFinal?.progressQna?.createDate, Date.now())}>/ <span>{calculateNumberOfDays(examFinal?.progressQna?.createDate, Date.now())}</span> {t("Days")}</span></span>, classNames: "text-center", actionName: '' },
                            { content: t("à_noter"), classNames: "text-end badge-text warning", actionName: '' },
                        ]
                    }
                }
            })
        }
        
        setListToReviewExam(list);
    }, [props?.listToReviewExam])

    useEffect(() => {
        const list = {
            headers: headers,
            body: props?.listCompletedExam?.map((examFinal: any) => {
                const finalMarkToDisplay = (status: string) => {
                    if (status === "COMPLETED") {
                        return <span><span className={markColor(examFinal?.progressQna?.finalMark, examFinal?.progressQna?.scaleNote)}>{examFinal?.progressQna?.finalMark}</span>/{examFinal?.progressQna?.scaleNote}</span>
                    } else if (status === "TOREVIEW") {
                        return <span> {t("à_noter")} </span>
                    }
                }
                let type = '';
                if (examFinal?.trainingName === null) {
                    type = (examFinal?.entityType === 'PATH') ? t('pathTitle') : (examFinal?.entityType === 'SESSION') ? t('sessionTitle') : t('Course')
                } else {
                    type = (examFinal?.trainingType === 'PATH') ? t('pathTitle') : (examFinal?.trainingType === 'SESSION') ? t('sessionTitle') : t('Course');
                }
                return {
                    row: {
                        examFinal: examFinal,
                        actionName: "goToCorrection",
                        classNames: "table-white",
                        columns: [
                            { content: (examFinal?.courseName !== null)?examFinal?.courseName :examFinal?.trainingName, classNames: "", actionName: '' },
                            { content: type, classNames: "text-center", actionName: '' },
                            { content: examFinal?.studentName, classNames: "", actionName: '' },
                            { content: <span>{dateFormat(examFinal?.progressQna?.createDate, "dd/mm/yyyy")}  {t("TimePassed")} <span className="badge-text secondary">/ <span>{calculateNumberOfDays(examFinal?.progressQna?.createDate, Date.now())}</span> {t("Days")}</span></span>, classNames: "text-center", actionName: '' },
                            { content: finalMarkToDisplay(examFinal?.progressQna?.status), classNames: 'text-center', actionName: '' },
                        ]
                    }
                }
            })
        }
        setListCompletedExam(list);
    }, [props?.listCompletedExam])

    useEffect(() => {
        const list = {
            headers: headers,
            body: props?.listAllExam?.map((examFinal: any) => {
                const finalMarkToDisplay = (status: string) => {
                    if (status === "COMPLETED") {
                        return <span><span className={markColor(examFinal?.progressQna?.finalMark, examFinal?.progressQna?.scaleNote)}>{examFinal?.progressQna?.finalMark}</span>/{examFinal?.progressQna?.scaleNote}</span>
                    } else if (status === "TOREVIEW") {
                        return <span className="text-end badge-text warning"> {t("à_noter")} </span>
                    }
                }
                let type = '';
                if (examFinal?.trainingName === null) {
                    type = (examFinal?.entityType === 'PATH') ? t('pathTitle') : (examFinal?.entityType === 'SESSION') ? t('sessionTitle') : t('Course')
                } else {
                    type = (examFinal?.trainingType === 'PATH') ? t('pathTitle') : (examFinal?.trainingType === 'SESSION') ? t('sessionTitle') : t('Course');
                }
                return {
                    row: {
                        examFinal: examFinal,
                        actionName: "goToCorrection",
                        classNames: "table-white",
                        columns: [
                            { content: (examFinal?.courseName !== null)? examFinal?.courseName :examFinal?.trainingName , classNames: "", actionName: '' },
                            { content: type, classNames: "text-center", actionName: '' },
                            { content: examFinal?.studentName, classNames: "", actionName: '' },
                            { content: <span>{dateFormat(examFinal?.progressQna?.createDate, "dd/mm/yyyy")} {t("TimePassed")} <span className="badge-text secondary">/ <span>{calculateNumberOfDays(examFinal?.progressQna?.createDate, Date.now())}</span> {t("Days")}</span></span>, classNames: "text-center", actionName: '' },
                            {
                                content: finalMarkToDisplay(examFinal?.progressQna?.status),
                                classNames: 'text-center',
                                actionName: ''
                            },
                        ]
                    }
                }
            })
        }
        setListAllExam(list);
    }, [props?.listAllExam])


    const doAction = async (value: any) => {
        if (value.action === 'goToCorrection') {
            
            props?.clearHistoryArray()
            props?.redirect(t("Examens"), `${returnStartPathUrl()}/examsDetails/details`)
            props?.redirect( value?.row?.examFinal?.courseName, `${returnStartPathUrl()}/examsDetails/submission/${actualTab}/${value?.row?.examFinal?.progressQna?.id}`)
            await props?.getExamForm(value?.row?.examFinal, actualTab, value?.index);
             history.push(`${returnStartPathUrl()}/examsDetails/submission/${actualTab}/${value?.row?.examFinal?.progressQna?.id}`);
        }
    }

    return (
        <div >
            {(props?.actualTab === "TOREVIEW") &&
                <div>
                    {(props?.examsList?.listExamToReview?.length > 0) ? <>
                        <TableElement data={listToReviewExam} doAction={(value: any) => { doAction(value) }} />
                    </> : <div className="w-100 text-center mt-7">
                            <EmptyListElement lines={[t('examsToNote')]} />
                        </div>
                    }                    
                </div>}

            {(props?.actualTab === "COMPLETED") &&
                <div>
                    {(props?.examsList?.listExamCompleted?.length > 0) ? <>
                        <TableElement data={listCompletedExam} doAction={(value: any) => { doAction(value) }} />
                    </> : <div className="w-100 text-center mt-7">
                            <EmptyListElement lines={[t('notedExams')]} />
                        </div>
                    }
                </div>}

            {(props?.actualTab === "ALL") &&
                <div>
                    {(props?.examsList?.listAllExam?.length > 0) ? <>
                        <TableElement data={listAllExam} doAction={(value: any) => { doAction(value) }} />
                    </> : <div className="w-100 text-center mt-7">
                            <EmptyListElement lines={[t('noExam')]} />
                        </div>
                    }
                </div>}
        </div>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    connectedUserDetails: state.authReducer.connectedUser,
    history: ownProps?.history,
    examStats: state.examReducer.examStats,
    actualTab: state.examReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            redirect, 
            clearHistoryArray,
            getExamForm,
            getNextExam,
            clearExams
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(TableListExamDetailComponent);