import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import "../../translations/i18n";
import { useTranslation } from "react-i18next";


export const SchoolLogoImageCropping = (props: any) => {
    const { t } = useTranslation();
    let [imageSrc, setImageSrc] = useState<any>();
    const [counter, setCounter] = useState<number>(0)
    const dropPhoto:string= "Glissez-déposez votre photo (jpg, png)"
    const dropLogo:string= "Glissez-déposez votre logo (jpg, png)"

    useEffect(() => {
        setTimeout(() => {
            if (props?.imageSrc === undefined) {
                setCounter(counter + 1)
          
            } else {
 
                setImageSrc(props?.imageSrc)
            }

        }, 1000)
    }, [counter])


    useEffect(() => {
        setImageSrc(props?.imageSrc);

    }, [props?.imageSrc])



    const handleChange = (selectorFiles: FileList) => {
        let fileReader = new FileReader();
        let data;

        fileReader.onload = () => {
            data = fileReader.result;
            setImageSrc(data)
        };
        fileReader.readAsDataURL(selectorFiles[0]);
    }

    const onDrop = async (accepted: any, rejected: any) => {
        if (Object.keys(rejected).length === 0) {

            if (props?.logoSchool === "logo")
                props?.onGetBlobFile(accepted);
           await handleChange(accepted)
        }

        if(props?.type==="logo"){
           await props?.openModalFn()
        }
    };

    const onDelete = async(e: any) => {
        setImageSrc(null)
        await props?.onGetBlobFile(null);
        await props?.updateLogo();
    }

    const updateLogoModal = async() => {
        props?.openModalFn()
    }

        return (
            < >
                {imageSrc !== null ?
                        <div style={{ position: "relative"  , boxSizing:"border-box" , border:"1px solid #DADBDF" , borderRadius:"3px", padding:"27px 32px"}}>
                            <div className="container upload-input-crop-logo ">
                                <div className="d-flex">
                                    <div className="d-flex justify-content-start">
                                        <img style={{ height: "64px" , width:"46px" }} src={imageSrc} />
                                    </div>
                                    <div className="ms-auto p-2 d-flex flex-row justify-content-center" style={{gap:"8px"}}>
                                        <button className="btn-QuickActions" style={{ height: "48px" , width:"48px" }} onClick={(e) => onDelete(e)}  ><span className="material-icons-outlined">delete</span></button>
                                        {props?.type==="logo" && <button className="btn-Secondary medium" style={{ height: "48px" , width:"103px" }}  onClick={() => updateLogoModal()}  >{t('modifier')}</button>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    :
                    <>
                        <div style={{ position: "relative"  , boxSizing:"border-box" , border:"1px dashed #DADBDF" , borderRadius:"3px" , padding:"27px 32px"}}>

                            <Dropzone
                                style={{}}
                                multiple={false}
                                accept="image/*"
                                onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                            >
                                <>

                                    <div className="container upload-input-crop-logo">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="p-2">
                                            <span className="material-icons-outlined neutral-3"> cloud_upload </span>
                                            </div>
                                            <div className="p-2" >
                                                <span className="body-sm neutral-3" style={{display:"content"}} >{ props?.type==="logo" ? dropLogo : dropPhoto} </span>
                                            </div>
                                            <div className="ms-auto p-2">
                                                <button type="button" className="btn-Secondary large">
                                                    {t('uploadImage_2')}
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            </Dropzone>
                        </div>
                    </>
                }
            </>
        )
    


}

