import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import SelectElement from "../../elements/select/select-element";
import { useTranslation } from "react-i18next";
import InputSearch from '../../elements/inputs/input-search';
import { debounce } from "lodash";
import { ModalIDS } from '../modals/modal-ids';
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { openModal } from '../../global-state/actions/modal-actions';
import SelectElemenWithOptGroup from "../../elements/select/select-element-with-optgroup";

const FilterCommunityPathComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [tab, setTab] = useState<string>("");
    const [trieOption, setTrieOption] = useState<any[]>([]);
    const [filterOption, setFilterOption] = useState<any>([]);
    const [trie, setTrie] = useState<string>("recent");
    const [filtre, setFiltre] = useState<string>("all");
    const [searchFilter, setSearchFilter] = useState<string>("");
    const { t } = useTranslation();
  
    useEffect(() => {

        setTrie("recent");
        setFiltre("all");
        setSearchFilter("")
        setTab(props?.tabChatManager)
        setTrieOption([{ value: "recent", label: t('recent') }, { value: "oldest", label: t('oldest') }, { value: "maxResponses", label: t('maxResponses') }])
        let listFilter = [{ value: "all", label: t('allSubjects_2') },{ value: `${JSON.stringify({ id: props?.pathInfo?.id, typeCategory: props?.pathInfo?.type })}`, label: t('pathTitle')+" :  " + props?.pathInfo?.title },
        props?.pathInfo?.subjectsByPath?.map((obj: any) => {

           if (obj?.course) {
               return [{ value: `${JSON.stringify({ id: obj.course.id, typeCategory: obj.course.type })}`, label: "    "+t('Cours')+" :  " + obj.course.title },
               obj?.course?.orders?.map((chap: any) => {
                   return { value: `${JSON.stringify({ id: chap.chapter.id, typeCategory: 'CHAPTER' })}`, label: "       "+t('chapitre')+" : " + chap.chapter.title }
               })
               ]
           }

           if (obj?.path) {
               return [{ value: `${JSON.stringify({ id: obj.path.id, typeCategory: obj.path.type })}`, label: "    "+t('pathTitle')+" :" + obj.path.title },
               obj?.path?.subjectsByPath?.map((chap: any) => {
                   if (chap.course) {
                       return [{ value: `${JSON.stringify({ id: chap.course.id, typeCategory: chap.course.type })}`, label: "       "+t('Cours')+" : " + chap.course.title },
                       chap.course.orders.map((chap1: any) => {
                           return { value: `${JSON.stringify({ id: chap1.chapter.id, typeCategory: 'CHAPTER' })}`, label: "          "+t('chapitre')+" : "+ chap1.chapter.title }
                       })
                       ]
                   }
               })
               ]
           }

       })].flat(Infinity)
        setFilterOption(listFilter)
    }, [props?.tabChatManager])

    const sort = (trieSearch: string) => {
        setTrie(trieSearch);
        props?.isSearching({ trie: trieSearch, filtre: filtre, searchFilter: searchFilter });
    }
    const filter = (filterInput: string) => {
        setFiltre(filterInput);
        props?.isSearching({ trie: trie, filtre: filterInput, searchFilter: searchFilter });

    }
    const Search = (search: string) => {
        setSearchFilter(search);
        props?.isSearching({ trie: trie, filtre: filtre, searchFilter: search });
    }
    const debounceSearch = useCallback(debounce(Search, 300), []);
    const openModalFn = () => {
        if (tab === "discussion") {
            props?.openModal({ idModal: ModalIDS.ADD_COMMENT_PATH, size: "md" });
        } else {
            props?.openModal({ idModal: ModalIDS.ADD_QUESTION_PATH, size: "md" });
        }

    }
    return (
        <>
            <div className="mx-2 d-flex justify-content-between flex-wrap">
                <div className="mt-5 d-flex justify-content-start text-right px-0">
                    <button onClick={() => openModalFn()} type="button" className="btn-Primary large icon-left">
                        <span className="material-icons-outlined">add</span>
                        <div className="m-auto ps-2">{tab === "question" ? t('addQuestionButton') : t('addCommentButton')}</div>
                    </button>
                </div>
                <div className="mt-5 d-flex justify-content-end text-right px-0">
                    <div className="select-input me-2" style={{ width: '225px' }}>

                        <SelectElement disabled={false} taille={'lg'} defaultValue={trie} options={trieOption} placeholder={""} onChange={sort} />
                    </div>
                    <div className="select-input me-2" style={{ width: '225px' }}>

                        <SelectElemenWithOptGroup taille={'lg'} defaultValue={filtre} options={filterOption} placeholder={""} onChange={filter} />
                    </div>
                    <InputSearch value={searchFilter} onChange={debounceSearch} placeholder={t('searchProfessors')} />
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    pathInfo: state.studentTraining.currentTraining,
    connectedUser: state.authReducer.connectedUser,
    tabChatManager: state.chatReducer.tabChatManager,
    isSearching: ownProps?.isSearching
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FilterCommunityPathComponent);