import Skeleton from '@material-ui/lab/Skeleton';

function UpdateProfileInfosLoadingComponent() {
    return (
        <div className="" style={{ width: '100%', maxWidth: 1200 - 420, margin: 'auto' }} >
            <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-between  flex-column ">
                    <div className="dot">
                        <Skeleton variant="circle" animation="wave" width={152} height={152} className="skeleton " />
                    </div>
                    <div>
                        <Skeleton variant="rect" animation="wave" width={210} height={48} className="skeleton " />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="container-fluid">
                        <div className="row"  >
                            <div className="col-md-6">
                                <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'60px'} />
                            </div>
                            <div className="col-md-6">
                                <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'150px'} />
                            </div>
                        </div>

                        <div className="row" style={{ paddingTop: "22px" }}>
                            <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "column" }} >
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'60px'} />
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'100px'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'140px'} />
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'100px'} />

                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'140px'} />
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'100px'} />

                                </div>
                            </div>
                            <div className="col-md-6">

                                <div style={{ display: "flex", flexDirection: "column" }} >
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'60px'} />
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'100px'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'140px'} />
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'100px'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'140px'} />
                                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'100px'} />
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-8">
                                <hr className="mb-5" style={{ border: "1px solid #F2F2F5" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateProfileInfosLoadingComponent
