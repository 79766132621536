import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { clearHistory } from '../../global-state/actions/breadcrumb-actions'
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import SelectElement from '../../elements/select/select-element';
import { exportListCourses } from '../../helpers/export-courses-functions'
import { changeTabAllTrainings, searchTrainingsAssistant } from '../../global-state/actions/training-actions';
import InputSearch from '../../elements/inputs/input-search';

const FilterAllCoursesAssistantComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [filter, setFilter] = useState({ status: "", search: "" });
    const[actualTab,setActualTab]=useState(props?.actualTabAllCourses);
    const status = [
        { label: t('allStatus'), value: "ALL" },
        { label: t('Avalider'), value: "PENDING"},
        { label: t('Enligne'), value: "PUBLISHED" },
        { label: t('Archive'), value: "ARCHIVE"},
        { label: t('Refuse'), value: "REFUSED" },
        { label: t('Deactivate'), value: "DEACTIVATE" },
        { label: t('TrainingToedit'), value: "DRAFT" }
      ]
 
    useEffect(() => {
        props?.clearHistory();
    }, [])
    



    useEffect(()=> {
       setActualTab(props?.actualTabAllCourses)

    }, [props?.actualTabAllCourses])

       
        const exportData = () => {
   
            const lang = localStorage.getItem("language")
            let actualTabOp= actualTab === "online" ? t('ListOnlineCourses') :t('ListPendingCourses')
            let name = (actualTab === "all" ? t('ListAllCourses') : actualTabOp) + " " + props?.school.code;
            let filterCourse;
            if (actualTab === "all") {
                filterCourse = { entityId: props?.user.idSchool, entityType:"SCHOOL"};
            } else if(actualTab === "online") {
                filterCourse = { entityId: props?.user.idSchool ,entityType:"SCHOOL",status:"PUBLISHED"};
            }else{
                filterCourse = { entityId: props?.user.idSchool ,entityType:"SCHOOL",status:"PENDING"};
            }    
            exportListCourses(filterCourse ,name,lang)
        }


          

    return (
        <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-between" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button type="button" style={{ minHeight: '45px', marginRight: '10px' }} className="btn-Secondary large icon-left" onClick={() => exportData()}><span className="material-icons-outlined" >download</span> <div className="m-auto ps-2">{t('exportListe')}</div></button>
                        <div className="d-flex align-items-center flex-wrap">
                        <div style={{ maxWidth: '225px', minWidth:'225px', marginRight: '10px' }}>
                            <SelectElement disabled={false} taille={'lg'} defaultValue={'none'} options={status} onChange={(e: any) => { filter.status = e  ; props?.changeTabAllTrainings(e) ; props?.doFilter(filter) ; }} placeholder={t('allStatus')} />
                        </div>
                        <div style={{ maxWidth: '225px' }} className="input-form-control">
                            <InputSearch  placeholder={t('searchProfessors')} value={searchFilter} onChange={(e: any) => { filter.search = e ; props?.doFilter(filter) ; }}/>
                        </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}

const mapStateToProps = (state: AppState) => ({
    allCourses: state.trainingReducer.trainings,
    user: state.authReducer.connectedUser,
    school: state.authReducer.connectedSchoolInfo,
    actualTabAllCourses: state.trainingReducer.actualTabTraining
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators( { clearHistory,changeTabAllTrainings , searchTrainingsAssistant}, dispatch );

export default connect( mapStateToProps, mapDispatchToProps )(FilterAllCoursesAssistantComponent);
