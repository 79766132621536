import { ComponentProps, FC, useState } from 'react';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AppState } from '../../../global-state/store/root-reducers';
import { createOrUpdateShoolPaymentInfo, showPaymentSchoolInfo } from '../../../global-state/actions/app-container-actions';
import Dropzone from 'react-dropzone';
import axiosInstance from '../../../helpers/interceptors';
import CropImageProfile from '../../../elements/inputs/crop-image-profile';
import InputUploadSchoolLogo from '../../../elements/inputs/input-upload-school-logo';



const EditSchoolLogoModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [schoolLogo, setSchoolLogo] = useState(props?.connectedSchool?.logo)





    const onDrop = async (accepted: any, rejected: any) => {
        if (Object.keys(rejected).length === 0) {
            await onGetBlobFil(accepted);
        }
    };

    const onGetBlobFil = async (blobFile: any) => {
        const baseUrlGed = process.env.REACT_APP_BASE_URL4;
        if (blobFile === null)
            setSchoolLogo(null)
        else {
            const form = new FormData();
            form.append('file', blobFile[0]);
            await axiosInstance.post(baseUrlGed + "upload/image", form)
            .then(async (response: any) => { setSchoolLogo(response.data.link) })
        }
    }

    const deleteSchoolLogo =()=>{
        let object: any = {
            "id": props?.connectedSchool?.id,
            "code": props?.connectedSchool?.code,
            "color": props?.connectedSchool?.color,
            "coverPhoto":props?.connectedSchool?.coverPhoto,
            "centralLogo": null,
            "creationDate": props?.connectedSchool?.creationDate,
            "description": props?.schoolInfo?.description,
            "enabled": "Enabled",
            "logo": null,
            "name": props?.connectedSchool?.name,
            "shortDescription": props?.connectedSchool?.shortDescription,
            "domain":  props?.connectedSchool?.domain,
        }
        props?.UpdateSchool(object)

    }

    return (
        <div>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto" >
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()} >
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>

            <div className="modal-body ">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="H4-Subtitle">{t('saveSchoolLogo')}</span>
                    </div>
                    <div className="col-md-6 upload-image-profile text-center " style={{ paddingTop: "40px" }}>
                        {schoolLogo === null ? (
                            <Dropzone
                                style={
                                    { width: '100%', zIndex: 1, position: "relative" }
                                }
                                multiple={false}
                                accept="image/*"
                                onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                            >
                                <div className="mx-auto">
                                    <div className="bg-with-border dashed d-flex align-items-center justify-content-center flex-column" style={{ width: "100%", height: "230px" }}>
                                        <span className="material-icons-outlined" style={{ color: "#DADBDF" }}>cloud_upload</span>
                                        <p className="body-md bold neutral-3 mt-3"> {t('dragToUpload')}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-column mx-auto" style={{ paddingTop: "16px", paddingBottom: "56px" }}>
                                    <span style={{ paddingBottom: "16px" }}> {t('or')} </span>
                                    <button className="btn-Primary large" >{t('selectPhoto')}</button>
                                </div>
                            </Dropzone>
                        ) : (<>
                            <InputUploadSchoolLogo imageSrc={props?.imageSrc} />
                        </>
                        )}
                    </div>
                </div>
            </div>


        </div>
      
    )
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal,
        createOrUpdateShoolPaymentInfo,
        showPaymentSchoolInfo
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    connectedSchool: state.authReducer.connectedSchoolInfo,

});
export default connect(mapStateToProps, mapDispatchToProps)(EditSchoolLogoModalComponent);