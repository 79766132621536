import  { FC } from "react";
import PrivateRoute from "../../../navigations/PrivateRoute";
import PreviewProgressPage from "./preview-progress-page";
import CommunityPage from "./community-page";
import ProgressNavbar from '../../../components/progress/progress-navbar';
import WebinarCoursePage from './webinar-course-page';
import CustomSwitch from "../../../helpers/custom-switch";
const ProgressContainer: FC<any> = (props: any) => {
    return(
        <>
        <ProgressNavbar />
                <CustomSwitch>
                    <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/previewProgress/:id`} component={PreviewProgressPage}  />
                    <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/community/:id`} component={CommunityPage}/>
                     <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/webinar/:id`} component={WebinarCoursePage}/> 
                    
                </CustomSwitch>
        </>
    )
}
export default ProgressContainer;