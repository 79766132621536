import { FC } from "react";
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import SondageListPage from "./sondage-list-page";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
import SondagePage from "./sondage-page";
import SondageUserValidatePage from "./sondage-user-validate-page";
import { Route } from "react-router";
import PassQnaPage from "./pass-qna-page";
import RouteWithBreadCrumb from "../../navigations/RouteWithBreadCrumb";
import PreviewSondagePage from "./preview-sondage-page";
import PassQnaDirectorPage from "./pass-qna-director-page";
import PreviewSondage from "./preview-sondage";
import surveyPage from "./survey-detail/survey-preview-page";
import surveyDetailContainer from "./survey-detail/survey-detail-container";
import PrivateRoute from "../../navigations/PrivateRoute";
const SondageContainer: FC<any> = (props: any) => {

  return (<>
    <div className="page-wrapper-height">
      <CustomSwitch>
        <RouteWithNavbar exact={true} history={props?.history} roles={['role_director']} path={`${props?.match.path}`} component={SondageListPage} />
        <Route exact={true} path={`${props?.match.path}/user/:id`} component={SondagePage} />
        <Route exact={true} path={`${props?.match.path}/user/pass/:id`} component={PassQnaPage} />
        <Route exact={true} path={`${props?.match.path}/passed`} component={SondageUserValidatePage} />
        <PrivateRoute exact={true} history={props?.history} path={`${props.match.path}/view/:id`} component={PreviewSondagePage} roles={["role_director"]} />
        <RouteWithBreadCrumb exact={true} roles={['role_director', "role_adminstrator"]} path={`${props.match.path}/view/pass/:id`} component={PassQnaDirectorPage} />
        <PrivateRoute exact={true} history={props?.history} path={`${props?.match.path}/preview`} component={PreviewSondage} roles={["role_director"]}  />
        <PrivateRoute history={props?.history} path={`${props?.match.path}/details/:id`} access={true} component={surveyDetailContainer} /> 
      </CustomSwitch>

    </div>
    <FooterComponent />

  </>

  )
}
export default SondageContainer;