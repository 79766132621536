import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { createOrUpdateShoolPaymentInfo, showPaymentSchoolInfo } from '../../../global-state/actions/app-container-actions';




const IdAccountPaymeeModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [idAccount, setIdAccount] = useState<string>('');


    const { t } = useTranslation();

    const enregitrer = async () => {
        const object = {...props?.content.data}
        object["accountId"] = idAccount;
        object["isActivated"] = true;
        await props?.createOrUpdateShoolPaymentInfo(object);
        await props?.showPaymentSchoolInfo(object);
        await props?.closeModal()
    }
    const cancelUpdate = async () => {
        await props?.closeModal();
    }


    return (
        <div>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
               <div className="d-flex align-items-center justify-content-center w-100">
                <div className="d-flex align-items-start flex-column w-100" style={{maxWidth: 588}} >
                    <span className="H3-Headline mb-4"> {t('connectAccountPaymee')}</span>
                    <label className="form-title">{t('idPaymee')}</label>
                    <input dir="ltr"onChange={(e) => setIdAccount(e.target.value)}  type="text" className="form-control input text-default" placeholder="123456789"/>
                </div>
                </div> 
            </div>
            <div className="d-flex align-items-center justify-content-center w-100" style={{ paddingBottom: 80, borderTop: "none", marginTop:"20px"}}>
                <div className="d-flex align-items-center justify-content-end w-100" style={{maxWidth: 588}} >
                    <button className="btn-Secondary large me-3" onClick={() => { cancelUpdate() }}>{t('annuler')}</button>
                    <button type="button" className="btn-Primary large large" disabled={idAccount === ''} onClick={() => { enregitrer() }} >{t('connect')}</button>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    connectedSchool: state.schoolInfoReducer.paymentSchoolInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            createOrUpdateShoolPaymentInfo,
            showPaymentSchoolInfo,

        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(IdAccountPaymeeModalComponent);

