
import { ComponentProps, FC, useEffect,useState } from "react"
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData, setActualTabManageProgress } from "../../global-state/actions/course-actions";
import "../../translations/i18n";
import { useHistory, useLocation } from "react-router-dom";
import { returnDynamicValueFromUrl, returnStartPathUrl } from "../../helpers/domainCheck";


const ProgressNavbar: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [actualTab, setTab] = useState<string>(props?.tabProgressManager)
    const { t } = useTranslation();
    
    useEffect(()=>{
        setTab(actualTab)
        return()=>{
          props?.setActualTabManageProgress("previewProgress");
          setTab("previewProgress")
         
        }
    }, []);

    const setActualTab = (tab: string) => {
        props?.setActualTabManageProgress(tab);
        setTab(tab);
        history.push(`${returnStartPathUrl()}/courses/${tab}/${returnDynamicValueFromUrl(3)}`)
       
    }
    const goBackToList = () => {
      history.push(`${returnStartPathUrl()}/courses/my-courses`);
      props?.setActualTabManageProgress("previewProgress");
     
  }
   
    return (
        <>
        <div className="cours-nav">
    <div className="navbar navbar-expand-lg d-flex justify-content-between p-0 ">
      <div className="col-md-1 d-flex ">
        <a onClick={() => {goBackToList()}} style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center nav-back">
          <span className="material-icons-round">
            chevron_left
          </span>
        </a>
      </div>
      <div className="col-md-11 d-flex justify-content-center ">
        <div className="d-flex  align-items-center">
          <a  style={{cursor: 'pointer'}}onClick={() => { setActualTab('previewProgress')} }className="d-flex justify-content-center align-items-center mx-1">
            <span className={(actualTab === 'previewProgress')?"nav-btn-black-active":"nav-btn-black"}>
            {t('Course')}
            </span>
          </a>
          <a style={{cursor: 'pointer'}} onClick={() => { setActualTab('community')}} className="d-flex justify-content-center align-items-center mx-1">
            <span className={(actualTab === 'community')?"nav-btn-black-active":"nav-btn-black"}>
            {t('community')}
            </span>
          </a>
          <a style={{cursor: 'pointer'}} onClick={() => { setActualTab('webinar')}} className="d-flex justify-content-center align-items-center mx-1">
            <span className={(actualTab === 'webinar')?"nav-btn-black-active":"nav-btn-black"}>
            {t('Webinar')}
            </span>
          </a>
        </div>
        </div>
    </div>
  </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    tabProgressManager: state.courseReducer.tabProgressManager,
    courseInfo: state.courseReducer.courseInfo,
    history: ownProps?.history
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ clearAllData, setActualTabManageProgress, }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavbar);
