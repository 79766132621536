/* IMPORT IMGS */
import upload from "../../styles/imgs/upload.png";
import deleteIcone from "../../styles/imgs/delete.png";
import Skeleton from "@material-ui/lab/Skeleton";

const SubtitleUploadedLoading = () => {
  return (
    <div className="row container-subtitle-file-added w-100 mx-0 mb-32">
      <div className="col-3 ">
        <Skeleton
          variant="rect"
          animation="wave"
          width={"98%"}
          height={55}
          className="skeleton skeleton-main col-xs-7 me-4 second-card-list-activities"
        />
      </div>
      <div className="col-8 border border-light d-flex align-items-center p-0">
        <Skeleton
          variant="rect"
          animation="wave"
          width={"100%"}
          height={55}
          className="skeleton w-100 skeleton-main col-xs-7 second-card-list-activities"
        />
      </div>
      <div className="col-1  p-0 d-flex align-items-center justify-content-end">
        <Skeleton
          variant="rect"
          animation="wave"
          width={"50%"}
          height={55}
          className="skeleton skeleton-main col-xs-7 second-card-list-activities"
        />
      </div>
    </div>
  );
};

export default SubtitleUploadedLoading;
