
import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux"
import { AppState } from "../../global-state/store/root-reducers";

import { getStudentsOfProf, setActualTab } from '../../global-state/actions/professor-actions';
import HeaderGroupPageComponent from '../../components/headers-page/header-group-page-component';
import FilterGroupPageComponent from '../../components/filters/filter-group-page-component';
import TableStudentGroupComponent from '../../components/tables/table-student-group-component';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import { useTranslation } from "react-i18next";
import { ModalIDS } from '../../components/modals/modal-ids';
import { clearModal, openModal} from '../../global-state/actions/modal-actions';
import * as _ from 'lodash';
import { getGroupBySchool } from "../../services/groupe.student.service";

const GroupPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
   
    const [isSearch, setIssearch]= useState(false)
    const [numberStudents, setNumberstudents]= useState<number>()
    const { t } = useTranslation();
    const [group, setActualGroup]= useState<any>({})
    const [groupList, setGroupList] = useState<any>([])
    const [tabGroupManager, setGroupTabManager] = useState<any>('all')
    const [filteredStudents, setFilteredStudents] = useState([]);
    

    useEffect(()=>{
        getGroupBySchool(props?.schoolInfo?.id).then((response:any) =>{
            setGroupList(response)
            props?.clearModal()
        })
    },[props?.modalPage])

    useEffect(()=>{
        
        if(tabGroupManager==="all"){

            let personListId=_.uniq([group?.personList?.map((obj:any)=>{return obj?.id}), group?.childsList?.map((subGroup:any)=>{ return subGroup?.personList?.map((p:any)=>{return p.id})})].flat(Infinity))
            setNumberstudents(personListId?.length)
            if(group?.childList?.length !== 0){
                const personList = _.uniqBy([
                    ...(group?.personList ?? []),
                    ...(group?.childsList?.flatMap((subGroup: any) =>
                      subGroup?.personList ?? []
                    ) ?? [])
                  ], 'id');
                  setActualGroup({...group,allStudent:personList})
            }

        }else if (tabGroupManager===group?.label){
            setNumberstudents(group?.personList?.length)
        }else {
            let actualGroup=group?.childsList.find((obj:any)=>{return obj.label===tabGroupManager})
          
            setNumberstudents(actualGroup?.personList?.length)
        }
    },[group, tabGroupManager,props?.modalPage])
    useEffect(() => {
        if (props?.listGroup?.length === 0) {
            props?.clearHistory()
            if (props?.connectedUser?.role === 'role_prof') {
                props?.getStudentsOfProf(props?.connectedUser?.role, { entityId: props?.connectedUser?.idSchool, entityType: 'SCHOOL', idProf: props?.connectedUser?.idPerson }, { generalFilter: '', candidatesFilter: '', certifiedFilter: '' }, { sizeGeneral: 20, sizeCandidates: 20, sizeCertified: 20 }, '');
            } else {
                props?.getStudentsOfProf(props?.connectedUser?.role, { entityId: props?.connectedUser?.idSchool, entityType: 'SCHOOL' }, { generalFilter: '', candidatesFilter: '', certifiedFilter: '' }, { sizeGeneral: 20, sizeCandidates: 20, sizeCertified: 20 }, '')
                getGroupBySchool(props?.schoolInfo?.id).then((response:any) =>{
                    setGroupList(response)
                    
                })
            }
        } else {
            let groupFounded = groupList.find((obj: any) => obj.id === Number(props?.match.params.id));
            setActualGroup(groupFounded);
        }
    }, [group])
    useEffect(() => {
        let groupFounded = groupList.find((obj: any) => obj.id === Number(props?.match.params.id));
   
        setActualGroup(groupFounded);
        props?.clearModal()
    }, [groupList])
   
  
    const openModalFn = () => {
        props?.openModal({ idModal: ModalIDS.ADD_STUDENTS_TO_GROUP, size: "lg", scroll: 'body',data:{groupForm:group} })
    }
    const doSearch= (value:boolean)=>{
        setIssearch(value)
    }
    const filterStudents=(filteredStudents:any) =>{
        setFilteredStudents(filteredStudents)
    }
    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF' }}>
                <HeaderGroupPageComponent group={group} tabGroupManager={tabGroupManager}  groupList={groupList} groupForm ={group} />
            </div>
            {(numberStudents!== 0) ? 
            <><div className="px-3">
                <FilterGroupPageComponent tabGroupManager={tabGroupManager} group={group} groupList={groupList}  isSearch={doSearch} filterStudents={filterStudents} groupForm={group} />
                </div>
                <div className="py-4">
                    <TableStudentGroupComponent tabGroupManager={tabGroupManager}  isSearch={isSearch} filteredStudents={filteredStudents} groupForm={group} groupList={groupList} />
                </div>
            </> :
            <div style={{paddingTop:"15rem"}}>
                    <EmptyListElement lines={[t('noStudentInscribed')]}>
                        {((tabGroupManager!=="all")||(tabGroupManager==="all" && group.childsList?.length===0)) &&<button type="button" className="btn-Primary  large icon-left" onClick={() => { openModalFn() }}>
                            <span className="material-icons-outlined">add</span>
                            <div className="m-auto ps-2">{t('addStudentToGroup')}</div>
                        </button>}
                    </EmptyListElement>
            </div>}


        </>

    )
}
const mapStateToProps = (state: AppState) => ({
    modalPage:state.modalReducer.modalPage,
    connectedUser: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        getStudentsOfProf, setActualTab,openModal,clearModal
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);