import { ComponentProps, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../../global-state/store/root-reducers";
import {setActualTabManageChat, getSubjectCommunity} from '../../../global-state/actions/chat-actions';
import FilterCommunityProgressComponent from '../../filters/filter-community-progress-component';

const ManageCommunityProgressComponent : FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [actualTab, setTab]= useState((props?.tabChatManager) ? props?.tabChatManager : "discussion");
    const [doSearch, setDoSearch] = useState(false);
    const setActualTab = (tab: string) => {
        props?.setActualTabManageChat(tab); 
        if ((tab === "discussion") && (actualTab !== tab)) {
            let subjects = props?.chapter?.id + " CHAPTER FORUM";
            let object = {
                subject: [subjects],
                trie: "recent",
                searchFilter: "",
                page: 0,
                refresh:false,
               
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }
        } else if ((tab === "question") && (actualTab !== tab)) {
            let subjects=props?.chapter?.id + " CHAPTER QUESTION";
            let object = {
                subject: [subjects],
                trie: "recent",
                searchFilter: "",
                page: 0,
                refresh:false,
                
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }
        }
    }
    const isSearching =(doSearchInput:any)=>{
        props?.Searching(doSearchInput);
        setDoSearch(true)
    }
    return(
        <>
        <div >
                <div className="row justify-content-center m-0">
                    <div className="col-md-12">
                        <div style={{maxWidth:'1200px', margin : 'auto'}}  className="row justify-content-center">
                            <div className="col-md-12 mb-5 d-flex  justify-content-center text-left mt-4 px-0">
                                <button className={(actualTab === 'discussion') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} onClick={() => {setActualTab('discussion'); setTab('discussion') }}>{t('discussionButton')}</button>
                                <button className={(actualTab === 'question') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} onClick={() => { setActualTab('question'); setTab('question' ) }}> {t('questionButton')}</button>
                            </div>
                        </div>
                    </div>
                    {(props?.listPosts.length!==0 || (doSearch && props?.listPosts.length===0)) && <div style={{maxWidth:'1200px', margin : 'auto'}} className="col-md-12">
                        <div className="row justify-content-between">
                            <FilterCommunityProgressComponent isSearching={isSearching} chapter ={props?.chapter}/>
                        </div>
                    </div>}
                </div> 
            </div>

        </>
    )
}
const mapStateToProps=(state: AppState, ownProps: any)=>({
    tabChatManager: state.chatReducer.tabChatManager,
    connectedUser: state.authReducer.connectedUser,
    listPosts: state.chatReducer.listPosts,
    Searching:ownProps?.Searching,
    chapter: ownProps?.chapter
   

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => 
bindActionCreators({ 
    setActualTabManageChat,
    getSubjectCommunity
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageCommunityProgressComponent);
