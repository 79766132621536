
import { EReduxActionTypes, IReduxGetOfferTrainingsAction,IReduxGetSizeSubAction, IReduxSaveChangeToAction,IReduxGetPriceIdAction, IReduxGetLimitationsAction, IReduxGetProductListAction, IReduxVerifyApiAction, IReduxGetPaymentSettingAction, IReduxGetCountsAction, IReduxGetCourseStatusAction, IReduxGetLocalisationAction, IReduxGetActiveStudentsAction, IReduxGetProfStatusAction, IReduxGetStudentStatusAction, IReduxGetOffersAction, IReduxGetContractAction, IReduxGetOfferAction, IReduxUpdateCourseFinanceAction, IReduxUpdatePriceAction, IReduxAllOfferPayedFinanceAction, IReduxAllPayedCourseFinanceAction, IReduxAllProfessorsByIdSchoolAction, IReduxGetYearsMonthAction, IReduxCumulSalesByOfferFinanceAction, IReduxCreatePaymeePayementAction, IReduxCheckPaymeePayementAction, IReduxClearPaymentStatusAction, IReduxConfirmUpdateOfferAction, IReduxCancelOfferUpdateAction, IReduxClearOfferToUpdateAction, IReduxGetSizeAction, IReduxGetSizePaymentAction, IReduxGetPaymentFiltereAction, IReduxGetListPaymentsAction } from '../actions/finance-actions';

export interface IPaymeeGateway {
  idVendor: number,
  paymentToken: string,
  paymentStatus?: boolean
}
export interface IReduxFinanceState {
  listOffersPayed?: any
  listProfessors?: any
  ListYearAndMonth?: any
  cumulSalesByOffer?: any
  ListPayedCourse?: any,
  ListPayedCoursefilter?: any,
  finance: any,
  updatedPrice: any,
  loadingOffer: boolean,
  loadingOffreScroll: boolean,
  loadingSub: boolean,
  loadingSubScroll: boolean,
  loadingFac: boolean,
  loadingFacScroll: boolean,
  loadingPayment: boolean,
  loadingPaymentScroll: boolean,
  paymeePayment: IPaymeeGateway,
  offerToUpdateDetails: any,
  oldOfferPrice: boolean,
  offer: any,
  contract: any,
  allOffers: any,
  courseStatus: any,
  profStatus: any,
  studentStatus: any,
  activeStudents: any,
  localisation: any,
  priceId: any,
  token: any,
  check: any,
  profiles: any,
  paymentStting: any,
  limitations: any,
  verification: any,
  pageNumber: any
  size: any,
  sizePayment: any,
  productList: any,
  offersTrainigs: any,
  paymentListSubscription: any,
  paymentListEasy: any,
  sizeSub:any,
  sizeFac:any,
  changeTo:any,
}




const initialState: IReduxFinanceState = {
  listOffersPayed: [],
  listProfessors: [],
  ListYearAndMonth: [],
  cumulSalesByOffer: null,
  ListPayedCourse: [],
  ListPayedCoursefilter: [],
  finance: undefined,
  updatedPrice: undefined,
  loadingOffer: true,
  loadingSub: true,
  loadingSubScroll: true,
  loadingFac: true,
  loadingFacScroll: true,
  loadingOffreScroll: true,
  loadingPaymentScroll: true,
  loadingPayment: true,
  paymeePayment: {
    idVendor: 2144,
    paymentToken: "",
    paymentStatus: undefined

  },
  offerToUpdateDetails: undefined,
  oldOfferPrice: false,
  offer: undefined,
  contract: undefined,
  allOffers: undefined,
  courseStatus: false,
  profStatus: false,
  studentStatus: false,
  activeStudents: undefined,
  localisation: undefined,
  priceId: undefined,
  token: undefined,
  check: undefined,
  profiles: undefined,
  paymentStting: undefined,
  limitations: undefined,
  verification: undefined,
  pageNumber: 0,
  size: undefined,
  sizePayment: undefined,
  productList: undefined,
  offersTrainigs: undefined,
  paymentListSubscription: undefined,
  paymentListEasy: undefined,
  sizeSub:undefined,
  sizeFac:undefined,
  changeTo:false
};

type TFinanceReducerActions = IReduxGetOfferTrainingsAction |IReduxGetSizeSubAction| IReduxGetListPaymentsAction|IReduxGetPaymentFiltereAction | IReduxGetProductListAction | IReduxGetSizePaymentAction | IReduxGetCourseStatusAction | IReduxGetSizeAction | IReduxVerifyApiAction | IReduxGetLimitationsAction | IReduxGetCountsAction | IReduxGetPaymentSettingAction | IReduxGetPriceIdAction | IReduxGetLocalisationAction | IReduxGetActiveStudentsAction | IReduxGetProfStatusAction | IReduxGetStudentStatusAction | IReduxGetOffersAction | IReduxGetContractAction | IReduxGetOfferAction | IReduxUpdateCourseFinanceAction | IReduxAllOfferPayedFinanceAction | IReduxConfirmUpdateOfferAction |
  IReduxAllProfessorsByIdSchoolAction | IReduxSaveChangeToAction|IReduxGetYearsMonthAction | IReduxClearPaymentStatusAction | IReduxCancelOfferUpdateAction | IReduxClearOfferToUpdateAction |
  IReduxCumulSalesByOfferFinanceAction | IReduxAllPayedCourseFinanceAction | IReduxUpdatePriceAction | IReduxCreatePaymeePayementAction | IReduxCheckPaymeePayementAction

export default function (state: IReduxFinanceState = initialState, action: TFinanceReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.LOADING_OFFER:
     return { ...state, loadingOffer: true }
    case EReduxActionTypes.SAVE_CHANGE_TO:
      return { ...state, changeTo: action.data }  
    case EReduxActionTypes.GET_LIST_PAYMENTS:
      if(action?.filter?.paymentType==="ABN"){
        let p = state.pageNumber;
        if (action.scrollOrNot === 'first') {
          return { ...state, paymentListSubscription: action.data, pageNumber: p + 1, loadingSub: false, loadingSubScroll: false }
        } else if (action.scrollOrNot === 'no') {
          return { ...state, paymentListSubscription: [...state.paymentListSubscription, ...action.data], pageNumber: p + 1, loadingSub: false, loadingSubScroll: false }
        } else {
          return { ...state }
        }
      }
      else if(action?.filter?.paymentType==="FAC"){
        let p = state.pageNumber;
        if (action.scrollOrNot === 'first') {
          return { ...state, paymentListEasy: action.data, pageNumber: p + 1, loadingFac: false, loadingFacScroll: false }
        } else if (action.scrollOrNot === 'no') {
          return { ...state, paymentListEasy: [...state.paymentListEasy, ...action.data], pageNumber: p + 1, loadingFac: false, loadingFacScroll: false }
        } else {
          return { ...state }
        }
      }else {
        return { ...state }
      }
 
      case EReduxActionTypes.GET_SIZE_SUB:
        if(action?.filter?.paymentType==="ABN"){
          return { ...state, sizeSub: action.data }
        }
        else if(action?.filter?.paymentType==="FAC"){
          return { ...state, sizeFac: action.data }
        }else {
          return { ...state }
        }
   
    case EReduxActionTypes.GET_PRODUCT_LIST:
      return { ...state, productList: action.data }
    case EReduxActionTypes.GET_OFFER_TRAININGS:
      return { ...state, offersTrainigs: action.data }
    case EReduxActionTypes.GET_SIZE:
      return { ...state, size: action.data }
    case EReduxActionTypes.GET_SIZE_PAYMENT:
      return { ...state, sizePayment: action.data }
    case EReduxActionTypes.LOADING_OFFER_SCROLL:
      return { ...state, loadingOffreScroll: true }
    case EReduxActionTypes.GET_COUNTS:

      return { ...state, profiles: action?.data }
    case EReduxActionTypes.GET_LIMITATIONS:
      return { ...state, limitations: action.data }
    case EReduxActionTypes.GET_PAYMENT_SETTING:
      return { ...state, paymentStting: action.data }
    case EReduxActionTypes.VERIFY_API:
      return { ...state, verification: action.data }
    case EReduxActionTypes.GET_PRICE_ID:
      return { ...state, priceId: action.data }
    case EReduxActionTypes.GET_LOCALISATION:
      return { ...state, localisation: action.data }
    case EReduxActionTypes.GET_COURSE_STATUS:
      return { ...state, courseStatus: action.data }
    case EReduxActionTypes.GET_PROF_STATUS:
      return { ...state, profStatus: action.data }
    case EReduxActionTypes.GET_STUDENT_STATUS:
      return { ...state, studentStatus: action.data }
    case EReduxActionTypes.GET_ACTIVE_STUDENTS:
      return { ...state, activeStudents: action.data }
    case EReduxActionTypes.LOADING_PAYMENT:
      return { ...state, loadingPayment: true }
    case EReduxActionTypes.GET_OFFER:
      return { ...state, offer: action.data }
    case EReduxActionTypes.GET_CONTRACT:
      return { ...state, contract: action.data }
    case EReduxActionTypes.UPDATE_FINANCE_COURSES:
      return { ...state, courseInfo: action.data };
    case EReduxActionTypes.ALL_OFFER_PAYED:
      let p = state.pageNumber;
      if (action.scrollOrNot === 'first') {
        return { ...state, listOffersPayed: action.data, pageNumber: p + 1, loadingOffer: false, loadingOffreScroll: false }
      } else if (action.scrollOrNot === 'no') {
        return { ...state, listOffersPayed: [...state.listOffersPayed, ...action.data], pageNumber: p + 1, loadingOffer: false, loadingOffreScroll: false }
      } else {
        return { ...state }
      }
    case EReduxActionTypes.ALL_PROFESSORS_BY_SCHOOL:
      return { ...state, listProfessors: action.data };
    case EReduxActionTypes.ALL_YEARS_MONTH:
      return { ...state, ListYearAndMonth: action.data };
    case EReduxActionTypes.CUMUL_SALES_OFFER:
      return { ...state, cumulSalesByOffer: action.data };
    case EReduxActionTypes.GET_OFFERS:
      return { ...state, allOffers: action.data };
    case EReduxActionTypes.ALL_PAYED_COURSE:
      let p2 = state.pageNumber;
      if (action.scrollOrNot === 'first') {
        return { ...state, ListPayedCourse: action.data, pageNumber: p2 + 1, loadingPayment: false, loadingPaymentScroll: false }
      } else if (action.scrollOrNot === 'no') {
        return { ...state, ListPayedCourse: [...state.ListPayedCourse, ...action.data], pageNumber: p2 + 1, loadingPayment: false, loadingPaymentScroll: false }
      } else {
        return { ...state }
      }
    case EReduxActionTypes.GET_PAYMENT_FILTER:
      return { ...state, ListPayedCoursefilter: action.data };
    case EReduxActionTypes.UPDATE_PRICE:
      return { ...state, updatedPrice: action.data.finance };
    case EReduxActionTypes.CREATE_PAYMENT:
      const updated = { ...state.paymeePayment }
      updated.paymentToken = action.data
      return { ...state, paymeePayment: updated, token: action.data };
    case EReduxActionTypes.CHECK_PAYMENT:
      return { ...state, paymeePayment: action.data, check: action.data };
    case EReduxActionTypes.CLEAR_PAYMENT_STATUS:
      const clear = { ...state.paymeePayment }
      clear.paymentStatus = action.data
      return { ...state, paymeePayment: clear };
    case EReduxActionTypes.MODIFY_OFFER_PRICE_CONFRIMATION:
      return { ...state, offerToUpdateDetails: action.data };
    case EReduxActionTypes.CANCEL_PRODUCT_OFFER_UPDATE:
      return { ...state, oldOfferPrice: action.data };
    case EReduxActionTypes.CLEAR_OFFER_TO_UPDATE:
      return { ...state, oldOfferPrice: false, offerToUpdateDetails: undefined };
    default:
      return state;
  }
}
