import { ComponentProps, FC, useMemo } from 'react';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AppState } from '../../../global-state/store/root-reducers';
import { useHistory } from 'react-router';
import { ModalIDS } from '../modal-ids';
import { createOrUpdateShoolPaymentInfo, showPaymentSchoolInfo } from '../../../global-state/actions/app-container-actions';
import iconWarning from '../../../styles/imgs/icon-warning.svg';



const DeletePaymentMethod: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const schoolInfo = useMemo(() => props?.connectedSchool, [props?.connectedSchool])




    const disconnectAccount = async () => {
        const copyInfo = { ...schoolInfo };
        copyInfo["isActivated"] = false;
        await props?.createOrUpdateShoolPaymentInfo(copyInfo);
        await props?.showPaymentSchoolInfo(copyInfo);
        await props?.closeModal()
    }

    return (
        <div>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>

            <div className="modal-body d-flex flex-column align-item-center align-text-center justify-content-center" style={{overflow:'hidden', paddingTop: 0, zIndex: 1000, textAlign: "center" }}>
                <img style={{ maxHeight: "56px" }} src={iconWarning} />
                <span className='body-md bold black-800 disconnect-payment-method-title-header pt-3'>Attention,</span>
                <span className='body-md bold black-800 disconnect-payment-method-title-header'>Voulez-vous vraiment déconnecter votre compte?</span>
                <span className='body-md pt-3 pb-3'>Si vous vous déconnectez, vous ne pourrez plus créer de formations payantes</span>

            </div>
            <div style={{ paddingBottom: 80, borderTop: "none" }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }} >
                    <button className="btn-Secondary large me-3" onClick={()=> props?.closeModal()} >{t('annuler')}</button>
                    <button type="button" className="btn-Primary large large" onClick={()=>{disconnectAccount()}} >{t('disconnect')}</button>
                </div>
            </div>


        </div>
    )
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal,
        createOrUpdateShoolPaymentInfo,
        showPaymentSchoolInfo
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    connectedSchool: state.schoolInfoReducer.paymentSchoolInfo,

});
export default connect(mapStateToProps, mapDispatchToProps)(DeletePaymentMethod);