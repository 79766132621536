import { ComponentProps, FC} from "react"
import { useTranslation } from "react-i18next";
import FooterComponent from "../../components/footer-component";
import koorsLogo from "./../../styles/images/KoorsLogo.png";


const DisabledSchoolPage: FC<ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation()

    return (  <>      
                <div className="page-wrapper-height mx-auto" style={{maxWidth: 1248}}>
                    <div className="d-flex flex-column mx-4">
                        <div className="d-flex py-5">
                        <a href="https://www.koors.io" target="__blank" ><img  height={66} src={koorsLogo} alt="koors logo" /></a>
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{marginTop: "5%"}}>
                            <svg width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M109.5 48.6673H103.417V36.5007C103.417 19.7107 89.7902 6.08398 73.0002 6.08398C56.2102 6.08398 42.5835 19.7107 42.5835 36.5007V48.6673H36.5002C29.8085 48.6673 24.3335 54.1423 24.3335 60.834V121.667C24.3335 128.359 29.8085 133.834 36.5002 133.834H109.5C116.192 133.834 121.667 128.359 121.667 121.667V60.834C121.667 54.1423 116.192 48.6673 109.5 48.6673ZM54.7502 36.5007C54.7502 26.4023 62.9018 18.2507 73.0002 18.2507C83.0985 18.2507 91.2502 26.4023 91.2502 36.5007V48.6673H54.7502V36.5007ZM109.5 121.667H36.5002V60.834H109.5V121.667Z" fill="#272727"/>
                                <path d="M85.1668 91.2487C85.1668 97.9404 79.6918 103.415 73.0002 103.415C66.3085 103.415 60.8335 97.9404 60.8335 91.2487C60.8335 84.557 66.3085 79.082 73.0002 79.082C79.6918 79.082 85.1668 84.557 85.1668 91.2487Z" fill="#CACBCE"/>
                            </svg>
                            <div className="mt-5 pt-3 text-center">
                                <span className="H3-Headline black-800 ">{t('closedSchool')}</span>
                            </div>
                            <div className="mt-3 text-center" style={{maxWidth:324 }}>
                                <span className="body-xl black-800">{t('hold')} <span className="body-xl bold link-btn"><a href="https://www.koors.io" target="__blank" >{t('contact1')}</a></span> {t('contact2')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent />
            </>)
}
  
export default DisabledSchoolPage;