import { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';


const FilterProfessorsListLoadingComponent: FC = () => {

    return (
        <div className="d-flex align-items-center justify-content-between flex-wrap mt-5">
            <Skeleton className="skeleton square" animation="wave" variant="rect" width={225} height={48} />
            <div className="d-flex align-items-center">
                <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={225} height={48} />
                <Skeleton className="skeleton square" animation="wave" variant="rect" width={225} height={48} />
            </div>
        </div >

    );
}

export default FilterProfessorsListLoadingComponent;
