import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { getStudent } from '../../../../global-state/actions/profile-actions';
import { getStudentsOfProf,getStudentsOfCourse, setActualTab } from '../../../../global-state/actions/professor-actions';
import { clearHistory } from '../../../../global-state/actions/breadcrumb-actions';
import "../../../../translations/i18n";
import { getMyStudentsByCourse, getStudents, getRegistrationForm, setRegistrationForm, setApplicationFile, getApplicationFile, getFollow, getStudentById } from '../../../../global-state/actions/course-actions';
import HeaderStudentsListTrainingComponent from '../../../../components/headers-page/header-student-list-training-component';
import FilterStudentsListComponent from '../../../../components/filters/filter-students-list-component';
import TableStudentsListTrainingComponent from '../../../../components/tables/table-student-list-training-component';
import HeaderStudentsListLoadingComponent from '../../../../components/headers-page/header-students-list-loading-component';
import FilterStudentsListLoadingComponent from '../../../../components/filters/filter-students-list-loading-component';
import TableStudentsListLoadingComponent from '../../../../components/tables/table-students-list-loading-component';
import TableAutorisationsTrainingComponent from '../../../../components/tables/table-autorisation-training-component';
import { getGroupBySchool, searchGroupByLabel } from '../../../../global-state/actions/group-actions';
import { debounce } from 'lodash';
const StudentsPathPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [generalFilter, setGeneralFilter] = useState("");
    const [candidatesFilter, setCandidatesFilter] = useState("");
    const [certifiedFilter, setCertifiedFilter] = useState("");
    const [inscribedFilter, setInscribedFilter] = useState("");
    const [suspendedFilter, setSuspendedFilter] = useState("");
    const [unpaidFilter, setUnpaidFilter] = useState("");

    const [sizeGeneral, setSizeGeneral] = useState(20);
    const [sizeCandidates, setSizeCandidates] = useState(20);
    const [sizeCertified, setSizeCertified] = useState(20);
    const [sizeInscribed, setSizeInscribed] = useState(20);
    const [sizeSuspended, setSizeSuspended] = useState(20);
    const [sizeUnpaid, setSizeUnpaid] = useState(20);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(props?.loadingAllStudentsList)
    }, [props?.loadingAllStudentsList]);
    useEffect(() => {
        props?.clearHistory()
        if (props?.connectedUser?.role === 'role_prof') {
            props?.getStudentsOfCourse(props?.training,
                { entityId: props?.connectedUser?.idSchool, entityType: 'SCHOOL', idProf: props?.connectedUser?.idPerson },
                { generalFilter: generalFilter, candidatesFilter: candidatesFilter, certifiedFilter: certifiedFilter, inscribedFilter: inscribedFilter, suspendedFilter: suspendedFilter ,unpaidFilter: unpaidFilter },
                { sizeGeneral: sizeGeneral, sizeCandidates: sizeCandidates, sizeCertified: sizeCertified, sizeInscribed: sizeInscribed, sizeSuspended: sizeSuspended, sizeUnpaid: sizeUnpaid  }, 'PATH');
        } else {
            props?.getStudentsOfCourse(props?.training,
                { entityId: props?.connectedUser?.idSchool, entityType: 'SCHOOL', idProf: null },
                { generalFilter: generalFilter, candidatesFilter: candidatesFilter, certifiedFilter: certifiedFilter, inscribedFilter: inscribedFilter, suspendedFilter: suspendedFilter  ,unpaidFilter: unpaidFilter},
                { sizeGeneral: sizeGeneral, sizeCandidates: sizeCandidates, sizeCertified: sizeCertified, sizeInscribed: sizeInscribed, sizeSuspended: sizeSuspended, sizeUnpaid: sizeUnpaid  }, 'PATH')
                props?.getGroupBySchool(props?.schoolInfo?.id)
        }
    }, [])
    const addDataList = (tabText: string) => {
        switch (tabText) {
            case 'general':
                setSizeGeneral(sizeGeneral + 20);
                filterList('All', sizeGeneral + 20)
                break;
            case 'candidates':
                setSizeCandidates(sizeCandidates + 20);
                filterList('Candidate', sizeCandidates + 20)
                break;
            case 'certified':
                setSizeCertified(sizeCertified + 20);
                filterList('Certified', sizeCertified + 20)
                break;
            case 'inscribed':
                setSizeInscribed(sizeInscribed + 20);
                filterList('Inscribed', sizeInscribed + 20)
                break;
            case 'suspended':
                setSizeSuspended(sizeSuspended + 20);
                filterList('Suspended', sizeSuspended + 20)
                break;
            case 'unpaid':
                setSizeUnpaid(sizeUnpaid + 20);
                filterList('Unpaid', sizeUnpaid + 20)
                break;                   
        }
    }
    const filterDataList = (tab: string, value: string) => {
        switch (tab) {
            case 'All':
                setGeneralFilter(value);
                filterList('AllText', value)
                break;
            case 'Candidate':
                setCandidatesFilter(value);
                filterList('CandidateText', value)
                break;
            case 'Certified':
                setCertifiedFilter(value);
                filterList('CertifiedText', value)
                break;
            case 'Inscribed':
                setInscribedFilter(value);
                filterList('InscribedText', value)
                break;
            case 'Suspended':
                setSuspendedFilter(value);
                filterList('SuspendedText', value)
                break;
            case 'Unpaid':
                setUnpaidFilter(value);
                filterList('UnpaidText', value)
                break;  
        }
    }
    const filterList = (tab: string, value: any) => {
        const stateCopy = {
            filters: {
                generalFilter: generalFilter,
                candidatesFilter: candidatesFilter,
                certifiedFilter: certifiedFilter,
                inscribedFilter: inscribedFilter,
                suspendedFilter: suspendedFilter,
                unpaidFilter: unpaidFilter
            },
            sizes: {
                sizeGeneral: sizeGeneral,
                sizeCertified: sizeCertified,
                sizeCandidates: sizeCandidates,
                sizeInscribed: sizeInscribed,
                sizeSuspended: sizeSuspended,
                sizeUnpaid: sizeUnpaid

            }
        };
        if (tab === 'All') {
            stateCopy.sizes.sizeGeneral = value;
        } else if (tab === 'Candidate') {
            stateCopy.sizes.sizeCandidates = value;
        } else if (tab === 'Certified') {
            stateCopy.sizes.sizeCertified = value;
        } else if (tab === 'Inscribed') {
            stateCopy.sizes.sizeInscribed = value;
        } else if (tab === 'Suspended') {
            stateCopy.sizes.sizeSuspended = value;
        } else if (tab === 'Unpaid') {
            stateCopy.sizes.sizeUnpaid = value;    
        }  else if (tab === 'AllText') {
            stateCopy.filters.generalFilter = value;
        } else if (tab === 'CertifiedText') {
            stateCopy.filters.certifiedFilter = value;
        } else if (tab === 'CandidateText') {
            stateCopy.filters.candidatesFilter = value;
        } else if (tab === 'InscribedText') {
            stateCopy.filters.inscribedFilter = value;
        } else if (tab === 'SuspendedText') {
            stateCopy.filters.suspendedFilter = value;
        } else if (tab === 'UnpaidText') {
            stateCopy.filters.unpaidFilter = value;
        }
        debouncedChangeHandler(stateCopy)

    }
    const changeList = (stateCopy: any) => {
        if (props?.connectedUser?.role === 'role_prof') {
            props?.getStudentsOfCourse(props?.training,
                { entityId: props?.connectedUser?.idSchool, entityType: 'SCHOOL', idProf: props?.connectedUser?.idPerson },
                 stateCopy.filters, stateCopy.sizes, 'COURSE')
        } else {
            props?.getStudentsOfCourse(props?.training,
                { entityId: props?.connectedUser?.idSchool, entityType: 'SCHOOL', idProf: null },
                 stateCopy.filters, stateCopy.sizes, 'COURSE')
        }
    }
    const debouncedChangeHandler = useCallback(debounce(changeList, 300), []);

    return (
        <div className="container-fluid">
            {!loading ? <div className="row justify-content-center">
                <HeaderStudentsListTrainingComponent trainingTitle={props?.training?.title} isDiplomat={props?.training?.isDiplomat} />
                {props?.lastTabClicked !== 'Groups' ?<>
                <FilterStudentsListComponent inCourse={true} data={{ generalFilter: generalFilter, candidatesFilter: candidatesFilter, certifiedFilter: certifiedFilter, inscribedFilter: inscribedFilter, suspendedFilter: suspendedFilter ,unpaidFilter:unpaidFilter}} addDataList={filterDataList} />
                <TableStudentsListTrainingComponent linkRole={'/courses/create-formation/'} typeTraining={'path'} data={{ sizeGeneral: sizeGeneral, sizeCandidates: sizeCandidates, sizeCertified: sizeCertified, sizeInscribed: sizeInscribed, sizeSuspended: sizeSuspended,sizeUnpaid:sizeUnpaid  }} addDataList={addDataList} /></>:
                <TableAutorisationsTrainingComponent training={props?.training} />} 
            </div> :
                <div className="row justify-content-center">
                    <HeaderStudentsListLoadingComponent />
                    <FilterStudentsListLoadingComponent />
                    <TableStudentsListLoadingComponent />
                </div>
            }

        </div>
    );
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    myStudents: state.courseReducer.myStudents,
    nameSchool: state.authReducer.connectedSchoolInfo?.code,
    connectedUser: state.authReducer.connectedUser,
    listeStudents: state.profReducer.listeStudents,
    training: state.trainingReducer.path,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    history: ownProps?.history,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    loadingAllStudentsList: state.profReducer.loadingAllStudentsList,
    schoolInfo: state.schoolInfoReducer.schoolInfo

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getMyStudentsByCourse,
            getStudents,
            getApplicationFile,
            getRegistrationForm,
            getFollow,
            getStudentById,
            getStudent,
            getStudentsOfProf,
            getStudentsOfCourse,
            setApplicationFile,
            setRegistrationForm,
            clearHistory,
            setActualTab,
            getGroupBySchool,
            searchGroupByLabel
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(StudentsPathPage);
