import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import InputSondage from "../../pages/sondage/InputSondage";
import InputSondageComponent from "./input-sondage-component";


const TitleSondageComponent: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = (props: any) => {
   
  useEffect(()=>{

  },[])
  return (
    <div className="container-title-desc-sondage">
      <InputSondageComponent
        title={"Titre du sondage*"}
        placeholder={props?.qnaTopass?.title}
        htmlForId={"inputTitleSondage"}
        classNamePlaceholder={""}
        bg={"bg-white"}
        from="title"
      />

      <InputSondageComponent
        title={"Description du sondage"}
        placeholder={props?.qnaTopass?.description}
        htmlForId={"inputDescSondage"}
        classNamePlaceholder={"description-sondage"}
        bg={"bg-gray"}
        from="description"
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  qnaTopass:state.examReducer.examToPass
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TitleSondageComponent);
