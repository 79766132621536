import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { useHistory } from 'react-router-dom';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { clearFormTest, setTypeFormContentCourse, setIndexContent, getCreateOrUpdate } from '../../../global-state/actions/course-actions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';

const ChoiceTypeTestModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [typeTest, setTypeTest] = useState<string>('');
    const [examExist, setExamExist] = useState<boolean>(false);
    const [listTests, setListTests] = useState<any>([]);
    const [courseForm, setCourseForm] = useState<any>();
    useEffect(() => {
        setListTests(props?.listTest?.filter((test: any) => test.qnaType === "KNOWLEDGETEST"));
        if (props?.listTest?.filter((test: any) => test.qnaType === "FINALEXAM").length > 0) {
            setExamExist(true);
        } else {
            setExamExist(false);
        }
    }, [props?.listTest]);
    useEffect(() => {
        setCourseForm(props?.courseForm);
    }, [props?.courseForm]);
    const goTo = async () => {
        props?.clearHistoryArray()
        props?.redirect(t('courseTable'), `${returnStartPathUrl()}/courses/my-courses`)
        props?.redirect(courseForm.title || t('titleCourseForm'), `${returnStartPathUrl()}/courses/create-formation/course/content`)
        if (typeTest === 'TEST') {
            props?.redirect(t("textConnaissance"), `${returnStartPathUrl()}/courses/create-formation/course/chapter-test`)
            await props?.clearFormTest();
            await props?.setTypeFormContentCourse('KNOWLEDGETEST');
        } else {
            props?.redirect(t('finalExmBreadcrunbTitle'), `${returnStartPathUrl()}/courses/create-formation/course/chapter-test`)
            await props?.clearFormTest();
            await props?.setTypeFormContentCourse('FINALEXAM')
        }
        await props?.getCreateOrUpdate('create');
        await props?.setIndexContent(props?.data?.data.index);
         history.push(`${returnStartPathUrl()}/courses/create-formation/course/chapter-test`)
        await props?.closeModal();
    }
    return (
        <>
            <div className="d-flex w-100 align-items-center justify-content-end pt-3 pe-3">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
            <div className="d-flex align-items-center flex-column justify-content-center">
                <h4 className="H3-Headline mb-3">{t('newTestModalTitle')}</h4>
                <p className="body-md bold mt-3 mb-3">{t('modal_exam_title')}</p>
                <div className="mt-2 d-flex align-items-start justify-content-center w-100">
                    <div className="me-3 d-flex flex-column justify-content-center" style={{ maxWidth: 248 }}>
                        <input dir="ltr"readOnly={true} className="input-style-radio-button" type="radio" name="exampleRabuttonTestdios2" id="buttonTest" value="buttonTest" onClick={() => setTypeTest('TEST')} checked={(typeTest === 'TEST')} />
                        <label htmlFor="buttonTest">{t('TEST_DE_CONNAISSANCE')}</label>
                        <div className="text-center d-flex flex-column mt-3">
                            <span className="body-sm neutral-2"> - {t('Test_intermédiaire')}  </span>
                            <span className="body-sm neutral-2"> - {t('Non_éliminatoire')} </span>
                            <span className="body-sm neutral-2"> - {t('Question_fermées')} </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{ width: "100%", maxWidth: 248 }}>
                        <input dir="ltr"readOnly={true} className="input-style-radio-button" type="radio" name="buttonExam" id="buttonExam" value="buttonExam" onClick={() => setTypeTest('EXAM')} disabled={examExist} checked={(typeTest === 'EXAM')} />
                        <label className="w-100 text-center" htmlFor="buttonExam">{t('EXAMEN_FINAL')}</label>
                        <div className="mt-3 d-flex flex-column text-center">
                            {examExist ?
                                <p className="body-sm " style={{ color: "#F5B95F" }}> {t('alreadyCreateExam')}</p>
                                :
                                <>
                                    <span className="body-sm neutral-2"> - {" "} {t('Test_de_fin_de_cours')} </span>
                                    <span className="body-sm neutral-2"> - {" "} {t('Validant_le_diplome')} </span>
                                    <span className="body-sm neutral-2"> - {" "} {t('Question_fermée_et_ou_ouvertes')} </span>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className="" style={{ paddingTop: "45px", paddingBottom: "50px", alignItems: "center" }}>
                    <button type="button" className="btn-Secondary large" onClick={props?.closeModal} >{t('annuler')}</button>
                    <button type="button" disabled={(typeTest === '')} className="btn-Primary large" style={{ marginLeft: "12px" }} onClick={() => { goTo() }} data-bs-dismiss="modal">{t('validRegister')}</button>
                </div>
            </div>
        </>
    )

}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        openModal,
        closeModal,
        clearFormTest,
        setIndexContent,
        setTypeFormContentCourse,
        getCreateOrUpdate,
        redirect, clearHistoryArray
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    courseForm: state.courseReducer.courseForm,
    listTest: state.courseReducer.listTest,
    data: state.modalReducer.modalContent
});
export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTypeTestModalComponent);