import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { getUserProfileDetails } from '../../global-state/actions/auth-actions';

import { getActualTab } from '../../global-state/actions/auth-actions';
import AddCustomerComponent from '../../components/subscription/add-customer-component';
const FacturationPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const stripePromise = loadStripe(`${process.env.REACT_APP_BASE_STRIPE_KEY}`);
    return (

        <>

            <div className="d-flex flex-column w-100 my-5 py-3 px-4" style={{ margin: 'auto', backgroundColor: '#FBFBFD', maxWidth: '778px'}}>
                <div className=' pt-2'>

                    <div >
                        <Elements stripe={stripePromise}>
                            <AddCustomerComponent />
                        </Elements>
                    </div>
                </div>

            </div>

        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loadingProfile: state.authReducer.loadingProfile,
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    actualTab: state.authReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails, getActualTab
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacturationPage);