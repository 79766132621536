import { ComponentProps, FC, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';
import {sendMyRequest}  from '../../../global-state/actions/course-actions';
import { useHistory, useLocation } from 'react-router-dom';
import { AppState } from "../../../global-state/store/root-reducers";
import { returnDynamicValueFromUrl } from '../../../helpers/domainCheck';

const SuccessRegistrationModalComponent : FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> &  ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const data = useMemo(() => {return props?.content}, [props?.content]);
    const redirect=()=>{
        if(data.path){
            history.push(data.path);
        }
        props?.closeModal();
    }
    const cancelValidation = async ()=>{
    let status = {
      status: "CANDIDATE",
      follow: { id: returnDynamicValueFromUrl(3) }
    };
    await props?.sendMyRequest(status,null,null,null);
    await props?.closeModal();

    }
    return(
        <>
            <div className="modal-header" style={{  border: "none" }}>
                <div className="d-flex w-100 align-items-center justify-content-end">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body" >
                <div className="row body-modal-publish">
                    <div className="col-md-8 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 text-center mb-1">
                                <svg width="41" height="30" viewBox="0 0 41 30" fill="none" >
                                    <path d="M13.0001 23.8001L4.83348 15.6334C3.92348 14.7234 2.47681 14.7234 1.56681 15.6334C0.656808 16.5434 0.656808 17.9901 1.56681 18.9001L11.3435 28.6767C12.2535 29.5867 13.7235 29.5867 14.6335 28.6767L39.3668 3.96674C40.2768 3.05674 40.2768 1.61008 39.3668 0.700078C38.4568 -0.209922 37.0101 -0.209922 36.1001 0.700078L13.0001 23.8001Z" fill="#9DDBA9" />
                                </svg>

                            </div>
                            
                            <div className="d-flex w-100 align-items-center justify-content-center flex-column mt-4 text-center">
                               { data?.lines?.map((line:string, i: number) => (
                               <span key={i}className="H4-Subtitle">{line}</span>
                               ))}
                            </div>
                            {data?.buttonText && <div className="col-md-12 mt-4 mb-5 text-center">
                            <button type="button" style={{minWidth: 123}} className="btn-Primary large" onClick={()=>{redirect()}}>{data?.buttonText}</button>
                            </div>}
                            <div className="col-md-12 mt-4 mb-5 text-center">
                            <button type="button" style={{minWidth: 123}} className="btn-text outlined" onClick={()=>{cancelValidation()}}>{t('cancelValidation')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
const mapStateToProps = (state: AppState) => ({
  content: state.modalReducer.modalContent,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators( {
            closeModal,
            sendMyRequest
        },
        dispatch
    );
    export default connect(mapStateToProps,mapDispatchToProps)(SuccessRegistrationModalComponent);