import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import DoneIcon from '@material-ui/icons/Done';
import LinearProgress from '@material-ui/core/LinearProgress';

interface IProps {
    progress: string | number;
    transcoding: any;
    readyToStream: any;
    uploading: any
}

const LoadingUploadVideoModalComponent = ({ progress, transcoding, readyToStream }: IProps) => {
  const { t } = useTranslation();

  const [checkUpload, setCheckUpload] = useState<boolean>(false)

  useEffect(() => {

    checkUploadFinished(progress === 100 , checkUpload)
  }, [progress, checkUpload])
  
  const checkUploadFinished = (isChecked: boolean, checkLastValue: boolean) => {
    if(checkLastValue) {
        return;
    } else {

        setCheckUpload(isChecked);
    }
  }
  const progressBarStyle = {
    backgroundColor: "#000",
    width: `${progress}%`,
    height: "4px",
    borderRadius: '3px'
  }
  const defaultProgressBarStyle = {
    backgroundColor: "#000",
    width: `0px`,
    height: "5px",
    borderRadius: '3px'
  }

  return (
    <div className="modal-body my-3">
      <div className="row justify-content-center border-dashed p-15">
        <div
          className="import-video col-md-12 center text-center h-130"
          style={{ margin: "auto" }}
        >
          <div className="success-icon h-30">
            {checkUpload && <DoneIcon />}
          </div>
          <div className="container-progress-bar mt-20px">
            <div 
                className="progress-bar" 
                style={progressBarStyle}
            ></div>
          </div>
          <p className="H3-Headline m-0">{t('importVideo')}</p>
          {checkUpload && <p className="body-md form-default m-0">
            {t('importVideoSuccess')}
          </p>}

        
        </div>
        <div 
            className="transcoding-Video col-md-12 center text-center mb-3"
            style={{ margin: "auto" }}
        >
      
          <div className="container-progress-bar">
          {(transcoding || (readyToStream==='false' || readyToStream==='true'))
            ?  <LinearProgress />
            : <div className="progress-bar" style={defaultProgressBarStyle}></div>}
          </div>
          <p className="H3-Headline m-0">{t('transcodeVideo')}</p>
          <p className="body-md form-default m-0">
            {t('transcodeInProgress')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingUploadVideoModalComponent
