import { relativeTimeRounding } from "moment";
import { fetchData } from "../base/functions/Functions";

const baseUrl = process.env.REACT_APP_BASE_URL2;

export async function getGroupBySchool(idSchool: any) {
  try {
    const response = await fetchData(
      "GET",
      `${baseUrl}group/findGoursByLegalPerson/${idSchool}`,
      {}
    );
    const groupList = response.sort((a: any, b: any) => {
      if (a.id < b.id) return -1;
      if (a.id > b.id) return 1;
      return 0;
    });
    return groupList;
  } catch (error) {
    console.error("Error fetching groups by school:", error);
  }
}
export async function searchGroupByLabel(search: any, idSchool: any) {
  try {
    const response = await fetchData(
      "GET",
      `${baseUrl}group/searchGroup/${idSchool}/${search}`,
      {}
    );
    return response;
  } catch (error) {
    console.error("Error searching group by label:", error);
  }
}
export async function createGroup(group: any) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/createOrUpdate`,
      group
    );
    const newGroup = response;
    newGroup.personList = group.personList ? group.personList : [];
    newGroup.childsList = group.childsList ? group.childsList : [];
    return newGroup;
  } catch (error) {
    console.error("Error occurred while creating group:", error);
    throw error;
  }
}

export async function filterStudentInGroup(filter: any, idSchool: number) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/searchStudent/${idSchool}`,
      filter
    );
    return response;
  } catch (error) {
    console.error("Error occurred while filtering students in group:", error);
    throw error;
  }
}

export async function affectStudentToGroup(objects: any) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/affectPersonToGroup/${objects.idGroup}/${
        objects.idSchool
      }/${objects.idGroupParent}`,
      objects.person
    );

    return response;
  } catch (error) {
    console.error("Error occurred while affecting student to group:", error);
    throw error;
  }
}

export async function disaffectStudentFromGroup(objects: any) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/disaffectPersonToGroup/${objects.idGroup}/${
        objects.idSchool
      }/${objects.idGroupParent}`,
      objects.person
    );
    return response;
  } catch (error) {
    console.error("Error occured while disaffecting student to group");
  }
}

export async function getGroupByStudent(idStudent: number) {
  try {
    const response = await fetchData(
      "GET",
      `${baseUrl}group/findGoursByNaturalPerson/${idStudent}`,
      {}
    );
    return response;
  } catch (error) {
    console.error("Error occured while getting group by student");
  }
}

export async function addSubGroup(subGroup: any, idParentGroup: number) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/addSubGroupToGroup/${idParentGroup}`,
      subGroup
    );
    return response;
  } catch (error) {
    console.error("Error occured while adding a subGroup");
  }
}

export async function removeSubGroup(subGroup: any, idParentGroup: number) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/removeSubGroupFromGroup/${idParentGroup}`,
      subGroup
    );
    return response;
  } catch (error) {
    console.error("Error occured while removing a subGroup");
  }
}
export async function getFiltredGroup(filter:any) {
  try {
    const response = await fetchData(
      "POST",
      `${baseUrl}group/findGoursByIds/`,
      filter
    );
    return response;
  } catch (error) {
    console.error("Error occured while removing a subGroup");
  }
}

