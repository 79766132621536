import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import { reinstateTeamMember } from '../../../global-state/actions/professor-actions';
import { getOffer } from '../../../global-state/actions/app-container-actions';


const LimitationNotificationModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    let [commentReinstate, setCommentReinstate] = useState('');



    const goAction = async () => {

        await props?.openModal({ idModal: ModalIDS.COMPARISATION_MODAL, size: "md", path: "", selectedOffer: props?.content?.selectedOffer, limitations: props?.content?.limitations });
    }

    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="body-modal-publish">
                <div className="mx-5 px-5 center" style={{ margin: 'auto' }}>
                      
                            <div className="d-flex flex-column">

                                <div className='d-flex flex-row align-items-center mb-4'>
                                    <span className="material-icons Orange-800 me-2" style={{ fontSize: 32 }} >warning</span>
                                    <span className="H3-Headline align-items-center mt-2"> {t('warning')}</span>
                                </div>
                         

                            </div>
                            <div >
                                <span className="body-sm">{t('schoolUses')}{props?.content?.actualOffer?.label}{t('notIncludedInOffer')}{props?.content?.selectedOffer}.
                                 {t('passToOffer')}{props?.content?.selectedOffer}{t('allFonctionalities')}{props?.content?.actualOffer?.label}{t('areDesactivated')}</span>



                            </div>

                            <div className="mt-4 mb-4">
                                <button className="btn-text outlined" onClick={() => { props?.closeModal() }}> {t('compareOffers')}</button>
                            </div>

                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingTop: 0, border: "none" }}>
            <div className=" d-flex mt-3 mb-5 mx-5 px-5">
                    <div className='col-md-12 text-center justify-content-center buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large" onClick={() => { props?.closeModal() }} style={{ textTransform: 'uppercase', marginRight: '2%' }} >{t('annuler')}</button>
                        <button type="button" className="btn-Primary large " onClick={() => { goAction(); }}>{t('continue')}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            reinstateTeamMember,
            getOffer
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(LimitationNotificationModalComponent);