import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
const CardCourseHorizontalLoading: FC<ComponentProps<any>> = () => {

  return (
    <div className="card flex-row w-100">
      <Skeleton variant="text" animation="wave" height={186} className="skeleton image-horiz" />
      <div className="card-body horiz p-4 bg-with-border">
        <div className="H3-Headline pb-2">
          <Skeleton variant="text" animation="wave" className="skeleton text" />
        </div>
        <div className="card-text">
          <div className="body-sm bold py-4">
            <Skeleton variant="text" animation="wave" width={'40%'} className="skeleton text" />
          </div>
        </div>
        <div className="d-flex justify-content-start pt-2">
          <Skeleton variant="text" animation="wave" width={'25%'} className="skeleton text" />
        </div>
      </div>
    </div>
  );
}

export default CardCourseHorizontalLoading;
