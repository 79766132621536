import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getCourseChapters, changeStyleCardCourse, getRegistrationForm, getCourseTests, getMyCourses, createCourse, getMyCoursesProfessor, clearFormCourse, getCourse } from '../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardCourseVerticalLoading from "./card-course-student/card-course-vertical-loading-component";
import CardCourseHorizontalLoading from "./card-course-student/card-course-horizontal-loading-component";
import CardCourseVerticalComponent from "./card-course-professor/card-course-vertical-component";
import CardCourseHorizontalComponent from "./card-course-professor/card-course-horizontal-component";
import { getTrainingById, getTest, getMyTrainings, getTrainingNumber, clearMyTrainings, getSummaryTrainings } from "../../global-state/actions/training-actions";
import { formatNumber } from "../../base/functions/Functions";
import { getCountCourses } from "../../global-state/actions/catalog-actions"
import { returnStartPathUrl } from "../../helpers/domainCheck";

let outsideAtBottom = false;
const ProfessorListCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [follows, setFollows] = useState<any[]>([]);
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
    const [trainingLength, setTrainingLength] = useState(null);
    const [atBottom, setAtBottom] = useState(false);
    outsideAtBottom = atBottom;
    const pageNumber = useMemo(() => props?.pageNumber ? props?.pageNumber : 0, [props?.pageNumber])
    const [fetching, setFetching] = useState<boolean>(false)

    useEffect(() => {
        setLoadingScroll(props?.loadingScrollTrainings);
    }, [props?.loadingScrollTrainings])
    useEffect(() => {
        setTrainingLength(props?.trainingNumber);
    }, [props?.trainingNumber])
    useEffect(() => {
        if (atBottom) {
            getData()
        }
    }, [atBottom])
    useEffect(() => {
        props?.getCountCourses(props?.schoolInfo?.id)
    }, [props?.schoolInfo])
    useEffect(() => {
        props?.clearMyTrainings()
        props?.getTrainingNumber(props?.connectedUser?.idPerson, props?.schoolInfo?.id)
        let filterFormations = {
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL",
            idProf: props?.connectedUser?.idPerson,
            pageSize: 6,
            pageNumber: 0
        }
        props?.getSummaryTrainings(filterFormations, 'first time');

    }, [])

    const getData = async () => {
        
        if (trainingNumber > follows?.length && !loadingScroll  && fetching===false) {
            await setFetching(true)
            let filter = {
                entityId: props?.schoolInfo?.id,
                entityType: "SCHOOL",
                idProf: props?.connectedUser?.idPerson,
                pageSize: 6,
                pageNumber: pageNumber
            }
            await props?.getSummaryTrainings(filter, 'scroll');
            await setFetching(false)
        }
    }


    const trainingNumber = useMemo(() => { return props?.trainingNumber }, [props?.trainingNumber])
    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);


    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })


    const handleScroll = async () => {
        if (!(atBottom) && ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight ) && fetching ===false) {
            getData()
            document.removeEventListener('scroll', handleScroll);
        }
        if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        if (props?.myCourses) {
            setFollows(props?.myCourses)
        }
    }, [props?.myCourses]);



    const goToCreateCourse = async (id: number, course: any) => {
        await props?.getCourseChapters(id, course);
        await props?.getCourseTests(id);
        if (course.idRegistrationForm !== undefined && course.idRegistrationForm !== null && course.idRegistrationForm !== 0) {
            await props?.getRegistrationForm(course.idRegistrationForm);
        }
        history.push(`${returnStartPathUrl()}/courses/create-formation/course/description`);
    }


    const goToTraining = async (follow: any) => {

        if (follow.type === "COURSE") {
            await goToCreateCourse(follow.id, follow);
        } else {
            await props?.getTrainingById(follow?.type.toLowerCase().concat("s"), follow?.id)
            if (follow.idRegistrationForm !== undefined && follow.idRegistrationForm !== null && follow.idRegistrationForm !== 0) {
                await props?.getRegistrationForm(follow.idRegistrationForm);
            }
            await props?.getTest(follow?.type, follow?.id)
        }

        if (follow.type === "COURSE") {
            await goToCreateCourse(follow.id, follow);
        } else {
            await props?.getTrainingById(follow?.type.toLowerCase().concat("s"), follow?.id)
            await props?.getTest(follow?.type, follow?.id)
        }

        history.push(`${returnStartPathUrl()}/courses/create-formation/${follow?.type.toLowerCase()}/description`);
    }

    const createCourseFn = async () => {
        await props?.clearFormCourse();
        props?.clearHistoryArray()
        props?.redirect(t('createNewFormation', `${returnStartPathUrl()}/courses/my-courses`))
        history.push(`${returnStartPathUrl()}/courses/create-formation/choice`);
    }

    return (

        <>
            <div className="container-fluid">
                <span id='step-training-page_prof'></span>
                <div className="row justify-content-center">
                    <div className="col-md-12 py-4" style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="d-flex align-items-center w-100 justify-content-between flex-wrap mx-auto my-3" style={{ padding: '0px 12px', maxWidth: '1200px' }}>
                            <div className="d-flex align-items-center">
                                <span className="H2-Headline neutral-3 me-3">{t('TitleFormations')}</span>
                                {((trainingNumber && trainingNumber >= 0) || (trainingLength !== null && trainingLength !== undefined)) ? <span className="H2-Headline">{formatNumber(trainingNumber)}</span> :
                                    <div className="threedots-large-Animation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>}
                            </div>
                            <div className="d-flex align-items-center">
                                {(follows?.length > 0) && <> <div className="d-flex align-items-center me-4">
                                    <span className="material-icons-outlined" onClick={() => props?.changeStyleCardCourse('horizontal')} style={{ cursor: 'pointer', color: `${(props?.styleCardCourse === 'horizontal') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> view_headline </span>
                                    <span className="material-icons-outlined" onClick={() => props?.changeStyleCardCourse('vertical')} style={{ cursor: 'pointer', color: `${(props?.styleCardCourse === 'vertical') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> apps </span>
                                </div>
                                    <button className="btn-Primary large icon-left" onClick={() => createCourseFn()}>
                                        <span className="material-icons">add</span>
                                        <div className="m-auto ps-2">{t('createNewFormation')}</div>
                                    </button>
                                </>}

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ margin: "50px auto", maxWidth: '1200px' }}>
                        {!loading && <div className={`${(follows?.length === 0 || (props?.styleCardCourse === "horizontal")) ? "d-flex w-100 flex-wrap align-items-center justify-content-between" : "grid-cards p-0"}`}>
                            {(follows?.length === 0) &&
                                <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                                    <EmptyListElement lines={[t('noCoursesCreated'), t('noCoursesCreated_2')]} >
                                        <button type="button" className="btn-Primary large icon-left" onClick={() => { createCourseFn() }}>
                                            <span className="material-icons-outlined">
                                                add
                                            </span>
                                            <div className="m-auto ps-2">{t('createNewFormation')}</div>
                                        </button>
                                    </EmptyListElement>
                                </div>
                            }
                            {(follows?.length > 0) &&
                                <>
                                    {follows?.map((follow: any, index: number) => {
                                        return (props?.styleCardCourse === "vertical") ?

                                            <div className=" justify-content-center">
                                                <CardCourseVerticalComponent key={index + 1} follow={follow} onClick={() => { goToTraining(follow) }} />
                                            </div> :
                                            <div className="col-md-12 mb-3">
                                                <CardCourseHorizontalComponent key={index + 1} follow={follow} onClick={() => { goToTraining(follow) }} />
                                            </div>
                                    })}
                                </>
                            }
                        </div>}
                        {loading && <div className={`${(props?.styleCardCourse === "vertical") ? "grid-cards p-0" : "d-flex w-100 flex-wrap align-items-center justify-content-between"}`}>
                            {(props?.styleCardCourse === "vertical") ?
                                <>
                                    <div className="mb-3 justify-content-center mt-4">
                                        <CardCourseVerticalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center mt-4">
                                        <CardCourseVerticalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center mt-4">
                                        <CardCourseVerticalLoading />
                                    </div>
                                </> :
                                <>
                                    <div className="mb-3 justify-content-center w-100">
                                        <CardCourseHorizontalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center w-100">
                                        <CardCourseHorizontalLoading />
                                    </div>
                                    <div className="mb-3 justify-content-center w-100">
                                        <CardCourseHorizontalLoading />
                                    </div>
                                </>
                            }
                        </div>}
                        {(loadingScroll && follows?.length > 0) &&
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>

    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    myCourses: state.trainingReducer.trainings,
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    styleCardCourse: state.courseReducer.styleCardCourse,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    loading: state.trainingReducer.loadingTrainings,
    loadingScrollTrainings: state.trainingReducer.loadingScrollTrainings,
    trainingNumber: state.trainingReducer.trainingNumber,
    pageNumber: state.trainingReducer.pageNumber,
    subscription: state.schoolInfoReducer.subscription,
    courseNumber: state.catalogReducer.courseNumber
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getMyCourses,
            getMyCoursesProfessor,
            getCourseChapters,
            clearFormCourse,
            getRegistrationForm,
            changeStyleCardCourse,
            getCourse,
            getCourseTests,
            createCourse,
            getMyTrainings,
            getSummaryTrainings,
            getTrainingById,
            getTest,
            getTrainingNumber,
            getCountCourses,
            redirect,
            clearHistoryArray,
            clearMyTrainings
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProfessorListCardComponent);