import { FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import userLogo from '../../../styles/imgs/Default_user_icon.svg';
import { AppState } from "../../../global-state/store/root-reducers";
import { useTranslation } from "react-i18next";
const moment = require('moment');
interface Props {
    post: any
    t: any
}

const ResponseCard: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props) => {
    const dateFormat = require('dateformat');
    const { t } = useTranslation();
    const [post] = useState(props?.post)
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else { moment.defineLocale('en', { parentLocale: 'en' }); }
    }, [props?.post])
    return (
        <>
            <div className="mb-3">
                <div className="d-flex align-items-top mb-2">
                    <div className="me-3">
                        <img className="comment-reply pic me-2 mt-auto" src={(post?.idAuthor?.photo) ? post.idAuthor?.photo : userLogo} alt="profile img" />
                    </div>
                    <div className="d-flex flex-wrap badge-text dark align-items-center">
                        {(post.idAuthor && post.idAuthor.relation && post.idAuthor.relation.relationType && post.idAuthor.relation.relationType === "PROF") ?
                            <span className="body-sm bold me-3 discuttion-titles  mt-2">{post?.idAuthor?.firstName + " " + post?.idAuthor?.lastName + " / "}<span className="badge-text primary">{t('professeur')}</span></span> :
                            <span className="body-sm bold me-3 mt-2 discuttion-titles">{post?.idAuthor?.firstName + " " + post?.idAuthor?.lastName}</span>}
                        <div className="d-flex flex-wrap discuttion-titles  mt-2">
                            <span className="body-sm bold neutral-3 me-3">{(moment(Date()).diff(moment(post?.createdAt), 'days') < 1) ? moment(post?.createdAt).fromNow() : dateFormat(post.createdAt, "dd/mm/yyyy")}</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column ms-5 ps-1">
                    {post && post.comment && post.comment.description &&
                        <span className="body-md neutral-2">{post.comment.description}</span>
                    }
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ResponseCard);