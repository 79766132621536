import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { UpdateSchool } from '../../global-state/actions/auth-actions';
import { SchoolLogoImageCropping } from '../../UI/ImageCrop/SchoolLogoCropping';
import ColorPiker from '../../elements/color-picker/color-picker-element';
import SelectElementWithIcon from '../../elements/select/select-element-with-icons';
import axiosInstance from '../../helpers/interceptors';
import { getSchoolInfo, updateSchoolInfo } from '../../global-state/actions/app-container-actions';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "../../elements/edit-toolbar-quill/edit-toolbar-quill";
import { debounce } from 'lodash';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';

const SchoolInfoUpdateComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [coverPhoto, setCoverPhoto] = useState<any>();
    const [name, setName] = useState<any>()
    const [centralLogo, setCentralLogo] = useState<any>();
    const [code, setCode] = useState();
    const [creationDate, setCreationDate] = useState();
    const [description, setDescription] = useState();
    const [logo, setLogo] = useState<any>();
    const [shortDescription, setshortDescription] = useState();
    const [color, setColor] = useState<any>()
    let idSchool = useMemo(() => props?.idSchool, [props?.idSchool])


    
    


    useEffect(() => {
        setName(props?.connectedSchool.name)
        setCentralLogo(props?.connectedSchool.logo)
        setCoverPhoto(props?.connectedSchool.coverPhoto)
        setCode(props?.connectedSchool.code)
        setCreationDate(props?.connectedSchool.creationDate)
        setDescription(props?.schoolInfo?.description)
        setLogo(props?.connectedSchool.logo)
        setshortDescription(props?.connectedSchool.shortDescription)
        setColor(props?.connectedSchool.color)

        props?.getSchoolInfo(props?.connectedSchool.code)
    }, [props?.connectedSchool])

    const save = () => {
        let object: any = {
            "id": idSchool,
            "code": code,
            "color": color,
            "coverPhoto": coverPhoto,
            "centralLogo": centralLogo,
            "creationDate": creationDate,
            "description": props?.schoolInfo?.description,
            "enabled": "Enabled",
            "logo": logo,
            "name": name,
            "shortDescription": shortDescription,
            "domain": props?.schoolInfo?.domain,
        }
        props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)

    }

    const saveLog = (logoSchool: any) => {
        let object: any = {
            "id": idSchool,
            "code": code,
            "color": color,
            "coverPhoto": coverPhoto,
            "centralLogo": centralLogo,
            "creationDate": creationDate,
            "description": props?.schoolInfo?.description,
            "enabled": "Enabled",
            "logo": logoSchool,
            "name": name,
            "shortDescription": shortDescription,
            "domain": props?.schoolInfo?.domain,
        }
        props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)
    }


    const saveCover = (cover: any) => {
        let object: any = {
            "id": idSchool,
            "code": code,
            "color": color,
            "coverPhoto": cover,
            "centralLogo": props?.connectedSchool.logo,
            "creationDate": creationDate,
            "description": props?.schoolInfo?.description,
            "enabled": "Enabled",
            "logo":props?.connectedSchool.logo ,
            "name": name,
            "shortDescription": shortDescription,
            "domain": props?.schoolInfo?.domain,
        }
        props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)
    }

    const onGetBlobFil = (blobFile: any) => {
        const baseUrlGed = process.env.REACT_APP_BASE_URL4;
        if (blobFile === null)
            setLogo(null)
        else {
            const form = new FormData();
            form.append('file', blobFile[0]);
            axiosInstance.post(baseUrlGed + "upload/image",
                form
            ).then((response) => {
                setLogo(response.data.link)
                saveLog(response.data.link)
                setTimeout(() => {
                    props?.getSchoolInfo(props?.connectedSchool.code)
                }, 1000)
            })
        }
    }
    
    const onGetBlobFilCover = (blobFile: any) => {
        const baseUrlGed = process.env.REACT_APP_BASE_URL4;
        if (blobFile === null)
        setCoverPhoto(null)
        else {
            const form = new FormData();
            form.append('file', blobFile[0]);
            axiosInstance.post(baseUrlGed + "upload/image",
                form
            ).then((response) => {
                setCoverPhoto(response.data.link)
                saveCover(response.data.link)
                setTimeout(() => {
                    props?.getSchoolInfo(props?.connectedSchool.code)
                }, 1000)
            })
        }
    }



    const textAreaAdjust = (element: any, tag: string) => {
        element.style.height = "1px";
        element.style.height = (12 + element.scrollHeight) + "px";
        setshortDescription(element.value);
    }

    const updateColor = async (hexcolor: any) => {
        setColor(hexcolor)
        let object: any = {
            "id": idSchool,
            "code": code,
            "color": hexcolor,
            "coverPhoto": coverPhoto,
            "centralLogo": centralLogo,
            "creationDate": creationDate,
            "description": description,
            "enabled": "Enabled",
            "logo": logo,
            "name": name,
            "shortDescription": shortDescription,
            "domain": props?.schoolInfo?.domain,
        }
        await props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)
    }

    const updateLogo = async (img: any) => {

        let object: any = {
            "id": idSchool,
            "code": props?.connectedSchool.code,
            "color": props?.connectedSchool.color,
            "coverPhoto": props?.connectedSchool.coverPhoto,
            "centralLogo": img,
            "creationDate": props?.connectedSchool.creationDate,
            "description": props?.connectedSchool.description,
            "enabled": "Enabled",
            "logo": img,
            "name": props?.connectedSchool.name,
            "shortDescription": props?.connectedSchool.shortDescription,
            "domain": props?.schoolInfo?.domain,
        }

        await props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)

    }

    const updateCover = async (img: any) => {

        let object: any = {
            "id": idSchool,
            "code": props?.connectedSchool.code,
            "color": props?.connectedSchool.color,
            "coverPhoto": img,
            "centralLogo": props?.connectedSchool.logo,
            "creationDate": props?.connectedSchool.creationDate,
            "description": props?.connectedSchool.description,
            "enabled": "Enabled",
            "logo": props?.connectedSchool.logo,
            "name": props?.connectedSchool.name,
            "shortDescription": props?.connectedSchool.shortDescription,
            "domain": props?.schoolInfo?.domain,
        }

        await props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)

    }

    const saveText = () => {

        let object: any = {
            "id": idSchool,
            "code": code,
            "color": color,
            "coverPhoto": coverPhoto,
            "centralLogo": centralLogo,
            "creationDate": creationDate,
            "description": props?.schoolInfo?.description,
            "enabled": "Enabled",
            "logo": logo,
            "name": name,
            "shortDescription": shortDescription,
            "domain": props?.schoolInfo?.domain,
        }
        props?.UpdateSchool(object)
        setTimeout(() => {
            props?.getSchoolInfo(props?.connectedSchool.code)
        }, 1000)

    }

    const saveLongDesc = (desc: any) => {

        setDescription(desc)
        let object: any = {
            "id": props?.schoolInfo?.id,
            "code": props?.connectedSchool.code,
            "color": props?.connectedSchool.color,
            "coverPhoto": props?.connectedSchool.coverPhoto,
            "centralLogo": props?.connectedSchool.logo,
            "creationDate": props?.connectedSchool.creationDate,
            "description": desc,
            "enabled": "Enabled",
            "logo": props?.connectedSchool.logo,
            "name": props?.connectedSchool.name,
            "shortDescription": props?.connectedSchool.shortDescription,
            "domain": props?.schoolInfo?.domain
        }

        const baseUrl = process.env.REACT_APP_BASE_URL2;
         axiosInstance.post(baseUrl + "person/updatelegalperson", object)
            .then(() => {
                props?.getSchoolInfo(props?.connectedSchool.code)
            })
    }
    const handleChange = (value: any) => {
        saveLongDesc(value)
    }
    const debouncedChangeHandler = useCallback(debounce(handleChange, 1000), []);


    const openModalFn = () => {
        props?.openModal({ idModal: ModalIDS.UPLOAD_SCHOOL_PHOTO, size: "md" })
    }

    return (<>

        <div className="row  justify-content-center">
            <div className="col-md-8 mt-5">
                <span className="H3-Headline">{t('settingEcole')}</span>
                <div style={{ marginBottom: 40 }}></div>
                <div className="col-md-12">
                    <label className="form-title">{t('nomEcole')}</label>
                    <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('nomEcole')} value={name} onChange={(e) => setName(e.target.value)} onBlur={save} />
                </div>
                <div className="col-md-12 mt-4 mb-3">
                    <label className="form-title">Langue par défaut</label>
                    <SelectElementWithIcon />
                </div>
                <div className="col-md-12 mt-4 mb-3">
                    <label className="form-title">{t('logoEcole')}</label>
                    <SchoolLogoImageCropping imageSrc={logo} logoSchool="logo" onGetBlobFile={onGetBlobFil} updateLogo={updateLogo} type={"logo"} openModalFn={()=>{openModalFn()}}/>
                    
                </div>
                <span className="body-md neutral-3" style={{paddingTop:"10px"}}>Taille recommandée (150px x 150px)</span>
                <div className="col-md-12 mt-4 mb-3">
                    <label className="form-title">Photo de couverture</label>
                    <SchoolLogoImageCropping imageSrc={coverPhoto} logoSchool="logo" onGetBlobFile={onGetBlobFilCover} updateLogo={updateCover} type={"cover"} openModalFn={()=>{openModalFn()}}/>
                   
                </div>
                <span className="body-md neutral-3" style={{paddingTop:"10px"}}>Taille recommandée (1440px x 360px)</span >
                <div className="col-md-12 mt-4 mb-3">
                    <label className="form-title">{t('MainSchoolColor')}</label>
                    <ColorPiker color={color} update={updateColor} />
                </div>
                <div className="col-md-12 mt-4 mb-3">
                    <label className="form-title">{t('persenCourte')}</label>
                    <textarea style={{ fontSize: "16px" }} value={shortDescription} onBlur={saveText} onChange={(e) => textAreaAdjust(e.target, 'text')} id="professorDetails" className="textaria-textField" placeholder={t('textAreaPlaceholder')} ></textarea>
                </div>
                <div className="col-md-12 mt-4 mb-3" >
                    <label className="form-title">Présentation longue </label>
                    <EditorToolbar />
                    <ReactQuill style={{ height: '348px' }} theme="snow" value={description} modules={modules} formats={formats} onChange={(e: any) => debouncedChangeHandler(e)} />
                </div>
            </div>
        </div>

    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedSchool: state.authReducer.connectedSchoolInfo,
    idSchool: state.authReducer.connectedUser.idSchool,
    schoolInfo: state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    UpdateSchool,
    getSchoolInfo,
    updateSchoolInfo,
    openModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SchoolInfoUpdateComponent);


