import axios from "axios";

export const fetchFacebookAPI = async (url: string, body: any) => {
  let data;
  let msgError;
  try {
    if (process.env.REACT_APP_DATADOG_ENV === "prod") {
    const response = await axios.post(url, body);
    data = response.data;
    }
  } catch (error: any) {
    if(error) msgError=error;
  } 
  return { data, msgError }
};