import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxIProfState } from '../reducers/profile-reducer';
import axiosInstance from '../../helpers/interceptors';
import { getCodeFromUrl, getDomainFromUrl } from '../../helpers/domainCheck';

export enum EReduxActionTypes {
  GET_STUDENT = 'GET_STUDENT',
  SUSPEND_STUDENT = 'SUSPEND_STUDENT',
  REINSTATE_STUDENT = 'REINSTATE_STUDENT',
  GET_FOLLOWS_STUDENT = 'GET_FOLLOWS_STUDENT',
  CLEAR_STUDENT = 'CLEAR_STUDENT',
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}
export interface IReduxGetStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENT;
  data: any;
}
export interface IReduxSuspenfStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.SUSPEND_STUDENT;
  data: any;
}
export interface IReduxReinstateStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.REINSTATE_STUDENT;
  data: any;
}
export interface IReduxClearStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_STUDENT;
  data: any;
}
export interface IReduxGetFollowsStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_FOLLOWS_STUDENT;
  data: any;
}




export function getStudent(student: any): ThunkAction<Promise<IReduxGetStudentAction>, IReduxIProfState, undefined, IReduxGetStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetStudentAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_STUDENT,
      data: student
    })
  }
}
export function suspendStudent(object: any): ThunkAction<Promise<IReduxSuspenfStudentAction>, IReduxIProfState, undefined, IReduxSuspenfStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxSuspenfStudentAction>) => {
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    const baseUrl = process.env.REACT_APP_BASE_URL8;
    let code = getDomainFromUrl()
    let feedback={
      idPerson: object.idStudent,
      userStatus: "ANONYMOUS"
      }
    object['code']= code;
    if (object.code === 'localhost:3000') { object['code'] = "Koors.io" }
    const response: any = await axiosInstance.post(`${baseUrl2}person/suspendStudent/${object.idStudent}/${object.idSchool}`,object).then(resp => {
      axiosInstance.post(baseUrl+"feedback/updateStatusPerson", feedback)
      return resp.data;
    })
    return dispatch({
      type: EReduxActionTypes.SUSPEND_STUDENT,
      data: response
    })
  }
}
export function reinstateStudent(object: any): ThunkAction<Promise<IReduxReinstateStudentAction>, IReduxIProfState, undefined, IReduxReinstateStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxReinstateStudentAction>) => {
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    const baseUrl = process.env.REACT_APP_BASE_URL8;
    let code = getCodeFromUrl()
    let feedback={
      idPerson: object.idStudent,
      userStatus: "USER"
      }
    object['code']= code;
    if (code === 'localhost:3000') { object['code'] = "Koors.io" }
    const response: any = await axiosInstance.post(`${baseUrl2}person/reinstateStudent/${object.idStudent}/${object.idSchool}`, object).then(resp => {
      axiosInstance.post(baseUrl+"feedback/updateStatusPerson", feedback)
      return resp.data;
    }).catch(error => console.error(error));
    return dispatch({
      type: EReduxActionTypes.REINSTATE_STUDENT,
      data: response
    })
  }
}

export function getFollowsStudent(filter: any): ThunkAction<Promise<IReduxGetFollowsStudentAction>, IReduxIProfState, undefined, IReduxGetFollowsStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetFollowsStudentAction>) => {
    const myCourses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/filterStudent`, filter)
      .then((resp: any) => resp.data)
      .catch((e:any) => { return [] })
    let responseCandidate= myCourses?.filter((follow:any)=>{return follow?.followUp?.statusList?.find((el: any) => new Date(el?.creationDate).getTime() === Math.max(...follow?.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))).status==="CANDIDATE" || 
    follow?.followUp?.statusList?.find((el: any) => new Date(el?.creationDate).getTime() === Math.max(...follow?.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))).status==="REFUSED" || 
    follow?.followUp?.statusList?.find((el: any) => new Date(el?.creationDate).getTime() === Math.max(...follow?.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))).status==="UNPAID" }) 
    let responseInscribe= myCourses?.filter((follow:any)=>{return follow?.followUp?.statusList?.find((el: any) => new Date(el?.creationDate).getTime() === Math.max(...follow?.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))).status==="INSCRIBED"})
    let responseCertif = myCourses?.filter((follow: any) => {
      const statusList = follow?.followUp?.statusList || [];
      return statusList.some((el: any) => el.status === "COMPLETED") || statusList.some((el: any) => el.status === "CERTIFIED");
    });
    
    let dropped = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/getListOfCanceledTrainings/`+filter?.studentId +"/"+filter?.entityId)
                        .then((resp:any)=>resp.data)
    
    
    return dispatch({
      type: EReduxActionTypes.GET_FOLLOWS_STUDENT,
      data: {candidates: responseCandidate, inscribed: responseInscribe, certified: responseCertif , dropped:dropped}
    })
  }
}

export function clearStudent(): ThunkAction<Promise<IReduxClearStudentAction>, IReduxIProfState, undefined, IReduxClearStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxClearStudentAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_STUDENT,
      data: {}
    })
  }
}




