import { ComponentProps, FC, useEffect, useState, useRef } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import StudentListComponent from './student-list-component';
import ProfsListComponent from './profs-list-component';
import CourseListComponent from './course-list-component';
import axios from "../../helpers/interceptors";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useTranslation } from 'react-i18next';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getOffer } from '../../global-state/actions/app-container-actions';
interface Params {
    id: string
}
const VerificationComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [actualTab, setActualTab] = useState<any>("Profs")
    const { id } = useParams<Params>();
    const [verificationProf, setVerifcationProf] = useState<any>()
    const [verificationStudent, setVerifcationStudent] = useState<any>()
    const [verificationCourse, setVerifcationCourse] = useState<any>()
    const [rule, setRule] = useState<any>();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        props?.getOffer(id)
    }, [id])

    useEffect(() => {
        setRule(props?.offer?.rules)
    }, [props?.offer])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL14}offer/verifyApi/${props?.schoolInfo?.id}/${id}`).then((response: any) => {
            const data = response.data;
            setVerifcationProf(data.find((key: any) => key.key = "nb_prof").value)
            setVerifcationCourse(data.find((key: any) => key.key = "nb_cours").value)
            setVerifcationStudent(data.find((key: any) => key.key = "nb_student").value)
        })

    }, [id])
    const goAction = async (id:any) => {
        props?.clearHistoryArray()
        props?.redirect(t("paiement"), `${returnStartPathUrl()}/offer/payment/${id}`)
         history.push(`${returnStartPathUrl()}/offer/payment/${id}`)
    }
    return (<>
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center  py-5" style={{ backgroundColor: '#FFFFFF' }}>
                    <div className='d-flex flex-row justify-content-between' style={{ maxWidth: '1200px', margin: 'auto' }} >
                        <div className='d-flex flex-start'>
                            <h2 className="H2-Headline" style={{ display: 'inline-block' }}>{t('verification')}</h2>
                        </div>
                        <div className='d-flex flex-end'>
                            <button disabled={props?.profStatus === false || props?.courseStatus === false || props?.studentStatus === false} type="button" className="btn-Primary medium success " onClick={()=>{goAction(id)}} >
                                {t('valid')}
                            </button>
                        </div>

                    </div>

                    <div className="d-flex align-items-center flex-wrap px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}  >
                        <>
                            {(verificationProf) &&
                                <button className={`btn-Tabs ${(actualTab === 'Account') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("Profs") }} style={{ marginRight: "16px" }}>{t('ProfList')} </button>
                            }

                            {(verificationStudent) &&
                                <button className={`btn-Tabs ${(actualTab === 'Integration') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("Students") }} style={{ marginRight: "16px" }}>{t('StudentList')}</button>
                            }      {(verificationCourse) &&
                                <button className={`btn-Tabs ${(actualTab === 'Finanace') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("Courses") }} >{t('CourseList')}
                                </button>
                            }
                        </>
                    </div>
                </div>
                <div className="px-4" style={{ margin: "50px auto", maxWidth: '1248px' }}>
                    {(actualTab === "Profs") && (verificationProf) &&
                        <><ProfsListComponent rule={rule} /></>}
                    {(actualTab === "Students") && (verificationStudent) &&
                        <><StudentListComponent rule={rule} /></>}
                    {(actualTab === "Courses") && (verificationCourse) &&
                        <><CourseListComponent rule={rule} /></>}
                </div>
            </div>
        </div>
    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    offer: state.schoolInfoReducer.offer,
    subscription: state.schoolInfoReducer.subscription,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    studentStatus: state.schoolInfoReducer.studentStatus,
    courseStatus: state.schoolInfoReducer.courseStatus,
    profStatus: state.schoolInfoReducer.profStatus
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getOffer,
    redirect, clearHistoryArray
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerificationComponent);