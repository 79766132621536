import { fetchFacebookAPI } from "./fetchFacebookAPI";
import axios from "./interceptors";

export const exportListStudents = async (
  filterCourse: any,
  actualTab: string,
  idSchool: any,
  name: string,
  lang: any
) => {
  axios({
    url: `${
      process.env.REACT_APP_BASE_URL14
    }followUp/export/${actualTab}/${idSchool}/${name}/${lang}`,
    method: "POST",
    responseType: "blob",
    data: filterCourse,
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name + ".csv");
    document.body.appendChild(link);
    link.click();
  });
};

export const sha256 = async (text: string) => {
  const textAsBuffer = new TextEncoder().encode(text);
  const hashBuffer = await (window as any).crypto.subtle.digest(
    "SHA-256",
    textAsBuffer
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const facebookSendEvent = async (
  price?: number,
  url?: string,
  firstname?: string,
  lastname?: string,
  email?: string,
  eventName?: string,
  offerLabel?: string,
  predicted_ltv?: number,
  client_ip_address?: string,
  client_user_agent?: string
) => {
  if (process.env.REACT_APP_URL_EVENT_FB_ACTIVE === "false") return;

  // INIT FIRSTNAME & LASTNAME & EMAIL
  let fnSha = "";
  let lnSha = "";
  let emSha = "";
  firstname &&
    (await sha256(firstname).then((res: any) => {
      fnSha = res;
      return res;
    }));
  lastname &&
    (await sha256(lastname).then((res: any) => {
      lnSha = res;
      return res;
    }));
  email &&
    (await sha256(email).then((res: any) => {
      emSha = res;
      return res;
    }));

  // CONVERT A DATE TO A UNIX TIMESTAMP
  const unixTimestamp = Math.floor((new Date() as any) / 1000);
  const date = new Date();

  // INIT CUSTOM_DATA (BODY API)

  const custom_data_fn = () => {
    if (eventName === "InitiateCheckout" || eventName === "Subscribe") {
      return {
        content_name: offerLabel,
        content_type: "product",
        value: price,
        predicted_ltv: predicted_ltv,
        currency: "EUR",
      };
    }
  };
  let custom_data = custom_data_fn();

  // INIT USER_DATA (BODY API)
  const user_data_fn = () => {
    if (eventName === "AddToCart") {
      return {
        client_ip_address,
        client_user_agent,
      };
    }
    return {
      fn: [fnSha],
      ln: [lnSha],
      em: [emSha],
    };
  };
  let user_data= user_data_fn();

  let bodyApi: any = {
    data: [
      {
        event_id: unixTimestamp,
        event_name: eventName,
        event_time: date,
        action_source: "website",
        event_source_url: url,
        user_data,
        custom_data,
      },
    ],
  };

  if (process.env.REACT_APP_FB_TEST_EVENT_ID !== "") {
    bodyApi["test_event_code"] = process.env.REACT_APP_FB_TEST_EVENT_ID;
  }

  // CALL FUNCTION TO FETCH API FACEBOOK
  fetchFacebookAPI(
    `https://graph.facebook.com/${process.env.REACT_APP_FB_API_VERSION}/${
      process.env.REACT_APP_FB_PIXEL_ID
    }/events?access_token=${process.env.REACT_APP_FB_TOKEN}`,
    bodyApi
  );
};
