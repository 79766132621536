/* IMPORT IMGS */
import upload from "../../styles/imgs/upload.png";
import deleteIcone from "../../styles/imgs/delete.png";
import axiosInstance from "../../helpers/interceptors";

interface PropsSubtitleUploaded {
  fileName: string;
  language: any;
  idVideo: string;
  idSubtitle: any;
  getSubtitleUploaded: () => void;
}

const SubtitleUploaded = ({ fileName, language, idVideo, idSubtitle, getSubtitleUploaded }: PropsSubtitleUploaded) => {

  const handleDelete = async () => {
    await axiosInstance.delete(`${process.env.REACT_APP_BASE_URL4}delete-subtitle/${idVideo}?idSubtitle=${idSubtitle}`);
    getSubtitleUploaded()
  }

  return (
    <div className="row container-subtitle-file-added w-100 mx-0 mb-32">
      <div className="col-3 ">
        <div className="body-md bold">Langue du sous-titre</div>
        <div className="body-xl">{language}</div>
      </div>
      <div className="col-8 border border-light d-flex align-items-center">
        <img src={upload} alt="" className="mx-25" />
        <p className="body-md mb-0">
          {fileName}
        </p>
      </div>
      <div className="col-1  p-0 d-flex align-items-center justify-content-end">
        <div className="delete-btn" onClick={() => handleDelete()} >
          <img src={deleteIcone} alt="" /> 
          
        </div>
      </div>
    </div>
  );
}

export default SubtitleUploaded;
