import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';

const SkillsSectionComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const [type, setType] = useState<string>("course");
  useEffect(() => {
    if (props?.type) {
      setType(props?.type);
    }

  }, [props?.type])
  const getItemStyle = () => ((type !== "theme") ? {
    background: (!props.objectives && !props.prerequisite) ? "white" : (!!props?.schoolInfo?.color) ? props?.schoolInfo?.color : "#212529",
    color: (props.objectives || props.prerequisite) ? "#fff" : "#272727",
  } : { color: "#fff" })

  return <>

    <section className={`btnTestColor`} style={getItemStyle()} >
      <div className={`d-flex flex-column max-width ${(type !== "theme") && 'py-5'}`} style={(type !== "theme") ? { boxShadow: "inset 0px 0px 0px #DFDFDF", margin: 'auto' } : {}}>
        <div className={"mx-3 mx-sm-0"}>
          {<span className="H3-HeadlineTransparent" >{(type === "theme") ? "" : t((type === "course") ? 'skillsPreveiw' : (type === 'path') ? 'skillsPreveiwPath' : 'skillsPreveiwSession')}</span>}
          <div className="d-flex align-items-center flex-wrap mt-5" style={{ maxWidth: (props.prerequisite || props.objectives) ? "100%" : "60%" }}>
            {props?.skills?.map((skill: string, index: number) => {
              return <span key={index} className="btn-Tabs-uncolored outlined black icon me-3 mb-2" >
                <span className="material-icons-outlined me-1">check</span>
                {skill}
              </span>;
            }
            )}
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  objectives: ownProps?.objectives,
  prerequisite: ownProps?.prerequisite,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
});

export default connect(mapStateToProps, null)(SkillsSectionComponent);
