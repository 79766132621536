import { FC, useEffect, ComponentProps, useState } from "react"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { createPayment } from "../../services/school.service";
import { getDomainFromUrl, returnStartPathUrl } from "../../helpers/domainCheck";
import { useHistory } from "react-router";
import { connect } from "react-redux"
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getContract, getOffer, getSubscriptions } from '../../global-state/actions/app-container-actions';
import jwt from 'jwt-decode';
import axiosInstance from "../../helpers/interceptors";

interface DecodedToken {
  id_Person: number;
  user_name: string;
}

const CARD_OPTIONS: any = {
  showIcon: true,
  iconStyle: "solid",
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      iconColor: '#E06A6A',
      color: '#272727',
    },
  },
};
const CARD_OPTIONS_EXP = {
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      color: '#272727',
    },
  },
};
const CARD_OPTIONS_CVC = {
  style: {
    base: {
      backgroundColor: '#F2F2F5',
      iconColor: '#272727',
      color: '#272727',
      fontWeight: 500,
      fontFamily:
        '"Public", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#CACBCE',
      },
      ':-webkit-autofill': {
        color: '#272727',
      },
    },
    invalid: {
      backgroundColor: '#fae9e9',
      color: '#272727',
    },
  },
};

const PayStripeComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const [errorCVC, setErrorCVC] = useState(undefined);
  const [errorCardNumber, setErrorCardNumber] = useState(undefined);
  const [errorDateExp, setErrorDateExp] = useState(undefined);
  const stripe = useStripe();
  const elements = useElements();
  const [msg, setMsg] = useState("");
  const [name, setName] = useState("");
  const [oneYear, setOneYear] = useState<any>();
  const [oneMonth, setOneMonth] = useState<any>();
  const history = useHistory();


  const [periodPayment, setPeriodPayment] = useState("");





  useEffect(() => {
    setName(props.naturalPerson.lastName + " " + props.naturalPerson.firstName)
    setOneYear(new Date(Date.now() + (86400000 * 365)));
    setOneMonth(new Date(Date.now() + (86400000 * 30)));
  }, [periodPayment])

  useEffect(() => {
    if (!props?.offer?.pricings) return
    setPeriodPayment(props?.offer?.pricings.find((a: any) => a.id == props?.priceId)?.period)
  }, [props?.offer?.pricings])

  useEffect(() => {
    if (props.payAction) {
      document.getElementById("bt")?.click();
      props?.disablePayAction1()
    }

  }, [props.payAction])



  const handleSubmit = async (event: any) => {

    setMsg("")
    props?.loadingAction1(true)
    props?.errorAction(false)

    event.preventDefault();


    if (!stripe || !elements) {
      props?.loadingAction1(false)
      return;
    }
    const promise = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!
    });
    if (promise.paymentMethod == undefined) {
      props?.loadingAction1(false)
    }


    let user = ("" + localStorage.getItem("access_token")).replace(/\"/g, "")
    var userProfile = jwt(user) as DecodedToken;
    const idPrice = props?.offer?.pricings?.length > 1 ? props?.offer?.pricings?.find((a: any) => a.price == props.price).id : props?.offer?.pricings[0]?.id

    if (!!promise.paymentMethod) {

      let contract = {
        ...props.contract[0],
        contractDetails: {
          offerId: props?.offer?.id,
          contractDuration: {
            startAt: new Date(Date.now()),
            endAt: (props?.period == "YEAR") ? oneYear : oneMonth,
          },
          pricingId: props.priceId,
          paymentMethod: "CARD",
          paymentEngine: "STRIPE"
        },
        contractRoles: [],
      }

      let paymentInfo: any = {
        contract: contract,
        contractReferance: contract.contractIdentifier.contractReference,
        idPerson: userProfile?.id_Person,
        idSchool: props.legalPerson?.id,
        email: userProfile?.user_name,
        paymentMethod: promise.paymentMethod?.id!,
        priceId: idPrice,
        product: "PRICE",
        idCoupon: undefined,
      }

      if (props.coupon?.id) {
        paymentInfo.product = "COUPON"
        paymentInfo.idCoupon = props.coupon?.id
      }


      let response = await createPayment(paymentInfo)

      if (!response.success) {
        let status = response.stripeResponse?.codeDescription || response.stripeResponse?.code;

        if (status === "generic_decline") {
          setMsg("Paiement refusé pour des raisons génériques");
          props?.loadingAction1(false);
        } else if (status === "insufficient_funds") {
          setMsg("Les fonds de la carte sont insuffisants");
          props?.loadingAction1(false);
        } else if (status === "lost_card") {
          setMsg("La carte est déclarée perdue");
          props?.loadingAction1(false);
        } else if (status === "expired_card") {
          setMsg("La carte est expirée");
          props?.loadingAction1(false);
        } else if (status === "incorrect_cvc") {
          setMsg("Le code CVC est incorrect");
          props?.loadingAction1(false);
        } else if (status === "processing_error") {
          setMsg("Paiement refusé à cause d’erreur de traitement");
          props?.loadingAction1(false);
        } else if (status === "stolen_card") {
          setMsg("La carte est déclarée volée");
          props?.loadingAction1(false);
        } else {
          props?.errorAction(true)
          props?.loadingAction1(false);
        }
      } else {
        let data = await stripe.confirmCardPayment(response?.subscriptionResponse?.clientSecret!);
        if (data.paymentIntent?.status === "succeeded") {
          props?.getContract(props?.legalPerson?.id, "SCHOOL").then(() => {
            props?.getSubscriptions(props?.legalPerson?.id).then(() => {


              props?.getOffer(props?.offer?.id).then(async () => {

                const object = {
                  "properties": {
                    "email": userProfile?.user_name,
                    "lifecyclestage": "customer",
                    "hs_lead_status": "",
                  }
                }
    

                const objectCompany = {
                  "code": getDomainFromUrl(),
                  "properties": {
                    "lifecyclestage": "customer",
                    "koors_offer": props?.offer?.pricings.length > 1 ? process.env.REACT_APP_OFFER_LABEL_PRO : process.env.REACT_APP_OFFER_LABEL_BUSINESS
                  }
                }
    
                const objectDealDynamic = {
                  "email": userProfile?.user_name,
                  "properties": {
                    "amount": "960",
                    "dealstage": "decisionmakerboughtin"
                  }
                }
    
                const objectDealStatic = {
                  "email": userProfile?.user_name,
                  "properties": {
                    "amount": "960",
                    "dealstage": "closedwon"
                  }
                }
    
                await axiosInstance.post(
                  process.env.REACT_APP_BASE_URL21 +
                  "hubspot/updateContact",
                  object)
                  .then(async () => {
                    await axiosInstance
                      .post(
                        process.env.REACT_APP_BASE_URL21 +
                        "hubspot/updateCompanyWithSchoolUrl",
                        objectCompany
                      )
                      .then(async () => {
                        if (!!props?.offer && !!props?.offer?.pricings && props?.offer?.pricings[0]?.type == "STATIC") {
                          await axiosInstance
                            .post(
                              process.env.REACT_APP_BASE_URL21 +
                              "hubspot/updateDeal",
                              objectDealStatic
                            )
                        } else if (!!props?.offer && !!props?.offer?.pricings && props?.offer?.pricings[0]?.type == "DYNAMIC") {
                          await axiosInstance
                            .post(
                              process.env.REACT_APP_BASE_URL21 +
                              "hubspot/updateDeal",
                              objectDealDynamic
                            )
    
                        }
                      })
                  })

                history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined });
                props?.loadingAction1(false);
              })
            })
          })
        }
        else {
          let UnpaidContract = {
            ...props.contract[0],
            contractDetails: {
              offerId: props?.offer?.id,
              contractDuration: {
                startAt: new Date(Date.now()),
                endAt: (props?.period == "YEAR") ? oneYear : oneMonth,
              },
              pricingId: props.priceId,
              paymentMethod: "CARD",
              paymentEngine: "STRIPE"
            },
            contractRoles: [],
            status: [{
              statusType: "UNPAID"
            }]
          }
          await axiosInstance.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract", UnpaidContract).then(() => {
            props?.getContract(props?.schoolInfo?.id, "SCHOOL").then(() => {
              props?.loadingAction1(false);
              props?.errorAction(true)
            })
          })

        }

      }
    }
  }




  return (<>


    <div style={{ width: "100%", marginBottom: "50px" }} >
      <div>


        <div className="d-flex flex-column ml-3 mb-4">
          <label className="form-title">Nom sur la carte</label>
          <input type="text" className="form-control input text-default" value={name} placeholder="Nom sur la carte" onChange={(e: any) => setName(e?.target?.value)} />
        </div>
        <div className="ml-3 mb-4">
          <label className="form-title mb-2">Numéro de carte de crédit</label>
          <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorCardNumber) ? "#F2F2F5" : "#fae9e9" }}>
            <CardNumberElement onChange={(e: any) => setErrorCardNumber(e.error)} options={CARD_OPTIONS} />
          </div>
        </div>
        <div className="ml-3 d-flex align-items-center justify-content-between flew-wrap mb-4">
          <div className="w-100 me-2">
            <label className="form-title mb-2">Date d&apos;expiration</label>
            <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorDateExp) ? "#F2F2F5" : "#fae9e9" }}>
              <CardExpiryElement onChange={(e: any) => setErrorDateExp(e.error)} options={CARD_OPTIONS_EXP} />
            </div>
          </div>
          <div className="w-100 ml-3 ">
            <label className="form-title mb-2">Cryptogramme</label>
            <div className=" px-3 py-3" style={{ position: 'relative', borderRadius: '3px', backgroundColor: (!errorCVC) ? "#F2F2F5" : "#fae9e9" }}>
              <div className="w-60">
                <CardCvcElement onChange={(e: any) => setErrorCVC(e.error)} options={CARD_OPTIONS_CVC} />
              </div>
              <div style={{ position: "absolute", right: 12, top: 14 }}>

              </div>
            </div>
          </div>

        </div>
        {(msg !== "") && <div className="w-100 ml-3 mt-2 ">
          <label className="body-error  red-800 mb-2"> {msg}</label>
        </div>}
        <div className="d-flex align-items-top mt-2">

          <input className="input-style-checkbox " type="checkbox" id="checkBtn" />
          <label htmlFor="checkBtn" />
          <span className="form-title-welcome ps-1 ms-1">Enregistrer ce mode de paiement dans mon profil</span>
        </div>


        <button type="button" style={{ display: 'none', width: "100%" }} id='bt' className="btn-Primary large mt-4" onClick={handleSubmit}>
          confirmer et payer
        </button>
        <button id='checkedConditionsTrue' style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#activateModalFinal"></button>
        <button id='checkedConditionsFalse' style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#continuCreatingModal"></button>
      </div>
    </div>
  </>
  )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  schoolInfo: state.schoolInfoReducer.schoolInfo,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getSubscriptions,
      getOffer,
      getContract,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PayStripeComponent);



