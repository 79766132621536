import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import "../../translations/i18n";
import * as _ from 'lodash';
const HeaderGroupPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [group, setGroup] = useState<any>(props?.group);
    const [allStudents, setAllStudents] = useState<any>([])
    const [actualGroup, setActualGroup] = useState<string>(props?.tabGroupManager)
    
    useEffect(() => {
        if (props?.groupForm?.personList?.length !== 0 || props?.groupForm?.childsList?.length !== 0) {
            let personList = _.uniq([props?.groupForm?.personList?.map((obj: any) => { return obj?.id }), props?.groupForm?.childsList?.map((subGroup: any) => { return subGroup?.personList?.map((p: any) => { return p.id }) })].flat(Infinity))

            setAllStudents(personList);
        }
        setGroup(props?.groupForm)



    }, [props?.groupForm, props?.groupForm?.personList, props?.groupForm?.childsList])

    const setActualTabGroupFn = (tabGroup: string) => {
        setActualGroup(tabGroup)
    }

    const updateGroupTitle = () => {
        props?.openModal({ idModal: ModalIDS.UPDATE_GROUP_TITLE, size: "md", data: { group: group } })

    }

    const openModalFn = () => {
        props?.openModal({ idModal: ModalIDS.ADD_SUB_GROUP, size: "lg", scroll: 'body' ,data:{
            groupList:props?.groupList,
            groupForm:props?.groupForm
        }})
    }

    return (
        <>
            <div className="px-3 pt-3">
                <div className="col-md-12  px-0 mx-auto pt-5" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-between">
                        <div className=" d-flex align-items-center justify-content-between flex-wrap my-4 py-2">
                            <div className=" d-flex align-items-center">
                                <span className="H2-Headline neutral-3">
                                    {group?.label}
                                    <span className="ms-3" style={{ color: "#272727" }}>{allStudents?.length < 10 ? "0" + allStudents?.length : allStudents?.length}</span>

                                </span>
                                <button type="button" className="btn-QuickActions transparent ms-3" onClick={() => { updateGroupTitle() }}>
                                    <span className="material-icons-outlined">edit</span>
                                </button>
                            </div>
                            <button type="button" className="btn-Primary large icon-left" disabled={props?.groupList?.length<=1} onClick={() => { openModalFn() }}>
                                <span className="material-icons-outlined">add</span>
                                <div className="m-auto ps-2">{t('addSubGroup')}</div>
                            </button>
                        </div>
                    </div>
                </div>
                {group?.childsList?.length !== 0 && <div className="col-md-12 px-0 pb-4 mx-auto" style={{ maxWidth: '1200px', overflowX: "auto", overflow: "overlay" }}>
                    <div className="row justify-content-between w-100 mb-2">
                        <div className="d-flex w-100 align-items-center jsutify-content-start">
                            <button className={`btn-Tabs ${(actualGroup === "all") ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabGroupFn("all"); setActualGroup("all") }} style={{ marginRight: "16px" }}><div style={{ width: "max-content" }}>{t('allStudent')} ({allStudents?.length})</div></button>
                            <button className={`btn-Tabs ${(actualGroup === group?.label) ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabGroupFn(group?.label); setActualGroup(group?.label) }} style={{ marginRight: "16px" }}><div style={{ width: "max-content" }}>{t('pricipalGroup')}  ({group?.personList?.length})</div></button>
                            {group?.childsList?.map((subGroup: any) => {
                                return <button className={`btn-Tabs ${(actualGroup === subGroup?.label) ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTabGroupFn(subGroup?.label); setActualGroup(subGroup?.label) }} style={{ marginRight: "16px", textTransform: 'capitalize' }}><div style={{ width: "max-content" }}>{subGroup?.label} ({subGroup?.personList?.length})</div></button>
                            })}
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
  
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        openModal,
        closeModal,
        
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGroupPageComponent);
