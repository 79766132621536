import axios from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxChatState } from '../reducers/chat-reducer';
import * as _ from 'lodash';

export enum EReduxActionTypes {
  CREATE_POST = 'CREATE_POST',
  SET_ACTUAL_TAB_CHAT = 'SET_ACTUAL_TAB_CHAT',
  CREATE_RESPONSE = 'CREATE_RESPONSE',
  GET_POSTS_COMMUNITY = 'GET_POSTS_COMMUNITY',
  GET_SUBJECT = 'GET_SUBJECT',
  SET_ACTUAL_TAB_QUESTION = 'SET_ACTUAL_TAB_QUESTION',
  GET_QUESTION_BY_TYPE = 'GET_QUESTION_BY_TYPE',
  GET_FILTERED_QUESTIONS = 'GET_FILTERED_QUESTIONS',
  CREATE_SEENS = 'CREATE_SEENS',
  UPDATE_LIST_DISCUSSION = 'UPDATE_LIST_DISCUSSION',
  LOADING_QUESTION = "LOADING_QUESTION",
  LOADING_POSTS = "LOADING_POSTS",
  REFRESH_POSTS = "REFRESH_POSTS",
  SET_OPEN_CHAT = "SET_OPEN_CHAT",
  GET_QUESTIONS_FOR_ASSISTANTS = "GET_QUESTIONS_FOR_ASSISTANTS",
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}

export interface IReduxCreatePostAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_POST;
  data: any;
}

export interface IReduxCreateResponseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_RESPONSE;
  data: any;
}
export interface IReduxSetActualTabChatAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_CHAT;
  data: any;
}
export interface IReduxSetOpenChatAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_OPEN_CHAT;
  data: any;
}
export interface IReduxSetActualTabQuestionAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_QUESTION;
  data: any;
}
export interface IReduxGetPostsCommunityAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_POSTS_COMMUNITY | EReduxActionTypes.LOADING_POSTS | EReduxActionTypes.REFRESH_POSTS;
  data: any;
}
export interface IReduxGetSubjectAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SUBJECT;
  data: any;
}
export interface IReduxGetQuestionsByTypeAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_QUESTION_BY_TYPE | EReduxActionTypes.LOADING_QUESTION;
  data: any;
}
export interface IReduxCreateSeensAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_SEENS;
  data: any;
}

export interface IReduxGetFilteredQuestionsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_FILTERED_QUESTIONS;
  data: any;
}
export interface IReduxUpdateListDiscussionAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_LIST_DISCUSSION;
  data: any;
}

export interface IReduxGetQuestionsForAssistantAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_QUESTIONS_FOR_ASSISTANTS | EReduxActionTypes.LOADING_QUESTION;
  data: any;
}

export function setActualTabManageQuestion(tab: string): ThunkAction<Promise<IReduxSetActualTabQuestionAction>, IReduxChatState, undefined, IReduxSetActualTabQuestionAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxSetActualTabQuestionAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_QUESTION,
      data: tab
    })
  }
}

export function setOpenChat(value: boolean): ThunkAction<Promise<IReduxSetOpenChatAction>, IReduxChatState, undefined, IReduxSetOpenChatAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxSetOpenChatAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_OPEN_CHAT,
      data: value
    })
  }
}
export function setActualTabManageChat(tab: string): ThunkAction<Promise<IReduxSetActualTabChatAction>, IReduxChatState, undefined, IReduxSetActualTabChatAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxSetActualTabChatAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_CHAT,
      data: tab
    })
  }
}

export function createPost(object: any, subject: any, idSchool: number): ThunkAction<Promise<IReduxCreatePostAction>, IReduxChatState, undefined, IReduxCreatePostAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxCreatePostAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL10;
    const baseUrlPerson = process.env.REACT_APP_BASE_URL2;
    let createdSub = null;
    if (object.subjectId === null) {
      createdSub = await axios.post(baseUrl + "chat/createSubject", subject).then(sub => { return sub.data }).catch(err => console.error(err))
      object['subjectId'] = createdSub?.id;
    }
    let author = await axios.post(baseUrlPerson + "person/student/" + idSchool, [object.idAuthor]).then(person => { return person.data[0] }).catch(err => console.error(err))
    if (!object.event) {
      object['event'] = '';
    }

    const res: any = await axios.post(baseUrl + "chat/createPost", object)
      .then(response => {
        response.data.idAuthor = author;
        response.data['subject'] = subject;
        response.data['responses'] = [];
        response.data['seens'] = [];

        return response.data;
      })
      .catch(err => {
        console.error(err)
      });

    return dispatch({
      type: EReduxActionTypes.CREATE_POST,
      data: { post: res, event: object.event }
    });

  };
}

export function createResponse(object: any, what: string, idSchool: number): ThunkAction<Promise<IReduxCreateResponseAction>, IReduxChatState, undefined, IReduxCreateResponseAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxCreateResponseAction>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL10;
    const baseUrlPerson = process.env.REACT_APP_BASE_URL2;
    let author = await axios.post(baseUrlPerson + "person/student/" + idSchool, [object.post.idAuthor]).then(person => { return person.data[0] }).catch(err => console.error(err))
    const res: any = await axios.post(baseUrl + "chat/createResponse", object)
      .then((response: any) => {

        if (author) {
          response.data.idAuthor = author;
        }
        return response.data;
      })
      .catch(err => {
        console.error(err)
      });
    let finalResult = { response: res, what: what }
    return dispatch({
      type: EReduxActionTypes.CREATE_RESPONSE,
      data: finalResult
    });

  };
}
export function updatelistDiscussion(page: number, data: any, idSchool: number, subject: any): ThunkAction<Promise<IReduxUpdateListDiscussionAction>, IReduxChatState, undefined, IReduxUpdateListDiscussionAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxUpdateListDiscussionAction>) => {

    let subjectData = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getSubject`, subject).then(res => { return res.data }).catch(err => console.error(err.message));
    let author = await axios.post(`${process.env.REACT_APP_BASE_URL2}person/student/${idSchool}`, [Number(data.idAuthor)]).then(res => { return res.data[0] }).catch(err => console.error(err));
    data.idAuthor = author;
    data["subject"] = subjectData;
    data["seens"] = [];
    return dispatch({
      type: EReduxActionTypes.UPDATE_LIST_DISCUSSION,
      data: { page, data }
    });
  };
}


export function getSubjectCommunity(object: any, idSchool: number, isSearch: boolean): ThunkAction<Promise<IReduxGetPostsCommunityAction>, IReduxChatState, undefined, IReduxGetPostsCommunityAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxGetPostsCommunityAction>) => {
    if (object.refresh) {
      dispatch({ type: EReduxActionTypes.REFRESH_POSTS, data: undefined })
    } else {
      dispatch({ type: EReduxActionTypes.LOADING_POSTS, data: undefined })
    }
    const baseUrl = process.env.REACT_APP_BASE_URL10;
    const baseUrlPerson = process.env.REACT_APP_BASE_URL2;

    let listSubjects = await axios.post(baseUrl + "chat/getAllPosts", object).then(res => { return res.data })

    if (listSubjects.length === 0) {
      return dispatch({
        type: EReduxActionTypes.GET_POSTS_COMMUNITY,
        data: { liste: [] }
      })
    } else {
      let listSubjectsFinal: any;
      let listAuthorId: any = listSubjects.map((post: any) => {
        return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
      }).flat(Infinity);
      let listAuthorIdDistinct = _.uniq(listAuthorId)
      const listAuthor = await axios.post(baseUrlPerson + "person/student/" + idSchool, listAuthorIdDistinct).then(response => { return response.data })



      listSubjectsFinal = listSubjects.map((obj: any) => {
        obj['responses'] = obj?.responses?.map((res: any) => {
          const responseAuthor = listAuthor.find((person: any) => person.id === Number(res.idAuthor));
          if (responseAuthor) {
            res.idAuthor = responseAuthor;
          }
          return res;
        })
        const postAuthor = listAuthor.find((person: any) => person.id === Number(obj.idAuthor));
        if (postAuthor) {
          obj.idAuthor = postAuthor;
        }
        return obj;
      })
      if (object.chat && object.chat === true) {
        listSubjectsFinal = listSubjects;
      }

      return dispatch({
        type: EReduxActionTypes.GET_POSTS_COMMUNITY,
        data: { liste: listSubjectsFinal, isSearch: isSearch, chat: object.chat, refresh: object.refresh, }
      })
    }
  }
}

export function getsubject(object: any): ThunkAction<Promise<IReduxGetSubjectAction>, IReduxChatState, undefined, IReduxGetSubjectAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxGetSubjectAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL10;
    let Subject = await axios.post(baseUrl + "chat/getSubject", object)
      .then(res => { return res.data })
      .catch(err => console.error(err.message))
    return dispatch({
      type: EReduxActionTypes.GET_SUBJECT,
      data: Subject
    })
  }
}


export function getTrainingQuestionForAssistant(training: any, isSearch: boolean): ThunkAction<Promise<IReduxGetQuestionsForAssistantAction>, IReduxChatState, undefined, IReduxGetQuestionsForAssistantAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxGetQuestionsForAssistantAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_QUESTION, data: undefined })

    const baseUrlPerson = process.env.REACT_APP_BASE_URL2

    let TrainingList = [training]

    let coursesChapters = await axios.post(`${process.env.REACT_APP_BASE_URL}chapters/getCoursesChapters`, [training?.id]).then(res => { return res.data }).catch(err => { return [] })

    let subject = [
      TrainingList.
        map((obj: any) => { return obj.id + " " + obj.type + " " + "FORUM" }),
      coursesChapters?.map((obj: any) => { return obj.idChapter + " CHAPTER FORUM" })].
      flat(Infinity)


    let listUnsettledQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/unsettled`, { subject: subject, trie: "recent", searchFilter: "", page: 0 }).then(res => { return res.data })
    let listSettledQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/settled`, { subject: subject, trie: "recent", searchFilter: "", page: 0 }).then(res => { return res.data })
    let listAllQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/all`, { subject: subject, trie: "recent", searchFilter: "", page: 0 }).then(res => { return res.data })
    let listQuestions = { listUnsettledQuestions: listUnsettledQuestions, listSettledQuestions: listSettledQuestions, allQuestions: listAllQuestions, statistic: undefined }
    let listAuthorId: any = _.uniq([listUnsettledQuestions.map((post: any) => {
      return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
    }), listSettledQuestions.map((post: any) => {
      return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
    }), listAllQuestions.map((post: any) => {
      return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
    })].flat(Infinity));
    const listAuthor = await axios.post(baseUrlPerson + "person/student/" + training?.entityId, listAuthorId).then(response => { return response.data })

    listQuestions.statistic = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getTotalsQuestionCount`, { subject: subject }).then(res => { return res.data });

    listQuestions.listUnsettledQuestions = getFormatList(listUnsettledQuestions, listAuthor, TrainingList, coursesChapters)
    listQuestions.listSettledQuestions = getFormatList(listSettledQuestions, listAuthor, TrainingList, coursesChapters)
    listQuestions.allQuestions = getFormatList(listAllQuestions, listAuthor, TrainingList, coursesChapters)

    return dispatch({
      type: EReduxActionTypes.GET_QUESTIONS_FOR_ASSISTANTS,
      data: { liste: listQuestions, isSearch: isSearch }
    })
  }
}













export function getQuestionByType(professorId: number, entityId: number, entityType: string, isSearch: boolean): ThunkAction<Promise<IReduxGetQuestionsByTypeAction>, IReduxChatState, undefined, IReduxGetQuestionsByTypeAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxGetQuestionsByTypeAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_QUESTION, data: undefined })

    const baseUrlPerson = process.env.REACT_APP_BASE_URL2
    let Courses = await axios.post(`${process.env.REACT_APP_BASE_URL14}training/catalog`, { entityId: entityId, entityType: entityType, idProf: professorId }).then(res => { return res.data })

    const listCoursesIds = Courses?.filter((obj: any) => obj.type === "COURSE" && obj.status === "PUBLISHED").map((training: any) => { return training.id })

    let coursesChapters = await axios.post(`${process.env.REACT_APP_BASE_URL}chapters/getCoursesChapters`, listCoursesIds).then(res => { return res.data }).catch(err => { return [] })

    let subject = [
      Courses?.filter((training: any) => training.status === "PUBLISHED").map((obj: any) => { return obj.id + " " + obj.type + " " + "QUESTION" })
      , coursesChapters.map((obj: any) => { return obj.idChapter + " CHAPTER QUESTION" })
    ].flat(Infinity)


    let listUnsettledQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/unsettled`, { subject: subject, trie: "recent", searchFilter: "", page: 0 }).then(res => { return res.data })
    let listSettledQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/settled`, { subject: subject, trie: "recent", searchFilter: "", page: 0 }).then(res => { return res.data })
    let listAllQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/all`, { subject: subject, trie: "recent", searchFilter: "", page: 0 }).then(res => { return res.data })
    let listQuestions = { listUnsettledQuestions: listUnsettledQuestions, listSettledQuestions: listSettledQuestions, allQuestions: listAllQuestions, statistic: undefined }
    let listAuthorId: any = _.uniq([listUnsettledQuestions.map((post: any) => {
      return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
    }), listSettledQuestions.map((post: any) => {
      return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
    }), listAllQuestions.map((post: any) => {
      return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
    })].flat(Infinity));
    const listAuthor = await axios.post(baseUrlPerson + "person/student/" + entityId, listAuthorId).then(response => { return response.data })

    listQuestions.statistic = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getTotalsQuestionCount`, { subject: subject }).then(res => { return res.data });

    listQuestions.listUnsettledQuestions = getFormatList(listUnsettledQuestions, listAuthor, Courses, coursesChapters)
    listQuestions.listSettledQuestions = getFormatList(listSettledQuestions, listAuthor, Courses, coursesChapters)
    listQuestions.allQuestions = getFormatList(listAllQuestions, listAuthor, Courses, coursesChapters)

    return dispatch({
      type: EReduxActionTypes.GET_QUESTION_BY_TYPE,
      data: { liste: listQuestions, isSearch: isSearch }
    })
  }
}

export function getFilteredQuestion(professorId: number, schoolId: number, object: any, isSearch: boolean): ThunkAction<Promise<IReduxGetFilteredQuestionsAction>, IReduxChatState, undefined, IReduxGetFilteredQuestionsAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxGetFilteredQuestionsAction>) => {
    const baseUrlPerson = process.env.REACT_APP_BASE_URL2
    let Courses: any[];
    let listUnsettledQuestions: any;
    let listSettledQuestions: any;
    let listAllQuestions: any;
    let coursesChapters: any;
    let subject = []
    let filteredQuestions = []


    if (object.idCours === null) {
      Courses = await axios.post(`${process.env.REACT_APP_BASE_URL14}training/catalog`, { entityId: schoolId, entityType: 'SCHOOL', idProf: professorId }).then(res => { return res.data })

      const listCoursesIds = Courses?.filter((obj: any) => obj.type === "COURSE" && obj.status === "PUBLISHED").map((training: any) => { return training.id })
      coursesChapters = await axios.post(`${process.env.REACT_APP_BASE_URL}chapters/getCoursesChapters`, listCoursesIds).then(res => { return res.data })
      subject = [
        Courses?.filter((obj: any) => obj.status === "PUBLISHED").map((obj: any) => { return obj.id + " " + obj.type + " " + "QUESTION" })
        , coursesChapters.map((obj: any) => { return obj.idChapter + " CHAPTER QUESTION" })
      ].flat(Infinity)

    } else if (object.idCours !== null && object.type === "COURSE") {
      Courses = [await axios.get(`${process.env.REACT_APP_BASE_URL}courses/${object.idCours}`).then(res => { return res.data })]
      coursesChapters = await axios.post(`${process.env.REACT_APP_BASE_URL}chapters/getCoursesChapters`, [object.idCours]).then(res => { return res.data })
      subject = [object.idCours + " COURSE QUESTION", coursesChapters.map((obj: any) => { return obj.idChapter + " CHAPTER QUESTION" })].flat(Infinity)
    } else {
      Courses = await axios.post(`${process.env.REACT_APP_BASE_URL14}training/catalog`, { entityId: schoolId, entityType: 'SCHOOL', idProf: professorId }).then(res => { return res.data })
      subject = [object.idCours + " " + object.type + " " + "QUESTION"]
    }



    if (object.actualTab === "all") {
      listAllQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/all`, { subject: subject, trie: object.trie, searchFilter: object.searchFilter, page: object.page }).then(res => { return res.data })

      let listAuthorId: any = [listAllQuestions.map((post: any) => {
        return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
      })].flat(Infinity);
      let listAuthorIdDistinct = _.uniq(listAuthorId)
      const listAuthor = await axios.post(baseUrlPerson + "person/student/" + schoolId, listAuthorIdDistinct).then(response => { return response.data })

      filteredQuestions = getFormatList(listAllQuestions, listAuthor, Courses, coursesChapters)

    } else if (object.actualTab === "unsettled") {

      listUnsettledQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/unsettled`, { subject: subject, trie: object.trie, searchFilter: object.searchFilter, page: object.page }).then(res => { return res.data })
      let listAuthorId: any = [listUnsettledQuestions.map((post: any) => {
        return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
      })].flat(Infinity);
      let listAuthorIdDistinct = _.uniq(listAuthorId)
      const listAuthor = await axios.post(baseUrlPerson + "person/student/" + schoolId, listAuthorIdDistinct).then(response => { return response.data })

      filteredQuestions = getFormatList(listUnsettledQuestions, listAuthor, Courses, coursesChapters)
    } else {
      listSettledQuestions = await axios.post(`${process.env.REACT_APP_BASE_URL10}chat/getQuestionsByType/settled`, { subject: subject, trie: object.trie, searchFilter: object.searchFilter, page: object.page }).then(res => { return res.data })

      let listAuthorId: any = [listSettledQuestions.map((post: any) => {
        return [post.idAuthor, post.responses.map((response: any) => { return response.idAuthor })];
      })].flat(Infinity);
      let listAuthorIdDistinct = _.uniq(listAuthorId)
      const listAuthor = await axios.post(baseUrlPerson + "person/student/" + schoolId, listAuthorIdDistinct).then(response => { return response.data })

      filteredQuestions = getFormatList(listSettledQuestions, listAuthor, Courses, coursesChapters)
    }



    return dispatch({
      type: EReduxActionTypes.GET_FILTERED_QUESTIONS,
      data: { filteredQuestions, tab: object.actualTab, isSearch: isSearch }
    })
  }
}

export function createSeens(object: any, what: string): ThunkAction<Promise<IReduxCreateSeensAction>, IReduxChatState, undefined, IReduxCreateSeensAction> {
  return async (dispatch: ThunkDispatch<IReduxChatState, undefined, IReduxCreateSeensAction>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL10;
    const res: any = await axios.post(baseUrl + "chat/createSeens", object)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.error(err)
      });

    return dispatch({
      type: EReduxActionTypes.CREATE_SEENS,
      data: { seens: res.data, what: what }
    });

  };
}

export function getFormatList(list: any, listAuthor: any, Courses: any, coursesChapters: any) {

  return list.map((obj: any) => {
    obj['responses'] = obj?.responses?.map((res: any) => {
      const author = listAuthor.find((person: any) => person.id === Number(res.idAuthor));
      if (author) {
        res.idAuthor = author;
      }
      return res;
    })
    const postAuthor = listAuthor.find((person: any) => person.id === Number(obj.idAuthor));
    obj.idAuthor = postAuthor

    if (postAuthor && obj.subject.category !== "CHAPTER") {
      const course = Courses?.find((objcourse: any) => objcourse.id === Number(obj.subject.idSubject));
      obj.subject.category = course ? { category: obj.subject.category, idCourse: course.id, courseTitle: course.title } : obj.subject.category;
    }
    else if (postAuthor && obj.subject.category === "CHAPTER") {
      const chapter = coursesChapters.find((objchapter: any) => objchapter.idChapter === Number(obj.subject.idSubject));
      chapter['category'] = "CHAPTER";
      obj.subject.category = chapter ? chapter : "CHAPTER";
    }
    return obj;
  });





}

