import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { closeModal, openModal } from '../../../global-state/actions/modal-actions';
import axiosInstance from '../../../helpers/interceptors';
import SelectsMultiple from '../../../pages/sondage/SelectsMultiple';
import copy_link from '../../../styles/imgs/sondage/copy-link.svg';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { ModalIDS } from '../modal-ids';
import ShareSocialMedia from '../../share-social-media-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';

const ModalShareSondage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = ({ 
    closeModal,
    schoolInfo,
    openModal,
    sondageReducer 
}: any) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const currentUri = window.location.href
    const [studentsList, setStudentsList] = useState<any>([]);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    let idSurvey = useMemo(() => sondageReducer?.id, [sondageReducer?.id]) 
   let surveyLink = `${currentUri.split('/')[0]}//${currentUri.split('/')[2]}/${currentUri.split('/')[3]}/survey/user/${sondageReducer?.id}`
    const [mailObject, setMailObject] = useState("")
    const [mailMessage, setMailMessage] = useState("")
    const [isPrivate, setIsPrivate] = useState(false)
    const uri = process.env.REACT_APP_BASE_URL2

    useEffect(() => {
      const getListOfStudents = async() => await axiosInstance.get(`${uri}person/getListStudentsBySchoolId/${schoolInfo.id}`)
      
      getListOfStudents().then((response:any)=>setStudentsList(response.data));
    }, [])
    


    


    const shareSurvey = async ()=>{
        const filtredStudents = studentsList?.filter((obj:any) => studentsSelected?.includes(obj.id));
        setStudentsSelected(filtredStudents)
        let emailsList:any = []
        filtredStudents?.map((student:any)=> emailsList?.push(student?.email))

        let objectSurveyShare ={
            "surveyId": idSurvey,
            "studentsEmailList":emailsList,
            "isPrivate":isPrivate,
            "mailTitle":mailObject,
            "mailObject":mailObject,
            "mailMessage":mailMessage,
            "surveyLink": surveyLink
        }
        const baseUrl = process.env.REACT_APP_BASE_URL14;
       await  axiosInstance.post(baseUrl + "survey/sendSurveyInvitations",objectSurveyShare)
            .then((resp:any)=>{                
                openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: "RETOUR À LA LISTE  DE SONDAGE", path: `${returnStartPathUrl()}/survey`, lines: ["Sondage partagé avec succès"]});

            })
         
            
    }

    const shareViaSocialMedia = (type: string) => {
        
    }


  
    return (
        <div className='container-modal-share-sondage'>
            <div className="header-modal-sondage" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button 
                        className="btn-QuickActions" 
                        onClick={() => closeModal()}
                    >
                        <span className="material-icons">close</span>
                    </button>
                </div>
            </div>
            <div className="" style={{ paddingTop: 0, overflow:'hidden'}}>
                <div className='H3-Headline'>Partager le sondage</div>
                <div className='body-xl bold' style={{ color: '#cacbce', marginBottom: '16px'}}>Enquête sur le programme de l’école</div>
                <div className='body-md' style={{ marginBottom: '15px' }}>Partagez votre sondage via e-mail, en copiant le lien ou en partageant sur 
                    les réseaux sociaux de votre école</div>
                <div className='d-flex justify-content-between align-items-center' style={{ marginBottom: '28px' }}>
                    <span className='body-md bold'>Sondage externe</span> 
                    <label className="switch">
                        <input type="checkbox"/>
                        <span className="slider round" onClick={()=> setIsPrivate(!isPrivate)}></span>
                    </label>
                </div>

                
                {isPrivate===true && <div className='copy-shared-link d-flex justify-content-between align-items-center' style={{ marginBottom: "34px"}}>
                <div 
                        className='copy-link-sondage' 
                        onClick={() =>
                            navigator.clipboard
                              .writeText(surveyLink)
                              .then(() => {
                                setOpen(true);
                              })
                          }
                    >
                        <img src={copy_link} alt="" style={{ marginRight: '4px' }} />
                        <span>Copier le lien</span>
                    </div>
                    <div className='shared-social-media'>
                        <span className='share-txt'>Partagez sur</span>

                        {['FB', 'IN', 'TW'].map((type: any) => <ShareSocialMedia type={type} url={surveyLink} />)}
                        
                    </div>
                </div>}
                <div style={{ marginBottom: '32px' }}>
                    <span className='body-md bold'>Sélectionnez les étudiants</span>
                    <SelectsMultiple
                        optionsProps={studentsList}
                        taille={'lg'} 
                        studentsSelected={studentsSelected} 
                        setStudentSelectedList={setStudentsSelected}
                        status="coming"
                    />
                </div>
                <div style={{ marginBottom: '29px' }}>
                    <label className="form-title-welcome body-md bold">Objet du mail</label>
                    <input 
                        type="text" 
                        className="form-control input-welcome" 
                        placeholder="Objet du mail" 
                        onChange={(e)=> setMailObject(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: '53px' }}>
                    <label className="form-title-welcome body-md bold">Ajoutez votre message</label>
                    <input 
                        type="text" 
                        className="form-control input-welcome" 
                        placeholder="Corps du mail..." 
                        onChange={(e)=> setMailMessage(e.target.value)}  
                    />
                </div>
            </div>
            <div className='footer-modal-sondage d-flex justify-content-end'>
                <button type="button" className="btn-Secondary large" style={{ marginRight: '12px' }} onClick={()=>{closeModal()}}>
                    Annuler
                </button>

                <button type="button" className="btn-Primary large" onClick={()=>{shareSurvey()}} disabled={mailMessage?.length===0 || mailObject?.length===0 || studentsSelected.length===0 }>
                    Partager
                </button>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose}>Lien copié</Alert>
            </Snackbar>
        </div>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({

    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    loadingAllProfessors: state.profReducer.loadingAllProfessors,
    subscription: state.schoolInfoReducer.subscription,
    content: state.modalReducer.modalContent,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            closeModal,
            openModal
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalShareSondage);
