import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createRegistration, clearRegistrationForm, deleteRegistration } from '../../../../global-state/actions/course-actions';
import { debounce } from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import TextareaWithoutBorderElement from './../../../../elements/text-area/text-area-without-border-element';
import HeaderTrainingFormComponent from '../../../../components/formations-card/header-formation-form';

const AdmissionPathPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [admissionForm, setAdmissionForm] = useState<any>({
        id: null,
        questions: [],
        documents: [],
    });
    const [showForm, setShowForm] = useState<boolean>(false);
    useEffect(() => {
        setAdmissionForm(props?.registration);
        if (props?.path?.idRegistrationForm === null || props?.path?.idRegistrationForm === 0) {
            props?.clearRegistrationForm();
            setShowForm(false);
        }
        else {
            if (props?.registration?.documents.length === 0 && props?.registration?.questions.length === 0) {
                props?.deleteRegistration(props?.path?.idRegistrationForm, props?.path)
                props?.clearRegistrationForm();
                setShowForm(false)
            } else {
                setShowForm(true);
                setAdmissionForm({
                    id: props?.path?.idRegistrationForm,
                    questions: props?.registration?.questions,
                    documents: props?.registration?.documents,
                });
            }
        }
    }, []);
    useEffect(() => {
        setAdmissionForm(props?.registration);
    }, [props?.registration])


    const showFormFn = () => {
        setShowForm(!showForm);
    }
    const handleChangeInput = (e: string, index: any, type: string) => {
        const copyAdmissionForm = { ...admissionForm };
        if (type === "Question") {
            let newquestion = {
                id: copyAdmissionForm.questions[index].id,
                index: copyAdmissionForm.questions[index].index,
                question: e,
            }
            copyAdmissionForm.questions[index] = newquestion;

        }
        else if (type === "Document") {
            let newdocument = {
                id: copyAdmissionForm.documents[index].id,
                index: copyAdmissionForm.documents[index].index,
                document: e,
            }
            copyAdmissionForm.documents[index] = newdocument;
        }
        setAdmissionForm(copyAdmissionForm);
        debouncedChangeHandler(copyAdmissionForm)
    }
    const callApiCreateRegistration = (data: any) => {
        props?.createRegistration(data, props?.path)
    }
    const handleRemoveFields = (index: any, type: string) => {
        let values = { ...admissionForm };

        if (type === "Question") {
            values.questions.splice(index, 1);
            values.questions = values.questions.map((obj: any, indexObj: number) => {
                obj.index = indexObj + 1;
                return obj;
            })

        } else if (type === "Document") {
            values.documents.splice(index, 1);
            values.documents = values.documents.map((obj: any, itemIindex: number) => {
                obj.index = itemIindex + 1;
                return obj;
            })
        }
        setAdmissionForm(values);
        callApiCreateRegistration(values);
    }
    const handleAddQuestion = (type: string) => {
        const copyAdmissionForm = { ...admissionForm };
        if (type === "Question") {
            copyAdmissionForm.questions.push({ id: null, index: admissionForm.questions.length + 1, question: '' });
        }
        else if (type === "Document") {
            copyAdmissionForm.documents.push({ id: null, index: admissionForm.documents.length + 1, document: '' })
        }
        setAdmissionForm(copyAdmissionForm)

    }
    const debouncedChangeHandler = useCallback(debounce(callApiCreateRegistration, 1000), []);


    return (
        <>
        <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
                <HeaderTrainingFormComponent type={"COURSE"} title={t('inscriptionTitle')} status={props?.path?.status} trainingTitle={props?.path?.title} />
            </div>
            <div className="m-auto" style={{ maxWidth: 1200 }}>
                <div className="px-2">
                    <h3 className="mt-5 H3-Headline black-800">{t('conditionDeValidation')}</h3>
                </div>

                <div className="px-2 my-5 w-100 d-flex flex-column">
                        <div className="d-flex align-items-top mb-2">
                            <input dir="ltr"className="input-style-radio" readOnly type="radio" name="exampleRadios" id="radFalse" value="option" onClick={() => showFormFn()} checked={showForm === false} />
                            <label htmlFor="radFalse"></label>
                            <span onClick={() => showFormFn()} style={{cursor:'pointer'}} className=" body-md black-800 ps-1 ms-2"> {t('Acceptation')}</span>
                        </div>
                        <div className="d-flex align-items-top">
                            <input dir="ltr"className="input-style-radio" readOnly type="radio" name="exampleRadios" id="radTrue" value="option" onClick={() => showFormFn()} checked={showForm === true} />
                            <label htmlFor="radTrue"></label>
                            <span onClick={() => showFormFn()}  style={{cursor:'pointer'}} className=" body-md black-800 ps-1 ms-2"> {t('AcceptationSousDossier')}</span>
                        </div>
                </div>

                {showForm ?
                    <div className="px-2 mt-5">
                        <h3 className="mb-5 mt-5 H3-Headline black-800">{t('questions')}</h3>
                        {admissionForm?.questions?.map((question: any, index: any) => (
                            <div className="col-md-12 mt-1" key={index}>
                                <div className="d-flex align-items-center justify-content-between">

                                    <div style={{width: "90%"}}>
                                        <label className="body-md bold black-800 mb-3" >{t('Question')} {index + 1}</label>
                                        <TextareaWithoutBorderElement id={"question" + index} placeholder={t('placeholderInscriptionInput')}
                                            disabled={(props?.path?.status !== "DRAFT")}
                                            value={question?.question}
                                            onChange={(e: string) => handleChangeInput(e, index, "Question")} />
                                    </div>
                                    <div className="">
                                        <button className="btn-QuickActions" disabled={(props?.path?.status !== "DRAFT")} onClick={() => handleRemoveFields(index, "Question")} >
                                            <span className="material-icons-outlined">delete</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        ))}
                        <div>
                            <button className="btn-Secondary large icon-left mt-5 mb-5" onClick={() => handleAddQuestion("Question")} disabled={(props?.path?.status !== "DRAFT")}>
                                <span className="material-icons" >add</span>
                                <div className="m-auto ps-2">{t('AjouterQuestion')}</div>
                                </button>
                        </div>
                        <h3 className="mt-5 mb-5 H3-Headline black-800">{t('documents')}</h3>
                        {admissionForm?.documents?.map((document: any, index: any) => (
                            <div className="col-md-12 mt-1 mb-2" key={index}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{width: "90%"}}>
                                        <label className="body-md bold black-800 mb-3">{t('documentTextForm')} {index + 1}</label>
                                        <TextareaWithoutBorderElement id={"document" + index} placeholder={t('placeholderInscriptionInput')}
                                            disabled={(props?.path?.status !== "DRAFT")}
                                            value={document?.document}
                                            onChange={(e: string) => handleChangeInput(e, index, "Document")} />
                                    </div>
                                    <div className="">
                                        <button className="btn-QuickActions" disabled={(props?.path?.status !== "DRAFT")} onClick={() => handleRemoveFields(index, "Document")} >
                                            <span className="material-icons-outlined">delete</span>
                                        </button>
                                    </div>

                                </div>
                            </div>

                        ))}

                        <div>
                            <button className="btn-Secondary large icon-left mt-5 mb-5" style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleAddQuestion("Document")} disabled={(props?.path?.status !== "DRAFT")}  >
                                <span className="material-icons">add</span>
                                <div className="m-auto ps-2">{t('AjouterDocument')}</div>
                            </button>
                        </div>
                    </div> : null}
            </div>
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createRegistration,
            clearRegistrationForm,
            deleteRegistration
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    courseTitle: ownProps?.courseTitle,
    path: state.trainingReducer.path,
    coursInfo: state.courseReducer.courseInfo,
    registration: state.courseReducer.registration,
    history: ownProps?.history,
    t: ownProps?.t
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(AdmissionPathPage));