import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarOutline from '@material-ui/icons/StarOutline';
import DefaultTitle from '../../../helpers/default-title'
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
interface Props {
  follow?: any
  label?: string
  onClick: any
}

const tags = [
  { value: "Published", label: "En ligne", color: "success" },
  { value: "Draft", label: "Brouillon", color: "primary" },
  { value: "Pending", label: "En attente de validation par le directeur", color: "warning" },
  { value: "Refused", label: "Refusé", color: "danger" },
  { value: "Deactivate", label: "Desactivé", color: "secondary" },
  { value: "Archive", label: "Archivé", color: "#B1B1B1" },
  { value: "RequestDeactivation", label: "En attente de désactivation par le directeur", color: "#F5B95F" },
  { value: "RequestActivation", label: "En attente d'activation par le directeur", color: "#F5B95F" },
  { value: "RequestDeletion", label: "En attente d'archivage par le directeur", color: "#F5B95F" },

]
const levels = [
  { value: "BEGINNER", label: "selectLevelCourse_1" },
  { value: "INTERMEDIATE", label: "selectLevelCourse_2" },
  { value: "ADVANCED", label: "selectLevelCourse_3" },
  { value: "ANYLEVEL", label: "selectLevelCourse_4" },
]
const CardCourseVerticalComponent: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const tag = tags.find(obj => obj?.value.toUpperCase() === props?.follow?.status)
  const dateFormat = require('dateformat');
  const convertTime = (minutes: number) => {
    return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
  }
  const getType: any = () => {
    switch (props?.follow?.type) {
      case "COURSE": return <>
        <div className="d-flex align-items-center me-2">
          <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
            </g>
            <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
            <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
            <defs>
              <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="card-text">
          <div className="body-sm bold">
            <span className="card-text neutral-2">{t('Course')}</span>
          </div>
        </div>
      </>
      case "PATH": return <>
        <div className="d-flex align-items-center me-2">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909 12.4364 3.56364 13.0909
                 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z" fill="#FA83EE" />
          </svg>
        </div>
        <div className="card-text me-4">
          <div className="body-sm bold">
            <span className="card-text neutral-2">
              {t('parcoursTitle')}
            </span>
          </div>
        </div>
      </>
      case "SESSION": return <>
        <div className="d-flex align-items-center me-2">
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC"></path></g><line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3"></line><line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3"></line><line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3"></line><defs><clipPath id="clip0"><rect width="16" height="16" fill="white" transform="translate(0 1.5)"></rect></clipPath></defs></svg>
        </div>
        <div className="card-text me-4">
          <div className="body-sm bold">
            <span className="card-text neutral-2">
              {t('sessionTitle')} du {dateFormat(props?.follow?.startDay,"dd/mm/yyyy")} au {dateFormat(props?.follow?.closeDay,"dd/mm/yyyy")}
            </span>
          </div>
        </div>
      </>
      default: return <></>
    }
  }
  return (<>
    <div className="card vignette-hover" onClick={props?.onClick} style={{ cursor: 'pointer', minHeight: 534 }}>
      <img className="card-img-top img-card"
        src={(props?.follow?.linkImg && props?.follow?.linkImg !== '' && props?.follow?.linkImg !== null) ? props?.follow?.linkImg : `${process.env.PUBLIC_URL}/imgs/no-image.png`}
        alt="Card cap webinar" />
      <span className={`badge badge-${tag?.color}`}>{t('' + tag?.value)}</span>
      <div className="card-body p-4 bg-with-border d-flex flex-column">
        <div className="d-flex flex-column pb-2 mb-4">
          <div className="H3-Headline cours-card vertical title mb-2" style={{ height: 64 }}>
          <DefaultTitle title={props?.follow.title} type={props?.follow.type}/>
          </div>
          <div className="d-flex flex-row align-items-center pt-2 mb-4">
            {getType()}
          </div>
          <div className="d-flex flex-row me-3">
            <Box component="fieldset" borderColor="transparent" height="24px">
              <Rating
                readOnly
                precision={0.1}
                name="customized-empty"
                value={props?.follow?.rating}
                emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                style={{ color: "#F5B95F" }}
              />
            </Box>
          </div>
        </div>
        <div className="d-flex flex-column mt-auto">
          <div className="d-flex justify-content-start pb-2 mb-4 flex-row flex-wrap">
            <div className="d-flex pe-3 flex-column me-5">
              <span className="H4-Subtitle">
                {(props?.follow?.countInscribed < 10) ? '0' + props?.follow?.countInscribed : props?.follow?.countInscribed}
              </span>
              <span className="body-sm bold card-text title-name">
                {t('inscrits')}
              </span>
            </div>
            <div className="d-flex pe-3 flex-column me-5">
              <span className="H4-Subtitle">
                {(props?.follow?.countCertified < 10) ? '0' + props?.follow?.countCertified : props?.follow?.countCertified}
              </span>
              <span className="body-sm bold card-text title-name">
                {t('certified')}
              </span>
            </div>
            <div className="d-flex pe-3 flex-column">
              <span className="H4-Subtitle">
                {(props?.follow?.countCandidates < 10) ? '0' + props?.follow?.countCandidates : props?.follow?.countCandidates}
              </span>
              <span className="body-sm bold card-text title-name">
                {t('candidatsCapitalise')}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-start flex-wrap">
            <div className="d-flex me-3">
              <span className="material-icons-outlined icon-grey pe-1">
                signal_cellular_alt
              </span>
              <span className="body-md bold black-800">
                {props?.follow?.level === 'ANYLEVEL' ? <></> : <>{t('selectLevelCourse')} </>}
                {
                  // t('selectLevelCourse')} {tag?.value === 'Draft' ? <>...</> : 
                  t('' + levels.find(level => level.value === props?.follow?.level)?.label)
                }

              </span>
            </div>
            <div className="d-flex">
              <span className="material-icons-outlined icon-grey pe-1">
                timer
              </span>
              <span className="body-md bold details black-800">
                {tag?.value === 'Draft' ? <>...</> : convertTime(props?.follow?.duration)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default CardCourseVerticalComponent
