import { ComponentProps, FC, useMemo } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions'
import couponImg from "../../styles/images/visuel_coupon.png"
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { saveActionHistory } from "../../services/history.service";
const CouponNotFoundPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const {t} = useTranslation()
    const history = useHistory()
    const goTo = async () => {
        await saveActionHistory("koors_upgrade_coupon",props?.connectuser?.idPerson,props?.connectuser?.idSchool)
        props?.clearHistoryArray()
        props?.redirect(t("offersList"), `${returnStartPathUrl()}/coupon`)
         history.push(`${returnStartPathUrl()}/list`);
    }
    return (<>
        <>
            <div className="container-fluid" >
                <div className="row justify-content-center">
                    <div className="col-md-12" style={{ backgroundColor: '#ffffff' }}>
                        <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                            <div className=" col-md-6 mt-5" >
                                <div className="d-flex flex-column py-4">
                                    <span className="Button-xl mb-4" style={{ color: "#F5B95F" }}>{t('coupons').toUpperCase()}</span>
                                    <span className="H2-Headline mb-4" >{t('createAndPersonalizeCoupon')}</span>
                                    <span className="body-xl neutral-2" >{t('couponGeneration')}</span>
                                    <span className="body-xl neutral-2" >{t('couponGeneration2')}</span>
                                    <span className="body-xl mb-3 pb-2 neutral-2" >{t('couponGeneration3')}</span>
                                    <span className="body-xl neutral-2">{t('withKoors')}</span>
                                    <span className="body-xl mb-5 neutral-2">{t('withKoors2')}</span>


                                    <button type="button" className="btn-Primary large icon-right mt-2" onClick={()=>{goTo()}} >
                                        <div className="m-auto pe-2">{t('improveMyOffer')}</div>
                                        <span className="material-icons-outlined">
                                        keyboard_arrow_right
                                        </span>
                                    </button>
                                </div>
                                <div className="py-4 w-100">

                                </div>



                            </div>
                            <div className="col-md-6 mt-5 py-4" >

                                <img className="ps-5 w-150"  src={couponImg} alt="coupon creation"  />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    history: ownProps?.history,
    connectuser: state.authReducer.connectedUser,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ redirect, clearHistoryArray, clearAllData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CouponNotFoundPage);