import { FC } from "react";
import {Switch } from "react-router";
import RouteWithNavbar from "../../navigations/RouteWIthNavbar";
import RouteWithBreadCrumb from '../../navigations/RouteWithBreadCrumb';
import WebinarsListCardPage from "./webinars-list-card-page";
import RecordCardPage from "./record-card-page";
import FooterComponent from "../../components/footer-component";
import "../../translations/i18n";
import CustomSwitch from "../../helpers/custom-switch";
const WebinarsContainer: FC<any> = (props: any) => {
  return (<>
     <div className="page-wrapper-height">
    <CustomSwitch>
      <RouteWithNavbar history={props?.history} path={`${props?.match.path}`} exact={true} component={WebinarsListCardPage} />
      <RouteWithBreadCrumb   exact={true} path={`${props?.match.path}/record/:id`} component={RecordCardPage} />  
    </CustomSwitch>
    </div>
    <FooterComponent />
  </>
  )
}
export default WebinarsContainer;