import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../global-state/actions/profile-actions';
import { setActualTab } from '../../global-state/actions/professor-actions';
import { getStudentInfoById } from '../../global-state/actions/course-actions';
import TableElement from '../../elements/table/normal-without-action-table-element';
import { useHistory } from 'react-router-dom';
import { openModal } from '../../global-state/actions/modal-actions';
import { suspendStudent } from '../../global-state/actions/profile-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import { getStudentStatus } from '../../global-state/actions/app-container-actions';
import { getActiveStudentsApi } from '../../services/finance.service';

const StudentListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();

    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [allDataLength, setAllDataLength] = useState<any>(null);
    const [allData, setAllData] = useState<any>(null);
    const [atBottom, setAtBottom] = useState<any>(false)
    const [students, setStudents] = useState<any>()
    const generalHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
        { title: t('Action'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]
    useEffect(() => {
        setLoadingTable(props?.loadStudentsGeneral);
    }, [props?.loadStudentsGeneral])

    useEffect(() => {
        setActualTab(props?.lastTabClicked);
    }, [props?.lastTabClicked])
    const SuspendStudentButton = async (id: any) => {
        await props?.getStudentInfoById(id, props?.schoolInfo?.id).then(async (response: any) => {
            await props?.openModal({ idModal: ModalIDS.DEACTIVATE_STUDENT, size: "md", data: { student: response.data } })

        })
    }
    const reinstateStudentButton = async (id: any) => {
        await props?.getStudentInfoById(id, props?.schoolInfo?.id).then(async (response: any) => {
            props?.openModal({ idModal: ModalIDS.REINTEGRATE_STUDENT, size: "md", data: { student: response.data } });
        });
    }

    const getActiveStudents = (id: number) =>{
        const result = getActiveStudentsApi(id)
        setStudents(result)
    }

    useEffect(() => {
        getActiveStudents(props?.schoolInfo?.id)
    }, [props?.schoolInfo])

    useEffect(() => {
        if (!!students) {
            const list = {
                headers: generalHeaders,
                body: students.map((student: any) => {
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },

                                {
                                    content: (student?.lastConnexion === null) ? '-' : moment(student?.lastConnexion).fromNow(),
                                    classNames: `text-end`,
                                    actionName: ''
                                },
                                {
                                    content: <>{(student.statusStudent == "ACTIVE") &&
                                        <button type="button" className="btn-Primary medium danger" onClick={() => { SuspendStudentButton(student.id) }}>
                                            {t('deactivateCours')}
                                        </button>}
                                        {(student.statusStudent == "SUSPENDU") &&
                                            <button type="button" className="btn-Primary medium " onClick={() => { reinstateStudentButton(student.id) }}>
                                                {t('activate')}
                                            </button>}

                                    </>, classNames: `text-end badge-text `, actionName: ''
                                },
                            ]
                        }
                    }
                })
            }
            setAllData(list)
        }



    }, [students])
  
    useEffect(() => {


        const student = props?.rule.filter((rule: any) => rule.key === 'nb_student')[0] 
        if (!!props?.rule && !!students) {
            (Number(student.value) >= students?.filter((student: any) => student?.statusStudent === "ACTIVE")?.length) ? props?.getStudentStatus(true) : props?.getStudentStatus(false)
        }
    }, [props?.rule, students])

    return (<>

        <div>
            {(allData?.body?.length > 0) && <>
                <TableElement data={allData} />


            </>
            }

        </div>
    </>);
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    loadStudentsGeneral: state.profReducer.loadStudentsGeneral,
    addDataList: ownProps?.addDataList,
    data: ownProps?.data,
    student: state.courseReducer.studentInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudentInfoById,
            getStudent,
            setActualTab,
            suspendStudent,
            openModal,
            getStudentStatus,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentListComponent);