import { FC, useEffect } from "react";
import RouteWithBreadCrumb from '../../navigations/RouteWithBreadCrumb';
import RouteWithNavbar from '../../navigations/RouteWIthNavbar';
import OfferDetailsPage from "./offer-details-page";
import PaymentListPage from "./payment-list-page";
import FooterComponent from "../../components/footer-component";
import CustomSwitch from "../../helpers/custom-switch";
import PrivateRoute from "../../navigations/PrivateRoute";
const FinanceContainer: FC<any> = (props: any) => {

  return (<>
    <div className="page-wrapper-height">
      <CustomSwitch>
            <RouteWithNavbar exact={true} history={props?.history} roles={['role_director']} path={`${props?.match.path}/all`} component={PaymentListPage} />
            <RouteWithBreadCrumb exact={true} roles={['role_director']} path={`${props?.match.path}/detail-course/:id`} component={OfferDetailsPage} />
  
      </CustomSwitch>

    </div>
    <FooterComponent />

  </>

  )
}
export default FinanceContainer;