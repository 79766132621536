import { useState, ComponentProps, FC, useEffect} from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import TableStudentGroupElement from '../../elements/table/table-student-group-element';
import ActionTableStudentGroupListe from '../group/action-table-group-student-component';
import { clearHistoryArray, redirect } from '../../global-state/actions/breadcrumb-actions';
import { getStudent } from '../../global-state/actions/profile-actions';
import { useHistory } from 'react-router-dom';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import * as _ from 'lodash';
import { getStudentInfoById } from '../../global-state/actions/course-actions';

const moment = require('moment');

const TableStudentGroupComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState<any>(null);
    const [allData, setAllData] = useState<any>(null)
    const [studentList, setStudentList] = useState<any>([]);
    const [open, setOpen] = useState([false, false]);
    const tabHeaders = [
        { title: t('Etudiants'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Action'), sorted: false, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto " },
    ]
    const tabHeadersAllStudent = [
        { title: t('Etudiants'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('connectedThereIs'), sorted: true, classNames: "w-15", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    ]
    const dateFormat = require('dateformat');
    useEffect(() => {

        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else { moment.defineLocale('en', { parentLocale: 'en' }); }
    }, [])

    const openActionTable = (i: number, action: string) => {
        const openCopy = [...open];
        if ((action === 'open' && openCopy[i]) || action === '') {
            openCopy[i] = !openCopy[i]
        }
        setOpen(openCopy);
    }
    useEffect(() => {
        if (props?.isSearch === true) {
            setStudentList(props?.filteredStudents)
        }
    }, [props?.filteredStudents])

    useEffect(() => {
        
            if (props?.tabGroupManager === "all" && props?.groupForm?.childsList?.length !== 0) {
             
                const personList = _.uniqBy([
                    ...(props?.groupForm?.personList ?? []),
                    ...(props?.groupForm?.childsList?.flatMap((subGroup: any) =>
                      subGroup?.personList ?? []
                    ) ?? [])
                  ], 'id');
                  setStudentList(personList)

            } else if (props?.tabGroupManager === props?.groupForm?.label || (props?.tabGroupManager === "all" && props?.groupForm?.childsList.length === 0)) {
                setStudentList(props?.groupForm?.personList)
            } else {
                let actualGroup = props?.groupForm?.childsList.find((obj: any) => { return obj.label === props?.tabGroupManager })
                setStudentList(actualGroup?.personList)
            }
        

    }, [props?.groupForm, props?.groupForm?.personList, props?.tabGroupManager])

    useEffect(() => {

        let list = {
            headers: tabHeaders,


            body: studentList?.map((student: any, index: number) => {
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white relative",
                        columns: [
                            { content: student?.firstName + " " + student?.lastName, classNames: "", actionName: '' },
                            {
                                content: <span> {dateFormat(student?.creationDate, "dd/mm/yyyy")}
                                    {(moment(Date()).diff(moment(student?.creationDate), 'days') < 3) && <span className="badge-text secondary">{" / " + moment(student?.creationDate).fromNow()}</span>}
                                    {(3 <= moment(Date()).diff(moment(student?.creationDate), 'days') && moment(Date()).diff(moment(student?.creationDate), 'days') < 6) && <span className="badge-text warning">{" / " + moment(student?.creationDate).fromNow()}</span>}
                                    {(6 <= moment(Date()).diff(moment(student?.creationDate), 'days')) && <span className="badge-text danger">{" / " + moment(student?.creationDate).fromNow()}</span>}
                                </span>,
                                classNames: "text-center", actionName: ''
                            },
                            { content:
                                <span> 
                                {(student?.lastConnexion == null) ?  <span>-</span>:<span>{moment(student?.lastConnexion).fromNow()}</span>}

                            </span>,
                            classNames: "text-center", actionName: ''
                            },
                            {
                                content: <ActionTableStudentGroupListe student={student} index={index}  tabGroupManager={props.tabGroupManager} groupForm={props.groupForm} groupList={props.groupList}  />
                                , classNames: "text-center with-icon p-0 m-0 relative-position ", actionName: 'openModifiers'
                            },
                        ]
                    }
                }
            })
        }
        setData(list)
    }, [studentList])

    useEffect(() => {
        let list = {
            headers: tabHeadersAllStudent,
            body: studentList?.map((student: any, index: number) => {
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white ",
                        columns: [
                            { content: student?.firstName + " " + student?.lastName, classNames: "", actionName: '' },
                            {
                                content: <span> {dateFormat(student?.creationDate, "dd/mm/yyyy")}
                                    {(moment(Date()).diff(moment(student?.creationDate), 'days') < 3) && <span className="badge-text secondary">{" / " + moment(student?.creationDate).fromNow()}</span>}
                                    {(3 <= moment(Date()).diff(moment(student?.creationDate), 'days') && moment(Date()).diff(moment(student?.creationDate), 'days') < 6) && <span className="badge-text warning">{" / " + moment(student?.creationDate).fromNow()}</span>}
                                    {(6 <= moment(Date()).diff(moment(student?.creationDate), 'days')) && <span className="badge-text danger">{" / " + moment(student?.creationDate).fromNow()}</span>}
                                </span>,
                                classNames: "text-center", actionName: ''
                            },
                            { content:
                                <span> 
                                {(student?.lastConnexion == null) ?  <span>-</span>:<span>{moment(student?.lastConnexion).fromNow()}</span>}

                            </span>,
                            classNames: "text-center", actionName: ''
                            },
                        ]
                    }
                }
            })
        }
        setAllData(list)
    }, [props?.groupForm, props?.groupForm?.allStudent, studentList])

    const doAction = async (value: any) => {
        if (value.action === "openModifiers") {
            openActionTable(0, 'open')
        } else {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
         
            await props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
            history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }
    }
    return (
        <>
            <TableStudentGroupElement  data={(props?.tabGroupManager === "all" && props?.groupForm?.childsList?.length !== 0) ? allData : data} doAction={(value: any) => { doAction(value) }}   />
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    history: ownProps?.history,
    isSearch: ownProps?.isSearch,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudent, 
            getStudentInfoById,
            clearHistoryArray,
            redirect
        }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableStudentGroupComponent);