import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { useLocation } from 'react-router';

const CheckFormTrainingModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [training, setTraining] = useState<any>();
    const [errors, setErrors] = useState<any>({});
    const [title,setTitle] = useState("")
    const [back, setBack] =useState("")
    const location = useLocation()

    useEffect(() => {
        if (location.pathname.includes('/course/')) {
            setTraining(props?.course)
            setErrors(props?.errors.course)
            setTitle(t('continueCreatingCourse'))
            setBack(t('returnToCourse'))
        }
        else if (location.pathname.includes('/path/')) {
            setTraining(props?.path)
            setErrors(props?.errors.path)
            setTitle(t('continueCreatingPath'))
            setBack(t('returnToPath'))
        }
        else if (location.pathname.includes('/session/')) {
            setTraining(props?.session)
            setErrors(props?.errors.session)
            setTitle(t('continueCreatingSession'))
            setBack(t('returnToSession'))
        }
        
        
    });
    return (
        <>
            <div className="d-flex w-100 align-items-center justify-content-end pt-3 pe-3">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
            <div className="d-flex justify-content-center " style={{ paddingTop: 0, marginLeft: '10px' }}>
                <div className="col-md-10 ">
                    <div className="text-left" >
                        <h3 className="H3-Headline black-800 " style={{ textAlign: 'left' }}>{title}</h3>
                        <h3 className="H4-Subtitle neutral-3 mb-4">{training?.title} </h3>
                        {(errors && errors.hasOwnProperty("titleTrainingError")) && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.titleTrainingError}</p>}
                        {(errors && errors.hasOwnProperty("linkImgError")) && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.linkImgError}</p>}
                        {(errors && errors.hasOwnProperty("descriptionError")) && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.descriptionError}</p>}
                        {(errors && errors.hasOwnProperty("content")) && <p className="body-md black-800 mb-2" style={{ display: 'flex', alignItems: 'center' }}> <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span> {errors.content}</p>}

                    </div>
                </div>
            </div>
                <div className='d-flex mt-3 mb-5 me-5 pe-3 justify-content-end'>
                    <button type="button" onClick={() => props?.closeModal()} className="btn-Primary large">{back}</button>
                </div>
        </>

    )

}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal,
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    course: state.trainingReducer.course,
    path: state.trainingReducer.path,
    session: state.trainingReducer.session,
    errors: state.trainingReducer.errors
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckFormTrainingModalComponent);