import { CallBackProps } from "react-joyride";
import { returnStartPathUrl } from "../domainCheck";
import { generateClassNamesFloaterOpen, generateClassNamesSpotlight } from "./generate-classnames";

export const callBackUserGuideContext = (
  data: CallBackProps,
  historyBrowser: any,
  isMailChecked: boolean,
  roleUser: string,
  setUserGuideState: any,
  notifications: any,
) => {

  const { action, index, lifecycle, type, status } = data;
 
  generateClassNamesSpotlight(index, isMailChecked, roleUser, notifications);
  generateClassNamesFloaterOpen(index, isMailChecked, roleUser, notifications);

  document.querySelector('[aria-label="Last"]')?.removeAttribute('title');
  document.querySelector('[aria-label="Next"]')?.removeAttribute('title');
  document.querySelector('[aria-label="Passer"]')?.removeAttribute('title');
  
  const checkNext = {
    [index]: (type: string) => (action: string) => {
      
      if (type === "step:after") {
        
        if(action === 'next') {

          if(index === 0) historyBrowser.push(`${returnStartPathUrl()}/professors/all`);
          if(index === 2) historyBrowser.push(`${returnStartPathUrl()}/students/all`);
          if(index === 4) historyBrowser.push(`${returnStartPathUrl()}/all-courses`);
          if(index === 7) document.getElementById('go-to-prof-role')?.click();
          if(index === 8 && lifecycle !== 'init') historyBrowser.push(`${returnStartPathUrl()}/courses/my-courses`);
          if(index === 10) historyBrowser.push(`${returnStartPathUrl()}/examsDetails/details`);
          if(index === 12) historyBrowser.push(`${returnStartPathUrl()}/questions/all`);

          setUserGuideState((prev: any) => ({
            ...prev,
            run: true,
            stepIndex: index + 1,
            isIncrement: index + 1,
            isOpenCloseUserInfo: index === 6 ? false : true,
            tourActive: true,
          }))
        } 
        if (action === 'skip') {
          setUserGuideState((prev: any) => ({
            ...prev,
            run: false,
            stepIndex: 0,
            isIncrement: 0,
            isOpenCloseUserInfo: true,
            tourActive: false
          }))
        } 
        if (action === 'close') {
          setUserGuideState((prev: any) => ({
            ...prev,
            run: false,
            stepIndex: 0,
            isIncrement: 0,
            isOpenCloseUserInfo: true,
            tourActive: false
          }))
        } 
      } 

      
      //REFRESH CONDITION
      if(status === 'paused' && action !== 'close' && action !== 'stop') {
          
        setUserGuideState((prev: any) => ({
            ...prev,
            run: true,
            isOpenCloseUserInfo: index === 6 ? false : true,
            tourActive: true,
          }))
      }
      
      //COMPLETE CONDITION
      if(lifecycle === 'complete' && type === 'tour:end' && status === 'finished') {
      }
    },
  };
  checkNext[index](type)(action);
};
