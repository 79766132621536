import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import sondage from '../../styles/images/sondage.svg';
import { useTranslation } from 'react-i18next';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useParams } from 'react-router-dom';
import { createProgressQna, getExamToPass } from '../../global-state/actions/exam-actions'
interface Props {
    t: any

}
interface Params {
    id: any
}
const SondageArchivedPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const { id } = useParams<Params>();
    const [qna, setQna] = useState<any>()
    useEffect(() => {
        props?.getExamToPass(id)
    }, [id])

    useEffect(() => {
        setQna(props?.examToPass)
    }, [props?.examToPass])
    return (<>

        <div style={{ backgroundColor: "#FFFFFF" }}>

            <div className="d-flex flex-column align-items-center mx-4" style={{ backgroundColor: "#FBFBFD", paddingTop: "41px" }}>


                <div className="w-100  d-flex align-items-center justify-content-center flex-column px-5 mt-4" style={{ minHeight: 300 }}>

                    <img src={sondage} alt="user icon" />

                </div>
                <h3 className="H1-Headline mt-4" >Ce sondage est clôturé! </h3>
                <span className="body-xl bold mt-3" >{props?.school?.name} </span>

                <h3 className="H3-Headline mt-2" >{qna?.title}  </h3>

                <div className="d-flex flex-column mx-auto" style={{ textAlign: 'center' ,width: 544 }}>
                    <span className="body-md neutral-2" >{qna?.description} </span>

                </div>
            </div>



        </div>



    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    school: state.schoolInfoReducer.schoolInfo,
    examToPass: state.examReducer.examToPass
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamToPass
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,

)(SondageArchivedPage);
