import { ComponentProps, FC, useEffect, useMemo } from 'react';
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { useHistory } from 'react-router-dom';
import { AppState } from "../../../global-state/store/root-reducers";

const FailedModalWithButtonComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {

    const data = useMemo(() => { return props?.content }, [props?.content]);

    return (
        <>
            <div className="modal-header" style={{ border: "none" }}>
                <div className="d-flex w-100 align-items-center justify-content-end">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body" style={{ overflow:'hidden' }}>
                <div className="row body-modal-publish">
                    <div className="col-md-8 center" style={{ margin: 'auto' }}>
                        <div className="row">
                        <div className="col-md-12 text-center mb-1">
                        <span className="material-icons-outlined" style={{ color: '#E06A6A', marginRight: '12px' }}>error_outline</span>

                            </div>

                            <div className="d-flex w-100 align-items-center justify-content-center flex-column mt-4 text-center">
                                {data?.lines?.map((line: string, i: number) => (
                                    <span key={i} className="H4-Subtitle">{line}</span>
                                ))}
                            </div>
                            {data?.buttonText && <div className="col-md-12 mt-4 mb-5 text-center">
                                <button type="button" style={{ minWidth: 123 }} className="btn-Primary large" onClick={() => { props?.closeModal() }}>{data?.buttonText}</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal
    },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FailedModalWithButtonComponent);