import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
export default function CardClosedQuestionComponent(props: any) {
    const index = useMemo(() => { return props?.indexQuestion }, [props?.indexQuestion]);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [question, setQuestion] = useState<string>("");
    const [formQuestion, setFormQuestion] = useState<any>();
    const [displayEx, setDisplayEx] = useState<boolean>(false);
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const openAccordion = () => { setOpen(!open); }
    const [checkQuestionFormState, setCheckQuestionFormState] = useState<boolean>(false)
    const [checkQuestionChoiceState, setCheckQuestionChoiceState] = useState<boolean>(false)
    

    useEffect(() => {
        setFormQuestion(props?.formQuestion);
        setQuestion(props?.formQuestion?.question?.questionStatement)
        if (props?.formQuestion?.question !== '' && !props?.formQuestion?.question?.questionChoice?.map((obj: any) => { return obj.choice }).includes('')) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [])

    useEffect(() => {
        setFormQuestion(props?.formQuestion);
        setQuestion(props?.formQuestion?.question?.questionStatement);
        setDisplayEx((props?.formQuestion.question?.questionAnswerExplanation !== ''));
        if (props?.formQuestion?.question !== '' && !(props?.formQuestion?.question?.questionChoice?.map((obj: any) => { return obj.choice }).includes('')) ) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [props?.formQuestion])

    useEffect(() => {
        if(formQuestion?.id === null) {
            setOpen(false);
        }
    }, [formQuestion])
    const explication = (e: any) => {
        e.preventDefault();
        setDisplayEx(true);
    }
    const addAnswer = (e: any) => {
        const copyForm = { ...formQuestion };
        copyForm.question.questionChoice.push({ id: null, choice: '', choiceState: false });
        setFormQuestion(copyForm);
        checkForm(copyForm);
    }
    const deleteAnswer = (i: number, e: any) => {
        const copyForm = { ...formQuestion };
        copyForm.question.questionChoice.splice(i, 1);
        setFormQuestion(copyForm);
        checkForm(copyForm);
    }
    const changeInputResponse = (e: any, index: number) => {
        const copyForm = { ...formQuestion };
        copyForm.question.questionChoice[index].choice = e;
        setFormQuestion(copyForm);
        checkForm(copyForm);
    }
    const changeInputQuestion = (e: any) => {
        const copyForm = { ...formQuestion };
        copyForm.question.questionStatement = e;
        setFormQuestion(copyForm);
        checkForm(copyForm);
    }
    const changeValueAnswer = (index: number, value: boolean, e: any) => {
        const copyForm = { ...formQuestion };
        copyForm.question.questionChoice[index].choiceState = value;
        copyForm.question.questionChoice.filter((choice: any) => choice.choiceState === true).length > 1 ? copyForm.question.quizType = "QCM" : copyForm.question.quizType = "QCU";
        setFormQuestion(copyForm);
        checkForm(copyForm);
    }


    const verifQuestion = (formQuestion:any)=>{
        let verifTitle:boolean=false
        let verifyChoices:boolean=false
        if(formQuestion?.questionStatement!=0){
            verifTitle=true
        }
        (formQuestion?.questionChoice?.findIndex((choice:any)=>
            choice?.choice?.length===0
        ) ===-1) ? verifyChoices=true :verifyChoices=false

        setCheckQuestionChoiceState(verifTitle && verifyChoices)
    }

    const saveQuestion = async () => {
        await verifQuestion(formQuestion)
        if(checkQuestionChoiceState ===false && checkQuestionFormState === false) {
        await setQuestion(formQuestion.question.questionStatement);
        await props?.saveQuestion(formQuestion, props?.indexQuestion);
        await setDisplayEx(false);
        await setCheckQuestionFormState(false)
        await setCheckQuestionChoiceState(false)
        await setCheckQuestionFormState(false)
        }
    }
    const deleteQuetion = () => {
        props?.deleteQuestion(props?.indexQuestion);
        setDisplayEx(false);
    }

    const checkForm = (form: any) => {
        if (form.question.questionStatement !== '' && !form.question.questionChoice.map((obj: any) => { return obj.choice }).includes('') && form.question.questionChoice.map((obj: any) => { return obj.choiceState }).includes(true)) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }
    const changeExplanation = (e: any) => {
        const copyForm = { ...formQuestion };
        copyForm.question.questionAnswerExplanation = e;
        setFormQuestion(copyForm);
        checkForm(copyForm);
    }

    const cancelClick = () =>{
        const copy = JSON.parse(JSON.stringify(props?.initForm))
        setFormQuestion(copy)
    }
    
    return (
        <>
            <div className="d-flex flex-column mb-3">
                <div className="bg-accordion-border white px-4 w-100" style={(question === '')?{ paddingTop: 35, paddingBottom: 35 }: {paddingTop: 24, paddingBottom: 24}}>
                    <div className="d-flex align-items-center justify-content-between accordion-head-open mx-2">
                        <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                            <div className="d-flex flex-column" >
                                <div className="d-flex align-items-center flex-wrap" style={{marginBottom: 14}}>
                                    <span className="H3-Headline  me-2 pe-1">
                                        <span className="H3-Headline neutral-3">
                                            {t('questionLabel')}
                                        </span> {index + 1}
                                    </span>
                                    {question !== '' && <span className="body-sm bold"><span className="body-sm bold neutral-3">{t('singleClosedQuestion')} / </span> 1 {t('pointsQuestion')}</span>}
                                </div>
                                {question !== '' && <span className="H4-Subtitle bold ">{question}</span>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-no-wrap">
                            <button onClick={() => deleteQuetion()} type="button" className="btn-QuickActions mx-3">
                                <span className="material-icons-outlined">delete</span>
                            </button>
                            <button onClick={() => openAccordion()} type="button" className="btn-QuickActions">
                                {!open ? <>
                                    <span className="material-icons-outlined">expand_less</span>
                                </> :
                                    <> <span className="material-icons-outlined">expand_more</span></>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {!open && <>
                    <div className="bg-accordion-border white accordion-body">
                        <div className="d-flex flex-column">
                            <div>
                                <label className="H4-Subtitle mb-2">{t('questionLabel')}</label>
                                <TextareaUnderlineElement placeholder={t('questionPlaceholder')} value={formQuestion?.question?.questionStatement} onChange={(value: string) => changeInputQuestion(value)} id={'questionText'+index} />
                            </div>
                            {formQuestion?.question?.questionChoice?.map((answer: any, j: number) => {
                                return (<div className="flex-row d-flex flex-wrap mt-5 pt-2 ms-5">
                                    <div className="w-70">
                                    <label className="H4-Subtitle mb-2">{t('answerQuestion')} {(j + 1)}</label>
                                        <TextareaUnderlineElement placeholder={t('responsePlceholder')} underline={true} value={answer.choice} onChange={(value: string) => changeInputResponse(value, j)} id={'Response' + index + '' + j} />
                                    </div>
                                    <div className="d-flex col justify-content-end mt-4">
                                        <div onClick={(e) => { changeValueAnswer(j, !answer.choiceState, e) }} style={{margin:"0px"}}>
                                        <button disabled={(answer.choice.length > 0 ? false :true )}  type="button" className="btn-Secondary large icon-left ">
                                            <span className={`material-icons-outlined ${(answer.choiceState)?"icon-state success": ""}`}>
                                                check
                                            </span>
                                            <div className="m-auto ps-2">{t('choiceLabelBON')}</div>
                                        </button>
                                        </div>
                                        <button onClick={(e) => { deleteAnswer(j, e) }} type="button" className="btn-QuickActions" style={{marginLeft:"12px"}}>
                                            <span className="material-icons-outlined">delete</span>
                                        </button>
                                    </div>
                                </div>
                                )
                            })
                            }
                            <div className="flex-row d-flex flex-wrap mt-5 pt-2 ms-5">
                                <button type="button" onClick={(e) => { addAnswer(e) }} className="btn-Secondary large icon-left me-2" >
                                    <span className="material-icons-outlined">
                                        add
                                    </span>
                                    <div className="m-auto ps-2">{t('addAnswerButton')}</div>
                                </button>
                                <button disabled={(displayEx === true)} onClick={(e) => { explication(e) }} type="button" className="btn-Secondary large icon-left mx-3">
                                    <span className="material-icons-outlined">
                                        add
                                    </span>
                                    <div className="m-auto ps-2">{t('AJOUTER_EXPLICATION')}</div>
                                </button>
                            </div>
                            {displayEx === true && <div className="flex-row d-flex flex-wrap pt-2 mt-5 ms-5">
                                <div className="w-70">
                                    <label className="H4-Subtitle mb-2">{t('Explication')}</label>
                                    <TextareaUnderlineElement value={formQuestion?.question?.questionAnswerExplanation} onChange={(value: string) =>changeExplanation(value) } placeholder={t('explicationPlaceholder')} underline={true} id={'explicationText'+index} />
                                </div>
                                <div className="d-flex col justify-content-end mt-4">
                                    <button onClick={() => {changeExplanation(''); setDisplayEx(false)}} type="button" className="btn-QuickActions">
                                        <span className="material-icons-outlined">delete</span>
                                    </button>
                                </div>
                            </div>}
                            

                            <div className="flex-row d-flex flex-wrap justify-content-end mt-4 ms-5">
                                <button onClick={() => cancelClick()} type="button" className="btn-Secondary large me-2">
                                {t('annuler')}
                                </button>
                                <button onClick={() => {saveQuestion();openAccordion()}}  disabled={buttonDisabled} type="button" className="btn-Primary large ms-1">
                                {t('saveChapter')}
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </>
    );
}

