import { ComponentProps, FC, useEffect} from "react"
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { getNumberOfCourses } from "../../services/student.service";



const  LoadingRoleComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const history = useHistory();

    const getNbr = async()=>{
        let filter = {
            studentId: props?.connectedUser?.idPerson,
            entityId: props?.connectedUser?.idSchool,
            entityType: "SCHOOL",
            
        }
       let data = await getNumberOfCourses(filter);
        if(data==0){
                    
            history.push({ pathname: `${returnStartPathUrl()}/catalog`, state: undefined })
        }else{
            history.push({ pathname: `${returnStartPathUrl()}/courses/my-courses`, state: undefined })
        }
    }
    useEffect(() => {
          if (props?.connectedUser && props?.returnPathPlateform?.path !== '') {
            history.push({ pathname: props?.returnPathPlateform?.path, state: undefined });
            props?.clearSaveReturnPath();
        } else if (props?.connectedUser && props?.location?.state && props?.location?.state?.from && props?.location?.state?.from?.pathname && props?.location?.state?.from?.pathname !== "" && props?.location?.state?.from?.pathname !== "/professors/all" && props?.location?.state?.from?.pathname !== "/courses/my-courses" && props?.location?.state?.from?.pathname !== null) {
            history.push({ pathname: props?.location?.state?.from?.pathname, state: undefined })
        } else if (props?.connectedUser && localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== '') {

            
            switch (props?.connectedUser?.role) {
                case "role_director": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                case "role_adminstrator": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                case "role_prof": history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined }); break;
                case "role_student": getNbr(); break;
            }
        }
    }, [props?.connectedUser]);
  return (
    <div className="page-wrapper-height mx-auto" style={{display:"flex", alignItems:"center" , justifyContent:"center", flexDirection:"column", margin:"auto"}}>
    <div  style={{display:"flex", alignItems:"center" , justifyContent:"center", position:"relative" }}>
        <div className="bt-spinner " style={{width:"80px" , height:"80px" }}> 
        </div>
   
    </div>


    </div>
  )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    error: state.messageReducer.error,
    logoschool: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    location: ownProps?.location,
    connectedUser: state.authReducer.connectedUser,
    returnPathPlateform: state.historyReducer.returnPathPlateform
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ 
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoadingRoleComponent);