import { ComponentProps, FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { openModal,closeModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';

const AnnuleInvitationModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();

    const handleReturnToImport = () =>{
        props.setModalCancelOpened(false)
        props.setModalListInviteOpened(false)
        props.setModalOpened(true)
    }
    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="body-modal-publish">
                    <div className="mx-5 px-5 center" style={{ margin: 'auto' }}>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-row align-items-center mb-4'>
                                <span className="H3-Headline align-items-center mt-2">{t('annulerImportation')} </span>
                            </div>
                        </div>
                        <div >
                            <span className="body-sm">{t('annulerImportationConfirmation')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingTop: 0, border: "none" }}>
                <div className=" d-flex mt-3 mb-5 mx-5 px-5">
                    <div className='col-md-12 text-center justify-content-center buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                        <button type="button" className="btn-Secondary large me-1" onClick={() => handleReturnToImport()} style={{ textTransform: 'uppercase', marginRight: '2%' }} >{t('annuler')}</button>
                        </div>
                        <div style={{paddingLeft:"12px"}}>
                        <button type="button" className="btn-Primary large danger "  onClick={() => { props?.setModalCancelOpened(false) }}>{t('retourImportation')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    student: state.detailsProfileReducer.actualStudent,
    connectionUser: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { closeModal,openModal },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(AnnuleInvitationModalComponent);