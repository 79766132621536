import { ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';


const ResponseClosedQuestion: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {



    const calculateTotalResponses = () => {
        let total = props?.response?.studentAnswerList?.length;
        return total;
      };

      const calculatePercentagePerResponse = (data: any) => {
        if (data === 0 || calculateTotalResponses() === 0) {
            return 0; 
          } else {
            const percentage = (data / calculateTotalResponses()) * 100;
            return Number.isInteger(percentage) ? percentage : percentage.toFixed(2);        }
      };
    return (

        <div style={{ marginBottom: "20px" }}>
            <div className="d-flex flex-column" style={{ width: "724px", border: "1px solid #EBECEE", padding: "20px" }}>
                <span className="H3-Headline neutral-3" style={{ lineHeight: '43px' }}>Question
                    <span style={{ color: "black" }}> {props?.index}*</span>
                </span>
                <span className="H4-Subtitle ">
                    {props?.response?.questionStatement}
                </span>
            </div>
            <div className="d-flex flex-column" style={{ width: "724px", border: "1px solid #EBECEE", padding: "20px" }}>

                <span className="body-md " style={{ marginBottom: "22px" }}>
                    Question(s) ignorée(s): {props?.totalResponse - props?.response?.studentAnswerList?.length}
                </span>
                <div style={{ marginLeft: "18px" }}>
                    <div className='d-flex flex-row justify-content-between' style={{ width: "530px", backgroundColor: "#FBFBFD", padding: "5px 10px 5px 10px" }}>
                        <span className='body-md bold neutral-3'>Choix de réponses</span>
                        <span className='body-md bold neutral-3'>Réponses</span>
                    </div>
                    {props?.response?.staticAnswerDtoList?.reverse().map((response: any, index: any) => (

                        <div className='d-flex flex-row justify-content-between align-items-center' style={{ width: "530px", padding: "15px 10px 15px 10px", borderBottom: "1px solid #EBECEE" }}>
                            <span className='body-md ' style={{ maxWidth: "250px" }}>{response.choice}</span>
                            <span className='body-md bold'>{calculatePercentagePerResponse(response.numberResponse)}%
                                <span className='body-md bold neutral-3'> &nbsp;{response.numberResponse}</span>
                            </span>
                        </div>
                    ))}

                    



                    <div className='d-flex flex-row justify-content-between align-items-center' style={{
                        width: "530px", height: "34px",
                        borderRadius: "0px 0px 4px 4px",
                        backgroundColor: "#272727", padding: "5px 10px 5px 10px"
                    }}>
                        <span className='body-md bold' style={{ color: "#FFFFFF" }} >Total des réponses</span>
                        <span className='body-md bold' style={{ color: "#FFFFFF" }} >{calculateTotalResponses()}</span>
                    </div>
                </div>
            </div>
        </div>


    );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseClosedQuestion);
