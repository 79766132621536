import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";

import { getUserProfileDetails } from '../../global-state/actions/auth-actions';

import { getActualTab } from '../../global-state/actions/auth-actions';
import PaymentComponent from '../../components/subscription/payment-component';

const PaymentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    return (
       
        <PaymentComponent/>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loadingProfile: state.authReducer.loadingProfile,
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    actualTab:state.authReducer.actualTab
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,getActualTab
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);