import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from '../../helpers/interceptors';
import { IReduxIProfState } from '../reducers/professor-reducer'
import globalAxios from 'axios';
import axiosInstance from '../../helpers/interceptors';


export enum EReduxActionTypes {
  GET_PROF = 'GET_PROF',
  GET_STUDENTS_BY_PROF = 'GET_STUDENTS_BY_PROF',
  LOADING_GET_STUDENTS_BY_PROF = 'LOADING_GET_STUDENTS_BY_PROF',
  GET_STUDENTS_BY_COURSE = 'GET_STUDENTS_BY_COURSE',
  GET_LAST_TAB = 'GET_LAST_TAB',
  CLEAR_ALL = 'CLEAR_ALL',
  LOADING_PROFESSORS_LIST = "LOADING_PROFESSORS_LIST",
  GET_ASSISTANT = 'GET_ASSISTANT',
  LOADING_ASSISTANT_LIST = 'LOADING_ASSISTANT_LIST',
  GET_TEAM_NUMBER = 'GET_TEAM_NUMBER ',
  CLEAR_TEAM_PAGE = 'CLEAR_TEAM_PAGE ',
  GET_TEAM = 'GET_TEAM',
  GET_ALL_TEAM = 'GET_ALL_TEAM',
  TEAM_SEARCH = 'TEAM_SEARCH',
  REINSTATE_TEAM_MEMBER = 'REINSTATE_TEAM_MEMBER',
  CLEAR_TEAM_LIST = 'CLEAR_TEAM_LIST',
  SUSPEND_TEAM_MEMBER = 'SUSPEND_TEAM_MEMBER',
  RESEND_INVITATION = 'RESEND_INVITATION',
  DELETE_INVITATION = 'DELETE_INVITATION',
  GET_COUNT_FORMATION_STUDENTS = 'GET_COUNT_FORMATION_STUDENTS',
  LOADING_EXPORT_BUTTON = 'LOADING_EXPORT_BUTTON',
  EXPORT_LIST = 'EXPORT_LIST'
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}

export interface IReduxTypeSuspendAssistantAction extends IReduxBaseAction {
  type: EReduxActionTypes.SUSPEND_TEAM_MEMBER;
  data: any
}

export interface IReduxReinstateTeamMemberAction extends IReduxBaseAction {
  type: EReduxActionTypes.REINSTATE_TEAM_MEMBER;
  data: any;
}
export interface IReduxGetTeamSearchAction extends IReduxBaseAction {
  type: EReduxActionTypes.TEAM_SEARCH;
  data: any;
}

export interface IReduxGetAllTeamAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_ALL_TEAM;
  data: any;
}

export interface IReduxGetTeamNumberAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TEAM_NUMBER;
  data: any;
}

export interface IReduxLoadingProfessorsListAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_PROFESSORS_LIST;
}

export interface IReduxGetAssistantAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_ASSISTANT | EReduxActionTypes.LOADING_ASSISTANT_LIST;
  data: any;
}

export interface IReduxGetProfAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_PROF | EReduxActionTypes.LOADING_PROFESSORS_LIST;
  data: any;
}

export interface IReduxGetStudentsOfProfAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENTS_BY_PROF | EReduxActionTypes.LOADING_GET_STUDENTS_BY_PROF | EReduxActionTypes.GET_COUNT_FORMATION_STUDENTS;
  data: any;
}
export interface IReduxGetStudentsOfProfLoadingAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_GET_STUDENTS_BY_PROF;
}

export interface IReduxGetStudentsOfCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENTS_BY_COURSE | EReduxActionTypes.LOADING_GET_STUDENTS_BY_PROF;
  data: any;
}
export interface IReduxGetLastTabAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_LAST_TAB;
  data: any;
}

export interface IReduxClearAllAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_ALL
}

export interface IReduxClearNumberPagesTeamAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_TEAM_PAGE
}
export interface IReduxClearTeamListTeamAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_TEAM_LIST
}

export interface IReduxGetTeamAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TEAM | EReduxActionTypes.LOADING_ASSISTANT_LIST | EReduxActionTypes.LOADING_PROFESSORS_LIST;
  data: any;
}

export interface IReduxTypeResendInvitationAction extends IReduxBaseAction {
  type: EReduxActionTypes.RESEND_INVITATION;

}

export interface IReduxTypeDeleteInvitationAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_INVITATION;
  data: any
}


export interface IReduxTypeExportListAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_EXPORT_BUTTON | EReduxActionTypes.EXPORT_LIST;
  data: any
}


export function resend(id: any): ThunkAction<Promise<IReduxTypeResendInvitationAction>, IReduxIProfState, undefined, IReduxTypeResendInvitationAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxTypeResendInvitationAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;
    await axiosInstance.post(baseURL + "invitation/resend/" + id).then((response) => { return response.data }).catch(err => { return null; })
    return dispatch({
      type: EReduxActionTypes.RESEND_INVITATION,

    })
  }
}
export function deleteInvitation(idTeamMemeber: number, type: string, schoolId: number): ThunkAction<Promise<IReduxTypeDeleteInvitationAction>, IReduxIProfState, undefined, IReduxTypeDeleteInvitationAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxTypeDeleteInvitationAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;
    await axiosInstance.post(baseURL + "invitation/delete/" + idTeamMemeber + "/" + schoolId).then((response) => { return response.data }).catch(err => { return null; })
    return dispatch({
      type: EReduxActionTypes.DELETE_INVITATION,
      data: { id: idTeamMemeber, type: type }
    })
  }
}

export function getTeam(idSchool: any, type: string, page: number, refresh: string): ThunkAction<Promise<IReduxGetTeamAction>, IReduxIProfState, undefined, IReduxGetTeamAction> {
  let numberElementsToStart = page * 12;
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetTeamAction>) => {
    if (refresh !== 'refresh' && type === "ADMINSTRATOR") {
      dispatch({ type: EReduxActionTypes.LOADING_ASSISTANT_LIST, data: undefined });
    }
    if (refresh !== 'refresh' && type === "PROF") {
      dispatch({ type: EReduxActionTypes.LOADING_PROFESSORS_LIST, data: undefined });
    }
    if (refresh !== 'refresh' && type === "ALL") {
      dispatch({ type: EReduxActionTypes.LOADING_PROFESSORS_LIST, data: undefined });
    }
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const res = await axios.post(baseURL + "person/getpersonnelofschool/" + idSchool + "/" + type + "/" + numberElementsToStart).then((response) => {
      return response.data
    })

    return dispatch({
      type: EReduxActionTypes.GET_TEAM,
      data: { liste: res, refresh: refresh, type: type, page: page }
    })
  }
}

export function clearTeam(): ThunkAction<Promise<IReduxClearNumberPagesTeamAction>, IReduxIProfState, undefined, IReduxClearNumberPagesTeamAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxClearNumberPagesTeamAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_TEAM_PAGE,
    });
  };
}

export function clearTeamList(): ThunkAction<Promise<IReduxClearTeamListTeamAction>, IReduxIProfState, undefined, IReduxClearTeamListTeamAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxClearTeamListTeamAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_TEAM_LIST,
    });
  };
}
export function getTeamNumber(idSchool: any): ThunkAction<Promise<IReduxGetTeamNumberAction>, IReduxIProfState, undefined, IReduxGetTeamNumberAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetTeamNumberAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const team = await axiosInstance.post(baseURL + "person/getpersonnelofschoolNumber/" + idSchool).then((response) => { return response.data }).catch(err => { return null; })
    return dispatch({
      type: EReduxActionTypes.GET_TEAM_NUMBER,
      data: team
    })
  }
}

export function setActualTab(tab: string): ThunkAction<Promise<IReduxGetLastTabAction>, IReduxIProfState, undefined, IReduxGetLastTabAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetLastTabAction>) => {

    return dispatch({
      type: EReduxActionTypes.GET_LAST_TAB,
      data: tab
    })
  }
}
export function getProfessor(idSchool: any, refresh: string): ThunkAction<Promise<IReduxGetProfAction>, IReduxIProfState, undefined, IReduxGetProfAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetProfAction>) => {
    if (refresh !== 'refresh') {
      dispatch({ type: EReduxActionTypes.LOADING_PROFESSORS_LIST, data: undefined });
    }
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const res = await axios.get(baseURL + "person/all/naturalperson/" + idSchool).then((response) => {
      return response.data
    })

    return dispatch({
      type: EReduxActionTypes.GET_PROF,
      data: { liste: res, refresh: refresh }
    })
  }
}
export function getAssistant(idSchool: any, refresh: string): ThunkAction<Promise<IReduxGetAssistantAction>, IReduxIProfState, undefined, IReduxGetAssistantAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetAssistantAction>) => {
    if (refresh !== 'refresh') {
      dispatch({ type: EReduxActionTypes.LOADING_ASSISTANT_LIST, data: undefined });
    }
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const res = await axios.get(baseURL + "person/all/schoolAssistants/" + idSchool).then((response) => {
      return response.data
    })

    return dispatch({
      type: EReduxActionTypes.GET_ASSISTANT,
      data: { liste: res, refresh: refresh }
    })
  }
}
export function clearExams(): ThunkAction<Promise<IReduxClearAllAction>, IReduxIProfState, undefined, IReduxClearAllAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxClearAllAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_ALL,
    });
  };
}
export function searchProf(idSchool: any, search: any, type: any, page: any): ThunkAction<Promise<IReduxGetProfAction>, IReduxIProfState, undefined, IReduxGetProfAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetProfAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const res = await axios.post(baseURL + "person/searchpersonnelofschool/" + idSchool + "/" + search + "/" + type + "/" + page).then((response) => { return response.data }).catch(err => { return null; })

    return dispatch({
      type: EReduxActionTypes.GET_PROF,
      data: res
    })
  }
}
export function searchAssistant(idSchool: any, search: any, type: any, page: any): ThunkAction<Promise<IReduxGetAssistantAction>, IReduxIProfState, undefined, IReduxGetAssistantAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetAssistantAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;

    const res = await axios.post(baseURL + "person/searchpersonnelofschool/" + idSchool + "/" + search + "/" + type + "/" + page).then((response) => { return response.data }).catch(err => { return null; })

    return dispatch({
      type: EReduxActionTypes.GET_ASSISTANT,
      data: res
    })
  }
}
export function searchTeam(idSchool: any, search: any, type: any, page: any): ThunkAction<Promise<IReduxGetTeamSearchAction>, IReduxIProfState, undefined, IReduxGetTeamSearchAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetTeamSearchAction>) => {

    let baseURL = process.env.REACT_APP_BASE_URL2;
    const res = await axios.post(baseURL + "person/searchpersonnelofschool/" + idSchool + "/" + search + "/" + type + "/" + page).then((response) => { return response.data }).catch(err => { return null; })

    return dispatch({
      type: EReduxActionTypes.TEAM_SEARCH,
      data: res
    })
  }
}

export function getStudentsOfProf(role: any, filter: any, search: any, size: any, refresh: string): ThunkAction<Promise<IReduxGetStudentsOfProfAction>, IReduxIProfState, undefined, IReduxGetStudentsOfProfAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetStudentsOfProfAction>) => {
    if (refresh !== 'refresh') {
      dispatch({ type: EReduxActionTypes.LOADING_GET_STUDENTS_BY_PROF, data: undefined })
    }

    let payload = {
      "entityId": filter.entityId,
      "entityType": filter.entityType,
      "role": role,
      "idprof": role == 'role_prof' ? filter.idProf : null
    }


    const listeStudents: any = await axios.post(`${process.env.REACT_APP_BASE_URL}training/getTrainingsBreveDetails`, payload)
    .then(async (resCourses: any) => {
      return resCourses?.data.map((course: any) => { 
        return { id: course.id, title: course.title, type: course.type, idRegistration: course.idRegistrationForm } });
    })
    .catch(error => { return [] });

    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByGeneral/${filter.entityId}/${size.sizeGeneral}`, { coursesId: (role == "role_prof") ? listeStudents : [], search: search.generalFilter, idProf: filter.idProf })
      .then((generalData) => {
        dispatch({ type: EReduxActionTypes.GET_STUDENTS_BY_PROF, data: { key: 'generalInfo', generalInfo: generalData.data, keyLoad: 'loadStudentsGeneral' } })
        return generalData.data
      })
      .catch(err => { return { list: [], length: 0 } });

    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsBySuspended/${filter.entityId}/${size.sizeSuspended}`, { coursesId: (role == "role_prof") ? listeStudents : [], search: search.suspendedFilter, idProf: filter.idProf })
      .then((suspendedData) => {
        dispatch({ type: EReduxActionTypes.GET_STUDENTS_BY_PROF, data: { key: 'suspendedInfo', suspendedInfo: suspendedData.data, keyLoad: 'loadStudentsSuspended' } })
        return suspendedData.data
      })
      .catch(err => { return { list: [], length: 0 } });


    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsCount/${filter.entityId}/20`, { coursesId: listeStudents, search: "" }).then(res => {
      dispatch({
        type: EReduxActionTypes.GET_COUNT_FORMATION_STUDENTS,
        data: res.data
      })
      return res.data
    })

    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByCandidate/${filter.entityId}/${size.sizeCandidates}`, { coursesId: listeStudents, search: search.candidatesFilter, idProf: filter.idProf })
      .then((candidateData) => {
        dispatch({ type: EReduxActionTypes.GET_STUDENTS_BY_PROF, data: { key: 'candidatesInfo', candidatesInfo: candidateData.data, keyLoad: 'loadStudentsCandidate' } })
        return candidateData.data
      })
      .catch(err => { return { list: [], length: 0 } });
    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByCertified/${filter.entityId}/${size.sizeCertified}`, { coursesId: listeStudents, search: search.certifiedFilter, idProf: filter.idProf })
      .then((certifiedData) => {
        dispatch({ type: EReduxActionTypes.GET_STUDENTS_BY_PROF, data: { key: 'certifiedInfo', certifiedInfo: certifiedData.data, keyLoad: 'loadStudentsCertified' } })
        return certifiedData.data
      })
      .catch(err => { return { list: [], length: 0 } });
    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsUnpaid/${filter.entityId}/${size.sizeUnpaid}`, { coursesId: listeStudents, search: search.unpaidFilter, idProf: filter.idProf })
      .then((unpaidData) => {
        dispatch({ type: EReduxActionTypes.GET_STUDENTS_BY_PROF, data: { key: 'unpaidInfo', unpaidInfo: unpaidData.data, keyLoad: 'loadStudentsUnpaid' } })
        return unpaidData.data
      })
      .catch(err => { return { list: [], length: 0 } });

    axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByInscribed/${filter.entityId}/${size.sizeInscribed}`, { coursesId: listeStudents, search: search.inscribedFilter, idProf: filter.idProf })
      .then((inscribedData) => {
        dispatch({ type: EReduxActionTypes.GET_STUDENTS_BY_PROF, data: { key: 'inscribedInfo', inscribedInfo: inscribedData.data, keyLoad: 'loadStudentsInscribed' } })
        return inscribedData.data
      })
      .catch(err => { return { list: [], length: 0 } });
    return dispatch({
      type: EReduxActionTypes.GET_STUDENTS_BY_PROF,
      data: null
    })
  }
}
export function getStudentsOfCourse(course: any, filter: any, search: any, size: any, type: string): ThunkAction<Promise<IReduxGetStudentsOfCourseAction>, IReduxIProfState, undefined, IReduxGetStudentsOfCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxGetStudentsOfCourseAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_GET_STUDENTS_BY_PROF, data: undefined })
    const object = { id: course.id, title: course.title, idRegistration: course.idRegistrationForm, type: type };
    const listeFollows: any = await axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByGeneral/${filter.entityId}/${size.sizeGeneral}`, { coursesId: [object], search: search.generalFilter, idProf: filter.idProf })
      .catch(err => { return { data: { list: [], length: 0 } } });
    const listeSuspended: any = await axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsBySuspended/${filter.entityId}/${size.sizeSuspended}`, { coursesId: [object], search: search.suspendedFilter, idProf: filter.idProf })
      .catch(err => { return { data: { list: [], length: 0 } } });
    const listeCandidates: any = await axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByCandidate/${filter.entityId}/${size.sizeCandidates}`, { coursesId: [object], search: search.candidatesFilter, idProf: filter.idProf })
      .catch(err => { return { data: { list: [], length: 0 } } });
    const listeCertified: any = await axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByCertified/${filter.entityId}/${size.sizeCertified}`, { coursesId: [object], search: search.certifiedFilter, idProf: filter.idProf })
      .catch(err => { return { data: { list: [], length: 0 } } });
    const listeInscribed: any = await axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsByInscribed/${filter.entityId}/${size.sizeInscribed}/0`, { coursesId: [object], search: search.inscribedFilter, idProf: filter.idProf })
      .catch(err => { return { data: { list: [], length: 0 } } });
    const listeUnpaid: any = await axios.post(`${process.env.REACT_APP_BASE_URL14}followUp/allFollowsUnpaid/${filter.entityId}/${size.sizeInscribed}`, { coursesId: [object], search: search.inscribedFilter, idProf: filter.idProf })
      .catch(err => { return { data: { list: [], length: 0 } } });
    const listeStudents = await globalAxios.all([listeFollows, listeCandidates, listeCertified, listeInscribed, listeSuspended, listeUnpaid]).then(globalAxios.spread((...responses) => {
      const general = responses[0].data;
      const candidate = responses[1].data;
      const certified = responses[2].data;
      const inscribed = responses[3].data;
      const suspended = responses[4].data;
      const unpaid = responses[5].data;
      return { generalInfo: general, certifiedInfo: certified, candidatesInfo: candidate, inscribedInfo: inscribed, suspendedInfo: suspended, unpaidInfo: unpaid }
    }));
    return dispatch({
      type: EReduxActionTypes.GET_STUDENTS_BY_COURSE,
      data: listeStudents
    })
  }
}


export function reinstateTeamMember(id: any, idDirector: any, object: any, type: any): ThunkAction<Promise<IReduxReinstateTeamMemberAction>, IReduxIProfState, undefined, IReduxReinstateTeamMemberAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxReinstateTeamMemberAction>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    let response: any;

    if (type === "PROF") {
      response = await axios.post(baseUrl + "courses/reinstateProf/" + id + "/" + object.idSchool, null).then(async (res: any) => {
        object['messageReinstateProf'] = res.data;
        await axios.post(baseUrl2 + 'person/reinstateProf/' + id + '/' + idDirector, object)
        return res.data;
      })
    } else if (type === "ADMINSTRATOR") {
      response = await axios.post(baseUrl2 + 'person/reinstateProf/' + id + '/' + idDirector, object)
    }

    return dispatch({
      type: EReduxActionTypes.REINSTATE_TEAM_MEMBER,
      data: { response, type: type, id: id }
    });
  };
}

export function suspendTeamMember(idTeamMemeber: any, idOtherTeamMember: any, idDirector: any, object: any, type: any): ThunkAction<Promise<IReduxTypeSuspendAssistantAction>, IReduxIProfState, undefined, IReduxTypeSuspendAssistantAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxTypeSuspendAssistantAction>) => {
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    let teamMember
    if (type === "ADMINSTRATOR") {
      teamMember = await axios.post(baseUrl2 + 'person/suspendAssistant/' + idTeamMemeber + '/' + idDirector, object).then(res => { return res.data }).catch(err => console.error(err));
    } else {
      teamMember = await axios.post(baseUrl2 + 'person/suspendProf/' + idTeamMemeber + '/' + idOtherTeamMember + '/' + idDirector, object).then(res => { return res.data }).catch(error => console.error(error));
    }
    return dispatch({
      type: EReduxActionTypes.SUSPEND_TEAM_MEMBER,
      data: { teamMember: teamMember, type: type, id: idTeamMemeber }
    });
  }
}

export function exportList(filterCourse: any, actualTab: string, idSchool: number, name: string, lang: any): ThunkAction<Promise<IReduxTypeExportListAction>, IReduxIProfState, undefined, IReduxTypeExportListAction> {
  return async (dispatch: ThunkDispatch<IReduxIProfState, undefined, IReduxTypeExportListAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_EXPORT_BUTTON, data: true })

    let status: boolean = await axios({
      url: `${process.env.REACT_APP_BASE_URL14}followUp/export/${actualTab}/${idSchool}/${name}/${lang}`,
      method: "POST",
      responseType: "blob",
      data: filterCourse,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".csv");
      document.body.appendChild(link);
      link.click();
      return false
    });

    return dispatch({
      type: EReduxActionTypes.EXPORT_LIST,
      data: status
    })
  }
}