import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearHistory } from "../../global-state/actions/breadcrumb-actions";

const HeaderPreviewExamDirector: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [title, setTitle] = useState(props?.title)

    useEffect(() => {
        setTitle(props?.title);
    }, [props?.title])


    return (
        <div style={{ paddingTop: "50px", paddingLeft: "120px", paddingBottom: "40px", backgroundColor: '#FFFFFF' }}  >
            <span className="H2-Headline" style={{ color: "#272727" }}> {title} </ span>
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ clearHistory }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,

});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderPreviewExamDirector);
