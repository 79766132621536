import { formatTrainingSection } from "../../services/training.service";
import { EReduxActionTypes, IReduxSetProgressAction,IReduxGetSidebarSectionContentWithoutApiAction, IReduxGetInvitationsListAction,IReduxsetExamResultAction, IReduxCalculateTrainingAction, IReduxClearCurrentCourseAction, IReduxUpdateIndexPathSessionPassAction, IReduxUpdateCurrentProgressExamAction, IReduxCreateProgressExamAction, IReduxStudentcheckProgressCourseAction, IReduxGetCurrentTrainingAction, IReduxGetCurrentCourseAction, IReduxGetSidebarSectionContentAction, IReduxGetTrainingAction, IReduxGetTrainingSectionContentAction, IReduxGetInitilizeIndexAction, IReduxGetUpdateIndexAction } from "../actions/student-training-actions";
export interface IContentSectionDetails {
    sectionContent: any,
    index : number,
    contentType: string  
}   

export interface IReduxStudentTrainingeState {

    studentTraining : any,
    currentCourse :any,
    listFollowUps: any[],
    sectionContentDetails : IContentSectionDetails ,
    isCompleted: string
    currentTraining: any
    currentTrainingProgress: any
    currentProgress: any
    examResult: any,
    first:boolean,
    invitationList:any
    sections:any
}
const  initialState: IReduxStudentTrainingeState = {

    studentTraining : undefined,
    currentCourse :undefined,
    listFollowUps: [],
    currentTrainingProgress: undefined,
    sectionContentDetails : {
    sectionContent: undefined,
    index : 0,
    contentType: ""  
    },
    isCompleted: "",
    currentTraining: undefined,
    currentProgress: undefined,
    examResult: undefined,
    first:false,
    invitationList:undefined,
    sections:[]
};


type TStudentTrainingActions = IReduxCreateProgressExamAction | IReduxGetInvitationsListAction|IReduxGetTrainingSectionContentAction| IReduxsetExamResultAction | IReduxClearCurrentCourseAction | IReduxUpdateIndexPathSessionPassAction | IReduxCalculateTrainingAction | IReduxUpdateCurrentProgressExamAction | IReduxGetCurrentTrainingAction | IReduxSetProgressAction | IReduxStudentcheckProgressCourseAction | IReduxGetTrainingAction | IReduxGetCurrentCourseAction | IReduxGetSidebarSectionContentAction 
|IReduxGetSidebarSectionContentWithoutApiAction | IReduxGetInitilizeIndexAction |IReduxGetUpdateIndexAction;

export default function (state: IReduxStudentTrainingeState = initialState, action: TStudentTrainingActions) {
    switch (action.type) {
        case EReduxActionTypes.INITIALIZE_INDEX:
            return { ...state, first:false};
            case EReduxActionTypes.GET_INVITATIONS_LIST:
            return { ...state, invitationList:action.data};
        case EReduxActionTypes.UPDATE_INDEX:
            return { ...state, first:true};
        case EReduxActionTypes.SET_EXAM_RESULT:
            return { ...state, examResult:action.data };
        case EReduxActionTypes.GET_STUDENT_TRAINING:
            return { ...state, studentTraining:action.data };
        case EReduxActionTypes.CLEAR_CURRENT_COURSE:
            return {...state, studentTraining : undefined, currentCourse :undefined, listFollowUps: [], currentTrainingProgress: undefined, sectionContentDetails : { sectionContent: undefined, index : 0, contentType: ""   }, isCompleted: "", currentTraining: undefined, currentProgress: undefined, examResult: undefined, first:false, invitationList:undefined, sections:[]}
        case EReduxActionTypes.GET_CURRENT_COURSE:
             return {...state , currentCourse:action?.data?.training[0], currentTrainingProgress: action?.data?.training[0]?.followUp?.progressTraining , sections:formatTrainingSection(action?.data?.training[0],action?.data?.training[0]?.followUp?.progressTraining )} ;
        case EReduxActionTypes.GET_CURRENT_TRAINING:
            return {...state , currentTraining:action?.data?.training[0], listFollowUps: action?.data?.followUpsProgress, currentProgress: action?.data?.training[0]?.followUp?.progressTraining } ;
        case EReduxActionTypes.GET_SIDEBAR_SECTION_CONTENT:
            const listFollowsCp = [...state.listFollowUps];
            if(action.data.progress) {
            const indexFollow = listFollowsCp.findIndex((obj: any) => obj.courseId === action.data.progress.entityId);
            
            if(indexFollow !== -1) {
                listFollowsCp[indexFollow].progressTraining = action.data.progress;
            }
        }
        return {...state , sectionContentDetails:action.data.section,listFollowUps: listFollowsCp} ;

        

        case EReduxActionTypes.GET_TRAINING_SECTION_CONTENT:
                const listFollowsCopy = [...state.listFollowUps];
                if(action.data.progress) {
                const indexFollow = listFollowsCopy.findIndex((obj: any) => obj.courseId === action.data.progress.entityId);
                
                if(indexFollow !== -1) {
                    listFollowsCopy[indexFollow].progressTraining = action.data.progress;
                }
            }
        return {...state , sectionContentDetails:action.data.section,listFollowUps: listFollowsCopy} ;
                
     
        case EReduxActionTypes.GET_SIDEBAR_SECTION_CONTENT_Without_Api:
            return {...state , sectionContentDetails:action.data.section} ;
        
        case EReduxActionTypes.CHECK_PROGRESS_COURSE:
            const copyCurrentCourse = {...state.currentCourse};
            if(copyCurrentCourse && copyCurrentCourse.followUp && action.data.follow && action.data.status === 'CERTIFIED') {
                copyCurrentCourse.followUp.certificationLink = action.data.follow.certificationLink;
                return {...state, isCompleted: action.data.status, currentCourse: copyCurrentCourse};
            } else {
                if(state.isCompleted === "PENDING") {
                    return {...state, isCompleted: "PENDING"};
                } else {
                    return {...state, isCompleted: action.data.status};
                }
            }
        case EReduxActionTypes.SET_STUDENT_PROGRESS:
            let courseToFormat = {...state.currentCourse};
                return {...state, currentTrainingProgress: action.data.newProgressTraining , sections:formatTrainingSection(courseToFormat,action.data.newProgressTraining )};
        case EReduxActionTypes.CREATE_EXAM_PROGRESS:
            const copyCurrCourse = {...state.currentCourse};
            const indexExam = copyCurrCourse.exams.findIndex((obj: any) => obj.id === action.data.idQna); 
            if(indexExam !== -1) {
                copyCurrCourse.exams[indexExam].progression = action.data;
                copyCurrCourse.exams[indexExam].progress = action.data;
            }
            return { ...state, currentCourse: copyCurrCourse };
        case EReduxActionTypes.UPDATE_PROGRESS_EXAM:
            const currentCOurseCopy = {...state.currentCourse};
            let copyProgress = {...state?.currentCourse?.followUp?.progressTraining};
            const listFollowUpsCopy = [...state.listFollowUps];
            if(copyProgress !== null) {
                copyProgress = action.data;
                const index = listFollowUpsCopy.findIndex((obj: any) => obj.id === currentCOurseCopy.followUp.id);
                listFollowUpsCopy[index].progressTraining = copyProgress;
            }
            return {...state, currentCourse: {...state.currentCourse, followUp: {...state.currentCourse.followUp, progressTraining: copyProgress}}, listFollowUps: listFollowUpsCopy};
        case EReduxActionTypes.CALCULATE_TRAINING:
            if( action.data === null ) {
                return {...state}
            } else {
                return {...state, currentTraining: action.data, currentProgress:action?.data?.followUp?.progressTraining };
            }
        case EReduxActionTypes.UPDATE_INDEX_PATH_SESSION:
            const duplicateCurrentTraining = {...state.currentTraining};
            if(action?.data) {
                duplicateCurrentTraining.followUp.progressTraining = action.data;
                return {...state, currentTraining: duplicateCurrentTraining, currentProgress: action.data}
            } else {
                return {...state}
            }
        default:
            return state;
        }
        
    }
  
  
