
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import PathCourseSidebarComponent from '../../../components/path-components/path-course-components/path-course-sidebar-component';
import PathCourseContentComponent from '../../../components/path-components/path-course-components/path-course-content-component';
import { useParams } from 'react-router';
import { getCurrentCourse, getSideBarSectionContent, setStudentProgress } from '../../../global-state/actions/student-training-actions';
import axiosInstance from '../../../helpers/interceptors';
import { getDetailsSubscriptions } from '../../../global-state/actions/course-actions';
import { updateLastStudentProgress } from '../../../services/training.service';
interface RouteParams {
    idCourse: any
}
const CourseContentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { idCourse } = useParams<RouteParams>();
    const [courseContent, setCourseContent] = useState<any>(undefined);
    const [sections, setsections] = useState<any>(undefined)
    const [followId, setFollowId] = useState<number>()
    const [sectionsIndex, setSectionsIndex] = useState<number>(0)
    const [currentSection, setCurrentSection] = useState<any>(undefined)
    const [courseProgress, setCourseProgress] = useState<any>(null);

    useEffect(() => {
        if (props?.currentTrainingProgress && props?.currentTrainingProgress !== null) {
            setCourseProgress(props?.currentTrainingProgress)
            if (sectionsIndex !== props?.currentTrainingProgress.lastProgression) {
                setSectionsIndex(props?.currentTrainingProgress.lastProgression)
            }
        } else {
            setCourseProgress(null)
        }
    }, [props?.currentTrainingProgress])

    useEffect(() => {
        if (courseContent !== props?.course) {
            setCourseContent(props?.course);
            getCourseOfferFn(props?.course?.id, props?.connectedUser?.idPerson)
            setFollowId(props?.course?.followUp?.id)
        }
    }, [props?.course])


    useEffect(() => {
        if (typeof Number(idCourse) === 'number') {
            props?.getCurrentCourse(Number(idCourse));
        }
    }, [idCourse])

    const getCourseOfferFn = async (courseId: number, userId: number) => {
        let object = {
            id: courseId,
            idPerson: userId
        }
        await props?.getDetailsSubscriptions(object)

    }

    useEffect(() => {
        if (courseContent !== undefined && courseContent?.diploma === null && courseContent?.followUp?.progressTraining?.progress === 100) {
            let verif: any = courseContent?.followUp?.statusList?.filter((status: any) => status?.status === "COMPLETED")
            if (verif?.length === 0) {
                let status = {
                    "id": null,
                    "status": "COMPLETED",
                    "follow": courseContent?.followUp
                }
                axiosInstance.post(`${process.env.REACT_APP_BASE_URL3}follow/status`, status)


            }
        }

    }, [courseContent])
    useEffect(() => {
        if (sections !== undefined) {
            !sections && getContent(sectionsIndex)
        }

    }, [sections])

    useEffect(() => {
        if (props?.sections !== sections) {
            setsections(props?.sections)
        }
    }, [props?.sections])

    const getContent = (index: number) => {
        setSectionsIndex(index);
        if (courseContent?.followUp?.id) {
            updateLastStudentProgress(index, courseContent?.followUp?.id)
        }
        if (currentSection !== sections[index]) {
            setCurrentSection(sections[index])
            if (sections[index]?.progression?.progress !== "COMPLETED" && sections[index]?.video?.length === 0) {
                chapterProgression(sections[index])
            }
        }
        props?.getSideBarSectionContent(sections[index], index, sections[index]?.type, followId);
    }

    const chapterProgression = async (progressionState: any) => {
        let prog = {
            id: (progressionState?.progress !== null) ? progressionState?.progress?.id : null,
            chapterId: progressionState?.id,
            follow: { id: courseContent?.followUp?.id },
            progress: "COMPLETED",
            duration: 100,
            progressTrainingId: (courseContent?.followUp?.progressTraining === null) ? null : courseContent?.followUp?.progressTraining?.id,
            entityId: courseContent?.id,
        }
        if ((progressionState?.progress === null || progressionState?.progress?.progress !== "COMPLETED") && courseContent !== null && courseContent !== undefined) {
            await props?.setStudentProgress(prog, courseContent, courseProgress, props?.listFollowUps);
        }
    }

    return (
        <div style={{
            margin: "auto", display: "flex", flexDirection: "row"
        }}>
            <div style={{ backgroundColor: "#FFFFFF", boxShadow: "1px 1px 0 #f5f5f6", zIndex: 2 }}>
                <PathCourseSidebarComponent courseSections={sections} getContent={getContent} />
            </div>
            <div className='style-progress-training' id="communityScroll" style={{ width: '100%', maxHeight: 'calc(100vh - 68px)' }}>
                < PathCourseContentComponent courseSections={sections} getTrainingContent={getContent} currentSection={currentSection} />
            </div>
        </div>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getCurrentCourse,
    getSideBarSectionContent,
    setStudentProgress,
    getDetailsSubscriptions,
}, dispatch);

const mapStateToProps = (state: AppState) => ({
    course: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    follow: state.studentTraining.listFollowUps,
    sections: state.studentTraining.sections,
    currentTrainingProgress: state.studentTraining.currentTrainingProgress,
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContentPage);

