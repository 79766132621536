import Skeleton from '@material-ui/lab/Skeleton';

function HeaderViewFinalExamSubmissionLoadingComponent() {
    return (

        <div style={{ marginTop: "20px", backgroundColor: "#FFFFFF" }}>
            <div style={{ maxWidth: "1200px", margin: "auto" }}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-wrap mb-3 pt-4" >
                        <div className=" me-3">
                            <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={48} height={48} />
                        </div>
                        <div>
                            <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={48} height={48} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-wrap mb-3 pt-4" style={{ float: 'right' }}>

                        <Skeleton className="skeleton mt-3" animation="wave" variant="rect" width={91} height={48} />

                    </div>
                </div>
                <div className="row" >
                    <Skeleton className="skeleton " animation="wave" variant="rect" width={348} height={24} />
                </div>
                <div className="row pb-4">
                    <Skeleton className="skeleton " animation="wave" variant="rect" width={348} height={24} />
                </div>
            </div >
        </div >
    )
}

export default HeaderViewFinalExamSubmissionLoadingComponent
