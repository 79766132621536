import axios from '../../helpers/interceptors';
import { v4 as uuidv4 } from 'uuid';
import * as tus from 'tus-js-client';
import qs from 'querystring';
import Axios from 'axios';




export enum EReduxActionTypes {
  POST_VIMEO_START = 'vimeo/POST_VIMEO_START',
  POST_VIMEO_SUCCESS = 'vimeo/POST_VIMEO_SUCCESS',
  POST_VIMEO_FAILED = 'vimeo/POST_VIMEO_FAILED',
  POST_VIMEO_PROGRESS = 'vimeo/POST_VIMEO_PROGRESS',
  POST_VIMEO_PROGRESS_TRANSCODE = 'vimeo/POST_VIMEO_PROGRESS_TRANSCODE',
  CLEAR_FORM = 'CLEAR_FORM',
  CHECK_VIDEO_AVALIBILITY = 'CHECK_VIDEO_AVALIBILITY',

}

export const clearFormVideo = (): any => {
  return async (dispatch: any): Promise<any> => {
    dispatch({
      type: EReduxActionTypes.CLEAR_FORM,
      payload: {
        uploading: false,
        transcoding: false,
        progress: 0,
        transcodingProgress: '',
        transcodeStatus: '',
        uploadStatus: '',
        videolink: '',
        idVideo: '',
        loading: true,
        readyToDisplay: '',
      }
    });
  };
}
export const clearLoadingVideo = (): any => {
  return async (dispatch: any): Promise<any> => {
    dispatch({
      type: EReduxActionTypes.CLEAR_FORM,
      payload: {
        loading: false,
      }
    });
  };
}
export const uploadVimeo = ({ createVideoLink, getVideoLink, domain, checkStopped, ...postBody }: any) => {
  return async (dispatch: any): Promise<any> => {
    const failedDownload = () => {
      dispatch({
        type: EReduxActionTypes.POST_VIMEO_FAILED,
        payload: {
          uploading: false,
          progress: 0,
          uploadStatus: 'failed'
        }
      });
      return null;
    };
    const patchVimeoFunc = async (videoUri: any, uploadLink: any, videoData: any, size: any, uploadOffset: any, uri: any): Promise<any> => {
      const upload: any = new tus.Upload(videoData, {
        endpoint: 'https://api.vimeo.com/me/videos',
        uploadUrl: uploadLink,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          name: videoData.name,
          filetype: videoData.type
        },
        headers: {},
        onError: function (error) {
          dispatch({
            type: EReduxActionTypes.POST_VIMEO_FAILED,
            payload: {
              uploading: false,
              progress: 0,
              transcoding: false,
              uploadStatus: 'failed'
            }
          });
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          dispatch({
            type: EReduxActionTypes.POST_VIMEO_PROGRESS,
            payload: {
              transcoding: false,
              uploading: true,
              progress: percentage,
              uploadStatus: 'progress'
            }
          });
        },
        onSuccess: function () {
          dispatch({
            type: EReduxActionTypes.POST_VIMEO_PROGRESS,
            payload: {
              uploading: false,
              transcoding: true,
              loading: false,
              uploadStatus: 'success',
              progress: 100,
              idVideo: videoUri,
            }
          });
        }
      });
      upload.start();
    };
    try {
      dispatch({
        type: EReduxActionTypes.POST_VIMEO_START,
        payload: {
          uploading: true
        }
      });
      const { videoData, size } = postBody;

      const createVideoAPIResult = await axios.post(`${process.env.REACT_APP_BASE_URL4}getLinkVideo/vimeo`,
        {
          size: size,
          name: videoData.name,
          domain: domain
        }
      );

      const createVideoResult = createVideoAPIResult.data;
      const uploadLink = createVideoResult.linkToUploadVideo;
      const videoUri = createVideoResult.videolink;
      const uri = createVideoResult.uri;
      dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS,
        payload: {
          idVideo: videoUri
        }
      })
      return patchVimeoFunc(videoUri, uploadLink, videoData, size, 0, uri);
    } catch (err) {
      return failedDownload();
    }
  };
};
export const checkTranscode = (uri: any) => {
  return async (dispatch: any): Promise<any> => {
    const id = uuidv4();
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL4}checkPourcentage`, { link: '/videos/' + uri.split('/')[4] }).then(response => {
      return response.data;
    })
    if (res.message === 'Your video is still transcoding.') {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE,
        payload: {
          transcoding: true,
          uploading: false,
          idVideo: uri,
          progress: 100,
          uploadStatus: '',
          id: id
        }
      });
    } else if (res.message == "Your video finished transcoding.")  {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE,
        payload: {
          transcoding: false,
          transcodeStatus: '',
          uploadStatus: '',
          progress: 100,
          idVideo: uri,
          readyToDisplay: 'false'
        }
      });
    }else if (res.message === "Your video encountered an error during transcoding.") {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE,
        payload: {
          transcoding: false,
          uploadStatus: '',
          progress: 100,
          idVideo: ''
        }
      });
    }
     
    }
  }

export const checkTranscodeOnLoad = (uri: any) => {
  return async (dispatch: any): Promise<any> => {
    const id = uuidv4();
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL4}checkPourcentage`, { link: '/videos/' + uri.split('/')[4] }).then(response => {
      return response.data;
    })
    if (res.message === 'Your video is still transcoding.') {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE,
        payload: {
          transcoding: true,
          uploading: false,
          idVideo: uri,
          progress: 100,
          uploadStatus: '',
          
        }
      });
    } else if (res.message == "Your video finished transcoding.")  {
      
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE,
        payload: {
          transcoding: false,
          transcodeStatus: '',
          uploadStatus: '',
          idVideo: uri,
          progress: 100,
          readyToDisplay: 'terminate'
        }
      });
    } 
    else if (res.message === "Your video encountered an error during transcoding.") {
      return dispatch({
        type: EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE,
        payload: {
          transcoding: false,
          uploadStatus: '',
          progress: 100,
          idVideo: '',
        }
      });
    }
  }
};

export const checkVideoAvalibility = (videoId: any, finish: string): any => {
  return async (dispatch: any): Promise<any> => {
    const id = uuidv4();
    Axios.request({
      url: "oembed.json?url=" + videoId + "&autoplay=false&muted=false&loop=false&playsinline=false&controls=true&autopause=false&byline=false&portrait=false&title=false&responsive=true",
      method: "get",
      baseURL: "https://vimeo.com/api/",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        "url": videoId,

      })
    }).then(() => {
      if (finish === 'terminate') {
        dispatch({
          type: EReduxActionTypes.CHECK_VIDEO_AVALIBILITY,
          payload: {
            status: 'terminate',
            idVideo: videoId,
            progress: 0
          }
        });
      } else {
        dispatch({
          type: EReduxActionTypes.CHECK_VIDEO_AVALIBILITY,
          payload: {
            status: 'true',
            idVideo: videoId
          }
        });
      }

    }).catch(() => {
      dispatch({
        type: EReduxActionTypes.CHECK_VIDEO_AVALIBILITY,
        payload: {
          status: 'false',
          uuidReadyToDisplay: id,
          idVideo: videoId
        }
      });
    })
  };
}