import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getCoursesForDirector,getCourse, getCourseTests, getRegistrationForm, getCourseChapters, getCourseFromEmail } from '../../global-state/actions/course-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { useHistory } from 'react-router';
import Axios from '../../helpers/interceptors';
import { getTrainingById } from '../../global-state/actions/training-actions';
import FilterAllCoursesAssisitantComponent from '../filters/filter-all-courses-assisitant-component';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import TableWithExpandElement from '../../elements/table/table-with-expand-element';
import DefaultTitle from '../../helpers/default-title'
import { returnStartPathUrl } from '../../helpers/domainCheck';


const TableAssistantAllCoursesComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const status = [
    { label: t('Avalider'), value: "PENDING", color: "warning" },
    { label: t('Enligne'), value: "PUBLISHED", color: "success" },
    { label: t('Archive'), value: "ARCHIVE", color: "secondary" },
    { label: t('Refuse'), value: "REFUSED", color: "danger" },
    { label: t('Deactivate'), value: "DEACTIVATE", color: "dark" },
    { label: t('TrainingToedit'), value: "DRAFT", color: "primary" }
  ]
  const [courses, setCourses] = useState<any>(props?.allCourses);
  const [filteredCourses, setFilteredCourses] = useState<any>([]);
  const headers = [
    { title: t('TitleFormations'), sorted: true, classNames: "w-32", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('Professeurs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t("creationDate"), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
    { title: t('statusFilter'), sorted: true, classNames: "w-16", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
  ]
  const [filterValue, setFilterValue] = useState<any>({status: "ALL", search: ''})
  const [tabLength, setTabLength] = useState(filteredCourses?.body?.length)
  useEffect(() => {
    setTabLength(filteredCourses?.body?.length)
  }, [filteredCourses])
  
  
  
  const history = useHistory();

  const doAction = async (value: any)=>{
    if(value.row.course.type === "COURSE"){
      await props?.getCourseChapters(value?.row?.course?.id, value?.row?.course);
      await props?.getCourseTests(value?.row?.course?.id);
      await props?.getCourseFromEmail(value?.row?.course?.id)
      if (value?.row?.course?.idRegistration!== null && value?.row?.course?.idRegistration !== 0) {
        await props.getRegistrationForm(value?.row?.course?.idRegistration);
    }
     history.push(`${returnStartPathUrl()}/courses/create-formation/course/description`);
    } else {
     
      await props?.getTrainingById(value?.row?.course?.type?.toLowerCase().concat("s"), value?.row?.course?.id)
      if (value?.row?.course?.idRegistration !== null && value?.row?.course?.idRegistration !== 0) {
        await props.getRegistrationForm(value.row.course.idRegistration);
    }
    
    if(value.row.course.type ==='PATH'){
       history.push(`${returnStartPathUrl()}/courses/create-formation/path/description`);
    }else {
       history.push(`${returnStartPathUrl()}/courses/create-formation/session/description`);
    }
    }
  }

  const doFilter = (filter: any) => {
  
    let filterList = { ...courses };

    filterList.body = filterList.body.filter((course: any) => {
      let actualStatus: any = status.find((e: any) => e?.value === filter.status)?.label || '';
  
      let statusFilter = course?.row.columns[3]?.content.includes(actualStatus);
      let searchFilter = course?.row.columns[0]?.content?.toString().toLowerCase().includes(filter?.search.toLowerCase());
      return ( statusFilter && searchFilter);
    });
    filter.status === '' && setFilterValue((prev: any) => ({...prev, search: filter.search}))
    filter.status.length > 0 && setFilterValue((prev: any) => ({...prev, status: filter.status, search: filter.search}))
    setFilteredCourses(filterList);
  }
  const getData = () => {
    const professors: any = []
    Axios.get(`${process.env.REACT_APP_BASE_URL2}person/all/${props?.user?.idSchool}/profs`).then(response => {
      response?.data?.forEach((prof: any) => {
        professors.push({ label: prof.name, value: prof.name, id: prof.id })
      });

      const data = {
        headers: headers,
        body: props?.allCourses?.map((course: any) => {
          
          const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === course?.status?.toLowerCase());
          return {
            row: {
              course: course,
              actionName: "",
              classNames: "table-white",
              columns: [
                { content: <DefaultTitle title={course?.title} type={course?.type}/>, classNames: "w-30", actionName: 'goToPath' },
                { content: course.personName, classNames: "w-25", actionName: 'goToPath' },
                { content: (course.type === 'COURSE')?t('Cours'): (course.type === 'PATH')?t('parcoursTitle'):t('sessionTitle'), classNames: "w-16", actionName: 'goToPath' },
                { content: course.creationDate.substring(0,10).split("-").reverse().join("/") ,classNames: "w-16", actionName: 'goToPath' },
                { content: t(actualStatus?.label), classNames: ` text-end badge-text ${actualStatus?.color}`, actionName: 'goToPath' },

              ],
  
            }
          }
        })
      }
      setTabLength(data?.body?.length)
      setCourses(data);
      setFilteredCourses(data);
    })
  }

  useEffect(() => {   
    getData();
  }, [props?.allCourses]);
  
  const listFilter: any = {
    ALL: (msg1: string, msg2: string, status: string) =>  <div className="w-100 text-center mt-5 " style={{paddingTop:"120px"}}>
          <EmptyListElement lines={[t(msg1),t(msg2) ]} />
           {status === 'default' && <div style={{alignItems:"center" , margin:"auto" , display:"flex", justifyContent:"center"}}>
            <button className="btn-Primary large icon-left" onClick={() => props?.createTraining()}>
              <span className="material-icons">add</span>
              <div className="m-auto ps-2">{t('createNewFormation')}</div>
            </button>
          </div> }
        </div>, 
    PENDING: (msg1: string, msg2: string, status: string) => <div className="w-100 text-center mt-5" style={{paddingTop:"120px"}}>
          {status === 'default' && <><EmptyListElement lines={[t(msg1)]} /></>}
          {status === 'search' && <><EmptyListElement lines={[t(msg1), t(msg2)]} /></>}
        </div>, 
    PUBLISHED: (msg1: string, msg2: string, status: string) => <div className="w-100 text-center mt-5" style={{paddingTop:"120px"}}>
         {status === 'default' && <><EmptyListElement lines={[t(msg1)]} /></>}
          {status === 'search' && <><EmptyListElement lines={[t(msg1), t(msg2)]} /></>}
        </div>, 
    ARCHIVE: (msg1: string, msg2: string, status: string) => <div className="w-100 text-center mt-5" style={{paddingTop:"120px"}}>
          {status === 'default' && <><EmptyListElement lines={[t(msg1)]} /></>}
          {status === 'search' && <><EmptyListElement lines={[t(msg1), t(msg2)]} /></>}
        </div>, 
    REFUSED: (msg1: string, msg2: string, status: string) => <div className="w-100 text-center mt-5" style={{paddingTop:"120px"}}>
          {status === 'default' && <><EmptyListElement lines={[t(msg1)]} /></>}
          {status === 'search' && <><EmptyListElement lines={[t(msg1), t(msg2)]} /></>}
        </div>, 
    DEACTIVATE: (msg1: string, msg2: string, status: string) => <div className="w-100 text-center mt-5" style={{paddingTop:"120px"}}>
          {status === 'default' && <><EmptyListElement lines={[t(msg1)]} /></>}
          {status === 'search' && <><EmptyListElement lines={[t(msg1), t(msg2)]} /></>}
        </div>, 
    DRAFT: (msg1: string, msg2: string, status: string) => <div className="w-100 text-center mt-5" style={{paddingTop:"120px"}}>
          {status === 'default' && <><EmptyListElement lines={[t(msg1)]} /></>}
          {status === 'search' && <><EmptyListElement lines={[t(msg1), t(msg2)]} /></>}
        </div>
  }

  const displaySearchMsgEmptyListFormation = (filterName: string, msg1?: string, msg2?: string) => {
    if(tabLength === 0 && filterValue.search.length > 0 && filterValue.status === filterName) return listFilter[filterName](msg1, msg2, 'search')
  }

  const displayDefaultMsgEmptyListFormation = (filterName: string, msg1?: string, msg2?: string) => {
    if(tabLength === 0 && filterValue.search.length === 0 && filterValue.status === filterName) {
      return  listFilter[filterName](msg1, msg2, 'default')
    }
  }

  
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-12">
           {props?.allcourses?.length === 0 ? null :<FilterAllCoursesAssisitantComponent doFilter={(filter: any) => doFilter(filter)} />}
        </div>
  
          {
            tabLength > 0 ?
            <div className="col-md-12 mt-5">
              <TableWithExpandElement data={filteredCourses} doAction={(value: any) => { doAction(value); } } size={props?.allCourses?.length} />
            </div> :
            <>
              {displaySearchMsgEmptyListFormation("ALL", "emptyListFormation1", "emptyListFormation2")}
          
              {displaySearchMsgEmptyListFormation("PENDING", "FormationAValider", "emptyListFormation2")}
              
              {displaySearchMsgEmptyListFormation("PUBLISHED", "FormationEnLigne", "emptyListFormation2")}
              
              {displaySearchMsgEmptyListFormation("ARCHIVE", "archivedTrainings", "emptyListFormation2")}
              
              {displaySearchMsgEmptyListFormation("REFUSED", "refusedTrainings", "emptyListFormation2")}
              
              {displaySearchMsgEmptyListFormation("DEACTIVATE", "desactivatedTrainings", "emptyListFormation2")}
              
              {displaySearchMsgEmptyListFormation("DRAFT", "editedTrainings", "emptyListFormation2")} 
            </> 
          }
        

        {/* msg default */}
        {displayDefaultMsgEmptyListFormation("ALL", "noCoursesCreated", "noCoursesCreated_2")}
      
        {displayDefaultMsgEmptyListFormation("PENDING", "FormationAValider")}
        
        {displayDefaultMsgEmptyListFormation("PUBLISHED", "FormationEnLigne")}
        
        {displayDefaultMsgEmptyListFormation("ARCHIVE", "archivedTrainings")}
        
        {displayDefaultMsgEmptyListFormation("REFUSED", "refusedTrainings")}
        
        {displayDefaultMsgEmptyListFormation("DEACTIVATE", "desactivatedTrainings")}
        
        {displayDefaultMsgEmptyListFormation("DRAFT", "editedTrainings")} 
         
      </div >
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  allCourses: state.trainingReducer.trainings,
  user: state.authReducer.connectedUser,
  actualTabAllCourses: state.trainingReducer.actualTabTraining,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { getCoursesForDirector,
      getCourse,
      getCourseTests,
      getRegistrationForm, 
      getTrainingById,
      getCourseChapters,
      getCourseFromEmail,
     }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableAssistantAllCoursesComponent);





