import { ComponentProps, FC, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import axios from "../../../helpers/interceptors";
import { returnStartPathUrl } from "../../../helpers/domainCheck";
import { useHistory } from 'react-router';
const TransferModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const contractData = useMemo(() => { return props?.content?.data }, [props?.content]);

    const history = useHistory()

    const createSubscription = async () => {
    

                await axios.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract", {
                    contractIdentifier: {
                        contractReference: props?.contract[0]?.contractIdentifier?.contractReference
                    },

                    status: [{
                        statusType: "CANCELLED"
                    }]

                })

                const objectDealDynamic = {
                    "email": contractData?.email,
                    "properties": {
                      "amount": "960",
                      "dealstage": "decisionmakerboughtin"
                    }
                  }

                  await axios
                  .post(
                    process.env.REACT_APP_BASE_URL21 +
                    "hubspot/updateDeal",
                    objectDealDynamic
                  )

                if (!!contractData?.offer && !!contractData?.offer?.pricings) {
                    await axios.post(process.env.REACT_APP_BASE_URL17 + "contracts/createorupdatecontract",

                        {
                            contractIdentifier: {
                                contractId: null,
                                contractReference: null
                            },
                            contractDetails: {
                                offerId: contractData?.offer?.id,
                                contractDuration: {
                                    startAt: new Date(Date.now()),
                                    endAt: new Date(Date.now() + 26306e5),
                                },
                                pricingId: !!contractData?.offer?.pricings && contractData?.offer?.pricings.length > 1 ? contractData?.offer?.pricings.find((a: any) => a.price == contractData?.price)?.id : contractData?.offer?.pricings[0]?.id,
                                paymentMethod: "TRANSFER",
                                paymentEngine: null
                            },
                            contractRoles: [{
                                personId: props?.schoolInfo?.id,
                                roleType: "SCHOOL"
                            }],
                            status: [{
                                statusType: "ONGOING"
                            }]

                        }


                    ).then(async(resp:any)=>{
                        if(!!resp && !!resp.data){
                            await props?.closeModal()
                            await history.push(`${returnStartPathUrl()}/offer/success`)
                        }
                    })
                }
            }

        

   
    
return (
    <>

        <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
            <div className="w-100 d-flex align-items-center justify-content-end p-3">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
        </div>

        <div className="modal-body " style={{ paddingTop: 0, zIndex: 1000, overflow:'hidden' }}>
            <div className="row">
                <div className="col-md-10 center" style={{ margin: 'auto' }}>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-start mb-1">
                            <h3 className="H3-Headline">Vous souhaitez payer par virement bancaire? </h3>
                        </div>

                        <div className="col-md-12 input-text-form-control" style={{ maxWidth: 563 }}>
                            <span className="body-xl">En cliquant sur confirmer, vous passez à un mode de paiement par virement bancaire.
                                Un conseiller vous contactera pour vous fournir tous les détails afin d’effectuer
                                votre virement. </span>

                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none" }}>
            <div className="row mt-3 mb-5">
                <div className='col-md-9 d-flex  justify-content-end' >
                    <button className="btn-Secondary large me-3" onClick={() => { props?.closeModal() }}>{t('annuler')}</button>
                    <button type="button" className="btn-Primary large" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { createSubscription() }} >Confirmer</button>
                </div>
            </div>
        </div>

    </>
)
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    content: state.modalReducer.modalContent,
    contract: state.schoolInfoReducer.contract,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(TransferModalComponent);