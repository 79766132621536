import {  FC } from 'react'
import "../../translations/i18n";
import CheckoutSuccededComponent from '../../components/subscription/checkout-succeeded-component';

const CheckoutSuccededPage:FC = () => {

    return (
        <><CheckoutSuccededComponent /></>
    )
}


export default CheckoutSuccededPage;