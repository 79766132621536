import { ComponentProps, FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';

const PreviewClosedQuestion: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {


    return (

        <div style={{ marginBottom: "20px", backgroundColor: "#FFFFFF" }}>
            <div className="d-flex flex-column" style={{ border: "1px solid #EBECEE", padding: "27px", borderRadius: "3px" }}>
                <span className="H3-Headline neutral-3" >Question
                    <span style={{ color: "black" }}> {props?.index}*</span>
                </span>
                <span className="H4-Subtitle">
                    {props?.question?.questionStatement}
                </span>
            </div>
            <div className="d-flex flex-column" style={{ width: "588px", paddingBottom: "52px", paddingTop: "38px", marginLeft: "50px" }}>
                {props?.question?.questionChoice?.map((question: any, index: any) => (

                    <div className="d-flex align-items-center" style={{ marginBottom: "55px" }}>
                        <input
                            disabled={true}
                            className={`${props.question.quizType === "QCU" ? "input-style-radio" : "input-style-checkbox"}`}
                            readOnly={true}
                            type={`${props.question.quizType === "QCU" ? "radio" : "checkbox"}`}
                            style={{ border: '1px solid #F2F2F5' }}
                            value="option1"
                        />
                        <label
                            style={{ textTransform: 'none', cursor: 'default' }}

                        >
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex"></div>
                            </div>
                        </label>
                        <span className="ms-3 body-md">{question.choice}</span>
                    </div>
                ))}
            </div>
        </div>


    );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewClosedQuestion);
