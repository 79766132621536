import Axios from '../../../helpers/interceptors';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { getProfessor, getTeam, getTeamNumber , clearTeamList } from '../../../global-state/actions/professor-actions';
import { ModalIDS } from '../modal-ids';
import { useHistory } from 'react-router';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { saveActionHistory } from '../../../services/history.service';
import { getCodeFromUrl, returnStartPathUrl } from '../../../helpers/domainCheck';
const InviteTeamMemberModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const history = useHistory();
    const { t } = useTranslation();
    let [email, setEmail] = useState<string>("");
    let [msg, setMsg] = useState("")
    let [profileProf, setProfileProf] = useState<any>()
    let [profileAssistant, setProfileAssistant] = useState<any>()
    let link = getCodeFromUrl();
    const regx = RegExp(/^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const [role, setRole] = useState("PROF")
    const goTo = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("offersList"), `${returnStartPathUrl()}/professors/all`)
        await saveActionHistory("koors_upgrade_professors",props?.director?.idPerson,props?.director?.idSchool)
         history.push(`${returnStartPathUrl()}/list`);
        await props?.closeModal()


    }
    const goToList = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("offersList"), `${returnStartPathUrl()}/professors/all`)
        await saveActionHistory("koors_upgrade_administrators",props?.director?.idPerson,props?.director?.idSchool)
         history.push(`${returnStartPathUrl()}/list`);
        await props?.closeModal()


    }
    const Inviter = () => {
        let auth = ('Bearer ' + localStorage.getItem("access_token")).replace(/\"/g, "")
        Axios.get(baseUrl + "users/" + props?.director?.id, {
            headers: {
                'Authorization': auth
            }
        })
            .then((result) => {

                Axios.post(baseUrl1 + "person/invitationWithLimitations", {
                    "invitation": {
                        "invitedBy": {
                            "id": result.data.id,
                            "firstName": result.data.firstName,
                            "lastName": result.data.lastName,
                            "gender": result.data.gender,
                            "type": "NP"
                        },

                        "invited": {
                            "email": email?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                            "type": "NP"
                        },
                        "relationType": role,

                        "to": {
                            "id": props?.director?.idSchool,
                            "code": props?.schoolInfo?.domain ?? document.location.hostname,
                            "name": props?.schoolInfo?.name,
                            "type": "LP",
                        }
                    },
                    "domain":props?.director?.role
                   

                }).then((res) => {
                    if (res.data != ""){
                        Axios.post(baseUrl1 + "person/attach", {
                            "invitation":
                            {
                                "id": res.data.id,
                                "invitedBy": res.data.invitedBy,
                                "invited": res.data.invited,
                                "relationType": role,
                                "to": res.data.to,
                            }
                        }).then(async (ress) => {
    
                            
                                if (role === "PROF") {
                                    await props?.clearTeamList()
                                    await props?.getTeamNumber(props?.director?.idSchool);
                                    await props.getTeam(props?.director?.idSchool, "ADMINSTRATOR", 0, '')
                                    await props.getTeam(props?.director?.idSchool, "PROF", 0, '')
                                    await props.getTeam(props?.director?.idSchool, "ALL", 0, '')
                                    await props.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'ok', lines: [t('inviteSuccess')] });
        
                                } else if (role === "ADMINSTRATOR") {
                                    await props?.clearTeamList()
                                    await props?.getTeamNumber(props?.director?.idSchool);
                                    await props.getTeam(props?.director?.idSchool, "ADMINSTRATOR", 0, '')
                                    await props.getTeam(props?.director?.idSchool, "PROF", 0, '')
                                    await props.getTeam(props?.director?.idSchool, "ALL", 0, '')
                                    await props.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'ok', lines: [t('invitationSuccessAssistant')] });
        
                                }
                                props?.getProfessor(props?.director.idSchool, 'refresh');
                            
                            
                           
                        })
                    } else {
                        
                        setMsg(t('userAlreadyExists'))
                    }
                    
                })
               

            }).catch((error) => {
                
                setMsg(error?.response?.data?.message)
            })
    }

    useEffect(() => {
        if (!!props?.content?.profiles) {
            
            !!props?.content?.profiles.find((profile: any) => profile.key == "nb_prof") && setProfileProf(props?.content?.profiles.find((profile: any) => profile.key == "nb_prof"))
            !!props?.content?.profiles.find((profile: any) => profile.key == "nb_administrateur") && setProfileAssistant(props?.content?.profiles.find((profile: any) => profile.key == "nb_administrateur"))

        }

    }, [props?.content?.profiles])

    const closeModal =async()=>{
        await setEmail('')
        props?.closeModal()
    }


    useEffect(() => {
      if(!regx.test(email) && (email !== '')){
        setMsg(t('enterValidEmail'))
      }else{
        setMsg("")
      }
    }, [email])
    
 
    const classStatus = () => {
        if((!regx.test(email) && (email !== '')) || (msg.length>0)) {
            return 'error'
        }
        if(email !== '') return 'success';
        return ''
    }
   
    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none"}}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row justify-content-center">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="H3-Headline">{t('membreInvitation')}</h3>
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="form-title">{t('role')}*</label>
                                <div className="d-flex flex-row align-items-center justify-content-start flex-wrap w-100 mt-2">
                                    <input dir="ltr"className="input-style-radio-button" type="radio" name="exampleRadios3" id="beginner" value="option2" onClick={() => { setRole("PROF") }} checked={role === "PROF" ? true : false} />
                                    <label style={{ cursor: 'pointer', width: 170, textAlign: 'center' }} className='me-4' htmlFor="beginner">{t('filterProfessors')}</label>
                                    <input dir="ltr"className="input-style-radio-button" type="radio" name="exampleRadios3" id="anyLevel" value="option1" onClick={() => { setRole("ADMINSTRATOR") }} checked={role === "ADMINSTRATOR" ? true : false} />
                                    <label style={{ cursor: 'pointer',  textAlign: 'center' , justifyContent:"center" }} className='me-4' htmlFor="anyLevel">{t('schoolAssistant')}</label>
                                </div>
                            </div>
                            {
                                (role == "PROF" && !!profileProf && profileProf?.valueContract > 0 &&   profileProf?.valueSchool >= profileProf?.valueContract ) ?
                                    <> <div className="col-md-12 ">
                                        <div className='notif-warning d-flex flex-row align-items-center mt-4'>
                                            <span className="material-icons Orange-800 me-3" style={{ fontSize: 32 }}>warning</span>
                                            <span className='body-md black-800'> {t('professorLimitAtteined')}</span>
                                        </div>
                                        <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                                            <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >
                                                {t('annuler')}
                                            </button>

                                            <button className={`btn-Primary large `} onClick={() => { goTo() }} >
                                                {t('Voirlesforfaits')}
                                            </button>

                                        </div>
                                    </div></> :
                                    <>{
                                        role == "PROF"&& <>
                                          <div className="col-md-12 mt-4">
                                            <label className="form-title">{t('memberMail')}</label>
                                            <input dir="ltr"type="text" className={`form-control input text-default ${classStatus()}`} placeholder={t('emailLogin')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                            {msg.length>0 &&<label className="error-input mt-1">{msg}</label>}
                                        </div>
                                        
                                        <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                                            <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >
                                                {t('annuler')}
                                            </button>

                                            <button className={`btn-Primary large ${(email !== '' && regx.test(email)) ? "success" : ""}`} disabled={!(email !== '' && regx.test(email))} onClick={Inviter} >
                                                {t('send')}
                                            </button>
                                            
                                        </div></>
                                    }
                                    </>
                            }
                            {
                                (role === "ADMINSTRATOR" && profileAssistant && profileAssistant?.valueContract > 0 && profileAssistant?.valueSchool >= profileAssistant?.valueContract) ?
                                    <> <div className="col-md-12 ">
                                        <div className='notif-warning d-flex flex-row align-items-center mt-4'>
                                            <span className="material-icons Orange-800 me-3" style={{ fontSize: 32 }}>warning</span>
                                            <span className='body-md black-800'> {t('assistantLimitAtteined')}</span>
                                        </div>
                                        { (!regx.test(email) && (email !== '')) && <span className='error-input mt-1'>{t('enterValidEmail')}</span>}
                                        <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                                            <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >
                                                {t('annuler')}
                                            </button>

                                            <button className={`btn-Primary large `} onClick={() => { goToList() }} >
                                                {t('Voirlesforfaits')}
                                            </button>

                                        </div>
                                    </div></> :
                                    <>
                                        {role === "ADMINSTRATOR" && <>
                                            <div className="col-md-12 mt-4">
                                                <label className="form-title">{t('memberMail')}</label>
                                                <input dir="ltr"type="text" className={`form-control input text-default ${classStatus()}`} placeholder={t('emailLogin')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {msg.length>0 &&<label className="error-input mt-1">{msg}</label>}

                                            </div>

                                            <div className="d-flex flex-column w-100 mt-5 mb-4 ">
                                               <div className='d-flex flex-row justify-content-end'>
                                                    <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >
                                                        {t('annuler')}
                                                    </button>

                                                    <button className={`btn-Primary large ${(email !== '' && regx.test(email)) ? "success" : ""}`} disabled={!(email !== '' && regx.test(email))} onClick={Inviter} >
                                                        {t('send')}
                                                    </button>
                                               </div>
                                                
                                            </div></>}

                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({

    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    loadingAllProfessors: state.profReducer.loadingAllProfessors,
    subscription: state.schoolInfoReducer.subscription,
    content: state.modalReducer.modalContent,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            getProfessor,
            getTeamNumber,
            getTeam,
            clearTeamList,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteTeamMemberModalComponent);
