import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux"
import { AppState } from "../../../global-state/store/root-reducers";
import { getSubjectCommunity, setActualTabManageChat } from "../../../global-state/actions/chat-actions";
import ManageCommunityComponent from "../../../components/community/course-community/manage-community-component";
import ListCardCommentStudent from "../../../components/community/course-community/comments/list-card-comment-student";
import CommentCardLoading from "../../../components/community/course-community/comments/comment-card/comment-card-loading";
import ManageCommunityLoading from "../../../components/community/manage-community-loadding-component";
let outsideTab = "";
let outsidepageDiscussion = 0;
let outsidepageQuestion = 0;
let outsideTrie = "";
let outsideFiltre = "";
let outsidesearchFilter = "";
let outsideAtBottom = false;
const CommunityPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [actualTab, setActualTab] = useState<string>("discussion")
    const [trie, setTrie] = useState("recent");
    const [filtre, setFiltre] = useState("all");
    const [searchFilter, setSearchFilter] = useState("");
    const [doSearch, setDoSearch] = useState(false);
    const [pageDiscussion, setPageDiscussion] = useState(0);
    const [pageQuestion, setPageQuestion] = useState(0);
    const [atBottom, setAtBottom] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    outsideTab = actualTab;
    outsidepageDiscussion = pageDiscussion;
    outsidepageQuestion = pageQuestion;
    outsideTrie = trie;
    outsideFiltre = filtre;
    outsidesearchFilter = searchFilter;
    outsideAtBottom = atBottom;
    useEffect(() => {
        setActualTab(props?.tabChatManager);
    }, [props?.tabChatManager]);
    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);
    useEffect(() => {
        setRefresh(props?.refresh)
    }, [props?.refresh]);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        changeList("recent", "all", "", 0, true, false)
        setActualTab("discussion");
        return () => {
            window.removeEventListener('scroll', handleScroll);
            props?.setActualTabManageChat("discussion");
            setTrie("recent");
            setFiltre("all");
            setDoSearch(false);
            setSearchFilter("");
            setPageDiscussion(0);
            setPageQuestion(0);
        }
    }, [])
    const handleScroll = (event: any) => {
        if (!(outsideAtBottom) && isBottom()) {
            setAtBottom(true);
            changeList(outsideTrie, outsideFiltre, outsidesearchFilter, (outsideTab === 'discussion') ? outsidepageDiscussion + 1 : outsidepageQuestion + 1, false, false);
            if (outsideTab === 'discussion') {
                setPageDiscussion(outsidepageDiscussion + 1)
            } else {
                setPageQuestion(outsidepageQuestion + 1)
            }
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }
    const isBottom = () => {
        return ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
    }
    const changeList = (trie: string, filter: string, searchFilter: string, page: number, isSearch: boolean, refresh: boolean) => {
        if (actualTab === "discussion") {
            if (filter === "all") {
                let subjects = props?.courseInfo?.orders?.map((obj: any) => { return obj.chapter.id + " CHAPTER FORUM" })
                subjects.push(props?.courseInfo?.id + " COURSE FORUM")
                let object = {
                    subject: subjects,
                    trie: trie,
                    searchFilter: searchFilter,
                    page: page,
                    refresh: refresh,
                }
                if(typeof object!= undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            } else {
                let filtre = JSON.parse(filter);
                let subjects = filtre.id + " " + filtre.typeCategory + " FORUM"
                let object = {
                    subject: [subjects],
                    trie: trie,
                    searchFilter: searchFilter,
                    page: page,
                    refresh: refresh,
                }
                if(typeof object!= undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            }


        } else if (actualTab === "question") {
            if (filter === "all") {
                let subjects = props?.courseInfo?.orders?.map((obj: any) => { return obj.chapter.id + " CHAPTER QUESTION" })
                subjects.push(props?.courseInfo?.id + " COURSE QUESTION")
                let object = {
                    subject: subjects,
                    trie: trie,
                    searchFilter: searchFilter,
                    page: page,
                    refresh: refresh,
                }
                if(typeof object!= undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            } else {
                let filtre = JSON.parse(filter);
                let subjects = filtre.id + " " + filtre.typeCategory + " QUESTION"
                let object = {
                    subject: [subjects],
                    trie: trie,
                    searchFilter: searchFilter,
                    page: page,
                    refresh: refresh,
                }
                if(typeof object!= undefined && object?.subject?.length!=0){
                props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            }
        }
    }
    const Search = (doSearch: any) => {
        switch (outsideTab) {
            case 'discussion':
                setPageDiscussion(0);
                break;
            case 'question':
                setPageQuestion(0);
                break;
        }
        setTrie(doSearch.trie);
        setFiltre(doSearch.filtre);
        setSearchFilter(doSearch.searchFilter)
        setDoSearch(true);
        changeList(doSearch.trie, doSearch.filtre, doSearch.searchFilter, 0, true, true);
    }
    return (
        <>
            {!loading && <div className="page-wrapper-height">
                <ManageCommunityComponent Searching={Search} />
                <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                    {refresh ?
                        <div className="col-md-10">
                            <CommentCardLoading />
                            <CommentCardLoading />
                            <CommentCardLoading />
                            <CommentCardLoading />
                        </div> :
                        <>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-12 p-0 mt-5">
                                    <ListCardCommentStudent doSearch={doSearch} what={actualTab} postsList={props?.listPosts} />
                                </div>
                            </div>
                        </>}

                </div>
            </div>}
            {loading && <div className="page-wrapper-height">
                <ManageCommunityLoading title={props?.courseInfo?.title} />
                <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                    <div className="row d-flex justify-content-center mt-5">
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    tabChatManager: state.chatReducer.tabChatManager,
    connectedUser: state.authReducer.connectedUser,
    listPosts: state.chatReducer.listPosts,
    loading: state.chatReducer.loadingPosts,
    courseInfo: state.studentTraining.currentCourse,
    refresh: state.chatReducer.refreshPosts,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        getSubjectCommunity,
        setActualTabManageChat
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommunityPage);