import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

interface Props {
  data: any
  index?: any
  open: any
  loading : any
  dragable:any
}
export default function RowExpandElement(props: Props) {
    const [open, setOpen] = useState([true]);
    const [index, setIndex] = useState(-1);
    const [data, setData] = useState<any>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setData(props?.data?.row)
      }, [props]);


  useEffect(() => {
    setLoading(props?.loading)
}, [props?.loading]);



      useEffect(() => {
        for (let index = 0; index < data?.body?.length; index++) {
          open[index] = true;
        }
       }, [props]);
       
    return(
      <tr className="table-white shadow-table" style={{ height: '75px'}}>

          {
            !open[index] ? <>
            {props.dragable &&
  <td className=""></td>
            }
           
              <td className="w-50" colSpan={2}></td>
            </> :
            <>
            </> 
          }
            <>
          {
            !open[index] && !loading ?
            <>
              <td className="text-end " colSpan={4}>
                  <span className="neutral-3 expandText" style={{marginRight: "16px"}}>{t('Registered')} </span>
                  <span style={{marginRight: "64px"}}>{props?.data?.countInscribed}</span>
                  <span className="neutral-3 expandText" style={{marginRight: "16px"}}>{t('certified')}</span> 
                  <span style={{marginRight: "64px"}}>{props?.data?.countCertified}</span>
                  <span className="neutral-3 expandText" style={{marginRight: "16px"}}>{t('candidatsCapitalise')}</span> 
                  <span className="">{props?.data?.countCandidates}</span> 

              </td>
   
         
            </> : <>
            <td className="text-center w-15">
                <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table" />
                
              </td>
              <td className="text-end w-15"> 
                  <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table" /> 
              </td>
              <td className="text-end w-15" style={{ paddingRight: 0}}>
                <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table" /> 
              </td>
              <td className=" w-2">
               <Skeleton variant="text" animation="wave" width={'70%'} className="skeleton data-table" />
              </td>
            </>
          }
        </>
      </tr>
    )
}