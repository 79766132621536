import { useTranslation } from "react-i18next"




export default function CardExamComponent(props: any) {
    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    const { t } = useTranslation();
    return (<div onClick={() => props?.getExam(props?.exam)} className="d-flex align-items-center bg-with-border white vignette-hover dragAndDrop py-28 ps-40 pe-4">
    <div style={{width: 70}}>
      <span className="H3-Headline">{(props?.index < 10)?"0" + props?.index: props?.index}</span>
    </div>
    <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span className="H3-Headline mb-6 formation-card horizontal title">{props?.exam?.title}</span>
          <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
            <div className="d-flex me-2">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3469 3.94322V1.41788C13.3469 0.633432 12.6588 0 11.7905 0H1.57023C0.706601 0 0 0.633432 0 1.41788V12.4169C0 13.2056 0.706601 13.8432 1.57023 13.8432H9.23662V15.4121C9.23662 15.6261 9.37055 15.819 9.57376 15.9239C9.77696 16.0288 10.031 16.0246 10.2296 15.9155L12.4879 14.678L14.7463 15.9155C14.8479 15.97 14.9633 15.9994 15.0834 15.9994C15.1942 15.9994 15.3143 15.9742 15.4113 15.9239C15.6145 15.819 15.7484 15.6261 15.7484 15.4121V10.7977C16.5336 10.0594 17 9.06102 17 7.95775C16.9954 5.97356 15.4252 4.31237 13.3469 3.94322ZM9.23662 12.6686H1.57023C1.41782 12.6686 1.29313 12.5596 1.29313 12.4169V1.41788C1.29313 1.27945 1.41782 1.17458 1.57023 1.17458H11.7905C11.9429 1.17458 12.0538 1.27945 12.0538 1.41788V3.8803C9.79082 4.07326 7.9712 5.82674 7.9712 7.95356C7.9712 9.05682 8.45151 10.0594 9.23662 10.7935V12.6686ZM14.4553 14.3718L12.8343 13.4867C12.6265 13.3734 12.3586 13.3734 12.1508 13.4867L10.5297 14.3718V11.6451C11.1301 11.901 11.7952 12.0478 12.4925 12.0478C13.1899 12.0478 13.8549 11.9052 14.4553 11.6451V14.3718ZM12.4925 10.8732C10.7237 10.8732 9.2828 9.56441 9.2828 7.95775C9.2828 6.3511 10.7237 5.04229 12.4925 5.04229C14.2613 5.04229 15.7023 6.34691 15.7023 7.95775C15.7023 9.56441 14.2613 10.8732 12.4925 10.8732Z" fill="#9DDBA9" />
                <path d="M8.35911 3.10413H2.54004V4.27871H8.35911V3.10413Z" fill="#9DDBA9" />
                <path d="M6.4656 6.33429H2.54004V7.50887H6.4656V6.33429Z" fill="#9DDBA9" />
                <path d="M7.34308 9.56445H2.54004V10.739H7.34308V9.56445Z" fill="#9DDBA9" />
              </svg>
            </div>
            <span className="body-sm bold neutral-2">{t('finalExmBreadcrunbTitle')}</span>
          </div>
        </div>
      </div>
      <div className=" d-flex align-items-center detail-liste-responsive-actions me-2 justify-content-end" style={{ minWidth: 290 }}>
        <div className="d-flex flex-row flex-wrap">
          <div className="d-flex align-items-center">
            <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
            <span className="body-md bold">{convertTime(props?.exam?.duration)}</span>
          </div>
        </div>
      </div>
    </div>
  </div>)
}