import { useTranslation } from "react-i18next";

export default function CardTestShowOnlyComponent(props: any) {
    const { t } = useTranslation();

        const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {
            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    return (<>
    <div className={` d-flex align-items-center bg-with-border white vignette-hover dragAndDrop  ps-40 pe-4 py-28 mt-3`}>
          <div style={{width: 70}}>
            <span className="H3-Headline">{(props?.index < 10)?"0"+ props?.index: props?.index}</span>
          </div>

      <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span className="H3-Headline mb-6 formation-card horizontal title">{props?.test.title}</span>
            <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
              <div className="d-flex me-2">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9963 8.31081V12.8756C12.9963 13.1532 12.7778 13.3784 12.508 13.3784H2.06358C1.79384 13.3784 1.57531 13.1534 1.57531 12.8756V2.12442C1.57531 1.84675 1.79384 1.6218 2.06358 1.6218H10.8301V0H2.06358C0.923454 0 0 0.950777 0 2.12442V12.8758C0 14.0494 0.923454 15 2.06358 15H12.5079C13.648 15 14.5714 14.0494 14.5714 12.8758V8.31081H12.9963Z" fill="#9DDBA9" />
                  <path d="M7.83045 10.487L3.99609 6.57939L5.11657 5.43755L7.83045 8.20319L15.8805 0L17.0008 1.14184L7.83045 10.487Z" fill="#9DDBA9" />
                </svg>
              </div>
              <span className="body-sm bold neutral-2">{t('textConnaissance')}</span>
            </div>
          </div>
        </div>
        <div className=" d-flex align-items-center detail-liste-responsive-actions me-2 justify-content-end" style={{ minWidth: 290 }}>
          <div className="d-flex flex-row flex-wrap">
            {/* <div className="d-flex align-items-center me-4 pe-2 ">
              <span className="body-md bold black-800 me-2 pe-1">
                85%
              </span>
              <span className="body-md bold neutral-3" style={{ width: 'max-content' }}>
                Taux de réussite
              </span>
            </div> */}
            <div className="d-flex align-items-center">
              <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
              <span className="body-md bold">{convertTime(props?.test?.duration)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>)
}