import {FC, useEffect} from "react"
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom"
import { AppState } from "../global-state/store/root-reducers";
interface Props extends RouteProps {
    component?: any;
    children?: any;
    roles?:string[]
}
const PrivateRoute:  FC<ReturnType<typeof mapStateToProps> & Props> = (props) =>{
    let { component: Component, children, roles,user, ...rest } = props;

    useEffect(() => {
        user = props?.user;
    }, [props?.user?.loggedIn])

       return ( 
          
        <Route
            {...rest}
            render={routeProps =>  
                (user?.loggedIn ? (!(Array.isArray(roles)? roles.includes(user.connectedUser.role):true)?
                                        <Redirect to={{ pathname: (user.connectedUser.role === 'role_student')?'/courses/my-courses': '/activity' }} />: 
                                        (Component? ( <Component {...routeProps} /> ) : ( children ) )) : 
                                  ( <Redirect to={{ pathname: '/auth/login', state: { from: routeProps?.location }, }} /> )
                )}
        /> 
    );
};


const mapStateToProps = (state: AppState, ownProps: any) => ({
    user:state.authReducer,
    history: ownProps?.hisotry
  }); 

  
  export default connect( mapStateToProps  )(PrivateRoute);