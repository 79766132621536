export const TRANSLATIONS_SV = {
    addCourse: "NY KURS",
    createCourse: "Skapa en kurs",
    myCourses: "Mina kurser",
    Published: "Uppkopplad",
    Draft: "Oorganiserad",
    Pending: "Väntar på godkännande av direktören",
    RequestDeactivation: "Väntar på inaktivering av regissören",
    RequestActivation: "Väntar på aktivering av regissören",
    RequestDeletion: "Väntar på arkivering av regissören",
    certified: "Utexaminerade",
    candidats: "KANDIDATER",
    candidatsCapitalise: "Kandidater",
    courseStatus_1: "Kursen kommer att finnas kvar",
    courseStatus_2: "privat tills det publiceras",
    Pending_2: "Väntar på validering",
    RequestDeletion_2: "Väntar på arkivering",
    RequestDeactivation_2: "Väntar på avaktivering",
    RequestActivation_2: "Väntar på aktivering",
    actvity: "AKTIVITET",
    students: "Studenter",
    students_2: "Studerande",
    description: "BESKRIVNING",
    archive: "ARKIV",
    publish: "PUBLICERA",
    inscription: "INSKRIFT",
    inscriptionTitle: "Inskrift",
    certifiedTitle: "Tog examen på",
    lastVisitProfile: "Senaste besök sedan",
    lastVisitProfile_1: "senaste besöket",
    lastVisitProfile_2: "Ja",
    descProf: "Kort beskrivning av läraren som kommer att synas i presentationen av kursen",
    content: "INNEHÅLL",
    titleCoursePlaceholder: "Ange kursens titel här...",
    titleCourseForm: "Kurstitel",
    modalPublishCourse_1: "Skicka till direktören för godkännande",
    modalPublishCourse_1_1: "Validera inlägget",
    modalRefusedCourse_1: "Vägra publicering",
    modalPublishCourse_2: "Lägg till en kommentar för regissören",
    modalPublishCourse_2_2: "Lägg till en kommentar för läraren",
    modalPublishCourse_3: "Kommentator...",
    modalPublishCourse_4: "SKICKA KURS",
    modalPublishCourse_4_4: "PUBLICERA KURS",
    modalRefusedCourse_4: "AVVISA KURSEN",
    modalPublishCourseSuccess: "Kursen har skickats till rektor för validering.",
    modalPublishPathSuccess: "Kursen har skickats till rektor för validering.",
    modalPublishSessionSuccess: "Sessionen skickades till direktören för validering.",
    modalPublishCourseSuccess_1: "Kursen publiceras. Den finns nu tillgänglig online.",
    modalDeactivateCours: "Kursen är handikappad. Det är inte längre tillgängligt online.",
    modalRefusedCourseSuccess: "Du har precis tackat nej till publicering av kursen. Ditt avslag har skickats till läraren.",
    titleFormCourse_1: "Videopresentation av kursen",
    titleFormCourse_2: "Visuellt för att illustrera kursen",
    titleFormCourse_3: "Textpresentation av kursen",
    resume: "Inledande sammanfattning",
    textAreaPlaceholder: "Skriv in text här...",
    placeholderInscriptionInput: "Skriv in text här",
    prerequisite: "Förkunskaper för att gå denna kurs",
    objectives: "utbildningsmål",
    skills: "Kunskaper och färdigheter som förvärvats genom denna kurs",
    skillsPlaceholder: "Ange ett sökord...",
    uploadVideo_1: "Dra och släpp kursvideon du vill ladda upp (mp4, mov)",
    uploadVideo_2: "VÄLJ EN VIDEOFIL",
    uploadImage_1: "Dra och släpp ett foto för att illustrera kursen (jpg, png)",
    uploadImage_2: "VÄLJ EN BILD",
    downloadVideo: "Ladda ner",
    awaitVideo: "I kön",
    whiletranscode: "Visar just nu",
    transcodingVideo: "Videobearbetning",
    breadCrumbChapter: "Kapitel",
    chapitre: "Kapitel",
    newTest: "NYTT TEST",
    newChapter: "NYTT KAPITEL",
    selectLevelCourse: "Nivå",
    selectLevelCourse_1: "Nybörjare",
    selectLevelCourse_2: "Mellanliggande",
    selectLevelCourse_3: "Bekräftad",
    listChaptersContent: "För att komma igång, specificera kursnivån och skapa ditt första kapitel.",
    chapterSelectFomCHapter: "KAPITEL",
    saveChapter: "ATT REGISTRERA",
    durationChapter: "Varaktighet",
    placeholderTitleChapter: "Skriv in rubriken på kapitlet här...",
    titleFormChapter_1: "Kapitel Video",
    titleFormChapter_2: "Filer att ladda ner",
    titleFormChapter_3: "Kapiteltext",
    textFileUpload_1: "Dra och släpp kapiteluppladdningsfiler",
    textFileUpload_2: "(ljud, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Välj fil",
    uploadVideoChpter_1: "Dra och släpp videon för kapitlet du vill ladda upp (mp4, mov)",
    tests: "Tester",
    test: "Testa",
    resultat: "Resultat",
    reponseInscrit: "Prenumerationsförfrågan",
    reponseAv: "Svara innan",
    refusedAt: "Vägrade på",
    Refused: "Vägra",
    Unpaid: "Att betala",
    seeDemand: "Se min förfrågan",
    certifButton: "CERTIFIKAT",
    courseTable: "Bildning",
    DATEACHAT: "Inköpsdatum",
    exportListe: "EXPORTLISTA",
    filterProfessors: "Lärare",
    statusFilter: "Status",
    search: "Forskning",
    Tous: "Allt",
    Brouillon: "Oorganiserad",
    Avalider: "Att validera",
    Enligne: "Uppkopplad",
    Archive: "Arkiv",
    Refuse: "Vägra",
    Cours: "Kurs",
    COURSE: "Kurs",
    Professeurs: "Lärare",
    Demandes: "Förfrågningar",
    filterCourse: "Alla kurser",
    Certifies: "Auktoriserad",
    Statut: "Status",
    PresProf: "Presentation av professorn",
    dernVisite: "senaste besöket",
    Enattente: "I väntan på",
    Admis: "accepterad",
    Suspendu: "Upphängd",
    inviteProf: "BJUD IN EN LÄRARE",
    inviteProf_2: "Bjud in en lärare att registrera sig",
    send: "Skicka",
    inviteSuccess: "Din inbjudan har skickats till läraren.",
    myAccount: "Mitt konto",
    saveImg: "spara bild",
    Profile: "Profil",
    Nom: "Efternamn",
    NomObligatoire: "Efternamn*",
    PrenomObligatoire: "Förnamn*",
    Prenom: "Förnamn",
    country: "bosättningsland",
    Tunisie: "Tunisien",
    France: "Frankrike",
    city: "residensstad",
    birthdate: "Födelsedatum",
    birthdate_input: "mm / dd / åååå",
    NivScolaire: "Skolnivå",
    Primaire: "Primär",
    College: "Grundskola",
    Lycee: "Gymnasium",
    Bac: "Bac",
    Technicien2: "Tekniker (Bac +2)",
    License: "Licens (Bac+3)",
    Master: "Master eller motsvarande (BAC +4 eller +5)",
    Ingenieur: "Ingenjör",
    Doctorat: "doktorsexamen",
    emailAdd: "E-postadress",
    updatePass: "Ändra mitt lösenord",
    password: "Lösenord",
    actualPass: "Nuvarande lösenord",
    newPass: "nytt lösenord",
    enterPassword: "Minst 8 tecken",
    msgErrorPassword: "Ange minst 8 siffror/bokstäver",
    msgErrorConfirmPassword: "Ange samma lösenord",
    confimrNewPass: "Lösenordsbekräftelse",
    msgErrorMail: "Vänligen ange en giltig e-postadress",
    confimrNewEmail: "Ny Email Adress",
    validPassButton: "Validera mitt nya lösenord",
    MainSchoolColor: "Huvudskola färg",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "Lägg till din färg för att anpassa presentationen av kurserna",
    imgCrop_1: "Dra och släpp ett foto",
    imgCrop_3: "Dra och släpp din logotyp",
    imgCrop_2: "VÄLJ EN BILD",
    pathBy: "Kurs ges av",
    sessionBy: "Session ges av",
    catalogCourses: "Kurskatalog",
    Etudiants: "Studenter",
    Ecole: "Skola",
    Catalogue: "Katalog",
    Activite: "Aktivitet",
    lecole: "Skola",
    howItWorks: "Hur det fungerar",
    hello: "Välkommen !",
    cnxButton: "Logga in",
    createAccountMsg: "skapa mitt konto",
    questionCreateAccount: "Du har inte skapat ett konto än?",
    forgotLogin: "Glömt ditt lösenord ?",
    emailLogin: "E-post",
    validInscription: "VALIDERA MIN REGISTRERING",
    activateAccount: "AKTIVERA MITT KONTO",
    madame: "KVINNOR",
    monsieur: "MAN",
    other: "ÖVRIG",
    registerDone_1: "Din registreringsförfrågan har beaktats.",
    registerDone_2: "Ett e-postmeddelande har skickats till dig.",
    registerDone_3: "Klicka på länken i detta e-postmeddelande för att bekräfta skapandet av ditt konto.",
    registerDone_4: "Ses snart !",
    validRegister: "Att validera",
    activateMyAccount: "Aktivera mitt konto",
    directeur: "Direktör",
    professeur: "Lärare",
    invalidToken: "Denna länk har använts tidigare och den har upphört att gälla, kontakta supporten om det behövs.",
    questionAccount: "Har du redan ett konto ?",
    verifMail_1: "Din e-postadress har verifierats, du kan nu logga in.",
    verifMail_3: "Validering av din e-post",
    changePass: "ändra lösenord",
    msgUpdatedPass: "Lösenordet har ändrats.",
    recPass: "Återställa lösenord",
    reinMail: "Ange din e-postadress nedan. Du kommer att få en länk för att återställa ditt lösenord.",
    reinBUttonPass: "Återställ mitt lösenord",
    sendMailRein: "Ett e-postmeddelande med din återställningslänk har skickats till dig.",
    alreadyHaveAcc: "Har du redan ett konto ?",
    followCourse: "Gå den här kursen",
    followPath: "Följ denna väg",
    followSession: "Följ session",
    objPEda: "utbildningsmål",
    preresuiPeda: "Förkunskaper för denna kurs",
    preresuiPedaPath: "Förkunskaper för denna kurs",
    preresuiPedaSession: "Förutsättningar för denna session",
    skillsPreveiw: "Kunskaper och färdigheter som förvärvats genom denna kurs",
    skillsPreveiwPath: "Kunskaper och färdigheter som förvärvats genom denna kurs",
    skillsPreveiwSession: "Kunskaper och färdigheter som förvärvats under denna session",
    courseProgramm: "Kursprogram",
    Chapitres: "Kapitel",
    videoCourse: "Videolektioner",
    textCourse: "Kurstext",
    downloadFiles: "Filer att ladda ner",
    testConn: "Kunskapstester",
    aboutSchool: "Om skolan",
    aboutProfessor: "Om läraren",
    registerCourse: "Anmäl dig till kursen",
    registerPath: "Anmäl dig till kursen",
    registerSession: "Anmäl dig till sessionen",
    testConn_2: "Testa dina kunskaper",
    progressView: "Spåra dina framsteg",
    previewText_1: "Fyll i din anmälningsförfrågan så att läraren kan validera den.",
    previewText_2: "Gå vidare i ditt program och lär dig nya färdigheter.",
    previewText_3: "Validera dina kunskaper med tester och få ditt certifikat.",
    Introduction: "Introduktion",
    Documents: "Dokument",
    downloadFIleDetails: "Att ladda ned",
    Etudiant_1: "Studerande",
    Professeur_1: "Lärare",
    Directeur_1: "Direktör",
    annuler: "ATT AVBRYTA",
    modifier: "Modifierare",
    logout: "Logga ut",
    connexionButton: "Anslutning",
    languageFR: "franska",
    languageEN: "engelsk",
    language: "Språk",
    Pending_3: "Att validera",
    CERTIFICAT: "CERTIFIKAT",
    refuseCourse: "VÄGRA PUBLICERING",
    joursVisit: "dagar",
    consulter: "ATT KONSULTERA",
    jourVisit: "Dag",
    Candidat: "Kandidat",
    enCours: "STUDENTER PÅGÅENDE",
    certifiés: "EXAMENDER",
    Dossier: "Fall",
    Demande: "Begäran",
    DemandeEnvoyéeIlYA: "Förfrågan skickad sedan",
    Resultats: "Resultat",
    Certificat: "Certifikat",
    Tempsdobtention: "Erhållande",
    Tempsdobtention_2: "Få tid",
    refuseCourseDirector: "Vägra publicering",
    refuseCourseDirector_2: "Lägg till en kommentar för läraren",
    refuseCourseDirector_3: "AVVISA KURSEN",
    modalRefuseCourseSuccess_1: "Du har precis tackat nej till publicering av kursen.",
    modalRefuseCourseSuccess_2: "Ditt avslag har skickats till läraren.",
    archiveCourseDirector: "Är du säker på att du vill arkivera den här kursen?",
    archiveCourseDirectorSuccess: "Kursen är arkiverad.",
    archiveCourseDirector_2: "ARKIVERA KURSEN",
    nothingTOSay: "Inget att säga",
    online: "Uppkopplad",
    offline: "Off-line",
    validationNewPassword: "Du kan logga in med ditt nya lösenord.",
    showMore: "VISA FÖLJANDE",
    folderRegistrationTrueOrFalse: "Med förbehåll för validering av filen.",
    nextCommentsFeedback: "Visa nästa",
    emailUpdateButtonShH: "ÄNDRA MIN E-postadress",
    conditionDeValidation: "Villkor för validering av ansökan om studentregistrering av professorn.",
    Acceptation: "Godkännande av automatisk studentregistrering.",
    AcceptationSousDossier: "Godkännande av studentens registrering förutsatt validering av filen.",
    questions: "Frågor att ställa till studenten",
    documents: "Handlingar som ska begäras av studenten",
    titreQuestion: "Ange rubriken på frågan här",
    titreDocument: "Ange titeln på dokumentet här",
    AjouterQuestion: "Lägg till fråga",
    AjouterDocument: "Lägg till dokument",
    demandeDinscription: "Prenumerationsförfrågan",
    envoyerDemande: "SKICKA MIN FÖRFRÅGAN",
    titreRegistration_1: "Frågor från läraren",
    titreRegistration_2: "Dokument som läraren begärt",
    Inscribed: "Pågående",
    CertifiedStudent: "Auktoriserad",
    DraftStudent: "Registreringsfil pågår",
    Candidate: "Väntar på validering av filen",
    finishRegistration: "AVSLUTA MIN FÖRFRÅGAN",
    noCoursesStudent: "Du är inte anmäld till någon kurs.",
    discoverCatalog: "UPPTÄCK KATALOGEN",
    noCoursesCreated: "Ingen utbildning har skapats ännu.",
    noCoursesCreated_2: "För att komma igång, skapa din första kurs.",
    noCourses_1: "Inga kurser gjorda",
    noCourses_2: "Ingen registreringsbegäran",
    noCourses_3: "Inga avslutade kurser",
    noCoursesPublished: "Inga kurser har publicerats ännu.",
    deleteCompte: "Ta bort mitt konto",
    searchProfessors: "Att efterforska...",
    descriptionProfLabel: "Kort beskrivning av läraren som kommer att synas i presentationen av sina kurser.",
    relaunchProfInvitation: "ÅTERUPPLIVA",
    noProfessorsList_1: "Ingen lärare hittades.",
    noProfessorsList_2: "Sök igen.",
    noProfessorsListDefault_1: "Ingen lärare är registrerad.",
    noProfessorsListDefault_2: "Bjud in en lärare att registrera sig för att komma igång.",
    signatureProf: "Professorns signatur",
    signatureDirector: "Direktörens signatur",
    Deactivate: "Inaktiverad",
    breadCrumbTest: "Testa",
    placeholderTitleExam: "Ange här provets titel...",
    exempleTitleExam: "Exempel, Test av kunskap om den första kunskapen",
    noQuestionTest: "För att komma igång, skapa din första fråga.",
    newQuestion: "NYTT PROBLEM",
    addQuestionText: "Lägg till fråga",
    placeholderQuestionText: "Ange frågan",
    answerQuestion: "Ange svaret",
    placeholderChoiceText: "Ange texten för flervalen",
    delete: "Att radera",
    suppcomte: "Radering av konto",
    selectMotif: "Välj ett mönster",
    coursNeMeconvier: "Klasser passar inte mig",
    comm: "Lägg till en kommentar",
    DeleteSuccess: "Ditt konto har raderats. Adjö.",
    CreationAccountProfDirector: "Ditt konto har skapats. Du kan logga in.",
    messageEnvoyerDemande_1: "Din registreringsförfrågan har skickats.",
    messageEnvoyerDemande_2: "Du får svar via e-post",
    messageEnvoyerDemande_3: "under de kommande 3 dagarna.",
    candidat_status: "VÄNTAR PÅ VALIDERING",
    refuser: "ATT VÄGRA",
    refuser_1: "VÄGRA",
    valider: "ATT VALIDERA",
    valider_1: "ACCEPTERAD",
    refuserDemandeRegistration: "Neka studentregistrering",
    ajouterCommentaireEtudiant: "Lägg till en kommentar för studenten.",
    refuserLaDemande: "AVVISA BEGÄRAN",
    messageRefuseRegistrationCours: "Studentregistreringen till kursen avböjdes.",
    messageRefuseRegistrationPath: "Studentens registrering till kursen har avvisats.",
    messageRefuseRegistrationSession: "Studentregistreringen för sessionen avvisades.",
    messageValiderRegistration: "Studenten bjöds in att börja läsa kursen.",
    messageValiderRegistrationParcours: "Studenten har bjudits in att börja följa kursen",
    messageValiderRegistrationSession: "Eleven har bjudits in att börja följa sessionen",
    SuspendProf: "Att stänga av",
    textProfSuspend: "Den här läraren kommer inte längre att kunna komma åt lektionerna. Du har möjlighet att återställa den om du ändrar dig.",
    otherChoice: "Övrig",
    commentProfSuspend: "Lägg till en kommentar för läraren",
    questionLabel: "Fråga",
    choiceLabelBON: "Rätt svar",
    addAnswerButton: "Lägg till ett svar",
    noQuestionTestPreview: "Inga frågor",
    passedTestAverage: "Framgång",
    param: "Miljö",
    presen: "Presentation",
    commentSuspendInputProf: "Kommentar...",
    motifSuspendProf: "Välj ett mönster",
    affectOtherPRof: "Välj en lärare att tilldela lektioner till",
    ReintegrateProf: "Återintegrera",
    ReintegrateProfText: "Den här läraren kommer att få tillgång till lektionerna. Du har möjlighet att stänga av läraren om du ändrar dig.",
    AcceptProf: "Acceptera",
    aArchiver: "Ska arkiveras",
    aDesactiver: "För att avaktivera",
    aActiver: "Att aktivera",
    desactiverCourse: "BEKRÄFTA AVAKTIVERING",
    refuseDesactivationCourse: "VÄGRA AVAKTIVERING",
    activerCours: "VALIDERA AKTIVERING",
    refuserLactivationCours: "AVVISA AKTIVERING",
    archiverCours: "VALIDERA ARKIVERING",
    refuserArchivationCours: "VÄGRA ARKIVERING",
    settingEcole: "Skolinställningar",
    nomEcole: "Namn på skolan",
    logoEcole: "skolans logotyp",
    persenCourte: "Kort presentation (denna text kommer att visas på kurssidorna)",
    listCandidates: "Registreringsförfrågningar",
    listFollowing: "Utbildningskurser följde",
    listeCertificated: "Genomförda utbildningar",
    sendDate: "Skickar begäran",
    importVideoUpload: "Video nedladdning",
    waitingUpload: "Vänta...",
    uploadFinishing: "Videon kommer att finnas tillgänglig inom kort",
    uploadPending: "Bearbetas...",
    candidatures: "Ansökningar",
    certifcations: "CERTIFIERINGAR",
    dashboardAllStudents: "Studerande",
    certifiedDashStudents: "Utexaminerad",
    YesWord: "Ja",
    NoWord: "Ej",
    obtentionPeriod: "Erhållande",
    In: "I",
    TimePassed: "Ja",
    noStudentFound: "Inga nuvarande studenter",
    noCandidateFound: "Inga ansökningar väntande",
    noSuspendedFound: "Ingen elev är avstängd",
    noCertifedFound: "Inga certifierade eller doktorander",
    textStudentSuspend: "Den här studenten kommer inte längre att kunna komma åt kurserna. Du har möjlighet att återställa den om du ändrar dig.",
    commentStudentSuspend: "Lägg till en kommentar för studenten",
    successSuspendStudent: "Studenten har inte längre tillgång till kurserna.",
    ReintegrateStudentText: "Den här studenten kommer att få tillgång till kurserna.",
    ReintegrateStudentText_2: "Du har möjlighet att utesluta studenten om du ändrar dig.",
    successReinstateStudent: "Eleven har nu tillgång till kurserna.",
    successSuspendProf: "Professor framgångsrikt avstängd",
    successSuspendAssitant: "Guiden har stängts av",
    successReinstateProf: "Professor återintegrerad framgångsrikt",
    successReinstateAssistant: "Lyckad återintegreringsguide",
    returnToProfProfile: "Tillbaka TILL PROFIL",
    diplomeButton: "Diplom",
    validatedDiploma: "DIPLOM",
    Declcatalog: "UPPTÄCK KATALOGEN",
    deactivateCoursText: "Denna kurs kommer inte längre att vara tillgänglig, du har möjlighet att återaktivera den om du ändrar dig.",
    modalDeactivateCoursTitle: "Avaktivering av kursen",
    deactivateCours: "Inaktivera",
    successdeactivateCours: "Har slutförts framgångsrikt.",
    createBy: "Getts av",
    maleGender: "M.",
    femaleGender: "Mamma",
    modalReactivateCoursTitle: "Återaktivering av kursen",
    modalReactivatePathTitle: "Återaktivering av kursen",
    modalReactivateSessionTitle: "Återaktivering av session",
    reactivateCours: "Återaktivera",
    reactivateCoursText: "Denna kurs kommer att vara tillgänglig.",
    reactivateCoursText_2: "Du har möjlighet att stänga av den om du ändrar dig.",
    reactivatePathText: "Denna kurs kommer att vara tillgänglig.",
    reactivatePathText_2: "Du har möjlighet att stänga av den om du ändrar dig.",
    reactivateSessionText: "Denna session kommer att vara tillgänglig.",
    reactivateSessionText_2: "Du har möjlighet att stänga av den om du ändrar dig.",
    champsObligatoires: "(*) Obligatoriska fält",
    continueCreatingCourse: "Du kan tyvärr inte publicera den här kursen.",
    continueBUttonText: "FORTSÄTTA",
    errorChaptersLength: "Du måste ha minst ett kapitel (obligatoriskt).",
    linkImgCourseError: "Bild för att illustrera kursen (obligatorisk).",
    descriptionCourseError: "Textpresentation av kursen (obligatorisk).",
    congratulation: "Grattis till studenten!",
    diplomaMsg : "För att kunna ladda ner ditt diplom, se allt innehåll i kapitlen (videor ingår)",
    onlineCOurses: "Onlinekurs",
    validatedCourses: "Kurs att validera",
    AllCoursesDirector: "Alla kurser",
    BonneRéponses: "Bra svar",
    Question: "Fråga",
    ReturnExam: "ÅTERUPPTA MITT NUVARANDE TEST",
    textExa: "Välj ett eller flera svar beroende på instruktionen. Testet utförs endast en gång.",
    StartExam: "Börja",
    Réponse: "Svar",
    QCU: "Endast 1 rätt svar möjligt",
    QCM: "Många möjliga svar",
    validCourse: "VALIDERA PUBLIKATIONEN",
    refuseInscriptionStudent: "BEGÄRAN AVVISAD",
    BonneReponses: "Bra svar",
    placeholderTextArea: "Skriv in text här...",
    languageIT: "Italien",
    languageES: "spanska",
    languageDE: "tysk",
    languageNL: "holländska",
    languageSV: "svenska",
    obligatoireFormCourse: "obligatorisk",
    Registered: "Registrerad",
    New: "Ny",
    Suspended: "Upphängd",
    CatalogueVisitorsInTheLast30Days: "Katalogisera besökare under de senaste 30 dagarna.",
    Course: "Kurs",
    Candidates: "Kandidater",
    Teachers: "Lärare",
    ConversionRateOfApplicationsInTheLast30Days: "Omvandlingsfrekvens för besök under de senaste 30 dagarna.",
    MedianCourseNotes: "Medianresultatet för slutprovet",
    AverageAgeOfStudents: "Medelålder för elever",
    Male: "Män",
    Female: "Kvinnor",
    MostRepresentedCities: "Mest representerade städer",
    VisitsAndApplicationsForRegistration: "BESÖK & REGISTRERING",
    RegistrationsAndCertifications: "REGISTRERINGAR OCH GRADER",
    ConnectedStudents: "Uppkopplade studenter",
    LevelOfStudy: "nivå av studier",
    numberOfVisits: "Översiktssida besök",
    Applications: "Registreringsförfrågningar",
    Registration: "Antal registreringar",
    StudentsNumber: "Antal elever anslutna",
    Jan: "Jan",
    Fev: "feb",
    Mar: "Mar",
    Avr: "apr",
    May: "Maj",
    Jui: "juni",
    Jul: "Jui",
    Aug: "Mig",
    Sep: "sep",
    Oct: "okt",
    Nov: "nov",
    Dec: "dec",
    Access: "Få tillgång till",
    Year: "år",
    StudentsConnectedThisMonth: "Loggat in de senaste 30 dagarna.",
    AverageTimeSpentOnThePlatformForGraduation: "Genomsnittlig tid på plattformen för examen",
    AverageTimeBetweenRegistrationAndGraduation: "Genomsnittlig tid från inskrivning till examen",
    certification: "Certifiering",
    waitingCertif: "Laddar ditt diplom, vänta...",
    ListEtds: "Studentlista",
    listcand: "Lista över kandidater",
    listdipv: "Lista över akademiker",
    listInscribed: "Lista över registrerade",
    listSuspended: "Lista över avstängda",
    Days: "dagar",
    noVideoToShow: "Ingen video.",
    donnerAvis: "Det är viktigt !",
    donnerAvis_2: "Ge din åsikt om kursen",
    avisTitle: "Titel på din recension",
    avisText: "Skriv din recension",
    votreAvis: "Din åsikt här",
    publishMyReview: "Publicera",
    monAvis: "Min åsikt om kursen",
    avis: "Lägga märke till",
    Anonymous: "Anonym",
    noFeedback: "Inga recensioner (för nu)",
    Finance: "Finansiera",
    PublishedAt: "publicerad på",
    PublishedAte: "publicerad på",
    ActualPrice: "Periodens sista pris",
    MoyPrix: "Genomsnittspris",
    discussionButton: "Diskussioner",
    questionButton: "Frågor till läraren",
    addCommentButton: "Lägg till en kommentar",
    addCommentForChapter: "Lägg till en kommentar till kapitlet",
    addcommentForCourse: "Lägg till en kommentar till kursen",
    addcommentForPath: "Lägg till en kommentar till rutten",
    addCommentForSession: "Lägg till en kommentar till sessionen",
    commentTitle: "Kommentarstitel",
    yourComment: "Skriv din kommentar",
    votreCommentaie: "Din kommentar här...",
    addQuestionButton: "Lägg till fråga",
    addCommentSuccess: "Din kommentar har lagts till",
    responses: "Svar",
    noCommentFound: "Inga kommentarer (för nu)",
    repondre: "Svar",
    labeldeletecompte: "All din data kommer att raderas permanent",
    listdesProf: "Lista över lärare",
    noCoursePayedDefault: "Ingen betald kurs.",
    noCoursePayed_1: "Inga betalda kurser hittades.",
    noCoursePayed_2: "Sök igen.",
    Courspayed: "Betalda kurser",
    CoursFree: "Gratis lektioner",
    paie: "Lista över betalningar",
    prunp: "Ange en period",
    Année: "År",
    community: "gemenskap",
    thematic: "Välj ett tema",
    comment: "Kommentar",
    NumberOfCertifications: "Antal certifikat",
    inscriptionTypePayment: "Typ d'inscription",
    registrationFees: "Registrerings avgift",
    gratuit: "Fri",
    payant: "betalande",
    addQuestionForChapter: "Ställ en fråga till läraren",
    yourQuestion: "Skriv din fråga",
    addQuestionSuccess: "Din fråga har lagts till",
    noQuestionFound: "Inga frågor att ta itu med",
    noQuestionFound_4: "Inga frågor besvarade",
    noQuestionFound_5: "Du har inga frågor.",
    paymentTitle: "Betalning",
    paymentTitlePlural: "Betalningar",
    paymentMethodCourses: "Kursens betalningsmetod",
    choiceCurerncy: "Välj valuta",
    Questions: "Frågor",
    unsettledQuestion: "Frågor att ta itu med",
    settledQuestion: "Frågor som tas upp",
    allQuestion: "Alla frågor",
    recent: "Den senaste",
    oldest: "Den minst nya",
    maxResponses: "Med så många svar som möjligt",
    allSubjects: "Alla kurser",
    allSubjects_2: "Alla ämnen",
    noQuestionFound_2: "Din sökning matchade inga frågor.",
    noQuestionFound_3: "Sök igen.",
    noCommentFound_2: "Din sökning matchade inga kommentarer",
    noPaymentInfo: "Du måste välja din skolas betalningssätt",
    NoPaymentSchoolConfig_1: "För att ställa in betalningsmetod.",
    NoPaymentSchoolConfig_2: "Klicka här",
    newResponses: "Nytt svar",
    SortsMostRecent: "Senaste",
    SortOldest: "Den äldsta",
    allStatus: "Alla statusar",
    WAITING: "Väntar på validering",
    SOON: "Att komma",
    ONGOING: "Pågående",
    ENDED: "Slutade",
    REFUSED: "Vägra",
    allCourses: "Alla kurser",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "Inga webbseminarier har skapats ännu. Skapa ditt första webbseminarium för att komma igång.",
    ForStudentsOf: "För utbildningsstudenter",
    WebinarGivenBy: "Webinar ges av",
    ENTERTHEWebinar: "GÅ MED I webbinariet",
    StartTHEWebinar: "Starta webbinariet",
    AddToMyAgenda: "Lägg till i min kalender",
    validate: "Att validera",
    inMyAgenda: "i min dagbok",
    Edit: "Modifierare",
    REVIEWTHEwebinar: "GRANSKA webbinariet",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "Jag skulle vilja att webbinariet ska vara tillgängligt i repris för den berörda allmänheten",
    NewWebinar: "Nytt webbseminarium",
    close: "STÄNGA",
    Webinar: "Webbseminarier",
    WebinarTitle: "Titre du webinar",
    WebinarDate: "Date du webinar",
    WebinarSchedule: "Webinarschema",
    ShortDescriptionOfTheWebinar: "Kort beskrivning av webbinariet",
    Cancel: "att avbryta",
    WaitingForValidation: "Väntar på validering",
    ComingSoon: "Inom kort",
    LetsGo: "Nu går vi",
    ToReview: "Att recensera",
    inscriptionCourseFaq: "Anmäl dig till en kurs",
    followCourseFaq: "Gå en kurs",
    certificationFaq: "Examen",
    professorsFaq: "Professorer",
    stopCourseFaq: "Stoppa en lektion",
    paymentFaq: "Fakturering",
    PAYED: "Betalt",
    PRIX: "Pris",
    openQuestion: "Öppen fråga",
    Consignes: "Instruktioner",
    Valeurdelaquestion: "Frågevärde",
    point: "punkt",
    Nouveau_test: "Nytt test",
    TEST_DE_CONNAISSANCE: "KUNSKAPSPROV",
    Test_intermédiaire: "Mellanliggande bedömningsprov",
    Non_éliminatoire: "Inte eliminerande",
    Question_fermées: "Stängda frågor",
    Test_de_fin_de_cours: "Slut på kursprov",
    Validant_le_diplome: "Validerar diplomet",
    Question_fermée_et_ou_ouvertes: "Stängda och/eller öppna frågor",
    modal_exam_title: "Vilken typ av test vill du skapa?",
    AJOUTER_EXPLICATION: "LÄGG TILL EN FÖRKLARING",
    Explication: "Förklaring",
    add: "Tillägga",
    Créer_votre_premiere_question: "Skapa din första fråga",
    Examen_final_envoyé_le: "Slutprov skickas vidare",
    En_attende_de_correction_par_le_professeur: "Väntar på rättelse av läraren",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Svara på stängda eller öppna frågor genom att följa instruktionerna.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "Testet utförs endast en gång och",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "avgör din examen.",
    Réuissi: "Framgångsrik",
    insuffisant: "otillräcklig",
    Valider_ma_réponse: "Bekräfta mitt svar",
    Rendu_le: "Levererad på",
    Par: "handla om",
    NOTER_LA_QUESTION: "OBS FRÅGA",
    Examens_à_noter: "Prov för att göra poäng",
    Examen_notés: "Betygsatta tentor",
    Tous_les_examens: "Alla tentor",
    aucun_examen_à_noter: "Inget prov att betygsätta",
    NOTE: "NOTERA",
    à_noter: "att notera",
    Commentaire_du_professeur: "Lärarens kommentar",
    Pas_de_commentaire: "Ingen kommentar",
    Ajouter_un_commentaire: "Lägg till en kommentar",
    VALIDER_MA_NOTATION: "VALIDERA MIN BETYG",
    EXAMEN_FINAL: "SLUTPROV",
    exam1: "Ange här titeln på slutprovet ...",
    exam2: "Ange titeln på kunskapsprovet här",
    votreMessage: "Skriv ditt meddelande...",
    noMessage: "Inget meddelande",
    noMessageDispo: "inte tillgänglig än...",
    message_1: "Du måste vänta på läraren",
    message_2: "öppna konversationen",
    message_1_prof: "Starta en konversation genom att skriva",
    message_2_prof: "ditt meddelande nedan.",
    textFaq: "Vad händer om jag vill veta mer om betygsättningen av min skriftliga uppgift?",
    draftstatdateFollow: "Fallet började",
    finishdraftFollowButton: "Avsluta min fil",
    gotocourseStudent: "Gå till kursen",
    myFormations: "Mina träningar",
    SelectCourse: "Välj kurs",
    NoWebinarHasBeenCreatedYet: "Inga webbseminarier har skapats ännu.",
    TitleFormations: "Formationer",
    InscriptionDate: "Registrera dig på",
    biography: "Biografi",
    labelMailProfessor: "Lärarens mejl",
    comments: "Kommentarer",
    noStudentInscribed: "Ingen elev är registrerad ännu",
    demandTable: "Förfrågan gjord den",
    createNewFormation: "Ny formation",
    selectFormatFormation: "Välj en storlek",
    pathTitle: "Resa",
    createPath: "skapa en rutt",
    sessionTitle: "Session",
    createSession: "skapa en session",
    requiredLevelText: "Nivå som krävs för att anmäla dig till kursen",
    anyLevelCourse: "alla nivåer",
    firstLevel: "Nybörjare",
    secondLevel: "Mellanliggande",
    thirdLevel: "Expert-",
    ANYLEVEL: "alla nivåer",
    BEGINNER: "Nybörjare",
    courseDescriptionForm: "Beskriv din kurs",
    descriptionTitle: "Beskrivning",
    courseContentTitle: "Kursinnehåll",
    pathContentTitle: "Kursinnehåll",
    sessionContentTitle: "Sessionens innehåll",
    importVideoCourse: "Videoimport",
    createContentText: "Skapa ditt första innehåll",
    chapterPresentation: "Presentation av kapitlet",
    textPresentationChapter: "Kapitlets inledande text",
    groupes: "Grupper",
    newGroup: "Ny grupp",
    groupTitle: "Studentgruppens titel",
    newTestModalTitle: "Nytt test",
    alreadyCreateExam: "Du har redan skapat ett slutprov",
    finalExmBreadcrunbTitle: "Slutprov",
    noGroupFound: "Ingen studentgrupp skapad",
    noGroupFound_2: "Din sökning matchar inte någon grupp",
    updateGroupTitle: "Redigera titel",
    deleteGroup: "Ta bort grupp",
    updateGroup: "Ändra grupptitel",
    addSubGroup: "Lägg till en undergrupp",
    addStudentToGroup: "Lägg till elever",
    Actions: "Åtgärder",
    textConnaissance: "Kunskapstest",
    points: "poäng",
    connectedThereIs: "loggade in sedan",
    messageDeleteGroup_1: "Denna grupp kommer att tas bort permanent.",
    messageDeleteGroup_2: "Du har inte möjlighet att återställa den här gruppen om du ändrar dig",
    messageDeleteGroup_3: "Gruppen har tagits bort",
    closedQuestion: "Stängda frågor",
    pointsQuestion: "punkt(er)",
    explicationText: "Skriv din förklaring...",
    allStudent: "Alla elever",
    subGroup: "undergrupp",
    changeGroup: "Byt grupp",
    addToGroup: "Lägg till i grupp",
    deleteFromGroup: "Ta bort från grupp",
    documentTextForm: "Dokumentera",
    returnToCourse: "tillbaka till klassen",
    returnToPathButton: "återgå till kursen",
    returnToSessionButton: "tillbaka till sessionen",
    sendPublishCoursePRofTitle: "Skicka till regissören för att validera publikationen",
    returnToStudentList: "Tillbaka till studentlistan",
    Previsualiser: "Förhandsvisning",
    editeMode: "Redigera",
    withInscription: "Anmälan under fil",
    freeTitleCourse: "erbjuds",
    questionPlaceholder: "Din fråga här...",
    responsePlceholder: "Skriv ett svar här..",
    explicationPlaceholder: "Skriv en förklaring här...",
    consignesPlaceholder: "Dina instruktioner här...",
    goToCatalogueError: "För att gå tillbaka till föregående sida klicka på knappen nedan.",
    errorCode: "Felkod",
    oupsWord: "Hoppsan!",
    errorInfoNotFound: "Tyvärr, sidan du letar efter kan inte hittas.",
    errorInfoUnauthorized: "Inga behörigheter hittades.",
    errorSubinfoUnauthorized: "Denna sida är inte tillgänglig för allmänheten.",
    errorInfoAccessRefused: "Tillträde beviljas ej.",
    errorSubInfoAccessRefused: "Du har inte behörighet till den här sidan.",
    serverProblem: "serverproblem",
    tryLater: "Försök om några minuter.",
    unexpectedError: "Tyvärr, oväntat fel.",
    errorInfoServerProblem: "Sidan du letar efter är för närvarande under underhåll och kommer snart tillbaka.",
    continueCreatingPath: "Du kan tyvärr inte publicera den här rutten.",
    continueCreatingSession: "Du kan tyvärr inte publicera denna session.",
    returnToPath: "återgå till kursen",
    returnToSession: "tillbaka till sessionen",
    courseDesactivationSuccess: "Kursen har inaktiverats",
    pathDesactivationSuccess: "Kursen har inaktiverats",
    sessionDesactivationSuccess: "Sessionen har inaktiverats",
    textDeactivateCourse: "Inaktivera kurs",
    textDeactivatePath: "Inaktivera kurs",
    textDeactivateSession: "Inaktivera session",
    textreactiovationCourse: "Kursen har aktiverats",
    textreactiovationPath: "Rutten har aktiverats",
    textreactiovationSession: "Sessionen har aktiverats",
    text_1_desactivateCourse: "Den här kursen kommer inte längre att vara tillgänglig.",
    text_2_desactivateCourse: "Du har möjlighet att återaktivera kursen om du ändrar dig",
    text_1_desactivatePath: "Den här kursen kommer inte längre att vara tillgänglig.",
    text_2_desactivatePath: "Du har möjlighet att återaktivera kursen om du ändrar dig",
    text_1_desactivateSession: "Denna session kommer inte längre att vara tillgänglig.",
    text_2_desactivateSession: "Du har möjlighet att återaktivera sessionen om du ändrar dig",
    refuseCourseText: "Vägra publicering av kursen",
    refusePathText: "Vägra publicering av kursen",
    refuseSessionText: "Neka sessionspublicering",
    backToListCourses: "tillbaka till kurslistan",
    textRefusedCourseSuccess: "Kursen avvisades",
    textRefusedPathSuccess: "Sökvägen avvisades",
    textRefusedSessionSuccess: "Sessionen avvisades",
    acceptCourseText: "Validera publiceringen av kursen",
    acceptPathText: "Validera publiceringen av kursen",
    acceptSessionText: "Validera publiceringen av sessionen",
    textAcceptCourse: "Denna kurs kommer att vara tillgänglig för alla studenter.",
    textAcceptPath: "Denna kurs kommer att vara tillgänglig för alla studenter.",
    textAcceptSession: "Denna session kommer att vara tillgänglig för alla studenter.",
    publishCourseSuccessModal: "Kursen har publicerats framgångsrikt",
    publishPathSuccessModal: "Kursen har publicerats framgångsrikt",
    publishSessionSuccessModal: "Sessionen har publicerats",
    errorContentLength: "Du måste ha minst ett innehåll (obligatoriskt).",
    errorPathLinkImg: "Visuellt för att illustrera kursen (obligatoriskt).",
    errorSessionLinkImg: "Visuellt för att illustrera sessionen (obligatoriskt).",
    errorPathDescription: "Textpresentation av kursen (obligatorisk).",
    errorSessionDescription: "Textpresentation av sessionen (obligatorisk).",
    typeTable: "typ",
    parcoursTitle: "Resa",
    PATH: "Resa",
    progress: "befordran",
    gotopathStudent: "Gå till kursen",
    gotosessionStudent: "Gå till sessionen",
    newContent: "nytt innehåll",
    chapterTitleBegin: "Kapiteltitel",
    testTitleBegin: "Kunskapstesttitel",
    examTitleBegin: "Tentamens titel",
    onlineFormations: "Online utbildning",
    validateFormations: "Utbildning ska valideras",
    allFormations: "Alla kurser",
    inscribedTimeStudent: "registrerad på",
    suspendedAt: "Avstängd på",
    newFinalExam: "Lägg till ett slutprov",
    backToMyrequest: "Tillbaka till min förfrågan",
    beforeUploadText: "Dra och släpp filerna du vill dela",
    uploadFile: "VÄLJ EN FIL",
    Subscription: "Prenumerationsförfrågan",
    waiting: "I väntan på",
    fileValidated: "Filen validerad",
    fileRefused: "Filen avvisades",
    autorisationTraining: "Behörigheter",
    autorisationTrainingTable: "Tillstånd",
    autorzedStatus: "Auktoriserad",
    unauthorized: "Obehörig",
    groupNameTable: "Namn på gruppen",
    changeMyDecision: "Ändra mitt beslut",
    backtodemand: "tillbaka på begäran",
    cancelValidation: "Avbryt validering",
    downloadFileCertif: "Ladda ner mitt diplom",
    nextChapterText: "Nästa kapitel",
    getMyDiplome: "Mitt diplom",
    ListAllCourses: "Lista alla kurser",
    ListOnlineCourses: "Lista över onlinekurser",
    ListPendingCourses: "Lista över pågående kurser",
    ListStudents: "Lista över elever",
    courseBy: "Kurs ges av",
    SessionGivenBy: "Session ges av",
    PathGivenBy: "Kurs ges av",
    PaymentDetails: "betalningsinformation",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Slutför ditt köp genom att ange din betalningsinformation.",
    LastNameandFirstName: "Fullständiga namn",
    CreditCardNumber: "kreditkortsnummer",
    ExpirationDate: "Datum d'utgångs",
    confirmandpay: "bekräfta och betala",
    Yourpaymentwassuccessful: "Din betalning har genomförts.",
    StartThePath: "börja resan",
    Yourpaymenthasbeendeclined: "Din betalning har avvisats",
    StartTheCourse: "starta kursen",
    StartTheSession: "starta sessionen",
    selectLevelCourse_4: "Alla nivåer",
    progressTable: "Befordran",
    notationLabel: "Notation",
    singleClosedQuestion: "Frågan stängd",
    Du: "Från",
    Au: "På",
    Examens: "Tentor",
    fromWhereTraining: "Kommer från",
    congratsTraining: "Grattis, du har slutfört kursen.",
    donnerAvis_3: "Ge din åsikt om kursen:",
    donnerAvis_4: "Ge din åsikt om sessionen:",
    donnerAvisButton: "Ge din åsikt",
    InitialPathName: "Kurstitel",
    PathDecriptionCreation: "Beskriv din bakgrund",
    prerequisite1: "Förutsättningar för att följa denna kurs",
    skillsNeeded: "Kunskaper och färdigheter som förvärvats genom denna kurs",
    pathCoverPhot: "Dra och släpp ett foto för att illustrera kursen (jpg, png)",
    pathVideoDescription: "Videopresentation av kursen",
    pathVideoDrop: "Dra och släpp kursvideon du vill ladda upp (mp4, mov)",
    PathLevel: "Nivå krävs för att anmäla sig till kursen",
    SessionTitleCreation: "Sessionens titel",
    SessionLevel: "Nivå krävs för att registrera dig för sessionen",
    SessionDescriptionCreation: "Beskriv din session",
    prerequisite2: "Förutsättningar för att följa denna session",
    SessionSkills: "Kunskaper och färdigheter som förvärvats under denna session",
    SessionCoverDescription: "Visual för att illustrera sessionen",
    SessionCoverDrop: "Dra och släpp ett foto för att illustrera sessionen (jpg, png)",
    SessionDescriptionVideo: "Videopresentation av sessionen",
    DragDropSession: "Dra och släpp sessionsvideon du vill ladda upp (mp4, mov)",
    saveSchoolLogo: "Lägg till en bild",
    descriptionChoiseCours: "Skapa din kurs med dess kapitel, prov och slutprov. Konfigurera din antagningsfil",
    descriptionChoisePath: "Sätt ihop dina kurser och banor och skapa en utbildning med godkända förutsättningar",
    descriptionChoiseSession: "Montera din träning med passagevillkoren inställda i tid",
    returnButton: "flip",
    waitingToPayment: "Väntar på betalning",
    noUnpaidFound: "Ingen förväntan på betalning",
    schoolAssistant: "administratör",
    passwordMandadory1: "Lösenordet måste innehålla",
    passwordMandadory2: "Minst 8 tecken",
    passwordMandadory3: "Minst en stor bokstav och en liten bokstav",
    passwordMandadory4: "Minst 1 siffra",
    passwordMandadory5: "Minst ett specialtecken (#?!=@$%^&*-)",
    passConfirmation: "Lösenordsbekräftelse",
    RésultatCumulé: "Kumulativt resultat",
    etudiantsInscrits: "Registrerade studenter",
    TarifActuel: "Nuvarande pris",
    TarifMoyen: "Genomsnittspris",
    priceHistory: "Betygsätt historik",
    hidePricehistory: "Dölj prishistorik",
    saveModification: "Ändringen har sparats!",
    Total: "Total",
    TotalInscrits: "Totalt registrerade",
    periodMedian: "Genomsnittspris för perioden",
    totalMedian: "Totalt genomsnittspris",
    totalResult: "Totalt kryphål",
    QUESTIONS_OUVERTES: "ÖPPNA FRÅGOR",
    notValid: "Inte giltig",
    validAction: "Validerad åtgärd",
    studentResponse: "Elevens svar",
    markUpdate: "modifierare ma notation",
    webinarEnded: "Webinariet avslutat",
    added: "Lagt till",
    warning: "Uppmärksamhet!",
    changePrice: "Du har precis ändrat kurspriset:",
    priceNow: "Nuvarande pris:",
    priceAfter: "Nytt påverkat pris:",
    saveUpdate: "BEHÅLL VÄXEL",
    profchoice: "Välj en lärare att tilldela kursen/kurserna",
    deleteReason: "Orsak till radering*",
    disableAssistant: "Denna administratör kommer inte längre att kunna komma åt plattformen. Du har möjlighet att återställa den om du ändrar dig.",
    addCommentToAssistant: "Lägg till en kommentar för guiden",
    connectAccountPaymee: "Anslut ditt Paymee-konto",
    connect: "ANSLUTA",
    idPaymee: "ID-betalningsmottagare",
    assistantInvitation: "Bjud in en administratör att registrera sig",
    mailAssistant: "Assistentens e-post",
    membreInvitation: "Bjud in en medlem",
    role: "Roll",
    newCourse: "Ny kurs",
    newPath: "Ny rutt",
    newSession: "Ny session",
    profMandadotory: "Denna kurs måste tilldelas en lärare.",
    profMandadtory2: "Välj en lärare att tilldela denna kurs till*",
    rehireAssistant: "Denna administratör kommer att kunna komma åt plattformen. Du har möjlighet att stänga av assistenten om du ändrar dig.",
    dragToUpload: "Dra och släpp ditt foto",
    or: "ELLER",
    selectPhoto: "välj din bild",
    offerList: "Lista över erbjudanden",
    answer: "Ditt svar",
    completedParts: "delar gjorda",
    followPaths: "Följ Kurs",
    followSessions: "Följ Kurs",
    displayVideo: "Din presentation video eller bild",
    toValidate: "Att validera",
    archiveSheet: "Arkiv",
    connectZoom: "Anslut Zoom",
    accountZoom: "zoomkonto",
    connectAccount: "Anslut mitt konto",
    connectAccountToschool: "Koppla ditt zoomkonto till din skola",
    changeOffer: "Ändra erbjudande",
    facture: "Fakturor",
    paymentMethod: "Betalningsmetoder",
    cancelSubscription: "Avsluta mitt abonnemang",
    offerPrice: "Erbjudande avgifter",
    nextDeadline: "Nästa förfallodag för betalning",
    deleteOffer: "Radering av erbjudande",
    deleteMsgOffer: "Vill du ta bort ditt erbjudande?",
    passwordUpdated: "Ditt lösenord har ändrats",
    passwordNoMatch: "de två lösenorden stämmer inte överens",
    modifyProfil: "Redigera min profil",
    newEmail: "ny Email Adress",
    connectStripToSchool: "Koppla ditt Stripe-konto till din skola",
    disconnect: "Koppla ifrån",
    connectPaymeeToSchool: "Koppla ditt Paymee-konto till din skola",
    paymentMessage: "Slutför ditt köp genom att ange din betalningsinformation.",
    paymentConfimation: "bekräfta betalningen",
    activate: "Gör det möjligt",
    month: "En gång i månaden",
    yearly: "Ettåriga",
    offerMessage1: "Allt som ingår i grundplanen, plus:",
    Choisir: "Välja ut",
    compareoffers: "Jämför alla funktioner",
    offer: "Erbjudande",
    monthlyFor: "Månatlig för",
    yearFor: "Årliga för",
    totalPrice: "Totalbelopp:",
    valid: "Giltig",
    ProfList: "Prof List",
    StudentList: "Studentlista",
    CourseList: "Kurslista",
    Inscrits: "Registrerad",
    team: "Besättning",
    ADMINSTRATOR: "administratör",
    info: "Information",
    shortDescription: "kort beskrivning",
    shortDescription1: "Kort beskrivning av",
    nbrConx: "Antal anslutningar",
    inscDate: "Inskriftsdatum",
    role1: "roll",
    totalRevenu: "Total inkomst",
    makeAPayment: "betala",
    Fileacceptedon: "Filen accepterades den",
    params: "inställningar",
    pricipalGroup: "huvudgrupp",
    addPhoto: "lägg till ditt foto",
    changePhoto: "Ändra ditt foto",
    deletePhoto: "Ta bort bild",
    seletionner: "VÄLJ",
    facturationDetails: "Faktureringsinformation",
    closedSchool: "Denna skola är för närvarande stängd",
    hold: "Snälla du",
    contact1: "Kontakta supporten",
    contact2: "för mer information.",
    question: "fråga",
    allTeam: "Hela laget",
    assistants: "administratörer",
    noTeam1: "Ingen lärare/administratör hittades.",
    noTeam2: "Sök igen.",
    noTeamDefault_1: "Ingen lagmedlem är registrerad.",
    noTeamDefault_2: "Bjud in en medlem att registrera sig för att komma igång.",
    noAssistant_1: "Inga administratörer hittades.",
    noAssistant_2: "Sök igen.",
    noAssistantDefault_1: "Ingen administratör är registrerad.",
    noAssistantDefault_2: "Bjud in en administratör att registrera sig för att komma igång.",
    TrainingToedit: "Pågående",
    webinarForm1: "Välj en lärare att tilldela detta webbseminarium till*",
    webinarForm2: "Välj en lärare",
    webinarForm3: "För kursstudenter:",
    listeDesAssistants: "lista över administratörer",
    listeTeam: "laglista",
    listOfUnpaidStudents: "lista över obetalda studenter",
    Nounpaidstudent: "Ingen obetald student",
    memberMail: "Medlemsmail",
    integrations: "Integrationer",
    monForfait: "Mitt paket",
    Facturation: "Fakturering",
    Detailsduforfait: "Paketdetaljer",
    Gerezoumodifiezvotreforfait: "Hantera eller ändra din plan",
    Votreoffre: "Ditt erbjudande",
    Fraisdeloffre: "Erbjudande avgifter",
    Prochainedatedecheancedupaiement: "Nästa förfallodag för betalning",
    Modedepayement: "Betalningsmetod",
    Changerleforfait: "Ändra plan",
    Annulermonabonnement: "Avsluta mitt abonnemang",
    Limitationduforfait: "Paketbegränsning",
    Suivrelalimitationdevotreforfaitactuel: "Spåra din nuvarande plans strypning",
    Voirlesforfaits: "Se paket",
    Vousavezatteintlalimitedaccesdevos: "Du har nått åtkomstgränsen för din",
    Vouspouvezajouterdes: "Du kan lägga till",
    endesactivantun: "genom att inaktivera en",
    endesactivantune: "genom att inaktivera en",
    actifouenameliorantvotreforfait: "genom att uppgradera din plan",
    activeouenameliorantvotreforfait: "genom att uppgradera din plan",
    Modesdepayement: "Betalningsmetoder",
    Gérezoumodifiezvotremodesdepaiement: "Hantera eller ändra dina betalningsmetoder",
    congratsSession: "Grattis, du har slutfört sessionen.",
    avisTitle1: "Min åsikt om kursen",
    avisTitle2: "Min åsikt om sessionen",
    FormationAValider: "Ingen utbildning att validera.",
    FormationEnLigne: "Ingen onlineutbildning.",
    emptyListFormation1: "Sök igen.",
    noTrainingSearch: "Den här läraren har ingen utbildning än.",
    NoWebinarToValidate: "Inget webinar att validera.",
    NoWebinarIsComingSoon: "Inga kommande webbseminarier.",
    NoWebinarIsOnGoing: "Inga aktuella webbseminarier.",
    NoWebinarHasEnded: "Inga avslutade webbseminarier.",
    NoWebinarIsRefused: "Inga webbseminarier har tackat nej.",
    NoWebinarIsAssociatedToThisCourse: "Inget webinar kopplat till denna kurs.",
    NoWebinarExists: "Inga webbseminarier hittades.",
    FirstWebinar: "Inga webbseminarier har skapats ännu.",
    FirstWebinar2: "Skapa ditt första webbseminarium för att komma igång.",
    transformKnowledge: "Förvandla din kunskap till rikedom",
    addToDescription: "Lägg till beskrivning",
    importListOfStudents: "Importera listan över elever.*",
    Remplacer: "Byta ut",
    returnToStudentsList: "Tillbaka till elevlistan",
    invite: "inbjudan",
    continue: "Fortsätta",
    savedUsers: "Jag har samtycke från de användare som är registrerade i denna fil, i enlighet med gällande lag om personuppgiftsskydd i det land som gäller.",
    download: "Ladda ner",
    loadInformation: "Laddar din information, vänligen vänta...",
    yourAccount: "Från ditt konto",
    emailSend: "Email skickat",
    check: "kolla upp",
    verifyYourEmail: "Kontrollera din e-post och öppna länken du fick för att fortsätta",
    returnToConnexionPage: "tillbaka till inloggningssidan",
    save: "Att registrera",
    coursResume: "Skriv sammanfattningen av denna kurs, presentera dess viktigaste punkter och visa dess mervärde för medlemmen.",
    coursObjectives: "Lista de mål som ska uppnås under denna kurs.",
    preRequisite: "Lägg till nödvändiga förkunskaper för att gå denna kurs.",
    skillsToLearn: "Lista de färdigheter som ska förvärvas under denna kurs i form av nyckelord.",
    pathResume: "Skriv en sammanfattning av den här kursen, presentera dess viktigaste punkter och visa dess mervärde för medlemmen.",
    pathObjectives: "Lista de mål som ska uppnås under denna kurs.",
    pathPreRequisite: "Lägg till nödvändiga förkunskaper för att följa denna kurs.",
    pathSkillsToLearn: "Lista de färdigheter som ska förvärvas under denna kurs i form av nyckelord.",
    sessionResume: "Skriv sammanfattningen av denna session, presentera dess viktigaste punkter och visa dess mervärde för medlemmen.",
    sessionObjectives: "Lista de mål som ska uppnås under denna session.",
    sessionPreRequisite: "Lägg till de nödvändiga förutsättningarna för att följa denna session.",
    sessionSkillsToLearn: "Lista de färdigheter som ska förvärvas under denna session i form av nyckelord.",
    timeNotAvailable: "Detta schema är inte tillgängligt, välj ett annat.",
    annulerImportation: "Avbryt import",
    annulerImportationConfirmation: "Är du säker på att du vill avbryta importen?.",
    retourImportation: "Tillbaka till import",
    failedRequest: "Begäran misslyckades",
    lowerDeal: "För att byta till en lägre plan måste du justera din gräns enligt gränserna för den valda planen.",
    activeProfessors: "Aktiva lärare",
    activeStudents: "Aktiva elever",
    activeAssistants: "Aktiva administratörer",
    professorList: "Lista över lärare",
    trainingList: "Lista över kurser",
    setAsPrincipal: "Ange som huvud?",
    replacePaymentMode: "Detta kommer att ersätta din nuvarande primära betalningsmetod.",
    deletePaymentMethod: "Ta bort betalningsmetod",
    deletePaymentMethodConfirmation: "Är du säker på att du vill ta bort betalningsmetoden.",
    deleteInvitation: "Avbryt inbjudan",
    invitationAnnulationOfYourSchool: "Om du avbryter inbjudan kan den personen inte längre bli medlem i",
    yourSchool: "din skola.",
    youCanReinvite: "Du kan alltid bjuda tillbaka honom.",
    keepInvitation: "behåll inbjudan",
    noCourseFound: "Inga kurser hittades",
    wantCreateCourse: "Vill du skapa en kurs?",
    cannotImportListOfStudents: "Du kan inte importera en elevlista som innehåller fel.",
    firstName: "Förnamn",
    obligatoire: "är obligatoriskt",
    name: "Namnet",
    emailAddressNotValid: "Ogiltig e-postadress.",
    emailFormat: "Formatet på e-postmeddelandet måste vara",
    emailExample: "votrenom@exempel.com",
    importationResult: "Importera resultat",
    succesfulImportation: "framgångsrik import",
    lignes: "rader",
    containErrors: "innehåller fel",
    addStudent: "Lägg till en elev",
    importList: "Importera en lista",
    invitationSentSuccesfully: "Inbjudan har skickats",
    invitationSuccessAssistant: "Din inbjudan har skickats till assistenten.",
    userAlreadyExists: "Användaren med denna e-postadress finns redan.",
    enterValidEmail: "Vänligen ange en giltig e-postadress.",
    professorLimitAtteined: "Du har nått dina lärares åtkomstgräns. Du kan lägga till lärare genom att inaktivera en aktiv lärare eller uppgradera din plan.",
    assistantLimitAtteined: "Du har nått åtkomstgränsen för dina administratörer. Du kan lägga till administratörer genom att inaktivera en aktiv administratör eller uppgradera din plan.",
    compareOffers: "Jämför erbjudanden",
    schoolUses: "Din skola använder funktioner",
    notIncludedInOffer: "som inte stöds av erbjudandet",
    passToOffer: "Om du byter till erbjudandet",
    allFonctionalities: ", alla funktioner",
    areDesactivated: "av din skola kommer att vara inaktiverad och otillgänglig.",
    invitationsSentSuccesfully: "inbjudningar har skickats",
    automaticSendOfInvitations: "Automatisk sändning av inbjudningar",
    invitationSendConfirmation: "Bekräftar du att skicka inbjudningar till listan du importerade?",
    confirmAndSend: "Bekräfta och skicka",
    principal: "Rektor",
    noPaymentMethodAdded: "Ingen betalningsmetod har lagts till",
    addPaymentMethod: "Lägg till en betalningsmetod",
    remainning: "du har fortfarande",
    changePaymentMethod: "Ändra betalningsmetod",
    leaveMsg: "Vi är ledsna att se dig gå!",
    annulationWarning: "När du avslutar kommer ditt konto att förbli aktivt tills din fakturering upphör",
    annulationWarning2: "Efter det kommer du att förlora tillgången till din skola.",
    annulationConfirmation: "Är du säker på att du vill avsluta din prenumeration?",
    enterPasswordtoConfirm: "Ange ditt lösenord för att bekräfta",
    cancelationReasons: "Vänligen meddela oss varför du säger upp din prenumeration. Dina kommentarer, det kommer att hjälpa oss att förbättra produkten",
    whyuCanceling: "Varför säger du upp din prenumeration",
    selectLowerCase: "Välj",
    missingFonctionality: "En funktion jag vill ha saknas",
    expensive: "Jag tycker det är för dyrt",
    notInteressted: "Je n'utilise plus feber",
    movingToAnotherPlatform: "Jag byter till en annan plattform",
    preciser: "Vänligen specificera",
    wantToTellUsMore: "Vill du berätta mer?",
    unlimited: "Obegränsat",
    Cryptogramme: "Kryptogram",
    savePaymentMethod: "Spara denna betalningsmetod i min profil",
    paymentPassed: "Betalning gjord",
    paymentSuccesfull: "Din betalning har behandlats.",
    returnToActivity: "Tillbaka till aktiviteten",
    twoFreeMonths: "2 månader gratis",
    fourFreeMonths: "4 maanden gratis",
    essentialTools: "De viktigaste verktygen för att komma igång, utan dolda kostnader",
    forever: "för all tid",
    actuelOffer: "Ditt nuvarande erbjudande",
    selectFreeOffer: "Välj gratiserbjudandet",
    pathAndSessions: "Rutter och sessioner",
    forProfessionalsAndNonProfessionals: "För professionella tränare och infoprenörer.",
    yearlyBill: "Faktureras årligen",
    perMonth: "per månad",
    selectProOffer: "Välj Pro-erbjudandet",
    courses: "Klasser",
    illimitedAccess: "Att njuta av obegränsat Koors.",
    perStudent: "per elev",
    minimum: "minimum",
    selectBussinessOffer: "Välj Business-erbjudandet",
    illimitedNumberOfStudents: "Obegränsat antal studenter",
    illimitedNumberOfCours: "Obegränsat antal lektioner",
    illimitedNumberOfProfessors: "Obegränsat antal lärare",
    illimitedNumberOfAssistants: "Obegränsat antal administratörer",
    subscriptionPayments: "Prenumerationsbetalning",
    Devise: "Tänka ut",
    FacturationCycle: "Faktureringsperiod",
    monthly: "En gång i månaden",
    yearly_2: "Årlig",
    ofReduction: "minskning",
    minimalBill: "Minsta fakturering",
    nextBill: "Nästa fakturering",
    confirmPayment: "Betalningsbekräftelse",
    reductionOnCurrentPlan: "Sänkning av årsplanen",
    verification: "Verifiering",
    noStudentFound_2: "Inga elever hittades",
    archivedTrainings: "Ingen utbildning arkiverad",
    refusedTrainings: "Ingen utbildning vägrades",
    desactivatedTrainings: "Ingen träning inaktiverad",
    editedTrainings: "Ingen utbildning redigeras",
    noTrainingHaveBeenCreated: "Inga ostar har skapats ännu.",
    createFirstTraining: "Börja med att skapa din första formation.",
    examsToNote: "Inga prov att betygsätta.",
    notedExams: "Inget betygsatt prov.",
    noExam: "Du har inget prov.",
    resendInvitation: "Skicka inbjudan igen",
    Copyright: "upphovsrätt",
    rights: "Alla rättigheter förbehållna.",
    noExamFound: "Inga recensioner hittades.",
    atteinedMaximum: "Du har nått det högsta tillåtna antalet för en plan",
    updatePlan: "Uppdatera din plan för att skapa fler formationer.",
    noBill: "Ingen faktura.",
    finalExam: "Slutprov .",
    selectAnImage: "VÄLJ EN BILD",
    slideToAddAnImage: "Dra och släpp ett foto för att illustrera webbinariet",
    yourWebinarsAreIntegrated: "Dina webbseminarier är integrerade",
    onThePlatform: "till plattformen med",
    offreProAndBussiness: "Professionella och företagserbjudanden",
    createWebinar: "Skapa helt enkelt dina webbseminarier direkt i Koors Select och bjud in dina elever direkt att delta (berättigade nya studenter bjuds in automatiskt).",
    findReplayAndPublishWebinar: "Hitta repriserna av dina webbseminarier och publicera dem i din träning.",
    improveMyOffer: 'Förbättra mitt erbjudande',
    soonWebinarsAvailable: "Snart kommer webbseminarier att erbjudas dig.",
    willInformuSoon: "Vi håller dig informerad.",
    courseResumeIntroduction: "Inledande sammanfattning av kursen...",
    reprendre: "Att återuppta",
    freeOffreNoPaymentMethod: "Du har inga betalningsmetoder. Du är registrerad för gratiserbjudandet.",
    Credit: "kredit",
    DebitCard: "Kontokort",
    offre: "Erbjudande",
    nameOnCard: "Namn på kortet",
    traitement: "behandling...",
    paiement: "Betalning",
    offersList: "Lista över erbjudanden",
    perYear: "per år",
    account: "konto",
    wrongBirthDate: "Fel födelsedatum",
    selectCountry: "Välj ett land",
    cardAddedSuccessfully: "ditt kort har lagts till",
    returnToParameters: "återgå till parametern",
    addNewPaymentMethod: "Lägg till en ny betalningsmetod",
    verifyPassword: "Felaktigt lösenord, vill du verifiera ditt lösenord",
    returnToOffer: "Tillbaka till mitt erbjudande",
    subscriptionCancelled: "Din prenumeration har avslutats",
    perMonthPayedAnually: "månad, betalas årligen",
    downloadTemplate: "ladda ner mall",
    toSee: "excel för att se ett exempelformat.",
    slideExcel: "Dra och släpp din Excel-fil",
    reposition: "REPOSITION",
    importNewPhoto: "IMPORTERA ETT NYTT FOTO",
    importPhoto: "IMPORTERA ETT FOTO",
    slideToSet: "dra till position",
    publishMyOpinion: "Publicera min recension",
    noWebinarIsPlanned: "Inga webinarier är planerade för närvarande",
    perYearPayedAnually: "år, betalas årligen",
    titleSessionPlaceholder: "ange titeln på sessionen här...",
    titlePathPlaceholder: 'ange titeln på kursen här...',
    addVideoUrl: "Lägg till videons URL",
    itegrateVideo: "inbäddad video",
    enterValidUrl: "Se till att du har angett en giltig URL.",
    pasteVideoUrl: "Klistra in videolänk...",
    addVideoToIntegrate: "Lägg till video-url för att bädda in",
    creationDate: "Skapelsedagen",
    createdAt: "Skapade",
    generic_decline: "Betalningen avvisades av generiska skäl",
    insufficient_funds: "Kortmedel är otillräckliga",
    lost_card: "Kortet förklaras förlorat",
    stolen_card: "Kortet är anmält stulet",
    expired_card: "Kortet har gått ut",
    incorrect_cvc: "CVC-koden är felaktig",
    processing_error: "Betalningen avvisades på grund av bearbetningsfel",
    gender: "Kön",
    male: "Man",
    female: "Kvinnor",
    noCommentFound1: "Ingen kommentar",
    lastUpdate: "Senaste inlägget",
    lignes1: "linje",
    containErrors1: "innehåller fel",
    emptyTitleError: "Kurstitel (obligatoriskt)",
    emptyPathTitleError: "Kurstitel (obligatoriskt)",
    emptySessionTitleError: "Sessionstitel (obligatoriskt)",
    Webinar1: "Webbseminarier",
    notFound1: "Voeg meer studenten toe en schakel een versnelling hoger ",
    notFound2: "Koors brengt de beste features samen om online cursussen onder zoveel mogelijk mensen te verspreiden.",
    notFound3: "Dankzij Koors kun je eenvoudig je studenten-/klantengemeenschappen beheren en altijd contact houden.",
    emailAlreadyUsed: "Den här mailadressen är redan i bruk",
    Clôturé: "Stängd",
    Ouvert: "Öppen",
    TITREdusondage: "Undersökningsrubrik",
    Auteur: "Författare",
    CRÉELE: "Skapad den",
    CloturéLE: "Stängd den",
    RÉPONSEs: "Svar",
    Créerunsondage: "Skapa en undersökning",
    sondages: "Undersökningar",
    sondage: "Undersökningar",
    inscribedInSchool:"Registrering i skolan",
    catalogVisits:"Besök på katalogsidan",
    currentStudentNumber:"Antal nuvarande elever",
    currentStudentsWithDiploma:"Antal doktorander",
    noSchooling:"inte nämnt",
    transcodeInProgress: 'Het finaliseren van de import is bezig. U kunt doorgaan met het voltooien van de inhoud.',

}