import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CardOpenQuestionPreviewComponent(props: any) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const openAccordion = () => {
    setOpen(!open);
  }
  return (
    <div className="d-flex flex-column mb-4" style={{backgroundColor: '#FFFFFF'}}>
      <div className="bg-accordion-border p-4" >
        <div className="d-flex align-items-center justify-content-between  accordion-head-open mx-2">
          <div className="d-flex flex-wrap align-items-center justify-content-between w-83 pt-3">
              <div className="d-flex flex-row flex-wrap align-items-center" style={{ marginBottom: 14 }}>
                <span className="H3-Headline me-2 pe-1" ><span className="H3-Headline neutral-3">{t('questionLabel')} </span> {props?.index}</span>
                <span className="body-sm bold"><span className="body-sm bold neutral-3">{t('openQuestion')} / </span> {props?.question?.scaleNote} {t('pointsQuestion')}</span>
              </div>
          </div>
          <div className="d-flex align-items-center flex-no-wrap my-2">
            <button onClick={() => openAccordion()} type="button" className="ms-3 btn-QuickActions">
              {!open ? <>
                <span className="material-icons-outlined">expand_less</span>
              </> :
                <> <span className="material-icons-outlined">expand_more</span></>
              }
            </button>
          </div>
        </div>
      </div>
      {!open && <>
        <div className="bg-accordion-border accordion-body pb-3" style={{backgroundColor: '#FFFFFF'}}>
          <div className="d-flex flex-column mb-4">
                <div className="d-flex flex-column">
                  <span className="form-title mb-2">{t('openQuestion')}</span>
                  <span className="body-md black-800">{props?.question?.question?.questionStatement} </span>
                </div>
                <div className="d-flex flex-column mt-4">
                  <span className="form-title mb-2">{t('Consignes')}</span>
                  <span className="body-md black-800">{props?.question?.question?.questionAnswerExplanation} </span>
                </div>
          </div>
        </div>
      </>}
    </div>
  );
}
