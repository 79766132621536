
import { ComponentProps, FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
import SearchFilterQuestionLoadingComponent from '../filters/searchFilter-question-loading-component';

const ManageQuestionProfessorLoadingComponent : FC <ComponentProps<any>> = () => {
    return(
        <>
            <div className="row justify-content-center mt-5">
                <div className="col-md-12 mb-3">
                    <div style={{maxWidth:'1200px', margin : 'auto'}}  className="row justify-content-between">
                        <div className="col-md-12">
                        <Skeleton className="skeleton square me-3" animation="wave" variant="rect" width={230} height={36} />
                        </div>
                        <div className="col-md-12 mb-5 d-flex justify-content-start text-left mt-4 px-0">
                                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={230} height={36}/>
                                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={230} height={36}/>
                                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={230} height={36}/>
                        </div>
                    </div>
                </div>
                <div style={{maxWidth:'1200px', margin : 'auto'}} className="col-md-12">
                    <div className="row justify-content-between">
                        <SearchFilterQuestionLoadingComponent/>
                     </div>
                </div>
            </div> 
       </>
    )
}
export default ManageQuestionProfessorLoadingComponent;