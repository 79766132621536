import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "../../../../global-state/store/root-reducers";
const moment = require('moment');
interface Props {
    post: any,
    from?: string,
    subjectType: string,
}
const CardHeader: FC<ReturnType<typeof mapStateToProps> & Props> = (props: any) => {
    const { t } = useTranslation();
    const [object, setObject] = useState<any>();
    const dateFormat = require('dateformat');
    const getSubject=(typeInput:string)=>{
        let subjectByTraining= (typeInput==="PATH")?props?.trainingInfo?.subjectsByPath:props?.trainingInfo?.subjectsBySession;
        
        return [{ id: props?.trainingInfo?.id, title: props?.trainingInfo?.title, type: typeInput },
            subjectByTraining?.map((obj: any) => {
            if (obj.course) {
                return [{ id: obj?.course?.id, title: obj?.course?.title, type: "COURSE" },
                obj?.course?.orders?.map((chap: any) => {
                    return { id: chap?.chapter?.id, title: chap?.chapter?.title, type: "CHAPTER" }
                })
                ]
            }
            if (obj?.path) {
                return [{ id: obj?.path?.id, title: obj?.path?.title, type: "PATH" },
                obj?.path?.subjectsByPath?.map((chap: any) => {
                    if (chap?.course) {
                        return [{ id: chap?.course?.id, title: chap?.course?.title, type: "COURSE" },
                        chap?.course?.orders?.map((chap1: any) => {
                            return { id: chap1?.chapter?.id, title: chap1?.chapter?.title, type: "CHAPTER" }
                        })
                        ]
                    }
                })
                ]
            }

        })].flat(Infinity)
    }
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else { moment.defineLocale('en', { parentLocale: 'en' }); }
        
        let subjects = getSubject((props?.subjectType === "path")?"PATH":"SESSION")
        let header = subjects?.find((obj: any) => { return obj?.id === Number(props?.post?.subject?.idSubject) && obj?.type === props?.post?.subject?.category })
        setObject(header)

    }, [])
    const returnTypeFn = (type: string)=>{
        switch(type){
            case 'PATH':
                return t('pathTitle');
            case 'COURSE':
                return t('Cours');
            case 'CHAPTER':
                return t('pathTitle'); 
            default:
                return t('sessionTitle') ;
        }
    }
    const calculateTime=(createdAt:any)=>{
        if(moment(Date()).diff(moment(createdAt), 'days')<1)
            return moment(createdAt).fromNow();
        else 
            return dateFormat(createdAt,"dd/mm/yyyy");
    }
    return (
        <>
            { props?.from === "communityProgress" ?
                <div className="d-flex flex-wrap discuttion-titles  mt-2">
                    <span className="body-sm bold neutral-3 me-3">{calculateTime(props?.post?.createdAt)}</span>
                </div>
                : <div className="d-flex flex-wrap discuttion-titles  mt-2">
                    <span className="body-sm bold me-3"><span className="badge-text secondary me-1">{returnTypeFn(object?.type)}</span>{object?.title}</span>
                    <span className="body-sm bold neutral-3 me-3">{calculateTime(props?.post?.createdAt)}</span>
                </div>}
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    connectedUser: state.authReducer.connectedUser,
    trainingInfo: state.studentTraining.currentTraining,
    sessionInfo: state.courseReducer.sessionInfo,

});

export default connect(mapStateToProps)(CardHeader)