import { EReduxActionTypes,IReduxRegisterProfDirectorAction,IReduxSetConnectedUserAction, IReduxGetTecketsAction,IReduxChangeTokenAction,IReduxGetProfBioAction, IReduxLoginSuccessAction, IReduxChangeRoleAction,IReduxLoginFailsction, IReduxLogoutAction, IReduxRegisterUserAction, IReduxUpdateSchoolAction, IReduxGetConnectedUserAction, IReduxRegisterStudentAction, IReduxUploadImageAction, IReduxInvitePersonAction, IReduxCheckTokenAction, IReduxResetPasswordAction, IReduxCheckPasswordAction, IReduxGetActualTabAction, IReduxGetUserProfileDetailsAction, IReduxCheckPasswordUserAction, IReduxGetCardsAction, IReduxLoginWithCookieAction,IReduxIsPersonInvitedAction, IReduxGetInscribedSchoolsListAction, IReduxResumFirstLoginAction } from '../actions/auth-actions';

export interface IUserInfo {
  id: string;
  idPerson: string,
  idSchool: string,
  role: string,
}
export interface IUser {
  firstName: string,
  lastName: string,
  photo: string
}
export interface ISchool {
  code: string,
  color: string,
  coverPhoto: string,
  centralLogo: string,
  creationDate: string,
  description: string,
  logo: string,
  name: string,
  shortDescription: string
}

export interface ISchoolCat {
  id: string
  code: string,
  color: string,
  coverPhoto: string,
  centralLogo: string,
  creationDate: string,
  description: string,
  logo: string,
  name: string,
  shortDescription: string,
  enabled: string
}
export interface IReduxAuthState {
  connectedUser?: IUserInfo;
  connectedUserInfo?: IUser;
  connectedSchoolInfo?: ISchool,
  schoolInfo?: ISchoolCat,
  loggedIn?: boolean;
  registerProfDirector: any,
  registerStudent: any,
  imageUploaded: any,
  invitedPerson: any,
  isPersonInvited:any,
  tokenStatus: any,
  newPassword: any,
  password: any,
  error: any,
  profData?: any,
  actualTab:any,
  userProfile: any,
  loadingProfile: boolean,
  passwordChecked:any,
  cards:any,
  listOfInscribedSchools:any,
  isFirstLogin: boolean
  loadingRegisterStudent: boolean | null;
}


export const initialState: IReduxAuthState = {
  userProfile: undefined,
  loadingRegisterStudent: null,
  loadingProfile: true,
  passwordChecked:undefined,
  cards:undefined,
  connectedUser: undefined,
  connectedUserInfo: undefined,
  connectedSchoolInfo: undefined,
  schoolInfo: undefined,
  loggedIn: false,
  registerProfDirector: undefined,
  registerStudent: undefined,
  imageUploaded: undefined,
  invitedPerson: undefined,
  isPersonInvited:undefined,
  tokenStatus: undefined,
  newPassword: undefined,
  password: undefined,
  error: undefined,
  profData: undefined,
  actualTab:"Integration",
  listOfInscribedSchools:undefined,
  isFirstLogin: false
};





type TAuthReducerActions = IReduxResumFirstLoginAction | IReduxGetActualTabAction|IReduxLoginSuccessAction | IReduxSetConnectedUserAction | IReduxGetTecketsAction |
 IReduxChangeRoleAction|IReduxChangeTokenAction|IReduxGetProfBioAction | IReduxCheckPasswordAction | IReduxResetPasswordAction | IReduxCheckTokenAction |
  IReduxInvitePersonAction | IReduxUploadImageAction | IReduxRegisterStudentAction | IReduxRegisterProfDirectorAction | IReduxLoginFailsction |
   IReduxLogoutAction | IReduxRegisterUserAction | IReduxGetConnectedUserAction | IReduxUpdateSchoolAction|
   IReduxGetUserProfileDetailsAction|IReduxCheckPasswordUserAction|IReduxGetCardsAction | IReduxIsPersonInvitedAction| IReduxLoginWithCookieAction |IReduxGetInscribedSchoolsListAction;

export default function (state: IReduxAuthState = initialState, action: TAuthReducerActions) {

  switch (action.type) {
    case EReduxActionTypes.INVALIDE_TOKEN:
      return { ...state, error: action.data };
    case EReduxActionTypes.GET_PROF_BIO:
      return { ...state, profData: action.data };
    case EReduxActionTypes.LOGIN_SUCCESS:

      localStorage.setItem("is_logged", "true");
      localStorage.setItem("access_token", JSON.stringify(action.data.access_token));
      localStorage.setItem("refresh_token", JSON.stringify(action.data.refresh_token));
      localStorage.setItem("expires_in", JSON.stringify(action.data.expires_in))
      localStorage.setItem("lastConnexion", JSON.stringify(action.data.lastConnexion))
      return { ...state, connectedUser: action.data.user, connectedUserInfo: action.data.userInfo, connectedSchoolInfo: action.data.schoolInfo, loggedIn: true }
    case EReduxActionTypes.CHANGE_TOKEN:
      const offer_token: any = localStorage.getItem("access_limitation");
      localStorage.clear();
      localStorage.setItem("access_limitation", offer_token);
      localStorage.setItem("is_logged", "true");
      localStorage.setItem("access_token", JSON.stringify(action.data.access_token));
      localStorage.setItem("refresh_token", JSON.stringify(action.data.refresh_token));
      localStorage.setItem("expires_in", JSON.stringify(action.data.expires_in))
      localStorage.setItem("lastConnexion", JSON.stringify(action.data.lastConnexion))
      return { ...state, loggedIn: true }

    case EReduxActionTypes.GET_TICKETS:
      localStorage.setItem("access_tickets", JSON.stringify(action.data));
      return { ...state, loggedIn: true }

    case EReduxActionTypes.CHANGE_ROLE:
      return { ...state, connectedUser: action.data, loggedIn: true }

    case EReduxActionTypes.LOGIN_FAIL:
      return { ...state, loggedIn: false };
    case EReduxActionTypes.REGISTER_PROF_DIRECTOR:
      return { ...state, registerProfDirector: action.data };
    case EReduxActionTypes.CHECK_TOKEN:
      return { ...state, tokenStatus: action.data };
    case EReduxActionTypes.CHECK_PASSWORD:
      return { ...state, tokenStatus: action.data };
    case EReduxActionTypes.REGISTER_USER:
      return { ...state, connectedUser: action.data, loggedIn: false };
    case EReduxActionTypes.LOADING_REGISTER_STUDENT: 
      return {...state, loadingRegisterStudent: action.data};
    case EReduxActionTypes.REGISTER_STUDENT:
      return { ...state, registerStudent: action.data, loadingRegisterStudent: false };
    case EReduxActionTypes.INVITE_PERSON:
      return { ...state, invitedPerson: action.data };
    case EReduxActionTypes.IS_PERSON_INVITED:
      return { ...state, isPersonInvited: action.data };
    case EReduxActionTypes.RESET_PASSWORD:
      return { ...state, newPassword: action.data };
    case EReduxActionTypes.UPLOAD_IMAGE:
      return { ...state, imageUploaded: action.data };
    case EReduxActionTypes.LOGOUT_USER:
      localStorage.clear();
      window.location.reload();
      return initialState;
    case EReduxActionTypes.GET_CONNECTED_USER:
      return { ...state, connectedUserInfo: action.data.userInfo, loggedIn: true };
    case EReduxActionTypes.UPDATE_INFO_SCHOOL:
      return { ...state, connectedSchoolInfo: action.data.schoolInfo, loggedIn: true };
    case EReduxActionTypes.SET_CONNECTED_USER_INFO:
      return { ...state, connectedUserInfo: action.data }
    case EReduxActionTypes.GET_ACTUAL_TAB:
      return { ...state, actualTab: action.data }
    case EReduxActionTypes.LOADING_USER_PROFILE:
      return { ...state, loadingProfile: true };
    case EReduxActionTypes.GET_CARDS:
      return { ...state, cards: action.data };
    case EReduxActionTypes.CHECK_PASSWORD_USER:
      return { ...state, passwordChecked: action.data };
    case EReduxActionTypes.GET_USER_PROFILE_DETAILS:
      return { ...state, connectedUserInfo: action.data, loadingProfile: false };

    case EReduxActionTypes.LOGIN_WITH_COOKIES:
      localStorage.setItem("is_logged", "true");
      localStorage.setItem("access_token", JSON.stringify(action.data.access_token));
      localStorage.setItem("refresh_token", JSON.stringify(action.data.refresh_token));
      localStorage.setItem("expires_in", JSON.stringify(action.data.expires_in))
      localStorage.setItem("lastConnexion", JSON.stringify(action.data.lastConnexion))
      if (typeof window !== 'undefined' && (!window?.location?.href.includes("localhost"))) {
        document.cookie = action?.data?.schoolInfo?.code+"Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.koors.io";
      } else {
        document.cookie = action?.data?.schoolInfo?.code+"Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ";
      }
      return { ...state, connectedUser: action.data.user, connectedUserInfo: action.data.userInfo, connectedSchoolInfo: action.data.schoolInfo, loggedIn: true }
    
    case EReduxActionTypes.GET_INSCRIBED_SCHOOLS_LIST:
      return {...state, listOfInscribedSchools:action.data}
    
    case EReduxActionTypes.IS_FIRST_LOGIN:
      return { ...state, isFirstLogin: action.data }
    case EReduxActionTypes.RESUM_FIRST_LOGIN:
      return { ...state, isFirstLogin: action.data }
      
    default:
      return state;
  }
}
