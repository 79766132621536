import { ComponentProps, FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';

const PreviewOpenQuestion: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {


    return (

        <div style={{ marginBottom: "20px", backgroundColor: "#FFFFFF" }}>
            <div className="d-flex flex-column" style={{ border: "1px solid #EBECEE", padding: "27px", borderRadius: "3px" }}>
                <span className="H3-Headline neutral-3" >Question
                    <span style={{ color: "black" }}> {props?.index}*</span>
                </span>
                <span className="H4-Subtitle">
                   {props?.question?.questionStatement}
                </span>
            </div>
            <div className="d-flex flex-column" style={{ width: "588px", paddingBottom: "52px", paddingTop: "38px" }}>
                <span className='body-md' style={{ marginLeft: "50px", borderBottom: "1px solid #EBECEE" }}>Réponse longue</span>
            </div>
        </div>


    );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {

        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewOpenQuestion);
