
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectElement from "../../../elements/select/select-element";
import TextareaUnderlineElement from "../../../elements/text-area/text-area-underline-element";
const selectOptions = [{ value: 1, label: "1" }, { value: 2, label: "2" }, { value: 3, label: "3" }, { value: 4, label: "4" }, { value: 5, label: "5" }]
export default function CardOpenQuestionComponent(props: any) {

    const id = props?.openQuestion.question.id;
    const [questionStatement, setQuestionStatement] = useState(props?.openQuestion.question.questionStatement);
    const [questionAnswerExplanation, setQuestionAnswerExplanation] = useState(props?.openQuestion.question.questionAnswerExplanation);
    const questionAssessmentLink = props?.openQuestion.question.questionAssessmentLink;
    const [scaleNote, setScaleNote] = useState(props?.openQuestion.scaleNote);
    const index = props?.indexQuestion;
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const openAccordion = () => {
        setOpen(!open);
    }
    useEffect(() => {
        if (props?.openQuestion.question.id === null) {
            setOpen(false);
        }
    }, [props?.openQuestion.question.id])

    const saveOpenQuestion = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const openQuestion = {
            id: props?.openQuestion.id,
            scaleNote,
            question: {
                id,
                questionStatement,
                questionAnswerExplanation,
                type: "OQ",
                questionAssessmentLink,

            }
        }
        props?.saveOpenQuestion(openQuestion, index)
    }

    const deleteOpenQuestion = () => {
        props?.deleteQuestion(props?.indexQuestion);
    }

    const cancelClick = () =>{
        setScaleNote(props?.initForm?.scaleNote)
        setQuestionStatement(props?.initForm?.question.questionStatement)
        setQuestionAnswerExplanation(props?.initForm?.question.questionAnswerExplanation)
    }
    return (
        <>
            <div className="d-flex flex-column mb-3">
                <div className="bg-accordion-border white px-4" style={(props?.openQuestion.question.questionStatement === '') ? { paddingTop: 35, paddingBottom: 35 } : { paddingTop: 24, paddingBottom: 24 }}>
                    <div className="d-flex align-items-center justify-content-between accordion-head-open mx-2">
                        <div className="d-flex flex-wrap align-items-center justify-content-between w-83">
                            <div className="d-flex flex-column" >
                                <div className="d-flex align-items-center flex-wrap" style={{ marginBottom: 14 }}>
                                    <span className="H3-Headline  me-2 pe-1">
                                        <span className="H3-Headline neutral-3">
                                            {t('questionLabel')}
                                        </span> {index + 1}
                                    </span>
                                    {props?.openQuestion.question.questionStatement !== '' && <span className="body-sm bold"><span className="body-sm bold neutral-3">{t('openQuestion')} / </span> {props?.openQuestion.scaleNote} {t('pointsQuestion')}</span>}
                                </div>
                                {props?.openQuestion.question.questionStatement !== '' && <span className="H4-Subtitle bold ">{props?.openQuestion.question.questionStatement}</span>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-no-wrap">
                            <button type="button" className="btn-QuickActions mx-3" onClick={() => { deleteOpenQuestion() }}>
                                <span className="material-icons-outlined" >delete</span>
                            </button>
                            <button onClick={() => openAccordion()} type="button" className="btn-QuickActions">
                                {!open ? <>
                                    <span className="material-icons-outlined">expand_less</span>
                                </> :
                                    <> <span className="material-icons-outlined">expand_more</span></>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {!open && <>
                    <div className="bg-accordion-border white accordion-body">
                        <div className="d-flex flex-column">
                            <div className="mb-5">
                                <label className="H4-Subtitle mb-2">{t('openQuestion')}</label>
                                <TextareaUnderlineElement placeholder={t('questionPlaceholder')} value={questionStatement} onChange={(value: string) => setQuestionStatement(value)} id={1} />
                            </div>
                            <div className="mb-2">
                                <label className="H4-Subtitle mb-2">{t('Consignes')}</label>
                                <TextareaUnderlineElement placeholder={t('consignesPlaceholder')} value={questionAnswerExplanation} onChange={(value: string) => setQuestionAnswerExplanation(value)} id={2} />
                            </div>

                            <div className="flex-row d-flex flex-wrap mt-4 w-100">
                                <div style={{ width: '400px' }}>
                                    <span className="form-title d-flex mb-2">{t('Valeurdelaquestion')}</span>
                                    <SelectElement disabled={false} taille={"lg"} defaultValue={scaleNote} options={selectOptions} placeholder={"Sélectionner un nombre de points"} onChange={(value: string) => setScaleNote(Number(value))} />
                                </div>

                                <div className="d-flex col justify-content-end  ms-auto mt-4 pt-2">
                                    <button onClick={(e) => { cancelClick() }} type="button" className="btn-Secondary large me-2">
                                        {t("annuler")}
                                    </button>
                                    <button onClick={(e) => { saveOpenQuestion(e); openAccordion() }} type="button" className="btn-Primary large ms-1">
                                        {t("saveChapter")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </>
    )

}




