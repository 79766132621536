import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarOutline from '@material-ui/icons/StarOutline';
import { saveReturnPath, redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { createFollow, getRegistrationForm, getApplicationFile, clearApplicationFile, } from '../../global-state/actions/course-actions';
import { useHistory } from 'react-router-dom';
import { getCurrentCourse, getCurrentTraining } from '../../global-state/actions/student-training-actions';
import { refreshToken } from '../../global-state/actions/auth-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getAllFeedbacksApi } from '../../services/feedBack.service';
import { getOffer } from '../../global-state/actions/app-container-actions';
const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]

const HeaderSectionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [courseForm, setCourseForm] = useState<any>();
    const show = useMemo(() => { return props?.show }, [props?.show]);
    const [rate, setRate] = useState<number>(0);
    const [level, setLevel] = useState<string>("");
    const history = useHistory();
    const [clicked, setClicked] = useState<boolean>(false);
    const [type, setType] = useState<string>("course");
    const [allFeedbacks, setAllFeedBacks] = useState<any>()
    const { t } = useTranslation();

    useEffect(() => {
        setCourseForm(props?.courseForm);
        if (props?.courseForm && props?.courseForm.rate && props?.courseForm.rate.length > 0) {
            setRate(props?.courseForm.rate[0].rating);
        }
        const levelFouned = levels.find(obj => obj.value === props?.courseForm?.level);
        setLevel((levelFouned) ? levelFouned.label : '...');
    }, [props?.courseForm]);

    useEffect(() => {
        setRate(props?.follow?.rating);
    }, [props?.follow]);

    const getAllFeedbacks = async (idSubject: number, page: number, action: string) => {
        const result = await getAllFeedbacksApi(idSubject, page, action)
        setAllFeedBacks(result)
    }

    const getFeedbacksData = async () => {
        if (props?.courseForm?.id) {
            await getAllFeedbacks(props?.courseForm?.id, 0, 'refresh');
        }
    }

    useEffect(() => {
        setClicked(false);
        getFeedbacksData();
    }, []);

    useEffect(() => {
        getFeedbacksData();
        if (allFeedbacks && allFeedbacks.feedbacks.length > 0) {
            setRate(allFeedbacks.feedbacks.map((obj: any) => { return obj.rate }).reduce((a: any, b: any) => a + b, 0) / allFeedbacks.feedbacks.length);
        }
    }, [props?.courseForm]);

    useEffect(() => {
        if (props?.type) {
            setType(props?.type);
        }
    }, [props?.type]);
    useEffect(() => {
        setRate(props?.follow?.rating);
        if (props?.follow && props?.follow[0] && props?.follow[0]?.id && clicked) {
            if (courseForm?.type !== 'COURSE') {
                props?.getCurrentTraining(props?.follow[0]?.id);
            } else {


                if (!!props?.follow[0]?.id) {
                    props?.getCurrentCourse(props?.follow[0]?.id);
                }
            }
            history.push(`${returnStartPathUrl()}${(courseForm?.type === 'COURSE') ? '/passCourse/course' : (courseForm?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.follow[0]?.id}`);
        }
    }, [props?.follow, clicked]);

    useEffect(() => {
        if (props?.coursePrice !== undefined && props?.coursePrice?.offer_id !== undefined) {
            props?.getOffer(props?.coursePrice?.offer_id)
        }
    }, [props?.coursePrice])


    const followTraining = async (trainingType: string) => {
        if (props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
            if ((courseForm?.idRegistrationForm === 0 || courseForm?.idRegistrationForm === null || courseForm?.idRegistrationForm === undefined) && (props?.price?.price === 0)) {

                let contractToCreate = {
                    contractIdentifier: {
                        contractId: null,
                        contractReference: null
                    },
                    contractDetails: {
                        offerId: props?.coursePrice?.offer_id,
                        contractDuration: {
                            startAt: new Date(Date.now()),
                            endAt: null
                        },
                        pricingId: props?.offer?.idPricings[0],
                        paymentMethod: "CARD",
                        paymentEngine: "STRIPE"
                    },
                    contractRoles: [
                        {
                            personId: props?.connectedUser?.idPerson,
                            roleType: "STUDENT"
                        }
                    ],
                    status: [
                        {
                            statusType: "ONGOING"
                        }
                    ]
                }

                let follow = {
                    studentId: props?.connectedUser?.idPerson,
                    trainingId: courseForm.id,
                    trainingType: courseForm.type,
                    entityId: courseForm.entityId,
                    entityType: courseForm.entityType,
                    statusList: [{
                        status: "INSCRIBED"
                    }],
                }

                await props?.createFollow(follow, contractToCreate);
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
                await setClicked(true);
            } else if ((courseForm?.idRegistrationForm === 0 || courseForm?.idRegistrationForm === null || courseForm?.idRegistrationForm === undefined) && (props?.price?.price !== 0)) {

                props?.clearHistoryArray();
                props?.redirect(props?.courseForm?.title, "")
                props?.redirect("Paiement", "")
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${returnStartPathUrl()}/courses/payement/${trainingType}/${courseForm?.id}`)
                });

            }
            else if ((courseForm?.idRegistrationForm !== 0 && courseForm?.idRegistrationForm !== null && courseForm?.idRegistrationForm !== undefined) ||
                ((courseForm?.idRegistrationForm !== 0 && courseForm?.idRegistrationForm !== null && courseForm?.idRegistrationForm !== undefined) && (props?.price?.price !== 0))) {

                await props?.getApplicationFile(props?.connectedUser?.idPerson, props?.registration?.id);
                await props?.getRegistrationForm(props?.registration?.id);
                props?.redirect(t('Subscription'), `${returnStartPathUrl()}/catalog/inscription/${courseForm?.type?.toLowerCase()}/${courseForm?.idRegistrationForm}`)
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${returnStartPathUrl()}/catalog/inscription/${courseForm?.type?.toLowerCase()}/${courseForm?.idRegistrationForm}`);
                });

            }

        }

        else {
            await props?.saveReturnPath({ path: `${returnStartPathUrl()}/catalog/preview/course/${courseForm?.id}`, tabPage: '' });
            history.push(`${returnStartPathUrl()}/auth/login`);
        }

    }

    const getButtonwordingFN = (typeInput: string) => {
        switch (typeInput) {
            case 'course':
                return t('followCourse');
            case 'path':
                return t('followPath');
            default:
                return t('followSession')
        }

    }

    return <>
        <section style={{ backgroundColor: "#FFFFFF" }}>
            <div className="d-flex flex-column px-2 pt-5 pb-3" style={{ maxWidth: 1200, margin: 'auto' }} >
                {
                    (courseForm?.type === "COURSE") && <>
                        <div className=" mt-2 d-flex align-items-center justify-content-between">
                            <h2 className="H2-Headline black-800" style={{ width: "80%" }}>{courseForm?.title}</h2>
                            {props?.disabled && <button className="btn-Primary large" disabled>{getButtonwordingFN(type)} </button>}

                            {!props?.disabled && !show && <>
                                <button className="btn-Primary large" onClick={() => followTraining("course")} > {getButtonwordingFN(type)} </button>
                            </>}
                        </div>
                    </>
                }
                {
                    (courseForm?.type === "PATH") && <>
                        <div className=" mt-2 d-flex align-items-center justify-content-between">
                            <h2 className="H2-Headline black-800" style={{ width: "80%" }}>{courseForm?.title}</h2>
                            {props?.disabled && <button className="btn-Primary large" disabled> {t('followPaths')}  </button>}

                            {!props?.disabled && !show && <>
                                <button className="btn-Primary large" onClick={() => followTraining("path")} >  {t('followSessions')} </button>
                            </>}
                        </div>
                    </>}
                {
                    (courseForm?.type === "SESSION") && <>
                        <div className=" mt-2 d-flex align-items-center justify-content-between">
                            <h2 className="H2-Headline black-800" style={{ width: "80%" }}>{courseForm?.title}</h2>
                            {props?.disabled && <button className="btn-Primary large" disabled> {t('followSession')}  </button>}

                            {!props?.disabled && !show && <>
                                <button className="btn-Primary large" onClick={() => followTraining("session")} > {t('followSession')}  </button>
                            </>}
                        </div>
                    </>}
                <div className="d-flex align-items-center justify-content-start mt-3">

                    {
                        (courseForm?.type === "COURSE") && <>

                            <svg className="me-2" width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
                                </g>
                                <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                                <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
                                    </clipPath>
                                </defs>
                            </svg>


                            <span className="body-sm bold neutral-2 me-4 pe-3">{t('courseBy')} {props?.owner}</span>

                        </>
                    }

                    {
                        (courseForm?.type === "PATH") && <>

                            <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909
                    12.4364 3.56364 13.0909 4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z"
                                    fill="#FA83EE" />
                            </svg>

                            <span className="body-sm bold neutral-2 me-4 pe-3">{t('pathBy')} {props?.owner}</span>

                        </>
                    }
                    {
                        (courseForm?.type === "SESSION") && <>

                            <svg className="me-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC" />
                                </g>
                                <line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3" />
                                <line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3" />
                                <line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3" />
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white" transform="translate(0 1.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="body-sm bold neutral-2 me-4 pe-3">{t('SessionGivenBy')} {props?.owner}</span>

                        </>
                    }

                    <div className="me-3 d-flex align-items-center">
                        <Box component="fieldset" borderColor="transparent" height="24px">
                            <Rating
                                readOnly
                                precision={0.1}
                                name="customized-empty"
                                value={rate}
                                emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                                style={{ color: "#F5B95F" }}
                            />
                        </Box>
                        <span className="Button-xl ms-3" style={{ color: "#F5B95F" }}>{rate}</span>
                    </div>
                    <button className="btn-text outlined" onClick={() => { props?.avisClicked() }}>{t('avis') + " (" + allFeedbacks?.totalItems + ")"}</button>
                </div>
                <div className="my-3 py-3 body-md neutral-2" dangerouslySetInnerHTML={{ __html: (courseForm?.resume ? courseForm?.resume : t('courseResumeIntroduction')) }}></div>
                <div className="d-flex align-items-center justify-content-start pb-5">
                    <div className="d-flex align-items-center me-3">
                        <span className="body-md bold neutral-3 me-1">{t('lastUpdate')}</span>
                        <span className="body-md bold black-800">{courseForm?.creationDate.substring(0, 10).split("-").reverse().join("/")}</span>
                    </div>
                    <div className="d-flex align-items-center me-3">
                        <span className="material-icons-outlined neutral-3 me-1">signal_cellular_alt</span>
                        <span className="body-md bold black-800">{t('selectLevelCourse')} {t(level)}</span>
                    </div>
                    <div className="d-flex align-items-center me-3">
                        <span className="material-icons-outlined neutral-3 me-1">timer</span>
                        <span className="body-md bold black-800">{t('durationChapter')} {(props?.time === '0 MN') ? '...' : props?.time}</span>
                        <span></span>
                    </div>
                    {(courseForm?.idRegistrationForm !== undefined && courseForm?.idRegistrationForm !== null && courseForm?.idRegistrationForm !== 0) && <div className="d-flex align-items-center me-3">
                        <span className="material-icons-outlined neutral-3 me-1">description</span>
                        <span className="body-md bold black-800"> {t('withInscription')}</span>
                    </div>}

                    <div className="d-flex align-items-center ">
                        <span className="material-icons-outlined neutral-3 me-1">credit_card</span>
                        {(props?.paymentMethod === null || props?.price === null || props?.price?.price === 0) ? <span className="body-md bold black-800"> {t('registrationFees') + ' ' + t('freeTitleCourse')}</span> :
                            <><span className="H3-Headline black-800">{props?.price?.price}</span><span className="H4-Subtitle neutral-3">{props?.paymentMethod !== null && props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span></>}
                        <span></span>
                    </div >

                </div >
            </div >
        </section >
    </>
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    saveReturnPath,
    createFollow,
    refreshToken,
    getRegistrationForm,
    getApplicationFile,
    clearApplicationFile,
    getCurrentTraining,
    getCurrentCourse,
    redirect,
    clearHistoryArray,
    getOffer
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseForm: ownProps?.courseForm,
    price: ownProps?.price,
    time: ownProps?.time,
    disabled: ownProps?.disabled,
    show: ownProps?.show,
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    registration: state.courseReducer.registration,
    avisClicked: ownProps?.avisClicked,
    type: ownProps?.type,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    coursePrice: state.courseReducer.priceCourse,
    offer: state.schoolInfoReducer.offer
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSectionComponent);