import Dropzone from "react-dropzone";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";

export default function InputUploadFile(props: any) {
    const { t } = useTranslation();

    function onDrop  (accepted: any, rejected: any)  {
        
        if (Object.keys(rejected).length !== 0) {
            props?.addFile(rejected);
        } else {
            props?.addFile(accepted);
        }
    };
    return <>
    
    <div className="mx-auto">
        <Dropzone maxSize={10485760} accept=".pdf,.jpg,.jpeg,.png,.doc,.docm,.dot,.dotm,.dotx,.odt,.rtf,.docx,.pptx,.ppt,.txt,.xps,.pot,.potm,.ppa,.potx,.pps,.xlsx,.xls,.csv,.gif,.ods,.mp3,.wav,.epub,.odp" style={{ width: '100%', zIndex: 1 }} multiple={false} onDrop={onDrop} >
                { ({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
              return <div className="bg-with-border dashed grey-background p-4">
                <div className="d-flex m-2 align-items-center justify-content-between">
                    <div className="d-flex align-items-center me-2">
                        <div className="me-3 d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                            <span className="material-icons-outlined neutral-4"> cloud_upload </span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="form-default">{t('textFileUpload_1')}</span>
                        </div>
                    </div>
                    <button className="btn-Secondary large">{t('uploadFile')}</button>
                </div>
            </div>
            }
        }
    </Dropzone>
        </div>
    </>
}
