import { FC, useEffect, ComponentProps, useState } from "react"
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from "@stripe/react-stripe-js";
import { createPayment, upgradeContract, upgradeSubscription } from "../../services/school.service";
import { getDomainFromUrl, returnStartPathUrl } from "../../helpers/domainCheck";
import { useHistory } from "react-router";
import { connect } from "react-redux"
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getSubscriptions, getContract} from '../../global-state/actions/app-container-actions';
import jwt from 'jwt-decode';
import axiosInstance from "../../helpers/interceptors";

interface DecodedToken {
    id_Person: number;
    user_name: string;
}

const CARD_OPTIONS: any = {
    showIcon: true,
    iconStyle: "solid",
    style: {
        base: {
            backgroundColor: '#F2F2F5',
            iconColor: '#272727',
            color: '#272727',
            fontWeight: 500,
            fontFamily:
                '"Public", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
                color: '#CACBCE',
            },
            ':-webkit-autofill': {
                color: '#272727',
            },
        },
        invalid: {
            backgroundColor: '#fae9e9',
            iconColor: '#E06A6A',
            color: '#272727',
        },
    },
};
const CARD_OPTIONS_EXP = {
    style: {
        base: {
            backgroundColor: '#F2F2F5',
            iconColor: '#272727',
            color: '#272727',
            fontWeight: 500,
            fontFamily:
                '"Public", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
                color: '#CACBCE',
            },
            ':-webkit-autofill': {
                color: '#272727',
            },
        },
        invalid: {
            backgroundColor: '#fae9e9',
            color: '#272727',
        },
    },
};
const CARD_OPTIONS_CVC = {
    style: {
        base: {
            backgroundColor: '#F2F2F5',
            iconColor: '#272727',
            color: '#272727',
            fontWeight: 500,
            fontFamily:
                '"Public", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
                color: '#CACBCE',
            },
            ':-webkit-autofill': {
                color: '#272727',
            },
        },
        invalid: {
            backgroundColor: '#fae9e9',
            color: '#272727',
        },
    },
};

const UpgradeStripeComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [errorCVC, setErrorCVC] = useState(undefined);
    const [errorCardNumber, setErrorCardNumber] = useState(undefined);
    const [errorDateExp, setErrorDateExp] = useState(undefined);
    const stripe = useStripe();
    const elements = useElements();
    const [msg, setMsg] = useState("");
    const [name, setName] = useState("");
    const [oneYear, setOneYear] = useState<any>();
    const [oneMonth, setOneMonth] = useState<any>();
    const history = useHistory();


    const [periodPayment, setPeriodPayment] = useState("");

    const [contract, setContract] = useState<any>();

    useEffect(()=>{
        props?.getContract(props?.schoolInfo?.id, "SCHOOL")
    },[])

    useEffect(()=>{
        setContract(props?.contract)
    },[props?.contract])

    useEffect(() => {
        setName(props?.connectedUser?.firstName + " " + props?.connectedUser?.lastName)
        setOneYear(new Date(Date.now() + (86400000 * 365)));
        setOneMonth(new Date(Date.now() + (86400000 * 30)));
    }, [periodPayment])

    useEffect(() => {
        if (!props?.offer?.pricings) return
        setPeriodPayment(props?.offer?.pricings.find((a: any) => a.id == props?.priceId)?.period)
    }, [props?.offer?.pricings])

    useEffect(() => {
        if (props.payAction) {
            document.getElementById("bt")?.click();
            props?.disablePayAction1()
        }

    }, [props.payAction])

    const handleEndDateFn = () => {
        !!props?.offer?.pricings && props?.offer?.pricings.length > 1
          ? props?.offer?.pricings?.find((a: any) => a?.price == props?.price)?.period === "MONTH"
            ? new Date(Date.now() + 26306e5)
            : new Date(Date.now() + 3170e7)
          : new Date(Date.now() + 26306e5)
        if (!!props?.offer?.pricings && props?.offer?.pricings.length > 1) {
          if (props?.offer?.pricings?.find((a: any) => a?.price == props?.price)?.period === "MONTH") {
            return new Date(Date.now() + 26306e5)
          } else {
            return new Date(Date.now() + 3170e7)
          }
        }
        return new Date(Date.now() + 26306e5)
      }

    const handleSubmit = async (event: any) => {

        setMsg("")
        props?.loadingAction1(true)
        props?.errorAction(false)

        event.preventDefault();


        if (!stripe || !elements) {
            props?.loadingAction1(false)
            return;
        }
        const promise = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)!
        });
        if (promise.paymentMethod == undefined) {
            props?.loadingAction1(false)
        }

        const idPrice = props?.offer?.pricings?.length > 1 ? props?.offer?.pricings?.find((a: any) => a.price == props.price).id : props?.offer?.pricings[0]?.id

        if (!!promise.paymentMethod) {

            let paymentInfo: any = {

                idPerson: props?.connectedUser?.id,
                idSchool: props.schoolInfo?.idSchool,
                email: props?.connectedUser?.email,
                paymentMethod: promise.paymentMethod?.id!,
                priceId: idPrice,
                product: "PRICE",
                idCoupon: undefined,
            }
            if (props.coupon?.id) {
                paymentInfo.product = "COUPON"
                paymentInfo.idCoupon = props.coupon?.id
            }

            let response = await upgradeSubscription(paymentInfo);
            if (!response.success) {
                let status = response.stripeResponse?.codeDescription || response.stripeResponse?.code;

                if (status === "generic_decline") {
                    setMsg("Paiement refusé pour des raisons génériques");
                    props?.loadingAction1(false);
                } else if (status === "insufficient_funds") {
                    setMsg("Les fonds de la carte sont insuffisants");
                    props?.loadingAction1(false);
                } else if (status === "lost_card") {
                    setMsg("La carte est déclarée perdue");
                    props?.loadingAction1(false);
                } else if (status === "expired_card") {
                    setMsg("La carte est expirée");
                    props?.loadingAction1(false);
                } else if (status === "incorrect_cvc") {
                    setMsg("Le code CVC est incorrect");
                    props?.loadingAction1(false);
                } else if (status === "processing_error") {
                    setMsg("Paiement refusé à cause d’erreur de traitement");
                    props?.loadingAction1(false);
                } else if (status === "stolen_card") {
                    setMsg("La carte est déclarée volée");
                    props?.loadingAction1(false);
                } else {
                    props?.errorAction(true)
                    props?.loadingAction1(false);
                }
            } else {
                let data = await stripe.confirmCardPayment(response?.subscriptionResponse?.clientSecret!);
                if (data.paymentIntent?.status === "succeeded") {


                    let oldContract = {
                        contractIdentifier: {
                            contractReference: contract[0]?.contractIdentifier?.contractReference
                        },
                        status: [{
                            statusType: "CANCELLED"
                        }]
                    }

                    let newContract =                           {
                        contractIdentifier: {
                          contractId: null,
                          contractReference: null
                        },
                        contractDetails: {
                          offerId: props?.offer?.id,
                          contractDuration: {
                            startAt: new Date(Date.now()),
                            endAt: handleEndDateFn(),
                          },
                          pricingId: idPrice,
                          paymentMethod: "CARD",
                          paymentEngine: "STRIPE"
                        },
                        contractRoles: [{
                          personId: props?.schoolInfo?.idSchool,
                          roleType: "SCHOOL"
                        }],
                        status: [{
                          statusType: "ONGOING"
                        }]
                      }

                    let upgradeOfferObject = {
                        oldContract: oldContract,
                        newContract: newContract,
                        contractId: contract[0]?.contractIdentifier?.contractId,
                        newSubscriptionId: response?.subscriptionResponse.subscriptionId
                    }

                    
                   await upgradeContract(upgradeOfferObject).then(async (response)=> {

                      const objectDealStatic = {
                        "email": props?.connectedUser?.email,
                        "properties": {
                          "amount": "960",
                          "dealstage": "closedwon"
                        }
                      }
                      await axiosInstance.post(process.env.REACT_APP_BASE_URL21 + "hubspot/updateDeal",objectDealStatic)
                      
                      const object = {
                        "code": getDomainFromUrl(),
                        "properties": {
                          "koors_offer": props?.offer?.pricings.length > 1 ? process.env.REACT_APP_OFFER_LABEL_PRO : process.env.REACT_APP_OFFER_LABEL_BUSINESS
                        }
                      }
                      await axiosInstance.post(process.env.REACT_APP_BASE_URL21 + "hubspot/updateCompanyWithSchoolUrl", object)
                      
                      props?.loadingAction1(false);
                      history.push(`${returnStartPathUrl()}/offer/success`)
                   })

   
                } else {
                    props?.loadingAction1(false);
                    props?.errorAction(true)
                }
            }



        }
    }

    return (<>


        <div style={{ width: "100%", marginBottom: "50px" }} >
            <div>


                <div className="d-flex flex-column ml-3 mb-4">
                    <label className="form-title">Nom sur la carte</label>
                    <input type="text" className="form-control input text-default" value={name} placeholder="Nom sur la carte" onChange={(e: any) => setName(e?.target?.value)} />
                </div>
                <div className="ml-3 mb-4">
                    <label className="form-title mb-2">Numéro de carte de crédit</label>
                    <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorCardNumber) ? "#F2F2F5" : "#fae9e9" }}>
                        <CardNumberElement onChange={(e: any) => setErrorCardNumber(e.error)} options={CARD_OPTIONS} />
                    </div>
                </div>
                <div className="ml-3 d-flex align-items-center justify-content-between flew-wrap mb-4">
                    <div className="w-100 me-2">
                        <label className="form-title mb-2">Date d&apos;expiration</label>
                        <div className=" px-3 py-3" style={{ borderRadius: '3px', backgroundColor: (!errorDateExp) ? "#F2F2F5" : "#fae9e9" }}>
                            <CardExpiryElement onChange={(e: any) => setErrorDateExp(e.error)} options={CARD_OPTIONS_EXP} />
                        </div>
                    </div>
                    <div className="w-100 ml-3 ">
                        <label className="form-title mb-2">Cryptogramme</label>
                        <div className=" px-3 py-3" style={{ position: 'relative', borderRadius: '3px', backgroundColor: (!errorCVC) ? "#F2F2F5" : "#fae9e9" }}>
                            <div className="w-60">
                                <CardCvcElement onChange={(e: any) => setErrorCVC(e.error)} options={CARD_OPTIONS_CVC} />
                            </div>
                            <div style={{ position: "absolute", right: 12, top: 14 }}>

                            </div>
                        </div>
                    </div>

                </div>
                {(msg !== "") && <div className="w-100 ml-3 mt-2 ">
                    <label className="body-error  red-800 mb-2"> {msg}</label>
                </div>}
                <div className="d-flex align-items-top mt-2">

                    <input className="input-style-checkbox " type="checkbox" id="checkBtn" />
                    <label htmlFor="checkBtn" />
                    <span className="form-title-welcome ps-1 ms-1">Enregistrer ce mode de paiement dans mon profil</span>
                </div>


                <button type="button" style={{ display: 'none', width: "100%" }} id='bt' className="btn-Primary large mt-4" onClick={handleSubmit}>
                    confirmer et payer
                </button>
                <button id='checkedConditionsTrue' style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#activateModalFinal"></button>
                <button id='checkedConditionsFalse' style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#continuCreatingModal"></button>
            </div>
        </div>
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUserInfo,
    schoolInfo: state.authReducer.connectedUser,
    contract: state.schoolInfoReducer.contract
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getSubscriptions, getContract
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeStripeComponent);



