import { FC, useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';

import { connect } from "react-redux";
import { getCatalog } from "../../../global-state/actions/catalog-duplicaction-actions";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { getStatisticsApi, getTimesApi } from "../../../services/activity.service";

interface Props {
    courseList: any[]
    courseFormId:any,
    date:any
}


const VisitChart: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const { t } = useTranslation();
    const [appFile, setAppFile] = useState<any>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [visit, setVisit] = useState<any>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    let labels :any= [
        t('Jan'),
        t('Fev'),
        t('Mar'),
        t('Avr'),
        t('May'),
        t('Jui'),
        t('Jul'),
        t('Aug'),
        t('Sep'),
        t('Oct'),
        t('Nov'),
        t('Dec')
    ]
    const [months, setMonths] = useState<any>(labels)
    const [times, setTimes] = useState<any>()

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) { 
            getTimes(); 
        }
    }, [])

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) {
            props?.getYears(times?.visit)
        }
    }, [times]);

    useEffect(() => {
        props?.getYears(times?.visit)

    }, [times]);

    const getStatistics = async (year: any, list: any, schoolId: number) => {
        const result = await getStatisticsApi(year, list, schoolId)        
        setAppFile(result?.appFile)
        setVisit(result?.visit)
    }
    
    const getTimes = async () =>{
        const result = await getTimesApi()
        setTimes(result)
      
    }

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) {
            getStatistics(props?.selectedValue, [props?.courseFormId],props?.schoolInfo?.id)

        }
    }, [props?.courseFormId, props?.selectedValue]);

    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0 && props?.courseFormId === undefined) {
            getTimes();
        }
    }, [props?.courseList]);

    useEffect(() => {


        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0 && !!props?.selectedValue && props?.courseFormId === undefined) {
            getStatistics(props?.selectedValue, props?.courseList,props?.schoolInfo?.id)

        }
    }, [props?.courseList, props?.selectedValue]);

    useEffect(() => {

        let currentDate = new Date()
        let nbrMonth = currentDate.getMonth()
        

        if(props?.date === currentDate.getFullYear()){
            setMonths(labels.slice(0, nbrMonth+1))
        }else{
            setMonths(labels)
        }
    }, [])

    
    const data = {
        labels: months,
        datasets: [
            {
                label:  t('inscribedInSchool'),
                data: appFile,
                yAxisID: "rightAxe",
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                backgroundColor: "#9DDBA9",
                borderColor: "#9DDBA9",
                borderWidth: 1,
                
            },
            {
                label: t('catalogVisits'),
                data: (visit === undefined ? [] : visit[0]),
                yAxisID: "leftAxe",
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                backgroundColor: "#8A57CC",
                borderColor: "#8A57CC",
                borderWidth: 1,
                
            }
        ]
    }
    return (
        <div style={{pointerEvents:"none"}}>



            <Bar options={{
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        fontColor: '#333',
                        usePointStyle: true,
                    },
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false
                    }
                }, scales: {
                    xAxes: [{
                        offset: true,
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            
                           
                        },
                    }],
                    yAxes: [{
                        id:  "rightAxe",
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 1,
                        },
                        offset: false,
                        scaleLabel: {
                            display: true,
                            labelString: t('inscribedInSchool'),
                            align: 'start',
                            padding: {
                                top: 10,
                                bottom: 30
                            },

                        },
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            
                            color: "#9DDBA9"
                        }
                    }, {
                        id: "leftAxe",
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 4,
                        },
                        offset: false,
                        position: "right",
                        scaleLabel: {
                            display: true,
                            labelString: t('catalogVisits')
                        },
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            color: "#8A57CC"
                        }
                    }]

                }
            }} data={data} />
        </div>)
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courses: state.catalogReducer.courses,
    user: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseForm,
    courseInfo: state.courseReducer.courseInfo,
    getYears: ownProps?.getYears,
    selectedValue: ownProps?.selectedValue,
    schoolInfo:state.schoolInfoReducer.schoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getCatalog,
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(VisitChart);