import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { getCourse, getCourseTests, getRegistrationForm } from '../../global-state/actions/course-actions';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { useHistory } from 'react-router';
import FilterProfessorsListComponent from '../filters/filter-professors-list-component';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import TableTeamWithActions from '../../elements/table/table-team-with-actions';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TeamTableAction from '../../elements/table-actions/team-table-action';
import { returnStartPathUrl } from '../../helpers/domainCheck';



const TableProfessorsListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const dateFormat = require('dateformat');
  const { t } = useTranslation();
  const history = useHistory();
  const [profs, setProfs] = useState<any>();
  const [filteredProfs, setFilteredProfs] = useState<any>();
  const headers = [
    { title: t('filterProfessors'), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0" },
    { title: t('InscriptionDate'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('lastVisitProfile'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('courseTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('biography'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
    { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
  ]
  const status = [
    { label: t('Enattente'), value: "Enattente", color: "warning" },
    { label: t('Admis'), value: "Admis", color: "success" },
    { label: t('Suspendu'), value: "Suspendu", color: "danger" },
  ]
  
  
  const [activateSnakbar, setActivateSnakbar] = useState<boolean>(false)
  const activateSnakBar = ()=>{
    setActivateSnakbar(true)
  }

  const doFilter = (value: string) => {
    let copyProfs = { ...profs };
    let actualStatus: any = status.find((e: any) => e?.value === value)?.value;
    copyProfs.body = copyProfs.body.filter((prof: any) => {
      return (value !== "" ? prof.row.columns[5]?.content.includes(t(actualStatus)) : true)
    });
    setFilteredProfs(copyProfs);
  }
  const doAction = async (value: any) => {
    if (value.action === 'goToPath') {
      props?.clearHistoryArray()
      props?.redirect(t('Teachers'), `${returnStartPathUrl()}/professors/all`)
      props?.redirect(value.row.prof.name, `${returnStartPathUrl()}/professors/infoteam/${value.row.prof.id}`)
       history.push(`${returnStartPathUrl()}/professors/infoteam/${value.row.prof.id}`);
    }
  }
  useEffect(() => {
    const professors = {
      headers: headers,
      body: props?.profs?.map((prof: any) => {
        const actualStatus: any = status.find(obj => obj?.value?.toLowerCase() === prof?.status?.toLowerCase());
        return {
          row: {
            prof: prof,
            actionName: "goToPath",
            classNames: "table-white",
            columns: [
              { content: prof?.name, classNames: "", actionName: '' },
              { content: prof?.inscription === null ? "-" : dateFormat(prof?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
              { content: prof?.lastVisit === "-" ? "-" : prof?.lastVisit + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
              { content: prof?.cours, classNames: "text-center", actionName: '' },
              {
                content: (prof?.biography !== null && prof?.pp !== '') ?
                  <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">check</span> :
                  <span style={{ position: "absolute", bottom: 24 }} className="material-icons-round p-0 m-0">highlight_off</span>,
                classNames: (prof?.pp !== null && prof?.pp !== '') ?
                  "text-center with-icon badge-text success p-0 m-0 relative-position" :
                  "text-center with-icon badge-text secondary p-0 m-0 relative-position",
                actionName: ''
              },
              { content:prof?.status==="Enattente" ?< TeamTableAction status={actualStatus?.label}  color={actualStatus?.color}  id={prof?.id} role={prof?.role} activateSnakBar={activateSnakBar}/> : <span className={`text-end badge-text ${actualStatus?.color}`}>{prof?.status}</span>, classNames: `text-end with-action-edit`, actionName: '' },
            ]
          }
        }
      })
    }
    setProfs(professors);
    setFilteredProfs(professors)
  }, [props?.profs]);


  return (
    <>
    <div className="container-fluid m-0 p-0 mx-auto" style={{ maxWidth: '1200px' }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          {props?.actualTab === 'prof' && props?.sizeProfs > 0 && <FilterProfessorsListComponent doFilter={doFilter} actualTab= "PROF" />}
        </div>
        <div className="col-md-12 p-0 m-0 mt-5">
          {(props?.sizeProfs > 0) ? <>
            <TableTeamWithActions height={10} data={filteredProfs} doAction={(value: any) => { doAction(value) }} actualTab={props?.actualTab} />
          </> : <>
            <div className="wrapper-empty-list w-100 mt-5 pt-5">
              <EmptyListElement lines={[t('noProfessorsListDefault_1'), t('noProfessorsListDefault_2')]} />
              {props?.inviteMember()}
            </div>
          </>
          }
        </div>
      </div>
    </div>
    <Snackbar open={activateSnakbar} autoHideDuration={6000}  onClose={()=>{setActivateSnakbar(!activateSnakbar)}}>
    <Alert severity="success">
    Invitation envoyée avec succes
    </Alert>
</Snackbar>
</>
  );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  profs: state.profReducer.profList,
  director: state.authReducer.connectedUser,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  history: ownProps?.history,
  sizeProfs: state.profReducer.sizeProfs,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {  redirect, clearHistoryArray, getCourse, getCourseTests, getRegistrationForm }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableProfessorsListComponent);
