
import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import { getUserProfileDetails } from '../../global-state/actions/auth-actions';
import FinanceProfileComponent from '../../components/profile-components/finance-profile-component';
import { getActualTab } from '../../global-state/actions/auth-actions';
import LimitationComponent from '../../components/profile-components/limitation-component';
import FacturationComponent from '../../components/profile-components/facturation-component';
import SchoolInfoUpdateComponent from '../../components/profile-components/school-info-update-component';
import IntegrationModulesComponent from '../../components/school-integrations-modules/integration-modules-components';
import SchoolPreviewComponent from '../../components/school-preview/school-preview-component';

const ParametrePage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [actualTab, setActualTab] = useState("Integration")
    const [role, setRole] = useState(props?.connectedUser?.role)
    const [checkOffer, setCheckOffer] = useState(false)
    const [modeView, setModeView] = useState<any>(false)

    useEffect(() => {
        if (props?.offer?.pricings) {
            if (props?.offer?.pricings.length > 1) {
                setCheckOffer(true)
            } else if (props?.offer?.pricings.length === 1) {
                if (props?.offer?.pricings[0]?.min !== 0 || props?.offer?.pricings[0]?.price !== 0) {
                    setCheckOffer(true)
                } else if (props?.offer?.pricings[0]?.price === null || props?.offer?.pricings[0]?.price === undefined || props?.offer?.pricings[0]?.price === 0) {
                    setCheckOffer(false)
                }

            } else {
                setCheckOffer(false)
            }

        }
    }, [props?.offer])

    useEffect(() => {
        props?.getUserProfileDetails(props?.connectedUser?.id)
        if (typeof role != undefined && (role === "role_director" || role === "role_adminstrator")) {
            setActualTab("School")
        } else {
            setActualTab("Integration")
        }
        return () => {
            setActualTab("Integration")
        }
    }, [])


    useEffect(() => {
        setRole(props?.connectedUser?.role)
    }, [props?.connectedUser?.role])




    return (
        <>
            <div className="container-fluid" >
                <div className="row justify-content-center" >
                    <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                            <div className="col-md-12 mt-5" >
                                <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left' }}>{t('params')}</h1>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap px-2 pb-4 mt-3 justify-content-between mx-auto" style={{ maxWidth: '1200px' }}  >
                            <div className='d-flex justify-content-row'>
                                {role === "role_director" && <button className={`btn-Tabs ${(actualTab === 'School') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("School") }} style={{ marginRight: "16px" }}>{t('Ecole')}</button>}
                                <button
                                    className={`btn-Tabs ${(actualTab === 'Integration') ? '' : 'unselected'} me-3 d-flex align-items-center`}
                                    onClick={() => { setActualTab("Integration") }}
                                    style={{ marginRight: "16px" }}
                                >
                                    {t('integrations')}
                                </button>
                                {role === "role_director" && <button className={`btn-Tabs ${(actualTab === 'Forfait') ? '' : 'unselected'} me-3 d-flex align-items-center`} onClick={() => { setActualTab("Forfait") }} style={{ marginRight: "16px" }}>{t('monForfait')} </button>}
                            </ div>
                            <div>
                                <input dir="ltr" readOnly className="input-style" type="checkbox" id="toggleBtn4" onClick={() => setModeView(!modeView)} checked={modeView} />
                                <div className="toggle-container cours-editing">
                                    <label className="label-style cours-editing" htmlFor="toggleBtn4">
                                        <span className="on cours-editing">{t('editeMode')} </span>
                                        <span className="off cours-preview">{t('Previsualiser')}</span>
                                        <input dir="ltr" type="text" style={{ display: 'none' }} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            {modeView === false ? <div className='mx-auto' style={{ maxWidth: '1200px' }}>
                {actualTab === "Forfait" &&
                    role === "role_director" && <>
                        <div className="d-flex flex-column w-100 my-5 py-2 px-4" style={{ margin: 'auto', backgroundColor: '#FBFBFD', maxWidth: '1030px' }}>
                            <div className=' pt-2 mb-2'>
                                <div className="d-flex flex-column w-100 mx-auto mb-4"  >
                                    <span className="H3-Headline mb-3" >{t('Detailsduforfait')}</span>
                                    <span className="body-md neutral-2">{t('Gerezoumodifiezvotreforfait')}.</span>
                                </div>
                                <div className="d-flex flex-column w-100 bg-with-border white">
                                    <FinanceProfileComponent />
                                </div>
                            </div>
                            <div className='mt-4 pt-2'>
                                <div className="d-flex flex-column w-100 mx-auto mb-4"  >
                                    <span className="H3-Headline mb-3" >{t('Limitationduforfait')}</span>
                                    <span className="body-md neutral-2">{t('Suivrelalimitationdevotreforfaitactuel')}.</span>
                                </div>
                                <div className="d-flex flex-column w-100 bg-with-border white">
                                    <LimitationComponent />
                                </div>
                            </div>
                        </div>
                    </>}

                {(actualTab === 'Integration') &&
                    <>
                        <div className="d-flex flex-column w-100" style={{ backgroundColor: '#FBFBFD' }}>
                            <IntegrationModulesComponent />
                        </div>
                    </>
                }


                {(actualTab === 'Finanace' && role === "role_director") && <div className="d-flex flex-column w-100 my-5 py-2 px-4" style={{ margin: 'auto', backgroundColor: '#FBFBFD', maxWidth: '1030px' }}>
                    <div className=' pt-2 mb-2'>
                        <div className="d-flex flex-column w-100 mx-auto mb-4"  >
                            <span className="H3-Headline mb-3" >{t('Modesdepayement')}</span>
                            {checkOffer === true ? <span className="body-md neutral-2">{t('Gérezoumodifiezvotremodesdepaiement')}.</span> : <span className="body-md neutral-2">{t('freeOffreNoPaymentMethod')}</span>}
                        </div>
                        {checkOffer === true ? 
                        <div className="d-flex flex-column w-100 bg-with-border white">
                            <FacturationComponent />
                        </div> : <div className='d-flex flex-column w-100 mx-auto'>
                            <span className="H3-Headline mb-3" >{t('facture')}</span>
                            <span className="body-md neutral-2">{t('noBill')}</span>
                        </div>}
                    </div>

                </div>}

                {(actualTab === 'School' && role === "role_director") &&
                    <div className="d-flex flex-column w-100" style={{ margin: 'auto', backgroundColor: '#FBFBFD' }}>
                        <SchoolInfoUpdateComponent />
                    </div>
                }
            </div>
                :
                <div>
                    <SchoolPreviewComponent />
                </div>
            }
        </>

    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loadingProfile: state.authReducer.loadingProfile,
    userProfileDetails: state.authReducer.connectedUserInfo,
    connectedUser: state.authReducer.connectedUser,
    actualTab: state.authReducer.actualTab,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    offer: state.schoolInfoReducer.offer
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    getActualTab,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParametrePage);
