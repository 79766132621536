import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {IReduxSocketState} from '../reducers/socket-reducer';
export enum EReduxActionTypes{

    
    CONNECT_CHAT= 'CONNECT_CHAT', 
    INITIATE_SOCKET= 'INITIATE_SOCKET',
}
interface IReduxBaseAction {
    type: EReduxActionTypes;
  }
  export interface IReduxConnectAction extends IReduxBaseAction {
    type: EReduxActionTypes.CONNECT_CHAT;
  }
  export interface IReduxSocketInitAction extends IReduxBaseAction {
    type: EReduxActionTypes.INITIATE_SOCKET;
  }
  
  export function initSocket():ThunkAction<Promise<IReduxSocketInitAction>,IReduxSocketState, undefined, IReduxSocketInitAction> {
    return async (dispatch:ThunkDispatch<IReduxSocketState,undefined,IReduxSocketInitAction>)=>{

       return dispatch({
           type:EReduxActionTypes.INITIATE_SOCKET,
       });

    }
 }
  export function connectSocketChat():ThunkAction<Promise<IReduxConnectAction>,IReduxSocketState, undefined, IReduxConnectAction> {
    return async (dispatch:ThunkDispatch<IReduxSocketState,undefined,IReduxConnectAction>)=>{

       return dispatch({
           type:EReduxActionTypes.CONNECT_CHAT,
       });

    }
 }


 