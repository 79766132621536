import Skeleton from '@material-ui/lab/Skeleton';

function DeleteAccountLoadingComponent() {
    return (
        <div style={{ maxWidth: "1200px", margin: 'auto', paddingLeft: "390px" }} >
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-5"></div>
                <div className="col-md-3" style={{ display: "flex", justifySelf: "flex-end" }}>
                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'180px'} />
                </div>
            </div>
        </div>
    )
}

export default DeleteAccountLoadingComponent
