import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import axiosToken from '../../helpers/interceptors';

import { IReduxCoursesState } from '../reducers/course-reducer';

export enum EReduxActionTypes {
   GET_CATALOG = 'GET_CATALOG',
   LOADING_CATALOG='LOADING_CATALOG',
   CHANGE_STYLE_CARD='CHANGE_STYLE_CARD',
   GET_DURATION='GET_DURATION'
}

 interface IReduxBaseAction {
   type: EReduxActionTypes;
 }
 export interface IReduxGetCatalogAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CATALOG  | EReduxActionTypes.LOADING_CATALOG ;
  data: any;
}

export interface IReduxChangeStyleCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_STYLE_CARD;
  data: any;
}

export interface IReduxGetDurationAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_DURATION;
  data: any;
}

export function getCatalog(filter:any): ThunkAction<Promise<IReduxGetCatalogAction>, IReduxCoursesState, undefined,IReduxGetCatalogAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCatalogAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_CATALOG, data: undefined }) 
    
  const profs = axiosToken.get(`${process.env.REACT_APP_BASE_URL2}person/all/${filter.entityId}/profs`)
  .then((resp:any)=> resp)
  .catch(e=> {return {data:[]}})
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const catalogeCourses = axiosToken.post(`${process.env.REACT_APP_BASE_URL14}training/all`, filter)
  .then((resp:any)=> resp)
  .catch(e=> {return {data:[]}})
  const listIdCours= {
    ids:(await catalogeCourses).data.map((obj: any)=>{return obj.id})
  }
  const rating:any = await axiosToken.post(`${process.env.REACT_APP_BASE_URL8}feedback/rating`, listIdCours)
  .then((resp:any)=> resp)
  .catch(e=> {return {data:[]}})
  const duration = await axios.post(baseUrl+`courses/duration`, listIdCours.ids)
  .then((resp:any)=> resp)
  .catch(e=> {return {data:[]}})

  const coursesList=axios.all([profs, catalogeCourses, rating,duration]).then(axios.spread((...responses) => {
      return(responses[1].data.map((course: any) =>{
      return {
          professor:{
              name:responses[0].data.find((prof:any)=> prof.id===course.idProf)?.name
          },
          rating :{
            rating:responses[2].data.find((rate:any)=> Number(rate.idSubject)===Number(course.id))?.rating
          },
          duration :{
            duration:responses[3].data.find((obj:any)=> Number(obj.id)===Number(course.id))?.duration
          },
          ...course
       }}
      ))
  })).catch(errors => {
console.error(errors)
  })
    return dispatch({
      type: EReduxActionTypes.GET_CATALOG,
      data: await coursesList
    })
  }
}
 


export function changeStyleCardCourse(style: string): ThunkAction<Promise<IReduxChangeStyleCourseAction>, IReduxCoursesState, undefined, IReduxChangeStyleCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxChangeStyleCourseAction>) => {

    return dispatch({
      type: EReduxActionTypes.CHANGE_STYLE_CARD,
      data: style
    });
  }
}




export function getDurationCourse(courseId:any): ThunkAction<Promise<IReduxGetDurationAction>, IReduxCoursesState, undefined, IReduxGetDurationAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetDurationAction>) => {
    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const duration = await axios.post(baseUrl+`courses/duration`, courseId)
    .then((resp:any)=> resp.data)
    .catch(e=> {return {data:0}})
    return dispatch({
      type: EReduxActionTypes.GET_DURATION,
      data: duration
    });
  }
}