import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import { closeModal, openModal } from '../../global-state/actions/modal-actions';
import { createApplicationFile, clearApplicationFile, createFollow, setApplicationFile, sendMyRequest } from '../../global-state/actions/course-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import * as XLSX from "xlsx";
import templateFr from '../../styles/templates-xlsx/template-fr.xlsx'
import templateEn from '../../styles/templates-xlsx/template-en.xlsx'
import templateAr from '../../styles/templates-xlsx/template-ar.xlsx'
import { getInvitationsList } from '../../global-state/actions/student-training-actions';
import StudentMultiInvitationBeforeUploadElement from "../../elements/file-upload-card/before-upload-element-import-students";
interface Params {
    type: string;
    id: string;
}
const AddMultipleStudentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [name, setName] = useState('')
    const [checkBox, setCheckBox] = useState(false)
    const [template, setTemplate] = useState(templateEn)
    const [clicked, setClicked] = useState<boolean>(false)
    const [upload, setUpload] = useState<boolean>(true)

    useEffect(() => {
        if (localStorage.getItem('language') === "fr") {
            setTemplate(templateFr)
        }
        else if (localStorage.getItem('language') === "ar") {
            setTemplate(templateAr)
        }
        else {
            setTemplate(templateEn)
        }
    }, [])

    const readExcel = (file: any) => {
        setName(file.name)
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e: any) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws, { defval: "" });

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d: any) => {
            props.setInviteStudentsList(d)
            setItems(d);
        });
    };

    useEffect(() => {
        if(clicked===true && checkBox===true && name.length !== 0){
            props.setModalOpened(false)
            props.setModalListInviteOpened(true)
        }else if (clicked===false && checkBox===true && name.length !== 0){
            setClicked(true)
        }else if(clicked===true && name.length===0){
            setUpload(false)
        }
    }, [clicked])


    useEffect(() => {
        setClicked(false)
    }, [name,checkBox])


    return (
        <>
            <div className="mb-4">

                <span className="body-md">{t('hold')} <a
                    href={template}
                    download
                    className="btn-text outlined black-800 body-md bold"

                >
                    {t('downloadTemplate')}
                </a>  {t('toSee')}</span>
            </div>
            <div className=" mt-2 mb-3">
                <span className="form-title">{t("importListOfStudents")}</span>

            </div>
            <div className="mb-4">{
                name === '' ? <StudentMultiInvitationBeforeUploadElement t={t} addFile={(e: any) => { readExcel(e[0]); }} clicked={upload} /> : <>

                    <div className="bg-with-border white " >
                        <div className="d-flex align-items-center justify-content-between  " style={{padding:"35px 32px 35px 32px"}}>
                            <div className="d-flex align-items-center">
                                <div className="me-3 d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                                    <span className="material-icons-outlined black-800"> cloud_upload </span>
                                </div>
                                <div className="d-flex flex-column black-800">
                                    <span className="form-edit mb-1">{name}</span>
                                </div>



                            </div>
                            <div className="d-flex flex-end align-items-center">
                                <button type="button" className="btn-Secondary medium" onClick={() => { setName('') }}>
                                    {t("Remplacer")}
                                </button>

                            </div>
                        </div>
                    </div>
                </>
            }
            </div>

            <div className="d-flex">
                <input className="input-style-checkbox" type="checkbox" id="checkBtn" onChange={(e) => {setCheckBox(e.target.checked); setClicked(false)}} />
                <label htmlFor="checkBtn" />
                <span className={`body-sm ms-2 ${(clicked === true && checkBox===false ) ? "error-input" : ""}`}>J'ai le consentement des utilisateurs enregistrés dans ce fichier, selon la loi de <br></br> protection des données personnelles du pays en vigueur.</span>
            </div>
            <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                <button className="btn-Secondary large me-3" onClick={() => {props?.setModalOpened(false)}}  >
                    {t('annuler')}
                </button>
                <button className="btn-Primary large "
                    onClick={() => { setClicked(true) }}
                >
                    {t('continue')}
                </button>

            </div>
        </>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.courseReducer.courseInfo,
    training: state.courseReducer.training,
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,
    history: ownProps?.history,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    sendMyRequest,
    openModal,
    clearApplicationFile,
    createApplicationFile,
    createFollow,
    setApplicationFile,
    getInvitationsList,
    closeModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddMultipleStudentComponent);

function addFile(file: any, index: any) {
    throw new Error("Function not implemented.");
}
