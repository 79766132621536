import { ComponentProps, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { getCountStudent } from "../../global-state/actions/training-actions";
import { AppState } from "../../global-state/store/root-reducers";
import EmptyListElement from "../empty-list/normal-empty-list-element";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RowExpandElement from "./row-expanded-element";
import { updateIndexTraining } from "../../services/training.service";
import { Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { ModalIDS } from "../../components/modals/modal-ids";
import { openModal, closeModal } from "../../global-state/actions/modal-actions";

const DragableTableWithExpandElement: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [open, setOpen] = useState([true]);
    const [index, setIndex] = useState(-1);
    const [data, setData] = useState<any>(props?.data);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false)

    const [isShown, setIsShown] = useState([false]);



    const handleDragEnd = async (e: any) => {

        
        for (let index = 0; index < props?.size; index++) {
            open[index] = true;
        }
        if (!e.destination) return;
        let dataCopy = [...data.body]
        const temp = dataCopy[e.source.index];
        dataCopy[e.source.index] = dataCopy[e.destination.index];
        dataCopy[e.destination.index] = temp;
        let newRows = [...dataCopy]
        for (let index = 0; index < newRows.length; index++) {
            newRows[index].row.course.index = index + 1;
        }

        const courseArray = newRows.map(item => ({
            id: item.row.course.id,
            index: item.row.course.index
        }));


        const updatedData = { ...data, body: newRows };
        setData(updatedData)
        setSnackBarOpen(true);
        await updateIndexTraining(courseArray)
    };


    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);

    useEffect(() => {
        setData(props?.data)
    }, [props?.data]);
    const doAction = async (e: any, actionName: string, row: any, index: number) => {
        const openCopy = [...open];
        if ((actionName == "openexpand")) {

            openCopy[index] = !openCopy[index];
            if (openCopy[index] === true) {
                setIndex(index);
            }

            setOpen(openCopy);
            if (data.body[index].row.course.countInscribed == null && data.body[index].row.course.countCertified == null && data.body[index].row.course.countCandidates == null) {
                props?.getCountStudent(data.body[index].row.course).then(
                    (res: any) => {
                        data.body[index].row.course.countInscribed = res.data.countInscribed;
                        data.body[index].row.course.countCertified = res.data.countCertified;
                        data.body[index].row.course.countCandidates = res.data.countCandidates;
                    }
                );
            }
            e.stopPropagation();

        } else if ((actionName == "open")) {


            if (data.body[index].row.course.isVisible && data.body[index].row.course.status == "PUBLISHED") {
              await props?.openModal({ idModal: ModalIDS.VISE_TRAINING, size: "md", data: data.body[index] })//masquer
              e.stopPropagation();
            } else if (!data.body[index].row.course.isVisible && data.body[index].row.course.status == "PUBLISHED") {
              await props?.openModal({ idModal: ModalIDS.NOT_VISE_TRAINING, size: "md", data: data.body[index] })//afficher
              e.stopPropagation();
      
            }
      
      
          } else if (actionName !== '') {

            e.stopPropagation();

            const object = {
                row: row,
                action: actionName,
                index: index
            }
            props?.doAction(object);
        }
    }


    const doActionHover = (index: number) => {
        const openCopy = [...isShown];
        openCopy[index] = true;
        setIsShown(openCopy);

    }

    const doActionafterHover = (index: number) => {
        const openCopy = [...isShown];
        openCopy[index] = false;
        setIsShown(openCopy);

    }



    useEffect(() => {
        for (let index = 0; index < props?.size; index++) {
            open[index] = true;
            isShown[index] = false
        }

    }, []);

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        ...draggableStyle,
        opacity: isDragging ? 0.9 : 1,
        position: isDragging ? "fixed" : "static",
        zIndex: isDragging ? 9999 : "auto",
        paddingTop: isDragging && 0,
        marginTop: isDragging && 0

    })

    return (
        <>
            <div className="my-3" >
                <div className="table-responsive d-flex mx-auto" style={{ maxWidth: 1200 }}>

                    <DragDropContext onDragEnd={handleDragEnd}>
                        <table className="w-100 custom-table">
                            <thead className="mb-4">
                                <tr>
                                    <th></th>
                                    {data?.headers?.map((header: any) =>
                                    (<th key={header.title} scope={header.scope} className={header.classNames}>
                                        <button className={header.buttonClasses}>
                                            {t(header.title)}
                                        </button>
                                    </th>)

                                    )}
                                    <th key="Actions" scope="col" className="">

                                        <button className="d-flex ms-auto button-trie px-0">
                                            {t('Actions')}

                                        </button>
                                    </th>
                                </tr>
                            </thead>


                            <Droppable droppableId="droppable-1">
                                {(provider) => (
                                    <tbody className="w-100 "
                                        style={{ height: 30 }}
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}


                                    >
                                        {data?.body?.map((data: any, index: any) => (

                                            <Draggable
                                                key={JSON.stringify(data.row.course.id)}
                                                draggableId={JSON.stringify(data.row.course.id)}
                                                index={index}
                                            >

                                                {(provider, snapshot) => (
                                                    <>
                                                        <tr
                                                            {...provider.draggableProps}
                                                            {...provider.dragHandleProps}
                                                            onMouseEnter={() => doActionHover(index)}
                                                            onMouseLeave={() => isShown[index] = false}
                                                            ref={provider.innerRef}
                                                            style={getItemStyle(snapshot.isDragging, provider.draggableProps.style,)}
                                                    
                                                            onClick={(e: any) => doAction(e, data.row.actionName, data.row, index)}
                                                            className={data.row.classNames}>
                                                            {
                                                                isShown[index] === true ?
                                                                    <td

                                                                        key={index + 'column'}
                                                                        style={{ 
                                                                            position: 'relative',
                                                                         
                                                                          }}
                                                                    >
                                                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z" fill="#272727" />
                                                                        </svg>
                                                                    </td>
                                                                    :
                                                                    <td

                                                                        key={index + 'column'}
                                                                        className="indexes"
                                                                        style={{ 
                                                                            position: 'relative',
                                                                  
                                                                          }}
                                                                    >
                                                                        {data.row.course.index}
                                                                    </td>
                                                            }


                                                            {data.row.columns.map((column: any, index: number) => (

                                                                <td

                                                                    key={index + 'column'}
                                                                    onClick={(e) => doAction(e, column.actionName, data.row, index)}
                                                                    className={column.classNames}
                                                                    style={{ position: 'relative' }}
                                                                    colSpan={column.colspan}

                                                                >
                                                                    {column.content}
                                                                </td>


                                                            ))}

                                                            <td key={index + 'columnc'} >
                                                                <div className="d-flex mx-auto flex-row" style={{ maxWidth: 80 }}>
                                                                    <div className="d-flex flex-row  align-items-center black-800 me-4" onClick={(e) => { doAction(e, "open", data.row, index) }}>

                                                                        {data.row.course.isVisible && data.row.course.status == "PUBLISHED" && (data.row.course.idProf != 0 && data.row.course.idProf != null) ? <>
                                                                            <span className="material-icons-outlined" style={{cursor:"pointer"}}>visibility</span>

                                                                        </> :
                                                                            (!data.row.course.isVisible && data.row.course.status == "PUBLISHED") && (data.row.course.idProf != 0 && data.row.course.idProf != null) ?
                                                                                <> <span className="material-icons-outlined" style={{cursor:"pointer"}}>visibility_off</span>
                                                                                </> :
                                                                                <></>}
                                                                    </div>
                                                                    <div className="d-flex flex-row  align-items-center black-800" onClick={(e) => { doAction(e, "openexpand", data.row, index) }}>

                                                                        {!open[index] ? <>
                                                                            <span className="material-icons-outlined" style={{cursor:"pointer"}}>expand_less</span>

                                                                        </> :
                                                                            <> <span className="material-icons-outlined" style={{cursor:"pointer"}}>expand_more</span></>}
                                                                    </div>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        {!open[index] &&
                                                            <RowExpandElement data={data.row.course} index={index} open={open} loading={loading} dragable={true} />
                                                        }
                                                        
                                                        </>
                                                        
                                                )}
                                            
                                            </Draggable>
                                                 
                                        ))}
                                       {provider.placeholder}
                                    </tbody>
                                )}
                            </Droppable>

                        </table>
                    </DragDropContext>
                </div>
                {data?.body?.length === 0 && (
                    <div className="w-100 mt-7">
                        <div className="msg-formation-list-empty">
                            <EmptyListElement lines={[t('emptyListFormation1'), t('emptyListFormation2')]} />
                        </div>
                    </div>
                )}
            </div>

            <Snackbar open={snackBarOpen} autoHideDuration={1000} onClose={() => { setSnackBarOpen(!snackBarOpen) }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <div style={{ marginTop: 136 }}>
                    <Alert severity="success" onClose={() => { setSnackBarOpen(!snackBarOpen) }}>
                        Ordre de la formation changé dans le catalogue
                    </Alert>
                </div>
            </Snackbar>
        </>
    );

}
const mapStateToProps = (state: AppState) => ({
    countStudent: state.trainingReducer.countStudent,
    loading: state.trainingReducer.loadingcountStudent,
    LengthCourses: state.courseReducer.LengthCourses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { getCountStudent,
            openModal,
            closeModal }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DragableTableWithExpandElement);










