import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import TableElement from '../../elements/table/normal-table-element';
import { couponStatus, couponType } from '../../helpers/commons';
import { useHistory } from 'react-router-dom';
import {emptyCoupon} from '../../global-state/actions/coupon-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';


const TableAllCouponsComponent: FC<ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const history = useHistory()

    const status = [
        { label: "Planifié", color: "warning" },
        { label: "En cours", color: "success" },
        { label: "Éxpiré", color: "secondary" },
        { label: "Annulé", color: "dark" }, 
        { label: "Brouillon", color: "primary" }, 
    ]   
    const [couponList, setCouponList] = useState<any>();

    const headers = [
        { title: "Nom du coupon", sorted: true, classNames: "w-20 ", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: "Statut", sorted: true, classNames: "w-20 ", scope: "col", buttonClasses: "d-flex button-trie mx-auto" },
        { title: "date de Début", sorted: true, classNames: "w-15 ", scope: "col", buttonClasses: "d-flex button-trie mx-auto" },
        { title: "date de fin", sorted: true, classNames: "w-15 ", scope: "col", buttonClasses: "d-flex button-trie mx-auto" },
        { title: "utilisé", sorted: true, classNames: "w-15 ", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]

    let getData = () => {
        const data = {
            headers: headers,
            body: props?.coupons?.map((coupon: any) => {
                let statusCoupon = "";
                if(coupon.status === "DRAFT") {
                    statusCoupon = "Brouillon";
                } else if(coupon.status === "INACTIVE") {
                    statusCoupon = "Annulé";
                } else {
                    statusCoupon = couponStatus(coupon?.startAt, coupon?.expireAt);
                }
                let actualStatus: any = status.find((e: any) => e?.label === statusCoupon) || '';
                let coupontype = couponType(coupon?.promotion.promoType);
                let couponTypeTranslate = t(`${coupontype}`);
                return {
                    row: {
                        coupon: coupon,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            {
                                content:
                                    <div className="d-flex align-items-start flex-column">
                                        <span className="body-sm bold black_800">{`${coupon?.promotion.promoCode}`}</span>
                                        <span className="body-sm neutral-2">{`${coupon?.promotion.promoValue}${couponTypeTranslate}`}</span>
                                    </div>
                                , classNames: "", actionName: ''
                            },
                            { content: statusCoupon, classNames: `text-center badge-text ${actualStatus?.color}`, actionName: '' },
                            { content: coupon?.startAt?.substring(0, 10).split("-").reverse().join("/"), classNames: " text-center", actionName: '' },
                            { content: (coupon?.expireAt !== null) ? coupon?.expireAt.substring(0, 10).split("-").reverse().join("/") : "-", classNames: "text-center", actionName: '' },
                            { content: coupon?.usedBy?.length, classNames: ` text-end`, actionName: '' },
                        ],
                    }
                }
            })
        }
        setCouponList(data);

    }
    useEffect(() => {

        getData();
    }, [props?.coupons]);
    const doAction = async(value: any) => {
        let couponData = value.row.coupon
        await history.push({ pathname: `${returnStartPathUrl()}/coupon/create-coupon`, state: { couponData }})
    }


    return (

        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="modal-body  mt-3" style={{ paddingTop: 0 }}>
                    <TableElement data={couponList} doAction={(value: any) => { doAction(value) }} />
                </div>
            </div>
        </div>

    );
}


export default (TableAllCouponsComponent);






