import { ComponentProps, FC, useState, useEffect } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { useHistory } from "react-router";
import VisitChart from "../chart-card/visit-chart";
import InscriptionChart from "../chart-card/registration-chart";
import EtudeChart from "../chart-card/level-of-study-chart";
import EtudiantChart from "../chart-card/connected-students-chart"
import { returnStartPathUrl } from '../../../helpers/domainCheck';
const CardActivityProf: FC<ComponentProps<any>> = (props) => {
  const history = useHistory()
  const { t } = useTranslation();
  const [years, setYears] = useState([])
  const [selectedValue, setSelectedValue] = useState(Number(new Date().getFullYear()))
  const [active, setActive] = useState("FirstCard");
  const [followersNumber, setFollowersNumber] = useState<any>();
  const redirect = () => {
    history.push(`${returnStartPathUrl()}/courses/my-courses/`)
  }
  const redirecto = () => {
    history.push({ pathname: `${returnStartPathUrl()}/students/all`, state: { locationTab: "Candidate" } })
  }
  function getYears(value: any) {
    setYears(value);
  }
  const handleChange = (e: any) => {

    setSelectedValue(Number(e.target.value));
  };
  useEffect(() => {
    let value = Number(props?.followers / props?.visit) > 1 ? 100 : (Number(props?.followers / props?.visit) * 100)
    let visit = isNaN((props?.followers / props?.visit)) ? 0 : value;
    setFollowersNumber(visit)
  }, []);



  return (
    <div className="container p-0 pt-3" style={{ maxWidth: '1224px' }}>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row main-list-activities">
          <div className="w-100 mb-4">
            <div className="d-flex flex-column">
              <div className='d-flex flex-row mb-4 second-list-activities'>
                <div className="activity-card large col-xs-8 me-4 w-100 second-card-list-activities" >
                  <div className="d-flex flex-row flex-wrap justify-content-between">
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-column align-items-start me-4">
                        <span className="H2-Headline" style={{ lineHeight: '43px' }}>{props?.subscriptionProf}</span>
                        <span className="body-sm bold neutral-3">{t('Registered')}</span>
                      </div>
                      <div className="d-flex align-items-start mt-2 pt-1">
                        <div className="d-flex flex-row align-items-center">
                          <span className="H4-Subtitle neutral-3 me-1 min-content">{props?.newInscribed}</span>
                          <span className="body-sm bold neutral-2 min-content">{t('New')}</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-column align-items-start me-4">
                        <span className="H2-Headline neutral-3" style={{ lineHeight: '43px' }}>{props?.formation}</span>
                        <span className="body-sm bold neutral-3">{t('Inscribed')}</span>
                      </div>
                      <div className="d-flex flex-column align-items-start me-4">
                        <span className="H2-Headline neutral-3" style={{ lineHeight: '43px' }}>{props?.certif}</span>
                        <span className="body-sm bold neutral-3">{t('certified')}</span>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <span className="H2-Headline neutral-3" style={{ lineHeight: '43px' }}>{props?.refused}</span>
                        <span className="body-sm bold neutral-3">{t('Suspended')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="activity-card small col-xl-4 me-4 py-3 d-flex justify-content-between main-card-list-activities main-card-list-activities-end">
                  <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column me-4 ">
                      <span className="H2-Headline" style={{ lineHeight: '43px' }}>{props?.courseList?.length}</span>
                      <span className="body-sm bold neutral-3">{t('Course')}</span>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-between">
                      <div className="d-flex flex-row align-items-center mb-3">
                      </div>
                      <button className="btn-Secondary medium" onClick={redirect}>{t('Access')}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-xs-12 d-flex flex-row second-list-activities'>
                <div className="activity-card small col-xl-4 me-4 py-3 d-flex justify-content-between second-card-list-activities" >
                  <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column me-4 ">
                      <span className="H2-Headline" style={{ lineHeight: '43px' }}>{props?.appFile}</span>
                      <span className="body-sm bold neutral-3">{t('Candidates')}</span>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-between">
                      <div className="d-flex flex-row align-items-center mb-3">
                        <span className="H4-Subtitle neutral-3 me-1 min-content">{props?.newCandidate} </span>
                        <span className="body-sm bold neutral-2 min-content">{t('New')}</span>
                      </div>
                      <button className="btn-Secondary medium" onClick={redirecto}>{t('Access')}</button>
                    </div>
                  </div>
                </div>
                <div className="activity-card large col-xs-8 me-4 w-100 main-card-list-activities main-card-list-activities-end" >
                  <div className="d-flex flex-row flex-wrap">
                    <div className="d-flex flex-column align-items-start me-5">
                      <span className="H2-Headline" style={{ lineHeight: '43px' }}>{props?.connectedList?.studentNbre}</span>
                      <span className="body-sm bold neutral-3">{t('ConnectedStudents')}</span>
                    </div>
                    <div className="d-flex flex-column align-items-start me-4">
                      <span className="H2-Headline" style={{ lineHeight: '43px' }}>{props?.connectedList?.lastDays}</span>
                      <span className="body-sm bold neutral-3">{t('StudentsConnectedThisMonth')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="activity-card col-md-3 col-xs-3 d-flex mb-4" style={{ maxWidth: 282 }}>
            <div className="d-flex flex-column justify-content-around">
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex flex-row">
                  <span className="H2-Headline">{props?.visit}</span>

                </div>
                <span className="body-sm bold neutral-3">{t('CatalogueVisitorsInTheLast30Days')}</span>
              </div>
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex flex-row">

                  <span className="H2-Headline">         {followersNumber?.toFixed(1)
                  }      <span className="H3-Headline">%</span></span>

                </div>
                <span className="body-sm bold neutral-3">{t('ConversionRateOfApplicationsInTheLast30Days')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex row">
          <div className='col-md-12 col-xs-12 d-flex flex-row main-list-activities justify-content-between '>
            <div className="activity-card small padding-perso me-4 py-3 d-flex justify-content-between w-100 main-card-list-activities" >
              <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                <div className="d-flex flex-column">
                  <span className="H2-Headline" style={{ lineHeight: '43px' }}>
                    {(isNaN(props?.markMedian) || props?.markMedian === 0) ? 0 : Number(props?.markMedian).toFixed(1)}
                    <span className="H3-Headline neutral-3">/10</span></span>
                  <span className="body-sm bold neutral-3">{t('MedianCourseNotes')}</span>
                </div>
              </div>
            </div>
            <div className="activity-card small me-4 py-3 d-flex justify-content-between w-100 main-card-list-activities" >
              <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                <div className="d-flex flex-column me-4 ">
                  <span className="H2-Headline" style={{ lineHeight: '43px' }}>            {(isNaN(props?.avgage) || props?.avgage === 0) ? 0 : Number(props?.avgage).toFixed(0)}<span className="H3-Headline"> {t('Year')}</span></span>
                  <span className="body-sm bold neutral-3">{t('AverageAgeOfStudents')}</span>
                </div>
              </div>
            </div>
            <div className="activity-card small me-4 py-3 d-flex justify-content-between w-100 main-card-list-activities" >
              <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                <div className="d-flex flex-column me-4 ">
                  <span className="H2-Headline" style={{ lineHeight: '43px' }}>
                    {100 - (props?.percentagemale)}<span className="H3-Headline">%</span></span>
                  <span className="body-sm bold neutral-3">{t('Female')}</span>
                </div>
                <div className="d-flex flex-column me-4 ">
                  <span className="H2-Headline" style={{ lineHeight: '43px' }}>{props?.percentagemale}<span className="H3-Headline">%</span></span>
                  <span className="body-sm bold neutral-3">{t('Male')}</span>
                </div>
              </div>
            </div>
            <div className="activity-card small py-3 d-flex justify-content-between w-100">
              <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                <div className="d-flex flex-column me-4">
                  <span className="H4-Subtitle" style={{ lineHeight: '43px' }}>
                    {props?.ville?.length === 0 ? "..." : props?.ville?.map((a: any, index: number) => (
                      <>
                        {a}
                        {index !== props.ville.length - 1 && ", "}
                      </>
                    ))}
                  </span>
                  <span className="body-sm bold neutral-3">{t('MostRepresentedCities')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row mb-4 " style={{ justifyContent: "space-between " }} >
        <ButtonGroup style={{ marginTop: 56 }} aria-label="outlined">
          <Button className={(active === "FirstCard") ? "btn-Group liste active" : "btn-Group liste"} onClick={() => setActive("FirstCard")} >{t('VisitsAndApplicationsForRegistration')}</Button>
          <Button className={(active === "SecondCard") ? "btn-Group liste active" : "btn-Group liste"} onClick={() => setActive("SecondCard")}>{t('RegistrationsAndCertifications')}</Button>
          <Button className={(active === "ThirdCard") ? "btn-Group liste active" : "btn-Group liste"} onClick={() => setActive("ThirdCard")}>{t('ConnectedStudents')}</Button>
          <Button className={(active === "FourthCard") ? "btn-Group liste active" : "btn-Group liste"} onClick={() => setActive("FourthCard")}>{t('LevelOfStudy')}</Button>
        </ButtonGroup>

        <div className="select-input" style={{ color: "#272727", minWidth: 120, marginTop: 56, width: "auto" }}>
          <select className="select-city-register" value={selectedValue} onChange={(e) => handleChange(e)} >
            {
              years?.map((year) =>
                <option key={year} value={year}>{year}</option>)}
          </select>

        </div>

      </div>
      <div style={{ border: "1px solid #EDEDED", padding: 10 }}>
        {active === "FirstCard" && <VisitChart courseList={props?.courseList} selectedValue={selectedValue} getYears={getYears} />}
        {active === "SecondCard" && <InscriptionChart courseList={props?.courseList} selectedValue={selectedValue} getYears={getYears} />}
        {active === "FourthCard" && <EtudeChart courseList={props?.courseList} />}
        {active === "ThirdCard" && <EtudiantChart courseList={props?.courseList} selectedValue={selectedValue} getYears={getYears} />}
      </div>

    </div>);
}

export default CardActivityProf;
