import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.js';
import './index.scss'
import { datadogRum } from '@datadog/browser-rum';


if(process.env.REACT_APP_RUN_DATAGOG_RUM ==='true'){
    datadogRum.init({
        applicationId: 'dc16380a-580d-4d89-9945-8d68e779c786',
        clientToken: 'pub76ec11d917150999a8c44393e4322208',
        site: 'datadoghq.com',
        service:'koors-web-client',
        env:process.env.REACT_APP_DATADOG_ENV ,
        // Specify a version number to identify the deployed version of your application in Datadog 
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input'
    });
    
    // If you want to start measuring performance in your app, pass a function
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    datadogRum.startSessionReplayRecording();
}

    
ReactDOM.render( <App />, document.getElementById('root') );


reportWebVitals();
