import { ComponentProps, FC, useEffect, useState, useRef, useMemo } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getUserProfileDetails, updateProfile } from '../../global-state/actions/auth-actions';
import { setConnectedUser } from '../../global-state/actions/auth-actions';
import { getUserById } from '../../global-state/actions/user-auth-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getOffer, getContract } from '../../global-state/actions/app-container-actions';
import { getCountsApi } from '../../services/finance.service';

const LimitationComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [offer, setOffer] = useState<any>()
    const [profiles, setProfiles] = useState<any>()
    let history = useHistory()

    useEffect(() => {
        if (!!props?.subscription && !!props?.subscription?.idOffer) {
            props?.getOffer(props?.subscription?.idOffer)
        }
    }, [props?.subscription])

    useEffect(() => {
        if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
            props?.getContract(props?.schoolInfo?.id, "SCHOOL")
        }
    }, [props?.schoolInfo])

    useEffect(() => {
        if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
            getProfiles(props?.schoolInfo?.id)
        }
    }, [props?.schoolInfo])

    useEffect(() => {
        if (!!props?.offer) {
            setOffer(props?.offer)
        }
    }, [props?.offer])

    const getProfiles = async (idEntity: number) =>{
        const result = await getCountsApi(idEntity);
        setProfiles(result)
    }

    const goTo = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("offersList"), `${returnStartPathUrl()}/param`)
        history.push(`${returnStartPathUrl()}/list`)
    }

    return (<>

        <>
            <div className='w-100 d-flex flex-column p-4'>
                <div className='my-2' >
                    <span className="H3-Headline black-800">{offer?.label}</span>
                </div>
                <hr className="hr-nav my-3" />

                {!!profiles && profiles?.map((profile: any, index: any) => {
                    return <>
                        <div className='d-flex flex-column '>
                            {(profile.key != null) && <>
                                <div className='d-flex flex-row justify-content-between mb-4'>
                                    <span className="body-xl bold blach-800" >{profile.key == "nb_prof" ? t('Professeurs') : profile.key == "nb_cours" ? t('TitleFormations') : profile.key == "nb_student" ? t('students') : t('assistants')}</span>

                                    {profile.valueContract == -1 ? <div>

                                        <span className="body-md bold neutral-3" >{t('unlimited')}</span>
                                    </div> : <div>
                                        <span className="body-md black-800" >{profile.valueSchool}</span>
                                        <span className="body-md bold neutral-3" >/{profile.valueContract}</span>
                                    </div>}
                                </div>
                                <div className="progress-bar-bg limitation w-100">
                                    <div className={(profile.valueSchool / profile.valueContract) * 100 >= 100 ? "progress-bar-bg limitation danger" : "progress-bar-bg limitation success"} role="progressbar" style={{ width: (profile.valueSchool / profile.valueContract) * 100 > 100 ? 100 + "%" : (profile.valueSchool / profile.valueContract) * 100 + "%" }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                    </div>
                                </div>{(profile.valueSchool / profile.valueContract) * 100 >= 100 && <>
                                    <div className='notif-warning d-flex flex-row align-items-center mt-4'>
                                        <span className="material-icons Orange-800 me-3" style={{ fontSize: 32 }}>warning</span>
                                        <span className='body-md black-800'> {t('Vousavezatteintlalimitedaccesdevos')} {profile.key == "nb_prof" ? t('Professeurs').toLowerCase() : profile.key == "nb_cours" ? t('TitleFormations').toLowerCase() : profile.key == "nb_student" ? t('students').toLowerCase() : t('assistants').toLowerCase()}.
                                            <br />   {t('Vouspouvezajouterdes')}&nbsp;
                                            {profile.key == "nb_prof" ? t('Professeurs').toLowerCase() : profile.key == "nb_cours" ? t('TitleFormations').toLowerCase() : profile.key == "nb_student" ? t('students').toLowerCase() : t('assistants').toLowerCase()}
                                            &nbsp;
                                            {profile.key == "nb_cours" ? t('activeouenameliorantvotreforfait') : t('actifouenameliorantvotreforfait')}.</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-end mt-4">
                                        <button type="button" className="btn-Secondary medium" onClick={(e) => { goTo() }}>
                                            {t('Voirlesforfaits')}
                                        </button>
                                    </div>
                                </>
                                }

                            </>}

                        </div>
                        {(profile.key != null) && <hr className="hr-nav my-3" />}
                    </>
                })}

            </div>

        </>

    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    offer: state.schoolInfoReducer.offer,
    subscription: state.schoolInfoReducer.subscription,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedSchool: state.authReducer.connectedSchoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    updateProfile,
    getUserById,
    setConnectedUser, getOffer, getContract,
    redirect, clearHistoryArray

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LimitationComponent);


