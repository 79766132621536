import { FC, useEffect, useState } from "react";
import FooterComponent from "../../../components/footer-component";
import PathProgressPage from "./path-progress-page";
import CourseContentPage from "./course-content-page";
import PrivateRoute from "../../../navigations/PrivateRoute";
import PathCommunityPage from "./path-community-page";
import WebinarTrainingPage from "./webinar-training-page";
import NavbarProgressPathComponent from "../../../components/navigation-components/navbar-progress-path-component";
import ExamResultPage from "./exam-result-page";
import CustomSwitch from "../../../helpers/custom-switch";
import NavbarResponsiveCourseComponent from "../../../components/navigation-components/ navbar-responsive-progress-course";


const PathContainerContainer: FC<any> = (props: any) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (isMobile ? <> <NavbarResponsiveCourseComponent idPath={props.location.pathname.split("/")[props.location.pathname.split("/").length-1]} /><div className="page-wrapper-height">
    </div></> : <>
        <div className="page-wrapper-height">
            <NavbarProgressPathComponent />
            <CustomSwitch>
                <PrivateRoute exact={true} path={`${props?.match.path}/progressPath/:idPath`} component={PathProgressPage} />
                <PrivateRoute exact={true} path={`${props?.match.path}/resultExam/:idExam`} component={ExamResultPage} />
                <PrivateRoute exact={true} path={`${props?.match.path}/passCourse/:idCourse`} component={CourseContentPage} />
                <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/community/:id`} component={PathCommunityPage} />
                <PrivateRoute history={props?.history} role={['role_student']} exact={true} path={`${props?.match.path}/webinar/:id`} component={WebinarTrainingPage} />
            </CustomSwitch>
        </div>
        <FooterComponent />
    </>
    )
}
export default PathContainerContainer;