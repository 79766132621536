import { ComponentProps, FC} from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import HeaderAdmissionFormPageComponent from '../../components/headers-page/header-admission-form-page-component';
import AdmissionFormForStudentComponent from '../../components/admission/admission-form-for-student-component';
const AdmissionFormMyCoursesPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    return (
        <>
             <div style={{ backgroundColor: '#FFFFFF' }}>
                <HeaderAdmissionFormPageComponent/>
             </div>
            <AdmissionFormForStudentComponent/>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
   
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
   
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionFormMyCoursesPage);