
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useParams } from 'react-router-dom';
import HeaderPassExam from '../../components/headers-page/header-pass-exam'
import { AppState } from '../../global-state/store/root-reducers';
import { getExamToPass, getExistingAnswers, setCurrentQuestion, setQuestions, clearExamProgress } from '../../global-state/actions/exam-actions';
import PassExamComponent from '../../components/pass-exam/pass-exam-component';
import { checkProgressCourse, getCurrentCourse } from '../../global-state/actions/student-training-actions';
import LoadingPassExam from './loading-pass-exam';



interface Params {
    id: any
}
const PassExamPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { id } = useParams<Params>();
    const [exam, setExam] = useState(props?.examToPass)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props?.examToPass != undefined) {
            setExam(props?.examToPass)
        }
    }, [props?.examToPass])




    useEffect(() => {
        onInit(id);
        return () => {
            const finish = async () => {
                if (props?.currentCourse?.type === 'COURSE') {
                    if (props?.currentCourse?.followUp && props?.currentCourse?.followUp?.id) {
                        await props?.checkProgressCourse(props?.currentCourse?.followUp, props?.currentCourse?.id, props?.connectedUser?.idPerson, true, props?.currentCourse);
                    }
                    await props?.getCurrentCourse(props?.currentCourse?.followUp?.id);
                }
                await props?.clearExamProgress();
            }
            finish();
        };
    }, [id])

    const onInit = async (id: any) => {
        await props?.getExamToPass(id);
        await props?.getExistingAnswers(id, props?.connectedUser?.idPerson, props?.currentCourse?.followUp?.id);
        await props?.setCurrentQuestion(0)
    }

    useEffect(() => {
        if (props?.examToPass) {
            props?.setQuestions(props?.examToPass?.questionByQnaList)
        }
    }, [props?.examToPass])

    return (
        <div>
            {(exam !== undefined && props?.index !== undefined) ? <div style={!isMobile ? { paddingTop: "60px" } : {}}>
                <div>
                    <HeaderPassExam isMobile={isMobile} />
                </div>
                <div>
                    <PassExamComponent isMobile={isMobile} />
                </div>
            </div> :
                <div style={{ paddingTop: "104px" }}>
                    <LoadingPassExam />
                </div>}
        </div>
    )
}



const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamToPass,
            setQuestions,
            getExistingAnswers,
            setCurrentQuestion,
            clearExamProgress,
            checkProgressCourse,
            getCurrentCourse
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    examToPass: state.examReducer.examToPass,
    currentCourse: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    index: state.examReducer.indexQuestionToPass
});

export default connect(mapStateToProps, mapDispatchToProps)(PassExamPage);

