import { FC } from "react";
import OfferListPage from "./offer-page";
import { Route } from "react-router";
import CustomSwitch from "../../helpers/custom-switch";
import offerPaymentPage from "./offer-payment-page";
const OfferContainer: FC<any> = (props: any) => {
    return (
        <CustomSwitch>
            
            <Route exact={true} path={`${props?.match.path}/payment`} component={offerPaymentPage} />
            <Route exact={true} path={`${props?.match.path}/list`} component={OfferListPage} />
        </CustomSwitch>
    )
}
export default OfferContainer;