import Skeleton from '@material-ui/lab/Skeleton';

export default function HeaderStudentsListLoadingComponent() {

    return (
        <div style={{backgroundColor: '#FFFFFF'}}>
            <div className="col-md-12 mb-3 px-0 mx-auto mt-5"  style={{maxWidth: '1200px'}}>
                <div className="row justify-content-between">
                    <div className="col-md-12">
                        <Skeleton className="skeleton me-3" animation="wave" variant="rect" width={260} height={50} />
                    </div>

                </div>
            </div>
            <div className="col-md-12  mt-3 px-0 pb-4 mx-auto"  style={{maxWidth: '1200px'}}>
                <div className="row justify-content-between">
                    <div className="d-flex w-100 align-items-center jsutify-content-start">
                    <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                    <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
                    <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />   
                    <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />                    
                    </div>
                </div>
            </div>
        </div>
    );
}

