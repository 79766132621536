import axiosInstance from '../../helpers/interceptors';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IReduxTrainingState } from '../reducers/training-reducer'; 
export enum EReduxActionTypes {

    GET_TRAININGS = 'GET_TRAININGS',
    CLEAR_TRAININGS = 'CLEAR_TRAININGS',
    CREATE_TRAINING = 'CREATE_TRAINING',
    GET_TRAINING = 'GET_TRAINING',
    ADD_SUBJECT = 'ADD_SUBJECT',
    DELETE_SUBJECT = 'DELETE_SUBJECT',
    HANDLE_ERRORS = 'HANDLE_ERRORS',
    VALIDE_TRAINING = 'VALIDE_TRAINING',
    ADD_TEST = 'ADD_TEST',
    REMOVE_TEST = 'REMOVE_TEST',
    GET_TEST = 'GET_TEST',
    PATCH_GROUP = 'PATCH_GROUP',
    GET_MY_TRAININGS = 'GET_MY_TRAININGS', 
    GET_SUMMARY_TRAININGS = 'GET_SUMMARY_TRAININGS', 
    LOADING_TRAININGS = 'LOADING_TRAININGS',
    LOADING_ALL_COURSES = "LOADING_ALL_COURSES",
    GET_COUNT_STUDENT = 'GET_COUNT_STUDENT',
    LOADING_COUNT_STUDENT = 'LOADING_COUNT_STUDENT',
    TRAINING_NUMBER = 'TRAINING_NUMBER',
    CLEAR_MY_TRAININGS = 'CLEAR_MY_TRAININGS',
    PUBLISH_TRAINING = ' PUBLISH_TRAINING',
    LOADING_TRAININGS_SCROLL = 'LOADING_TRAININGS_SCROLL',
    GET_TRAININGS_FINANCE = 'GET_TRAININGS_FINANCE',
    GET_TRAININGS_FOR_ASSISITANT = "GET_TRAININGS_FOR_ASSISITANT",
    SEARCH_TRAINING_ASSISTANT = "SEARCH_TRAINING_ASSISTANT",
    SET_TAB_ALL_TRAINING = "SET_TAB_ALL_TRAINING",
    GET_SESSION_SUBTITLE_VIMEO_UPLOADED_LOADING = 'GET_SESSION_SUBTITLE_VIMEO_UPLOADED_LOADING',
    GET_SESSION_SUBTITLE_VIMEO_UPLOADED_FAILED = 'GET_SESSION_SUBTITLE_VIMEO_UPLOADED_FAILED',
    GET_SESSION_SUBTITLE_VIMEO_UPLOADED_SUCCESS = 'GET_SESSION_SUBTITLE_VIMEO_UPLOADED_SUCCESS',
    GET_PATH_SUBTITLE_VIMEO_UPLOADED_SUCCESS = 'GET_PATH_SUBTITLE_VIMEO_UPLOADED_SUCCESS',
    GET_PATH_SUBTITLE_VIMEO_UPLOADED_FAILED = 'GET_PATH_SUBTITLE_VIMEO_UPLOADED_FAILED',
    GET_PATH_SUBTITLE_VIMEO_UPLOADED_LOADING = 'GET_PATH_SUBTITLE_VIMEO_UPLOADED_LOADING'
}
interface IReduxBaseAction {
  type: EReduxActionTypes;
}

export interface IReduxGetCountStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COUNT_STUDENT | EReduxActionTypes.LOADING_COUNT_STUDENT;
  data: any;
}
export interface IReduxChangeTabAllTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_TAB_ALL_TRAINING;
  data: any
}
export interface IReduxCreateTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_TRAINING;
  data: any;
}
export interface IReduxValideTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.VALIDE_TRAINING;
  data: any;
}
export interface IReduxClearTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_TRAININGS;
  data: any;
}
export interface IReduxGetTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TRAINING;
  data: any;
}
export interface IReduxGetTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TRAININGS;
  data: any[];
}

export interface IReduxAddSubjectAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_SUBJECT;
  data: any;
  trainingType:string
}
export interface IReduxDeleteSubjectAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_SUBJECT;
  data: any;
}
export interface IReduxHandleErrorsAction extends IReduxBaseAction {
  type: EReduxActionTypes.HANDLE_ERRORS
  data: any,
  trainingType: string
}
export interface IReduxAddTestAction extends IReduxBaseAction {
  type: EReduxActionTypes.ADD_TEST
  data: any,
  trainingType: string
}
export interface IReduxRemoveTestAction extends IReduxBaseAction {
  type: EReduxActionTypes.REMOVE_TEST
  data: any,
  trainingType: string
}
export interface IReduxGetTestAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TEST
  data: any, 
  trainingType: string
}
export interface IReduxPatchGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.PATCH_GROUP;
  data: any,
  trainingType: string
}
export interface IReduxLoadingTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_TRAININGS
  data: any;
  pageNumber:any
  scrollOrNot:any
}
export interface IReduxLoadingScrollTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_TRAININGS_SCROLL
  data: any;
  pageNumber:any
  scrollOrNot:any
}
export interface IReduxLoadingAllCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_ALL_COURSES;
}
export interface IReduxGetMyTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_MY_TRAININGS | EReduxActionTypes.LOADING_TRAININGS | EReduxActionTypes.LOADING_TRAININGS_SCROLL;
  data: any;
  pageNumber:any
  scrollOrNot:any
}
export interface IReduxGetSummaryTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SUMMARY_TRAININGS | EReduxActionTypes.LOADING_TRAININGS | EReduxActionTypes.LOADING_TRAININGS_SCROLL;
  data: any;
  pageNumber:any
  scrollOrNot:any
}

export interface IReduxGetTrainingsFinanceAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TRAININGS_FINANCE ;
  data: any;
  pageNumber:any
}
export interface IReduxTrainingNumberAction extends IReduxBaseAction {
  type: EReduxActionTypes.TRAINING_NUMBER
  data: any;
}
export interface IReduxClearMyTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_MY_TRAININGS 
}
export interface IReduxPublishTrainingsAction extends IReduxBaseAction {
  type: EReduxActionTypes.PUBLISH_TRAINING
}


export interface IReduxGetMyTrainingsForAssistantAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TRAININGS_FOR_ASSISITANT | EReduxActionTypes.LOADING_ALL_COURSES ;
  data: any;
}


export interface IReduxSearchTrainingAssistantAction extends IReduxBaseAction {
  type: EReduxActionTypes.SEARCH_TRAINING_ASSISTANT ;
  data: any;
}

export interface IReduxGetSessionSubtitleAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_SUCCESS | EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_FAILED | EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_LOADING;
  data?: any;
}

export interface IReduxGetPathSubtitleAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_SUCCESS | EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_FAILED | EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_LOADING;
  data?: any;
}

export function changeTabAllTrainings(actualTab: string): ThunkAction<Promise<IReduxChangeTabAllTrainingsAction>, IReduxTrainingState, undefined, IReduxChangeTabAllTrainingsAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxChangeTabAllTrainingsAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_TAB_ALL_TRAINING,
      data: { tab: actualTab }
    });
  }
}

export function getCountStudent(filter:any): ThunkAction<Promise<IReduxGetCountStudentAction>, IReduxTrainingState, undefined, IReduxGetCountStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetCountStudentAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL14;
    dispatch({type:EReduxActionTypes.LOADING_COUNT_STUDENT,data:undefined,pageNumber:undefined, scrollOrNot: undefined});

    let storedStudent = await axiosInstance.post(baseUrl + "training/getCountStudents",filter).then(res => { return res.data }).catch(err => {return []} );
    
    return dispatch({
      type: EReduxActionTypes.GET_COUNT_STUDENT,
      data: storedStudent
    });

  }
}

export function createTraining(type: string, training: any): ThunkAction<Promise<IReduxCreateTrainingAction>, IReduxTrainingState, undefined, IReduxCreateTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxCreateTrainingAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    
    let storedTraining = await axiosInstance.post(baseUrl + `${type}`, training).then(res => { return res.data }).catch(err => console.error(err));
    
    return dispatch({
      type: EReduxActionTypes.CREATE_TRAINING,
      data: storedTraining
    });

  }
}

export function valideTraining(type: string, training: any): ThunkAction<Promise<IReduxValideTrainingAction>, IReduxTrainingState, undefined, IReduxValideTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxValideTrainingAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
        let baseURL_offer = process.env.REACT_APP_BASE_URL11;
    let storedTraining = await axiosInstance.post(baseUrl + `${type}`, training)
    .then(res => { return res.data })
    .catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.VALIDE_TRAINING,
      data: {content: storedTraining, type: type}
    });

  }
}
export function getTrainings(filter: any): ThunkAction<Promise<IReduxGetTrainingsAction>, IReduxTrainingState, undefined, IReduxGetTrainingsAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetTrainingsAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let trainings = await axiosInstance.post(baseUrl + `training/allowed/contents`, filter).then(res => { return res.data }).catch(err => {return []});
    return dispatch({
      type: EReduxActionTypes.GET_TRAININGS,
      data: trainings
    });

  }
}

export function getTrainingById(type: string, id: any): ThunkAction<Promise<IReduxGetTrainingAction>, IReduxTrainingState, undefined, IReduxGetTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetTrainingAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let training = await axiosInstance.get(baseUrl + `${type}/${(type === 'sessions')?'getSessionById/':''}${id}`)
    .then(res => { return res.data })
    .catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.GET_TRAINING,
      data: {type: type, training}
    });

  }
}

export function clearTraining(type: string): ThunkAction<Promise<IReduxClearTrainingAction>, IReduxTrainingState, undefined, IReduxClearTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxClearTrainingAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_TRAININGS,
      data: type
    });

  }

}
export function deleteTraining(type: string, trainingId: number): ThunkAction<Promise<IReduxClearTrainingAction>, IReduxTrainingState, undefined, IReduxClearTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxClearTrainingAction>) => {

    const baseUrl = process.env.REACT_APP_BASE_URL;
    await axiosInstance.delete(`${baseUrl}training/${trainingId}`)
      .catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.CLEAR_TRAININGS,
      data: type
    });

  }

}
export function addSubject(type: string, subject: any): ThunkAction<Promise<IReduxAddSubjectAction>, IReduxTrainingState, undefined, IReduxAddSubjectAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxAddSubjectAction>) => {
    return dispatch({
      type: EReduxActionTypes.ADD_SUBJECT,
      data:  subject,
      trainingType:type
    });

  }

}
export function deleteSubject(trainingId: string, subjectId: number): ThunkAction<Promise<IReduxDeleteSubjectAction>, IReduxTrainingState, undefined, IReduxDeleteSubjectAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxDeleteSubjectAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let training = await axiosInstance.delete(`${baseUrl}training/${trainingId}/subject/${subjectId}`)
    .then(res => { return res.data })
    .catch(err => console.error(err));
    
    return dispatch({
      type: EReduxActionTypes.DELETE_SUBJECT,
      data: training
    });
  }
}

export function handleErrors(type: string, errors: any): ThunkAction<Promise<IReduxHandleErrorsAction>, IReduxTrainingState, undefined, IReduxHandleErrorsAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxHandleErrorsAction>) => {
    return dispatch({
      type: EReduxActionTypes.HANDLE_ERRORS,
      data: errors,
      trainingType: type
    });

  }
}
export function addTest(test: any): ThunkAction<Promise<IReduxAddTestAction>, IReduxTrainingState, undefined, IReduxAddTestAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxAddTestAction>) => {

    let storedTest = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL6}exams/createOrUpdateExam`, test).then(res => { return res.data }).catch(err => console.error(err));
    
    return dispatch({
      type: EReduxActionTypes.ADD_TEST,
      data: storedTest,
      trainingType:test?.entityType
    });
  }
}
export function removeTest(type: string,id: number): ThunkAction<Promise<IReduxRemoveTestAction>, IReduxTrainingState, undefined, IReduxRemoveTestAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxRemoveTestAction>) => {
    await axiosInstance.post(`${process.env.REACT_APP_BASE_URL6}exams/deleteExam/${id}`, null)
    .then(async (res: any) => {
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.REMOVE_TEST,
      data: id,
      trainingType:type
    });
  } 
}
export function getTest(type: string,id: number): ThunkAction<Promise<IReduxGetTestAction>, IReduxTrainingState, undefined, IReduxGetTestAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetTestAction>) => {
    const test= await axiosInstance.post(`${process.env.REACT_APP_BASE_URL6}exams/getByTrainings`,{entities:[{entityType:type,entityId:id}]}).then(async (res: any) => {
      return res.data;
    }).catch(err =>[]);
    return dispatch({
      type: EReduxActionTypes.GET_TEST,
      data: test,
      trainingType:type
    });
  } 
}

export function patchGroup(training: any): ThunkAction<Promise<IReduxPatchGroupAction>, IReduxTrainingState, undefined, IReduxPatchGroupAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxPatchGroupAction>) => {
     let groups=  await axiosInstance.post(process.env.REACT_APP_BASE_URL + "training/groups", training).then(res => { return res.data }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.PATCH_GROUP,
      data: groups,
      trainingType:training?.type
    });
  }
}
export function getMyTrainings(filter: any, action: string): ThunkAction<Promise<IReduxGetMyTrainingsAction>, IReduxTrainingState, undefined, IReduxGetMyTrainingsAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetMyTrainingsAction>) => {
    if(action === 'first time') {
      dispatch({type:EReduxActionTypes.LOADING_TRAININGS,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
    } else {
      dispatch({type:EReduxActionTypes.LOADING_TRAININGS_SCROLL,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
    }
    let trainings = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/allTrainings`, filter).then(res => { return res.data; }).catch(err => {return []})
    let pageNumber=trainings? filter?.pageNumber+1:filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.GET_MY_TRAININGS,
      data: trainings,
      pageNumber,
      scrollOrNot: action
    })
  }
}
  export function getSummaryTrainings(filter: any, action: string): ThunkAction<Promise<IReduxGetMyTrainingsAction>, IReduxTrainingState, undefined, IReduxGetSummaryTrainingsAction> {
    return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetMyTrainingsAction>) => {
      if(action === 'first time') {
        dispatch({type:EReduxActionTypes.LOADING_TRAININGS,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
      } else {
        dispatch({type:EReduxActionTypes.LOADING_TRAININGS_SCROLL,data:undefined,pageNumber:undefined, scrollOrNot: undefined});
      }
      let trainings = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/trainingSummaries`, filter).then(res => { return res.data; }).catch(err => {return []})
      let pageNumber=trainings? filter?.pageNumber+1:filter?.pageNumber
      return dispatch({
        type: EReduxActionTypes.GET_MY_TRAININGS,
        data: trainings,
        pageNumber,
        scrollOrNot: action
      })
    }
  }
export function getTrainingsFinance(filter: any): ThunkAction<Promise<IReduxGetTrainingsFinanceAction>, IReduxTrainingState, undefined, IReduxGetTrainingsFinanceAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetTrainingsFinanceAction>) => {

    let trainings = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/getAllDir`, filter).then(res => { return res.data; }).catch(err => {return []})
    let pageNumber=trainings? filter?.pageNumber+1:filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.GET_TRAININGS_FINANCE,
      data: trainings,
      pageNumber,
    })
  }
}

export function getTrainingsForAssistant(filter: any): ThunkAction<Promise<IReduxGetMyTrainingsForAssistantAction>, IReduxTrainingState, undefined, IReduxGetMyTrainingsForAssistantAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetMyTrainingsForAssistantAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_ALL_COURSES, data: undefined })

    let trainings = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/allTAssistant`, filter).then(res => { return res.data; }).catch(err => {return []})
    return dispatch({
      type: EReduxActionTypes.GET_TRAININGS_FOR_ASSISITANT,
      data: trainings,
      
    })
  }
}


export function getTrainingNumber(idProf: any, idSchool:any): ThunkAction<Promise<IReduxTrainingNumberAction>, IReduxTrainingState, undefined, IReduxTrainingNumberAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxTrainingNumberAction>) => {
   
    let trainingNumber:number|undefined = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}training/countTrainingByTeacher/${idProf}/${idSchool}`).then(res => { return res.data }).catch(err => {  return 0;})
  
     return dispatch({
      type: EReduxActionTypes.TRAINING_NUMBER,
      data: trainingNumber
    })
  }
}
export function clearMyTrainings(): ThunkAction<Promise<IReduxClearMyTrainingsAction>, IReduxTrainingState, undefined, IReduxTrainingNumberAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxClearMyTrainingsAction>) => {
     return dispatch({
      type: EReduxActionTypes.CLEAR_MY_TRAININGS
    })
  }
}
export function publishTraining(value: string,idUser:number,idProf:number,obj:any): ThunkAction<Promise<IReduxPublishTrainingsAction>, IReduxTrainingState, undefined, IReduxPublishTrainingsAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxPublishTrainingsAction>) => {
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    obj['status'] = value;

    axiosInstance.post(baseUrl2 + 'person/coursvalidation/' + idUser + '/' + idProf, obj)
    .catch(error => console.error(error));
     return dispatch({
      type: EReduxActionTypes.PUBLISH_TRAINING
    })
  }
}


export function searchTrainingsAssistant(searchFilter: any): ThunkAction<Promise<IReduxSearchTrainingAssistantAction>, IReduxTrainingState, undefined, IReduxSearchTrainingAssistantAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxSearchTrainingAssistantAction>) => {
    
    let trainings = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/searchAssistantTrainings`, searchFilter).then(res => { return res.data; }).catch(err => {return []})
    return dispatch({
      type: EReduxActionTypes.SEARCH_TRAINING_ASSISTANT,
      data: trainings,
      
    })
  }
}



export function getSessionSubtitleVimeoUploaded(idVideo: any): ThunkAction<Promise<IReduxGetSessionSubtitleAction>, IReduxTrainingState, undefined, IReduxGetSessionSubtitleAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetSessionSubtitleAction>) => {


    const res = await axiosInstance
      .get(
        `${
          process.env.REACT_APP_BASE_URL4
        }getAllSubtitles/${idVideo}`
      )
      .then((response) => {
        return response.data;
      });

    if (!res) {
      return dispatch({
        type: EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_FAILED,
      });
    }
    return dispatch({
      type: EReduxActionTypes.GET_SESSION_SUBTITLE_VIMEO_UPLOADED_SUCCESS,
      data: res.data,
    });
  };
}

export function getPathSubtitleVimeoUploaded(idVideo: any): ThunkAction<Promise<IReduxGetPathSubtitleAction>, IReduxTrainingState, undefined, IReduxGetPathSubtitleAction> {
  return async (dispatch: ThunkDispatch<IReduxTrainingState, undefined, IReduxGetPathSubtitleAction>) => {
    const res = await axiosInstance
      .get(
        `${
          process.env.REACT_APP_BASE_URL4
        }getAllSubtitles/${idVideo}`
      )
      .then((response) => {
        return response.data;
      });

    if (!res) {
      return dispatch({
        type: EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_FAILED,
      });
    }
    return dispatch({
      type: EReduxActionTypes.GET_PATH_SUBTITLE_VIMEO_UPLOADED_SUCCESS,
      data: res.data,
    });
  };
}
