import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { activateModal, closeModal } from '../../../global-state/actions/modal-actions';
import TableScrollElement from '../../../elements/table/table-scroll-element';
import { addSubGroup, getGroupBySchool, removeSubGroup, searchGroupByLabel } from '../../../services/groupe.student.service';

const AddsubGroupModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [searchFilter, setSearchFilter] = useState("");
    const [data, setData] = useState<any>(null);
    const [listGroup, setListGroup] = useState<any>();
    const [groupList, setGroupList] = useState<any>(props?.groupList)
    const [groupForm,setGroupForm] = useState<any>(props?.groupForm)
    const tabHeaders = [
        { title: t('groupes'), sorted: true, classNames: "w-30", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Etudiants'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Action'), sorted: false, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-4 pe-2" },
    ]
    useEffect(() => {
        const updatedList = groupList?.filter((obj: any) => {
            if (obj?.id === groupForm.id) return false;
            if (obj.childsList?.some((child: any) => child.id === groupForm.id)) return false;
            return true;
        });
        setListGroup(updatedList);
    }, [groupForm]);
    

    useEffect(() => {
        let list = {
            headers: tabHeaders,
            body: listGroup?.map((group: any) => {
                return {
                    row: {
                        group: group,
                        classNames: "table-white",
                        columns: [
                            { content: group?.label, classNames: "w-50", actionName: '' },
                            { content: <span style={{ textTransform: "capitalize" }}> {group?.personList?.length + " " + t('Etudiants').toLowerCase()}</span>, classNames: "", actionName: "" },
                            {
                                content: (checkSubGroup(group.id) === false) ? <button type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> add </span>
                                    <div className="m-auto ps-2">{t('add')}</div></button> :
                                    <button type="button" className="btn-Secondary medium icon-left adding-done">
                                        <span className="material-icons-outlined"> check </span>
                                        <div className="m-auto ps-2">{t('add')}</div></button>
                                , classNames: "text-end with-icon d-flex justify-content-end aligns-items-center  ", actionName: (checkSubGroup(group.id) === false) ? 'affectSubGroup' : 'disaffectSubGroup'
                            },
                        ]
                    }
                }
            })
        }
        setData(list)
    }, [groupList, listGroup, groupForm?.childsList])
    useEffect(() => {
        let list = {
            headers: tabHeaders,
            body: listGroup?.map((group: any) => {
                return {
                    row: {
                        group: group,
                        classNames: "table-white",
                        columns: [
                            { content: group?.label, classNames: "w-50", actionName: '' },
                            { content: <span style={{ textTransform: "capitalize" }}> {group?.personList?.length + " " + t('Etudiants').toLowerCase()}</span>, classNames: "", actionName: "" },
                            {
                                content: (checkSubGroup(group.id) === false) ? <button type="button" className="btn-Secondary medium icon-left">
                                    <span className="material-icons-outlined"> add </span>
                                    <div className="m-auto ps-2">{t('add')}</div></button> :
                                    <button type="button" className="btn-Secondary medium icon-left adding-done">
                                        <span className="material-icons-outlined"> check </span>
                                        <div className="m-auto ps-2">{t('add')}</div></button>
                                , classNames: "text-end with-icon d-flex justify-content-end aligns-items-center ", actionName: (checkSubGroup(group.id) === false) ? 'affectSubGroup' : 'disaffectSubGroup'
                            },
                        ]
                    }
                }
            })
        }
        setData(list)
    }, [groupForm])

    const checkSubGroup = (idGroup: number) => {
        let group = groupForm.childsList?.find((obj: any) => { return obj?.id === Number(idGroup) });
        if (group) { return true }
        else { return false }
    }
    const affectSubGroup = async (group: any) => {
        group.owner["type"] = "LP";
        let persons = group?.personList.map((p: any) => { p['type'] = "NP"; return p; });
        group.personList = persons;
        await addSubGroup(group, groupForm.id).then((response:any)=>{
            setGroupForm(response)
        })

    }
    const removeSubGroupFN = async (group: any) => {
        group.owner["type"] = "LP";
        let persons = group.personList.map((p: any) => { p['type'] = "NP"; return p; });
        group.personList = persons;
        removeSubGroup(group, groupForm.id).then((response:any)=>{
            setGroupForm(response)
        })
    }
    const doAction = (value: any) => {
        if (value.action === 'affectSubGroup') {
            affectSubGroup(value.row.group);
            props?.activateModal();
        } else if (value.action === 'disaffectSubGroup') {
            removeSubGroupFN(value.row.group);
            props?.activateModal();
        }
    }
    const SearchGroup = async (event: any) => {
        if (event.target.value.length > 2) {
            searchGroupByLabel(event.target.value, props?.schoolInfo?.id).then((response: any) => {
                const filteredResponse = response.filter((group: any) => group.id !== groupForm.id);
                setListGroup(filteredResponse);
            });
        }
        if (event.target.value.length === 0) {
            getGroupBySchool(props?.schoolInfo?.id).then((response: any) => {
                const filteredResponse = response.filter((group: any) => group.id !== groupForm.id);
                setListGroup(filteredResponse);
            });
        }
    };
    return (
        <>
            <div style={{ height: 696, background: "#F8F8FA" }}>
                <div className="modal-header  mb-2" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="p-3 d-flex align-items-center justify-content-between  w-100 ">
                    <h3 className="H3-Headline">{t('addSubGroup')}</h3>
                    <div className="d-flex align-items-center justify-content-end py-2 ">
                        <div className="input-form-control me-2" style={{ maxWidth: '220px' }}>
                            <input dir="ltr"type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={searchFilter} onChange={(e)=>{SearchGroup(e);  setSearchFilter(e.target.value)}} />
                            <span className="material-icons-outlined">search</span>
                        </div>
                        <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                    </div>
                    </div>
                </div>

                <div className="modal-body  mt-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                    <TableScrollElement data={data} doAction={(value: any) => { doAction(value) }} />
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    groupForm: state.modalReducer?.modalContent?.data?.groupForm,
    groupList: state.modalReducer?.modalContent?.data?.groupList
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { closeModal,activateModal },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(AddsubGroupModalComponent);