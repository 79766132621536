import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getFollowsStudent, clearStudent } from '../../global-state/actions/profile-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { addLastPath, redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { setRegistrationForm, setApplicationFile, getFollow, getStudentById, getMyCoursesProfessor, getCourseFromEmail, getCourseChapters, getCourseTests, getRegistrationForm } from '../../global-state/actions/course-actions';

import axios from '../../helpers/interceptors';
import "../../translations/i18n";
import CoursSuiviComponent from "./cours-suivie-component";
import CourseFinishedComponent from "./course-finished-component"
import { getCatalog } from "../../global-state/actions/catalog-actions";
import SubscriptionApplicationComponent from "./subscription-application-component";
import { useTranslation } from 'react-i18next';
import { ModalIDS } from '../../components/modals/modal-ids';
import { clearModal, openModal } from '../../global-state/actions/modal-actions';
import { useLocation } from "react-router-dom";

import { useHistory } from 'react-router';
import { returnDynamicValueFromUrl, returnStartPathUrl } from '../../helpers/domainCheck';
import DroppedTrainingsListComponent from './dropped-trainings-list-component';
import { disaffectStudentFromGroup, getGroupByStudent } from '../../services/groupe.student.service';
const DetailsStudentComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [student, setStudent] = useState(props?.student);
    const [listCandidates, setListCandidates] = useState(props?.listeFollows?.candidates);
    const [listInscribed, setListInscribed] = useState(props?.listeFollows?.inscribed);
    const [listCertified, setListCertified] = useState(props?.listeFollows?.certified);
    const [listGroupForStudent, setListGroupForStudent] = useState([])
    let droppedStudentList = useMemo(() => props?.listeFollows?.dropped, [props?.listeFollows?.dropped])

    const { t } = useTranslation();
    const history = useHistory()
    const location = useLocation();
    const idStudent = returnDynamicValueFromUrl(4)
    useEffect(() => {
        let filterStudentProfile = {
            studentId: idStudent,
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL"
        }

        if (props?.connectionUser?.role === 'role_director') {

            let filter = {
                entityId: props?.connectionUser.idSchool,
                entityType: "SCHOOL",
                status: "PUBLISHED"
            }

            props?.getCatalog(filter, 'first time')
            props?.getFollowsStudent(filterStudentProfile);

           getGroupByStudent(parseInt(idStudent)).then((response:any)=>{
            setListGroupForStudent(response)
           })
        }
        if (props?.connectionUser?.role === 'role_prof') {
            props?.getMyCoursesProfessor(props?.connectionUser?.idSchool, "SCHOOL", props?.connectionUser?.idPerson);
            props?.getFollowsStudent(filterStudentProfile);
            getGroupByStudent(parseInt(idStudent)).then((response:any)=>{
                setListGroupForStudent(response)
               })
        }
        setStudent(props?.student);
        setListCandidates(props?.listeFollows?.candidates);
        setListInscribed(props?.listeFollows?.inscribed);
        setListCertified(props?.listeFollows?.certified);
        props?.clearModal()
        return () => {
            setStudent({});
            setListCandidates([]);
            setListInscribed([]);
            setListCertified([]);
            props?.clearStudent()

        };
        
    }, [])

    useEffect(() => {
        getGroupByStudent(parseInt(idStudent)).then((response:any)=>{
            setListGroupForStudent(response)
           })
           props?.clearModal()
    }, [props?.modalPage])



    useEffect(() => {
        setStudent(props?.student);
        setListCandidates(props?.listeFollows?.candidates);
        setListInscribed(props?.listeFollows?.inscribed);
        setListCertified(props?.listeFollows?.certified);
        setListGroupForStudent(listGroupForStudent)
    }, [props?.student, props?.listeFollows, listGroupForStudent,])

    const downloadFile = (e: any, link: string) => {
        e.preventDefault();
        e.stopPropagation();
        let filename = link.split('/')[3];
        axios.post(`${process.env.REACT_APP_BASE_URL4}download/file`, { filename: filename }, { responseType: 'blob' }).then((res: any) => {
            const urlLink = window.URL.createObjectURL(new Blob([res.data]));
            const linkHtml = document.createElement('a');
            linkHtml.href = urlLink;
            linkHtml.setAttribute('download', decodeURIComponent(filename));
            document.body.appendChild(linkHtml);
            linkHtml.click();
        })
    }
    const goToCourse = async (row: any) => {
        if (props?.connectionUser.role === 'role_prof') {
            await props?.getCourseFromEmail(row.courseId);
            await props?.getCourseChapters(row.courseId, props?.courseForm);
            await props?.getCourseTests(row.courseId);

            if (props?.courseForm?.idRegistrationForm !== undefined && props?.courseForm?.idRegistrationForm !== null && props?.courseForm?.idRegistrationForm !== 0) {
                await props?.getRegistrationForm(props?.courseForm?.idRegistrationForm);
            }
            props?.clearHistoryArray()
            props?.redirect(row.courseTitle, `${returnStartPathUrl()}/courses/manageCourse`);
            props?.history.push(`${returnStartPathUrl()}/courses/manageCourse`);
        } else {
            history.push(`${returnStartPathUrl()}/courses/details-course/${row.courseId}`)
        }
    }
    const disaffectstudentFromGrpoup = async (idGroup: number) => {
        let objects = {
            person: { ...student, type: 'NP' },
            idGroup: idGroup,
            idSchool: props?.connectionUser.idSchool,
            subGroup: false,
            parentGroup:false,
            idGroupParent:idGroup,
        }
        await disaffectStudentFromGroup(objects).then((response:any)=> {
            const updatedGroupForStudent = listGroupForStudent.filter((group: any) => response.id !== group.id);
            setListGroupForStudent(updatedGroupForStudent);
        })
    }
    const openModalFn = () => {
        props?.openModal({ idModal: ModalIDS.ADD_STUDENT_TO_GROUPS, size: "lg", scroll: 'body' ,data:{groupForStudent:listGroupForStudent}})
    }

    return (
        <>
            <div style={{ marginTop: 64 }}>
                <div className="d-flex flex-column px-4 mx-auto my-5 py-2" style={{ maxWidth: '1248px' }}>
                    <span className="H3-Headline">{t('groupes')} ({listGroupForStudent?.length})</span>
                    <div className="d-flex  flex-wrap mt-4 "   >

                        {props?.connectionUser?.role === 'role_director' && <>
                            {
                                listGroupForStudent?.map((group: any) => {
                                    return <button key={group.id} className="btn-Tabs outlined white icon me-3 mb-3" onClick={() => { disaffectstudentFromGrpoup(group.id) }}>
                                        {group.label} <span className="material-icons-outlined ms-1" >close</span>
                                    </button>
                                })
                            }
                            <button className="btn-Tabs outlined white icon me-3 mb-3" style={{ backgroundColor: "#f2f2f5", border: 'none' }} onClick={() => { openModalFn() }}>
                                <span className="material-icons-outlined ms-1"> add </span> {t('addToGroup')}
                            </button>
                        </>}
                        {props?.connectionUser?.role === 'role_prof' && <>
                            {
                                listGroupForStudent?.map((group: any) => {
                                    return <button key={group.id} className="btn-Tabs outlined white icon me-3 mb-3" onClick={() => { disaffectstudentFromGrpoup(group.id) }}>
                                        {group.label}
                                    </button>
                                })
                            }
                        </>}
                    </div>
                    <div className="mt-4 pt-3">
                        <span className="H3-Headline black-800">{t('listCandidates')} ({listCandidates?.length})</span>
                        <div className="mt-4">
                            {(listCandidates?.length > 0) && <div>

                                <SubscriptionApplicationComponent listCandidates={listCandidates}
                                    goToCourse={goToCourse} />

                            </div>}
                        </div>
                    </div>
                    <div className="mt-4 pt-3">

                        <span className="H3-Headline black-800">{t('listFollowing')} ({listInscribed?.length})</span>

                        <div className="mt-4">
                            {(listInscribed?.length > 0) && <div>
                                <CoursSuiviComponent goToCourse={goToCourse} listInscribed={listInscribed} />
                            </div>}
                        </div>
                    </div>

                    <div className="mt-4 pt-3">
                        <span className="H3-Headline black-800">{t('listeCertificated')} ({listCertified?.length})</span>
                        <div className="mt-4">
                            {(listCertified?.length > 0) && <div >
                                <CourseFinishedComponent goToCourse={goToCourse} listCertified={listCertified} downloadFile={downloadFile} /> </div>}
                        </div>
                    </div>


                    {props?.connectionUser?.role === 'role_director' && <div className="mt-4 pt-3">
                        <span className="H3-Headline black-800">Formations abandonnées ({droppedStudentList?.length})</span>
                        <div className="mt-4">
                            {(droppedStudentList?.length > 0) && <div >
                                <DroppedTrainingsListComponent/> </div>}
                        </div>
                    </div>}
                </div>

            </div>

        </>
    );

}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectionUser: state.authReducer.connectedUser,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
    student: state.detailsProfileReducer.actualStudent,
    listeFollows: state.detailsProfileReducer.studentFollows,
    history: ownProps?.history,
    myCourses: state.courseReducer.myCourses,
    courseForm: state.courseReducer.courseForm,
    courses: state.catalogReducer.courses,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    modalPage:state.modalReducer.modalPage,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearStudent,
            setRegistrationForm,
            setApplicationFile,
            getFollow,
            getStudentById,
            getFollowsStudent,
            getMyCoursesProfessor,
            getCourseFromEmail,
            getCourseChapters,
            getCourseTests,
            getRegistrationForm,
            addLastPath,
            getCatalog,
            openModal, 
            clearModal,
            redirect, clearHistoryArray
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(DetailsStudentComponent);

