import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyListElement from "../../../../../elements/empty-list/normal-empty-list-element";
import CommentCard from "./comment-card";
interface Props{
    postsList: any,
    isSearch:boolean,
}
export default function CommentTrainingProfessorCardHeader(props:any){
    const {t} = useTranslation();
    const [posts, setPosts]= useState(props?.postsList)
    useEffect(()=>{
        setPosts(props?.postsList)
    }, [props?.postsList])
     return(
         <>
            {posts?.length===0  && <div className="w-100 text-center mt-5 mx-auto "> <EmptyListElement lines={[t('noCommentFound1')]}/></div>}
            { posts?.map((post:any) => {

             return <div key={post?.id}><CommentCard key={post?.id} t={t}  post= {post} what={""}/></div>
             })}
         </>
     )
}