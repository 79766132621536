export const TRANSLATIONS_EN = {
    addCourse: "NEW COURSE",
    createCourse: "Create a course",
    myCourses: "My courses",
    Published: "On line",
    Draft: "Disorganized",
    Pending: "Awaiting approval by the director",
    RequestDeactivation: "Awaiting deactivation by director",
    RequestActivation: "Awaiting activation by director",
    RequestDeletion: "Awaiting archiving by director",
    certified: "Graduates",
    candidats: "CANDIDATES",
    candidatsCapitalise: "Candidates",
    courseStatus_1: "The course will remain",
    courseStatus_2: "private until published",
    Pending_2: "Waiting for validation",
    RequestDeletion_2: "Waiting for archiving",
    RequestDeactivation_2: "Waiting for deactivation",
    RequestActivation_2: "Waiting for activation",
    actvity: "ACTIVITY",
    students: "Students",
    students_2: "Student",
    description: "DESCRIPTION",
    archive: "ARCHIVE",
    publish: "PUBLISH",
    inscription: "INSCRIPTION",
    inscriptionTitle: "Inscription",
    certifiedTitle: "Graduated on",
    lastVisitProfile: "Last visit ago",
    lastVisitProfile_1: "last visit",
    lastVisitProfile_2: "Il ya",
    descProf: "Short description of the teacher who will appear in the presentation of the course",
    content: "CONTENTS",
    titleCoursePlaceholder: "Enter course title here...",
    titleCourseForm: "Course title",
    modalPublishCourse_1: "Send to director for approval",
    modalPublishCourse_1_1: "Validate the post",
    modalRefusedCourse_1: "Refuse publication",
    modalPublishCourse_2: "Add a comment for the director",
    modalPublishCourse_2_2: "Add a comment for the teacher",
    modalPublishCourse_3: "Commenter...",
    modalPublishCourse_4: "SEND COURSE",
    modalPublishCourse_4_4: "PUBLISH COURSE",
    modalRefusedCourse_4: "REFUSE THE COURSE",
    modalPublishCourseSuccess: "The course has been sent to the director for validation.",
    modalPublishPathSuccess: "The course has been sent to the director for validation.",
    modalPublishSessionSuccess: "The session was sent to the director for validation.",
    modalPublishCourseSuccess_1: "The course is published. It is now available online.",
    modalDeactivateCours: "The course is disabled. It is no longer available online.",
    modalRefusedCourseSuccess: "You have just refused the publication of the course. Your refusal has been sent to the teacher.",
    titleFormCourse_1: "Video presentation of the course",
    titleFormCourse_2: "Visual to illustrate the course",
    titleFormCourse_3: "Text presentation of the course",
    resume: "Introductory summary",
    textAreaPlaceholder: "Enter text here...",
    placeholderInscriptionInput: "Enter text here",
    prerequisite: "Prerequisites to take this course",
    objectives: "educational goals",
    skills: "Knowledge and skills acquired through this course",
    skillsPlaceholder: "Enter a keyword...",
    uploadVideo_1: "Drag and drop the course video you want to upload (mp4, mov)",
    uploadVideo_2: "SELECT A VIDEO FILE",
    uploadImage_1: "Drag and drop a photo to illustrate the course (jpg, png)",
    uploadImage_2: "SELECT A PICTURE",
    downloadVideo: "Download",
    awaitVideo: "In the queue",
    whiletranscode: "Currently viewing",
    transcodingVideo: "Video processing",
    breadCrumbChapter: "Chapter",
    chapitre: "Chapter",
    newTest: "NEW TEST",
    newChapter: "NEW CHAPTER",
    selectLevelCourse: "Level",
    selectLevelCourse_1: "Novice",
    selectLevelCourse_2: "Intermediate",
    selectLevelCourse_3: "Confirmed",
    listChaptersContent: "To get started, specify the course level and create your first chapter.",
    chapterSelectFomCHapter: "CHAPTER",
    saveChapter: "TO REGISTER",
    durationChapter: "Duration",
    placeholderTitleChapter: "Enter the title of the chapter here...",
    titleFormChapter_1: "Chapter Video",
    titleFormChapter_2: "Files to download",
    titleFormChapter_3: "Chapter text",
    textFileUpload_1: "Drag and drop chapter upload files",
    textFileUpload_2: "( audio, PDF, PowerPoint, Word, Excel...)",
    fileUploadButton: "Select file",
    uploadVideoChpter_1: "Drag and drop the video of the chapter you want to upload (mp4, mov)",
    tests: "Tests",
    test: "Test",
    resultat: "Results",
    reponseInscrit: "Subscription request",
    reponseAv: "Reply before",
    refusedAt: "Refused on",
    Refused: "Refuse",
    Unpaid: "To pay",
    seeDemand: "See my request",
    certifButton: "CERTIFICATE",
    courseTable: "Training",
    DATEACHAT: "Date of purchase",
    exportListe: "EXPORT LIST",
    filterProfessors: "Teacher",
    statusFilter: "Status",
    search: "Research",
    Tous: "All",
    Brouillon: "Disorganized",
    Avalider: "To validate",
    Enligne: "On line",
    Archive: "Archive",
    Refuse: "Refuse",
    Cours: "Course",
    COURSE: "Course",
    Professeurs: "Teachers",
    Demandes: "Requests",
    filterCourse: "All courses",
    Certifies: "Certified",
    Statut: "Status",
    PresProf: "Presentation of the Professor",
    dernVisite: "last visit",
    Enattente: "Pending",
    Admis: "accepted",
    Suspendu: "Suspended",
    inviteProf: "INVITE A TEACHER",
    inviteProf_2: "Invite a teacher to register",
    send: "Send",
    inviteSuccess: "Your invitation has been sent to the teacher.",
    myAccount: "My account",
    saveImg: "save image",
    Profile: "Profile",
    Nom: "Last name",
    NomObligatoire: "Last name*",
    PrenomObligatoire: "First name*",
    Prenom: "First name",
    country: "country of residence",
    Tunisie: "Tunisia",
    France: "France",
    city: "town of residence",
    birthdate: "Date of Birth",
    birthdate_input: "mm/dd/yyyy",
    NivScolaire: "School level",
    Primaire: "Primary",
    College: "Middle School",
    Lycee: "High school",
    Bac: "Bac",
    Technicien2: "Technician (Bac +2)",
    License: "Licence (Bac+3)",
    Master: "Master or equivalent (BAC +4 or + 5)",
    Ingenieur: "Engineer",
    Doctorat: "doctorate",
    emailAdd: "E-mail address",
    updatePass: "Change my password",
    password: "Password",
    actualPass: "Current Password",
    newPass: "New Password",
    enterPassword: "Minimum 8 characters",
    msgErrorPassword: "Please enter at least 8 numbers/letters",
    msgErrorConfirmPassword: "Please enter the same password",
    confimrNewPass: "Password Confirmation",
    msgErrorMail: "Please enter a valid email address",
    confimrNewEmail: "New e-mail address",
    validPassButton: "Validate my new password",
    MainSchoolColor: "Main school color",
    AddYourColorToPersonalizeThePresentationOfTheCourses: "Add your color to personalize the presentation of the courses",
    imgCrop_1: "Drag and drop a photo",
    imgCrop_3: "Drag and drop your logo",
    imgCrop_2: "SELECT A PICTURE",
    pathBy: "Course given by",
    sessionBy: "Session given by",
    catalogCourses: "Course Catalog",
    Etudiants: "Students",
    Ecole: "School",
    Catalogue: "Catalog",
    Activite: "Activity",
    lecole: "School",
    howItWorks: "How it works",
    hello: "Welcome !",
    cnxButton: "Log in",
    createAccountMsg: "create my account",
    questionCreateAccount: "You haven't created an account yet?",
    forgotLogin: "Forgot your password ?",
    emailLogin: "E-mail",
    validInscription: "VALIDATE MY REGISTRATION",
    activateAccount: "ACTIVATE MY ACCOUNT",
    madame: "WOMEN",
    monsieur: "MAN",
    other: "OTHER",
    registerDone_1: "Your registration request has been taken into account.",
    registerDone_2: "An email has been sent to you.",
    registerDone_3: "Click on the link provided inside this e-mail to confirm the creation of your account.",
    registerDone_4: "See you soon !",
    validRegister: "To validate",
    activateMyAccount: "Activate my account",
    directeur: "Director",
    professeur: "Teacher",
    invalidToken: "This link has been used before and it has expired, please contact support if needed.",
    questionAccount: "Already have an account ?",
    verifMail_1: "Your email has been verified, you can now log in.",
    verifMail_3: "Validation of your email",
    changePass: "Change password",
    msgUpdatedPass: "The password has been changed.",
    recPass: "Recover Password",
    reinMail: "Enter your email address below. You will receive a link to reset your password.",
    reinBUttonPass: "Reset my password",
    sendMailRein: "An email containing your reset link has been sent to you.",
    alreadyHaveAcc: "Already have an account ?",
    followCourse: "Take this course",
    followPath: "Follow this route",
    followSession: "Follow Session",
    objPEda: "educational goals",
    preresuiPeda: "Prerequisites for this course",
    preresuiPedaPath: "Prerequisites for this course",
    preresuiPedaSession: "Prerequisites for this session",
    skillsPreveiw: "Knowledge and skills acquired through this course",
    skillsPreveiwPath: "Knowledge and skills acquired through this course",
    skillsPreveiwSession: "Knowledge and skills acquired during this session",
    courseProgramm: "Course program",
    Chapitres: "Chapters",
    videoCourse: "Video course",
    textCourse: "Course text",
    downloadFiles: "Files to download",
    testConn: "Knowledge tests",
    aboutSchool: "About the school",
    aboutProfessor: "About the teacher",
    registerCourse: "Register for the course",
    registerPath: "Register for the course",
    registerSession: "Register for the session",
    testConn_2: "Test your knowledge",
    progressView: "Track your progress",
    previewText_1: "Fill in your registration request so that the teacher can validate it.",
    previewText_2: "Advance in your program and learn new skills.",
    previewText_3: "Validate your knowledge with tests and get your certificate.",
    Introduction: "Introduction",
    Documents: "Documents",
    downloadFIleDetails: "To download",
    Etudiant_1: "Student",
    Professeur_1: "Teacher",
    Directeur_1: "Director",
    annuler: "CANCEL",
    modifier: "Edit",
    logout: "Logout",
    connexionButton: "Connexion",
    languageFR: "French",
    languageEN: "English",
    language: "Language",
    Pending_3: "To validate",
    CERTIFICAT: "CERTIFICATE",
    refuseCourse: "REFUSE PUBLICATION",
    joursVisit: "Days",
    consulter: "TO CONSULT",
    jourVisit: "Day",
    Candidat: "Candidate",
    enCours: "STUDENTS IN PROGRESS",
    certifiés: "GRADUATES",
    Dossier: "Case",
    Demande: "Request",
    DemandeEnvoyéeIlYA: "Request sent ago",
    Resultats: "Results",
    Certificat: "Certificate",
    Tempsdobtention: "Obtaining",
    Tempsdobtention_2: "Obtain time",
    refuseCourseDirector: "Refuse publication",
    refuseCourseDirector_2: "Add a comment for the teacher",
    refuseCourseDirector_3: "REFUSE THE COURSE",
    modalRefuseCourseSuccess_1: "You have just refused the publication of the course.",
    modalRefuseCourseSuccess_2: "Your refusal has been sent to the teacher.",
    archiveCourseDirector: "Are you sure you want to archive this course?",
    archiveCourseDirectorSuccess: "The course is archived.",
    archiveCourseDirector_2: "ARCHIVE THE COURSE",
    nothingTOSay: "Nothing to say",
    online: "On line",
    offline: "Offline",
    validationNewPassword: "You can log in with your new password.",
    showMore: "SHOW FOLLOWING",
    folderRegistrationTrueOrFalse: "Subject to validation of the file.",
    nextCommentsFeedback: "Show next",
    emailUpdateButtonShH: "CHANGE MY EMAIL ADDRESS",
    conditionDeValidation: "Conditions of validation of the student registration request by the professor.",
    Acceptation: "Acceptance of automatic student registration.",
    AcceptationSousDossier: "Acceptance of the student's registration subject to validation of the file.",
    questions: "Questions to ask the student",
    documents: "Documents to be requested from the student",
    titreQuestion: "Enter the title of the question here",
    titreDocument: "Indicate the title of the document here",
    AjouterQuestion: "Add question",
    AjouterDocument: "Add document",
    demandeDinscription: "Subscription request",
    envoyerDemande: "SEND MY REQUEST",
    titreRegistration_1: "Questions asked by the teacher",
    titreRegistration_2: "Documents requested by the teacher",
    Inscribed: "In progress",
    CertifiedStudent: "Certified",
    DraftStudent: "Registration file in progress",
    Candidate: "Awaiting validation of the file",
    finishRegistration: "FINISH MY REQUEST",
    noCoursesStudent: "You are not enrolled in any course.",
    discoverCatalog: "DISCOVER THE CATALOG",
    noCoursesCreated: "No training has been created yet.",
    noCoursesCreated_2: "To get started, create your first course.",
    noCourses_1: "No courses taken",
    noCourses_2: "No registration request",
    noCourses_3: "No courses completed",
    noCoursesPublished: "No courses have been published yet.",
    deleteCompte: "Delete my account",
    searchProfessors: "To research...",
    descriptionProfLabel: "Short description of the teacher who will appear in the presentation of his courses?.",
    relaunchProfInvitation: "REVIVE",
    noProfessorsList_1: "No teacher found.",
    noProfessorsList_2: "Please search again.",
    noProfessorsListDefault_1: "No teacher is registered.",
    noProfessorsListDefault_2: "Invite a teacher to sign up to get started.",
    signatureProf: "Professor's signature",
    signatureDirector: "Director's signature",
    Deactivate: "Disabled",
    breadCrumbTest: "Test",
    placeholderTitleExam: "Indicate here the title of the test...",
    exempleTitleExam: "Example, Test of knowledge of the first knowledge",
    noQuestionTest: "To get started, create your first question.",
    newQuestion: "NEW ISSUE",
    addQuestionText: "Add question",
    placeholderQuestionText: "Indicate the question",
    answerQuestion: "Indicate the answer",
    placeholderChoiceText: "Indicate the text of the multiple choices",
    delete: "delete",
    suppcomte: "Account deletion",
    selectMotif: "Select a pattern",
    coursNeMeconvier: "Classes don't suit me",
    comm: "add a comment",
    DeleteSuccess: "Your account has been successfully deleted. Goodbye.",
    CreationAccountProfDirector: "Your account has been created. You can log in.",
    messageEnvoyerDemande_1: "Your registration request has been sent.",
    messageEnvoyerDemande_2: "You will receive an answer by e-mail",
    messageEnvoyerDemande_3: "in the next 3 days.",
    candidat_status: "WAITING FOR VALIDATION",
    refuser: "TO REFUSE",
    refuser_1: "REFUSE",
    valider: "TO VALIDATE",
    valider_1: "ACCEPTED",
    refuserDemandeRegistration: "Deny student registration",
    ajouterCommentaireEtudiant: "Add a comment for the student.",
    refuserLaDemande: "REFUSE THE REQUEST",
    messageRefuseRegistrationCours: "Student enrollment in course successfully declined.",
    messageRefuseRegistrationPath: "The student's registration for the course has been successfully refused.",
    messageRefuseRegistrationSession: "Student registration for session successfully denied.",
    messageValiderRegistration: "The student was invited to start taking the course.",
    messageValiderRegistrationParcours: "The student has been invited to start following the course",
    messageValiderRegistrationSession: "The student has been invited to start following the session",
    SuspendProf: "To suspend",
    textProfSuspend: "This teacher will no longer be able to access the lessons. You have the option of reinstating it if you change your mind.",
    otherChoice: "Other",
    commentProfSuspend: "Add a comment for the teacher",
    questionLabel: "Question",
    choiceLabelBON: "Right answer",
    addAnswerButton: "Add an answer",
    noQuestionTestPreview: "No questions",
    passedTestAverage: "Success rate",
    param: "Setting",
    presen: "Presentation",
    commentSuspendInputProf: "Comment...",
    motifSuspendProf: "Select a pattern",
    affectOtherPRof: "Select a teacher to assign lessons to",
    ReintegrateProf: "Reintegrate",
    ReintegrateProfText: "This teacher will be able to access the lessons. You have the option of suspending the teacher if you change your mind.",
    AcceptProf: "Accept",
    aArchiver: "To be archived",
    aDesactiver: "To deactivate",
    aActiver: "To activate",
    desactiverCourse: "CONFIRM DEACTIVATION",
    refuseDesactivationCourse: "REFUSE DEACTIVATION",
    activerCours: "VALIDATE ACTIVATION",
    refuserLactivationCours: "REFUSE ACTIVATION",
    archiverCours: "VALIDATE ARCHIVING",
    refuserArchivationCours: "REFUSE ARCHIVING",
    settingEcole: "School Settings",
    nomEcole: "Name of the school",
    logoEcole: "school logo",
    persenCourte: "Short presentation (this text will be displayed on the course pages)",
    listCandidates: "Registration requests",
    listFollowing: "Training courses followed",
    listeCertificated: "Completed trainings",
    sendDate: "Sending the request",
    importVideoUpload: "Video download",
    waitingUpload: "Please wait...",
    uploadFinishing: "The video will be available shortly",
    uploadPending: "Being processed...",
    candidatures: "Applications",
    certifcations: "CERTIFICATIONS",
    dashboardAllStudents: "Student",
    certifiedDashStudents: "Graduate",
    YesWord: "Yes",
    NoWord: "Non",
    obtentionPeriod: "Obtaining",
    In: "In",
    TimePassed: "Since",
    noStudentFound: "No current students",
    noCandidateFound: "No applications pending",
    noSuspendedFound: "No student is suspended",
    noCertifedFound: "No certified or graduate students",
    textStudentSuspend: "This student will no longer be able to access the courses?. You have the option of reinstating it if you change your mind.",
    commentStudentSuspend: "Add a comment for the student",
    successSuspendStudent: "The student no longer has access to the courses?.",
    ReintegrateStudentText: "This student will be able to access the courses?.",
    ReintegrateStudentText_2: "You have the option of excluding the student if you change your mind.",
    successReinstateStudent: "The student now has access to the courses?.",
    successSuspendProf: "Professor successfully suspended",
    successSuspendAssitant: "Wizard successfully suspended",
    successReinstateProf: "Professor successfully reintegrated",
    successReinstateAssistant: "Successful Reintegration Wizard",
    returnToProfProfile: "Back TO PROFILE",
    diplomeButton: "Diploma",
    validatedDiploma: "DIPLOMAS",
    Declcatalog: "DISCOVER THE CATALOG",
    deactivateCoursText: "This course will no longer be accessible, you have the possibility to reactivate it if you change your mind.",
    modalDeactivateCoursTitle: "Deactivation of the course",
    deactivateCours: "Disable",
    successdeactivateCours: "Has been successfully completed.",
    createBy: "Given by",
    maleGender: "M. ",
    femaleGender: "Mom",
    modalReactivateCoursTitle: "Course reactivation",
    modalReactivatePathTitle: "Course reactivation",
    modalReactivateSessionTitle: "Session reactivation",
    reactivateCours: "Reactivate",
    reactivateCoursText: "This course will be accessible.",
    reactivateCoursText_2: "You have the option to turn it off if you change your mind.",
    reactivatePathText: "This course will be accessible.",
    reactivatePathText_2: "You have the option to turn it off if you change your mind.",
    reactivateSessionText: "This session will be accessible.",
    reactivateSessionText_2: "You have the option to turn it off if you change your mind.",
    champsObligatoires: "(*) Required fields",
    continueCreatingCourse: "Sorry, you cannot publish this course.",
    continueBUttonText: "CONTINUE",
    errorChaptersLength: "You must have at least one chapter (required).",
    linkImgCourseError: "Visual to illustrate the course (compulsory).",
    descriptionCourseError: "Text presentation of the course (compulsory).",
    congratulation: "Congratulations on your graduation!",
    diplomaMsg :"To be able to download your diploma, please consult all the contents in the chapters (Videos included)",
    onlineCOurses: "Online course",
    validatedCourses: "Course to validate",
    AllCoursesDirector: "All courses",
    BonneRéponses: "Good Answers",
    Question: "Question",
    ReturnExam: "RESUME MY CURRENT TEST",
    textExa: "Select one or more responses depending on the instruction. The test is performed only once.",
    StartExam: "Begin",
    Réponse: "Answer",
    QCU: "Only 1 correct answer possible",
    QCM: "Many possible responses",
    validCourse: "VALIDATE THE PUBLICATION",
    refuseInscriptionStudent: "REQUEST REFUSED",
    BonneReponses: "Good Answers",
    placeholderTextArea: "Enter text here...",
    languageIT: "Italy",
    languageES: "Spanish",
    languageDE: "German",
    languageNL: "Dutch",
    languageSV: "Swedish",
    obligatoireFormCourse: "obligatory",
    Registered: "Registered",
    New: "New",
    Suspended: "Suspended",
    CatalogueVisitorsInTheLast30Days: "Catalog visitors in the last 30 days.",
    Course: "Course",
    Candidates: "Candidates",
    Teachers: "Teachers",
    ConversionRateOfApplicationsInTheLast30Days: "Conversion rate of visits in the last 30 days.",
    MedianCourseNotes: "Median final exam score",
    AverageAgeOfStudents: "Average age of students",
    Male: "Men",
    Female: "Women",
    MostRepresentedCities: "Most represented cities",
    VisitsAndApplicationsForRegistration: "VISITS & REGISTRATION",
    RegistrationsAndCertifications: "REGISTRATIONS & DEGREES",
    ConnectedStudents: "Connected students",
    LevelOfStudy: "level of studies",
    numberOfVisits: "Overview page visits",
    Applications: "Registration requests",
    Registration: "Number of registrations",
    StudentsNumber: "Number of students connected",
    Jan: "Jan",
    Fev: "Feb",
    Mar: "Mar",
    Avr: "Apr",
    May: "May",
    Jui: "June",
    Jul: "Jui",
    Aug: "Me",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    Access: "To access",
    Year: "year",
    StudentsConnectedThisMonth: "Logged in the last 30 days.",
    AverageTimeSpentOnThePlatformForGraduation: "Average time spent on the platform for graduation",
    AverageTimeBetweenRegistrationAndGraduation: "Average time from enrollment to graduation",
    certification: "Certification",
    waitingCertif: "Loading your diploma, please wait...",
    ListEtds: "Student List",
    listcand: "List of candidates",
    listdipv: "List of graduates",
    listInscribed: "List of registrants",
    listSuspended: "List of suspended",
    Days: "days",
    noVideoToShow: "No video.",
    donnerAvis: "It is important !",
    donnerAvis_2: "Give your opinion on the course",
    avisTitle: "Title of your review",
    avisText: "Write your review",
    votreAvis: "Your opinion here",
    publishMyReview: "Publish",
    monAvis: "My opinion on the course",
    avis: "Notice",
    Anonymous: "Anonymous",
    noFeedback: "No reviews (for now)",
    Finance: "Finance",
    PublishedAt: "Published on",
    PublishedAte: "Published on",
    ActualPrice: "Last price of the period",
    MoyPrix: "Average price",
    discussionButton: "Discussions",
    questionButton: "Questions to the teacher",
    addCommentButton: "add a comment",
    addCommentForChapter: "Add a comment to the chapter",
    addcommentForCourse: "Add a comment to the course",
    addcommentForPath: "Add a comment to the course",
    addCommentForSession: "Add a comment to the session",
    commentTitle: "Comment title",
    yourComment: "Write your comment",
    votreCommentaie: "Your comment here...",
    addQuestionButton: "Add question",
    addCommentSuccess: "Your comment has been added successfully",
    responses: "Answer(s)",
    noCommentFound: "No comments (for now)",
    repondre: "Answer",
    labeldeletecompte: "All your data will be permanently deleted",
    listdesProf: "List of teachers",
    noCoursePayedDefault: "No paid course.",
    noCoursePayed_1: "No paid courses found.",
    noCoursePayed_2: "Please search again.",
    Courspayed: "Paid courses",
    CoursFree: "Free lessons",
    paie: "List of payments",
    prunp: "Specify a period",
    Année: "Year",
    community: "Community",
    thematic: "Select a theme",
    comment: "Comment",
    NumberOfCertifications: "Number of certificates",
    inscriptionTypePayment: "Type d’inscription",
    registrationFees: "Registration fees",
    gratuit: "Free",
    payant: "paying",
    addQuestionForChapter: "Ask the teacher a question",
    yourQuestion: "Write your question",
    addQuestionSuccess: "Your question has been successfully added",
    noQuestionFound: "No questions to deal with",
    noQuestionFound_4: "No questions answered",
    noQuestionFound_5: "You have no questions.",
    paymentTitle: "Payment",
    paymentTitlePlural: "Payments",
    paymentMethodCourses: "Trainings payment method",
    choiceCurerncy: "Choose currency",
    Questions: "Questions",
    unsettledQuestion: "Issues to address",
    settledQuestion: "Issues addressed",
    allQuestion: "All the questions",
    recent: "The most recent",
    oldest: "The least recent",
    maxResponses: "With as many answers as possible",
    allSubjects: "All courses",
    allSubjects_2: "All topics",
    noQuestionFound_2: "Your search did not match any questions.",
    noQuestionFound_3: "Please search again.",
    noCommentFound_2: "Your search did not match any comments",
    noPaymentInfo: "You must choose your school's payment method",
    NoPaymentSchoolConfig_1: "To set the payment method.",
    NoPaymentSchoolConfig_2: "Click here",
    newResponses: "New answer(s)",
    SortsMostRecent: "Most recent",
    SortOldest: "The oldest",
    allStatus: "All statuses",
    WAITING: "Waiting for validation",
    SOON: "To come",
    ONGOING: "In progress",
    ENDED: "Ended",
    REFUSED: "Refuse",
    allCourses: "All courses",
    NoWebinarHasBeenCreatedYetCreateYourFirstWebinarToGetStarted: "No webinars have been created yet. Create your first webinar to get started.",
    ForStudentsOf: "For training students",
    WebinarGivenBy: "Webinar given by",
    ENTERTHEWebinar: "ENTER THE webinar",
    StartTHEWebinar: "Start THE webinar",
    AddToMyAgenda: "Add to my calendar",
    validate: "To validate",
    inMyAgenda: "in my diary",
    Edit: "Edit",
    REVIEWTHEwebinar: "REVIEW THE webinar",
    IWantTheWebinarToBeAccessibleInReplayWithThePublicConcerned: "I would like the webinar to be accessible in replay to the public concerned",
    NewWebinar: "New webinar",
    close: "CLOSE",
    Webinar: "Webinars",
    WebinarTitle: "Webinar title",
    WebinarDate: "Date you webinar",
    WebinarSchedule: "Webinar Schedule",
    ShortDescriptionOfTheWebinar: "Short description of the webinar",
    Cancel: "cancel",
    WaitingForValidation: "Waiting for validation",
    ComingSoon: "Shortly",
    LetsGo: "Let's go",
    ToReview: "To review",
    inscriptionCourseFaq: "Register for a course",
    followCourseFaq: "Take a class",
    certificationFaq: "The graduation",
    professorsFaq: "Professors",
    stopCourseFaq: "Stop a lesson",
    paymentFaq: "Billing",
    PAYED: "Paid",
    PRIX: "Price",
    openQuestion: "Open question",
    Consignes: "Instructions",
    Valeurdelaquestion: "Question value",
    point: "point",
    Nouveau_test: "New test",
    TEST_DE_CONNAISSANCE: "KNOWLEDGE TEST",
    Test_intermédiaire: "Intermediate assessment test",
    Non_éliminatoire: "Not eliminatory",
    Question_fermées: "Closed questions",
    Test_de_fin_de_cours: "End of course test",
    Validant_le_diplome: "Validating the diploma",
    Question_fermée_et_ou_ouvertes: "Closed and/or open questions",
    modal_exam_title: "What type of test do you want to create?",
    AJOUTER_EXPLICATION: "ADD AN EXPLANATION",
    Explication: "Explanation",
    add: "Add",
    Créer_votre_premiere_question: "Create your first question",
    Examen_final_envoyé_le: "Final exam sent on",
    En_attende_de_correction_par_le_professeur: "Awaiting correction by the teacher",
    Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes: "Answer closed or open questions by following the instructions.",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome: "The test is performed only once and",
    Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2: "determines your graduation.",
    Réuissi: "Successful",
    insuffisant: "insufficient",
    Valider_ma_réponse: "Validate my answer",
    Rendu_le: "Delivered on",
    Par: "about",
    NOTER_LA_QUESTION: "NOTE QUESTION",
    Examens_à_noter: "Exams to note",
    Examen_notés: "Graded exams",
    Tous_les_examens: "All exams",
    aucun_examen_à_noter: "No exam to grade",
    NOTE: "NOTE",
    à_noter: "to note",
    Commentaire_du_professeur: "Teacher's comment",
    Pas_de_commentaire: "No comment",
    Ajouter_un_commentaire: "add a comment",
    VALIDER_MA_NOTATION: "VALIDATE MY RATING",
    EXAMEN_FINAL: "FINAL EXAM",
    exam1: "Indicate here the title of the final exam ...",
    exam2: "Indicate the title of the knowledge test here",
    votreMessage: "Write your message...",
    noMessage: "No message",
    noMessageDispo: "not yet available...",
    message_1: "You have to wait for the teacher",
    message_2: "open the conversation",
    message_1_prof: "Start a conversation by typing",
    message_2_prof: "your message below.",
    textFaq: "What if I want to know more about the grading of my written assignment?",
    draftstatdateFollow: "Case started on",
    finishdraftFollowButton: "Finish my case",
    gotocourseStudent: "Access the course",
    myFormations: "My trainings",
    SelectCourse: "Select course",
    NoWebinarHasBeenCreatedYet: "No webinars have been created yet.",
    TitleFormations: "Trainings",
    InscriptionDate: "Register on",
    biography: "Biography",
    labelMailProfessor: "Teacher email",
    comments: "Comments",
    noStudentInscribed: "No student is registered yet",
    demandTable: "Request made on",
    createNewFormation: "New formation",
    selectFormatFormation: "Select a size",
    pathTitle: "Path",
    createPath: "create a route",
    sessionTitle: "Session",
    createSession: "create a session",
    requiredLevelText: "Level required to enroll in the course",
    anyLevelCourse: "all levels",
    firstLevel: "Beginner",
    secondLevel: "Intermediate",
    thirdLevel: "Expert",
    ANYLEVEL: "all levels",
    BEGINNER: "Beginner",
    courseDescriptionForm: "Describe your course",
    descriptionTitle: "Description",
    courseContentTitle: "Course content",
    pathContentTitle: "Course content",
    sessionContentTitle: "Session content",
    importVideoCourse: "Video import",
    createContentText: "Create your first content",
    chapterPresentation: "Presentation of the chapter",
    textPresentationChapter: "Introductory text of the chapter",
    groupes: "Groups",
    newGroup: "New group",
    groupTitle: "Student group title",
    newTestModalTitle: "New test",
    alreadyCreateExam: "You have already created a final exam",
    finalExmBreadcrunbTitle: "Final exam",
    noGroupFound: "No student group created",
    noGroupFound_2: "Your search does not match any group",
    updateGroupTitle: "Edit title",
    deleteGroup: "Delete group",
    updateGroup: "Change group title",
    addSubGroup: "Add a subgroup",
    addStudentToGroup: "Add students",
    Actions: "Actions",
    textConnaissance: "Knowledge test",
    points: "points",
    connectedThereIs: "logged in ago",
    messageDeleteGroup_1: "This group will be permanently deleted.",
    messageDeleteGroup_2: "You don't have the option to restore this group if you change your mind",
    messageDeleteGroup_3: "Group deleted successfully",
    closedQuestion: "Closed questions",
    pointsQuestion: "point(s)",
    explicationText: "Write your explanation...",
    allStudent: "All the students",
    subGroup: "subgroup",
    changeGroup: "Change group",
    addToGroup: "Add to group",
    deleteFromGroup: "Remove from group",
    documentTextForm: "Document",
    returnToCourse: "back to class",
    returnToPathButton: "return to course",
    returnToSessionButton: "back to session",
    sendPublishCoursePRofTitle: "Send to the director to validate the publication",
    returnToStudentList: "Back to student list",
    Previsualiser: "Preview",
    editeMode: "Edit",
    withInscription: "Registration under file",
    freeTitleCourse: "offered",
    questionPlaceholder: "Your question here...",
    responsePlceholder: "Write an answer here..",
    explicationPlaceholder: "Write an explanation here...",
    consignesPlaceholder: "Your instructions here...",
    goToCatalogueError: "To return to the previous page click on the button below.",
    errorCode: "Error code",
    oupsWord: "Whoops!",
    errorInfoNotFound: "Sorry, the page you are looking for cannot be found.",
    errorInfoUnauthorized: "No permissions found.",
    errorSubinfoUnauthorized: "This page is not accessible to the public.",
    errorInfoAccessRefused: "Access denied.",
    errorSubInfoAccessRefused: "You are not authorized to access this page.",
    serverProblem: "server problem",
    tryLater: "Please try in a few minutes.",
    unexpectedError: "Sorry, unexpected error.",
    errorInfoServerProblem: "The page you are looking for is currently under maintenance and will be back soon.",
    continueCreatingPath: "Sorry, you cannot publish this course.",
    continueCreatingSession: "Sorry, you cannot publish this session.",
    returnToPath: "return to course",
    returnToSession: "back to session",
    courseDesactivationSuccess: "Course deactivated successfully",
    pathDesactivationSuccess: "Course deactivated successfully",
    sessionDesactivationSuccess: "Session disabled successfully",
    textDeactivateCourse: "Disable course",
    textDeactivatePath: "Disable course",
    textDeactivateSession: "Deactivate session",
    textreactiovationCourse: "Course successfully activated",
    textreactiovationPath: "Route successfully activated",
    textreactiovationSession: "Session activated successfully",
    text_1_desactivateCourse: "This course will no longer be accessible.",
    text_2_desactivateCourse: "You have the option to reactivate the course if you change your mind",
    text_1_desactivatePath: "This route will no longer be accessible.",
    text_2_desactivatePath: "You have the option to reactivate the course if you change your mind",
    text_1_desactivateSession: "This session will no longer be accessible.",
    text_2_desactivateSession: "You have the option to reactivate the session if you change your mind",
    refuseCourseText: "Refuse the publication of the course",
    refusePathText: "Refuse the publication of the route",
    refuseSessionText: "Deny session publication",
    backToListCourses: "back to course list",
    textRefusedCourseSuccess: "Course successfully declined",
    textRefusedPathSuccess: "Path successfully declined",
    textRefusedSessionSuccess: "Session successfully denied",
    acceptCourseText: "Validate the publication of the course",
    acceptPathText: "Validate the publication of the course",
    acceptSessionText: "Validate the publication of the session",
    textAcceptCourse: "This course will be accessible to all students.",
    textAcceptPath: "This course will be accessible to all students.",
    textAcceptSession: "This session will be accessible to all students.",
    publishCourseSuccessModal: "Course successfully published",
    publishPathSuccessModal: "Course published successfully",
    publishSessionSuccessModal: "Session published successfully",
    errorContentLength: "You must have at least one content (required).",
    errorPathLinkImg: "Visual to illustrate the course (mandatory).",
    errorSessionLinkImg: "Visual to illustrate the session (mandatory).",
    errorPathDescription: "Text presentation of the course (mandatory).",
    errorSessionDescription: "Text presentation of the session (compulsory).",
    typeTable: "type",
    parcoursTitle: "Path",
    PATH: "Path",
    progress: "advancement",
    gotopathStudent: "Access the course",
    gotosessionStudent: "Access the session",
    newContent: "new content",
    chapterTitleBegin: "Chapter title",
    testTitleBegin: "Knowledge test title",
    examTitleBegin: "Exam title",
    onlineFormations: "Online training",
    validateFormations: "Training to be validated",
    allFormations: "All courses",
    inscribedTimeStudent: "registered on",
    suspendedAt: "Suspended on",
    newFinalExam: "Add a final exam",
    backToMyrequest: "Back to my request",
    beforeUploadText: "Drag and drop the files you want to share",
    uploadFile: "SELECT A FILE",
    Subscription: "Subscription request",
    waiting: "Pending",
    fileValidated: "File validated",
    fileRefused: "File refused",
    autorisationTraining: "Permissions",
    autorisationTrainingTable: "Permission",
    autorzedStatus: "Authorized",
    unauthorized: "Unauthorized",
    groupNameTable: "Name of the group",
    changeMyDecision: "Change my decision",
    backtodemand: "back on demand",
    cancelValidation: "Cancel validation",
    downloadFileCertif: "Download my diploma",
    nextChapterText: "Next chapter",
    getMyDiplome: "My diploma",
    ListAllCourses: "List all courses",
    ListOnlineCourses: "List of online courses",
    ListPendingCourses: "List of pending courses",
    ListStudents: "List of students",
    courseBy: "Course given by",
    SessionGivenBy: "Session given by",
    PathGivenBy: "Course given by",
    PaymentDetails: "Payment details",
    Completeyourpurchasebyprovidingyourpaymentinformation: "Complete your purchase by providing your payment information.",
    LastNameandFirstName: "Full name",
    CreditCardNumber: "Credit Card Number",
    ExpirationDate: "Date d'expiration",
    confirmandpay: "confirm and pay",
    Yourpaymentwassuccessful: "Your payment has been made successfully.",
    StartThePath: "start the journey",
    Yourpaymenthasbeendeclined: "Your payment has been declined",
    StartTheCourse: "start the course",
    StartTheSession: "start session",
    selectLevelCourse_4: "All levels",
    progressTable: "Advancement",
    notationLabel: "Notation",
    singleClosedQuestion: "Question closed",
    Du: "From",
    Au: "At",
    Examens: "Exams",
    fromWhereTraining: "Comes from",
    congratsTraining: "Congratulations, you have completed the course.",
    donnerAvis_3: "Give your opinion on the course:",
    donnerAvis_4: "Give your opinion on the session:",
    donnerAvisButton: "Give your opinion",
    InitialPathName: "Course title",
    PathDecriptionCreation: "Describe your background",
    prerequisite1: "Prerequisites to follow this course",
    skillsNeeded: "Knowledge and skills acquired through this course",
    pathCoverPhot: "Drag and drop a photo to illustrate the course (jpg, png)",
    pathVideoDescription: "Video presentation of the course",
    pathVideoDrop: "Drag and drop the course video you want to upload (mp4, mov)",
    PathLevel: "Level required to register for the course",
    SessionTitleCreation: "Session title",
    SessionLevel: "Level required to register for the session",
    SessionDescriptionCreation: "Describe your session",
    prerequisite2: "Prerequisites to follow this session",
    SessionSkills: "Knowledge and skills acquired during this session",
    SessionCoverDescription: "Visual to illustrate the session",
    SessionCoverDrop: "Drag and drop a photo to illustrate the session (jpg, png)",
    SessionDescriptionVideo: "Video presentation of the session",
    DragDropSession: "Drag and drop the session video you want to upload (mp4, mov)",
    saveSchoolLogo: "Add a picture",
    descriptionChoiseCours: "Create your course with its chapters, tests and final exam. Set up your admission file",
    descriptionChoisePath: "Assemble your courses and courses and create a training course with the passing conditions",
    descriptionChoiseSession: "Assemble your training with the passage conditions set in time",
    returnButton: "flip",
    waitingToPayment: "Awaiting payment",
    noUnpaidFound: "No expectation of payment",
    schoolAssistant: "administrator",
    passwordMandadory1: "The password must contain",
    passwordMandadory2: "At least 8 characters",
    passwordMandadory3: "At least one uppercase letter and one lowercase letter",
    passwordMandadory4: "At least 1 digit",
    passwordMandadory5: "At least one special character (#?!=@$%^&*-)",
    passConfirmation: "Password Confirmation",
    RésultatCumulé: "Cumulative result",
    etudiantsInscrits: "Registered students",
    TarifActuel: "Current price",
    TarifMoyen: "Average price",
    priceHistory: "Rate history",
    hidePricehistory: "Hide Rate History",
    saveModification: "Change saved!",
    Total: "Total",
    TotalInscrits: "Total registered",
    periodMedian: "Average price for the period",
    totalMedian: "Total average price",
    totalResult: "Total loophole",
    QUESTIONS_OUVERTES: "OPEN QUESTIONS",
    notValid: "Not valid",
    validAction: "Validated action",
    studentResponse: "Student Response",
    markUpdate: "modifier ma notation",
    webinarEnded: "Webinar finished",
    added: "Added",
    warning: "Attention !",
    changePrice: "You have just changed the course price:",
    priceNow: "Current price:",
    priceAfter: "New affected price:",
    saveUpdate: "SAVE CHANGE",
    profchoice: "Select a teacher to assign the course(s) to",
    deleteReason: "Reason for deletion*",
    disableAssistant: "This administrator will no longer be able to access the platform. You have the option of reinstating it if you change your mind.",
    addCommentToAssistant: "Add a comment for the wizard",
    connectAccountPaymee: "Connect your Paymee account",
    connect: "CONNECT",
    idPaymee: "ID Paymee",
    assistantInvitation: "Invite an administrator to register",
    mailAssistant: "administrator email",
    membreInvitation: "Invite a member",
    role: "Role",
    newCourse: "New course",
    newPath: "New course",
    newSession: "New session",
    profMandadotory: "This course must be assigned to a teacher.",
    profMandadtory2: "Select a teacher to assign this course to*",
    rehireAssistant: "This administrator will be able to access the platform. You have the option to suspend the administrator if you change your mind.",
    dragToUpload: "Drag and drop your photo",
    or: "OR",
    selectPhoto: "select your picture",
    offerList: "List of offers",
    answer: "Your answer",
    completedParts: "parts done",
    followPaths: "Follow Course",
    followSessions: "Follow Course",
    displayVideo: "Your presentation video or visual",
    toValidate: "To validate",
    archiveSheet: "Archive",
    connectZoom: "Connect Zoom",
    accountZoom: "zoom account",
    connectAccount: "Connect my account",
    connectAccountToschool: "Connect your zoom account with your school",
    changeOffer: "Change offer",
    facture: "Invoices",
    paymentMethod: "Payment methods",
    cancelSubscription: "Cancel my subscription",
    offerPrice: "Offer fees",
    nextDeadline: "Next payment due date",
    deleteOffer: "Deletion of offer",
    deleteMsgOffer: "Do you want to delete your offer?",
    passwordUpdated: "your password has been changed",
    passwordNoMatch: "the two passwords do not match",
    modifyProfil: "Edit my profile",
    newEmail: "new e-mail address",
    connectStripToSchool: "Connect your Stripe account with your school",
    disconnect: "Disconnect",
    connectPaymeeToSchool: "Connect your Paymee account with your school",
    paymentMessage: "Complete your purchase by providing your payment information.",
    paymentConfimation: "confirm payment",
    activate: "Enable",
    month: "Monthly",
    yearly: "Annuals",
    offerMessage1: "Everything included in the Basic plan, plus:",
    Choisir: "Picking out",
    compareoffers: "Compare all features",
    offer: "Offer",
    monthlyFor: "Monthly for",
    yearFor: "Annuals for",
    totalPrice: "Total price:",
    valid: "Valid",
    ProfList: "Prof List",
    StudentList: "Student List",
    CourseList: "Course List",
    Inscrits: "Registered",
    team: "Team",
    ADMINSTRATOR: "administrator",
    info: "Information",
    shortDescription: "short description",
    shortDescription1: "Short description of",
    nbrConx: "Number of connection",
    inscDate: "Date d'inscription",
    role1: "role",
    totalRevenu: "Total income",
    makeAPayment: "make a payment",
    Fileacceptedon: "File accepted on",
    params: "Settings",
    pricipalGroup: "main group",
    addPhoto: "add your photo",
    changePhoto: "Change your photo",
    deletePhoto: "Delete picture",
    seletionner: "SELECT",
    facturationDetails: "Billing Information",
    closedSchool: "This school is currently closed",
    hold: "Please",
    contact1: "Contact Support",
    contact2: "for more information.",
    question: "question",
    allTeam: "The whole team",
    assistants: "administrators",
    noTeam1: "No teacher/administrator found.",
    noTeam2: "Please search again.",
    noTeamDefault_1: "No team member is registered.",
    noTeamDefault_2: "Invite a member to sign up to get started.",
    noAssistant_1: "No administrators found.",
    noAssistant_2: "Please search again.",
    noAssistantDefault_1: "No administrator is registered.",
    noAssistantDefault_2: "Invite an administrator to sign up to get started.",
    TrainingToedit: "In progress",
    webinarForm1: "Select a teacher to assign this webinar to*",
    webinarForm2: "Select a teacher",
    webinarForm3: "For course students:",
    listeDesAssistants: "list of administrators",
    listeTeam: "team list",
    listOfUnpaidStudents: "list of unpaid students",
    Nounpaidstudent: "No unpaid student",
    memberMail: "Member email",
    integrations: "Integrations",
    monForfait: "My package",
    Facturation: "Billing",
    Detailsduforfait: "Package details",
    Gerezoumodifiezvotreforfait: "Manage or change your plan",
    Votreoffre: "Your offer",
    Fraisdeloffre: "Offer fees",
    Prochainedatedecheancedupaiement: "Next payment due date",
    Modedepayement: "Payment method",
    Changerleforfait: "Change plan",
    Annulermonabonnement: "Cancel my subscription",
    Limitationduforfait: "Package limitation",
    Suivrelalimitationdevotreforfaitactuel: "Track your current plan's throttling",
    Voirlesforfaits: "See packages",
    Vousavezatteintlalimitedaccesdevos: "You have reached the access limit of your",
    Vouspouvezajouterdes: "You can add",
    endesactivantun: "by disabling a",
    endesactivantune: "by disabling a",
    actifouenameliorantvotreforfait: "by upgrading your plan",
    activeouenameliorantvotreforfait: "by upgrading your plan",
    Modesdepayement: "payment methods",
    Gérezoumodifiezvotremodesdepaiement: "Manage or change your payment methods",
    congratsSession: "Congratulations, you have completed the session.",
    avisTitle1: "My opinion on the course",
    avisTitle2: "My opinion on the session",
    FormationAValider: "No training to validate.",
    FormationEnLigne: "No online training.",
    emptyListFormation1: "Please search again.",
    noTrainingSearch: "This teacher has no training yet.",
    NoWebinarToValidate: "No webinar to validate.",
    NoWebinarIsComingSoon: "No upcoming webinars.",
    NoWebinarIsOnGoing: "No current webinars.",
    NoWebinarHasEnded: "No completed webinars.",
    NoWebinarIsRefused: "No webinars declined.",
    NoWebinarIsAssociatedToThisCourse: "No webinar associated with this course.",
    NoWebinarExists: "No webinars found.",
    FirstWebinar: "No webinars have been created yet.",
    FirstWebinar2: "Create your first webinar to get started.",
    transformKnowledge: "Turn your knowledge into wealth",
    addToDescription: "Add to Description",
    importListOfStudents: "Import the list of students.*",
    Remplacer: "Replace",
    returnToStudentsList: "Back to student list",
    invite: "invite",
    continue: "Continue",
    savedUsers: "I have the consent of the users registered in this file, according to the personal data protection law of the country in force.",
    download: "Download",
    loadInformation: "Loading your information, please wait...",
    yourAccount: "From your account",
    emailSend: "Email sent",
    check: "check",
    verifyYourEmail: "Check your email and open the link you received to continue",
    returnToConnexionPage: "back to login page",
    save: "Save",
    coursResume: "Write the summary of this course, presenting its most important points and showing its added value for the member.",
    coursObjectives: "List the objectives to be achieved during this course.",
    preRequisite: "Add the necessary prerequisites to take this course.",
    skillsToLearn: "List the skills to be acquired during this course in the form of keywords.",
    pathResume: "Write a summary of this course, presenting its most important points and showing its added value for the member.",
    pathObjectives: "List the objectives to be achieved during this course.",
    pathPreRequisite: "Add the necessary prerequisites to follow this course.",
    pathSkillsToLearn: "List the skills to be acquired during this course in the form of keywords.",
    sessionResume: "Write the summary of this session, presenting its most important points and showing its added value for the member.",
    sessionObjectives: "List the objectives to be achieved during this session.",
    sessionPreRequisite: "Add the necessary prerequisites to follow this session.",
    sessionSkillsToLearn: "List the skills to be acquired during this session in the form of keywords.",
    timeNotAvailable: "This schedule is not available, please choose another one.",
    annulerImportation: "Cancel import",
    annulerImportationConfirmation: "Are you sure you want to cancel the import?.",
    retourImportation: "Back to import",
    failedRequest: "Request failed",
    lowerDeal: "To switch to a lower plan, you must adjust your limit according to the limits of the chosen plan.",
    activeProfessors: "Active teachers",
    activeStudents: "Active students",
    activeAssistants: "Active administrators",
    professorList: "List of teachers",
    trainingList: "List of courses",
    setAsPrincipal: "Set as main?",
    replacePaymentMode: "This will replace your current primary payment method.",
    deletePaymentMethod: "Delete payment method",
    deletePaymentMethodConfirmation: "Are you sure you want to delete the payment method.",
    deleteInvitation: "Cancel invite",
    invitationAnnulationOfYourSchool: "Canceling the invitation will no longer allow that person to become a member of",
    yourSchool: "your school.",
    youCanReinvite: "You can always invite him back.",
    keepInvitation: "keep the invite",
    noCourseFound: "No courses found",
    wantCreateCourse: "Want to create a course?",
    cannotImportListOfStudents: "You cannot import a student list that contains errors.",
    firstName: "First name",
    obligatoire: "is obligatory",
    name: "The name",
    emailAddressNotValid: "Invalid email address.",
    emailFormat: "The format of the email must be",
    emailExample: "votrenom@exemple.com ",
    importationResult: "Import result",
    succesfulImportation: "successful imports",
    lignes: "lines",
    containErrors: "contain errors",
    addStudent: "Add a student",
    importList: "Import a list",
    invitationSentSuccesfully: "Invitation sent successfully",
    invitationSuccessAssistant: "Your invitation has been successfully sent to the administrator.",
    userAlreadyExists: "The user with this email already exists.",
    enterValidEmail: "Please enter a valid email address.",
    professorLimitAtteined: "You have reached your teachers' access limit. You can add teachers by deactivating an active teacher or upgrading your plan.",
    assistantLimitAtteined: "You have reached the access limit for your administrators. You can add administrators by disabling an active administrator or upgrading your plan.",
    compareOffers: "Compare offers",
    schoolUses: "Your school uses features",
    notIncludedInOffer: "that are not supported by the offer",
    passToOffer: "If you switch to the offer",
    allFonctionalities: ", all features",
    areDesactivated: "of your school will be deactivated and inaccessible.",
    invitationsSentSuccesfully: "invitations have been sent successfully",
    automaticSendOfInvitations: "Automatic sending of invitations",
    invitationSendConfirmation: "Do you confirm sending invitations to the list you imported?",
    confirmAndSend: "Confirm and Send",
    principal: "Principal",
    noPaymentMethodAdded: "No payment method added",
    addPaymentMethod: "Add a payment method",
    remainning: "you still have",
    changePaymentMethod: "Change payment method",
    leaveMsg: "We're sorry to see you go!",
    annulationWarning: "Once you cancel, your account will remain active until your billing ends on",
    annulationWarning2: "After that, you will lose access to your school.",
    annulationConfirmation: "Are you sure you want to cancel your subscription?",
    enterPasswordtoConfirm: "Enter your password to confirm",
    cancelationReasons: "Please let us know why you are canceling your subscription. Your comments, it will help us to improve the product",
    whyuCanceling: "Why are you canceling your subscription",
    selectLowerCase: "Select",
    missingFonctionality: "A feature I want is missing",
    expensive: "I think it's too expensive",
    notInteressted: "Je n'utilise plus fever",
    movingToAnotherPlatform: "I'm switching to another platform",
    preciser: "Please specify",
    wantToTellUsMore: "Want to tell us more?",
    unlimited: "Unlimited",
    Cryptogramme: "Cryptogram",
    savePaymentMethod: "Save this payment method in my profile",
    paymentPassed: "Payment made",
    paymentSuccesfull: "Your payment has been successfully processed.",
    returnToActivity: "Back to activity",
    twoFreeMonths: "2 months free",
    fourFreeMonths:"4 months free",
    essentialTools: "The essential tools to get started, with no hidden costs",
    forever: "for all time",
    actuelOffer: "Your current offer",
    selectFreeOffer: "Choose the Free offer",
    pathAndSessions: "Routes and sessions",
    forProfessionalsAndNonProfessionals: "For professional trainers and infopreneurs.",
    yearlyBill: "Billed annually",
    perMonth: "per month",
    selectProOffer: "Choose the Pro offer",
    courses: "Classes",
    illimitedAccess: "To enjoy unlimited Koors.",
    perStudent: "per student",
    minimum: "minimum",
    selectBussinessOffer: "Choose the Business offer",
    illimitedNumberOfStudents: "Unlimited number of students",
    illimitedNumberOfCours: "Unlimited number of lessons",
    illimitedNumberOfProfessors: "Unlimited number of teachers",
    illimitedNumberOfAssistants: "Unlimited number of administrators",
    subscriptionPayments: "Subscription payment",
    Devise: "Devise",
    FacturationCycle: "Billing cycle",
    monthly: "Monthly",
    yearly_2: "Annual",
    ofReduction: "reduction",
    minimalBill: "Minimum billing",
    nextBill: "Next billing",
    confirmPayment: "Payment confirmation",
    reductionOnCurrentPlan: "Reduction on the annual plan",
    verification: "Verification",
    noStudentFound_2: "No students found",
    archivedTrainings: "No training archived",
    refusedTrainings: "No training refused",
    desactivatedTrainings: "No training disabled",
    editedTrainings: "No training being edited",
    noTrainingHaveBeenCreated: "No cheeses have been created yet.",
    createFirstTraining: "To begin, create your first formation.",
    examsToNote: "No exams to grade.",
    notedExams: "No graded exam.",
    noExam: "You have no exam.",
    resendInvitation: "Resend invitation",
    Copyright: "Copyright",
    rights: "All rights reserved.",
    noExamFound: "No reviews found.",
    atteinedMaximum: "You have reached the maximum number allowed for a plan",
    updatePlan: "Please update your plan to create more formations.",
    noBill: "No invoice.",
    finalExam: "Final exam .",
    selectAnImage:"SELECT AN IMAGE",
    slideToAddAnImage:"Drag and drop a photo to illustrate the webinar",
    yourWebinarsAreIntegrated:"Your webinars are integrated",
    onThePlatform:"to the platform with the",
    offreProAndBussiness:"Professional and Business offers",
    createWebinar:"Simply create your webinars directly in Koors Select and directly invite your students to participate (eligible new students are automatically invited).",
    findReplayAndPublishWebinar:"Find the replays of your webinars and publish them in your training.",
    improveMyOffer:'Improve my offer',
    soonWebinarsAvailable:"Soon webinars will be offered to you.",
    willInformuSoon:"We keep you informed.",
    courseResumeIntroduction:"Introductory summary of the course...",
    reprendre:"Resume",
    freeOffreNoPaymentMethod:"You don't have payment methods. You are registered for the Free offer.",
    Credit:"Credit",
    DebitCard:"Debit card",
    offre:"Offer",
    nameOnCard:"Name on the card",
    traitement:"PROCESSING...",
    paiement:"Payment",
    offersList:"List of offers",
    perYear:"per year", 
    wrongBirthDate:"Wrong date of birth",
    selectCountry:"Choose a country",
    cardAddedSuccessfully:"your card has been successfully added",
    returnToParameters:"back to settings",
    addNewPaymentMethod:"Add a new payment method",
    verifyPassword:"Invalid Password, please verify your password",
    returnToOffer:"Back to my offer",
    subscriptionCancelled:"Your subscription has been successfully canceled",
    perMonthPayedAnually:"month, paid annually",
    downloadTemplate:"download template",
    toSee:"excel to see format example.",
    slideExcel:"Drag and drop your Excel file",
    reposition:"REPOSITIONING",
    importNewPhoto:"UPLOAD A NEW PHOTO",
    importPhoto:"UPLOAD A PHOTO",
    slideToSet:"drag to position",
    publishMyOpinion:"Publish my review",
    noWebinarIsPlanned:"No webinars is planned." ,
    account:"Account",
    perYearPayedAnually:"year, paid annually",
    titleSessionPlaceholder:"Enter the title of Session here...",
    titlePathPlaceholder:'Enter the title of Path here...',
    addVideoUrl:"Add Video URL",
    itegrateVideo:"embed video",
    enterValidUrl:"Make sure you entered a valid URL.",
    pasteVideoUrl:"Paste video link...",
    addVideoToIntegrate:"Add video url to embed",
    creationDate:"creation date",
    createdAt:"Created at",
    generic_decline: "Payment declined for generic reasons",
    insufficient_funds : "Card funds are insufficient",
    lost_card : "The card is declared lost",
    stolen_card : "The card is reported stolen",
    expired_card : "The card has expired",
    incorrect_cvc : "CVC code is incorrect",
    processing_error : "Payment declined due to processing error",
    gender:"Gender",
    male:"Man",
    female:"Women",
    noCommentFound1:"No comment",
    lastUpdate:"Last post",
    lignes1:"line",
    containErrors1:"contains errors",
    emptyTitleError:"Course title (required)",
    emptyPathTitleError:"Path title (required)",
    emptySessionTitleError:"Session title (required)",
    Webinar1: "Webinars",
    notFound1:"Add more students and step up a gear",
    notFound2:"Koors brings together the best features to distribute online courses to as many people as possible." ,
    notFound3:"Thanks to Koors, you can easily manage your student/customer communities and keep in touch at all times..",
    emailAlreadyUsed:"This email address is already in use",
    Clôturé:"Closed",
    Ouvert:"Open",
    TITREdusondage:"Survey Title",
    Auteur:"Author",
    CRÉELE:"Created on",
    CloturéLE:"Closed on",
    RÉPONSEs:"Responses",
    Créerunsondage:"Create a survey",
    sondages:"Surveys",
    sondage:"Survey",
    inscribedInSchool:"Registration in the school",
    catalogVisits:"Catalog page visits",
    currentStudentNumber:"Number of current students",
    currentStudentsWithDiploma:"Number of graduate students",
    noSchooling:"not mentioned",
    transcodeInProgress: 'Finalizing the import is in progress. You can continue to complete the content.',

}