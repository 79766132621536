import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { clearTeamList, deleteInvitation, getTeam, getTeamNumber } from '../../../global-state/actions/professor-actions';
import { closeModal, closeModalWithAction, deactivateModal } from '../../../global-state/actions/modal-actions';
import { useHistory } from 'react-router';
import { returnStartPathUrl } from '../../../helpers/domainCheck';

const DeleteTeamMemberInvitation: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [idMember, setidMember] = useState(props?.content?.info?.id)
    const [role, setRole] = useState(props?.content?.info?.role)
    const { t } = useTranslation();
    const history = useHistory()


    useEffect(() => {
        setidMember(props?.content?.info?.id)
        setRole(props?.content?.info?.role)
    }, [props?.content])


    const closeModal = () => {
        props?.closeModal()
    }


    const cancleInvitation = async () => {
        props.deactivateModal()
        await props?.deleteInvitation(idMember, role, props?.director?.idSchool)
        if (role === "PROF" || role === "ADMINSTRATOR") {
            await props?.clearTeamList()
            await props?.closeModal()
            await props?.getTeamNumber(props?.director?.idSchool);
            await props.getTeam(props?.director?.idSchool, "ADMINSTRATOR", 0, '')
            await props.getTeam(props?.director?.idSchool, "PROF", 0, '')
            await props.getTeam(props?.director?.idSchool, "ALL", 0, '')
            history.push(`${returnStartPathUrl()}/professors/all`)
        } else {
            await props?.closeModalWithAction('reload')
        }
    }





    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="row justify-content-center">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="H3-Headline">{t('deleteInvitation')}</h3>
                            </div>

                            <div className="col-md-12 pt-2 d-flex flex-column">
                                <span className='body-md neutral-2'>{t('invitationAnnulationOfYourSchool')}</span>
                                <span className='body-md neutral-2'>{t('yourSchool')}</span>
                                <span className='body-md neutral-2'>{t('youCanReinvite')}</span>
                            </div>


                            <div className="d-flex flex-column w-100 mt-5 mb-4 ">
                                <div className='d-flex flex-row justify-content-end' >
                                    <div style={{ paddingRight: "12px" }}>
                                        <button className="btn-Secondary large" onClick={() => props?.closeModal()} >
                                            {t('keepInvitation')}
                                        </button>
                                    </div>
                                    <button className="btn-Primary large danger" onClick={() => { cancleInvitation() }} >
                                        {t('deleteInvitation')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({

    director: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearTeamList,
            getTeamNumber,
            getTeam,
            deleteInvitation,
            closeModal,
            closeModalWithAction,
            deactivateModal
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteTeamMemberInvitation);