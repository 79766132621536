
import { EReduxActionTypes, IReduxGetStudentAction, IReduxClearStudentAction, IReduxGetFollowsStudentAction  } from '../actions/profile-actions'


export interface IReduxIProfState {
  actualStudent?:any
  studentFollows?: any
}

const  initialState: IReduxIProfState = {
    actualStudent: undefined,
    studentFollows: {},
};

type TDetailsReducerActions = IReduxGetStudentAction | IReduxGetFollowsStudentAction | IReduxClearStudentAction;

export default function (state: IReduxIProfState = initialState, action: TDetailsReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.GET_STUDENT:  
     return  { ...state, actualStudent:action.data};
    case EReduxActionTypes.GET_FOLLOWS_STUDENT: 
     const copyStateIns = {...state};
      copyStateIns.studentFollows =  action.data;

      return {...state, studentFollows: copyStateIns.studentFollows }
    case EReduxActionTypes.CLEAR_STUDENT:
    return {...state, studentFollows: {}};
    default:
      return state;
  }
}
