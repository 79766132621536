/* eslint react/no-did-mount-set-state: 0 */
import { FC, useMemo, useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { updatePath } from '../../../../global-state/actions/breadcrumb-actions'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { uploadImageCourse } from '../../../../global-state/actions/course-actions';
import {clearFormVideo} from '../../../../global-state/actions/upload-video-course-actions';
import { useTranslation } from 'react-i18next';
import DescriptionPathFormComponent from '../../../../components/formation-path-form/description-path-form-component';
import HeaderTrainingFormComponent from '../../../../components/formations-card/header-formation-form';
import axiosInstance from '../../../../helpers/interceptors';
import PreviewPathPageFromProf from '../../../catalog/preview-path-page-from-prof';

const DescriptionPathFormPage: FC <ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const { t } = useTranslation();
    const [copyDate,setCopyDate]=useState()
    const [modeVision, setModeVision] = useState<boolean>(false);

    const [trainingOffer, setTrainingOffer] = useState(null)

    const path=useMemo(() => {
        return props?.path;
    }, [props?.path])


    useEffect(()=>{
        setCopyDate(props?.path?.creationDate.substring(0,10).split("-").reverse().join("/"));
    },[props?.path])


        return (
            <>
            <div style={{backgroundColor: '#FFFFFF'}} className="w-100">
            <HeaderTrainingFormComponent type={"PATH"} title={t('descriptionTitle')} status={path?.status} trainingTitle={path?.title} creationDate={copyDate}>
                <div>
                    <input dir="ltr"readOnly className="input-style" type="checkbox" id="toggleBtn4" onClick={() => setModeVision(!modeVision)} checked={modeVision} />
                    <div className="toggle-container cours-editing">
                        <label className="label-style cours-editing" htmlFor="toggleBtn4">
                            <span className="on cours-editing">{t('editeMode')} </span>
                            <span className="off cours-preview">{t('Previsualiser')}</span>
                            <input dir="ltr"type="text" style={{ display: 'none' }} />
                        </label>
                    </div>
                </div>
            </HeaderTrainingFormComponent>
            </div>
            
        {!modeVision ? <DescriptionPathFormComponent /> : <PreviewPathPageFromProf pathInfoFromProf={props?.path} trainingOfferFromPath={trainingOffer} />}

            </>
        );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { 
            uploadImageCourse,
            updatePath,
            clearFormVideo,
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    path: state.trainingReducer.path,
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionPathFormPage);