import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';

const PathExamComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [exam, setExam] = useState<any>();
    const progress = useMemo(() => { return props?.exam?.progression }, [props?.exam]);
    useEffect(() => {
        if (props?.exam) {
            setExam(props?.exam);
        }
    }, [props?.exam])

    const clickedExam = () => {
            props?.clickedExam(props?.exam);
    }
    const getDuration = ( ) => {
        if (exam?.duration < 60) {
            return exam?.duration + ' mn';
        } else {
            return Math.floor(exam?.duration / 60) + 'h' + (((exam?.duration % 60) < 10 ? '0' : '') + (exam?.duration % 60));
        }
    }
    return (
            <>
                {
                    !props.isMobile && <div onClick={() => { clickedExam() }} className="d-flex align-items-center bg-with-border white vignette-hover dragAndDrop py-28 ps-40 pe-4" style={{ maxWidth: props?.width, width: '-webkit-fill-available'  }}>
                        <div className="me-40">
                            {(props?.secondIndex === undefined) && <span className="H3-Headline">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span>}
                            {(props?.secondIndex !== undefined) && <span className="H3-Headline">{props?.index + 1}.{props?.secondIndex + 1}</span>}    
                        </div>
        
                        <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100">
                            <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                    <span className="H3-Headline mb-6 formation-card horizontal title">{exam?.title}</span>
                                    <div className="d-flex flex-row align-items-center" style={{ height: 24 }}>
                                        <div className="d-flex me-2">
                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.3469 3.94322V1.41788C13.3469 0.633432 12.6588 0 11.7905 0H1.57023C0.706601 0 0 0.633432 0 1.41788V12.4169C0 13.2056 0.706601 13.8432 1.57023 13.8432H9.23662V15.4121C9.23662 15.6261 9.37055
                                                    15.819 9.57376 15.9239C9.77696 16.0288 10.031 16.0246 10.2296 15.9155L12.4879 14.678L14.7463 15.9155C14.8479 15.97 14.9633 15.9994 15.0834 15.9994C15.1942 15.9994 15.3143 15.9742 15.4113 15.9239C15.6145
                                                    15.819 15.7484 15.6261 15.7484 15.4121V10.7977C16.5336 10.0594 17 9.06102 17 7.95775C16.9954 5.97356 15.4252 4.31237 13.3469 3.94322ZM9.23662 12.6686H1.57023C1.41782 12.6686 1.29313 12.5596 1.29313
                                                    12.4169V1.41788C1.29313 1.27945 1.41782 1.17458 1.57023 1.17458H11.7905C11.9429 1.17458 12.0538 1.27945 12.0538 1.41788V3.8803C9.79082 4.07326 7.9712 5.82674 7.9712 7.95356C7.9712 9.05682 8.45151 10.0594
                                                    9.23662 10.7935V12.6686ZM14.4553 14.3718L12.8343 13.4867C12.6265 13.3734 12.3586 13.3734 12.1508 13.4867L10.5297 14.3718V11.6451C11.1301 11.901 11.7952 12.0478 12.4925 12.0478C13.1899 12.0478 13.8549
                                                    11.9052 14.4553 11.6451V14.3718ZM12.4925 10.8732C10.7237 10.8732 9.2828 9.56441 9.2828 7.95775C9.2828 6.3511 10.7237 5.04229 12.4925 5.04229C14.2613 5.04229 15.7023 6.34691 15.7023 7.95775C15.7023 9.56441
                                                    14.2613 10.8732 12.4925 10.8732Z" fill="#9DDBA9" />
                                                <path d="M8.35911 3.10413H2.54004V4.27871H8.35911V3.10413Z" fill="#9DDBA9" />
                                                <path d="M6.4656 6.33429H2.54004V7.50887H6.4656V6.33429Z" fill="#9DDBA9" />
                                                <path d="M7.34308 9.56445H2.54004V10.739H7.34308V9.56445Z" fill="#9DDBA9" />
                                            </svg>
                                        </div>
                                        <div className="body-sm bold neutral-2">
                                            {t('finalExmBreadcrunbTitle')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" d-flex align-items-center detail-liste-responsive-actions detail-liste-responsive-session me-4 pe-2 justify-content-end" style={{ minWidth: 520 }}>
                                <div className="ms-2 d-flex flex-row detail-liste-responsive-other progress-training flex-wrap me-4 pe-2">
                                    <div className="d-flex align-items-center detail-liste-responsive-level me-5 pe-3">
                                        {(progress?.status === 'TOREVIEW') ?
                                            <div className={`state-cours unlocked-inprogress d-flex align-items-center justify-content-center`}>
                                                <div className="threedots-large-Animation d-flex align-items-center">
                                                    <span className="small"></span>
                                                    <span className="small"></span>
                                                    <span className="small"></span>
                                                </div>
                                            </div>
                                            : (progress === null) ?
                                                <div className="state-cours unlocked-inprogress">
                                                    <div className="threedots-small d-flex">
                                                        <span className="inprogress"></span>
                                                        <span className="inprogress"></span>
                                                        <span className="inprogress"></span>
                                                    </div>
                                                </div>
                                                : <div className={`state-cours exam ${(progress?.status === 'COMPLETED' && (progress?.scaleNote / progress?.finalMark) > 0.5) ? 'done' : (progress?.status === 'COMPLETED' && (progress?.scaleNote / progress?.finalMark) < 0.5) ? 'refused' : ''}`}>
                                                    <span className="material-icons-outlined state-cours-icon small">
                                                        emoji_events
                                                    </span>
                                                </div>}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
                                        <span className="body-md bold">{getDuration()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    props.isMobile && <div onClick={() => { clickedExam() }} className="w-100 bg-with-border white vignette-hover p-3" style={{ maxWidth: props?.width, width: '-webkit-fill-available'  }}>
                        <div className='d-flex align-items-center'>
                            <div className="col-2">
                                {(props?.secondIndex === undefined) && <span className="H4-Subtitle">{((props?.index + 1) < 10) ? '0' + (props?.index + 1) : (props?.index + 1)}</span>}
                                {(props?.secondIndex !== undefined) && <span className="H4-Subtitle">{props?.index + 1}.{props?.secondIndex + 1}</span>}    
                            </div>

                            <div className="col-7">
                                <span className="H4-Subtitle formation-card">{exam?.title}</span>
                            </div>
                            
                            <div className="d-flex align-items-center">
                                <span className="material-icons-outlined neutral-3">timer</span>
                                <span className="body-md bold">{getDuration()}</span>
                            </div>
                        </div> 

                        <div className='d-flex mt-2'>
                            <div className='col-2'/>
                            <div className='d-flex align-items-center col-5'>
                                <div className=" me-2">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3469 3.94322V1.41788C13.3469 0.633432 12.6588 0 11.7905 0H1.57023C0.706601 0 0 0.633432 0 1.41788V12.4169C0 13.2056 0.706601 13.8432 1.57023 13.8432H9.23662V15.4121C9.23662 15.6261 9.37055
                                            15.819 9.57376 15.9239C9.77696 16.0288 10.031 16.0246 10.2296 15.9155L12.4879 14.678L14.7463 15.9155C14.8479 15.97 14.9633 15.9994 15.0834 15.9994C15.1942 15.9994 15.3143 15.9742 15.4113 15.9239C15.6145
                                            15.819 15.7484 15.6261 15.7484 15.4121V10.7977C16.5336 10.0594 17 9.06102 17 7.95775C16.9954 5.97356 15.4252 4.31237 13.3469 3.94322ZM9.23662 12.6686H1.57023C1.41782 12.6686 1.29313 12.5596 1.29313
                                            12.4169V1.41788C1.29313 1.27945 1.41782 1.17458 1.57023 1.17458H11.7905C11.9429 1.17458 12.0538 1.27945 12.0538 1.41788V3.8803C9.79082 4.07326 7.9712 5.82674 7.9712 7.95356C7.9712 9.05682 8.45151 10.0594
                                            9.23662 10.7935V12.6686ZM14.4553 14.3718L12.8343 13.4867C12.6265 13.3734 12.3586 13.3734 12.1508 13.4867L10.5297 14.3718V11.6451C11.1301 11.901 11.7952 12.0478 12.4925 12.0478C13.1899 12.0478 13.8549
                                            11.9052 14.4553 11.6451V14.3718ZM12.4925 10.8732C10.7237 10.8732 9.2828 9.56441 9.2828 7.95775C9.2828 6.3511 10.7237 5.04229 12.4925 5.04229C14.2613 5.04229 15.7023 6.34691 15.7023 7.95775C15.7023 9.56441
                                            14.2613 10.8732 12.4925 10.8732Z" fill="#9DDBA9" />
                                        <path d="M8.35911 3.10413H2.54004V4.27871H8.35911V3.10413Z" fill="#9DDBA9" />
                                        <path d="M6.4656 6.33429H2.54004V7.50887H6.4656V6.33429Z" fill="#9DDBA9" />
                                        <path d="M7.34308 9.56445H2.54004V10.739H7.34308V9.56445Z" fill="#9DDBA9" />
                                    </svg>
                                </div>
                                <div className="body-sm bold neutral-2">
                                    {t('finalExmBreadcrunbTitle')}
                                </div>
                            </div>
                            <div className='mx-2'>
                                <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.2466 13.7905C25.2466 20.3474 19.9308 25.663 13.3733 25.663C6.81577 25.663 1.5 20.3474 1.5 13.7905C1.5 7.23355 6.81577 1.91797 13.3733 1.91797C19.9308 1.91797 25.2466 7.23355 25.2466 13.7905Z" fill="white" stroke="#DADBDF" stroke-width="3"/>
                                    <path d="M16.382 10.6702H15.769V9.44441C15.769 7.75279 14.396 6.37988 12.7043 6.37988C11.0126 6.37988 9.63961 7.75279 9.63961 9.44441V10.6702H9.02667C8.35243 10.6702 7.80078 11.2218 7.80078 11.896V18.0251C7.80078 18.6993 8.35243 19.2509 9.02667 19.2509H16.382C17.0562 19.2509 17.6079 18.6993 17.6079 18.0251V11.896C17.6079 11.2218 17.0562 10.6702 16.382 10.6702ZM10.8655 9.44441C10.8655 8.42699 11.6868 7.6057 12.7043 7.6057C13.7218 7.6057 14.5432 8.42699 14.5432 9.44441V10.6702H10.8655V9.44441ZM16.382 18.0251H9.02667V11.896H16.382V18.0251ZM12.7043 16.1864C13.3786 16.1864 13.9302 15.6348 13.9302 14.9606C13.9302 14.2864 13.3786 13.7348 12.7043 13.7348C12.0301 13.7348 11.4784 14.2864 11.4784 14.9606C11.4784 15.6348 12.0301 16.1864 12.7043 16.1864Z" fill="#DADBDF"/>
                                </svg>
                            </div>

                        </div> 

                    </div>
                }
            </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUserDetails: state.authReducer.connectedUser,
    currentTraining: state.studentTraining.currentTraining,
    index: ownProps?.index,
    clickedExam: ownProps?.clickedExam,
    exam: ownProps?.exam
});

export default connect(mapStateToProps, null)(PathExamComponent);
