import { ComponentProps, FC, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import { createCoupon } from '../../../services/coupon.service';
import { desactivateCoupon } from '../../../global-state/actions/form-actions';

const DeactivateCouponModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
 
    const couponData = useMemo(() => {return props?.content?.data}, [props?.content]);
    const deactivateCoupon = async () => {
        await createCoupon(couponData)
        await props?.desactivateCoupon();
        await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: 'RETOUR', lines: ["Coupon désactivé avec succès"]}); 
    }

    return (
        <>

<div className="modal-header" style={{ paddingBottom: 0 ,borderBottom: "none"}}>
            <div className="w-100 d-flex align-items-center justify-content-end p-3">
                 <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
        </div>

            <div  className="modal-body " style={{ paddingTop: 0, zIndex:1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-start mb-1">
                                <h3 className="H3-Headline">Désactiver le coupon “{couponData?.promotion?.promoCode}”</h3>
                            </div>
                        
                            <div className="col-md-12 input-text-form-control">
                            <span className="body-xl">Voulez-vous vraiment désactiver le coupon </span>
                            <span className="body-xl bold">“{couponData?.promotion?.promoCode}”?</span>
                            </div>
                           
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0 ,borderTop: "none"}}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-9 d-flex  justify-content-end' >
                        <button className="btn-Secondary large me-3" onClick={() => {props?.closeModal()}}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large danger" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { deactivateCoupon() }} >{t('deactivateCours')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectionUser: state.authReducer.connectedUser,
    profs: state.profReducer.profList,
    couponStatus:state.formReducer.couponStatus

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal, 
            desactivateCoupon
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DeactivateCouponModalComponent);


