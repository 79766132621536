import { ComponentProps, FC, useState } from 'react';
import FilterCommunityLoadingComponent from '../filters/filter-community-loading-component';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
const ManageCommunityLoading: FC<ReturnType<typeof mapStateToProps>  & ComponentProps<any>> = (props: any) => {
    const [actualTab]= useState((props?.tabChatManager) ? props?.tabChatManager : "discussion")
    const { t } = useTranslation();
    return (
    <>
        <div >
                <div className="row justify-content-center m-0 w-100">
                <div style={{backgroundColor: "#FFFFFF"}} className="col-md-12">
                        <div style={{maxWidth:'1200px', margin : 'auto'}}  className="row justify-content-center">
                            <div className={props.isMobile ? "d-none" : "col-md-12 d-flex  justify-content-center mt-4 pt-2 px-0"}>
                                <h1 className="title-liste-student" style={{color: '#272727'}}>{props?.title} <span style={{ marginLeft: '10px' }}></span></h1>
                            </div>
                            <div className="col-md-12 mb-5 d-flex justify-content-center text-left mt-4 px-0" style={props.isMobile && {width:'100%' }}>
                                <button style={props.isMobile && { width:'40%', fontSize: "16px", height: "50px", whiteSpace: "nowrap" }} className={(actualTab === 'discussion') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} >{t('discussionButton')}</button>
                                <button style={props.isMobile && { width:'60%', fontSize: "16px", height: "50px", whiteSpace: "nowrap" }} className={(actualTab === 'question') ? "btn-Tabs me-2" : "btn-Tabs unselected me-2"} > {t('questionButton')}</button>
                            </div>
                        </div>
                    </div>
                    <div style={{maxWidth:'1200px', margin : 'auto'}} className="col-md-12">
                        <div className="row justify-content-between">
                        <FilterCommunityLoadingComponent/>
                        </div>
                    </div>
                </div> 
        </div>
    </>
)
}
const mapStateToProps=(state: AppState, ownProps:any)=>({
    courseInfo: state.courseReducer.courseInfo,
    tabChatManager: state.chatReducer.tabChatManager,
    connectedUser: state.authReducer.connectedUser,
    title: ownProps?.title,

});
export default connect(mapStateToProps, null)(ManageCommunityLoading);


