import { FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import SidebarComponent from './sidebarComponent';
import AccordionFAQ from './faqDataComponent';
import FooterComponent from "../../components/footer-component";
const ManageFaqComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & any> = (props) => {

    return (
        <>
            <div className="page-wrapper-height">
       <div className="container-fluid m-0 p-0 mx-auto">
       <div className="row justify-content-center">
       <div className="col-md-12" style={{ backgroundColor: '#ffffff' }}>
       <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                            <div className="col-md-12 mt-5" ></div>

                            <div className="py-4 w-100">
                                <div className="d-flex flex-row justify-content-between my-2">
                                    <div className="d-flex flex-row mx-auto ">
                                        <span className="H2-Headline me-3">FAQ</span>
                                        
                                    </div>


                                  
                                </div>
                            </div>




                                </div>
                                </div>
    
        <div className="row mx-auto mt-5" style={{width:1200}}>
            <div className="col-md-3">
                <SidebarComponent />
            </div>
            <div className="col-md-9">
            <div className="bg-accordion-border accordion-FAQ">
                <AccordionFAQ />
                <AccordionFAQ />
                <AccordionFAQ />
            </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        <FooterComponent/>
        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    profs: state.profReducer.profs,
    connectionUser: state.authReducer.connectedUser,
    historyPath: state.historyReducer.listHistoryBreadCrumb,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(ManageFaqComponent);