import { useState, ComponentProps, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { ModalIDS } from '../modal-ids';
import Axios from '../../../helpers/interceptors';
import { getStudentsOfProf } from '../../../global-state/actions/professor-actions';
const AntSwitch = withStyles(() => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 3,
        color: 'white',
        '&$checked': {
            transform: 'translateX(18px)',
            color: '#ffffff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5A5A5A',
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        boxShadow: '0px 4px 4px rgba(34, 34, 34, 0.25)',
    },
    track: {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: '#EBECEE',
    },
    checked: {},
}))(Switch);

const ValideInvitationModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const baseUrl = process.env.REACT_APP_BASE_URL1;
    const [invited, setInvited] = useState<any>([])
    let link = window.location.href

    useEffect(() => {
        const object: any = []
        props.inviteStudentsList.map((item: any) => {
            object.push({
                "firstName": Object.values(item)[0],
                "lastName": Object.values(item)[1],
                "email": (Object.values(item)[2] as any)?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, ""),
                "type": "NP"
            })
        })
        setInvited(object)
    }, [])


    const Inviter = () => {
        let auth = ('Bearer ' + localStorage.getItem("access_token")).replace(/\"/g, "")
        Axios.get(baseUrl + "users/" + props?.connectedUser?.id, {
            headers: {
                'Authorization': auth
            }
        })
            .then((result) => {
                Axios.post(baseUrl1 + "person/invitationStudentsWithLimitations", {
                    "invitation": {
                        "invitedBy": {
                            "id": result.data.id,
                            "firstName": result.data.firstName,
                            "lastName": result.data.lastName,
                            "gender": result.data.gender,
                            "type": "NP"
                        },
                        "invited": invited,
                        "relationType": "STUDENT",
                        "to": {
                            "id": props?.schoolInfo?.id,
                            "code": link.split("//")[1].split("/")[0] === "localhost:3000" || link.split("//")[1].split("/")[0] === "app" + process.env.REACT_APP_BASE_SUBDOMAIN ? "HSI" : link.split("//")[1].split(".")[0],
                            "name": props?.schoolInfo?.name,
                            "type": "LP",
                        }
                    }
                }).then((res) => {
                    if (res && res.data) {
                        Axios.post(baseUrl1 + "person/attachMutiple", res.data).then((ress) => {
                            const newDate = new Date()
                            const date = newDate.getDate();
                            const month = newDate.getMonth() + 1;
                            const year = newDate.getFullYear();

                            if (props?.connectedUser?.role === 'role_prof') {
                                let students: any = []
                                res?.data.forEach((student: any) => {
                                    students.push({
                                        firstName: student?.invited?.firstName,
                                        lastName: student?.invited?.lastName,
                                        inscriptionDate: `${date + "/" + month + "/" + year}`,
                                        lastConnexion: null,
                                        studentStatus: "Enattente"
                                    })
                                })
                                props?.setGeneral({
                                    length: props?.general?.length + 1,
                                    list: [...students, ...props?.general?.list]
                                })

                            } else {
                                let students: any = []

                                res?.data.forEach((student: any) => {
                                    students.push({
                                        fullName: `${student?.invited?.firstName + " " + student?.invited?.lastName}`,
                                        inscriptionDate: `${date + "/" + month + "/" + year}`,
                                        lastConnectionDate: "-",
                                        studentEmail: student?.invited?.email?.toLowerCase(),
                                        studentId: student?.invited?.id,
                                        studentStatus: "Enattente"
                                    })
                                })

                                props.setStudentsList([...students, ...props.studentsList])
                            }

                            props.setModalSuccessOpened(false)

                            props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToStudentList'), lines: [props?.content?.data?.items?.length + t('invitationsSentSuccesfully')], data: { items: props?.content?.data?.items } });
                        })
                    }
                    if (res === undefined || res.data.length === 0 || res.data === undefined) {
                        props.setModalSuccessOpened(false)
                        props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToStudentList'), lines: [props?.content?.data?.items?.length + t('invitationsSentSuccesfully')], data: { items: props?.content?.data?.items } });
                    }
                })
            })
    }
    return (
        <>
            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.setModalSuccessOpened(false)}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="body-modal-publish">
                    <div className="mx-5 px-5 center" style={{ margin: 'auto' }}>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-row align-items-center mb-4'>
                                <span className="H3-Headline align-items-center mt-2">{t('automaticSendOfInvitations')}</span>
                            </div>
                        </div>
                        <div >
                            <span className="body-sm">{t('invitationSendConfirmation')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ paddingTop: 0, border: "none" }}>
                <div className=" d-flex mt-3 mb-5 mx-5 px-5">
                    <div className='col-md-12 text-center justify-content-center buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large me-1" onClick={() => { props?.setModalSuccessOpened(false) }} style={{ textTransform: 'uppercase', marginRight: '2%' }}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large " onClick={() => { Inviter() }} >{t('confirmAndSend')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    student: state.detailsProfileReducer.actualStudent,
    connectedUser: state.authReducer.connectedUser,
    content: state.modalReducer.modalContent,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { closeModal, openModal, getStudentsOfProf },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ValideInvitationModalComponent);