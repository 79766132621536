import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

function LoadingPassExam() {
  return (
    <div style={{display:"flex",margin:"auto" , flexDirection:"column" , alignItems:"center"}}>
         <div className="bg-with-border grey-backgounrd p-4" style={{display:"flex", flexDirection:"column"}}>
         <div className="d-flex flex-row justify-content-between mx-2">
                <div className=" d-flex align-items-center justify-content-start me-4">
                    <Skeleton variant='rect' height={48} width={48} /> 
                </div>
                <div className="d-flex flex-column" >
                    <div className=" d-flex flex-row justify-content-center">
                        <Skeleton variant='rect' height={24} width={144} />
                    </div>
                    <div className=" d-flex flex-row justify-content-center" style={{paddingTop:"14px"}}>
                        <Skeleton variant='rect' height={24} width={990} /> 
                    </div>
                </div>
                <div className=" d-flex align-items-center justify-content-end ms-4">
                <Skeleton variant='rect' height={48} width={48} />
                </div>
            </div>
         </div>
         <div style={{padding:"24px"}}> 
            <Skeleton variant='rect' height={24} width={167} /> 
         </div>
         <div>
            <Skeleton variant='rect' height={352} width={1200} /> 
         </div>
         <div style={{padding:"32px"}}>
            <Skeleton variant='rect' height={80} width={1200} /> 
         </div>
    </div>
  )
}

export default LoadingPassExam