import { FC, useEffect, useState, ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import HeaderTrainingFormComponent from '../../../components/formations-card/header-formation-form';
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import axios from '../../../helpers/interceptors';
import { getChapter, getContent, getExam, setTypeFormContentCourse, updateTraining } from '../../../global-state/actions/course-actions';
import ContentCourseCardPreviewComponent from "../../../components/formation-path-form/content-course-card-preview-component";
import FinalExamPreviewDirector from "../../../components/preview-course-components/chapter-exam/final-exam-preview-director";
import { getTrainingById } from "../../../global-state/actions/training-actions";

interface Params {
  type: string;
  id: string;
}
const ContentPathPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  const { type, id } = useParams<Params>();
  const { t } = useTranslation();
  const [pathInfo, setPathInfo] = useState<any>();
  const [listTest, setListTest] = useState<any>();
  const [sign, setSign] = useState<string>("director");
  const [allListe, setAllListe] = useState<any>([]);
  const history = useHistory();
  const [checkDiplome, setCheckDiplome] = useState<boolean>(props?.training?.isDiplomat)
  useEffect(() => {
    setPathInfo(props?.path);
  }, [props?.path]);

  useEffect(() => {
    if(checkDiplome===true){
      handleDiploma()
    }else{
      handleNotDiploma()
    }
  }, [checkDiplome])
  


  const verif = async () => {
    let checked = await axios.get(`${process.env.REACT_APP_BASE_URL}diploma/course/` + pathInfo?.id).then(res => { return res.data })
    if (checked.signingBy != 0) {
      if (checked.signingBy === props?.path?.idProf) {
        setSign("professor")
      }
      else {
        setSign("director")
      }
    }



  }

  useEffect(() => {
    if (pathInfo != undefined && pathInfo?.id != null && pathInfo === props?.path) {
      verif()

    }
  }, [pathInfo]);








  const check = async (event: any) => {
    let checked = await axios.get(`${process.env.REACT_APP_BASE_URL}diploma/course/` + pathInfo.id).then(res => { return res.data })
    if (checked.signingBy != 0) {
      setSign(event.target.value)
      let diploma = {
        training: { id: pathInfo.id, type: 'PATH' },
        signingBy: props?.connectedUser?.id,
        id: checked.id
      }
      if (event.target.value === "professor") {
        diploma.signingBy = pathInfo.idProf
      }

      axios.post(`${process.env.REACT_APP_BASE_URL}diploma`, diploma)
    } else {
      setSign(event.target.value)
      let diploma = {
        training: { id: pathInfo.id, type: 'PATH' },
        signingBy: props?.connectedUser?.id,

      }
      if (event.target.value === "professor") {
        diploma.signingBy = pathInfo.idProf
      }

      await axios.post(`${process.env.REACT_APP_BASE_URL}diploma`, diploma)

    }
  }

  const handleNotDiploma = async () => {
    setCheckDiplome(false);
    let filter = {
      id: props?.training?.id,
      isDiplomat: false
    }
    if(filter?.id!=null && filter?.id!==undefined){
    await props?.updateTraining(filter)
    }
    await axios.delete(`${process.env.REACT_APP_BASE_URL}diploma/delete/` + props?.training?.id).then(res => { return res.data })
    refreshData()

  }
  const handleDiploma = async () => {
    setCheckDiplome(true);
    let filter = {
      id: props?.training?.id,
      isDiplomat: true
    }
    if(filter?.id!=null && filter?.id!==undefined){
      await props?.updateTraining(filter)
    }
    

    let diploma = {
      training: { id: pathInfo.id, type: 'PATH' },
      signingBy: pathInfo.idProf,

    }

    let checked = await axios.get(`${process.env.REACT_APP_BASE_URL}diploma/course/` + pathInfo.id).then(res => { return res.data })
    
    if (checked == null || checked == undefined) {

      await axios.post(`${process.env.REACT_APP_BASE_URL}diploma`, diploma)
    }
    refreshData()

  }

  const refreshData = async () => {
    props?.getTrainingById('paths', id);
    let filtre = {
        trainingId: props?.training?.id,
        trainingType: "PATH",
        entityType: "SCHOOL",
        entityId: props?.connectedUser?.idSchool
    }
    props?.getContent(filtre);

  }




  return (<>
    <div style={{ backgroundColor: '#FFFFFF' }} className="w-100 px-2">
      <HeaderTrainingFormComponent type={"PATH"} title={t('pathContentTitle')} status={pathInfo?.status} trainingTitle={pathInfo?.title} />
    </div>
    <div className="px-2 pt-5" style={{ maxWidth: 1200, margin: 'auto' }}>
      <div 
                style={{
                  pointerEvents: (props?.path?.status ==="PUBLISHED") ? 'none' : 'auto',
                  opacity: (props?.path?.status ==="PUBLISHED" || props?.path?.status ==="DESACTIVATED") ? '0.3' : '1'
              }}
      className="d-flex flex-row align-items-center justify-content-start flex-wrap w-100 mt-2 mb-4">

        <span className="body-md bold me-2"> Parcours</span>
        <input dir="ltr" className="input-style-radio-button" type="radio"  name="exampleRadios3" id="anyLevel" value="option1" checked={!checkDiplome} onClick={() => { handleNotDiploma() }} />
        <label style={{ cursor: 'pointer', width: 189, height: 48, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='me-4' htmlFor="anyLevel">Non diplômant</label>
        <input dir="ltr" className="input-style-radio-button" type="radio"  name="exampleRadios3" id="beginner" value="option2" checked={checkDiplome} onClick={() => { handleDiploma() }} />
        <label style={{ cursor: 'pointer', width: 169, height: 48, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='me-4' htmlFor="beginner">diplômant</label>

      </div>

      {
        checkDiplome && <div className="d-flex align-items-center ms-4 px-4" >

          <div className="d-flex align-items-top me-4 ms-4">
            <input dir="ltr" className="input-style-radio ms-3"  readOnly type="radio" value={"professor"} name="professor" id="professor" onClick={(e) => check(e)} checked={sign === 'professor'} />
            <label htmlFor="professor"></label>
            <span onClick={() => check({ target: { value: 'professor' } })} style={{ cursor: 'pointer' }} className=" body-md black-800 ps-1 ms-2"> {t('signatureProf')}</span>
          </div>
          <div className="d-flex align-items-top">
            <input dir="ltr" className="input-style-radio" readOnly  type="radio" name="director" id="director" value="director" onClick={(e) => check(e)} checked={sign === 'director'} />
            <label htmlFor="director"></label>
            <span onClick={() => check({ target: { value: 'director' } })} style={{ cursor: 'pointer' }} className=" body-md black-800 ps-1 ms-2"> {t('signatureDirector')}</span>
          </div>
        </div>
      }

      <div className='mt-4 pt-3 mb-4'>
        {pathInfo?.subjectsByPath.map((obj: any, index: number) => {
          return <div key={index}>
            <ContentCourseCardPreviewComponent type={'path'} content={(obj?.course) ? obj?.course : obj?.path} index={index + 1} />
          </div>
        })}

        {props?.training?.exams[0] && <div style={{ paddingTop: "16px" }}>
          <FinalExamPreviewDirector type={'path'} id={pathInfo?.id} index={pathInfo?.subjectsByPath?.length + 1} title={props?.training?.exams[0]?.title} duration={props?.training?.exams[0]?.duration} />
        </ div>}
      </div>
    </div>
  </>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getChapter,
  getExam,
  getTrainingById,
  setTypeFormContentCourse,
  getContent,
  updateTraining
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  loggedIn: state.authReducer.loggedIn,
  path: state.trainingReducer.path,
  listeHistory: state.historyReducer.listHistoryBreadCrumb,
  history: ownProps?.history,
  listTest: state.courseReducer.listTest,
  training: state.courseReducer.training,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentPathPage);