import {ComponentProps, FC, useEffect} from "react";
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import SubtitleUploadedLoading from "./subtitle-uploaded-loading-component";
import InputUploadSubtitle from "./input-upload-subtitle-component";
import { 
    getChapterSubtitleVimeoUploaded,
} from '../../global-state/actions/upload-video-chapter-actions'
import { getSessionSubtitleVimeoUploaded } from "../../global-state/actions/training-actions";
import { displaySubtitleUploaded } from "../../helpers/upload-subtitle/displaySubtitleUploaded";

const ContainerUploadSubtitle: 
    FC<ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> 
    & ComponentProps<any>> = ({
        getChapterSubtitleUploadedLoading,
        getChapterSubtitleUploaded,
        idVideo,
        getSubtitleUploaded,
        getSessionSubtitleUploaded,
        getPathSubtitleUploaded,
        getCourseSubtitleUploaded,
        type
    }) => {

        return (
            <>
                <div className="H3-Headline my-32">Sous-titrage de la vidéo du chapitre</div>

                {/* START DISPLAY LIST OF SUBTITLE UPLOADED */}
                { displaySubtitleUploaded[type](
                    getChapterSubtitleUploaded, 
                    getSessionSubtitleUploaded,
                    getPathSubtitleUploaded,
                    getCourseSubtitleUploaded,
                    idVideo,
                    getSubtitleUploaded
                  ) }
                { getChapterSubtitleUploadedLoading && <SubtitleUploadedLoading /> }
                {/* END DISPLAY LIST OF SUBTITLE UPLOADED */}
                
                {/* START DISPLAY INPUT TO UPLOAD SUBTITLE */}
                <InputUploadSubtitle 
                    idVideo={idVideo} 
                    getSubtitleUploaded={getSubtitleUploaded} 
                    type={type}
                />
                {/* END DISPLAY INPUT TO UPLOAD SUBTITLE */}
            </>
        )
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getChapterSubtitleVimeoUploaded,
            getSessionSubtitleVimeoUploaded
        },
        dispatch
    );

const mapStateToProps = (state: AppState, ownProps: any) => ({
    getChapterSubtitleUploadedLoading: state.uploadChapterReducer.getAllSubtitleUploadedLoading,
    getChapterSubtitleUploaded: state.uploadChapterReducer.getChapterSubtitleUploaded,
    session: state.trainingReducer.session,
    getSessionSubtitleUploaded: state.trainingReducer.getSessionSubtitleUploaded,
    getPathSubtitleUploaded: state.trainingReducer.getPathSubtitleUploaded,
    getCourseSubtitleUploaded: state.courseReducer.getCourseSubtitleUploaded
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerUploadSubtitle);
