import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { ModalIDS } from '../../components/modals/modal-ids';
import {sendMyRequest} from '../../global-state/actions/course-actions';
import {openModal} from '../../global-state/actions/modal-actions';
import { useParams } from "react-router";
import { refreshToken } from "../../global-state/actions/auth-actions";
import { getDomainFromUrl } from "../../helpers/domainCheck";

interface Params {
    type: string;
    id:string;
}
const HeaderAdmissionFormPageComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [answers, setAnswers] = useState<any>()
    const [documents, setDocuments] = useState<any>()
    const {type} = useParams<Params>();
    const idTraining= useMemo(()=>{return (type === 'course')?props?.courseInfo?.id:props?.training?.id;},[])
    
    const follow = useMemo(()=>{
       if(props?.follow!==undefined && Object.keys(props?.follow).length > 0 ) {
        let actualFollow:any
        let followList=[...props?.follow];
    
        switch(type){
            case 'course':
                actualFollow= followList?.find((obj:any)=>{ return obj?.pathParentId===null && obj?.courseId===Number(idTraining) && obj?.pathChildId===null && obj?.sessionId===null})
               return actualFollow;
               
            case 'path':
                actualFollow= followList?.find((obj:any)=>{ return obj?.pathParentId===Number(idTraining) && obj?.courseId===null && obj?.pathChildId===null && obj?.sessionId===null})
                return actualFollow;
                
            case 'session':
                actualFollow= followList?.find((obj:any)=>{ return obj?.pathParentId===null && obj?.courseId===null && obj?.pathChildId===null && obj?.sessionId===Number(idTraining)})
                return actualFollow;
               

        }}

    },[props?.follow])
    useEffect(() => {
        setAnswers((props?.applicationFile && props?.applicationFile?.answers) ? props?.applicationFile?.answers : _.times(props?.registration?.questions?.length, _.constant({ 'id': null, 'index': props?.registration?.questions?.length + 1, 'answer': '' })))
        setDocuments((props?.applicationFile && (props?.applicationFile?.documents && props?.applicationFile?.documents.length > 0)) ? props?.applicationFile?.documents : _.times(props?.registration?.documents?.length, _.constant({ 'id': null, 'index': props?.registration?.documents?.length + 1, 'link': '', 'name': '' })))
    }, [props?.applicationFile])

    const envoyerMaDemande = async () => {
        let code =  getDomainFromUrl()
        let value="CANDIDATE"       
        let obj={
        comment:t('nothingTOSay'),
        idStudent:props?.connectedUser?.idPerson,
        idRegistration:props?.registration?.id,
        status:value ,
        code:code,
        idFollow:follow?.id,
        title:(type === 'course')?props?.courseInfo?.title:props?.training?.title,
        idSchool:props?.connectedUser?.idSchool,
        trainingType:(type === 'course')?props?.courseInfo?.type:props?.training?.type,

      }
      
        let status={
          status: value,
          follow:{id:follow?.id}
      };

      await props?.sendMyRequest(status,obj,props?.connectedUser?.idPerson,(type === 'course')?props?.courseInfo?.idProf: props?.training?.idProf);
      await props?.refreshToken(props?.connectedUser?.idSchool,props?.connectedUser?.idPerson);
      await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('backToMyrequest'), path:`/courses/registration/${follow?.id}/${props?.connectedUser?.idPerson}/${props?.registration?.id}`,lines: [t('messageEnvoyerDemande_1'),t('messageEnvoyerDemande_2'),t('messageEnvoyerDemande_3')]});
    }
    return (
        <>
            <div className="px-3 pt-3">
                <div className="col-md-12 pb-5 px-0 mx-auto pt-5" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-between">
                        <div className=" d-flex align-items-center justify-content-between flex-wrap mt-5">
                            <div className="col-md-9">
                                <h1 style={{ textAlign: 'left' }} className="H2-Headline ">{t('demandeDinscription')}</h1>
                                <p className="H3-Headline neutral-3"><span>{(type === 'course')?props?.courseInfo?.title:props?.training?.title}</span></p>
                            </div>
                            <div className="col-md-3">
                                {(follow && follow?.statusList?.find((status: any) => new Date(status?.creationDate).getTime() === Math.max(...follow?.statusList?.map((e: any) => new Date(e.creationDate))))?.status === "CANDIDATE") ?
                                    <div className="badge-withicon progres">
                                        <span className="material-icons-outlined">free_breakfast</span>
                                        <span className="ms-2">{t('waiting')} </span>
                                    </div> :
                                    <button className="btn-Primary large" disabled={(answers?.map((obj: any) => { return obj.answer; }).includes('') || documents?.map((obj: any) => { return obj.name; }).includes(''))} onClick={() => { envoyerMaDemande() }}>{t('envoyerDemande')}</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courseInfo: state.courseReducer.courseInfo,
    training: state.courseReducer.training,
    connectedUser: state.authReducer.connectedUser,
    follow: state.courseReducer.follow,
    registration: state.courseReducer.registration,
    applicationFile: state.courseReducer.applicationFile,


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    refreshToken,  sendMyRequest, openModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmissionFormPageComponent);