import Skeleton from '@material-ui/lab/Skeleton';

function UpdatePasswordLoadingComponent() {
    return (
        <div className="" style={{ width:'100%', maxWidth: 1200-420, margin: 'auto' }} >
            <div className="row" style={{ display: "flex", justifyContent: "space-between" }} >
                <div className="col-md-4"></div>
                <div className="col-md-8" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'170px'} />
                    <Skeleton className="skeleton subtext mb-2" animation="wave" variant="text" width={'220px'} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8">
                    <hr className="mb-5" style={{ border: "1px solid #F2F2F5" }} />
                </div>
            </div>
        </div>
    )
}

export default UpdatePasswordLoadingComponent



