import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import { getProfessor, } from '../../../global-state/actions/professor-actions';
import { useHistory } from 'react-router-dom';
import { clearFormCourse, createCourse } from '../../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { clearMyTrainings, clearTraining, createTraining } from '../../../global-state/actions/training-actions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
const ProfessorChoiceModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

    const { t } = useTranslation();
    const [teacherToAffect, setTeacherToAffect] = useState<any>()
    const [idSchool, setIdSchool] = useState<number>(props?.connectedUser?.idSchool)
    const [profList, setProfList] = useState<any>([])
    const [type, setType] = useState(props?.content?.data?.type)
    const history = useHistory();



    useEffect(() => {
        setIdSchool(props?.connectedUser?.idSchool)
        setProfList(props?.profs)
        setType(props?.content?.data?.type)

    }, [])
    useEffect(() => {
        if (idSchool && idSchool != undefined) {
            getData()
        }
    }, [idSchool])

    const getData = async () => {
        await props?.getProfessor(props?.connectedUser?.idSchool);
        await setProfList(props?.profs)
    }

    useEffect(() => {
        if (props?.profs.length != profList) {
            setProfList(props?.profs)
        }

    }, [props?.profs])



    const createCourseFn = async () => {
        await props?.clearFormCourse();
        const config = {
            id: null,
            objectives: '',
            resume: '',
            prerequisite: '',
            skills: [],
            idRegistrationForm: null,
            status: 'DRAFT',
            level: "ANYLEVEL",
            creationDate: Date.now().toString(),
            idProf: teacherToAffect,
            creator: teacherToAffect,
            entityId: props?.director?.idSchool,
            entityType: 'SCHOOL',
            isDiplomat :false,
            linkImg: '',
            video: ''
        }
        const object = {
            title: 'Titre du cours',
            type: 'COURSE',
            ...config
        }

        await props?.createCourse(object, props?.director);
        await props?.clearMyTrainings()
        props?.redirect(t('createCourse'), `${returnStartPathUrl()}/courses/create-formation/course/description`)
     history.push(`${returnStartPathUrl()}/courses/create-formation/course/description`);
    }
    const addTraining = async (type: string) => {
        const config = {
            id: null,
            objectives: '',
            resume: '',
            prerequisite: '',
            skills: [],
            idRegistrationForm: null,
            status: 'DRAFT',
            level: "ANYLEVEL",
            creationDate: Date.now().toString(),
            idProf: teacherToAffect,
            creator: teacherToAffect,
            entityId: props?.director?.idSchool,
            entityType: 'SCHOOL',
            linkImg: '',
            video: ''
        }
        await props?.clearTraining(type)
        switch (type) {
            case "PATH": await props?.createTraining("paths", { ...props?.path, ...config, subjectsByPath: [], title: t('InitialPathName'), type });  history.push(`${returnStartPathUrl()}/courses/create-formation/path/description`); break;
            case "COURSE": await props?.createTraining("course", { ...props?.course, ...config, type });  history.push(`${returnStartPathUrl()}/courses/create-formation/course/description`); break;
            case "SESSION": await props?.createTraining("sessions", { ...props?.session, ...config, subjectsBySession: [], title: t('SessionTitleCreation'), type });  history.push(`${returnStartPathUrl()}/courses/create-formation/session/description`); break;
        }
    }



    const goToCreate = async () => {


        await props?.closeModal();


        if (type === "COURSE") {
            await createCourseFn()
        } else {
            await addTraining(type)
        }


    }


    return (
        <>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-end p-3">
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className="modal-body mb-3" style={{ paddingTop: 0, overflow:'hidden' }}>
                <div className="row justify-content-center">
                    <div className="col-md-10" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12">
                                {type === "COURSE" && <h3 className="H3-Headline">{t('newCourse')}</h3>}
                                {type === "PATH" && <h3 className="H3-Headline">{t('newPath')}</h3>}
                                {type === "SESSION" && <h3 className="H3-Headline">{t('newSession')}</h3>}
                                <label className="H4-Headline body-sm black-800">{t('profMandadotory')}</label>
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="form-title">{t('profMandadtory2')}</label>
                                <div className="select-input">
                                    <select id="choiceProfessor" className="form-control input text-default" onChange={(e) => { setTeacherToAffect(e.target.value) }} >
                                        <option className="option-placeholder" value="" selected disabled hidden>{t('affectOtherPRof')}</option>
                                        {props?.profs?.filter((obj: any) => obj.status == "Admis").map((profObject: any , index:number) => {

                                            return <option key={profObject.id} className="option-choice" value={profObject.id}>{profObject.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex w-100 mt-5 mb-4 justify-content-end">
                                <button className="btn-Secondary large me-3" onClick={() => props?.closeModal()} >
                                    {t('annuler')}
                                </button>
                                
                                {type === "COURSE" && <button className={`btn-Primary large `} onClick={goToCreate} >
                                    {t('createCourse')}
                                </button>}
                                {type === "PATH" && <button className={`btn-Primary large `} onClick={goToCreate} >
                                    {t('createPath')}
                                </button>}
                                {type === "SESSION" && <button className={`btn-Primary large `} onClick={goToCreate} >
                                    {t('createSession')}
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    content: state.modalReducer.modalContent,

    director: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    history: ownProps?.history,
    loadingAllProfessors: state.profReducer.loadingAllProfessors,
    profs: state.profReducer.profs,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            getProfessor,
            clearFormCourse,
            createCourse,
            clearTraining,
            createTraining,
            clearMyTrainings,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfessorChoiceModalComponent);