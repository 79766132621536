import Skeleton from '@material-ui/lab/Skeleton/Skeleton'

function LoadingHeaderPathSessionComponent() {
  return (
    <div style={{ maxWidth: "1200px", margin: "auto" }}>
            <div className="mx-2">
            <Skeleton className="skeleton square" animation="wave" variant="rect" width={1000} height={48} />
                <div className="d-flex flex-row align-items-center" style={{ height: 24 , paddingTop:"16px" }}>
                <Skeleton className="skeleton square" animation="wave" variant="rect" width={248} height={20} />
                </div>
            </div>
        </div>
  )
}

export default LoadingHeaderPathSessionComponent