
import { ComponentProps, FC, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { UpdateSchool } from '../../global-state/actions/auth-actions';
import { closeModal } from '../../global-state/actions/modal-actions';
import { AppState } from '../../global-state/store/root-reducers';
import axiosInstance from '../../helpers/interceptors';
import CropImageSchool from './crop-image-school';
import "../../translations/i18n";
import { useTranslation } from "react-i18next";

const InputUploadSchoolLogo: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [profile] = useState(props?.connectedSchool)
    const [photo, setPhoto] = useState<any>(props?.photo);
    const { t } = useTranslation();
    useEffect(() => {
        setPhoto(props?.photo)
    }, [props?.photo])

    useEffect(() => {
        setPhoto(props?.connectedSchool.centralLogo)

    }, [props?.connectedSchool.centralLogo])

    const onGetBlobFil = async (blobFile: any) => {
        const baseUrlGed = process.env.REACT_APP_BASE_URL4;
        if (blobFile === null)
            setPhoto(undefined)
        else {
            const form = new FormData();
            form.append('file', blobFile[0]);
            await axiosInstance.post(baseUrlGed + "upload/image",
                form
            ).then((response) => {
                const copyProfile = { ...profile };
                copyProfile["id"] = props?.schoolInfo.id;
                copyProfile["enabled"] = props?.schoolInfo?.enabled;
                copyProfile["coverPhoto"] = props?.schoolInfo?.coverPhoto;
                copyProfile['domain']=props?.schoolInfo?.domain;
                copyProfile.centralLogo = response?.data?.link;
                props?.UpdateSchool(copyProfile);
            })
        }
    }

    const onDrop = async (accepted: any, rejected: any) => {
        if (Object.keys(rejected).length === 0) {
            await onGetBlobFil(accepted);
            handleChange(accepted);
        }
    };

    const handleChange = (selectorFiles: FileList) => {
        let fileReader = new FileReader();
        let data;

        fileReader.onload = () => {
            data = fileReader.result;
            setPhoto(data)
        };
        fileReader.readAsDataURL(selectorFiles[0]);
    }

    const addFile = async (file: any) => {
        const form = new FormData();
        form.append('file', file[0]);
        const copyProfile = { ...profile };

        await axiosInstance.post(process.env.REACT_APP_BASE_URL4 + "upload/image",
            form
        ).then(async (response) => {
            await setPhoto(response.data.link)
            copyProfile["id"] = props?.schoolInfo.id;
            copyProfile["enabled"] = props?.schoolInfo?.enabled;
            copyProfile["coverPhoto"] = props?.schoolInfo?.coverPhoto
            copyProfile['domain']=props?.schoolInfo?.domain;
            copyProfile.centralLogo = response.data.link;
            props?.UpdateSchool(copyProfile).then(() => {
                setTimeout(() => { 
                    props?.closeModal();
                }, 300)
            });

        })
    };

    async function deleteFile (){
        const copyProfile = { ...profile };
        copyProfile.centralLogo = null;
        copyProfile["id"] = props?.schoolInfo.id;
        copyProfile["enabled"] = props?.schoolInfo?.enabled;
        copyProfile["logo"] = null;
        copyProfile['domain']=props?.schoolInfo?.domain;
        await props?.UpdateSchool(copyProfile);
    }

    return (
        <div>
            {photo === null ? (
                <>
                    <Dropzone
                        style={
                            { width: '100%', zIndex: 1, position: "relative" }
                        }
                        multiple={false}
                        accept="image/*"
                        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                    >
                        <div className="mx-auto" style={{ maxWidth: '240px' }}>
                            <div className="bg-with-border dashed d-flex align-items-center justify-content-center flex-column py-5" style={{ height: '240px' }}>
                                <span className="material-icons-outlined" style={{ color: "#DADBDF" }}>cloud_upload</span>
                                <p className="body-md bold neutral-3 mt-3"> {t('dragToUpload')}</p>

                            </div>
                            <div className=" justify-content-center align-items-center d-flex" style={{ paddingTop: "54px", paddingBottom: "40px" }}>
                                <button className="btn-Primary large" >{t('seletionner')}</button>
                            </div>
                        </div>
                    </Dropzone>

                </>
            ) : (<>
                <CropImageSchool t={t} width={240} file={photo+"?cacheblock=true"} deleteFile={deleteFile} addFile={(image: any) => addFile(image)} closeModal={props?.closeModal} />

            </>
            )}
        </div >
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    userProfileDetails: state.authReducer.connectedUserInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedSchool: state.authReducer.connectedSchoolInfo,
    updatedSchool: ownProps?.updatedSchool
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    UpdateSchool,
    closeModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InputUploadSchoolLogo);