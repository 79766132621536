import { ComponentProps, FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../global-state/actions/modal-actions';
import TextareaUnderlineElement from '../../../elements/text-area/text-area-underline-element';
import { ModalIDS } from '../modal-ids';
import { clearTraining, createTraining, publishTraining } from '../../../global-state/actions/training-actions';
import { getDomainFromUrl } from '../../../helpers/domainCheck';
const PublishTrainingModalComponent: FC<ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [training, setTraining] = useState<any>();
    const [comment, setComment] = useState<string>("");
    const [idProf, setIdProf] = useState('');
    useEffect(() => {
        if (location.pathname.includes('/course/')) {
            setTraining(props?.course)
        }
        else if (location.pathname.includes('/path/')) {
            setTraining(props?.path)
        }
        else if (location.pathname.includes('/session/')) {
            setTraining(props?.session)
        }


    }, [props]);
    const inputChange = (value: string) => {
        setComment(value);
    }
    const sendPublish = async () => {
        let code = getDomainFromUrl()
        let obj = {
            comment: comment,
            idCours: training?.id,
            status: "",
            title: training?.title,
            code: code,
            userRole: props?.connectedUser?.role,
            idSchool: props?.connectedUser?.idSchool,
            trainingType: training?.type,
        }
        switch (training.type) {
            case "COURSE": await props?.createTraining("courses", { ...training, status: "PENDING" }); break;
            case "PATH": await props?.createTraining("paths", { ...training, status: "PENDING" }); break;
            case "SESSION": await props?.createTraining("sessions", { ...training, status: "PENDING" }); break;
        }
        await props?.publishTraining("PENDING", props?.connectedUser?.role == "role_prof" ? props?.connectedUser?.idPerson : idProf, props?.connectedUser?.idSchool, obj)
        await props?.openModal({ idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", lines: [(training.type === "PATH") ? t('modalPublishPathSuccess') : t('modalPublishSessionSuccess')], path: location.pathname, buttonText: (training.type === 'COURSE') ? t('returnToCourse') : (training.type === 'PATH') ? t('returnToPathButton') : t('returnToSessionButton') });

    }
    return (
        <>
            <div className="d-flex w-100 align-items-center justify-content-end pt-3 pe-3">
                <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
            <div className="modal-body" style={{ paddingTop: 0, marginLeft: '10px', overflow:'hidden' }}>
                <div className="row justify-content-center ">
                    <div className="col-md-10 text-left" >
                        <h3 className="H3-Headline black-800 " style={{ textAlign: 'left' }}>{t('sendPublishCoursePRofTitle')}</h3>
                        <h3 className="H4-Subtitle neutral-3 mb-4">{training?.title} </h3>

                        <p className="form-title mt-4 pt-2">{t('modalPublishCourse_2')}</p>
                        <TextareaUnderlineElement id={"commentPublishProf"} value={comment} onChange={(value: string) => inputChange(value)} placeholder={t('modalPublishCourse_3')} />

                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ border: 'none' }}>
                <div className="row">
                    <div className='d-flex mt-3 mb-5 me-5 pe-3'>
                        <button type="button" onClick={() => props?.closeModal()} className="btn-Secondary large me-3">{t('annuler')}</button>
                        <button type="button" onClick={() => sendPublish()}  className="btn-Primary large success">{t('send')}</button>
                    </div>
                </div>
            </div>
        </>

    )

}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        closeModal, openModal, clearTraining, createTraining, publishTraining
    }, dispatch);
const mapStateToProps = (state: AppState) => ({
    course: state.trainingReducer.course,
    path: state.trainingReducer.path,
    session: state.trainingReducer.session,
    connectedUser: state.authReducer.connectedUser,
    profs: state.profReducer.profs,
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishTrainingModalComponent);