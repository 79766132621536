import { ComponentProps, FC, useEffect, useState } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useTranslation } from 'react-i18next';
import zoomLogo from '../../styles/imgs/Zoom.png';
import { fetchData } from '../../base/functions/Functions';
import axiosInstance from '../../helpers/interceptors';


const AddAndUpdateZoomAccountCredentialComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
  
    const [url, setUrl] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [zoomAccountInfo, setZoomAccountInfo] = useState<any>()
    const [redirectUrl, setRedirectUrl] = useState<any>("");

    
    const findUrl = async () =>{
        let check= window.location.href.indexOf("/param")
         setRedirectUrl(window.location.href.substring(0,check+6))
        
         if (window.location.href != "http://localhost:3000/param"){
            setRedirectUrl(window.location.href.substring(0,check+6))
        }
    }

    const getZoomAccountDetails = async (id: Number) =>{
        await axiosInstance
       .get(process.env.REACT_APP_BASE_URL9 + `externalAccount/getAccountInfoForDirector/`+id )
       .then(res => {  setZoomAccountInfo(res.data) })
       .catch(err => console.error(err));
   }

    const disconnectFromZoom = async (id: Number) =>{
        let accountInfo= await fetchData("GET",process.env.REACT_APP_BASE_URL9 + `externalAccount/disconnect/`+id, {}).then(res => { return res }).catch(err => console.error(err));
        setZoomAccountInfo(accountInfo)
    }

    const addExternalAccount = async (account:any) =>{
        let accountInfo= await fetchData("POST",process.env.REACT_APP_BASE_URL9 + `externalAccount/createOrUpdateExternalAccount`, account).then(res => { return res }).catch(err => console.error(err));
        setZoomAccountInfo(accountInfo)
    }


    useEffect(() => {
        findUrl()
        if(url === ""){
            setUrl(window.location.href)
            findUrl()
        }
        if( props?.connectedUser.role==="role_director"){
            getZoomAccountDetails(props?.connectedUser?.idSchool)

        }else {
            getZoomAccountDetails(props?.connectedUser?.idPerson)

        }
    }, [])


    useEffect(() => {
      setUrl(window.location.href)
    }, [window.location.href])

    useEffect(() => {
        
        if(url.indexOf("code=")>1) {
            getCode(url)
        }
       
    }, [url])

    const getCode = async (urlToCheck :any)=>{
        let check= urlToCheck.indexOf("code=")
       await setCode(urlToCheck.substring(check+5))
       
       
       
    }
    
    const addAccount =async () => {
        let add ={
            "id":null,
             "entityId":props?.connectedUser?.role==="role_director" ?  props?.connectedUser?.idSchool : props?.connectedUser?.idPerson ,
             "entityType":props?.connectedUser?.role==="role_prof" ? "PERSONAL":"SCHOOL" ,
             "code":code,
             "redirectUri":redirectUrl
         }
         await addExternalAccount(add)
         await setCode("")
    }
    useEffect(() => {
      if(code.length>0 && code != "" && zoomAccountInfo===""){
        addAccount()
      }
    }, [code])

    
    const desactivateAccount  = async( )=>{
        await disconnectFromZoom(parseInt(zoomAccountInfo?.id))
        await getZoomAccountDetails(props?.connectedUser.idPerson)

    }
    
    

    return (
       
        <div className="d-flex  flex-column w-100" style={{ maxWidth: 1200, margin: 'auto' }} >
                <div style={{backgroundColor:"#FFFFFF" , padding:"24px 22px" , boxSizing:"border-box" , border:"1px solid #FFFFFF"}}>
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <div className=' d-flex align-item-center'>
                            <img width='32px' height="32px" src={zoomLogo} alt="zoom logo" />
                            <div className='d-flex flex-column' style={{marginLeft:"16px"}}>
                                <div className="mb-1">
                                    <span className="H3-Headline" >{t('account')} Zoom</span>
                                    {zoomAccountInfo?.accountStatus==="CONNECTED" && <span className='body-sm' style={{color:"#9DDBA9" , marginLeft:"8px"}}>{t('connect').toLowerCase()}</span>}
                                </div>
                                <div>
                                    {(zoomAccountInfo?.accountStatus === "DISCONNECTED" || zoomAccountInfo ==="") ? <div>
                                    <span className="H4-Subtitle neutral-3" >{t('connectAccountToschool')}</span>
                                        </div> :
                                        <span className="H4-Subtitle neutral-3" >Nom d'utilisateur: <span className="black-800">{props?.connectedUserInfo?.firstName + " " + props?.connectedUserInfo?.lastName }</span></span>
                                     }
                                </div>
                            </div>
                        </div>
                        <div>
                            {(zoomAccountInfo?.accountStatus === "CONNECTED") ?
                                <button type="button" className="btn-Secondary large icon-left" 
                                        onClick={()=> desactivateAccount()}
                                        style={{width:"160px", height:"36px" , display:"flex" , justifyContent:"center", alignItems:"center"}}
                                >
                                    <span className="material-icons-outlined">
                                    close
                                    </span>
                                <div className="m-auto ps-2" 
                                >{t('disconnect')}</div>
                                </button> 
                            :
                                <button type="button" className="btn-Primary large icon-left"
                                onClick={(e) => {
                                e.preventDefault();
                                window.location.href=process.env.REACT_APP_ZOOM_URL+redirectUrl;
                                }}
                                style={{width:"160px", height:"36px" , display:"flex" , justifyContent:"center", alignItems:"center"}}
                                >
                                    <span className="material-icons-outlined">
                                        sync_alt
                                    </span>
                                <div className="m-auto ps-2">{t('connect')}</div>
                                </button>
                            }
                        </div>
                    </div>
                    <div>
                        
                    </div>
                </div>
        </div>

    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
});

export default connect(mapStateToProps)(AddAndUpdateZoomAccountCredentialComponent);


