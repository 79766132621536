import { ComponentProps, FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal} from '../../../global-state/actions/modal-actions';

import { redirect } from '../../../global-state/actions/breadcrumb-actions';
import { useHistory } from 'react-router-dom';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import { createGroup } from '../../../services/groupe.student.service';


const AddNewGroupModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const[title, setTitle]=useState("");
    const [groupForm, setGroupForm] = useState(undefined);
    const history = useHistory();
    useEffect(()=>{
          if(groupForm) {
              history.push(`${returnStartPathUrl()}/students/group/${props?.groupForm?.id}`);
                props?.closeModal();
            }
    }, [groupForm])
    const addNewGroup =async () => {
        props.schoolInfo["type"] = "LP";
        let group={
            id: null,
            label:title,
            description: "",
            owner:props?.schoolInfo,
            personList:[]
        }
        await createGroup(group).then((response:any) =>{
            setGroupForm(response)
        })
        props?.redirect(t('students_2'),`${returnStartPathUrl()}/students/all`)
        props?.redirect(group.label, `${returnStartPathUrl()}/students/group/${props?.groupForm?.id}`)        
    }
    return (
        <>
        
        <div className="modal-header" style={{paddingBottom: 0 , borderBottom: "none"}}>
            <div className="w-100 d-flex align-items-center justify-content-end p-3">
                 <button className="btn-QuickActions" onClick={() => props?.closeModal()}><span className="material-icons">close</span></button>
            </div>
        </div>

            <div  className="modal-body " style={{ paddingTop: 0, zIndex:1000, overflow:'hidden' }}>
                <div className="row">
                    <div className="col-md-10 center" style={{ margin: 'auto' }}>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center mb-4">
                                <h3 className="H3-Headline">{t('newGroup')}</h3>
                            </div>
                            <div className="col-md-12 mb-2">
                                <p className="body-md bold">{t('groupTitle')}</p>
                            </div>
                            <div className="col-md-12  mb-4 ">
                                <input dir="ltr"type="text" className="form-control input text-default" value={title} onChange={(e) => {setTitle( e.target.value ) }} placeholder={t('groupTitle')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{paddingBottom: 0 , borderTop: "none"}}>
                <div className="row mt-3 mb-5">
                    <div className='col-md-9 d-flex  justify-content-center' >
                        <button className="btn-Secondary large me-3" onClick={() => {props?.closeModal(); setTitle("")}}>{t('annuler')}</button>
                        <button type="button" className="btn-Primary large" style={{ textTransform: 'uppercase', marginRight: '2%' }} onClick={() => { addNewGroup() }} disabled={title === ''}>{t('publishMyReview')}</button>
                    </div>
                </div>
            </div>
        
</>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo, 
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      redirect,
    },
    dispatch
  );
export default connect( mapStateToProps, mapDispatchToProps )(AddNewGroupModalComponent);