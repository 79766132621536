import { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { redirect, clearHistoryArray } from "../../global-state/actions/breadcrumb-actions";
import { getCourse, getCourseTests, getOtherCoursesForDirector, getRegistrationForm } from "../../global-state/actions/course-actions";
import { getTrainingById } from "../../global-state/actions/training-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from "../../global-state/store/root-reducers";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import TableWithExpandElementNotVisible from "../../elements/table/table-with-expand-element-not-visible";
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import DefaultTitle from '../../helpers/default-title'
import { returnStartPathUrl } from "../../helpers/domainCheck";

let outsideAtBottom = false;
const TableOtherComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [atBottom, setAtBottom] = useState<boolean>(false);
    outsideAtBottom = atBottom;
    const status = [
        { label: t('Aaccepter'), color: "warning" },

    ]
    const history = useHistory();
    const [courses, setCourses] = useState<any>();
    const [filteredCourses, setFilteredCourses] = useState<any>();
    const [loadingOther, setLoadingOther] = useState(true);
    const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
    const pageNumberOther = useMemo(() => props?.pageNumber ? props?.pageNumber : 0, [props?.pageNumber])
    const trainingTitle: any = {
        "COURSE": t('Cours'),
        "PATH": t('parcoursTitle'),
        "SESSION": t('sessionTitle')
    }
    const headers = [
        { title: t('TitleFormations'), sorted: true, classNames: "w-32", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Ecole'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("creationDate"), sorted: true, classNames: "w-25", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },


    ]

    const handleScroll = async () => {

        if (!(atBottom) && (window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight && props?.otherCourses?.length < props?.LengthCoursesOther && !loadingOther) {
            setAtBottom(true);

            let filterOther = {
                entityId: props?.user?.idSchool,
                entityType: 'SCHOOL',
                pageSize: 12,
                pageNumber: pageNumberOther + 1,
            }
            await props?.getOtherCoursesForDirector(filterOther, 'no')
            document.removeEventListener('scroll', handleScroll);
        }

        if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
            setAtBottom(false);
        }

    }

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    useEffect(() => {
        setLoadingOther(props?.loadingOtherCourses)
    }, [props?.loadingOtherCourses]);

    const doAction = async (value: any) => {
        if (value.action === 'goToPath') {
            if (value.row.course.type === 'COURSE') {
                await props?.getCourse(value.row.course.id);
                if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                    await props?.getRegistrationForm(value.row.course.idRegistration);
                }
                await props?.getCourseTests(value.row.course.id);
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`)
                history.push(`${returnStartPathUrl()}/all-courses/course/${value.row.course.id}/description`);
            } else if (value.row.course.type === 'PATH') {
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`)
                await props?.getTrainingById('paths', value.row.course.id);
                if (!!value.row.course.idRegistration && value.row.course.idRegistration !== 0) {
                    await props?.getRegistrationForm(value?.row?.course?.idRegistration);
                }
                history.push(`${returnStartPathUrl()}/all-courses/path/${value.row.course.id}/description`);
            } else {
                props?.clearHistoryArray()
                props?.redirect(value.row.course.title, `${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`)
                await props?.getTrainingById('sessions', value.row.course.id);
                history.push(`${returnStartPathUrl()}/all-courses/session/${value.row.course.id}/description`);
            }
        }
    }

    let getData = () => {
                const dataOther = {
            headers: headers,
            body: props?.otherCourses?.map((course: any) => {

                return {
                    row: {
                        course: course,
                        actionName: "",
                        classNames: "table-white",
                        columns: [
                            { content: <DefaultTitle title={course?.title} type={course?.type} />, classNames: "w-30", actionName: 'goToPath' },
                            { content: course?.origin?.name, classNames: "w-25", actionName: 'goToPath' },
                            { content: trainingTitle[course.type], classNames: "w-16", actionName: 'goToPath' },
                            { content: course?.creationDate?.substring(0, 10).split("-").reverse().join("/"), actionName: 'goToPath' },
                            {
                                content: <><span style={{width:105}}>
                                    {t(status[0]?.label)}
                                </span>
                                </>, classNames: ` d-flex flex-row text-end badge-text ${status[0]?.color}  `, actionName: 'goToPath'
                            },

                        ],

                    }
                }
            })
        }
        setCourses(dataOther);
        setFilteredCourses(dataOther);
    }

    useEffect(() => {
        getData();
    }, [props?.otherCourses]);
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">

                {(props?.LengthCoursesOther > 0) ?


                    <div className="col-md-12 mt-5">
                        <TableWithExpandElementNotVisible data={filteredCourses} doAction={(value: any) => { doAction(value); }} size={props?.LengthCoursesOther} />
                        {(props?.otherCourses?.length > 0 && loadingOther) &&
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>
                        }

                    </div>
                    : <>
                        <div className="w-100 mt-5 pt-5">
                            <EmptyListElement lines={[t('FormationEnLigne')]} />
                        </div>
                    </>
                }
            </div >
        </div>
    );



}

const mapStateToProps = (state: AppState) => ({
    otherCourses: state.courseReducer.otherCourses,
    user: state.authReducer.connectedUser,
    actualTabAllCourses: state.courseReducer.actualTabAllCourses,
    loadingOtherCourses: state.courseReducer.loadingOtherCourses,
    loadingOtherCoursesScroll: state.courseReducer.loadingOtherCoursesScroll,
    LengthCoursesOther: state.courseReducer.LengthCoursesOther,
    pageNumberOther: state.courseReducer.pageNumberOther
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        { redirect, clearHistoryArray, getOtherCoursesForDirector, getCourse, getCourseTests, getRegistrationForm, getTrainingById }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableOtherComponent);
