import { createContext, useEffect, useState } from 'react';
import { mySteps } from '../constants/mySteps';

export const UserGuideContext: any = createContext(null);

const initState = {
  run: false,
  stepIndex: 0,
  steps: mySteps,
  tourActive: false,
  isIncrement: 0,
  isOpenCloseUserInfo: true,
  startGuide: false,
  displayInitModal: true,
  endOfSteps: false,
  resumeGuide: false,
  isClickPosition: ''
}
export function UserGuideProvider({ children }: any) {
  const userGuideContext = localStorage.getItem('userGuideContext')
  const [userGuideState, setUserGuideState] = useState(userGuideContext ? JSON.parse(userGuideContext) : initState);

  useEffect(() => {
    localStorage.setItem('userGuideContext', JSON.stringify(userGuideState))
  }, [userGuideState])
  
  return (
    <UserGuideContext.Provider value={{ userGuideState, setUserGuideState }}>
      { children }
    </UserGuideContext.Provider>
  )
}