import { ComponentProps, FC, useEffect,useMemo,useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import HeaderRegistrationPreviewProfPageComponent from '../../components/headers-page/header-registration-preview-prof-page-component';
import AdmissionPreviewStudentComponent from '../../components/admission/admission-preview-student-component';
import HeaderRegistrationPreviewBlockStudentInfoComponent from '../../components/headers-page/header-registration-preview-block-student-info-component';
import HeaderRegistrationPreviewDirectorPageComponent from '../../components/headers-page/header-registration-preview-director-page-component';
import AdmissionDiscussionSidbarElement from '../../elements/sidebar/admission-discussion-sidbar-element';
import { getSubjectCommunity, updatelistDiscussion , createPost} from '../../global-state/actions/chat-actions';
import { connectSocketChat, initSocket } from '../../global-state/actions/socket-actions';
import { getFollow,getRegistrationForm ,getApplicationFile,getStudentInfoById, getOfferCourse, getTraining} from '../../global-state/actions/course-actions';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next/";
import { returnDynamicValueFromUrl, returnStartPathUrl } from "../../helpers/domainCheck";
interface Params {
    idFollow: string;
    idStudent: string;
    idRF: string;
}
const RegistrationPreviewFromListStudentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [showChat, setShowChat] = useState(false)
    const [iscalled, setIsCalled] = useState(false)
    const location = useLocation();
    const [listPosts, setListPosts] = useState<any>()
    const [page] = useState(0)

    const { idFollow, idStudent, idRF } = useParams<Params>();
    const follow= useMemo(()=>{return (props?.follow?.find((followObj:any)=>{ return followObj.id===Number(idFollow)}))},[])

    const getRegistrationFN= async ()=>{
        await props?.getStudentInfoById(returnDynamicValueFromUrl(4), props?.schoolInfo?.id);
        await props?.getApplicationFile(Number(idStudent),Number(idRF));
        await props?.getRegistrationForm(Number(idRF));
        await props?.getFollow(Number(idFollow));
        setIsCalled(true)
    }

    const setTrainingIdAndType = (attribute:any) => {
        let trainingId
        let trainingType
        if (attribute !== null) {
            trainingId = follow?.courseId
            trainingType = "COURSE"
        } else {
            if (attribute!==null){
                trainingId = follow?.sessionId
                trainingType = "SESSION"
            } else {
                trainingId = follow?.pathParentId
                trainingType = "PATH"

            }
        }
        return [trainingId,trainingType]
    }
    useEffect(()=>{
        if( props?.registration?.id === null || Number(returnDynamicValueFromUrl(5))!== props?.registration?.id){
            getRegistrationFN();
        }
    },[])
    useEffect(()=>{
        if(props?.actualStudent) {
         setBreadcrumbData();
        }
    },[iscalled, props?.actualStudent])
    const setBreadcrumbData = async () => {
         props?.clearHistoryArray()
         props?.redirect(t('Etudiant_1'), `${returnStartPathUrl()}/students/all`)
         props?.redirect(props?.actualStudent?.firstName + " " + props?.actualStudent?.lastName, `${returnStartPathUrl()}/students/profile/student/${props?.actualStudent?.id}`)
         props?.redirect(t('reponseInscrit'), `${returnStartPathUrl()}/students/registration/${Number(returnDynamicValueFromUrl(3))}/${props?.actualStudent?.id}/${Number(returnDynamicValueFromUrl(5))}`)
         history.push({ pathname: `${returnStartPathUrl()}/students/registration/${Number(returnDynamicValueFromUrl(3))}/${props?.actualStudent?.id}/${Number(returnDynamicValueFromUrl(5))}`})
    }
    useEffect(() => {
        if(props?.socket=== undefined) {
            props?.connectSocketChat()
        } else {
            if(!props?.socketInit) {
                props?.initSocket();
                props?.socket?.on("newMessageSended", (data: any) => {   
                    if(Number(data.idAuthor) !== props?.connectedUser?.idPerson && returnDynamicValueFromUrl(3)!==undefined) {
                        props?.updatelistDiscussion(page, data, props?.connectedUser?.idSchool, { idSubject: returnDynamicValueFromUrl(3), category: "CHAT", type: "ADMISSION" });
                    }
                });
            }
            
        }  
    }, [props?.socket])

    useEffect(() => {
        if (follow)
        {
            const [trainingId,trainingType] = setTrainingIdAndType(follow?.courseId)
            props?.getOfferCourse(Number(trainingId));
           if(props?.connectedUser?.role==="role_prof" ||props?.connectedUser?.role==="role_student" ){
            let object = {
                subject: [returnDynamicValueFromUrl(3) + " CHAT ADMISSION"],
                trie: 'recent',
                searchFilter: "",
                page: 0,
                chat: true,
                event: "ADMISSION_CHAT",
                ticketObject:[trainingId+" "+trainingType]
            }
            if(typeof object!= undefined && object?.subject?.length!=0){
            props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, true);
            }
        }
        }else{
            props?.getFollow(Number(idFollow))
        }
        return () => {
            setShowChat(false);
        };
    }, [follow])

    
    useEffect(() => {
        setListPosts(props?.listPosts)
    }, [props?.listPosts])
   
    const openChat = (value: boolean) => {
        setShowChat(!showChat);
    }
    const createPostFN=(value:string)=>{  

       
        const [trainingId,trainingType] = setTrainingIdAndType(follow?.courseId)
        let object = {
            subjectId: (listPosts.length === 0) ? null : listPosts[0]?.subjectId,
            idAuthor: props?.connectedUser?.idPerson,
            title: null,
            type: "POST",
            event: "ADMISSION_CHAT",
            comment: {
              description:value,
              type: "TEXT"
            },
            ticketObject:[trainingId+" "+trainingType]
          }
          props?.createPost(object, { ticketObject:[trainingId+" "+trainingType],idSubject: returnDynamicValueFromUrl(3), category: 'CHAT', type: 'ADMISSION' }, props?.connectedUser?.idSchool);
         
    }
    return (
        <>
         <div className="d-flex align-items-top">
            <div className="w-100">
            {(props?.connectedUser?.role === "role_prof"  || props?.connectedUser?.role === "role_adminstrator")&& <div style={{ backgroundColor: '#FFFFFF' }}>
                <HeaderRegistrationPreviewProfPageComponent openChat={openChat} />
            </div>}
            {props?.connectedUser?.role === "role_director" && <div style={{ backgroundColor: '#FFFFFF' }}>
                <HeaderRegistrationPreviewDirectorPageComponent />
            </div>}
            <HeaderRegistrationPreviewBlockStudentInfoComponent />
            <AdmissionPreviewStudentComponent />
            </div>
            
            {showChat && <div id="chatSidebar" >
                <AdmissionDiscussionSidbarElement listPosts={listPosts} follow={props?.follow} createPost={createPostFN} openChat={showChat} />
                </div>}


                </div>
        </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    socket: state.socketReducer.socketChat,
    listPosts: state.chatReducer.listPosts,
    follow: state.courseReducer.follow,
    socketInit: state.socketReducer.socketInit,
    schoolInfo:state.schoolInfoReducer.schoolInfo,
    actualStudent: state.courseReducer.studentInfo,
    registration: state.courseReducer.registration,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
                    getSubjectCommunity, 
                    connectSocketChat, 
                    updatelistDiscussion, 
                    getFollow, 
                    createPost, 
                    initSocket,
                    getRegistrationForm,
                    getApplicationFile,
                    getStudentInfoById,
                    getTraining,
                    getOfferCourse, 
                    redirect, clearHistoryArray
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPreviewFromListStudentPage);
