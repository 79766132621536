import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { clearHistory } from "../../../global-state/actions/breadcrumb-actions";
import HeaderPreviewExamDirector from "../../../components/headers-page/header-preview-exam-director";
import ExamPathListQuestions from "./exam-path-list-questions";

const ExamPreviewContent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [exam, setExam] = useState(props?.pathExam?.exams[0]);

    useEffect(() => {
        setExam(props?.pathExam?.exams[0])
    }, [props?.pathExam])


    return (
        <div>
            <div>
                <HeaderPreviewExamDirector title={exam?.title} />
            </div>
            <div>
                < ExamPathListQuestions questions={exam?.questionByQnaList} />
            </div>

        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ clearHistory }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    pathExam: state.courseReducer.training,


});
export default connect(mapStateToProps, mapDispatchToProps)(ExamPreviewContent);
