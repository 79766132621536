import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayStripeComponent from "../../components/finance/pay-stripe-component";
import axiosInstance from "../../helpers/interceptors";
import { ModalIDS } from "../../components/modals/modal-ids";
import { openModal } from '../../global-state/actions/modal-actions';
import { getOffer } from "../../global-state/actions/app-container-actions";



const OfferPaymentPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation()

    const stripePromise = loadStripe(`${process.env.REACT_APP_BASE_STRIPE_KEY}`);

    const history = useHistory();
    const [price, setPrice] = useState<any>(null);
    const [priceId, setPriceId] = useState<any>(null);
    const [payaction, setpayAction] = useState<Boolean>(false);
    const [period, setPeriod] = useState<any>();
    const [buttonStyle, setButtonStyle] = useState<any>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [contract, setContract] = useState<any>()

    const [clicked, setClicked] = useState<Boolean>(false)
    const [promoCode, setPromoCode] = useState('')
    const [msg, setMsg] = useState<String>('')
    const [periodicity, setPeriodicity] = useState<String>('')
    const [coupon, setCoupon] = useState<any>()
    const [valid, setValid] = useState<Boolean>()
    const [offer, setOffer] = useState<any>()

    const getContract = async () => {
        let baseURL = process.env.REACT_APP_BASE_URL23;
        let response = await axiosInstance.post(baseURL + "contract", {
            entityId: props?.schoolInfo?.id,
            entityType: "SCHOOL",
            paymentEngine: null,
            paymentMethod: null
        })
        setContract(response.data);
    }
    const loadingAction = ((btn: boolean) => {
        setLoading(btn)
    })

    const errorAction = ((btn: boolean) => {
        setError(btn)
    })

    const disablePayAction = () => {
        if (payaction === true) {
            setpayAction(false);
        }
    };

    const goBack = () => {
        history.goBack();
    };

    useEffect(() => {
        props?.getOffer(props?.schoolInfo?.id)
        getContract();
    }, []);

    useEffect(()=>{
        setPeriod("Monthly");

        setPrice(
            props?.offer?.pricings.length > 1
                ? props?.offer?.pricings[1]?.price
                : props?.offer?.pricings[0]?.min
        );

        setPriceId(
            props?.offer?.pricings.length > 1
                ? props?.offer?.pricings[1]?.id
                : offer?.pricings[0]?.id
        );
        setPeriodicity("MONTH")
    },[props?.offer])

    function calculateReducedPrice(price: number, coupon?: any) {
        let reducedPrice: number = price;
        reducedPrice -= (coupon/1.2);
        if (Number.isInteger(reducedPrice)) {
            return reducedPrice;
        } else {
            return reducedPrice.toFixed(2);
        }
    }

    function calculateReducedPriceDiscount(price: number, coupon?: any) {
        let reducedPrice: number = price;
        reducedPrice -= (reducedPrice / 100) * coupon;

        if (Number.isInteger(reducedPrice)) {
            return reducedPrice;
        } else {
            return reducedPrice.toFixed(2);
        }
    }

    const getFinalPrice = (prix: any, couponValeur: any, couponType: any) => {
        let prixFinal = prix;
        if (couponType === 'CASHBACK') {
            // Traitement pour le coupon de type cashback
            prixFinal -= couponValeur;
        } else if (couponType === 'DISCOUNT') {
            const cashbackAmount = (couponValeur / 100) * prix;
            prixFinal -= cashbackAmount;
        }
        if (Number.isInteger(prixFinal)) {
            return prixFinal;
        } else {
            return prixFinal.toFixed(2);
        }
    };

    const getFinalTva = (prix: any, couponValeur: any, couponType: any) => {
        let prixFinal = prix / 1.2;
        if (couponType === 'CASHBACK') {
          prixFinal = (prixFinal - (couponValeur/1.2)) * 0.2;
        } else if (couponType === 'DISCOUNT') {
          prixFinal = (prixFinal - (couponValeur / 100) * prixFinal) * 0.2;
        }
        if (Number.isInteger(prixFinal)) {
          return prixFinal;
        } else {
          return prixFinal.toFixed(2);
        }
      };


    const verifyCoupon = async () => {
        if (promoCode !== "") {
            const couponObject ={
                "product":"KOORS",
                "promoCode":promoCode
              }
            let coupon = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL22}coupons/couponsSearch` , couponObject).then((res: { data: any; }) => { return res.data })
            if (coupon && coupon.couponError?.errorMessage === "promoCode doesn't exists") {
                setMsg("code promo invalide")
                setValid(false)
            }
            else if (coupon?.expireAt !== null && (new Date(coupon?.expireAt) < new Date())) {
                setMsg("Le code promo est expiré")
            }
            else if (!coupon.offers.includes(offer.id)) {
                setMsg("code promo invalide")
            }
            else if (coupon?.promotion?.promoType !== "CASHBACK" && coupon?.promotion.promoType !== "DISCOUNT") {
                setMsg("code promo invalide")
            } else {
                await setCoupon(coupon)
                setValid(true)
                setMsg('')
            }
        }
        else {
            setMsg("Veuillez entrer un code promo")
            setValid(false)
        }
    }

    const getprice = (price: any) => {
        const priceWithoutTax = price / 1.2;
        if (Number.isInteger(priceWithoutTax)) {
            return priceWithoutTax;
        } else {
            return priceWithoutTax.toFixed(2);
        }
    }


    const openDepositModal = async () => {
        let newContract = {
            ...contract[0],
            contractDetails: {
              offerId: props?.offer?.id,
              contractDuration: {
                startAt: new Date(Date.now()),
                endAt: new Date(Date.now() + 3170e7),
              },
              pricingId: props?.offer?.idPricings[0],
              paymentMethod: "TRANSFER",
              paymentEngine: null
            },
            status: [{
              statusType: "ONGOING"
            }],
            contractRoles: []
          }
        await props?.openModal({ idModal: ModalIDS.DEPOSIT_MODAL, size: "md", data: { newContract } })
    }

    return (

        <div className="container-fluid mt-4">
            <div className="navbar navbar-expand-lg d-flex justify-content-start px-4 flex-nowrap mb-4" onClick={goBack}>
                <div className="d-flex justify-content-start order-1 w-100">
                    <a

                        className="d-flex justify-content-center align-items-center "
                        style={{
                            background: "#F2F2F5",
                            height: "48px",
                            width: "48px",
                            cursor: "pointer",
                        }}
                    >
                        <span className="material-icons-outlined" style={{ color: "black" }}>keyboard_arrow_left</span>
                    </a>
                </div>
            </div>

            <div className="row text-center " style={{ marginBottom: "100px" }}>
                <span className="H3-HeadlineOffer2  mb-8">Procédez au paiement</span>

                <span>
                    {" "}
                    <span className="body-xl neutral-3">Finalisez votre achat pour <span style={{ color: "black", fontWeight: "bold" }}> l’offre {props?.offer?.label} </span> </span>
                </span>
            </div>

            <div className="d-flex mx-auto px-4" style={{ maxWidth: '1248px' }}>
                <div className=" grid-offer justify-content-around w-100">


                    <div className="d-flex me-4 mt-4">
                        <div className="d-flex flex-column pe-3 w-100">
                            <span className="H3-Headline mb-3 mt-2">Période de facturation</span>

                            {!!offer && !!props?.offer?.pricings && props?.offer?.pricings.length > 1 &&
                                <><div className="d-flex flex-row">
                                    <button style={{ height: "fit-content", width: "100%" }} className={buttonStyle ? "btn-outlined-offer selected me-4 w-100" : "btn-outlined-offer default me-4 w-100"} onClick={(e) => { setPrice(props?.offer?.pricings[1]?.price); setPriceId(props?.offer?.pricings[1]?.id); setButtonStyle(true); }}>
                                        <div className="d-flex flex-column">
                                            <span className="body-sm mb-2 pb-1">{t('Facturation')} - {t('monthly')}</span>
                                            <div className="d-flex flex-row align-items-center">
                                                <span className="priceText">
                                                    {getprice(props?.offer?.pricings[1]?.price)}
                                                    €&nbsp;</span>
                                                <span className="body-xl neutral-3 bold"> HT / Mois</span>
                                            </div>
                                        </div>

                                    </button>
                                    <div className="w-100 d-flex flex-column justify-content-center">
                                        <button className={!buttonStyle ? "btn-outlined-offer selected w-100" : "btn-outlined-offer default  w-100"} onClick={(e) => { setPrice(props?.offer?.pricings[0]?.price); setPriceId(props?.offer?.pricings[0]?.id); setButtonStyle(false); }}>
                                            <div className="d-flex flex-column">
                                                <span className="body-sm mb-2 pb-1">{t('Facturation')} - {t('yearly_2')}</span>
                                                <div className="d-flex flex-row align-items-center">
                                                    <span className="priceText">
                                                        {getprice(props?.offer?.pricings[0]?.price)}
                                                        €&nbsp;</span>
                                                    <span className="body-xl neutral-3 bold"> HT / An</span>
                                                </div>
                                            </div>
                                        </button>

                                    </div>


                                </div></>
                            }
                            {!!offer && !!props?.offer?.pricings && props?.offer?.pricings.length == 1 &&
                                <div className="d-flex flex-row">

                                    <button className={buttonStyle ? "btn-outlined-offer selected me-4 w-100" : "btn-outlined-offer default me-4 w-100"} onClick={(e) => { setPrice(props?.offer?.pricings[0]?.min); setPriceId(props?.offer?.pricings[0]?.id); setButtonStyle(true) }}>
                                        <div style={{ maxHeight: 176 }}>
                                            <div className="d-flex flex-row justify-content-between " >
                                                <div className="d-flex flex-start">
                                                    <span className="body-md">{t('Facturation')} - {t('monthly')}</span>
                                                </div>

                                            </div>
                                            <div className="d-flex flex-row justify-content-between mt-3" >
                                                <div className="d-flex flex-end">
                                                    <span className="priceText">
                                                        {getprice(props?.offer?.pricings[0]?.min)}
                                                        €   <span className="body-xl bold neutral-3">  HT   /   Mois</span></span>
                                                </div>
                                            </div>
                                        </div>

                                    </button>




                                </div>
                            }
                            <span className="H3-Headline" style={{ marginTop: "64px", marginBottom: "32px" }}>Moyen de paiement</span>
                            <Elements stripe={stripePromise}>
                                <PayStripeComponent
                                    priceId={priceId}
                                    price={
                                        price
                                    }
                                    coupon={coupon}
                                    offer={offer}
                                    naturalPerson={props.connectedUserInfo}
                                    legalPerson={props.schoolInfo}
                                    disablePayAction1={disablePayAction}
                                    loadingAction1={loadingAction}
                                    errorAction={errorAction}
                                    payAction={payaction}
                                    period={periodicity}
                                    contract={contract}
                                />
                            </Elements>
                        </div>
                    </div>

                    <div className=" w-100 px-4">
                        <div className=" px-3">
                            <div className=" w-100 m-auto" style={{ maxWidth: 426 }}>
                                <div className="d-flex flex-column w-100">
                                    <div className="product-detail-payment w-100">
                                        <div className="d-flex flex-column">
                                            <div className="col-12">
                                                {clicked ? (
                                                    <span className="form-title">
                                                        Code promo / coupon
                                                    </span>
                                                ) : (
                                                    <button
                                                        className="btn-text body-sm bold outlined"
                                                        onClick={() => setClicked(true)}
                                                    >
                                                        Avez-vous un code promo / coupon ?
                                                    </button>
                                                )}
                                                {valid ? (
                                                    <div
                                                        className="d-flex flex-row p-2 mt-3"
                                                        style={{
                                                            border: "2px solid #9DDBA9",
                                                            borderRadius: "4px",
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-center align-items-center pe-2">
                                                            <span className='material-icons-outlined green-800 pe-1 pt-1'>local_offer</span>
                                                        </div>
                                                        <div className="me-auto body-md bold justify-content-center align-items-center">
                                                            {promoCode}
                                                        </div>
                                                        <button className="btn-text small">
                                                            <span
                                                                className="d-flex justify-content-end body-md bold"
                                                                onClick={() => {
                                                                    setValid(false);
                                                                    setPromoCode("");
                                                                    setMsg("Code promo retiré");
                                                                    setCoupon({});
                                                                }}
                                                            >
                                                                Retirer
                                                            </span>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    clicked && (
                                                        <div className="d-flex flex-row mt-3">
                                                            <div className="d-flex col-8">
                                                                <label className="form-title"></label>
                                                                <input
                                                                    type="text"
                                                                    value={promoCode}
                                                                    className={`form-control input text-default ${msg !== "" &&
                                                                        msg !== "Code promo retiré"
                                                                        ? "error"
                                                                        : ""
                                                                        }`}
                                                                    onChange={(e) => {
                                                                        setPromoCode(e.target.value);
                                                                    }}
                                                                    placeholder="Entrez le code"
                                                                />
                                                            </div>
                                                            <div className="d-flex ms-2 col-4">
                                                                <button
                                                                    type="button"
                                                                    className="btn-Primary large"
                                                                    onClick={() => verifyCoupon()}
                                                                >
                                                                    Appliquer{" "}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {msg != "Code promo retiré" ? (
                                                    <span className="error-input mt-1">{msg}</span>
                                                ) : (
                                                    <span className="form-error mt-1 green-800">
                                                        {msg}
                                                    </span>
                                                )}
                                            </div>
                                            <hr
                                                className="hr-payment my-4"
                                                style={{ margin: 0 }}
                                            />
                                            <div className="d-flex flex-row justify-content-between mb-8">
                                                <div className="d-flex flex-start">
                                                    <span className="neutral-2 body-md">
                                                        Offre {props?.offer?.label} -{" "}
                                                        {buttonStyle ? "Mensuel" : "Annuel"}{" "}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-end">
                                                    <span className="H4-Subtitle black-800 body-md bold">
                                                        { }

                                                        {getprice(price)}€
                                                    </span>
                                                </div>
                                            </div>

                                            <hr
                                                className="hr-payment my-4"
                                                style={{ margin: 0 }}
                                            />

                                            <div className="d-flex justify-content-between mb-1">
                                                <span className="H4-Subtitle body-md neutral-2">
                                                    Prix total HT
                                                </span>
                                                <span className="H4-Subtitle body-md neutral-2 bold">
                                                    {getprice(price)}€
                                                </span>
                                            </div>

                                            {valid && (
                                                <div
                                                    className="d-flex flex-row justify-content-between  "
                                                    style={{ paddingBottom: "8px" }}
                                                >
                                                    <div className="d-flex flex-start">
                                                        <div className="d-flex justify-content-center align-items-center pe-2">
                                                            <span className='material-icons-outlined green-800 pe-1 pt-1'>local_offer</span>
                                                        </div>
                                                        <span className="body-md green-800">
                                                            {" "}
                                                            {promoCode}{" "}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-end">
                                                        {coupon?.promotion.promoType ===
                                                            "CASHBACK" && (
                                                                <span className="H4-Subtitle body-md green-800 bold">
                                                                   -{(coupon?.promotion.promoValue/1.2).toFixed(2)}
                                                                    €
                                                                </span>
                                                            )}
                                                        {coupon?.promotion.promoType ===
                                                            "DISCOUNT" && (
                                                                <span className="H4-Subtitle body-md green-800 bold">
                                                                    -{getprice((price * (coupon?.promotion.promoValue / 100)))}
                                                                    €
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                            {valid && (
                                                <div
                                                    className="d-flex flex-row justify-content-between  "
                                                    style={{ paddingBottom: "8px" }}
                                                >
                                                    <div className="d-flex flex-start">

                                                        <span className="body-md neutral-2">
                                                            Prix HT après réduction
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-end">
                                                        {coupon?.promotion.promoType ===
                                                            "CASHBACK" && (
                                                                <span className="H4-Subtitle body-md neutral-2 bold">
                                                                    {calculateReducedPrice((price / 1.2), coupon?.promotion.promoValue)}
                                                                    €
                                                                </span>
                                                            )}
                                                        {coupon?.promotion.promoType ===
                                                            "DISCOUNT" && (
                                                                <span className="H4-Subtitle body-md neutral-2 bold">
                                                                    {calculateReducedPriceDiscount((price / 1.2), coupon?.promotion.promoValue)}
                                                                    €
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="d-flex justify-content-between mb-3">
                                                <span className="H4-Subtitle body-md neutral-2">
                                                    TVA 20%
                                                </span>
                                                <span className="H4-Subtitle body-md neutral-2 bold">

                                                    {coupon?.promotion?.promoValue
                                                        ? getFinalTva(price , coupon?.promotion?.promoValue, coupon?.promotion?.promoType)
                                                        : getprice(price * (20 / 100))}

                                                    <span className="H4-Subtitle body-md neutral-2">
                                                        €
                                                    </span>
                                                </span>
                                            </div>

                                            <div className="d-flex justify-content-between mb-3">
                                                <span className="H3-Headline black-800">
                                                    Prix total TTC
                                                </span>
                                                <span className="H3-Headline black-800">
                                                    {coupon?.promotion?.promoValue
                                                        ? getFinalPrice(price, coupon?.promotion?.promoValue, coupon?.promotion?.promoType)
                                                        : price}
                                                    <span className="H3-Headline neutral-3">
                                                        €
                                                    </span>
                                                </span>
                                            </div>
                                            {!loading ? (
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn-Primary large mt-4"
                                                        style={{ width: "100%" }}
                                                        onClick={() => setpayAction(true)}
                                                    >
                                                        confirmer et payer
                                                    </button>

                                                    {!!offer &&
                                                        !!props?.offer?.pricings &&
                                                        props?.offer?.pricings[0]?.type === "DYNAMIC" && (
                                                            <button
                                                                type="button"
                                                                className="btn-Secondary large mt-4"
                                                                style={{ width: "100%" }}
                                                                onClick={openDepositModal}
                                                            >
                                                                Payer par virement bancaire
                                                            </button>
                                                        )}
                                                </div>
                                            ) : (
                                                <button className="btn-Primary large mt-4 w-100 loaddata ">
                                                    <div className="threedots-large-Animation">
                                                        Traitement...
                                                    </div>
                                                </button>
                                            )}
                                            {error && <span className="body-sm" style={{ color: "#E06A6A", marginTop: "6px" }}>Erreur de paiement, vous n&apos;avez pas été prélevés. Veuillez
                                                réessayer plus tard</span>}
                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    )


}

const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    offer: state.schoolInfoReducer.offer
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    openModal, getOffer
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(OfferPaymentPage);