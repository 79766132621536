/* eslint-disable import/no-anonymous-default-export */
import { IReduxClearLastTrainingIdAction, IReduxAddLastTrainingIdAction, IReduxSaveEnvUrlAction, IReduxGoToRedirectionAction, IReduxClearHistoryArrayAction, IReduxRedirectAction, EReduxActionTypes, IReduxClearHistoryProfesseurAction, IReduxClearSaveReturnPathAction, IReduxSaveReturnPathAction, IReduxAddLastPathSecondAction, IReduxAddLastPathAction, IReduxGoToProfesseurAction, IReduxGoToAction, IReduxUpdatePathAction, IReduxClearHistoryAction, IReduxRedirectPopupAction } from './../actions/breadcrumb-actions';


export interface IReturnPath {
  path: string;
  tabPage: string;
}

export interface IHistory {
  title: string;
  path: string;
}
export interface IReduxHistoryState {
  listHistoryBreadCrumb: any[];
  listHistoryProfesseur: any[];
  lastPathBeforeBreadCrumb: string;
  lastPathBreadCrumb: string;
  returnPathPlateform: IReturnPath
  redirectPopup: boolean,
  envUrl:string,
  history:IHistory[],
  lastTrainingId:any
}

const initialState: IReduxHistoryState = {
  listHistoryBreadCrumb: [],
  listHistoryProfesseur: [],
  lastPathBeforeBreadCrumb: '',
  lastPathBreadCrumb: '',
  returnPathPlateform: {
    path: '',
    tabPage: ''
  },
  redirectPopup:false,
  envUrl:'',
  history:[],
  lastTrainingId:undefined
};

type TAuthReducerActions = IReduxClearLastTrainingIdAction | IReduxAddLastTrainingIdAction | IReduxSaveEnvUrlAction | IReduxGoToRedirectionAction | IReduxClearHistoryArrayAction | IReduxRedirectAction | IReduxGoToAction | IReduxClearSaveReturnPathAction |IReduxSaveReturnPathAction | IReduxClearHistoryAction | IReduxAddLastPathSecondAction | IReduxUpdatePathAction | IReduxAddLastPathAction | IReduxGoToProfesseurAction | IReduxClearHistoryProfesseurAction | IReduxRedirectPopupAction;

export default function (state: IReduxHistoryState = initialState, action: TAuthReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.GO_TO:
      const newState = { ...state };
      if (action.data.index === null) {
        newState.listHistoryBreadCrumb.push({ title: action.data.title, path: action.data.path });
      } else {
        newState.listHistoryBreadCrumb.length = action.data.index + 1;
      }
      return { ...state, listHistoryBreadCrumb: newState.listHistoryBreadCrumb };
    case EReduxActionTypes.REDIRECT_POPUP:
      return {...state, redirectPopup:action.data}
    case EReduxActionTypes.CLEAR_HISTORY:
      return { ...state, listHistoryBreadCrumb: [] };
    case EReduxActionTypes.SAVE_RETURN_PATH:
      return { ...state, returnPathPlateform: action.data };
    case EReduxActionTypes.CLEAR_SAVE_RETURN_PATH:
      return { ...state, returnPathPlateform: { path: '', tabPage: '' } };
    case EReduxActionTypes.ADD_LAST_PATH:
      return { ...state, lastPathBeforeBreadCrumb: action.data };
    case EReduxActionTypes.ADD_LAST_PATH_SECOND:
      return { ...state, lastPathBreadCrumb: action.data };
    case EReduxActionTypes.CLEAR_HISTORY_PROFESSEUR:
      return { ...state, listHistoryProfesseur: [{ title: "Professeurs", path: "/professors/all" }] };
    case EReduxActionTypes.UPDATE_PATH:
      const newListe = [...state.listHistoryBreadCrumb];
      const pathIndex = newListe.findIndex((obj: any) => obj.path === action.data.path);
      if(action.data.index) {
        newListe[action.data.index].title = action.data.title
      }
      else if (pathIndex === -1) {
        newListe.push({ title: action.data.title, path: action.data.path })
      } else {
        newListe[pathIndex].title = action.data.title
      }
      return { ...state, listHistoryBreadCrumb: newListe };
    case EReduxActionTypes.GO_TO_PROFESSEUR:
      const newListeProf = { ...state };
      newListeProf.listHistoryProfesseur.push({ title: action.data.title, });
      return { ...state, listHistoryProfesseur: newListeProf.listHistoryProfesseur };

    case EReduxActionTypes.REDIRECT: 
      const oldHistory = [...state.history]
      oldHistory.push(action.data)      
      return {...state, history:oldHistory};

    case EReduxActionTypes.GO_TO_REDIRECTION:       
      return {...state, history:action.data};

    case EReduxActionTypes.CLEAR_HISTORY_ARRAY: 
      return {...state, history:[]};
    
    case EReduxActionTypes.SAVE_ENV_URL: 
      return {...state, envUrl:action.data};
    
    case EReduxActionTypes.ADD_LAST_URL: 
      return {...state, lastTrainingId:action.data};

    case EReduxActionTypes.CLEAR_LAST_URL: 
      return {...state, lastTrainingId:undefined};
      
    default:
      return state;
  }
}
