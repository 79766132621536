import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import "../../translations/i18n";
import koolog from '../../styles/images/koolog.png';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getExamToPass } from '../../global-state/actions/exam-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useHistory } from 'react-router';
import SondageUserPage from './sondage-user-page ';
import SondageArchivedPage from './sondage-archived-page';
interface Props {
    t: any

}
interface Params {
    id: any
}
const SondagePage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const { id } = useParams<Params>();
    const [currentExam, setCurrentExam] = useState<any>()
    useEffect(() => {
        props?.getExamToPass(id)
    }, [id])
    useEffect(() => {
        setCurrentExam(props?.examToPass)

    }, [props?.examToPass])

    return (<>

        <div >

            {
                !!currentExam && currentExam?.status === "ACTIVE" ? <>
                    <SondageUserPage />
                </> : <>
                    <SondageArchivedPage />
                </>
            }



        </div>



    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    school: state.schoolInfoReducer.schoolInfo,
    examToPass: state.examReducer.examToPass,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamToPass
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,

)(SondagePage);
