import { FC, useEffect, useState } from "react";

import { Line } from 'react-chartjs-2';
import { connect } from "react-redux";
import { getCatalog } from "../../../global-state/actions/catalog-duplicaction-actions";
import { AppState } from "../../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { fetchData } from "../../../base/functions/Functions";
import { getStatisticsConnectionApi, getTimesConnectionApi } from "../../../services/activity.service";
interface Props {
    courseList: any[]
    courseFormId:any,
    date:any
}
const EtudiantChart: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props> = (props) => {
    const { t } = useTranslation();
    const [connexion, setConnexion] = useState({
        years: []
    })
    const [months, setMonths] = useState([])
    const [times, setTimes] = useState<any>()
    const [statistics, setStatistics] = useState<any>()

    useEffect(() => {

        let currentDate = new Date()
        let nbrMonth = currentDate.getMonth()
        
        let labels :any= [
            t('Jan'),
            t('Fev'),
            t('Mar'),
            t('Avr'),
            t('May'),
            t('Jui'),
            t('Jul'),
            t('Aug'),
            t('Sep'),
            t('Oct'),
            t('Nov'),
            t('Dec')
        ]
        if(props?.date === currentDate.getFullYear()){
            setMonths(labels.slice(0, nbrMonth+1))
        }else{
            setMonths(labels)
        }
    }, [])
  


    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) { getTimesConnection(); }
    }, [])

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) {
                    props?.getYears(times?.visit)
        }
    }, [times]);

    useEffect(() => {
        if (!!props?.courseFormId && !!props?.selectedValue) {
            getStatisticsConnection(props?.selectedValue, [props?.courseFormId])

        }
    }, [props?.courseFormId, props?.selectedValue]);

    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0  && props?.courseFormId === undefined) {
            getTimesConnection();

        }
    }, [props?.courseList]);

    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0  && props?.courseFormId === undefined) 
        {props?.getYears(times?.connected)
        }
    }, [times]);

    useEffect(() => {
        if (Array.isArray(props?.courseList) && props?.courseList?.length > 0  && props?.courseFormId=== undefined)  {
            getStatisticsConnection(props?.selectedValue, props?.courseList)
        }
    }, [props?.courseList, props?.selectedValue]);

    useEffect(() => {
        setConnexion(statistics?.connexion)

    }, [statistics]);

    const getTimesConnection = async () =>{
        const result = await getTimesConnectionApi()
        setTimes(result)
    }

    const getStatisticsConnection = async (year: any, list: any) =>{
        const result = await getStatisticsConnectionApi(year, list)
        setStatistics(result)
    }

    const data = {
        labels: months,
        datasets: [
            {
                label: t('ConnectedStudents'),
                data: statistics?.connexion,
                backgroundColor: "#8A57CC",
                borderColor: "#8A57CC",
                borderWidth: 1,
                yAxisID: "leftAxe"
            }
        ]
    }
    return (
        <div style={{pointerEvents:"none"}}>


            <Line options={{
                legend: {
                    display: true,
                    labels: {
                        fontColor: '#333',
                        usePointStyle: true,

                    }
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false
                    }
                }, scales: {

                    xAxes: [{
                        offset: false,
                        gridLines: {
                            display: true,
                            drawOnChartArea: false
                        },

                    }],
                    yAxes: [{

                        id: "leftAxe",
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 1,
                        },
                        offset: false,
                        scaleLabel: {
                            display: true,
                            labelString: t('StudentsNumber')
                        },
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            color: "#8A57CC"
                        }
                    }]

                }
            }} data={data} /></div>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    courses: state.catalogReducer.courses,
    user: state.authReducer.connectedUser,
    getYears: ownProps?.getYears,
    selectedValue: ownProps?.selectedValue,
    courseForm: state.courseReducer.courseForm,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getCatalog
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(EtudiantChart);