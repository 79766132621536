import { ComponentProps, FC, useEffect, useState } from 'react';
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import Dropzone from 'react-dropzone';
import CropImageProfile from '../../../elements/inputs/crop-image-profile';
import axiosInstance from '../../../helpers/interceptors';
import { getUserProfileDetails, updateProfile } from '../../../global-state/actions/auth-actions';
import { useTranslation } from "react-i18next";
import { getDomainFromUrl } from '../../../helpers/domainCheck';


const UpdateProfilePhotoModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [photoToEdit, setPhotoToEdit] = useState<any>(null);
    useEffect(() => {
        setPhotoToEdit(props?.connectedUser?.photo);
    }, [props?.connectedUser])
    const onDrop = async (accepted: any, rejected: any) => {
        if (Object.keys(rejected).length === 0) {
            await onGetBlobFil(accepted);
        }
    };

    const onGetBlobFil = async (blobFile: any) => {
        const baseUrlGed = process.env.REACT_APP_BASE_URL4;
        if (blobFile === null)
            setPhotoToEdit(null)
        else {
            const form = new FormData();
            form.append('file', blobFile[0]);
            await axiosInstance.post(baseUrlGed + "upload/image", form).then(async (response) => { setPhotoToEdit(response.data.link) })
        }
    }

   

    function addFile  (file: any) {
        const form = new FormData();
        form.append('file', file[0]);
        const copyProfile = { ...props?.connectedUser };
        axiosInstance.post(process.env.REACT_APP_BASE_URL4 + "upload/image",
            form
        ).then(async (response) => {
            setPhotoToEdit(response.data.link);
            copyProfile.photo = response.data.link
            copyProfile.id = props?.user?.idPerson
            copyProfile.email = props?.connectedUser?.email
            copyProfile.lastName = props?.connectedUser?.lastName;
            copyProfile.firstName = props?.connectedUser?.firstName;
            copyProfile.country = props?.connectedUser?.country;
            copyProfile.city = props?.connectedUser?.city;
            copyProfile.dateNaiss = props?.connectedUser?.dateNaiss;
            copyProfile.domain = props?.connectedUser?.domain; 
            copyProfile.domainUrl= getDomainFromUrl()
            await props?.updateProfile(copyProfile);
            await props?.getUserProfileDetails(props?.user?.id, 'dont');

        })
    };


    async function deletePhotoToUpdate() {
        await setPhotoToEdit(null);
        await props?.closeModal();
    }

    async function deleteFile() { 
        const copyProfile = { ...props?.connectedUser };
        copyProfile.photo = null;
        await props?.updateProfile(copyProfile);
        await props?.getUserProfileDetails(props?.user?.id, 'dont'); //person
    }

    return (
        <div >

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto" >
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()} >
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>

            <div className="modal-body " style={{overflow:'hidden'}}>
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="H4-Subtitle">{t('saveSchoolLogo')}</span>
                    </div>
                    <div className="col-md-6 upload-image-profile text-center " style={{ paddingTop: "40px" }}>
                        {photoToEdit === null ? (
                            <Dropzone
                                style={
                                    { width: '100%', zIndex: 1, position: "relative" }
                                }
                                multiple={false}
                                accept="image/*"
                                onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                            >
                                <div className="mx-auto">
                                    <div className="bg-with-border dashed d-flex align-items-center justify-content-center flex-column" style={{ width: "100%", height: "230px" }}>
                                        <span className="material-icons-outlined" style={{ color: "#DADBDF" }}>cloud_upload</span>
                                        <p className="body-md bold neutral-3 mt-3"> {t('dragToUpload')}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-column mx-auto" style={{ paddingTop: "16px", paddingBottom: "56px" }}>
                                    <span style={{ paddingBottom: "16px" }}> {t('or')} </span>
                                    <button className="btn-Primary large" /*onClick={() => { confirmCropOrUpload(photoToEdit) }}*/>{t('selectPhoto')}</button>
                                </div>
                            </Dropzone>
                        ) : (<>
                            <CropImageProfile width={304} file={photoToEdit+"?cacheblock=true"} deleteFile={deleteFile} addFile={addFile} deletePhotoToUpdate={deletePhotoToUpdate} t={t} />
                        </>
                        )}
                    </div>
                </div>
            </div>

        </ div>
    )
}

const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUserInfo,
    user : state.authReducer.connectedUser
    


});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            updateProfile,
            getUserProfileDetails,

        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateProfilePhotoModalComponent);