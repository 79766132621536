import { Component, ComponentProps } from 'react';
import AvatarEditor from 'react-avatar-editor';
import SliderZoomInOut from './../../elements/slider/slider-zoom-in-out';
class CropImageProfile extends Component<any, ComponentProps<any>> {   
    editor: AvatarEditor | undefined;
    container: any;
    constructor(props: any) {
        super(props)
        this.state = {
            file: props?.file,
            config: {
                scale: 1,
                position: { x: 0, y: 0 },
                cropped: false,
                crop: { x: 10, y: 20 },
                aspect: 1,
                width: props?.width, // course 840, webinar 700
                maxWidth: props?.width
            }
        }

    }



    componentDidMount() {
        window.addEventListener("resize", this.changeWindowSize);
        this.changeWindowSize();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.changeWindowSize);
    }
    changeWindowSize = () => {
        if ( (this?.container?.offsetWidth!=undefined) && (this?.container?.offsetWidth!= null) && (this?.container?.offsetWidth < this?.state?.config?.maxWidth) ) {
            const copyState = { ...this.state.config };
            copyState.width = this.container?.offsetWidth - 80;
            this.setState({ config: copyState });
        } else if (this.state.width !== this.state.config.maxWidth - 80) {
            const copyState = { ...this.state.config };
            copyState.width = this.state.config.maxWidth - 80;
            this.setState({ config: copyState });
        }
    }
    componentWillReceiveProps(nextProps: any) {

        this.setState({
            file: nextProps?.file,
            scale: 1,
            position: { x: 0, y: 0 },
            cropped: false,
            crop: { x: 10, y: 20 },
            aspect: 1,
            width: nextProps?.width, // course 840, webinar 700
            maxWidth: nextProps?.width
        })
    }

    handleScale = (value: any) => {
        const copyConfig = { ...this.state.config };
        const newScale = parseFloat(value);
        copyConfig.scale = newScale;
        this.setState({ config: copyConfig });
    }
    handlePositionChange = (position: any) => {
        const copyConfig = { ...this.state.config };
        copyConfig.position = position;
        this.setState({ config: copyConfig });

    }
    onCrop = (e: any) => {
        if (this.editor) {
            const canvasScaled = this.editor.getImageScaledToCanvas();
            const canvas = canvasScaled.toDataURL();

            fetch(canvas).then(res => res.blob()).then((blob: any) => {
  
                let name = "";
                decodeURIComponent(this?.state?.file.split("/")[3]).split("-").forEach((substr: string, index: number) => {
                    if (index > 0 && index < this.state.file.split("/")[3].split("-").length - 1) {
                        name = name + substr + "-";
                    } else if (index === this.state.file.split("/")[3].split("-").length - 1) {
                        name = name + substr;
                    }
                })

                let newFile = new File([blob], (this.state.file.name) ? this.state.file.name : name, { type: blob.type, lastModified: Date.now() });
                this.props?.addFile([newFile]);
                const copyState = { ...this.state };
                copyState.config = {
                    scale: 1,
                    position: { x: 0, y: 0 },
                    cropped: false,
                    crop: { x: 10, y: 20 },
                    aspect: 1,
                    width: this.props?.width, // course 840, webinar 700
                    maxWidth: this.props?.width
                };
                this.setState(copyState);
            });
        }
        this.props?.deletePhotoToUpdate();
    }
    onDelete = () => {
        this.props?.deleteFile();
    }
    setEditorRef = (editor: any) => {
        this.editor = editor
    }


    render() {
        return <>
            <div style={{ position: 'relative' }}>
                <div ref={(ref) => this.container = ref} className=" d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center" style={{ position: "absolute", top: 10, right: 0, width: 18, height: 18 }}>
                        <div style={{position: "absolute" ,right: 75}}>
                            <button className="btn-QuickActions white d-flex align-items-center justify-content-center" style={{ width: 1, height: 2}} onClick={() => this.onDelete()}><span className="material-icons-outlined" color="white">close</span></button>
                        </div>

                    </div>
                    <AvatarEditor
                        crossOrigin="anonymous"
                        ref={(ref) => this.setEditorRef(ref)}
                        border={4}
                        borderRadius={200}
                        scale={parseFloat(this.state.config.scale)}
                        width={222}
                        height={222}
                        position={this.state.config.position}
                        onPositionChange={this.handlePositionChange}
                        image={this.state?.file}
                    />
                    
                </div>
                <div className=" w-90 d-flex align-items-center justify-content-center mt-3 mb-3">
                        <SliderZoomInOut scale={parseFloat(this.state.config.scale)} newScale={this.handleScale} />
                </div>

            </div>
            <div className=" justify-content-center align-items-center d-flex " style={{paddingBottom: '56px'}}>
                <button type="button" className="btn-Primary large " onClick={(e) => { this.onCrop(e) }} >
                {this.props.t('save')}
                </button>
            </div>
        </>
    }
}


export default CropImageProfile;