import { ComponentProps, FC, useEffect, useMemo } from "react"
import ProfessorActivitiesCardComponent from "../../components/activities-cards/prof-activities-card";
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { clearAllData } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DirectorActivitiesCardComponent from "../../components/activities-cards/director-activities-card";
import {loginWithCookies } from "../../global-state/actions/auth-actions";
import { useHistory } from 'react-router-dom';
import { returnStartPathUrl } from "../../helpers/domainCheck";


const ActivitiesCardPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const user = useMemo(() => { return props?.connectedUser }, [props?.connectedUser]);
    const history = useHistory();

        useEffect(() => {        
        if (props.contract!==undefined && props?.connectedUser!=undefined && props?.connectedUser?.role === "role_director" && Array.isArray(props.contract) && props.contract.length === 0) {
            history.push({ pathname: `${returnStartPathUrl()}/offers/list`, state: undefined });
        }
    }, [props.contract , props?.connectedUser])

    return (<>
        {user?.role.includes("role_prof") &&
            <>
                <ProfessorActivitiesCardComponent />
            </>

        }
        {(user?.role.includes("role_director") || user?.role.includes("role_adminstrator")) &&
            <>
                <DirectorActivitiesCardComponent />
            </>

        }
    </>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    contract : state.schoolInfoReducer.contract

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ 
    clearAllData, 
    loginWithCookies,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesCardPage);