import { EReduxActionTypes, IReduxBaseAction } from "./../actions/form-actions";

export interface IReduxFormState {
  couponStatus: boolean | null;
}

export const initialState: IReduxFormState = {
  couponStatus: null
};

const formReducer = (
  state: IReduxFormState = initialState,
  action: IReduxBaseAction
): IReduxFormState => {
  switch (action.type) {
    case EReduxActionTypes.ACTIVATE_COUPON:
      return {
        ...state,
        couponStatus: true
      };
    case EReduxActionTypes.DESACTIVATE_COUPON:
      return {
        ...state,
        couponStatus: false
      };
    case EReduxActionTypes.CANCEL_COUPON:
      return {
        ...state,
        couponStatus: null
      };
    default:
      return state;
  }
};

export default formReducer;
