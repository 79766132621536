import React, { useState, FC, useCallback, useEffect } from 'react';


import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { clearHistory } from '../../global-state/actions/breadcrumb-actions'
import { withTranslation } from 'react-i18next';
import "../../translations/i18n";
import SondagesListPage from './sondages-list-page';
import { clearSurveyForm, getSizeQnas } from '../../global-state/actions/sondage-actions';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import BtnAddSondage from './BtnAddSondage';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useHistory } from "react-router";

const SondageListPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props: any) => {
  let [size, setSize] = useState(props?.size)
  let [filter, setfilter] = useState({

    qnaType: "SERVEY",
    entityType: "SCHOOL",
    entityId: props?.school?.id,
    pageSize: 12,
    pageNumber: 0,
  })
  const { t }: any = props;
  const history = useHistory();


  useEffect(() => {
    props?.getSizeQnas(filter, props?.school.id);
    props?.clearSurveyForm()

  }, [])

  useEffect(() => {


    setSize(props?.size)
  }, [props?.size])

  return (




    <div className="container-fluid">


      <div className="row justify-content-center">
        <div className="row justify-content-center">
          <div className="col-md-12 " style={{ backgroundColor: '#FFFFFF' }}>
            <div className="row mx-auto" style={{ maxWidth: '1200px' }}>
              <div className="col-md-12 mt-5" >
                <div className="d-flex align-items-center mb-3 " style={{ justifyContent: "space-between" }}>
                  <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left' }}>{t('sondages')}<span className="H2-Headline" style={{ marginLeft: '10px' }}>{size}</span></h1>

                  {
                    size > 0 && <div className='d-flex flex-end mb-4'>
                      
                      <button type="button" className="btn-Primary large" onClick={()=> history.push(`${returnStartPathUrl()}/create-sondage`)}>
                        Créer un sondage
                      </button>
                    </div>
                  }


                </div>
              </div>
            </div>
          </div>


          <>
            <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
              <div className="col-md-12 mx-auto" style={{ maxWidth: '1200px' }}>
                <div className="row mt-5">

                </div>


              </div>

              <SondagesListPage t={t} filter={filter} />

            </div>
          </>


        </div >
      </div>
    </div>
  );
}


const mapStateToProps = (state: AppState) => ({
  school: state.schoolInfoReducer.schoolInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      clearHistory,
      getSizeQnas,
      openModal,
      clearSurveyForm

    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SondageListPage));
function t(arg0: string) {
  throw new Error('Function not implemented.');
}

