import { EReduxActionTypes } from "../actions/upload-video-chapter-actions";

export interface IReduxUploadState {
  vimeoLink?: any;
  uploading: any;
  uploadStatus: any;
  idVideo: string;
  transcoding: any;
  transcodingProgress: any;
  transcodeStatus: string;
  checkStopped: boolean;
  id?: any;
  loading: boolean;
  readyToDisplay: string;
  subtitleUploaded: boolean;
  getChapterSubtitleUploaded: string | object[];
  getChapterSubtitleUploadedLoading: boolean;
  uuidReadyToDisplay?: any;
}

const initialState: IReduxUploadState = {
  vimeoLink: undefined,
  uploading: false,
  uploadStatus: "",
  idVideo: "",
  transcoding: false,
  transcodingProgress: 0,
  transcodeStatus: "",
  checkStopped: false,
  id: undefined,
  loading: true,
  readyToDisplay: "",
  subtitleUploaded: false,
  getChapterSubtitleUploaded: [],
  getChapterSubtitleUploadedLoading: false,
  uuidReadyToDisplay: undefined,
};
export default (state = initialState, action: any) => {
  switch (action.type) {
    case EReduxActionTypes.POST_VIMEO_START_CHAPTER:
      return {
        ...state,
        ...action.payload,
      };
    case EReduxActionTypes.CLEAR_FORM_CHAPTER:
      return {
        ...state,
        ...action.payload,
        readyToDisplay: "",
      };

    case EReduxActionTypes.POST_VIMEO_PROGRESS_TRANSCODE_CHAPTER:
      return {
        ...state,
        ...action.payload,
      };

    case EReduxActionTypes.POST_VIMEO_SUCCESS_CHAPTER:
      return {
        ...state,
        ...action.payload,
      };

    case EReduxActionTypes.POST_VIMEO_FAILED_CHAPTER:
      return {
        ...state,
        ...action.payload,
      };

    case EReduxActionTypes.POST_VIMEO_PROGRESS_CHAPTER:
      return {
        ...state,
        ...action.payload,
      };
    case EReduxActionTypes.CHECK_VIDEO_AVALIBILITY:
      const copyState = { ...state };
      if (copyState.idVideo !== "") {
        return {
          ...state,
          readyToDisplay: action.payload.status,
          uuidReadyToDisplay: action.payload.uuidReadyToDisplay,
        };
      } else {
        return { ...state };
      }
    case EReduxActionTypes.SET_SUBTITLE_VIMEO_FAILED:
      return { ...state, subtitleUploaded: action.data.status };

    case EReduxActionTypes.SET_SUBTITLE_VIMEO_SUCCESS:
      return { ...state, subtitleUploaded: action.data.status };

    case EReduxActionTypes.GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_LOADING:
      return { ...state, getChapterSubtitleUploadedLoading: true };
    case EReduxActionTypes.GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_FAILED:
      return {
        ...state,
        getChapterSubtitleUploaded: "upload failed",
        getChapterSubtitleUploadedLoading: false,
      };
    case EReduxActionTypes.GET_CHAPTER_SUBTITLE_VIMEO_UPLOADED_SUCCESS:
      return {
        ...state,
        getChapterSubtitleUploaded: action.data,
        getChapterSubtitleUploadedLoading: false,
      };
    default:
      return state;
  }
};
