import { ComponentProps, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory, useParams } from "react-router";
import { useTranslation } from 'react-i18next';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import CorrectionExamBodyComponent from '../../components/correction-exam/correction-exam-body-component';
import CorrectionExamBodyLoadingComponent from '../../components/correction-exam/correction-exam-body-loading-component';
import HeaderViewFinalExamSubmissionComponent from '../../components/headers-page/header-view-final-exam-submission-component';
import HeaderViewFinalExamSubmissionLoadingComponent from '../../components/headers-page/header-view-final-exam-submission-loading-component';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { getExamForm } from '../../global-state/actions/exam-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { log } from 'console';


interface Params {
    id: any,
    actualTab: any
}
const FinalExamCorrectionPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const {t} = useTranslation()
    const { id } = useParams<Params>();
    const { actualTab } = useParams<Params>();

    const history = useHistory();
    const [examForm, setExamForm] = useState<any>(props?.examForm);
    const [loadingTables] = useState(props?.loadingLists);
    const [loadingExamForm] = useState(props?.loadingExamForm);


    useEffect(() => {
        if (props?.examForm === undefined) {
            
            
            let examIndex;
            switch (actualTab) {
                case "TOREVIEW": {
                    examIndex = props?.examsList?.listExamToReview?.findIndex((obj: any) => obj?.progressQna?.id === Number(id));
                    break;
                }
                case "COMPLETED": {
                    examIndex = props?.examsList?.listExamCompleted?.findIndex((obj: any) => obj?.progressQna?.id === Number(id));
                    break;
                }
                case "TOREVIEW": {
                    examIndex = props?.examsList?.listAllExam?.findIndex((obj: any) => obj?.progressQna?.id === Number(id));
                    break;
                }
                default: {
                    props?.clearHistoryArray();
                    history.push(`${returnStartPathUrl()}/examsDetails/details`);
                    break;
                }
            }
            if (examIndex === -1) {
                
                props?.clearHistoryArray();
                history.push(`${returnStartPathUrl()}/examsDetails/details`);
            } else {
                
                props?.getExamForm(props?.examsList.listAllExam[examIndex], actualTab, examIndex);
            }
        } else if ((actualTab === undefined) || (id === undefined)) {
            
            props?.clearHistoryArray();
            history.push(`${returnStartPathUrl()}/examsDetails/details`);
        } else if ((Number(id) !== props?.examForm?.progressQna?.id) || ((actualTab !== props?.examForm?.progressQna?.status))) {
            
            let examIndex;
            switch (actualTab) {
                case "TOREVIEW": {
                    examIndex = props?.examsList?.listExamToReview?.findIndex((obj: any) => obj?.progressQna?.id === Number(id));
                    break;
                }
                case "COMPLETED": {
                    examIndex = props?.examsList?.listExamCompleted?.findIndex((obj: any) => obj?.progressQna?.id === Number(id));
                    break;
                }
                case "ALL": {
                    examIndex = props?.examsList?.listAllExam?.findIndex((obj: any) => obj?.progressQna?.id === Number(id));
                    break;
                }
                default: {
                    props?.clearHistoryArray();
                    history.push(`${returnStartPathUrl()}/examsDetails/details`);
                    break;
                }
            }
            if (examIndex === -1) {
                
                props?.clearHistoryArray();
                history.push(`${returnStartPathUrl()}/examsDetails/details`);
            } else {
                
                if(props?.historyList.lastPathBeforeBreadCrumb!="/examsDetails/details"){
                    props?.getExamForm(props?.examsList?.listAllExam[examIndex], actualTab, examIndex);
                }
            }
        }

        else {
            props?.clearHistoryArray()
            props?.redirect(t("Examens"), `${returnStartPathUrl()}/examsDetails/details`)
            props?.redirect(props?.examForm.courseName, `${returnStartPathUrl()}/examsDetails/submission/${actualTab}/${props?.examForm.progressQna.id}`)
        }
    }, []);

    useEffect(() => {

        if (props?.examForm === undefined) {
            
            const examIndex = props?.examsList.listAllExam.findIndex((obj: any) => obj.progressQna.id === Number(id));
            if (examIndex === -1) {
                props?.clearHistoryArray()
                history.push(`${returnStartPathUrl()}/examsDetails/details`);
            } else {
                props?.getExamForm(props?.examsList.listAllExam[examIndex], 'ALL', examIndex);
            }
        }
        else if (Number(id) !== props?.examForm?.progressQna?.id) {
           
            props?.clearHistoryArray()
            props?.redirect(t("Examens"), `${returnStartPathUrl()}/examsDetails/details`)
            props?.redirect(props?.examForm?.courseName, `${returnStartPathUrl()}/examsDetails/submission/${actualTab}/${props?.examForm?.progressQna?.id}`)
            history.push(`${returnStartPathUrl()}/examsDetails/submission/${actualTab}/${props?.examForm?.progressQna?.id}`);
        }
        setExamForm(props?.examForm);
    }, [props?.examForm]);


    return (
        <div className="container-fluid mt-5"  >
            <div className="row" >
                {(loadingExamForm && loadingTables) ? <HeaderViewFinalExamSubmissionLoadingComponent /> : <HeaderViewFinalExamSubmissionComponent />}
            </div>
            <div  >
                {(loadingExamForm && loadingTables) ? <CorrectionExamBodyLoadingComponent /> : <CorrectionExamBodyComponent examForm={examForm} Idprogress={id} />}
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    examsList: state.examReducer.examsList,
    connectedUserDetails: state.authReducer.connectedUser,
    history: ownProps?.history,
    examStats: state.examReducer.examStats,
    actualTab: state.examReducer.actualTab,
    examForm: state.examReducer.examForm,
    loadingExamForm: state.examReducer.loadingExamForm,
    historyList:state.historyReducer
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamForm,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FinalExamCorrectionPage);