import { useState, FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { getListPayments } from '../../global-state/actions/finance-actions';
import { getCoursesForDirector } from '../../global-state/actions/course-actions';
import { clearHistory } from '../../global-state/actions/breadcrumb-actions'
import { withTranslation } from 'react-i18next';
import "../../translations/i18n";
import CoursesPayedListPage from './courses-payed-list-page';
import OffersListPage from './offers-list-page';
import axios from '../../helpers/interceptors';
import { getTrainingsFinance } from '../../global-state/actions/training-actions';
import SelectElement from '../../elements/select/select-element';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import SubscriptionsListPage from './subscriptions-list-page'
import FaciliteListPage from './facilite-list-page';
import { debounce } from 'lodash';
import { getAllOfferPayedFinanceApi, getAllPayedCourseFinanceApi, getIncome, getPaymentFilterApi, getSizePaimentFinanceApi, getSizePayedFinanceApi, getSizeSubApi } from '../../services/finance.service';
import Skeleton from '@material-ui/lab/Skeleton';
import { fetchData, getListYearAndMonthsToDay } from '../../base/functions/Functions';

const PaymentListPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props: any) => {
  const [filterPayed, setFilterPayed] = useState({
    idPerson: "",
    label: "",
    yearOrMonthbyPayed: "" + new Date().getFullYear(),
    startDate: new Date().getFullYear() + "-01-01",
    endDate: new Date().getFullYear() + "-12-31",
    pageSize: 12,
    pageNumber: 0,
  });
  const { t }: any = props;
  const [actualTab, setActualTab] = useState("Payed");
  const [periodePayed, setPeriodePayed] = useState(false);
  const [priceMoyenAnnuel, setPriceMoyenAnnuel] = useState(0);
  const [filterPaid2, setFilterPaid2] = useState({
    idCourse: "",
    search: "",
    pageSize: 12,
    pageNumber: props?.pageNumber,
  });
  const [filterPaid3, setFilterPaid3] = useState({
    idCourse: "",
    search: "",
    pageSize: 12,
    pageNumber: props?.pageNumber,
    entityId: props?.user?.idSchool,
    entityType: "SCHOOL",
    paymentType: "ABN",
  });
  const [filterPaid4, setFilterPaid4] = useState({
    idCourse: "",
    search: "",
    pageSize: 12,
    pageNumber: props?.pageNumber,
    entityId: props?.user?.idSchool,
    entityType: "SCHOOL",
    paymentType: "FAC",
  });
  const [listPayed, setListPayed] = useState<{ label: string; value: any; }[]>([{ label: t('Tous'), value: "" }]);
  const [listProf, setListProf] = useState<{ label: string; value: any; }[]>([{ label: t('Tous'), value: "" }]);
  const [list, setList] = useState<any>();
  const first = { label: t('Année') + " " + new Date().getFullYear(), value: t('Année') + " " + new Date().getFullYear() }
  const [listOffersPayed, setListOffersPayed] = useState<any>()
  const [listPayedCourse, setListPayedCourse] = useState<any>()
  const [listPayedCoursefilter, setListPayedCoursefilter] = useState<any>()
  const [listYearAndMonth, setListYearAndMonth] = useState<any>()

  const [sizePayment, setSizePayment] = useState<any>();
  const [sizeSub, setSizeSub] = useState<any>();
  const [sizeFac, setSizeFac] = useState<any>();
  const [size, setSize] = useState<any>();
  const [totalIncome, setTotalIncome] = useState<number>(0)

  const [loadingSizePayment, setLoadingSizePayment] = useState<boolean>(true);
  const [loadingSizeSub, setLoadingSizeSub] = useState<boolean>(true);
  const [loadingSizeFac, setLoadingSizeFac] = useState<boolean>(true);
  const [loadingSize, setLoadingSize] = useState<boolean>(true);
  const [totalTrainingGains, setTotalTraningGains] = useState<number>(0);


  const getAllOfferPayedFinance = async (filter: any, idSchool: any, action: any) => {
    const result = await getAllOfferPayedFinanceApi(filter, idSchool, action)
    setListOffersPayed(result)
  }

  const getAllPayedCourseFinance = async (filter: any, idSchool: any, action: any) => {
    const result = await getAllPayedCourseFinanceApi(filter, idSchool, action)
    setListPayedCourse(result)
  }

  const getPaymentFilter = async (filter: any, idSchool: any) => {
    const result = await getPaymentFilterApi(filter, idSchool)
    setListPayedCoursefilter(result)
  }

  const getSizePayedFinance = async (filter: any, idSchool: any) => {
    const result = await getSizePayedFinanceApi(filter, idSchool)
    setSize(result)
    setLoadingSize(false)
  }

  const getSizePaimentFinance = async (filter: any, idSchool: any) => {
    const result = await getSizePaimentFinanceApi(filter, idSchool)
    setSizePayment(result)
    setLoadingSizePayment(false)
  }

  const getSizeSub = async (filter: any) => {
    const result = await getSizeSubApi(filter)
    if (filter.paymentType === "ABN") {
      setSizeSub(result)
      setLoadingSizeSub(false)
    } else {
      setSizeFac(result)
      setLoadingSizeFac(false)
    }
  }

  const getTotalIncome = async (idSchool:number) => {
    const total:any = await getIncome(idSchool);
    setTotalIncome(total)
  }

  useEffect(() => {
    getAllOfferPayedFinance(filterPayed, props?.school.id, "first")
    getTotalIncome(props?.school.id)
    const filter = {
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
    };
    let filterPaid = {
      idCourse: "",
      search: "",
    }
    getSizePayedFinance(filterPayed, props?.user?.idSchool)
    props?.clearHistory();
    fetchProfessors()
    setListYearAndMonth(getListYearAndMonthsToDay())
    props?.getTrainingsFinance(filter);
    getPaymentFilter(filterPaid, props?.user?.idSchool);
    getAllPayedCourseFinance(filterPaid, props?.user?.idSchool, "filter");
    getSizePaimentFinance(filter, props?.user?.idSchool);
    const filterPaymentAbn = {
      idCourse: null,
      search: null,
      pageSize: 12,
      pageNumber: props?.pageNumber,
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
      paymentType: "ABN",
    };
    getSizeSub(filterPaymentAbn);
    props?.getListPayments(filterPaymentAbn, "filter");

    const filterPaymentFac = {
      idCourse: null,
      search: null,
      pageSize: 12,
      pageNumber: props?.pageNumber,
      entityId: props?.user?.idSchool,
      entityType: 'SCHOOL',
      paymentType: "FAC",
    };
    getSizeSub(filterPaymentFac);
    props?.getListPayments(filterPaymentFac, "filter");

  }, []);

  useEffect(() => {
    let total = 0;
    listOffersPayed?.forEach((payed: any) => {
      total += payed.totalPrice;
    });
    setPriceMoyenAnnuel(total);
  }, [listOffersPayed]);
  function getDateDebutAndFin(e: any) {
    const x = e.toString().length;
    if (x === 4) {
      setFilterPayed({ ...filterPayed, yearOrMonthbyPayed: e, startDate: e + "-01-01", endDate: e + "-12-31" });
      setPeriodePayed(false);
    } else if (x === 2 || x === 1) {
      setFilterPayed({
        ...filterPayed,
        yearOrMonthbyPayed: e,
        startDate: x === 1 ? new Date().getFullYear() + "-0" + e + "-01" : new Date().getFullYear() + "-" + e + "-01",
        endDate: x === 1 ? new Date().getFullYear() + "-0" + e + "-31" : new Date().getFullYear() + "-" + e + "-31",
      });
      setPeriodePayed(false);
    } else {
      setPeriodePayed(true);
    }
  }

  const genericExcel = () => {
    const { t }: any = props;
    const name = t('finance') + " " + props?.school.code
    const lang = localStorage.getItem("language")
    const baseUrl1 = process.env.REACT_APP_BASE_URL11;
    axios({
      url: baseUrl1 + "offers/export/" + props?.user.idSchool + "/" + name + "/" + lang,
      method: 'POST',
      responseType: 'blob',
      data: {
        startDate: new Date().getFullYear() + '-01-01',
        endDate: new Date().getFullYear() + '-12-31',
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name + '.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

    })
  }

  const genericPayment = () => {
    const name = "Payment" + " " + props?.school.code
    const lang = localStorage.getItem("language")
    const baseUrl1 = process.env.REACT_APP_BASE_URL13;
    let filterPaid = {
      idCourse: "",
      search: "",
    }
    axios({
      url: baseUrl1 + "payment/export/" + name + "/" + lang + "/" + props?.user.idSchool,
      method: 'POST',
      responseType: 'blob',
      data: filterPaid
    }).then((responseExcel) => {
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([responseExcel.data]));
      link.setAttribute('download', name + '.csv'); //or any other extension
      link.href = url;
      document.body.appendChild(link);
      link.click();

    })
  }

  useEffect(() => {
    let arr = [{ label: t('allSubjects'), value: t('allSubjects') }];

    const newOptions = listOffersPayed?.map((obj: any) => ({
      label: obj?.title,
      value: obj?.courseId,
    }));

    const uniqueOptions = newOptions?.filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.label === value.label && t.value === value.value
      ))
    );

    arr = arr.concat(uniqueOptions);

  }, [listOffersPayed]);


  useEffect(() => {
    let arr = [{ label: t('allSubjects'), value: t('allSubjects') }];

    const newOptions = listPayedCoursefilter?.map((obj: any) => ({
      label: obj?.label,
      value: obj?.id,
    }));

    const uniqueOptions = newOptions?.filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.label === value.label && t.value === value.value
      ))
    );

    arr = arr.concat(uniqueOptions);

    setListPayed(arr);
  }, [listPayedCoursefilter]);

  const fetchProfessors = async () => {


    const proffessors: any = await fetchData('GET', `${process.env.REACT_APP_BASE_URL2}person/getProfsPerSchoolId/${props?.user?.idSchool}`, {})
    let arr = [{ label: "Tous les professeurs", value: "Tous les professeurs" }];

    proffessors?.forEach((obj: any) => {
      arr.push({ label: obj?.name, value: obj?.id });
    });

    setListProf(arr);
  }

  useEffect(() => {
    let arr: { label: string; value: any; }[] = [
      { label: t('Année') + " " + new Date().getFullYear(), value: new Date().getFullYear() }
    ]
    listYearAndMonth?.map((obj: any, i: number) => {
      if (Number.isInteger(obj.label)) {
        let obj1 = { label: t('Année') + " " + t(obj.label), value: obj.value }
        arr.push(obj1)
      } else {
        let obj1 = { label: t(obj.label) + " " + new Date().getFullYear(), value: obj.value }
        arr.push(obj1)
      }

    })

    const last = { label: t("prunp"), value: "period" }
    arr.push(last)
    setList(arr)
  }, [listYearAndMonth])

  const handleFilterProf = (e: any) => {
    setFilterPayed({
      ...filterPayed,
      label: e,
      pageSize: 10000
    });
  }

  const debounceSearchProf = useCallback(debounce(handleFilterProf, 1000), []);
  const handleFilterProf2 = (e: any) => {
    setFilterPaid2({
      ...filterPaid2,
      search: e,
      pageSize: 10000
    })
  }

  const debounceSearchProf2 = useCallback(debounce(handleFilterProf2, 1000), []);

  const handleFilterProf3 = (e: any) => {
    setFilterPaid3({
      ...filterPaid3,
      search: e,

    })
  }

  const debounceSearchProf3 = useCallback(debounce(handleFilterProf3, 1000), []);

  const handleFilterProf4 = (e: any) => {
    setFilterPaid4({
      ...filterPaid4,
      search: e
    })
  }
  const debounceSearchProf4 = useCallback(debounce(handleFilterProf4, 1000), []);

  const handleStartDate = (e: any) => {
    setFilterPayed({
      ...filterPayed,
      startDate: e
    })
  }
  const debounceStartDate = useCallback(debounce(handleStartDate, 1000), []);

  const handleEndDate = (e: any) => {
    setFilterPayed({
      ...filterPayed,
      endDate: e
    })
  }
  const debounceEndDate = useCallback(debounce(handleEndDate, 1000), []);

  return (

    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="row justify-content-center">
          <div className="col-md-12" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="row mx-auto" style={{ maxWidth: '1200px' }}>
              <div className="col-md-12 mt-5" >
                <h1 className="H2-Headline neutral-3 d-flex align-items-center" style={{ textAlign: 'left' }}>{t('Finance')}<span className="H2-Headline" style={{ marginLeft: '10px' }}>{totalIncome}<span className="H3-Headline neutral-3" style={{ marginLeft: '10px' }}>{props?.paymentMethod?.currency === "TND" ? "TND" : "€"} {new Date().getFullYear()}</span></span></h1>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap px-2 pb-4 mt-3 mx-auto" style={{ maxWidth: '1200px' }}  >
              {!loadingSize ?
                <button className={`btn-Tabs ${(actualTab === 'Payed') ? '' : 'unselected'} me-2 d-flex align-items-center`} onClick={() => { setActualTab("Payed") }} style={{ marginRight: "16px" }}>{t('TitleFormations')}  ({size})</button>
                :
                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
              }
              {!loadingSizePayment ?
                <button className={`btn-Tabs ${(actualTab === 'paie') ? '' : 'unselected'} me-2 d-flex align-items-center`} onClick={() => { setActualTab("paie") }} style={{ marginRight: "16px" }}>{t('paie')} ({sizePayment})</button>
                :
                <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
              }
              {
                !loadingSizeSub ?
                  <button className={`btn-Tabs ${(actualTab === 'ABN') ? '' : 'unselected'} me-2 d-flex align-items-center`} onClick={() => { setActualTab("ABN") }} style={{ marginRight: "16px" }}>Abonnements ({sizeSub})</button>
                  :
                  <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
              }
              {
                !loadingSizeFac ?
                  <button className={`btn-Tabs ${(actualTab === 'FAC') ? '' : 'unselected'} me-2 d-flex align-items-center`} onClick={() => { setActualTab("FAC") }} style={{ marginRight: "16px" }}>Paiements par facilité ({sizeFac})</button>
                  :
                  <Skeleton className="skeleton radius me-3" animation="wave" variant="rect" width={130} height={36} />
              }

            </div>
          </div>



          {(actualTab === 'Payed') &&
            <>
              <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
                <div className="col-md-12 mx-auto" style={{ maxWidth: '1200px' }}>
                  <div className="row mt-5">
                    {<div className="col-md-12 d-flex flex-wrap align-items-center justify-content-between">
                      <button type="button" className="btn-Secondary large icon-left" onClick={() => genericExcel()}><span className="material-icons-outlined"  >download</span> <div className="m-auto ps-2">{t('exportListe')}</div></button>
                      <div className="d-flex">
                        <div className="select-input" style={{ minWidth: 215, marginRight: '10px' }}>

                        </div>
                        <div className="select-input" style={{ minWidth: 215, marginRight: '10px' }}>
                          <SelectElement placeholder={first.label} disabled={false} taille={'lg'} defaultValue={"none"} options={list} onChange={(e: any) => {
                            setFilterPayed({
                              ...filterPayed,
                              yearOrMonthbyPayed: e,
                            }); getDateDebutAndFin(e);
                          }} />
                        </div>
                        <div className="select-input" style={{ minWidth: 215, marginRight: '10px' }}>
                          <SelectElement placeholder={listProf[0].label} disabled={false} taille={'lg'} defaultValue={"none"} options={listProf} onChange={(e: any) => {
                            if (e === "Tous les professeurs") {
                              e = ""
                            }
                            setFilterPayed({
                              ...filterPayed,
                              pageSize: 10000, idPerson: e
                            });

                          }} />
                        </div>
                        <div style={{ minWidth: 215 }} className="input-form-control">
                          <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} onChange={(e: any) => { debounceSearchProf(e.target.value) }} />
                          <span className="material-icons-outlined">search</span>
                        </div>
                      </div>
                    </div>}
                  </div>
                  {periodePayed && <> <div className="mt-3">
                    <div className="d-flex flex-wrap justify-content-end">
                      <div className="d-flex flex-wrap align-items-center me-2">
                        <span className="body-sm bold me-2" style={{ color: "#272727" }}>Du : </span>
                        <div style={{ minWidth: 215 }}>
                          <input dir="ltr" type="date" className="form-control input input-date text-default" onChange={(e: any) => {
                            debounceStartDate(e.target.value);
                          }} />
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <span className="body-sm bold me-2" style={{ color: "#272727" }}>Au : </span>
                        <div style={{ minWidth: 215 }}>
                          <input dir="ltr" type="date" className="form-control input input-date text-default" onChange={(e: any) => {
                            debounceEndDate(e.target.value)
                          }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  </>}
                </div>
                {(size !== undefined || size !== 0) && <OffersListPage t={t} filter={filterPayed} setTotalTraningGains={setTotalTraningGains} />}

              </div>
            </>
          }




          {(actualTab === 'paie') &&

            <>
              {listPayed &&
                <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
                  <div className="col-md-12 mx-auto" style={{ maxWidth: '1200px' }}>
                    <div className="row mt-5">
                      <div className="col-md-12 d-flex flex-wrap align-items-center justify-content-between">

                        <button type="button" className="btn-Secondary large icon-left" onClick={() => genericPayment()}><span className="material-icons-outlined"  >download</span> <div className="m-auto ps-2">{t('exportListe')}</div></button>



                        <div className="d-flex">

                          <div className="select-input" style={{ minWidth: 215, marginRight: '10px' }}>
                            <SelectElement placeholder={listPayed[0].label} disabled={false} taille={'lg'} defaultValue={"none"} options={listPayed} onChange={(e: any) => {
                              if (e === t('allSubjects')) {
                                e = ""
                              }
                              setFilterPaid2({
                                ...filterPaid2,
                                idCourse: e,
                                pageSize: 10000

                              })

                            }} />

                          </div>
                          <div style={{ minWidth: 215 }} className="input-form-control">
                            <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} onChange={(e: any) => {


                              debounceSearchProf2(e.target.value)


                            }} />
                            <span className="material-icons-outlined">search</span>
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>

                  {sizePayment !== undefined && sizePayment > 0 ? <CoursesPayedListPage t={t} filter={filterPaid2} /> : <div className='mt-5 pt-5'>
                    <div className="msg-list-empty">
                      <EmptyListElement lines={[t('noCoursePayedDefault')]} />
                    </div>
                  </div>}
                </div>
              }
            </>
          }
          {(actualTab === 'ABN') &&

            <>
              <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
                <div className="col-md-12 mx-auto" style={{ maxWidth: '1200px' }}>
                  <div className="row mt-5">
                    {size > 0 && <div className="col-md-12 d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex justify-content-end" style={{ width: '100%' }}>

                        <div className="select-input" style={{ minWidth: 215, marginRight: '10px' }}>
                          <SelectElement placeholder={listPayed[0].label} disabled={false} taille={'lg'} defaultValue={"none"} options={listPayed} onChange={(e: any) => {

                            if (e === t('allSubjects')) {
                              e = ""
                            }
                            setFilterPaid3({
                              ...filterPaid3,
                              idCourse: e
                            })

                          }} />

                        </div>
                        <div style={{ minWidth: 215 }} className="input-form-control">
                          <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} onChange={(e: any) => {

                            debounceSearchProf3(e.target.value)
                          }} />
                          <span className="material-icons-outlined">search</span>
                        </div>

                      </div>
                    </div>}
                  </div>

                </div>
                {sizeSub !== undefined && sizeSub > 0 ? <SubscriptionsListPage t={t} filter={filterPaid3} /> : <div className='mt-5 pt-5'>
                  <div className="msg-list-empty">
                    <EmptyListElement lines={[t('noCoursePayedDefault')]} />
                  </div>
                </div>}




              </div>
            </>
          }
          {(actualTab === 'FAC') &&
            <>
              <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
                <div className="col-md-12 mx-auto" style={{ maxWidth: '1200px' }}>
                  <div className="row mt-5">
                    {size > 0 &&

                      <div className="col-md-12 d-flex flex-wrap align-items-center justify-content-between">
                        <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                          <div className="select-input me-2" style={{ minWidth: 215 }}>
                            <SelectElement
                              placeholder={listPayed[0].label}
                              disabled={false}
                              taille={'lg'}
                              defaultValue={"none"}
                              options={listPayed}
                              onChange={(e: any) => {
                                if (e === t('allSubjects')) {
                                  e = ""
                                }
                                setFilterPaid4({
                                  ...filterPaid4,
                                  idCourse: e
                                })

                              }}
                            />
                          </div>
                          <div style={{ minWidth: 215 }} className="input-form-control">
                            <input
                              dir="ltr"
                              type="text"
                              className="form-control input text-default"
                              placeholder={t('searchProfessors')}

                              onChange={(e: any) => {

                                debounceSearchProf4(e.target.value)
                              }}
                            />
                            <span className="material-icons-outlined">search</span>
                          </div>
                          <div></div>
                        </div>
                      </div>}
                  </div>

                </div>
                {sizeFac !== undefined && sizeFac > 0 ? <FaciliteListPage t={t} filter={filterPaid4} /> : <div className='mt-5 pt-5'>
                  <div className="msg-list-empty">
                    <EmptyListElement lines={[t('noCoursePayedDefault')]} />
                  </div>
                </div>}




              </div>
            </>
          }
        </div >
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  school: state.schoolInfoReducer.schoolInfo,
  user: state.authReducer.connectedUser,
  allCourses: state.courseReducer.allCourses,
  paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
  pageNumber: state.courseReducer.pageNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCoursesForDirector,
      clearHistory,
      getTrainingsFinance,
      getListPayments
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PaymentListPage));