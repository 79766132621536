import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";


interface Props {
  chapter:any;
  index:any;
  moveCard:any;
  options:any;
  updateChapter:any
}


export default function CardChapterComponent(props: Props) {
    const [isShown, setIsShown] = useState<boolean>(false);
    const { t } = useTranslation();
    const index = useMemo(() => { return props?.index }, [props?.index])
    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {
            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }

    return (<div className={` d-flex align-items-center bg-with-border white vignette-hover dragAndDrop  ps-40 pe-4 ${(isShown !== true) ? "py-28" : "py-23"} mt-3`} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
    {!isShown ? <>
      <div style={{width: 70}}>
        <span className="H3-Headline">{((index+1) < 10)? "0" + (index+1): (index+1)}</span>
      </div>
    </> :
      <><div style={{width: 70}}>
        <button type="button" className="btn-QuickActions small mb-2" disabled={index === 0} onClick={() => { props?.moveCard && props?.moveCard(index, index - 1) }} >
          <span className="material-icons-outlined">
            arrow_upward</span>
        </button>
        <button type="button" className="btn-QuickActions small" disabled={index === props?.options?.length-1} onClick={() => { props?.moveCard && props?.moveCard(index, index + 1) }} >
          <span className="material-icons-outlined">
            arrow_downward</span>
        </button>
      </div></>
    }
    <div className=" d-flex flex-row justify-content-between align-items-center detail-liste-responsive w-100" onClick={()=>{props?.updateChapter(props?.chapter)}}>
      <div className="d-flex align-items-center" >
        <div className="d-flex flex-column" >
          <span className="H3-Headline mb-6 formation-card horizontal title" style={{marginTop:"15px", marginBottom:"15px"}}>{props?.chapter?.chapter?.title}</span>
          
        </div>
      </div>




      <div className=" d-flex align-items-center detail-liste-responsive-actions me-2 justify-content-end" style={{ minWidth: 290 }}>
        <div className="d-flex align-items-center detail-liste-responsive-chapter">
          <span className="material-icons-outlined me-2 pe-1 neutral-3">timer</span>
          <span className="body-md bold">{convertTime(props?.chapter?.chapter?.duration)}</span>
        </div>
      </div>
    </div>
  </div>)
}