
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import logoSchool from '../../../styles/imgs/koorsK.png'
import "../../../translations/i18n";
import Register from "./register";
import { AppState } from "../../../global-state/store/root-reducers";
import { invitePerson } from "../../../global-state/actions/auth-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Axios from '../../../helpers/interceptors';
import { returnStartPathUrl } from "../../../helpers/domainCheck";

interface RouteParams {
    token: string,
    role: string
}
const RegisterComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = ({ invitedPerson, logoschool, invitePerson }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { token } = useParams<RouteParams>();
    const { role } = useParams<RouteParams>();
    const [invitation, setInvitation] = useState(false);
    const [type, setType] = useState("");
    const [relationType, setRelationType] = useState("");
    const [data, setData] = useState<any>("");
    let [validation, setValidation] = useState(false);
    let [email, setEmail] = useState("");
    let [newPass, setNewPass] = useState("")
    let [confirmPass, setConfirmPass] = useState("")
    const [typeClass, setTypeClass] = useState<any>()
    const [showCPClass, setShowCPClass] = useState<any>()
    const [newColor, setNewColor] = useState<any>()
    const baseUrl1 = process.env.REACT_APP_BASE_URL2;
    const [showNPClass, setShowNPClass] = useState<any>(true)
    const [showCPType, setShowCPType] = useState<any>(true)
    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)

    useEffect(() => {
        if (!!token) {
            if (role === "STUDENT") {
                setType("Inscription")
                setRelationType("STUDENT")
                invitePerson(token)
            } else {
                invitePerson(token)
            }
        } else {
            setType("Inscription")
            setRelationType("STUDENT")
        }

    }, [])



    useEffect(() => {
        if (!!invitedPerson) {

            setData(invitedPerson)
            setInvitation(true);
            setEmail(invitedPerson?.invited?.email?.toLowerCase())
            setRelationType(invitedPerson?.relationType)

            switch (invitedPerson?.relationType) {
                case "DIRECTOR": {
                    setType("directeur")
                    break;
                }
                case "STUDENT": {
                    setType("Inscription")
                    break;
                }
                case "ADMINSTRATOR": {
                    setType("administrateur")
                    break;
                }
                default: {
                    setType("professeur")
                    break;
                }
            }
        } else {
            setInvitation(false)
        }
    }, [invitedPerson])
    const action = () => {
        setValidation(true)
    }
    useEffect(() => {
        setTypeClass(type === "Inscription" ? "Inscription" : "Activation de mon compte " + type.toLowerCase())

    }, [type])

    // STATES CHECK EMAIL, PASSWORD, CONFIRM PASSWORD
    const [emailClass, setEmailClass] = useState<any>()
    const [pwdClass, setPwdClass] = useState<any>()
    const [confirmPwdClass, setConfirmPwdClass] = useState<any>()

    const [emailMsgError, setEmailMsgError] = useState<any>("")
    const [passwordMsgError, setPasswordMsgError] = useState<any>()
    const [passwordConfirmMsgError, setPasswordConfirmMsgError] = useState<any>()

    // START EMAIL CHECK INPUT AND ERROR MESSAGE 
    useEffect(() => {
        if (email === '' || email.length > 0) return setEmailClass("form-control input text-default")
        if (regx.test(email)) return setEmailClass("form-control input text-default success")
    }, [email])

    const checkMailBlur = async () => {
        if (!regx.test(email)) {
            setEmailClass("form-control input text-default error")
            setEmailMsgError(t('msgErrorMail'))
        }
        else {
            const emailExist = await Axios.get(baseUrl1 + "person/getpersonbyemail/" + email?.toLowerCase()).then((emailRes: any) => {
                return emailRes.data;
            })
                .catch((err: any) => {
                    return err
                });
            if (emailExist === "Email not found") {
                setEmailClass("form-control input text-default success")
                setEmailMsgError("")
            }
            else {
                setEmailMsgError(t("emailAlreadyUsed"))
            }

        }
    }

    // PASSWORD CHECK INPUT AND ERROR MESSAGE
    useEffect(() => {
        if (newPass === '' || newPass.length > 0) return setPwdClass("form-control input text-default")
        if (newPass.length >= 8) return setPwdClass("form-control input text-default success")
    }, [newPass])

    const checkNewPasslBlur = () => {
        if (newPass.length < 8) {
            setPwdClass("form-control input text-default error")
            setPasswordMsgError(t('msgErrorPassword'))
        } else {
            setPwdClass("form-control input text-default success")
            setPasswordMsgError("")
        }
    }

    // CONFIRM PASSWORD CHECK INPUT AND ERROR MESSAGE
    useEffect(() => {
        if (confirmPass === '') return setConfirmPwdClass("form-control input text-default")
    }, [confirmPass])

    const checkConfirmPassBlur = () => {
        if (confirmPass.length >= 8 && newPass === confirmPass) {
            setConfirmPwdClass("form-control input text-default success")
            setPasswordConfirmMsgError("")
        }
        if (confirmPass.length < 8 || confirmPass !== newPass) {
            setConfirmPwdClass("form-control input text-default error")
            setPasswordConfirmMsgError(t('msgErrorConfirmPassword'))
        }
    }

    const logoSrc = () => {
        if (logoschool?.logo === null) return logoSchool
        return logoschool?.logo
    }

    const typePasswordInput = (showClass: any) => {
        if (showClass) return 'password'
        return ''
    }

    const displayEye = () => {
        if (showCPType) return <RemoveRedEyeIcon />
        return <VisibilityOffIcon />
    }

    return (
        <>
            {validation === false &&
                <>

                    <div className="d-flex flex-column align-items-center w-100  auth-form mx-5">
                        <div className="auth-form-logo">
                            <img style={{ maxHeight: "164px" }} src={logoSrc()} alt="logo school" />
                        </div>
                        <div className="d-flex flex-column align-items-center w-100 mb-4">
                            {(invitation === true || token === undefined) &&
                                <>
                                    {(type !== "Inscription") && <span className="H3-Headline black-800 mb-3"> {t("activateMyAccount") + " "}<span>{type}</span> </span>}
                                    {(type === "Inscription") && <span className="H3-Headline black-800 mb-3"> {t('inscriptionTitle')} </span>}

                                    {(validation === false) &&
                                        <>
                                            <div className="mb-4 w-100 position-relative">
                                                <label className="form-title">{t('emailLogin')}</label>
                                                <input id="email" dir="ltr" type="text" className={emailClass} placeholder={t('emailLogin')} value={email} disabled={(token !== undefined)} onChange={(e) => setEmail(e.target.value.trim())}
                                                    onBlur={() => checkMailBlur()}
                                                />
                                                <span className="error-input">
                                                    {emailMsgError}
                                                </span>
                                            </div>

                                            <div className="w-100 mb-3 position-relative">
                                                <label className="form-title">{t('password')}  </label>
                                                <div className="input-form-control-assistant">
                                                    <input
                                                        id="password"
                                                        dir="ltr"
                                                        type={typePasswordInput(showNPClass)}
                                                        autoComplete="new-password"
                                                        onPaste={e => { e.preventDefault() }}
                                                        className={pwdClass}
                                                        placeholder={t('enterPassword')}
                                                        name="password"
                                                        value={newPass}
                                                        onChange={(e) => setNewPass(e.target.value)}
                                                        data-container="body"
                                                        data-bs-toggle="popover"
                                                        data-placement="right"
                                                        data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                                                        onBlur={() => checkNewPasslBlur()}
                                                    />
                                                    <div className="container-eye">
                                                        <span className="material-icons" style={{ cursor: 'pointer', color: newColor }} onClick={() => setShowNPClass(!showNPClass)}>
                                                            {showNPClass ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span className="error-input">
                                                    {passwordMsgError}
                                                </span>
                                            </div>
                                            <div className="w-100 mt-3 position-relative">
                                                <label className="form-title">{t('passConfirmation')} </label>
                                                <div className="input-form-control-assistant">
                                                    <input
                                                        id="confirm-password"
                                                        dir="ltr"
                                                        type={typePasswordInput(showCPType)}
                                                        autoComplete="new-password"
                                                        onPaste={e => { e.preventDefault() }}
                                                        className={confirmPwdClass}
                                                        placeholder={t('confimrNewPass')}
                                                        name="password"
                                                        value={confirmPass}
                                                        onChange={(e) => setConfirmPass(e.target.value)}
                                                        onBlur={() => checkConfirmPassBlur()}
                                                    />
                                                    <div className="container-eye">
                                                        <span className="material-icons" style={{ cursor: 'pointer', color: newColor }} onClick={() => setShowCPType(!showCPType)}>
                                                            {displayEye()}
                                                        </span>
                                                    </div>

                                                    {showCPClass}
                                                </div>
                                                <span className="error-input">{passwordConfirmMsgError}</span>
                                            </div>
                                            <div className="mt-4">
                                                <button type="button" className="btn-Primary large" disabled={!((newPass.length >= 8) && (newPass === confirmPass) && (regx.test(email)) && (emailMsgError === ""))} onClick={action}>
                                                    {t('validRegister')}
                                                </button>
                                            </div>

                                            <div className="mt-5 d-flex  flex-column align-items-center">
                                                <div className=" text-center">
                                                    <span className="body-md bold black-800" >{t('alreadyHaveAcc')}</span>

                                                </div>

                                                <div className="mt-3 ">
                                                    <button type="button" className="btn-Secondary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                                                        {t('cnxButton')}
                                                    </button>

                                                </div>
                                            </div>
                                        </>}
                                </>}
                            {(invitation === false && token !== undefined) && <>
                                <div></div>
                                <span className="H4-Subtitle col-md-12 text-center">  {t('invalidToken')}</span>
                            </>

                            }

                        </div>

                    </div>
                </>
            }
            {validation === true &&
                <>
                    <Register bio={data?.invited?.biographique} type={typeClass} email={email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")} id={data?.invited?.id} relationType={relationType} firstName={data?.invited?.firstName} lastName={data?.invited?.lastName} password={confirmPass} token={token}></Register>
                </>
            }
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    invitedPerson: state.authReducer.invitedPerson,
    logoschool: state.schoolInfoReducer.schoolInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            invitePerson
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
