import { useEffect, useState } from "react";

export default function TextareaWithoutBorderElement(props: any) {
  const [text, setText] = useState('');
  useEffect(() => {
    setText(props?.value);
  }, [props?.value]);
  useEffect(() => {
    const b: any = document.getElementById(`textArea${props?.id}`);
    if (b.scrollHeight !== 0) {
      b.style.height = "1px";
      b.style.height = (1 + b.scrollHeight) + "px";
    }
  }, [text])
  const changeInputValue = (value: string) => {
    const b: any = document.getElementById(`textArea${props?.id}`);
    if (b.scrollHeight !== 0) {
      b.style.height = "1px";
      b.style.height = (1 + b.scrollHeight) + "px";
    }
    setText(value)
    props?.onChange(value);
  }

  return (
    <>
      <textarea maxLength={254} id={`textArea${props?.id}`} disabled={props?.disabled} value={text} className="textaria-patagraph" rows={1} style={{ resize: "none" }} onChange={(e) => { changeInputValue(e.target.value); }} placeholder={props?.placeholder}></textarea>
    </>
  );
}