/* eslint react/no-did-mount-set-state: 0 */
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createCourse, clearFormTest, updateCourse, getChapter, getExam, checkFinalExam } from '../../../../global-state/actions/course-actions';
import { clearFormVideo } from '../../../../global-state/actions/upload-video-course-actions';
import ContentCourseFormComponent from '../../../../components/formation-course-form/content-course-form-component';
import { useTranslation } from 'react-i18next';
import HeaderTrainingFormComponent from '../../../../components/formations-card/header-formation-form';

const ContentCourseFormPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [course, setCourse] = useState<any>();
    const { t } = useTranslation();
    const [copyDate,setCopyDate] = useState<any>()
    useEffect(() => {
        setCourse(props?.courseForm);
        setCopyDate(props?.courseForm?.creationDate?.substring(0,10).split("-").reverse().join("/"))
    }, [props?.courseForm])

    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF' }} className="w-100">
                <HeaderTrainingFormComponent type={"COURSE"} title={t('courseContentTitle')} status={course?.status} trainingTitle={course?.title} creationDate={copyDate} />
            </div>
            <ContentCourseFormComponent />
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createCourse,
            updateCourse,
            getChapter,
            clearFormVideo,
            getExam,
            clearFormTest,
            checkFinalExam,
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    loggedIn: state.authReducer.loggedIn,
    courseForm: state.courseReducer.courseForm,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    t: ownProps?.t,
    listTest: state.courseReducer.listTest,
    nbrFinalExam: state.courseReducer.nbrFinalExam,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentCourseFormPage);