import Dropzone from "react-dropzone";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const InputUploadSubtitleFile = ({ handleUploadFile, incorrectFile, isReset }: any) => {
  const { t } = useTranslation();
  const [isDroped, setIsDroped] = useState({ fileName: "", status: false });

  function onDrop(accepted: any, rejected: any) {
    if (Object.keys(rejected).length !== 0) {
      setIsDroped({ fileName: rejected[0].name, status: false });
      incorrectFile(true)
    } else {
      setIsDroped({ fileName: accepted[0].name, status: true });
      incorrectFile(false)
      handleUploadFile(accepted[0]);
    }
  }

  useEffect(() => {
    if(isReset) {
      setIsDroped({ fileName: '', status: false });
    }
  }, [isReset])
  

  return (
    <>
      <div className="mx-auto">
        <Dropzone
          accept=".vtt, .srt"
          style={{ width: "100%", zIndex: 1 }}
          multiple={false}
          onDrop={onDrop}
        >
          {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
            return (
              <div className="bg-with-border dashed grey-background p-5-25">
                <div className="d-flex m-2 align-items-center justify-content-between">
                  <div className="d-flex align-items-center me-2">
                    <div
                      className="me-3 d-flex align-items-center justify-content-center"
                      style={{ width: 40, height: 40 }}
                    >
                      <span className="material-icons-outlined neutral-4">
                        cloud_upload
                      </span>
                    </div>
                    <div className="d-flex flex-column neutral-4">
                      {isDroped.fileName
                        ? isDroped.fileName
                        : "Glissez-déposez les fichiers à télécharger (vtt, srt)"}
                    </div>
                  </div>
                  <button className="btn-Secondary large">
                    {isDroped.status ? t("fileUploaded") : t("uploadFile")}
                  </button>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </>
  );
};

export default InputUploadSubtitleFile;