


export default function ChoiceCardFormationComponent (props: any) {

    return (<div className="bg-with-border white p-4" style={{ maxWidth: 384 }}>
    <div className="d-flex flex-column align-items-center my-2">
        <div className="d-flex flex-column align-items-center mb-4">
            <span className="H3-Headline mb-3">{props?.title}</span>
            {props?.icon}
            <span className="body-md black-800 text-center">
                {props?.description}
            </span>
        </div>
        <div className="d-flex flex-column">
            {props?.children}
        </div>
    </div>
</div>)
}