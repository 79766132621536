/* eslint react/no-did-mount-set-state: 0 */
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { updatePath } from '../../../../global-state/actions/breadcrumb-actions'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createCourse, uploadImageCourse } from '../../../../global-state/actions/course-actions';
import { clearFormVideo } from '../../../../global-state/actions/upload-video-course-actions';
import DescriptionCourseFormComponent from '../../../../components/formation-course-form/description-course-form-component';
import { useTranslation } from 'react-i18next';
import PreviewCourseComponent from '../../../../components/formation-course-form/preview-course-component';
import HeaderTrainingFormComponent from '../../../../components/formations-card/header-formation-form';
import PreviewCoursePageFromProf from '../../../catalog/preview-course-page-from-prof';

const DescriptionCourseFormPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const [course, setCourse] = useState<any>("");
    const [modeVision, setModeVision] = useState<boolean>(false);
    const [copyDate,setCopyDate] = useState<any>()
    const { t } = useTranslation();
    useEffect(() => {
        setCourse(props?.courseForm);
        setCopyDate(props?.courseForm?.creationDate?.substring(0,10).split("-").reverse().join("/"))
    }, [props?.courseForm])
    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF', boxShadow: 'inset 0px -1px 0px #F5F5F6' }} className="w-100 px-2">

                <HeaderTrainingFormComponent type={"COURSE"} title={t('courseContentTitle')} status={course?.status} trainingTitle={course?.title} creationDate={copyDate} >
                    <div>
                        <input dir="ltr"readOnly className="input-style" type="checkbox" id="toggleBtn4" onClick={() => setModeVision(!modeVision)} checked={modeVision} />
                        <div className="toggle-container cours-editing">
                            <label className="label-style cours-editing" htmlFor="toggleBtn4">
                                <span className="on cours-editing">{t('editeMode')} </span>
                                <span className="off cours-preview">{t('Previsualiser')}</span>
                                <input dir="ltr"type="text" style={{ display: 'none' }} />
                            </label>
                        </div>
                    </div>
                </HeaderTrainingFormComponent>
            </div>

            {!modeVision ? <DescriptionCourseFormComponent /> : <PreviewCoursePageFromProf courseInfoFromProf={props?.courseForm} />}
        </>
    );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createCourse,
            uploadImageCourse,
            updatePath,
            clearFormVideo,
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    courseForm: state.courseReducer.courseForm,

});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionCourseFormPage);