import { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

const LargeSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    border: '1px solid transparent',
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '12px 26px 12px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);
const MeduimSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f2f2f5",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    padding: '6px 26px 6px 12px',
    fontFamily: [
      'Public',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
    width: '100%'
  }
}));

interface Props {
  options : {label: string, value: string | number}[];
  onChange: any;
  placeholder: string;
  defaultValue: string;
  taille?:string;
  disabled: boolean;
}
export default function SelectElement(props: Props) {
  const classes = useStyles();
  const [value, setValue] = useState("none");

  useEffect(() => {
    if(props?.defaultValue && props?.defaultValue !== value) {
      setValue(props?.defaultValue);
    }
  }, [props?.defaultValue]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    props?.onChange(event.target.value);
  };
  return (
    <div >
      <FormControl className={classes?.margin} >
        <Select

        disabled={props?.disabled}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={value}
          defaultValue={value}
          onChange={(e) => handleChange(e)}
          input={props?.taille !=="lg"?<MeduimSelect/>:<LargeSelect />}
          className={classes?.margin}
          
          renderValue= {((value: any) => {
            if(value === "none") {
              return <span className="select-placeholder">{props?.placeholder}</span>;
            }
            return <>{props?.options?.find(obj => obj.value === value)?.label}</>
          })}
        >
          <MenuItem value={'none'} hidden></MenuItem>
          {props?.options?.map(((option, index) => {
            return <MenuItem key={index}  value={option.value}>{option.label}</MenuItem>
          }))
        }
        </Select>
      </FormControl>
    </div>
  );
}