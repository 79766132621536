import { ComponentProps, FC, useState } from 'react';

import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../global-state/actions/modal-actions';
import axiosInstance from '../../../helpers/interceptors';
import { getContract, getOffer, getSubscriptions } from '../../../global-state/actions/app-container-actions';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import { useHistory } from 'react-router';

const DepositModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
        setLoading(true)
        axiosInstance
            .post(
                process.env.REACT_APP_BASE_URL17 +
                "contracts/createorupdatecontract",
                props.content.data.newContract
            ).then(async () => {
                props?.getContract(props?.schoolInfo?.id, "SCHOOL").then(() => {
                    props?.getSubscriptions(props?.schoolInfo?.id).then(() => {
                            props.closeModal()
                            history.push({ pathname: `${returnStartPathUrl()}/activity`, state: undefined });
                            setLoading(false)
                    })
                })
            })
    };
    return (
        <>

            <div className="modal-header p-4 pb-0" style={{ border: "none" }}>
                <div className="ms-auto">
                    <button type="button" className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div
                className="modal-body mb-3"
                style={{ paddingTop: 0, overflow:'hidden' }}
            >
                <div className="body-modal-publish">
                    <div
                        className="mx-5 px-5 center"
                        style={{ margin: "auto" }}
                    >
                        <div className="d-flex flex-column">

                            <div className='d-flex flex-row align-items-center mb-4'>

                                <span className="H3-Headline align-items-center mt-2">Vous souhaitez payer par virement bancaire?</span>
                            </div>
                        </div>
                        <div className="d-flex flex-colunm">
                            <span className="body-sm">En cliquant sur confirmer, vous passez à un mode de paiement par virement bancaire.
                            </span>
                        </div>
                        <div className="d-flex flex-colunm">

                            <span className="body-sm">
                                Un conseiller vous contactera pour vous fournir tous les détails afin d’effectuer
                            </span>
                        </div>
                        <div className="d-flex flex-colunm">

                            <span className="body-sm">

                                votre virement.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal-footer"
                style={{ paddingTop: 0, border: "none" }}
            >
                <div className=" d-flex mt-3 mb-5 mx-5 px-5">
                    <div className='col-md-12 text-center justify-content-center buttons-suspend-prof' style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn-Secondary large me-2" onClick={() => { props.closeModal() }} style={{ textTransform: 'uppercase', marginRight: '2%' }} >annuler</button>

                        {!loading
                            ? <button type="button" className="btn-Primary large " onClick={handleSubmit} >confirmer</button>
                            : <button type="button" className="btn-Primary large " >Traitement...</button>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            getSubscriptions,
            getOffer,
            getContract,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DepositModalComponent);