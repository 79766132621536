import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const ShareSocialMedia = ({ type, url }: any) => {

  const handleShare: any = {
    'FB': () =>  <FacebookShareButton url={url}>
        <FacebookIcon size={28} round={true} />
    </FacebookShareButton>,
    'IN': () => <LinkedinShareButton url={url}>
        <LinkedinIcon size={28} round={true} />
    </LinkedinShareButton>,
    'TW': () => <TwitterShareButton url={url}>
        <TwitterIcon size={28} round={true} />
    </TwitterShareButton>
  }

  
  return handleShare[type]();
}

export default ShareSocialMedia;
