import jwt from 'jwt-decode';
import { IReduxGetProfStatusAction, IReduxGetCourseStatusAction, IReduxGetStudentStatusAction, IReduxSaveChangeToAction, IReduxGetOfferAction, IReduxGetContractAction, EReduxActionTypes, IReduxGetSubscriptionsAction, IReduxShowPayementMethodAction, IReduxGetSchoolInfoAction, IReduxGetPaymentSchoolInfoAction, IReduxCreateOrUpdateShoolPaymentInfoAction, IReduxCreatePayementSettingsAction, IReduxUpdateSchoolInfoAction, IReduxGetConnectionAction } from '../actions/app-container-actions';

interface ISchool {
  id: string
  code: string,
  color: string,
  coverPhoto: string,
  centralLogo: string,
  creationDate: string,
  description: string,
  logo: string,
  name: string,
  shortDescription: string,
  enabled: string
  paymentInfo: any,
  payementSetting: any,

}
export interface ISchoolPaymentInfo {
  id: number
  legalPersonId: number,
  paymentEngine: string,
  currency: string
}
export interface IReduxSchoolInfoState {
  schoolInfo: ISchool | undefined
  paymentSchoolInfo: ISchoolPaymentInfo | null;
  payementSetting: any,
  paymentMethod: any,
  subscription?: any,
  connectionNumber:any,
  offer:any,
  contract:any,
  changeTo:any,
  studentStatus:any,
  courseStatus:any,
  profStatus:any
}
const initialState: IReduxSchoolInfoState = {
  schoolInfo: undefined,
  paymentSchoolInfo: null,
  payementSetting: undefined,
  paymentMethod: undefined,
  subscription: undefined,
  connectionNumber:undefined,
  offer:undefined,
  contract:undefined,
  changeTo:false,
  studentStatus: false,
  courseStatus:false,
  profStatus:false
};

type TSchoolReducerActions = IReduxGetProfStatusAction | IReduxGetCourseStatusAction | IReduxGetStudentStatusAction | IReduxSaveChangeToAction | IReduxGetOfferAction | IReduxGetContractAction | IReduxGetSubscriptionsAction | IReduxShowPayementMethodAction | IReduxCreatePayementSettingsAction | IReduxGetSchoolInfoAction |
  IReduxGetPaymentSchoolInfoAction | IReduxCreateOrUpdateShoolPaymentInfoAction | IReduxUpdateSchoolInfoAction | IReduxGetConnectionAction;

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: IReduxSchoolInfoState = initialState, action: TSchoolReducerActions) {
  switch (action.type) {
    case EReduxActionTypes.GET_SCHOOL_INFO:
      return { ...state, schoolInfo: action.data.schoolInfo, paymentSchoolInfo: action.data.paymentInfo };
    case EReduxActionTypes.UPDATE_SCHOOL_INFO:
      return { ...state, schoolInfo: action.data.schoolInfo };
    case EReduxActionTypes.PAYMENT_SCHOOL_INFO:
      return { ...state, paymentSchoolInfo: action.data };
    case EReduxActionTypes.GET_SUBSCRIPTIONS:
      localStorage.setItem("access_limitation", action?.data?.token);
      return { ...state, subscription: action.data };
    case EReduxActionTypes.CREATE_UPDATE_PAYMENT_SCHOOL_INFO:
      return { ...state, paymentSchoolInfo: action.data };
    case EReduxActionTypes.PAYEMENT_METHOD:
      return { ...state, paymentMethod: action.data };
    case EReduxActionTypes.CREATE_PAYEMENT_SETTING:
      return { ...state, payementSetting: action.data };
    case EReduxActionTypes.GET_CONNECTION_NUMBER:
      return { ...state, connectionNumber: action.data };
    case EReduxActionTypes.GET_OFFER: 
      return { ...state, offer: action.data };
    case EReduxActionTypes.GET_CONTRACT: 
      return { ...state, contract: action.data };
    case EReduxActionTypes.SAVE_CHANGE_TO:
        return { ...state, changeTo: action.data }
    case EReduxActionTypes.GET_STUDENT_STATUS:
          return { ...state, studentStatus: action.data }
    case EReduxActionTypes.GET_COURSE_STATUS:
          return { ...state, courseStatus: action.data }
    case EReduxActionTypes.GET_PROF_STATUS:
          return { ...state, profStatus: action.data }
    default:
      return state;
  }
}
