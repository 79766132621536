import { FC, useEffect } from "react";
import DatePickerElement from "./date-picker";
import TimePickerElement from "./time-picker";

interface Props{
  fieldValue:any,
  titleDate:any,
  titleTime:any,
  setField:any,
  status:any,
  startOrEnd:any

}
const DateAndTimeComponent: FC<Props> = (props: Props) => {
  return (
    <>
      <div className=" d-flex flex-column  flex-wrap me-3 pb-2 ">
        <div className="d-flex">
          <div className="col-6 me-3">
            <div className="form-title">{props?.titleDate}</div>
          </div>
          <div className="col-6">
            <div className="form-title">{props?.titleTime}</div>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="col-6 me-3">
            <DatePickerElement
              onChange={(date: string) => {
              
                    
                let newDate: string;
                if (props?.fieldValue === null) {
                  newDate = "".concat(date.split("T")[0]);
                } else {
                  newDate = "".concat(date.split("T")[0], "T", props?.fieldValue.split("T")[1]);
                }

                if (props?.startOrEnd === "startAt") {
                  props?.setField((prevState: any) => ({ ...prevState, startAt: newDate }));
                } else if (props?.startOrEnd === "expireAt") {
                  props?.setField((prevState: any) => ({ ...prevState, expireAt: newDate }));
                }
              } }
           
              dateValue={props?.fieldValue?.split("T")[0]}
              status={props?.status} label={undefined} spanClasses={undefined} required={undefined} classes={undefined}            />
          </div>
          <div className="col-6">
            <div className="select-input">
              <TimePickerElement
                onChange={(date: string) => {
  
                  const newTime = props?.fieldValue !== null && props?.fieldValue !== undefined
                    ? "".concat(props?.fieldValue.split("T")[0], "T", date)
                    : null;

                  if (props?.startOrEnd === "startAt") {
                    props?.setField((prevState: any) => ({ ...prevState, startAt: newTime }));
                  } else if (props?.startOrEnd === "expireAt") {
                    props?.setField((prevState: any) => ({ ...prevState, expireAt: newTime }));
                  }
                } }
                timeValue={props?.fieldValue?.split("T")[1]}
                status={props?.status} label={undefined} spanClasses={undefined} required={undefined} classes={undefined} dateValue={undefined}              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateAndTimeComponent;
